var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("header", { staticClass: "header" }, [
        _c("div", { staticClass: "header-content" }, [
          _c(
            "div",
            { staticClass: "logo" },
            [
              _c("router-link", { attrs: { to: "/" } }, [
                _vm.header.PC_TOP_LEFT_T1 && _vm.header.PC_TOP_LEFT_T1.length
                  ? _c("img", {
                      attrs: {
                        src: _vm.header.PC_TOP_LEFT_T1[0].sysFileUrl,
                        alt: "",
                      },
                    })
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _c("h4", [_vm._v("到货提醒")]),
        ]),
      ]),
      _c("ShoppingCartList"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "forget-password-from" }, [
    _c("div", { staticClass: "pwd-success" }, [
      _c(
        "div",
        { staticClass: "success-tips clearfix" },
        [
          _vm._m(0),
          _c(
            "p",
            [
              _vm._v("您的账号密码修改成功，"),
              _c(
                "el-button",
                {
                  staticClass: "login",
                  attrs: { type: "text" },
                  on: { click: _vm.goLogin },
                },
                [_vm._v("登录")]
              ),
              _vm._v("后可以正常下单"),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "login-btn",
              attrs: { type: "primary" },
              on: { click: _vm.goLogin },
            },
            [_vm._v("去登录")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tips-icon" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/login/icon-success.png"), alt: "" },
      }),
      _c("span", [_vm._v("恭喜您密码修改成功！")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="corporate-info">
    <div class="info-box">
      <h4 class="info-title">基本信息</h4>
      <ul class="info-items">
        <li class="item">
          <div class="label">企业名称：</div>
          <div class="value">{{baseInfo.buyersNm}}</div>
        </li>
        <li class="item">
          <div class="label">统一社会信用代码：</div>
          <div class="value">{{baseInfo.socialCreditCode}}</div>
        </li>
        <li class="item">
          <div class="label">企业法人：</div>
          <div class="value">{{baseInfo.legalPersonMember}}</div>
        </li>
        <li class="item">
          <div class="label">企业类型：</div>
          <div class="value">{{baseInfo.customerTypeName}}</div>
        </li>
        <li class="item">
          <div class="label">负责人：</div>
          <div class="value">{{baseInfo.entContactMan}}</div>
        </li>
        <li class="item">
          <div class="label">负责人手机：</div>
          <div class="value">{{baseInfo.contactMoblie}}</div>
        </li>
        <li class="item">
          <div class="label">所在区域：</div>
          <div class="value">{{baseInfo.province}}{{baseInfo.city}}{{baseInfo.district}}</div>
        </li>
        <li class="item">
          <div class="label">注册地址：</div>
          <div class="value">{{baseInfo.regAddr}}</div>
        </li>
        <li class="item">
          <div class="label">经营地址：</div>
          <div class="value">{{baseInfo.businessAddress}}</div>
        </li>
      </ul>
    </div>
    <div class="info-box">
      <h4 class="info-title">经营范围</h4>
      <ul class="info-items">
        <li class="item" v-for="(item,index) in businessRange" :key="index">
          <div class="label">{{item.nm}}：</div>
          <div class="value">{{item.rangeNm}}</div>
        </li>
        <!--        <li class="item">-->
        <!--          <div class="label">器械：</div>-->
        <!--          <div class="value">一类医疗器械；二类医疗器械；无证的二类医疗器械；三类医疗器械</div>-->
        <!--        </li>-->
        <!--        <li class="item">-->
        <!--          <div class="label">其他商品：</div>-->
        <!--          <div class="value">保健用品</div>-->
        <!--        </li>-->
      </ul>
    </div>
    <div class="info-box">
      <h4 class="info-title">收货地址</h4>
      <div class="info-items">
        <el-table
            border
            :data="addressList"
            header-row-class-name="table-header"
            style="width: 100%">
          <el-table-column
              align="center"
              label="序号"
              type="index"
              width="50">
          </el-table-column>
          <el-table-column
              prop="contactCustomerNm"
              show-overflow-tooltip
              width="128"
              label="随货联系企业名称"
          >
          </el-table-column>
          <el-table-column
              prop="ettleAccountNm"
              show-overflow-tooltip
              width="120"
              label="分支机构">
          </el-table-column>
          <el-table-column
              prop="receiverName"
              show-overflow-tooltip
              label="联系人"
              width="120">
          </el-table-column>
          <el-table-column
              prop="receiverMobile"
              show-overflow-tooltip
              label="联系电话"
              width="180">
          </el-table-column>
          <el-table-column
              prop="district"
              show-overflow-tooltip
              width="218"
              label="收货区域">
            <template slot-scope="scope">
              <span>{{scope.row.province}}</span>
              <span v-if="scope.row.city">-{{scope.row.city}}</span>
              <span v-if="scope.row.district">-{{scope.row.district}}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="receiverAddr"
              show-overflow-tooltip
              label="详细地址">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="info-box">
      <h4 class="info-title">发票信息</h4>
      <div class="info-items">
        <el-table
            :data="invoiceList"
            border
            header-row-class-name="table-header"
            style="width: 100%">
          <el-table-column
              label="序号"
              type="index"
              align="center"
              width="50">
          </el-table-column>
          <el-table-column
              prop="ettleAccountNm"
              label="分支机构"
              show-overflow-tooltip
              width="128">
          </el-table-column>
          <el-table-column
              prop="invoiceType"
              width="120"
              show-overflow-tooltip
              label="发票类型">
          </el-table-column>
          <el-table-column
              prop="invoiceTitle"
              label="发票抬头"
              show-overflow-tooltip
              width="176px">
          </el-table-column>
          <el-table-column
              prop="invoiceTfn"
              label="税号"
              show-overflow-tooltip
              width="144px">
          </el-table-column>
          <el-table-column
              prop="regTel"
              width="128px"
              show-overflow-tooltip
              label="注册电话">
          </el-table-column>
          <el-table-column
              prop="unitAddr"
              width="248px"
              show-overflow-tooltip
              label="单位地址">
          </el-table-column>
          <el-table-column
              prop="bankAccount"
              width="200px"
              show-overflow-tooltip
              label="银行账户">
          </el-table-column>
          <el-table-column
              prop="openBank"
              width="200px"
              show-overflow-tooltip
              label="开户银行">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    getCorporateBaseInfo,
    getCorporateBusinessRange,
    getCorporateReceiverAddr,
    getCorporateInvoiceInfo
  } from '@/api/member-center/personal-center'

  export default {
    name: 'CorporateInfo',
    data () {
      return {
        baseInfo: {},
        businessRange: [],
        addressList: [],
        invoiceList: []
      }
    },
    mounted () {
      this.init()
    },
    methods: {
      // 重新渲染表格头部
      renderHeader (h, { column }) {
        return h('i', {
          class: 'table-header'
        })
      },
      init () {
        this.getBaseInfo()
        this.getBusinessRange()
        this.getReceiverAddrList()
        this.getInvoiceList()
      },
      getBaseInfo () {
        getCorporateBaseInfo().then(
          res => {
            const { data } = res
            this.baseInfo = data[0]
          }
        )
      },
      getBusinessRange () {
        getCorporateBusinessRange().then(
          res => {
            const { data } = res
            this.businessRange = data
          }
        )
      },
      getReceiverAddrList () {
        getCorporateReceiverAddr().then(
          res => {
            const { data } = res
            this.addressList = data
          }
        )
      },
      getInvoiceList () {
        getCorporateInvoiceInfo().then(
          res => {
            const { data } = res
            this.invoiceList = data
          }
        )
      },
    }
  }
</script>

<style scoped lang="scss">
  .corporate-info {
    width: 100%;
    height: auto;
    background: #ffffff;
    text-align: left;

    .info-box {
      border-bottom: 1px solid rgba(229, 229, 229, 1);

      .info-title {
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        background: $titleBg;
        font-size: 16px;
        font-weight: 600;
        color: rgba(34, 34, 34, 1);
      }

      .info-items {
        padding: 24px 20px 32px 20px;
        font-size: 14px;
        font-weight: 500;

        .item {
          padding-bottom: 24px;
          display: flex;
          flex-direction: row;
          align-items: center;

          &:last-child {
            padding-bottom: 0;
          }

          &:first-child {
            padding-top: 0;
          }

          .label {
            color: rgba(102, 102, 102, 1);
            width: 160px;
            text-align: right;
            margin-right: 8px;
          }

          .value {
            color: rgba(34, 34, 34, 1);
            flex: 1;
          }
        }
      }
    }

    /*表格*/
    /deep/ .table-header {
      height: 40px;
      background: #E5E5E5;
      justify-content: flex-start;

      th {
        /*box-sizing: border-box;*/
        /*padding: 12px 0;*/
        .cell {
          padding: 0 12px;
          font-size: 12px;
          font-weight: 600;
          color: rgba(34, 34, 34, 1);
        }
      }
    }

    /deep/ .el-table {
      td {
      }

      .el-table__body {
        .cell {
          font-size: 14px;
          font-weight: 500;
          color: rgba(102, 102, 102, 1);
          padding: 0 12px;
        }
      }


    }


  }
</style>

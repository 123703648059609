import { getBus } from '@/utils/auth'
import {ApiGet, ApiPost} from "@/api";

/**
 * @description 获取某个装修模块的信息
 */
export const getPageModuleMap = data => ApiPost('/pc/front/pageModule/getPageModuleMap', data,{
  async: false,
  notCancelXhr:true,
})

/**
 * @description 获取限时折扣最近一个活动
 */
export const getDiscountRuleStart = data => ApiGet('/pc/front/discountRule/getDiscountRuleStart', data)

/**
 * @description 获取某个限时折扣详情
 */
export const pageDiscountProductRel = data => ApiPost('/pc/front/discountRule/pageDiscountProductRel', data)

/**
 * @description 获取优惠券
 */
export const pageCouponList = data => ApiPost('/pc/front/coupon/pageCouponList', data)

/**
 * @description 领取优惠券
 */
export const saveCoupon = data => ApiPost('/pc/couponReceiveSituation/saveCouponReceiveSituation', data)

/**
 * @description 为你推荐列表
 */
export const recommendToList = data => ApiGet('/pc/front/product/recommendToYou', data)

/**
 * @description 为你推荐手动设置列表
 */
export const pageRecommend = data => ApiPost('/recommendProduct/front/pageRecommend', data)

/**
 * @description 资讯分类集合树
 */
export const getSubArticleCategoryByCode = () => ApiGet('/pc/infArticle/front/getSubArticleCategoryByCode', {
  categoryCode: '000001',
  org: getBus().orgId
})

/**
 * @description 商品分类
 */
export const categoryTree = data => ApiGet('/pc/front/category/categoryTree', {
  orgId: getBus().orgId
})

/**
 * @description 商品分类
 */
export const addInfArticleViews = infArticleId => ApiGet('/infArticle/front/addInfArticleViews', {
  infArticleId
},{
  id: infArticleId
})

/**
 * @description 商品分类
 */
export const addInfVideoViews = infVidelId => ApiGet('/infVidel/front/addInfVidelViews', {
  infVidelId
},{
  id: infVidelId
})


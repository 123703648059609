import {ApiGet, ApiPost} from "@/api";
/**
 * @description 登录接口
 * @param {Object} params 请求头部
 * @param {String} params.code 	验证码
 * @param {String} params.randomStr 获取验证码所用随机字符串
 * @param {Object} data 请求参数
 * @param {String} data.loginId 登录账号
 * @param {String} data.password 登录密码,前端必须加密
 */
export const login = (params, data) => ApiPost('/pc/front/user/login',data,{
  headers: {
    'x-validate-code': params.code,
    'x-random-str': params.randomStr
  }
})

/*
* 获取用户数据
* */
export const getInfo = data => ApiGet('/pc/member/user/getFrontUserInfoVo',data,{
  notCancelXhr: true
})

export const getOrderNumDetail = data => ApiGet('/pc/order/memberDetailspOrderNum',data)

/*
* 登出
* */
export const logout = data => ApiPost('/vue-admin-template/user/logout',data,{},false)

/*
* 手机登录
* */
export const phoneVerif = data => ApiPost('/pc/front/user/loginByMobile', {
  loginMobileId: data.phone,
  validateCode: data.sms
})

/*
* 验证码
* */
export const phoneVerifCode = (val, code) => ApiPost('/smsQueue/front/savePlatformSmsQueue', {
  mobile: val
},{
  headers: {
    'x-merchant-code-header': code
  },
})

export const getSmsAccountInfState = data => ApiGet('/smsAccountInf/front/getSmsAccountInfState',data)

export const findByProductIds = data => ApiGet('/productLicenseRel/front/findByProductIds',data)

/**
 * @description 批量下载商品资质
 */
export const downloadPdfZip = data => ApiPost('/productLicenseRel/upload/downloadPdfZip',data)

/*
* 获取采购计划提醒
* */
export const  getProcurementPlanTx = data => ApiGet('/pc/procurementPlan/getProcurementPlanTx', data)
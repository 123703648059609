var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.recoProduct.length > 0
    ? _c("div", [
        _vm.type === "default"
          ? _c(
              "div",
              { staticClass: "recommend-container recommend-default" },
              [
                _c(
                  "div",
                  { staticClass: "recommend" },
                  [
                    _c("p", [_vm._v("为你精选")]),
                    _c("swiper", {
                      staticClass: "recommend-swiper",
                      attrs: { "reco-product": _vm.recoProduct },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _c("div", { staticClass: "recommend-container recommend-for" }, [
              _c("div", { staticClass: "recommend" }, [
                _c("p", [_vm._v("猜您喜欢")]),
                _c(
                  "div",
                  [
                    _c("swiper", {
                      staticClass: "recommend-swiper",
                      attrs: {
                        "slides-per-view": 3,
                        "reco-product": _vm.recoProduct,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
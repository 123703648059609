<template>
  <el-form
    ref="form"
    :model="form"
    :rules="formRules"
    label-width="0px"
    class="forget-phone-from"
  >
    <el-form-item prop="loginMobileId">
       <!-- @keyup.native="verfy" -->
      <el-input
        v-model="form.loginMobileId"
       
        placeholder="输入新的手机号码"
        type="tel"
        maxlength="11"
      ></el-input>
      <div class="input-icon">
        <img src="@/assets/img/login/icon-shouji.png" class="img-shouji" alt />
      </div>
    </el-form-item>
    <el-form-item prop="smsCode " class="sj-yanzheng">
      <el-input
        ref="sms"
        v-model="form.smsCode "
        placeholder="输入验证码"
        @keyup.native="verfy"
        name="sms"
        tabindex="2"
        @keyup.enter.native="showValid"
        clearable
      />
      <div class="input-icon">
        <img src="@/assets/img/login/icon-yanzhengma.png" alt />
      </div>
      <el-button :disabled="!VerifBtn" @click="handleVerif"
        >{{ timeNum > 0 ? `已发送${timeNum}s` : "发送验证码" }}
      </el-button>
    </el-form-item>
    <el-form-item>
      <el-button
        @click="changePhone"
        class="sureBut"
        type="primary"
        :disabled="isSure"
      >
        {{
          origin === "memberChangePhones" ? "确定绑定" : "确定修改"
        }}</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import formRules from "@/utils/validate";
import { phoneVerifCode } from "@/api/user";
import { saveFrontVerification } from "@/api/login/forget-password";
import Search from "@/components/Search/Search";

import { changePhone } from "@/api/member-center/personal-center";

export default {
  name: "changeSuccess",
  components: {
    Search,
  },
  data() {
    return {
      origin: this.$route.query.origin,
      changeTitle: "memberChangePhone",
      form: {
        loginMobileId: "",
        smsCode: "",
      },
      formRules: {
        loginMobileId: [formRules.phone],
        smsCode: [{ required: true, trigger: "blur", message: "请输入验证码" }],
      },
      isSure: true,
      VerifBtn: false,
      timeNum: 0,
    };
  },
  watch: {
    'form.loginMobileId': {
      handler: function () {
        if (this.form.loginMobileId !== "") {

          if (formRules.validPhone(this.form.loginMobileId)) {
            if (this.timeNum === 0) {
              this.VerifBtn = true;
            }
          } else {
            this.VerifBtn = false;
          }
        } else {
          this.VerifBtn = false;
        }
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    verfy() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.isSure = false;
        }
      });
    },

    changePhone() {
      changePhone(this.form).then(
        async (res) => {
          if (res.code === "0") {
            await this.$store.dispatch("user/getInfo");
            await this.$router.push({ path: "/" });
            this.$message.success("账户手机号修改成功");
          }
        }
      );
    },
    handleVerif() {
      // this.handleLoginPhone()
      this.rightTime();
    },
    showValid() {
      this.changePhone();
    },
    rightTime() {
      // 倒计时
      if (!this.timeNum) {
        const data = this.form.loginMobileId;
        this.$store
          .dispatch("user/phoneVerifCode", { data })
          .then((response) => {
            this.timeNum = 60; // 初始值为60秒
            this.VerifBtn = false;
            const clertime = setInterval(() => {
              // 计时器
              this.timeNum--;
              if (this.timeNum <= 0) {
                // 如果减到0则清楚计时器
                clearInterval(clertime);
                this.VerifBtn = true;
              }
            }, 1000);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sj-yanzheng .el-button {
  margin-top: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  color: #999;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #999;
  width: 104px;
  padding: 0;

  &.is-disabled {
    color: #ccc;
    background-color: #f8f8f8;
    border-color: #ccc;
  }

  span {
    font-size: 16px;
  }
}

.sj-yanzheng {
  .el-input__inner {
    margin-right: 120px;
    width: 224px;
  }
}
.input-icon {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
  }
}
.forget-phone-from {
  height: 328px;
  width: 400px;
  margin: 40px auto 25px;
  /deep/ .el-input__inner {
    padding-left: 45px;
  }
  /deep/ .el-dialog__header {
    padding: 0px;
  }
  /deep/ .el-dialog__headerbtn {
    top: 10px;
  }
  input {
    padding-left: 48px;
  }
  .input-icon {
    position: absolute;
    left: 7px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    .img-user {
      width: 34px;
      height: 36px;
    }
    .img-shouji {
      width: 37px;
      height: 36px;
    }
    .img-suo {
      width: 37px;
      height: 36px;
    }
  }

  .sureBut {
    width: 400px;
    height: 40px;
    background-color: #0971eb;
  }
}

.registerDiv {
  width: 100%;
  text-align: center;
  .step {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px auto 0 auto;
    .stepNum {
      width: 20px;
      height: 20px;
      background-color: #0971eb;
      border-radius: 50%;
      color: #ffffff;
      text-align: center;
      line-height: 20px;
      font-size: 14px;
    }
    .stepNumNot {
      background-color: #e5e5e5;
      color: #999999;
    }
    .stepTitle {
      display: block;
      padding-top: 2px;
      color: #222222;
      margin-left: 8px;
      font-weight: 700;
      font-size: 16px;
    }
    .stepTitleNot {
      color: #a3a3a3;
    }
    .stepLine {
      width: 60px;
      height: 1px;
      background-color: #0971eb;
      margin: auto 16px;
    }
    .stepLineNot {
      background-color: #dddddd;
    }
  }
  .registerFrom {
    width: 400px;
    margin: 25px auto;
    input {
      padding-left: 48px;
    }
    .input-icon {
      position: absolute;
      left: 12px;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      .img-user {
        width: 14px;
        height: 16px;
      }
      .img-suo {
        width: 13px;
        height: 16px;
      }
    }
  }

  .sureBut {
    width: 400px;
    height: 40px;
    background-color: #0971eb;
  }
  .divisionClass {
    height: 20px;
    width: 100%;
    background: #f6f6f6;
  }
}
</style>
<style lang="scss">
.forget-phone-from {
  .el-dialog {
    width: 500px;
    .el-dialog__body {
      padding: 50px 20px;
    }
  }
}
</style>

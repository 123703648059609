<template>
  <transition>
    <div class="messageDiv">
      <img class="icon" src="@/assets/img/customer-service/right.png" >
      {{message}}
    </div>
  </transition>
</template>

<script>
export default {
  name: "successMessage",
  props: {
    message: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.messageDiv{
  position: absolute;
  right: 50%;
  left: 50%;
  top: 40%;
  z-index: 100;
  height: 36px;
  width: fit-content;
  background-color: #ffffff;
  padding:0 20px;
  display: flex;
  font-size: 14px;
  color: #666666;
  line-height: 36px;
  box-shadow:0px 2px 4px 0px rgba(0,0,0,0.08);
  border-radius:2px;
  .icon{
    width: 18px;
    height: 18px;
    margin-right: 8px;
    margin-top: 9px;
  }
}
.v-enter, .v-leave-to{
  opacity: 0

}
.v-enter-active, .v-leave-active {
  transition: opacity 0.8s
}

</style>

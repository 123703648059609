var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "im-page-modal",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "security-center",
      attrs: { title: "安全中心", isShowBack: false },
    },
    [
      _c("el-col", { staticClass: "sercurity-item" }, [
        _c(
          "div",
          { staticClass: "sercurity-item-c" },
          [
            _c(
              "div",
              { staticClass: "left-c" },
              [
                _c("el-image", {
                  staticStyle: {
                    width: "48px",
                    height: "48px",
                    "margin-right": "20px",
                  },
                  attrs: { src: _vm.changePasswordImg },
                }),
                _c(
                  "div",
                  { staticStyle: { height: "100%" } },
                  [
                    _c("el-col", { staticClass: "con-text" }, [
                      _c("span", { staticClass: "logintitle" }, [
                        _vm._v("登录密码"),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "con-text" }, [
                      _c("span", { staticClass: "logininfo" }, [
                        _vm._v(
                          "互联网账号存在被盗风险，建议您定期更改密码以保护账户安全"
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticStyle: { width: "110px" },
                attrs: { size: "small" },
                on: {
                  click: function ($event) {
                    return _vm.changeInfo("password")
                  },
                },
              },
              [_vm._v("修改密码")]
            ),
          ],
          1
        ),
      ]),
      _c("el-col", { staticClass: "sercurity-item" }, [
        _c(
          "div",
          { staticClass: "sercurity-item-c" },
          [
            _c(
              "div",
              { staticClass: "left-c" },
              [
                _c("el-image", {
                  staticStyle: {
                    width: "48px",
                    height: "48px",
                    "margin-right": "20px",
                  },
                  attrs: { src: _vm.changePhoneImg },
                }),
                _c(
                  "div",
                  { staticStyle: { height: "100%" } },
                  [
                    _c("el-col", { staticClass: "con-text" }, [
                      _c("span", { staticClass: "logintitle" }, [
                        _vm._v("账户手机号"),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "con-text" }, [
                      _c("span", { staticClass: "logininfo" }, [
                        _vm._v(
                          "可用于登录，可通过手机对个人信息进行修改和重置，提高帐号安全性"
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticStyle: { width: "110px" },
                attrs: { size: "small" },
                on: {
                  click: function ($event) {
                    return _vm.changeInfo("phone")
                  },
                },
              },
              [_vm._v("修改手机号")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
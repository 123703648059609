<template>
  <div class="im-pagination">
    <div class="main-info" v-if="(isShowMainInfo && total > limit) || showCollection">
      共{{ total }}条记录 第 {{ currentPage < 1 ? 1:currentPage }} / {{ total === 0 ? 1: Math.ceil(total/limit) }} 页
    </div>
     <div class="main-info" v-else></div>
    <div>
      <el-pagination
        background
        :hide-on-single-page="hideOnSinglePage"
        :current-page.sync="page"
        :page-size.sync="limit"
        :page-sizes="pageSizes"
        :layout="layout"
        :total="total"
        :pager-count="pagerCount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
<script>
import defaultSettings from '@/settings'
export default {
  name: 'ImPagination',
  props: {
    showCollection: {
      type: Boolean,
      default: false
    },
    pageSize: {
      type: Number,
      default: defaultSettings.pageSize
    },
    pagerCount: {
      type: Number,
      default: 7
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 40, 50, 100]
      }
    },
    currentPage: {
      type: Number,
      default: 1
    },
    total: {
      required: true,
      type: Number
    },
    isShowMainInfo:{
      type: Boolean,
      default: true
    },
    layout:{
      type: String,
      default: 'prev, pager, next, sizes, jumper'
    },
    hideOnSinglePage:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
    }
  },
  computed: {
    page: {
      get() {
        return this.currentPage
      },
      set(val) {
        this.$emit('update:currentPage', val)
      }
    },
    limit: {
      get() {
        return this.pageSize
      },
      set(val) {
        this.$emit('update:pageSize', val)
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      //   this.limit = val
      this.$emit('pagination', { page: this.currentPage, pageSize: val })
    },
    handleCurrentChange(val) {
      this.$emit('pagination', { page: val, pageSize: this.limit })
    }
  }
}
</script>
<style lang="scss">
@import '~@/styles/variables';
.im-pagination {
   position: relative;
   z-index: 10;
    display: flex;
    justify-content: space-between;
    font-size: 14px !important;
    color: $tableTextColor;
    .main-info {
        font-size: 13px;
        line-height: 32px;
    }
    .el-input_inner {
        height: 24px;
    }
    .el-pager li {
        height: 24px;
    }
    .el-pagination.is-background .btn-prev, .el-pagination.is-background .btn-next, .el-pagination.is-background .el-pager li {
        background-color: transparent;
        min-width: 24px;
        height: 24px;
        line-height: 24px;
        margin: 2px;
    }
    .el-pagination__jump {
        margin-left: 11px;
    }
    /deep/ .el-input__inner {
        height: 24px !important;
    }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { width: "800px", title: "选择客户", visible: "" },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "div",
        { staticClass: "search" },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入客户编码/客户名称" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.onSearch.apply(null, arguments)
              },
            },
            model: {
              value: _vm.searchField,
              callback: function ($$v) {
                _vm.searchField = $$v
              },
              expression: "searchField",
            },
          }),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.onSearch },
            },
            [_vm._v("搜索")]
          ),
        ],
        1
      ),
      _c(
        "im-table-page",
        {
          ref: "tablePage",
          staticStyle: { width: "100%", "margin-top": "15px" },
          attrs: {
            data: _vm.tableData,
            "remote-method": _vm.queryData,
            addQuantity: true,
            index: true,
            "header-row-class-name": "header-row",
          },
          on: {
            "update:data": function ($event) {
              _vm.tableData = $event
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "erpCode",
              label: "客户编码",
              width: "100px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "buyersNm",
              align: "left",
              label: "客户名称",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "80px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.onChoose(scope.row)
                          },
                        },
                      },
                      [_vm._v("选择")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="info-detail-content">
    <Search :index-nav="true" />
    <div class="nav-address">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/info-channel' }">资讯中心</el-breadcrumb-item>
        <el-breadcrumb-item>{{ dataDateil.title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="detail-content">

      <div class="detail-name">{{ dataDateil.title }}</div>
      <div class="detail-info"><span>发布时间：{{ dataDateil.createTime }}</span></div>
      <div class="info-content" v-html="dataDateil.articleCont">


      </div>
      <!-- <div class="info-content">
        <tinymce id="inforContent" ref="Information" v-model="dataDateil.articleCont" />
      </div> -->
    </div>
  </div>
</template>

<script>
import Search from '@/components/Search/Search'
import { getInfArticle } from '@/api/promotion-zone/info-channel'
import Tinymce from '@/components/Tinymce/index'
export default {
  components: {
    Search,
    Tinymce
  },
  data() {
    return {
      dataDateil: {}
    }
  },
  watch: {
    $route: {
      handler(route) {
        if (route.params.id) {
          getInfArticle(route.params.id).then(res => {
            this.dataDateil = {
              ...res.data
            }
            //正则处理pc端<strong>标签加粗无效问题
            this.dataDateil.articleCont = this.dataDateil.articleCont.replace(/<strong>([\s\S]*?)<\/strong>/g, function (match, content) {
              return '<span style="font-weight: 800;">' + content + '</span>';
            });

          })

        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.info-detail-content {
  background: #F5F5F5;
  padding-bottom: 40px;

  .nav-address {
    width: 1200px;
    margin: 0 auto;
    padding: 0 20px;

    .el-breadcrumb {
      height: 50px;
      line-height: 50px;
    }
  }

  .detail-content {
    padding: 40px 110px;
    width: 1200px;
    margin: 0 auto 40px;
    text-align: left;
    background: #fff;

    .detail-name {
      font-size: 30px;
      // font-weight: 400;
      color: rgba(38, 38, 38, 1);
    }

    .detail-info {
      font-size: 12px;
      // font-weight: 500;
      color: rgba(113, 113, 113, 1);
      padding-top: 14px;

      span {
        padding-right: 20px;
      }
    }

    .info-content {
      line-height: 50px;
      padding-top: 20px;

      img {
        max-width: 980px;
      }

      table {
        margin: 0 auto;

        td {
          border: 1px solid #222;
        }
      }
    }
  }
}
</style>

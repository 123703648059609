<template>
  <im-page-modal
      class="procurement-plan-update"
      v-loading="loading"
      :title="title"
      :isShowBack="isShowBack"
      backTitle="返回采购计划"
      @handle-back="goBack(false)"
  >
    <el-row style="padding-top: 20px;">
      <el-col>
            <span style="display:inline-block;">
            <el-form
                ref="form"
                :model="form"
                :rules="formRules"
                label-width="142px"
            >
             <el-form-item label="采购计划名称：" prop="procurementPlanNm">
                <el-input
                    v-model="form.procurementPlanNm"
                    style="width: 400px;"
                    placeholder="请输入采购计划名称"
                    :disabled="disabled"
                />
              </el-form-item>
              <el-form-item label="开启提醒：" prop="isRemind">
                  <el-switch
                      v-model="form.isRemind"
                      :disabled="disabled"
                      class="swich-inner-text"
                      active-text="是"
                      inactive-text="否"
                      active-color="#1CB7CD"
                      active-value="Y"
                      inactive-value="N"
                  />
              </el-form-item>
            <template v-if="form.isRemind === 'Y'">
              <el-form-item label="是否重复提醒：" prop="isRepeatRemind" v-if="false">
                  <el-switch
                      @change="changeIsRepeatRemind"
                      v-model="form.isRepeatRemind"
                      style="margin-right:30px;"
                      :disabled="disabled"
                      class="swich-inner-text"
                      active-text="是"
                      inactive-text="否"
                      active-color="#1CB7CD"
                      active-value="Y"
                      inactive-value="N"
                  />
                  <span>每</span><el-input-number
                  v-model="form.remindDay"
                  size="small"
                  style="width: 60px;margin:0 10px;"
                  :min="0"
                  :disabled="disabled || form.isRepeatRemind == 'N' "
                  :controls="false"
              /><span>天重复提醒我</span>
               </el-form-item>
               <el-form-item label="提示内容：" prop="remindContent">
                <el-input
                    size="small"
                    v-model="form.remindContent"
                    style="width: 400px;"
                    placeholder=""
                    :disabled="disabled"
                />
              </el-form-item>
              <el-form-item label="提示方式：" prop="warnWay">
                  <el-checkbox-group v-model="form.warnWay" :disabled="disabled" @change="handleChangeWay">
<!--                    <el-checkbox label="isMobileRemind">PC</el-checkbox>-->
                    <el-checkbox label="isPcRemind">PC</el-checkbox>
                    <el-checkbox label="isAppRemind">App</el-checkbox>
                    <el-checkbox label="isWechatRemind">微信</el-checkbox>
                  </el-checkbox-group>
              </el-form-item>
              <el-form-item label="提醒时间：" prop="remindTime" >
                <el-date-picker
                  v-if="form.isRepeatRemind !== 'Y'"
                  style="width:400px"
                  prefix-icon="el-icon-date"
                  v-model="form.remindTime"
                  :disabled=" disabled"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetime"
                  placeholder="请选择提醒时间"
                />
                <el-time-picker
                  v-else
                   v-model="form.remindTime"
                   placeholder="请选择提醒时间">
                </el-time-picker>
              </el-form-item>
              </template>
            </el-form>
            </span>
      </el-col>
    </el-row>
    <!-- 商品清单 -->
    <div class="shop-list-header">
      <span class='header-text'>商品清单</span>
      <el-button  size="mini" @click="handelAddShop">添加商品</el-button>
      <el-button  size="mini" @click='handleDeleteMore'>批量删除</el-button>
    </div>
    <!-- 表格 -->
    <!-- <el-form  ref="tableForm" :model="form"> -->
    <el-table
        ref="tablePage"
        :data="form.tableData"
        :class="{'is-tabledata-empty':form.tableData.length === 0}"
        border
        style="width: 100%"
        @selection-change="(val)=>{selectItem = val}">
      >
      <el-table-column type="selection" width="50" align="center"/>
      <el-table-column prop="productNm" label="商品名称" width="180" fixed="left" show-overflow-tooltip>
        <template slot-scope="scope">
          {{$util.getGoodsName(scope.row)}}
        </template>
      </el-table-column>
      <el-table-column prop="spec" label="规格" width="120" show-overflow-tooltip/>
      <el-table-column prop="unit" label="单位" width="70" show-overflow-tooltip/>
      <el-table-column prop="manufacturer" label="厂家(上市许可持有人)/产地" width="200" show-overflow-tooltip/>
      <el-table-column prop="datepackTotal" label="中/件包装" width="120" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{scope.row.packTotal?scope.row.packTotal:'--'}}</span>&nbsp;/&nbsp;<span>{{scope.row.midPackTotal?scope.row.midPackTotal:'--'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="minBuyMultiple" label="最小购买倍数" width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          {{getRealMinBuyNum(scope.row)}}
        </template>
      </el-table-column>
      <el-table-column prop="stockQuantity" label="库存" width="80" show-overflow-tooltip>
        <template slot-scope="scope">
        <span>{{ scope.row.stockQuantity >= 100 ? '≥100' : '<100' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="price" align="right" label="单价" width="80"/>
      <el-table-column prop="productQuantity" label="数量" width="160" fixed="right" align="center">
        <template slot-scope="scope">
          <el-input-number size="mini"
                           v-model="scope.row.productQuantity"
                           step-strictly
                           :precision="getDecimalPointNum(scope.row)"
                           :step="getRealMinBuyNum(scope.row)"
                           :min="getRealMinBuyNum(scope.row)"/>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="操作" width="80" fixed="right" align='center'>
        <template slot-scope="scope">
          <span>
             <el-button size="small" style="padding:2px 4px"
                        @click="form.tableData.splice(scope.$index,1)">删除</el-button>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <!-- </el-form> -->
    <div v-if="form.tableData.length === 0" class="empty-default">
      <div class="empty-box">
        <el-image style="width: 160px; height: 160px" :src="emptyDefault" fit="fill"/>
        <p class="empty-tip">暂无商品~</p>
      </div>
    </div>

    <div v-if="editType === 'add'" slot="footer">
      <el-tooltip
          class="item"
          effect="dark"
          content="完成所有必填信息可提交"
          placement="top-end"
      >
        <el-button size="small" type="primary" @click="onCommit">创建采购计划</el-button>
      </el-tooltip>
      <el-button size="small" @click="goBack(false)">取消</el-button>
    </div>
    <div v-if="editType === 'edit'" slot="footer" class="content-footer">
      <el-checkbox v-model="AllChecked" @change="()=>{this.$refs.tablePage.toggleAllSelection()}">全选</el-checkbox>
      <div>
        <el-button size="small"
                   type="primary"
                   :disabled="isBanAddToCart"
                   style="background-color:#FA6400;border-color:#FA6400"
                   @click="handleAddCart">加入购物车</el-button>
        <el-button size="small" @click="onCommit">保存采购计划</el-button>
      </div>
    </div>
    <add-shop ref="addShop" :productList.sync='form.tableData'/>
  </im-page-modal>
</template>
<script>
  import formRules from '@/utils/validate'
  import emptyDefault from '@/assets/img/member-center/content-empty-default.png'
  import productDefault from '@/assets/img/index/product_default.png'
  import { batchAddItem } from '@/api/detail'
  import addShop from './components/add-shop'
  import { getBus } from '@/utils/auth'
  import { saveOrUpdateProcurementPlan, getProcurementPlanById } from '@/api/member-center/transactions-using'
  import addToCartMixin from '@/common/addToCart'

  import {mapGetters} from 'vuex'
  export default {
    name: 'ProcurementPlanUpdate',
    components: {
      addShop
    },
    mixins:[addToCartMixin],
    props: {
      params: {
        type: Object,
        default () {
          return {}
        }
      }
    },
    data () {
      const checkIsRepeatRemind = (rule, value, callback) => {
        if (value === 'Y' && !this.form.remindDay) {
          return callback(new Error('请填写天数'))
        }
        callback()
      }
      const CheckQuantity = (rule, value, callback) => {
        if (!value || value <= 0) {
          return callback(new Error('请输入商品数量'))
        }
        callback()
      }
      return {
        loading: false,
        editType: '',
        // tableData:[],
        selectItem: [],
        AllChecked: false,
        userId: getBus().buyersId,
        form: {
          procurementPlanNm: '',
          isRemind: 'N',
          isRepeatRemind: 'N',
          remindContent: '',
          remindDay: '',//提醒时间天
          warnWay: [],
          isMobileRemind: 'N',
          isAppRemind: 'N',
          isWechatRemind: 'N',
          remindTime: '',
          tableData: []
        },
        formRules: {
          procurementPlanNm: [formRules.blurRes('采购计划名称')],
          isRepeatRemind: [{ required: false, trigger: 'change', validator: checkIsRepeatRemind }]
        },
        productRules: {
          redemptionPrice: [{ required: true, trigger: 'blur', validator: CheckQuantity }]
        },
        productDefault,
        emptyDefault
      }
    },
    computed: {
      ...mapGetters(['license']),
      title () {
        const titleMap = {
          add: '新增',
          edit: '编辑',
          watch: '查看'
        }
        return titleMap[this.editType] + '采购计划'
      },
      disabled () {
        return this.editType === 'watch'
      },
      isShowBack () {
        return this.editType === 'edit'
      }
    },
    watch: {
      selectItem (newVal) {
        this.AllChecked = newVal.length === this.form.tableData.length
      }
    },
    created () {
      this.init()
    },

    methods: {
      changeIsRepeatRemind(e){
        this.form.remindDay = this.form.isRepeatRemind === 'Y'? this.form.remindDay : ''
        if(this.form.isRepeatRemind === 'Y'){
          this.form.remindTime = ''
        }
      },
      init () {
        const { rowData, editType } = this.params
        if (rowData) this.rowData = rowData
        this.editType = editType
        if (this.editType !== 'add') {
          getProcurementPlanById(rowData.procurementPlanId).then(res => {
            var formData = JSON.parse(JSON.stringify(res.data))
            formData.warnWay = []
            formData.tableData = formData.productVos
            if (formData.isMobileRemind === 'Y') formData.warnWay.push('isMobileRemind')
            if (formData.isWechatRemind === 'Y') formData.warnWay.push('isWechatRemind')
            if (formData.isAppRemind === 'Y') formData.warnWay.push('isAppRemind')
            if (formData.isPcRemind === 'Y') formData.warnWay.push('isPcRemind')
            delete formData.productVos
            this.form = formData
          })
        }
      },
      handleDeleteMore () {
        this.selectItem.map(itemOne => {
          this.form.tableData.filter((itemTwo, index) => {
            if (itemTwo.productId === itemOne.productId) {
              this.form.tableData.splice(index, 1)
            }
          })
        })
      },
      handleChangeWay () {
        this.form.isMobileRemind = this.form.warnWay.indexOf('isMobileRemind') >= 0 ? 'Y' : 'N'
        this.form.isPcRemind = this.form.warnWay.indexOf('isPcRemind') >= 0 ? 'Y' : 'N'
        this.form.isAppRemind = this.form.warnWay.indexOf('isAppRemind') >= 0 ? 'Y' : 'N'
        this.form.isWechatRemind = this.form.warnWay.indexOf('isWechatRemind') >= 0 ? 'Y' : 'N'
      },
      handelAddShop () {
        this.$refs.addShop.open()
      },
      validate () {
        let isPass = true
        this.$refs.form.validate(valid => {
          if (!valid) isPass = false
        })
        if (this.form.tableData.length === 0) {
          isPass = false
          this.$message.error('请选择采购商品')
        }
        return isPass
      },
      handleAddCart () {
        if (this.selectItem.length === 0) {
          this.$message.error('请选择商品')
          return
        }
        let itemList = this.selectItem.map(res => {
          return {
            skuId: res.skuId,
            quantity: res.productQuantity
          }
        })
        batchAddItem({ itemList }).then(res => {
          const { code } = res
          if (code === '0') {
            this.$message.success('加入成功！')
            this.$store.dispatch('user/setCartNum', res.data.allProductTypeTotalQuantity)
          }
        })
      },
      onCommit () {
        if (this.editType === 'watch') this.goBack()
        var isPass = this.validate()
        if (isPass) {
          var procurementPlanRelSaveVoList = []
          this.form.tableData.map(item => {
            var newObj = {
              procurementPlanRelId: item.procurementPlanRelId ? item.procurementPlanRelId : '',
              procurementPlanId: this.rowData.procurementPlanId ? this.rowData.procurementPlanId : '',
              productId: item.productId,
              skuId: item.skuId,
              productQuantity: item.productQuantity
            }
            procurementPlanRelSaveVoList.push(newObj)
          })
          if (this.form.isRemind === 'N') {
            this.form = {
              orgId: this.form.orgId ? this.form.orgId : '',
              procurementPlanId: this.form.procurementPlanId ? this.form.procurementPlanId : '',
              procurementPlanNm: this.form.procurementPlanNm,
              isRemind: 'N',
              isRepeatRemind: 'N',
              remindContent: '',
              remindDay: '',//提醒时间天
              warnWay: [],
              isMobileRemind: 'N',
              isAppRemind: 'N',
              isWechatRemind: 'N',
              remindTime: ''
            }
          }
          this.form.tableData = []
          this.form.warnWay = []
          var params = {
            ...this.form,
            isRepeatRemind: 'N',
            isMobileRemind: 'N',
            procurementPlanRelSaveVoList: procurementPlanRelSaveVoList,
            userId: this.userId
          }
          saveOrUpdateProcurementPlan(params).then(res => {
            this.$message.success(res.msg)
            this.goBack(true)
          })
        }
      },
      goBack (refreshList) {
        this.$emit('change-page', {
          page: 'ProcurementPlanList',
          params: {
            refreshList
          }
        })
      },
    }
  }
</script>
<style lang="scss">
  .procurement-plan-update {
    .header {
      border-bottom: 1px solid #ccc;
    }

    .footer {
      box-shadow: none;
      border-top: 1px solid #ccc;
    }

    .el-form-item {
      height: 32px;

      .el-form-item__content {
        display: flex;
        height: 32px;
        align-items: center;
      }

      .el-input__inner {
        height: 32px;
        line-height: 32px;
      }

      .el-form-item__label {
        height: 32px;
        line-height: 32px;
      }
    }

    .shop-list-header {

    }

    .is-tabledata-empty {
      .el-table__body-wrapper {
        display: none
      }
    }
  }
</style>
<style scoped lang='scss'>
  .procurement-plan-update {
    width: 975px;
    margin: 0 auto;
    padding: 0 15px;

    .shop-list-header {
      background-color: $hoverBg;
      height: 40px;
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .header-text {
        font-size: 16px;
        padding: 10px 20px;
      }
    }

    .content-footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .empty-default {
      display: flex;
      height: 442px;
      justify-content: center;
      padding-top: 80px;

      .empty-tip {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(113, 113, 113, 1);
        line-height: 20px;
      }
    }
  }

</style>

import {ApiGet, ApiPost} from "@/api";

/**
 * @description 获取某个订单详情
 */
export const findDetail = data => ApiGet('/order/findDetail',data)

/**
 * @description 获取订单分页
 */
export const pageOrder = data => ApiPost('/pc/order/pageOrder',data)

/**
 * @description 再次购买
 */
export const buyAgain = data => ApiGet('/pc/member/normalShoppingFlow/buyAgain',data)

/**
 * @description 查询是否可以再次购买
 */
export const checkBuyAgain = data => ApiGet('/pc/member/normalShoppingFlow/isBuyAgain',data)

/**
 * @description 全部支付方式
 */
export const allPaymentWay = data => ApiGet('/paymentWay/allPaymentWay',data)

/**
 * @description 导出商品明细
 */
export const exportOrderItem = data => ApiGet('/orderItem/exportOrderItemExcel',data,{
  responseType: 'blob'
})

/**
 * @description 确认收货
 */
export const comfirmOrder = data => ApiGet('/pc/order/comfirmOrder',data)

/**
 * @description 下载药检单
 */
export const downloadSheet = data => ApiGet('/fileService/fileLib/upload/downloadZipFiles',data,{
  responseType: 'blob',
},false)

/*
* @description 下载药检单V2
* */
export const downloadSheetV2 = data => ApiGet('/drugList/front/downloadDrugList',data,{
  responseType: 'blob'
})

/**
 * @description 订单对应的药检单
 */
export const pageOrderDrugList = data => ApiPost('/drugList/front/pageOrderDrugList',data)

/**
 * @description 确认订单
 */
export const comfirmTheOrder = data => ApiGet('/pc/order/comfirmTheOrder',data)

/**
 * @description 根据订单ID获取往来账单
 */
export const currentBills = data => ApiGet('/pc/currentBills/getCurrentBillsByOrderId',data)

/**
 * @description 取消订单
 */
export const cancelOrder = data => ApiGet('/pc/order/cancelOrder',data)

/**
 * @description 电子发票
 */
export const downloadInvoice = data => ApiPost('/pc/currentBills/upload/downloadZipFiles',data,{
  responseType:'blob'
})

/**
* @description 物流信息
*/
export const findWmsLogistics = data => ApiGet('/order/findWmsLogistics',data)

/**
 * @description 退款按钮显示与隐藏
 */
export const findByParamInfInnerCode = data => ApiGet('/paramInf/findByParamInfInnerCode',data)

/**
 * @description 细单确定退款
 */
export const refundOnlineOrderItem = data => ApiPost('/pc/order/refundOnlineOrderItem',data)

/**
 * @description 细单确定退款（部分退款）
 */
export const refundOnlinePartialFinishOrderItem = data => ApiPost('/pc/order/refundOnlinePartialFinishOrderItem',data)

/**
 * @description 总单退款
 */
export const refundOnlineOrder = data => ApiPost('/pc/order/refundOnlineOrder',data)

<template>
  <div class="currentAccountDiv">
    <div class="title">往来账核对</div>
    <div class="search">
      <el-date-picker
        v-model="searchForm.time"
        class="datePicker"
        type="daterange"
        value-format="yyyy-MM-dd"
        format="yyyy-MM-dd"
        size="small"
        align="right"
        unlink-panels
        range-separator="至"
        @change="changeDatePicker"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions">
      </el-date-picker>
      <el-button type="primary" @click="onSearch" size="mini" style="background-color: #0971EB">搜索</el-button>
      <el-button @click="onReset" size="mini" style="border-color: #ffffff">重置</el-button>
      <el-button class="importBut" size="mini" @click="getExcel">导出明细</el-button>
    </div>
    <el-collapse-transition>
      <div class="chooseState" v-show="chooseShow">
        <p class="received" :class="{colorBlue: searchForm.isReceivable != 'N'}" @click="selectMode(true)">已回款</p>
        <p class="received" :class="{colorBlue: searchForm.isReceivable === 'N'}" @click="selectMode(false)" >未回款</p>
      </div>
    </el-collapse-transition>
    <im-table-page
      ref="tablePage"
      :data.sync="tableData"
      :remote-method="remoteMode">
      <el-table-column label="序号" type="index"  width="60" fixed="left" align="center"/>
      <el-table-column label="回款情况" prop="receivable" width="100" fixed="left" show-overflow-tooltip>
        <template slot="header" slot-scope="scope">
          <div @click="chooseState">回款情况
            <i :class="chooseIcon"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <span :class="{colorOrg: scope.row.isReceivable === 'N'}">{{scope.row.receivable}}</span>
        </template>
      </el-table-column>
      <el-table-column label="业务日期" prop="businessDate" width="100" fixed="left" show-overflow-tooltip />
      <el-table-column label="分支机构" prop="ettleAccountNm" width="200" fixed="left" show-overflow-tooltip />
      <el-table-column label="金额" prop="amount" width="100" align="right" fixed="left" show-overflow-tooltip>
        <template slot-scope="scope">
          <span :class="{colorOrg: scope.row.isReceivable === 'N'}">{{$util.toFixed(scope.row.amount)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="货品ID" prop="goodsId" width="100" show-overflow-tooltip />
      <el-table-column label="商品名" prop="productNm" width="200" show-overflow-tooltip />
      <el-table-column label="通用名" prop="commonNm" width="200" show-overflow-tooltip />
      <el-table-column label="规格" prop="spec" width="100" show-overflow-tooltip />
      <el-table-column label="厂家(上市许可持有人)/产地" prop="manufacturer" width="200" show-overflow-tooltip />
      <el-table-column label="基本单位" prop="unit" min-width="100" show-overflow-tooltip />
      <el-table-column label="数量" prop="quantity" width="100" show-overflow-tooltip />
      <el-table-column label="单价" align="right" prop="unitPric" width="100" show-overflow-tooltip />
      <el-table-column label="发票日期" prop="invoiceDate" width="100" show-overflow-tooltip />
      <el-table-column label="发票号" prop="invoiceNum" width="100" show-overflow-tooltip />
      <el-table-column label="发票轨号" prop="invoiceRailNum" width="100" show-overflow-tooltip />
      <el-table-column label="帐期至" prop="paymentDays" width="180" show-overflow-tooltip />
      <el-table-column label="逾期日期" prop="overdueDate" min-width="180" show-overflow-tooltip />
    </im-table-page>
    <!-- 搜索区域 -->
<!--    <table></table>-->
  </div>
</template>

<script>
import {getIntercourseBill} from "@/api/member-center/property-manage";

export default {
  name: "currentAccount",
  data() {
    return{
      searchForm: {
        isReceivable: 'N',
        startTime: '',
        endTime: '',
        time: []
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      tableData: [],
      chooseIcon: 'el-icon-arrow-down',
      chooseShow: false
    }
  },
  methods: {
    remoteMode(pageParam) {
      return getIntercourseBill({
        ...pageParam, condition: this.searchForm
      }).then(res => {
        const { data } = res
        let records = data.records
        if (data && records) {
          records.forEach(item => {
            if (item.isReceivable === 'Y') {
              item.receivable = '已回款'
            } else {
              item.receivable = '未回款'
            }
          })
        }
        return res
      })
    },
    onSearch() {
      this.doRefresh();
    },
    onReset() {
      this.searchForm = {
        isReceivable: this.searchForm.isReceivable,
        startTime: '',
        endTime: '',
        time: []
      }
      this.doRefresh()
    },
    changeDatePicker(dateList) {
      console.log(this.searchForm.time)
      this.searchForm.startTime = dateList[0];
      this.searchForm.endTime = dateList[1];
    },
    doRefresh() {
      this.$refs.tablePage.doRefresh({currentPage: 0, pageSize: 10, condition: this.searchForm})
    },
    chooseState() {
      if(this.chooseShow) {
        this.chooseShow = false
        this.chooseIcon = 'el-icon-arrow-down'
      } else {
        this.chooseShow = true
        this.chooseIcon = 'el-icon-arrow-up'
      }
    },
    selectMode(receive) {
      if(receive){
        this.searchForm.isReceivable = 'Y'
      } else {
        this.searchForm.isReceivable = 'N'
      }
      this.chooseShow = false
      this.chooseIcon = 'el-icon-arrow-down'
      this.doRefresh()
    },
    getExcel() {
      if(this.tableData.length < 1){
        this.$message.warning('当前往来账为空')
        return
      }
      require.ensure([], () => {
        const { export_json_to_excel } = require('@/excel/Export2Excel.js')
        const tHeader = ['回款情况', '业务日期', '分支机构', '金额', '货品ID', '商品名', '通用名', '规格', '生产厂家', '基本单位', '数量', '单价', '发票日期', '发票号', '发票轨号', '帐期至', '逾期日期']
        const filterVal = ['receivable', 'businessDate', 'ettleAccountNm', 'amount', 'goodsId', 'productNm', 'commonNm', 'spec', 'manufacturer', 'unit', 'quantity', 'unitPric', 'invoiceDate', 'invoiceNum', 'invoiceRailNum', 'paymentDays', 'overdueDate']
        const list = this.tableData
        const data = this.formatJson(filterVal, list)
        export_json_to_excel(tHeader, data, '往来账核对')
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
  }
}
</script>

<style lang="scss" scoped>
.currentAccountDiv{
  /*width: 900px;*/
  background-color: #FFFFFF;
  text-align: left;
  padding: 0 20px;
  min-height: 780px;
  position: relative;
  .title {
    color: #2B2B2B;
    font-size: 16px;
    line-height: 50px;
    height: 50px;
    padding-left: 8px;
    font-weight: 700;
    border-bottom: #E5E5E5 solid 1px;
  }
  .search {
    padding: 16px 0px;
    .datePicker {
      width: 228px;
      margin-right: 28px;
    }
    .importBut {
      float: right;
      color: #0971EB;
      border-color: #0971EB;
      margin-top: 2px;
    }
  }
  .chooseState {
    position: absolute;
    left: 80px;
    top: 157px;
    width: 144px;
    z-index: 10;
    background-color: #ffffff;
    box-shadow:0px 2px 8px 0px rgba(0,0,0,0.15);
    border-radius:4px;
    .received {
      padding-left: 12px;
      height: 32px;
      line-height: 32px;
      font-size: 14px;
      font-family:PingFangSC-Medium,PingFang SC;
      font-weight:500;
      color:rgba(34,34,34,1);
    }
  }
}
.colorOrg {
  color:#FA6400
}
.colorBlue {
  background-color: rgba(241,247,254,1);
}
</style>

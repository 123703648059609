var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "procurement-plan-list" },
    [
      _c("div", { staticClass: "page-header" }, [_vm._v("采购计划")]),
      _vm.license.isFailure === "Y"
        ? _c(
            "div",
            { staticClass: "aptitudes" },
            [
              _vm._v(
                "温馨提示：" +
                  _vm._s(
                    _vm.userType !== "SALESMAN"
                      ? "您有" +
                          _vm.license.failureSum +
                          "张证照已过期,请联系业务员更换,以免影响您的正常采购"
                      : "该客户有" +
                          _vm.license.failureSum +
                          "张证照已过期，以免影响正常采购！"
                  )
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "text" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v("查看详情")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "page-header-btn" }, [
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticStyle: {
                  height: "35px",
                  "line-height": "35px",
                  padding: "0 20px",
                },
                attrs: { type: "primary" },
                on: { click: _vm.handleAdd },
              },
              [_vm._v(" 新增采购计划 ")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c("span", { staticStyle: { "margin-right": "10px" } }, [
              _vm._v("计划名称"),
            ]),
            _c("el-input", {
              staticStyle: { width: "210px", "margin-right": "20px" },
              attrs: { placeholder: "输入计划名称" },
              model: {
                value: _vm.searchForm.procurementPlanNm,
                callback: function ($$v) {
                  _vm.$set(_vm.searchForm, "procurementPlanNm", $$v)
                },
                expression: "searchForm.procurementPlanNm",
              },
            }),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "mini" },
                on: { click: _vm.handleSearch },
              },
              [_vm._v("搜索")]
            ),
            _c(
              "el-button",
              {
                staticStyle: { height: "30px", color: "#000000" },
                attrs: { type: "text", size: "small" },
                on: { click: _vm.handleReset },
              },
              [_vm._v("重置")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "im-table-page",
        {
          ref: "tablePage",
          attrs: { data: _vm.tableData, "remote-method": _vm.queryData },
          on: {
            "update:data": function ($event) {
              _vm.tableData = $event
            },
            updated: _vm.updated,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "序号",
              prop: "procurementPlanNm",
              width: "60px",
              "show-overflow-tooltip": "",
              fixed: "left",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.$index + 1))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "提醒",
              prop: "isRemind",
              width: "80px",
              align: "center",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-switch", {
                      staticClass: "swich-inner-text",
                      attrs: {
                        disabled: true,
                        "active-text": "启",
                        "inactive-text": "停",
                        "active-color": "#1CB7CD",
                        "active-value": "Y",
                        "inactive-value": "N",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.switchChange(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.isRemind,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "isRemind", $$v)
                        },
                        expression: "scope.row.isRemind",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "模板名称",
              prop: "procurementPlanNm",
              width: "200px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "商品总品规",
              prop: "productQuantity",
              "min-width": "110px",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" 共"),
                    _c("span", { staticClass: "text111" }, [
                      _vm._v(_vm._s(scope.row.productQuantity)),
                    ]),
                    _vm._v("种 "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "提醒方式",
              prop: "useCommodityTypes",
              width: "140px",
              "max-width": "200px",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.isAppRemind === "Y" ? "APP" : "")
                      ),
                    ]),
                    _vm._v("  "),
                    _c("span", [
                      _vm._v(_vm._s(scope.row.isPcRemind === "Y" ? "PC" : "")),
                    ]),
                    _vm._v("  "),
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.isWechatRemind === "Y" ? "微信" : "")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "提醒时间",
              prop: "remindTime",
              "min-width": "176",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "180px", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-row",
                      { staticClass: "table-edit-row" },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: scope.row.loading,
                                expression: "scope.row.loading",
                              },
                            ],
                            staticStyle: {
                              padding: "2px 4px",
                              "border-color": "#DCDFE6!important",
                              color: "#606266!important",
                            },
                            attrs: {
                              size: "mini",
                              disabled: _vm.isBanAddToCart,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addItem(
                                  scope.row.procurementPlanId,
                                  scope.row
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isLicenseOutOfTime
                                    ? "证照过期"
                                    : "一键采购"
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              padding: "2px 4px",
                              "border-color": "#DCDFE6!important",
                              color: "#606266!important",
                            },
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c("im-confirm-popup", {
                          attrs: {
                            index: scope.$index,
                            "tip-txt": "是否删除该采购计划？",
                            "reference-btn-txt": "删除",
                          },
                          on: {
                            confirm: function ($event) {
                              return _vm.handleDelete(scope.row)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "member-center" },
    [
      _c("Search", { attrs: { "hidden-nav": true } }),
      _c(
        "div",
        { staticClass: "member-center-container" },
        [
          _c(
            "el-container",
            {
              class: [
                { moreWidth: _vm.moreWidth },
                { customerWidth: _vm.customerWidth },
              ],
            },
            [
              _c(
                "el-aside",
                { attrs: { width: "200px" } },
                [
                  _c("nav-menu", {
                    attrs: { params: _vm.params },
                    on: { "handle-change-page": _vm.handleChangePage },
                  }),
                ],
                1
              ),
              _c(
                "el-main",
                [
                  _c("router-view", {
                    on: { "handle-change-page": _vm.handleChangePage },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("Search", { attrs: { "hidden-nav": true } }),
      _c(
        "div",
        { staticClass: "breadcrumb" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { "separator-class": "el-icon-arrow-right" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页"),
              ]),
              _c(
                "el-breadcrumb-item",
                { attrs: { to: { path: "/member-center" } } },
                [_vm._v("会员中心")]
              ),
              _c(
                "el-breadcrumb-item",
                {
                  attrs: {
                    to: { path: "/member-center/order-manage/store-order" },
                  },
                },
                [_vm._v("我的订单")]
              ),
              _c("el-breadcrumb-item", [_vm._v("订单详情")]),
              _c("el-breadcrumb-item", [_vm._v("查看物流")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.bindTab },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            _vm._l(_vm.packageVos, function (item, index) {
              return _c(
                "el-tab-pane",
                { key: item.packageId, attrs: { name: String(index) } },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v(" 包裹" + _vm._s(index + 1) + " "),
                  ]),
                ]
              )
            }),
            1
          ),
          _c("div", { staticClass: "header center" }, [
            _c("b", { staticClass: "center" }, [
              _vm._v("包裹" + _vm._s(Number(_vm.activeName + 1))),
            ]),
            _vm._v(" 运单号："),
            _c("span", [_vm._v(_vm._s(_vm.logisticsData.orderId))]),
          ]),
          _c("order-express", { attrs: { express: _vm.orderExpress } }),
          _c("div", { staticClass: "express-steps" }, [
            _vm.logisticsData.orderMsg.length == 0
              ? _c("div", { staticClass: "logistics-empty" }, [
                  _vm._v("暂无物流信息"),
                ])
              : _vm._e(),
            _vm.logisticsData.orderMsg.length > 0
              ? _c(
                  "div",
                  [
                    _c(
                      "el-timeline",
                      _vm._l(
                        _vm.logisticsData.orderMsg,
                        function (activity, index) {
                          return _c("el-timeline-item", { key: index }, [
                            _c("div", { staticClass: "logistics-content" }, [
                              _c("div", { staticClass: "logistics-title" }, [
                                _vm._v(" " + _vm._s(activity.step) + " "),
                              ]),
                              _c(
                                "div",
                                { staticClass: "logistics-details" },
                                _vm._l(
                                  activity.subSteps,
                                  function (detail, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "detail-item",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "detail-line" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(detail.trackMsg) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "detail-line" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(detail.stepDate) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                            _c("div", [_vm._v(_vm._s(activity.content))]),
                          ])
                        }
                      ),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c(
            "el-table",
            { attrs: { data: _vm.goodsList, border: "" } },
            [
              _c("el-table-column", {
                attrs: { label: "商品信息", "show-overflow-tooltip": "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "product-info" }, [
                          _c(
                            "div",
                            { staticClass: "product-image" },
                            [
                              _c("el-image", {
                                attrs: {
                                  src: scope.row.thumbnailUrl,
                                  fit: "fit",
                                },
                                on: {
                                  error: function ($event) {
                                    scope.row.thumbnailUrl =
                                      _vm.productDefaultImg
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c("ul", { staticClass: "product-detail" }, [
                            _c("li", { staticClass: "name" }, [
                              _vm._v(_vm._s(_vm.$util.getGoodsName(scope.row))),
                            ]),
                            _c("li", { staticClass: "detail" }, [
                              _c("p", [
                                _vm._v("规格："),
                                _c("span", { staticClass: "spec" }, [
                                  _vm._v(_vm._s(scope.row.spec)),
                                ]),
                                _vm._v(" 单位："),
                                _c("span", [_vm._v(_vm._s(scope.row.unit))]),
                              ]),
                            ]),
                            _c("li", { staticClass: "detail" }, [
                              _vm._v("厂家：" + _vm._s(scope.row.manufacturer)),
                            ]),
                            _c("li", { staticClass: "detail" }, [
                              _vm._v("批号：" + _vm._s(scope.row.batch)),
                            ]),
                            _c("li", { staticClass: "manufacturer detail" }, [
                              _vm._v("效期：" + _vm._s(scope.row.validityDate)),
                            ]),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "发货数量",
                  width: "450px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.num))])]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
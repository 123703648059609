var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "submittedDiv" }, [
    _c(
      "div",
      { staticClass: "submittedTip" },
      [
        _vm.params.openStat === "APPROVE_FAILURE"
          ? [
              _c("i", {
                staticClass: "el-icon-error successIcon",
                staticStyle: { color: "#F56C6C" },
              }),
              _vm._v(" " + _vm._s("您的审核未通过") + " "),
            ]
          : _vm.params.openStat === "APPROVE_SUCCESS"
          ? [
              _c("i", { staticClass: "el-icon-success successIcon" }),
              _vm._v(" " + _vm._s("您的审核已通过") + " "),
            ]
          : [
              _c("i", { staticClass: "el-icon-success successIcon" }),
              _vm._v(" " + _vm._s("您的资料已经提交，正在审核中…") + " "),
            ],
      ],
      2
    ),
    _c("div", { staticClass: "enterpriseInfo" }, [
      _vm._m(0),
      _c("div", { staticClass: "infoDiv" }, [
        _c("div", { staticClass: "infoFirst" }, [
          _vm._v("登录账号： "),
          _c("span", { staticClass: "infoSpan" }, [
            _vm._v(_vm._s(_vm.params.registerLoginId)),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.params.loginMobileId,
                expression: "params.loginMobileId",
              },
            ],
            staticClass: "infoSecond",
          },
          [
            _vm._v("手机号码： "),
            _c("span", { staticClass: "infoSpan" }, [
              _vm._v(_vm._s(_vm.params.loginMobileId)),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "infoDiv" }, [
        _vm._m(1),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customerName,
                expression: "customerName",
              },
            ],
            staticClass: "infoSecond",
          },
          [
            _vm._v("单位名称： "),
            _c("span", { staticClass: "infoSpan" }, [
              _vm._v(_vm._s(_vm.customerName) + " "),
            ]),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "enterpriseInfo nextInfo" }, [
      _vm._m(2),
      _c("div", { staticClass: "infoDiv" }, [
        _c("div", { staticClass: "infoFirst" }, [
          _vm._v("审核状态： "),
          _vm.params.openStat === "APPROVE_FAILURE"
            ? _c("span", { staticClass: "infoSpan" }, [_vm._v("审核未通过")])
            : _vm.params.openStat === "APPROVE_SUCCESS"
            ? _c("span", { staticClass: "infoSpan" }, [_vm._v("审核已通过")])
            : _c("span", { staticClass: "infoSpan" }, [_vm._v("待审核")]),
        ]),
        _c("div", { staticClass: "infoSecond" }, [
          _vm._v("注册信息： "),
          _vm.params.openStat === "APPROVE_FAILURE"
            ? _c(
                "a",
                { staticClass: "changeInfo", on: { click: _vm.changeInfo } },
                [_vm._v("重新提交")]
              )
            : _vm._e(),
          _vm.params.openStat === "WAIT_APPROVE"
            ? _c(
                "a",
                { staticClass: "changeInfo", on: { click: _vm.changeInfo } },
                [_vm._v("修改资料")]
              )
            : _vm._e(),
        ]),
      ]),
      _vm.params.openStat !== "APPROVE_SUCCESS"
        ? _c("div", { staticClass: "infoDiv" }, [
            _c("div", { staticClass: "infoFirst" }, [
              _vm._v("审核意见： "),
              _c("span", { staticClass: "infoSpan" }, [
                _vm._v(
                  _vm._s(
                    _vm.params.openStat === "APPROVE_FAILURE"
                      ? "修改资料重新提交"
                      : "请耐心等待审核"
                  )
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.params.openStat !== "APPROVE_SUCCESS"
        ? _c("div", { staticClass: "infoDiv" }, [
            _c(
              "div",
              { staticClass: "infoFirst", staticStyle: { width: "80%" } },
              [
                _vm._v("拒绝理由： "),
                _c(
                  "span",
                  { staticClass: "infoSpan", staticStyle: { color: "#666" } },
                  [_vm._v(_vm._s(_vm.params.rejectionReasons))]
                ),
              ]
            ),
          ])
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "backDiv" },
      [
        _c(
          "el-button",
          {
            staticClass: "back",
            staticStyle: { "margin-right": "8px" },
            attrs: { size: "mini" },
            on: { click: _vm.back },
          },
          [_vm._v("返回首页")]
        ),
        _vm._v(" 如有疑问请咨询QQ客服 "),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fromTitle" }, [
      _vm._v("填写的个人信息"),
      _c("span", { staticClass: "fromSpanTitle" }, [
        _vm._v("请记住登录账号密码跟手机号，登录时需使用"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "infoFirst" }, [
      _vm._v("账户类型： "),
      _c("span", { staticClass: "infoSpan" }, [_vm._v("采购商")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fromTitle" }, [
      _vm._v("审核进度"),
      _c("span", { staticClass: "fromSpanTitle" }, [
        _vm._v(
          "审核过程中，首页输入登录账号密码，可查看当前审核状态或修改注册信息"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="interfaceOrderDiv">
    <div class="title">{{params.title}}</div>
    <!-- 搜索区域 -->
    <el-form :inline="true" :model="searchForm" class="searchForm" size="small" label-width="70px">
      <el-form-item label="客户编码">
        <el-input class="searchFormItem" :class="{'isExpanded':isExpanded}" v-model="searchForm.customerCode" placeholder="输入客户编码"></el-input>
      </el-form-item>
      <el-form-item label="分支机构编码" label-width="100px">
        <el-input class="searchFormItem" :class="{'isExpanded':isExpanded}" v-model="searchForm.code" placeholder="输入分支机构编码"></el-input>
      </el-form-item>
      <el-form-item label="门店编码">
        <el-input class="searchFormItem" :class="{'isExpanded':isExpanded}" v-model="searchForm.storeCode" placeholder="输入门店编码"></el-input>
      </el-form-item>
      <el-form-item label="客户名称" v-show="isExpanded">
        <el-input class="searchFormItem" :class="{'isExpanded':isExpanded}" v-model="searchForm.buyersNm" placeholder="输入客户名称"></el-input>
      </el-form-item>
      <el-form-item label="分支机构名称" label-width="100px" v-show="isExpanded">
        <el-input class="searchFormItem" :class="{'isExpanded':isExpanded}" v-model="searchForm.ettleAccountNm" placeholder="输入分支机构名称"></el-input>
      </el-form-item>
      <el-form-item label="门店名称" v-show="isExpanded">
        <el-input class="searchFormItem" :class="{'isExpanded':isExpanded}" v-model="searchForm.storeNm" placeholder="输入门店名称"></el-input>
      </el-form-item>
      <el-form-item label="商品名称" v-show="isExpanded">
        <el-input class="searchFormItem" :class="{'isExpanded':isExpanded}" v-model="searchForm.productNm" placeholder="输入商品名称"></el-input>
      </el-form-item>
      <el-form-item label="要货时间" label-width="100px" v-show="isExpanded">
        <el-date-picker
            v-model="searchForm.time"
            class="dateFormItem"
            :class="{'isExpanded':isExpanded}"
            type="daterange"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            size="small"
            align="right"
            unlink-panels
            range-separator="至"
            @change="changeDatePicker"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item class="formBut">
        <el-button type="primary" size="mini" @click="search">搜索</el-button>
        <el-button type="text" size="mini" @click="reset">
          <span style="color: #000000">重置</span>
        </el-button>
        <div style="width: 1px;height: 12px;background-color: #E5E5E5;margin: 0 8px;margin-top: 8px;"></div>
        <el-button type="text" size="mini" @click="onExpand" style="color:#000000;font-weight:400;">
          {{ isExpanded ? '收起' : '展开' }}
          <i :class="isExpanded ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"/>
        </el-button>
      </el-form-item>
    </el-form>
    <div v-loading="loading">
      <im-tool-bar class="butDiv">
        <template slot="left">
          <el-button type="primary" @click="gotoPrice" size="small">调价</el-button>
          <el-button type="primary" @click="rematchStore" size="small">重新匹配门店</el-button>
          <el-button type="primary" @click="rematch" size="small">重新匹配品种</el-button>
        </template>
        <template slot="right">
          <el-checkbox v-model="isPriceMatch" class="rightCheck" @change="checked=>checkChange(checked,'isPriceMatch')">
            价格匹配
          </el-checkbox>
          <el-checkbox v-model="isPriceLower" class="rightCheck" @change="checked=>checkChange(checked,'isPriceLower')">
            低于业态价
          </el-checkbox>
          <el-checkbox v-model="isPriceHigh" class="rightCheck" @change="checked=>checkChange(checked,'isPriceHigh')">
            高于业态价
          </el-checkbox>
          <el-checkbox v-model="unmatched" class="rightCheck" @change="checked=>checkChange(checked,'unmatched')">数据异常
          </el-checkbox>
        </template>
      </im-tool-bar>
      <div class="tableDiv">
        <el-table
            :data="dataList"
            ref="table"
            border
            class="table"
            style="width: 100%;"
            size="mini"
            :row-class-name="tableRowClassName"
            :cell-class-name="cellClassName"
            :header-cell-class-name="cellClassName"
            @selection-change="handleSelectionChange"
            :cell-style="cellStyle">
          <el-table-column
              type="selection"
              width="55"
              fixed="left"
              align="center">
          </el-table-column>
          <el-table-column label="客户编码" prop="customerCode" width="80" show-overflow-tooltip></el-table-column>
          <el-table-column label="门店编码" prop="storeCode" width="80" show-overflow-tooltip></el-table-column>
          <el-table-column label="分支机构编码" prop="code" width="80" show-overflow-tooltip></el-table-column>
          <el-table-column label="门店名称" prop="storeNm" width="100" show-overflow-tooltip></el-table-column>
          <el-table-column label="订单编号" prop="distributionOrderNum" width="80" show-overflow-tooltip></el-table-column>
          <el-table-column label="商品编码" prop="imallProductCode" width="80" show-overflow-tooltip></el-table-column>
          <el-table-column label="商品名称" prop="productNm" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column label="规格" prop="spec" width="60" show-overflow-tooltip></el-table-column>
          <el-table-column label="生产厂家" prop="manufacturer" width="100" show-overflow-tooltip></el-table-column>
          <el-table-column label="数量" prop="quantity" width="60" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="平台报价/要货价格" prop="originalPrice" algin="right" width="150" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="display: flex">
                <span>{{scope.row.askingPrice}}</span>
                <div style="display: flex" v-if="scope.row.priceComparison === '1'||scope.row.priceComparison === '3'">/
                  <span class="price"
                        :style="{'background': scope.row.priceComparison === '3' ? 'rgba(51,174,103,0.8)': 'rgba(224,32,52,0.8)'}">{{ scope.row.originalPrice }}</span>
                  <img v-if="scope.row.priceComparison === '3'" class="priceIcon"
                       src="@/assets/img/member-center/up.png" alt>
                  <img v-else class="priceIcon" src="@/assets/img/member-center/down.png" alt>
                </div>
                <span v-else>/{{ scope.row.originalPrice }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="要货日期" prop="createTime" width="100" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="第三方商品编码" prop="thirdProductCode" min-width="100" align="center" show-overflow-tooltip></el-table-column>
        </el-table>
      </div>
      <im-pagination
          :isShowMainInfo="true"
          class="table-page"
          :total="total"
          :current-page.sync="currentPage"
          :page-size.sync="pageSize"
          @pagination="handlePagination"/>
    </div>
    <div class="bottomDiv">
      <el-checkbox v-model="allChecked" class="allChecked" @change="selectAll">全选</el-checkbox>
      <div class="bottomRight">
        <div style="margin-right: 10px">
          <el-select filterable v-model="chooseRemark" placeholder="请选择总单" @change="remarkSelect" style="width: 100%">
            <el-option v-for="(item,index) in chooseRemarkList" :key="index" :label="item.label" :value="item.value"/>
          </el-select>
          <el-input v-model="remark" placeholder="请输入订单备注" @change="remarkChange"/>
        </div>
        <div class="bottomButton">
          <el-button @click="batchDeletion" size="medium" class="removeBut" >批量删除</el-button>
          <el-button @click="checkExpDate" size="medium" class="orderBut" :loading='loading'>立即下单</el-button>
        </div>
      </div>
    </div>
    <im-drawer title="委托配送匹配" :visible.sync="drawerVisible" :before-close="beforeClose" @confirm="handleConfirm" @cancel="resetForm">
      <el-form
          v-loading="drawerLoading"
          ref="form"
          label-width="180px"
          style="padding-right:50px;padding-top: 24px;"
          :model="form"
          :rules="formRules">
        <el-form-item label="模式名称：" prop="modeName">
          <span class="modeName">{{form.modeName}}</span>
          <el-button
              type="primary"
              size="mini"
              @click="chooseMode()"
          >选择模式</el-button>
        </el-form-item>
        <el-form-item label="商品ERP_ID：" prop="erpCode">
          <span class="modeName">{{form.erpCode}}</span>
          <el-button
              type="primary"
              size="mini"
              @click="chooseGoods()"
          >选择商品</el-button>
        </el-form-item>
        <div class="tipItem">
          <p>商品名称：{{form.productNm}}</p>
          <p>商品规格：{{form.spec}}</p>
          <p>商品单位：{{form.unit}}</p>
          <p>生产厂家：{{form.manuFacturer}}</p>
        </div>
        <el-form-item label="第三方商品编码：" prop="thirdProductCode">
          <el-input v-model="form.thirdProductCode" placeholder="请输入第三方商品编码" />
        </el-form-item>
        <el-form-item label="第三方商品名称：" prop="thirdProductName">
          <el-input v-model="form.thirdProductName" placeholder="请输入第三方商品名称" />
        </el-form-item>
        <el-form-item label="第三方商品规格：" prop="thirdProductSpec">
          <el-input v-model="form.thirdProductSpec" placeholder="请输入第三方商品规格" />
        </el-form-item>
        <el-form-item label="第三方商品单位：" prop="thirdProductUtil">
          <el-input v-model="form.thirdProductUtil" placeholder="请输入第三方商品单位" />
        </el-form-item>
        <el-form-item label="第三方生产厂家：" prop="thirdProductFactory">
          <el-input v-model="form.thirdProductFactory" placeholder="请输入第三方生产厂家" />
        </el-form-item>
        <el-form-item label="数量比例：" prop="quantityProportion" style="margin-bottom: 5px;">
          <el-input v-model="form.quantityProportion" type="number" placeholder="请输入数量比例" />
        </el-form-item>
        <el-form-item>
          <span style="color: #666666;font-size: 13px">说明：数量比例=我方计价单位数量/客户计价单位数量</span>
        </el-form-item>
        <el-form-item label="是否取整：" prop="roundingOrNot">
          <el-switch
              v-model="form.roundingOrNot"
              class="swich-inner-text"
              active-text="是"
              inactive-text="否"
              active-color="#1890FF"
              active-value="Y"
              inactive-value="N"/>
        </el-form-item>
      </el-form>
    </im-drawer>
    <im-drawer title="委托配送匹配" :visible.sync="storeDrawerVisible" :before-close="storeBeforeClose" @confirm="storeHandleConfirm" @cancel="storeResetForm" >
      <el-form
          ref="form"
          label-width="180px"
          style="padding-right:50px;padding-top: 24px;"
          :model="storeForm"
          :rules="storeFormRules">
        <el-form-item label="识别码：" prop="identificationCode">
          <el-select v-model="storeForm.identificationCode" placeholder="选择识别码" style="width: 100%;">
            <el-option v-for="(item,index) in identificationCodeList" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="门店编码：" prop="storeCode">
          <el-input v-model="storeForm.storeCode" placeholder="请输入门店编码" />
        </el-form-item>
        <el-form-item label="门店名称：" prop="storeNm">
          <el-input v-model="storeForm.storeNm" placeholder="请输入门店名称" />
        </el-form-item>
        <el-form-item label="地址ID：" prop="addressId">
          <el-input v-model="storeForm.addressId" placeholder="请输入地址ID" />
        </el-form-item>
        <el-form-item label="模式名称：" prop="modeName">
          <span class="modeName">{{storeForm.modeName}}</span>
          <el-button
              type="primary"
              size="mini"
              @click="chooseMode()"
          >选择模式</el-button>
        </el-form-item>
        <el-form-item label="是否回传数据：" prop="isBack">
          <el-switch
              v-model="storeForm.isBack"
              class="swich-inner-text"
              active-text="是"
              inactive-text="否"
              active-color="#1890FF"
              active-value="Y"
              inactive-value="N"/>
        </el-form-item>
        <el-form-item label="备注说明：" prop="remark" style="margin-bottom: 5px">
          <el-input v-model="storeForm.remark" placeholder="请输入备注说明" />
        </el-form-item>
        <el-form-item>
          <span style="color: #666666;">建议填写客户的收货地址，便于区分</span>
        </el-form-item>
      </el-form>
    </im-drawer>
    <im-drawer :title="modeDrawerTitle" :visible.sync="modeDrawerVisible" :hasFooter="false" :wrapperClosable="false">
      <choose-mode
          @chooseModeSucc="chooseModeSucc"></choose-mode>
    </im-drawer>
    <im-drawer :title="goodsDrawerTitle" :visible.sync="goodsVisible" :hasFooter="false" :wrapperClosable="false">
      <choose-goods
          @chooseGoodsSucc="chooseGoodsSucc"></choose-goods>
    </im-drawer>

    <el-dialog
        title="以下是近效期品种"
        :visible.sync="expDateVisible"
        width="60%">
      <el-table ref="expDateTable" border :data="submitOrderData">
        <el-table-column label="商品编码" prop="imallProductCode" width="90"></el-table-column>
        <el-table-column label="商品名称" prop="productNm" min-width="120">
          <template slot-scope="scope">
            {{$util.getGoodsName(scope.row)}}
          </template>
        </el-table-column>
        <el-table-column label="规格" prop="spec" min-width="80"></el-table-column>
        <el-table-column label="生产厂家" prop="manufacturer" min-width="140"></el-table-column>
        <el-table-column label="最近/远效期" prop="expDate" min-width="100"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="expDateVisible = false">取 消</el-button>
        <el-button type="primary" @click="commitOrder">继续下单</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {
  batchDeleteDistributionOrder,
  commissionedOrder, rematch, saveModelDrugComparison,
  getOrderInterfaceConfigRemarkList,
  rematchOrderItem,
  getModelDrugComparison, pageIndexProduct,
  pageDistributionOrders
} from '@/api/member-center/consigned-delivery'
import formRules from '@/utils/validate'
import chooseMode from './chooseMode'
import chooseGoods from './chooseGoods'
// import {getjyPHXQ, getPHXQ} from "../../../../api/commodity-list";

export default {
  name: "interfaceOrder",
  components: { chooseMode,  chooseGoods},
  props: {
    outOfStock: {
      type: String,
      default: () => {
        return ''
      }
    },
    params: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    const validateQuantity = (rule, value, callback) => {
      if (!value) {
        this.next = false
        return callback(new Error('数量比例不能为空'))
      }
      if (value < 0) {
        this.next = false
        return callback(new Error('数量比例不能小于0'))
      }
      callback()
    }
    return {
      expDateVisible:false,
      submitOrderData:[],//要下单的商品列表
      form: {
        modelDrugComparisonId: '',
        modeName: '',
        erpCode: '',
        productNm: '',
        spec: '',
        unit: '',
        manuFacturer: '',
        productId: '',
        thirdProductCode: '',
        thirdProductName: '',
        thirdProductSpec: '',
        thirdProductUtil: '',
        thirdProductFactory: '',
        quantityProportion: '',
        roundingOrNot: 'Y',
        importModeId: ''
      },
      drawerVisible: false,
      modeDrawerVisible: false,
      goodsVisible: false,
      modeDrawerTitle: '选择模式',
      goodsDrawerTitle: '选择商品',
      formRules: {
        modeName: [{ required: true, trigger: 'blur', message: '模式名称不能为空' }],
        erpCode: [{ required: true, trigger: 'blur', message: '商品ID不能为空' }],
        thirdProductCode: [{ required: true, trigger: 'blur', message: '第三方商品编码不能为空' }],
        thirdProductName: [{ required: true, trigger: 'blur', message: '第三方商品名称不能为空' }],
        thirdProductSpec: [{ required: true, trigger: 'blur', message: '第三方商品规格不能为空' }],
        thirdProductUtil: [{ required: true, trigger: 'blur', message: '第三方商品单位不能为空' }],
        thirdProductFactory: [{ required: true, trigger: 'blur', message: '第三方生产厂家不能为空' }],
        quantityProportion: [{ required: true, trigger: 'blur', validator: validateQuantity }],
        roundingOrNot: [formRules.changeRes('启用状态')]
      },
      storeDrawerVisible: false,
      storeForm: {
        matchingCodeConfigId: '',
        identificationCode: '',
        storeCode: '',
        storeNm: '',
        addressId: '',
        modeName: '',
        importModeId: '',
        remark: '',
        isBack: 'Y'
      },
      storeFormRules: {
        isBack: [formRules.changeRes('启用状态')],
        identificationCode: [formRules.changeRes('识别码')],
        storeCode: [{ required: true, trigger: 'blur', message: '门店编码不能为空' }],
        addressId: [{ required: true, trigger: 'blur', message: '地址ID不能为空' }],
        modeName: [{ required: true, trigger: 'blur', message: '导入模式不能为空' }],
        remark: [{ required: true, trigger: 'blur', message: '备注说明不能为空' }]
      },
      searchForm: {
        productNm: '',
        storeCode: '',
        startTime: '',
        endTime: '',
        storeNm: '',
        unmatched: '',
        outOfStock: this.outOfStock,
        time: [],
        customerCode: '',
        buyersNm: '',
        code: '',
        ettleAccountNm: ''
      },
      identificationCodeList: [],
      price: '',
      checkItems: [],
      dataList: [],
      isExpanded: false,
      checked: false,
      isPriceMatch: false,
      isPriceLower: false,
      isPriceHigh: false,
      unmatched: false,
      loading: false,
      drawerLoading: false,
      currentPage: 0,
      pageSize: 30,
      total: 0,
      multipleSelection: [],
      selectList: [],
      allChecked: false,
      chooseRemark: '',
      chooseRemarkList: [],
      remark: '',
      remarkList: {}
    }
  },
  methods: {
    tableRowClassName({row,rowIndex}){
      if(row.isSkuInfo === 'Y'){
        return 'expDate-row'
      }
      return ''
    },
    search () {
      this.doRefresh()
    },
    reset () {
      this.searchForm= {
        productNm: '',
        storeCode: '',
        startTime: '',
        endTime: '',
        storeNm: '',
        unmatched: '',
        outOfStock: this.outOfStock,
        time: [],
        customerCode: '',
        buyersNm: '',
        code: '',
        ettleAccountNm: ''
      }
      this.doRefresh()
    },
    changeDatePicker (dateList) {
      this.searchForm.startTime = dateList[0] + ' 00:00:00'
      this.searchForm.endTime = dateList[1] + ' 23:59:59'
    },
    checkBox (val) {
      this.checkItems = val
    },
    checkChange (val, name) {
      let checkState = false
      if(val){
        checkState = true
      }
      if(name === 'isPriceMatch'){
        this.dataList.forEach(item => {
          if(item.askingPrice && item.originalPrice === item.askingPrice) {
            this.$refs.table.toggleRowSelection(item, checkState)
          }
        })
      }else if (name === 'isPriceLower'){
        this.dataList.forEach(item => {
          if(item.askingPrice && this.removeSemicolon(item.originalPrice) < this.removeSemicolon(item.askingPrice)) {
            this.$refs.table.toggleRowSelection(item, checkState)
          }
        })
      }else if(name === 'isPriceHigh'){
        this.dataList.forEach(item => {
          if(item.askingPrice && this.removeSemicolon(item.originalPrice) > this.removeSemicolon(item.askingPrice)){
            this.$refs.table.toggleRowSelection(item, checkState)
          }
        })
      } else{
        if (val) {
          this.searchForm[name] = 'Y'
        } else {
          this.searchForm[name] = ''
        }
        this.doRefresh()
      }
    },
    onExpand () {
      this.isExpanded = !this.isExpanded
    },
    handlePagination (data) {
      console.log(data)
      this.chooseRemarkList = []
      this.remark = ''
      this.currentPage = Math.min(this.currentPage, Math.ceil(this.total / data.pageSize))
      this.pageSize = data.pageSize
      this.doRefresh({ currentPage: this.currentPage, pageSize: this.pageSize })
    },
    cellClassName ({ row, column, rowIndex, columnIndex }) {
      if (row.unmatched === 'Y') {
        return 'cellColorOrder'
      } else {
        return 'cellColorOrder2'
      }
    },
    cellStyle ({ row, column, rowIndex, columnIndex }) {
      if (row.unmatched === 'Y') {
        return 'background-color: #FFF1F0;'
      } else {
        return ''
      }
    },
    remarkChange(val){
      this.dataList.forEach(item => {
        if(item.distributionOrderId === this.chooseRemark){
          item.remark = val
        }
      })
    },
    remarkSelect(val){
      this.dataList.forEach(item => {
        if(item.distributionOrderId === val){
          this.remark = item.remark
        }
      })
    },
    batchDeletion () {
      let distributionOrderItemIds = []
      this.multipleSelection.forEach((item, index) => {
        distributionOrderItemIds.push(item.distributionOrderItemId)
      })
      if (distributionOrderItemIds.length < 1) {
        this.$message.error('请选择需要删除的订单')
        return
      }
      this.$confirm('您确认要删除选中订单吗?', '删除订单', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        batchDeleteDistributionOrder({distributionOrderItemIds}).then(res => {
          this.$message.success('已删除')
          this.doRefresh({ currentPage: this.currentPage, pageSize: this.pageSize })
        })
      })
    },
    updateData () {
      this.isPriceMatch = false
      this.isPriceHigh = false
      this.isPriceLower = false
      const pageNum = this.currentPage < 1 ? 1 : this.currentPage
      let params = {
        pageNum,
        pageSize: this.pageSize,
        condition: this.searchForm
      }
      this.loading = true
      pageDistributionOrders(params).then(res => {
        const { data } = res
        this.dataList = []
        if (data && data.records) {
          data.records.forEach(item => {
            item.quantity = item.quantity - item.sentQuantity
            if (!item.askingPrice) {
              item.priceComparison = '0'   // 业态价为空
            } else {
              let askingPrice = this.removeSemicolon(item.askingPrice)
              let originalPrice = this.removeSemicolon(item.originalPrice)
              if (originalPrice < askingPrice) {
                item.priceComparison = '1'   // 要货价小于业态价
              } else if (originalPrice === askingPrice) {
                item.priceComparison = '2'   // 要货价等于业态价
              } else {
                item.priceComparison = '3'   // 要货价大于业态价
              }
            }
            this.dataList.push(item)
          })
          this.total = data.total
        }
        this.loading = false
      })
    },
    doRefresh (pageParam = { currentPage: 0, pageSize: this.pageSize }) {
      if (pageParam && pageParam instanceof Object) {
        this.currentPage = pageParam.currentPage
        this.pageSize = pageParam.pageSize
      }
      this.updateData()
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
      this.chooseRemarkList = []
      this.multipleSelection.forEach(item => {
        let isEqual = false
        this.chooseRemarkList.forEach(e => {
          if(e.value === item.distributionOrderId){
            isEqual = true
          }
        })
        if(!isEqual){
          let optionData = {
            value: item.distributionOrderId,
            label: item.distributionOrderNum + '/' + item.storeCode + '/' + item.code + '/' + item.storeNm
          }
          this.chooseRemarkList.push(optionData)
        }
      })
    },
    selectAll (val) {
      if (val) {
        this.dataList.forEach(item => {
          this.$nextTick( ()=> {
            this.$refs.table.toggleRowSelection(item, true)
          })
        })
      } else {
        this.$refs.table.clearSelection()
      }
    },
    removeSemicolon (num) {
      if(num){
        let x = num.split(',')
        return parseFloat(x.join(""))
      }else{
        return 0.00
      }
    },
    gotoPrice () {
      let orderList = []
      let isUnmatched = false
      this.multipleSelection.forEach(item => {
        if(item.unmatched === 'Y'){
          isUnmatched = true
        }
      })
      if(this.multipleSelection.length < 1){
        this.$message.error('请选择商品再调价')
        return
      }
      if(isUnmatched){
        this.$message.error('数据异常无法调价')
        return
      }
      this.$store.dispatch('user/setConsignedDelivery', this.multipleSelection)
      this.$router.push({
        path: '/member-center/consigned-delivery/modify-price',
        query: { outOfStock: this.outOfStock }
      })
    },
    rematch(){
      if(this.multipleSelection.length < 1){
        this.$message.error('请选择重新匹配的数据')
        return
      }
      if(this.multipleSelection.length > 1){
        this.$message.error('只能选择一条数据匹配')
        return
      }
      let params = {
        thirdProductCode: this.multipleSelection[0].thirdProductCode,
        customCode: this.multipleSelection[0].customerCode
      }
      this.form.thirdProductCode = this.multipleSelection[0].thirdProductCode
      this.form.thirdProductName = this.multipleSelection[0].productNm
      this.form.thirdProductSpec = this.multipleSelection[0].spec
      this.form.thirdProductUtil = ''
      this.form.thirdProductFactory = this.multipleSelection[0].manufacturer
      this.drawerVisible = true
      this.drawerLoading = true
      getModelDrugComparison(params).then(res => {
        if(res.data){
          this.form.modeName = res.data.modeName
          this.form.importModeId = res.data.importModeId
          this.form.quantityProportion = res.data.quantityProportion
          this.form.thirdProductUtil = res.data.thirdProductUtil
          this.form.erpCode = res.data.erpCode
          let productParams = {"pageNum":1,"pageSize":10,"condition":{"searchFiled":res.data.erpCode}}
          pageIndexProduct(productParams).then(res => {
            this.drawerLoading = false
            this.form.productId = res.data.records[0].productId
            this.form.productNm = res.data.records[0].drugNm
            this.form.spec = res.data.records[0].spec
            this.form.unit = res.data.records[0].unit
            this.form.manuFacturer = res.data.records[0].manufacturer
          }).catch(err=>{
            this.drawerLoading = false
          })
        } else {
          this.drawerLoading = false
        }
      }).catch(err=>{
        this.drawerLoading = false
      })
    },
    rematchStore(){
      if(this.multipleSelection.length < 1){
        this.$message.error('请选择重新匹配的数据')
        return
      }
      if(this.multipleSelection.length > 1){
        this.$message.error('只能选择一条数据匹配')
        return
      }
      getOrderInterfaceConfigRemarkList(this.form).then(res => {
        let data = res.data
        data.forEach(item => {
          let code = {
            label: item.userName,
            value: item.userName
          }
          this.identificationCodeList.push(code)
        })
        this.storeDrawerVisible = true
      })
      this.storeForm.storeCode = this.multipleSelection[0].storeCode
    },
    async checkExpDate(){
      if(this.multipleSelection.length){
        this.submitOrderData = [];
        let product = {};
        for(let item of this.multipleSelection){
          if(item.isSkuInfo === 'Y'){
            if(!product[item.productId]){
              product[item.productId] = item.productId
              this.submitOrderData.push(item)
            }
          }
        }
        if(!!this.submitOrderData.length){
          this.expDateVisible = true
        }else {
          this.commitOrder()
        }
      }else {
        this.commitOrder()
      }
    },
    commitOrder () {
      this.expDateVisible = false;
      let orderList = []
      let isUnmatched = false
      let orderVos = {}
      let remarks = {}
      this.multipleSelection.forEach(item => {
        if(item.unmatched === 'Y'){
          isUnmatched = true
        }
        let isHaveOrderId = false
        orderList.forEach( e => {
          if(e.distributionOrderId === item.distributionOrderId) {
            e.distributionOrderItemIdList.push(item.distributionOrderItemId)
            remarks[item.distributionOrderNum] = item.remark
            isHaveOrderId = true
          }
        })
        if(!isHaveOrderId){
          orderVos = {
            distributionOrderId: item.distributionOrderId,
            distributionOrderItemIdList: []
          }
          orderVos.distributionOrderItemIdList.push(item.distributionOrderItemId)
          remarks[item.distributionOrderNum] = item.remark
          orderList.push(orderVos)
        }
      })
      if (!orderList.length) {
        this.$message.error('请选择下单商品')
        return
      }
      if (isUnmatched){
        this.$message.error('数据异常无法下单')
        return
      }
      this.loading = true
      commissionedOrder({ orderList, remarks }).then(res => {
        this.loading = false
        if (res.data) {
          this.$store.dispatch('user/setConsignedDelivery', res.data)
          this.$router.push(`/member-center/consigned-delivery/interface-order/success`)
        } else {
          this.$message.error(res.data.msg || '下单失败')
        }
      }).catch(() => {
        this.loading = false
      })
    },
    beforeClose(done) {
      this.resetForm()
      done()
    },
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let params = {
            distributionOrderId: this.multipleSelection[0].distributionOrderId,
            distributionOrderItemId: this.multipleSelection[0].distributionOrderItemId,
            modelDrugComparisonSaveVo: this.form,
          }
          rematchOrderItem(params).then(res => {
            this.$message.success('已提交')
            this.drawerVisible = false
            this.resetForm()
            this.doRefresh()
          })
        }
      })
    },
    resetForm() {
      this.form = {
        modelDrugComparisonId: '',
        modeName: '',
        erpCode: '',
        productNm: '',
        spec: '',
        unit: '',
        manuFacturer: '',
        productId: '',
        thirdProductCode: '',
        thirdProductName: '',
        thirdProductSpec: '',
        thirdProductUtil: '',
        thirdProductFactory: '',
        quantityProportion: '',
        roundingOrNot: 'Y',
        importModeId: ''
      }
      this.$refs.form.resetFields()
    },
    chooseMode() {
      this.modeDrawerVisible = true
    },
    chooseGoods() {
      this.goodsVisible = true
    },
    chooseModeSucc(data) {
      this.modeDrawerVisible = false
      this.form.modeName = data.modeName
      this.form.importModeId = data.importModeId
      this.storeForm.modeName = data.modeName
      this.storeForm.importModeId = data.importModeId
    },
    chooseGoodsSucc(data) {
      this.goodsVisible = false
      this.form.erpCode = data.productCode
      this.form.productId = data.productId
      this.form.productNm = data.drugNm
      this.form.spec = data.spec
      this.form.unit = data.unit
      this.form.manuFacturer = data.manufacturer
    },
    storeBeforeClose(done) {
      this.storeResetForm()
      done()
    },
    storeHandleConfirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let params = {
            distributionOrderId: this.multipleSelection[0].distributionOrderId,
            distributionOrderItemId: this.multipleSelection[0].distributionOrderItemId,
            matchingCodeConfigSaveVo: this.storeForm,
          }
          saveModelDrugComparison(params).then(res => {
            this.$message.success('已提交')
            this.storeDrawerVisible = false
            this.storeResetForm()
            this.doRefresh()
          })
        }
      })
    },
    storeResetForm() {
      this.storeForm = {
        matchingCodeConfigId: '',
        identificationCode: '',
        storeCode: '',
        storeNm: '',
        addressId: '',
        modeName: '',
        importModeId: '',
        remark: '',
        isBack: 'Y'
      }
      this.$refs.form.resetFields()
    },
  },
  mounted () {
    let date = new Date();
    let year = date.getFullYear(); // 年
    let month = date.getMonth() + 1; // 月
    let day = date.getDate(); // 日
    this.searchForm.startTime = year + '-' + month + '-' + day + ' 00:00:00'
    this.searchForm.endTime = year + '-' + month + '-' + day + ' 23:59:59'
    this.searchForm.time = [year + '-' + month + '-' + day,year + '-' + month + '-' + day]
    this.doRefresh()
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-table {
  .expDate-row {
    td{
      background: rgba(240,230,140,.2) !important;
    }
    .cell{
      color: #FF5200 !important;
      >span{
        color: #FF5200 !important;
      }
      div{
        color: #FF5200 !important;
      }
      input{
        color: #FF5200 !important;
      }
      p{
        color: #FF5200 !important;
      }
    }
  }
}
.interfaceOrderDiv {
  background-color: #FFFFFF;
  text-align: left;
  padding: 0 20px;
  min-height: 780px;
  border-radius: 8px 8px 0px 0px;
  .title {
    color: #2B2B2B;
    font-size: 16px;
    line-height: 50px;
    height: 50px;
    padding-left: 8px;
    font-weight: 700;
    border-bottom: #E5E5E5 solid 1px;
  }
  .searchForm {
    border-bottom: 1px solid #E5E5E5;
    padding: 18px 20px 0 20px;

    .searchFormItem {
      width: 147px;
      margin-right: 10px;
    }
    .searchFormItem.isExpanded{
      width: 210px;
    }

    .dateFormItem {
      width: 220px;
      margin-right: 10px;
    }

    .rightCheck {
      line-height: 34px;
    }

    .formBut /deep/ .el-form-item__content {
      display: flex;
    }
  }

  .butDiv {
    margin: 18px 0 10px;
    align-items: center;
  }

  .tableDiv {
    padding-bottom: 18px;
    border-bottom: 1px solid #E5E5E5;

    .tableTitle {
      width: 100%;
      height: 40px;
      background: rgba(245, 245, 245, 1);
      border-radius: 4px 4px 0px 0px;
      display: flex;
      line-height: 40px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(102, 102, 102, 1);
    }

    .noData {
      text-align: center;
      padding-top: 15px;
      font-size: 12px;
      color: #909399;
    }

    .tableItem {
      /*height: 40px;*/
      background: rgba(241, 247, 254, 1);
      border: 1px solid rgba(229, 229, 229, 1);
      border-bottom: none;
      line-height: 40px;
      margin-top: 8px;
      font-size: 10px;
      font-weight: 500;
      color: rgba(102, 102, 102, 1);

      .tableItem_title {
        display: inline-table;
        line-height: 20px;
        label{
          font-weight: 400;
          display: inline-block;
          min-width: 180px;
        }
      }

      .tableAllChecked {
        margin-left: 20px;
        margin-right: 23px;
      }

      span {
        margin-right: 15px;
      }
    }

    .table {
      .price {
        color: #ffffff;
        border-radius: 2px;
        height: 100%;
        text-align: center;
        margin: 0 3px;
        padding: 0 5px;
      }

      .priceIcon {
        margin-top: 4px;
        width: 12px;
        height: 14px;
      }
    }

    .table-page {
      margin-top: 15px;
    }
  }

  .bottomDiv {
    display: flex;
    padding: 20px;
    line-height: 36px;
    justify-content: space-between;
    .leftDiv{
      display: flex;
    }
    .bottomRight {
      display: flex;
      height: 65px;
      /deep/ .el-input__inner {
        height: 30px;
        line-height: 30px;
      }
      /deep/ .el-input {
        height: 35px;
      }
    }
    .bottomButton {
      height: 60px;
      display: flex;
      margin-top: 6px;
    }
    .removeBut {
      color: #666666;
      border-color: #CCCCCC;
      font-size: 14px;
      font-weight: 400;
    }

    .orderBut {
      color: #ffffff;
      border-color: #FA6400;
      background-color: #FA6400;
      font-size: 14px;
      font-weight: 400;
    }
  }

  /deep/ .el-form-item--small.el-form-item {
    margin-bottom: 18px;
  }
  .modeName {
    color: #666666;
    margin-right: 10px;
  }
  .tipItem {
    background: #fafafa;
    height: 160px;
    width: 70%;
    margin: auto auto 24px;
    line-height: 32px;
    font-size: 14px;
    padding: 15px 20px;
  }
  .drawerSearch {
    display: flex;
    padding: 15px 15px 8px 15px;
    .drawerInput{
      width: 200px;
      margin-right: 15px;
    }
  }
}
</style>

<style lang="scss">
.cellColorOrder .cell {
  color: #E02020;
  font-size: 12px!important;
}
.cellColorOrder2 /deep/.cell {
  font-size: 12px!important;
}
</style>

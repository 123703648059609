<template>
  <div class="registerDiv">
    <keep-alive :include="['order','success']">
      <component :is="currentView" :params="params" outOfStock="Y"/>
    </keep-alive>
  </div>
</template>

<script>

import order from '../interface-order/order'
import success from '../interface-order/success'
export default {
  name: 'interfaceOrder',
  components: {
    order,
    success
  },
  data () {
    return {
      currentView: 'order',
      params: {
        title: '委托配送缺货订单'
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.registerDiv{
  width: 100%;
  text-align: center;
}
</style>

<template>
    <el-form ref="form" :model="form" :rules="formRules" label-width="0px" class="registerFrom">
      <el-form-item prop="registerLoginId" class="registerFromItem">
        <el-input v-model="form.registerLoginId" placeholder="输入登录账号" class="registerInput"></el-input>
        <div class="input-icon">
          <img src="@/assets/img/register/icon-user.png" class="img-user" alt/>
        </div>
      </el-form-item>
      <el-form-item prop="loginMobileId">
        <el-input v-model="form.loginMobileId" placeholder="输入手机号码" type="tel" maxlength="11"></el-input>
        <div class="input-icon">
          <img src="@/assets/img/register/phone.png" class="img-phone" alt/>
        </div>
      </el-form-item>
      <el-form-item prop="password">
        <el-input v-model="form.password" placeholder="设置登录密码" type="password"></el-input>
        <div class="input-icon">
          <img src="@/assets/img/register/icon-suo.png" class="img-suo" alt/>
        </div>
      </el-form-item>
      <el-form-item prop="passwordAgain">
        <el-input v-model="form.passwordAgain" placeholder="再次输入登录密码" type="password"></el-input>
        <div class="input-icon">
          <img src="@/assets/img/register/icon-suo.png" class="img-suo" alt/>
        </div>
      </el-form-item>
      <el-form-item prop="code">
        <el-input v-model="form.code" placeholder="输入验证码"></el-input>
        <div class="input-icon">
          <img src="@/assets/img/register/dun.png" class="img-phone" alt/>
        </div>
        <div class="zp-code">
          <div class="img-container">
            <img :src="codeUrl"/>
          </div>
          <el-button type="text" class="btn-change-code" @click="getRandom">换一张</el-button>
        </div>
      </el-form-item>
      <el-form-item prop="referrer">
        <el-input v-model="form.referrer" placeholder="输入推荐人" type="referrer"></el-input>
        <div class="input-icon">
          <img src="@/assets/img/register/icon-user.png" class="img-user" alt/>
        </div>
      </el-form-item>
      <el-form-item class="checkFrom" prop="agreement">
        <el-checkbox v-model="form.agreement"></el-checkbox>
        <span class="checkSpan" style="margin-left: 10px">我承诺并遵守<a @click="openAgreement">《鹭燕药业服务协议》</a></span>
      </el-form-item>
      <el-form-item>
        <el-button @click="next" class="sureBut" type="primary">确认并提交</el-button>
      </el-form-item>
      <el-dialog
          :visible.sync="dialogVisible"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :before-close="closeAgreement">
        <div slot="title" class="agreementDialog">
          {{agreementTitle}}
        </div>
        <p class="agreementP" v-html="agreementArticleCont"></p>
        <el-button @click="agree" class="agree" type="primary" size="mini">同意并继续</el-button>
      </el-dialog>
    </el-form>
</template>

<script>
import { saveUnableUser, getAgreement } from '@/api/register/index'
import formRules from '@/utils/validate'
import { encodeMd5 } from '@/utils/encode-md5'
export default {
  name: 'AccountInfor',
  props: {
    params: {
      type: Object,
      default: {}
    },
  },
  data () {
    const validateConfirmPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('确认密码不能为空'))
      }
      if (this.form.password && value !== this.form.password) {
        return callback(new Error('确认密码与新密码不相同'))
      }
      callback()
    }
    const validateAgreement = (rule,value,callback)=>{
      if(!value){
        return callback(new Error('请同意服务协议'))
      }
      callback()
    }
    return {
      active: 0,
      form:{
        registerLoginId: '',
        loginMobileId: '',
        password: '',
        passwordAgain: '',
        registerBuyersInfoId:'',
        code: '',
        referrer:'',//推荐人
        agreement: false
      },
      formRules: {
        registerLoginId: [{ required: true, trigger: 'blur', message: '登录账号不能为空' }],
        loginMobileId: [formRules.phone],
        password: [{ required: true, trigger: 'blur', message: '登录密码不能为空' }],
        passwordAgain: [{ required: true, trigger: 'blur', validator: validateConfirmPassword }],
        code: [{ required: true, trigger: 'blur', message: '图形验证码不能为空' }],
        agreement:[{required: true,validator:validateAgreement}],
      },
      codeBaseUrl: `${process.env.VUE_APP_BASE_API}/businessServiceYtyy/code/front/genCode`,
      random: new Date().getTime(),
      agreementTitle: '',
      agreementArticleCont: '',
      dialogVisible: false
    }
  },
  computed: {
    codeUrl () {
      return `${this.codeBaseUrl}?randomStr=${this.random}`
    }
  },
  methods: {
    closeAgreement(close){
      close()
    },
    next() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (!this.form.agreement) {
            alert('请先确认协议')
            return
          }
          let head = {
            code: this.form.code,
            randomStr: this.random
          }
          let paramsData = {
            loginMobileId: this.form.loginMobileId,
            referrer: this.form.referrer,
            password: encodeMd5(this.form.password),
            passwordAgain: encodeMd5(this.form.passwordAgain),
            registerLoginId: this.form.registerLoginId
          }
          saveUnableUser(head, paramsData).then(res => {
            if(res.data.tips === 'not_exist_user'){
              this.form.registerKey = res.data.registerKey
              this.form.tips = res.data.tips
              this.form.registerBuyersInfoId = res.data.registerBuyersInfoId
              let param = this.form
              this.$emit('next', 2, param)
            } else {
              this.$message.warning('抱歉，该用户名已注册，请重新输入，或直接登录')
            }
          }).catch(error => {
            if(error.msg != '验证码错误'){
              this.getRandom()
            }
          })
        }
      })
    },
    getRandom() {
      this.random = new Date().getTime()
    },
    openAgreement() {
      this.dialogVisible = true
    },
    agree() {
      this.dialogVisible = false
      this.form.agreement = true
    },
    getAgreementText(){
      getAgreement({}).then(res => {
        this.agreementTitle = res.data.title
        this.agreementArticleCont = res.data.articleCont
        this.dialogVisible = true
      })
    }
  },
  mounted() {
    this.getAgreementText()
  }
}
</script>

<style lang="scss" scoped>
.registerFrom {
  width: 400px;
  margin: 25px auto;
  .registerFromItem{
    margin-bottom: 24px;
  }
  /deep/ .el-dialog__body{
    padding: 16px 20px 30px 20px;
  }
  /deep/ .el-input__inner{
    padding-left: 45px;
    color: #222222;
  }
  /deep/ .el-dialog__header {
    padding: 0px;
  }
  /deep/ .el-dialog__headerbtn {
    top: 10px;
  }
  input{
    padding-left: 48px;
  }
  .input-icon {
    position: absolute;
    left: 12px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    .img-user {
      width: 14px;
      height: 16px;
    }
    .img-phone {
      width: 16px;
      height: 16px;
    }
    .img-suo {
      width: 13px;
      height: 16px;
    }
  }
  .zp-code {
    position: absolute;
    top: 0px;
    bottom: 10px;
    right: 15px;
    display: flex;
    .img-container {
      background-color: #fff;
      height: 28px;
      width: 90px;
      margin-top: 4px;
      img {
        width: 72px;
        height: 32px;
      }
    }
  }
  .checkSpan {
    margin-left: 10px;
    font-size: 12px;
    color: #A3A3A3;
    a {
      @include TextHighlightColor
    }
  }
  .checkFrom{
    text-align: left;
  }
  .agreementDialog {
    height: 36px;
    text-align: left;
    line-height: 36px;
    background-color: #F5F5F5;
    font-size: 16px;
    color: #222222;
    width: 100%;
    padding-left: 20px;
  }
  .agreementP{
    line-height: 20px;
    text-align: left;
  }
  .agree {
    width: 160px;
    height: 32px;
    text-align: center;
    font-size: 14px;
    margin-top: 40px;
  }
  .sureBut {
    width: 400px;
    height: 40px;
    @include NormalBtnColor
  }
  .btn-change-code{
    //@include TextHighlightColor
  }
  .agree{
    @include NormalBtnColor
  }
}
</style>

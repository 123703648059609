<template>
  <div class="main">
    <Search :hidden-nav="true"/>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/member-center' }">会员中心</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/member-center/order-manage/store-order' }">我的订单</el-breadcrumb-item>
        <el-breadcrumb-item>订单详情</el-breadcrumb-item>
        <el-breadcrumb-item>查看物流</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <el-tabs v-model="activeName" @tab-click="bindTab">
        <el-tab-pane :name="String(index)" v-for="(item,index) in packageVos" :key="item.packageId">
          <span slot="label">
            包裹{{index+1}}
          </span>
        </el-tab-pane>
      </el-tabs>
      <div class="header center">
        <b class="center">包裹{{Number(activeName+1)}}</b>
        物流公司：<span style="padding-right: 20px">{{logisticsCompanyNm}}</span>
        运单号：<span>{{expressCode}}</span>
      </div>
      <order-express :express="orderExpress" />
      <el-table :data="goodsList" border >
        <el-table-column label="商品信息" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="product-info">
              <div class="product-image">
                <el-image :src="scope.row.thumbnailUrl" @error="scope.row.thumbnailUrl = productDefaultImg"
                          fit="fit"/>
              </div>
              <ul class="product-detail">
                <li class="name">{{$util.getGoodsName(scope.row)}}</li>
                <li class="detail">
                  <p>规格：<span class="spec">{{ scope.row.spec}}</span> 单位：<span>{{ scope.row.unit}}</span></p>
                </li>
                <li class="manufacturer detail">厂家：{{ scope.row.manufacturer}}</li>
              </ul>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="购买数量" width="450px" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.num }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  import Search from '@/components/Search/Search'
  import OrderExpress from './components/order-express'
  import { getBus } from '@/utils/auth'
  import productDefaultImg from '@/assets/img/index/product_default.png'
  import { findDetail, findWmsLogistics} from '@/api/member-center/order-manage/order-list'

  export default {
    name: 'express-detail',
    components: { Search, OrderExpress },
    data () {
      return {
        productDefaultImg,
        activeName: 0,
        logisticsOrderNum:'',
        packageVos: [], //分包裹
        goodsList: [], //商品信息
        packageId: '',
        logisticsCompanyNm: '',
        orderExpress:[]
      }
    },
    computed:{
        expressCode(){//物流单号
            console.log(this.orderExpress)
            return this.orderExpress.length > 0 ? this.orderExpress[0].inputCode : ''
        }
    },
    mounted () {
      this.findDetail()
    },
    methods: {
      findDetail() {
          findDetail({
            orderId:this.$route.params.id,
            orgId: getBus().orgId
          }).then(res => {
            const { data } = res
            this.orderVo = data.orderVo
            this.packageVos = data.packageVos
            this.packageId = data.packageVos[0].packageId
            this.logisticsOrderNum = data.packageVos[0].logisticsOrderNum
            this.logisticsCompanyNm = data.packageVos[0].logisticsCompanyNm
            this.findWmsLogistics(this.packageId)
          })
      },
      bindTab(event){
        this.packageId = this.packageVos[event.name].packageId
        this.findWmsLogistics(this.packageId)
      },
      findWmsLogistics(packageId){
        findWmsLogistics({packageId:packageId}).then(res => {
          if(res.code != '0') return
          this.orderExpress = res.data.result || []
          this.goodsList = res.data.orderItemList || []
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .center {
    display: flex;
    align-items: center;
  }

  .main {
    background-color: #f5f5f5;
    padding-bottom: 20px;

    .content {
      width: 1200px;
      margin: 0 auto;
      padding-bottom: 20px;
      background: #fff;

      .header {
        font-size: 14px;
        line-height: 40px;
        color: #262626;
        background: $hoverBg;
        width: 100%;
        text-align: left;

        b {
          height: 18px;
          padding: 0 4px;
          color: $ThemeColor;
          border: 1px solid $ThemeColor;
          font-size: 12px;
          border-radius: 3px;
          margin: 0 10px 0 20px;
        }
      }

      .express-steps {
        max-height: 240px;
        overflow-y: auto;
      }

      .product-info {
        display: flex;
        flex-direction: row;

        .product-image {
          width: 70px;
          height: 70px;

          .el-image {
            width: 100%;
            height: 100%;
          }
        }

        .product-detail {
          padding-left: 16px;
          width: 212px;

          li {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;
          }

          .name {
            height: 18px;
            font-size: 14px;
            font-weight: 500;
            color: rgba(34, 34, 34, 1);
            line-height: 18px;
            margin-bottom: 4px;
          }

          .detail {
            font-weight: 400;
            color: rgba(113, 113, 113, 1);
            line-height: 18px;
            height: 18px;

            .spec {
              margin-right: 10px;
            }

            &.manufacturer {
              white-space: normal;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              height: 36px;
            }
          }
        }
      }

      /deep/ {
        .el-table--border th, .el-table--border td {
          border-right: 0;
        }

        .el-table .el-table__header th .cell {
          color: #717171;
          font-weight: 400;
        }

        .el-tabs {
          padding: 0 20px;
          .el-tabs__header {
            margin: 0;
            .el-tabs__item {
              height: 50px;
              line-height: 50px;
            }
          }
        }
      }
    }

    .breadcrumb {
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      padding-left: 24px;
      width: 1200px;
      margin: 0 auto;

      /deep/ .el-breadcrumb {
        display: inline-block;
        line-height: 50px;
        float: left;
      }
    }
  }
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "my-aptitudes" }, [
    _c("div", { staticClass: "title" }, [_vm._v("我的资质")]),
    _vm._m(0),
    _c(
      "ul",
      { staticClass: "aptitudes-cards" },
      _vm._l(_vm.aptitudesList, function (item, index) {
        return _c("li", { key: index, staticClass: "card" }, [
          _c(
            "div",
            {
              staticClass: "card-title",
              style: {
                background:
                  item.isForever === "N" && item.status === "已过期"
                    ? "#FFF0EF"
                    : "#f2f2f2",
              },
            },
            [
              _vm._v(" " + _vm._s(item.licenseNm) + " "),
              item.isForever === "N" && item.status !== "未过期"
                ? _c(
                    "span",
                    {
                      style: {
                        background:
                          item.status === "已过期" ? "#E02034" : "#FA6400",
                      },
                    },
                    [_vm._v(_vm._s(item.status))]
                  )
                : _vm._e(),
            ]
          ),
          _c("div", { staticClass: "card-main" }, [
            _c(
              "div",
              { staticClass: "card-image" },
              [
                _c("el-image", {
                  staticClass: "thumbnailImg",
                  attrs: {
                    src: item.imgUrl || _vm.ApitiudesPicAll,
                    "preview-src-list": [item.imgUrl || _vm.ApitiudesPicAll],
                  },
                  on: {
                    error: function ($event) {
                      item.imgUrl = _vm.ApitiudesPicAll
                    },
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "card-content" }, [
              _c(
                "p",
                { staticClass: "card-number", attrs: { title: item.certNum } },
                [_vm._v("证件号码：" + _vm._s(item.certNum) + " ")]
              ),
              _c("p", [
                _vm._v(
                  "证照有效期：" +
                    _vm._s(
                      item.isForever === "Y"
                        ? "长期"
                        : item.customerLicenseEndDate
                    )
                ),
              ]),
              _c(
                "div",
                { staticClass: "update-button" },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      refInFor: true,
                      attrs: {
                        action: "#",
                        "list-type": "picture-card",
                        "auto-upload": true,
                        "show-file-list": _vm.hideUploadProve,
                        "file-list": _vm.fileList,
                        "on-preview": _vm.handlePictureCardPreview,
                        "on-remove": _vm.handleRemoveProve,
                        "before-upload": _vm.beforeUpload,
                        "http-request": () =>
                          _vm.upload(item.customerLicenseTypeRelId),
                      },
                    },
                    [
                      _c(
                        "button",
                        { attrs: { slot: "trigger" }, slot: "trigger" },
                        [_vm._v("更新资质")]
                      ),
                    ]
                  ),
                  _c(
                    "el-dialog",
                    {
                      attrs: { visible: _vm.dialogVisible },
                      on: {
                        "update:visible": function ($event) {
                          _vm.dialogVisible = $event
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          width: "100%",
                          src: _vm.dialogImageUrl,
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "reminder" }, [
      _c("i", { staticClass: "el-icon-warning-outline" }),
      _vm._v("资质证照过期将影响您药品采购，请及时提交更换"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
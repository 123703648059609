import {ApiGet, ApiPost} from "@/api";

/**
 * @description 根据资讯分类
 */
export const getInfArticleCategoryTree = id => ApiGet('/pc/front/pageModule/getInfArticleCategoryTree', {
    org: id
},{
    id
})

/**
 * @description 获取资讯列表
 */
export const pageInfArticle = data => ApiPost('/pc/infArticle/front/pageInfArticleTree', data)

/**
 * @description 获取资讯详情
 */
export const getInfArticle = infArticleId => ApiGet('/pc/infArticle/front/getInfArticle', {
    infArticleId
},{
    id: infArticleId
})

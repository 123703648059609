<template>
  <im-page-modal
    class="product-code-list-page"
    v-loading="loading"
    title="商品对码表"
    :isShowBack="false"
  >
    <!-- 按钮区域 -->
    <im-search-pad
      class="search-content"
      :model="searchForm"
      :has-expand="false"
      @search="handleSearch"
      @reset="handleReset"
    >
      <im-search-pad-item prop="thirdProduct">
        第三方商品信息：
        <el-input
          style="width:180px;height:32px"
          v-model="searchForm.thirdProduct"
          placeholder="商品编码/商品名称/生产厂家"
        />
      </im-search-pad-item>&nbsp;&nbsp;
      <im-search-pad-item prop="product">
        平台商品信息：
        <el-input
          style="width:180px;height:32px"
          v-model="searchForm.product"
          placeholder="商品编码/商品名称/生产厂家"
        />
      </im-search-pad-item>
    </im-search-pad>
    <!-- 表格内容 -->
    <im-table-page
      style="margin-bottom:15px"
      ref="tablePage"
      :data.sync="tableData"
      :remote-method="queryData"
      @updated="updated"
    >
      <el-table-column label="商品编码" prop="productCode" width="180px" show-overflow-tooltip />
      <el-table-column label="商品规格" prop="spec" min-width="160px" show-overflow-tooltip />
      <el-table-column label="商品单位" prop="unit" min-width="120px" show-overflow-tooltip />
      <el-table-column label="生产厂家" prop="manufacturer" min-width="180px" show-overflow-tooltip />
      <el-table-column label="鹭燕商品编码" prop="thirdProductCode" width="180px" show-overflow-tooltip />
      <el-table-column
        label="鹭燕商品名称"
        prop="thirdProductName"
        min-width="180px"
        show-overflow-tooltip
      />
      <el-table-column label="规格" prop="thirdProductSpec" min-width="100px" show-overflow-tooltip />
      <el-table-column label="单位" prop="thirdProductUtil" min-width="60px" show-overflow-tooltip />
      <el-table-column
        label="生产厂家"
        prop="thirdProductFactory"
        min-width="180px"
        show-overflow-tooltip
      />
    </im-table-page>
  </im-page-modal>
</template>
<script>
import { pageProductCode } from "@/api/member-center/transactions-using";
export default {
  name: "ConsultPriceDraftsList",
  props: {
    params: {
      type: Object,
      default() {
        return {
          AllChecked: false,
          loading: false,
        };
      },
    },
  },
  data() {
    return {
      loading: false,
      time: [],
      tableData: [],
      searchForm: {
        product: "",
        thirdProduct: "",
      },
    };
  },
  watch: {
    params: {
      handler(newVal) {
        if (newVal.refreshList) {
          this.doRefresh();
        }
      },
      deep: true,
    },
  },
  methods: {
    doRefresh() {
      this.$refs.tablePage.doRefresh({ currentPage: 0, pageSize: 10 });
    },
    handleSearch() {
      this.doRefresh();
    },
    handleReset() {
      this.searchForm = {
        product: "",
        thirdProduct: "",
      };
      this.time = [];
    },
    queryData(data) {
      return pageProductCode({
        ...data,
        condition: {
          product: this.searchForm.product ? this.searchForm.product : null,
          thirdProduct: this.searchForm.thirdProduct
            ? this.searchForm.thirdProduct
            : null,
        },
      });
      // var tableData = [{productCode:'1111'},{productCode:'222'}]
      // return Promise.resolve({
      //     code: 0,
      //     msg: '',
      //     data: {
      //         records: tableData,
      //         total: tableData.length
      //     }
      // })
    },
    updated() {
      this.$refs.tablePage.doLayout();
    },

    // handleEdit(row){
    //   var shopList = []
    //    getInquiryBoxById(row.inquiryDraftBoxId).then( res=>{
    //        shopList = res.data
    //        this.$emit('change-page', {
    //         page: 'ConsultPriceDraftsUpdate',
    //         params: {
    //         editType: 'edit',
    //         rowData:row,
    //         shopList:shopList
    //         }
    //      })
    //     })

    // }
  },
};
</script>
<style  lang="scss">
.product-code-list-page {
  .content {
    padding-top: 0;
  }

  .page-header-btn {
    .el-input__inner {
      height: 30px;
      line-height: 30px;
    }
  }
}
</style>
<style scoped lang="scss">
.product-code-list-page {
  background-color: white;
  min-height: 100% !important;
  margin: 0 auto;
  padding: 0 15px;
  .page-header-btn {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    .right {
      font-style: normal;
      font-size: 12px;
      color: #666666;
      .el-input__inner {
        height: 30px;
      }
    }
  }
  .search-content {
    border-left: none;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    color: rgba(113, 113, 113, 1);
  }
}
</style>

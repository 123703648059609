var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shopping-cart-container" },
    [
      _c("header", { staticClass: "header" }, [
        _c("div", { staticClass: "header-content" }, [
          _c(
            "div",
            { staticClass: "logo" },
            [
              _c("router-link", { attrs: { to: "/" } }, [
                _vm.header.PC_TOP_LEFT_T1 && _vm.header.PC_TOP_LEFT_T1.length
                  ? _c("img", {
                      attrs: {
                        src: _vm.header.PC_TOP_LEFT_T1[0].sysFileUrl,
                        alt: "",
                      },
                    })
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _c("h4", [_vm._v(_vm._s(_vm.params.title))]),
          _c(
            "div",
            { staticClass: "steps" },
            [
              _c(
                "el-steps",
                {
                  attrs: {
                    active: _vm.params.active,
                    "align-center": true,
                    "finish-status": "finish",
                    "process-status": "finish",
                  },
                },
                [
                  _c("el-step", { attrs: { title: "进货单" } }),
                  _c("el-step", { attrs: { title: "填写核对订单" } }),
                  _c("el-step", { attrs: { title: "提交订单" } }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(_vm.params.currentView, {
        tag: "component",
        attrs: { params: _vm.params },
        on: { "change-page": _vm.handleChangePage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
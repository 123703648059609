<template>
  <div class="ICBCPayContainer">
    <div class="titleContainer">
      <div class="titleContent">
        <span class="title">鹭燕商城</span><span class="subTitle">订单在线支付</span>
      </div>
    </div>

    <div class="payContainer">
      <div class="payTitle">
        <div class="orderNum">订单编号：{{form.orderNum}}</div>
        <div class="payAmount"><span class="text">{{form.orderTotalAmount}}</span>元</div>
      </div>

      <div class="payContent">
        <div class="pay">
          <div class="payTips">扫一扫付款（元）</div>
          <div class="payAmount">{{form.orderTotalAmount}}</div>
          <div class="qrCodePayContent" v-show="ICBCQrCode">
            <canvas id="payQrCode" class="payQrCode"></canvas>
            <div class="qrCodeTips">支持微信、支付宝扫码</div>
            <el-button type="primary" size="small" class="pay-success" @click="toDetail">我已支付</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qrCode from '@/common/qrCode'
export default {
  name: "ICBCPay",
  data() {
    return {
      form:{
        orderNum: '',
        orderTotalAmount: '',
        orderId: ''
      },
      ICBCQrCode: ''
    }
  },
  mixins:[qrCode],
  created() {
    let {
      orderNum,
      orderTotalAmount,
      qrCode,
      orderId,
    } = this.$route.query
    this.ICBCQrCode = qrCode
    this.form = {
      orderNum,
      orderId,
      orderTotalAmount
    }

    if(!orderNum || !orderId || !qrCode){
      this.$message.error('数据错误,请重试!')
    }
  },
  mounted() {
    this.$nextTick(()=>{
      this.drawQrCode('payQrCode',this.ICBCQrCode)
    })
  },
  methods: {
    toDetail(){
      this.$router.push({ path: '/order-detail/' + this.form.orderId })
    }
  }
}
</script>

<style scoped lang="scss">
  .ICBCPayContainer{
    width: 100%;
    background-color: #eff0f1;
    height: 100%;
    .titleContainer{
     width: 100%;
      background-color: #fff;
      border-top: 4px solid #4d4d4d;
      border-bottom: 1px solid #d9d9d9;
      .titleContent{
        width: 1000px;
        margin: 0 auto;
        height: 60px;
        line-height: 60px;
        text-align: left;
        .title{
          font-size: 28px;
          font-weight: bold;
          border-right: 1px solid #ccc;
          padding-right: 10px;
        }
        .subTitle{
          color: #666;
          font-size: 20px;
          margin-left: 10px;
        }
      }
    }
    .payContainer{
      width: 1000px;
      margin: 30px auto 0;
      .payTitle{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        line-height: 50px;
        padding: 16px 23px;
        box-sizing: border-box;
        border-bottom: 3px solid #b3b3b3;
        .orderNum{
          font-size: 14px;
          font-weight: bold;
        }
        .payAmount{
          font-size: 12px;
          color: #000;
          .text{
            color: #f60;
            font-size: 22px;
            font-weight: bold;
          }
        }
      }
      .payContent{
        width: 100%;
        background: #fff;
        min-height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 3px solid #b3b3b3;
        .pay{
          .payTips{
            font-size: 12px;
            text-align: center;
          }
          .payAmount{
            color: #f60;
            font-size: 26px;
            font-weight: 700;
            margin: 10px 0;
          }
          .qrCodePayContent{
            width: 100%;
            .payQrCode{
              display: block;
              width: 200px !important;
              height: 200px !important;
              margin: 0 auto;
            }
            .qrCodeTips{
              margin: 0 auto;
              text-align: center;
              width: 100%;
              color: #999999;
            }
            .pay-success{
              margin-top: 30px;
              width: 160px;
            }
          }
        }
      }
    }
  }
</style>
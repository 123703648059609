<template>
  <div class="main">
    <Search :hidden-nav="true"/>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/member-center' }">会员中心</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/member-center/order-manage/refunds' }">退货记录</el-breadcrumb-item>
        <el-breadcrumb-item>退货详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="line"></div>
    <div class="content">
      <div class="left">
        <h4 v-if="returnedOrderVo.returnedOrderStat === 'WAIT_APPROVE'">待审核</h4>
        <h4 v-if="returnedOrderVo.returnedOrderStat === 'WAIT_STOCK_IN'">退货处理中</h4>
        <h4 v-if="returnedOrderVo.returnedOrderStat === 'REFUSE'">已拒绝</h4>
        <h4 v-if="returnedOrderVo.returnedOrderStat === 'FINSH'">退货完成</h4>
        <h4 v-if="returnedOrderVo.returnedOrderStat === 'CANCEL'">申请取消</h4>
        <p>业务员： {{returnedOrderVo.salesmanName}} {{returnedOrderVo.salesmanContactTel}}</p>
        <el-button :disabled="isdisable" v-if="returnedOrderVo.returnedOrderStat === 'WAIT_APPROVE'" type="primary" size="mini" @click="cancelRefund">取消申请</el-button>
        <el-button type="primary" size="mini" @click="goback">返回退货列表</el-button>
      </div>
      <div class="steps">
        <el-steps
            :active="active"
            process-status="finish"
            finish-status="finish"
            :align-center="true">
            <el-step :description="stateChangeTime[0].stateTime" title="提交退货申请"></el-step>
            <el-step v-if="returnedOrderVo.returnedOrderStat !== 'CANCEL' && returnedOrderVo.returnedOrderStat !== 'REFUSE'" :description="stateChangeTime[1].stateTime" title="业务员审核"></el-step>
            <el-step v-if="returnedOrderVo.returnedOrderStat !== 'CANCEL' && returnedOrderVo.returnedOrderStat !== 'REFUSE'" :description="stateChangeTime[2].stateTime" title="退货处理中"></el-step>
            <el-step v-if="returnedOrderVo.returnedOrderStat !== 'CANCEL' && returnedOrderVo.returnedOrderStat !== 'REFUSE'" :description="stateChangeTime[3].stateTime" title="退货完成"></el-step>
            <el-step v-if="returnedOrderVo.returnedOrderStat === 'CANCEL'" :description="stateChangeTime[4].stateTime" title="申请取消"></el-step>
            <el-step v-if="returnedOrderVo.returnedOrderStat === 'REFUSE'" :description="stateChangeTime[5].stateTime" title="退货审核拒绝"></el-step>
        </el-steps>
      </div>
    </div>

    <div class="footer">
      <div class="message">
        <div class="msg">
          <p>订单信息</p>
          <div class="detail">
            <div><b>订单编号</b><span>{{returnedOrderVo.orderNum}}</span></div>
            <div><b>退货编号</b><span>{{returnedOrderVo.returnedOrderNum}}</span></div>
            <div><b>下单时间</b><span>{{returnedOrderVo.orderTime}}</span></div>
          </div>
          <div class="detail">
            <div><b>分支机构</b><span>{{returnedOrderVo.ettleAccountNm}}</span></div>
            <div><b>分支机构编码</b><span>{{returnedOrderVo.code}}</span></div>
          </div>
        </div>
      </div>
       <div class="refund-detail">
           <!-- 退货详情 -->
          <template>
              <div class="content-header">
                 <h3 style="padding:0 12px;font-weight:600">退货详情</h3>
              </div>
              <div style="padding:16px;background:white">
                <el-table
                ref="tablePage"
                border
                :data.sync="returnProductVos"
                >
                 <el-table-column label="商品信息" width="358px" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <!-- <p  v-if="scope.row.orderCode">
                      订单编号：<span class="order-row-text">191205092323393</span>
                      下单时间：<span class="order-row-text">2019-12-20 09:05:39</span>
                    </p> -->
                    <div class="product-info">
                      <div style="float:left">
                        <el-image 
                        :src="scope.row.pictIdS || productDefaultImg" 
                        @error="scope.row.pictIdS = productDefaultImg"
                        fit="fit">
                      </el-image>
                      </div>
                      <ul style="float:right;position: absolute;left: 105px;">
                        <li class="proname" style="font-size:14px;color:rgba(34,34,34,1);line-height:20px;">{{scope.row.productNm? scope.row.drugNm + '(' + scope.row.productNm + ')' : scope.row.drugNm }}</li>
                        <li class="size-vender">
                          <p>规格：<span>{{ scope.row.spec}}</span>  单位：<span>{{ scope.row.unit}}</span></p>
                          <p class="manufacturer">厂家：<span>{{ scope.row.manufacturer}}</span></p>
                        </li>
                      </ul>
                    </div>
                    <div></div>
                  </template>
                </el-table-column>
                <el-table-column label="发货批号" prop="sendBatch" width="81px" show-overflow-tooltip>
                  <template slot-scope="scope">
                      <span style="color: #666">{{scope.row.sendBatch}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="退货价" prop="unitPrice" align="right" min-width="70px" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span>&yen;{{ scope.row.unitPrice }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="出库/可退数量" prop="outStockQuantity" min-width="90px" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span>{{ scope.row.outStockQuantity  + '/' + scope.row.returnableQuantity}}</span>
                  </template>
                </el-table-column>
                <el-table-column  label="退货数量" prop="returnOrderQuntity"  width="81px" show-overflow-tooltip >
                   <template slot-scope="scope">
                     <span style="color:#FA6400">{{ scope.row.returnOrderQuntity }}</span>
                  </template>
                </el-table-column>
                <el-table-column v-if="(returnedOrderVo.returnedOrderStat !== 'WAIT_APPROVE') && (returnedOrderVo.returnedOrderStat !== 'CANCEL')" label="业务员审核退货数量" prop="approvedQuantity"  width="100px" show-overflow-tooltip >
                   <template slot-scope="scope">
                     <span style="color:#FA6400">{{ scope.row.approvedQuantity }}</span>
                  </template>
                </el-table-column>
                </el-table>
                <div class="totalMoney">
                  <div class="contentMoney">
                     <div class="sqmoney"><span class="moneyinfo">申请退款总金额：</span> <span class="money">¥{{$util.toFixed(returnedOrderVo.returnedOrderAmount)}}</span></div>
                     <div class="sjmoney" v-if="returnedOrderVo.returnedOrderStat !== 'WAIT_APPROVE' && returnedOrderVo.returnedOrderStat !== 'CANCEL' && returnedOrderVo.returnedOrderStat !== 'REFUSE'"><span class="moneyinfo">实际退款总金额：</span> <span class="money">¥{{$util.toFixed(returnedOrderVo.refundTotalAmount)}}</span></div>
                  </div>
                </div>
              </div>
          </template>
           <template>
              <div class="content-header">
                 <h3 style="padding:0 12px;font-weight:600">退货信息</h3>
              </div>
              <el-form style="background-color:white;text-align:left;padding:25px 0" label-width="120px">
                <el-form-item label="退货原因：" prop="remark">
                  <p style="width:420px;">{{getReasonLabel(returnedOrderVo.remark)}}</p>
                </el-form-item>
                <el-form-item label="退货说明：" prop="descr">
                  <p style="width:420px;">{{returnedOrderVo.descr}}</p>
                </el-form-item>
                <el-form-item label="退货凭证：" prop="filePicture" class="picture-upload">
                    <el-image v-for="(item,index ) in returnedOrderVo.filePicture" :key="index"
                              style="width:70px; height: 70px;"
                              :preview-src-list="[item]"
                              :src="item"
                              fit="fit">
                    </el-image>
                </el-form-item>
                <el-form-item label="退货地址：" prop="address">
                  <p style="width:420px;">{{returnedOrderVo.receiverName}} - {{returnedOrderVo.receiverMobile}} - {{returnedOrderVo.province}} - {{returnedOrderVo.city}} - {{returnedOrderVo.district}} - {{returnedOrderVo.receiverAddr}}</p>
                </el-form-item>
             </el-form>
           </template>
           <template>
              <div class="content-header">
                 <h3 style="padding:0 12px;font-weight:600">退货记录</h3>
              </div>
              <order-express  :detail-list="returnOrderOperationLogVos"/>
          </template>
      </div>
    </div>
  </div>
</template>

<script>
  import {findRefundsOrderDetail, cancelReturnedOrder} from '@/api/member-center/refunds'
  import Search from '@/components/Search/Search'
  import OrderExpress from './order-express'
  import productDefaultImg from '@/assets/img/index/product_default.png'
  import refunds from "@/views/member-center/order-manage/refunds/mixins/refunds";
  export default {
    name: 'refunds-details',
    components: { Search,OrderExpress},
    mixins:[refunds],
    data () {
      return {
        // 退货单信息
        returnedOrderVo:{},
        // 退货商品
        returnProductVos:[],
        // 日志信息
        returnOrderOperationLogVos: [],
        productDefaultImg,
        returnedOrderId: '',
        order: {},
        active: 0,
        dialogVisible: false,
        isdisable: false,
        stateChangeTime:[
          {isState: false,
           stateTime: ''
          },
          {isState: false,
           stateTime: ''
          },
          {isState: false,
           stateTime: ''
          },
          {isState: false,
           stateTime: ''
          },
          {isState: false,
           stateTime: ''
          },
          {isState: false,
           stateTime: ''
          }
        ],
        orderFor: ''
      }
    },
    created () {
      this.returnedOrderId = this.$route.query.returnedOrderId
      this.queryData()
    },
    methods: {
      cancelRefund() {
        let returnedOrderId = this.returnedOrderId
        let isCancel = true
        this.$confirm('您是否取消申请?', '取消申请', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          cancelReturnedOrder({returnedOrderId, isCancel}).then(res => {
            if(res.code === '0'){
              this.$message.success('取消成功! ')
              this.queryData()
            }else {
              this.$message.error('取消失败! ')
            }
          })
        })  
      },
      goback() {
        this.$router.push({path:'/member-center/order-manage/refunds'})
      },
      queryData () {
        let returnedOrderId = this.returnedOrderId
        return new Promise(then => {
          findRefundsOrderDetail({
            returnedOrderId
          }).then(res => {
            this.returnedOrderVo = res.data.returnedOrderVo
            this.returnProductVos = res.data.returnProductVos
            this.returnOrderOperationLogVos = res.data.returnOrderOperationLogVos
            let logLen = this.returnOrderOperationLogVos.length
            // 业务员代替申请的单子   日志的 提交申请状态 是WAIT_STOCK_IN
            // 下游客户自己申请的单子 日志的 提交申请状态 WAIT_APPROVE
            if(this.returnOrderOperationLogVos[logLen-1].approveStat === 'WAIT_APPROVE'){
              this.orderFor = 'BUYERS'
            }else if(this.returnOrderOperationLogVos[logLen-1].approveStat === 'WAIT_STOCK_IN'){
              this.orderFor = 'SALEMAN'
            }
           
            this.returnOrderOperationLogVos.map(item => {
              if(this.orderFor === 'SALEMAN')  {
                if(item.approveStat === 'WAIT_STOCK_IN' && !this.stateChangeTime[1].isState){
                  this.stateChangeTime[0].stateTime = item.operationTime
                  this.stateChangeTime[0].isState = true
                  this.stateChangeTime[1].stateTime = item.operationTime
                  this.stateChangeTime[1].isState = true
                  this.stateChangeTime[2].stateTime = item.operationTime
                  this.stateChangeTime[2].isState = true
                }
              }else if(this.orderFor === 'BUYERS'){
                if(item.approveStat === 'WAIT_APPROVE' && !this.stateChangeTime[0].isState){
                  this.stateChangeTime[0].stateTime = item.operationTime
                  this.stateChangeTime[0].isState = true
                }
                if(item.approveStat === 'WAIT_STOCK_IN' && !this.stateChangeTime[1].isState){
                  this.stateChangeTime[1].stateTime = item.operationTime
                  this.stateChangeTime[1].isState = true
                  this.stateChangeTime[2].stateTime = item.operationTime
                  this.stateChangeTime[2].isState = true
                }
              }
              
            
              if(item.approveStat === 'REFUSE' && !this.stateChangeTime[5].isState){
                this.stateChangeTime[5].stateTime = item.operationTime
                this.stateChangeTime[5].isState = true
              }

              if(item.approveStat === 'FINISH' && !this.stateChangeTime[3].isState){
                this.stateChangeTime[3].stateTime = item.operationTime
                this.stateChangeTime[3].isState = true
              }

              if(item.approveStat === 'CANCEL' && !this.stateChangeTime[4].isState){
                this.stateChangeTime[4].stateTime = item.operationTime
                this.stateChangeTime[4].isState = true
              }
            })

            switch (this.returnedOrderVo.returnedOrderStat) {
              case 'WAIT_APPROVE':
                this.active = 0
                break
              case 'REFUSE':
                this.active = 2
                break
              case 'WAIT_STOCK_IN':
                this.active = 2
                break
              case 'FINSH':
                this.active = 3
                break
              case 'CANCEL':
                this.active = 1
                break
            }
          })
        })
      },
      productDetail(row) {
        this.$router.push({name:"detail",params:{id: row.productId}})
      }
    }
  }
</script>

<style scoped lang="scss">
  .steps {
    /deep/ .el-step.is-center .el-step__description {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .manufacturer {
    width: 200px
  }
  .contentMoney {
    float: right;
    height: 40px;
  }
  .sqmoney {
    width: 200px;
    float: left;
    height: 40px;
    display: inline-block;
    line-height: 40px;
  }
  .sjmoney {
    width: 200px;
    float: right;
    display: inline-block;
    line-height: 40px;
    right: 15px;
    margin-right: 10px;
  }
  .moneyinfo {
    font-size:14px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(102,102,102,1);
  }
  .money {
    left: -9px;
    position: relative;
    font-size: 14px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
    color: #FA6400;

  }
  .totalMoney {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 40px;
    background: white;
    border-radius: 0px 0px 4px 4px;
    border: 1px solid #e5e5e5;
    border-top: 0;
  }
  .size-vender {
    font-size:12px;
    font-family:PingFangSC-Medium,PingFang SC;
    font-weight:500;
    color:rgba(153,153,153,1);
    line-height:17px;
  }
  .proname {
    font-size:14px;
    font-family:PingFangSC-Medium,PingFang SC;
    font-weight:500;
    color:rgba(34,34,34,1);
    line-height:20px;
  }
  .main {
    background-color: #f5f5f5;

    .line {
      width: 1214px;
      height: 12px;
      background: #d9d9d9;
      box-shadow: 0 1px 0 0 #d1d1d1, 0 1px 3px 0 rgba(0, 0, 0, 0.3);
      border-radius: 6px;
      margin: 0 auto;
    }

    .content {
      width: 1200px;
      margin: -5px auto 0;
      padding: 20px 24px;
      display: flex;
      border-radius: 8px;
      background-color: #fff;
      align-items: center;

      .left {
        text-align: left;
        width: 280px;

        h4 {
          font-size: 20px;
          color: #222;
        }

        p {
          font-size: 14px;
          color: #717171;
          padding: 6px 0 13px;

          span {
            color: #FF3B30;
          }
        }

        /deep/ .el-button {
          padding: 7px 8px;

          &:nth-child(6) {
            margin-top: 10px;
          }
        }
      }

      .steps {
        width: 920px;
        flex: 1;

        /deep/ .el-steps {
          border-left: 1px solid #e5e5e5;
          justify-content: center;

          .el-step {
            flex-basis: 20% !important;
          }

          .el-step__icon {
            border: none;
            width: 20px;
            height: 20px;
            font-size: 14px;
            background: #CCCCCC;

            .el-step__icon-inner {
              color: #fff;
            }
          }

          .el-step__title {
            font-size: 14px;
            font-weight: 500;
            color: rgba(153, 153, 153, 1);
            line-height: 34px;
          }

          /*完成步骤*/
          .is-finish {
            .el-step__icon {
              .el-step__icon-inner {
                color: #ffffff;
              }
              color: #0971EB;
              background: #0971EB;
            }
            &.el-step__title {
              color: rgba(34, 34, 34, 1);
            }
          }
          .el-step__main {
            font-weight: 500;
            color: rgba(34, 34, 34, 1);
          }
          .el-step__line {
            margin: 0 28px !important;
            height: 1px;
          }
        }
      }
    }
    .content-header{
        width:912px;
        font-size: 14px;
        line-height: 40px;
        color: #262626;
        background: #F1F7FE;
        width: 100%;
        text-align: left;
    }
    .footer {
      width: 1200px;
      margin: 0 auto;
      font-size: 14px;
      display: flex;
      padding-bottom: 20px;
      padding-top:8px;
      .message {
        width: 280px;
        .msg {
          border-radius: 8px;
          background-color: #fff;
          text-align: left;
          padding: 8px;
          .header-msg {
            line-height: 40px;
            color: #262626;
            background: #F1F7FE;
            padding-left: 10px;
            margin-bottom: 10px;
          }

          .detail {
            padding: 18px 0 0 0;
            border-bottom: 1px solid rgba(229, 229, 229, 1);

            & > div {
              color: #999999;
              margin-bottom: 14px;
              display: flex;
            }

            b {
              width: 5em;
              text-align: right;
              display: inline-block;
              line-height: 1.4;
            }

            span {
              color: #2b2b2b;
              margin-left: 14px;
              flex: 1;
              line-height: 1.4;

              i {
                color: #FF3B30;
              }
            }

            &:last-child {
              border-bottom: none;
            }
          }

        }
      }
      .refund-detail{
        width:910px;
        margin-left:8px;
        background-color:white;
      }
      /deep/.el-table{
        .el-image{
        width:80px;
        height:80px;
       }
      }
      /deep/.el-form{
        .el-form-item__content{
          width:420px;
          font-size:14px;
          font-family:PingFangSC-Regular,PingFang SC;
          font-weight:400;
          color:rgba(34,34,34,1);
          line-height:20px;
        }
       .el-form-item__label{
         font-family:PingFangSC-Medium,PingFang SC;
         color:rgba(102,102,102,1);
         line-height:20px;
       }
       .el-image{
         margin-right:12px;
       }
      }
      /deep/ .el-form-item__label{
        font-family:PingFangSC-Medium,PingFang SC;
        color:rgba(102,102,102,1);
        line-height:20px;
      }
     /deep/ .el-step{
        .el-step__description.is-finish,.el-step__title.is-finish{
        color:#FA8C16;
      }
      .el-step__description.is-process,.el-step__description.is-wait{
        color:rgba(0,0,0,0.65);
     }
    }
  }

    .breadcrumb {
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      padding-left: 24px;
      width: 1200px;
      margin: 0 auto;

      /deep/ .el-breadcrumb {
        display: inline-block;
        line-height: 50px;
        float: left;
      }
    }
  }
</style>

<template>
  <div>
    <!-- 按钮区域 -->
    <div class="drawerSearch">
      <el-input v-model="searchForm.modeType" placeholder="输入模式名称" class="drawerInput" />
      <el-button
        type="primary"
        size="mini"
        class="but"
        @click="searchMode()"
        >搜索</el-button>
      <el-button
        type="text"
        size="mini"
        class="but"
        style="color:#595959"
        @click="resetMode()"
        >重置</el-button>
    </div>
    <!-- table -->
    <im-table-page
      ref="tablePage"
      :data.sync="tableData"
      index
      :remote-method="remote"
      >
      <el-table-column label="模式名称" prop="modeName" width="180" show-overflow-tooltip />
      <el-table-column label="模式类型" prop="modeTypeText" width="100" show-overflow-tooltip />
      <el-table-column label="价格类型" prop="priceTypeText" width="100" show-overflow-tooltip />
      <el-table-column label="状态" prop="isStopText" show-overflow-tooltip />
      <el-table-column label="操作" width="80" fixed="right">
        <template slot-scope="scope">
          <el-row class="table-edit-row">
            <el-button
              type="text"
              size="mini"
              @click="chooseModeSucc(scope.row)"
              >选择</el-button>
          </el-row>
        </template>
      </el-table-column>
    </im-table-page>
  </div>
</template>
<script>
import { pageImportModes } from '@/api/member-center/consigned-delivery'

export default {
  name: 'ChooseMode',
  data() {
    return {
      searchForm: {
        modeType: ''
      },
      modeTypeList: [{
        label: '单客户',
        value: 'SINGLE'
      },{
        label: '多客户',
        value: 'DOUBLE'
      }],
      modeTypeText: {
        'SINGLE': '单客户',
        'DOUBLE': '多客户'
      },
      upAndDownTypeList: [{
        label: '上游客户',
        value: 'UP'
      },{
        label: '下游客户',
        value: 'DOWN'
      }],
      upAndDownTypeText: {
        'UP': '上游客户',
        'DOWN': '下游客户'
      },
      priceTypeList: [{
        label: '平台价',
        value: 'PLATFORM'
      },{
        label: '招商价',
        value: 'INVESTMENT'
      }],
      priceTypeText: {
        'PLATFORM': '平台价',
        'INVESTMENT': '招商价'
      },
      isStopText: {
        'Y': '未冻结',
        'N': '已冻结'
      },
      tableData: []
    }
  },
  mounted() {
  },
  methods: {
    searchMode() {
      if (this.loadiing) return
      this.refresh()
    },
    resetMode() {
      this.searchForm = {
        modeType: ''
      }
    },
    refresh(r = false) {
      const p = r ? this.$refs.tablePage.currentPage : 0
      this.$refs.tablePage.doRefresh({ currentPage: p, pageSize: 10 })
    },
    chooseModeSucc(data) {
      this.$emit('chooseModeSucc', data)
    },
    remote(pageParam) {
      return pageImportModes({
        ...pageParam,
        condition: {
          modeName: this.searchForm.modeType,
          isStop: 'Y'
        }
      }).then(res => {
        const { data } = res
        let records = data.records
        if (data && records) {
          records.forEach(item => {
            item.modeTypeText = this.modeTypeText[item.modeType]
            item.upAndDownTypeText = this.upAndDownTypeText[item.upAndDownType]
            item.priceTypeText = this.priceTypeText[item.priceType]
            item.isStopText = this.isStopText[item.isStop]
          })
        }
        return res
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-item-box {
  padding: 16px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.drawerSearch {
  display: flex;
  padding: 15px 15px 8px 15px;
  .but{
    height: 28px;
    margin-top: 2px;
  }
  .drawerInput{
    width: 200px;
    margin-right: 15px;
    /deep/ .el-input__inner{
      height: 32px;
    }
  }
}
.el-form-item.is-error {
  .form-item-box {
    border-color: #ff4949;
  }
}
</style>

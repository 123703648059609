<template>
  <el-popover
    width="1315"
    placement="bottom-start"
    v-model="inputText"
    trigger="manual">
    <el-table
      :data="gridData"
      max-height="330"
      :id="'fosTable' + index"
      ref="fosTable"
      class="tableSearch"
      @row-click="rowClick"
      :row-class-name="rowClassName"
      :row-style="rowStyle"
      border>
      <el-table-column align="center" width="60" type="index" label="序号"></el-table-column>
      <el-table-column align="center" width="80" property="productCode" label="商品编码"></el-table-column>
      <el-table-column width="250" property="productNm" label="商品名称">
        <template slot-scope="scope">
          <div class="productNm">
            <span class="otcType" v-if="scope.row.otcType && scope.row.otcType!=='其他'" :class="{ 'jiaOtc': scope.row.otcType === '甲OTC', 'yiOtc': scope.row.otcType === '乙OTC', 'rxOtc': scope.row.otcType === 'RX' }">
              {{scope.row.otcType}}
            </span>
            <p>
              {{$util.getGoodsName(scope.row)}}
            </p>
          </div>
          <div>
          <tagList :taglist='scope.row.tagList'></tagList>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="userType =='SALESMAN'"
          label="标识"
          min-width="80px"
          align="center">
          <template slot-scope="scope">
              <div class="identifying">
                  <img v-if="scope.row.whiteRecordFlag  == 1" alt src="@/assets/img/icon/whiterecord.png">
                  <img v-else-if="scope.row.blackRecordFlag  == 1" alt src="@/assets/img/icon/blackrecord.png">
              </div>
          </template>
      </el-table-column>
      <el-table-column width="130" property="spec" label="规格"></el-table-column>
      <el-table-column width="190" property="manufacturer" label="厂家(上市许可持有人)/产地">
        <template slot-scope="scope">
          {{scope.row.manufacturer}}{{scope.row.area ? '/' + scope.row.area : ''}}
        </template>
      </el-table-column>
      <el-table-column min-width="50" property="stockQuantityStatus" label="库存">
        <template slot-scope="scope">
          <span :style="{color: scope.row.stockQuantity > 100 ? '': '#E02020'}">{{isSalesman? scope.row.stockQuantity : scope.row.stockQuantity > 100 ? '>100' : '<100'}}</span>
        </template>
      </el-table-column>
      <el-table-column align="right" width="90" property="buyersPrice" label="销售价">
        <template slot-scope="scope">
          <span style="color: #FF5200">{{$util.getPriceByZero(scope.row.buyersPrice,scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column align="right" width="90" property="assessmentPrice" label="考核价" v-if="isSalesman">
        <template slot-scope="scope">
          <span style="color: #FF5200">¥{{scope.row.assessmentPrice}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" width="65" property="packTotal" label="件包装">
        <template slot-scope="scope">
          <span>{{scope.row.packTotal}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" width="60" property="rate" label="销项税">
        <template slot-scope="scope">
          <span>{{scope.row.rate}}</span>
        </template>
      </el-table-column>
      <!--<el-table-column align="right" width="90" property="medicarePrice" label="医保价">
        <template slot-scope="scope">
          <span style="color: #FF5200">¥{{scope.row.medicarePrice}}</span>
        </template>
      </el-table-column>
      <el-table-column align="right" width="90" property="retailPrice" label="零售价">
        <template slot-scope="scope">
          <span style="color: #FF5200">¥{{scope.row.retailPrice}}</span>
        </template>
      </el-table-column> -->
      <el-table-column width="100" property="step" label="最小购买倍数"></el-table-column>
    </el-table>
    <el-input
      slot="reference"
      v-model="searchText"
      :id="'inputFocus' + index"
      :key="'input' + index"
      @input="getText"
      @keyup.up.native.stop.prevent="up"
      @keyup.down.native.stop.prevent="down"
      @keyup.enter.native.stop.prevent="enter"
      placeholder="请输入商品名称|编码"
      @blur="blur"
      style="height: 32px"
      @focus="validateCounts"
     >
    </el-input>
  </el-popover>
</template>

<script>
import tagList from '@/components/tagList/tagList'
import { getMedicineList } from '@/api/fast-order'
import { mapGetters } from 'vuex'
import addToCartMixin from '@/common/addToCart'

export default {
  name: "fastOrderSearch",
  mixins:[addToCartMixin],
  components:{
    tagList
  },
  computed: {
    ...mapGetters(['accountId'])
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    productNm: {
      type: String,
      default: ''
    },
    isSalesman: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      gridData: [],
      inputText: false,
      searchText: this.productNm,
      selectedPro: {},
      chooseIndex: 0
    }
  },
  watch: {
    productNm: function (val) {
      this.searchText = val
    },
  },
  mounted(){
    this.$nextTick(()=>{
      window.addEventListener('click',this.clickDocument)
      this.tableBody = this.$refs['fosTable'].$el.getElementsByClassName('el-table__body-wrapper')[0]
    })
  },
  destroyed() {
    window.removeEventListener('click',this.clickDocument)
  },
  methods: {
    changeIndex(){
      let table = this.tableBody.getElementsByTagName('table')[0]
      let tbody = table.getElementsByTagName('tbody')[0]
      let tr = tbody.getElementsByTagName('tr')
      let scroll = 0;
      tr.forEach((item,index)=>{
        if(index < this.chooseIndex - 2){
          scroll += item.offsetHeight
        }
      })

      // console.log(scroll)
      this.tableBody.scrollTop = scroll
    },
    clickDocument(){
      this.inputText = false
      this.gridData = []
      this.chooseIndex = 0
    },
     // 表格方法 --- 暂时不需要上下选择选中目标
     rowClassName(row) {
       if(row.rowIndex === this.chooseIndex){
        return 'order-row'
       }else {
        return ''
       }
    },
    rowStyle({ row, rowIndex }) {
      Object.defineProperty(row, "rowIndex", { // 给每行添加不可枚举属性rowIndex来标识当前行
        value: rowIndex,
        writable: true,
        enumerable: false
      });
    },
    // 在商品名称的输入框 按tab键切换下一行
    validateCounts() {
      this.$emit("fast_order_tab", "tab")
    },
    search(value) {
     if(this.accountId){
       let medicName = this.searchText
       this.inputText = false
       this.gridData = []
       if(medicName){
         getMedicineList({
           searchFiled : medicName
         }).then(res => {
           this.inputText = true
           this.$emit("getIndex", this.index)
           res.data.forEach(item => {
             item.step = this.getRealMinBuyNum(item)
           })
           this.gridData = res.data
         })
       }
     }else{
       this.$message.warning('请选择客户')
     }

    },
    up(){
      if(this.chooseIndex > 0){
        this.chooseIndex--
        if(this.chooseIndex === 0){
          this.chooseIndex = 0
        }
      }
      console.log(this.chooseIndex)
      this.changeIndex()
    },
    down(){
      if(this.chooseIndex < this.gridData.length){
        this.chooseIndex++
        if(this.chooseIndex === this.gridData.length){
          this.chooseIndex = 0
        }
      }
      console.log(this.chooseIndex)
      this.changeIndex()
    },
    enter(){
      if(this.gridData.length > 0){
        this.selectedPro = this.gridData[this.chooseIndex]
        this.searchText = this.productNm
        this.chooseIndex = 0
        this.$emit('fast_order_fu', this.selectedPro, this.index)
        this.inputText = false
      }
    },
    getText(value){
      if(value){
        this.search(value)
      } else {
        this.inputText = false
      }
    },
    rowClick(row, event, column) {
      this.selectedPro = row
      this.searchText = this.productNm
      this.$emit('fast_order_fu', this.selectedPro, this.index)
      this.inputText = false
    },
    closePopover(){
      this.inputText = false
    },
    blur(){
      // this.inputText = false
      // this.gridData = []
      // this.chooseIndex = 0
    },
    getFocus(){
      let inputNum= document.getElementById('inputFocus' + this.index)
      inputNum.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
    .jiaOtc{
      background-color: #E60012 !important;
    }
    .yiOtc{
      background-color: #33AE67 !important;
    }
    .rxOtc{
      background-color: #0091ff !important;
    }
  .tableSearch {
    /deep/ .el-table__body-wrapper  .order-row td{
        background-color: $hoverBg;
    }
  }




  /* .tableSearch{
    /deep/ .el-table {
    .order-row {
      color: red;
      td{
        background: #F1F7FE;
        padding: 0px 0px;
      }
    }
  } */

  .changeChose {
    background-color: rgba(2, 92, 214, 0.1);
  }
  /deep/ .el-input__inner {
    padding: 0 8px;
    font-size: 12px;
    height: 30px;
  }
.tableSearch {
  /deep/.cell {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
  }
  font-size: 12px;
  .productNm{
    color: #262626;
    font-weight: 700;
    font-size: 14px;
    .otcType {
      background-color: red;
      font-size: 14px;
      color: #FFFFFF;
      border-radius: 5px;
      padding: 0 2px;
      margin-right: 2px;
      font-weight: 500;
    }
  }
  .isCashTransaction {
    background-color: rgba(2, 92, 214, 0.1);
    color: #025CD6;
    font-size: 12px;
    border-radius: 5px;
    padding: 1px 4px;
    margin-right: 8px;
  }

  // 历史购买
  .isminBuyMultiple{
    background-color: rgba(255, 102, 0, 0.1);
    color: #FF6600;
    font-size: 12px;
    border-radius: 5px;
    padding: 1px 4px;
    margin-right: 8px;
  }
  .identifying {
      display: inline-block;
      width: 32px;
      height: 32px;
      img {
          width: 100%;
          height: 100%;
      }
  }
}
</style>

import { addItem } from '@/api/detail'
import {registerGood} from "@/api/shop-cart";
import {mapGetters} from "vuex";

export default {
    data(){
        return {
            addToCartLoading: false
        }
    },
    computed:{
        ...mapGetters(["buyersVo","userDetail","org",'userType','license']),
        //是否显示证照过期，不允许加购
        isLicenseOutOfTime(){
            // let licenseList = this.license.list || []
            // //规则1    与经营范围相关 且 过期 且为严禁
            // let arr1 = licenseList.filter(item => item.reminderType === 'STRICTLY' && item.status === '已过期' && item.isRelatedBusinessRanges === 'Y')
            // //规则2     与经营范围不相关，且过期
            // let arr2 = licenseList.filter(item => item.isRelatedBusinessRanges === 'N' && item.status === '已过期')
            //
            // return (!!arr1.length || !!arr2.length) && this.userType !== 'SALESMAN'

            // return this.license.isAllLicenseExpired === 'Y' && this.userType !== 'SALESMAN'
            return this.license.isAllLicenseExpired === 'Y'
        },
        //是否禁止加购
        isBanAddToCart(){
            // return this.license.isRealFailure === 'Y' && this.userType !== 'SALESMAN'
            console.log("isBanAddToCart license:", this.license)
            let flag = this.license.isRealFailure === 'Y' || this.license.isAllLicenseExpired === 'Y'
            console.log("isBanAddToCart flag:", flag)
            return this.license.isRealFailure === 'Y' || this.license.isAllLicenseExpired === 'Y'
        }
    },
    created() {
      // console.log({buyersVo:this.buyersVo})
      // console.log({userDetail:this.userDetail})
      // console.log({org:this.org})
      // console.log({license:this.license})
      //   console.log(this.isLicenseOutOfTime)
    },
    methods:{
        /*
        * 加购小数点数量
        * */
        getDecimalPointNum(row){
            let {minBuyDecimals} = row
            minBuyDecimals = minBuyDecimals || 1
            let str = String(minBuyDecimals)
            let arr = str.split('.')
            if(arr.length > 1){
                return arr[1].length
            }else {
                return 0
            }
        },

        /*
        * 判断商品是否是禁止购买
        * */
        checkProductIsDisabled(row){

        },

        /*
        * 获取真正的！加购倍数 - -!
        * Object row 商品数据
        * row -> {minBUyDecimals:最小购买数量,minBuyMultiple:最小购买倍数,isMinOrder:是否按照起订量购买,minOrderQuantity:起订量}
        * */
        getRealMinBuyNum(row){
            let {minBuyDecimals, minBuyMultiple,isMinOrder,minOrderQuantity} = row

            if(minBuyMultiple === '-/-'){
                return minBuyMultiple
            }
            //避免数据为空时报错，默认给个1
            minBuyDecimals = Number(minBuyDecimals) || 1
            minBuyMultiple = Number(minBuyMultiple) || 1
            minOrderQuantity = Number(minOrderQuantity) || 1

            let step = 0;
            if(isMinOrder === 'Y' && minOrderQuantity){ //促销活动起订量开启时，以起订量为最小购买倍数
                step = minOrderQuantity || 1
            }else {
                step = minBuyMultiple || 1
            }
            return step * minBuyDecimals   //真实购买倍数为 最小购买数量 × 最小购买倍数
        },

        /*
        * 加入购物车前的操作
        * */
        beforeAddItem(objId, itemType, quantity, row){
            if(!this.$util.isLogin()){
                this.$router.push({
                    path: '/login'
                })
                this.$message.warning('请先登录')
                return
            }
            if(row.isSkuInfoRed === 'Y'){
                this.$confirm('此商品是近效期品种，是否继续？','提示',{
                    confirmButtonText:'加入购物车',
                    type:'warning'
                }).then(res=>{
                    this.addItem(objId, itemType, quantity, row)
                })
            }else {
                this.addItem(objId, itemType, quantity, row)
            }
        },
        /*
        * 加入购物车
        * Number quantity 加购数量
        * Object row 商品数据
        * */
        addItem(objId, itemType, quantity, row = {}) {

            // this.checkOutOfStock(row,quantity)

            if(row){
                row.loading = true
            }

            this.addToCartloading = true

            addItem({objId, itemType, quantity, skuIdList: []}).then(res => {
                const {code} = res
                if (code === '0') {
                    this.$message.success('加入成功！')
                    this.$store.dispatch('user/setCartNum', res.data.allProductTypeTotalQuantity)
                }else if(code === '3'){
                    if(quantity > row.stockQuantity || /库存不足/.test(res.msg)){
                        this.checkOutOfStock(row,quantity)
                    }else {
                        if(res.msg){
                            this.$message.error(res.msg)
                        }
                    }
                }else {
                    if(res.msg){
                        this.$message.error(res.msg)
                    }
                }

                if(row){
                    row.loading = false
                }
                this.addToCartloading = false
            }).catch(err => {
                if(row){
                    row.loading = false
                }

                if(err.code === '3'){
                    if(quantity > row.stockQuantity || /库存不足/.test(err.msg)){
                        this.checkOutOfStock(row,quantity)
                    }else {
                        if(err.msg){
                            this.$message.error(err.msg)
                        }
                    }
                }else {
                    if(err.msg){
                        this.$message.error(err.msg)
                    }
                }
                this.addToCartloading = false
            })
        },

        /*
        * 立即购买
        * Object row 商品数据
        * Number quantity 加购数量
        * */
        buyNow (row = {},quantity) {
            // this.checkOutOfStock(row,quantity)

            addItem({
                objId: row.skuId,
                itemType: 'NORMAL',
                quantity,
            }).then((res) => {
                const { code } = res
                if (code === '0') {
                    this.$message.success('加入成功！')
                    this.$store.dispatch(
                        'user/setCartNum',
                        res.data.allProductTypeTotalQuantity
                    )
                    this.$router.push('/shopping-cart')
                }else if(code === '3'){
                    if(quantity > row.stockQuantity || /库存不足/.test(res.msg)){
                        this.checkOutOfStock(row,quantity)
                    }else {
                        if(res.msg){
                            this.$message.error(res.msg)
                        }
                    }
                }else {
                    if(res.msg){
                        this.$message.error(res.msg)
                    }
                }
            }).catch(err => {
                if(err.code === '3'){
                    if(quantity > row.stockQuantity || /库存不足/.test(err.msg)){
                        this.checkOutOfStock(row,quantity)
                    }else {
                        if(err.msg){
                            this.$message.error(err.msg)
                        }
                    }
                }else {
                    if(err.msg){
                        this.$message.error(err.msg)
                    }
                }
            })
        },

        /*
        * 检测是否缺货
        * */
        checkOutOfStock(row,quantity){
            this.$confirm(`您好，你购买的商品${row.productCode}数量大于商品库存，是否需要进行缺货登记？`,'温馨提示',{
                type:'warning'
            }).then(res=>{
                this.registerGoods(row,quantity).then(res=>{
                    this.$message.success(`商品${row.productCode}缺货登记成功`)
                })
            }).catch(()=>{})
        },
        /*
        * 直接点击缺货登记
        * */
        handleRegisterGoods(row,quantity){
            console.log(row)
            let html =  `<div>即将对商品` +
                            `<span style="color:#1CB7CD">${row.productCode}</span>` + `发起缺货登记，需求采购数为 ` +
                            `<span style="color:#F56C6C">${quantity}</span> ?` +
                        `</div>` +
                        `<div>运营人员可能依据采购数作参考进行补货</div>`
            this.$confirm(html,'请确认',{
                type:'warning',
                dangerouslyUseHTMLString: true
            }).then(res=>{
                this.registerGoods(row,quantity).then(res=>{
                    this.$message.success(`商品${row.productCode}缺货登记成功`)
                })
            }).catch(err=>{})
        },
        /*
        * 缺货登记
        * */
        registerGoods(row = {},quantity = 1){
            let {buyersId,buyersNo,buyersNm} = this.buyersVo
            let {orgId} = this.org
            let {drugNm,productId,productNm,productCode,stockQuantity,spec,unit} = row
            let data = {
                buyersId,
                buyersCode: buyersNo,
                buyersName: buyersNm,
                buyersPhone: this.userDetail.userMobile,
                orgId: orgId,
                enquiryNumber: quantity,
                productGeneralName: drugNm,
                productId,
                productName:productNm,
                productCode,
                stock: stockQuantity,
                spec,
                unit
            }
            return new Promise((resolve,reject)=>{
                registerGood(data).then(res => {
                    const { code } = res
                    if (code === '0') {
                        //缺货登记成功
                        resolve(res)
                    }else {
                        //缺货登记失败
                        reject && reject(res)
                    }
                }).catch(err=>{
                    //缺货登记失败
                    reject && reject(err)
                })
            })
        },
    }
}

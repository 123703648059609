<template>
    <el-form ref="form" :model="form" :rules="formRules" label-width="0px" class="forget-password-from">
      <el-form-item prop="loginId">
        <el-input v-model="form.loginId" placeholder="输入登录账号" :disabled="!!$util.isLogin()" class="registerInput"></el-input>
        <div class="input-icon">
          <img src="@/assets/img/register/icon-user.png" class="img-user" alt/>
        </div>
      </el-form-item>
      <el-form-item prop="loginMobileId">
        <el-input v-model="form.loginMobileId" placeholder="输入手机号码" :disabled="!!$util.isLogin()" type="tel" maxlength="11"></el-input>
        <div class="input-icon">
          <img src="@/assets/img/register/phone.png" class="img-shouji" alt/>
        </div>
      </el-form-item>
      <el-form-item prop="code">
        <el-input v-model="form.code" placeholder="输入验证码" @keyup.enter.native="next"></el-input>
        <div class="input-icon">
          <img src="@/assets/img/register/dun.png" class="img-suo" alt/>
        </div>
        <div class="zp-code">
          <el-button v-show="!secondShow" type="text" class="btn-change-code" :disabled="!disRandom" @click="getRandom">获取短信验证码</el-button>
          <el-button v-show="secondShow" type="text" class="btn-change-code" :disabled="true">{{ second }}秒后可重新获取</el-button>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button @click="next()" class="sureBut" type="primary">下一步</el-button>
        <div class="checkFrom"><span class="checkSpan" style="margin-left: 10px">没有注册手机号？<a @click="openAgreement" class="kf">联系客服</a></span></div>
      </el-form-item>
      <el-dialog :visible.sync="dialogVisible">
        <div slot="title" class="agreementDialog">
          提示
        </div>
        <p style="line-height: 24px">客服联系电话：<span class="kfPhone">{{ params.serviceTel }}</span></p>
        <el-button @click="agree()" class="agree" size="medium">返回</el-button>
      </el-dialog>
    </el-form>
</template>

<script>
import formRules from '@/utils/validate'
import { phoneVerifCode,getInfo } from '@/api/user'
import { saveFrontVerification } from '@/api/login/forget-password'
import { getSmsAccountInfState } from '@/api/user'
import { mapGetters } from 'vuex'
export default {
  name: 'AccountInfor',
  props: {
    params: {
      type: Object,
      default: {}
    },
  },
  data () {
    return {
      second: 59,
      secondShow: false,
      form:{
        loginId: '',
        loginMobileId: '',
        code: '',
      },
      formRules: {
        loginId: [{ required: true, trigger: 'blur', message: '登录账号不能为空' }],
        loginMobileId: [formRules.phone],
        code: [{ required: true, trigger: 'blur', message: '验证码不能为空' }]
      },
      codeBaseUrl: `${process.env.VUE_APP_BASE_API}/code`,
      random: new Date().getTime(),
      dialogVisible: false
    }
  },
  computed: {
    ...mapGetters(['userDetail']),
    codeUrl () {
      return `${this.codeBaseUrl}?randomStr=${this.random}`
    },
    disRandom() {
      return /^1[3456789]\d{9}$/.test(this.form.loginMobileId)
    }
  },
  mounted(){
    this.inint();
  },
  methods: {
    inint(){
      this.form.loginId = sessionStorage.getItem('loginId')
      this.form.loginMobileId = sessionStorage.getItem('userMobile')
    },
    next() {
      this.$refs.form.validate(valid => {
        if (valid) {
          saveFrontVerification({...this.form}).then(res => {
            let param = this.form.loginId
            this.$emit('next', 2, param)
          })
        }
      })
    },
    getRandom () {
      getSmsAccountInfState().then(vas => {
        if (vas.data.state === 'Y') {
          phoneVerifCode(this.form.loginMobileId).then(res => {
            this.secondShow = true
            const setInter = setInterval(() => {
              --this.second
              if (this.second < 0) {
                this.second = 59
                this.secondShow = false
                clearInterval(setInter)
              }
            }, 1000)
          })
        } else {
          this.$message.error('短信发送已关闭')
        }
      })
    },
    openAgreement() {
      this.dialogVisible = true
    },
    agree() {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.kfPhone{
  @include TextHighlightColor;
}
.forget-password-from {
  width: 400px;
  margin: 40px auto 25px;
  /deep/ .el-input__inner{
    padding-left: 45px;
  }
  /deep/ .el-dialog__header {
    padding: 0px;
  }
  /deep/ .el-dialog__headerbtn {
    top: 10px;
  }
  input{
    padding-left: 48px;
  }
  .input-icon {
    position: absolute;
    left: 12px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    .img-user {
      width: 14px;
      height: 16px;
    }
    .img-shouji {
      width: 17px;
      height: 16px;
    }
    .img-suo {
      width: 16px;
      height: 16px;
    }
  }
  .zp-code {
    position: absolute;
    top: 0px;
    bottom: 10px;
    right: 15px;
    display: flex;
    .btn-change-code{
      @include TextHighlightColor
    }
    .img-container {
      background-color: #fff;
      height: 28px;
      width: 90px;
      margin-top: 4px;
      img {
        width: 72px;
        height: 32px;
      }
    }
  }
  .checkSpan {
    margin-left: 10px;
    font-size: 12px;
    @include TextGrayColor;
    a {
      @include TextHighlightColor
    }
  }
  .checkFrom{
    margin-bottom: 10px;
    text-align: right;
  }
  .agreementDialog {
    height: 36px;
    text-align: left;
    line-height: 36px;
    background-color: #F5F5F5;
    font-size: 16px;
    color: #222222;
    width: 100%;
    padding-left: 20px;
  }
  .agree {
    padding: 0 28px;
    height: 28px;
    margin-top: 20px;
    font-size:12px;
  }
  .sureBut {
    width: 400px;
    height: 40px;
    @include NormalBtnColor;
  }
}
</style>
<style lang="scss">
  .forget-password-from{
    .el-dialog{
      width: 500px;
      .el-dialog__body{
        padding: 50px 20px;
      }
    }
  }
</style>

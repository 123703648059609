import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import router from '@/router'
import { getToken, getBus } from '@/utils/auth'
import defaultSettings from '@/settings'
import Fingerprint2 from 'fingerprintjs2'

// 获取浏览器的唯一标识符
Fingerprint2.get((components) => {
  // 参数只有回调函数时，默认浏览器指纹依据所有配置信息进行生成
  const values = components.map((component) => component.value) // 配置的值的数组
  let murmur = Fingerprint2.x64hash128(values.join(''), 31) // 生成浏览器指纹
  // console.log(components, values,murmur,'你好')
  //murmur就是浏览器指纹啦
  localStorage.setItem('browserId', murmur);
})

let requestUrl = ''

const $message = Message;

let messageFlag = false

const NO_CANCEL_URLS = [`/${defaultSettings.businessPre}/district/listByParentCode`,`/${defaultSettings.businessPre}/pc/order/pageOrder`]


const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 100000,
  'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
})

let pending = []
const CancelToken = axios.CancelToken
const requsetPending = (config) => {
  if (NO_CANCEL_URLS.includes(config.url)) {
    return
  }
  for (const i in pending) {
    if (pending[i].url === config.url && !config.notCancelXhr) {
      // console.log('cancel url:', config.url)
      pending[i].f()
    }
  }
}
const responsePending = (config) => {
  for (const i in pending) {
    if (pending[i].url === config.url) {
      pending.splice(i, 1)
    }
  }
}

service.interceptors.request.use(
  config => {
    requsetPending(config)
    config.cancelToken = new CancelToken(function executor(c) {
      pending.push({
        url: config.url,
        f: c
      })
    })
    if (!config.headers || !config.headers['Client-API-Version']) {
      config.headers['Client-API-Version'] = 1
    }

    requestUrl = config.url

    if (config.url.substring(0,config.url.indexOf("?")) !== '/businessServiceYtyy/payment/front/alipay/receive') {
      if (store.getters.token) {
        config.headers['x-access-token-header'] = getToken()
      } else if (getBus()) {
        config.headers['x-front-org-id-header'] = getBus().orgId
      }
    }
    config.headers['Equipment'] = 'PC'
    config.headers['Equipment-no'] = localStorage.getItem('browserId')

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(

  response => {
    if (response.config.responseType === 'arraybuffer') {
      return response
    }
    if (response.config.responseType === 'blob') {
      return response
    }
    responsePending(response.config)
    const res = response.data

    if (res.code !== '0') {
      if (!messageFlag && !response.config.ignoreMsg) {
        messageFlag = true
        let message = res.msg || 'Error'
        if (message === 'GENERAL') message = '服务器维护中，请稍后再试'
        if (message === 'SHORTCIRCUIT') message = '网站更新中，请稍后再试'
        $message({
          message,
          type: 'error',
          duration: 5 * 1000,
          onClose: () => {
            messageFlag = false
          }
        })
      }

      if (res.msg === '登录超时' || res.code === '401' || res.msg === '无该用户信息') {
        store.dispatch('user/logout')
        sessionStorage.haveAccount = false
        if(router.currentRoute.path !== '/login'){
          router.push(`/login`)
        }
      }
      return Promise.reject(res)
    } else {
      const token = response.headers['x-access-token-header']
      token && store.dispatch('user/toSetToken', token)
      return res
    }
  },
  error => {
    // debugger;
    console.log('request.js->error:', JSON.stringify(error), ' url:',  requestUrl)
    const err = {}
    try{
      err = error.response.data ? error.response.data : error.response
    }catch{
      err.msg = 'Exception'

    }
    
    
    if (!messageFlag) {
      messageFlag = true
      let message = err.msg || 'Error'
      if (message === 'GENERAL') message = '服务器维护中，请稍后再试'


      if(message != 'Exception'){
        $message({
          message,
          type: 'error',
          duration: 5 * 1000,
          onClose: () => {
            messageFlag = false
          }
        })
      }
      
    }
    return Promise.reject(error)
  }
)

//清空所有请求，退出账号时调用
export const cancelAllApi = ()=>{
  pending.forEach((item,index)=>{
    item.f()
  })
  pending = []
}
export default service

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "im-page-modal",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "index-analysis",
      attrs: { title: "指标分析", isShowBack: false },
    },
    [
      _c("div", { staticClass: "changeYear" }, [
        _c("div", { staticStyle: { width: "120px" } }, [
          _c("span", { staticClass: "year" }, [
            _vm._v(_vm._s(_vm.nowYear) + "年"),
          ]),
          _c(
            "div",
            {
              staticClass: "addYear",
              style: _vm.active1,
              on: {
                click: _vm.decYear,
                mouseover: function ($event) {
                  return _vm.mouseOver("dec")
                },
                mouseleave: function ($event) {
                  return _vm.mouseLeave("dec")
                },
              },
            },
            [_c("i", { staticClass: "el-icon-arrow-left lefticon" })]
          ),
          _c(
            "div",
            {
              staticClass: "addYear",
              style: _vm.active2,
              on: {
                click: _vm.addYear,
                mouseover: function ($event) {
                  return _vm.mouseOver("add")
                },
                mouseleave: function ($event) {
                  return _vm.mouseLeave("add")
                },
              },
            },
            [_c("i", { staticClass: "el-icon-arrow-right lefticon" })]
          ),
        ]),
      ]),
      _c(
        "el-table",
        {
          staticClass: "tableList",
          attrs: {
            data: _vm.sale_list,
            border: "",
            stripe: "",
            "show-summary": "",
            "summary-method": _vm.getTotal,
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "saleTime", label: "时间", width: "60" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "saleMoney",
              align: "right",
              label: "销售金额",
              width: "130",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$util.toFixed(scope.row.saleMoney)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "grossProfit", label: "毛利", width: "130" },
          }),
          _c("el-table-column", {
            attrs: { prop: "grossProfitMargin", label: "毛利率", width: "80" },
          }),
          _c("el-table-column", {
            attrs: { prop: "returnMoney", align: "right", label: "回款金额" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$util.toFixed(scope.row.returnMoney)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="member-center-home">
    <div v-if="index_YW">
      <div class="header">
        <template v-if="!editSign">
          <p class="sign" @click="handleEditSignBol">
            <i class="el-icon-edit"></i>
            <span class="sign-msg">{{ userSignName.signature ? userSignName.signature :'点击编辑文字设置自己的签名'}}</span>
          </p>
          <p class="login-msg">您是第{{loginCount}}次登录，您上一次登录：{{loginLastTime}}</p>
        </template>
        <p v-else class="sign">
          <el-input
              class="sign-input"
              placeholder="请输入签名"
              v-model="userSignName.signature"
              clearable
              :autofocus="autofocus"
              @blur="handleEditSign"
          >
            <i slot="prefix" class="el-icon-edit"></i>
          </el-input>
        </p>
      </div>
      <div class="member">
        <div class="member-info">
          <div class="portrait">
            <img src="@/assets/img/member-center/portrait.png" alt>
          </div>
          <div class="member-content">
            <div class="merchant-name">{{buyersVo.buyersNm}}</div>
            <div class="type">
              <span>会员类型：{{userDetail.memberDetailsVo.title}}</span>
              <span>客户类型：{{userDetail.memberDetailsVo.customerType}}</span>
            </div>
          </div>
        </div>
        <ul class="member-boxs">
          <li class="box">
            <router-link to='/member-center/property-manage/my-coupon'>
              <div class="number">{{userDetail.memberDetailsVo.couponsNum || 0}}<span>张</span></div>
              <p>优惠券</p>
           </router-link>
          </li>
          <li class="box">
            <router-link to='/member-center/property-manage/my-integral'>
              <div class="number">{{userDetail.memberDetailsVo.integral || 0}}<span>分</span></div>
              <p>积分</p>
            </router-link>
          </li>
          <li class="box" v-if="userDetail.memberDetailsVo.isCredit === 'Y'">
            <div class="number money">{{userDetail.memberDetailsVo.availableCredit || 0}}<span>元</span></div>
            <p>剩余可用额度</p>
            <span class="erpTag" v-if="userDetail.isOverdue ==='Y'">账期逾期</span>
          </li>
        </ul>
      </div>
      <div class="member-main">
        <div class="about-order">
          <div class="my-order">
            <h4 class="module-title">我的订单</h4>
            <div class="my-oder-boxs">
              <div class="order-box" @click="jumpOrder('WAIT_PAY')">
                <div class="order-icon unpaid">
                  <span class="badge">{{userDetail.orderStateVos.toBePaid || 0}}</span>
                </div>
                <p>待支付</p>
              </div>
              <div class="order-box" @click="jumpOrder('WAIT_SEND')">
                <div class="order-icon library">
                  <span class="badge">{{userDetail.orderStateVos.toBeDelivered || 0}}</span>
                </div>
                <p>待发货</p>
              </div>
              <div class="order-box" @click="jumpOrder('SEND')">
                <div class="order-icon receive">
                  <span class="badge">{{userDetail.orderStateVos.toBeReceived || 0}}</span>
                </div>
                <p>待收货</p>
              </div>
              <div class="order-box" @click="jumpOrder('退货/退款')">
                <div class="order-icon return">
                  <span class="badge">{{userDetail.orderStateVos.returnGoodes || 0}}</span>
                </div>
                <p>退货/退款</p>
              </div>
              <div class="order-box" @click="jumpOrder('')">
                <div class="order-icon all-order"></div>
                <p>全部订单</p>
              </div>
            </div>
          </div>
          <order-list ref="orderList" :remote-method="queryData" :time-value.sync="timeValue" :page-number="5"
                      :pager-count="5" :data.sync="tableData"/>
        </div>
        <div class="aside-right">
          <div class="about-account">
            <h4 class="module-title">账户相关</h4>
            <ul class="account-main">
              <li class="acccount-item">
                <router-link to='/member-center/transactions-using/procurement-plan'>
                  <span class="account-icon plan"></span>采购计划
                  <el-divider direction="vertical"></el-divider>
                  共有<span class="value">{{userDetail.memberDetailsVo.procurementPlan}}</span>套模板
                </router-link>
              </li>
              <li class="acccount-item">
                <router-link to='/member-center/transactions-using/good-collection'>
                  <span class="account-icon collect"></span>我的收藏
                  <el-divider direction="vertical"></el-divider>
                  共有<span class="value">{{userDetail.memberDetailsVo.myCollection}}</span>种品规
                </router-link>
              </li>
              <li class="acccount-item">
                <router-link to='/member-center/transactions-using/my-salesman'>
                  <span class="account-icon salesman"></span>我的业务员
                  <el-divider direction="vertical"></el-divider>
                  共有<span class="value">{{userDetail.memberDetailsVo.mySalesman}}</span>位业务员
                </router-link>
              </li>
            </ul>
          </div>
          <div class="browsing-history">
            <h4 class="module-title">浏览记录</h4>
            <ul class="record-container">
              <router-link :to="{path:`/detail/` + item.productId}" v-for="(item,index) in historyList" :key="index"
                           v-show="index < 6">
                <li class="record">
                  <img alt :src="item.picUrl || productDefaultImg" @error="item.picUrl = productDefaultImg">
                  <p>{{item.productNm}}</p>
                </li>
              </router-link>
              <!-- <li class="record" v-for="(item,index) in historyList" :key="index" v-show="index < 6" >
                 <img alt :src="item.picUrl || productDefaultImg" @error="item.picUrl = productDefaultImg">
                <p>{{item.productNm}}</p>
                </li> -->
            </ul>
          </div>
        </div>
      </div>
      <swiper :slides-per-view="3" class="recommend-swiper" type="recommendFor"></swiper>
    </div>
    <div v-else>
      <YWindex></YWindex>
    </div>
  </div>
</template>

<script>
  import ProDuct from '@/assets/img/member-center/product.png'
  import OrderList from '../order-manage/store-order/components/order-list'
  import {
    memberDetails,
    getOrderNum,
    getByUserIdLast,
    getUserSignature,
    saveUserSignature
  } from '@/api/member-center'
  import Swiper from '@/components/ForRcommend'
  import { pageOrder } from '@/api/member-center/order-manage/order-list'
  import { parseTime } from '@/utils'
  import { getBus } from '@/utils/auth'
  import productDefaultImg from '@/assets/img/index/product_default.png'
  import { getBrowsingHistory } from '@/api/detail'
  import { mapGetters } from 'vuex'
  import YWindex from './index_YW'
  import {getUserIntegral} from "../../../api/member-center/my-integral";

  export default {
    name: 'member-center-home',
    components: { OrderList, Swiper,YWindex },
    data () {
      return {
        ProDuct,
        index_YW:false,
        searchForm: {
          orderStatus: '',
          startDate: '',
          endDate: '',
          productNm: '',
          productCode: '',
          orderNum: '',
          paymentWayId: '',
          orderSource: ''
        },
        tableData: [],
        timeValue: '近7天',
        integerData:{},//积分数据
        loginLastTime: '',
        loginCount: '',
        userSignName: {
          userId: '',
          signature: '',
          userSignatureId: ''//用户签名id
        },
        editSign: false,
        historyList: [],
        autofocus: false,
        productDefaultImg
      }
    },
    created () {
      for(var i = 0;i<this.$route.meta.roles.length;i++){
        if(this.$route.meta.roles[i] === 'SALESMAN' || this.$route.meta.roles[i] === 'BUYERS_TEAM'){
          this.index_YW = false;
          break;
        }else{
          this.index_YW = true;
        }
      }
      this.$emit('handle-change-page', {
        text: '会员中心首页',
        index: '1-1',
        url: '/member-center',
        meta: { roles: ['BUYERS', 'SALESMAN','BUYERS_TEAM'] }
      })
      this.userSignName.userId = getBus().buyersId
      this.getDetail()
      this.getUserIntegral()
    },
    computed: {
      ...mapGetters(['userDetail', 'org', 'buyersVo'])
    },
    methods: {
      //获取会员积分数据
      getUserIntegral(){
        getUserIntegral().then(res=>{
            if(res.code === '0'){
              this.integerData = res.data
            }
          })
      },
      jumpOrder (orderStatus) {
        let obj = {}
        if (orderStatus === '退货/退款') {
          obj = {
            text: '退货/退款',
            index: '2-2',
            url: '/member-center/order-manage/refunds'
          }
        } else {
          obj = {
            text: '商城订单',
            index: '2-1',
            url: '/member-center/order-manage/store-order'
          }
        }
        this.$emit('handle-change-page', obj)
        this.$router.push({ path: obj.url, query: { activeName: orderStatus } })
      },
      queryData (data) {
        let nowDate = parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}')
        let halfYearDate = parseTime(new Date(Date.now() - (365 / 6 * 24 * 3600 * 1000)), '{y}-{m}-{d} {h}:{i}:{s}')
        switch (this.timeValue) {
          case '近7天' :
            halfYearDate = parseTime(new Date(Date.now() - (7 * 24 * 3600 * 1000)), '{y}-{m}-{d} {h}:{i}:{s}')
            break
          case '近30天' :
            halfYearDate = parseTime(new Date(Date.now() - (365 / 12 * 24 * 3600 * 1000)), '{y}-{m}-{d} {h}:{i}:{s}')
            break
          case '近三个月' :
            halfYearDate = parseTime(new Date(Date.now() - (365 / 4 * 24 * 3600 * 1000)), '{y}-{m}-{d} {h}:{i}:{s}')
            break
          case '近半年' :
            halfYearDate = parseTime(new Date(Date.now() - (365 / 2 * 24 * 3600 * 1000)), '{y}-{m}-{d} {h}:{i}:{s}')
            break
        }
        this.searchForm.startDate = halfYearDate
        this.searchForm.endDate = nowDate
        return pageOrder({
          ...data,
          condition: this.searchForm
        })
      },
      getDetail () {
        //获取登陆信息
        getByUserIdLast().then(res => {
          const { data } = res
          this.loginLastTime = data.lastTime
          this.loginCount = data.count
        })
        //获取用户签名
        this.handleGetSign()
        //获取浏览记录
        getBrowsingHistory(this.org.orgId).then(res => {
          this.historyList = res.data
        })
      },
      //获取用户签名
      handleGetSign () {
        getUserSignature().then(res => {
          const { data } = res
          if (data) {
            this.userSignName = data
          }
        })
      },
      // 点击签名出现输入框
      handleEditSignBol () {
        this.editSign = true
        this.autofocus = true
      },
      //编辑签名
      handleEditSign () {
        if (this.userSignName.userSignatureId) {
          this.handleUpdateSign()
        } else {
          this.handleSaveSign()
        }
        this.editSign = false
      },
      //修改签名
      handleUpdateSign () {
        var params = this.userSignName
        saveUserSignature(params).then(res => {
          this.$message.success(res.msg)
          this.editSign = false
          this.handleGetSign()
        })
      },
      //保存签名
      handleSaveSign () {
        var params = this.userSignName
        delete params.userSignatureId
        saveUserSignature(params).then(res => {
          this.$message.success(res.msg)
          this.editSign = false
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .member-center-home {
    width: 100%;
    height: auto;
    background: #f5f5f5;

    .module-title {
      padding: 0 16px;
      height: 46px;
      line-height: 46px;
      text-align: left;
      font-size: 16px;
      font-weight: 600;
      color: rgba(34, 34, 34, 1);
      border-bottom: 1px solid #E5E5E5;
    }

    .header {
      background: #ffffff;
      padding: 0 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 14px;
      border-radius: 8px;
      font-weight: 400;
      height: 44px;
      line-height: 44px;

      .sign {
        color: rgba(102, 102, 102, 1);
        width: 580px;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        i {
          display: inline;
          margin-right: 8px;
          cursor: pointer;
        }

        .sign-msg {
          display: inline;
        }

        /deep/ .sign-input {
          width: 800px;

          .el-input__inner {
            border: none
          }
        }
      }

      .login-msg {
        color: rgba(153, 153, 153, 1);
      }
    }

    .member {
      margin: 16px 0;
      width: 100%;
      height: 120px;
      background: #ffffff;
      border-radius: 8px;
      display: flex;
      align-items: center;

      .member-info {
        display: flex;
        align-items: center;
        width: 400px;
        height: 128px;
        background: $ThemeColor;
        box-shadow: 0 3px 6px 0 $ThemeColorHalf;
        border-radius: 8px;
        color: #ffffff;
        text-align: left;
        justify-content: center;

        .portrait {
          margin: 0 16px 0 24px;
          width: 64px;
          height: 64px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .member-content {
          padding: 2px 0;
          flex: 1;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .merchant-name {
            font-size: 20px;
            font-weight: 500;
            padding-bottom: 15px;
          }

          .type {
            font-size: 14px;
            font-weight: 400;

            span:first-child {
              margin-right: 16px;
            }
          }
        }
      }

      .member-boxs {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        flex: 1;
        justify-content: space-around;

        .box {
          .number {
            height: 46px;
            line-height: 46px;
            font-size: 36px;
            color: rgba(34, 34, 34, 1);
            margin-bottom: 8px;

            span {
              margin-left: 4px;
              font-size: 14px;
              color: rgba(34, 34, 34, 1);
            }

            &.money {
              color: #FA6400;
            }
          }
          .erpTag {
            display: flex;
            justify-content: center;
            align-items: center;
            color: red;
            width: 70px;
            height: 20px;
            margin: 6px auto 0;
            font-size: 12px;
            text-align: center;
            border: 1px solid red;
            padding: 1px 3px;
            border-radius: 3px;
          }

          p {
            font-size: 14px;
            font-weight: 400;
            color: rgba(102, 102, 102, 1);
          }
        }
      }
    }

    .member-main {
      display: flex;
      flex-direction: row;

      .about-order {
        width: 650px;
        height: auto;
        margin-right: 12px;

        .my-order {
          width: 100%;
          height: 210px;
          background: #ffffff;
          border-radius: 8px;

          .my-oder-boxs {
            display: flex;
            flex-direction: row;
            padding: 27px 0px;
            height: 164px;
            align-items: center;
            text-align: center;

            .order-box {
              cursor: pointer;
              margin: 0 10px;
              height: 100%;
              flex: 1;
              display: flex;
              flex-direction: column;
              align-items: center;

              &:hover {
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
                border-radius: 8px;
                .order-icon {
                  &.unpaid {
                    background-image: url("../../../assets/img/member-center/11-1.png");
                  }

                  &.library {
                    background-image: url("../../../assets/img/member-center/22-1.png");
                  }

                  &.receive {
                    background-image: url("../../../assets/img/member-center/33-1.png");
                  }

                  &.return {
                    background-image: url("../../../assets/img/member-center/44-1.png");
                  }

                  &.all-order {
                    background-image: url("../../../assets/img/member-center/55-1.png");
                  }
                }

              }

              .order-icon {
                position: relative;
                width: 48px;
                height: 48px;
                margin-top: 17px;
                margin-bottom: 12px;
                .badge {
                  position: absolute;
                  padding: 0 6px;
                  left: 35px;
                  top: -10px;
                  display: inline-block;
                  line-height: 20px;
                  height: 20px;
                  background: rgba(250, 100, 0, 1);
                  border-radius: 10px;
                  color: #ffffff;
                  font-size: 12px;
                  font-weight: 600;
                }

                &.unpaid {
                  background: url("../../../assets/img/member-center/11.png") no-repeat;
                  background-size: 100% 100%;
                }

                &.library {
                  background: url("../../../assets/img/member-center/22.png") no-repeat;
                  background-size: 100% 100%;
                }

                &.receive {
                  background: url("../../../assets/img/member-center/33.png") no-repeat;
                  background-size: 100% 100%;
                }

                &.return {
                  background: url("../../../assets/img/member-center/44.png") no-repeat;
                  background-size: 100% 100%;
                }

                &.all-order {
                  background: url("../../../assets/img/member-center/55.png") no-repeat;
                  background-size: 100% 100%;
                }
              }

              p {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(102, 102, 102, 1);
              }
            }
          }
        }

        /*订单列表*/
        .order-list-container {
          width: 100%;
          height: auto;
          background: #ffffff;
          margin-top: 12px;
          padding: 12px;

          .message {
            width: 196px;
            text-align: left;
            padding-left: 12px;
          }

          .price {
            width: 94px;
          }

          .number {
            width: 68px;
          }

          .total {
            width: 126px;
          }

          .operate {
            flex: 1;

            .state {
              height: 20px;
              font-size: 14px;
              font-weight: 500;
              color: rgba(9, 113, 235, 1);
              line-height: 20px;
              margin-bottom: 8px;
            }

            .operate-button-group {
              display: flex;
              justify-content: space-between;

              button {
                /*padding:0 4px;*/
                padding: 0 2px;
                border-radius: 2px;
                border: 1px solid rgba(204, 204, 204, 1);
                height: 20px;
                line-height: 20px;
                background: #ffffff;
                text-decoration: none;
                font-size: 12px;
                font-weight: 500;
                color: rgba(102, 102, 102, 1);

                &:first-child {
                  margin-right: 4px;
                }
              }
            }
          }

          .order-list {
            width: 100%;
            height: auto;

            .order-item {
              width: 100%;
              height: auto;
              border: 1px solid rgba(229, 229, 229, 1);
              margin-bottom: 8px;

              &:last-child {
                margin-bottom: 0 !important;
              }

              .order-code {
                text-align: left;
                padding-left: 12px;
                height: 40px;
                line-height: 40px;
                background: rgba(9, 113, 235, 0.06);
                border-bottom: 1px solid rgba(229, 229, 229, 1);
                font-size: 14px;
                font-weight: 500;
                color: rgba(34, 34, 34, 1);

                span {
                  margin-left: 32px;
                }
              }

              .order-item-row {
                display: flex;
                flex-direction: row;
                height: 100px;
                border-bottom: 1px solid rgba(229, 229, 229, 1);

                &:last-child {
                  border-bottom: 0;
                }

                li {
                  padding: 16px 12px;
                  height: 100%;
                  border-right: 1px solid rgba(229, 229, 229, 1);
                  display: flex;
                  flex-direction: column;
                  justify-content: center;

                  &:last-child {
                    border: none;
                  }

                  &.item-row {

                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    font-size: 14px;
                    font-weight: 500;
                    color: rgba(102, 102, 102, 1);

                    .product-name {
                      color: rgba(34, 34, 34, 1);
                      margin-bottom: 8px;
                      font-weight: 600;
                    }
                  }

                  &.money {
                    color: #FA6400;
                  }
                }
              }
            }

            .list-toggle {
              margin-top: -8px;
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: 500;
              color: rgba(102, 102, 102, 1);
              border: 1px solid rgba(229, 229, 229, 1) !important;
              border-top: 0 !important;
              cursor: pointer;
            }
          }

          .list-header {
            display: flex;
            height: 40px;
            line-height: 40px;
            background: rgba(242, 242, 242, 1);
            font-size: 14px;
            font-weight: 600;
            color: rgba(34, 34, 34, 1);
            border: 1px solid #E5E5E5;

          }
        }

        /deep/ .list .empty-box {
          padding-top: 31px;
        }
      }

      .aside-right {
        flex: 1;

        .about-account {
          width: 100%;
          height: 210px;
          background: #ffffff;
          border-radius: 8px;

          .account-main {
            padding: 25px 16px;
            height: 164px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .acccount-item {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              text-align: left;
              color: rgba(102, 102, 102, 1);
              display: flex;
              align-items: center;

              .account-icon {
                display: inline-block;
                width: 16px;
                height: 16px;
                margin-right: 4px;

                &.plan {
                  background: url("../../../assets/img/member-center/icon-plan.png") 100% 100% no-repeat;
                }

                &.collect {
                  background: url("../../../assets/img/member-center/icon-collect.png") 100% 100% no-repeat;
                }

                &.salesman {
                  background: url("../../../assets/img/member-center/icon-salesman.png") 100% 100% no-repeat;

                }
              }

              .value {
                @include TextHighlightColor;
                margin: 0 4px;
              }
            }
          }
        }

        .browsing-history {
          margin-top: 12px;
          width: 100%;
          height: 296px;
          background: #ffffff;
          border-radius: 8px;

          .record-container {
            padding: 24px 0px 8px 0;
            /*height: 250px;*/
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .record {
              margin-bottom: 16px;
              padding: 0 16px;
              width: 108px;
              overflow: hidden;

              img {
                width: 72px;
                height: 72px;
              }

              p {
                margin-top: 4px;
                width: 72px;
                height: 17px;
                font-size: 12px;
                font-weight: 500;
                color: rgba(102, 102, 102, 1);
                line-height: 17px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
</style>

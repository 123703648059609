var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c("div", { staticClass: "login-content" }, [
        _c("div", { staticClass: "login-rt" }, [
          _c(
            "div",
            { staticClass: "ls-logo" },
            [
              _c("router-link", { attrs: { to: "/" } }, [
                _c("img", {
                  attrs: { src: require("../../assets/img/logo.gif"), alt: "" },
                }),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "rt-content" },
            [
              _c(
                "el-tabs",
                {
                  staticClass: "login-tab",
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    {
                      staticClass: "first-zp",
                      attrs: { label: "账号登录", name: "first" },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "loginForm",
                          staticClass: "login-form",
                          attrs: {
                            model: _vm.loginForm,
                            rules: _vm.loginRules,
                            "auto-complete": "on",
                            "label-position": "left",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "username" } },
                            [
                              _c("el-input", {
                                ref: "username",
                                attrs: {
                                  placeholder: "输入登录账号",
                                  name: "username",
                                  type: "text",
                                  tabindex: "1",
                                  "auto-complete": "on",
                                  clearable: "",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.showValid.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.loginForm.username,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.loginForm, "username", $$v)
                                  },
                                  expression: "loginForm.username",
                                },
                              }),
                              _c("div", { staticClass: "input-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/img/login/icon-user.png"),
                                    alt: "",
                                  },
                                }),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "password" } },
                            [
                              _c("el-input", {
                                key: _vm.passwordType,
                                ref: "password",
                                attrs: {
                                  type: _vm.passwordType,
                                  placeholder: "输入登录密码",
                                  name: "password",
                                  tabindex: "2",
                                  "auto-complete": "on",
                                  clearable: "",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.showValid.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.loginForm.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.loginForm, "password", $$v)
                                  },
                                  expression: "loginForm.password",
                                },
                              }),
                              _c("div", { staticClass: "input-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/img/login/icon-suo.png"),
                                    alt: "",
                                  },
                                }),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "手机登录", name: "second" } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "loginFormPhone",
                          staticClass: "login-form",
                          attrs: {
                            model: _vm.loginFormPhone,
                            rules: _vm.loginPhoneRules,
                            "auto-complete": "on",
                            "label-position": "left",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "phone" } },
                            [
                              _c("el-input", {
                                ref: "phone",
                                attrs: {
                                  placeholder: "输入手机号码",
                                  name: "phone",
                                  type: "text",
                                  tabindex: "1",
                                  "auto-complete": "on",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.loginFormPhone.phone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.loginFormPhone, "phone", $$v)
                                  },
                                  expression: "loginFormPhone.phone",
                                },
                              }),
                              _c("div", { staticClass: "input-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/img/login/icon-shouji.png"),
                                    alt: "",
                                  },
                                }),
                              ]),
                            ],
                            1
                          ),
                          _vm.slider_show
                            ? _c(
                                "el-from-item",
                                [
                                  _c("SlidePuzzleVerification", {
                                    attrs: { isShow: _vm.slider_show },
                                    on: {
                                      validationResults: _vm.validationResults,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "sj-yanzheng",
                              attrs: { prop: "sms" },
                            },
                            [
                              _c("el-input", {
                                ref: "sms",
                                attrs: {
                                  placeholder: "输入验证码",
                                  name: "sms",
                                  tabindex: "2",
                                  clearable: "",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.showValid.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.loginFormPhone.sms,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.loginFormPhone, "sms", $$v)
                                  },
                                  expression: "loginFormPhone.sms",
                                },
                              }),
                              _c("div", { staticClass: "input-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/img/login/icon-yanzhengma.png"),
                                    alt: "",
                                  },
                                }),
                              ]),
                              _c(
                                "el-button",
                                {
                                  attrs: { disabled: !_vm.VerifBtn },
                                  on: { click: _vm.handleVerif },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.timeNum > 0
                                        ? `已发送${_vm.timeNum}s`
                                        : "发送验证码"
                                    ) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn-login",
                      attrs: {
                        loading: _vm.loading,
                        type: "primary",
                        plain: "",
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.showValid.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("登录 ")]
                  ),
                  _vm.activeName === "first"
                    ? _c(
                        "div",
                        { staticClass: "more-opration" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.autoLogin,
                                callback: function ($$v) {
                                  _vm.autoLogin = $$v
                                },
                                expression: "autoLogin",
                              },
                            },
                            [_vm._v("记住登录状态")]
                          ),
                          _c(
                            "div",
                            { staticClass: "flex" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: { click: _vm.goForgetPwd },
                                },
                                [_vm._v("忘记密码")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "more-opration",
                          staticStyle: { "justify-content": "flex-end" },
                        },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/register" } },
                            [
                              _c("el-button", { attrs: { type: "text" } }, [
                                _vm._v("我是新用户？ 立即注册"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("slide-verify-valid", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowValid,
            expression: "isShowValid",
          },
        ],
        ref: "slideVerify",
        on: { onCancel: _vm.hideValid, onSuccess: _vm.validSuccess },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <!--资质提醒-->
  <!-- license.isOverdue === 'Y' ? '超账期提醒' : " -->
  <div>
    <el-dialog width="600px" class="dialog-license" :title="zzOutOfTime ? '资质超期提醒' : '资质提醒'"
               :visible.sync="dialogVisible">
      <!--资质过期提示-->
      <div class="flex" v-if="zzOutOfTime">
        <div class="text">
          <h5>温馨提示</h5>
          <!--全部过期-->
          <p v-if="license.isAllLicenseExpired === 'Y'">您有{{outTimeAptitudesList.length}}张证照已过期<i v-if="license.proxyExpired === 'Y'">,并且委托书已过期</i></p>
          <p v-else-if="license.isFailure === 'Y'">
            您的证照<span v-for="(item,index) in outTimeAptitudesList"
                      :key="index">{{ index === outTimeAptitudesList.length - 1 ? item.licenseNm : item.licenseNm + '，' }}</span><i v-if="license.proxyExpired === 'Y'">和委托书</i>已过期
          </p>
          <p v-else-if="license.isAboutFailure === 'Y'">
            您的证照<span v-for="(item,index) in noMoreTimeAptitudesList"
                      :key="index">{{ index === noMoreTimeAptitudesList.length - 1 ? item.licenseNm : item.licenseNm + '，' }}</span>即将过期<i v-if="license.proxyExpired === 'Y'">，并且委托书已过期</i>
          </p>
          <p>请及时联系业务员更换，以免影响您的正常采购！</p>
          <el-button size="small" type="primary" @click="toLicense">查看详情</el-button>
        </div>

      </div>
      <!--超账期提醒-->
      <!-- <div class="text_CQ" v-else-if="license.isOverdue === 'Y'">
        <h5>温馨提示</h5>
        <p>您有逾期未付款的订单</p>
        <p>请尽快付款结算，以免影响您的正常采购！</p>
        <el-button size="small" type="primary" @click="toOrderDetail">查看详情</el-button>
      </div> -->
      <div class="text_CQ" v-else-if="licenseAsRangesList.length > 0">
        <h5>温馨提示</h5>
        <p>您的{{ licenseAsRangesList.length }}张证照无经营范围</p>
        <p>请及时联系业务员更换，以免影响您的正常采购！</p>
        <el-button size="small" type="primary" @click="toLicense">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {getMyAptitudes} from "../../../api/member-center/personal-center";
export default {
  name: "licenseExpiredModal",
  props:{
    visible:{
      type: Boolean,
      default: false
    },
  },
  data(){
    return {
      outTimeAptitudesList: [], //已过期资质
      noMoreTimeAptitudesList: [], //即将过期资质
      licenseAsRangesList: [], //不与经营范围相关且经营范围为空的资质
    }
  },
  watch:{
    license(){
      console.log(this.license)
    },
    buyersVo(){
      this.getAptitudesList()
    },
  },
  computed:{
    ...mapGetters(['license','buyersVo']),
    dialogVisible:{
      get(){
        return this.visible && (this.licenseAsRangesList.length > 0 || this.zzOutOfTime)
      },
      set(val){
        this.$emit('update:visible',val)
      }
    },
    zzOutOfTime() { //全部已过期 || 即将过期
      return this.license.isAllLicenseExpired === 'Y' || this.license.isFailure === 'Y' || this.license.isAboutFailure === 'Y'
    }
  },
  methods:{
    hide(){
      this.$emit('update:visible',false)
    },
    show(){
      this.$emit('update:visible',true)
    },
    //跳到资质列表
    toLicense() {
      this.$router.push(`/member-center/personal-center/my-aptitudes`)
      this.hide()
    },
    //跳到订单详情
    toOrderDetail(){
      this.$router.push("/member-center/order-manage/store-order?activeName=WAIT_PAY")
      this.hide()
    },
    //获取用户资质
    getAptitudesList() {
      let licenseNm = ['《营业执照》', '《医疗机构执业许可证》', '《药品经营许可证》','营业执照', '医疗机构执业许可证', '药品经营许可证']
      getMyAptitudes().then(res => {
        console.log('res1111111', this.license)
        let {data} = res;
        data = data.filter(item => item.isStop !== 'Y')

        this.outTimeAptitudesList = data.filter(item => licenseNm.includes(item.licenseNm) && item.status === '已过期')
        this.noMoreTimeAptitudesList = data.filter(item => licenseNm.includes(item.licenseNm) && item.status === '即将过期')
        //经营范围是否相关 = “否” 且 "已过期"
        this.licenseAsRangesList = data.filter(item => item.isRelatedBusinessRanges === 'N' && item.status === '已过期')

        console.log(this.licenseAsRangesList)
        if(this.licenseAsRangesList.length){
          this.$store.state.user.license.isNoRange = 'Y'
        }else {
          this.$store.state.user.license.isNoRange = 'N'
        }

        this.$store.state.user.license.list = data

        if (
            (
              this.license.isAllLicenseExpired === 'Y' ||
              this.license.isFailure === 'Y' ||
              this.noMoreTimeAptitudesList.length > 0 ||
              this.licenseAsRangesList.length > 0
            ) && this.userType !== 'SALESMAN'
        ) {
          this.show()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-license {
  /deep/ .el-dialog {
    text-align: left;

    .el-dialog__body {
      padding: 40px;

      .text_CQ {
        text-align: center;

        h5 {
          margin-bottom: 20px;
        }

        p {
          margin-top: 2px;
        }

        .el-button {
          margin-top: 20px;
        }
      }

      .flex {
        display: flex;
        align-items: center;

        .img {
          width: 180px;
          height: 180px;
          line-height: 180px;

          img {
            vertical-align: middle;
            width: 100%;
            height: 100%;
          }
        }

        .text {
          padding-left: 20px;

          h5 {
            font-size: 17px;
            padding-bottom: 10px;
          }

          p {
            color: #888;
            line-height: 1.4;

            span {
              color: #FA6400;
            }
          }

          .el-button {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>
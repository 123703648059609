<template>
  <div class="list">
    <div class="order-tab">
      <div class="one">
        <el-dropdown @command="handleCommand" trigger="click">
            <span class="el-dropdown-link">
              {{ more[moreIndex] }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
          <el-dropdown-menu slot="dropdown" style="width: 100px;">
            <el-dropdown-item v-for="(item, i) in more" :key="i" :command="item">
              {{item}}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="two">订单信息</div>
      <div class="third">订单总品规</div>
      <div class="four">订单状态</div>
      <div class="fine">交易操作</div>
    </div>

    <div class="order-list-container" v-if="data.length || isLoading">
      <div v-if="data.length" class="order-list" v-for="(item,index) in data" :key="index">
        <!--<div v-if="item.isTransferRecordFlag === 'Y'">调</div>-->
        <img src="@/assets/img/icon/diaobo.png" class="diaobo" v-if="item.isTransferRecordFlag === 'Y'">
        <div class="order-list-header">
          <div class="order-list-header-content">
            <span class="one">{{item.createDate}}</span>
            <span>订单编号：{{item.orderNum}}</span>
            <span v-if="item.saleOrderNum">ERP销售订单号：{{item.saleOrderNum.join(',')}}</span>
            <span>订单来源：{{item.orderSourceCode}}</span>
            <span>是否业务员订单：{{item.isSalesmanOrder === 'N'?'否':'是'}}</span>
            <span v-if="userType !=='SALESMAN'">客户名称：{{ item.buyerNm }}</span>
            <span v-if="userType =='SALESMAN'">是否调拨订单：{{item.isTransferRecordFlag === 'N'?'否':'是'}}</span>
            <br/>
            <span v-if="userType ==='SALESMAN'" style="color: #E02020">客户ID：{{item.erpCode || '--'}}</span>
            <span v-if="userType ==='SALESMAN'" style="color: #E02020">客户名称：{{item.buyerNm || '--'}}</span>
            <span v-if="userType ==='SALESMAN' && item.ettleAccountNm !== '默认分支机构'" style="color: #E02020">分支机构ID：{{item.ettleAccountNm === '默认分支机构' ?  '-' : item.code}}</span>
            <span v-if="userType ==='SALESMAN' && item.ettleAccountNm !== '默认分支机构'" style="color: #E02020">分支机构名称：{{item.ettleAccountNm === '默认分支机构' ?  '-' : item.ettleAccountNm}}</span>
          </div>
          <div class="order-list-header-btn">
            <el-button type="text" style="width:110px;margin-left: 20px; padding: 0;" @click="getOrderDeatils(item.orderId)">查看购物清单</el-button>
          </div>
        </div>
        <div class="order-list-content">
          <div class="one">
            <img alt :src="item.picture || productDefaultImg" @error="item.picture = productDefaultImg" @click="goDetails(item.orderId)">
          </div>
          <div class="two">
            <p>订单金额: <span>￥{{$util.toFixed(item.orderTotalAmount)}}</span></p>
            <p>支付方式: <span v-if="item.paymentWayCode === 'CASH'">在线支付</span><span v-if="item.paymentWayCode === 'CREDIT'">赊销</span><span v-if="item.isUseIntegral === 'Y'">(积分抵扣)</span></p>
            <p v-if="item.refundFlag === 2 && item.isPayed ==='Y'">补单</p>
            <p v-if="item.isFullRefund === 'Y' && item.isPayed ==='Y'">已全额退款：￥{{item.refundTotalAmount}}</p>
            <p v-if="item.isFullRefund === 'N' && item.isPayed ==='Y'">已部分退款：￥{{item.refundTotalAmount}}</p>
            <p>备注：{{item.remark === ''? '无': item.remark}}</p>
          </div>
          <div class="third">
            <p>共{{item.orderProductTotal}}种</p>
          </div>
          <div class="four">
            <p>{{item.orderStatus}}</p>
          </div>
          <div class="fine">
            <span @click="bindVisible('确认订单',item.orderId)" v-if="item.processStatCode === 'WAIT_APPROVE' && (userType ==='BUYERS' || userType === 'BUYERS_TEAM')">确认订单</span>
            <span @click="goDetails(item.orderId)">订单详情</span>
            <span class="pay" @click="toPay(item.orderId)" v-if="item.processStatCode === 'WAIT_PAY'">去支付 {{time(item.countdown)}}</span>
            <span @click="bindCancelOrder(item.orderId)" v-if="(item.processStatCode === 'WAIT_PAY' || item.processStatCode === 'WAIT_APPROVE') && userType ==='BUYERS'">取消订单</span>
            <span @click="buyAgain(item.orderId)" v-if="userType ==='BUYERS' || userType === 'BUYERS_TEAM'">再次购买</span>
            <span @click="buyAgain(item.orderId)" v-if="userType ==='SALESMAN' && item.processStatCode != 'WAIT_PAY' && item.processStatCode != 'WAIT_APPROVE'">再次购买</span>
<!--            <span @click="bindVisible('下载药检单',item.orderId)" v-if="item.processStatCode === 'FINISH' || item.processStatCode === 'SEND'">下载药检单</span>-->
            <span v-if="item.processStatCode === 'SEND' || item.processStatCode === 'SENDING' || item.processStatCode === 'FINISH'" @click="goOrderExpress(item.orderId)">查看物流</span>
            <span v-if="item.processStatCode === 'FINISH'" @click="toRefunds">申请退货</span>
            <span v-if=" paramValueString === 'Y' && item.processStatCode === 'WAIT_SEND' && item.refundFlag == 0 && item.paymentWayCode === 'CASH' && item.isPayed === 'Y'" @click="refundDialog(item)" >退款</span>
            <template v-if="item.processStatCode === 'SENDING' || item.processStatCode === 'SEND'">
              <span @click="bindVisible('确认收货',item.orderId,item.singlePackageId)" v-if="item.isSinglePackage === 'Y' && item.singlePackageId">确认收货</span>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!data.length && !isLoading" class="empty-box">
      <div>
        <el-image style="width: 160px; height: 160px" :src="emptyDefault" fit="fill"/>
        <p class="empty-tip">暂无订单</p>
      </div>
    </div>
    <im-pagination
        :show-collection="true"
        class="table-page"
        :total="total"
        :page-sizes="pageSizes"
        :pager-count="pagerCount"
        :current-page.sync="currentPage"
        :page-size.sync="pageSize"
        @pagination="handlePagination"
    />
    <el-dialog class="dialog-table" :width="dialogStatus === '下载药检单'?'900px':'400px'" :title="dialogStatus" :visible.sync="dialogTableVisible">
      <img v-if="dialogStatus == '确认收货'" :src="comfirmGoodsIcon" class="comfirmPic">
      <img v-if="dialogStatus == '确认订单'" :src="comfirmOrderIcon" class="comfirmPic">
      <div class="comfirmOrder" v-if="dialogStatus != '下载药检单'">您是否{{dialogStatus}}</div>
      <div v-if="dialogStatus === '下载药检单'">
        <div class="sheet">
          以下订单中的商品已检索到药检单，请勾选下载，若存在缺失资料品种请联系业务员（未出库品种不可下载）
        </div>
        <im-table-page ref="tablePage" :data.sync="tableData" :remote-method="queryData" :more-check-box="moreCheckBox" >
          <el-table-column label="序号" type="index" width="60px" align="center"/>
          <el-table-column type="selection" width="40px" align="center"/>
          <el-table-column prop="productNm" label="商品名称" min-width="140px">
              <template slot-scope="scope">
                {{$util.getGoodsName(scope.row)}}
              </template>
          </el-table-column>
          <el-table-column prop="spec" label="规格" min-width="100px" align="center"/>
          <el-table-column prop="manufacturer" label="生产厂家" min-width="183px" />
          <el-table-column prop="batch" label="商品批号" width="100px" align="center"/>
        </im-table-page>
      </div>
      <span slot="footer">
        <el-button type="primary" v-if="dialogStatus == '确认收货'" @click="bindComfirmOrder">确 定</el-button>
        <el-button @click="bindSureOrder" type="primary" v-if="dialogStatus == '确认订单'" >确 定</el-button>
        <el-button @click="downloadSheet" type="primary" :disabled="!fileIds.length > 0" v-loading="isDownloadingSheet" v-if="dialogStatus == '下载药检单'" >下载药检单</el-button>
        <el-button @click="dialogTableVisible = false">取 消</el-button>
      </span>
    </el-dialog>

    <!--商品清单-->
    <el-dialog class="dialog-table" width="1000px" title="购物清单" :visible.sync="dialogListVisible">
      <el-table :data="orderDetail.orderItemVos" border :max-height="dialogTableHeight + 'px'" style="width: 100%; padding-top: 1px;">
        <el-table-column label="商品信息" width="290px" show-overflow-tooltip>
          <template slot-scope="scope">
            <!--<div v-if="scope.row.isTransferRecordFlag === 'Y'">调</div>-->
            <img src="@/assets/img/icon/diaobo.png" class="diaobo2" v-if="scope.row.isTransferRecordFlag === 'Y'">
            <div class="product-info">
              <div class="product-image" @click="productDetail(scope.row)">
                <el-image :src="scope.row.thumbnailUrl" @error="scope.row.thumbnailUrl = productDefaultImg"
                          fit="fit"/>
                <img src="@/assets/img/detail/yizuofei.png" class="oneScrap" v-if="scope.row.orderItemStatus === 'INVALID'">
              </div>
              <ul class="product-detail">
                <li class="name" @click="productDetail(scope.row)">
                  {{$util.getGoodsName(scope.row)}}
                </li>
                <li class="detail">
                  <p>规格：<span class="spec">{{ scope.row.spec}}</span> 单位：<span>{{ scope.row.unit}}</span></p>
                </li>
                <li class="manufacturer detail">生产厂家:{{ scope.row.manufacturer}}</li>
                <li class="manufacturer detail" style="color:#2B2B2B" v-if="scope.row.orderItemType === 'PRESENT'">[特殊商品]</li>
              </ul>
            </div>
            <div></div>
          </template>
        </el-table-column>
        <el-table-column label="单价" prop="money" width="90px" show-overflow-tooltip align="right">
          <template slot-scope="scope">
            <span class="textColor">¥{{ scope.row.orderItemType === 'PRESENT'? '0.00':scope.row.originalUnitPrice }}</span>
          </template>
        </el-table-column>
        <el-table-column label="购买数量" prop="number" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.num }}</span>
          </template>
        </el-table-column>
        <el-table-column align="right"
                         label="实付金额" prop="code" width="150px"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <p class="textColor">¥{{ $util.toFixed(scope.row.subtotal) }}</p>
            <!--              <s class="textColor" v-if="Number(scope.row.discountAmount) != 0">¥{{ (scope.row.salePrice * scope.row.num).toFixed(2) }}</s>-->
          </template>
        </el-table-column>
        <el-table-column label="优惠明细" prop="number" show-overflow-tooltip align="right">
          <template slot-scope="scope">
            <span class="textColor">-￥{{scope.row.discountAmount}}</span>
          </template>
        </el-table-column>
        <!--<el-table-column label="最近/远效期" prop="expDate" v-if="userType === 'BUYERS'" show-overflow-tooltip align="center"></el-table-column>-->
        <!--          <el-table-column-->
        <!--          label="发货信息" prop="number" width="160px"-->
        <!--          show-overflow-tooltip v-if="orderVo.orderStatus === '已发货' || orderVo.orderStatus === '发货中' || orderVo.orderStatus === '已完成'">-->
        <!--            <template slot-scope="scope">-->
        <!--              <p class="goodInfor">发货批号：{{ scope.row.batchNum }}</p>-->
        <!--              <p class="goodInfor">有效期至：{{ scope.row.exp }}</p>-->
        <!--              <p class="goodInfor">实发数量：x{{ scope.row.sendQuantity  }}</p>-->
        <!--            </template>-->
        <!--          </el-table-column>-->
        <el-table-column label="操作" width="100px" align="center">
          <template slot-scope="scope">
            <div class="apply">
              <el-button @click="beforeAddItem(scope.row.skuId, 'NORMAL', scope.row.num,scope.row)" v-if="(userType ==='BUYERS' || userType ==='BUYERS_TEAM') && scope.row.orderItemType !== 'PRESENT'" class="download" size="mini" type="text">再次购买</el-button>
              <el-button @click="beforeAddItem(scope.row.skuId, 'NORMAL', scope.row.num,scope.row)" v-if="userType ==='SALESMAN' && orderDetail.orderVo.processStatCode != 'WAIT_PAY' && orderDetail.orderVo.processStatCode != 'WAIT_APPROVE' && scope.row.orderItemType !== 'PRESENT'" class="download" size="mini" type="text">再次购买</el-button>
              <el-button @click="bindVisible('下载药检单')" v-if="orderDetail.orderVo.processStatCode === 'FINISH'" class="download" size="mini" type="text">下载药检单</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog class="dialog-table" width="400px" title="退款理由" :visible.sync="dialogRefundVisible">
      <div class="textarea-input">
        <textarea
          v-model="refundRemark"
          placeholder="请输入具体原因"
          class="textarea"
          maxlength="40"
        ></textarea>
          <!-- 右下角显示文字 -->
          <div class="word-limit">
              <span class="green">{{ refundRemark.length }}</span>/40
          </div>
      </div>
      <span slot="footer">
        <el-button @click="toApplyRefunds" type="primary">确 定</el-button>
        <el-button @click="dialogRefundVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <div v-show="refundLoading" class="loading-mask" v-loading="refundLoading" element-loading-text="退款请求中"></div>
  </div>
</template>
<script>
  import productDefaultImg from '@/assets/img/index/product_default.png'
  import emptyDefault from '@/assets/img/member-center/emptyorder.png'
  import comfirmGoodsIcon from '@/assets/img/member-center/comfirm-order.png'
  import comfirmOrderIcon from '@/assets/img/member-center/icon-order.png'
  import { getBus, getAccount } from '@/utils/auth'
  import { mapGetters } from 'vuex'
  import { buyAgain, comfirmOrder, downloadSheet, pageOrderDrugList, comfirmTheOrder, cancelOrder,findDetail, findByParamInfInnerCode, refundOnlineOrder} from '@/api/member-center/order-manage/order-list'
  import addToCartMixin from '@/common/addToCart'

  export default {
    name: 'OrderList',
    props: {
      pageNumber: {
        type: Number,
        default: 10
      },
      data: {
        type: Array,
        required: true
      },
      pagerCount: {
        type: Number,
        default: 7
      },
      remoteMethod: {
        type: Function,
        default: () => {}
      },
      timeValue: {
        type: String,
        default: ''
      }
    },
    mixins:[addToCartMixin],
    data () {
      return {
        iconlist: [],
        ticker: null,
        tableData:[],
        emptyDefault,
        productDefaultImg,
        orderDetail:[],
        comfirmGoodsIcon,
        comfirmOrderIcon,
        dialogTableHeight:window.innerHeight - 200,
        more: [
          '全部','近7天', '近30天', '近三个月', '近半年'
        ],
        moreIndex: 0,
        pageSizes: [5, 10, 20, 30, 50, 100],
        total: 0,
        pageSize: 10,
        isLoading:false,
        currentPage: 0,
        dialogTableVisible: false,
        dialogStatus: '',
        dialogOrderId:'',
        isDownloadingSheet:false,
        dialogPackageId:'',
        fileIds:[], //下载药检单fileIds
        orderListArray: [],
        dialogListVisible:false,
        paramValueString: "", // 客户端是否可以发起退款
        dialogRefundVisible: false,
        refundData: '', // 退款数据
        refundRemark: '', // 退款原因
        refundLoading: false
      }
    },
    computed: {
      ...mapGetters(['userType','org'])
    },
    mounted () {
      this.moreIndex = this.more.findIndex(item => item === this.timeValue);
      this.currentPage = 0
      this.pageSize = this.pageNumber
      this.updateData()
      this.findByParamInfInnerCode()
    },
    methods: {
      getOrderDeatils(orderId){
        findDetail({
          orderId,
          orgId: getBus().orgId
        }).then(res=>{
          this.orderDetail = res.data
          this.dialogListVisible = true
        })
      },
      productDetail(row) {
        if(row.orderItemType != 'PRESENT'){
          this.$router.push({name:"detail",params:{id: row.productId}})
        }
      },
      sheetRefresh() {
        this.$nextTick(()=>{
          this.$refs.tablePage.doRefresh({currentPage: 0, pageSize: 10})
        })
      },
      queryData (data) {
        return pageOrderDrugList({
          ...data,
          condition:{
            orderId: this.dialogOrderId
          }
        })
      },
      beginTimer() { //这个计时器是每秒减去数组中指定字段的时间
        this.ticker = setInterval(() => {
          for (let i = 0, len = this.orderListArray.length; i < len; i++) {
            const item = this.orderListArray[i];
            if (item.countdown > 0) {
              item.countdown = item.countdown - 1000;
            }
          }
        }, 1000);
      },
      time(time) { //这个函数是每秒把时间重新计算下
        if (time >= 0) {
          let d = Math.floor(time / 1000 / 60 / 60 / 24)
          let h = Math.floor((time / 1000 / 60 / 60) % 24)
          let m = Math.floor((time / 1000 / 60) % 60)
          let s = Math.floor((time / 1000) % 60)
          return (d === 0?'':d+'天 ') + (h > 9 ? d:'0'+d)  +':'+ (m > 9 ? m:'0'+m) +':'+ (s > 9 ? s:'0'+s)
        }
      },
      toRefunds(){
        if(this.userType === 'SALESMAN') {
          this.$router.push({path:'/member-center/order-manage/refunds-yw',query:{
            active:'APPLY_RETURN'
          }})
        }
        if(this.userType === 'BUYERS'){
          this.$router.push({path:'/member-center/order-manage/refunds'})
        }
      },
      //  申请理由弹窗
      refundDialog(item) {
        this.refundData = item
        this.dialogRefundVisible = true
      },
      // 申请退款
      toApplyRefunds() {
        let _that = this
        let params = {
          buyersNo: this.refundData.code,
          buyersNm: this.refundData.buyerNm,
          orderId: this.refundData.orderId,
          refundRemark: this.refundRemark
        }
        if(this.userType ==='SALESMAN') {
          if (params.refundRemark !=='') {
            this.dialogRefundVisible = false
            this.refundLoading = true
            refundOnlineOrder(params).then(res => {
              if (res.code == "0") {
                this.$message.success('申请退款,等待审核!')
                let timer = setTimeout(function () {
                  _that.updateData()
                  clearTimeout(timer)
                }, 1000)
                this.dialogRefundVisible = false
              } else {
                this.$message.error(res.msg)
              }
            }).catch((err) => {
              console.log(err)
            }).finally(() => {
              this.refundLoading = false


            })
          } else {
            this.$message.warning('请填写退款理由')
          }
        } else {
          this.dialogRefundVisible = false
          this.refundLoading = true
          refundOnlineOrder(params).then(res => {
            if (res.code == "0") {
              this.$message.success('申请退款,等待审核!')
              let timer = setTimeout(function () {
                _that.updateData()
                clearTimeout(timer)
              }, 1000)
            } else {
              this.$message.error(res.msg)
            }
          }).catch((err) => {
            console.log(err)
          }).finally(() => {
            this.refundLoading = false
          })
        }

      },
      //取消订单
      bindCancelOrder(orderId){
        cancelOrder({ orderId }).then(res => {
          if(res.code != '0') return
          this.$message.success('订单取消成功!')
          this.updateData()
        })
      },
      buyAgain (orderId) {
        buyAgain({ orderId }).then(res => {
          const { code } = res
          if (code === '0'){
            if (res.data.tips !== '') {
            this.$message.warning(res.data.tips)

            }else{
            this.$message.success('加入成功！')
            this.$store.dispatch('user/setCartNum', res.data.allProductTypeTotalQuantity)

            }
          }
        })
      },
      goDetails (orderId) {
        // const { href } = this.$router.resolve({ path: '/order-detail/' + orderId })
        // window.open(href, '_blank')
        this.$router.push({path:'/order-detail/' + orderId})
      },
      toPay (orderId) {
        this.$router.push({path:'/cashier/'+ orderId})
      },
      //查看物流
       goOrderExpress (orderId) {
        this.$router.push({path:'/express-detail/' + orderId})
      },
      doRefresh (pageParam) {
        if (pageParam && pageParam instanceof Object) {
          this.currentPage = pageParam.currentPage
          this.pageSize = pageParam.pageSize
        }
        this.updateData()
      },
      moreCheckBox(val) {
        let fileIds = []
        val.forEach(element => {
          fileIds.push(element.fileId)
        })
        this.fileIds = fileIds
      },
      downloadSheet(){
        this.isDownloadingSheet = true;
        this.$util.downFiles(this.fileIds,'药检单','fileId').then(res =>{
          this.$message.success('下载成功')
          this.dialogTableVisible = false
          this.isDownloadingSheet = false
        }).catch(err=>{
          this.isDownloadingSheet = false
          err.msg && this.$message.error(err.msg)
        })
      },
      updateData () {
        let _this = this
        const pageNum = this.currentPage < 1 ? 1 : this.currentPage
        this.isLoading = true
        return this.remoteMethod({
          pageNum,
          pageSize: this.pageSize
        }).then(response => {
          const { data } = response
          if (data && data.records) {
            let startTime = new Date().getTime()
            data.records.map(item => {
              item.orderTotalAmount = this.$util.toThousands(item.orderTotalAmount)
              if(item.processStatCode != 'WAIT_PAY') return
              item.countdown = item.paymentTime - new Date().getTime()
            })
            _this.orderListArray = data.records
            this.$emit('update:data', _this.orderListArray)
            this.total = data.total
            this.$emit('updated', _this.orderListArray)
            if (this.ticker) { //这一段是防止进入页面出去后再进来计时器重复启动
              clearInterval(this.ticker);
            }
            this.beginTimer(); //启动计时器减指定字段的时间
          }
          this.$emit('loadEnding')
          this.isLoading = false
        }).catch(() => {
          this.isLoading = false
          this.$emit('loadEnding')
          this.$emit('update:data', [])
        })
      },
      handlePagination (data) {
        this.currentPage = Math.min(this.currentPage, Math.ceil(this.total / data.pageSize))
        this.updateData()
      },
      handleCommand(val) {
        this.moreIndex = this.more.findIndex(item => item === val);
        this.$emit('update:timeValue', val)
        this.$emit('selectTime', val)
        this.updateData()
      },
      //确认订单
      bindSureOrder(){
        comfirmTheOrder({ orderId:this.dialogOrderId}).then(res => {
          if(res.code != '0') return
          this.dialogTableVisible = false
          this.updateData()
          this.$emit('changeWaitConfirmOrder')
        })
      },
      //弹框
      bindVisible(status,orderId,packageId){
        this.dialogStatus = status
        this.dialogOrderId = orderId,
        this.dialogPackageId = packageId,
        this.dialogTableVisible = true
        if(status === '下载药检单') this.sheetRefresh()
      },
      //确定收货
      bindComfirmOrder(){
        comfirmOrder({packageId:this.dialogPackageId,orderId:this.dialogOrderId}).then(res =>{
          if(res.code != '0') return
          this.$message.success('收货成功')
          this.dialogTableVisible = false
          this.updateData()
        })
      },
      findByParamInfInnerCode() {
          const params= {
              innerCode: "CUSTOMER_REFUND_AUTH",
              orgId:  getBus().orgId
          }
        findByParamInfInnerCode(params).then(res => {
            if (this.userType == 'SALESMAN') {
                this.paramValueString = 'Y'
            }else {
                this.paramValueString = res.data.CUSTOMER_REFUND_AUTH
            }

        })
      }
    }
  }
</script>
<style lang="scss" scoped>
   .diaobo2 {
        width: 42px;
        height: 42px;
        z-index: 9;
   }
  /deep/.product-info {
    display: flex;
    flex-direction: row;
    padding: 0 10px;

    .product-image {
      width: 70px;
      min-width: 70px;
      height: 70px;
      cursor: pointer;

      .el-image {
        width: 100%;
        height: 100%;
      }
      .oneScrap{
        width: 62px;
        height: 62px;
        position: absolute;
        left: 14px;
        top: 11px;
      }
    }

    .product-detail {
      padding-left: 16px;
      width: 212px;

      li {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
      }

      .name {
        height: 18px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(34, 34, 34, 1);
        line-height: 18px;
        margin-bottom: 4px;
        cursor: pointer;
      }

      .detail {
        font-weight: 400;
        color: rgba(113, 113, 113, 1);
        line-height: 18px;
        height: 18px;

        .spec {
          margin-right: 10px;
          max-width: 75px;
          display: inline-flex;
          overflow: hidden;
        }

        &.manufacturer {
          white-space: normal;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  .list {
    background-color: #fff;
    font-size: 14px;
    text-align: left;

    .empty-box {
      flex: 1;
      display: flex;
      justify-content: center;
      padding-top: 252px;

      .empty-tip {
        font-size: 16px;
        font-weight: 500;
        color: rgba(113, 113, 113, 1);
        padding-top: 10px;
        text-align: center;
      }
    }

    .order-tab {
      display: flex;
      justify-content: space-between;
      margin: 0 20px;
      padding: 0 20px;
      height: 40px;
      line-height: 40px;
      background-color: #F5F5F5;
      color: #717171;

      /deep/ .el-dropdown {
        color: #222;
      }
    }
    .el-dropdown-link{
      cursor: pointer;
    }

    .order-list-container{
      min-height: 300px;
      //max-height: 800px;
      //overflow-y: auto;
    }
    .order-list {
      position: relative;
      color: #717171;
      margin: 8px 20px;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      .diaobo {
        position: absolute;
        bottom: 4px;
        right: 34px;
        width: 42px;
        height: 42px;
        z-index: 9;
      }
      .order-list-header {
        font-size: 12px;
        margin: 0 20px;
        border-bottom: 1px solid #E5E5E5;
        padding: 4px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .order-list-header-content{
            width: 100%;
        }
        span {
          padding: 5px 0;
          padding-right: 28px;
          display: inline-block;
        }
      }

      .order-list-content {
        display: flex;
        justify-content: space-between;
        margin: 8px 20px;

        .one {
          width: 100px;
          min-height: 70px;
          display: flex;
          align-items: center;
          img{
            cursor: pointer;
          }
        }

        .two {
          p {
            &:first-child {
              color: #222;
              font-weight: 500;
              margin: 8px 0 10px;
            }

            span {
              display: inline-block;
            }
          }
        }

        .four {
          color: #222;
        }

        .fine {
          color: #222;
          margin-top: 8px;

          div {
            display: inline;
          }
          span {
            display: inline-block;
            cursor: pointer;

            &:hover {
              @include TextHighlightColor
            }
          }

          .pay {
            color: #FA6400;
          }
        }

        img {
          width: 70px;
          height: 70px;
        }

        p {
          margin-top: 8px;
        }
      }

    }

    .table-page {
      padding: 20px;
    }

    .one {
      width: 18%;
    }

    .two {
      width: 30%;
    }

    .third {
      width: 14%;
    }

    .four {
      width: 12%;
    }

    .fine {
      width: 26%;
      span {
        margin: 0 20px 8px 0;
      }
    }
  }

  .about-order {
    .list {
      margin-top: 12px;
      padding-top: 12px;
      border-radius: 8px;

      .order-tab {
        margin: 0 12px;
      }

      .order-list {
        margin: 8px 12px;
      }

      .one {
        width: auto;
      }

      .two {
        width: 150px;
      }

      .third {
        width: 90px;
      }

      .four {
        width: 90px;
      }

      .fine {
        width: 154px;

        span {
          margin: 0 8px 8px 0;
        }

        .pay {
          margin-left: 0 !important;
        }
      }

      .table-page {
        padding: 12px 20px;
      }
    }
  }
  .comfirmPic{
    width: 24px;
    height: 24px;
    display: block;
    margin: 28px auto 10px;
  }
  .sheet{
    display: block;
    padding: 10px 8px;
    line-height: 20px;
    font-size: 14px;
    color: #222;
    margin-bottom: 8px;
    background-color: $hoverBg;
  }
  .comfirmOrder{
    display: block;
    height: 20px;
    line-height: 20px;
    color: #717171;
    font-size: 14px;
    text-align: center;
    margin-bottom: 35px;
  }
  /deep/{
    .el-dialog__body{
      padding: 8px;
    }
    .el-dialog__footer{
      padding: 10px 0;
      text-align: center;
      background-color: #F5F5F5;
    }
    .im-pagination {
      z-index: 0;
    }
  }
  .textarea-input {
      position: relative;
      .textarea {
          border: 1px solid #eee;
          outline: none; // 去除聚焦边框
          resize: none; // 去除右下的可拖动
          appearance: none; // 去除内阴影样式
          padding: 10px; // 增加内边距
          box-sizing: border-box;
          height: 96px;
          background: #fff;
          border-radius: 3px;
          width: 100%;
      }
      .word-limit {
          .green {
              color: #00D76E;
          }
          position: absolute;
          right: 10px;
          bottom: 10px;
          font-size: 12px;
          font-weight: 400;
          color: #999999;
      }
  }
  .loading-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
  }
</style>
<style lang="scss">

</style>

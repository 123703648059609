var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "brand-detail-content" },
    [
      _c("Search", { attrs: { "index-nav": true } }),
      _c(
        "div",
        { staticClass: "nav-address" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { "separator-class": "el-icon-arrow-right" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页"),
              ]),
              _c(
                "el-breadcrumb-item",
                { attrs: { to: { path: "/brand-center" } } },
                [_vm._v("品牌旗舰店")]
              ),
              _c("el-breadcrumb-item", [_vm._v("品牌详情")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "advert-content" }, [
        _c("div", { staticClass: "infor" }, [
          _c("div", { staticClass: "w-1200 info-posi" }, [
            _c("div", { staticClass: "info-logo" }, [
              _c("img", {
                attrs: {
                  src: _vm.brandInfo.brandLogoStr || _vm.brandDefaultImg,
                },
                on: {
                  error: function ($event) {
                    _vm.brandInfo.brandLogoStr = _vm.brandDefaultImg
                  },
                },
              }),
            ]),
            _c("div", { ref: "brandInfo", staticClass: "info-content" }, [
              _c("p", [_vm._v(_vm._s(_vm.brandInfo.brandNm))]),
              _c("div", { ref: "brandInfoTips", staticClass: "info-tips" }, [
                _c("span", { class: { "expend-content": _vm.expendArrow } }, [
                  _vm._v(" " + _vm._s(_vm.brandInfo.brandStory) + " "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.infoTips,
                          expression: "infoTips",
                        },
                      ],
                      class: {
                        "expend-arrow": !_vm.expendArrow,
                        arrow: _vm.expendArrow,
                      },
                      on: {
                        click: function ($event) {
                          _vm.expendArrow = !_vm.expendArrow
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/promotion-zone/brand-center/arrow-white-bottom.png"),
                        },
                      }),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm.page["PC_SHOP_ADV_BANNER1"].length
          ? _c(
              "div",
              { staticClass: "advert-banner" },
              [
                _c(
                  "swiper",
                  { attrs: { options: _vm.swiperOption } },
                  _vm._l(
                    _vm.page["PC_SHOP_ADV_BANNER1"],
                    function (item, index) {
                      return _c("swiper-slide", { key: index }, [
                        _c("img", {
                          attrs: { src: item.sysFileUrl, alt: "" },
                          on: {
                            click: function ($event) {
                              return _vm.bindUrl(item.link)
                            },
                          },
                        }),
                      ])
                    }
                  ),
                  1
                ),
                _c("div", { staticClass: "swiper-pagination" }),
                _vm._m(0),
              ],
              1
            )
          : _c("div", { staticClass: "seize" }),
      ]),
      _c("div", { staticClass: "advert-space" }, [
        _c("div", { staticClass: "w-1200" }, [
          _vm.page["PC_SHOP_ADV_BANNER_TOP1"].length > 0
            ? _c("div", { staticClass: "space-odei" }, [
                _c(
                  "div",
                  { staticClass: "space-list space-one" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: _vm.page["PC_SHOP_ADV_BANNER_TOP1"][0].link,
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.page["PC_SHOP_ADV_BANNER_TOP1"][0]
                              .sysFileUrl,
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.page["PC_SHOP_ADV_BANNER_TOP2"].length > 0 ||
          _vm.page["PC_SHOP_ADV_BANNER_TOP3"].length > 0
            ? _c("div", { staticClass: "space-odei" }, [
                _vm.page["PC_SHOP_ADV_BANNER_TOP2"].length > 0
                  ? _c(
                      "div",
                      { staticClass: "space-list space-two" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: _vm.page["PC_SHOP_ADV_BANNER_TOP2"][0].link,
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.page["PC_SHOP_ADV_BANNER_TOP2"][0]
                                  .sysFileUrl,
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.page["PC_SHOP_ADV_BANNER_TOP3"].length > 0
                  ? _c(
                      "div",
                      { staticClass: "space-list space-two" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: _vm.page["PC_SHOP_ADV_BANNER_TOP3"][0].link,
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.page["PC_SHOP_ADV_BANNER_TOP3"][0]
                                  .sysFileUrl,
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.page["PC_SHOP_ADV_BANNER_TOP4"].length > 0 ||
          _vm.page["PC_SHOP_ADV_BANNER_TOP5"].length > 0 ||
          _vm.page["PC_SHOP_ADV_BANNER_TOP6"].length > 0 ||
          _vm.page["PC_SHOP_ADV_BANNER_TOP7"].length > 0
            ? _c("div", { staticClass: "space-odei" }, [
                _vm.page["PC_SHOP_ADV_BANNER_TOP4"].length > 0
                  ? _c(
                      "div",
                      { staticClass: "space-list space-three" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: _vm.page["PC_SHOP_ADV_BANNER_TOP4"][0].link,
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.page["PC_SHOP_ADV_BANNER_TOP4"][0]
                                  .sysFileUrl,
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.page["PC_SHOP_ADV_BANNER_TOP5"].length > 0
                  ? _c(
                      "div",
                      { staticClass: "space-list space-three" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: _vm.page["PC_SHOP_ADV_BANNER_TOP4"][0].link,
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.page["PC_SHOP_ADV_BANNER_TOP5"][0]
                                  .sysFileUrl,
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.page["PC_SHOP_ADV_BANNER_TOP6"].length > 0
                  ? _c(
                      "div",
                      { staticClass: "space-list space-three" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: _vm.page["PC_SHOP_ADV_BANNER_TOP4"][0].link,
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.page["PC_SHOP_ADV_BANNER_TOP6"][0]
                                  .sysFileUrl,
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.page["PC_SHOP_ADV_BANNER_TOP7"].length > 0
                  ? _c(
                      "div",
                      { staticClass: "space-list space-three" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: _vm.page["PC_SHOP_ADV_BANNER_TOP4"][0].link,
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.page["PC_SHOP_ADV_BANNER_TOP7"][0]
                                  .sysFileUrl,
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "product-content" },
        [
          _c("category-search", {
            ref: "categorySearch",
            attrs: { "view-model": _vm.viewModel, searchForm: _vm.searchForm },
            on: {
              "change-page": _vm.handleChangViewModel,
              search: _vm.queryData,
            },
          }),
          _vm.viewModel === "ListBoard"
            ? _c("list-board", {
                attrs: {
                  "table-data": _vm.tableData,
                  "is-loading": _vm.isLoading,
                },
              })
            : _vm._e(),
          _vm.viewModel === "PhotoBoard"
            ? _c("photo-board", {
                attrs: {
                  "table-data": _vm.tableData,
                  "is-loading": _vm.isLoading,
                },
              })
            : _vm._e(),
          _c("pagination", {
            attrs: {
              isOnlyOnePageShow: !_vm.total > 0,
              total: _vm.total,
              "current-page": _vm.currentPage,
              "page-size": _vm.pageSize,
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function ($event) {
                _vm.currentPage = $event
              },
              "update:pageSize": function ($event) {
                _vm.pageSize = $event
              },
              "update:page-size": function ($event) {
                _vm.pageSize = $event
              },
              pagination: _vm.handlePagination,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "swiper-button" }, [
      _c("div", { staticClass: "swiper-button-next" }),
      _c("div", { staticClass: "swiper-button-prev" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
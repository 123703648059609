var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "list" },
    [
      _c("div", { staticClass: "order-tab" }, [
        _c(
          "div",
          { staticClass: "one" },
          [
            _c(
              "el-dropdown",
              {
                attrs: { trigger: "click" },
                on: { command: _vm.handleCommand },
              },
              [
                _c("span", { staticClass: "el-dropdown-link" }, [
                  _vm._v(" " + _vm._s(_vm.more[_vm.moreIndex])),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
                ]),
                _c(
                  "el-dropdown-menu",
                  {
                    staticStyle: { width: "100px" },
                    attrs: { slot: "dropdown" },
                    slot: "dropdown",
                  },
                  _vm._l(_vm.more, function (item, i) {
                    return _c(
                      "el-dropdown-item",
                      { key: i, attrs: { command: item } },
                      [_vm._v(" " + _vm._s(item) + " ")]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "two" }, [_vm._v("订单信息")]),
        _c("div", { staticClass: "third" }, [_vm._v("订单总品规")]),
        _c("div", { staticClass: "four" }, [_vm._v("订单状态")]),
        _c("div", { staticClass: "fine" }, [_vm._v("交易操作")]),
      ]),
      _vm.data.length || _vm.isLoading
        ? _c(
            "div",
            { staticClass: "order-list-container" },
            _vm._l(_vm.data, function (item, index) {
              return _vm.data.length
                ? _c("div", { key: index, staticClass: "order-list" }, [
                    item.isTransferRecordFlag === "Y"
                      ? _c("img", {
                          staticClass: "diaobo",
                          attrs: {
                            src: require("@/assets/img/icon/diaobo.png"),
                          },
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "order-list-header" }, [
                      _c("div", { staticClass: "order-list-header-content" }, [
                        _c("span", { staticClass: "one" }, [
                          _vm._v(_vm._s(item.createDate)),
                        ]),
                        _c("span", [
                          _vm._v("订单编号：" + _vm._s(item.orderNum)),
                        ]),
                        item.saleOrderNum
                          ? _c("span", [
                              _vm._v(
                                "ERP销售订单号：" +
                                  _vm._s(item.saleOrderNum.join(","))
                              ),
                            ])
                          : _vm._e(),
                        _c("span", [
                          _vm._v("订单来源：" + _vm._s(item.orderSourceCode)),
                        ]),
                        _c("span", [
                          _vm._v(
                            "是否业务员订单：" +
                              _vm._s(item.isSalesmanOrder === "N" ? "否" : "是")
                          ),
                        ]),
                        _vm.userType !== "SALESMAN"
                          ? _c("span", [
                              _vm._v("客户名称：" + _vm._s(item.buyerNm)),
                            ])
                          : _vm._e(),
                        _vm.userType == "SALESMAN"
                          ? _c("span", [
                              _vm._v(
                                "是否调拨订单：" +
                                  _vm._s(
                                    item.isTransferRecordFlag === "N"
                                      ? "否"
                                      : "是"
                                  )
                              ),
                            ])
                          : _vm._e(),
                        _c("br"),
                        _vm.userType === "SALESMAN"
                          ? _c("span", { staticStyle: { color: "#E02020" } }, [
                              _vm._v("客户ID：" + _vm._s(item.erpCode || "--")),
                            ])
                          : _vm._e(),
                        _vm.userType === "SALESMAN"
                          ? _c("span", { staticStyle: { color: "#E02020" } }, [
                              _vm._v(
                                "客户名称：" + _vm._s(item.buyerNm || "--")
                              ),
                            ])
                          : _vm._e(),
                        _vm.userType === "SALESMAN" &&
                        item.ettleAccountNm !== "默认分支机构"
                          ? _c("span", { staticStyle: { color: "#E02020" } }, [
                              _vm._v(
                                "分支机构ID：" +
                                  _vm._s(
                                    item.ettleAccountNm === "默认分支机构"
                                      ? "-"
                                      : item.code
                                  )
                              ),
                            ])
                          : _vm._e(),
                        _vm.userType === "SALESMAN" &&
                        item.ettleAccountNm !== "默认分支机构"
                          ? _c("span", { staticStyle: { color: "#E02020" } }, [
                              _vm._v(
                                "分支机构名称：" +
                                  _vm._s(
                                    item.ettleAccountNm === "默认分支机构"
                                      ? "-"
                                      : item.ettleAccountNm
                                  )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        { staticClass: "order-list-header-btn" },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                width: "110px",
                                "margin-left": "20px",
                                padding: "0",
                              },
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.getOrderDeatils(item.orderId)
                                },
                              },
                            },
                            [_vm._v("查看购物清单")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "order-list-content" }, [
                      _c("div", { staticClass: "one" }, [
                        _c("img", {
                          attrs: {
                            alt: "",
                            src: item.picture || _vm.productDefaultImg,
                          },
                          on: {
                            error: function ($event) {
                              item.picture = _vm.productDefaultImg
                            },
                            click: function ($event) {
                              return _vm.goDetails(item.orderId)
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "two" }, [
                        _c("p", [
                          _vm._v("订单金额: "),
                          _c("span", [
                            _vm._v(
                              "￥" +
                                _vm._s(_vm.$util.toFixed(item.orderTotalAmount))
                            ),
                          ]),
                        ]),
                        _c("p", [
                          _vm._v("支付方式: "),
                          item.paymentWayCode === "CASH"
                            ? _c("span", [_vm._v("在线支付")])
                            : _vm._e(),
                          item.paymentWayCode === "CREDIT"
                            ? _c("span", [_vm._v("赊销")])
                            : _vm._e(),
                          item.isUseIntegral === "Y"
                            ? _c("span", [_vm._v("(积分抵扣)")])
                            : _vm._e(),
                        ]),
                        item.refundFlag === 2 && item.isPayed === "Y"
                          ? _c("p", [_vm._v("补单")])
                          : _vm._e(),
                        item.isFullRefund === "Y" && item.isPayed === "Y"
                          ? _c("p", [
                              _vm._v(
                                "已全额退款：￥" +
                                  _vm._s(item.refundTotalAmount)
                              ),
                            ])
                          : _vm._e(),
                        item.isFullRefund === "N" && item.isPayed === "Y"
                          ? _c("p", [
                              _vm._v(
                                "已部分退款：￥" +
                                  _vm._s(item.refundTotalAmount)
                              ),
                            ])
                          : _vm._e(),
                        _c("p", [
                          _vm._v(
                            "备注：" +
                              _vm._s(item.remark === "" ? "无" : item.remark)
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "third" }, [
                        _c("p", [
                          _vm._v("共" + _vm._s(item.orderProductTotal) + "种"),
                        ]),
                      ]),
                      _c("div", { staticClass: "four" }, [
                        _c("p", [_vm._v(_vm._s(item.orderStatus))]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "fine" },
                        [
                          item.processStatCode === "WAIT_APPROVE" &&
                          (_vm.userType === "BUYERS" ||
                            _vm.userType === "BUYERS_TEAM")
                            ? _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.bindVisible(
                                        "确认订单",
                                        item.orderId
                                      )
                                    },
                                  },
                                },
                                [_vm._v("确认订单")]
                              )
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goDetails(item.orderId)
                                },
                              },
                            },
                            [_vm._v("订单详情")]
                          ),
                          item.processStatCode === "WAIT_PAY"
                            ? _c(
                                "span",
                                {
                                  staticClass: "pay",
                                  on: {
                                    click: function ($event) {
                                      return _vm.toPay(item.orderId)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "去支付 " + _vm._s(_vm.time(item.countdown))
                                  ),
                                ]
                              )
                            : _vm._e(),
                          (item.processStatCode === "WAIT_PAY" ||
                            item.processStatCode === "WAIT_APPROVE") &&
                          _vm.userType === "BUYERS"
                            ? _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.bindCancelOrder(item.orderId)
                                    },
                                  },
                                },
                                [_vm._v("取消订单")]
                              )
                            : _vm._e(),
                          _vm.userType === "BUYERS" ||
                          _vm.userType === "BUYERS_TEAM"
                            ? _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.buyAgain(item.orderId)
                                    },
                                  },
                                },
                                [_vm._v("再次购买")]
                              )
                            : _vm._e(),
                          _vm.userType === "SALESMAN" &&
                          item.processStatCode != "WAIT_PAY" &&
                          item.processStatCode != "WAIT_APPROVE"
                            ? _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.buyAgain(item.orderId)
                                    },
                                  },
                                },
                                [_vm._v("再次购买")]
                              )
                            : _vm._e(),
                          item.processStatCode === "SEND" ||
                          item.processStatCode === "SENDING" ||
                          item.processStatCode === "FINISH"
                            ? _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.goOrderExpress(item.orderId)
                                    },
                                  },
                                },
                                [_vm._v("查看物流")]
                              )
                            : _vm._e(),
                          item.processStatCode === "FINISH"
                            ? _c("span", { on: { click: _vm.toRefunds } }, [
                                _vm._v("申请退货"),
                              ])
                            : _vm._e(),
                          _vm.paramValueString === "Y" &&
                          item.processStatCode === "WAIT_SEND" &&
                          item.refundFlag == 0 &&
                          item.paymentWayCode === "CASH" &&
                          item.isPayed === "Y"
                            ? _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.refundDialog(item)
                                    },
                                  },
                                },
                                [_vm._v("退款")]
                              )
                            : _vm._e(),
                          item.processStatCode === "SENDING" ||
                          item.processStatCode === "SEND"
                            ? [
                                item.isSinglePackage === "Y" &&
                                item.singlePackageId
                                  ? _c(
                                      "span",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.bindVisible(
                                              "确认收货",
                                              item.orderId,
                                              item.singlePackageId
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("确认收货")]
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ])
                : _vm._e()
            }),
            0
          )
        : _vm._e(),
      !_vm.data.length && !_vm.isLoading
        ? _c("div", { staticClass: "empty-box" }, [
            _c(
              "div",
              [
                _c("el-image", {
                  staticStyle: { width: "160px", height: "160px" },
                  attrs: { src: _vm.emptyDefault, fit: "fill" },
                }),
                _c("p", { staticClass: "empty-tip" }, [_vm._v("暂无订单")]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("im-pagination", {
        staticClass: "table-page",
        attrs: {
          "show-collection": true,
          total: _vm.total,
          "page-sizes": _vm.pageSizes,
          "pager-count": _vm.pagerCount,
          "current-page": _vm.currentPage,
          "page-size": _vm.pageSize,
        },
        on: {
          "update:currentPage": function ($event) {
            _vm.currentPage = $event
          },
          "update:current-page": function ($event) {
            _vm.currentPage = $event
          },
          "update:pageSize": function ($event) {
            _vm.pageSize = $event
          },
          "update:page-size": function ($event) {
            _vm.pageSize = $event
          },
          pagination: _vm.handlePagination,
        },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-table",
          attrs: {
            width: _vm.dialogStatus === "下载药检单" ? "900px" : "400px",
            title: _vm.dialogStatus,
            visible: _vm.dialogTableVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _vm.dialogStatus == "确认收货"
            ? _c("img", {
                staticClass: "comfirmPic",
                attrs: { src: _vm.comfirmGoodsIcon },
              })
            : _vm._e(),
          _vm.dialogStatus == "确认订单"
            ? _c("img", {
                staticClass: "comfirmPic",
                attrs: { src: _vm.comfirmOrderIcon },
              })
            : _vm._e(),
          _vm.dialogStatus != "下载药检单"
            ? _c("div", { staticClass: "comfirmOrder" }, [
                _vm._v("您是否" + _vm._s(_vm.dialogStatus)),
              ])
            : _vm._e(),
          _vm.dialogStatus === "下载药检单"
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "sheet" }, [
                    _vm._v(
                      " 以下订单中的商品已检索到药检单，请勾选下载，若存在缺失资料品种请联系业务员（未出库品种不可下载） "
                    ),
                  ]),
                  _c(
                    "im-table-page",
                    {
                      ref: "tablePage",
                      attrs: {
                        data: _vm.tableData,
                        "remote-method": _vm.queryData,
                        "more-check-box": _vm.moreCheckBox,
                      },
                      on: {
                        "update:data": function ($event) {
                          _vm.tableData = $event
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          type: "index",
                          width: "60px",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "40px",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "productNm",
                          label: "商品名称",
                          "min-width": "140px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$util.getGoodsName(scope.row)
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3325910173
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "spec",
                          label: "规格",
                          "min-width": "100px",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "manufacturer",
                          label: "生产厂家",
                          "min-width": "183px",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "batch",
                          label: "商品批号",
                          width: "100px",
                          align: "center",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _vm.dialogStatus == "确认收货"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.bindComfirmOrder },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
              _vm.dialogStatus == "确认订单"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.bindSureOrder },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
              _vm.dialogStatus == "下载药检单"
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.isDownloadingSheet,
                          expression: "isDownloadingSheet",
                        },
                      ],
                      attrs: {
                        type: "primary",
                        disabled: !_vm.fileIds.length > 0,
                      },
                      on: { click: _vm.downloadSheet },
                    },
                    [_vm._v("下载药检单")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogTableVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-table",
          attrs: {
            width: "1000px",
            title: "购物清单",
            visible: _vm.dialogListVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogListVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "padding-top": "1px" },
              attrs: {
                data: _vm.orderDetail.orderItemVos,
                border: "",
                "max-height": _vm.dialogTableHeight + "px",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "商品信息",
                  width: "290px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isTransferRecordFlag === "Y"
                          ? _c("img", {
                              staticClass: "diaobo2",
                              attrs: {
                                src: require("@/assets/img/icon/diaobo.png"),
                              },
                            })
                          : _vm._e(),
                        _c("div", { staticClass: "product-info" }, [
                          _c(
                            "div",
                            {
                              staticClass: "product-image",
                              on: {
                                click: function ($event) {
                                  return _vm.productDetail(scope.row)
                                },
                              },
                            },
                            [
                              _c("el-image", {
                                attrs: {
                                  src: scope.row.thumbnailUrl,
                                  fit: "fit",
                                },
                                on: {
                                  error: function ($event) {
                                    scope.row.thumbnailUrl =
                                      _vm.productDefaultImg
                                  },
                                },
                              }),
                              scope.row.orderItemStatus === "INVALID"
                                ? _c("img", {
                                    staticClass: "oneScrap",
                                    attrs: {
                                      src: require("@/assets/img/detail/yizuofei.png"),
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("ul", { staticClass: "product-detail" }, [
                            _c(
                              "li",
                              {
                                staticClass: "name",
                                on: {
                                  click: function ($event) {
                                    return _vm.productDetail(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$util.getGoodsName(scope.row)) +
                                    " "
                                ),
                              ]
                            ),
                            _c("li", { staticClass: "detail" }, [
                              _c("p", [
                                _vm._v("规格："),
                                _c("span", { staticClass: "spec" }, [
                                  _vm._v(_vm._s(scope.row.spec)),
                                ]),
                                _vm._v(" 单位："),
                                _c("span", [_vm._v(_vm._s(scope.row.unit))]),
                              ]),
                            ]),
                            _c("li", { staticClass: "manufacturer detail" }, [
                              _vm._v(
                                "生产厂家:" + _vm._s(scope.row.manufacturer)
                              ),
                            ]),
                            scope.row.orderItemType === "PRESENT"
                              ? _c(
                                  "li",
                                  {
                                    staticClass: "manufacturer detail",
                                    staticStyle: { color: "#2B2B2B" },
                                  },
                                  [_vm._v("[特殊商品]")]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                        _c("div"),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "单价",
                  prop: "money",
                  width: "90px",
                  "show-overflow-tooltip": "",
                  align: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "textColor" }, [
                          _vm._v(
                            "¥" +
                              _vm._s(
                                scope.row.orderItemType === "PRESENT"
                                  ? "0.00"
                                  : scope.row.originalUnitPrice
                              )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "购买数量",
                  prop: "number",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.num))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "right",
                  label: "实付金额",
                  prop: "code",
                  width: "150px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("p", { staticClass: "textColor" }, [
                          _vm._v(
                            "¥" + _vm._s(_vm.$util.toFixed(scope.row.subtotal))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "优惠明细",
                  prop: "number",
                  "show-overflow-tooltip": "",
                  align: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "textColor" }, [
                          _vm._v("-￥" + _vm._s(scope.row.discountAmount)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "100px", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "apply" },
                          [
                            (_vm.userType === "BUYERS" ||
                              _vm.userType === "BUYERS_TEAM") &&
                            scope.row.orderItemType !== "PRESENT"
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "download",
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.beforeAddItem(
                                          scope.row.skuId,
                                          "NORMAL",
                                          scope.row.num,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("再次购买")]
                                )
                              : _vm._e(),
                            _vm.userType === "SALESMAN" &&
                            _vm.orderDetail.orderVo.processStatCode !=
                              "WAIT_PAY" &&
                            _vm.orderDetail.orderVo.processStatCode !=
                              "WAIT_APPROVE" &&
                            scope.row.orderItemType !== "PRESENT"
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "download",
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.beforeAddItem(
                                          scope.row.skuId,
                                          "NORMAL",
                                          scope.row.num,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("再次购买")]
                                )
                              : _vm._e(),
                            _vm.orderDetail.orderVo.processStatCode === "FINISH"
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "download",
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.bindVisible("下载药检单")
                                      },
                                    },
                                  },
                                  [_vm._v("下载药检单")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-table",
          attrs: {
            width: "400px",
            title: "退款理由",
            visible: _vm.dialogRefundVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogRefundVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "textarea-input" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.refundRemark,
                  expression: "refundRemark",
                },
              ],
              staticClass: "textarea",
              attrs: { placeholder: "请输入具体原因", maxlength: "40" },
              domProps: { value: _vm.refundRemark },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.refundRemark = $event.target.value
                },
              },
            }),
            _c("div", { staticClass: "word-limit" }, [
              _c("span", { staticClass: "green" }, [
                _vm._v(_vm._s(_vm.refundRemark.length)),
              ]),
              _vm._v("/40 "),
            ]),
          ]),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.toApplyRefunds },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogRefundVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.refundLoading,
            expression: "refundLoading",
          },
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.refundLoading,
            expression: "refundLoading",
          },
        ],
        staticClass: "loading-mask",
        attrs: { "element-loading-text": "退款请求中" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shop-cart-content" },
    [
      _c(
        "div",
        { staticClass: "main" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              attrs: {
                border: "",
                data: _vm.goodsData,
                "header-row-class-name": "header-row",
              },
              on: {
                "update:data": function ($event) {
                  _vm.goodsData = $event
                },
                "selection-change": _vm.handleSelectionChange,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  "class-name": "index",
                  align: "center",
                  label: "序号",
                  width: "45",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  "class-name": "selection",
                  align: "center",
                  width: "45",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  "class-name": "padding",
                  label: "商品图",
                  width: "80px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "commodity-image" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: { to: "/detail/" + scope.row.productId },
                              },
                              [
                                _c("img", {
                                  staticClass: "product-img",
                                  attrs: {
                                    src:
                                      (scope.row.productPicMap &&
                                        scope.row.productPicMap["48X48"]) ||
                                      _vm.productDefaultImg,
                                    alt: "",
                                  },
                                  on: {
                                    error: function ($event) {
                                      scope.row.productPicMap["48X48"] =
                                        _vm.productDefaultImg
                                    },
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "productCode",
                  label: "商品编码",
                  width: "70px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  "class-name": "visibility",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(" " + _vm._s(scope.row.productCode) + " "),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "productNm",
                  "class-name": "visibility",
                  label: "商品名称",
                  width: "160px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.isGroup
                          ? _c(
                              "div",
                              { staticClass: "table-product-name" },
                              [
                                _c(
                                  "p",
                                  [
                                    scope.row.otcTypeNm &&
                                    scope.row.otcTypeNm !== "其他"
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "row-tag tag-otc",
                                            class: {
                                              jiaOtc:
                                                scope.row.otcTypeNm === "甲OTC",
                                              yiOtc:
                                                scope.row.otcTypeNm === "乙OTC",
                                              rxOtc:
                                                scope.row.otcTypeNm === "RX",
                                            },
                                          },
                                          [_vm._v(_vm._s(scope.row.otcTypeNm))]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: "/detail/" + scope.row.productId,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$util.getGoodsName(scope.row)
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c("tagList", {
                                  attrs: { taglist: scope.row.tagList },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "spec",
                  align: "center",
                  label: "规格",
                  width: "70px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "manufacturer",
                  align: "left",
                  label: "厂家(上市许可持有人)/产地",
                  width: "170px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "unit",
                  align: "center",
                  label: "单位",
                  width: "56px",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "stock",
                  align: "center",
                  label: "库存",
                  width: "60px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            class: {
                              "inventory-warning":
                                scope.row.stockQuantity == "<100" ||
                                Number(scope.row.stockQuantity) < 100,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                Math.floor(scope.row.stockQuantity) ||
                                  scope.row.stockQuantity
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "最小购买倍数",
                  width: "90px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getRealMinBuyNum(scope.row) || "/") +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "采购数量",
                  width: "112px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input-number", {
                          attrs: {
                            size: "mini",
                            precision: _vm.getDecimalPointNum(scope.row),
                            min: _vm.getRealMinBuyNum(scope.row),
                            step: _vm.getRealMinBuyNum(scope.row),
                            "step-strictly": "",
                          },
                          model: {
                            value: scope.row.enquiryNumber,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "enquiryNumber", $$v)
                            },
                            expression: "scope.row.enquiryNumber",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "right",
                  label: "单价",
                  "mi-width": "75px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v("¥" + _vm._s(scope.row.price))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "right",
                  label: "小计",
                  "min-width": "75px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticStyle: { color: "#FF3B30" } }, [
                          _vm._v(
                            "¥" +
                              _vm._s(
                                (scope.row.originalTotalPrice * 1).toFixed(2)
                              )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "pushTime",
                  align: "center",
                  label: "到货时间",
                  width: "160px",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", width: "70", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.shoppingCartItemType !==
                          "FIX_DISCOUNT_PACKAGE" &&
                        scope.row.shoppingCartItemType !==
                          "COLLOCATE_DISCOUNT_PACKAGE"
                          ? _c("div", { staticClass: "table-operation" }, [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.openDeleteDialog(
                                        scope.row.regId
                                      )
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ])
                          : _c("div", [_vm._v("/")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("template", { slot: "empty" }, [
                _vm.goodsData.length === 0
                  ? _c("div", { staticClass: "empty-default" }, [
                      _c(
                        "div",
                        { staticClass: "empty-box" },
                        [
                          _c("el-image", {
                            staticStyle: { width: "160px", height: "160px" },
                            attrs: { src: _vm.emptyDefault, fit: "fill" },
                          }),
                          _c("p", { staticClass: "empty-tip" }, [
                            _vm._v("到货通知为空，你可以去首页逛逛~"),
                          ]),
                          _c(
                            "router-link",
                            { attrs: { to: "/" } },
                            [
                              _c(
                                "el-button",
                                { attrs: { type: "primary", size: "mini" } },
                                [_vm._v("去首页逛逛")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            2
          ),
          _c("im-pagination", {
            staticClass: "table-page",
            attrs: {
              "show-collection": true,
              total: _vm.total,
              "page-sizes": _vm.pageSize,
              "current-page": _vm.currentPage,
              "page-size": _vm.pageSize,
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function ($event) {
                _vm.currentPage = $event
              },
              "update:pageSize": function ($event) {
                _vm.pageSize = $event
              },
              "update:page-size": function ($event) {
                _vm.pageSize = $event
              },
              pagination: _vm.handlePagination,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogDeleteVisible,
            title: "提示",
            width: "400px",
            top: "30vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogDeleteVisible = $event
            },
          },
        },
        [
          _c("img", {
            staticClass: "icon",
            attrs: {
              src: require("@/assets/img/customer-service/icon-shanchu.png"),
              alt: "",
            },
          }),
          _c("p", { staticStyle: { padding: "10px 0 20px" } }, [
            _vm._v(
              _vm._s(
                _vm.regId === "" ? "确认删除所选商品？" : "确认删除该商品？"
              )
            ),
          ]),
          _c(
            "el-button",
            {
              staticClass: "agree",
              attrs: { type: "primary", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.deleteRow()
                },
              },
            },
            [_vm._v("确认")]
          ),
          _c(
            "el-button",
            {
              staticClass: "agree",
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  _vm.dialogDeleteVisible = false
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: { "background-color": "#fff", "padding-bottom": "10px" },
        },
        [
          _c("div", { staticClass: "settlement-container" }, [
            _c("div", { staticClass: "settlement-row" }, [
              _c(
                "div",
                { staticClass: "row-left" },
                [
                  _vm.goodsData.length
                    ? _c(
                        "el-checkbox",
                        {
                          class: _vm.checked ? "disabled-checkbox" : "",
                          on: { change: _vm.toggleAllSelection },
                          model: {
                            value: _vm.checked,
                            callback: function ($$v) {
                              _vm.checked = $$v
                            },
                            expression: "checked",
                          },
                        },
                        [_vm._v("全选")]
                      )
                    : _vm._e(),
                  _vm.goodsData.length
                    ? _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.openDeleteDialog()
                            },
                          },
                        },
                        [_vm._v("删除选中商品")]
                      )
                    : _vm._e(),
                  _c("router-link", { attrs: { to: "/" } }, [
                    _c("span", [_vm._v("返回首页")]),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "row-right" }, [
                _c("div", { staticClass: "settlement" }, [
                  _c("div", { staticClass: "total-money" }, [
                    _vm._v(" 商品总金额： "),
                    _c("span", { staticClass: "money" }, [
                      _vm._v("¥" + _vm._s(_vm.goodsTotal.toFixed(2))),
                    ]),
                  ]),
                  _c("div", { staticClass: "product-detail" }, [
                    _c("span", [
                      _vm._v("总件数：" + _vm._s(_vm.checkedArr.length)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "settlement-button-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "immediate-settlement",
                      class: { disable: _vm.checkedArr.length == 0 },
                      on: { click: _vm.handleCollateOrder },
                    },
                    [_vm._v("加入购物车")]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="im-search-pad">
    <el-form class="search-form" ref="searchForm" :label-width="labelWidth" :inline="true" :model="model">
      <slot/>
    </el-form>
    <div class="search-pad-button" :style="{width: needOtherBtn ? '172px' : '60px'}">
      <el-button size="mini" type="primary" @click="onSearch">搜索</el-button>
      <template v-if="needOtherBtn">
        <el-button size="mini" type="text" @click="onReset">重置</el-button>
        <el-button size="mini" class="more" v-if="hasExpand" type="text" @click="onExpand">
          {{ isExpanded? '收起' : '更多' }}
          <i :class="isExpanded ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"/>
        </el-button>
      </template>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'SearchPad',
    props: {
      needOtherBtn:{
          type:Boolean,
          default:true
      },
      model: {
        type: Object,
        default: () => {
          return {}
        }
      },
      labelWidth:{
        type: String
      },
      isExpand: {
        type: Boolean,
        default: false
      },
      hasExpand: {
        type: Boolean,
        default: true
      },
      isSearchBtn: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        isExpanded: this.isExpand
      }
    },
    methods: {
      onSearch () {
        this.$emit('search')
      },
      onReset () {
        this.$refs['searchForm'].resetFields()
        this.$emit('reset')
      },
      onExpand () {
        this.isExpanded = !this.isExpanded
        this.$emit('update:isExpand', this.isExpanded)
      }
    }
  }
</script>
<style lang="scss" scoped>
  .im-search-pad {
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .search-form {
      flex: 1;
    }

    .search-pad-button {
      height: 32px;
      display: flex;
      align-items: center;

      /deep/ .el-button--text {
        padding: 0 10px 0 0;
        border-radius: 0;
        border: 0;
        border-right: 1px solid;
        color: rgba(0, 0, 0, 0.65);

        &.more {
          border-right: 0;
        }
      }
    }
  }
</style>

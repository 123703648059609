import {ApiGet, ApiPost} from "@/api";

/**
 * @description 客服联系方式
 */
export const getServiceTel = () => ApiGet('/pc/front/user/serviceTel')

/**
 * @description 获取企业基本信息
 */
export const saveFrontVerification = data => ApiPost('/pc/front/user/pcFrontVerification',data)

/**
 * @description 修改密码
 */
export const updatePcFrontPassword = data => ApiPost('/pc/front/user/updatePcFrontPassword',data)
<template>
  <div class="store-order-list">
    <search-pad
        class="client-file-search-pad"
        :model="searchForm"
        label-width="70px"
        :is-expand.sync="isExpand"
        @search="handleSearch"
        @reset="handleReset"
    >

      <search-pad-item v-show="isExpand" prop="erpCode" label="客户ID">
        <el-input v-model="searchForm.erpCode" placeholder="输入客户ID"/>
      </search-pad-item>
      <search-pad-item v-show="isExpand" prop="buyerNm" label="客户名称">
        <el-input v-model="searchForm.buyerNm" placeholder="输入客户名称"/>
      </search-pad-item>
      <search-pad-item v-show="isExpand" prop="productCode" label="商品编号">
        <el-input v-model="searchForm.productCode" placeholder="输入商品编号"/>
      </search-pad-item>
      <search-pad-item prop="productNm" label="商品名称">
        <el-input v-model="searchForm.productNm" placeholder="输入商品名称"/>
      </search-pad-item>
      <search-pad-item v-show="isExpand" prop="payment" label="付款方式">
        <el-select v-model="searchForm.paymentWayCode" style="width: 180px" placeholder="请选择付款方式">
          <el-option
              v-for="item in payList"
              :key="item.paymentWayCode"
              :label="item.name"
              :value="item.paymentWayCode">
          </el-option>
        </el-select>
      </search-pad-item>
      <search-pad-item style="margin-right: 0" prop="orderSource" label="订单来源">
        <el-select style="width: 180px" v-model="searchForm.orderSource" placeholder="请选择付款方式">
          <el-option
              v-for="item in sourceList"
              :key="item.key"
              :label="item.name"
              :value="item.key">
          </el-option>
        </el-select>
      </search-pad-item>
      <search-pad-item prop="orderNum" label="订单编号">
        <el-input v-model="searchForm.orderNum" placeholder="输入订单编号"/>
      </search-pad-item>
      <search-pad-item prop="dealTime" v-show="isExpand" label="成交时间">
        <el-date-picker
            v-model="searchForm.dealTime"
            @change="handleChange"
            style="width: 280px"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </search-pad-item>
    </search-pad>

    <el-tabs v-model="activeName" @tab-click="handleTab">
      <el-tab-pane label="全部" name=""></el-tab-pane>
      <el-tab-pane name="WAIT_APPROVE">
        <template slot="label">
          <el-badge :value="waitConfirmOrderNum" v-if="waitConfirmOrderNum" class="item">
            <span>待确认</span>
          </el-badge>
          <span v-else>待确认</span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="待付款" name="WAIT_PAY"></el-tab-pane>
      <el-tab-pane label="待发货" name="WAIT_SEND"></el-tab-pane>
      <el-tab-pane label="待收货" name="SEND"></el-tab-pane>
      <el-tab-pane label="部分中止" name="PARTIAL_FINISH"></el-tab-pane>
      <el-tab-pane label="完全终止" name="OA_PARTIAL_FINISH"></el-tab-pane>
      <el-tab-pane label="已完成" name="FINISH"></el-tab-pane>
      <el-tab-pane label="已取消" name="CANCEL"></el-tab-pane>
      <el-tab-pane label="退款中" name="REFUNDING"></el-tab-pane>
      <el-tab-pane label="已退款" name="REFUND_FINISH"></el-tab-pane>
      <el-tab-pane label="退款失败" name="REFUND_FAILURE"></el-tab-pane>
    </el-tabs>
    <order-list ref="orderList" v-loading="isLoadingList" @loadEnding="isLoadingList = false" :remote-method="queryData" :time-value.sync="timeValue" @changeWaitConfirmOrder="getWaitConfirmOrderTotal" :data.sync="tableData" @selectTime="selectTime"/>
  </div>
</template>

<script>
  import SearchPad from '../../components/search-pad'
  import SearchPadItem from '../../components/search-pad-item'
  import OrderList from './components/order-list'
  import { parseTime } from '@/utils'
  import { pageOrder, allPaymentWay } from '@/api/member-center/order-manage/order-list'

  export default {
    name: 'store-order',
    components: { SearchPad, SearchPadItem, OrderList },
    data () {
      return {
        isLoadingList:false,
        searchForm: {
          processStatCode: '',
          erpCode: '',
          buyerNm: '',
          startDate: '',
          endDate: '',
          productNm: '',
          productCode: '',
          orderNum: '',
          paymentWayCode: '',
          orderSource: '',
          dealTime: []
        },
        waitConfirmOrderNum:0,
        timeValue: '全部',
        payList: [
          {
            name: '额度支付',
            paymentWayCode: 'CREDIT'
          },
          {
            name: '线上支付',
            paymentWayCode: 'CASH'
          },
        ],
        sourceList: [
          {
            name: '线上订单',
            key: 'ONLINE_ORDER'
          },
          {
            name: '线下订单',
            key: 'OFFLINE_ORDERS'
          },
          {
            name: '第三方订单',
            key: 'THIRD_PARTY_ORDER'
          }
        ],
        tableData: [],
        isExpand: true,
        activeName: ''
      }
    },
    created () {
      this.$emit('handle-change-page', {
        text: '商城订单',
        index: '2-1',
        url: '/member-center/order-manage/store-order'
      })
      this.bindCommand()
      if (this.$route.query.activeName) {
        this.activeName = this.$route.query.activeName
        this.searchForm.processStatCode = this.$route.query.activeName
      }
      this.allPaymentWay()
      this.getWaitConfirmOrderTotal()
    },
    methods: {
      selectTime() {
        this.bindCommand()
      },
      handleTab (tabs) {
        let name = tabs.name
        this.searchForm.processStatCode = name === '' ? '' : name
        this.activeName = name
        this.tableData = []
        this.doRefresh()
      },
      doRefresh(){
        this.isLoadingList = true;
        this.$refs.orderList.doRefresh()
      },
      handleChange (e) {
        if (e.length < 2) return
        this.searchForm.startDate = parseTime(e[0])
        this.searchForm.endDate = parseTime(e[1])
      },
      queryData (data) {
        if (this.searchForm.dealTime && this.searchForm.dealTime.length > 0) {
          this.searchForm.startDate = parseTime(this.searchForm.dealTime[0], '{y}-{m}-{d}') + ' 00:00:00'
          this.searchForm.endDate = parseTime(this.searchForm.dealTime[1], '{y}-{m}-{d}') + ' 23:59:59'
        } else {
          this.searchForm.startDate = ''
          this.searchForm.endDate = ''
        }
        return pageOrder({
          ...data,
          condition: this.searchForm
        })
      },
      getWaitConfirmOrderTotal(){
        pageOrder({
          "pageNum":1,
          "pageSize":1,
          "condition":{
            "processStatCode":"WAIT_APPROVE",
            "erpCode":"",
            "buyerNm":"",
            "startDate":"",
            "endDate":"",
            "productNm":"",
            "productCode":"",
            "orderNum":"",
            "paymentWayCode":"",
            "orderSource":"",
            "dealTime":[]
          }
        }).then(res=>{
          this.waitConfirmOrderNum = res.data.total || 0
        })
      },
      bindCommand () {
        let date
        switch (this.timeValue) {
          case '近7天' :
            date = Date.now() - 3600 * 1000 * 24 * 6
            break
          case '近30天' :
            date = Date.now() - 3600 * 1000 * 24 * 29
            break
          case '近三个月' :
            date = Date.now() - 3600 * 1000 * 24 * 29 * 3
            break
          case '近半年' :
            date = Date.now() - 3600 * 1000 * 24 * 29 * 6
            break
        }
        if (date) {
          this.searchForm.dealTime = []
          this.searchForm.startDate = parseTime(date, '{y}-{m}-{d}') + ' 00:00:00'
          this.searchForm.endDate = parseTime(Date.now(), '{y}-{m}-{d}') + ' 23:59:59'
          this.searchForm.dealTime.push(parseTime(date, '{y}-{m}-{d}'))
          this.searchForm.dealTime.push(parseTime(Date.now(), '{y}-{m}-{d}'))
          this.$refs.orderList ? this.handleSearch() : ''
        }
      },
      allPaymentWay () {
        allPaymentWay().then(res => {
          // this.payList = res.data
        })
      },
      handleSearch () {
        this.doRefresh()
      },
      handleReset () {
        this.searchForm = {
          processStatCode: '',
          startDate: '',
          erpCode: '',
          buyerNm: '',
          endDate: '',
          productNm: '',
          productCode: '',
          orderNum: '',
          paymentWayCode: '',
          orderSource: ''
        }
        this.doRefresh()
      }
    }
  }
</script>

<style scoped lang="scss">
  .store-order-list {
    background-color: #fff;
    text-align: left;
    font-size: 14px;
    height: 100%;

    .client-file-search-pad {
      padding: 20px 20px 10px;
      border-bottom: 1px solid #E5E5E5;

      .el-input {
        width: 180px;
        height: 32px;
      }

      /deep/ {
        .el-form-item__label, .el-form-item__content, .el-input__inner {
          height: 32px;
          line-height: 32px;
        }

        .el-input__inner {
          font-size: 14px;
          font-weight: 400;
          color: rgba(153, 153, 153, 1);
          padding: 0 8px;
        }
      }
    }

    /deep/ {
      .el-tabs {
        margin: 0 20px;
      }
      .el-tabs__nav-wrap::after {
        background-color: #fff;
      }

      .el-tabs__nav {
        //margin: 0 20px;
        height: 40px;
        line-height: 50px;
        .el-tabs__item{
          line-height: 35px;
        }
      }
    }
  }
</style>

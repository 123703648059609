var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "corporate-info" }, [
    _c("div", { staticClass: "info-box" }, [
      _c("h4", { staticClass: "info-title" }, [_vm._v("基本信息")]),
      _c("ul", { staticClass: "info-items" }, [
        _c("li", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("企业名称：")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.baseInfo.buyersNm)),
          ]),
        ]),
        _c("li", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("统一社会信用代码：")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.baseInfo.socialCreditCode)),
          ]),
        ]),
        _c("li", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("企业法人：")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.baseInfo.legalPersonMember)),
          ]),
        ]),
        _c("li", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("企业类型：")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.baseInfo.customerTypeName)),
          ]),
        ]),
        _c("li", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("负责人：")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.baseInfo.entContactMan)),
          ]),
        ]),
        _c("li", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("负责人手机：")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.baseInfo.contactMoblie)),
          ]),
        ]),
        _c("li", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("所在区域：")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(
              _vm._s(_vm.baseInfo.province) +
                _vm._s(_vm.baseInfo.city) +
                _vm._s(_vm.baseInfo.district)
            ),
          ]),
        ]),
        _c("li", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("注册地址：")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.baseInfo.regAddr)),
          ]),
        ]),
        _c("li", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("经营地址：")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.baseInfo.businessAddress)),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "info-box" }, [
      _c("h4", { staticClass: "info-title" }, [_vm._v("经营范围")]),
      _c(
        "ul",
        { staticClass: "info-items" },
        _vm._l(_vm.businessRange, function (item, index) {
          return _c("li", { key: index, staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [
              _vm._v(_vm._s(item.nm) + "："),
            ]),
            _c("div", { staticClass: "value" }, [_vm._v(_vm._s(item.rangeNm))]),
          ])
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "info-box" }, [
      _c("h4", { staticClass: "info-title" }, [_vm._v("收货地址")]),
      _c(
        "div",
        { staticClass: "info-items" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.addressList,
                "header-row-class-name": "table-header",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "序号",
                  type: "index",
                  width: "50",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "contactCustomerNm",
                  "show-overflow-tooltip": "",
                  width: "128",
                  label: "随货联系企业名称",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "ettleAccountNm",
                  "show-overflow-tooltip": "",
                  width: "120",
                  label: "分支机构",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "receiverName",
                  "show-overflow-tooltip": "",
                  label: "联系人",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "receiverMobile",
                  "show-overflow-tooltip": "",
                  label: "联系电话",
                  width: "180",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "district",
                  "show-overflow-tooltip": "",
                  width: "218",
                  label: "收货区域",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.province))]),
                        scope.row.city
                          ? _c("span", [_vm._v("-" + _vm._s(scope.row.city))])
                          : _vm._e(),
                        scope.row.district
                          ? _c("span", [
                              _vm._v("-" + _vm._s(scope.row.district)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "receiverAddr",
                  "show-overflow-tooltip": "",
                  label: "详细地址",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "info-box" }, [
      _c("h4", { staticClass: "info-title" }, [_vm._v("发票信息")]),
      _c(
        "div",
        { staticClass: "info-items" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.invoiceList,
                border: "",
                "header-row-class-name": "table-header",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  align: "center",
                  width: "50",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "ettleAccountNm",
                  label: "分支机构",
                  "show-overflow-tooltip": "",
                  width: "128",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "invoiceType",
                  width: "120",
                  "show-overflow-tooltip": "",
                  label: "发票类型",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "invoiceTitle",
                  label: "发票抬头",
                  "show-overflow-tooltip": "",
                  width: "176px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "invoiceTfn",
                  label: "税号",
                  "show-overflow-tooltip": "",
                  width: "144px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "regTel",
                  width: "128px",
                  "show-overflow-tooltip": "",
                  label: "注册电话",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "unitAddr",
                  width: "248px",
                  "show-overflow-tooltip": "",
                  label: "单位地址",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "bankAccount",
                  width: "200px",
                  "show-overflow-tooltip": "",
                  label: "银行账户",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "openBank",
                  width: "200px",
                  "show-overflow-tooltip": "",
                  label: "开户银行",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
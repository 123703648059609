<template>
  <div class="interfaceOrderDiv">
    <div class="title">未匹配数据列表</div>
    <!-- 搜索区域 -->
    <el-form :inline="true" :model="searchForm" class="searchForm" size="small">
      <el-form-item label="客户编码：">
        <el-input class="searchFormItem" v-model="searchForm.customerCode" placeholder="输入客户编码"></el-input>
      </el-form-item>
      <el-form-item label="门店编码：">
        <el-input class="searchFormItem" v-model="searchForm.storeCode" placeholder="输入门店编码"></el-input>
      </el-form-item>
      <el-form-item label="门店名称：">
        <el-input class="searchFormItem" v-model="searchForm.storeNm" placeholder="输入门店名称"></el-input>
      </el-form-item>
      <el-form-item label="商品名称：" v-show="isExpanded">
        <el-input class="searchFormItem" v-model="searchForm.productNm" placeholder="输入商品名称"></el-input>
      </el-form-item>
      <el-form-item label="配送地址：" v-show="isExpanded">
        <el-input class="searchFormItem" v-model="searchForm.sendAddr" placeholder="输入配送地址"></el-input>
      </el-form-item>
      <el-form-item label="要货时间" label-width="100px" v-show="isExpanded">
        <el-date-picker
            v-model="searchForm.time"
            class="dateFormItem"
            :class="{'isExpanded':isExpanded}"
            type="daterange"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            size="small"
            align="right"
            unlink-panels
            range-separator="至"
            @change="changeDatePicker"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item class="formBut">
        <el-button type="primary" size="mini" @click="search">搜索</el-button>
        <el-button type="text" size="mini" @click="reset">
          <span style="color: #000000">重置</span>
        </el-button>
        <div style="width: 1px;height: 12px;background-color: #E5E5E5;margin: 0 8px;margin-top: 8px;"></div>
        <el-button type="text" size="mini" @click="onExpand" style="color:#000000;font-weight:400;">
          {{ isExpanded? '收起' : '展开' }}
          <i :class="isExpanded ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" />
        </el-button>
      </el-form-item>
    </el-form>
    <im-tool-bar class="butDiv">
      <template slot="left">
        <el-button type="primary" @click="rematch" size="small">重新匹配</el-button>
      </template>
    </im-tool-bar>
    <im-table-page
        ref="tablePage"
        :data.sync="tableData"
        :more-check-box="checkBox"
        :cell-class-name="cellClassName"
        :header-cell-class-name="cellClassName"
        :remote-method="remoteMode">
      <el-table-column
          type="selection"
          width="55"
          fixed="left"
          align="center">
      </el-table-column>
      <el-table-column label="客户编码"  align="center" prop="customerCode"  width="80"></el-table-column>
      <el-table-column label="客户名称"  align="center" prop="buyersNm" width="150" show-overflow-tooltip></el-table-column>
      <el-table-column label="门店编码"  align="center" prop="storeCode" width="100"  show-overflow-tooltip ></el-table-column>
      <el-table-column label="门店名称"  align="center" prop="storeNm" width="120" show-overflow-tooltip ></el-table-column>
      <el-table-column label="订单编码"  align="center" prop="distributionOrderNum" width="150" show-overflow-tooltip ></el-table-column>
      <el-table-column label="第三方商品编码"  align="center" prop="thirdProductCode" width="140" show-overflow-tooltip ></el-table-column>
      <el-table-column label="商品名称"  align="center" prop="productNm" width="130" show-overflow-tooltip ></el-table-column>
      <el-table-column label="规格"  align="center" prop="spec" width="80" show-overflow-tooltip ></el-table-column>
      <el-table-column label="生产厂家"  align="center" prop="manufacturer" width="130" show-overflow-tooltip ></el-table-column>
      <el-table-column label="要货数量"  align="center" prop="quantity" width="80" show-overflow-tooltip ></el-table-column>
      <el-table-column label="要货价格"  align="right" prop="originalPrice" width="100" show-overflow-tooltip ></el-table-column>
      <el-table-column label="要货日期"  align="center" prop="createTime" width="100" show-overflow-tooltip ></el-table-column>
      <el-table-column label="配送地址"  align="center" prop="receiverAddr" min-width="220" show-overflow-tooltip ></el-table-column>
    </im-table-page>
  </div>
</template>

<script>
import {rematch, pageDistributionOrders} from "@/api/member-center/consigned-delivery";

export default {
  name: "unmatchedDatas",
  data(){
    return{
      searchForm: {
        type: 'notMatchData',
        customerCode: '',
        sendAddr: '',
        productNm: '',
        storeCode: '',
        startTime: '',
        endTime: '',
        storeNm: '',
        isPriceMatch: '',
        isPriceHigh: '',
        unmatched: 'Y',
        outOfStock: this.outOfStock,
        time: []
      },
      tableData: [],
      isExpanded: false,
      multipleSelection: []
    }
  },
  methods: {
    search() {
      this.doRefresh();
    },
    reset() {
      this.searchForm = {
        type: 'notMatchData',
        customerCode: '',
        sendAddr: '',
        productNm: '',
        storeCode: '',
        startTime: '',
        endTime: '',
        storeNm: '',
        isPriceMatch: '',
        isPriceHigh: '',
        unmatched: 'Y',
        outOfStock: this.outOfStock,
        time: []
      }
      this.doRefresh()
    },
    doRefresh() {
      this.$refs.tablePage.doRefresh({currentPage: 0, pageSize: 30, condition: this.searchForm})
    },
    remoteMode(pageParam){
      if( pageParam.pageSize < 30 ){
        pageParam.pageSize = 30
      }
      return pageDistributionOrders({
        ...pageParam, condition: this.searchForm
      })
    },
    onExpand() {
      this.isExpanded = !this.isExpanded
    },
    checkBox(val) {
      this.multipleSelection = val
    },
    rematch(){
      // console.log(this.multipleSelection)
      if(this.multipleSelection.length < 1){
        this.$message.error('请选择重新匹配的数据')
        return
      }
      let distributionOrderItemIds = []
      this.multipleSelection.forEach(item => {
        distributionOrderItemIds.push(item.distributionOrderItemId)
      })
      let params = {
        distributionOrderItemIds: distributionOrderItemIds
      }
      rematch(params).then(res => {
        this.$message.success('已提交')
        this.doRefresh()
      })
    },
    changeDatePicker (dateList) {
      this.searchForm.startTime = dateList[0] + ' 00:00:00'
      this.searchForm.endTime = dateList[1] + ' 23:59:59'
    },
    cellClassName ({ row, column, rowIndex, columnIndex }) {
      return 'cellFont'
    }
  },
  mounted () {
    let date = new Date();
    let year = date.getFullYear(); // 年
    let month = date.getMonth() + 1; // 月
    let day = date.getDate(); // 日
    this.searchForm.time = [year + '-' + month + '-' + day,year + '-' + month + '-' + day]
    this.searchForm.startTime = year + '-' + month + '-' + day + ' 00:00:00'
    this.searchForm.endTime = year + '-' + month + '-' + day + ' 23:59:59'
  }
}
</script>

<style lang="scss" scoped>
.interfaceOrderDiv {
  background-color: #FFFFFF;
  text-align: left;
  padding: 0 20px;
  min-height: 780px;
  .title {
    color: #2B2B2B;
    font-size: 16px;
    line-height: 50px;
    height: 50px;
    padding-left: 8px;
    font-weight: 700;
    border-bottom: #E5E5E5 solid 1px;
  }
  .searchForm{
    border-bottom: 1px solid #E5E5E5;
    padding: 18px 20px 0 20px;
    .searchFormItem{
      width: 146px;
      /deep/ .el-input__inner {
        padding: 0 6px;
      }
    }
    .formBut /deep/.el-form-item__content{
      display: flex;
    }
    .dateFormItem {
      width: 220px;
    }
  }
  .inputName /deep/.el-input__inner {
    height: 20px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .butDiv {
    margin: 10px 0;
    align-items: center;
  }
}
</style>
<style lang="scss">
.cellFont .cell {
  font-size: 12px!important;
}
</style>

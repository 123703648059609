<template>
    <el-dialog class="container" width="800px" title="查看批号效期" :visible="visible" @close="onClose">
        <div class="goodsData">
            <span>商品编码：{{ rowData.productCode }}</span>
            <span>商品名称：{{ $util.getGoodsName(rowData) }}</span>
            <span>规格：{{ rowData.spec }}</span>
            <span>单位：{{ rowData.unit }}</span>
            <span>厂家(上市许可持有人)/产地： {{ rowData.manufacturer }}{{ rowData.area ? '/' + rowData.area : '' }}</span>
        </div>
        <!-- <im-table-page ref="tablePage" :data.sync="tableData" :remote-method="queryData" :addQuantity="true"
            :index="true" header-row-class-name="header-row" style="width: 100%;margin-top: 15px">
            <el-table-column prop="batchId" label="批号" width="200px" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.isTransport }}{{ scope.row.batchId }}</template>
</el-table-column>
<el-table-column prop="entryName" label="调拨区域" width="200px" show-overflow-tooltip>
</el-table-column>
<el-table-column prop="costPrice" label="调拨价格" width="200px" show-overflow-tooltip>
    <template slot-scope="scope">{{ scope.row.costPrice || scope.row.dsj }}</template>
</el-table-column>
<el-table-column prop="expDate" label="有效期至" width="200px" show-overflow-tooltip>
    <template slot-scope="scope">
                    {{ scope.row.expDate != null ? $util.getTime(scope.row.expDate, 'yyyy-MM-dd') : "/" }}
                </template>
</el-table-column>
<el-table-column prop="goodsQty" label="库存" width="200px" show-overflow-tooltip />
</im-table-page> -->

        <el-table :data="tableData" border style="width: 100%">
            {{ tableData }}
            <el-table-column v-for="column in dynamicColumns" :key="column.prop" :label="column.label"
                :width="column.width">
                <template slot-scope="scope">
                    <span v-if="column.prop == 'batchId'">
                        {{ scope.row.isTransport }}<span v-if="columnFlag == 'Y'">{{ scope.row.batchId }}</span>
                    </span>
                    <!-- <span v-else-if="column.prop == 'costPrice'">
                        <div v-if="scope.row.isTransport == '调'">
                            {{ getCostPrice(scope.row.costPrice, scope.row.dsj) }}
                        </div>
                    </span> -->
                    <span v-else-if="column.prop == 'price'">
                        {{ scope.row.price }}
                    </span>
                    <span v-else-if="column.prop == 'expDate'">
                        {{ scope.row.expDate != null ? $util.getTime(scope.row.expDate, 'yyyy-MM-dd') : "/" }}
                    </span>
                    <span v-else>
                        {{ scope.row[column.prop] }}
                    </span>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>

</template>

<script>
import { getPHXQ } from "@/api/commodity-list";
export default {
    name: "chooseBuyers",
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        //商品数据
        rowData: {
            type: Object,
            default() {
                return {

                }
            }
        }
    },
    data() {
        return {
            tableData: [],
            columnFlag: 'Y',
            dynamicColumns: []
        }
    },
    created() {
        //全局监听键盘按键
        document.onkeydown = (e) => {
            let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
            //监听Tab键
            if (e1 && Number(e1.keyCode) === 9) {
                this.onClose()
            }
        }
    },
    mounted() {
        this.queryData()
    },
    methods: {
        onClose() {
            this.$emit('close')
        },
        onSearch() {
            this.$refs.tablePage.doRefresh({ currentPage: 0, pageSize: 10 })
        },
        async queryData() {
            let data = {
                pageNum: 1,
                pageSize: 10
            }

            const res = await getPHXQ({
                ...data,
                condition: {
                    productId: this.rowData.productId
                }
            })

            this.tableData = res.data.records
            this.columnFlag = res.data.isAuthTransfer

            if (this.columnFlag == 'Y') {
                this.dynamicColumns = [
                    { prop: 'batchId', label: '批号', width: 200 },
                    { prop: 'entryName', label: '调拨区域', width: 200 },
                    // { prop: 'costPrice', label: '调拨价格', width: 200 },
                    { prop: 'price', label: '调拨价格', width: 200 },
                    { prop: 'expDate', label: '有效期至', width: 200 },
                    { prop: 'goodsQty', label: '库存', width: 200 }
                ]
            } else {
                this.dynamicColumns = [
                    { prop: 'batchId', label: '批号', width: 200 },
                    { prop: 'expDate', label: '有效期至', width: 200 },
                    { prop: 'goodsQty', label: '库存', width: 200 }
                ]
            }
            
        },
        getCostPrice(costPrice, dsj) {
            if (!costPrice || costPrice == '0') {
                return dsj;
            }
            return costPrice;
        },
    }
}
</script>

<style scoped lang="scss">
/deep/ {
    .el-dialog__header {
        padding: 0 10px !important;
    }
}

.goodsData {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    span {
        margin: 0 30px 10px 0;
        word-break: break-all;
    }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "search-main",
      class: { hiddenNav: _vm.hiddenNav },
      attrs: { id: "search-main" },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "logo-left" }, [
          _vm.header.PC_TOP_LEFT_T1 && _vm.header.PC_TOP_LEFT_T1.length
            ? _c("img", {
                attrs: {
                  src: _vm.header.PC_TOP_LEFT_T1[0].sysFileUrl,
                  alt: "",
                },
                on: {
                  click: function ($event) {
                    return _vm.bindUrl(_vm.header.PC_TOP_LEFT_T1[0].link)
                  },
                },
              })
            : _vm._e(),
          _vm.hiddenSearchTitle === "memberChangePassword" ||
          _vm.hiddenSearchTitle === "memberChangePhone"
            ? _c("div", { staticClass: "line" })
            : _vm._e(),
          _vm.hiddenSearchTitle === "memberChangePassword" ||
          _vm.hiddenSearchTitle === "memberChangePhone"
            ? _c("div", { staticClass: "change-title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.hiddenSearchTitle === "memberChangePassword"
                        ? "修改密码"
                        : _vm.originChange === "memberChangePhones"
                        ? "绑定手机号"
                        : "修改手机号"
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
        ]),
        _vm.returnSecurity
          ? _c(
              "div",
              {
                staticClass: "return-security",
                on: { click: _vm.toReturnSecurity },
              },
              [_vm._v("返回安全中心")]
            )
          : _vm._e(),
        _c("div", { staticClass: "search-container" }, [
          !_vm.hiddenSearch
            ? _c(
                "div",
                { staticClass: "search" },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "search-input",
                      attrs: {
                        size: "large",
                        placeholder: "请输入商品名称、拼音首字母、国药准字",
                      },
                      on: { input: _vm.changeSearchInput },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.search(null)
                        },
                      },
                      model: {
                        value: _vm.searchData,
                        callback: function ($$v) {
                          _vm.searchData = $$v
                        },
                        expression: "searchData",
                      },
                    },
                    [
                      _c("el-button", {
                        staticClass: "search-button",
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            return _vm.search(null)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                  !_vm.hiddenNav &&
                  _vm.header.PC_TOP_SEARCH_LINK &&
                  _vm.header.PC_TOP_SEARCH_LINK.length
                    ? _c(
                        "div",
                        { staticClass: "search-tag" },
                        [
                          _c("span", [_vm._v("热门搜索：")]),
                          _vm._l(
                            _vm.header.PC_TOP_SEARCH_LINK,
                            function (item, index) {
                              return _c(
                                "span",
                                {
                                  key: index,
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectTags(index)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item.title))]
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
        !_vm.hiddenNav
          ? _c("div", { staticClass: "logo-phone" }, [
              _vm.header.PC_TOP_RIGHT_T1 && _vm.header.PC_TOP_RIGHT_T1.length
                ? _c("img", {
                    attrs: {
                      src: _vm.header.PC_TOP_RIGHT_T1[0].sysFileUrl,
                      alt: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.bindUrl(_vm.header.PC_TOP_RIGHT_T1[0].link)
                      },
                    },
                  })
                : _vm._e(),
            ])
          : _vm._e(),
        !_vm.hiddenNav
          ? _c("div", { staticClass: "logo-code" }, [
              _vm.header.PC_TOP_RIGHT_T2 && _vm.header.PC_TOP_RIGHT_T2.length
                ? _c("img", {
                    attrs: {
                      src: _vm.header.PC_TOP_RIGHT_T2[0].sysFileUrl,
                      alt: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.bindUrl(_vm.header.PC_TOP_RIGHT_T2[0].link)
                      },
                    },
                  })
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      !_vm.hiddenNav
        ? _c("div", { staticClass: "nav-body" }, [
            _c("div", { staticClass: "nav-item" }, [
              _c(
                "ul",
                {
                  staticClass: "nav-item-first",
                  on: { mouseenter: _vm.showNav, mouseleave: _vm.hideNav },
                },
                [_vm._m(0)]
              ),
              !_vm.header.PC_NAVIGATION_LINK ||
              !_vm.header.PC_NAVIGATION_LINK.length
                ? _c("ul", { staticClass: "nav-item-last" }, [
                    _c(
                      "li",
                      [
                        _c("router-link", { attrs: { to: "/" } }, [
                          _c("a", { attrs: { href: "#" } }, [_vm._v("首页")]),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _c(
                    "ul",
                    { staticClass: "nav-item-last" },
                    _vm._l(
                      _vm.header.PC_NAVIGATION_LINK,
                      function (item, index) {
                        return _c(
                          "li",
                          {
                            key: index,
                            class: [
                              {
                                "router-link-exact-active":
                                  _vm.nowLink === item.titleLink,
                              },
                            ],
                          },
                          [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.linkTo(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.title))]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.panelNav,
                    expression: "panelNav",
                  },
                ],
                ref: "navSide",
                staticClass: "nav-side",
                style: { height: !_vm.indexNav ? "400px" : "auto" },
                on: { mouseenter: _vm.showNav, mouseleave: _vm.hideNav },
              },
              [
                _c(
                  "ul",
                  [
                    _vm._l(
                      _vm.productCategory.childTree,
                      function (item, index) {
                        return [
                          _c(
                            "li",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: index < 8,
                                  expression: "index<8",
                                },
                              ],
                              key: index,
                              on: {
                                mouseenter: function ($event) {
                                  return _vm.showDetail(index)
                                },
                                mouseleave: function ($event) {
                                  return _vm.hideDetail(index)
                                },
                                click: function ($event) {
                                  return _vm.search(item, false)
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "nav-side-item" }, [
                                item.fileUrl
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "20px",
                                        height: "20px",
                                      },
                                      attrs: { src: item.fileUrl, alt: "" },
                                    })
                                  : _c("img", {
                                      staticStyle: {
                                        width: "20px",
                                        height: "20px",
                                      },
                                      attrs: {
                                        src: require("../../assets/img/icon/icon-yao (0).png"),
                                        alt: "",
                                      },
                                    }),
                                _vm._v(" " + _vm._s(item.categoryNm) + " "),
                              ]),
                              _c("i", { staticClass: "el-icon-arrow-right" }),
                            ]
                          ),
                        ]
                      }
                    ),
                    _c(
                      "li",
                      {
                        class: { "nav-side-all": !_vm.indexNav },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.enterAll.apply(null, arguments)
                          },
                          mouseenter: _vm.showNav,
                          mouseleave: _vm.hideNav,
                        },
                      },
                      [_vm._v("查看全部分类导航")]
                    ),
                  ],
                  2
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "nav-side-content" },
              [
                _vm._l(
                  _vm.productCategory.childTree,
                  function (navItem, navIndex) {
                    return [
                      _c(
                        "transition",
                        { key: navIndex, attrs: { name: "fade" } },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    navIndex === _vm.activeNavNum &&
                                    _vm.panelNav,
                                  expression:
                                    "navIndex === activeNavNum && panelNav",
                                },
                              ],
                              ref: "itemPanel",
                              refInFor: true,
                              staticClass: "detail-item-panel panel-2",
                              attrs: { duration: { enter: 100, leave: 100 } },
                              on: {
                                mouseenter: function ($event) {
                                  return _vm.showDetail(navIndex)
                                },
                                mouseleave: function ($event) {
                                  return _vm.hideDetail(navIndex)
                                },
                              },
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "detail-item-name",
                                  on: {
                                    click: function ($event) {
                                      return _vm.search(navItem, false)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(navItem.categoryNm))]
                              ),
                              _c(
                                "ul",
                                _vm._l(
                                  navItem.childTree,
                                  function (items, index) {
                                    return _c(
                                      "li",
                                      {
                                        key: index,
                                        staticClass: "detail-item-row",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "detail-item-title",
                                            on: {
                                              click: function ($event) {
                                                return _vm.search(items, false)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(items.categoryNm) + " "
                                            ),
                                            _c("span", {
                                              staticClass:
                                                "glyphicon glyphicon-menu-right",
                                            }),
                                          ]
                                        ),
                                        _vm._l(
                                          items.childTree,
                                          function (item, subIndex) {
                                            return _c("a", { key: subIndex }, [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "detail-item",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.search(
                                                        item,
                                                        false
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.categoryNm)
                                                  ),
                                                ]
                                              ),
                                            ])
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  }
                ),
              ],
              2
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("span", { staticClass: "nav-side-item" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/img/icon/classification.png"),
            alt: "",
          },
        }),
        _vm._v("商品分类"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
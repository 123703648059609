var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "brand-center-content" },
    [
      _c("Search", { attrs: { "index-nav": true } }),
      _c("div", { staticClass: "brand-content" }, [
        _c("div", { staticClass: "brand-bg" }),
        _c(
          "div",
          { staticClass: "brand" },
          [
            _c(
              "div",
              {
                staticClass:
                  "search-input el-input el-input--large el-input-group el-input-group--append",
                staticStyle: { width: "98%", "padding-block-end": "10px" },
                attrs: { "data-v-7b59048f": "" },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchForm.searchParams,
                      expression: "searchForm.searchParams",
                    },
                  ],
                  staticClass: "el-input__inner",
                  attrs: {
                    type: "text",
                    autocomplete: "off",
                    placeholder: "请输入活动名称/商品名称",
                  },
                  domProps: { value: _vm.searchForm.searchParams },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchForm,
                        "searchParams",
                        $event.target.value
                      )
                    },
                  },
                }),
                _c("div", { staticClass: "el-input-group__append" }, [
                  _c(
                    "button",
                    {
                      staticClass: "el-button search-button el-button--default",
                      attrs: { "data-v-7b59048f": "", type: "button" },
                      on: { click: _vm.handleSearch },
                    },
                    [_c("i", { staticClass: "el-icon-search" })]
                  ),
                ]),
              ]
            ),
            _c(
              "el-tabs",
              {
                on: { "tab-click": _vm.handleClick },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "优惠套餐", name: "first" } },
                  [
                    _vm.swiperData.length
                      ? _c("swiper-package", {
                          attrs: {
                            model: "more",
                            "swiper-data": _vm.swiperData,
                          },
                        })
                      : _vm._e(),
                    _vm.FIXED_PACKAGE.length
                      ? _c("swiper-package", {
                          attrs: {
                            model: "more",
                            type: "FIXED_PACKAGE",
                            "swiper-data": _vm.FIXED_PACKAGE,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "自选套餐", name: "second" } },
                  [
                    _vm.giftsData.length
                      ? _c("swiper-package2", {
                          attrs: {
                            model: "more",
                            "swiper-data": _vm.giftsData,
                          },
                          on: { openGifts: _vm.handleOpenGiftsDialog },
                        })
                      : _c("el-empty", { attrs: { description: "暂无数据" } }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.total
              ? _c("im-pagination", {
                  attrs: {
                    total: _vm.total,
                    "hide-on-single-page": false,
                    "current-page": _vm.currentPage,
                    "page-size": _vm.pageSize,
                    hideOnSinglePage: false,
                    isShowMainInfo: true,
                  },
                  on: {
                    "update:currentPage": function ($event) {
                      _vm.currentPage = $event
                    },
                    "update:current-page": function ($event) {
                      _vm.currentPage = $event
                    },
                    "update:pageSize": function ($event) {
                      _vm.pageSize = $event
                    },
                    "update:page-size": function ($event) {
                      _vm.pageSize = $event
                    },
                    pagination: _vm.handlePagination,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("giftsDialog", {
        ref: "giftsDialogRef",
        attrs: {
          activeType: _vm.nowActivityType,
          fullCondition: _vm.fullCondition,
        },
        on: { hideDialog: _vm.hideDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
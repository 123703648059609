var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "expandingCustomersDiv" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("我要拓客")]),
      _vm._m(0),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "from",
          attrs: { model: _vm.form, "label-width": "120px" },
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "formItem",
              attrs: { label: "拓客链接：", prop: "customerName" },
            },
            [
              _c("el-input", {
                staticClass: "urlInput",
                attrs: { placeholder: "输入单位全称" },
                on: { input: _vm.changeUrl },
                model: {
                  value: _vm.form.url,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "url", $$v)
                  },
                  expression: "form.url",
                },
              }),
              _c(
                "el-button",
                {
                  staticClass: "copyUrlBt",
                  attrs: { size: "small" },
                  on: { click: _vm.copyUrl },
                },
                [_vm._v("复制链接")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "formItem qrcodeFrom",
              attrs: { label: "APP二维码：", prop: "customerName" },
            },
            [
              _c("div", { staticClass: "qrcodeDiv" }, [
                _c("div", { attrs: { id: "qrcodeApp" } }),
              ]),
              _c("img", {
                staticClass: "qrcodeImg",
                attrs: {
                  src: require("@/assets/img/customer-service/favicon.png"),
                },
              }),
              _c(
                "el-button",
                {
                  staticClass: "copyQrcodeBt",
                  attrs: { size: "small" },
                  on: { click: _vm.copyQrcode },
                },
                [_vm._v("复制二维码")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "formItem qrcodeFrom",
              attrs: { label: "小程序二维码：", prop: "customerName" },
            },
            [
              _c("div", { staticClass: "qrcodeDiv" }, [
                _c("div", { attrs: { id: "qrcode" } }),
              ]),
              _c("img", {
                staticClass: "qrcodeImg",
                attrs: {
                  src: require("@/assets/img/customer-service/favicon.png"),
                },
              }),
              _c(
                "el-button",
                {
                  staticClass: "copyQrcodeBt",
                  attrs: { size: "small" },
                  on: { click: _vm.copyQrcode },
                },
                [_vm._v("复制二维码")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("success-message", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showMessage,
            expression: "showMessage",
          },
        ],
        staticClass: "messageBox",
        attrs: { message: _vm.message },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("img", {
        staticClass: "icon",
        attrs: { src: require("@/assets/img/customer-service/info.png") },
      }),
      _vm._v(" 复制拓客链接、APP小程序二维码，即可进 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <im-page-modal
                class="procurement-plan-update"
                v-loading="loading"
                :title="title"
                :isShowBack="!!isShowBack"
                backTitle="返回"
                @handle-back="goBack(false)"
        >
            <div class="form-content">
                <el-form ref="form" :model="form">
                    <el-form-item prop="buyersIdList">
                        <div class="header">
                            <span class="label"><i>*</i>客户信息</span>
                            <el-button size="mini" type="primary" v-if="editType === 'add'" @click="chooseBuyers">选择客户</el-button>
                        </div>
                        <div class="buyersDetail">
                            <template v-if="buyerSelectItem.erpCode || form.buyersNo">
                                <span>客户编码：{{editType !== 'add' ? form.buyersNo : buyerSelectItem.erpCode}}</span>
                                <span>客户名称：{{editType !== 'add' ? form.buyersNm : buyerSelectItem.buyersNm}}</span>
                            </template>
                        </div>
                    </el-form-item>
                    <el-form-item prop="productIdList">
                        <div class="header">
                            <span class="label"><i>*</i>商品信息</span>
                            <el-button size="mini" type="primary" v-if="editType === 'add' " @click="chooseGoods">选择商品</el-button>
                        </div>

                        <el-table ref="table"
                                  :border="true"
                                  :data="tableData"
                                  style="margin-top: 3px">
                            <el-table-column align="center" type="index">
                                <template slot="header">
                                    <i class="el-icon-menu" />
                                </template>
                            </el-table-column>
                            <el-table-column label="商品编码" prop="productCode" align="center" show-overflow-tooltip width="100px"/>
                            <el-table-column label="商品名称" prop="productNm" align="center" show-overflow-tooltip width="200px">
                                <template slot-scope="scope">
                                    {{$util.getGoodsName(scope.row)}}
                                </template>
                            </el-table-column>
                            <el-table-column label="规格" prop="spec" align="center" show-overflow-tooltip width="130px"/>
                            <el-table-column label="单位" prop="unit" align="center" show-overflow-tooltip width="100px"/>
                            <el-table-column prop="manufacturer" align="center" label="厂家(上市许可持有人)/产地" show-overflow-tooltip width="230px">
                                <template slot-scope="scope">
                                    {{scope.row.manufacturer}}{{scope.row.area ? '/' + scope.row.area : ''}}
                                </template>
                            </el-table-column>
                            <el-table-column label="最小购买倍数" prop="minBuyMultiple" align="center" show-overflow-tooltip width="120px">
                              <template slot-scope="scope">
                                {{getRealMinBuyNum(scope.row)}}
                              </template>
                            </el-table-column>
                            <el-table-column label="当前电商价" align="right" prop="price1" show-overflow-tooltip width="120px">
                                <template slot-scope="scope">
                                    <span style="color:#F59A23">{{$util.toFixed(scope.row.customerTypePriceVo[0].price)}}</span>
                                </template>
                            </el-table-column>

<!--                            <el-table-column label="当前考核价" align="right" prop="retailPrice" show-overflow-tooltip width="120px">-->
<!--                                <template slot-scope="scope">-->
<!--                                    <span style="color:#F59A23">{{$util.toFixed(scope.row.customerTypePriceVo[0].assessmentPrice)}}</span>-->
<!--                                </template>-->
<!--                            </el-table-column>-->

                            <el-table-column label="报价" fixed="right" align="center" prop="price" show-overflow-tooltip width="150px">
                                <template slot-scope="scope">
                                    <el-input-number  :min="0" size="mini" style="width: 120px" :controls="false" v-model="scope.row.price" placeholder="输入报价"></el-input-number>
                                </template>
                            </el-table-column>

<!--                            <el-table-column label="毛利率" fixed="right" align="center" show-overflow-tooltip width="100px">-->
<!--                                <template slot-scope="scope">-->
<!--                                    {{getRate(scope.row) + '%' }}-->
<!--                                </template>-->
<!--                            </el-table-column>-->

                            <el-table-column label="有效期至" prop="date" fixed="right" align="center" width="240px">
                                <template slot-scope="scope">
                                    <el-date-picker
                                            style="width: 180px;"
                                            size="mini"
                                            v-model="scope.row.expDate"
                                            type="datetime"
                                            placeholder="选择日期">
                                    </el-date-picker>
                                </template>
                            </el-table-column>

                            <el-table-column label="操作" fixed="right" width="80px" v-if="editType === 'add'">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="text" @click="removeItem(scope.row)">移除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                </el-form>
                <div class="button">
                    <el-button size="mini" type="primary" @click="confirmForm">提交报价单</el-button>
                    <el-button size="mini" @click="goBack">取消</el-button>
                </div>
            </div>
        </im-page-modal>
        <!-- 选择客户 -->
        <choose-buyers @confirm="onChoose" v-if="chooseBuyersVisible" @close="chooseBuyersVisible = false"></choose-buyers>
        <!-- 选择商品 -->
        <choose-goods @confirm="onChooseGoods" :customer-item="buyerSelectItem" v-if="chooseGoodsVisible" @close="chooseGoodsVisible = false"></choose-goods>
    </div>
</template>

<script>
    import {getQuotationById, saveQuotation, updateQuotation} from "../../../../api/member-center/transactions-using";
    import chooseBuyers from "./components/chooseBuyers";
    import chooseGoods from "./components/chooseGoods";
    import addToCartMixin from '@/common/addToCart'

    export default {
      name: "add-update",
      components:{
          chooseBuyers,
          chooseGoods
      },
      mixins:[addToCartMixin],
      props: {
          params: {
              type: Object,
              default () {
                  return {}
              }
          }
      },
      data(){
          return {
              loading: false,
              editType: 'add',
              buyerSelectItem:{},
              tableData:[],
              chooseBuyersVisible:false,
              chooseGoodsVisible:false,
              form:{
                  // buyersId:'',//选择的客户ID
                  approveStat:'WAIT_APPROVE',
                  buyersIdQuotation:'',
                  ettleAccountIdQuotation:'',
                  userIdQuotation:'',
                  // orgId: this.$store.state.user.org.orgId,
                  productList:[
                      // {
                      //     expDate:'',
                      //     productId:'',
                      //     skuId:'',
                      //     price:'',//报价
                      //     grossProfitRate:'',
                      // }
                  ]
              }
          }
      },
      computed:{
          title () {
              const titleMap = {
                  add: '新增',
                  edit: '编辑'
              }
              return titleMap[this.editType] + '报价单'
          },
      },
      created(){
          const { rowData, editType } = this.params
          if (rowData) this.rowData = rowData
          this.editType = editType
          if (this.editType !== 'add') {
              getQuotationById({quotationId:rowData.quotationId}).then(res => {
                  this.form = {...res.data,...rowData,...{
                      userIdQuotation: res.data.userIdQuotation,
                      ettleAccountIdQuotation:res.data.ettleAccountIdQuotation,
                      buyersIdQuotation: res.data.buyersIdQuotation
                  }}
                  this.tableData = [{...res.data,...rowData,retailPrice:rowData.assessmentPrice,price1:rowData.originalUnitPrice,...{
                          userIdQuotation: res.data.userIdQuotation,
                          ettleAccountIdQuotation:res.data.ettleAccountIdQuotation,
                          buyersIdQuotation: res.data.buyersIdQuotation
                      }}]
              })
          }
      },
      methods:{
          chooseGoods(){
              if(!this.buyerSelectItem.ettleAccountId){
                  this.$message.error('请先选择客户')
                  return
              }
              this.chooseGoodsVisible = true
          },
          removeItem(item){
              this.tableData = this.tableData.filter(obj => obj.productId !== item.productId)
          },
          onChooseGoods(data){
              data.forEach(item=>{
                  if(!this.tableData.find(obj => item.productId === obj.productId)){
                      this.tableData.push(item)
                  }
              })
          },
          chooseBuyers(){
              if(this.tableData.length){
                  this.$confirm('重新选择客户需要重新选择商品，继续？','提示',{
                      distinguishCancelAndClose:true
                  }).then(res=>{
                      this.chooseBuyersVisible = true
                  })
              }else {
                  this.chooseBuyersVisible = true
              }
          },
          onChoose(item){
              this.buyerSelectItem = item
              if(this.tableData.length){
                  this.tableData = []
              }
              console.log(this.buyerSelectItem)
          },
          isShowBack () {
              return this.editType === 'edit'
          },
          getRate(item){
              let rate = this.$util.multiply(this.$util.adding(Number(item.price),-Number(item.retailPrice))/Number(item.price),100)
              if(/,/.test(rate)){
                  rate = rate.replace(/,/g,'')
              }
              if(/-/.test(rate)){
                  rate = -(Number(rate.replace(/-/g,'')).toFixed(2))
              }
              return  Number(rate).toFixed(2)
          },
          addSubmit(){
              if(!this.buyerSelectItem.buyersId){
                  this.$message.error('请选择客户')
                  return;
              }
              if(!this.tableData.length){
                  this.$message.error('请选择商品')
                  return;
              }

              let item = this.tableData.find(obj => !obj.price)
              if(item){
                  this.$message.error(`商品${this.$util.getGoodsName(item)}未填写报价，无法提交`)
                  return;
              }

              this.form.buyersIdQuotation = this.buyerSelectItem.buyersId
              this.form.ettleAccountIdQuotation = this.buyerSelectItem.ettleAccountId
              this.form.userIdQuotation = this.buyerSelectItem.userId
              this.form.productList = this.tableData.map(item=>{
                  return {
                      productId: item.productId,
                      skuId:item.skuId,
                      price: Number(item.price),
                      grossProfitRate : this.getRate(item),
                      expDate: item.expDate ? this.$util.getTime(item.expDate) : null
                  }
              })

              this.$confirm('请确认提交当前表单','提示',{
                  confirmButtonText:'确认',
                  type:'warning'
              }).then(()=>{
                  saveQuotation(this.form).then(res=>{
                      this.goBack()
                  })
              })
          },
          editSubmit(){
              let data = this.tableData[0];
              if(!data.price){
                  this.$message.error(`商品${this.$util.getGoodsName(data)}未填写报价，无法提交`)
                  return;
              }
              this.$confirm('请确认提交当前表单','提示',{
                  confirmButtonText:'确认',
                  type:'warning'
              }).then(()=>{
                  updateQuotation({
                      ...data,
                      price: Number(data.price),
                      grossProfitRate: this.getRate(data),
                      expDate: data.expDate ? this.$util.getTime(data.expDate) : null
                  }).then(res => {
                      this.goBack()
                  })
              })
          },
          confirmForm(){
              if(this.editType === 'add'){
                  this.addSubmit()
              }else {
                  this.editSubmit()
              }
          },
          goBack (refreshList) {
              this.$emit('change-page', {
                  page: 'quotationList',
                  params: {
                      refreshList
                  }
              })
          },
      }
    }
</script>

<style scoped lang="scss">
    .form-content{
        padding: 0 20px;
        /deep/{
            .el-table__header{

                border: 1px solid rgba(233, 233, 233, 1);
                border-bottom: 0;
                tr{
                    th{
                        height: 40px;
                        line-height: 40px;
                        box-sizing: border-box;
                        -webkit-box-sizing: border-box;
                        padding: 0;
                    }
                }
            }
        }
    }
    .header{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: rgba(250, 250, 250, 1);
        border: 1px solid rgba(233, 233, 233, 1);
        .label{
            font-size: 16px;
            font-weight: bold;
            margin-right: 30px;
            i{
                color:rgba(255, 0, 0, 0.84)
            }
        }
        /deep/{
            .el-button{
                height: unset;
            }
        }
    }
    .buyersDetail{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 20px;
        border: 1px solid rgba(233, 233, 233, 1);
        border-top: 0;
        span{
            font-size: 14px;
            line-height: 22px;
            margin-right: 30px;
        }
    }
</style>
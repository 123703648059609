<template>
  <div class="registerDiv">
    <div class="header">
      <div class="registerHeader">
        <router-link class="already-button" to="/"><img class="bgImg" src="@/assets/img/logo.gif" alt=""></router-link>
        <div class="division"></div>
        <span class="title">{{ this.$route.query.isEdit ? '修改密码' : '找回密码' }}</span>
        <span class="already"><router-link class="already-button" to="/login">登录</router-link><router-link class="already-button" to="/register">注册</router-link></span>
      </div>
    </div>
    <div class="step">
      <span class="stepNum">1</span>
      <span class="stepTitle">验证手机号</span>
      <span class="stepLine" :class="{stepLineNot: writeInfo}"></span>
      <span class="stepNum" :class="{stepNumNot: writeInfo}">2</span>
      <span class="stepTitle" :class="{stepTitleNot: writeInfo}">设置新密码</span>
      <span class="stepLine" :class="{stepLineNot: writeSuccess}"></span>
      <span class="stepNum" :class="{stepNumNot: writeSuccess}">3</span>
      <span class="stepTitle" :class="{stepTitleNot: writeSuccess}">设置成功，去登录</span>
    </div>
    <keep-alive :include="['accountInfo', 'setPassword']">
      <component :is="currentView" @next="next" :params="params"/>
    </keep-alive>
    <div class="divisionClass"></div>
  </div>
</template>

<script>
import accountInfo from './accountInfo'
import setPassword from './setPassword'
import forgetSuccess from './forget-success'
import { getServiceTel } from '@/api/login/forget-password'
import Search from '@/components/Search/Search'

export default {
  name: 'ForgetPassword',
  components: {
    accountInfo,
    forgetSuccess,
    setPassword,
    Search
  },
  data () {
    return {
      currentView: 'accountInfo',
      writeInfo: true,
      writeSuccess: true,
      params: {
        serviceTel: '0592-8128032',
        loginId: ''
      },
      phone: '',
      origin: this.$route.query.origin,
      changeTitle: 'memberChangePassword'
    }
  },
  mounted() {
    // getServiceTel().then(res => {
    //   this.params.serviceTel = res.data.serviceTel
    // })
  },
  methods: {
    next(index, params) {
      if (index === 2) {
        this.writeInfo = false
        this.params.loginId = params
        this.writeSuccess = true
        this.currentView = 'setPassword'
      }else if (index === 3 ) {
        window.scrollTo(0,0);
        this.writeInfo = false
        this.writeSuccess = false
        this.currentView = 'forgetSuccess'
      }else {
        this.writeInfo = true
        this.writeSuccess = true
        this.currentView = 'accountInfo'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.registerDiv{
  width: 100%;
  text-align: center;
  .step {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px auto 0 auto;
    .stepNum {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      text-align: center;
      line-height: 20px;
      font-size: 14px;
      @include StepNumColor;
    }
    .stepNumNot {
      background-color: #E5E5E5;
      color: #999999;
    }
    .stepTitle {
      display: block;
      padding-top: 2px;
      color: #222222;
      margin-left: 8px;
      font-weight: 700;
      font-size: 16px;
    }
    .stepTitleNot {
      color: #A3A3A3;
    }
    .stepLine {
      width: 60px;
      height: 1px;
      @include StepLineColor;
      margin: auto 16px;
    }
    .stepLineNot {
      background-color: #DDDDDD;
    }
  }
  .registerFrom {
    width: 400px;
    margin: 25px auto;
    input{
      padding-left: 48px;
    }
    .input-icon {
      position: absolute;
      left: 12px;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      .img-user {
        width: 14px;
        height: 16px;
      }
      .img-suo {
        width: 13px;
        height: 16px;
      }
    }
    .checkSpan {
      margin-left: 10px;
      font-size: 12px;
      color: #A3A3A3;
      a {
        @include TextHighlightColor
      }
    }
  }
  .checkFrom{
    text-align: left;
    margin-bottom: 10px;
  }
  .sureBut {
    width: 400px;
    height: 40px;
    background-color: #0971EB;
  }
  .divisionClass{
    height: 20px;
    width: 100%;
    background: #F6F6F6;
  }
  .header {
    height: 100px;
    width: 100%;
    text-align: center;
    box-shadow:0px 4px 8px 0px rgba(0,0,0,0.08);
    .registerHeader {
      width: 1200px;
      margin: 0 auto;
      text-align: left;
      display: flex;
      .bgImg {
        width: 230px;
        height: 80px;
        margin-top: 10px;
      }
      .division {
        width:1px;
        height:40px;
        background:rgba(204,204,204,1);
        margin: 30px 20px 0 20px;
      }
      .title{
        font-size:20px;
        font-weight:500;
        color:rgba(34,34,34,1);
        line-height: 100px;
      }
      .already{
        font-size:12px;
        font-weight:500;
        color:rgba(102,102,102,1);
        line-height:100px;
        margin-left: auto;
        .already-button{
          padding-left: 8px;
          line-height: 18px;
        }
      }
    }
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "registerDiv" },
    [
      _c("Search", {
        attrs: {
          "return-security": _vm.isReturn,
          "hidden-nav": true,
          "hidden-search": true,
          originChange: _vm.origin,
          "hidden-search-title": _vm.changeTitle,
        },
      }),
      _c(
        "keep-alive",
        { attrs: { include: ["verifyPhone", "changeSuccess"] } },
        [
          _c(_vm.currentView, {
            tag: "component",
            attrs: { params: _vm.params, "old-phone": _vm.oldphone },
            on: { next: _vm.next },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "divisionClass" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <im-page-modal
      :isShowBack="false"
      class="consult-price-drafts-update"
      title="编辑询价单"
      v-loading="loading"
  >
    <!-- 按钮区域 -->
    <div class="page-header-btn" style="float:right">
      <div>
        <el-button size="mini" @click="exportExcel">导出询价单</el-button>
        <el-button size="mini" @click="goBack(false)">我的草稿箱</el-button>
      </div>
    </div>
    <!-- 表格内容 -->
    <im-table-page
        :data.sync="tableData"
        :more-check-box="moreCheckBox"
        :remote-method="queryData"
        @updated="updated"
        default-expand-all
        border
        row-key="skuId"
        :tree-props="{children: 'otherSkulist', hasChildren: 'hasChildren'}"
        ref="tablePage"
    >
      <el-table-column label="序号" width="80px" show-overflow-tooltip align="center" fixed="left">
        <template slot-scope="scope">
<!--          <span>{{ scope.$index + 1 }}</span>-->
          <span v-if="scope.row.index || scope.row.index === 0">{{ scope.row.index + 1 }}</span>
        </template>
      </el-table-column>
<!--      <el-table-column align="center" fixed='left' type="selection" width="60px"/>-->
      <el-table-column label="商品编码" prop="productCode" show-overflow-tooltip width="180px">
        <template slot-scope="scope">
          {{ scope.row.productCode }}
        </template>
      </el-table-column>
      <el-table-column label="商品名称" min-width="180px" prop="productNm" show-overflow-tooltip>
        <template slot-scope="scope">
          <fast-order-search
              v-if="scope.row.index || scope.row.index === 0"
              :ref="'productNm' + scope.$index"
              class="inputName"
              :index="scope.row.index"
              :id="'search'+scope.$index"
              :productNm="$util.getGoodsName(scope.row)"
              @keyup.46.native.stop.prevent="handleDelete(scope.row,scope.$index)"
              @getIndex="getIndex"
              @fast_order_fu="selectRow"
          >
          </fast-order-search>
          <span v-else>{{scope.row.drugNm}}({{scope.row.productNm}})</span>
        </template>
<!--        <template slot-scope="scope">-->
<!--          {{ $util.getGoodsName(scope.row.pageSkuVo)}}-->
<!--        </template>-->
      </el-table-column>
      <el-table-column label="规格" min-width="100px" prop="spec" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.spec }}
        </template>
      </el-table-column>
      <el-table-column label="单位" min-width="60px" prop="unit" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.unit }}
        </template>
      </el-table-column>
      <el-table-column label="区域" prop="orgNm" min-width="170px" show-overflow-tooltip />
      <el-table-column label="生产厂家" min-width="180px" prop="manufacturer" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.manufacturer }}
        </template>
      </el-table-column>
      <el-table-column label="件包装" min-width="100px" prop="useCommodityTypes" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.packTotal || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="最小购买倍数" min-width="120px" prop="minBuyMultiple" show-overflow-tooltip>
        <template slot-scope="scope">
          {{  getRealMinBuyNum(scope.row)}}
        </template>
      </el-table-column>
      <el-table-column label="库存" min-width="60px" prop="stockQuantity" show-overflow-tooltip>
        <template slot-scope="scope">
          {{userType === 'BUYERS' ? scope.row.stockQuantity > 100 ? '>100' : '<100' : scope.row.stockQuantity}}
        </template>
      </el-table-column>
      <el-table-column label="单价" align="right" min-width="100px" style="text-align: right" prop="price" show-overflow-tooltip>
        <template slot-scope="scope">
          <div style="text-align: right">{{ scope.row.price }}</div>
        </template>
      </el-table-column>
<!--      <el-table-column align="center" fixed="right" label="数量" min-width="170px" prop="productQuantity"-->
<!--                       show-overflow-tooltip>-->
<!--        <template slot-scope="scope">-->
<!--          <el-input-number :precision="getDecimalPointNum(scope.row.pageSkuVo)"-->
<!--                           :step="getRealMinBuyNum(scope.row.pageSkuVo)"-->
<!--                           :min='getRealMinBuyNum(scope.row.pageSkuVo)'-->
<!--                           @change="handleChangeNum(scope.row)"-->
<!--                           size="mini"-->
<!--                           v-model="scope.row.pageSkuVo.productQuantity"/>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column fixed="right" label="操作" width="70px">
        <template slot-scope="scope">
          <el-row class="table-edit-row">
              <span class="table-edit-row-item">
                <el-button @click="deleteNode(scope.row)" size='small' style="padding:2px 4px">删除</el-button>
              </span>
          </el-row>
        </template>
      </el-table-column>
    </im-table-page>
    <div class="addPro" @click="addPro()">
      +添加
    </div>
    <div class="content-footer" slot="footer">
      <div class="left-con">
<!--        <el-checkbox @change="handleAllSelect" style="margin-right:20px;" v-model="AllChecked">全选</el-checkbox>-->
<!--        <span class="text-detail">共 <span-->
<!--            style="color:#0971EB;padding:0 2px">{{ selectItem.length ? selectItem.length : 0 }}</span>种商品，总数量<span-->
<!--            style="color:#0971EB;padding:0 2px">{{ totalNum }}</span></span>-->
      </div>
      <div class="right-con">
<!--        <span class="text-one">合计:</span>-->
<!--        <span class="text-two">&yen;{{ $util.toFixed(totlalPrice) }}</span>-->
<!--        <el-button @click="handleAddCart"-->
<!--                   class="btn-two"-->
<!--                   type="primary"-->
<!--                   :disabled="isBanAddToCart">加入购物车</el-button>-->
        <el-button @click="handleAddDraft" class='btn-one'>保存草稿</el-button>
      </div>
    </div>
  </im-page-modal>
</template>
<script>
import {saveOrUpdateInquiryBox, getInquiryBoxById, getConsultPrice} from '@/api/member-center/transactions-using'
import { removeCommas } from '@/utils'
import {mapGetters} from 'vuex'
import {exportExcel} from "@/api/member-center/transactions-using";
import {batchAddItem} from "@/api/detail";
import addToCartMixin from '@/common/addToCart'
import fastOrderSearch from "../consult-price/components/fastOrderSearch.vue";

export default {
  name: 'ConsultPriceDraftsUpdate',
  components: {fastOrderSearch},
  mixins:[addToCartMixin],
  props: {
    params: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      loading: '',
      AllChecked: false,
      totalNum: 0,
      totalPrice: 0,
      rowData: {},
      totlalPrice: 0,
      tableData: [],
      indexInput: 0,
      selectItem: [],
      searchForm: {
        activityNm: ''
      }
    }
  },
  watch: {
    params: {
      handler(newVal) {
        if (newVal.refreshList) {
          this.doRefresh()
        }
      },
      deep: true
    },
    selectItem(newVal) {
      this.AllChecked = newVal.length == this.tableData.length
      this.checkSum(newVal)
    },
    tableData(newVal) {
      this.tableData.forEach((item, index)=> {
        this.tableData[index]['index'] = index
      })
    },
  },
  mounted() {
    this.init()
  },
  computed:{
    ...mapGetters(['userType','license'])
  },
  methods: {
    init() {
      const {rowData, editType} = this.params
      if (rowData) this.rowData = rowData
      this.editType = editType
      if (this.editType !== 'add') {

      }

    },
    doRefresh() {
      this.$refs.tablePage.doRefresh({currentPage: 0, pageSize: 10})
    },
    goBack(refreshList) {
      this.$emit('change-page', {
        page: 'ConsultPriceDraftsList',
        params: {
          refreshList
        }
      })
    },
    getIndex(index) {
      this.indexInput = index
    },
    handleDelete(data, index) {
      this.tableData.splice(index, 1)
    },

    async selectRow(item) {
      let res = await getConsultPrice({erpCode: item.productCode})
      let isExist = this.tableData.some((item, num) => item.productCode === res.data.list[0].productCode)
      if (isExist) {
        this.$message.error('该商品已存在')
        return
      }
      this.tableData.splice(this.indexInput, 1, res.data.list[0])
      this.$forceUpdate();
    },
    deleteNode(node) {
      // 递归查找要删除的节点的父节点
      const findParent = (nodes, nodeId) => {
        for (let i = 0; i < nodes.length; i++) {
          if (nodes[i].otherSkulist && nodes[i].otherSkulist.find(child => child.skuId === nodeId)) {
            return nodes[i];
          } else if (nodes[i].otherSkulist && nodes[i].otherSkulist.length > 0) {
            const parent = findParent(nodes[i].otherSkulist, nodeId);
            if (parent) return parent;
          }
        }
        return null;
      };

      const parent = findParent(this.tableData, node.skuId);
      if (parent) {
        parent.otherSkulist = parent.otherSkulist.filter(child => child.skuId !== node.skuId);
      } else {
        // 如果没有找到父节点，则直接在根节点数组中筛选
        this.tableData = this.tableData.filter(item => item.skuId !== node.skuId);
      }
    },
    getPage() {
      this.tableData = []
      var pageSize = this.$refs.tablePage.pageSize
      var curPage = this.$refs.tablePage.currentPage < 1 ? 1 : this.$refs.tablePage.currentPage
      if (this.params.shopList.length >= pageSize * curPage) {
        // this.tableData = this.params.shopList.slice(pageSize * (curPage - 1), pageSize * curPage)
        this.params.shopList.slice(pageSize * (curPage - 1), pageSize * curPage).map(item=> {
          this.tableData.push(item.pageSkuVo)
        })
      } else if ((this.params.shopList.length >= pageSize * (curPage - 1)) && (this.params.shopList.length < pageSize * curPage)) {
        // this.tableData = this.params.shopList.slice(pageSize * (curPage - 1), this.params.shopList.length)
        this.params.shopList.slice(pageSize * (curPage - 1), this.params.shopList.length).map(item=> {
          this.tableData.push(item.pageSkuVo)
        })
      }
      return {
        shopList: this.params.shopList,
        tableData: this.tableData
      }
    },
    ReformatPrice(num) {
      var newNum = num.split(',').join('')
      return Number(newNum)
    },
    checkSum(newVal) {
      var totalNum = 0
      var totalPrice = 0
      newVal.map(item => {
        if (item.pageSkuVo.productQuantity) {
          totalNum += item.pageSkuVo.productQuantity
          totalPrice = this.$util.adding(totalPrice,item.pageSkuVo.productQuantity * this.ReformatPrice(item.pageSkuVo.price))
        }
      })
      this.totalNum = totalNum
      this.totalPrice = this.$util.toFixed(totalPrice)
    },
    handleChangeNum(row) {
      this.checkSum(this.selectItem)
    },
    queryData(data) {
      var {shopList, tableData} = this.getPage()
      // 分页截取
      return Promise.resolve({
        code: 0,
        msg: '',
        data: {
          records: tableData,
          total: shopList.length
        }
      })
    },
    updated() {
      this.$refs.tablePage.doLayout()
    },
    handleAdd() {
      this.$emit('change-page', {
        page: 'ConsultPriceList',
        params: {
          editType: 'add'
        }
      })
    },
    handleAllSelect() {
      this.$refs.tablePage.$refs.table.toggleAllSelection()
    },
    handleAddCart() {
        if (this.selectItem.length === 0) {
            this.$message.error('请选择商品')
            return
        }
        let obj = this.selectItem.find(item => item.pageSkuVo.productQuantity % this.getRealMinBuyNum(item.pageSkuVo) !== 0)
        if(obj){
          this.$message.error(`商品${this.$util.getGoodsName(obj.pageSkuVo)}的最小购买倍数必须是${this.getRealMinBuyNum(obj.pageSkuVo) }`)
        }
        let itemList = this.selectItem.map(res => {
            return {
                skuId: res.pageSkuVo.skuId,
                quantity: res.pageSkuVo.productQuantity
            }
        })
        batchAddItem({ itemList }).then(res => {
            const { code } = res
            if (code === '0') {
                this.$message.success('加入成功！')
                this.$store.dispatch('user/setCartNum', res.data.allProductTypeTotalQuantity)
            }
        }).catch(err=>{
        })
    },
    handleAddDraft() {
      if (!this.tableData.length) {
        this.$message.error('询价商品不能为空')
        return
      }
      var inquiryBoxDetailSaveVoList = []
      this.tableData.map((item, index) => {
        var newObj = {
          inquiryBoxDetailId: item.inquiryBoxDetailId,
          productId: item.productId,
          inquiryDraftBoxId: item.inquiryDraftBoxId,
          productNm: item.productNm,
          drugNm:item.drugNm,
          pageSkuVo: this.tableData[index]
          //  skuID:item.skuId
        }
        inquiryBoxDetailSaveVoList.push(newObj)
      })
      var params = {
        title: this.rowData.title ? this.rowData.title : '',
        inquiryDraftBoxId: this.rowData.inquiryDraftBoxId ? this.rowData.inquiryDraftBoxId : '',
        inquiryBoxDetailSaveVoList
      }
      saveOrUpdateInquiryBox(params).then(res => {
        this.$message.success(res.msg)
        this.goBack(true)
      })
    },
    moreCheckBox(val) {
      let value = 0
      val.map(vas => {
        if (vas.pageSkuVo.productQuantity) {
           value = this.$util.adding(value,removeCommas(vas.pageSkuVo.price) * vas.pageSkuVo.productQuantity)
        } else {
          this.params.shopList.map(item => {
            if (vas.inquiryBoxDetailId === item.inquiryBoxDetailId) {
              item.pageSkuVo.productQuantity = 1
              value = this.$util.adding(value,removeCommas(vas.pageSkuVo.price) * vas.pageSkuVo.productQuantity)
            }
          })
        }
      })
      this.totlalPrice = value
      this.selectItem = val
    },

    exportExcel() {
      if(this.tableData.length === 0){
        this.$message.warning('没有数据可以导出')
        return
      }
      let data = []
      this.tableData.map(item => {
        let val = item
        data.push({
          ...val,
          erpCode: val.erpCode || '',
          productNm: this.$util.getGoodsName(val) || '',
          spec: val.spec || '',
          unit: val.unit || '',
          manufacturer: val.manufacturer || '',
          approvalNumber: val.approvalNumber || '',
          productCode: val.productCode || '',
          barCode: val.barCode || '',
          buyNm: val.buyNm || '',
          price: val.price || '',
          packTotal: val.packTotal || '',
          minBuyMultiple: this.getRealMinBuyNum(val),
        })
      })
      exportExcel({ inquiryBoxDetailVoList: data}).then(res => {
        // const content = res.data
        // const fileNamex = '询价单-' + (new Date()).valueOf() + '.xlsx'
        // const blob = new Blob([content])
        // if ('download' in document.createElement('a')) { // 非IE下载
        //   const elink = document.createElement('a')
        //   elink.download = fileNamex
        //   elink.style.display = 'none'
        //   elink.href = URL.createObjectURL(blob)
        //   document.body.appendChild(elink)
        //   elink.click()
        //   URL.revokeObjectURL(elink.href) // 释放URL 对象
        //   document.body.removeChild(elink)
        // } else { // IE10+下载
        //   navigator.msSaveBlob(blob, fileNamex)
        // }
        let blob = new Blob([res.data], {type :'application/vnd.ms-excel'})
        const disposition = res.headers['content-disposition'].split(";")[1].split("=")[1];
        var fileName = decodeURI(disposition);
        //去掉双引号
        fileName = fileName.replace(/\"/g, "");
        let link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = fileName
        link.click()
        link.remove()
        this.$message.success('下载成功')
      })
    },
    // 增加一列商品
    addPro() {
      let startData = {
        skuId: new Date().getTime() + '' + this.tableData.length,
        productId: '',
        orgId: '',
        productCode: '',
        productNm: '',
        minBuyMultiple: '-/-',
        step: 1,
        manufacturer: '-/-',
        rate: '-/-',
        area: '',
        spec: '-/-',
        unit: '-/-',
        medicarePrice: '-/-',
        lastSellPrice: '-/-',
        assessmentPrice: '-/-',
        packTotal: '-/-',
        retailPrice: '-/-',
        stockQuantity: -1,
        stockQuantityStatus: '',
        otcType: '',
        price: '-/-',
        buyersPrice: '-/-',
        buyNum: ''
      }
      this.tableData.push(startData)
      // this.$nextTick(() => {
      //   this.$refs.tablePage.bodyWrapper.scrollTop = this.$refs.tablePage.bodyWrapper.scrollHeight
      // })
    },
  }
}
</script>
<style lang="scss">
.consult-price-drafts-update {
  .page-header-btn {
    .el-input__inner {
      height: 30px;
      line-height: 30px
    }
  }

  .content {
    padding-top: 0;
  }

  .footer {
    box-shadow: none;
    border-top: 1px solid #ccc;
    height: 60px;
  }
}
</style>
<style lang="scss" scoped>
.consult-price-drafts-update {
  padding: 0 15px;

  .page-header {
    height: 52px;
    line-height: 50px;
    text-align: left;
    border-bottom: 1px solid #ccc;
  }

  .page-header-btn {
    display: flex;
    flex-direction: flex-end;
    padding: 10px 0;
    font-style: normal;
    font-size: 12px;
    color: #666666;

    .el-input__inner {
      height: 30px;
    }
  }

  .content-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-con {
      .text-detail {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(102, 102, 102, 1);
        line-height: 17px;
      }
    }

    .right-con {
      display: flex;
      align-items: center;

      .text-one {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(34, 34, 34, 1);
        padding-right: 5px;
      }

      .text-two {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(224, 32, 52, 1);
        margin-right: 15px;
      }

      .btn-one {
        display: inline-block;
        height: 36px;
        line-height: 36px;
        padding: 0 35px;
        border-radius: 2px;
        border: 1px solid rgba(255, 102, 0, 1);
        color: rgba(255, 102, 0, 1);
      }

      .btn-two {
        display: inline-block;
        height: 36px;
        line-height: 36px;
        padding: 0 35px;
        border-radius: 2px;
        border: 1px solid rgba(255, 102, 0, 1);
        background: rgba(255, 102, 0, 1);
      }
    }
  }
}
.addPro {
  cursor: pointer;
  width: 100%;
  margin: 0 0px 20px;
  height: 36px;
  background: white;
  border-radius: 4px;
  border: 1px dashed #cccccc;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  @include TextHighlightColor;
  line-height: 31px;;
  box-sizing: border-box;
}
</style>

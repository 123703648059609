var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "im-pagination" }, [
    (_vm.isShowMainInfo && _vm.total > _vm.limit) || _vm.showCollection
      ? _c("div", { staticClass: "main-info" }, [
          _vm._v(
            " 共" +
              _vm._s(_vm.total) +
              "条记录 第 " +
              _vm._s(_vm.currentPage < 1 ? 1 : _vm.currentPage) +
              " / " +
              _vm._s(_vm.total === 0 ? 1 : Math.ceil(_vm.total / _vm.limit)) +
              " 页 "
          ),
        ])
      : _c("div", { staticClass: "main-info" }),
    _c(
      "div",
      [
        _c("el-pagination", {
          attrs: {
            background: "",
            "hide-on-single-page": _vm.hideOnSinglePage,
            "current-page": _vm.page,
            "page-size": _vm.limit,
            "page-sizes": _vm.pageSizes,
            layout: _vm.layout,
            total: _vm.total,
            "pager-count": _vm.pagerCount,
          },
          on: {
            "update:currentPage": function ($event) {
              _vm.page = $event
            },
            "update:current-page": function ($event) {
              _vm.page = $event
            },
            "update:pageSize": function ($event) {
              _vm.limit = $event
            },
            "update:page-size": function ($event) {
              _vm.limit = $event
            },
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="aside-menu">
    <div class="menu-genre" v-for="(item,index) in rolesMenu" :key="index">
      <h3 class="genre-name" v-if="item.title!==''">{{item.title}}</h3>
      <ul class="menu-routers">
        <li v-for="(itemType,indexType) in item.list"
            :index="itemType.index" :key="itemType.index"
            :class="{'active':params.index===itemType.index,'firstRow':item.title===''}"
            @click="switchTab(itemType)"
        >
          <div class="slide-line"></div>
          <p>{{itemType.text}}</p>
        </li>
      </ul>
    </div>
    <!-- <el-button
      style="margin: 20px auto 0; display: block; width: 85%;"
      @click="logout"
    >
      退出登录
    </el-button> -->
  </div>
</template>

<script>
  import MenuList from './router'
  import { mapGetters } from 'vuex'

  export default {
    name: 'NavMenu',
    props: {
      params: {
        type: Object,
        default: {},
        deep: true
      }
    },
    data () {
      return {
        MenuList,
        rolesMenu: []
      }
    },
    created () {
      this.menuData(this.userType)
    },
    watch: {
      userType: function (userType) {
        this.menuData(userType)
      }
    },
    computed: {
      ...mapGetters(['userType'])
    },
    methods: {
      logout () {
        this.$store.dispatch('user/logout').then(_ => {
          sessionStorage.haveAccount = false
          this.$router.push(`/login`)
        })
      },
      switchTab (itemType) {
        if (this.params.url !== itemType.url) {
          this.$router.replace({
            path: itemType.url
          })
          this.$emit('handle-change-page', { ...itemType })
        }
      },
      menuData (userType) {
        console.log(MenuList)
        MenuList.forEach(menu => {
          if (menu.meta.roles.includes(userType)) {
            let arr = []
            let { list } = menu
            list.forEach(data => {
              if (data.meta.roles.includes(userType)) {
                arr.push(data)
              }
              let { url } = data
              if (url === this.$route.fullPath) {
                this.$emit('handle-change-page', { ...data })
              }
            })
            this.rolesMenu.push({
              ...menu,
              list: arr
            })
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .aside-menu {
    padding: 10px 0 20px;
    width: 100%;
    height: 100%;
    text-align: left;

    .menu-genre {
      width: 100%;
      height: auto;

      .genre-name {
        height: 49px;
        line-height: 49px;
        margin: 0 0 0 24px;
        font-size: 18px;
        color: #222;
        font-weight: bold;
        padding-top: 5px;
        border-top: 1px solid #E5E5E5;
      }


      .menu-routers {
        width: 100%;

        li {
          height: 32px;
          line-height: 32px;
          font-size: 14px;
          color: #222;
          cursor: pointer;
          position: relative;

          &.firstRow {
            height: 40px;
            line-height: 40px;
            padding: 0 24px;
            margin-bottom: 0!important;
            font-size: 14px;
            font-weight: bold;
            @include TextHighlightColor;

            p {
              font-size: 16px;
              padding: 0;
            }
          }

          p {
            font-size: 14px;
            padding: 0 24px;
          }

          &:hover {
            background: $hoverBg;
          }

          &.active {
            @include TextHighlightColor;
            .slide-line {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              /*top: 10px;*/
              left: 14px;
              width: 2px;
              height: 12px;
              background: $ThemeColor;
            }
          }

          &:last-child {
            margin-bottom: 10px;
          }
        }

        /*.slide-line{*/
        /*    width: 2px;*/
        /*    height: 12px;*/
        /*    background: #ffffff;*/

        /*}*/
      }
    }
  }
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog-license",
          attrs: {
            width: "1200px",
            title: "我的站内信",
            visible: _vm.dialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "im-search-pad",
                {
                  staticClass: "search-content",
                  attrs: { model: _vm.searchForm, "has-expand": false },
                  on: { search: _vm.handleSearch, reset: _vm.handleReset },
                },
                [
                  _c(
                    "im-search-pad-item",
                    { attrs: { prop: "readStatus" } },
                    [
                      _vm._v(" 是否已读： "),
                      _c(
                        "el-select",
                        {
                          staticClass: "!w-240px",
                          attrs: {
                            placeholder: "请选择状态",
                            size: "small",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.readStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "readStatus", $$v)
                            },
                            expression: "searchForm.readStatus",
                          },
                        },
                        _vm._l(_vm.readStatusList, function (dict) {
                          return _c("el-option", {
                            key: dict.value,
                            attrs: { label: dict.label, value: dict.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v("   "),
                  _c(
                    "im-search-pad-item",
                    { attrs: { prop: "readStatus" } },
                    [
                      _vm._v(" 发送时间： "),
                      _c("el-date-picker", {
                        staticStyle: { width: "240px" },
                        attrs: {
                          size: "small",
                          "value-format": "yyyy-MM-dd",
                          type: "daterange",
                          "range-separator": "~",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        on: { change: _vm.handleChangeTime },
                        model: {
                          value: _vm.time,
                          callback: function ($$v) {
                            _vm.time = $$v
                          },
                          expression: "time",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "other-btns" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: { click: _vm.handleUpdateList },
                    },
                    [_vm._v("标记已读")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: { click: _vm.handleUpdateAll },
                    },
                    [_vm._v("全部已读")]
                  ),
                ],
                1
              ),
              _c(
                "im-table-page",
                {
                  ref: "tablePage",
                  staticStyle: { "margin-bottom": "15px", padding: "0 15px" },
                  attrs: {
                    data: _vm.tableData,
                    "remote-method": _vm.queryData,
                    height: _vm.tableData.length > 0 ? "340" : "auto",
                    "more-check-box": _vm.checkBox,
                  },
                  on: {
                    "update:data": function ($event) {
                      _vm.tableData = $event
                    },
                    updated: _vm.updated,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      width: "55",
                      align: "center",
                      selectable: _vm.judgeSelect,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "发送人",
                      align: "center",
                      prop: "templateNickname",
                      width: "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "发送时间",
                      prop: "createTime",
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.createTime))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "类型",
                      align: "center",
                      prop: "templateType",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.templateType === 1
                              ? _c("el-tag", [_vm._v("通知公告")])
                              : scope.row.templateType === 2
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("系统公告"),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "消息内容",
                      align: "center",
                      prop: "templateContent",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "是否已读",
                      align: "center",
                      prop: "readStatus",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.readStatus
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("是"),
                                ])
                              : _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v("否"),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "阅读时间",
                      align: "center",
                      prop: "readTime",
                      width: "160",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.readTime))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "100px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-row",
                              { staticClass: "table-edit-row" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      link: "",
                                      type: scope.row.readStatus
                                        ? "primary"
                                        : "warning",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openDetail(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.readStatus ? "详情" : "已读"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.handleHide } }, [
                _vm._v("关 闭"),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "详情", visible: _vm.dialogDetailVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogDetailVisible = $event
            },
          },
        },
        [
          _c(
            "el-descriptions",
            { staticClass: "margin-top", attrs: { column: 1 } },
            [
              _c("el-descriptions-item", { attrs: { label: "发送人" } }, [
                _vm._v(_vm._s(_vm.detailCon.templateNickname)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "发送时间" } }, [
                _vm._v(_vm._s(_vm.detailCon.createTime)),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "消息类型" } },
                [
                  _vm.detailCon.templateType === 1
                    ? _c("el-tag", [_vm._v("通知公告")])
                    : _vm.detailCon.templateType === 2
                    ? _c("el-tag", { attrs: { type: "success" } }, [
                        _vm._v("系统公告"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "是否已读" } },
                [
                  _vm.detailCon.readStatus
                    ? _c("el-tag", { attrs: { type: "danger" } }, [
                        _vm._v("是"),
                      ])
                    : _c("el-tag", { attrs: { type: "info" } }, [_vm._v("否")]),
                ],
                1
              ),
              _c("el-descriptions-item", { attrs: { label: "阅读时间" } }, [
                _vm._v(_vm._s(_vm.detailCon.readTime)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "内容" } }, [
                _vm._v(_vm._s(_vm.detailCon.templateContent)),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
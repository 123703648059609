var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", [
    _c("div", { staticClass: "messageDiv" }, [
      _c("img", {
        staticClass: "icon",
        attrs: { src: require("@/assets/img/customer-service/right.png") },
      }),
      _vm._v(" " + _vm._s(_vm.message) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
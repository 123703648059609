<template>
  <div v-loading="isLoading">

    <ul class="photo-board-items">
      <li :class="['commodity-item',{'expDate-row':item.isSkuInfoRed === 'Y'}]" v-for="(item,index) in tableData" :key="index">
        <div class="commodity-image">

          <img :src="item.picUrl || productDefaultImg" @error="item.picUrl = productDefaultImg" alt/>
          <div class="purchase-history-tag" v-if="item.isHistoryBuyProduct === 'Y'">历史购买</div>
          <div class="sale-out-mark" v-if="item.stockQuantity === 0">已售罄</div>
        </div>
        <div class="commodity-presentation">
          <div class="commodity-name" @click="jumpDetail(item.productId)">
          <span
              class="name-tag"
              v-if="item.otcType && item.otcType !== '其他'"
              :class="{ 'jiaOtc': item.otcType === '甲OTC', 'yiOtc': item.otcType === '乙OTC', 'rxOtc': item.otcType === 'RX' }"
          >{{item.otcType}}</span>
            {{$util.getGoodsName(item)}}
          </div>
          <ul class="commodity-middle">
            <li class="middle-item"><span>商品编码：{{item.productCode}}</span><span>规格：{{item.spec}}</span><span>单位：{{item.unit}}</span><span>件包装：{{item.packTotal}}</span></li>
            <li class="middle-item">厂家(上市许可持有人)/产地：{{item.manufacturer}}</li>
          </ul>
          <tagList :taglist='item.tagList'></tagList>
        </div>

        <div class="commodity-price">
          <div class="price-item price-detail">
            <span class="label">销售价：</span>
            <span class="value sale-price">{{ isLogin ? $util.getPriceByZero(item.price,item) : '登录可见'}}</span>
          </div>
          <div class="price-item">
            <div class="item-side">
              <span class="label">销项税：</span>
              <span class="value">{{item.rate}}</span>
            </div>
            <template v-if="userType ==='SALESMAN'">
              <div class="item-side">库存：{{item.stockQuantity}}</div>
            </template>
            <template v-if="userType === 'BUYERS'">
              <div v-if="item.stockQuantity && item.stockQuantity>=100" class="item-side">库存：<span class="value">>100</span></div>
              <div v-else class="item-side" style="color: #FF6600">库存：<span class="value"><100</span></div>
            </template>
          </div>
          <div class="price-item">
            <div class="item-side">
              <span class="label">最近效期：</span>
              <span class="value">{{item.expDate}}</span>
            </div>
            <div class="item-side">
              最小购买倍数：<span class="value">{{getRealMinBuyNum(item)}}</span>
            </div>
          </div>
        </div>
        <div class="table-operation" v-if="isLicenseOutOfTime">
          <el-button disabled>证照过期{{isLicenseOutOfTime}}</el-button>
        </div>
        <div class="commodity-operation" v-else>
          <div class="input-number">
            <el-input-number
                size="mini"
                v-model="item.count"
                :precision="getDecimalPointNum(item)"
                step-strictly
                :step="getRealMinBuyNum(item)"
                :min="getRealMinBuyNum(item)"
            />
          </div>
          <el-button style="width: 100%"
                     type="info"
                     v-if="item.stockQuantity === 0"
                     @click="handleRegisterGoods(item,item.count)">缺货登记</el-button>
          <el-button v-else class="add-commodity-add"
                     :disabled="isBanAddToCart"
                     @click="beforeAddItem(item.skuId, 'NORMAL', item.count,item)"
                     :loading='item.loading'>加入购物车</el-button>
        </div>
      </li>
    </ul>
    <empty-box style="padding:16px" v-if="tableData.length === 0 && !isLoading"/>

  </div>
</template>

<script>
  import tagList from '@/components/tagList/tagList'
  import {getToken} from "@/utils/auth";
  import productDefaultImg from '@/assets/img/index/product_default.png'
  import { mapGetters } from 'vuex'
  import addToCartMixin from '@/common/addToCart'
  import emptyBox from '@/components/tableEmptyDefault'

  export default {
    name: 'photo-board',
    components:{
      tagList,
      emptyBox
    },
    mixins:[addToCartMixin],
    props: {
      tableData: {
        type: Array
      },
      isLoading: {
        type:Boolean,
        default: true
      }
    },
    data () {
      return {
        loading:false,
        isLogin:false,
        productDefaultImg,
        num: 5
      }
    },
    mounted(){
      this.isLogin = getToken()
    },
    computed: {
      ...mapGetters(['license','userType'])
    },
    methods: {
      jumpDetail(productId){
        this.$router.push({ path: '/detail/' + productId })
      },
    }
  }
</script>

<style scoped lang="scss">


    .disbaledButton{
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #FFFFFF;
    }
  .photo-board-items {
    margin-top: 8px;
    width: 100%;
    height: auto;
    min-height: 200px;
    border-top: 1px solid #E8E8E8;

    .commodity-item {
      width: 100%;
      height: 142px;
      padding: 20px;
      border-bottom: 1px solid #E8E8E8;
      display: flex;
      flex-direction: row;
      &.expDate-row {
        background: rgba(240,230,140,.2) !important;
        li,div,input,>span,p{
          color: #FF5200 !important;
        }
      }
      .commodity-image {
        width: 100px;
        height: 100px;
        position: relative;
        margin-right: 30px;

        img {
          width: 100%;
          height: 100%;
          position: relative;
        }
        .noShoping {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: #000000;
          opacity: 0.5;
          position: absolute;
          left:0;
          top: 0;
          span{
            // width: 60px;
            // height: 28px;
            position: absolute;
            left:0;
            top: 40%;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
          }
        }

        .sale-out-mark {
          position: absolute;
          text-align: center;
          top: 7px;
          left: 7px;
          width: 90px;
          height: 90px;
          line-height: 86px;
          background: rgba(0, 0, 0, 0.7);
          border-radius: 50%;
          font-size: 20px;
          font-weight: 500;
          color: rgba(255, 255, 255, 1);
          box-shadow: 0px 0px 4px black;
        }

        .purchase-history-tag {
          position: absolute;
          padding: 0 4px;
          bottom: 0;
          height: 20px;
          line-height: 20px;
          background: rgba(250, 173, 20, 1);
          border-radius: 0px 8px 0px 0px;
          font-size: 12px;
          font-weight: 600;
          color: rgba(255, 255, 255, 1);
        }
      }

      .commodity-presentation {
        flex: 1;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 20px;

        .commodity-name {
          font-size: 16px;
          font-weight: 600;
          color: rgba(34, 34, 34, 1);
          vertical-align: center;
          cursor: pointer;

          .name-tag {
            font-size: 14px;
            // font-weight: 500;
            color: rgba(255, 255, 255, 1);
            background: rgba(255, 102, 0, 1);
            border-radius: 4px;
            padding: 0 4px;
          }
        }

        .commodity-middle {
          font-size: 12px;
          font-weight: 500;
          color: rgba(102, 102, 102, 1);

          span {
            margin-right: 16px;
          }

          .middle-item {
            height: 17px;
            line-height: 17px;

            &:first-child {
              margin-bottom: 4px;
            }
          }
        }

        .commodity-related-tags {
          display: flex;

          .tag {
            height: 18px;
            font-size: 12px;
            /*font-family:PingFangSC-Medium,PingFang SC;*/
            font-weight: 500;
            /*color:rgba(2,92,214,1);*/
            line-height: 18px;
            border-radius: 3px;
            border-width: 1px;
            border-style: solid;
            margin-right: 4px;
            padding: 0 4px;
            box-sizing: border-box;
          }

          .tag-promotion {
            background: #FF6600;
            border: none;
            color: #ffffff;
          }

          .tag-cash {

            border-color: rgba(2, 92, 214, 0.5);
            color: #025CD6;
          }

          .tag-coupon {
            border-color: rgba(255, 163, 102, 0.5);
            color: #FF6600;
          }

          .tag-plus {
            border-color: rgba(51, 174, 103, 0.5);
            color: #33AE67;
          }
        }

      }

      .commodity-price {
        width: 360px;

        .price-item {
          font-size: 12px;
          font-weight: 600;
          color: rgba(102, 102, 102, 1);
          display: flex;
          align-items: baseline;
          margin-bottom: 8px;

          .item-side {
            text-align: left;
            flex: 1;
          }
        }

        .sale-price {
          font-size: 16px;
          font-weight: 600;
          color: rgba(255, 82, 0, 1);
        }
      }

      .commodity-operation {
        width: 144px;

        .add-commodity {
          height: 40px;
          line-height: 40px;
          background: rgba(255, 102, 0, 1);
          border-radius: 2px;
          border: 0!important;
          font-size: 14px;
          font-weight: 500;
          color: rgba(255, 255, 255, 1);
          cursor: pointer;
          &.disabled {
            background: rgba(192, 192, 192, 1);
            cursor: not-allowed;
            line-height: 40px;
            width: 144px;
            margin: 0 auto;
          }
        }
        /deep/ .el-button.is-disabled{
          width: 100%;
        }
          .add-commodity-add{
            width: 100%;
            // height: 32px;
            // line-height: 32px;
            background: rgba(255, 102, 0, 1);
            border-radius: 2px;
            border: 0 !important;
            font-size: 14px;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
            cursor: pointer;
            &.disabled {
              width: 100%;
              background: #C0C0C0;
              cursor: not-allowed;
              line-height: 32px;
              // width: 102px;
              margin: 0 auto;
            }
          }

        .input-number {
          width: 144px;
          height: 32px;
          margin-bottom: 12px;

          /deep/ .el-input-number {
            width: 144px;

            .el-input-number__decrease, .el-input-number__increase {
              width: 36px;
              height: 32px;
              line-height: 32px;
              background: #ffffff;
              box-sizing: border-box;
              border-radius: 1px;
              border: 1px solid rgba(230, 230, 230, 1);
            }

            .el-input {
              height: 32px;
            }

            .el-input-number__decrease {
              left: 0;
            }

            .el-input-number__increase {
              right: 0;
            }

            .el-input__inner {
              padding: 0 36px;
              height: 32px;
              line-height: 32px;
              vertical-align: top;
              margin-top: 1px;
            }
          }
        }

      }
    }
  }
  .jiaOtc{
    background: #E60012 !important;
  }
  .yiOtc{
    background: #33AE67 !important;
  }
  .rxOtc{
    background: #0091ff !important;
  }
</style>

<template>
  <im-drawer
      v-if="addCustomerVisible"
      :visible.sync="addCustomerVisible"
      :title="title"
      size="50%"
      :modal="false"
      :append-to-body="true"
      @cancel="closeConfirm"
  >
    <div class="section-container">
      <!-- 搜索区域 -->
      <im-search-pad
          class="search-content"
          :has-expand="false"
          @search="handleSearch"
          @reset="handleReset"
      >
        <im-search-pad-item prop="buyersNo">
          <el-input size="small" class="search-name"  style="width: 220px" v-model="buyersNo" placeholder="输入客户编码"/>
        </im-search-pad-item>
        <im-search-pad-item prop="buyersNo">
          <el-input size="small" class="search-name"  style="width: 220px" v-model="buyersNm" placeholder="输入客户名称"/>
        </im-search-pad-item>
      </im-search-pad>
      <!-- 表格内容 -->
      <im-table-page
          ref="tablePageAdd"
          :data.sync="tableData"
          :remote-method="queryData"
      >
        <el-table-column type="index" label="序号" width="60" fixed="left" align="center"/>
        <el-table-column label="分支机构" prop="ettleAccountNm" min-width="160px" show-overflow-tooltip/>
        <el-table-column label="客户名称" prop="buyersNm" min-width="200px" show-overflow-tooltip/>
        <el-table-column label="联系手机" prop="contactMoblie" width="120px" show-overflow-tooltip/>
        <el-table-column label="所在地区" prop="region" min-width="240px" show-overflow-tooltip>
          <template slot-scope="scope">
              <span>{{scope.row.province}}-{{scope.row.city}}-{{scope.row.district}}</span>
          </template>
        </el-table-column>
        <el-table-column label="客户类型" prop="customerTypeName" width="120px" show-overflow-tooltip/>
        <el-table-column label="操作" width="55px" fixed="right" align="center">
          <template slot-scope="scope">
            <el-row class="table-edit-row">
              <span  class="table-edit-row-item">
                <el-button size="small" class="choose-one" style="padding:2px 4px;border: 0px" @click="handleChoose(scope.row)">选择</el-button>
              </span>
            </el-row>
          </template>
        </el-table-column>
      </im-table-page>
    </div>
  </im-drawer>
</template>
<script>
  import productDefault from '@/assets/img/index/product_default.png'
  import { pageIndexProduct } from '@/api/member-center/transactions-using'
  import { getAccount } from '@/api/org'
  import { pageCustomer } from '@/api/member-center/customer-service'


  export default {
    name: 'AddCustomer',
    // props: {
    //   productList: {
    //     type: Array,
    //     default: () => {
    //       return []
    //     }
    //   }
    // },
    data () {
      return {
        addCustomerVisible: false,
        title: '选择客户',
        tableData: [],
        selectItem: [],
        buyersNm: '',
        buyersNo: '',
        productDefault,
        productList: [
          {
            name: '1小麦纤维素颗粒颗粒颗粒非比...',
            size: '20mlx10支/盒',
            changjia: '广州白云山制药有限公司',
            code: 'A191101',
            time: '2019-03-03 12:09:08',
            batch: 'A19100',
            money: '¥75.60',
            number: '20 / 0'
          },
          {
            name: '2小麦纤维素颗粒颗粒颗粒非比...',
            size: '20mlx10支/盒',
            changjia: '广州白云山制药有限公司',
            code: 'A191101',
            time: '2019-03-03 12:09:08',
            batch: 'A19100',
            money: '¥75.60',
            number: '20 / 0'
          },

        ]
      }
    },
    methods: {
      init() {
        this.addCustomerVisible = true
      },
      open () {
        this.addCustomerVisible = true
      },
      handleChoose (data) {
        // 传递客户id  ettleAccountId  buyersId
        // let newObj = {}
        // newObj.buyersId = data.buyersId
        // newObj.ettleAccountId = data.ettleAccountId
        // newObj.buyersNm = data.buyersNm
        // newObj.entContactMan = data.entContactMan
        // newObj.contactMoblie = data.contactMoblie
        // newObj.availableCredit = data.availableCredit
        // newObj.ettleAccountNm = data.ettleAccountNm
        this.$emit('child-msg', data)
        this.addCustomerVisible = false
      },
      doRefresh () {
        this.$refs.tablePageAdd.doRefresh({ currentPage: 0, pageSize: 10 })
      },
      closeConfirm () {
        this.addCustomerVisible = false
      },
      handleSearch () {
          if(this.$refs.tablePageAdd){
            this.$refs.tablePageAdd.doRefresh()
          }
      },
      handleReset () {
        this.buyersNm = ''
        this.buyersNo = ''
        this.$refs.tablePageAdd.doRefresh()
      },
      queryData (data) {
        return pageCustomer({
          ...data,
          condition: {
            buyersNm: this.buyersNm,
            buyersNo: this.buyersNo
          }
        })
      },
    }
  }
</script>
<style scoped lang="scss">
  .search-content {
    border-left: none;
    text-align: left;
  }

  .search-name {
    width:272px;
    height:32px;
    background:rgba(255,255,255,1);
    border-radius:4px;
  }
  .section-container {
    /deep/ {
      .im-search-pad {
        border: 0;
        padding: 0px;
        margin-bottom: 0px;
      }
    }
  }
  .choose-one {
    font-size:14px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    line-height:22px;
    text-shadow:0px 0px 1px rgba(0,0,0,0.25);
  }
</style>

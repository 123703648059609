import Cookies from 'js-cookie'

const TokenKey = 'YTYY_TOKEN'
const BusKey = 'BUS_ID'
const AccountKey = 'ACCOUNT_ID'
const UserType = 'SALESMAN'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getUserType() {
  return Cookies.get(UserType)
}

export function setUserType(type) {
  return Cookies.set(UserType, type)
}

export function removeUserType() {
  return Cookies.remove(UserType)
}

export function getBus() {
  const cook = Cookies.get(BusKey)
  if (cook === 'undefined') {
    Cookies.remove(BusKey)
    location.reload()
  }
  return cook ? JSON.parse(cook) : ''
}

export function setBus(key) {
  return Cookies.set(BusKey, key)
}

export function removeBus() {
  return Cookies.remove(BusKey)
}

export function getAccount() {
  return Cookies.get(AccountKey) ? JSON.parse(Cookies.get(AccountKey)) : ''
}

export function setAccount(key) {
  return Cookies.set(AccountKey, key)
}

export function removeAccount() {
  return Cookies.remove(AccountKey)
}

// todo 暂时存的机构id
const OrgIdKey = 'ORG_ID'

export function getOrgId() {
  return Cookies.get(OrgIdKey)
}

export function setOrgId(orgId) {
  return Cookies.set(OrgIdKey, orgId)
}

export function removeOrgId() {
  return Cookies.remove(OrgIdKey)
}

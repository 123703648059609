var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "aside-menu" },
    _vm._l(_vm.rolesMenu, function (item, index) {
      return _c("div", { key: index, staticClass: "menu-genre" }, [
        item.title !== ""
          ? _c("h3", { staticClass: "genre-name" }, [
              _vm._v(_vm._s(item.title)),
            ])
          : _vm._e(),
        _c(
          "ul",
          { staticClass: "menu-routers" },
          _vm._l(item.list, function (itemType, indexType) {
            return _c(
              "li",
              {
                key: itemType.index,
                class: {
                  active: _vm.params.index === itemType.index,
                  firstRow: item.title === "",
                },
                attrs: { index: itemType.index },
                on: {
                  click: function ($event) {
                    return _vm.switchTab(itemType)
                  },
                },
              },
              [
                _c("div", { staticClass: "slide-line" }),
                _c("p", [_vm._v(_vm._s(itemType.text))]),
              ]
            )
          }),
          0
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <page-modal title="收银台" :active="2">
    <div class="cashier-container">
      <div class="order-prompt">
        <div class="order-state">
          <span>
            <i v-if="overtime !=0 " size="32" class="el-icon-success"></i>
            <i v-else size="32" class="el-icon-error" style="color: red;"></i>
            <span v-if="overtime == 1">订单提交成功，请尽快付款！</span>
            <span v-else-if='overtime == 0' style="padding-right: 10px">您的订单超时未支付已取消</span>
            <span v-else>订单交易成功！</span>
            <span  v-if="overtime == 1">订单号：{{ receiver.orderNum }}</span>
          </span>
          <p> 应付金额<span>{{ $util.toFixed(receiver.orderTotalAmount) }}</span>元</p>
        </div>
        <div class="oder-sum-container" v-if="overtime == 1">
          <p>请您在 <span class="red">{{countdown.dd == '00' ? '':(countdown.dd+'天')}} {{countdown.hh}}时{{countdown.mm}}分{{countdown.ss}}秒</span>
            内完成支付，否则订单会被自动取消
            <el-button type="text" class="info" @click="isExpand = !isExpand">
              订单信息<i class="toggle-arrow" :class="isExpand ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i></el-button>
          </p>
        </div>
        <div class="oder-sum-container" v-else-if='overtime == 0'><p>订单已取消</p></div>
      </div>

      <div class="cashier-info" v-show="isExpand">
        <div class="cashier-title">订单信息</div>
        <div class="cashier-content">
          <div class="item">
            <div class="label">订单编号</div>
            <div class="text">{{ receiver.orderNum }}
              <el-button type="text" @click="goDetails(orderId)">查看订单详情</el-button>
            </div>
          </div>
          <div class="item">
            <div class="label">交易金额</div>
            <div class="text">{{ $util.toFixed(receiver.transactionTotalAmount) }}元</div>
          </div>
          <div class="item">
            <div class="label">购买时间</div>
            <div class="text">{{ receiver.createDate}}</div>
          </div>
          <div class="item">
            <div class="label">收货人</div>
            <div class="text">{{ receiver.receiverName }}</div>
          </div>
          <div class="item">
            <div class="label">联系电话</div>
            <div class="text">{{ receiver.receiverMobile }}</div>
          </div>
          <div class="item">
            <div class="label">收货地址</div>
            <div class="text">
              <span>{{ receiver.receiverAddr }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="cashier-pay" v-if="overtime != 0 && orderTotalAmount != 0">
        <div class="cashier-info cashier-initial" v-if="receiver.paymentWayCode !== 'CREDIT'">
          <div class="flexCash" v-for="item in payWayList" :key="item.paymentWayId"
               :class="item.select ? 'selected' : 'unselected'" @click="changePay(item)">
            <div class="flex">
              <div class="selection-icon"></div>
              <img :src="item.payWayLogo" alt>
              <p>{{ item.paymentWayName }}</p>
            </div>
            <div v-show="item.select" class="payment-amount">支付<span>{{ receiver.orderTotalAmount }}</span>元</div>
          </div>

<!--          <div class="qrCodePayContent" v-show="ICBCQrCode">-->
<!--            <canvas id="payQrCode" class="payQrCode"></canvas>-->
<!--            <div class="qrCodeTips">支持微信、支付宝扫码</div>-->
<!--          </div>-->

          <el-button type="primary" class="btn-choose" :loading="payBtnLoading" :disabled="payBtnLoading" @click="goToPay">立即支付</el-button>
        </div>
        <el-dialog width="600px" :visible.sync="dialogPayVisible" :show-close="false" :close-on-click-modal="false">
          <p>
            请您在
            <span style="color: #FF3B30;">{{countdown.dd == '00' ? '':(countdown.dd+'天')}} {{countdown.hh}}时{{countdown.mm}}分{{countdown.ss}}秒</span>
            内完成支付，否则订单会被自动取消
          </p>
          <div class="payVisible">
            <div class="payVisible-left">
              <p>支付成功请点击</p>
              <el-button type="primary" @click="goDetail(orderId)">已完成付款</el-button>
            </div>
            <div class="payVisible-line"></div>
            <div>
              <p>遇到问题请点击</p>
              <el-button @click="goToPay">重新付款</el-button>
            </div>
          </div>
          <div class="payVisible-p">
            <p>注：重新付款前，请关闭之前的付款页面</p>
            <p>如有疑问或需要帮助，请联系客服 <span>0592-8128032</span></p>
          </div>
        </el-dialog>
        <template>
          <!-- 支付表单 -->
          <div v-html="payFormHtml"></div>
        </template>
<!--        <div class="cashier-info">-->
<!--          <div class="cashier-title">注意事项</div>-->
<!--          <div class="cashier-content attention-list">-->
<!--            1、收货地址是否正确购买宝贝的规格是否是自己想要的规格（规格指的是码子、颜色等信息）付款价格是否有出入<br/>-->
<!--            2、是否赠送运费险了，如果未赠送，需看看是否要用官方赠送的运费险或者自行购买运费险<br/>-->
<!--            3、是否用金币币或者积分<br/>-->
<!--            4、是否要朋友代付<br/>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </page-modal>
</template>

<script>
  import {getOrderInf } from '@/api/shop-cart'
  import pageModal from './page-modal'
  import pay from '@/common/pay'
  export default {
    name: 'cashier',
    components: {
      pageModal
    },
    mixins:[pay],
    props: {
      params: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data () {
      return {
        receiver: {
          receiverName: '',
          receiverMobile: '',
          createDate: '',
          orderTotalAmount: '',
          orderNum: '',
          receiverAddr: '',
          paymentWayCode: '',
          paymentTime: ''
        },
        countdown: {
          dd: 0,
          hh: 0,
          mm: 0,
          ss: 0
        },
        dialogPayVisible: false,
        isExpand: true,
        orderTotalAmount:'',
        overtime: 1,
        orderId: '',
        timer: '',
        outInterVal: ''
      }
    },
    created () {
      //获取支付方式
      this.getPayWay().then(res => {
        if (res.data.length) {
          res.data.map(val => { val.select = false })
          this.payWayList = res.data
          this.payWayList[0].select = true
          this.handlePay()
        }
      })
      if (this.$route.params.id) {
        this.orderId = this.$route.params.id
      }
    },
    mounted () {
      getOrderInf({ orderId: this.orderId }).then(res => {
        const { data } = res
        this.orderTotalAmount = data.data.orderTotalAmount;
        if(data.data.orderTotalAmount == 0){
          this.overtime = 2;
          this.getTimeList(res.data.data.paymentTime)
        }else{
          
        let start = new Date().getTime()
        let rightTime = res.data.data.paymentTime  - start
        if (rightTime < 0) {
          this.overtime = 0
        }
        this.receiver = data.data
        this.getTimeList(res.data.data.paymentTime)
        }
        //赊销 跳转到支付成功页面
        if (this.receiver.paymentWayCode === 'CREDIT') {
          this.$router.push({ path: '/pay-success/' + this.orderId })
        }
      })
    },
    methods: {
      changePay ({paymentWayId,onlinePayWayEnum}) {
        this.payWayList.map(val => {
          val.select = val.paymentWayId === paymentWayId
        })
        this.handlePay()
      },
      //倒计时
      getTimeList (endTime) {
        let outInterVal = null
        let _this = this
        if(endTime === null){
          this.overtime = 2;
          getOrderInf({ orderId: _this.orderId }).then(res => {
              const { data } = res
              _this.receiver = data.data
              // clearInterval(outInterVal)
          }).catch(error => {  
            console.error('cashier.vue->getTimeList getOrderInf error:', error);  
              // 可以根据错误类型或消息进行更具体的处理  
          });
        }else{
          
        outInterVal = setInterval(function () {
          let start = new Date().getTime()
          let rightTime = endTime - start
          if (rightTime > 0) {
            let dd = Math.floor(rightTime / 1000 / 60 / 60 / 24)
            let hh = Math.floor((rightTime / 1000 / 60 / 60) % 24)
            let mm = Math.floor((rightTime / 1000 / 60) % 60)
            let ss = Math.floor((rightTime / 1000) % 60)
            dd = dd > 9 ? dd : '0' + dd
            hh = hh > 9 ? hh : '0' + hh
            mm = mm > 9 ? mm : '0' + mm
            ss = ss > 9 ? ss : '0' + ss
            _this.countdown.dd = dd
            _this.countdown.hh = hh
            _this.countdown.mm = mm
            _this.countdown.ss = ss
            _this.overtime = 1
          } else {
            _this.overtime = 0
            // _this.$router.go(0)
            getOrderInf({ orderId: _this.orderId }).then(res => {
              const { data } = res
              _this.receiver = data.data
              clearInterval(outInterVal)
            })
          }
        }, 1000)
        }
      },
      goDetail(orderId) {
        this.$router.push({ path: '/order-detail/' + orderId })
      },
      goDetails (orderId) {
        const { href } = this.$router.resolve({
          path: '/order-detail/' + orderId
        })
        window.open(href, '_blank')
      },
    },
    destroyed () {
      clearInterval(this.timer)
    }
  }
</script>

<style scoped lang="scss">
  .flexCash {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1056px;
    padding: 20px 30px;
    margin: 0 auto;
    border-radius: 4px;
    border: 2px solid #fff;
    cursor: pointer;
    img{
      width: 35px;
      height: auto;
    }
    &.selected {
      background: #f1f7fe;

      .selection-icon {
        background: url("../img/selected.png") no-repeat;
        background-size: 100% 100%;
      }
    }

    &.unselected .selection-icon {
      background: url("../img/unselected.png") no-repeat;
      background-size: 100% 100%;
    }

    &:hover {
      border: 2px solid #1890FF;
    }

    .flex {
      display: flex;
      align-items: center;

      p {
        color: #262626;
        font-size: 21px;
        font-weight: bold;
        margin-left: 13px;
      }
    }

    .selection-icon {
      width: 28px;
      height: 28px;
      margin-right: 40px;
    }

    .payment-amount {
      text-align: left;
      font-size: 15px;
      font-weight: bold;
      color: rgba(102, 102, 102, 1);
      margin-bottom: 12px;

      span {
        font-size: 22px;
        padding: 0 4px;
        position: relative;
        top: 1px;
        color: rgba(255, 59, 48, 1);
      }
    }
  }

  .cashier-container {
    width: 1200px;
    height: auto;
    box-sizing: content-box;
    margin: 20px auto;
    border-radius: 8px;
    /*订单提示*/
    .order-prompt {
      padding: 20px 0;
      text-align: left;
      border-bottom: 1px solid #EEEEEE;

      .order-state {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        font-size: 24px;
        font-weight: 500;
        color: rgba(34, 34, 34, 1);

        & > span {
          display: flex;
          align-items: center;
        }

        i {
          margin-right: 8px;
          font-size: 32px;
          color: #09AD25;
        }

        p {
          font-size: 15px;
          font-weight: bold;
          color: #2B2B2B;

          span {
            font-size: 20px;
            color: rgba(255, 59, 48, 1);
            padding: 0 4px;
            position: relative;
            top: 1px;
          }
        }
      }

      .oder-sum-container {
        padding-left: 40px;
        height: 22px;
        color: #9D9D9D;
        line-height: 22px;

        .red {
          color: #FF3B30;
          padding: 0 4px;
        }

        .info {
          font-size: 14px;
          float: right;
          cursor: pointer;
        }
      }
    }

    .cashier-pay {
      background: #fff;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.11);
      border-radius: 8px;
      margin-top: 5px;

      .cashier-info {
        padding: 40px;
      }

      /deep/ {
        .el-dialog__header {
          background: #fff;
        }

        .el-button {
          width: 140px;
        }
      }

      .payVisible {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 40px;

        .payVisible-line {
          height: 76px;
          width: 1px;
          margin: 0 28px;
          background: #e5e5e5;
        }

        p {
          padding: 0 0 20px;
        }
      }

      .payVisible-p {
        color: #999999;
        margin: 28px 0 32px;

        p {
          line-height: 2em;

          span {
            color: #222;
          }
        }
      }
    }

    /**/
    .cashier-info {
      padding: 20px;
      display: flex;
      justify-content: flex-start;

      &.cashier-initial {
        display: inline-block;
        text-align: left;
      }

      .cashier-title {
        margin-right: 40px;
        font-size: 14px;
        font-weight: 600;
      }

      .cashier-content {
        flex: 1;
        /*订单信息*/
        .item {
          width: 100%;
          height: auto;
          display: flex;
          margin-bottom: 13px;
          font-size: 12px;
          font-weight: 500;
          color: rgba(34, 34, 34, 1);

          .label {
            width: 68px;
            min-width: 48px;
            text-align: left;
            flex-shrink: 0;
            margin-right: 20px;
          }

          .text {
            text-align: left;
            flex: 1;

            span {
              padding-right: 5px;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        /*支付平台*/
        .payment-amount {
          text-align: left;
          font-size: 12px;
          font-weight: 500;
          color: rgba(102, 102, 102, 1);
          margin-bottom: 12px;

          span {
            font-size: 16px;
            color: rgba(255, 59, 48, 1);
          }
        }

        .payment-button-groups {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;

          .payment-button {
            display: inline-block;
            width: 200px;
            height: 50px;
            text-align: center;
            text-decoration: none;
            border: 1px solid rgba(204, 204, 204, 1);
            background: #ffffff;
            margin-right: 16px;
            padding: 0;
            box-sizing: border-box;

            &.wechat {
              background: url("../img/wechat.png") 100% 100% no-repeat;
            }

            &.alipay {
              background: url("../img/alipay.png") 100% 100% no-repeat;
            }

            &.unionpay {
              background: url("../img/unionpay.png") 100% 100% no-repeat;
            }
          }
        }

        /*注意事项*/
        &.attention-list {
          text-align: left;
          font-size: 12px;
          font-weight: 500;
          color: rgba(102, 102, 102, 1);
          line-height: 16px;
        }
      }

      .qrCodePayContent{
        width: 100%;
        .payQrCode{
          display: block;
          width: 200px !important;
          height: 200px !important;
          margin: 0 auto;
        }
        .qrCodeTips{
          margin: 0 auto;
          text-align: center;
          width: 100%;
          color: #999999;
        }
      }
      .btn-choose {
        display: block;
        margin: 40px auto 0;
        width: 180px;
        font-size: 18px;
        height: 48px;
        line-height: 48px;
        padding: 0;
        background: #E02020;
        border-color: #E02020;

        &:hover {
          opacity: .9;
        }
      }
    }
  }
</style>

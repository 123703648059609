<template>
  <transition name='fade'>
    <!-- 蒙版 -->
    <div class="mask" v-if="show && imgUrl.length" @touchmove.prevent @click="onMask">
      <div class="window" v-if="imgUrl.length">
        <img @click.stop="bindUrl(imgUrl[0].link)" :src="imgUrl[0].sysFileUrl" alt="">
        <img class="close" src="../../assets/img/icon/icon-guanbi.png" alt="">
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'app',
    data () {
      return {
        show: true
      }
    },
    props: {
      imgUrl: {
        type: Array,
        default(){
          return []
        }
      }
    },
    created(){
        if(!!this.imgUrl.length){
          this.show = !!this.imgUrl[0].sysFileUrl
        }
    },
    methods: {
      onMask () {
        this.show = false
      },
      bindUrl (path) {
        if (path.substr(0, 1) === '/') {
          this.$router.push({ path })
        } else {
          window.open(path, '_blank')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  // 渐变过渡
  .fade-enter, .fade-leave-active {
    opacity: 0;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .35s;
  }

  // 全局弹窗
  .mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;

    .fixed {
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 75/75rem;
    }

    .window {
      width: 100%;
      border-radius: 8px;
      text-align: center;
      position: fixed;
      top: 20vh;
    }

    .close {
      position: absolute;
      top: -40px;
    }
  }
</style>

<template>
  <div class="pagination-container">
    <el-pagination
        background
        :current-page.sync="page"
        :page-size.sync="limit"
        :hide-on-single-page='isOnlyOnePageShow'
        :page-sizes="pageSizes"
        layout="prev,pager,next,total,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
  </div>
</template>

<script>
  import defaultSettings from '@/settings'
  export default {
    name: 'pagination',
    props: {
      pageSize: {
        type: Number,
        default: defaultSettings.pageSize
      },
      pageSizes: {
        type: Array,
        default () {
          return [10, 20, 30, 40, 50, 100]
        }
      },
      currentPage: {
        type: Number,
        default: 1
      },
      total: {
        required: true,
        type: Number
      },
      isShowMainInfo: {
        type: Boolean,
        default: true
      },
      isOnlyOnePageShow: { //一页时是否显示分页
        type: Boolean,
        default: false
      }
    },
    computed: {
      page: {
        get() {
          return this.currentPage
        },
        set(val) {
          this.$emit('update:currentPage', val)
        }
      },
      limit: {
        get() {
          return this.pageSize
        },
        set(val) {
          this.$emit('update:pageSize', val)
        }
      }
    },
    methods: {
      handleSizeChange(val) {
        this.$emit('pagination', { page: this.currentPage, pageSize: val })
      },
      handleCurrentChange(val) {
        this.$emit('pagination', { page: val, pageSize: this.limit })
      }
    }
  }
</script>

<style scoped lang="scss">
  .pagination-container {
    padding: 20px 0 40px;
    background: #ffffff;

    /deep/ .el-pagination {
      font-size: 14px;
      color: #222222;

      .btn-prev, .btn-next {
        background: #ffffff;
        border: 1px solid rgba(229, 229, 229, 1);
      }

      .el-pager {
        color: #666666;

        li {
          background: #ffffff;
          border: 1px solid rgba(229, 229, 229, 1);
        }
      }

      .el-pagination__total {
        margin-left: 35px;
      }
    }


  }
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "successDiv" }, [
    _vm._m(0),
    _c("div", { staticClass: "orderDiv" }, [
      _c(
        "div",
        { staticClass: "successTip" },
        [
          _vm._m(1),
          _c(
            "el-button",
            {
              staticClass: "backBut",
              attrs: { size: "mini" },
              on: { click: _vm.back },
            },
            [_vm._v("返回订单")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "orderInfo" }, [
        _c("div", { staticClass: "title" }, [_vm._v("订单信息")]),
        _c(
          "div",
          { staticClass: "tableDiv" },
          [
            _c(
              "el-table",
              {
                staticClass: "table",
                staticStyle: { width: "100%" },
                attrs: { data: _vm.tableData, border: "" },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "序号",
                    align: "center",
                    type: "index",
                    width: "60",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "订单编号",
                    prop: "orderId",
                    width: "200",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "交易金额",
                    prop: "orderTotalAmount",
                    align: "right",
                    width: "100",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$util.toFixed(scope.row.orderTotalAmount)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "客户名称",
                    prop: "buyersNm",
                    width: "200",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "门店编码",
                    prop: "storeCode",
                    width: "112",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "联系电话",
                    prop: "receiverMobile",
                    width: "112",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "收货地址",
                    prop: "receiverAddr",
                    "min-width": "200",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "购买时间",
                    prop: "saveDate",
                    width: "155",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: { fixed: "right", width: "64", label: "操作" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              staticStyle: { padding: "4px 6px" },
                              attrs: { size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.toOrderDetail(scope.row.orderId)
                                },
                              },
                            },
                            [_vm._v("详情")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "orderInfo" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("缺货提醒 "),
          _c("span", { staticClass: "titleTip" }, [
            _vm._v("以下商品存在缺货，请到 "),
            _c(
              "a",
              {
                staticStyle: { color: "#0971EB" },
                on: { click: _vm.orderAgain },
              },
              [_vm._v("委托配送缺货下单")]
            ),
            _vm._v(" 中查"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "tableDiv" },
          [
            _c(
              "el-table",
              {
                staticClass: "table",
                staticStyle: { width: "100%" },
                attrs: { data: _vm.outOfStockData, border: "" },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "序号",
                    type: "index",
                    width: "60",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "门店名称",
                    prop: "storeName",
                    width: "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "商品编码",
                    prop: "erpCode",
                    width: "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "商品名称",
                    prop: "drugNm",
                    width: "150",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "规格",
                    prop: "spec",
                    width: "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "平台价格",
                    prop: "price",
                    width: "120",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$util.toFixed(scope.row.price)) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "缺货数量",
                    prop: "quantity",
                    width: "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "金额",
                    prop: "totalPrice",
                    width: "100",
                    align: "right",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$util.toFixed(scope.row.totalPrice)) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "缺货描述",
                    prop: "descInfo",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "registerHeader" }, [
        _c("img", {
          staticClass: "bgImg",
          attrs: { src: require("@/assets/img/register/bg.png"), alt: "" },
        }),
        _c("div", { staticClass: "division" }),
        _c("span", { staticClass: "title" }, [_vm._v("下单成功")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { display: "flex" } }, [
      _c("i", { staticClass: "el-icon-success successIcon" }),
      _vm._v(" 您的订单已提交，请耐心等待！ "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
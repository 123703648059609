var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "im-page-modal",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "good-collection-page",
      attrs: { title: "收藏的商品", isShowBack: false },
    },
    [
      _vm.license.isFailure === "Y"
        ? _c(
            "div",
            { staticClass: "aptitudes" },
            [
              _vm._v(
                "温馨提示：" +
                  _vm._s(
                    _vm.userType !== "SALESMAN"
                      ? "您有" +
                          _vm.license.failureSum +
                          "张证照已过期,请联系业务员更换,以免影响您的正常采购"
                      : "该客户有" +
                          _vm.license.failureSum +
                          "张证照已过期，以免影响正常采购！"
                  )
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "text" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v("查看详情")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tableData.length === 0,
              expression: "tableData.length === 0",
            },
          ],
          staticClass: "empty-box",
        },
        [
          _c(
            "div",
            [
              _c("el-image", {
                staticStyle: { width: "160px", height: "160px" },
                attrs: { src: _vm.emptyDefault, fit: "fill" },
              }),
              _c("p", { staticClass: "empty-tip" }, [_vm._v("收藏无商品~")]),
            ],
            1
          ),
        ]
      ),
      _c(
        "im-table-page",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tableData.length !== 0,
              expression: "tableData.length !== 0",
            },
          ],
          ref: "tablePage",
          staticStyle: { "margin-bottom": "15px" },
          attrs: {
            data: _vm.tableData,
            isShowTableHeager: false,
            hideOnSinglePage: false,
            showCollection: true,
            isHasBorder: false,
            addQuantity: true,
            isShowMainInfo: true,
            "remote-method": _vm.queryData,
            "more-check-box": (val) => {
              _vm.selectItem = val
            },
          },
          on: {
            "update:data": function ($event) {
              _vm.tableData = $event
            },
            updated: _vm.updated,
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "商品图片", prop: "picUrl", "min-width": "130px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100px",
                          height: "100px",
                          poaition: "relative",
                        },
                      },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/detail/" + scope.row.productId } },
                          [
                            _c("el-image", {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src:
                                  scope.row.productVo.picUrl ||
                                  _vm.productDefault,
                                fit: "fill",
                              },
                              on: {
                                error: function ($event) {
                                  scope.row.productVo.picUrl =
                                    _vm.productDefault
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "router-link",
                          { attrs: { to: "/detail/" + scope.row.productId } },
                          [
                            scope.row.productVo.isOnSale === "N"
                              ? _c("el-image", {
                                  staticClass: "collect-fail",
                                  staticStyle: {
                                    width: "80px",
                                    height: "80px",
                                  },
                                  attrs: { src: _vm.collectFail, fit: "fill" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "商品描述",
              prop: "orderFullAmount",
              width: "240px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticStyle: { height: "100%", width: "240px" } },
                      [
                        _c(
                          "el-col",
                          { staticClass: "detail-title" },
                          [
                            scope.row.productVo.otcType === "A_OTC"
                              ? _c(
                                  "span",
                                  { staticClass: "detail-title-tap" },
                                  [_vm._v("甲OTC")]
                                )
                              : _vm._e(),
                            scope.row.productVo.otcType === "B_OTC"
                              ? _c(
                                  "span",
                                  { staticClass: "detail-title-tap" },
                                  [_vm._v("乙OTC")]
                                )
                              : _vm._e(),
                            scope.row.productVo.otcType === "RX"
                              ? _c(
                                  "span",
                                  { staticClass: "detail-title-tap" },
                                  [_vm._v("RX")]
                                )
                              : _vm._e(),
                            _c(
                              "router-link",
                              {
                                attrs: { to: "/detail/" + scope.row.productId },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "text_hidden",
                                    staticStyle: {
                                      display: "inline-block",
                                      width: "180px",
                                      cursor: "pointer",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$util.getGoodsName(
                                            scope.row.productVo
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("el-col", { staticClass: "con-text" }, [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-right": "16px",
                                overflow: "hidden",
                                "text-overflow": "ellipsis",
                                "white-space": "nowrap",
                              },
                              attrs: { title: scope.row.productVo.spec },
                            },
                            [
                              _vm._v(
                                "规格：" + _vm._s(scope.row.productVo.spec)
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v("单位：" + _vm._s(scope.row.productVo.unit)),
                          ]),
                        ]),
                        _c("el-col", { staticClass: "con-text" }, [
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "16px" } },
                            [
                              _vm._v(
                                "中包装：" +
                                  _vm._s(scope.row.productVo.midPackTotal)
                              ),
                            ]
                          ),
                          _c("span", [
                            _vm._v(
                              "件包装：" + _vm._s(scope.row.productVo.packTotal)
                            ),
                          ]),
                        ]),
                        _c("el-col", { staticClass: "con-text" }, [
                          _c(
                            "p",
                            {
                              staticClass: "text_hidden",
                              attrs: {
                                title: scope.row.productVo.manufacturer,
                              },
                            },
                            [
                              _vm._v(
                                "厂家(上市许可持有人)/产地：" +
                                  _vm._s(scope.row.productVo.manufacturer)
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "商品价格",
              "min-width": "140px",
              "show-overflow-tooltip": "",
              align: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "con-text",
                        staticStyle: { height: "100%", "padding-top": "31px" },
                      },
                      [
                        _c("el-col", [
                          _c("span", [_vm._v("销售价：")]),
                          _c("span", { staticStyle: { color: "#FA6400" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$util.getPriceByZero(
                                  scope.row.productVo.price,
                                  scope.row.productVo
                                )
                              )
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "库存",
              "min-width": "170px",
              "show-overflow-tooltip": "",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "con-text",
                        staticStyle: { height: "100%", "padding-top": "5px" },
                      },
                      [
                        _c("el-col", [
                          _c("span", [_vm._v("库存：")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row.productVo.stockQuantity >= 100
                                  ? ">100"
                                  : "<100"
                              )
                            ),
                          ]),
                        ]),
                        _c("el-col", [
                          _c("span", [_vm._v("最小购买倍数：")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.getRealMinBuyNum(scope.row.productVo))
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "min-width": "170px",
              "show-overflow-tooltip": "",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.isLicenseOutOfTime
                      ? _c(
                          "div",
                          { staticClass: "table-operation" },
                          [
                            _c("el-button", { attrs: { disabled: "" } }, [
                              _vm._v("证照过期"),
                            ]),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticStyle: { height: "100%" } },
                          [
                            _c(
                              "el-col",
                              [
                                _c("el-input-number", {
                                  staticClass: "change-count",
                                  attrs: {
                                    size: "mini",
                                    precision: _vm.getDecimalPointNum(
                                      scope.row.productVo
                                    ),
                                    step: _vm.getRealMinBuyNum(
                                      scope.row.productVo
                                    ),
                                    min: _vm.getRealMinBuyNum(
                                      scope.row.productVo
                                    ),
                                    "step-strictly": "",
                                  },
                                  model: {
                                    value: scope.row.count,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "count", $$v)
                                    },
                                    expression: "scope.row.count",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticStyle: { "margin-top": "15px" } },
                              [
                                scope.row.productVo.stockQuantity === 0
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { width: "140px" },
                                        attrs: { size: "mini", type: "info" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRegisterGoods(
                                              scope.row,
                                              scope.row.count
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("缺货登记")]
                                    )
                                  : _c(
                                      "el-button",
                                      {
                                        staticClass: "cart-buy",
                                        attrs: {
                                          disabled: _vm.isBanAddToCart,
                                          loading: scope.row.loading,
                                          size: "small",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.beforeAddItem(
                                              scope.row.skuId,
                                              "NORMAL",
                                              scope.row.count,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("加入购物车")]
                                    ),
                              ],
                              1
                            ),
                            _c("i", {
                              staticClass: "el-icon-delete del-product-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(scope.row)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.tableData.length !== 0
        ? _c(
            "div",
            {
              staticClass: "content-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "div",
                { staticClass: "left-con" },
                [
                  _c(
                    "el-checkbox",
                    {
                      staticStyle: { "margin-right": "20px" },
                      on: { change: _vm.handleAllSelect },
                      model: {
                        value: _vm.AllChecked,
                        callback: function ($$v) {
                          _vm.AllChecked = $$v
                        },
                        expression: "AllChecked",
                      },
                    },
                    [_vm._v("全选")]
                  ),
                  _c("span", { staticClass: "text-detail" }, [
                    _c("span", { staticStyle: { color: "#0971EB" } }, [
                      _vm._v("已选 " + _vm._s(_vm.selectItem.length) + " 商品"),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "btn-buy",
                  attrs: {
                    type: "primary",
                    loading: _vm.loadingCartAll,
                    disabled: _vm.isBanAddToCart,
                  },
                  on: { click: _vm.handleBuyMore },
                },
                [
                  _vm._v(
                    _vm._s(_vm.isLicenseOutOfTime ? "证照过期" : "加入购物车")
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
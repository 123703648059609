<template>
  <div class="commodity-list-container">
    <search :text.sync="searchForm.searchFiled" />
    <div class="commodity-list">
      <div class="breadcrumb-container">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item v-if="!selectArr[0]">{{ searchForm.searchFiled }}</el-breadcrumb-item>
          <el-breadcrumb-item v-if="selectArr[0]">
            <el-dropdown placement="bottom-start">
              <span style="cursor: pointer">{{ selectArr[0].categoryNm }}</span>
              <el-dropdown-menu slot="dropdown" class="childList">
                <el-dropdown-item class="childListson" v-for="(item, index) in productCategory.childTree" :key="index">
                  <router-link
                    :to="{ path: '/commodity-list', query: { categoryId: item.categoryId, categoryCode: item.categoryCode } }">{{
                    item.categoryNm }}</router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-breadcrumb-item>
          <el-breadcrumb-item v-if="selectArr[1]">
            <el-dropdown placement="bottom-start">
              <span style="cursor: pointer">{{ selectArr[1].categoryNm }}</span>
              <el-dropdown-menu slot="dropdown" class="childList">
                <el-dropdown-item class="childListson" v-for="(item, index) in secondArray" :key="index">
                  <router-link
                    :to="{ path: '/commodity-list', query: { categoryId: item.categoryId, categoryCode: item.categoryCode } }">{{
                    item.categoryNm }}</router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-breadcrumb-item>
          <el-breadcrumb-item v-if="selectArr[2]">{{ selectArr[2].categoryNm }}</el-breadcrumb-item>
        </el-breadcrumb>
        <!--种类分页-->

      </div>
      <div class="aptitudes" v-if="license.isAllLicenseExpired === 'Y'">温馨提示：{{ userType !== 'SALESMAN' ? '您有' +
        license.failureSum + '张证照已过期,请联系业务员更换,以免影响您的正常采购' : '该客户有' + license.failureSum + '张证照已过期，以免影响正常采购！' }}<el-button
          size="small" type="text" @click="handleConfirm">查看详情</el-button>
      </div>
      <swiper style="margin-top: -32px" :appoint="true" />
      <category-search ref="categorySearch" :view-model="viewModel" @change-page="handleChangViewModel" @select="select"
        @secondCategory='secondCategory' :manufacture='manufacture' :searchForm.sync="searchForm"
        @secondFilter="secondFilter" :searchs="searchs" @search="queryData">
        <template>
          <div data-v-7b59048f="" class="search-input el-input el-input--large el-input-group el-input-group--append"
            style="width: 240px; height: 32px; margin: 6px 20px 0 10px;">
            <input type="text" autocomplete="off" placeholder="在结果中搜索" style="height: 32px"
              v-model="searchForm.searchFiled" class="el-input__inner">
            <div class="el-input-group__append">
              <button data-v-7b59048f="" type="button" style="height: 32px" @click="queryData"
                class="el-button search-button el-button--mini">
                <i class="el-icon-search"></i>
              </button>
            </div>
          </div>
          <div class="kind-paging">
            找到品种{{ total }}个
            <span>{{ currentPage }}/{{ Math.ceil(total / pageSize) }}</span>
            <el-pagination :small="true" background layout="prev,next" :total="total" :current-page.sync="currentPage"
              :page-size.sync="pageSize" @prev-click="handlePrev" @next-click="handlePrev" />
          </div>
        </template>
      </category-search>
      <list-board :table-data="tableData" :isLoading="isLoadingList" :sale-date-type="saleDateType" :sorts="sorts"
        @sortOder="sortOder" v-if="viewModel === 'ListBoard'" />
      <photo-board :table-data="tableData" :isLoading="isLoadingList" v-if="viewModel === 'PhotoBoard'" />
      <pagination v-if="tableData.length" :total="total" :current-page.sync="currentPage" :page-size.sync="pageSize"
        @pagination="handlePagination" />
    </div>

    <tool-bar />
  </div>
</template>

<script>
import Search from '../../components/Search/Search'
import ToolBar from '../../components/ToolBar/ToolBar'
import Swiper from '../../components/ForRcommend'
import CategorySearch from './components/category-search'
import ListBoard from './components/list-board'
import PhotoBoard from './components/photo-board'
import Pagination from './components/pagination'
import { pageIndexProduct, getManufacture, getConfigMap } from '@/api/commodity-list'
import { getBus } from '@/utils/auth'
import { calcSalePrice } from '@/utils'
import { mapGetters } from 'vuex'
import addToCartMixin from '@/common/addToCart'

export default {
  name: 'CommodityList',
  components: { Search, ToolBar, Swiper, CategorySearch, ListBoard, PhotoBoard, Pagination },
  mixins: [addToCartMixin],
  data() {
    return {
      searchForm: {
        searchData: '',
        searchFiled: '',
        categoryId: '',
        fromCouponId: '',
        orgId: getBus().orgId,
        stockCondition: '',
        // expCondition: '',
        // isNotUseCoupon: '',
        // isCashTransaction: '',
        // isPresell: '',
        // isMedicareProduct: '',
        // isForbidCashTransaction: '',
        // isReturnable: '',
        // isJicai: '',
        otcType: '',
        isOnSale: 'Y',
        useAttribute: '0' //显示普通商品
      },
      isLoadingList: false,
      manufacture: [],
      selectArr: [],
      pageSize: 10,
      currentPage: 1,
      total: 100,
      tableData: [],
      secondArray: [],
      viewModel: 'ListBoard',
      searchs: [],
      sorts: [],
      saleDateType: ''
    }
  },
  watch: {
    $route: {
      handler(route) {
        this.searchForm.searchFiled = route.query.searchData
        if (route.query.categoryId) {
          this.searchForm.categoryId = route.query.categoryId
        }
        if (route.query.categoryCode) {
          this.searchForm.categoryCode = route.query.categoryCode
        } else {
          this.searchForm.categoryCode = ''
        }

        if (route.query.couponId) {
          this.searchForm.fromCouponId = route.query.couponId
        } else {
          this.searchForm.fromCouponId = ''
        }
        this.queryData()
      },
      immediate: true
    },
    '$store.state.user.refreshModule': {
      handler() {
        this.queryData()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['license', 'productCategory', 'userType'])
  },
  created() {
    this.getSortList()
  },
  mounted() {
    if (this.$route.query.categoryId) {
      this.searchForm.categoryId = this.$route.query.categoryId
      this.searchForm.categoryCode = this.$route.query.categoryCode
    }
  },
  methods: {
    //生产厂家
    getManufacture(data) {
      getManufacture(this.searchForm).then(res => {
        this.manufacture = res.data
      })
    },

    handleConfirm() {
      this.$router.push(`/member-center/personal-center/my-aptitudes`)
    },
    handlePrev(data) {
      this.currentPage = data
      this.queryData()
    },
    select(data) {
      this.selectArr = data
    },
    secondCategory(data) {
      this.secondArray = data
    },
    handlePagination(data) {
      this.currentPage = Math.min(this.currentPage, Math.ceil(this.total / data.pageSize))
      this.queryData()
    },
    handleChangViewModel(data) {
      this.viewModel = data.viewModel
    },
    queryData() {
      var allItem = {};
      this.tableData = []
      this.isLoadingList = true
      pageIndexProduct({
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        condition: this.searchForm
      }).then(res => {
        this.getManufacture(this.searchForm)
        const { data } = res
        if (data && data.records) {
          if (data.total > 0) {
            sensors.track("SearchResult", { "searchResult": 1 });
          } else {
            sensors.track("SearchResult", { "searchResult": 0 });
          }
          this.total = data.total
          data.records.forEach(item => {
            for (let key in item) {
              if (item[key] === null && key !== 'salePrice') {
                item[key] = '/'
              }
            }
            this.tableData.push({
              ...item,
              loading: false,
              count: this.getRealMinBuyNum(item)
            })
          })
        }
        this.isLoadingList = false
      }).catch(err => {
        this.isLoadingList = false
      })
    },
    async getSortList() {
      this.searchs = []
      await getConfigMap({ orgId: getBus().orgId }).then(res => {
        console.log('res', res.data.searchs)
        this.sorts = res.data.sorts
        res.data.searchs.map(item => {
          if (item === 'SALE_NUM_ONE_YEAR') {
            this.searchs.push({ name: '销量', code: 'SALE_NUM_ONE_YEAR' })
          } else if (item === 'GLOBAL_PRICE') {
            this.searchs.push({ name: '定价', code: 'GLOBAL_PRICE' })
          } else if (item === 'EXP_DAY_NEAR') {
            this.searchs.push({ name: '近效期', code: 'EXP_DAY_NEAR' })
          } else if (item === 'EXP_DAY_FAR') {
            this.searchs.push({ name: '远效期', code: 'EXP_DAY_FAR' })
          } else if (item === 'IN_ACTIVITY') {
            this.searchs.push({ name: '活动促销', code: 'IN_ACTIVITY' })
          }
        })
      })
    },
    secondFilter(params) {
      this.searchForm.searchList = []
      this.saleDateType = ''
      console.log('获取选择结果', params)
      if (params.selectionSearchs.includes('SALE_NUM_ONE_YEAR') && params.saleDateType) {
        this.saleDateType = params.saleDateType
        this.searchForm.searchList.push({ code: params.saleDateType })
      }
      if (params.selectionSearchs.length) {
        params.selectionSearchs.map(item => {
          if (item !== 'SALE_NUM_ONE_YEAR') {
            this.searchForm.searchList.push({ code: item })
          }
        })
      }
      this.queryData()
    },
    sortOder(column) {
      console.log('column', column)
      this.searchForm.sortList = []
      // console.log('获取的排序', column)
      if (column.prop === 'saleNumAllTime') {
        if (column.order === 'descending') {
          this.searchForm.sortList.push({ code: 'SALE_NUM_ALL_TIME', value: 'DESC' })
        } else {
          this.searchForm.sortList.push({ code: 'SALE_NUM_ALL_TIME', value: 'ASC' })
        }
      } else if (column.prop === 'saleNumOneYear') {
        if (column.order === 'descending') {
          this.searchForm.sortList.push({ code: 'SALE_NUM_ONE_YEAR', value: 'DESC' })
        } else {
          this.searchForm.sortList.push({ code: 'SALE_NUM_ONE_YEAR', value: 'ASC' })
        }
      } else if (column.prop === 'saleNumHalfYear') {
        if (column.order === 'descending') {
          this.searchForm.sortList.push({ code: 'SALE_NUM_HALF_YEAR', value: 'DESC' })
        } else {
          this.searchForm.sortList.push({ code: 'SALE_NUM_HALF_YEAR', value: 'ASC' })
        }
      } else if (column.prop === 'saleNumThreeMonth') {
        if (column.order === 'descending') {
          this.searchForm.sortList.push({ code: 'SALE_NUM_THREE_MONTH', value: 'DESC' })
        } else {
          this.searchForm.sortList.push({ code: 'SALE_NUM_THREE_MONTH', value: 'ASC' })
        }
      } else if (column.prop === 'saleNumOneMonth') {
        if (column.order === 'descending') {
          this.searchForm.sortList.push({ code: 'SALE_NUM_ONE_MONTH', value: 'DESC' })
        } else {
          this.searchForm.sortList.push({ code: 'SALE_NUM_ONE_MONTH', value: 'ASC' })
        }
      } else if (column.prop === 'stockQuantity') {
        if (column.order === 'descending') {
          this.searchForm.sortList.push({ code: 'STOCK_QUANTITY', value: 'DESC' })
        } else {
          this.searchForm.sortList.push({ code: 'STOCK_QUANTITY', value: 'ASC' })
        }
      } else if (column.prop === 'expDate') {
        if (column.order === 'descending') {
          this.searchForm.sortList.push({ code: 'EXP_DAY', value: 'DESC' })
        } else {
          this.searchForm.sortList.push({ code: 'EXP_DAY', value: 'ASC' })
        }
      } else if (column.prop === 'price') {
        if (column.order === 'descending') {
          this.searchForm.sortList.push({ code: 'GLOBAL_PRICE', value: 'DESC' })
        } else {
          this.searchForm.sortList.push({ code: 'GLOBAL_PRICE', value: 'ASC' })
        }
      } else if (column.prop === 'inActivity') {
        if (column.order === 'descending') {
          this.searchForm.sortList.push({ code: 'IN_ACTIVITY', value: 'DESC' })
        } else {
          this.searchForm.sortList.push({ code: 'IN_ACTIVITY', value: 'ASC' })
        }
      }
      this.queryData()
    }
  }
}
</script>

<style scoped lang="scss">
.childList {
  width: 250px;
  // height: 200px;
  //opacity: 0.8;
  border: 1px solid whitesmoke;
  background: #fff;
  margin: 0 auto;

  .childListson {
    //  display: inline-block;
    //  width: 200px;
    // color: #409eff;
    width: 50%;
    max-width: 250px;
    overflow: hidden;
    margin: 0 auto;

    font-size: 12px;
    font-weight: 500;
    color: #222222;
    margin: 0;
    padding: 0;
    display: block;
    padding: 0 20px 0 20px;
    float: left;

    &:hover {
      color: #409eff;
    }

    a {
      white-space: nowrap;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      // cursor: pointer;
      // color: #409eff;
    }
  }
}

.el-dropdown {
  span {

    // background: white;
    // border: 1px solid white;
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.aptitudes {
  line-height: 40px;
  font-size: 14px;
  color: #555;
  background-color: #FFF0EF;
  margin-bottom: 32px;
}

.commodity-list-container {
  width: 100%;
  height: auto;
  background: #ffffff;
  border-bottom: 20px solid #F5F5F5;
}

.commodity-list {
  min-width: 1200px;
  width: 94%;
  max-width: 1600px;
  height: auto;
  margin: auto;

  /*面包屑*/
  .breadcrumb-container {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;


  }

  .kind-paging {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: rgba(102, 102, 102, 1);
    padding: 10px 0;
    justify-content: flex-end;
    margin-right: 20px;

    span {
      color: #333333;
      margin: 0 20px 0 8px;
    }

    /*分页器*/
    /deep/ .el-pagination {
      padding: 0;
      height: 24px;

      .btn-prev,
      .btn-next {
        padding: 0 9px;
        border: 1px solid rgba(230, 230, 230, 1);
        margin: 0;
        background: #ffffff;

        &:disabled {
          background: #ffffff;
        }
      }
    }
  }

}
</style>

const MenuList = [
  {
    title: '',
    list: [
      {
        text: '会员中心首页',
        index: '1-1',
        url: '/member-center',
        meta: { roles: ['SALESMAN'] }
      },
      {
        text: '会员中心首页',
        index: '1-2',
        url: '/member-center/home',
        meta: { roles: ['BUYERS', 'BUYERS_TEAM'] }
      }
    ],
    meta: { roles: ['BUYERS', 'SALESMAN', 'BUYERS_TEAM'] }
  },
  {
    title: '订单中心',
    list: [
      {
        text: '商城订单',
        index: '2-1',
        url: '/member-center/order-manage/store-order',
        meta: { roles: ['BUYERS', 'SALESMAN', 'BUYERS_TEAM'] }
      },
      {
        text: ' 退货/退款',
        index: '2-2',
        url: '/member-center/order-manage/refunds',
        meta: { roles: ['BUYERS', 'BUYERS_TEAM'] }
      },
      {
        text: ' 退货/退款',
        index: '2-3',
        url: '/member-center/order-manage/refunds-yw',
        meta: { roles: ['SALESMAN'] }
      },
      {
        text: '飞速下单',
        index: '2-4',
        url: '/fast-order',
        meta: { roles: ['BUYERS', 'BUYERS_TEAM'] }
      },
      {
        text: '业务员下单',
        index: '2-5',
        url: '/fast-order',
        meta: { roles: ['SALESMAN'] }
      },
      {
        text: '历史采购',
        index: '2-6',
        url: '/member-center/order-manage/history-purchase',
        meta: { roles: ['BUYERS', 'BUYERS_TEAM'] }
      }
    ],
    meta: { roles: ['BUYERS', 'SALESMAN', 'BUYERS_TEAM'] }
  },
  {
    title: '客户服务',
    list: [
      {
        text: '我的客户',
        index: '3-1',
        url: '/member-center/customer-service/my-customer',
        meta: { roles: ['SALESMAN', 'BUYERS_TEAM'] }
      },
      // {
      //   text: '指标分析',
      //   index: '3-2',
      //   url: '/member-center/customer-service/analysis',
      //   meta: { roles: ['SALESMAN'] }
      // },
      // {
      //   text: '往来账单核对',
      //   index: '3-3',
      //   url: '/member-center/property-manage/intercourse-bill',
      //   meta: { roles: ['SALESMAN'] }
      // },
      // {
      //   text: '我要询价',
      //   index: '3-4',
      //   url: '/member-center/transactions-using/consult-price',
      //   meta: { roles: ['SALESMAN'] }
      // },
      // {
      //   text: '询价草稿箱',
      //   index: '3-5',
      //   url: '/member-center/transactions-using/consult-price-drafts',
      //   meta: { roles: ['SALESMAN'] }
      // },
      // {
      //   text: '商品对码表',
      //   index: '3-6',
      //   url: '/member-center/transactions-using/product-code-list',
      //   meta: { roles: ['SALESMAN'] }
      // },
      {
        text: '业务员报价单',
        index: '3-8',
        url: '/member-center/transactions-using/quotation-list',
        meta: { roles: ['SALESMAN'] }
      },
      {
        text: '审批流',
        index: '3-9',
        url: '/member-center/transactions-using/approval',
        meta: { roles: ['SALESMAN'] }
      },
      // {
      //   text: '我要拓客',
      //   index: '3-7',
      //   url: '/member-center/customer-service/expanding-customers/index',
      //   meta: { roles: ['SALESMAN'] }
      // }
    ],
    meta: { roles: ['SALESMAN'] }
  },
  {
    title: '资产管理',
    list: [
      {
        text: '我的优惠券',
        index: '4-1',
        url: '/member-center/property-manage/my-coupon',
        meta: { roles: ['BUYERS', 'SALESMAN', 'BUYERS_TEAM'] }
      },
      {
        text: '我的积分',
        index: '4-2',
        url: '/member-center/property-manage/my-integral',
        meta: { roles: ['BUYERS', 'BUYERS_TEAM'] }
        // url: '/member-center/order-manage/integral'
      },
      // {
      //   text: '往来账单核对',
      //   index: '4-3',
      //   url: '/member-center/property-manage/intercourse-bill',
      //   meta: { roles: ['BUYERS'] }
      // }
    ],
    meta: { roles: ['BUYERS', 'SALESMAN', 'BUYERS_TEAM'] }
  },
  {
    title: '交易运用',
    list: [
      {
        text: '我的业务员',
        index: '5-1',
        url: '/member-center/transactions-using/my-salesman',
        meta: { roles: ['BUYERS', 'BUYERS_TEAM'] }
      },
      {
        text: '商品收藏',
        index: '5-2',
        url: '/member-center/transactions-using/good-collection',
        meta: { roles: ['BUYERS', 'BUYERS_TEAM'] }
      },
      // {
      //   text: '采购计划',
      //   index: '5-3',
      //   url: '/member-center/transactions-using/procurement-plan',
      //   meta: { roles: ['BUYERS', 'BUYERS_TEAM'] }
      // },
      {
        text: '我要询价',
        index: '5-4',
        url: '/member-center/transactions-using/consult-price',
        meta: { roles: ['BUYERS', 'BUYERS_TEAM'] }
      },
      {
        text: '询价草稿箱',
        index: '5-5',
        url: '/member-center/transactions-using/consult-price-drafts',
        meta: { roles: ['BUYERS', 'BUYERS_TEAM'] }
      },
      // {
      //   text: '商品对码表',
      //   index: '5-6',
      //   url: '/member-center/transactions-using/product-code-list',
      //   meta: { roles: ['BUYERS'] }
      // }
    ],
    meta: { roles: ['BUYERS', 'BUYERS_TEAM'] }
  },
  {
    title: '委托配送',
    list: [
      {
        text: '委托配送订单',
        index: '5-1',
        url:'/member-center/consigned-delivery/interface-order/order',
        meta: { roles: ['SALESMAN'] }
      },
      {
        text: '委托配送缺货订单',
        index: '5-2',
        url:'/member-center/consigned-delivery/out-of-stock-order/index',
        meta: { roles: ['SALESMAN'] }
      },
      {
        text: '未匹配数据列表',
        index: '5-3',
        url:'/member-center/consigned-delivery/unmatched-datas/index',
        meta: { roles: ['SALESMAN'] }
      },
      {
        text: '订单执行情况',
        index: '5-4',
        url:'/member-center/consigned-delivery/data-execution/index',
        meta: { roles: ['SALESMAN'] }
      }
    ],
    meta: { roles: ['NONE'] }
  },
  {
    title: '设置',
    list: [
      {
        text: '企业信息',
        index: '6-1',
        url: '/member-center/personal-center/corporate-info',
        meta: { roles: ['BUYERS', 'BUYERS_TEAM'] }
      },
      {
        text: '账户管理',
        index: '6-2',
        url: '/member-center/personal-center/account-manage',
        meta: { roles: ['BUYERS', 'BUYERS_TEAM'] }
      },
      {
        text: '我的资质',
        index: '6-3',
        url: '/member-center/personal-center/my-aptitudes',
        meta: { roles: ['BUYERS', 'BUYERS_TEAM'] }
      },
      {
        text: '安全中心',
        index: '6-4',
        url: '/member-center/personal-center/security-center',
        meta: { roles: ['SALESMAN','BUYERS', 'BUYERS_TEAM'] }
      },
      {
        text: '账户管理',
        index: '6-5',
        url: '/member-center/personal-center/user-info',
        meta: { roles: ['SALESMAN'] }
      }
    ],
    meta: { roles: ['BUYERS', 'SALESMAN', 'BUYERS_TEAM'] }
  }
]

export default MenuList

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pagination-container" },
    [
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.page,
          "page-size": _vm.limit,
          "hide-on-single-page": _vm.isOnlyOnePageShow,
          "page-sizes": _vm.pageSizes,
          layout: "prev,pager,next,total,jumper",
          total: _vm.total,
        },
        on: {
          "update:currentPage": function ($event) {
            _vm.page = $event
          },
          "update:current-page": function ($event) {
            _vm.page = $event
          },
          "update:pageSize": function ($event) {
            _vm.limit = $event
          },
          "update:page-size": function ($event) {
            _vm.limit = $event
          },
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="express-steps">
    <el-steps direction="vertical" :active="1">
      <div v-for="(item,index) in detailList " :key='index'>
        <span>{{item.operationTimeDetail}}</span>
        <el-step :title="item.operationExplains" ></el-step>
      </div>
      <!-- <div>
        <span>2019-12-12  15:50:31</span>
        <el-step title="派送中" description="您的订单正在配送途中 快递员：陈圣刚，电话：18998308752，请您耐心等待。"></el-step>
      </div>
      <div>
        <span>2019-12-12  15:50:31</span>
        <el-step description="您的订单已到达广州市【广州凌塘营业部】"></el-step>
      </div>
      <div>
        <span>2019-12-12  15:50:31</span>
        <el-step description="离开【宁波市北厂区中心】，下一站【广州市凌塘营业部】"></el-step>
      </div>
      <div>
        <span>2019-12-12  15:50:31</span>
        <el-step description="【宁波市】物流公司已收取快件"></el-step>
      </div> -->
    </el-steps>
  </div>
</template>
<script>
  export default {
    name: 'OrderExpress',
    props: {
      activeNum:{
       type:String,
       defualt: '1'
      },
      detailList:{
        type:Array
      }
    },
    data () {
      return {
       
      }
    },
    methods: {
    }
  }
</script>
<style lang="scss" scoped>
  .express-steps {
    padding-top: 15px;
    display: flex;
    justify-content: left;
    /deep/ {
      .el-steps {
        & > div {
          display: flex;
          & > span {
            font-size: 14px;
            padding: 0 15px;
          }
          &:last-child {
            .el-step:last-of-type {
              .el-step__line {
                display: none;
              }
            }
          }
        }
        .el-step.is-vertical {
          flex: 1!important;
          .el-step__head {
            width: auto;
            .el-step__line {
              top: 20px;
              left: 4px;
            }
            .el-step__icon {
              width: 10px;
              height: 10px;

              &.is-text {
                border: 3px solid;
              }

              .el-step__icon-inner {
                display: none;
              }
            }
          }
          .el-step__main {
            padding-left: 15px;
            .el-step__title {
              font-size: 14px;
              line-height: 18px;
              &.is-process {
                font-weight: 400;
              }
              &.is-wait {
                padding-bottom: 3px;
              }
            }
          }
        }
        .el-step:last-of-type {
          .el-step__description {
            min-height: 40px;
            padding-bottom: 15px;
            font-size: 14px;
          }
          .el-step__line {
            display: block;
          }
        }
      }
    }
  }
</style>

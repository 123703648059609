import {ApiGet, ApiPost} from "@/api";

/**
 * @description 分页搜索品牌及对应的商品
 */
export const pageAreaActivityProduct = data => ApiPost('/pc/front/product/pageAreaActivityProduct', data)


/**
 * @description 获取后台品牌装修接口
 */
export const findPicByAreaActivityId = areaActivityId => ApiGet('/pc/front/product/findPicByAreaActivityId', {
    areaActivityId
})

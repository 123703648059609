import {ApiGet, ApiPost} from "@/api";

/**
 * @description 分页查询我的客户
 */
export const pageCustomer = data => ApiPost('/myClient/pageMyClient',data)

/*
* 导出客户
* */
export const exportClient = data => ApiPost('/myClient/exportMyClientExcel',data,{
  responseType: 'blob'
})

/**
 * @description 查询经营范围
 */
export const getBusinessRangeList = data => ApiGet('/businessRange/getBusinessRangeMap',data)

/**
 * @description 编辑客户信息 保存
 */
export const handleEditCustomer = data => ApiPost('/buyersSalesmanRel/updateBuyersSalesmanRel',data)

/** @description 更改客户状态
 *
*/
export const isEnableCustomer = data => ApiGet('/myClient/salesmanIsEnable',data)
<template>
  <im-page-modal
      class="approval-page"
      title="审批流"
      :isShowBack="false"
  >

    <div class="approval-list">
      <el-tabs v-model="activeName" @tab-click="handleTab">
        <el-tab-pane label="待处理" name="WAIT_APPROVE"></el-tab-pane>
        <el-tab-pane label="通过" name="APPROVE_SUCCESS"></el-tab-pane>
        <el-tab-pane label="驳回" name="APPROVE_FAILURE"></el-tab-pane>
        <el-tab-pane label="已完成" name="FINISH"></el-tab-pane>
      </el-tabs>
      <im-table-page
        ref="tablePage"
        :data.sync="tableData"
        :remote-method="queryData"
        row-key="id"
        style="width: 100%">
        <el-table-column label="名称" prop="buyersNm" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="订单编号" prop="orderNum" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="类型" width="115px" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>退单</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="nodeNewStatus" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.nodeNewStatus === 'WAIT_APPROVE'">待{{ scope.row.nodeNewStatusName }}审核</span>
            <span v-if="scope.row.nodeNewStatus === 'APPROVE_FAILURE'">驳回</span>
            <span v-if="scope.row.nodeNewStatus === 'FINISH' && scope.row.refundStat === 'REFUNDING'">通过（退款中）</span>
            <span v-if="scope.row.nodeNewStatus === 'FINISH' && scope.row.refundStat === 'FINISH'">通过</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime" show-overflow-tooltip>
        </el-table-column>
        <el-table-column fixed="right" align="center" width="200" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="handleSee(scope.row)">查看</el-button>
<!--            <el-button type="text" @click="deleteItem(scope.row)">删除</el-button>-->
          </template>
        </el-table-column>
      </im-table-page>
    </div>

    <!-- 审批弹窗-->

    <el-dialog title="退款" :visible.sync="dialogVisible" width="60%" :before-close="handleClose">
      <div class="process-list">
        <el-steps :active="flowIndex" class="flow-box">
          <el-step v-for="(item, index) in processList" :key="index" :class="{'pass':setShowClass(item.approveStatus), 'pass':setShowClass(item.approveStatus)}">
            <template slot="description">
              <el-card class="flow-card">
                <h4 class="unser-name">{{ item.nodeName.split('节点')[0] }}: {{ item.approverName }}</h4>
                <p class="remarks" v-if="item.approveStatus === 'APPROVE_SUCCESS'">同意 | {{ item.approveRemark!=''?item.approveRemark: '无' }}</p>
                <p class="remarks" v-else-if="item.approveStatus === 'APPROVE_FAILURE'">驳回 | {{ item.approveRemark!=''?item.approveRemark: '无' }}</p>
                <p class="remarks" v-else="item.approveRemark != ''">{{ item.approveRemark }}</p>
                <span class="flow-time">{{ item.approveTime != null ? item.approveTime: ""}}</span>
              </el-card>
            </template>
          </el-step>
        </el-steps>
      </div>
      <el-table class="goods-list" :data="goodsList" border style="margin-bottom: 10px;">
        <el-table-column property="productCode" label="商品编码"/>
        <el-table-column property="productNm" label="商品图片">
          <template slot-scope="scope">
            <el-image
              style="width: 60px; height: 60px"
              :src="scope.row.picture">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column property="drugNm" label="商品名称"/>
        <el-table-column property="manufacturer" label="数量">
          <template slot-scope="scope">
            <span>{{ scope.row.returnedQuantity != null ? scope.row.returnedQuantity : 1}}</span>
            <span>{{scope.row.unit}}</span>
          </template>
        </el-table-column>
        <el-table-column property="totalAmount" label="退款金额"/>
      </el-table>
      <div class="refund-amount">退款总金额：￥{{refundAmount}}</div>
      <div v-show="searchForm.approveStatus === 'WAIT_APPROVE'" class="approval-operate">
        <label for="approvalText">备注</label>
        <el-input
          id="approvalText"
          type="textarea"
          placeholder="请输入内容"
          v-model="approveRemark"
          maxlength="30"
          show-word-limit
        >
        </el-input>
      </div>
      <span v-show="searchForm.approveStatus === 'WAIT_APPROVE'" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit('APPROVE_SUCCESS')">通 过</el-button>
        <el-button type="danger" @click="submit('APPROVE_FAILURE')">驳 回</el-button>
      </span>
      <span v-show="searchForm.approveStatus !== 'WAIT_APPROVE'" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <div v-show="approveLoading" class="loading-mask" v-loading="approveLoading" element-loading-text="审批提交中"></div>
  </im-page-modal>
</template>
<script>

import {getRefundFlowOrder, queryRefundFlowNodeList, getRefundOrderDetail, approveRefundFlowNode} from "../../../../api/member-center/approval";
  import { getBus } from '@/utils/auth'

  export default {
    data () {
      return {
        searchForm: {
          orgId: getBus().orgId,
          approveStatus: 'WAIT_APPROVE',
          refundStat: '',
          searchFields: '',
          approverCode: sessionStorage.getItem('loginId')
        },
        activeName: 'WAIT_APPROVE',
        tableData: [],
        dialogVisible: false,
        processList: [], // 流程图
        flowIndex: 1,
        goodsList: [], // 商品信息
        refundOrderId: '',
        approveRemark: '',
        flowId: '',
        serialNumber: '',
        approveLoading: false,
        refundAmount: 0, // 总退款金额

      }
    },
    created() {

    },
    methods: {
      setShowClass(item) {
        if (item == 'APPROVE_SUCCESS' || item =='APPROVE_FAILURE') {
          return true
        } else {
          return false
        }
      },
      handleTab (tabs) {
        console.log(tabs)
        let name = tabs.name
        this.searchForm.approveStatus = name === 'FINISH' ? 'APPROVE_SUCCESS' : name
        this.searchForm.refundStat = name === 'FINISH' ? 'FINISH' : ''
        this.activeName = name
        this.tableData = []
        this.$nextTick(()=>{
          this.$refs.tablePage.doRefresh()
        })
      },
      // handleSee (value) {
      //   this.$router.push({name:'approvalDetail',params: {con: JSON.stringify(value)}})
      // },
      queryData(data) {
        return new Promise((then, reject) => {
          let params ={
            ...data,
            condition: this.searchForm
          }
          getRefundFlowOrder(params).then(res => {
            then(res)
          })
        })
      },
      handleClose(done) {
        this.$confirm('确认关闭审批？')
          .then(_ => {
            this.refundOrderId = ''
            this.flowId = ''
            done();
          })
          .catch(_ => {});
      },
      // 流程图
      handleSee(value) {
        this.dialogVisible = true
        this.refundOrderId = value.refundOrderId
        this.flowId = value.flowId
        this.serialNumber = value.serialNumber
        let params = {
          refundOrderId: value.refundOrderId,
          flowId: value.flowId,
          orgId: this.orgId
        }
        this.goodsList = []
        this.refundAmount = 0
        this.processList = []
        this.flowIndex = 1
        queryRefundFlowNodeList(params).then(res => {
          this.processList = res.data
          this.processList.forEach((item, index) => {
            if (item.approveStatus === '' || item.approveStatus === null) {
              return this.flowIndex = index
            } else if(item.approveStatus === 'APPROVE_FAILURE') {
              return this.flowIndex = index + 1
            } else if(item.approveStatus === 'WAIT_APPROVE') {
              return this.flowIndex = index
            }
            this.flowIndex = index + 1
          })
        })
        this.loadList(value.refundOrderId)
      },
      // 商品信息
      loadList(id) {
        let params = {
          refundOrderId: id
        }
        getRefundOrderDetail(params).then(res => {
          if (res.code == "0") {
            this.goodsList = res.data.refundOrderItemDetailVoList
            this.refundAmount = res.data.refundAmount
          }
        })
      },
      // 审批操作
      submit(approveStatus) {
        let _that = this
        let params = {
          approveStatus: approveStatus,
          approveTime: "",
          approverCode: this.searchForm.approverCode,
          approverName: "",
          buyersErpCode: "",
          flowId: this.flowId,
          isLastNode: "",
          nextNodeSort: "",
          nodeId: "",
          nodeName: "",
          orgId: this.orgId,
          refundFlowNodeId: "",
          refundOrderId: this.refundOrderId,
          remark: '',
          userType: "",
          approveRemark: this.approveRemark,
          serialNumber:this.serialNumber
        }
        if(this.approveRemark !== '') {
          if(approveStatus === 'APPROVE_FAILURE') {
            this.$confirm('确认要驳回审批？')
              .then(_ => {
                this.approveLoading = true
                approveRefundFlowNode(params).then(res => {
                  if (res.code == "0") {
                    this.$message.success('驳回已完成！')
                  }else {
                    this.$message.error(res.msg)
                  }
                }).catch((err) => {

                }).finally(() => {
                  this.dialogVisible = false
                  let timer = setTimeout(function () {
                    _that.$refs.tablePage.doRefresh()
                    _that.approveLoading = false
                    clearTimeout(timer)
                  }, 1000)
                })
              })
              .catch(_ => {
                this.$message.error('取消驳回！')
              });
          } else {
            this.approveLoading = true
            approveRefundFlowNode(params).then(res => {
              if (res.code == "0") {
                this.$message.success('已通过！')
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err) => {

            }).finally(() => {
              this.dialogVisible = false
              let timer = setTimeout(function () {
                _that.$refs.tablePage.doRefresh()
                _that.approveLoading = false
                clearTimeout(timer)
              }, 1000)
            })
          }
        } else {
          this.$message.error("请填写！")
        }
      },
    }
  }
</script>
<style lang="scss" scope>
  .approval-page{
    background: white;
    /deep/ {
      .el-tabs__nav-wrap::after {
        background-color: #fff;
      }

      .el-tabs__nav {
        margin: 0 20px;
        height: 40px;
        line-height: 50px;
        .el-tabs__item{
          line-height: 35px;
        }
      }
    }
    .approval-list {
      padding: 0 20px;
    }
    .im-pagination {
      z-index: 10;
    }
    //流程弹窗
    .process-list {
      overflow-y: hidden;
      overflow-x: auto;
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      .process-li {
        flex-shrink: 0;
        min-width: 140px;
        margin-right: 60px;
        font-size: 14px;
        line-height: 32px;

        .process-info {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          text-align: left;
          //width: 160rpx;
          padding: 0 20px;
          .arrow-right {
            position: absolute;
            top: 1rpx;
            right: -40px;
            font-size: 32px;
            color: #000;
          }

          .approval-text {
            display: flex;
            flex-wrap: nowrap;
            .text-name{
              white-space: nowrap;
            }
            .remarks {
              max-width: 120px;
              white-space: wrap;
            }
          }


          &.pass {
            color: rgb(41, 121, 255);

            .unser-name {
              color: rgb(41, 121, 255);
            }
            .arrow-right {
              color: rgb(41, 121, 255);
            }
          }
        }

        &:last-child {
          .process-info {
            .arrow-right {
              display: none;
            }
          }
        }
      }
    }

    .goods-list {
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      max-height: 240px;
      margin: 20px auto 0;
      border-bottom: 1px solid #D9D9D9;
      .goods-li {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        font-size: 14px;
        line-height: 18px;

        .order-id {
          width: 240px;
          padding: 0 20px;
        }

        .goods-name {
          overflow: hidden;
          width: 240px;
          padding: 0 20px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .goods-num {}

        .unit {
          padding-left: 40px;
        }
      }
    }
    .refund-amount {
      margin: 10px 0 20px;
      text-align: right;
    }
    .approval-operate {
      label {
        display: inline-block;
        width: 100%;
        margin: 0px 0 10px;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
      }
    }
    .el-dialog__footer {
      text-align: center;
    }
  }
  .flow-box {
    text-align: left;
    margin-bottom: 20px;
    .flow-card {
      width: 240px;
      margin: 20px 40px 0 0;
      .el-card__body {
        padding: 10px;
      }
      .unser-name {
        overflow: hidden;
        font-size: 14px;
        line-height: 24px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .flow-time {
        display: block;
        text-align: right;
      }
    }
    .pass {
      .unser-name {
        color: #3A6BED;
      }
    }
  }
  .loading-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
  }
</style>

<template>
  <div class="page-settlement-container">
    <div class="check-box">
      <div class="title">
        收货地址
      </div>
      <div class="check-radio">
        <el-radio-group v-if="shop.receiverAddrList.length" v-model="shop.buyersReceiverAddrId" @change="addressChange">
          <el-radio v-for="(item, index) in shop.receiverAddrList" :key="index" :label="item.buyersReceiverAddrId" v-show="index<checkShowNum">
            <span v-if="'缺省' == item.receiverName">{{ item.contactCustomerNm }}</span>
            <span v-else>{{ item.contactCustomerNm }}【{{ item.receiverName }}】</span>
            <span>{{ item.receiverMobile }}</span>
            <span>
              <span>{{ item.province }}</span>
              <span>{{ item.city }}</span>
              <span>{{ item.district }}</span>
              <span>{{ item.receiverAddr }}</span>
            </span>
          </el-radio>
        </el-radio-group>
        <div v-else style="padding-right: 40px;font-size: 14px;font-weight: 500;color: #e02020;">
          {{userType ==='SALESMAN'?'该客户未备案收货地址。':'您还未备案收货地址，请联系业务员。'}}
        </div>
        <template v-if="shop.receiverAddrList && shop.receiverAddrList.length > 1">
          <span v-if="isExpand" class="check-radio-span" @click="checkShow">
            收起更多地址 <i class="toggle-arrow el-icon-arrow-up"></i>
          </span>
          <span v-else class="check-radio-span check-radio-e" @click="checkShow">
            展开更多地址 <i class="toggle-arrow el-icon-arrow-down"></i>
          </span>
        </template>
      </div>
    </div>
    <div class="check-box">
      <div class="title">
        支付方式
      </div>
      <el-radio-group v-model="shop.paymentWayId" @change="paymentChange">
        <el-radio v-for="(item, index) in shop.paymentWayList" :key="item.paymentWayCode"
                  :label="item.paymentWayId">
          <span>{{ item.paymentWayName }}</span>
          <span v-if="item.paymentWayCode === 'CREDIT'">您的可用额度：¥{{ shop.availableCredit }}</span>
        </el-radio>
      </el-radio-group>
    </div>

    <div class="inventory">
      <h4>商品清单
        <el-button type="text" size="small" @click="goShop">返回购物车</el-button>
      </h4>
      <!--table列表-->
      <el-table
              ref="table"
              border
              :data="itemList"
              header-row-class-name="header-row"
              :class="!hidden && itemList.length > 4 ? 'hidden-more' : ''"
              style="width: 100%">
        <el-table-column type="index" align="center" label="序号" width="45"/>
        <el-table-column align="center" label="商品图" width="80px">
          <template slot-scope="scope">
            <img class="product-img" alt
                 :src="(scope.row.productPicMap && scope.row.productPicMap['48X48']) || productDefaultImg"
                 @error="scope.row.productPicMap['48X48'] = productDefaultImg">
          </template>
        </el-table-column>
        <el-table-column prop="productCode" label="商品编码" align="center" min-width="70px"/>
        <!--        <el-table-column prop="drugNm" label="通用名称" width="100px" show-overflow-tooltip />-->
        <el-table-column prop="productNm" label="商品名称" min-width="200px">
          <template slot-scope="scope">
            <div class="table-product-name">
              <p><b class="otc" v-if="scope.row.otcTypeNm && scope.row.otcTypeNm !== '其他'"
                    :class="{ 'jiaOtc': scope.row.otcTypeNm === '甲OTC', 'yiOtc': scope.row.otcTypeNm === '乙OTC', 'rxOtc': scope.row.otcTypeNm === 'RX' }">
                {{scope.row.otcTypeNm}}</b>{{$util.getGoodsName(scope.row)}}
              </p>
            </div>
            <ul class="table-row-tags">
              <li class="row-tag tag-coupon"
                  v-if="scope.row.shoppingCartItemType === 'ADDITIONAL'">超值换购
              </li>
              <li class="row-tag tag-coupon"
                  v-else-if="scope.row.shoppingCartItemType === 'SPECIAL_PRICE_PURCHASE'">特价换购
              </li>
              <li class="row-tag tag-coupon"
                  v-else-if="scope.row.shoppingCartItemType === 'PRESENT'">特殊商品
              </li>
              <li class="row-tag tag-coupon"
                  v-else-if="scope.row.tagList.findIndex(res => res.tag === '禁限销') !== -1">禁限销
              </li>
              <li class="row-tag tag-coupon"
                  v-else-if="scope.row.tagList.findIndex(res => res.tag === '近效期') !== -1">近效期
              </li>
            </ul>
          </template>
        </el-table-column>
        <el-table-column prop="spec" align="center" label="规格" width="90px"/>
        <el-table-column prop="manufacturer" align="left" label="厂家(上市许可持有人)/产地" show-overflow-tooltip width="170px"/>
        <el-table-column prop="unit" align="center" label="单位" show-overflow-tooltip width="50px"/>
        <el-table-column prop="rate" align="center" label="销项税" show-overflow-tooltip width="60px"/>
        <el-table-column prop="stock" align="center" label="库存" min-width="60px" v-if="userType === 'SALESMAN'"/>
        <el-table-column prop="stock" align="center" label="库存" min-width="60px" v-if="userType === 'BUYERS'">
          <template slot-scope="scope">
            <span v-if="scope.row.stock && scope.row.stock === '>100'">>100</span>
            <span class="inventory-warning" v-else><100</span>
          </template>
        </el-table-column>
<!--        <el-table-column prop="expDate" align="center" v-if="userType === 'BUYERS'" label="最近/远效期" width="200px" show-overflow-tooltip/>-->
        <el-table-column prop="minBuyMultiple" align="center" label="最小购买倍数" width="90px"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ getRealMinBuyNum(scope.row)}}
          </template>
        </el-table-column>
        <el-table-column prop="quantity" align="center" label="采购数量" width="80px"/>
        <el-table-column prop="originalUnitPrice" align="right" label="单价" min-width="100px">
          <template slot-scope="scope">
            <div v-if="scope.row.isGifts === 'Y' && scope.row.isMainProduct === 'N'">
              ¥{{ scope.row.originalUnitPrice }}<br/>
              <span style="text-decoration: line-through;color: #999">¥{{ scope.row.salePrice }}</span>
            </div>
            <span v-else>{{scope.row.originalUnitPrice}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="originalTotalPrice" align="right" label="小计" min-width="100px">
          <template slot-scope="scope">
            <div v-if="scope.row.isGifts === 'Y' && scope.row.isMainProduct === 'N'" style="color: #FF3B30">
              ¥{{ (scope.row.originalTotalPrice * 1).toFixed(2) }}<br/>
              <span style="text-decoration: line-through;color: #FF3B30">¥{{ scope.row.saleTotalPrice }}</span>
            </div>
            <span v-else style="color: #FF3B30">¥{{(scope.row.originalTotalPrice * 1).toFixed(2)}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="toggle-table" v-if="itemList.length > 4" @click="hidden = !hidden">
      <div class="fold" v-if="hidden">
        <span>收起</span>
        <i class="el-icon-arrow-up el-icon--right"></i>
      </div>
      <div class="fold" v-else>
        <span>展示更多{{ surplusItemNum }}件商品</span>
        <i class="el-icon-arrow-down el-icon--right"></i>
      </div>
    </div>

    <!--备注-->
    <div class="remark">
      <span>备注</span>
      <el-input v-model="remark" placeholder="请输入您的订单备注信息"></el-input>
    </div>

    <!--发票信息-->
    <div class="invoice-info">
      发票信息
      <span>{{ shop.invoiceTypeNm }}</span>
      <span>{{ shop.invoiceTitle }}</span>
      <el-button type="text" @click="openAgreement">查看详情</el-button>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="500px">
      <div slot="title" style="font-size: 14px">
        发票信息
      </div>
      <div class="dialog-content">
        <div class="invo-list">
          <div class="invo-lt">发票类型</div>
          <div class="invo-rt">{{ shop.invoiceTypeNm }}</div>
        </div>
        <div class="invo-list">
          <div class="invo-lt">发票抬头</div>
          <div class="invo-rt">{{ shop.invoiceTitle }}</div>
        </div>
        <div class="invo-list">
          <div class="invo-lt">税号</div>
          <div class="invo-rt">{{ shop.invoiceTfn }}</div>
        </div>
        <div>
          <div class="invo-list" v-if="shop.unitAddr">
            <div class="invo-lt">单位地址</div>
            <div class="invo-rt">{{ shop.unitAddr }}</div>
          </div>
          <div class="invo-list" v-if="shop.regTel">
            <div class="invo-lt">注册电话</div>
            <div class="invo-rt">{{ shop.regTel }}</div>
          </div>
          <div class="invo-list" v-if="shop.bankAccount">
            <div class="invo-lt">银行账号</div>
            <div class="invo-rt">{{ shop.bankAccount }}</div>
          </div>
          <div class="invo-list" v-if="shop.openBank">
            <div class="invo-lt">开户银行</div>
            <div class="invo-rt">{{ shop.openBank }}</div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button class="agree" size="mini" @click="dialogReturn">返回</el-button>
            </span>
    </el-dialog>
    <!--优惠券-->
    <div class="coupons">
      <el-collapse v-model="collapse" v-if="shop.couponList.length || shop.notCouponList.length">
        <el-collapse-item title="优惠券" name="1">
          <el-row :gutter="20">
            <el-col :span="6" v-for="(item,i) in shop.couponList" :key="i">
              <div class="coupon usable">
                <div class="coupon-left">
                  <p class="money" v-if="item.couponTypeNm === '折扣'"><span>{{ item.discount }}</span> 折</p>
                  <p class="money" v-else>¥<span>{{ item.discount }}</span></p>
                  <p class="condition">{{ item.discountDesc }}</p>
                </div>
                <div class="coupon-right">
                  <p class="name">{{ item.couponNm }}</p>
                  <p class="indate">有效期：{{ item.useEndTime }}</p>
                </div>
                <div class="selection-icon" :class="item.select ? 'selected' : 'unselected'"
                     @click="onCoupon(item.receiveRecordId)"></div>
              </div>
            </el-col>
          </el-row>
        </el-collapse-item>
        <el-collapse-item title="不可用优惠券" name="2">
          <el-row :gutter="20">
            <el-col :span="6" v-for="(item,i) in shop.notCouponList" :key="i">
              <div class="coupon usable grayed-out">
                <div class="coupon-left">
                  <p class="money" v-if="item.couponTypeNm === '折扣'"><span>{{ item.discount }}</span> 折</p>
                  <p class="money" v-else>¥<span>{{ item.discount }}</span></p>
<!--                  <p class="condition">{{ item.discountDesc }}</p>-->
                </div>
                <div class="coupon-right">
                  <p class="name">{{ item.couponNm }}</p>
                  <p class="indate">有效期：{{ item.useEndTime }}</p>
                  <p class="reason">
                    <el-popover placement="bottom" width="200" trigger="hover">
                      <p style="padding: 10px; font-size: 12px; line-height: 18px;">{{ item.discountDesc }}</p>
                      <i slot="reference" class="el-icon-info" style="margin-top: 4px; position: absolute"><span style="margin-left: 2px">不可用原因</span></i>
                    </el-popover>
                    <span style="margin-left: 16px"></span>
                  </p>
                </div>
                <el-button class="use-coupon-btn"  @click="handleUesCoupon(item.couponId)" >
                  <span>去<br>使<br>用</span>
                </el-button>
              </div>
            </el-col>
          </el-row>
        </el-collapse-item>
      </el-collapse>
      <p class="none" v-else>暂无可用优惠券</p>
    </div>

    <!--积分-->
    <div class="point" v-if="pointShow()">
      <div class="point-text">
        当前剩余积分{{ shop.userIntegralVo.availableIntegral }}，本单可以抵扣{{ shop.userIntegralVo.availableIntegralDiscountAmount }}元，是否使用
      </div>
      <div class="point-switch">
        <el-switch
          v-model="isPointCheck"
          active-color="#00D5D1"
          @change="switchChange">
        </el-switch>
      </div>
    </div>

    <!--结算信息-->
    <div class="settlement">
      <div class="settlement-info">
        <div class="settlement-info-row">
          <span>商品总金额：</span>
          <span class="money">¥{{ $util.toFixed(shop.productTotalAmount) }}</span>
        </div>
        <div class="settlement-info-row">
          <span>运费：</span>
          <span class="money">¥{{ shop.freightAmount }}</span>
        </div>
        <div class="settlement-info-row">
          <span>优惠金额：</span>
          <span class="money">¥{{ shop.discountAmount }}</span>
        </div>
        <div class="settlement-info-row">
          <span>积分抵扣：</span>
          <span class="money">¥{{ integralDiscountAmount }}</span>
        </div>
      </div>
      <div class="confirm-row">
        <div class="amount-payable">
          订单应付金额
          <p>¥{{ $util.toFixed(shop.originalTotalPrice) }}</p>
        </div>
        <el-button class="confirm-button" :class="{ 'disabled': isOverdue}" :loading="isSubmitLoading" :disabled="isSubmitLoading" @click="isNearEffective">确认提交</el-button>
      </div>
      <div class="consignee-info">
        收货人：{{ shop.receiverName }}
        <span class="phone">{{ shop.receiverMobile }}</span>
        <span class="address">寄送至：{{ shop.province }}{{ shop.city }}{{ shop.district }}{{ shop.receiverAddr }}</span>
      </div>
    </div>

    <!--效期提醒-->
    <el-dialog class="dialog-table dialog-table-effective" title="效期提醒" width="700px" :visible.sync="dialogTableVisible">
      <h4>
        <img class="icon" src="@/assets/img/customer-service/icon-shanchu.png" alt>
        以下商品效期小于一年，您是否确定下单？
      </h4>
      <el-table :data="isNear" border style="margin-bottom: 10px;">
        <el-table-column type="index" label="序号" width="55" fixed="left" align="center"/>
        <el-table-column property="productNm" label="商品名称">
          <template slot-scope="scope">
            {{$util.getGoodsName(scope.row)}}
          </template>
        </el-table-column>
        <el-table-column property="manufacturer" label="生产厂家" width="250"/>
        <el-table-column property="spec" label="规格" width="140"/>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleSubmitOrder">继续下单</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import productDefaultImg from '@/assets/img/index/product_default.png'
  import {
    getSettlement,
    submitOrder,
    updatePaymentWay,
    updateReceiverAddr,
    updateCoupon,
    getAllProductTypeTotalQuantity,
    useIntegral
  } from '@/api/shop-cart'
  import {mapGetters} from "vuex";
  import addToCartMixin from '@/common/addToCart'

  export default {
    name: 'CollateOrder',
    mixins:[addToCartMixin],
    props: {
      params: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        productDefaultImg,
        collapse: ['1', '2'],
        hidden: false,
        remark: '',//备注
        itemList: [],
        isSubmitLoading:false,
        shop: {
          paymentWayId: '',
          couponList: [],
          receiverAddrList:[]
        },
        isNear: [],
        surplusItemNum: 0,
        checkShowNum: 1,
        dialogVisible: false,
        dialogTableVisible: false,
        isExpand: false,
        isOverdue: false,
        isOverdueButton: false,
        isPointCheck: false,
        isUseIntegralDeduction: 'N', //是否展示积分开关
				availableIntegral: 0, //可用积分
        integralDiscountAmount: '0.00'//积分抵扣金额
      }
    },
    computed: {
      ...mapGetters(['userType'])
    },
    mounted () {
      getSettlement().then(res => {
        if(res.code == 0){
          this.handleData(res)
        }
      }).catch(err=>{
        //如果出错则跳转回到购物车
        this.goShop()
      })
      // 结算页刷新重新回到结算页
      window.addEventListener('beforeunload', this.handleListen)
    },
    destroyed () {
      window.removeEventListener('beforeunload', this.handleListen)
    },
    methods: {
      checkShow () {
        this.isExpand = !this.isExpand
        this.checkShowNum = this.isExpand ? 999 : 1
      },

      handleListen () {
        if (this.params.currentView === 'CollateOrder') {
          sessionStorage.setItem('collate', 'Y')
        }
      },
      goShop () {
        this.$emit('change-page', {
          params: {
            title: '购物车',
            currentView: 'ShoppingCartList',
            active: 0
          }
        })
      },
      handleData (res) {
        this.itemList = []
        this.isNear = []
        res.data.itemList.forEach(val => {
          let type = val.shoppingCartItemType
          if (type === 'NORMAL' || type === 'ADDITIONAL' || type === 'SPECIAL_PRICE_PURCHASE' || type === 'PRESENT') {
            if (val.isNearEffectivePeriod === 'Y') this.isNear.push(val)
            this.itemList.push(val)
          } else {
            val.itemDetailList.forEach(item => {
              if (val.isNearEffectivePeriod === 'Y') this.isNear.push(val)
              this.itemList.push(item)
            })
          }
        })
        this.shop = res.data
        this.isUseIntegralDeduction = res.data.userIntegralVo.isUseIntegralDeduction
				this.availableIntegral = res.data.userIntegralVo.availableIntegral
        this.isPointCheck = res.data.userIntegralVo.isUseIntegral == 'Y' ? true : false
        this.integralDiscountAmount = res.data.integralDiscountAmount
        // this.shop.receiverAddrList.unshift(...this.shop.receiverAddrList.splice(this.shop.receiverAddrList.findIndex(i => i === this.shop.buyersReceiverAddrId), 1))
        if(this.shop.receiverAddrList.length>0){
          for (var i = 0; i < this.shop.receiverAddrList.length; i++) {
            if (this.shop.receiverAddrList[i].buyersReceiverAddrId === this.shop.buyersReceiverAddrId) {
              this.shop.receiverAddrList.splice(this.shop.receiverAddrList[i], 0);
              break;
            }
          }
          this.shop.receiverAddrList.unshift(this.shop.receiverAddrList[i]);
          this.shop.receiverAddrList = [...new Set(this.shop.receiverAddrList)]
        }
        // console.log(this.shop.receiverAddrList)
        this.surplusItemNum = this.itemList.length - 4
        if (res.data.couponList.length) {
          res.data.couponList.map(val => {
            val.select = this.shop.receiveRecordId === val.receiveRecordId
          })
          this.shop.couponList = res.data.couponList
        }
      },
      onCoupon (receiveRecordId) {
        this.isOverdue = true
        updateCoupon({ receiveRecordId }).then(res => {
          this.$message.success('修改成功！')
          this.handleData(res)
          this.isOverdue = false

          getSettlement().then(res => {
            if(res.code == 0){
              this.handleData(res)
            }
          }).catch(err=>{
            //如果出错则跳转回到购物车
            this.goShop()
          })
        })

      },
      //收货地址修改
      addressChange (value) {
        this.isOverdue = true
        updateReceiverAddr(value).then(res => {
          this.handleData(res)
          this.isOverdue = false
        })
      },
      paymentChange (id) {
        this.isOverdue = true
        const params = this.shop.paymentWayList.find(v => v.paymentWayId === id)
        //判断是在线支付或者是授信支付 CREDIT:授信支付 CASH:在线支付，在线支付不需要id
        updatePaymentWay(params).then(res => {
          this.handleData(res)
          this.isOverdue = false
        }).catch(error => {
          this.isOverdue = true
        })
      },
      openAgreement () {
        this.dialogVisible = true
      },
      dialogReturn () {
        this.dialogVisible = false
      },
      isNearEffective () {
        if (!this.isNear.length) {
          this.handleSubmitOrder()
        } else {
          this.dialogTableVisible = true
        }
      },
      //积分
      pointShow(){
        if(this.isUseIntegralDeduction == 'Y' && this.availableIntegral > 0){
          return true
        }else{
          return false
        }
      },
      switchChange(e){
        // console.log('switchChange isPointCheck:', this.isPointCheck)
        let isUseIntegral = this.isPointCheck ? 'Y' : 'N'
        useIntegral(isUseIntegral).then(res => {
          this.integralDiscountAmount = res.data.integralDiscountAmount
          console.log('switchChange integralDiscountAmount:', this.integralDiscountAmount)

          getSettlement().then(res => {
            if(res.code == 0){
              this.handleData(res)
            }
          }).catch(err=>{
            //如果出错则跳转回到购物车
            this.goShop()
          })
        })

      },
      // 结算
      handleSubmitOrder () {
        if (!this.isOverdue) {
          this.isOverdue = true
          this.isOverdueButton = true
          this.isSubmitLoading = true
          submitOrder({ remark: this.remark, isUseIntegral: this.isPointCheck ? 'Y' : 'N' }).then(res => {
            this.$message.success(res.msg)
            getAllProductTypeTotalQuantity().then(res => {
              const { data } = res
              this.$store.dispatch('user/setCartNum', data)
              this.isOverdue = false
              this.isOverdueButton = false
            })
            this.$router.push({ path: '/cashier/' + res.data.orderId })
            this.isSubmitLoading = false
          }).catch(_ => {
            this.isOverdue = false
            this.isSubmitLoading = false
          })
        } else {
          if (this.isOverdueButton) {
            this.$message.error('请切换至可用的支付方式')
          }
        }
      },
      handleUesCoupon (id) {
        // this.$router.push({ path: '/' })
        this.$confirm('当前正处于购物车结算中，是否离开当前页面?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({ path: '/commodity-list', query: { couponId: id} })
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .page-settlement-container {
    border: 1px solid rgba(238, 238, 238, 1);
    box-sizing: content-box;
    border-radius: 8px;
    margin: 20px auto;
    width: 1200px;
    height: auto;
    background: #ffffff;

    /*地址&支付方式*/
    .check-box {
      width: 100%;
      height: auto;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid rgba(229, 229, 229, 1);;

      &:last-child {
        border-bottom: none;
      }

      .title {
        padding-right: 40px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(34, 34, 34, 1);
      }

      /deep/ .el-radio-group {
        flex: 1;
        text-align: left;
        padding-bottom: 5px;

        .el-radio {
          display: block;
          margin-bottom: 16px;

          .el-radio__label {
            font-size: 12px;
            font-weight: 500;
            color: rgba(102, 102, 102, 1);

            & > span {
              margin-right: 30px;

              & > span {
                margin-right: 10px;
              }
            }
          }

          &:last-child {
            margin-bottom: 0;
          }

          &.is-checked {
            .el-radio__label {
              color: rgba(34, 34, 34, 1);
            }
          }
        }
      }

      .check-radio {
        display: flex;
        flex-direction: column;
        align-items: baseline;

        .check-radio-span {
          font-size: 12px;
          margin-top: 10px;
          cursor: pointer;
        }

        .check-radio-e {
          margin: -5px 0;
        }
      }
    }

    /*商品清单*/
    .inventory {
      padding: 10px 20px 0 20px;

      .hidden-more {
        height: 333px;
      }

      h4 {
        font-size: 14px;
        font-weight: 500;
        color: rgba(34, 34, 34, 1);
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .jiaOtc {
        background: #E60012 !important;
      }

      .yiOtc {
        background: #33AE67 !important;
      }

      .rxOtc {
        background: #0091ff !important;
      }
    }

    /*折叠与展开*/
    .toggle-table {
      margin: 0 20px;
      padding: 5px 0;
      font-size: 12px;
      font-weight: 600;
      color: rgba(102, 102, 102, 1);
      border: 1px solid rgba(231, 231, 231, 1);
      border-top: none;
      cursor: pointer;

      icon {
        margin-left: 4px;
      }
    }

    /*备注*/
    .remark {
      padding: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid rgba(232, 232, 232, 1);

      span {
        margin-right: 20px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(34, 34, 34, 1)
      }

      .el-input {
        flex: 1;

        /deep/ .el-input__inner {
          height: 32px;
          line-height: 32px;
        }
      }
    }

    /*发票信息*/
    .invoice-info {
      padding: 15px 20px;
      display: flex;
      flex-direction: row;
      align-items: baseline;
      font-size: 14px;
      font-weight: 500;
      color: rgba(38, 38, 38, 1);
      border-bottom: 1px solid rgba(232, 232, 232, 1);

      span {
        margin-right: 20px;
        font-size: 12px;
        color: rgba(102, 102, 102, 1);

        &:first-child {
          margin-left: 28px;
        }
      }
    }

    /*优惠券*/
    .coupons {
      padding: 0px 20px;
      /*border-bottom: 1px solid rgba(232, 232, 232, 1);*/
      .coupon {
        width: 100%;
        height: 93px;
        margin: 10px 0;
        display: flex;
        flex-direction: row;
        position: relative;
        border-radius: 8px;
        box-shadow: 0 0 8px 0 rgba(0,0,0,.1);
        &.grayed-out {
          filter: grayscale(1);
          cursor: pointer;
        }
        .coupon-left {
          width: 127px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          font-weight: bold;

          .money {
            font-size: 16px;

            span {
              font-size: 36px;
            }
          }

          .condition {
            font-size: 12px;
            color: rgba(255, 255, 255, 1);
            position: relative;
            top: -10px;
          }
        }

        .coupon-right {
          position: relative;
          flex: 1;
          padding: 12px 10px;
          box-sizing: border-box;
          height: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;

          p {
            text-align: left;
          }
          .name {
            font-size: 14px;
            font-weight: 500;
            color: rgba(102, 102, 102, 1);
            line-height: 18px;
          }
          .indate {
            font-size: 12px;
            font-weight: 500;
            color: rgba(153, 153, 153, 1);
          }
        }
        &.grayed-out {
          .coupon-right {
            .name {
              font-size: 14px;
            }
            .indate {
              font-size: 10px;
            }
            .reason {
              font-size: 12px;
            }
          }
        }

        .use-coupon-btn {
          padding: 0;
          background: none;
          border: none;
          width: 18px;
          justify-content: center;
          display: flex;
          align-items: center;
          height: 100%;
          //position: absolute;
          //top: 0;
          //right: 0;
          font-size: 12px;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          line-height: 20px;
          background: #999;
        }

        .selection-icon {
          position: absolute;
          width: 20px;
          height: 20px;
          right: 4px;
          bottom: 4px;
          cursor: pointer;

          &.selected {
            background: url("../img/danxuan-s.png") no-repeat;
            background-size: 100% 100%;
          }

          &.unselected {
            background: url("../img/unselected.png") 100% 100% no-repeat;
            background-size: 100%;
          }
        }

        &.usable {
          background: url("../img/usable-conpon-bg.png") 100% 100% no-repeat;

        }

        &.unusable {
          background: url("../img/unusable-conpon-bg.png") 100% 100% no-repeat;

          .selection-icon {
            background: url("../img/unusable-select.png") 100% 100% no-repeat;
          }
        }
      }

      /deep/ .el-collapse {
        border: none;

        .el-collapse-item__header {
          border: none;
          font-size: 14px;
          font-weight: 500;
          color: rgba(43, 43, 43, 1);

          .el-collapse-item__arrow {
            margin: 0 8px 0 4px;
          }
        }

        .el-collapse-item__wrap {
          border-bottom: none;

          .el-collapse-item__content {
            padding-bottom: 0;
          }
        }
      }

      .none {
        font-size: 14px;
        line-height: 48px;
        text-align: left;
      }
    }

    .point{
      display: flex;
      align-items: center;
      padding: 40px 20px;
      .point-text{
			  font-size: 14px;
		  }
      .point-switch{
        margin-left: 40px;

		  }
    }

    .settlement {
      padding: 40px 20px;
      /*align-items: ;*/
      .settlement-info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        height: auto;
        padding-bottom: 17px;
        font-size: 12px;
        font-weight: 600;
        color: rgba(113, 113, 113, 1);

        .settlement-info-row {
          width: 210px;
          height: 17px;
          line-height: 17px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }

          .money {
            color: #222222;
          }
        }
      }

      .confirm-row {
        width: 100%;
        height: 48px;
        background: rgba(245, 245, 245, 1);
        border-radius: 2px;
        display: flex;
        flex-direction: row;
        justify-items: center;
        justify-content: flex-end;

        .amount-payable {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-weight: 500;
          color: rgba(125, 125, 125, 1);

          p {
            margin-top: 2px;
            font-size: 20px;
            font-weight: 500;
            color: rgba(224, 32, 32, 1);
          }
        }

        .confirm-button {
          margin-left: 20px;
          border: none;
          text-align: center;
          text-decoration: none;
          display: inline-block;
          font-size: 16px;
          padding: 0 38px;
          height: 100%;
          color: #ffffff;
          background: #E02020;
          border-radius: 0;
          &.disabled {
            background: #999;
          }
        }
      }

      /*收件人信息*/
      .consignee-info {
        margin-top: 18px;
        text-align: right;
        font-size: 12px;
        font-weight: 500;
        color: rgba(102, 102, 102, 1);

        .phone {
          margin-left: 13px;
        }

        .address {
          margin-left: 23px;
        }
      }
    }

    /deep/ .el-table--border {
      border: 1px solid #E5E5E5;
    }

    /deep/ .el-table__row {
      height: 60px;

      .cell {
        padding: 0 8px;
        font-size: 12px;
        font-weight: 500;
        color: rgba(38, 38, 38, 1);
      }

      .product-img {
        width: 50px;
        height: 50px;
      }

      /*重写计数器样式*/
      .el-input-number {
        width: 100px;
      }

      .el-input-number__decrease, .el-input-number__increase {
        width: 20px;
        height: 24px;

        /*right: 0;*/
        background: #ffffff;
        box-sizing: border-box;
        border-radius: 1px;
        border: 1px solid rgba(230, 230, 230, 1);
      }

      .el-input-number__decrease {
        left: 0;
      }

      .el-input-number__increase {
        right: 0;
      }

      .el-input__inner {
        padding: 0 19px;
        height: 24px;
        line-height: 24px;
      }

      .otc {
        margin-right: 6px;
        display: inline-block;
        color: #ffffff;
        height: 18px;
        line-height: 18px;
        font-size: 12px;
        background: rgba(255, 102, 0, 1);
        border-radius: 4px;
        padding: 0 2px;

        &:last-child {
          margin-right: 3px;
        }
      }

      .table-row-tags {
        display: flex;
        flex-direction: row;

        .row-tag {
          height: 18px;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          border-radius: 3px;
          border-width: 1px;
          border-style: solid;
          margin-right: 4px;
          padding: 0 4px;
          box-sizing: border-box;
        }

        .tag-promotion {
          background: #FF6600;
          border: none;
          color: #ffffff;
        }

        .tag-cash {

          border-color: rgba(2, 92, 214, 0.5);
          color: #025CD6;
        }

        .tag-coupon {
          border-color: rgba(255, 163, 102, 0.5);
          color: #FF6600;
        }

        .tag-plus {
          border-color: rgba(51, 174, 103, 0.5);
          color: #33AE67;
        }
      }

      /*商品名称*/
      .table-product-name {
        p {
          font-size: 14px;
          font-weight: 600;
          color: rgba(34, 34, 34, 1);
        }
      }

      /*库存*/
      .inventory-warning {
        font-weight: 600;
        color: rgba(255, 82, 0, 1);
      }

      /*操作栏*/
      .table-operation {
        display: flex;
        flex-direction: row;
        align-items: center;
        /*justify-content: space-between;*/

        span {
          margin-right: 4px;
          height: 20px;
          font-size: 12px;
          font-weight: 500;
          color: rgba(102, 102, 102, 1);
          line-height: 20px;
          padding: 0 4px;
          border-radius: 2px;
          border: 1px solid rgba(204, 204, 204, 1)

        }
      }

    }

    /deep/ .header-row {
      height: 40px;
      background: #E5E5E5;

      th {
        /*box-sizing: border-box;*/
        /*padding: 12px 0;*/
        .cell {
          padding: 0 8px;
          font-size: 12px;
          font-weight: 600;
          color: rgba(34, 34, 34, 1);
        }
      }
    }

    /deep/ .dialog-table-effective {
      .el-dialog__body {
        padding: 0 8px 20px;
      }

      h4 {
        background: #FEF4EB;
        margin: 8px 0;
        line-height: 40px;
        height: 40px;
        text-align: left;
        display: flex;
        align-items: center;
      }

      img {
        margin: 0 8px;
      }

      .el-button {
        line-height: 32px;
        height: 32px;
        padding: 0;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        border-radius: 2px;
      }
    }
  }
</style>

<style lang="scss">
  .page-settlement-container {
    .dialog-content {
      padding: 0 15px;

      .invo-list {
        display: flex;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 20px;

        .invo-lt {
          width: 66px;
          min-width: 66px;
          color: #666666;
          text-align: right;
        }

        .invo-rt {
          color: #222222;
          padding-left: 20px;
        }
      }
    }

    .el-dialog__footer {
      text-align: center;
      padding: 12px 0;
      background-color: #F5F5F5;
    }
  }
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "activity-decorate-content" },
    [
      _c("Search", { attrs: { "index-nav": true } }),
      _c("div", { staticClass: "brand-content" }, [
        _c("div", { staticClass: "brand-bg" }, [
          _c("img", { attrs: { src: _vm.topImg, alt: "" } }),
        ]),
        _c(
          "div",
          { staticClass: "brand" },
          [
            _c(
              "div",
              {
                staticClass:
                  "search-input el-input el-input--large el-input-group el-input-group--append",
                staticStyle: { width: "98%", "padding-block-end": "10px" },
                attrs: { "data-v-7b59048f": "" },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.queryParams.condition.searchFiled,
                      expression: "queryParams.condition.searchFiled",
                    },
                  ],
                  staticClass: "el-input__inner",
                  attrs: {
                    type: "text",
                    autocomplete: "off",
                    placeholder: "请输入商品名称",
                  },
                  domProps: { value: _vm.queryParams.condition.searchFiled },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.queryParams.condition,
                        "searchFiled",
                        $event.target.value
                      )
                    },
                  },
                }),
                _c("div", { staticClass: "el-input-group__append" }, [
                  _c(
                    "button",
                    {
                      staticClass: "el-button search-button el-button--default",
                      attrs: { "data-v-7b59048f": "", type: "button" },
                      on: { click: _vm.handleSearch },
                    },
                    [_c("i", { staticClass: "el-icon-search" })]
                  ),
                ]),
              ]
            ),
            _vm.tableData.length > 0
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.pageLoading,
                        expression: "pageLoading",
                      },
                    ],
                    staticClass: "goods-list",
                  },
                  _vm._l(_vm.tableData, function (product, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "commodity-item" },
                      [
                        _c(
                          "div",
                          { staticClass: "commodity-item-img" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: { path: "/detail/" + product.productId },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    alt: "",
                                    src:
                                      product.picUrl || _vm.productDefaultImg,
                                  },
                                  on: {
                                    error: function ($event) {
                                      product.picUrl = _vm.productDefaultImg
                                    },
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "commodity-item-info" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: { path: "/detail/" + product.productId },
                                },
                              },
                              [
                                _c(
                                  "p",
                                  { staticClass: "commodity-item-name" },
                                  [_vm._v(" " + _vm._s(product.drugNm) + " ")]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "commodity-item-detail" },
                              [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(product.spec) +
                                      "/" +
                                      _vm._s(product.unit)
                                  ),
                                ]),
                                _c("p", [_vm._v(_vm._s(product.manufacturer))]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "commodity-add-shopcart" },
                              [
                                _c("p", { staticClass: "commodity-price" }, [
                                  _vm._v("¥" + _vm._s(product.price)),
                                ]),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "code-button",
                                    attrs: { disabled: _vm.isBanAddToCart },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addItem(
                                          product.skuId,
                                          "NORMAL",
                                          _vm.quantity,
                                          product
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" 加入 "),
                                    _c("img", {
                                      attrs: {
                                        src: require("../../../assets/img/icon/icon-gouwuche.png"),
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _c("el-empty", { attrs: { description: "暂无数据" } }),
            _vm.total
              ? _c("im-pagination", {
                  attrs: {
                    total: _vm.total,
                    "hide-on-single-page": false,
                    "current-page": _vm.currentPage,
                    "page-size": _vm.queryParams.pageSize,
                    hideOnSinglePage: false,
                    isShowMainInfo: true,
                    pageSizes: _vm.pageSizesArr,
                  },
                  on: {
                    "update:currentPage": function ($event) {
                      _vm.currentPage = $event
                    },
                    "update:current-page": function ($event) {
                      _vm.currentPage = $event
                    },
                    "update:pageSize": function ($event) {
                      return _vm.$set(_vm.queryParams, "pageSize", $event)
                    },
                    "update:page-size": function ($event) {
                      return _vm.$set(_vm.queryParams, "pageSize", $event)
                    },
                    pagination: _vm.handlePagination,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info-channel-content" },
    [
      _c("Search", { attrs: { "index-nav": true } }),
      _vm._m(0),
      _c("div", { staticClass: "channel-nav" }, [
        _c(
          "div",
          { staticClass: "nav-odei" },
          _vm._l(_vm.navList, function (item, index) {
            return _c(
              "div",
              {
                staticClass: "nav-list",
                class: { active: index === _vm.navIndex },
                on: {
                  click: function ($event) {
                    return _vm.handleEdit(
                      item.videoGroupId,
                      index,
                      item.videoGroupNm
                    )
                  },
                },
              },
              [_vm._v(_vm._s(item.videoGroupNm))]
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "channel-content" },
        [
          _c("div", { staticClass: "channel-odes" }, [
            _c(
              "div",
              { staticClass: "channel-list" },
              _vm._l(_vm.contentList, function (item) {
                return _c("div", { staticClass: "channel-list" }, [
                  _c("div", { staticClass: "info-name" }, [
                    _vm._v(_vm._s(item.videoNm)),
                  ]),
                  _c("div", { staticClass: "info-introduce" }, [
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "video-img",
                          attrs: { plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.playVideo(item.vUrl, item.pUrl)
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "v-img",
                            attrs: { src: item.pUrl, alt: "" },
                          }),
                          _c("i", { staticClass: "el-icon-video-play" }),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "info-bot" }, [
                    _c("span", [
                      _vm._v("发布时间：" + _vm._s(item.updateTime)),
                    ]),
                  ]),
                ])
              }),
              0
            ),
          ]),
          _vm.contentList.length === 0
            ? _c("empty-box", { staticStyle: { "padding-bottom": "80px" } })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "channel-pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "hide-on-single-page": true,
                  "current-page": _vm.currentPage,
                  "page-size": 5,
                  layout: "total, prev, pager, next, jumper",
                  background: "",
                  total: _vm.total,
                },
                on: { "current-change": _vm.handleCurrentChange },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "视频预览", visible: _vm.dialogPlay, width: "55%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogPlay = $event
            },
            close: _vm.closeDialog,
          },
          model: {
            value: _vm.dialogPlay,
            callback: function ($$v) {
              _vm.dialogPlay = $$v
            },
            expression: "dialogPlay",
          },
        },
        [
          _c("div", { staticClass: "video-box" }, [
            _c(
              "video",
              {
                ref: "dialogVideo",
                staticClass: "video",
                attrs: {
                  src: _vm.videoUrl,
                  poster: _vm.videoPUrl,
                  controls: "",
                  autoplay: "",
                },
              },
              [
                _c("source", {
                  attrs: { type: 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"' },
                }),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "channel-banner" }, [
      _c("div", { staticClass: "banner-bg" }),
      _c("p", [_vm._v("视频资讯")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
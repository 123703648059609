<template>
<div>
  <div class="improveDiv">
    <div class="heard">账户类型：
      <span class="heardText">采购商</span>
      登录账号：
      <span class="heardText">{{params.registerLoginId}}</span>
      {{params.loginMobileId? '手机号码：': ''}}
      <span class="heardText">{{params.loginMobileId}}</span>
    </div>
    <el-form ref="form" :model="form" :rules="formRules" label-width="165px" class="from">
      <div class="enterpriseInfo">
        <div class="fromTitle">企业信息</div>
        <el-form-item label="客户名称" class="formItem" prop="customerName">
          <el-input v-model="form.customerName" placeholder="输入单位全称"></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码" class="formItem" prop="socialCreditCode">
          <el-input v-model="form.socialCreditCode" placeholder="输入统一社会信用代码"></el-input>
        </el-form-item>
        <el-form-item label="法人代表" class="formItem" prop="legalPersonMember">
          <el-input v-model="form.legalPersonMember" placeholder="输入法人代表"></el-input>
        </el-form-item>
        <el-form-item label="所在地区" class="formItem" prop="districtCode">
          <div class="chooseDiv">
            <el-select @change="provinceChange" v-model="form.provinceCode" placeholder="请选择" class="province">
              <el-option
                v-for="item in provinceList"
                :key="item.districtId"
                :label="item.name"
                :value="item.nodeCode+'_'+item.districtId">
              </el-option>
            </el-select>
            <el-select @change="cityChange" v-model="form.cityCode" placeholder="请选择" class="province">
              <el-option
                v-for="item in cityList"
                :key="item.districtId"
                :label="item.name"
                :value="item.nodeCode+'_'+item.districtId">
              </el-option>
            </el-select>
            <el-select @change="districtChange" v-model="form.districtCode" placeholder="请选择" style="width: 109px">
              <el-option
                v-for="item in districtList"
                :key="item.districtId"
                :label="item.name"
                :value="item.nodeCode+'_'+item.districtId">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="注册地址" class="formItem" prop="regAddr">
          <el-input v-model="form.regAddr" placeholder="输入本单位注册地址"></el-input>
        </el-form-item>
        <el-form-item label="经营地址" class="formItem" prop="businessAddress">
          <el-input v-model="form.businessAddress" placeholder="输入本单位经营地址"></el-input>
        </el-form-item>
        <el-form-item label="联系人" class="formItem" prop="chargePerson">
          <el-input v-model="form.chargePerson" placeholder="输入联系人"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" class="formItem" prop="chargePersonPhone">
          <el-input v-model="form.chargePersonPhone" placeholder="输入联系电话"></el-input>
        </el-form-item>
      </div> <!--      企业信息-->
      <div class="enterpriseInfo nextInfo">
        <div class="fromTitle">收货地址</div>
        <el-form-item label="随货联客户名称" class="formItem" prop="contactCustomerNm">
          <el-input v-model="form.contactCustomerNm" placeholder="输入随货联客户名称"></el-input>
        </el-form-item>
        <el-form-item label="所在地区" class="formItem" prop="addrDistrictCode">
          <div class="chooseDiv">
            <el-select @change="addrProvinceChange" v-model="form.addrProvinceCode" placeholder="请选择" class="province">
              <el-option
                v-for="item in provinceList"
                :key="item.districtId"
                :label="item.name"
                :value="item.nodeCode+'_'+item.districtId">
              </el-option>
            </el-select>
            <el-select @change="addrCityChange" v-model="form.addrCityCode" placeholder="请选择" class="province">
              <el-option
                v-for="item in addrCityList"
                :key="item.districtId"
                :label="item.name"
                :value="item.nodeCode+'_'+item.districtId">
              </el-option>
            </el-select>
            <el-select @change="addrDistrictChange" v-model="form.addrDistrictCode" placeholder="请选择" style="width: 109px">
              <el-option
                v-for="item in addrDistrictList"
                :key="item.districtId"
                :label="item.name"
                :value="item.nodeCode+'_'+item.districtId">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="收货地址" class="formItem" prop="detailAddr">
          <el-input v-model="form.detailAddr" placeholder="输入收货地址"></el-input>
        </el-form-item>
        <el-form-item label="收货联系人" class="formItem" prop="receiverName">
          <el-input v-model="form.receiverName" placeholder="输入收货联系人"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" class="formItem" prop="receiverMobile">
          <el-input v-model="form.receiverMobile" placeholder="输入联系电话"></el-input>
        </el-form-item>
      </div> <!--      收货地址-->
      <div class="enterpriseInfo nextInfo">
        <div class="fromTitle">发票信息</div>
        <el-form-item label="发票类型" class="formItem" prop="invoiceType">
          <el-radio v-model="form.invoiceType" label="COMMERCIAL_INVOICE">增值税普通发票</el-radio>
          <el-radio v-model="form.invoiceType" label="VAT_INVOICE">增值税专业发票</el-radio>
        </el-form-item>
        <el-form-item label="客户发票名称" class="formItem" prop="invoiceTitle">
          <el-input v-model="form.invoiceTitle" placeholder="输入客户发票名称"></el-input>
        </el-form-item>
        <el-form-item label="税号" class="formItem" prop="invoiceTfn">
          <el-input v-model="form.invoiceTfn" placeholder="输入税号"></el-input>
        </el-form-item>
        <el-form-item label="地址" class="formItem" prop="unitAddr" v-if="form.invoiceType !== 'COMMERCIAL_INVOICE'">
          <el-input v-model="form.unitAddr" placeholder="输入地址"></el-input>
        </el-form-item>
        <el-form-item label="电话" class="formItem" prop="regTel" v-if="form.invoiceType !== 'COMMERCIAL_INVOICE'">
          <el-input v-model="form.regTel" placeholder="输入电话"></el-input>
        </el-form-item>
        <el-form-item label="开户行" class="formItem" prop="openBank" v-if="form.invoiceType !== 'COMMERCIAL_INVOICE'">
          <el-input v-model="form.openBank" placeholder="输入开户行"></el-input>
        </el-form-item>
        <el-form-item label="账号" class="formItem" prop="bankAccount" v-if="form.invoiceType !== 'COMMERCIAL_INVOICE'">
          <el-input v-model="form.bankAccount" placeholder="输入账号"></el-input>
        </el-form-item>
        <el-form-item label="纳税人证明照" class="formItem" prop="taxpayerCertificationId">
          <div style="width: 400px;height: 80px"
             v-loading="loading">
            <el-upload
              action="#"
              accept=".jpg,.jpeg,.bmp,.gif, .png"
              ref="upload"
              list-type="picture-card"
              :auto-upload="true"
              :show-file-list="hideUploadProve"
              :file-list="fileList"
              :class="{hide:hideUploadProve, show:!hideUploadProve}"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemoveProve"
              :before-upload="beforeUpload"
              :http-request="() => Upload(100)">
              <div slot="trigger" class="upText">上传文件</div>
            </el-upload>
<!--            <img v-if="hideUploadProve" :src="form.taxpayerCertificationUrl" class="avatar">-->
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </div>

        </el-form-item>
      </div> <!--      发票信息-->
      <div class="enterpriseInfo nextInfo">
        <div class="fromTitle">委托人信息</div>
        <el-form-item label="姓名" class="formItem" prop="clientName">
          <el-input v-model="form.clientName" placeholder="输入委托人姓名"></el-input>
        </el-form-item>
        <el-form-item label="身份证号" class="formItem" prop="clientIdNum">
          <el-input v-model="form.clientIdNum" placeholder="输入身份证号"></el-input>
        </el-form-item>
        <el-form-item label="身份证有效期截止日期" class="formItem idCardDate" prop="idCardNum">
          <el-date-picker
            v-show="isShow"
            style="width: 275px;height: 36px;margin-right: 15px;"
            v-model="form.idCardNum"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="date"
            :picker-options="pickerOptions"
            @input="idDateChange"
            placeholder="选择日期"
            :disabled="langTrue">
          </el-date-picker>
          <el-checkbox v-model="langTrue" @change="langChange">长期有效</el-checkbox>
        </el-form-item>
      </div> <!--      委托人信息-->
      <div class="enterpriseInfo nextInfo">
        <div class="fromTitle">客户资质</div>
        <el-form-item label="客户类型" class="formItem" prop="customType">
          <el-select v-model="form.customType" style="width: 361px;height: 36px" placeholder="请选择" @change="customTypeChange">
            <el-option
              v-for="item in customTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <div v-for="(item, index) in form.qualificationList">
          <el-form-item label="是否对公支付" class="formItem publicPayItem" prop="publicPay" v-if="index === form.qualificationList.length - 3">
            <el-radio v-model="form.publicPay" label="true">是</el-radio>
            <el-radio v-model="form.publicPay" label="false">否</el-radio>
          </el-form-item>
          <div class="customerDiv"  :class="index != 0 && index != form.qualificationList.length - 3? 'nextInfo': ''" v-if="index < form.qualificationList.length - 3 || form.publicPay === 'false'">
          <div class="customerTitle">
            <div class="titleIcon"></div>
            {{item.licenseNm}}
            <span class="titleTip">{{item.tip}}</span>
            <a class="downTemplate" v-if="item.downUrl" :href="item.downUrl">下载模板</a>
          </div>
          <div class="customerInput">
            <el-form-item label="证件编码" class="formCustomItem" label-width="80px"
            v-if="item.isCertNum != '3'"
            :prop="'qualificationList.' + index + '.businessScope'"
            :rules="[{required: item.isCertNum === '1', trigger: 'blur', message: '证件编码不能为空' }]">
              <el-input v-model="item.businessScope" placeholder="输入证件编码"></el-input>
            </el-form-item>
            <el-form-item label="有效期" class="formCustomItem idCardDate" label-width="80px"
              v-if="item.isAllTime != '3' || item.isValidityTime !=3" style="width: 450px" v-show="isShow"
              :prop="'qualificationList.' + index + '.validityText'"
              :rules="[{required: item.isAllTime === '1', trigger: 'change', message: '有效期不能为空'}]">
              <el-date-picker
                style="width: 250px;height: 36px"
                v-model="item.rangeDate"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @input="rangeDateChange(item.rangeDate,index)"
                :disabled="item.forever">
              </el-date-picker>
              <el-checkbox v-if="item.isAllTime != '3'" style="margin-left:15px" v-model="item.forever" @change="rangeDateChange(item.forever,index)">长期有效</el-checkbox>
            </el-form-item>
            <el-form-item label="发证日期" class="formCustomItem" label-width="80px"
              v-if="item.isStartDate != '3'"
              :prop="'qualificationList.' + index + '.issueDate'"
              :rules="[{required: item.isStartDate === '1', trigger: 'blur', message: '发证日期不能为空' }]">
              <el-date-picker
                style="width: 250px;height: 36px"
                v-model="item.issueDate"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </div>
          <el-form-item label=" " class="formCustomItem" label-width="80px"
            v-if="item.isPic != '3'"
            :prop="'qualificationList.' + index + '.fileId'"
            :rules="[{required: item.isPic === '1', trigger: 'blur', message: '证件照片不能为空' }]">
            <div style="width: 280px;height: 80px"
               v-loading="loadingList[index]">
              <el-upload
                action="#"
                accept=".jpg,.jpeg,.bmp,.gif, .png"
                list-type="picture-card"
                :auto-upload="true"
                :show-file-list="hideUpload[index]"
                :file-list="qualityFileList[index]"
                :class="{customHide:hideUpload[index], customShow:!hideUpload[index]}"
                :on-preview="handlePictureCardPreview"
                :on-remove="(file) => handleRemove(file, index)"
                :before-upload="beforeUpload"
                :http-request="() => Upload(index)">
                <div slot="trigger" class="upText">上传文件</div>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </div>
          </el-form-item>
          <div class="customerTip">请上传清晰彩色原件扫描或数码照，支持jpg、jpeg、bmp、gif、png格式照片，大小不超过2m</div>
        </div>
        </div>
      </div> <!--      客户资质-->
    </el-form>
  </div>
  <div class="buttonDiv">
    <el-button @click="nextBut" class="sureBut" type="primary">提交</el-button>
  </div>
</div>
</template>

<script>
import {
  updateBuyersAudit,
  getLicenses,
  batchRegisterUploadFile,
  getDistrictData,
  register,
  findRegisterBuyersInfoByLoginUser
} from '@/api/register/index'
import formRules from '@/utils/validate'
export default {
  name: "improveInfo",
  components: {
  },
  props: {
    params: {
      type: Object,
      default: {}
    },
  },
  data() {
    const validateDistrictId = (rule, value, callback) => {
      if (this.next && !value) {
        this.next = false
        return callback(new Error('所在地区不能为空'))
      }
      callback()
    }
    const validateIdNum = (rule, value, callback) => {
      if (!this.langTrue && !value) {
        return callback(new Error('身份有效期截止日期不能为空'))
      }
      callback()
    }
    return {
      form: {
        chargePerson:'',
        chargePersonPhone:'',
        customerName: '',
        socialCreditCode: '',
        legalPersonMember: '',
        regAddr: '',
        businessAddress: '',
        contactCustomerNm: '',
        receiverName: '',
        receiverMobile: '',
        invoiceType: '',
        invoiceTitle: '',
        invoiceTfn: '',
        unitAddr: '',
        regTel: '',
        openBank: '',
        bankAccount: '',
        clientName: '',
        clientIdNum: '',
        idCardNum: '',
        taxpayerCertificationId: '',
        provinceCode: '',
        cityCode: '',
        districtCode: '',
        addrProvinceCode: '',
        addrCityId: '',
        addrDistrictId: '',
        detailAddr: '',
        customType: '',
        qualificationList: [],
        publicPay: 'true'
      },
      isShow: true,
      formRules: {
        chargePerson:[{ required: true, trigger: 'blur', message: '公司名称不能为空' }],
        chargePersonPhone:[formRules.phone],
        customerName: [{ required: true, trigger: 'blur', message: '客户名称不能为空' }],
        socialCreditCode: [{ required: true, trigger: 'blur', message: '统一社会信用不能为空' }],
        legalPersonMember: [{ required: true, trigger: 'blur', message: '法人代表不能为空' }],
        regAddr: [{ required: true, trigger: 'blur', message: '注册地址不能为空' }],
        businessAddress: [{ required: true, trigger: 'blur', message: '经营地址不能为空' }],
        contactCustomerNm: [{ required: true, trigger: 'blur', message: '随货联客户名称不能为空' }],
        receiverName: [{ required: true, trigger: 'blur', message: '收货联系人不能为空' }],
        receiverMobile:  [formRules.phone],
        invoiceType: [{ required: true, trigger: 'blur', message: '发票类型不能为空' }],
        invoiceTitle: [{ required: true, trigger: 'blur', message: '发票名称不能为空' }],
        invoiceTfn: [{ required: true, trigger: 'blur', message: '税号不能为空' }],
        unitAddr: [{ required: true, trigger: 'blur', message: '地址不能为空' }],
        regTel:  [formRules.phone],
        openBank: [{ required: true, trigger: 'blur', message: '开户行不能为空' }],
        bankAccount: [{ required: true, trigger: 'blur', message: '账号不能为空' }],
        clientName: [{ required: true, trigger: 'blur', message: '委托人姓名不能为空' }],
        clientIdNum: [formRules.idCard],
        idCardNum: [{ required: true, trigger: 'change', validator: validateIdNum}],
        taxpayerCertificationId: [{ required: true, trigger: ['blur','change'], message: '纳税人证明照不能为空' }],
        districtCode: [{ required: true, trigger: 'blur', validator: validateDistrictId }],
        addrDistrictCode: [{ required: true, trigger: 'blur', validator: validateDistrictId }],
        detailAddr: [{ required: true, trigger: 'blur', message: '收货地址不能为空' }],
        customType: [{ required: true, trigger: 'blur', message: '客户类型不能为空' }]
      },
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      hideUploadProve: false,
      loading: false,
      loadingList:[],
      hideUpload: [],
      customTypeList: [{
        label: '商业公司/零售药店',
        value: 'RETAIL_PHARMACY'
        },{
        label: '医疗机构',
        value: 'MEDICAL_INSTITUTION'
      }],
      newFile: new FormData(),
      provinceList: [],
      cityList: [],
      districtList: [],
      addrCityList: [],
      addrDistrictList: [],
      next: false,
      langTrue: false,
      fileList: [],
      qualityFileList: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        }
      }
    }
  },
  methods: {
    customTypeChange(value, change) {
      this.form.registerBuyersType = value
      getLicenses(value).then(res => {
        let { data } = res
        this.form.qualificationList = []
        this.hideUpload = []
        this.qualityFileList = []
        data.forEach(item => {
          item.licenseNm = item.licenseNm.replace("《","")
          item.licenseNm = item.licenseNm.replace("》","")
          // 1--必传 2--非必传 3--不显示
          switch (item.licenseNm) {
            case '营业执照':
              if(this.form.customType === 'RETAIL_PHARMACY'){
                item.isPic = '1'
                item.isAllTime = '1'
                item.isValidityTime = '1'
              }else {
                item.isPic = '2'
                item.isAllTime = '2'
                item.isValidityTime = '2'
              }
              item.isCertNum = '2'
              item.isStartDate = '2'
              break
            case '上年度企业年度报告公示情况':
              item.isPic = '2'
              item.isCertNum = '3'
              item.isStartDate = '3'
              item.isAllTime = '3'
              item.isValidityTime = '3'
              break
            case '药品经营许可证':
              item.isPic = '1'
              item.isCertNum = '2'
              item.isStartDate = '2'
              item.isAllTime = '3'
              item.isValidityTime = '2'
              break
            case '采购人员法人委托书及身份证复印件':
              item.isPic = '1'
              item.isCertNum = '3'
              item.isStartDate = '3'
              item.isAllTime = '3'
              item.isValidityTime = '2'
              item.tip = '授权书需要法人签字和盖章'
              break
            case '客户质量体系调查表':
              item.isPic = '1'
              item.isCertNum = '3'
              item.isStartDate = '3'
              item.isAllTime = '3'
              item.isValidityTime = '3'
              break
            case '收货签收样章备案':
              item.isPic = '2'
              item.isCertNum = '3'
              item.isStartDate = '3'
              item.isAllTime = '3'
              item.isValidityTime = '3'
              item.tip = '若采购含特殊药品复方制剂，必须传'
              break
            case '医疗器械经营许可证':
              item.isPic = '2'
              item.isCertNum = '2'
              item.isStartDate = '2'
              item.isAllTime = '3'
              item.isValidityTime = '2'
              item.tip = '若采购医疗器械'
              break
            case '食品经营许可证':
              item.isPic = '2'
              item.isCertNum = '2'
              item.isStartDate = '2'
              item.isAllTime = '3'
              item.isValidityTime = '2'
              item.tip = '若采购食品需提供'
              break
            case '现金购药法人委托书':
              item.isPic = '1'
              item.isCertNum = '3'
              item.isStartDate = '3'
              item.isAllTime = '3'
              item.isValidityTime = '3'
              break
            case '常规现金交易客户审批表':
              item.isPic = '1'
              item.isCertNum = '3'
              item.isStartDate = '3'
              item.isAllTime = '3'
              item.isValidityTime = '3'
              break
            case '非对公账户现金备案表':
              item.isPic = '1'
              item.isCertNum = '3'
              item.isStartDate = '3'
              item.isAllTime = '3'
              item.isValidityTime = '3'
              item.tip = '采购含特殊药品复方制剂，法人或负责人银行卡/支付宝实名账户'
              break
            case '医疗机构执业许可证':
              item.isPic = '1'
              item.isCertNum = '2'
              item.isStartDate = '2'
              item.isAllTime = '3'
              item.isValidityTime = '2'
              break
            case '第二类医疗器械经营备案凭证':
              item.isPic = '2'
              item.isCertNum = '2'
              item.isStartDate = '2'
              item.isAllTime = '3'
              item.isValidityTime = '3'
              item.tip = '若采购医疗器械'
              break
            case '母婴保健技术服务执业许可证':
              item.isPic = '2'
              item.isCertNum = '2'
              item.isStartDate = '2'
              item.isAllTime = '3'
              item.isValidityTime = '2'
              item.tip = '若采购终止妊娠药品'
              break
          }
          item.businessScope =''
          item.validityText =''
          item.validityDate =''
          item.issueDate = ''
          item.fileId = ''
          item.downUrl = item.downloadTemplatePath
          this.hideUpload.push(false)
          this.qualityFileList.push([])
        })
        let bottomList = []
        let dataList = data.filter(item => {
          if(item.licenseNm === '现金购药法人委托书' || item.licenseNm === '常规现金交易客户审批表' || item.licenseNm === '非对公账户现金备案表'){
            bottomList.push(item)
          }
          return item.licenseNm !== '现金购药法人委托书' && item.licenseNm !== '常规现金交易客户审批表' && item.licenseNm !== '非对公账户现金备案表'
        });
        dataList = dataList.concat(bottomList)
        this.form.qualificationList = dataList
        if(change){
          this.form.qualificationList.forEach((item,index) => {
            change.forEach(changeItem => {
              if(item.licenseNo === changeItem.license.licenseNo){
                item.businessScope = changeItem.businessScope
                item.registerBuyersLicenseRelId = changeItem.registerBuyersLicenseRelId
                item.rangeDate = []
                if(changeItem.validityDate){
                  item.rangeDate = [changeItem.validityDate,changeItem.endDate]
                  item.validityText = '有值'
                }
                item.forever = false
                if(changeItem.isForever === 'Y'){
                  item.forever = true
                  item.validityText = '有值'
                }
                item.issueDate = changeItem.issueDate
                if(changeItem.fileUrl){
                  this.qualityFileList[index] = [{name: 'food.jpeg', url: changeItem.fileUrl}]
                  this.form.qualificationList[index].fileId = changeItem.fileId
                  this.form.qualificationList[index].downloadTemplatePath = changeItem.fileUrl
                  this.hideUpload[index] = true
                }
              }
              if(changeItem.license.licenseNm === '《现金购药法人委托书》'){
                this.form.publicPay = 'false'
              }
            })
          })
          // console.log(this.form.qualificationList)
        }
      })
    },
    handleRemove(file, index) {
      this.hideUpload[index] = false
      this.$forceUpdate()
    },
    handleRemoveProve(file, index) {
      this.hideUploadProve = false
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforeUpload(file) {
      const testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'png'
      const extension2 = testmsg === 'jpg'
      const extension3 = testmsg === 'gif'
      const isLt2M = file.size / 1024 / 1024 < 30
      if (!extension && !extension2 && !extension3) {
        this.$message({
          message: '上传文件只能是 png、jpg、gif格式!',
          type: 'warning'
        })
        return false
      }else if(!isLt2M) {
        this.$message({
          message: '上传文件大小不能超过 30MB!',
          type: 'warning'
        });
        return false
      }else{
      this.newFile = new FormData();
      this.newFile.append("files", file);
      }
    },
    Upload(index) {
      if(index === 100){
        this.loading = true
      }else{
        this.$set(this.loadingList,index,true)
      }
      batchRegisterUploadFile(this.newFile).then(res => {
        this.loadingList[index] = false
        this.loading = false
        if(res.code === '0'){
          if(index === 100) {
            if(res.data && res.data.fileId){
              this.form.taxpayerCertificationId = res.data.fileId
              this.hideUploadProve = true
            }else{
              this.fileList = []
            }
          } else {
            if(res.data && res.data.fileId){
              this.form.qualificationList[index].fileId = res.data.fileId
              this.form.qualificationList[index].downloadTemplatePath = res.data.fileUrl
              this.$set(this.hideUpload,index,true)
            } else {
              this.qualityFileList[index] = []
            }
          }
        } else if(index === 100){
          this.fileList = []
        }else{
          this.qualityFileList[index] = []
        }
      }).catch(error => {
        this.loading = false
        this.loadingList[index] = false
        if(index === 100){
          this.fileList = []
        }else{
          this.qualityFileList[index] = []
        }
      })
    },
    provinceChange(value) {
      let params = {
        parentCode: value.split('_')[0]
      }
      getDistrictData(params).then(res => {
        this.cityList = res.data
      })
    },
    cityChange(value) {
      let params = {
        parentCode: value.split('_')[0]
      }
      getDistrictData(params).then(res => {
        this.districtList = res.data
      })
    },
    districtChange(value) {
    },
    addrProvinceChange(value) {
      let params = {
        parentCode: value.split('_')[0]
      }
      getDistrictData(params).then(res => {
        this.addrCityList = res.data
      })
    },
    addrCityChange(value) {
      let params = {
        parentCode: value.split('_')[0]
      }
      getDistrictData(params).then(res => {
        this.addrDistrictList = res.data
      })
    },
    addrDistrictChange() {
    },
    langChange(value) {
      if(value){
        this.form.idCardNum = ''
      }
    },
    idDateChange(){
      this.isShow = false    // 修改日期的时候没有生效 必须重新刷新DOM
      this.isShow = true
    },
    rangeDateChange(value, index){
      this.isShow = false
      this.isShow = true
      if(value){
        this.form.qualificationList[index].validityText = '有值'
      }else {
        this.form.qualificationList[index].validityText = ''
      }
    },
    nextBut() {
      this.next = true
      console.log(this.form.qualificationList)
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.langTrue) {
            this.form.idNumCloseDate = '9999-12-31 00:00:00'
          } else {
            this.form.idNumCloseDate = this.form.idCardNum
          }
          this.form.qualificationList.forEach(item => {
            item.isForever = 'N'
            if(item.forever){
              item.isForever = 'Y'
              item.validityDate = ''
              item.endDate = ''
            }else if(item.rangeDate){
              item.validityDate = item.rangeDate[0]
              item.endDate = item.rangeDate[1]
            }
          })
          this.form.provinceId = this.form.provinceCode.split('_')[1]
          this.form.cityId = this.form.cityCode.split('_')[1]
          this.form.districtId = this.form.districtCode.split('_')[1]
          this.form.addrProvinceId = this.form.addrProvinceCode.split('_')[1]
          this.form.addrCityId = this.form.addrCityCode.split('_')[1]
          this.form.addrDistrictId = this.form.addrDistrictCode.split('_')[1]
          let registerBuyersLicenseSaveVoList = this.form.qualificationList.filter(item => {
            return item.fileId || item.validityDate || item.isForever === 'Y' || item.issueDate || item.businessScope
          })
          if (this.params.isChange) {
            let param = {
              registerKey: this.params.registerKey,
              registerBuyersLicenseRelUpdateVo:  registerBuyersLicenseSaveVoList,
              pcBuyersUpdateVo: {
                ...this.form,
                registerBuyersInfoId: this.params.registerBuyersInfoId
              }
            }
            updateBuyersAudit(param).then(res => {
              this.$message.success('提交成功')
              let upParam = {
                customerName: this.form.customerName,
                registerKey: res.data,
                openStat: 'WAIT_APPROVE',
                registerLoginId: this.params.registerLoginId
              }
              this.$router.push({ path: '/register', query:{registerKey: res.data,loginId: this.$route.query.loginId}})
              this.$emit('next', 3, upParam)
            })
          }else{
            let param = {
              registerKey: this.params.registerKey,
              registerBuyersLicenseSaveVoList:  registerBuyersLicenseSaveVoList,
              pcRegisterBuyersInfoSaveVo: {
                ...this.form,
                registerBuyersInfoId: this.params.registerBuyersInfoId
              }
            }
            register(param).then(res => {
              let upParam = {
                customerName: this.form.customerName,
                registerKey: res.data,
                openStat: 'WAIT_APPROVE',
                registerLoginId: this.params.registerLoginId
              }
              this.$message.success('提交成功')
              this.$emit('next', 3, upParam)
            })
          }
        }
      })
    },
    getUserInfo(){
      findRegisterBuyersInfoByLoginUser(this.params.registerKey).then(res => {
        let formInfo = {
          customType: '',
          qualificationList: []
        }
        this.form = Object.assign(res.data,formInfo)
        this.form.idCardNum = res.data.idNumCloseDate
        this.fileList = [{name: 'food.jpeg', url: res.data.taxpayerCertificationUrl}]
        this.form.taxpayerCertificationId = res.data.taxpayerCertificationId
        this.hideUploadProve = true
        this.form.customType = res.data.registerBuyersType
        this.form.provinceId = res.data.provinceId
        this.form.cityId = res.data.cityId
        this.form.districtId = res.data.districtId
        this.form.addrProvinceId = res.data.addrProvinceId
        this.form.addrCityId = res.data.addrCityId
        this.form.addrDistrictId = res.data.addrDistrictId
        this.customTypeChange(this.form.customType, res.data.registerBuyersLicenseVoList)
        this.getRegion('000','provinceList','provinceId','provinceCode').then(data =>{
          if(!data) return;
          this.getRegion(data,'cityList','cityId','cityCode').then(data =>{
              if(!data) return;
            this.getRegion(data,'districtList','districtId','districtCode').then(data =>{
              this.getRegion('000','provinceList','addrProvinceId','addrProvinceCode').then(data =>{
                  if(!data) return;
                this.getRegion(data,'addrCityList','addrCityId','addrCityCode').then(data =>{
                    if(!data) return;
                  this.getRegion(data,'addrDistrictList','addrDistrictId','addrDistrictCode')
                })
              })
            })
          })
        })
      })
    },
    async getRegion(requestCode,formList,formId,formCode){
      let parentCode = await getDistrictData({parentCode: requestCode}).then(res => {
        this[formList] = res.data
        let nodeCode
        res.data.forEach(item => {
          if(item.districtId === this.form[formId]){
            this.form[formCode] = item.nodeCode + '_' + item.districtId
            nodeCode = item.nodeCode
          }
        })
        return nodeCode
      })
      return parentCode
    }
  },
  activated() {
    if (this.params.isChange) {
      this.getUserInfo()
    } else {
      let param = {
        parentCode: '000'
      }
      getDistrictData(param).then(res => {
        this.provinceList = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.improveDiv{
  width: 1200px;
  margin: 60px auto;
  font-size: 14px;
  /deep/ .hide .el-upload--picture-card{
    display: none;
  }
  /deep/ .show .el-upload--picture-card {
    display: block;
    width: 361px;
    height: 80px;
    background: #FFFFFF url(../../assets/img/register/camera.png) no-repeat 50% 17px;
    background-size: 30px 30px;
  }
  /deep/ .customHide {
    .el-upload--picture-card{
      display: none;
    }
    .el-upload-list__item-actions{
      display: block;
    }
  }
  /deep/ .customShow{
    .el-upload-list__item-actions{
      display: none;
    }
    .el-upload--picture-card {
      display: block;
      width: 250px;
      height: 80px;
      background: #FFFFFF url(../../assets/img/register/camera.png) no-repeat 50% 17px;
      background-size: 30px 30px;
    }
  }
  /deep/ .idCardDate .el-form-item__content {
    display: flex;
  }
  .heard {
    text-align: left;
    font-size: 16px;
    color: #666666;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    .heardText {
      color: #222222;
      margin-right: 15px;
    }
  }
  .enterpriseInfo {
    margin-top: 20px;
    width: 100%;
    border: 1px solid #E5E5E5;
    padding-bottom: 20px;
    /deep/ .el-input__inner{
      height: 36px;
      line-height: 36px;
      color: #222222;
    }
    .fromTitle {
      width: 100%;
      height: 40px;
      background-color: rgba(9, 113, 235, 0.06);
      line-height: 40px;
      font-weight: 800;
      color: #222222;
      text-align: left;
      padding-left: 20px;
      margin-bottom: 30px;
    }
    .formItem {
      width: 526px;
      margin: 0 auto 24px auto;
      position: relative;
      right: 20px;
      /deep/ .el-upload-list--picture-card .el-upload-list__item {
        width: 361px;
        height: 80px;
        margin-left: -25px;
      }
      .chooseDiv{
        display: flex;
        .province{
          width: 109px;
          margin-right: 20px
        }
      }
      .upPicDiv{
        background: #FFFFFF url(../../assets/img/register/camera.png) no-repeat 50% 19px;
        background-size: 8%;
      }
    }
    .publicPayItem{
      text-align: left;
      margin: 8px 24px;
    }
    .formCustomItem {
      width: 330px;
      /deep/ .el-upload-list--picture-card .el-upload-list__item {
        width: 250px;
        height: 80px;
        margin-left: -25px;
      }
    }
    .checkDiv {
      display: flex;
      width: 700px;
      line-height: 30px;
      margin: 7.5px auto;
      .checkTitle {
        width: 150px;
        color: #222222
      }
      .checkChecked {
        margin-left: 30px;
        text-align: left;
        width: 600px;
      }
    }
    .customerDiv {
      border: 1px solid #E5E5E5;
      width: 90%;
      padding: 16px;
      margin: 0 auto;
      .customerTitle {
        text-align: left;
        line-height: 100%;
        display: flex;
        font-weight: 700;
        .titleIcon {
          width: 4px;
          height: 12px;
          background-color: #0971EB;
          margin-right: 5px;
        }
        .downTemplate {
          color: #0971EB;
          font-size: 12px;
          margin-left: 12px;
        }
      }
      .customerInput {
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;
        .formCustomItem{
          margin-right: 127px;
        }
      }
      .customerTip {
        text-align: left;
        font-size: 12px;
        color: #999999;
        margin-left: 80px;
      }
      .titleTip{
        font-size: 12px;
        color: #999999;
        margin-left: 12px;
      }
    }
    .nextInfo {
      margin-top: 0px;
      border-top: 0;
    }
  }
}
.buttonDiv {
  width: 100%;
  height: 60px;
  background-color: #F6F6F6;
  .sureBut {
    margin-top: 20px;
    width: 200px;
    height: 40px;
    @include NormalBtnColor
  }
}
/deep/ .el-form-item__label {
  color: #222222;
}
/*.avatar {*/
/*  width: 361px;*/
/*  height: 80px;*/
/*}*/
.upText {
  position: relative;
  top: -10px;
  color: #999999;
  font-size: 12px;
  font-weight: 700;
}
</style>

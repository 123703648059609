var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "swiper-container" },
    [
      _c(
        "swiper",
        { attrs: { options: _vm.swiperOption } },
        _vm._l(_vm.recoProduct, function (item, index) {
          return _c("swiper-slide", { key: index }, [
            _c(
              "div",
              { staticClass: "swiper-content" },
              [
                _vm.userType == "SALESMAN"
                  ? _c("div", { staticClass: "identifying" }, [
                      item.whiteRecordFlag == 1
                        ? _c("img", {
                            attrs: {
                              alt: "",
                              src: require("@/assets/img/icon/whiterecord.png"),
                            },
                          })
                        : item.blackRecordFlag == 1
                        ? _c("img", {
                            attrs: {
                              alt: "",
                              src: require("@/assets/img/icon/blackrecord.png"),
                            },
                          })
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "product" }, [
                  _c(
                    "div",
                    {
                      staticClass: "product-image",
                      on: {
                        click: function ($event) {
                          return _vm.checkDetail(item)
                        },
                      },
                    },
                    [
                      _c("el-image", {
                        attrs: {
                          src: item.picUrl || item.pictUrls || _vm.productImage,
                          fit: "fill",
                        },
                        on: {
                          error: function ($event) {
                            item.picUrl = _vm.productImage
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "product-description" }, [
                    _c("div", { staticClass: "product-detail" }, [
                      _c("div", { staticClass: "product-name" }, [
                        item.otcType && item.otcType !== "其他"
                          ? _c(
                              "span",
                              {
                                staticClass: "otc",
                                class: {
                                  jiaOtc: item.otcType === "甲OTC",
                                  yiOtc: item.otcType === "乙OTC",
                                  rxOtc: item.otcType === "RX",
                                },
                              },
                              [_vm._v(_vm._s(item.otcType))]
                            )
                          : _vm._e(),
                        _c(
                          "span",
                          {
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.checkDetail(item)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                " " + _vm._s(_vm.$util.getGoodsName(item)) + " "
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _c(
                        "p",
                        {
                          staticClass: "specification",
                          attrs: { title: item.spec + "x" + item.unit },
                        },
                        [_vm._v(_vm._s(item.spec) + "x" + _vm._s(item.unit))]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "manufacturers",
                          attrs: { title: item.manufacturer },
                        },
                        [_vm._v(_vm._s(item.manufacturer))]
                      ),
                    ]),
                    _c("p", { staticClass: "product-price" }, [
                      _vm._v(
                        _vm._s(
                          _vm.isLogin
                            ? _vm.$util.getPriceByZero(item.price, item)
                            : "登录可见"
                        )
                      ),
                    ]),
                  ]),
                ]),
                _c("tagList", { attrs: { taglist: item.tagList } }),
              ],
              1
            ),
          ])
        }),
        1
      ),
      _c("div", { staticClass: "swiper-button-next" }),
      _c("div", { staticClass: "swiper-button-prev" }),
      _c("div", {
        staticClass: "swiper-pagination",
        attrs: { slot: "pagination" },
        slot: "pagination",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="my-aptitudes">
    <div class="title">我的资质</div>
    <div class="reminder"><i class="el-icon-warning-outline"></i>资质证照过期将影响您药品采购，请及时提交更换</div>
    <ul class="aptitudes-cards">
      <li class="card" v-for="(item, index) in aptitudesList" :key="index">
        <div class="card-title" :style="{background:item.isForever === 'N' && item.status === '已过期'?'#FFF0EF':'#f2f2f2'}">
          {{item.licenseNm}}
          <span v-if="item.isForever === 'N' && item.status !== '未过期'" :style="{background:item.status === '已过期' ? '#E02034' : '#FA6400'  }">{{item.status}}</span>
        </div>
        <div class="card-main">
          <div class="card-image">
            <el-image
                class="thumbnailImg"
                :src="item.imgUrl || ApitiudesPicAll" @error="item.imgUrl = ApitiudesPicAll"
                :preview-src-list="[item.imgUrl || ApitiudesPicAll]">
            </el-image>
          </div>
          <div class="card-content">
            <p class='card-number' :title="item.certNum">证件号码：{{item.certNum}} </p>
            <p>证照有效期：{{item.isForever === 'Y'?'长期':item.customerLicenseEndDate}}</p>
            <div class="update-button">
              <el-upload
                  action="#"
                  ref="upload"
                  list-type="picture-card"
                  :auto-upload="true"
                  :show-file-list="hideUploadProve"
                  :file-list="fileList"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemoveProve"
                  :before-upload="beforeUpload"
                  :http-request="() => upload(item.customerLicenseTypeRelId)">
                <button slot="trigger">更新资质</button>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import ApitiudesPic from '@/assets/img/member-center/apitiudes.png'
import ApitiudesPicAll from '@/assets/img/member-center/apitiudes-all.png'
  import ApitiudesLarge from '@/assets/img/member-center/apitiudes-big.png'
  import { getMyAptitudes, saveBuyersLicenseRel } from '@/api/member-center/personal-center'
  import { batchRegisterUploadFile } from '@/api/register/index'
  import { getBus } from '@/utils/auth'

  export default {
    name: 'my-aptitudes',
    data () {
      return {
        dialogImageUrl: '',
        dialogVisible: false,
        hideUploadProve: false,
        fileList: [],
        aptitudesList: [],
        ApitiudesLarge,
        ApitiudesPic,
        ApitiudesPicAll
      }
    },
    mounted () {
      this.getAptitudesList()
    },
    methods: {
      upload (customerLicenseTypeRelId) {
        batchRegisterUploadFile(this.newFile).then(res => {
          if (res.data && res.data.fileId) {
            saveBuyersLicenseRel({
              fileId: res.data.fileId,
              fileUrl: res.data.fileUrl,
              buyersId: getBus().buyersId,
              orgId: getBus().orgId,
              isForever: 'N',
              customerLicenseTypeRelId
            }).then(res => {
              this.getAptitudesList()
              this.$message.success('保存成功')
            })
          } else {
            this.fileList = []
          }
        }).catch(error => {
          this.fileList = []
        })
      },
      handlePictureCardPreview (file) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
      handleRemoveProve (file, index) {
        this.hideUploadProve = false
      },
      beforeUpload (file) {
        this.newFile = new FormData()
        this.newFile.append('files', file)
      },
      getAptitudesList () {
        getMyAptitudes().then(
          res => {
            const { data } = res
            this.aptitudesList = data
          }
        )
      }
    }
  }
</script>

<style scoped lang="scss">
  .my-aptitudes {
    width: 100%;
    min-height: 100%;
    height: auto;
    background: #ffffff;
    box-sizing: border-box;

    .title {
      height: 50px;
      line-height: 50px;
      text-align: left;
      padding-left: 28px;
      border-bottom: 1px solid #E5E5E5;
      font-size: 16px;
      font-weight: 600;
      color: rgba(43, 43, 43, 1);
    }

    .reminder {
      text-align: left;
      margin: 16px 20px;
      height: 40px;
      line-height: 40px;
      background: $titleBg;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(34, 34, 34, 1);

      i {
        color: #0971EB;
        margin-left: 20px;
        margin-right: 8px;
        font-size: 16px;
      }
    }

    .aptitudes-cards {
      padding: 0 20px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .card {
        width: 460px;
        height: 192px;
        background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        border: 1px solid rgba(204, 204, 204, 1);
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;

        &:nth-child(odd) {
          margin-right: 16px;
        }

        .card-title {
          position: relative;
          padding-left: 16px;
          font-size: 14px;
          font-weight: 500;
          color: rgba(34, 34, 34, 1);
          text-align: left;
          line-height: 44px;
          height: 44px;
          background: rgba(242, 242, 242, 1);
          border-bottom: 1px solid rgba(229, 229, 229, 1);

          span {
            position: absolute;
            right: -1px;
            top: -1px;
            height: 24px;
            line-height:24px;
            font-size: 14px;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
            padding: 2px 8px;
            // background: rgba(224, 32, 52, 1);
            border-radius: 0 4px 0 8px;
          }
        }

        .card-main {
          width: 100%;
          // flex: 1;
          padding: 24px 16px;
          display: flex;
          align-items: center;

         /deep/ .card-image {
            width: 100px;
            height: 100px;
            border-radius: 4px;
            border: 1px solid rgba(204, 204, 204, 1);
            margin-right: 26px;
            .thumbnailImg{
              width: 100%;
              height: 100%;
              display: block;
            }
          }

          .card-content {
            flex: 1;
            text-align: left;
            font-size: 14px;
            font-weight: 400;
            color: rgba(153, 153, 153, 1);
            // .card-number{
            //   height: 20px;
            //   line-height: 20px;
            //   margin-bottom: 8px;
            // }

            p {
              width: 300px;
              // width: 40%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              height: 20px;
              line-height: 20px;
              margin-bottom: 8px;
            }

            .update-button {
              button {
                height: 28px;
                background: rgba(255, 255, 255, 1);
                border: 1px solid #ccc;
                font-size: 12px;
                font-weight: 500;
                color: #666;
                border-radius: 4px;
                cursor: pointer;
                width: 70px;
              }

              &:hover {
                opacity: .8;
              }
            }
          }
        }
      }
    }

    /deep/ .el-upload--picture-card {
      display: flex;
      width: 70px;
      height: 28px;
      border: 0;
    }
  }
</style>

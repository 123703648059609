<template>
  <div class="main">
    <Search />
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item v-if="strList.length > 0"
          :to="{ path: '/commodity-list', query: { categoryId: product.categoryId, categoryCode: product.categoryCode } }">
          <el-dropdown placement="bottom-start">
            <span>{{ strList[0] }}</span>
            <el-dropdown-menu slot="dropdown" class="childList">
              <el-dropdown-item class="childListson" v-for="(item, index) in firstList" :key="index">
                <router-link
                  :to="{ path: '/commodity-list', query: { categoryId: item.categoryId, categoryCode: item.categoryCode } }">
                  {{ item.categoryNm }}
                </router-link>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="strList.length > 1"
          :to="{ path: '/commodity-list', query: { categoryId: product.categoryId, categoryCode: product.categoryCode } }">
          <el-dropdown placement="bottom-start">
            <span>{{ strList[1] }}</span>
            <el-dropdown-menu slot="dropdown" class="childList">
              <el-dropdown-item class="childListson" v-for="(item, index) in secondList" :key="index">
                <router-link
                  :to="{ path: '/commodity-list', query: { categoryId: item.categoryId, categoryCode: item.categoryCode } }">
                  {{ item.categoryNm }}
                </router-link>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-breadcrumb-item>

        <el-breadcrumb-item v-if="product.productNm || product.drugNm">{{ product | getGoodsName }}</el-breadcrumb-item>
      </el-breadcrumb>
      <!--      <span class="buy">-->
      <!--        手机购买-->
      <!--        <img src="../../assets/img/icon/code.png" alt/>-->
      <!--        <i class="el-icon-arrow-down"></i>-->
      <!--      </span>-->
    </div>

    <div class="content">
      <div class="detail-header">
        <div ref="headerLeft" class="detail-header-left">
          <div class="preview">
            <div class="identifying" v-if="userType == 'SALESMAN'">
              <img v-if="product.whiteRecordFlag == 1" alt src="@/assets/img/icon/whiterecord.png">
              <img v-else-if="product.blackRecordFlag == 1" alt src="@/assets/img/icon/blackrecord.png">
            </div>
            <im-pic-zoom :url="product.pictUrls || productDefaultImg" />

            <div class="min-img-box" v-if="preview.length">
              <div class="button-prev" @click="toLeftMove"><i class="el-icon-arrow-left"></i></div>
              <ul class="img-list" ref="scorll_left">
                <li :class="{ 'active': item.active }" @mouseenter="previewImg(index)" v-for="(item, index) in preview">
                  <img alt :src="item.url || productDefaultImg" @error="item.url = productDefaultImg" />
                </li>
              </ul>
              <div class="button-next" @click="toRightMove"><i class="el-icon-arrow-right"></i></div>
            </div>

            <div v-if="userType !== 'SALESMAN'" class="collect" @click="starProduct(product.isProductCollect)"
              :class="{ 'active': product.isProductCollect === 'Y' }">
              <i class="el-icon-star-off"></i>
              {{ product.isProductCollect === 'Y' ? '已收藏' : '加入收藏' }}
            </div>
          </div>
          <div class="message">
            <div class="message-top">
              <div class="mes-name">
                <b v-if="product.otcType && product.otcType !== '其他'"
                  :class="{ 'jiaOtc': product.otcType === '甲OTC', 'yiOtc': product.otcType === '乙OTC', 'rxOtc': product.otcType === 'RX' }">{{
                    product.otcType }}</b>
                <p>
                  {{ $util.getGoodsName(product) }}
                </p>
              </div>
              <div class="mes-subtitle">
                <tagList :taglist='product.tagList'></tagList>
              </div>
              <!-- <div class="mes-subtitle" v-else>
                <span class="type2" v-for="item in tagList"> {{ item.tag }}</span>
              </div> -->
              <div class="mes-info-list">
                <!--                <div class="mes-info-item">-->
                <!--                  <span class="mes-info-title">商品通用名</span>-->
                <!--                  <span class="mes-info-content">{{ product.drugNm }}</span>-->
                <!--                </div>-->
                <div class="mes-info-item">
                  <span class="mes-info-title">规格</span>
                  <span class="mes-info-content">{{ product.spec }}</span>
                </div>
                <div class="mes-info-item">
                  <span class="mes-info-title">商品编码</span>
                  <span class="mes-info-content">{{ product.productCode }}</span>
                </div>
                <div class="mes-info-item">
                  <span class="mes-info-title">单位</span>
                  <span class="mes-info-content">{{ product.unit }}</span>
                </div>

                <div class="mes-info-item">
                  <span class="mes-info-title">销项税</span>
                  <span class="mes-info-content">{{ product.rate }}</span>
                </div>
                <div class="mes-info-item" style="width: 100%">
                  <span class="mes-info-title">厂家(上市许可持有人)/产地</span>
                  <span class="mes-info-content" style="padding-left: 10px">{{ product.manufacturer }}</span>
                </div>
                <div class="mes-info-item" style="width: 100%">
                  <span class="mes-info-title">最近效期</span>
                  <span class="mes-info-content">{{ product.expDate }}</span>
                </div>
              </div>
            </div>
            <div class="message-discount" v-if="product.discountEndTime">
              <div class="count-down" v-if="product.isCountDown === 'Y'">
                <span class="count-down-text">距离结束剩余</span>
                <span class="count-down-num count-down-hour">{{ timeList.day.split('')[0] }}</span>
                <span class="count-down-num count-down-hour">{{ timeList.day.split('')[1] }}</span>
                <span class="count-down-point">天</span>
                <span class="count-down-num count-down-minute">{{ timeList.hou.split('')[0] }}</span>
                <span class="count-down-num count-down-minute">{{ timeList.hou.split('')[1] }}</span>
                <span class="count-down-point">:</span>
                <span class="count-down-num count-down-seconds">{{ timeList.min.split('')[0] }}</span>
                <span class="count-down-num count-down-seconds">{{ timeList.min.split('')[1] }}</span>
                <span class="count-down-point">:</span>
                <span class="count-down-num count-down-seconds">{{ timeList.sec.split('')[0] }}</span>
                <span class="count-down-num count-down-seconds">{{ timeList.sec.split('')[1] }}</span>
              </div>
            </div>
            <div class="price-num">
              <div class="price flex">
                <div class="wholesale flex">
                  <div class="price-name">销售价</div>
                  <div class="price-wholesale">
                    <div style="text-align: right;">{{ $util.getPriceByZero(product.price, product) }}</div>
                    <div style="text-decoration: line-through;color: #999999;text-align: right"
                      v-if="product.isDiscount === 'Y'">{{ product.salePrice ? '￥' + product.salePrice : '' }}</div>
                  </div>
                </div>
                <!--<div class="market flex">
                  <div class="price-name">零售价</div>
                  <div class="price-market">¥{{ product.retailPrice }}</div>
                </div>
                <div class="market flex">
                  <div class="price-name">医保支付价</div>
                  <div class="price-market">¥{{ product.medicarePrice }}</div>
                </div> -->
              </div>
              <div class="promotion"
                v-if="coupon.length || additional.length || present.length || special.length || reduce.length">
                <div class="promotion-title">
                  <span style="width: 5em">促销信息</span>
                  <div class="promotion-list">
                    <div v-if="coupon.length" class="promotion-item">
                      <div>
                        <span>优惠券</span>
                      </div>
                      <b v-for="(item, index) in coupon" :key="index" v-show="index < 4 && item.isShow === 'Y'">{{
                        item.couponNm }}</b>
                      <div class="promotion-more" @click="dialogCouponVisible = !dialogCouponVisible">领取优惠券</div>
                    </div>
                    <div v-if="additional.length" class="promotion-item">
                      <div>
                        <span>加价购</span>
                      </div>
                      {{ '购满' + additional[0].orderFullAmount + '元，即可享受加价购优惠' }} <span style="cursor:pointer;"
                        @click="showAdditionalProduct = true">查看加购商品</span>

                    </div>
                    <div v-if="special.length" class="promotion-item">
                      <div>
                        <span>特价采购</span>
                      </div>
                      {{ '购满' + special[0].orderFullAmount + '元，即可享受特价换购优惠' }} <span style="cursor:pointer;"
                        @click="showSpecialProduct = true">查看换购商品</span>

                    </div>
                    <div v-if="present.length" class="promotion-item">
                      <div>
                        <span v-if="present[0].frsPresentRelList.length > 0">自选套餐</span>
                        <!-- <span v-else>满减赠</span> -->
                      </div>
                      <!--                      满{{ present[0].orderFullAmount }}送{{ present[0].frsPresentRelList.length }}件特殊商品-->
                      <!--                      <div class="promotion-more" @click="dialogPresentVisible = !dialogPresentVisible">查看规则</div>-->
                    </div>

                    <div v-if="reduce.length" class="promotion-item">
                      <div class="reduce-box">
                        <div class="reduce-tag">
                          <span>满减/送</span>
                        </div>
                        <div class="reduce-text" :title="reduceText">
                          {{ reduceText }}

                        </div>
                      </div>




                    </div>
                    <el-dialog width="450px" title="领取优惠券" :visible.sync="dialogCouponVisible">
                      <div class>
                        <div class="coupon-dialog" v-for="(item, index) in coupon" :key="index"
                          v-if="item.isShow === 'Y'">
                          <div class="flex">
                            <div class="name" v-if="item.couponType === 'FULL_REDUCTION'">
                              <div class="left-con"></div>
                              <p>¥{{ item.fullReductionAmount }}</p>
                              <div class="right-con"></div>
                            </div>
                            <div class="name" v-else>
                              <div class="left-con"></div>
                              <p>{{ item.discount }}折</p>
                              <div class="right-con"></div>
                            </div>
                            <div class="text">
                              <div class="text_1">{{ item.couponNm }}</div>
                              <div class="text_2" v-if="item.useTimeType === 'IN_THE_DAY'">领券当日起{{ item.useValidDay
                              }}天内可用
                              </div>
                              <div class="text_2" v-else-if="item.useTimeType === 'TIMES_OF_THE_DAY'">领券次日起{{
                                item.useValidDay }}天内可用
                              </div>
                              <div class="text_2" v-else>{{ item.useStartTime }} - {{ item.useEndTime }}</div>
                            </div>
                          </div>
                          <div class="button" @click="handleReceive(item)"
                            v-if="item.isLimitTimes === 'Y' && item.userReceivedQuantity < item.limitNumber && item.remainQuantity > 0 && item.userReceivedQuantity < item.remainQuantity">
                            <p>立即领券</p>
                          </div>
                          <div class="button" @click="handleReceive(item)"
                            v-if="item.isLimitTimes === 'N' && item.remainQuantity > 0">
                            <p>立即领券</p>
                          </div>
                          <div class="button isReceive"
                            v-else-if="item.userReceivedQuantity >= item.limitNumber && item.userReceivedQuantity > 0">
                            <p>已领取</p>
                          </div>
                          <!--                          <div class="button" v-else-if="item.isLimitTimes === 'Y' && item.userReceivedQuantity >= item.limitNumber">-->
                          <!--                            <p>已领取</p>-->
                          <!--                          </div>-->
                          <div class="button disabled"
                            v-else-if="item.remainQuantity <= 0 && item.userReceivedQuantity <= 0">
                            <p>已抢光</p>
                          </div>

                        </div>
                      </div>
                    </el-dialog>
                    <el-dialog width="450px" title="特殊商品规则" :visible.sync="dialogPresentVisible">
                      <div class="present-dialog" v-for="(item, index) in present" :key="index">
                        <div class="name">
                          <p>·满{{ item.orderFullAmount }}，送{{ item.frsPresentRelList.length }}件特殊商品</p>
                        </div>
                        <div class="img">
                          <img v-for="(img, i) in item.frsPresentRelList" :key="i"
                            :src="img.fieldId || productDefaultImg" @error="img.fieldId = productDefaultImg" alt />
                        </div>
                      </div>
                    </el-dialog>
                  </div>
                </div>
              </div>
            </div>
            <div class="qualifications" v-if="isLicenseOutOfTime">{{ userType !== 'SALESMAN' ? '您有' + license.failureSum
              +
              '张证照已过期,该商品不能购买，请提供最新证照，详询4001-600998' : '该客户有' + license.failureSum + '张证照已过期，请联系客户提供最新证照' }}
              <router-link to="/member-center/personal-center/my-aptitudes"
                v-if="userType !== 'SALESMAN'">更新资质</router-link>
            </div>
            <template v-else>
              <div class="message-bottom">
                <div class="buy-num">
                  <div class="promotion-title">购买数量</div>
                  <el-input-number size="mini" v-model="quantity" step-strictly :precision="getDecimalPointNum(product)"
                    :step="getRealMinBuyNum(product)" :min="getRealMinBuyNum(product)" label="购买数量" v-if="quantityIf" />
                  <div class="stock" v-if="$store.getters.buyersVo && $store.getters.buyersVo.showStockType != 5">
                    <span>库存：</span>
                    <span v-if="$store.getters.buyersVo.showStockType == 4">
                      {{ product.stockQuantity >= 100 ? '>100' : '<100' }} </span>

                        <span v-else-if="$store.getters.buyersVo.showStockType == 3">
                          {{ product.stockQuantity > 0 ? '有' : '无' }}
                        </span>
                        <span v-else-if="$store.getters.buyersVo.showStockType == 2">
                          {{ product.stockQuantity }}
                        </span>
                  </div>
                  <!-- <div  v-if="userType === 'SALESMAN'">库存：{{product.stockQuantity}}</div> -->
                  <!-- <div class="stock" v-if="userType === 'BUYERS'">库存：{{ product.stockQuantity >= 100 ? '>100' : '<100' }}</div> -->
                  <div class="stock">最小购买倍数：{{ getRealMinBuyNum(product) }}
                  </div>
                </div>
                <div class="add-button">
                  <!--                  <el-button v-if="product.stockQuantity === 0" type="info">已售罄</el-button>-->
                  <el-button v-if="product.stockQuantity === 0" type="info"
                    @click="handleRegisterGoods(product, quantity)">缺货登记</el-button>
                  <el-button v-else-if="product.isOnSale === 'N'" type="info">已下架</el-button>
                  <div v-else>
                    <el-button class="code-button" :disabled="isBanAddToCart"
                      @click="addItem(product.skuId, 'NORMAL', quantity, product); tract(product, quantity)" :loading='addToCartloading'>
                      <img src="../../assets/img/icon/icon-gouwuche.png" alt />加入购物车
                    </el-button>
                    <el-button type="primary" @click="buyNow(product, quantity)"
                      :disabled="isBanAddToCart">立即购买</el-button>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <!--        同类产品-->
        <div ref="headerRight" class="detail-header-right">
          <div class="detail-header-right-text">
            <span class="title">同类产品</span>
            <span class="refresh" @click="getSimilarProductList()">
              换一批
              <div class="el-icon-refresh"></div>
            </span>
          </div>
          <div v-if="similarProduct.length">
            <div class="detail-header-right-content" v-for="(item, index) in similarProduct" :key="index"
              @click="handleGetDetail(item.productId)">
              <div class="detail-header-right-img">
                <img alt :src="item.pictUrls || productDefaultImg" @error="item.pictUrls = productDefaultImg" />
              </div>
              <p class="name">{{ $util.getGoodsName(item) }}</p>
              <p class="num">{{ item.spec }}</p>
              <p class="company">{{ item.manufacturer }}</p>
              <p class="price">{{ item.priceStr }}</p>
              <!-- <p class="price">¥{{item.price}}</p> -->
            </div>
          </div>
          <div v-else>
            <p style="font-size: 13px;margin-top: 200px">暂无信息</p>
          </div>
        </div>
      </div>
      <!--搭配套餐-->
      <swiper-package v-if="COLLOCATION_PACKAGE.length" :type="'COLLOCATION_PACKAGE'"
        :swiper-data="COLLOCATION_PACKAGE"></swiper-package>
      <!--组合套餐-->
      <swiper-package v-if="FIXED_PACKAGE.length" :type="'FIXED_PACKAGE'" :swiper-data="FIXED_PACKAGE"></swiper-package>
      <div class="detail-footer">
        <!--浏览记录-->
        <div class="detail-header-right">
          <div class="detail-header-right-text">
            <span class="title">浏览记录</span>
          </div>
          <template v-for="(item, index) in historyList">
            <div class="detail-header-right-content" :key="index" v-if="index < 4">
              <div class="identifying" v-if="userType == 'SALESMAN'">
                <img v-if="item.whiteRecordFlag == 1" alt src="@/assets/img/icon/whiterecord.png">
                <img v-else-if="item.blackRecordFlag == 1" alt src="@/assets/img/icon/blackrecord.png">
              </div>
              <div class="detail-header-right-img" @click="handleGetDetail(item.productId)">
                <img alt :src="item.picUrl || productDefaultImg" @error="item.picUrl = productDefaultImg" />
              </div>
              <p class="name" @click="handleGetDetail(item.productId)">
                <span>
                  {{ $util.getGoodsName(item) }}
                </span>
              <p class="num">{{ item.spec }}</p>
              <p class="company">{{ item.manufacturer }}</p>
              <p class="price">
                <span v-if="item.price">¥{{ item.price }}</span>
              </p>
            </div>
          </template>
        </div>
        <!--商品详情-->
        <div class="detail-footer-tab">
          <el-tabs v-model="activeFooter" @tab-click="handleTabClick">
            <el-tab-pane label="商品详情" name="first" class="first">
              <div class="mes-info-list">
                <div class="mes-info-item">
                  <span class="mes-info-title">商品编码</span>
                  <span class="mes-info-content">{{ product.productCode }}</span>
                </div>
                <div class="mes-info-item">
                  <span class="mes-info-title">单位</span>
                  <span class="mes-info-content">{{ product.unit }}</span>
                </div>
                <div class="mes-info-item">
                  <span class="mes-info-title">件包装</span>
                  <span class="mes-info-content">{{ product.packTotal }}</span>
                </div>
                <div class="mes-info-item">
                  <span class="mes-info-title">处方类型</span>
                  <span class="mes-info-content">{{ product.otcType }}</span>
                </div>
                <div class="mes-info-item">
                  <span class="mes-info-title">商品名称</span>
                  <span class="mes-info-content">{{ $util.getGoodsName(product) }}</span>
                </div>
                <div class="mes-info-item">
                  <span class="mes-info-title">剂型</span>
                  <span class="mes-info-content">{{ product.dosageForm }}</span>
                </div>
                <div class="mes-info-item">
                  <span class="mes-info-title">品牌</span>
                  <span class="mes-info-content">{{ product.brandNm }}</span>
                </div>
                <!--                <div class="mes-info-item">-->
                <!--                  <span class="mes-info-title">通用名称</span>-->
                <!--                  <span class="mes-info-content">{{ product.drugNm }}</span>-->
                <!--                </div>-->
                <div class="mes-info-item">
                  <span class="mes-info-title">生产企业</span>
                  <span class="mes-info-content">{{ product.manufacturer }}</span>
                </div>
                <div class="mes-info-item">
                  <span class="mes-info-title">规格</span>
                  <span class="mes-info-content">{{ product.spec }}</span>
                </div>
                <div class="mes-info-item">
                  <span class="mes-info-title">批准文号</span>
                  <span class="mes-info-content">{{ product.approvalNumber }}</span>
                </div>
                <div class="mes-info-item" v-if="userType === 'BUYERS'">
                  <span class="mes-info-title">最近效期</span>
                  <span class="mes-info-content">{{ product.expDate }}</span>
                </div>
              </div>
              <div class="detail-img-hr"></div>
              <div class="detail-img second" v-html="product.productDescrString"></div>
              <div v-if="!product.productDescrString" class="empty-box">
                <div>
                  <el-image style="width: 160px; height: 160px" :src="emptyDefault" fit="fill" />
                  <p class="empty-tip">暂无信息</p>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="说明书" name="second">
              <div class="second" v-html="product.instructionsString"></div>
              <div v-if="!product.instructionsString" class="empty-box">
                <div>
                  <el-image style="width: 160px; height: 160px" :src="emptyDefault" fit="fill" />
                  <p class="empty-tip">暂无信息</p>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="下载商品资质" name="fine" v-if="isLogin">
              <div class="fine">
                <div style="margin-top: 10px;margin-bottom: 5px;margin-left: 230px;">目前提供的pdf电子版的商品首营资质资料,如果需要纸质版,请联系业务员
                </div>
                <el-table ref="tableData" :data="licenses" border style="margin: 0 10px 0 0; width: 100%;">
                  <el-table-column prop="productNm" label="商品名称" align="center" width="180" show-overflow-tooltip />
                  <el-table-column prop="productCode" label="商品编码" align="center" width="180" show-overflow-tooltip />
                  <!--<el-table-column prop="validOnDate" label="开始日期－结束日期" align="left">
                    <template slot-scope="scope">
                      <span v-if="scope.row.isForever == 'Y'">长期</span>
                      <span v-else-if="scope.row.licenseStartDate && scope.row.licenseEndDate">{{scope.row.licenseStartDate}}至{{scope.row.licenseEndDate}}</span>
                      <span v-else>{{scope.row.licenseStartDate}}{{scope.row.licenseEndDate}}</span>
                    </template>
                  </el-table-column> -->
                  <el-table-column prop="pdfUrl" label="操作" align="center">
                    <template v-if="scope.row.pdfUrl" slot-scope="scope">
                      <span v-for="(item, index) in scope.row.pdfUrl.split(',')">
                        <el-button type="text"
                          @click="$util.downFiles(item, scope.row.productCode + '|' + scope.row.productNm)">电子版资质资料</el-button>

                        <!--<el-button type="text" @click="alert('还没开发完成')">触发需要纸质版通知</el-button>-->
                      </span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
            <el-tab-pane label="批号有效期" name="five" v-if="userType == 'SALESMAN'">
              <div class="table-PHXQ-box">
                <!-- <el-table ref="tableDataPHXQ" :data="tableDataPHXQ" v-loading="tableLoading" border
                  style="margin: 0 10px 0 0; width: 100%;">
                  <el-table-column type="index" width="100" label="序号" align="center"> </el-table-column>
                  <el-table-column prop="batch" label="批号" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <span v-if="scope.row.isTransport != ''">{{ scope.row.isTransport }}</span>
                      <span>{{ scope.row.batchId }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="entryName" label="调拨区域" align="center" show-overflow-tooltip />
                  <el-table-column prop="" label="调拨价格" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <span v-if="scope.row.costPrice != ''">{{ scope.row.costPrice }}</span>
                      <span v-else>{{ scope.row.dsj }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="expDate" label="有效期至" align="center" show-overflow-tooltip />
                  <el-table-column prop="goodsQty" label="库存" align="center" show-overflow-tooltip />
                </el-table>
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                  :current-page="paginationPHXQ.pageNum" :page-sizes="[15, 30]" :page-size="paginationPHXQ.pageSize"
                  layout="total, sizes, prev, pager, next, jumper" :total="paginationPHXQ.total">
                </el-pagination> -->
                <el-table ref="tableDataPHXQ" :data="tableDataPHXQ" v-loading="tableLoading" border
                  style="margin: 0 10px 0 0; width: 100%;">
                  <el-table-column v-for="column in phxqColumns" :key="column.prop" :label="column.label"
                    :width="column.width">
                    <template slot-scope="scope">
                      <span v-if="column.prop == 'batchId'">
                        {{ scope.row.isTransport }}<span v-if="columnFlag == 'Y'">{{ scope.row.batchId }}</span>

                      </span>
                      <span v-else-if="column.prop == 'costPrice'">
                        <div v-if="scope.row.isTransport == '调'">
                          <!-- {{ scope.row.costPrice || scope.row.dsj }} -->
                          {{ getCostPrice(scope.row.costPrice, scope.row.dsj) }}
                        </div>
                      </span>
                      <span v-else-if="column.prop == 'expDate'">
                        {{ scope.row.expDate != null ? $util.getTime(scope.row.expDate, 'yyyy-MM-dd') : "/" }}
                      </span>
                      <span v-else>
                        {{ scope.row[column.prop] }}
                      </span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>

      <el-dialog class="addition-dialog" v-if="additional.length" width="600px" title="加价购商品"
        :visible.sync="showAdditionalProduct">
        <h3>{{ additional[0].activityNm }}</h3>
        <div class="additionalBox">
          <div class="additional" v-for="(item, index) in additional[0].productVoList" :key="index">
            <div class="imgBox">

              <img :src="item.thumbnailUrl || item.picUrl || productDefaultImg"
                @error="item.thumbnailUrl = productDefaultImg" alt>

            </div>
            <div class="additionContent">
              <div class="name">
                {{ $util.getGoodsName(item) }}
              </div>
              <div class="spec">{{ item.spec }}</div>
              <div class="manufacturer">{{ item.manufacturer }}</div>
              <div class="price">¥{{ item.redemptionPrice || item.price || item.originalUnitPrice }}<span>¥{{
                item.salePrice
                  }}</span></div>
            </div>
          </div>
        </div>
      </el-dialog>
      <el-dialog class="addition-dialog" v-if="special.length" width="600px" title="特价换购商品"
        :visible.sync="showSpecialProduct">
        <h3>{{ special[0].activityNm }}</h3>
        <div class="additionalBox">
          <div class="additional" v-for="(item, index) in special[0].productVoList" :key="index">
            <div class="imgBox">

              <img :src="item.thumbnailUrl || item.picUrl || productDefaultImg"
                @error="item.thumbnailUrl = productDefaultImg" alt>

            </div>
            <div class="additionContent">
              <div class="name">{{ $util.getGoodsName(item) }}</div>
              <div class="spec">{{ item.spec }}</div>
              <div class="manufacturer">{{ item.manufacturer }}</div>
              <div class="price">¥{{ item.redemptionPrice || item.price || item.originalUnitPrice }}<span>¥{{
                item.salePrice
                  }}</span></div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <ToolBar />
  </div>
</template>

<script>
import tagList from '@/components/tagList/tagList'
import {
  addItem,
  getAdditionalActivityByProductId,
  getSpecialPricePurchaseByProductId,
  getCouponByProductId,
  getDiscountPackageByProductId,
  getLicensePicUrlList,
  getPresentByProductId,
  getReduceByProductId,
  getProductDetail,
  saveOrUpdateProductCollect,
  getBrowsingHistory,
  saveBrowsingHistory,
  listRecommendProduct,
  downloadZipFiles
} from '@/api/detail'
import { findByProductIds } from '@/api/user'
import { mapGetters } from 'vuex'
import { saveCouponReceiveSituation } from '@/api/promotion-zone'
import Search from '@/components/Search/Search'
import ToolBar from '@/components/ToolBar/ToolBar'
import SwiperPackage from './components/swiper-package'
import productDefaultImg from '@/assets/img/index/product_default.png'
import emptyDefault from '@/assets/img/member-center/content-empty-default.png'
import { toThousands, removeCommas, countDown } from '@/utils'
import { getBus, getToken } from '@/utils/auth'
import { getPHXQ } from "../../api/commodity-list";
import addToCartMixin from '@/common/addToCart'
export default {
  name: 'detail',
  mixins: [addToCartMixin],
  components: {
    Search,
    ToolBar,
    SwiperPackage,
    tagList
  },
  data() {
    return {
      productDefaultImg,
      emptyDefault,
      addToCartloading: false,
      activeFooter: 'first',
      preview: [],
      productId: '',
      dialogCouponVisible: false,
      dialogPresentVisible: false,
      showAdditionalProduct: false,
      showSpecialProduct: false,
      isLogin: false,
      licenses: [],
      coupon: [],
      additional: [],
      special: [],
      present: [],
      reduce: [],
      reduceText: '',
      similarProduct: [],
      FIXED_PACKAGE: [],
      COLLOCATION_PACKAGE: [],
      product: {
        pictUrls: '',
      },
      strList: [],
      tagList: [],
      secondList: [],
      firstList: [],
      quantity: 1,
      categoryId: '',
      similarPageNum: 1,
      historyList: [],
      timeList: {
        day: '00',
        hou: '00',
        min: '00',
        sec: '00'
      },
      quantityIf: false,
      tableDataPHXQ: [],
      paginationPHXQ: {
        pageNum: 1,
        pageSize: 15,
        total: 0
      },
      phxqColumns: [],
      columnFlag: 'Y',
      tableLoading: false,
      eveyMenuWidth: 70, // 每一个菜单的宽度
      minImgNum: 0,  // 总共多少小图片
      nowImgIndex: 0
    }
  },
  created() {

    this.productId = this.$route.params.id
    this.getProduct()
    // this.getCart()
    // this.getPHXQ()
  },
  computed: {
    ...mapGetters(['userType', 'license'])
  },
  watch: {
    // 'this.product.isHandleCart': {
    //   handler () {
    //     if (this.product.isHandleCart === 'Y') {
    //       this.getCart()
    //     }
    //   },
    //   deep: true,
    // },
    '$store.state.user.refreshModule': {
      handler() {
        this.getProduct()
      },
      deep: true,
    },
    '$route.params.id': {
      handler() {
        this.$router.go(0)
      },
      deep: true,
    },
    'product.minOrderQuantity': {
      handler(newVal) {
        this.quantityIf = true
      },
      deep: true,
    }
  },
  methods: {
    tract(product, quantity) {
      // console.log('加入购物车 product:', product, ' quantity:', quantity)
      sensors.track("AddCartButtonClick", {
				"itemNo": product.productCode,
				"itemName": product.drugNm,
				"originalPrice": product.price,
				"buyCount": quantity
			});
    },
    handleTabClick(tab, event) {
      if (this.activeFooter == 'five') {
        // console.log('handleTabClick:批号有效期');
        this.getGoodsBatch()

      }

    },
    handleReceive(item) {
      if (getToken()) {
        saveCouponReceiveSituation({ couponId: item.couponId }).then(res => {
          this.$message.success('领取成功')
          this.getCoupon()
        })
      } else {
        this.$message.warning('请先登录')
        this.$router.push(`/login`)
      }
    },
    getBase64Images(image) {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      const context = canvas.getContext('2d')
      context.drawImage(image, 0, 0, image.width, image.height)
      return canvas.toDataURL('image/png', 0)
    },
    downloadImage(item) {
      downloadZipFiles({
        fileIdList: [item.fileId],
        orgId: getBus().orgId,
      }).then((res) => {
        const content = res.data
        const blob = new Blob([content], { type: 'application/zip' })
        const timestamp = (new Date()).valueOf()
        const fileName = timestamp + '.zip'
        if ('download' in document.createElement('a')) { // 非IE下载
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = window.URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          window.URL.revokeObjectURL(elink.href) // 释放URL 对象
          document.body.removeChild(elink)
        } else { // IE10+下载
          navigator.msSaveBlob(blob, fileName)
        }
        this.$message.success('下载成功')
      })
    },
    throttle(handle, wait) {
      let lastTime = 0
      return function (e) {
        let nowTime = new Date().getTime()
        if (nowTime - lastTime > wait) {
          handle.apply(this, arguments)
          lastTime = nowTime
        }
      }
    },
    starProduct(isProductCollect) {
      let type = isProductCollect === 'Y' ? 'remove' : 'save'
      saveOrUpdateProductCollect({
        userId: getBus().buyersId,
        productId: this.productId,
        type,
      }).then((res) => {
        const { msg } = res
        this.product.isProductCollect = isProductCollect === 'Y' ? 'N' : 'Y'
        this.$message.success(msg)
      })
    },
    getProductDetail() {
      let that = this
      let productId = this.productId
      getProductDetail({ productId }).then((res) => {
        const { data } = res
        // console.log('商品信息:', data)
        sensors.track("GoodsItemClick", { "itemNo": data.productCode, "itemName": data.drugNm });
        this.quantity = this.getRealMinBuyNum(data)
        this.product = {
          ...data,
          count: this.quantity
        }

        if (this.product.categoryNm) {
          this.strList = this.product.categoryNm.split('/')
          for (let i in that.product.categoryTree) {
            if (i === that.strList[0]) {
              this.firstList = that.product.categoryTree[i]
            }
            if (i === that.strList[1]) {
              this.secondList = that.product.categoryTree[i]
            }
          }
        }
        this.preview = []
        if (data.imgArray) {
          data.imgArray.forEach(data => {
            let obj = {}
            obj.active = false
            obj.url = data
            this.preview.push(obj)
          })
          this.minImgNum = data.imgArray.length
          this.preview[0].active = true
        }
        this.categoryId = data.categoryId
        this.getSimilarProductList(data.categoryId)
        if (data.discountEndTime) {
          this.setIntervalObj = setInterval(() => {
            this.timeList = countDown(data.discountEndTime)
          }, 1000)
        }

      })
    },
    getCoupon() {
      getCouponByProductId({ productId: this.productId }).then((res) => {
        const { data } = res
        this.coupon = data
      })
    },
    async getProduct() {
      let productId = this.productId
      // 商品详情
      this.getProductDetail()
      // 优惠券
      this.getCoupon();
      // 资质证书
      await findByProductIds({ productIds: productId }).then(res => {
        const { data } = res
        this.licenses = data
      });
      // 加价购
      await getAdditionalActivityByProductId({ productId }).then((res) => {
        const { data } = res
        this.additional = data
      })
      await getSpecialPricePurchaseByProductId({ productId }).then((res) => {
        const { data } = res
        this.special = data
      })
      // 特殊商品
      await getPresentByProductId({ productId }).then((res) => {
        const { data } = res
        this.present = data
      })

      // 满减
      await getReduceByProductId({ productId }).then((res) => {
        const { data } = res
        this.reduce = data
        let reduceTextList = []
        this.reduce.forEach((item) => {
          reduceTextList.push(item.activityNm)
        })
        this.reduceText = reduceTextList.join(',')
      })

      // 搭配套餐
      await getDiscountPackageByProductId({
        productId,
        discountPackageType: 'COLLOCATION_PACKAGE',
      }).then((res) => {
        const { data } = res
        data.forEach((data) => {
          let list = data.productMessageList
          list.forEach((item) => {
            item.price = toThousands(item.price)
            item.select = false
          })
          list[0].select = true
          data.total = toThousands(removeCommas(list[0].salePrice) * list[0].quantity)
          data.packagePrice = toThousands(list[0].discountPackagePrice * list[0].quantity)
          data.select = 1
        })
        this.COLLOCATION_PACKAGE = data
      })
      // 固定套餐
      await getDiscountPackageByProductId({
        productId,
        discountPackageType: 'FIXED_PACKAGE',
      }).then((res) => {
        const { data } = res
        data.forEach((data) => {
          let total = 0
          data.productMessageList.forEach((item) => {
            total += item.salePrice ? removeCommas(item.salePrice) * item.quantity : 0
            item.price = removeCommas(item.price)
          })
          data.total = toThousands(total)
        })
        this.FIXED_PACKAGE = data
      })
      //记录浏览商品
      await saveBrowsingHistory({ org: getBus().orgId, productId: productId })
      //获取浏览记录
      await getBrowsingHistory(getBus().orgId).then((res) => {
        this.historyList = res.data
      })
    },
    //同类商品
    getSimilarProductList() {
      listRecommendProduct(this.productId).then((res) => {
        const { data } = res
        if (data.length > 0) {
          this.similarProduct = data
        }
      })
    },
    //查看其他商品详情
    handleGetDetail(productId) {
      let routeUrl = this.$router.resolve({ path: '/detail/' + productId })
      window.open(routeUrl.href, '_blank')
      // this.$router.push({path: '/detail/' + productId})
      // this.productId = productId
      // if (this.setIntervalObj) {
      //   clearInterval(this.setIntervalObj)
      // }
      // this.getProduct()
    },
    previewImg(index) {
      this.product.pictUrls = this.preview[index].url
      this.preview.forEach((e, i) => {
        this.preview[i].active = false
      })
      this.preview[index].active = true
    },

    // 获取批号效期
    getGoodsBatch() {
      const params = {
        pageNum: this.paginationPHXQ.pageNum,
        pageSize: this.paginationPHXQ.pageSize,
        condition: {
          productId: this.productId
        }
      }
      this.tableLoading = true
      getPHXQ(params).then(res => {
        this.tableDataPHXQ = res.data.records
        this.paginationPHXQ.total = res.data.total
        this.columnFlag = res.data.isAuthTransfer

        if (this.columnFlag == 'Y') {
          this.phxqColumns = [
            { prop: 'batchId', label: '批号', width: 200 },
            { prop: 'entryName', label: '调拨区域', width: 200 },
            { prop: 'price', label: '调拨价格', width: 200 },
            { prop: 'expDate', label: '有效期至', width: 200 },
            { prop: 'goodsQty', label: '库存', width: 200 }
          ]

        } else {
          this.phxqColumns = [
            { prop: 'batchId', label: '批号', width: 200 },
            { prop: 'expDate', label: '有效期至', width: 200 },
            { prop: 'goodsQty', label: '库存', width: 200 }
          ]
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.tableLoading = false
      })
    },
    getCostPrice(costPrice, dsj) {
      if (!costPrice || costPrice == '0') {
        return dsj;
      }
      return costPrice;
    },
    // 每一页多少条
    handleSizeChange(val) {
      this.paginationPHXQ.pageSize = val
      this.getGoodsBatch()
    },
    // 当前第几页
    handleCurrentChange(val) {
      this.paginationPHXQ.pageNum = val
      this.getGoodsBatch()
    },
    toLeftMove() {
      if (this.nowImgIndex <= (this.minImgNum - 5) && this.nowImgIndex > 0) {
        this.nowImgIndex--;
        this.$refs.scorll_left.scrollLeft = this.nowImgIndex * this.eveyMenuWidth
      } else {
        this.nowImgIndex = 0;
        this.$refs.scorll_left.scrollLeft = 0;
      }

    },
    toRightMove() {
      if (this.nowImgIndex < (this.minImgNum - 5)) {
        this.nowImgIndex++;
        this.$refs.scorll_left.scrollLeft = this.nowImgIndex * this.eveyMenuWidth
      } else {
        this.nowImgIndex = this.minImgNum - 5;
        this.$refs.scorll_left.scrollLeft = this.nowImgIndex * this.eveyMenuWidth;
      }
    }
  },
  destroyed() {
    if (this.setIntervalObj) {
      clearInterval(this.setIntervalObj)
    }
  },
  mounted() {
    this.$refs.headerRight.style.height = this.$refs.headerLeft.height + 'px'
    this.isLogin = getToken()
  },
}
</script>

<style lang="scss" scoped>
.addition-dialog {
  h3 {
    background-color: #F1F7FE;
    line-height: 40px;
    font-weight: 600;
    color: #222;
    text-align: left;
    padding-left: 20px;
  }

  .additionalBox {
    height: 400px;
    width: 100%;

    .additional {
      display: flex;
      align-items: center;
      padding: 10px;
      margin: 0;
      width: 280px;
      float: left;
      box-sizing: border-box;

      &:nth-child(2n-1) {
        margin-left: 20px;
      }

      .imgBox {
        margin-right: 12px;

        img {
          display: block;
          width: 80px;
          height: 80px;
        }
      }

      .additionContent {
        text-align: left;
        width: 180px;
        overflow: hidden;

        >div {
          height: 16px;
          line-height: 16px;
        }

        .name {
          color: #222;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .manufacturer,
        .spec {
          font-size: 12px;
          color: #666666;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }

        .price {
          color: #E02034;
          font-weight: 600;
          font-size: 16px;

          span {
            text-decoration: line-through;
            font-size: 14px;
            font-weight: 400;
            color: #999;
            padding-left: 8px;
          }
        }
      }
    }
  }

  /deep/ {
    .el-dialog__body {
      padding: 0;
    }
  }
}

.aptitudes {
  line-height: 40px;
  font-size: 14px;
  color: #555;
  background-color: #FFF0EF;
  margin-bottom: 32px;
}

.childList {
  width: 250px;
  border: 1px solid whitesmoke;
  background: #fff;
  margin: 0 auto;

  .childListson {
    width: 50%;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 500;
    color: #222222;
    margin: 0;
    display: block;
    padding: 0 20px 0 20px;
    float: left;

    &:hover {
      color: #409eff;
    }

    a {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap
    }
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.main {
  background-color: #f5f5f5;

  .breadcrumb {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    padding-left: 20px;
    width: 1200px;
    margin: 0 auto;

    /deep/ .el-breadcrumb {
      display: inline-block;
      line-height: 50px;
      float: left;
    }

    .buy {
      float: right;

      img {
        margin-left: 5px;
        width: 16px;
        height: 16px;
        vertical-align: text-bottom;
      }
    }
  }

  .content {
    width: 1200px;
    margin: 0 auto;

    .detail-header {
      display: flex;

      .detail-header-left {
        display: flex;
        width: 1000px;
        margin: 0 10px 10px 0;
        padding: 0 20px 20px 0;
        background-color: #fff;

        .preview {
          position: relative;
          margin: 36px 24px;
          font-size: 14px;

          .min-img-box {
            display: flex;
            flex-wrap: no-wrap;

            .button-prev,
            .button-next {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20px;
              height: 60px;
              margin-top: 20px;
              font-weight: 900;
              font-size: 32px;
              cursor: pointer;

              i {
                color: #cccccc;
              }

              &:hover {
                i {
                  color: #999999;
                }
              }
            }

            .button-prev {
              margin-right: 10px;
            }
          }

          .magnifier-box {
            width: 360px;
            height: 360px;
          }

          .img-list {
            display: flex;
            flex-shrink: 0;
            overflow: hidden;
            margin-top: 20px;
            width: 350px;
            -webkit-transition: all 0.8s;
            transition: all 0.8s;

            li {
              -webkit-transition: all 0.8s;
              transition: all 0.8s;
            }

            .active img {
              border: 2px solid #42bc1c;
            }

            img {
              cursor: pointer;
              width: 60px;
              height: 60px;
              margin-right: 10px;
            }
          }

          .collect {
            margin-top: 8px;
            color: #666;
            text-align: left;
            cursor: pointer;

            &.active,
            &:hover {
              color: #ff6600;
            }
          }
        }

        .lastItem {
          margin: 10px;
        }

        .message {
          text-align: left;
          margin-left: 46px;
          width: 792px;

          .message-top {
            margin: 0 0 10px 20px;

            .mes-name {
              font-size: 24px;
              margin-top: 35px;
              font-weight: 600;
              line-height: 34px;

              b {
                padding: 3px 8px;
                background: #0091ff;
                border-radius: 4px;
                font-size: 15px;
                color: #fff;
                font-weight: 500;
                margin-right: 8px;
                vertical-align: bottom;

                &:last-child {
                  margin-right: 0;
                }
              }
            }

            .mes-subtitle {
              margin: 10px 0 17px;

              span {
                font-size: 12px;
                border-radius: 3px;
                padding: 2px 4px;
                margin-right: 4px;
              }

              .type1 {
                background: #ff6600;
                border: 1px solid #ff6600;
                color: #fff;
              }

              .type2 {
                border: 1px solid #025cd6;
                color: #025cd6;
              }

              .type3 {
                background: #fff;
                border: 1px solid #ff6600;
                color: #ff6600;
              }

              .type4 {
                background: #fff;
                border: 1px solid #33ae67;
                color: #33ae67;
              }
            }

            .mes-info-list {
              .mes-info-item {
                width: 50%;
                display: inline-block;
                font-size: 12px;
                margin-bottom: 4px;

                .mes-info-title {
                  display: inline-block;
                  color: #a3a3a3;
                  min-width: 5.6em;
                  line-height: 1.5;
                }

                .mes-info-content {
                  color: #717171;
                  display: inline-block;
                  width: 180px;
                  line-height: 1.5;
                  vertical-align: top;
                }
              }
            }
          }

          .message-discount {
            height: 36px;
            background-image: url("../../assets/img/detail/bg.png");
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .count-down {
              margin-right: 10px;
              display: inline-block;
              font-size: 14px;
              font-weight: bold;

              .count-down-text {
                color: #fff;
                padding-right: 8px;
                font-size: 12px;
              }

              .count-down-num {
                padding: 4px;
                margin-right: 2px;
                background-color: #222;
                color: #fff;
              }

              .count-down-point {
                color: #000;
                margin-right: 2px;
              }
            }
          }

          .price-num {
            background: #f5f5f5;
            padding: 0 20px;

            .price {
              padding-top: 22px;
              padding-bottom: 15px;

              .price-name {
                font-size: 12px;
                font-weight: 500;
                color: rgba(153, 153, 153, 1);
              }

              .price-wholesale {
                font-size: 16px;
                font-weight: 500;
                color: rgba(255, 82, 0, 1);
                padding-left: 23px;

                span {
                  font-size: 24px;
                }
              }

              .market {
                margin-left: 50px;

                .price-market {
                  font-size: 12px;
                  color: rgba(113, 113, 113, 1);
                  padding-left: 10px;
                }
              }
            }

            .num {
              padding: 12px 0;

              .num-list {
                margin-right: 34px;
                font-size: 12px;
                font-weight: 500;

                .num-lt {
                  color: #666666;
                }

                .num-rt {
                  color: #222222;
                  padding-left: 10px;
                }
              }
            }

            .promotion {
              display: flex;
              line-height: 28px;
              font-size: 12px;
              padding: 10px 0 15px;
              border-top: 1px solid #e5e5e5;

              .promotion-title {
                color: #666;
                display: flex;

                .promotion-list {
                  color: #222;

                  .promotion-item {
                    &>div {
                      min-width: 5em;
                      display: inline-block;
                    }

                    span {
                      color: #ff6600;
                      border-radius: 3px;
                      padding: 2px 4px;
                      border: 1px solid #ffa366;
                    }

                    b {
                      background-color: #ff6600;
                      border-radius: 3px;
                      color: #fff;
                      padding: 2px 4px;
                      border: 1px solid #ffa366;
                      margin-right: 8px;
                      display: inline-block;
                      line-height: 14px;
                    }

                    .reduce-box {
                      display: flex;

                      .reduce-tag {}

                      .reduce-text {
                        margin-left: 5px;
                        width: 200px;
                        /* 设置一个固定的宽度或者根据需求设置 */
                        white-space: nowrap;
                        /* 防止文本换行 */
                        overflow: hidden;
                        /* 隐藏超出容器的内容 */
                        text-overflow: ellipsis;
                        /* 使用省略号表示被隐藏的文本 */
                      }



                    }


                  }
                }
              }

              .promotion-more {
                color: #222;
                cursor: pointer;
                margin-left: 5px;
                font-weight: 600;
              }

              .coupon-dialog {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 16px;

                .flex {
                  display: flex;
                }

                .name {
                  color: #fff;
                  margin-right: 10px;
                  position: relative;
                  text-align: center;

                  .left-con,
                  .right-con {
                    width: 10px;
                    height: 10px;
                    background: #fff;
                    position: absolute;
                    border-radius: 50%;
                    top: 12px;
                    left: -5px;
                  }

                  .right-con {
                    left: auto;
                    right: -5px;
                  }

                  p {
                    padding: 2px 15px;
                    min-width: 80px;
                    background-color: #fa6400;
                    border-radius: 2px;
                  }
                }

                .text {
                  line-height: 1.3em;

                  .text_1 {
                    font-size: 14px;
                    font-weight: 600;
                    color: #222;
                  }

                  .text_2 {
                    font-size: 12px;
                    color: #999;
                  }
                }

                .button {
                  font-size: 12px;
                  white-space: nowrap;
                  line-height: 28px;
                  text-align: center;
                  width: 80px;

                  p {
                    color: #fa6400;
                    padding: 0 16px;
                    border: 1px solid #fa6400;
                    border-radius: 2px;
                    cursor: pointer;

                    &:hover {
                      color: #fff;
                      background: #fa6400;
                    }
                  }
                }

                .disabled {
                  p {
                    color: #999;
                    padding: 0 16px;
                    border: 1px solid #999;
                    border-radius: 2px;
                    cursor: default;

                    &:hover {
                      color: #999;
                      background: #fff;
                    }
                  }
                }

                .isReceive {
                  p {
                    cursor: default;

                    &:hover {
                      color: #fa6400;
                      background: #fff;
                    }
                  }
                }
              }

              .present-dialog {
                .name {
                  color: #222;
                  font-size: 14px;
                  font-weight: 600;
                }

                .img {
                  img {
                    width: 50px;
                    height: 50px;
                    margin-right: 12px;
                  }
                }
              }
            }
          }

          .message-bottom {
            margin-left: 20px;

            .buy-num {
              font-size: 12px;
              display: flex;
              line-height: 28px;
              margin-top: 20px;

              .promotion-title {
                color: #666;
                margin-right: 10px;
              }

              .stock {
                margin-left: 20px;
              }

              /deep/ {
                .el-input-number__decrease,
                .el-input-number__increase {
                  background-color: #fff;
                }

                .el-input--mini .el-input__inner {
                  font-size: 16px;
                }
              }
            }

            .add-button {
              margin-top: 20px;

              /deep/ .el-button {
                padding: 0;
                margin: 0 20px 0 0;
                width: 130px;
                font-size: 15px;
                height: 40px;
                line-height: 40px;

                &.el-button--info {
                  background-color: #c0c0c0;
                  border: 1px solid #c0c0c0;

                  &:hover {
                    background: #cfcfcf;
                    border: 1px solid #cfcfcf;
                  }
                }
              }

              .code-button {
                height: 40px;
                line-height: 40px;
                vertical-align: bottom;
                width: 160px;
                background: #ff6600;
                border: 1px solid #ff6600;

                &.is-disabled {
                  background: #999 !important;
                  color: #fff;
                  border: 0 !important;
                }

                /deep/ span {
                  color: #fff;

                  img {
                    vertical-align: text-top;
                    margin-right: 8px;
                  }
                }

                &:hover {
                  background: #ff7d18;
                }
              }
            }
          }

          .qualifications {
            padding: 16px;
            background-color: rgba(242, 242, 242, 1);
            font-size: 14px;
            color: #f59a23;
            margin-top: 16px;
            line-height: 20px;

            a {
              color: #0000ff;
              padding-left: 10px;
              font-size: 12px;
              font-weight: 400;
            }
          }

          .law {
            font-size: 12px;
            background: #f5f5f5;
            color: #666666;
            margin-top: 20px;
            padding: 16px 20px;
            display: flex;
            line-height: 1.5;

            .law-title {
              width: 6em;
            }
          }

          .flex {
            display: flex;
            align-items: center;
          }

          /deep/ .el-dialog {
            text-align: left;

            .el-dialog__body {
              padding-top: 20px;
            }
          }
        }
      }
    }

    .detail-header-right {
      width: 200px;
      min-width: 200px;
      background-color: #fff;
      height: fit-content;
      margin-bottom: 10px;
      min-height: 556px;

      .detail-header-right-text {
        display: flex;
        align-items: center;
        padding: 12px 0;
        border-bottom: 1px solid #eee;

        .title {
          color: #2b2b2b;
          margin-left: 20px;
        }

        .refresh {
          cursor: pointer;
          @include TextHighlightColor;
          font-size: 12px;
          margin-left: 36px;
        }
      }

      .detail-header-right-content {
        position: relative;

        p {
          font-size: 12px;
          color: #666;
          text-align: left;
          margin: 0 12px;
          line-height: 1.5;
        }

        .detail-header-right-img {
          img {
            cursor: pointer;
            margin-top: 12px;
            width: 140px;
            height: 140px;
            transition: transform 0.4s;
          }

          span {
            display: block;
            margin: 0 auto;
            width: 140px;
            height: 20px;
            line-height: 20px;
            position: relative;
            top: -20px;
            color: #666;
            font-size: 12px;
            opacity: 0;
            background: rgba(245, 245, 245, 0.8);
            transition: transform 0.4s, opacity 0.4s;
            z-index: 9;
          }

          &:hover {
            span {
              opacity: 1;
              transition: transform 0.4s, opacity 0.4s;
            }
          }
        }

        .name {
          color: #222;
          margin: 3px 12px 4px;
          height: 18px;
          overflow: hidden;
          cursor: pointer;
          font-weight: 600;
          font-size: 14px;
        }

        .price {
          color: #ff5200;
          font-size: 16px;
          padding: 4px 0 16px;
          border-bottom: 1px solid #eee;

          img {
            vertical-align: text-top;
            margin-right: 3px;
            cursor: pointer;
          }
        }

        &:last-child {
          .price {
            border-bottom: none;
          }
        }
      }
    }

    /deep/ .el-tabs {
      .el-tabs__header {
        margin: 0;
      }

      .el-tabs__nav-wrap::after {
        height: 1px;
        background-color: #eee;
      }

      .el-tabs__nav-scroll {
        padding: 0 20px;

        .el-tabs__item {
          font-size: 16px;
          height: 50px;
          line-height: 50px;
        }
      }
    }

    .detail-footer {
      display: flex;
      min-height: 1131px;

      .detail-header-right-text {
        height: 50px;
        line-height: 50px;
      }

      .detail-footer-tab {
        margin: 0 0 10px 10px;
        width: 1000px;
        background-color: #fff;
        position: relative;

        .detail-img-hr {
          height: 10px;
          background-color: #f5f5f5;
        }

        .evaluate {
          text-align: left;
          font-size: 14px;
          padding: 20px;
          line-height: 1.8;

          .item {
            padding: 20px;

            .headerT {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .name {
                color: #666666;
              }

              .time {
                color: #999999;
                padding-left: 40px;
              }

              .rate {
                display: inline-block;

                /deep/ .el-rate__icon {
                  font-size: 22px;
                }
              }
            }

            .text {
              color: #222222;
              padding-bottom: 12px;
            }

            .reply {
              color: #ff5200;
            }

            &:nth-of-type(even) {
              background-color: #f5f5f5;
            }
          }

          &.none {
            color: #717171;
            display: block;

            img {
              margin: 140px auto 0;
              display: block;
            }
          }
        }

        .first {
          .mes-info-list {
            text-align: left;
            background-color: #fff;
            padding: 20px;

            .mes-info-item {
              width: 33.3%;
              display: inline-block;
              font-size: 14px;

              .mes-info-title {
                display: inline-block;
                color: #717171;
                min-width: 6em;
                line-height: 2;
              }

              .mes-info-content {
                color: #222;
              }
            }
          }

          .detail-img {
            background-color: #fff;
            padding: 30px 20px;
            text-align: left;
          }
        }

        .second {
          font-size: 14px;
          color: #717171;
          padding: 30px 20px;
          text-align: left;
          background-color: #fff;
          line-height: 1.5;

          p {
            padding-bottom: 20px;
          }
        }

        .third {
          .header {
            display: flex;
            align-items: center;
            padding: 0 40px;
            height: 170px;
            color: #717171;
            font-size: 14px;

            .num {
              font-weight: 500;
              padding-right: 80px;
              line-height: 1.5;

              .percentage {
                color: #ff5200;
                font-size: 36px;
              }
            }

            .textPre {
              line-height: 2.2;
              margin-right: 12px;
            }

            .progress {
              width: 200px;
              text-align: left;

              /deep/ .el-progress {
                line-height: 2.2;

                .el-progress-bar__outer {
                  border-radius: 0;
                }

                .el-progress-bar__inner {
                  background-color: #ff5200;
                  border-radius: 0;
                }

                .el-progress__text {
                  margin-left: 15px;
                  font-size: 14px !important;
                }
              }
            }
          }
        }

        .fine {
          text-align: left;
          color: #222;
          padding: 30px;

          .download {
            display: flex;
            align-items: center;

            span {
              padding-right: 10px;
              font-size: 18px;
            }

            .button {
              cursor: pointer;
              color: #0971eb;
              font-size: 14px;
            }
          }

          .shop {
            margin: 20px 0;
          }
        }

        .table-PHXQ-box {
          padding: 30px;

          /deep/ .el-pagination {
            display: flex;
            margin: 10px 0 0;
            justify-content: flex-end;
          }
        }

        .empty-box {
          flex: 1;
          display: flex;
          justify-content: center;
          padding-top: 252px;

          .empty-tip {
            font-size: 16px;
            font-weight: 500;
            color: rgba(113, 113, 113, 1);
            padding-top: 10px;
            text-align: center;
          }
        }

        /deep/ .el-steps {
          margin-top: 30px;

          .el-step__title {
            font-size: 14px;
          }
        }
      }
    }
  }

  .identifying {
    position: absolute;
    top: -20px;
    left: 0px;
    width: 62px;
    height: 62px;
    z-index: 10;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .detail-header-right-content {
    .identifying {
      top: 10px;
      left: 10px;
      width: 32px;
      height: 32px;
    }
  }

  .jiaOtc {
    background: #E60012 !important;
  }

  .yiOtc {
    background: #33AE67 !important;
  }

  .rxOtc {
    background: #0091ff !important;
  }

  .detail-line-download {
    position: absolute;
    top: 8px;
    right: 16px;
  }
}
</style>

<style lang="scss">
.promotion {
  .el-dialog__body {
    max-height: 400px;
    overflow-y: auto;
  }
}
</style>

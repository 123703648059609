var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "drawerSearch" },
        [
          _c("el-input", {
            staticClass: "drawerInput",
            attrs: { placeholder: "输入商品名称/编码/厂家" },
            model: {
              value: _vm.searchForm.searchFiled,
              callback: function ($$v) {
                _vm.$set(_vm.searchForm, "searchFiled", $$v)
              },
              expression: "searchForm.searchFiled",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "but",
              attrs: { type: "primary", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.searchMode()
                },
              },
            },
            [_vm._v("搜索")]
          ),
          _c(
            "el-button",
            {
              staticClass: "but",
              staticStyle: { color: "#595959" },
              attrs: { type: "text", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.resetMode()
                },
              },
            },
            [_vm._v("重置")]
          ),
        ],
        1
      ),
      _c(
        "im-table-page",
        {
          ref: "tablePage",
          attrs: {
            data: _vm.tableData,
            index: "",
            "remote-method": _vm.remote,
          },
          on: {
            "update:data": function ($event) {
              _vm.tableData = $event
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "商品ID",
              prop: "productCode",
              width: "100",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "商品名称",
              prop: "drugNm",
              width: "130",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(_vm.getGoodsName(scope.row)) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "规格",
              prop: "spec",
              width: "100",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "单位",
              prop: "unit",
              width: "80",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "生产厂家",
              prop: "manufacturer",
              "min-width": "120",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "80", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-row",
                      { staticClass: "table-edit-row" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.chooseGoodsSucc(scope.row)
                              },
                            },
                          },
                          [_vm._v("选择")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
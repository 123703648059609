<template>
  <div class="im-page-modal">
    <div v-if="showHeader" class="header">
      <h3>{{ title }}</h3>
      <el-button size="small" @click="goBack" v-if='isShowBack'>{{backTitle}}</el-button>
    </div>
    <div class="content">
      <slot />
    </div>
    <div v-if="$scopedSlots.footer" class="footer">
      <slot name="footer" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'ImPageModal',
  props: {
    showHeader: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    isShowBack:{
      type: Boolean,
      default: true
    },
    backTitle:{
       type:String,
       default:'返回'
    }

  },
  data() {
    return {}
  },
  methods: {
    goBack() {
      this.$emit('handle-back')
    }
  }
}
</script>
<style lang="scss">
.im-page-modal {
  // position: absolute; //由于AddUpdate弹窗显示有问题，暂时取消绝对定位
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    line-height: 50px;
    padding: 0 16px;
    border-bottom:1px solid #e1e1e1;
    h3 {
      margin: 0;
      font-size: 16px;
      color: #262626;
      font-weight:bold;
    }
    .el-button {
      img{
        width: 15px;
        height: 15px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
      }
    }
  }
  .content {
    // height: calc(100vh - 220px);
    // overflow-y: auto;
    padding-top: 20px;
    background-color: #fff;
    flex: 1;
    overflow: auto;
  }
  .footer {
    background-color: #fff;
    height: 56px;
    border-top: 1px solid #FCFBFC;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top:1px solid #e5e5e5;
  }
  .el-tabs--left .el-tabs__header.is-left {
    margin-right: 16px;
  }
}
//不显示头部时，自定义样式
.custom-page-modal{
  border: 16px solid #F5F5F5;
  .content{
    padding: 0;
  }
}
</style>

import {ApiDelete, ApiGet, ApiPost} from "@/api";

/**
 * 获取某个商品详情
 */
export const getProductDetail = data => ApiGet('/pc/front/product/getProductDetail',data)

/**
 * 获取某个商品详情下优惠卷
 */
export const getCouponByProductId = data => ApiGet('/pc/front/product/getCouponByProductId',data)

/**
 * 获取某个商品详情下资质图片
 */
export const getLicensePicUrlList = data => ApiGet('/pc/front/product/getLicensePicUrlList',data)

/**
 * 获取某个商品详情下加价购
 */
export const getAdditionalActivityByProductId = data => ApiGet('/pc/front/product/getAdditionalActivityByProductId',data)

/**
 * 获取某个商品详情下特价采购
 */
export const getSpecialPricePurchaseByProductId = data => ApiGet('/pc/front/product/getSpecialPricePurchaseByProductId',data)

/**
 * 获取某个商品详情下特殊商品
 */
export const getPresentByProductId = data => ApiGet('/pc/front/product/getPresentByProductId',data)

/**
 * 获取满减
 */
export const getReduceByProductId = data => ApiGet('/pc/front/product/getFullReduceSendByProductId',data)

/**
 * 获取某个商品详情下套餐
 */
export const getDiscountPackageByProductId = data => ApiGet('/pc/front/product/getDiscountPackageByProductId',data)

/**
 * 普通商品-加入购物车
 */
export const addItem = data => ApiPost('/pc/member/normalShoppingFlow/addItem',data,{
  ignoreMsg: true
})

/**
 * 普通商品-批量加入购物车
 */
export const batchAddItem = data => ApiPost('/pc/member/normalShoppingFlow/batchAddItem',data)

/**
 * 保存或修改商品收藏
 */
export const saveOrUpdateProductCollect = data => ApiPost('/pc/productCollect/saveOrUpdateProductCollect',data)

/**
 * 删除商品收藏
 */
export const delProductCollectById = productCollectId => ApiDelete('/pc/productCollect/delProductCollectById', {
  productCollectId
})

/**
 * 获取浏览记录
 */
export const getBrowsingHistory = org => ApiGet('/product/front/getBrowsingHistory', {
  org
})

/**
 * 保存浏览记录
 */
export const saveBrowsingHistory = ({productId,org}) => ApiGet('/product/front/saveBrowsingHistory', {
  productId,
  org
})

/**
 * 获取同类商品
 */
export const listRecommendProduct = productId => ApiGet('/pc/front/product/listRecommendProduct', {
  productId
})

/**
 * 批量下载文件zip
 */
export const downloadZipFiles = data => ApiPost('/fileService/fileLib/upload/downloadZipFiles',data,{
  responseType: 'blob',
},false)

/**
 * 获取退款流水详情
 */
export const findRefundDetail = data => ApiGet('/pc/order/findRefundDetail',data)

/**
 * 调整细单单价
 * @param data
 * @returns {*}
 */
export const updateOrderItemSalePrice = data => ApiPost('/pc/order/updateOrderItemSalePrice',data)

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "quotation" },
    [
      _c(
        "search-pad",
        {
          staticClass: "client-file-search-pad",
          attrs: {
            model: _vm.searchForm,
            "need-other-btn": false,
            "has-expand": false,
          },
          on: { search: _vm.handleSearch, reset: _vm.handleReset },
        },
        [
          _c(
            "search-pad-item",
            { attrs: { prop: "erpCode", label: "客户编码" } },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "输入客户编码" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.handleSearch.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.searchForm.buyersNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "buyersNo", $$v)
                  },
                  expression: "searchForm.buyersNo",
                },
              }),
            ],
            1
          ),
          _c(
            "search-pad-item",
            { attrs: { prop: "buyerNm", label: "客户名称" } },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "输入客户名称" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.handleSearch.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.searchForm.buyersNm,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "buyersNm", $$v)
                  },
                  expression: "searchForm.buyersNm",
                },
              }),
            ],
            1
          ),
          _c(
            "search-pad-item",
            { attrs: { prop: "productCode", label: "商品编码" } },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "输入商品编码" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.handleSearch.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.searchForm.productCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "productCode", $$v)
                  },
                  expression: "searchForm.productCode",
                },
              }),
            ],
            1
          ),
          _c(
            "search-pad-item",
            { attrs: { prop: "productNm", label: "商品名称" } },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "输入商品名称" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.handleSearch.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.searchForm.productNm,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "productNm", $$v)
                  },
                  expression: "searchForm.productNm",
                },
              }),
            ],
            1
          ),
          _c(
            "search-pad-item",
            { attrs: { prop: "manufacturer", label: "厂家产地" } },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "输入厂家产地" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.handleSearch.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.searchForm.manufacturer,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "manufacturer", $$v)
                  },
                  expression: "searchForm.manufacturer",
                },
              }),
            ],
            1
          ),
          _c(
            "search-pad-item",
            { attrs: { prop: "dealTime", label: "更新时间" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "240px" },
                attrs: {
                  size: "small",
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.searchForm.updateTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "updateTime", $$v)
                  },
                  expression: "searchForm.updateTime",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "handleBtn" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.onAdd },
            },
            [_vm._v("新增")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                size: "mini",
                type: "primary",
                disabled: !_vm.selectItem.length,
              },
              on: { click: _vm.onDelete },
            },
            [_vm._v("批量删除")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tab" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleTab },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "全部", name: "" } }),
              _c("el-tab-pane", {
                attrs: { label: "待审核", name: "WAIT_APPROVE" },
              }),
              _c("el-tab-pane", {
                attrs: { label: "已审核", name: "APPROVE_SUCCESS" },
              }),
              _c("el-tab-pane", {
                attrs: { label: "已拒绝", name: "APPROVE_FAILURE" },
              }),
              _c("el-tab-pane", {
                attrs: { label: "已失效", name: "APPROVE_INVALID" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "list" },
        [
          _c(
            "im-table-page",
            {
              ref: "tablePage",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "remote-method": _vm.queryData,
                addQuantity: true,
                index: true,
                "more-check-box": (val) => {
                  _vm.selectItem = val
                },
                "header-row-class-name": "header-row",
              },
              on: {
                "update:data": function ($event) {
                  _vm.tableData = $event
                },
                updated: _vm.updated,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  label: "选择",
                  align: "center",
                  fixed: "left",
                  width: "50",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "code",
                  label: "报价单编码",
                  width: "100px",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "buyersNo",
                  align: "left",
                  label: "客户编码",
                  "show-overflow-tooltip": "",
                  width: "100px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "buyersNm",
                  align: "left",
                  label: "客户名称",
                  width: "200px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "productCode",
                  align: "left",
                  label: "商品编码",
                  "show-overflow-tooltip": "",
                  width: "100px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "productNm",
                  align: "left",
                  label: "商品名称",
                  width: "230px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.$util.getGoodsName(scope.row)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "spec",
                  align: "left",
                  label: "规格",
                  "min-width": "130px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "unit",
                  align: "left",
                  label: "单位",
                  "show-overflow-tooltip": "",
                  width: "56px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "manufacturer",
                  align: "left",
                  label: "厂家(上市许可持有人)/产地",
                  width: "250px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.manufacturer) +
                            _vm._s(scope.row.area ? "/" + scope.row.area : "") +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "price",
                  align: "right",
                  label: "新增报价",
                  "show-overflow-tooltip": "",
                  width: "100px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticStyle: { color: "#F59A23" } }, [
                          _vm._v(_vm._s(_vm.$util.toFixed(scope.row.price))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "originalUnitPrice",
                  align: "right",
                  label: "当前电商价",
                  width: "100px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticStyle: { color: "#F59A23" } }, [
                          _vm._v(
                            _vm._s(_vm.$util.toFixed(_vm.getPrice(scope.row)))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  align: "center",
                  label: "报价时间",
                  width: "100px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "approveStat",
                  fixed: "right",
                  align: "center",
                  label: "报价单状态",
                  width: "100px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.getApproveStat(scope.row.approveStat)
                            ),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  align: "center",
                  width: "200",
                  label: "操作",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isStop === "N" &&
                        scope.row.approveStat === "APPROVE_SUCCESS"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.invalidData(scope.row)
                                  },
                                },
                              },
                              [_vm._v("作废")]
                            )
                          : _vm._e(),
                        scope.row.approveStat === "APPROVE_FAILURE"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.reExamineData(scope.row)
                                  },
                                },
                              },
                              [_vm._v("申请复审")]
                            )
                          : _vm._e(),
                        scope.row.isStop === "Y" &&
                        scope.row.approveStat === "APPROVE_INVALID"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openData(scope.row)
                                  },
                                },
                              },
                              [_vm._v("启用")]
                            )
                          : _vm._e(),
                        scope.row.approveStat !== "APPROVE_INVALID"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onEdit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteItem(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="info-channel-content">
    <Search :index-nav="true" />
    <div class="channel-banner">
      <div class="banner-bg"></div>
      <p>资讯中心</p>
    </div>
    <div class="channel-nav">
      <div class="nav-odei">
        <div class="nav-list" v-for="(item, index) in navList" :class="{'active': index === navIndex}" @click="handleEdit(item.infArticleCategoryId, index, item.categoryNm)">{{ item.categoryNm }}</div>
        <div class="nav-expend">
          <span @click.stop="isExpend = !isExpend" :class="{'arrow-open': !isExpend, 'arrow-away': isExpend }">{{ !isExpend ? '展开' : '收起' }}</span>
          <div @click.stop="isExpend = true" class="expend-content" :class="{ 'none-expend': !isExpend }">
            <div v-if="expendList.length > 0" style="display: flex; flex-flow: wrap;">
              <div class="expend-list" :class="{ 'active': (index+5) === navIndex }" v-for="(item, index) in expendList" @click="handleEdit(item.infArticleCategoryId, index+5)">{{ item.categoryNm }}</div>
            </div>
            <div class="none-more" v-else>
              暂无更多
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="channel-content">
      <div class="channel-odes">
        <div class="channel-list" v-for="item in contentList">
          <div class="info-name" @click="checkDetail(item)">{{ item.title }}</div>
          <div class="info-introduce" v-if="item.chainOrNot !== 'Y'">{{ item.articleContStr }}</div>
          <div class="info-bot">
            <span>发布时间：{{ item.createTime }}</span>
          </div>
        </div>
      </div>
      <empty-box v-if="contentList.length === 0" style="padding-bottom:80px;" />
      <div class="channel-pagination">
        <el-pagination
          :hide-on-single-page="true"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="5"
          layout="total, prev, pager, next, jumper"
          background
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Search from '@/components/Search/Search'
import { getInfArticleCategoryTree, pageInfArticle } from '@/api/promotion-zone/info-channel'
import emptyBox from '@/components/tableEmptyDefault'
import {getBus} from "@/utils/auth";
import { addInfArticleViews } from '@/api/index/index'
export default {
  components: {
    Search,
    emptyBox
  },
  data() {
    return {
      isExpend: false,
      total: 0,
      currentPage: 1,
      pageSize: 5,
      isShowMainInfo: true,
      navList: [],
      expendList: [],
      navIndex: 0,
      contentList: [],
      infArticleCategoryId: '',
      categoryNm: ''
    }
  },
  mounted() {
    document.addEventListener('click', () => {
      this.isExpend = false
    })
    getInfArticleCategoryTree(getBus().orgId).then(res => {
      this.expendList = res.data[0].subcategory.splice(5)
      this.navList = res.data[0].subcategory
      const categoryNm = this.$route.params.categoryNm
      let isId = false
      let indexId = 0
      res.data[0].subcategory.map((val, index) => {
        if (categoryNm && val.categoryNm === categoryNm) {
          isId = true
          indexId = index
        }
      })
      if (isId) {
        this.handleEdit(res.data[0].subcategory[indexId].infArticleCategoryId, indexId, res.data[0].subcategory[indexId].categoryNm)
      } else {
        this.handleEdit(res.data[0].subcategory[0].infArticleCategoryId, 0, res.data[0].subcategory[0].categoryNm)
      }
    })
  },
  methods: {
    handleCurrentChange(val) {
      pageInfArticle({
        pageSize: 5,
        pageNum: val,
        condition: {
          infArticleCategoryId: this.infArticleCategoryId
        }
      }).then(res => {
        this.total = res.data.total
        res.data.records.map(val => {
          let str = ''
          str = val.articleCont.replace(/<[^>]*>|&nbsp;|/g,"");
          val.articleContStr = str
        })
        this.contentList = res.data.records
      })
    },
    checkDetail(item) {
      addInfArticleViews(item.infArticleId)
      if (item.chainOrNot === 'Y') {
        let url = /^(http|https)/.test(item.externalUrlAddress) ? item.externalUrlAddress : 'http://' + item.externalUrlAddress
        window.open(url, '_blank')
        return ''
      }
      if (item.isShowNewWindow === 'Y') {
        const { href } = this.$router.resolve({
          path: '/info-detail/' + item.infArticleId,
          query: { name: this.categoryNm }
        })
        window.open(href, '_blank')
      } else {
        this.$router.push({path: `/info-detail/${item.infArticleId}`, query: { name: this.categoryNm }})
      }
    },
    handleEdit(id, index, categoryNm) {
      this.categoryNm = categoryNm
      this.navIndex = index ? index : 0
      this.infArticleCategoryId = id
      pageInfArticle({
        pageSize: 5,
        pageNum: this.currentPage,
        condition: {
          infArticleCategoryId: id
        }
      }).then(res => {
        this.total = res.data.total
        res.data.records.map(val => {
          let str = ''
          str = val.articleCont.replace(/<[^>]*>|&nbsp;|/g,"");
          val.articleContStr = str
        })
        this.contentList = res.data.records
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.info-channel-content{
  background: #F5F5F5;
  padding-bottom: 40px;
  .channel-banner{
    position: relative;
    min-width: 1200px;
    .banner-bg{
      min-width: 1200px;
      height: 320px;
      background: url("../../../assets/img/promotion-zone/info-channel-banner.png") no-repeat center;
      background-size: cover;
    }
    p{
      position: absolute;
      font-size:100px;
      font-weight:800;
      color:rgba(255,255,255,1);
      top: 75px;
      left: 0;
      right: 0;
    }
  }
  .channel-nav{
    width: 1200px;
    margin: -20px auto 0;
    height:48px;
    position: relative;
    .nav-odei{
      background:rgba(255,255,255,1);
      box-shadow:0px 1px 0px 0px rgba(229,229,229,1);
      border-radius:4px 0 0 4px;
      display: flex;
      height:48px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      .nav-list{
        width:220px;
        line-height: 48px;
        font-size:18px;
        font-weight:400;
        color:rgba(34,34,34,1);
        cursor: pointer;
        &:first-child {
          border-radius:4px 0 0 4px;
        }
        &.active{
          background: $titleBg1;
          font-weight:500;
          color:rgba(255,255,255,1);
        }
        &:hover{
          background: $hoverBg1;
          font-weight:500;
          color:rgba(255,255,255,1);
        }
      }
      .nav-expend{
        width: 100px;
        line-height: 48px;
        font-size:18px;
        font-weight:400;
        color:rgba(34,34,34,1);
        position: relative;
        span{
          position: relative;
          cursor: pointer;
          padding-right: 10px;
          &:after{
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            border-top: 1px solid #222222;
            border-right: 1px solid #222222;
            top: 1px;
            right: -12px;
            transform: rotate(135deg);
            transition: all .3s ease-out;
            z-index: 3;
          }
        }
        .arrow-open{
          &:after{
            transform: rotate(135deg);
          }
        }
        .arrow-away{
          &:after{
            top: 7px;
            transform: rotate(315deg);
          }
        }
        .expend-content{
          width: 400px;
          display: flex;
          flex-flow: wrap;
          padding: 12px 10px 24px 40px;
          position: absolute;
          top: 49px;
          right: 0;
          background: #fff;
          z-index: 11;
          box-shadow:0px 8px 12px 0px rgba(0,0,0,0.08);
          animation: myfirst .5s;
          .expend-list{
            font-size:18px;
            font-weight:400;
            color:rgba(34,34,34,1);
            margin-right: 30px;
            margin-top: 12px;
            line-height:25px;
            cursor: pointer;
            &:nth-child(3n-2) {
              margin-left: 0;
            }
            &:hover{
              color: $hoverBg1;
            }
            &.active{
              color: $titleBg1;
            }
          }
          .none-more{
            line-height: 20px;
            padding-top: 12px;
            display: block;
            text-align: center;
            width: 100%;
            color: #b5b5b5;
            padding-right: 30px;
          }
        }
        .none-expend{
          opacity: 0;
          display: none;
        }
        @keyframes myfirst
        {
          from {opacity: 0;}
          to {display: none}
        }
      }
    }
  }
  .channel-content{
    width: 1200px;
    margin: 0 auto;
    background: #fff;
    .channel-odes{
      text-align: left;
      .channel-list{
        padding: 44px 48px;
        background:rgba(255,255,255,1);
        border-bottom: 1px #e5e5e5 solid;
        &:hover{
          background: $hoverBg;
        }
        .info-name{
          font-size:24px;
          font-weight:400;
          color:rgba(34,34,34,1);
          cursor: pointer;
          display: inline-block;
        }
        .info-introduce{
          font-size:14px;
          font-weight:400;
          color:rgba(102,102,102,1);
          line-height: 26px;
          padding-top: 10px;
          //text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          //text-overflow: ellipsis;
          //display: -webkit-box;
          //-webkit-line-clamp: 2;
          //-webkit-box-orient: vertical;
          max-height: 60px;
          //display: -webkit-box;
          //-webkit-box-orient: vertical;
          //-webkit-line-clamp: 2;
          //overflow: hidden;
        }
        .info-bot{
          font-size:12px;
          font-weight:500;
          color:rgba(153,153,153,1);
          padding-top: 20px;
          span{
            padding-right: 25px;
          }
        }
      }
    }
    .channel-pagination{
      padding: 20px 0;
    }
  }
}
</style>

<style lang="scss">
.info-channel-content{
  .channel-content{
    .channel-odes{
      .info-introduce{
        img{
          max-width: 100%;
          height: 0;
        }
      }
    }
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "im-page-modal",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "consult-price-drafts-update",
      attrs: { isShowBack: false, title: "编辑询价单" },
    },
    [
      _c(
        "div",
        { staticClass: "page-header-btn", staticStyle: { float: "right" } },
        [
          _c(
            "div",
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.exportExcel } },
                [_vm._v("导出询价单")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.goBack(false)
                    },
                  },
                },
                [_vm._v("我的草稿箱")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "im-table-page",
        {
          ref: "tablePage",
          attrs: {
            data: _vm.tableData,
            "more-check-box": _vm.moreCheckBox,
            "remote-method": _vm.queryData,
            "default-expand-all": "",
            border: "",
            "row-key": "skuId",
            "tree-props": {
              children: "otherSkulist",
              hasChildren: "hasChildren",
            },
          },
          on: {
            "update:data": function ($event) {
              _vm.tableData = $event
            },
            updated: _vm.updated,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "序号",
              width: "80px",
              "show-overflow-tooltip": "",
              align: "center",
              fixed: "left",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.index || scope.row.index === 0
                      ? _c("span", [_vm._v(_vm._s(scope.row.index + 1))])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "商品编码",
              prop: "productCode",
              "show-overflow-tooltip": "",
              width: "180px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.productCode) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "商品名称",
              "min-width": "180px",
              prop: "productNm",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.index || scope.row.index === 0
                      ? _c("fast-order-search", {
                          ref: "productNm" + scope.$index,
                          staticClass: "inputName",
                          attrs: {
                            index: scope.row.index,
                            id: "search" + scope.$index,
                            productNm: _vm.$util.getGoodsName(scope.row),
                          },
                          on: {
                            getIndex: _vm.getIndex,
                            fast_order_fu: _vm.selectRow,
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                $event.keyCode !== 46
                              )
                                return null
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.handleDelete(scope.row, scope.$index)
                            },
                          },
                        })
                      : _c("span", [
                          _vm._v(
                            _vm._s(scope.row.drugNm) +
                              "(" +
                              _vm._s(scope.row.productNm) +
                              ")"
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "规格",
              "min-width": "100px",
              prop: "spec",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.spec) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "单位",
              "min-width": "60px",
              prop: "unit",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.unit) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "区域",
              prop: "orgNm",
              "min-width": "170px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "生产厂家",
              "min-width": "180px",
              prop: "manufacturer",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.manufacturer) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "件包装",
              "min-width": "100px",
              prop: "useCommodityTypes",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.packTotal || "--"))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "最小购买倍数",
              "min-width": "120px",
              prop: "minBuyMultiple",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(_vm.getRealMinBuyNum(scope.row)) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "库存",
              "min-width": "60px",
              prop: "stockQuantity",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.userType === "BUYERS"
                            ? scope.row.stockQuantity > 100
                              ? ">100"
                              : "<100"
                            : scope.row.stockQuantity
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            staticStyle: { "text-align": "right" },
            attrs: {
              label: "单价",
              align: "right",
              "min-width": "100px",
              prop: "price",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticStyle: { "text-align": "right" } }, [
                      _vm._v(_vm._s(scope.row.price)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { fixed: "right", label: "操作", width: "70px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-row", { staticClass: "table-edit-row" }, [
                      _c(
                        "span",
                        { staticClass: "table-edit-row-item" },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { padding: "2px 4px" },
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteNode(scope.row)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "addPro",
          on: {
            click: function ($event) {
              return _vm.addPro()
            },
          },
        },
        [_vm._v(" +添加 ")]
      ),
      _c(
        "div",
        {
          staticClass: "content-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("div", { staticClass: "left-con" }),
          _c(
            "div",
            { staticClass: "right-con" },
            [
              _c(
                "el-button",
                { staticClass: "btn-one", on: { click: _vm.handleAddDraft } },
                [_vm._v("保存草稿")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
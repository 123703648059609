import { render, staticRenderFns } from "./collate-order.vue?vue&type=template&id=260828b2&scoped=true"
import script from "./collate-order.vue?vue&type=script&lang=js"
export * from "./collate-order.vue?vue&type=script&lang=js"
import style0 from "./collate-order.vue?vue&type=style&index=0&id=260828b2&scoped=true&lang=scss"
import style1 from "./collate-order.vue?vue&type=style&index=1&id=260828b2&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "260828b2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/jobs/pre-pc-web/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('260828b2')) {
      api.createRecord('260828b2', component.options)
    } else {
      api.reload('260828b2', component.options)
    }
    module.hot.accept("./collate-order.vue?vue&type=template&id=260828b2&scoped=true", function () {
      api.rerender('260828b2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/shopping-cart/components/collate-order.vue"
export default component.exports
<template>
  <im-drawer
    class="input-excel-drawer-page"
    v-if="consultPriceDraftVisible"
    :visible.sync="consultPriceDraftVisible"
    title="保存询价单"
    :size="size"
    :modal="false"
    :append-to-body="true"
    @confirm="handleconfirm"
    @cancel="closeConfirm"
  >
    <el-form ref="form" :model="form" :rules="formRules" label-width="160px" class="form">
        <el-form-item label="询价单标题：" prop="title">
           <el-input style="width:300px;height;32px" v-model="form.title" palceholder="请为该询价单填写标题"></el-input>
        </el-form-item>
    </el-form>
  </im-drawer>
</template>
<script>
import formRules from '@/utils/validate'
export default {
  name: 'InputExcelDrawer',
  props: {
    consultTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      consultPriceDraftVisible: false,
      size: '30%',
      form:{
         title:''
      },
      formRules:{
         title: [formRules.blurRes('询价草稿标题')],
      }
     }
  },
  watch:{
  },
  methods: {
    open() {
      this.consultPriceDraftVisible = true
    },
    validate(){
      var isPass = true
      this.$refs.form.validate(valid => {
            if (!valid) isPass = false
      })
      return isPass
    },
    // 绑定
    handleconfirm() {
        if(this.validate()){
          this.$emit('update:consultTitle',this.form.title)
          this.$emit('handleSaveDraft')
          this.consultPriceDraftVisible = false
        }
       
    },
    closeConfirm() {
      this.consultPriceDraftVisible = false
    }
  }
}
</script>
<style lang="scss">
.input-excel-drawer-page{
    .el-form{
        .el-form-item__content{
            text-align:left;
        }
       .el-radio{
           display:inline-block;
           height:40px;
           line-height:40px;
       }
    }
    
}
</style>
<style scoped lang="scss">
 .form{
     padding:16px;
     .el-radio{
         display:inline-block;
         height:100%;
     }
     .btn-download{
         border: 1px solid #1890ff;
         padding:3px 5px;
     }
 }
</style>

<template>
  <div class="my-integral-content">
    <div class="surplus-integral">
      可用积分<span>{{ userDetail.memberDetailsVo.integral || 0 }}</span>
    </div>
    <div class="inte-content">
      <el-tabs v-model="activeName" @tab-click="handleClick" class="use-tab">
        <el-tab-pane label="全部" name="全部">
        <span slot="label">
          全部
       </span>
        </el-tab-pane>
        <el-tab-pane label="收入" name="ADD">
        <span slot="label">
          到账积分
        </span>
        </el-tab-pane>
        <el-tab-pane label="支出" name="SUBTRACT">
        <span slot="label">
          消费积分
        </span>
        </el-tab-pane>
      </el-tabs>
      <el-radio-group v-model="searchForm.isThreeMonth" size="mini" @change="handleTime">
        <el-radio-button label="Y">近三个月内明细</el-radio-button>
        <el-radio-button label="N">三个月前明细</el-radio-button>
      </el-radio-group>
      <div class="tab-content">
        <im-table-page
          ref="tablePage"
          :data.sync="tableData"
          :remote-method="queryData"
          index
        >
          <el-table-column label="日期" width="220px" prop="operationTime" />
          <el-table-column v-if="activeName === '全部'" label="收入/支出" width="170px">
            <template slot-scope="scope">
              <span v-if="scope.row.operation == 'ADD'" class="income">+{{ scope.row.originalIntegral }}</span>
              <span v-else class="expend">-{{ scope.row.originalIntegral }}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="activeName === 'ADD'" label="收入" width="170px">
            <template slot-scope="scope">
              <span class="income">+{{ scope.row.originalIntegral }}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="activeName === 'SUBTRACT'" label="支出" width="170px">
            <template slot-scope="scope">
              <span class="expend">-{{ scope.row.originalIntegral }}</span>
            </template>
          </el-table-column>
          <el-table-column label="剩余积分" prop="integralAfterChange" width="170px" />
          <el-table-column label="详细说明" prop="operationExplain" min-width="220px" show-overflow-tooltip />
        </im-table-page>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserIntegral, pageUserIntegralLog } from '@/api/member-center/my-integral'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      loading: false,
      activeName: '全部',
      tableData: [],
      searchForm: {
        operation: '全部',
        isThreeMonth: 'Y'
      },
      availableIntegral: ''
    }
  },
  computed:{
      ...mapGetters(['userDetail'])
  },
  mounted() {
    this.$store.dispatch('user/getInfo'); 

    this.$emit('handle-change-page', {
      text: '我的积分',
      index: '4-2',
      url: '/member-center/property-manage/my-integral',
    })
  },
  methods: {
    handleClick(val) {
      this.searchForm.operation = val.name
      this.$refs.tablePage.doRefresh({currentPage: 0, pageSize: 10})
    },
    handleTime() {
      this.$refs.tablePage.doRefresh({currentPage: 0, pageSize: 10})
    },
    handlePagination(data) {
      this.currentPage = Math.min(this.currentPage, Math.ceil(this.total / data.pageSize))
      this.queryData()
    },
    queryData(data) {
      const params = {
        isThreeMonth: this.searchForm.isThreeMonth,
        operation: this.searchForm.operation === '全部' ? '' : this.searchForm.operation
      }
      return pageUserIntegralLog({
        ...data,
        condition: params
      })
    },
  }
}
</script>
<style lang="scss">
.my-integral-content {
    background:white;
  .header {
    border-bottom: none
  }

  .content {
    padding: 0 16px;

  }

  .el-tabs__nav-wrap::after {
    display: none
  }
  .el-radio-group{
    margin-left: 8px;
  }
}
</style>
<style scoped lang="scss">
.my-integral-content {
  text-align: left;
  background:white;
  height:100%;
  .surplus-integral {
    background: #fff;
    height: 90px;
    line-height: 90px;
    font-size:14px;
    font-weight:400;
    color:rgba(34,34,34,1);
    padding: 0 18px;
    text-align: left;
    border-bottom: 8px solid #f5f5f5;
    span{
      font-size:32px;
      font-weight:bold;
      color:rgba(34,34,34,1);
      padding-left: 4px;
    }
  }
  .inte-content{
    background: #fff;
    padding-bottom: 40px;

    .use-tab {
      display: flex;
      align-items: center;
      padding: 0 16px;
      height: 52px;
    }
    .tab-content{
      margin-top: 12px;
      padding: 0 8px;
      .income{
        color: #FA6400;
      }
      .expend{
        color: #379E22;
      }
    }
  }
  .im-table-page .im-pagination{
    padding: 0;
    margin: 0;
    border: 0;
  }
}
</style>

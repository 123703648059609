import {batchUploadFile, getReturnedOrderReason} from "@/api/member-center/refunds";
export default {
    data(){
        return {
            reasonList:[], //退货原因列表
            isUploading: false, //是否正在上传图片
            fileList:[],
            newFile: new FormData(),
            imgAcceptList: ['image/png','image/jpeg','image/jpg','jpg','png','jpeg']
        }
    },
    created() {
        this.getReason()
    },
    methods:{
        //删除图片
        handleRemove(file) {
            this.fileList = this.fileList.filter(item => item.uid !== file.uid)
        },
        //上传前
        beforeUpload(file) {
            let nameArr = file.name.split('.')
            if(!this.imgAcceptList.includes(nameArr[nameArr.length - 1])){
                this.$message.error('请上传jpeg/jpg/png格式的图片')
                return false
            }
            if(this.fileList.length === 5){
                return false
            }
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                this.$message.error('您上传的图片超大，请重新上传新的图片');
                return false
            }
            this.newFile = new FormData();
            this.newFile.append("files", file);
        },
        //上传图片
        Upload(file) {
            this.isUploading = true
            batchUploadFile(this.newFile).then(res => {
                if(res.code === '0'){
                    res.data.uid = file.file.uid
                    this.fileList.push(res.data)
                }
                this.isUploading = false
            }).catch(err=>{
                this.isUploading = false
            })
        },
        //获取退货原因label
        getReasonLabel(val,a){
            let reason = this.reasonList.find(item => item.value === val)
            return reason ? reason.label : ''
        },
        //获取退货原因列表
        getReason(){
            getReturnedOrderReason().then(res=>{
                if(res.data){
                    this.reasonList = JSON.parse(res.data) || []
                    this.reasonList = this.reasonList.map(item=>{
                        return {
                            label: item.name,
                            value: item.code
                        }
                    })
                    console.log(this.reasonList)
                }
            })
        }
    }
}
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.addCustomerVisible
    ? _c(
        "im-drawer",
        {
          attrs: {
            visible: _vm.addCustomerVisible,
            title: _vm.title,
            size: "50%",
            modal: false,
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addCustomerVisible = $event
            },
            cancel: _vm.closeConfirm,
          },
        },
        [
          _c(
            "div",
            { staticClass: "section-container" },
            [
              _c(
                "im-search-pad",
                {
                  staticClass: "search-content",
                  attrs: { "has-expand": false },
                  on: { search: _vm.handleSearch, reset: _vm.handleReset },
                },
                [
                  _c(
                    "im-search-pad-item",
                    { attrs: { prop: "buyersNo" } },
                    [
                      _c("el-input", {
                        staticClass: "search-name",
                        staticStyle: { width: "220px" },
                        attrs: { size: "small", placeholder: "输入客户编码" },
                        model: {
                          value: _vm.buyersNo,
                          callback: function ($$v) {
                            _vm.buyersNo = $$v
                          },
                          expression: "buyersNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "im-search-pad-item",
                    { attrs: { prop: "buyersNo" } },
                    [
                      _c("el-input", {
                        staticClass: "search-name",
                        staticStyle: { width: "220px" },
                        attrs: { size: "small", placeholder: "输入客户名称" },
                        model: {
                          value: _vm.buyersNm,
                          callback: function ($$v) {
                            _vm.buyersNm = $$v
                          },
                          expression: "buyersNm",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "im-table-page",
                {
                  ref: "tablePageAdd",
                  attrs: {
                    data: _vm.tableData,
                    "remote-method": _vm.queryData,
                  },
                  on: {
                    "update:data": function ($event) {
                      _vm.tableData = $event
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      label: "序号",
                      width: "60",
                      fixed: "left",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "分支机构",
                      prop: "ettleAccountNm",
                      "min-width": "160px",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "客户名称",
                      prop: "buyersNm",
                      "min-width": "200px",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "联系手机",
                      prop: "contactMoblie",
                      width: "120px",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "所在地区",
                      prop: "region",
                      "min-width": "240px",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(scope.row.province) +
                                    "-" +
                                    _vm._s(scope.row.city) +
                                    "-" +
                                    _vm._s(scope.row.district)
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2453368733
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "客户类型",
                      prop: "customerTypeName",
                      width: "120px",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "55px",
                      fixed: "right",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-row", { staticClass: "table-edit-row" }, [
                                _c(
                                  "span",
                                  { staticClass: "table-edit-row-item" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "choose-one",
                                        staticStyle: {
                                          padding: "2px 4px",
                                          border: "0px",
                                        },
                                        attrs: { size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleChoose(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("选择")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3219137658
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
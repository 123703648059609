var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("Search", { attrs: { "hidden-nav": true } }),
      _c(
        "div",
        { staticClass: "breadcrumb" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { "separator-class": "el-icon-arrow-right" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页"),
              ]),
              _c(
                "el-breadcrumb-item",
                { attrs: { to: { path: "/member-center" } } },
                [_vm._v("会员中心")]
              ),
              _c(
                "el-breadcrumb-item",
                {
                  attrs: {
                    to: { path: "/member-center/order-manage/store-order" },
                  },
                },
                [_vm._v("我的订单")]
              ),
              _c("el-breadcrumb-item", [_vm._v("订单详情")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "line" }),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("h4", { staticStyle: { "margin-bottom": "4px" } }, [
              _vm._v(_vm._s(_vm.orderVo.orderStatus)),
            ]),
            _vm.overtime &&
            _vm.orderVo.processStatCode === "WAIT_PAY" &&
            _vm.orderVo.paymentWayCode === "CASH" &&
            _vm.orderVo.isPayed === "N"
              ? _c("p", [
                  _vm._v("剩余付款时间 "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.countdown.dd) +
                        "天 " +
                        _vm._s(_vm.countdown.hh) +
                        ":" +
                        _vm._s(_vm.countdown.mm) +
                        ":" +
                        _vm._s(_vm.countdown.ss) +
                        " "
                    ),
                  ]),
                ])
              : _vm._e(),
            !_vm.overtime
              ? _c("p", [_vm._v("支付已超时,请重新购买")])
              : _vm._e(),
            _vm.orderVo.processStatCode === "WAIT_SEND"
              ? _c("p", [_vm._v("仓库发货中，请您耐心等待")])
              : _vm._e(),
            _vm.orderVo.processStatCode === "SENDING"
              ? _c("p", [_vm._v("部分商品未出库，请您耐心等待")])
              : _vm._e(),
            _vm.orderVo.processStatCode === "SEND" ||
            _vm.orderVo.processStatCode === "FINISH"
              ? _c("p", [_vm._v("商品已全部出库，请留意物流最新信息。")])
              : _vm._e(),
            _vm.orderVo.processStatCode === "WAIT_APPROVE" &&
            _vm.orderVo.isManufacturerSalesmanOrder
              ? _c("p", [
                  _vm._v(
                    "厂家业务员【" +
                      _vm._s(_vm.orderVo.salesmanName) +
                      "】为您下单，请您进行确认！"
                  ),
                ])
              : _vm._e(),
            _vm.orderVo.processStatCode === "CANCEL"
              ? _c("p", [_vm._v("订单已取消，交易自动关闭")])
              : _vm._e(),
            _vm.orderVo.processStatCode === "REFUND_FAILURE" &&
            _vm.orderVo.partialFinishType === "ERP"
              ? _c("p", [_vm._v("订单退款已被驳回，继续交易")])
              : _vm._e(),
            _vm.orderVo.processStatCode === "REFUND_FAILURE" &&
            _vm.orderVo.partialFinishType != "ERP"
              ? _c("p", [_vm._v("订单退款已被驳回，交易自动关闭")])
              : _vm._e(),
            _vm.overtime && _vm.orderVo.processStatCode === "WAIT_PAY"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.toPay(_vm.orderVo.orderId)
                      },
                    },
                  },
                  [_vm._v("去支付")]
                )
              : _vm._e(),
            _vm.orderVo.processStatCode === "WAIT_APPROVE" &&
            _vm.userType === "BUYERS"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.bindVisible("确认订单")
                      },
                    },
                  },
                  [_vm._v("确认订单")]
                )
              : _vm._e(),
            _c(
              "el-button",
              {
                attrs: { size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.exportDetail(_vm.orderVo.orderId)
                  },
                },
              },
              [_vm._v("导出商品明细")]
            ),
            _vm.userType === "BUYERS" || _vm.userType === "BUYERS_TEAM"
              ? _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.buyAgain } },
                  [_vm._v("再次购买")]
                )
              : _vm._e(),
            _vm.userType === "SALESMAN" &&
            _vm.orderVo.processStatCode != "WAIT_PAY" &&
            _vm.orderVo.processStatCode != "WAIT_APPROVE"
              ? _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.buyAgain } },
                  [_vm._v("再次购买")]
                )
              : _vm._e(),
            (_vm.orderVo.processStatCode === "WAIT_PAY" ||
              _vm.orderVo.processStatCode === "WAIT_APPROVE") &&
            _vm.userType === "BUYERS"
              ? _c(
                  "el-button",
                  {
                    attrs: { size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.bindCancelOrder(_vm.orderVo.orderId)
                      },
                    },
                  },
                  [_vm._v("取消订单")]
                )
              : _vm._e(),
            _vm.orderVo.processStatCode === "SEND" ||
            _vm.orderVo.processStatCode === "SENDING" ||
            _vm.orderVo.processStatCode === "FINISH"
              ? _c(
                  "el-button",
                  {
                    attrs: { size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.goOrderExpress(_vm.orderVo.orderId)
                      },
                    },
                  },
                  [_vm._v("查看物流")]
                )
              : _vm._e(),
            _vm.orderVo.processStatCode === "FINISH"
              ? _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.toRefunds } },
                  [_vm._v("申请退货")]
                )
              : _vm._e(),
            _c(
              "el-button",
              { attrs: { size: "mini" }, on: { click: _vm.downLoadLine } },
              [_vm._v("下载商品资质")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "steps" },
          [
            _c(
              "el-steps",
              {
                attrs: {
                  active: _vm.active,
                  "process-status": "finish",
                  "finish-status": "finish",
                  "align-center": true,
                },
              },
              [
                _c("el-step", {
                  attrs: {
                    title: "提交订单",
                    description: _vm.orderVo.createDate,
                  },
                }),
                _vm.orderVo.processStatCode !== "CANCEL"
                  ? _c("el-step", {
                      attrs: {
                        title: "付款成功",
                        description: _vm.orderVo.payedTime,
                      },
                    })
                  : _vm._e(),
                _vm.orderVo.processStatCode !== "CANCEL"
                  ? _c("el-step", {
                      attrs: {
                        title: "商品出库",
                        description: _vm.orderVo.sentTime,
                      },
                    })
                  : _vm._e(),
                _vm.orderVo.processStatCode !== "CANCEL"
                  ? _c("el-step", {
                      attrs: {
                        title: "等待收货",
                        description: _vm.orderVo.receiverTime,
                      },
                    })
                  : _vm._e(),
                _vm.orderVo.processStatCode !== "CANCEL"
                  ? _c("el-step", {
                      attrs: {
                        title: "订单完成",
                        description: _vm.orderVo.finishTime,
                      },
                    })
                  : _vm._e(),
                _vm.orderVo.processStatCode === "CANCEL"
                  ? _c("el-step", { attrs: { title: "交易关闭" } })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c("div", { staticClass: "message" }, [
            _c("div", { staticClass: "msg" }, [
              _c("p", { staticClass: "num" }, [
                _vm._v("订单编号: " + _vm._s(_vm.orderVo.orderNum)),
              ]),
              _c("div", { staticClass: "detail" }, [
                _c("div", [
                  _c("b", [_vm._v("下单时间")]),
                  _c("span", [_vm._v(_vm._s(_vm.orderVo.createDate))]),
                ]),
                _c("div", [
                  _c("b", [_vm._v("支付方式")]),
                  _vm.orderVo.paymentWayCode === "CASH"
                    ? _c("span", [_vm._v("在线支付")])
                    : _vm._e(),
                  _vm.orderVo.paymentWayCode === "CREDIT"
                    ? _c("span", [_vm._v("赊销")])
                    : _vm._e(),
                ]),
                _c("div", [
                  _c("b", [_vm._v("支付时间")]),
                  _c("span", [_vm._v(_vm._s(_vm.orderVo.payedTime || "-/-"))]),
                ]),
                _c("div", [
                  _c("b", [_vm._v("订单来源")]),
                  _c("span", [_vm._v(_vm._s(_vm.orderVo.orderSource))]),
                ]),
                _c("div", [
                  _c("b", [_vm._v("下单渠道")]),
                  _c("span", [_vm._v(_vm._s(_vm.orderVo.orderChannel))]),
                ]),
                _c("div", [
                  _c("b", [_vm._v("配送方式")]),
                  _c("span", [_vm._v(_vm._s(_vm.orderVo.orderId))]),
                ]),
                _c("div", [
                  _c("b", [_vm._v("备注信息")]),
                  _c("span", [_vm._v(_vm._s(_vm.orderVo.remark))]),
                ]),
              ]),
              _c("div", { staticClass: "detail" }, [
                _c("div", [
                  _c("b", [_vm._v("收货人")]),
                  _c("span", [_vm._v(_vm._s(_vm.orderVo.receiverName))]),
                ]),
                _c("div", [
                  _c("b", [_vm._v("收货地区")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.orderVo.province) +
                        "-" +
                        _vm._s(_vm.orderVo.city) +
                        "-" +
                        _vm._s(_vm.orderVo.district)
                    ),
                  ]),
                ]),
                _c("div", [
                  _c("b", [_vm._v("联系手机")]),
                  _c("span", [_vm._v(_vm._s(_vm.orderVo.receiverMobile))]),
                ]),
                _c("div", [
                  _c("b", [_vm._v("详细地址")]),
                  _c("span", [_vm._v(_vm._s(_vm.orderVo.receiverAddr))]),
                ]),
              ]),
              _c("div", { staticClass: "detail" }, [
                _c("div", [
                  _c("b", [_vm._v("客户名称")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.orderVo.buyerNm || _vm.orderBuyerVo.buyerNm)
                    ),
                  ]),
                ]),
                _c("div", [
                  _c("b", [_vm._v("客户编码")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.orderVo.buyerNo || _vm.orderBuyerVo.buyerNo)
                    ),
                  ]),
                ]),
                _vm.orderVo.ettleAccountNm !== "默认分支机构"
                  ? _c("div", [
                      _c("b", [_vm._v("分支机构")]),
                      _c("span", [_vm._v(_vm._s(_vm.orderVo.ettleAccountNm))]),
                    ])
                  : _vm._e(),
                _vm.orderVo.ettleAccountNm !== "默认分支机构"
                  ? _c("div", [
                      _c("b", [_vm._v("分支机构编码")]),
                      _c("span", [_vm._v(_vm._s(_vm.orderVo.code))]),
                    ])
                  : _vm._e(),
                _c("div", [
                  _c("b", [_vm._v("下单联系人")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.orderVo.salesmanName || _vm.orderVo.receiverName
                      )
                    ),
                  ]),
                ]),
                _c("div", [
                  _c("b", [_vm._v("联系手机")]),
                  _c("span", [_vm._v(_vm._s(_vm.orderVo.loginMobileId))]),
                ]),
              ]),
              _c("div", { staticClass: "detail" }, [
                _c("div", [
                  _c("b", [_vm._v("总品规")]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.orderVo.generalSpecification)),
                  ]),
                ]),
                _c("div", [
                  _c("b", [_vm._v("总数量")]),
                  _c("span", [_vm._v(_vm._s(_vm.orderVo.totalNum))]),
                ]),
                _c("div", [
                  _c("b", [_vm._v("商品总金额")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$util.toFixed(_vm.orderVo.productTotalAmount))
                    ),
                  ]),
                ]),
                _c("div", [
                  _c("b", [_vm._v("优惠券")]),
                  _c("span", [_vm._v(_vm._s(_vm.orderVo.couponPrice))]),
                ]),
                _c("div", [
                  _c("b", [_vm._v("折扣优惠")]),
                  _c("span", [_vm._v(_vm._s(_vm.orderVo.discountPrice))]),
                ]),
                _c("div", [
                  _c("b", [_vm._v("积分抵扣")]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.orderVo.integralDiscountAmount)),
                  ]),
                ]),
                _c("div", [
                  _c("b", [_vm._v("运费")]),
                  _c("span", [_vm._v(_vm._s(_vm.orderVo.freightAmount))]),
                ]),
                _c("div", [
                  _c("b", [_vm._v("实付金额")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$util.toFixed(_vm.orderVo.orderTotalAmount))
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  [
                    _c("b", [_vm._v("发票状态")]),
                    _vm.currentBills.length > 0
                      ? _c("span", [_vm._v("已开票")])
                      : _c("span", [_vm._v("未开票")]),
                    _vm.currentBills.length > 0 &&
                    _vm.orderVo.invNoAddress !== ""
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { padding: "0", flex: "1" },
                            attrs: { type: "text" },
                            on: { click: _vm.downloadInvoice },
                          },
                          [_vm._v("下载发票")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "table" },
            [
              _vm._l(_vm.packageVos, function (item, index) {
                return _c(
                  "div",
                  { key: item.packageId },
                  [
                    _c(
                      "div",
                      { staticClass: "package" },
                      [
                        _c("div", { staticClass: "package-index" }, [
                          _vm._v("包裹" + _vm._s(index + 1)),
                        ]),
                        _c("div", { staticClass: "package-text" }, [
                          _vm._v(
                            "物流公司：" + _vm._s(item.logisticsCompanyNm)
                          ),
                        ]),
                        _c("div", { staticClass: "package-text" }, [
                          _vm._v("物流单号：" + _vm._s(item.logisticsOrderNum)),
                        ]),
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "bottom",
                              width: "600",
                              trigger: "click",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "line-height": "40px",
                                  "border-bottom": "1px solid #E9E9E9",
                                  color: "#262626",
                                  background: "#fafafa",
                                },
                              },
                              [
                                _c(
                                  "b",
                                  {
                                    staticStyle: {
                                      padding: "2px 4px",
                                      color: "#0971EB",
                                      border: "1px solid #0971EB",
                                      "font-size": "12px",
                                      "border-radius": "3px",
                                      margin: "0 5px 0 15px",
                                    },
                                  },
                                  [_vm._v("包裹" + _vm._s(index + 1))]
                                ),
                                _vm._v(" 物流公司："),
                                _c(
                                  "span",
                                  { staticStyle: { "padding-right": "20px" } },
                                  [_vm._v(_vm._s(item.logisticsCompanyNm))]
                                ),
                                _vm._v(" 物流单号："),
                                _c("span", [
                                  _vm._v(_vm._s(item.logisticsOrderNum)),
                                ]),
                              ]
                            ),
                            _c("order-express", {
                              attrs: { express: _vm.orderExpress },
                            }),
                            _c(
                              "el-button",
                              {
                                staticClass: "express",
                                attrs: { slot: "reference", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.bindExpress(item.packageId)
                                  },
                                },
                                slot: "reference",
                              },
                              [
                                _vm._v(" 查看物流状态"),
                                _c("i", { staticClass: "el-icon-arrow-down" }),
                              ]
                            ),
                          ],
                          1
                        ),
                        item.isBuyerSigned != "Y"
                          ? _c(
                              "el-button",
                              {
                                staticStyle: {
                                  "margin-left": "20px",
                                  border: "1px solid #FA6400",
                                  "background-color": "#FA6400",
                                },
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.bindVisible(
                                      "确认收货",
                                      item.packageId
                                    )
                                  },
                                },
                              },
                              [_vm._v("确认收货")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: item.itemDetailVos,
                          "show-header": false,
                          "span-method": _vm.objectSpanMethod1,
                          border: "",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "商品信息",
                            "min-width": "275px",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.isTransferRecordFlag === "Y"
                                      ? _c("img", {
                                          staticClass: "diaobo",
                                          attrs: {
                                            src: require("@/assets/img/icon/diaobo.png"),
                                          },
                                        })
                                      : _vm._e(),
                                    _c("div", { staticClass: "product-info" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "product-image",
                                          on: {
                                            click: function ($event) {
                                              return _vm.productDetail(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("el-image", {
                                            attrs: {
                                              src:
                                                scope.row.thumbnailUrl ||
                                                _vm.productDefaultImg,
                                              fit: "fit",
                                            },
                                            on: {
                                              error: function ($event) {
                                                scope.row.thumbnailUrl =
                                                  _vm.productDefaultImg
                                              },
                                            },
                                          }),
                                          scope.row.orderItemStatus ===
                                          "INVALID"
                                            ? _c("img", {
                                                staticClass: "oneScrap",
                                                attrs: {
                                                  src: require("@/assets/img/detail/yizuofei.png"),
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "ul",
                                        { staticClass: "product-detail" },
                                        [
                                          _c(
                                            "li",
                                            {
                                              staticClass: "name",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.productDetail(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$util.getGoodsName(
                                                      scope.row
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c("li", { staticClass: "detail" }, [
                                            _c("p", [
                                              _vm._v("商品编码："),
                                              _c(
                                                "span",
                                                { staticClass: "spec" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.productCode
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]),
                                          _c("li", { staticClass: "detail" }, [
                                            _c("p", [
                                              _vm._v("规格："),
                                              _c(
                                                "span",
                                                { staticClass: "spec" },
                                                [_vm._v(_vm._s(scope.row.spec))]
                                              ),
                                              _vm._v(" 单位："),
                                              _c("span", [
                                                _vm._v(_vm._s(scope.row.unit)),
                                              ]),
                                            ]),
                                          ]),
                                          _c(
                                            "li",
                                            {
                                              staticClass:
                                                "manufacturer detail",
                                            },
                                            [
                                              _vm._v(
                                                "生产厂家:" +
                                                  _vm._s(scope.row.manufacturer)
                                              ),
                                            ]
                                          ),
                                          _vm.userType === "SALESMAN"
                                            ? _c(
                                                "li",
                                                { staticClass: "detail" },
                                                [
                                                  _vm._v(
                                                    "是否调拨:" +
                                                      _vm._s(
                                                        scope.row
                                                          .isTransferRecordFlag ===
                                                          "Y"
                                                          ? "是"
                                                          : "否"
                                                      )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.userType === "SALESMAN"
                                            ? _c(
                                                "li",
                                                { staticClass: "detail" },
                                                [
                                                  _vm._v(
                                                    "调拨经营单元：" +
                                                      _vm._s(
                                                        scope.row
                                                          .transferRecordOrg ||
                                                          "无"
                                                      )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          scope.row.orderItemType === "PRESENT"
                                            ? _c(
                                                "li",
                                                {
                                                  staticClass:
                                                    "manufacturer detail",
                                                  staticStyle: {
                                                    color: "#2B2B2B",
                                                  },
                                                },
                                                [_vm._v("[特殊商品]")]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]),
                                    _c("div"),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "单价",
                            prop: "originalUnitPrice",
                            width: "180px",
                            "show-overflow-tooltip": "",
                            align: "right",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", { staticClass: "textColor" }, [
                                      _vm._v(
                                        "¥" +
                                          _vm._s(
                                            scope.row.orderItemType ===
                                              "PRESENT"
                                              ? "0.00"
                                              : scope.row.originalUnitPrice
                                          )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "购买数量",
                            width: "90px",
                            prop: "number",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [_vm._v(_vm._s(scope.row.num))]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "实付金额",
                            prop: "originalUnitPrice",
                            align: "right",
                            width: "110px",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("p", { staticClass: "textColor" }, [
                                      _vm._v(
                                        "实付：¥" +
                                          _vm._s(
                                            _vm.$util.toFixed(
                                              scope.row.subtotal
                                            )
                                          )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "优惠明细",
                            width: "110px",
                            "show-overflow-tooltip": "",
                            align: "right",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", { staticClass: "textColor" }, [
                                      _vm._v(
                                        "-￥" + _vm._s(scope.row.discountAmount)
                                      ),
                                    ]),
                                    scope.row.integralDiscountAmount > 0
                                      ? _c("p", { staticClass: "goodInfor" }, [
                                          _vm._v(
                                            "积分： -￥" +
                                              _vm._s(
                                                scope.row.integralDiscountAmount
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm.orderVo.orderStatus === "已发货" ||
                        _vm.orderVo.orderStatus === "发货中" ||
                        _vm.orderVo.orderStatus === "已完成" ||
                        _vm.orderVo.orderStatus === "部分终止" ||
                        _vm.orderVo.orderStatus === "退款驳回"
                          ? _c("el-table-column", {
                              attrs: {
                                label: "发货信息",
                                width: "150px",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            style: {
                                              color: _vm.isHidelight(
                                                item.itemDetailVos,
                                                scope.row
                                              )
                                                ? "#fa6400"
                                                : "",
                                            },
                                          },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "goodInfor" },
                                              [
                                                _vm._v(
                                                  "发货批号：" +
                                                    _vm._s(scope.row.batchNum)
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              { staticClass: "goodInfor" },
                                              [
                                                _vm._v(
                                                  "有效期至：" +
                                                    _vm._s(scope.row.exp)
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              { staticClass: "goodInfor" },
                                              [
                                                _vm._v(
                                                  "实发数量：x" +
                                                    _vm._s(scope.row.quantity)
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          : _vm._e(),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            width: "110px",
                            fixed: "right",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "apply" },
                                      [
                                        _vm.userType === "BUYERS" ||
                                        _vm.userType === "BUYERS_TEAM"
                                          ? _c(
                                              "el-button",
                                              {
                                                staticClass: "download",
                                                attrs: {
                                                  loading: _vm.loading,
                                                  size: "mini",
                                                  type: "text",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.beforeAddItem(
                                                      scope.row.skuId,
                                                      "NORMAL",
                                                      scope.row.num,
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("再次购买")]
                                            )
                                          : _vm._e(),
                                        _vm.userType === "SALESMAN" &&
                                        _vm.orderVo.processStatCode !=
                                          "WAIT_PAY" &&
                                        _vm.orderVo.processStatCode !=
                                          "WAIT_APPROVE"
                                          ? _c(
                                              "el-button",
                                              {
                                                staticClass: "download",
                                                attrs: {
                                                  loading: _vm.loading,
                                                  size: "mini",
                                                  type: "text",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.beforeAddItem(
                                                      scope.row.skuId,
                                                      "NORMAL",
                                                      scope.row.num,
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("再次购买")]
                                            )
                                          : _vm._e(),
                                        _vm.orderVo.processStatCode === "FINISH"
                                          ? _c(
                                              "el-button",
                                              {
                                                staticClass: "download",
                                                attrs: {
                                                  size: "mini",
                                                  type: "text",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleDownloadSheet(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("下载药检单")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.packageVos &&
                        _vm.packageVos.length > 0 &&
                        _vm.tableData.length > 0,
                      expression:
                        "packageVos && packageVos.length > 0 && tableData.length > 0",
                    },
                  ],
                  staticClass: "package",
                },
                [_c("div", { staticClass: "package-text" }, [_vm._v("未发货")])]
              ),
              _c(
                "im-table-page",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.tableData.length > 0,
                      expression: "tableData.length > 0",
                    },
                  ],
                  ref: "tablePage",
                  attrs: {
                    data: _vm.tableData,
                    "remote-method": _vm.queryData,
                    "show-page": false,
                    "row-key": "id",
                    defaultExpandAll: true,
                    "tree-props": {
                      children: "children",
                      hasChildren: "hasChildren",
                    },
                    "more-check-box": _vm.handleSelectionChange,
                  },
                  on: {
                    "update:data": function ($event) {
                      _vm.tableData = $event
                    },
                  },
                },
                [
                  _vm.paramValueString === "Y" &&
                  _vm.orderVo.processStatCode === "WAIT_SEND" &&
                  _vm.orderVo.isPayed === "Y" &&
                  _vm.orderVo.paymentWayCode === "CASH"
                    ? _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          selectable: _vm.selectable,
                          align: "center",
                          width: "55",
                        },
                      })
                    : _vm.userType == "SALESMAN" &&
                      _vm.adjustStatus == "1" &&
                      !_vm.salemanAdjustFlag &&
                      _vm.orderVo.paymentWayCode != "CASH"
                    ? _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          selectable: _vm.selectable,
                          align: "center",
                          width: "55",
                        },
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品信息",
                      "min-width": "275px",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v("商品信息")]),
                            _vm.orderItemVos && _vm.orderItemVos.length > 0
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      "margin-left": "20px",
                                      padding: "0",
                                    },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        _vm.dialogListVisible = true
                                      },
                                    },
                                  },
                                  [_vm._v("查看购物清单")]
                                )
                              : _vm._e(),
                            _vm.batchBtnShow &&
                            _vm.paramValueString === "Y" &&
                            (_vm.orderVo.processStatCode === "WAIT_SEND" ||
                              _vm.orderVo.processStatCode ===
                                "PARTIAL_FINISH") &&
                            _vm.orderVo.paymentWayCode === "CASH" &&
                            _vm.orderVo.isPayed === "Y"
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      "margin-left": "20px",
                                      padding: "0",
                                    },
                                    attrs: { type: "text" },
                                    on: { click: _vm.toAllApplyRefunds },
                                  },
                                  [_vm._v("批量退款")]
                                )
                              : _vm._e(),
                            _vm.userType == "SALESMAN" &&
                            _vm.adjustStatus == "1" &&
                            !_vm.salemanAdjustFlag &&
                            _vm.orderVo.paymentWayCode != "CASH"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateOrderItemSalePrice(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("确认修改订单价格")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.isTransferRecordFlag === "Y"
                              ? _c("img", {
                                  staticClass: "diaobo",
                                  attrs: {
                                    src: require("@/assets/img/icon/diaobo.png"),
                                  },
                                })
                              : _vm._e(),
                            _c("div", { staticClass: "product-info" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "product-image",
                                  on: {
                                    click: function ($event) {
                                      return _vm.productDetail(scope.row)
                                    },
                                  },
                                },
                                [
                                  _c("el-image", {
                                    attrs: {
                                      src:
                                        scope.row.thumbnailUrl ||
                                        _vm.productDefaultImg,
                                      fit: "fit",
                                    },
                                    on: {
                                      error: function ($event) {
                                        scope.row.thumbnailUrl =
                                          _vm.productDefaultImg
                                      },
                                    },
                                  }),
                                  scope.row.orderItemStatus === "INVALID"
                                    ? _c("img", {
                                        staticClass: "oneScrap",
                                        attrs: {
                                          src: require("@/assets/img/detail/yizuofei.png"),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("ul", { staticClass: "product-detail" }, [
                                _c(
                                  "li",
                                  {
                                    staticClass: "name",
                                    on: {
                                      click: function ($event) {
                                        return _vm.productDetail(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$util.getGoodsName(scope.row))
                                    ),
                                  ]
                                ),
                                _c("li", { staticClass: "detail" }, [
                                  _c("p", [
                                    _vm._v("商品编码："),
                                    _c("span", { staticClass: "spec" }, [
                                      _vm._v(_vm._s(scope.row.productCode)),
                                    ]),
                                  ]),
                                ]),
                                _c("li", { staticClass: "detail" }, [
                                  _c("p", [
                                    _vm._v("规格："),
                                    _c("span", { staticClass: "spec" }, [
                                      _vm._v(_vm._s(scope.row.spec)),
                                    ]),
                                    _vm._v(" 单位："),
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.unit)),
                                    ]),
                                  ]),
                                ]),
                                _c(
                                  "li",
                                  { staticClass: "manufacturer detail" },
                                  [
                                    _vm._v(
                                      "生产厂家:" +
                                        _vm._s(scope.row.manufacturer)
                                    ),
                                  ]
                                ),
                                _vm.userType === "SALESMAN"
                                  ? _c("li", { staticClass: "detail" }, [
                                      _vm._v(
                                        "是否调拨:" +
                                          _vm._s(
                                            scope.row.isTransferRecordFlag ===
                                              "Y"
                                              ? "是"
                                              : "否"
                                          )
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.userType === "SALESMAN"
                                  ? _c("li", { staticClass: "detail" }, [
                                      _vm._v(
                                        "调拨经营单元：" +
                                          _vm._s(
                                            scope.row.transferRecordOrg || "无"
                                          )
                                      ),
                                    ])
                                  : _vm._e(),
                                scope.row.orderItemType === "PRESENT"
                                  ? _c(
                                      "li",
                                      {
                                        staticClass: "manufacturer detail",
                                        staticStyle: { color: "#2B2B2B" },
                                      },
                                      [_vm._v("[特殊商品]")]
                                    )
                                  : _vm._e(),
                                _c("li", { staticClass: "detail" }, [
                                  _c("p", [
                                    _vm._v("备注："),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.remark === ""
                                            ? "无"
                                            : scope.row.remark
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c("li", { staticClass: "detail" }, [
                                  _c("p", [
                                    _vm._v(
                                      "细单状态：" +
                                        _vm._s(scope.row.changeStatusName)
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                            _c("div"),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "单价",
                      prop: "money",
                      width: "180px",
                      "show-overflow-tooltip": "",
                      align: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.adjustStatus == "1" &&
                            _vm.orderVo.processStatCode === "WAIT_SEND" &&
                            scope.row.refundFlag !== 2 &&
                            !_vm.salemanAdjustFlag
                              ? _c("span", [
                                  _vm.userType === "SALESMAN" &&
                                  _vm.orderVo.paymentWayCode !== "CASH"
                                    ? _c(
                                        "ul",
                                        { staticClass: "product-detail" },
                                        [
                                          _c(
                                            "li",
                                            {
                                              staticClass:
                                                "manufacturer detail",
                                            },
                                            [
                                              _c("el-input-number", {
                                                attrs: {
                                                  precision: 2,
                                                  step: 0.01,
                                                  min: 0,
                                                  max: 10000000000,
                                                  size: "mini",
                                                },
                                                model: {
                                                  value: scope.row.settlePrice,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "settlePrice",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.settlePrice",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "li",
                                            {
                                              staticClass:
                                                "manufacturer detail",
                                            },
                                            [
                                              _vm._v(
                                                " 调价范围：" +
                                                  _vm._s(
                                                    _vm.calcPrice(
                                                      scope.row
                                                        .originalUnitPrice,
                                                      _vm.minPercent
                                                    )
                                                  ) +
                                                  "~" +
                                                  _vm._s(
                                                    _vm.calcPrice(
                                                      scope.row
                                                        .originalUnitPrice,
                                                      _vm.maxPercent
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  scope.row.originalUnitPrice ==
                                  scope.row.settlePrice
                                    ? _c("span", { staticClass: "textColor" }, [
                                        _vm._v(
                                          "¥" +
                                            _vm._s(scope.row.originalUnitPrice)
                                        ),
                                      ])
                                    : _c("span", { staticClass: "textColor" }, [
                                        _c("s", [
                                          _vm._v(
                                            "¥" +
                                              _vm._s(
                                                scope.row.originalUnitPrice
                                              )
                                          ),
                                        ]),
                                        _vm._v(
                                          "¥" + _vm._s(scope.row.settlePrice)
                                        ),
                                      ]),
                                ])
                              : _c("a", [
                                  scope.row.originalUnitPrice ==
                                  scope.row.settlePrice
                                    ? _c("span", { staticClass: "textColor" }, [
                                        _vm._v(
                                          "¥" +
                                            _vm._s(scope.row.originalUnitPrice)
                                        ),
                                      ])
                                    : _c("span", { staticClass: "textColor" }, [
                                        _c("s", [
                                          _vm._v(
                                            "¥" +
                                              _vm._s(
                                                scope.row.originalUnitPrice
                                              )
                                          ),
                                        ]),
                                        _vm._v(
                                          "¥" + _vm._s(scope.row.settlePrice)
                                        ),
                                      ]),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "购买数量",
                      width: "90px",
                      prop: "number",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.num))])]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "right",
                      label: "金额",
                      prop: "code",
                      width: "110px",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.paramValueString === "Y" &&
                            _vm.orderVo.processStatCode !== "REFUND_FAILURE" &&
                            scope.row.refundFlag == 1 &&
                            _vm.orderVo.paymentWayCode === "CASH" &&
                            _vm.orderVo.isPayed === "Y"
                              ? _c("p", { staticClass: "textColor" }, [
                                  _vm._v(
                                    "退款：¥" +
                                      _vm._s(
                                        _vm.$util.toFixed(
                                          scope.row.cancelQuantity *
                                            scope.row.settlePrice
                                        )
                                      )
                                  ),
                                ])
                              : _c("p", { staticClass: "textColor" }, [
                                  _vm._v(
                                    "实付：¥" +
                                      _vm._s(
                                        _vm.calculateTotalprice(
                                          scope.row.settlePrice,
                                          scope.row.num
                                        )
                                      )
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "优惠明细",
                      width: "110px",
                      prop: "number",
                      "show-overflow-tooltip": "",
                      align: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticClass: "textColor" }, [
                              _vm._v("-￥" + _vm._s(scope.row.discountAmount)),
                            ]),
                            scope.row.integralDiscountAmount > 0
                              ? _c("p", { staticClass: "goodInfor" }, [
                                  _vm._v(
                                    "积分： -￥" +
                                      _vm._s(scope.row.integralDiscountAmount) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.orderVo.orderStatus === "已发货" ||
                  _vm.orderVo.orderStatus === "发货中" ||
                  _vm.orderVo.orderStatus === "已完成" ||
                  _vm.orderVo.orderStatus === "部分终止" ||
                  _vm.orderVo.orderStatus === "退款驳回"
                    ? _c("el-table-column", {
                        attrs: {
                          label: "发货信息",
                          width: "150px",
                          prop: "number",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.refundFlag == 1
                                    ? _c("p", { staticClass: "goodInfor" }, [
                                        _vm._v(
                                          "退款数：× " +
                                            _vm._s(scope.row.cancelQuantity)
                                        ),
                                      ])
                                    : _c("p", { staticClass: "goodInfor" }, [
                                        _vm._v(
                                          "剩余数量：" +
                                            _vm._s(scope.row.notSendQuantity)
                                        ),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3526805654
                        ),
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "110px",
                      fixed: "right",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "apply" },
                              [
                                (_vm.userType === "BUYERS" ||
                                  _vm.userType === "BUYERS_TEAM") &&
                                scope.row.orderItemType !== "PRESENT"
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "download",
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.beforeAddItem(
                                              scope.row.skuId,
                                              "NORMAL",
                                              scope.row.num,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("再次购买")]
                                    )
                                  : _vm._e(),
                                _vm.userType === "SALESMAN" &&
                                _vm.orderVo.processStatCode != "WAIT_PAY" &&
                                _vm.orderVo.processStatCode != "WAIT_APPROVE" &&
                                scope.row.orderItemType !== "PRESENT"
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "download",
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.beforeAddItem(
                                              scope.row.skuId,
                                              "NORMAL",
                                              scope.row.num,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("再次购买")]
                                    )
                                  : _vm._e(),
                                _vm.orderVo.processStatCode === "FINISH"
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "download",
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDownloadSheet(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("下载药检单")]
                                    )
                                  : _vm._e(),
                                _vm.paramValueString === "Y" &&
                                scope.row.refundFlag == 0 &&
                                _vm.orderVo.processStatCode === "WAIT_SEND" &&
                                _vm.orderVo.paymentWayCode === "CASH" &&
                                _vm.orderVo.isPayed === "Y"
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "download",
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toApplyRefunds(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("退款")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-dialog",
            {
              staticClass: "dialog-table",
              attrs: { width: _vm.dialogWidth, visible: _vm.dialogVisible },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.dialogStatus)),
              ]),
              _vm.dialogStatus === "确认收货"
                ? _c("img", {
                    staticClass: "comfirmPic",
                    attrs: { src: _vm.comfirmGoodsIcon },
                  })
                : _vm._e(),
              _vm.dialogStatus === "确认订单"
                ? _c("img", {
                    staticClass: "comfirmPic",
                    attrs: { src: _vm.comfirmOrderIcon },
                  })
                : _vm._e(),
              _vm.dialogStatus === "确认收货" || _vm.dialogStatus === "确认订单"
                ? _c("div", { staticClass: "comfirmOrder" }, [
                    _vm._v("您是否" + _vm._s(_vm.dialogStatus)),
                  ])
                : _vm._e(),
              _vm.dialogStatus == "下载药检单"
                ? _c("div", { staticClass: "sheet" }, [
                    _vm._v(
                      " 以下订单中的商品已检索到药检单，请勾选下载，若存在缺失资料品种请联系业务员（未出库品种不可下载） "
                    ),
                  ])
                : _vm._e(),
              _vm.dialogStatus == "下载药检单"
                ? _c(
                    "im-table-page",
                    {
                      ref: "tablePage",
                      attrs: {
                        data: _vm.sheetData,
                        "remote-method": _vm.querySheet,
                        "more-check-box": _vm.moreCheckBox,
                      },
                      on: {
                        "update:data": function ($event) {
                          _vm.sheetData = $event
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          type: "index",
                          width: "60px",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "40px",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "productNm",
                          label: "商品名称",
                          "min-width": "140px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$util.getGoodsName(scope.row)
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3325910173
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "spec",
                          label: "规格",
                          "min-width": "100px",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "manufacturer",
                          label: "生产厂家",
                          "min-width": "183px",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "batch",
                          label: "商品批号",
                          width: "100px",
                          align: "center",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dialogStatus === "查看发票"
                ? _c(
                    "div",
                    { staticClass: "invoice" },
                    _vm._l(_vm.currentBills, function (item) {
                      return _c("div", { staticClass: "invoice-pic" }, [
                        _c("img", { attrs: { src: item.invNoAddress } }),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm.dialogStatus === "确认收货"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.bindComfirmOrder },
                        },
                        [_vm._v("确 定")]
                      )
                    : _vm._e(),
                  _vm.dialogStatus == "确认订单"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.bindSureOrder },
                        },
                        [_vm._v("确 定")]
                      )
                    : _vm._e(),
                  _vm.dialogStatus == "下载药检单"
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: !_vm.fileIds.length > 0,
                          },
                          on: { click: _vm.downloadSheet },
                        },
                        [_vm._v("下载药检单")]
                      )
                    : _vm._e(),
                  _vm.dialogStatus === "查看发票"
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: !_vm.currentBills.length > 0,
                          },
                          on: { click: _vm.downloadInvoice },
                        },
                        [_vm._v("下载发票")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.orderItemVos
            ? _c(
                "el-dialog",
                {
                  staticClass: "dialog-table",
                  attrs: {
                    width: "1000px",
                    title: "购物清单",
                    visible: _vm.dialogListVisible,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogListVisible = $event
                    },
                  },
                },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%", "padding-top": "1px" },
                      attrs: {
                        data: _vm.orderItemVos,
                        border: "",
                        "max-height": _vm.dialogTableHeight + "px",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "商品信息",
                          width: "290px",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.isTransferRecordFlag === "Y"
                                    ? _c("img", {
                                        staticClass: "diaobo",
                                        attrs: {
                                          src: require("@/assets/img/icon/diaobo.png"),
                                        },
                                      })
                                    : _vm._e(),
                                  _c("div", { staticClass: "product-info" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "product-image",
                                        on: {
                                          click: function ($event) {
                                            return _vm.productDetail(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _c("el-image", {
                                          attrs: {
                                            src: scope.row.thumbnailUrl,
                                            fit: "fit",
                                          },
                                          on: {
                                            error: function ($event) {
                                              scope.row.thumbnailUrl =
                                                _vm.productDefaultImg
                                            },
                                          },
                                        }),
                                        scope.row.orderItemStatus === "INVALID"
                                          ? _c("img", {
                                              staticClass: "oneScrap",
                                              attrs: {
                                                src: require("@/assets/img/detail/yizuofei.png"),
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "ul",
                                      { staticClass: "product-detail" },
                                      [
                                        _c(
                                          "li",
                                          {
                                            staticClass: "name",
                                            on: {
                                              click: function ($event) {
                                                return _vm.productDetail(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$util.getGoodsName(
                                                    scope.row
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c("li", { staticClass: "detail" }, [
                                          _c("p", [
                                            _vm._v("规格："),
                                            _c(
                                              "span",
                                              { staticClass: "spec" },
                                              [_vm._v(_vm._s(scope.row.spec))]
                                            ),
                                            _vm._v(" 单位："),
                                            _c("span", [
                                              _vm._v(_vm._s(scope.row.unit)),
                                            ]),
                                          ]),
                                        ]),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "manufacturer detail",
                                          },
                                          [
                                            _vm._v(
                                              "生产厂家：" +
                                                _vm._s(scope.row.manufacturer)
                                            ),
                                          ]
                                        ),
                                        _vm.userType === "SALESMAN"
                                          ? _c(
                                              "li",
                                              { staticClass: "detail" },
                                              [
                                                _vm._v(
                                                  "是否调拨:" +
                                                    _vm._s(
                                                      scope.row
                                                        .isTransferRecordFlag ===
                                                        "Y"
                                                        ? "是"
                                                        : "否"
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.userType === "SALESMAN"
                                          ? _c(
                                              "li",
                                              { staticClass: "detail" },
                                              [
                                                _vm._v(
                                                  "调拨经营单元：" +
                                                    _vm._s(
                                                      scope.row
                                                        .transferRecordOrg ||
                                                        "无"
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        scope.row.orderItemType === "PRESENT"
                                          ? _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "manufacturer detail",
                                                staticStyle: {
                                                  color: "#2B2B2B",
                                                },
                                              },
                                              [_vm._v("[特殊商品]")]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]),
                                  _c("div"),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2258446562
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单价",
                          prop: "money",
                          width: "180px",
                          "show-overflow-tooltip": "",
                          align: "right",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", { staticClass: "textColor" }, [
                                    _vm._v(
                                      "¥" +
                                        _vm._s(
                                          scope.row.orderItemType === "PRESENT"
                                            ? "0.00"
                                            : scope.row.originalUnitPrice
                                        )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          757637586
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "购买数量",
                          prop: "number",
                          "show-overflow-tooltip": "",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [_vm._v(_vm._s(scope.row.num))]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2460652848
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "right",
                          label: "实付金额",
                          prop: "code",
                          width: "150px",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("p", { staticClass: "textColor" }, [
                                    _vm._v(
                                      "¥" +
                                        _vm._s(
                                          _vm.$util.toFixed(scope.row.subtotal)
                                        )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1128915350
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "优惠明细",
                          prop: "number",
                          "show-overflow-tooltip": "",
                          align: "right",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", { staticClass: "textColor" }, [
                                    _vm._v(
                                      "-￥" + _vm._s(scope.row.discountAmount)
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3905254866
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          width: "100px",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "apply" },
                                    [
                                      (_vm.userType === "BUYERS" ||
                                        _vm.userType === "BUYERS_TEAM") &&
                                      scope.row.orderItemType !== "PRESENT"
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "download",
                                              attrs: {
                                                size: "mini",
                                                type: "text",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.beforeAddItem(
                                                    scope.row.skuId,
                                                    "NORMAL",
                                                    scope.row.num,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("再次购买")]
                                          )
                                        : _vm._e(),
                                      _vm.userType === "SALESMAN" &&
                                      _vm.orderVo.processStatCode !=
                                        "WAIT_PAY" &&
                                      _vm.orderVo.processStatCode !=
                                        "WAIT_APPROVE" &&
                                      scope.row.orderItemType !== "PRESENT"
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "download",
                                              attrs: {
                                                size: "mini",
                                                type: "text",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.beforeAddItem(
                                                    scope.row.skuId,
                                                    "NORMAL",
                                                    scope.row.num,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("再次购买")]
                                          )
                                        : _vm._e(),
                                      _vm.orderVo.processStatCode === "FINISH"
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "download",
                                              attrs: {
                                                size: "mini",
                                                type: "text",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.bindVisible(
                                                    "下载药检单"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("下载药检单")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4091742257
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-dialog",
            {
              staticClass: "dialog-table",
              attrs: {
                title: "下载商品资质",
                width: "60%",
                visible: _vm.downLicensesVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.downLicensesVisible = $event
                },
              },
            },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.downLicensesLoading,
                      expression: "downLicensesLoading",
                    },
                  ],
                  ref: "tableData",
                  attrs: { data: _vm.licenses, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "productNm",
                      label: "商品名称",
                      align: "center",
                      width: "180",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productCode",
                      label: "商品编码",
                      align: "center",
                      width: "180",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "pdfUrl", label: "操作", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return scope.row.pdfUrl
                              ? _vm._l(
                                  scope.row.pdfUrl.split(","),
                                  function (item, index) {
                                    return _c("span", [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "deepskyblue",
                                            cursor: "pointer",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$util.downFiles(
                                                item,
                                                scope.row.productCode +
                                                  "|" +
                                                  scope.row.productNm
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("电子版资质资料")]
                                      ),
                                    ])
                                  }
                                )
                              : undefined
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.downLicensesVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              staticClass: "dialog-table",
              attrs: {
                title: "退单",
                width: "60%",
                visible: _vm.refundsVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.refundsVisible = $event
                },
              },
            },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.downLicensesLoading,
                      expression: "downLicensesLoading",
                    },
                  ],
                  ref: "tableData2",
                  attrs: { data: _vm.refundData, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "商品信息",
                      "min-width": "275px",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.isTransferRecordFlag === "Y"
                              ? _c("img", {
                                  staticClass: "diaobo",
                                  attrs: {
                                    src: require("@/assets/img/icon/diaobo.png"),
                                  },
                                })
                              : _vm._e(),
                            _c("div", { staticClass: "product-info" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "product-image",
                                  on: {
                                    click: function ($event) {
                                      return _vm.productDetail(scope.row)
                                    },
                                  },
                                },
                                [
                                  _c("el-image", {
                                    attrs: {
                                      src:
                                        scope.row.thumbnailUrl ||
                                        _vm.productDefaultImg,
                                      fit: "fit",
                                    },
                                    on: {
                                      error: function ($event) {
                                        scope.row.thumbnailUrl =
                                          _vm.productDefaultImg
                                      },
                                    },
                                  }),
                                  scope.row.orderItemStatus === "INVALID"
                                    ? _c("img", {
                                        staticClass: "oneScrap",
                                        attrs: {
                                          src: require("@/assets/img/detail/yizuofei.png"),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("ul", { staticClass: "product-detail" }, [
                                _c(
                                  "li",
                                  {
                                    staticClass: "name",
                                    on: {
                                      click: function ($event) {
                                        return _vm.productDetail(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$util.getGoodsName(scope.row))
                                    ),
                                  ]
                                ),
                                _c("li", { staticClass: "detail" }, [
                                  _c("p", [
                                    _vm._v("商品编码："),
                                    _c("span", { staticClass: "spec" }, [
                                      _vm._v(_vm._s(scope.row.productCode)),
                                    ]),
                                  ]),
                                ]),
                                _c("li", { staticClass: "detail" }, [
                                  _c("p", [
                                    _vm._v("规格："),
                                    _c("span", { staticClass: "spec" }, [
                                      _vm._v(_vm._s(scope.row.spec)),
                                    ]),
                                    _vm._v(" 单位："),
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.unit)),
                                    ]),
                                  ]),
                                ]),
                                _c(
                                  "li",
                                  { staticClass: "manufacturer detail" },
                                  [
                                    _vm._v(
                                      "生产厂家:" +
                                        _vm._s(scope.row.manufacturer)
                                    ),
                                  ]
                                ),
                                _vm.userType === "SALESMAN"
                                  ? _c("li", { staticClass: "detail" }, [
                                      _vm._v(
                                        "是否调拨:" +
                                          _vm._s(
                                            scope.row.isTransferRecordFlag ===
                                              "Y"
                                              ? "是"
                                              : "否"
                                          )
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.userType === "SALESMAN"
                                  ? _c("li", { staticClass: "detail" }, [
                                      _vm._v(
                                        "调拨经营单元：" +
                                          _vm._s(
                                            scope.row.transferRecordOrg || "无"
                                          )
                                      ),
                                    ])
                                  : _vm._e(),
                                scope.row.orderItemType === "PRESENT"
                                  ? _c(
                                      "li",
                                      {
                                        staticClass: "manufacturer detail",
                                        staticStyle: { color: "#2B2B2B" },
                                      },
                                      [_vm._v("[特殊商品]")]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                            _c("div"),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "单价",
                      prop: "money",
                      width: "100px",
                      "show-overflow-tooltip": "",
                      align: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticClass: "textColor" }, [
                              _vm._v("¥" + _vm._s(scope.row.originalUnitPrice)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "退款数量",
                      width: "160px",
                      prop: "number",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input-number", {
                              staticClass: "proNums",
                              attrs: {
                                size: "mini",
                                placeholder: "数量",
                                precision: _vm.getDecimalPointNum(scope.row),
                                step: scope.row.productId
                                  ? _vm.getRealMinBuyNum(scope.row)
                                  : 1,
                                min:
                                  _vm.orderVo.processStatCode ==
                                  "PARTIAL_FINISH"
                                    ? scope.row.notSendQuantity
                                    : scope.row.minBuyDecimals,
                                max: scope.row.notSendQuantity,
                              },
                              on: { change: _vm.handleChange },
                              model: {
                                value: scope.row.num,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "num", $$v)
                                },
                                expression: "scope.row.num",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.refundReson },
                    },
                    [_vm._v("确 定")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.refundsVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              staticClass: "dialog-table",
              attrs: {
                width: "400px",
                title: "退款理由",
                visible: _vm.dialogRefundVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogRefundVisible = $event
                },
              },
            },
            [
              _c("div", { staticClass: "textarea-input" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.refundRemark,
                      expression: "refundRemark",
                    },
                  ],
                  staticClass: "textarea",
                  attrs: { placeholder: "请输入具体原因", maxlength: "40" },
                  domProps: { value: _vm.refundRemark },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.refundRemark = $event.target.value
                    },
                  },
                }),
                _c("div", { staticClass: "word-limit" }, [
                  _c("span", { staticClass: "green" }, [
                    _vm._v(_vm._s(_vm.refundRemark.length)),
                  ]),
                  _vm._v("/40 "),
                ]),
              ]),
              _c(
                "span",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.getRefunds },
                    },
                    [_vm._v("确 定")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogRefundVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.refundLoading,
            expression: "refundLoading",
          },
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.refundLoading,
            expression: "refundLoading",
          },
        ],
        staticClass: "loading-mask",
        attrs: { "element-loading-text": "退款请求中" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="search-main" id="search-main" :class="{'hiddenNav': hiddenNav}">
    <div class="container">
      <div class="logo-left">
        <img v-if="header.PC_TOP_LEFT_T1 && header.PC_TOP_LEFT_T1.length"
             @click="bindUrl(header.PC_TOP_LEFT_T1[0].link)" :src="header.PC_TOP_LEFT_T1[0].sysFileUrl" alt>
        <div class="line"
             v-if="hiddenSearchTitle === 'memberChangePassword' || hiddenSearchTitle === 'memberChangePhone'"></div>
        <div class="change-title"
             v-if="hiddenSearchTitle === 'memberChangePassword' || hiddenSearchTitle === 'memberChangePhone' ">
          {{hiddenSearchTitle === 'memberChangePassword' ? '修改密码' : originChange === 'memberChangePhones' ? '绑定手机号' : '修改手机号'}}
        </div>
      </div>
      <div class="return-security" v-if="returnSecurity" @click="toReturnSecurity">返回安全中心</div>
      <div class="search-container">
        <div class="search" v-if="!hiddenSearch">
          <el-input v-model="searchData" size="large" class="search-input" placeholder="请输入商品名称、拼音首字母、国药准字"
                    @keyup.enter.native="search(null)" @input="changeSearchInput">
            <el-button class="search-button" slot="append" icon="el-icon-search" @click="search(null)"></el-button>
          </el-input>
          <div class="search-tag" v-if="!hiddenNav && header.PC_TOP_SEARCH_LINK && header.PC_TOP_SEARCH_LINK.length">
            <span>热门搜索：</span>
            <span v-for="(item, index) in header.PC_TOP_SEARCH_LINK" :key="index" @click="selectTags(index)">{{item.title}}</span>
          </div>
        </div>
      </div>
      <div class="logo-phone" v-if="!hiddenNav">
        <img v-if="header.PC_TOP_RIGHT_T1 && header.PC_TOP_RIGHT_T1.length"
             @click="bindUrl(header.PC_TOP_RIGHT_T1[0].link)" :src="header.PC_TOP_RIGHT_T1[0].sysFileUrl" alt>
      </div>
      <div class="logo-code" v-if="!hiddenNav">
        <img v-if="header.PC_TOP_RIGHT_T2 && header.PC_TOP_RIGHT_T2.length"
             @click="bindUrl(header.PC_TOP_RIGHT_T2[0].link)" :src="header.PC_TOP_RIGHT_T2[0].sysFileUrl" alt>
      </div>

    </div>
    <div class="nav-body" v-if="!hiddenNav">
      <!-- 头部导航 -->
      <div class="nav-item">
        <ul class="nav-item-first" @mouseenter="showNav" @mouseleave="hideNav">
          <li>
            <span class="nav-side-item"><img src="../../assets/img/icon/classification.png" alt>商品分类</span>
          </li>
        </ul>
        <ul class="nav-item-last" v-if="!header.PC_NAVIGATION_LINK || !header.PC_NAVIGATION_LINK.length">
          <li>
            <router-link to="/">
              <a href="#">首页</a>
            </router-link>
          </li>
        </ul>
        <ul class="nav-item-last" v-else>
          <li v-for="(item,index) in header.PC_NAVIGATION_LINK" :key="index" :class="[{'router-link-exact-active':nowLink === item.titleLink}]">
            <!-- <router-link :to="item.titleLink">item.titleLink-->
              <a @click="linkTo(item)">{{item.title}}</a>
            <!-- </router-link> -->
          </li>
        </ul>
      </div>
      <!-- 侧边导航 -->
      <div class="nav-side" :style="{'height': !indexNav ? '400px' : 'auto'}" v-show="panelNav" @mouseenter="showNav"
           @mouseleave="hideNav" ref="navSide">
        <ul>
          <template v-for="(item ,index) in productCategory.childTree">
            <li @mouseenter="showDetail(index)" @mouseleave="hideDetail(index)" :key="index"
                @click="search(item, false)" v-show="index<8">
              <span class="nav-side-item">
                <img v-if="item.fileUrl" style='width: 20px;height:20px' :src="item.fileUrl" alt="">
                <img v-else style='width: 20px;height:20px' src="../../assets/img/icon/icon-yao (0).png" alt>
                {{item.categoryNm}}
              </span>
              <i class="el-icon-arrow-right"></i>
            </li>
          </template>
          <li :class="{'nav-side-all': !indexNav }" @click.stop="enterAll"  @mouseenter="showNav" @mouseleave="hideNav">查看全部分类导航</li>
        </ul>
      </div>
      <!-- 侧边导航内容 -->
      <div class="nav-side-content">
        <template v-for="(navItem,navIndex) in productCategory.childTree">
          <transition name="fade" :key="navIndex">
            <div
                class="detail-item-panel panel-2"
                :duration="{ enter: 100, leave: 100 }"
                v-show="navIndex === activeNavNum && panelNav"
                ref="itemPanel"
                @mouseenter="showDetail(navIndex)"
                @mouseleave="hideDetail(navIndex)"
            >
              <p class="detail-item-name" @click="search(navItem, false)">{{navItem.categoryNm}}</p>
              <ul>
                <li v-for="(items, index) in navItem.childTree" :key="index" class="detail-item-row">
                  <span class="detail-item-title" @click="search(items, false)">{{items.categoryNm}}
                    <span class="glyphicon glyphicon-menu-right"></span>
                  </span>
                  <a v-for="(item, subIndex) in items.childTree" :key="subIndex">
                    <span @click="search(item, false)" class="detail-item">{{item.categoryNm}}</span>
                  </a>
                </li>
              </ul>
            </div>
          </transition>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Search',
    props: {
      text: {
        type: String,
        default: ''
      },
      indexNav: {
        type: Boolean,
        default: true
      },
      hiddenNav: {
        type: Boolean,
        default: false
      },
      hiddenSearch: {
        type: Boolean,
        default: false
      },
      originChange: {
        type: String,
        default: ''
      },
      hiddenSearchTitle: {
        type: String,
        default: ''
      },
      returnSecurity: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        searchData: '',
        panelNav: false,
        activeNavNum: '',  //当前分类索引
        nowLink: ''
      }
    },
    watch: {
      text: {
        handler (newVal) {
          this.searchData = newVal
        },
        immediate:true
      },
      $route: {
        handler (route) {
          if (route.fullPath === '/') this.panelNav = true
        }
      }
    },
    created () {
      this.panelNav = !this.indexNav
      this.nowLink = this.$route.fullPath
    },
    methods: {
      changeSearchInput(val){
          this.$emit('update:text',val)
      },
      linkTo(path){
        var newStr=path.titleLink.indexOf("http");
        this.nowLink = path.titleLink

        console.log('this.nowLink', this.nowLink)
        if(newStr!=0){
          this.$router.push(path.titleLink)
        }else{
          window.open(path.titleLink,'_blank')
        }
      },
      bindUrl (path) {
        if (path.substr(0, 1) === '/') {
          this.$router.push({ path })
        } else {
          window.open(path, '_blank')
        }
      },
      enterAll () {
        this.$router.push({ path: '/allCategory' })
      },
      selectTags (index) {
        this.searchData = this.header.PC_TOP_SEARCH_LINK[index].title
        this.search()
      },
      search (item, valBol) {
        let categoryId = item ? item.categoryId : ''
        let categoryCode = item ? item.categoryCode : ''
        let searchData = ''
        if (!categoryId) { searchData = this.searchData }
        this.panelNav = false

        let t = this.$util.getTime(new Date(),'timeStamp-13')
        if (!this.$route.query.categoryId) {
          this.$router.push({ path: '/commodity-list', query: { searchData, categoryId, categoryCode , t} })
        } else if(!valBol && this.$route.path === '/commodity-list') {
          this.$router.push({ path: '/commodity-list', query: { searchData, categoryId, categoryCode , t} })
        } else {
          this.$router.go(0)
        }

      },
      showDetail (index) {
        this.activeNavNum = index
        if (this.indexNav) this.panelNav = true
      },
      hideDetail () {
        this.activeNavNum = ''
        if (this.indexNav) this.panelNav = false
      },
      showNav () {
        if (this.indexNav) this.panelNav = true
      },
      hideNav () {
        if (this.indexNav) this.panelNav = false
      },
      toReturnSecurity () {
        this.$router.push({
          path: '/member-center/personal-center/security-center',
          query: { toReturnSecurity: false }
        })
      }
    },
    computed: {
      ...mapGetters(['header', 'productCategory'])
    },
    mounted () {
      if (!this.hiddenNav) {
        // this.$refs.itemPanel1.style.left = this.$refs.navSide.offsetWidth + 'px'
        if (this.$refs.itemPanel) {
          this.$refs.itemPanel.forEach(item => {
            item.style.left = '230px'
          })
        }
      }
    },
    updated () {
      if (!this.hiddenNav) {
        if (this.$refs.itemPanel) {
          this.$refs.itemPanel.forEach(item => {
            item.style.left = '230px'
          })
        }
      }
    },
    beforeDestroy () {
      this.activeNavNum = ''
    }
  }
</script>

<style lang="scss" scoped>
  .search-main {
    background-color: #fff;
    border-bottom: 2px solid $ThemeColor;

    &.hiddenNav {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);

      .container {
        height: 100px;
        .search-container {
          margin-right: 0;
        }
      }
    }

    .container {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      height: 120px;

      .search-container {
        padding-top: 15px;
        margin: 0 auto 15px;
        width: 600px;

        .search {
          margin: 5px 0;

          .search-input {
            border-radius: 3px;
            border: 2px solid $ThemeColor;

            /deep/ .el-input__inner {
              border: 0;
              height: 35px;

              &::placeholder {
                color: #999;
              }

              &::-webkit-input-placeholder {
                /* WebKit browsers 适配谷歌 */
                color: #999;
              }

              &:-moz-placeholder {
                /* Mozilla Firefox 4 to 18 适配火狐 */
                color: #999;
              }

              &::-moz-placeholder {
                /* Mozilla Firefox 19+ 适配火狐 */
                color: #999;
              }

              &:-ms-input-placeholder {
                /* Internet Explorer 10+  适配ie*/
                color: #999;
              }
            }

            .search-button {
              @include NormalBtnColor;
              height: 38px;
              margin-right: -22px;
              border-radius: 0;
              display: flex;
              width: 80px;
              justify-content: center;
              align-items: center;

              /deep/ .el-icon-search {
                color: #fff;
                font-size: 18px;
              }
            }
          }

          .search-tag {
            color: #666666;
            font-size: 12px;
            text-align: left;
            line-height: 1.5;
            height: 2em;
            overflow: hidden;
            padding-top: 0.6em;

            span {
              padding-right: 10px;
              cursor: pointer;
            }

            span:first-child {
              padding-right: 0;
              cursor: default;
            }
          }
        }
      }

      .logo-phone {
        line-height: 22px;

        .phone {
          vertical-align: text-bottom;
          padding-left: 4px;
          @include TextHighlightColor
        }

        .time {
          color: #999999;
          font-size: 12px;
        }
      }

      .logo-code {
        display: flex;
        align-items: center;
        padding-left: 10px;

        .qr-code {
          padding-left: 10px;

          span {
            color: #666666;
            font-size: 12px;
          }
        }
      }
    }

    .nav-body {
      display: inline-block;
      width: 1200px;
      text-align: left;

      .nav-side {
        width: 230px;
        position: absolute;
        z-index: 100;
        background-color: #fff;
        border-top: 2px solid $ThemeColor;

        .nav-side-all {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 230px;
        }

        li {
          padding: 8px 12px 8px 20px;
          font-size: 14px;
          line-height: 29px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          cursor: default;

          &:hover {
            box-shadow: 3px 4px 6px 0 rgba(0, 0, 0, 0.1);
          }

          &:last-child {
            display: block;
            font-size: 12px;
            text-align: center;
            padding: 0;
            color: #666666;
            line-height: 42px;
            height: 42px;
            border-top: 1px solid #E5E5E5;
            cursor: pointer;

            &:hover {
              @include TextHighlightColor
            }
          }

          img {
            margin-right: 8px;
            vertical-align: text-bottom;
          }
        }
      }

      .nav-side-content {
        position: absolute;
      }

      .nav-item {
        height: 44px;

        .nav-item-first {
          color: #fff;
          border-radius: 3px 3px 0 0;
          background: $ThemeColor;
          float: left;
          padding: 8px 12px 8px 16px;
          line-height: 28px;
          width: 230px;
          font-size: 14px;

          img {
            vertical-align: initial;
            margin-right: 8px;
          }
        }

        .nav-item-last {
          display: flex;

          li {
            padding: 8px 15px 8px 20px;
            line-height: 28px;
            font-weight: bold;

            &.router-link-exact-active {
              a {
                @include TextHighlightColor
              }
            }
          }
        }

        a {
          text-decoration: none;
          color: #555555;

          &:hover {
            @include TextHighlightColor
          }
        }
      }
    }

    .detail-item-panel {
      width: 740px;
      height: 402px;
      background-color: #fff;
      overflow: auto;
      position: absolute;
      z-index: 999;
      text-align: left;
      padding-bottom: 16px;
      box-shadow: 0 2px 6px 0 #e0e0e0;

      .detail-item-name {
        margin: 12px 40px;
        font-weight: bold;
        cursor: pointer;
      }

      .nav-detail-item {
        margin-left: 26px;
        margin-top: 15px;
        margin-bottom: 15px;
        cursor: pointer;
        color: #eee;
      }

      .detail-item-row {
        line-height: 40px;
        margin: 0 40px;
        border-bottom: 1px solid #E5E5E5;

        .detail-item-title {
          padding-right: 20px;
          font-weight: bold;
          font-size: 14px;
          cursor: pointer;
          color: #555555;

          &:hover {
            @include TextHighlightColor
          }
        }

        a {
          font-size: 12px;
          display: inline-block;

          .detail-item {
            font-size: 14px;
            padding: 0 12px;
            cursor: pointer;
            border-right: 1px solid #ccc;
            color: #666666;

            &:hover {
              @include TextHighlightColor
            }
          }

          &:last-child {
            .detail-item {
              border-right: none;
            }
          }
        }
      }
    }
  }

  .line {
    display: inline-block;
    width: 1px;
    height: 40px;
    background: #cccccc;
    position: absolute;
    top: 18px;
    margin-left: 12px;
  }

  .change-title {
    position: absolute;
    display: inline-block;
    width: 100px;
    height: 28px;
    font-size: 20px;
    font-weight: 500;
    color: #222222;
    line-height: 28px;
    top: 22px;
    margin-left: 38px;
  }

  .logo-left {
    position: relative;

    img {
      width: 230px;
      height: 80px;
    }
  }

  .return-security {
    position: absolute;
    display: inline-block;
    width: 72px;
    height: 17px;
    font-size: 12px;
    font-weight: 500;
    color: #666666;
    line-height: 17px;
    right: 77px;
  }

</style>

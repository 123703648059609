import {ApiGet, ApiPost} from "@/api";
/**
 * @description 审批流各个状态列表
 */
export const getRefundFlowOrder = data => ApiPost('/refundOrder/pageRefundFlowOrder',data)

/**
 * @description 退款流程
 */
export const queryRefundFlowNodeList = data => ApiPost('/bpmService/refundFlowNode/queryRefundFlowNodeList',data, {},false)

/**
 * @description 商品订单信息
 */
export const getRefundOrderDetail = data => ApiGet('/refundOrder/getRefundOrderDetail',data)

/**
 * @description 审批
 */
export const approveRefundFlowNode = data => ApiPost('/bpmService/refundFlowNode/approveRefundFlowNode',data, {},false)

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "improveDiv" },
      [
        _c("div", { staticClass: "heard" }, [
          _vm._v("账户类型： "),
          _c("span", { staticClass: "heardText" }, [_vm._v("采购商")]),
          _vm._v(" 登录账号： "),
          _c("span", { staticClass: "heardText" }, [
            _vm._v(_vm._s(_vm.params.registerLoginId)),
          ]),
          _vm._v(
            " " + _vm._s(_vm.params.loginMobileId ? "手机号码：" : "") + " "
          ),
          _c("span", { staticClass: "heardText" }, [
            _vm._v(_vm._s(_vm.params.loginMobileId)),
          ]),
        ]),
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "from",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              "label-width": "165px",
            },
          },
          [
            _c(
              "div",
              { staticClass: "enterpriseInfo" },
              [
                _c("div", { staticClass: "fromTitle" }, [_vm._v("企业信息")]),
                _c(
                  "el-form-item",
                  {
                    staticClass: "formItem",
                    attrs: { label: "客户名称", prop: "customerName" },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入单位全称" },
                      model: {
                        value: _vm.form.customerName,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "customerName", $$v)
                        },
                        expression: "form.customerName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "formItem",
                    attrs: {
                      label: "统一社会信用代码",
                      prop: "socialCreditCode",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入统一社会信用代码" },
                      model: {
                        value: _vm.form.socialCreditCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "socialCreditCode", $$v)
                        },
                        expression: "form.socialCreditCode",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "formItem",
                    attrs: { label: "法人代表", prop: "legalPersonMember" },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入法人代表" },
                      model: {
                        value: _vm.form.legalPersonMember,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "legalPersonMember", $$v)
                        },
                        expression: "form.legalPersonMember",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "formItem",
                    attrs: { label: "所在地区", prop: "districtCode" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "chooseDiv" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "province",
                            attrs: { placeholder: "请选择" },
                            on: { change: _vm.provinceChange },
                            model: {
                              value: _vm.form.provinceCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "provinceCode", $$v)
                              },
                              expression: "form.provinceCode",
                            },
                          },
                          _vm._l(_vm.provinceList, function (item) {
                            return _c("el-option", {
                              key: item.districtId,
                              attrs: {
                                label: item.name,
                                value: item.nodeCode + "_" + item.districtId,
                              },
                            })
                          }),
                          1
                        ),
                        _c(
                          "el-select",
                          {
                            staticClass: "province",
                            attrs: { placeholder: "请选择" },
                            on: { change: _vm.cityChange },
                            model: {
                              value: _vm.form.cityCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cityCode", $$v)
                              },
                              expression: "form.cityCode",
                            },
                          },
                          _vm._l(_vm.cityList, function (item) {
                            return _c("el-option", {
                              key: item.districtId,
                              attrs: {
                                label: item.name,
                                value: item.nodeCode + "_" + item.districtId,
                              },
                            })
                          }),
                          1
                        ),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "109px" },
                            attrs: { placeholder: "请选择" },
                            on: { change: _vm.districtChange },
                            model: {
                              value: _vm.form.districtCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "districtCode", $$v)
                              },
                              expression: "form.districtCode",
                            },
                          },
                          _vm._l(_vm.districtList, function (item) {
                            return _c("el-option", {
                              key: item.districtId,
                              attrs: {
                                label: item.name,
                                value: item.nodeCode + "_" + item.districtId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "formItem",
                    attrs: { label: "注册地址", prop: "regAddr" },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入本单位注册地址" },
                      model: {
                        value: _vm.form.regAddr,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "regAddr", $$v)
                        },
                        expression: "form.regAddr",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "formItem",
                    attrs: { label: "经营地址", prop: "businessAddress" },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入本单位经营地址" },
                      model: {
                        value: _vm.form.businessAddress,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "businessAddress", $$v)
                        },
                        expression: "form.businessAddress",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "formItem",
                    attrs: { label: "联系人", prop: "chargePerson" },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入联系人" },
                      model: {
                        value: _vm.form.chargePerson,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "chargePerson", $$v)
                        },
                        expression: "form.chargePerson",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "formItem",
                    attrs: { label: "联系电话", prop: "chargePersonPhone" },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入联系电话" },
                      model: {
                        value: _vm.form.chargePersonPhone,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "chargePersonPhone", $$v)
                        },
                        expression: "form.chargePersonPhone",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "enterpriseInfo nextInfo" },
              [
                _c("div", { staticClass: "fromTitle" }, [_vm._v("收货地址")]),
                _c(
                  "el-form-item",
                  {
                    staticClass: "formItem",
                    attrs: {
                      label: "随货联客户名称",
                      prop: "contactCustomerNm",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入随货联客户名称" },
                      model: {
                        value: _vm.form.contactCustomerNm,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "contactCustomerNm", $$v)
                        },
                        expression: "form.contactCustomerNm",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "formItem",
                    attrs: { label: "所在地区", prop: "addrDistrictCode" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "chooseDiv" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "province",
                            attrs: { placeholder: "请选择" },
                            on: { change: _vm.addrProvinceChange },
                            model: {
                              value: _vm.form.addrProvinceCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "addrProvinceCode", $$v)
                              },
                              expression: "form.addrProvinceCode",
                            },
                          },
                          _vm._l(_vm.provinceList, function (item) {
                            return _c("el-option", {
                              key: item.districtId,
                              attrs: {
                                label: item.name,
                                value: item.nodeCode + "_" + item.districtId,
                              },
                            })
                          }),
                          1
                        ),
                        _c(
                          "el-select",
                          {
                            staticClass: "province",
                            attrs: { placeholder: "请选择" },
                            on: { change: _vm.addrCityChange },
                            model: {
                              value: _vm.form.addrCityCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "addrCityCode", $$v)
                              },
                              expression: "form.addrCityCode",
                            },
                          },
                          _vm._l(_vm.addrCityList, function (item) {
                            return _c("el-option", {
                              key: item.districtId,
                              attrs: {
                                label: item.name,
                                value: item.nodeCode + "_" + item.districtId,
                              },
                            })
                          }),
                          1
                        ),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "109px" },
                            attrs: { placeholder: "请选择" },
                            on: { change: _vm.addrDistrictChange },
                            model: {
                              value: _vm.form.addrDistrictCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "addrDistrictCode", $$v)
                              },
                              expression: "form.addrDistrictCode",
                            },
                          },
                          _vm._l(_vm.addrDistrictList, function (item) {
                            return _c("el-option", {
                              key: item.districtId,
                              attrs: {
                                label: item.name,
                                value: item.nodeCode + "_" + item.districtId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "formItem",
                    attrs: { label: "收货地址", prop: "detailAddr" },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入收货地址" },
                      model: {
                        value: _vm.form.detailAddr,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "detailAddr", $$v)
                        },
                        expression: "form.detailAddr",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "formItem",
                    attrs: { label: "收货联系人", prop: "receiverName" },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入收货联系人" },
                      model: {
                        value: _vm.form.receiverName,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "receiverName", $$v)
                        },
                        expression: "form.receiverName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "formItem",
                    attrs: { label: "联系电话", prop: "receiverMobile" },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入联系电话" },
                      model: {
                        value: _vm.form.receiverMobile,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "receiverMobile", $$v)
                        },
                        expression: "form.receiverMobile",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "enterpriseInfo nextInfo" },
              [
                _c("div", { staticClass: "fromTitle" }, [_vm._v("发票信息")]),
                _c(
                  "el-form-item",
                  {
                    staticClass: "formItem",
                    attrs: { label: "发票类型", prop: "invoiceType" },
                  },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "COMMERCIAL_INVOICE" },
                        model: {
                          value: _vm.form.invoiceType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "invoiceType", $$v)
                          },
                          expression: "form.invoiceType",
                        },
                      },
                      [_vm._v("增值税普通发票")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "VAT_INVOICE" },
                        model: {
                          value: _vm.form.invoiceType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "invoiceType", $$v)
                          },
                          expression: "form.invoiceType",
                        },
                      },
                      [_vm._v("增值税专业发票")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "formItem",
                    attrs: { label: "客户发票名称", prop: "invoiceTitle" },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入客户发票名称" },
                      model: {
                        value: _vm.form.invoiceTitle,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "invoiceTitle", $$v)
                        },
                        expression: "form.invoiceTitle",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "formItem",
                    attrs: { label: "税号", prop: "invoiceTfn" },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入税号" },
                      model: {
                        value: _vm.form.invoiceTfn,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "invoiceTfn", $$v)
                        },
                        expression: "form.invoiceTfn",
                      },
                    }),
                  ],
                  1
                ),
                _vm.form.invoiceType !== "COMMERCIAL_INVOICE"
                  ? _c(
                      "el-form-item",
                      {
                        staticClass: "formItem",
                        attrs: { label: "地址", prop: "unitAddr" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "输入地址" },
                          model: {
                            value: _vm.form.unitAddr,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "unitAddr", $$v)
                            },
                            expression: "form.unitAddr",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.invoiceType !== "COMMERCIAL_INVOICE"
                  ? _c(
                      "el-form-item",
                      {
                        staticClass: "formItem",
                        attrs: { label: "电话", prop: "regTel" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "输入电话" },
                          model: {
                            value: _vm.form.regTel,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "regTel", $$v)
                            },
                            expression: "form.regTel",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.invoiceType !== "COMMERCIAL_INVOICE"
                  ? _c(
                      "el-form-item",
                      {
                        staticClass: "formItem",
                        attrs: { label: "开户行", prop: "openBank" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "输入开户行" },
                          model: {
                            value: _vm.form.openBank,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "openBank", $$v)
                            },
                            expression: "form.openBank",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.invoiceType !== "COMMERCIAL_INVOICE"
                  ? _c(
                      "el-form-item",
                      {
                        staticClass: "formItem",
                        attrs: { label: "账号", prop: "bankAccount" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "输入账号" },
                          model: {
                            value: _vm.form.bankAccount,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "bankAccount", $$v)
                            },
                            expression: "form.bankAccount",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  {
                    staticClass: "formItem",
                    attrs: {
                      label: "纳税人证明照",
                      prop: "taxpayerCertificationId",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        staticStyle: { width: "400px", height: "80px" },
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            ref: "upload",
                            class: {
                              hide: _vm.hideUploadProve,
                              show: !_vm.hideUploadProve,
                            },
                            attrs: {
                              action: "#",
                              accept: ".jpg,.jpeg,.bmp,.gif, .png",
                              "list-type": "picture-card",
                              "auto-upload": true,
                              "show-file-list": _vm.hideUploadProve,
                              "file-list": _vm.fileList,
                              "on-preview": _vm.handlePictureCardPreview,
                              "on-remove": _vm.handleRemoveProve,
                              "before-upload": _vm.beforeUpload,
                              "http-request": () => _vm.Upload(100),
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "upText",
                                attrs: { slot: "trigger" },
                                slot: "trigger",
                              },
                              [_vm._v("上传文件")]
                            ),
                          ]
                        ),
                        _c(
                          "el-dialog",
                          {
                            attrs: { visible: _vm.dialogVisible },
                            on: {
                              "update:visible": function ($event) {
                                _vm.dialogVisible = $event
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                width: "100%",
                                src: _vm.dialogImageUrl,
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "enterpriseInfo nextInfo" },
              [
                _c("div", { staticClass: "fromTitle" }, [_vm._v("委托人信息")]),
                _c(
                  "el-form-item",
                  {
                    staticClass: "formItem",
                    attrs: { label: "姓名", prop: "clientName" },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入委托人姓名" },
                      model: {
                        value: _vm.form.clientName,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "clientName", $$v)
                        },
                        expression: "form.clientName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "formItem",
                    attrs: { label: "身份证号", prop: "clientIdNum" },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入身份证号" },
                      model: {
                        value: _vm.form.clientIdNum,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "clientIdNum", $$v)
                        },
                        expression: "form.clientIdNum",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "formItem idCardDate",
                    attrs: { label: "身份证有效期截止日期", prop: "idCardNum" },
                  },
                  [
                    _c("el-date-picker", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isShow,
                          expression: "isShow",
                        },
                      ],
                      staticStyle: {
                        width: "275px",
                        height: "36px",
                        "margin-right": "15px",
                      },
                      attrs: {
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                        type: "date",
                        "picker-options": _vm.pickerOptions,
                        placeholder: "选择日期",
                        disabled: _vm.langTrue,
                      },
                      on: { input: _vm.idDateChange },
                      model: {
                        value: _vm.form.idCardNum,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "idCardNum", $$v)
                        },
                        expression: "form.idCardNum",
                      },
                    }),
                    _c(
                      "el-checkbox",
                      {
                        on: { change: _vm.langChange },
                        model: {
                          value: _vm.langTrue,
                          callback: function ($$v) {
                            _vm.langTrue = $$v
                          },
                          expression: "langTrue",
                        },
                      },
                      [_vm._v("长期有效")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "enterpriseInfo nextInfo" },
              [
                _c("div", { staticClass: "fromTitle" }, [_vm._v("客户资质")]),
                _c(
                  "el-form-item",
                  {
                    staticClass: "formItem",
                    attrs: { label: "客户类型", prop: "customType" },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "361px", height: "36px" },
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.customTypeChange },
                        model: {
                          value: _vm.form.customType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "customType", $$v)
                          },
                          expression: "form.customType",
                        },
                      },
                      _vm._l(_vm.customTypeList, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._l(_vm.form.qualificationList, function (item, index) {
                  return _c(
                    "div",
                    [
                      index === _vm.form.qualificationList.length - 3
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "formItem publicPayItem",
                              attrs: {
                                label: "是否对公支付",
                                prop: "publicPay",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "true" },
                                  model: {
                                    value: _vm.form.publicPay,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "publicPay", $$v)
                                    },
                                    expression: "form.publicPay",
                                  },
                                },
                                [_vm._v("是")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "false" },
                                  model: {
                                    value: _vm.form.publicPay,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "publicPay", $$v)
                                    },
                                    expression: "form.publicPay",
                                  },
                                },
                                [_vm._v("否")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      index < _vm.form.qualificationList.length - 3 ||
                      _vm.form.publicPay === "false"
                        ? _c(
                            "div",
                            {
                              staticClass: "customerDiv",
                              class:
                                index != 0 &&
                                index != _vm.form.qualificationList.length - 3
                                  ? "nextInfo"
                                  : "",
                            },
                            [
                              _c("div", { staticClass: "customerTitle" }, [
                                _c("div", { staticClass: "titleIcon" }),
                                _vm._v(" " + _vm._s(item.licenseNm) + " "),
                                _c("span", { staticClass: "titleTip" }, [
                                  _vm._v(_vm._s(item.tip)),
                                ]),
                                item.downUrl
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "downTemplate",
                                        attrs: { href: item.downUrl },
                                      },
                                      [_vm._v("下载模板")]
                                    )
                                  : _vm._e(),
                              ]),
                              _c(
                                "div",
                                { staticClass: "customerInput" },
                                [
                                  item.isCertNum != "3"
                                    ? _c(
                                        "el-form-item",
                                        {
                                          staticClass: "formCustomItem",
                                          attrs: {
                                            label: "证件编码",
                                            "label-width": "80px",
                                            prop:
                                              "qualificationList." +
                                              index +
                                              ".businessScope",
                                            rules: [
                                              {
                                                required:
                                                  item.isCertNum === "1",
                                                trigger: "blur",
                                                message: "证件编码不能为空",
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "输入证件编码",
                                            },
                                            model: {
                                              value: item.businessScope,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "businessScope",
                                                  $$v
                                                )
                                              },
                                              expression: "item.businessScope",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  item.isAllTime != "3" ||
                                  item.isValidityTime != 3
                                    ? _c(
                                        "el-form-item",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.isShow,
                                              expression: "isShow",
                                            },
                                          ],
                                          staticClass:
                                            "formCustomItem idCardDate",
                                          staticStyle: { width: "450px" },
                                          attrs: {
                                            label: "有效期",
                                            "label-width": "80px",
                                            prop:
                                              "qualificationList." +
                                              index +
                                              ".validityText",
                                            rules: [
                                              {
                                                required:
                                                  item.isAllTime === "1",
                                                trigger: "change",
                                                message: "有效期不能为空",
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            staticStyle: {
                                              width: "250px",
                                              height: "36px",
                                            },
                                            attrs: {
                                              type: "daterange",
                                              "value-format": "yyyy-MM-dd",
                                              "range-separator": "至",
                                              "start-placeholder": "开始日期",
                                              "end-placeholder": "结束日期",
                                              disabled: item.forever,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.rangeDateChange(
                                                  item.rangeDate,
                                                  index
                                                )
                                              },
                                            },
                                            model: {
                                              value: item.rangeDate,
                                              callback: function ($$v) {
                                                _vm.$set(item, "rangeDate", $$v)
                                              },
                                              expression: "item.rangeDate",
                                            },
                                          }),
                                          item.isAllTime != "3"
                                            ? _c(
                                                "el-checkbox",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "15px",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.rangeDateChange(
                                                        item.forever,
                                                        index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: item.forever,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "forever",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.forever",
                                                  },
                                                },
                                                [_vm._v("长期有效")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  item.isStartDate != "3"
                                    ? _c(
                                        "el-form-item",
                                        {
                                          staticClass: "formCustomItem",
                                          attrs: {
                                            label: "发证日期",
                                            "label-width": "80px",
                                            prop:
                                              "qualificationList." +
                                              index +
                                              ".issueDate",
                                            rules: [
                                              {
                                                required:
                                                  item.isStartDate === "1",
                                                trigger: "blur",
                                                message: "发证日期不能为空",
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            staticStyle: {
                                              width: "250px",
                                              height: "36px",
                                            },
                                            attrs: {
                                              "value-format": "yyyy-MM-dd",
                                              type: "date",
                                              placeholder: "选择日期",
                                            },
                                            model: {
                                              value: item.issueDate,
                                              callback: function ($$v) {
                                                _vm.$set(item, "issueDate", $$v)
                                              },
                                              expression: "item.issueDate",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              item.isPic != "3"
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticClass: "formCustomItem",
                                      attrs: {
                                        label: " ",
                                        "label-width": "80px",
                                        prop:
                                          "qualificationList." +
                                          index +
                                          ".fileId",
                                        rules: [
                                          {
                                            required: item.isPic === "1",
                                            trigger: "blur",
                                            message: "证件照片不能为空",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.loadingList[index],
                                              expression: "loadingList[index]",
                                            },
                                          ],
                                          staticStyle: {
                                            width: "280px",
                                            height: "80px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-upload",
                                            {
                                              class: {
                                                customHide:
                                                  _vm.hideUpload[index],
                                                customShow:
                                                  !_vm.hideUpload[index],
                                              },
                                              attrs: {
                                                action: "#",
                                                accept:
                                                  ".jpg,.jpeg,.bmp,.gif, .png",
                                                "list-type": "picture-card",
                                                "auto-upload": true,
                                                "show-file-list":
                                                  _vm.hideUpload[index],
                                                "file-list":
                                                  _vm.qualityFileList[index],
                                                "on-preview":
                                                  _vm.handlePictureCardPreview,
                                                "on-remove": (file) =>
                                                  _vm.handleRemove(file, index),
                                                "before-upload":
                                                  _vm.beforeUpload,
                                                "http-request": () =>
                                                  _vm.Upload(index),
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "upText",
                                                  attrs: { slot: "trigger" },
                                                  slot: "trigger",
                                                },
                                                [_vm._v("上传文件")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-dialog",
                                            {
                                              attrs: {
                                                visible: _vm.dialogVisible,
                                              },
                                              on: {
                                                "update:visible": function (
                                                  $event
                                                ) {
                                                  _vm.dialogVisible = $event
                                                },
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  width: "100%",
                                                  src: _vm.dialogImageUrl,
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "customerTip" }, [
                                _vm._v(
                                  "请上传清晰彩色原件扫描或数码照，支持jpg、jpeg、bmp、gif、png格式照片，大小不超过2m"
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "buttonDiv" },
      [
        _c(
          "el-button",
          {
            staticClass: "sureBut",
            attrs: { type: "primary" },
            on: { click: _vm.nextBut },
          },
          [_vm._v("提交")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
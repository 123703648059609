var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "container",
      attrs: { width: "800px", title: "查看批号效期", visible: _vm.visible },
      on: { close: _vm.onClose },
    },
    [
      _c("div", { staticClass: "goodsData" }, [
        _c("span", [_vm._v("商品编码：" + _vm._s(_vm.rowData.productCode))]),
        _c("span", [
          _vm._v("商品名称：" + _vm._s(_vm.$util.getGoodsName(_vm.rowData))),
        ]),
        _c("span", [_vm._v("规格：" + _vm._s(_vm.rowData.spec))]),
        _c("span", [_vm._v("单位：" + _vm._s(_vm.rowData.unit))]),
        _c("span", [
          _vm._v(
            "厂家(上市许可持有人)/产地： " +
              _vm._s(_vm.rowData.manufacturer) +
              _vm._s(_vm.rowData.area ? "/" + _vm.rowData.area : "")
          ),
        ]),
      ]),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "" },
        },
        [
          _vm._v(" " + _vm._s(_vm.tableData) + " "),
          _vm._l(_vm.dynamicColumns, function (column) {
            return _c("el-table-column", {
              key: column.prop,
              attrs: { label: column.label, width: column.width },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        column.prop == "batchId"
                          ? _c("span", [
                              _vm._v(" " + _vm._s(scope.row.isTransport)),
                              _vm.columnFlag == "Y"
                                ? _c("span", [
                                    _vm._v(_vm._s(scope.row.batchId)),
                                  ])
                                : _vm._e(),
                            ])
                          : column.prop == "price"
                          ? _c("span", [
                              _vm._v(" " + _vm._s(scope.row.price) + " "),
                            ])
                          : column.prop == "expDate"
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.expDate != null
                                      ? _vm.$util.getTime(
                                          scope.row.expDate,
                                          "yyyy-MM-dd"
                                        )
                                      : "/"
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                " " + _vm._s(scope.row[column.prop]) + " "
                              ),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
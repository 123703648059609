<template>
  <im-page-modal
      class="security-center"
      v-loading="loading"
      title="安全中心"
      :isShowBack="false"
  >
    <el-col class='sercurity-item'>
      <div class="sercurity-item-c">
        <div class="left-c">
          <el-image style="width: 48px; height: 48px;margin-right:20px" :src="changePasswordImg"/>
          <div style="height:100%;">
            <el-col class='con-text'><span class="logintitle">登录密码</span></el-col>
            <el-col class='con-text'><span class="logininfo">互联网账号存在被盗风险，建议您定期更改密码以保护账户安全</span></el-col>
          </div>
        </div>
        <el-button size="small" style="width: 110px" @click="changeInfo('password')">修改密码</el-button>
      </div>
    </el-col>
    <el-col class='sercurity-item'>
      <div class="sercurity-item-c">
        <div class="left-c">
          <el-image style="width: 48px; height: 48px;margin-right:20px" :src="changePhoneImg"/>
          <div style="height:100%;">
            <el-col class='con-text'><span class="logintitle">账户手机号</span></el-col>
            <el-col class='con-text'><span class="logininfo">可用于登录，可通过手机对个人信息进行修改和重置，提高帐号安全性</span></el-col>
          </div>
        </div>
        <el-button size="small" style="width: 110px" @click="changeInfo('phone')">修改手机号</el-button>
      </div>
    </el-col>
  </im-page-modal>
</template>
<script>
  import { findByUserId } from '@/api/member-center/transactions-using'
  import changePasswordImg from '@/assets/img/member-center/change-password.png'
  import changePhoneImg from '@/assets/img/member-center/change-phone.png'


  export default {
    name: 'security-center',
    data () {
      return {
        loading: false,
        isShowBack: false,
        changePasswordImg,
        changePhoneImg,
        returnSecurity: this.$route.returnSecurity
      }
    },
    mounted () {},
    methods: {
      changeInfo(data) {
        if(data === 'password') {
          this.$router.push({path: '/forget-password', query: {origin: "memberChangePassword",isEdit:true}})
        }else if(data === 'phone') {
          // 验收旧手机号界
          this.$router.push({path: '/change-phone', query: {origin: "memberChangePhone",oldphone:this.$store.getters.userMobile}})
        }
      }
    }
  }
</script>
<style lang="scss">
  .security-center {
    background-color: white;

    .content {
      padding: 0 20px;
    }
  }

  .logintitle {
    position: relative;
    font-size: 16px;
    font-family: PingFangSC-Semibold,PingFang SC;
    font-weight: 600;
    color: #222222;
    line-height: 22px;
    top: -4px;
  }

  .logininfo {
    font-size:14px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(153,153,153,1);
    line-height:20px;
  }

  .changebtn {
    position: absolute;
    width: 104px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #0971eb;
    font-size: 14px;
    font-family: PingFangSC-Medium,PingFang SC;
    font-weight: 500;
    color: #0971eb;
    line-height: 7px;
    right: 408px;
  }

   .sercurity-item {
      margin-top: 16px;
      display: flex;
      align-items: center;
      height: 104px;
      border-radius: 4px;
      border: 1px solid rgba(229, 229, 229, 1);
      padding: 28px 20px;
     .sercurity-item-c{
       display: flex;
       justify-content: space-between;
       align-items: center;
       width: 100%;
     }
      .left-c{
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .con-text {
        text-align: left;
        height: 24px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(34, 34, 34, 1);
        line-height: 24px;
      }
    }
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.isAuthInquiryBox == "Y"
    ? _c(
        "div",
        [
          _c(
            "im-page-modal",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "procurement-plan-list",
              attrs: { title: "发布询价单", isShowBack: false },
            },
            [
              _vm.license.isFailure === "Y"
                ? _c(
                    "div",
                    { staticClass: "aptitudes" },
                    [
                      _vm._v(
                        "温馨提示：" +
                          _vm._s(
                            _vm.userType !== "SALESMAN"
                              ? "您有" +
                                  _vm.license.failureSum +
                                  "张证照已过期,请联系业务员更换,以免影响您的正常采购！"
                              : "该客户有" +
                                  _vm.license.failureSum +
                                  "张证照已过期，以免影响正常采购！"
                          )
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "text" },
                          on: { click: _vm.handleConfirm },
                        },
                        [_vm._v("查看详情")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "page-header-btn" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "import",
                      attrs: { type: "primary" },
                      on: { click: _vm.handleAdd },
                    },
                    [_vm._v(" Excel批量导入询价 ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: { click: _vm.exportExcel },
                        },
                        [_vm._v("导出询价单")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.handleGoToDraft()
                            },
                          },
                        },
                        [_vm._v("我的草稿箱")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "table-padding" },
                [
                  _c(
                    "el-table",
                    {
                      ref: "tablePage",
                      class: {
                        "is-tabledata-empty": _vm.tableData.length === 0,
                      },
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        "default-expand-all": "",
                        border: "",
                        "row-key": "skuId",
                        "tree-props": {
                          children: "otherSkulist",
                          hasChildren: "hasChildren",
                        },
                      },
                      on: { "row-click": _vm.handleRowClick },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          width: "80px",
                          "show-overflow-tooltip": "",
                          align: "center",
                          fixed: "left",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.index || scope.row.index === 0
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.index + 1)),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          585218832
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品编码",
                          prop: "productCode",
                          "min-width": "100px",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品名称",
                          prop: "productNm",
                          "min-width": "200px",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.index || scope.row.index === 0
                                    ? _c("fast-order-search", {
                                        ref: "productNm" + scope.$index,
                                        staticClass: "inputName",
                                        attrs: {
                                          index: scope.row.index,
                                          id: "search" + scope.$index,
                                          productNm: _vm.$util.getGoodsName(
                                            scope.row
                                          ),
                                        },
                                        on: {
                                          getIndex: _vm.getIndex,
                                          fast_order_fu: _vm.selectRow,
                                        },
                                        nativeOn: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              $event.keyCode !== 46
                                            )
                                              return null
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.handleDelete(
                                              scope.row,
                                              scope.$index
                                            )
                                          },
                                        },
                                      })
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.drugNm) +
                                            "(" +
                                            _vm._s(scope.row.productNm) +
                                            ")"
                                        ),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2483176672
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "规格",
                          prop: "spec",
                          "min-width": "100px",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单位",
                          prop: "unit",
                          "min-width": "80px",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "区域",
                          prop: "orgNm",
                          "min-width": "170px",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单价",
                          prop: "price",
                          align: "right",
                          "min-width": "100px",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row.price) + "￥"),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2836026245
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "厂家(上市许可持有人)/产地",
                          prop: "manufacturer",
                          width: "200px",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "件包装",
                          prop: "useCommodityTypes",
                          "min-width": "100px",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row.packTotal || "--")),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          654440221
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "最小购买倍数",
                          prop: "minBuyMultiple",
                          "min-width": "100px",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getRealMinBuyNum(scope.row)) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2543269228
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "库存",
                          prop: "stockQuantity",
                          "min-width": "120px",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.userType === "BUYERS"
                                          ? scope.row.stockQuantity > 100
                                            ? ">100"
                                            : "<100"
                                          : scope.row.stockQuantity
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4263262254
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "80px", fixed: "right" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-row",
                                    { staticClass: "table-edit-row" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "table-edit-row-item" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                padding: "2px 4px",
                                              },
                                              attrs: { size: "small" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteNode(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3001554918
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.tableData.length === 0
                ? _c("div", { staticClass: "empty-default" }, [
                    _c(
                      "div",
                      { staticClass: "empty-box" },
                      [
                        _c("el-image", {
                          staticStyle: { width: "160px", height: "160px" },
                          attrs: { src: _vm.emptyDefault, fit: "fill" },
                        }),
                        _c("p", { staticClass: "empty-tip" }, [
                          _vm._v("暂无信息~"),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c(
                "el-button",
                {
                  staticClass: "addPro",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.addPro()
                    },
                  },
                },
                [_vm._v(" +添加 ")]
              ),
              _c(
                "div",
                {
                  staticClass: "content-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  false
                    ? _c(
                        "div",
                        { staticClass: "left-con" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              staticStyle: { "margin-right": "20px" },
                              on: { change: _vm.handleAllSelect },
                              model: {
                                value: _vm.AllChecked,
                                callback: function ($$v) {
                                  _vm.AllChecked = $$v
                                },
                                expression: "AllChecked",
                              },
                            },
                            [_vm._v("全选")]
                          ),
                          _c("span", { staticClass: "text-detail" }, [
                            _vm._v("共 "),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#0971EB",
                                  padding: "0 2px",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.selectItem.length
                                      ? _vm.selectItem.length
                                      : 0
                                  )
                                ),
                              ]
                            ),
                            _vm._v("种商品，总数量"),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#0971EB",
                                  padding: "0 2px",
                                },
                              },
                              [_vm._v(_vm._s(_vm.totalNum))]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "right-con" },
                    [
                      false
                        ? _c("span", { staticClass: "text-one" }, [
                            _vm._v("合计:"),
                          ])
                        : _vm._e(),
                      false
                        ? _c("span", { staticClass: "text-two" }, [
                            _vm._v(
                              "¥" + _vm._s(_vm.$util.toFixed(_vm.totalPrice))
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn-one",
                          on: {
                            click: () => {
                              this.$refs.AddConsultPrice.open()
                            },
                          },
                        },
                        [_vm._v("保存草稿")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("input-excel", {
                ref: "inputExcel",
                attrs: {
                  tableData: _vm.tableData,
                  resData: _vm.resData,
                  formWay: _vm.formWay,
                },
                on: {
                  uploadResult: _vm.uploadResult,
                  "update:tableData": function ($event) {
                    _vm.tableData = $event
                  },
                  "update:table-data": function ($event) {
                    _vm.tableData = $event
                  },
                  "update:resData": function ($event) {
                    _vm.resData = $event
                  },
                  "update:res-data": function ($event) {
                    _vm.resData = $event
                  },
                  "update:formWay": function ($event) {
                    _vm.formWay = $event
                  },
                  "update:form-way": function ($event) {
                    _vm.formWay = $event
                  },
                },
              }),
              _c("add-consult-price-title", {
                ref: "AddConsultPrice",
                attrs: { consultTitle: _vm.title },
                on: {
                  "update:consultTitle": function ($event) {
                    _vm.title = $event
                  },
                  "update:consult-title": function ($event) {
                    _vm.title = $event
                  },
                  handleSaveDraft: _vm.handleSaveDraft,
                },
              }),
              _vm.visibleIsInputSuccess
                ? _c(
                    "el-dialog",
                    {
                      attrs: {
                        title: "导入提示",
                        top: "30vh",
                        visible: _vm.visibleIsInputSuccess,
                        modal: false,
                        width: "15%",
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.visibleIsInputSuccess = $event
                        },
                      },
                    },
                    [
                      _c("h3", { staticClass: "tip-title" }, [
                        _c("i", { staticClass: "icon-success" }),
                        _vm._v("恭喜你，导入成功！"),
                      ]),
                      _vm.resData.errCount
                        ? _c(
                            "p",
                            { staticClass: "tip-detail" },
                            [
                              _vm._v(
                                "其中有" +
                                  _vm._s(_vm.resData.errCount) +
                                  "个无效编码，详情 "
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.viewNotes(_vm.resData.errList)
                                    },
                                  },
                                },
                                [_vm._v("查看备注")]
                              ),
                              _vm._v(" 。 "),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm.visibleIsInputFail
                ? _c(
                    "el-dialog",
                    {
                      attrs: {
                        title: "导入提示",
                        top: "30vh",
                        visible: _vm.visibleIsInputFail,
                        modal: false,
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.visibleIsInputFail = $event
                        },
                      },
                    },
                    [
                      _c("h3", { staticClass: "tip-title" }, [
                        _c("i", { staticClass: "icon-error" }),
                        _vm._v("导入失败!"),
                      ]),
                      _c("p", { staticClass: "tip-detail" }, [
                        _vm._v("文件格式错误！请检查Excel文件格式"),
                      ]),
                      _c("p", { staticClass: "tip-detail" }, [
                        _vm._v(" 或检查匹配方式是否正确。"),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "查看备注",
                    top: "30vh",
                    width: "1000px",
                    visible: _vm.visibleViewNotes,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.visibleViewNotes = $event
                    },
                  },
                },
                [
                  _c("p", { staticClass: "noViewNotes" }, [
                    _vm._v("以下商品存在编码无效"),
                  ]),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableDataNotes,
                        border: "",
                        "max-height": "200",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", label: "序号", width: "60" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "erpCode",
                          label: "商品编码",
                          "min-width": "120",
                          "show-overflow-tooltip": "",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "productNm",
                          label: "商品名称",
                          "min-width": "120",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "spec", label: "规格" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "unit", label: "单位" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "manuFacturer", label: "生产厂家" },
                      }),
                      _vm.formWay === "INQUIRYBOXDETAILGY"
                        ? _c("el-table-column", {
                            attrs: {
                              prop: "approvalNumber",
                              label: "国药准字",
                            },
                          })
                        : _vm._e(),
                      _vm.formWay === "INQUIRYBOXDETAILKH" ||
                      _vm.formWay === "INQUIRYBOXDETAILSP"
                        ? _c("el-table-column", {
                            attrs: {
                              prop: "barCode",
                              label: "商品条形码(69码)",
                            },
                          })
                        : _vm._e(),
                      _c("el-table-column", {
                        attrs: { prop: "buyNm", label: "购买数量" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "dialog-footer" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              _vm.visibleViewNotes = false
                            },
                          },
                        },
                        [_vm._v("返回")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c("div", { staticClass: "no-auth" }, [_vm._v(" 询价功能暂未开放 ")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue'
import Router from 'vue-router'
import fa from 'element-ui/src/locale/lang/fa'

Vue.use(Router)

export const constantRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login'),
    hidden: true,
    meta: {
      title: '登录',
      requireAuth: false,
    }
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404'),
    hidden: true,
    meta: {
      title: '404',
      requireAuth: false
    }
  },
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/Index'),
    meta: {
      title: '首页',
      keepAlive: true,
      requireAuth: true
    }
  },
  {
    path: '/allCategory',
    name: 'allCategory',
    component: () => import('@/views/Index/allCategory'),
    meta: {
      title: '全部分类',
      requireAuth: true
    }
  },

  {
    path: '/detail/:id',
    name: 'detail',
    component: () => import('@/views/detail'),
    meta: {
      title: '商品详情',
      requireAuth: true
    }
  },
  {
    path: '/order-detail/:id',
    name: 'detail',
    component: () => import('@/views/member-center/order-manage/store-order/order-detail'),
    meta: {
      title: '订单详情',
      requireAuth: true
    }
  },
  {
    path: '/express-detail/:id',
    name: 'detail',
    component: () => import('@/views/member-center/order-manage/store-order/express-detail'),
    meta: {
      title: '物流详情',
      requireAuth: true
    }
  },
  {
    path: '/ICBC-Pay',
    name: 'ICBCPay',
    component: () => import('@/views/pay/ICBCPay'),
    meta: {
      title: '订单支付',
      requireAuth: true
    }
  },
  {
    path: '/shopping-cart',
    name: 'ShoppingCart',
    component: () => import('@/views/shopping-cart'),
    meta: {
      title: '购物车',
      requireAuth: true
    }
  },
  {
    path: '/commodity-list',
    name: 'CommodityList',
    component: () => import('@/views/commodity-list'),
    meta: {
      title: '商品列表',
      requireAuth: true
    }
  },
  {
    path: '/refunds-replay-list',
    name: 'refundReplayList',
    component: () => import('@/views/member-center/order-manage/refunds/components/refunds-replay-list'),
    meta: {
      title: '退货单',
      requireAuth: true
    }
  },
  {
    path: '/refunds-replay-list-show',
    name: 'refundReplayListShow',
    component: () => import('@/views/member-center/order-manage/refunds-yw/components/refunds-replay-list-show'),
    meta: {
      title: '退货清单-业务员',
      requireAuth: true
    }
  },
  {
    path: '/refunds-replay-list-yw',
    name: 'refundReplayList',
    component: () => import('@/views/member-center/order-manage/refunds-yw/components/refunds-replay-list-yw'),
    meta: {
      title: '退货单',
      requireAuth: true
    }
  },
  {
    path: '/refunds-detail-yw',
    name: 'refundsDetail',
    component: () => import('@/views/member-center/order-manage/refunds-yw/components/refunds-detail'),
    meta: {
      title: '业务员退货详情',
      requireAuth: true
    }
  },
  {
    path: '/refunds-detail',
    name: 'refundsDetail',
    component: () => import('@/views/member-center/order-manage/refunds/components/refunds-detail'),
    meta: {
      title: '退货详情',
      requireAuth: true
    }
  },
  //支付成功
  {
    path: '/pay-success/:id',
    name: 'pay-success',
    component: () => import('@/views/shopping-cart/components/pay-success'),
    meta: {
      title: '支付成功',
      requireAuth: true
    }
  },
  //支付页面
  {
    path: '/cashier/:id',
    name: 'pay-success',
    component: () => import('@/views/shopping-cart/components/cashier'),
    meta: {
      title: '支付',
      requireAuth: true
    }
  },
  {
    path: '/member-center',
    name: 'MemberCenter',
    component: () => import('@/views/member-center'),
    children: [
      {
        path: '/member-center',
        name: 'MemberCenter',
        component: () => import('@/views/member-center/home'),
        meta: {
          title: '会员中心首页',
          requireAuth: true,
          roles: ['SALESMAN']
        }
      },
      {
        path: '/member-center/home',
        name: 'MemberCenter',
        component: () => import('@/views/member-center/home'),
        meta: {
          title: '会员中心首页',
          requireAuth: true,
          roles: ['BUYERS']
        }
      },
      {
        path: '/member-center/order-manage/store-order',
        name: 'StoreOrder',
        component: () => import('@/views/member-center/order-manage/store-order'),
        meta: {
          title: '商城订单',
          requireAuth: true,
          roles: ['BUYERS', 'SALESMAN']
        }
      },
      {
        path: '/member-center/order-manage/refunds',
        name: 'orderRefunds',
        component: () => import('@/views/member-center/order-manage/refunds'),
        meta: {
          title: '退货退款',
          requireAuth: true,
          roles: ['BUYERS']
        }
      },
      {
        path: '/member-center/order-manage/refunds-yw',
        name: 'orderRefunds',
        component: () => import('@/views/member-center/order-manage/refunds-yw'),
        meta: {
          title: '退货退款',
          requireAuth: true,
          roles: ['SALESMAN']
        }
      },
      {
        path: '/member-center/order-manage/history-purchase',
        name: 'HistoryPurchase',
        component: () => import('@/views/member-center/order-manage/history-purchase'),
        meta: {
          title: '历史采购',
          requireAuth: true,
          roles: ['BUYERS']
        }
      },
      // 资产管理 下游客户
      {
        path: '/member-center/property-manage/my-coupon',
        name: 'myCoupon',
        component: () => import('@/views/member-center/property-manage/my-coupon'),
        meta: {
          title: '我的优惠券',
          requireAuth: true,
          roles: ['BUYERS']
        }
      },
      // 资产管理 下游客户
      {
        path: '/member-center/property-manage/my-integral',
        name: 'myIntegral',
        component: () => import('@/views/member-center/property-manage/my-integral'),
        meta: {
          title: '我的积分',
          requireAuth: true,
          roles: ['BUYERS']
        }
      },
      {
        path: '/member-center/property-manage/intercourse-bill',
        name: 'IntercourseBill',
        component: () => import('@/views/member-center/property-manage/intercourse-bill'),
        meta: {
          title: '往来账单核对',
          requireAuth: true,
          roles: ['BUYERS', 'SALESMAN']
        }
      },
      // 交易运用 下游客户
      {
        path: '/member-center/transactions-using/my-salesman',
        name: 'mySalesman',
        component: () => import('@/views/member-center/transactions-using/my-salesman'),
        meta: {
          title: '我的业务员',
          requireAuth: true,
          roles: ['BUYERS']
        }
      },
      {
        path: '/member-center/transactions-using/good-collection',
        name: 'goodCollection',
        component: () => import('@/views/member-center/transactions-using/good-collection'),
        meta: {
          title: '商品收藏',
          requireAuth: true,
          roles: ['BUYERS']
        }
      },
      {
        path: '/member-center/transactions-using/procurement-plan',
        name: 'procurementPlan',
        component: () => import('@/views/member-center/transactions-using/procurement-plan'),
        meta: {
          title: '采购计划',
          requireAuth: true,
          roles: ['BUYERS']
        }
      },
      {
        path: '/member-center/transactions-using/consult-price',
        name: 'consultPrice',
        component: () => import('@/views/member-center/transactions-using/consult-price'),
        meta: {
          title: '我要询价',
          requireAuth: true,
          roles: ['BUYERS', 'SALESMAN']
        }
      },
      {
        path: '/member-center/transactions-using/consult-price-drafts',
        name: 'consultPriceDrafts',
        component: () => import('@/views/member-center/transactions-using/consult-price-drafts'),
        meta: {
          title: '询价草稿箱',
          requireAuth: true,
          roles: ['BUYERS', 'SALESMAN']
        }
      },
      {
        path: '/member-center/transactions-using/product-code-list',
        name: 'productCodeList',
        component: () => import('@/views/member-center/transactions-using/product-code-list'),
        meta: {
          title: '商品对码表',
          requireAuth: true,
          roles: ['BUYERS', 'SALESMAN']
        }
      },
      {
        path: '/member-center/transactions-using/quotation-list',
        name: 'quotationList',
        component: () => import('@/views/member-center/transactions-using/quotation-list'),
        meta: {
          title: '业务员报价单',
          requireAuth: true,
          roles: ['SALESMAN']
        }
      },
      {
        path: '/member-center/transactions-using/approval',
        name: 'approval',
        component: () => import('@/views/member-center/transactions-using/approval'),
        meta: {
          title: '审批流',
          requireAuth: true,
          roles: ['SALESMAN']
        }
      },
      // 设置 下游客户
      {
        path: '/member-center/personal-center/corporate-info',
        name: 'CorporateInfo',
        component: () => import('@/views/member-center/personal-center/corporate-info'),
        meta: {
          title: '企业信息',
          requireAuth: true,
          roles: ['BUYERS']
        }
      },
      {
        path: '/member-center/personal-center/account-manage',
        name: 'AccountManage',
        component: () => import('@/views/member-center/personal-center/account-manage'),
        meta: {
          title: '账户管理',
          requireAuth: true,
          roles: ['BUYERS']
        }
      },
      {
        path: '/member-center/personal-center/my-aptitudes',
        name: 'MyAptitudes',
        component: () => import('@/views/member-center/personal-center/my-aptitudes'),
        meta: {
          title: '我的资质',
          requireAuth: true,
          roles: ['BUYERS']
        }
      },
      // 客户服务 业务员
      {
        path: '/member-center/customer-service/my-customer',
        name: 'MyCustomer',
        component: () => import('@/views/member-center/customer-service/my-customer'),
        meta: {
          title: '我的客户',
          requireAuth: true,
          roles: ['SALESMAN']
        }
      },
      {
        path: '/member-center/customer-service/analysis',
        name: 'analysis',
        component: () => import('@/views/member-center/customer-service/analysis'),
        meta: {
          title: '指标分析',
          requireAuth: true,
          roles: ['SALESMAN']
        }
      },
      {
        path: '/member-center/customer-service/current-account',
        name: 'currentAccount',
        component: () => import('@/views/member-center/customer-service/current-account'),
        meta: {
          title: '往来账单核对',
          requireAuth: true,
          roles: ['SALESMAN']
        }
      },
      {
        path: '/member-center/customer-service/expanding-customers/index',
        name: 'expandingCustomers',
        component: () => import('@/views/member-center/customer-service/expanding-customers'),
        meta: {
          title: '我要拓客',
          requireAuth: true,
          roles: ['SALESMAN']
        }
      },
      // 委托配送 业务员
      {
        path: '/member-center/consigned-delivery/interface-order/order',
        name: 'interfaceOrder',
        component: () => import('@/views/member-center/consigned-delivery/interface-order'),
        meta: {
          title: '委托配送订单',
          requireAuth: true,
          roles: ['SALESMAN']
        }
      },
      {
        path: '/member-center/consigned-delivery/out-of-stock-order/index',
        name: 'outOfStockOrder',
        component: () => import('@/views/member-center/consigned-delivery/out-of-stock-order'),
        meta: {
          title: '委托配送缺货订单',
          requireAuth: true,
          roles: ['SALESMAN']
        }
      },
      {
        path: '/member-center/consigned-delivery/unmatched-datas/index',
        name: 'unmatchedDatas',
        component: () => import('@/views/member-center/consigned-delivery/unmatched-datas'),
        meta: {
          title: '未匹配数据列表',
          requireAuth: true,
          roles: ['SALESMAN']
        }
      },
      {
        path: '/member-center/consigned-delivery/data-execution/index',
        name: 'dataExecution',
        component: () => import('@/views/member-center/consigned-delivery/data-execution'),
        meta: {
          title: '订单执行情况',
          requireAuth: true,
          roles: ['SALESMAN']
        }
      },
      // 设置 业务员
      {
        path: '/member-center/personal-center/security-center',
        name: 'SecurityCenter',
        component: () => import('@/views/member-center/personal-center/security-center'),
        meta: {
          title: '安全中心',
          requireAuth: true,
          roles: ['SALESMAN']
        }
      },
      {
        path: '/member-center/personal-center/user-info',
        name: 'UserInfo',
        component: () => import('@/views/member-center/personal-center/user-info'),
        meta: {
          title: '账户管理',
          requireAuth: true,
          roles: ['SALESMAN']
        }
      }
    ],
    meta: {
      title: '会员专区',
      requireAuth: true,
      roles: ['BUYERS', 'SALESMAN']
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register'),
    hidden: true,
    meta: {
      title: '注册',
      requireAuth: false
    }
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: () => import('@/views/login/forget-password'),
    hidden: true,
    meta: {
      title: '忘记密码',
      requireAuth: false
    }
  },
  {
    path: '/fast-order',
    name: 'fastOrder',
    component: () => import('@/views/promotion-zone/fast-order'),
    hidden: true,
    meta: {
      title: '飞速下单',
      requireAuth: true
    }
  },
  {
    path: '/brand-center',
    name: 'brandCenter',
    component: () => import('@/views/promotion-zone/brand-center'),
    hidden: true,
    meta: {
      title: '品牌中心'
    }
  },
  {
    path: '/brand-detail/:id',
    name: 'brandDetail',
    component: () => import('@/views/promotion-zone/brand-center/detail'),
    hidden: true,
    meta: {
      title: '品牌详情'
    }
  },
  {
    path: '/discount-package',
    name: 'discountPackage',
    component: () => import('@/views/promotion-zone/discount-package'),
    hidden: true,
    meta: {
      title: '优惠套餐',
      requireAuth: true
    }
  },
  {
    path: '/activity-decorate',
    name: 'activityDecorate',
    component: () => import('@/views/promotion-zone/activity-decorate'),
    hidden: true,
    meta: {
      title: '活动专区',
      requireAuth: true
    }
  },
  {
    path: '/coupon-center',
    name: 'couponCenter',
    component: () => import('@/views/promotion-zone/coupon-center'),
    hidden: true,
    meta: {
      title: '领券中心'
    }
  },
  {
    path: '/discount-time',
    name: 'discountTime',
    component: () => import('@/views/promotion-zone/discount-time'),
    hidden: true,
    meta: {
      title: '限时折扣'
    }
  },
  {
    path: '/info-channel',
    name: 'InfoChannel',
    component: () => import('@/views/promotion-zone/info-channel'),
    hidden: true,
    meta: {
      title: '资讯中心',
      requireAuth: false
    }
  },
  {
    path: '/video-channel',
    name: 'VideoChannel',
    component: () => import('@/views/promotion-zone/video-channel'),
    hidden: true,
    meta: {
      title: '视频资讯',
      requireAuth: false
    }
  },
  {
    path: '/info-detail/:id',
    name: 'InfoDetail',
    component: () => import('@/views/promotion-zone/info-channel/detail'),
    hidden: true,
    meta: {
      title: '资讯详情',
      requireAuth: false,
    }
  },
  {
    path: '/change-phone',
    name: 'ChangePhone',
    component: () => import('@/views/member-center/personal-center/change-phone'),
    hidden: true,
    meta: {
      title: '修改手机号',
      requireAuth: false
    }
  },
  {
    path: '/member-center/consigned-delivery/interface-order/success',
    name: 'interfaceOrderSuccess',
    component: () => import('@/views/member-center/consigned-delivery/interface-order/success'),
    hidden: true,
    meta: {
      title: '委托配送下单成功'
    }
  },
  {
    path: '/member-center/consigned-delivery/modify-price',
    name: 'modifyPrice',
    component: () => import('@/views/member-center/consigned-delivery/modify-price'),
    hidden: true,
    meta: {
      title: '委托配送调价'
    }
  },
  //往来账单支付成功页面
  {
    path: '/intercourse-success/:id',
    name: 'intercourseSuccess',
    hidden: true,
    component: () => import('@/views/member-center/property-manage/intercourse-success'),
    meta: {
      title: '支付成功'
    }
  },
  //到货提醒
  {
    path: '/arrival-reminder',
    name: 'arrivalReminder',
    hidden: true,
    component: () => import('@/views/arrival-reminder/index'),
    meta: {
      title: '到货提醒'
    }
  }
]

// 解决侧边导航栏vue-router在3.0版本以上重复点击菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export const asyncRoutes = [
  // 404 page must be placed at the end !!!
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior (to, from, savedPosition) {
    // keep-alive 返回缓存页面后记录浏览位置
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition
    }
    // 异步滚动操作
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 0)
    })
  },
  routes: constantRoutes
})

const router = createRouter()

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router

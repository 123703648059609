<template>
    <div class="consult-price-drafts-page">
         <keep-alive :include="['ConsultPriceDraftsList']">
            <component
                :is="currentView"
                :params="params"
                @change-page="handleChangePage"
            />
        </keep-alive>
    </div>
</template>
<script>
import ConsultPriceDraftsList from './list'
import ConsultPriceDraftsUpdate from './add-update'
export default {
    components:{
     ConsultPriceDraftsList,
     ConsultPriceDraftsUpdate
    },
    data(){
        return{
           currentView: 'ConsultPriceDraftsList',
            params: {
                rowData: {},
                refreshList: false,
                shoplist:[]
            },
            editType: ''
        }
    },
    methods: {
    handleChangePage(data) {
      this.params.rowData = {}
      this.params.refreshList = false
      if (data.params.rowData) {
        this.params.rowData = data.params.rowData
      }
       if (data.params.shopList) {
        this.params.shopList = data.params.shopList
      }
      this.params.refreshList = data.params.refreshList
      this.currentView = data.page
      this.params.editType = data.params.editType
    }
  }
}
</script>
<style scoped>
    .consult-price-drafts-page{
      height:100%;
      background-color:white;
    } 
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "member-center-home" }, [
    _vm.index_YW
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "header" },
              [
                !_vm.editSign
                  ? [
                      _c(
                        "p",
                        {
                          staticClass: "sign",
                          on: { click: _vm.handleEditSignBol },
                        },
                        [
                          _c("i", { staticClass: "el-icon-edit" }),
                          _c("span", { staticClass: "sign-msg" }, [
                            _vm._v(
                              _vm._s(
                                _vm.userSignName.signature
                                  ? _vm.userSignName.signature
                                  : "点击编辑文字设置自己的签名"
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c("p", { staticClass: "login-msg" }, [
                        _vm._v(
                          "您是第" +
                            _vm._s(_vm.loginCount) +
                            "次登录，您上一次登录：" +
                            _vm._s(_vm.loginLastTime)
                        ),
                      ]),
                    ]
                  : _c(
                      "p",
                      { staticClass: "sign" },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "sign-input",
                            attrs: {
                              placeholder: "请输入签名",
                              clearable: "",
                              autofocus: _vm.autofocus,
                            },
                            on: { blur: _vm.handleEditSign },
                            model: {
                              value: _vm.userSignName.signature,
                              callback: function ($$v) {
                                _vm.$set(_vm.userSignName, "signature", $$v)
                              },
                              expression: "userSignName.signature",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit",
                              attrs: { slot: "prefix" },
                              slot: "prefix",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
              ],
              2
            ),
            _c("div", { staticClass: "member" }, [
              _c("div", { staticClass: "member-info" }, [
                _vm._m(0),
                _c("div", { staticClass: "member-content" }, [
                  _c("div", { staticClass: "merchant-name" }, [
                    _vm._v(_vm._s(_vm.buyersVo.buyersNm)),
                  ]),
                  _c("div", { staticClass: "type" }, [
                    _c("span", [
                      _vm._v(
                        "会员类型：" +
                          _vm._s(_vm.userDetail.memberDetailsVo.title)
                      ),
                    ]),
                    _c("span", [
                      _vm._v(
                        "客户类型：" +
                          _vm._s(_vm.userDetail.memberDetailsVo.customerType)
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("ul", { staticClass: "member-boxs" }, [
                _c(
                  "li",
                  { staticClass: "box" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: "/member-center/property-manage/my-coupon",
                        },
                      },
                      [
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            _vm._s(
                              _vm.userDetail.memberDetailsVo.couponsNum || 0
                            )
                          ),
                          _c("span", [_vm._v("张")]),
                        ]),
                        _c("p", [_vm._v("优惠券")]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  { staticClass: "box" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: "/member-center/property-manage/my-integral",
                        },
                      },
                      [
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            _vm._s(_vm.userDetail.memberDetailsVo.integral || 0)
                          ),
                          _c("span", [_vm._v("分")]),
                        ]),
                        _c("p", [_vm._v("积分")]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm.userDetail.memberDetailsVo.isCredit === "Y"
                  ? _c("li", { staticClass: "box" }, [
                      _c("div", { staticClass: "number money" }, [
                        _vm._v(
                          _vm._s(
                            _vm.userDetail.memberDetailsVo.availableCredit || 0
                          )
                        ),
                        _c("span", [_vm._v("元")]),
                      ]),
                      _c("p", [_vm._v("剩余可用额度")]),
                      _vm.userDetail.isOverdue === "Y"
                        ? _c("span", { staticClass: "erpTag" }, [
                            _vm._v("账期逾期"),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "member-main" }, [
              _c(
                "div",
                { staticClass: "about-order" },
                [
                  _c("div", { staticClass: "my-order" }, [
                    _c("h4", { staticClass: "module-title" }, [
                      _vm._v("我的订单"),
                    ]),
                    _c("div", { staticClass: "my-oder-boxs" }, [
                      _c(
                        "div",
                        {
                          staticClass: "order-box",
                          on: {
                            click: function ($event) {
                              return _vm.jumpOrder("WAIT_PAY")
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "order-icon unpaid" }, [
                            _c("span", { staticClass: "badge" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.userDetail.orderStateVos.toBePaid || 0
                                )
                              ),
                            ]),
                          ]),
                          _c("p", [_vm._v("待支付")]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "order-box",
                          on: {
                            click: function ($event) {
                              return _vm.jumpOrder("WAIT_SEND")
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "order-icon library" }, [
                            _c("span", { staticClass: "badge" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.userDetail.orderStateVos.toBeDelivered ||
                                    0
                                )
                              ),
                            ]),
                          ]),
                          _c("p", [_vm._v("待发货")]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "order-box",
                          on: {
                            click: function ($event) {
                              return _vm.jumpOrder("SEND")
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "order-icon receive" }, [
                            _c("span", { staticClass: "badge" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.userDetail.orderStateVos.toBeReceived || 0
                                )
                              ),
                            ]),
                          ]),
                          _c("p", [_vm._v("待收货")]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "order-box",
                          on: {
                            click: function ($event) {
                              return _vm.jumpOrder("退货/退款")
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "order-icon return" }, [
                            _c("span", { staticClass: "badge" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.userDetail.orderStateVos.returnGoodes || 0
                                )
                              ),
                            ]),
                          ]),
                          _c("p", [_vm._v("退货/退款")]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "order-box",
                          on: {
                            click: function ($event) {
                              return _vm.jumpOrder("")
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "order-icon all-order" }),
                          _c("p", [_vm._v("全部订单")]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("order-list", {
                    ref: "orderList",
                    attrs: {
                      "remote-method": _vm.queryData,
                      "time-value": _vm.timeValue,
                      "page-number": 5,
                      "pager-count": 5,
                      data: _vm.tableData,
                    },
                    on: {
                      "update:timeValue": function ($event) {
                        _vm.timeValue = $event
                      },
                      "update:time-value": function ($event) {
                        _vm.timeValue = $event
                      },
                      "update:data": function ($event) {
                        _vm.tableData = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "aside-right" }, [
                _c("div", { staticClass: "about-account" }, [
                  _c("h4", { staticClass: "module-title" }, [
                    _vm._v("账户相关"),
                  ]),
                  _c("ul", { staticClass: "account-main" }, [
                    _c(
                      "li",
                      { staticClass: "acccount-item" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: "/member-center/transactions-using/procurement-plan",
                            },
                          },
                          [
                            _c("span", { staticClass: "account-icon plan" }),
                            _vm._v("采购计划 "),
                            _c("el-divider", {
                              attrs: { direction: "vertical" },
                            }),
                            _vm._v(" 共有"),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.userDetail.memberDetailsVo.procurementPlan
                                )
                              ),
                            ]),
                            _vm._v("套模板 "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      { staticClass: "acccount-item" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: "/member-center/transactions-using/good-collection",
                            },
                          },
                          [
                            _c("span", { staticClass: "account-icon collect" }),
                            _vm._v("我的收藏 "),
                            _c("el-divider", {
                              attrs: { direction: "vertical" },
                            }),
                            _vm._v(" 共有"),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.userDetail.memberDetailsVo.myCollection
                                )
                              ),
                            ]),
                            _vm._v("种品规 "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      { staticClass: "acccount-item" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: "/member-center/transactions-using/my-salesman",
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "account-icon salesman",
                            }),
                            _vm._v("我的业务员 "),
                            _c("el-divider", {
                              attrs: { direction: "vertical" },
                            }),
                            _vm._v(" 共有"),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.userDetail.memberDetailsVo.mySalesman
                                )
                              ),
                            ]),
                            _vm._v("位业务员 "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "browsing-history" }, [
                  _c("h4", { staticClass: "module-title" }, [
                    _vm._v("浏览记录"),
                  ]),
                  _c(
                    "ul",
                    { staticClass: "record-container" },
                    _vm._l(_vm.historyList, function (item, index) {
                      return _c(
                        "router-link",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: index < 6,
                              expression: "index < 6",
                            },
                          ],
                          key: index,
                          attrs: { to: { path: `/detail/` + item.productId } },
                        },
                        [
                          _c("li", { staticClass: "record" }, [
                            _c("img", {
                              attrs: {
                                alt: "",
                                src: item.picUrl || _vm.productDefaultImg,
                              },
                              on: {
                                error: function ($event) {
                                  item.picUrl = _vm.productDefaultImg
                                },
                              },
                            }),
                            _c("p", [_vm._v(_vm._s(item.productNm))]),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ]),
              ]),
            ]),
            _c("swiper", {
              staticClass: "recommend-swiper",
              attrs: { "slides-per-view": 3, type: "recommendFor" },
            }),
          ],
          1
        )
      : _c("div", [_c("YWindex")], 1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "portrait" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/img/member-center/portrait.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("page-modal", { attrs: { title: "收银台", active: 2 } }, [
    _c("div", { staticClass: "cashier-container" }, [
      _c("div", { staticClass: "order-prompt" }, [
        _c("div", { staticClass: "order-state" }, [
          _c("span", [
            _vm.overtime != 0
              ? _c("i", {
                  staticClass: "el-icon-success",
                  attrs: { size: "32" },
                })
              : _c("i", {
                  staticClass: "el-icon-error",
                  staticStyle: { color: "red" },
                  attrs: { size: "32" },
                }),
            _vm.overtime == 1
              ? _c("span", [_vm._v("订单提交成功，请尽快付款！")])
              : _vm.overtime == 0
              ? _c("span", { staticStyle: { "padding-right": "10px" } }, [
                  _vm._v("您的订单超时未支付已取消"),
                ])
              : _c("span", [_vm._v("订单交易成功！")]),
            _vm.overtime == 1
              ? _c("span", [_vm._v("订单号：" + _vm._s(_vm.receiver.orderNum))])
              : _vm._e(),
          ]),
          _c("p", [
            _vm._v(" 应付金额"),
            _c("span", [
              _vm._v(_vm._s(_vm.$util.toFixed(_vm.receiver.orderTotalAmount))),
            ]),
            _vm._v("元"),
          ]),
        ]),
        _vm.overtime == 1
          ? _c("div", { staticClass: "oder-sum-container" }, [
              _c(
                "p",
                [
                  _vm._v("请您在 "),
                  _c("span", { staticClass: "red" }, [
                    _vm._v(
                      _vm._s(
                        _vm.countdown.dd == "00" ? "" : _vm.countdown.dd + "天"
                      ) +
                        " " +
                        _vm._s(_vm.countdown.hh) +
                        "时" +
                        _vm._s(_vm.countdown.mm) +
                        "分" +
                        _vm._s(_vm.countdown.ss) +
                        "秒"
                    ),
                  ]),
                  _vm._v(" 内完成支付，否则订单会被自动取消 "),
                  _c(
                    "el-button",
                    {
                      staticClass: "info",
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          _vm.isExpand = !_vm.isExpand
                        },
                      },
                    },
                    [
                      _vm._v(" 订单信息"),
                      _c("i", {
                        staticClass: "toggle-arrow",
                        class: _vm.isExpand
                          ? "el-icon-arrow-up"
                          : "el-icon-arrow-down",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ])
          : _vm.overtime == 0
          ? _c("div", { staticClass: "oder-sum-container" }, [
              _c("p", [_vm._v("订单已取消")]),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isExpand,
              expression: "isExpand",
            },
          ],
          staticClass: "cashier-info",
        },
        [
          _c("div", { staticClass: "cashier-title" }, [_vm._v("订单信息")]),
          _c("div", { staticClass: "cashier-content" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("订单编号")]),
              _c(
                "div",
                { staticClass: "text" },
                [
                  _vm._v(_vm._s(_vm.receiver.orderNum) + " "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.goDetails(_vm.orderId)
                        },
                      },
                    },
                    [_vm._v("查看订单详情")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("交易金额")]),
              _c("div", { staticClass: "text" }, [
                _vm._v(
                  _vm._s(
                    _vm.$util.toFixed(_vm.receiver.transactionTotalAmount)
                  ) + "元"
                ),
              ]),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("购买时间")]),
              _c("div", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.receiver.createDate)),
              ]),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("收货人")]),
              _c("div", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.receiver.receiverName)),
              ]),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("联系电话")]),
              _c("div", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.receiver.receiverMobile)),
              ]),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("收货地址")]),
              _c("div", { staticClass: "text" }, [
                _c("span", [_vm._v(_vm._s(_vm.receiver.receiverAddr))]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm.overtime != 0 && _vm.orderTotalAmount != 0
        ? _c(
            "div",
            { staticClass: "cashier-pay" },
            [
              _vm.receiver.paymentWayCode !== "CREDIT"
                ? _c(
                    "div",
                    { staticClass: "cashier-info cashier-initial" },
                    [
                      _vm._l(_vm.payWayList, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.paymentWayId,
                            staticClass: "flexCash",
                            class: item.select ? "selected" : "unselected",
                            on: {
                              click: function ($event) {
                                return _vm.changePay(item)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "flex" }, [
                              _c("div", { staticClass: "selection-icon" }),
                              _c("img", {
                                attrs: { src: item.payWayLogo, alt: "" },
                              }),
                              _c("p", [_vm._v(_vm._s(item.paymentWayName))]),
                            ]),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.select,
                                    expression: "item.select",
                                  },
                                ],
                                staticClass: "payment-amount",
                              },
                              [
                                _vm._v("支付"),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.receiver.orderTotalAmount)),
                                ]),
                                _vm._v("元"),
                              ]
                            ),
                          ]
                        )
                      }),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn-choose",
                          attrs: {
                            type: "primary",
                            loading: _vm.payBtnLoading,
                            disabled: _vm.payBtnLoading,
                          },
                          on: { click: _vm.goToPay },
                        },
                        [_vm._v("立即支付")]
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "el-dialog",
                {
                  attrs: {
                    width: "600px",
                    visible: _vm.dialogPayVisible,
                    "show-close": false,
                    "close-on-click-modal": false,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogPayVisible = $event
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(" 请您在 "),
                    _c("span", { staticStyle: { color: "#FF3B30" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.countdown.dd == "00"
                            ? ""
                            : _vm.countdown.dd + "天"
                        ) +
                          " " +
                          _vm._s(_vm.countdown.hh) +
                          "时" +
                          _vm._s(_vm.countdown.mm) +
                          "分" +
                          _vm._s(_vm.countdown.ss) +
                          "秒"
                      ),
                    ]),
                    _vm._v(" 内完成支付，否则订单会被自动取消 "),
                  ]),
                  _c("div", { staticClass: "payVisible" }, [
                    _c(
                      "div",
                      { staticClass: "payVisible-left" },
                      [
                        _c("p", [_vm._v("支付成功请点击")]),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.goDetail(_vm.orderId)
                              },
                            },
                          },
                          [_vm._v("已完成付款")]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "payVisible-line" }),
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("遇到问题请点击")]),
                        _c("el-button", { on: { click: _vm.goToPay } }, [
                          _vm._v("重新付款"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "payVisible-p" }, [
                    _c("p", [_vm._v("注：重新付款前，请关闭之前的付款页面")]),
                    _c("p", [
                      _vm._v("如有疑问或需要帮助，请联系客服 "),
                      _c("span", [_vm._v("0592-8128032")]),
                    ]),
                  ]),
                ]
              ),
              [_c("div", { domProps: { innerHTML: _vm._s(_vm.payFormHtml) } })],
            ],
            2
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
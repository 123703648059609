import {ApiGet, ApiPost} from "@/api";

/**
 * @description 委托配送--下单列表
 */
export const pageDistributionOrderList = data => ApiPost('/distributionOrder/pageDistributionOrderList',data)

/**
 * @description 委托配送--调价
 */
export const adjustPrice = data => ApiPost('/distributionOrderItem/adjustPrice',data)

/**
 * @description 委托配送--批量删除
 */
export const batchDeleteDistributionOrder = data => ApiPost('/distributionOrder/batchDeleteDistributionOrder',data)

/**
 * @description 委托配送--批量下单
 */
export const commissionedOrder = data => ApiPost('/pc/member/distributionOrder/batchSave',data)

/**
 * @description 委托配送--重新匹配
 */
export const rematch = data => ApiPost('/distributionOrder/rematch',data)

/**
 * @description 从索引中分页查询 商品 信息
 */
export const pageIndexProduct = data => ApiPost('/product/pageIndexProduct',data)

/**
 * @description 查询导入模式列表
 */
export const pageImportModes = data => ApiPost('/importMode/pageImportModes',data)

/**
 * @description 新增药品对比
 */
export const saveModelDrugComparison = data => ApiPost('/distributionOrder/rematchOrder',data)

/**
 * @description 新增药店对比
 */
export const rematchOrderItem = data => ApiPost('/distributionOrder/rematchOrderItem',data)

/**
 * @description 订单识别码列表
 */
export const getOrderInterfaceConfigRemarkList = data => ApiGet('/orderInterfaceConfig/getOrderInterfaceConfigRemarkLis',data)

/**
 * @description 药品对比详情查询
 */
export const getModelDrugComparison = data => ApiPost('/modelDrugComparison/getModelDrugComparison',data)

/**
 * @description 新委托配送查询
 */
export const pageDistributionOrders = data => ApiPost('/distributionOrder/pageDistributionOrders',data)
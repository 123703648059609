<template>
  <div class="procurement-plan-page">
    <keep-alive :include="['ProcurementPlanList']">
      <component
          :is="currentView"
          :params="params"
          @change-page="handleChangePage"
      />
    </keep-alive>
  </div>
</template>
<script>
  import ProcurementPlanList from './list'
  import ProcurementPlanUpdate from './add-update'

  export default {
    components: {
      ProcurementPlanList,
      ProcurementPlanUpdate
    },
    data () {
      return {
        currentView: 'ProcurementPlanList',
        params: {
          rowData: {},
          refreshList: false
        },
        editType: ''
      }
    },
    mounted() {
      this.$emit('handle-change-page', {
        text: '采购计划',
        index: '5-3',
        url: '/member-center/transactions-using/procurement-plan',
      })
    },
    methods: {
      handleChangePage (data) {
        this.params.rowData = {}
        this.params.refreshList = false
        if (data.params.rowData) {
          this.params.rowData = data.params.rowData
        }
        this.params.refreshList = data.params.refreshList
        this.currentView = data.page
        this.params.editType = data.params.editType
      }
    }
  }
</script>
<style scoped>
  .procurement-plan-page {
    height: 100%;
    background-color: white;
  }
</style>

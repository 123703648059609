<template>
  <div class="coupon-center-page">
    <!-- 搜索、导航栏 -->
    <Search :index-nav="true" :panelNav="true"/>
    <div class="coupon-center-content">
      <!-- 领取中心banner  -->
      <div class="coupon-banner-bg"></div>

      <div class="coupon-item-box">
         <template v-for="(item,index) in tableData" v-if="item.isShow === 'Y'">
          <el-row
            :class="{'coupon-item-content':'coupon-item-content', 'has-used': item.isUse === 'Y' ,'has-pass': item.remainQuantity <= 0}"
            :style="{'margin-right': index === tableData.lenght ? 0 : '10px'}"
            :key="item.couponUseSituationId"
          >
            <template v-if="item.remainQuantity <= 0">
              <img class="coupon-pass-time" src="../../../assets/img/promotion-zone/coupon-center/coupon-pass-time.png"
                   alt="">
            </template>
            <el-col class="col-1">
              <template v-if="item.couponType === 'FULL_REDUCTION'">
                <span class="col-1-full">&yen;</span>
                <span class="col-1-discount">{{item.fullReductionAmount}}</span>
                <span class="col-1-cont">满{{item.fullAmount}}元使用</span>
              </template>
              <template v-else>
                <span class="col-1-discount">{{item.discount}}</span>
                <span class="col-1-2-discount-text">折</span>
                <span class="col-1-cont">特定商品{{item.discount}}折</span>
              </template>
            </el-col>
            <el-col class="col-3-text">
              <p>
                <span v-if="item.couponType === 'FULL_REDUCTION'" class="coupon-type-text">[满减券]</span>
                <span v-else class="coupon-type-text">[折扣券]</span>
                {{item.couponNm}}
                <el-tooltip v-if="item.illustrate" class="item" effect="dark" :content="item.illustrate" placement="top-start">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </p>
              <p v-if="item.useTimeType === 'IN_THE_DAY'">领券当日起{{item.useValidDay}}天内可用</p>
              <p v-else-if="item.useTimeType === 'TIMES_OF_THE_DAY'">领券次日起{{item.useValidDay}}天内可用</p>
              <p v-else>{{item.useStartTime.split(' ')[0].replace(new RegExp("-","gm"),".")}} - {{item.useEndTime.split(' ')[0].replace(new RegExp("-","gm"),".")}}</p>
            </el-col>
<!--            <el-button class="use-coupon-btn" :disabled="item.remainQuantity <= 0">-->
<!--              <span v-if="item.remainQuantity <= 0">立<br>即<br>领<br>取</span>-->
<!--              <span v-else-if="!item.receivedQuantity" @click="handleReceive(item)">立<br>即<br>领<br>取</span>-->
<!--              <router-link :to="{path: '/'}"><span v-if="item.receivedQuantity">去<br>使<br>用</span></router-link>-->
<!--            </el-button>-->
            <el-button class="use-coupon-btn" :disabled="item.remainQuantity <= 0" v-if="item.isReciever === 'N'" @click="() => { $router.push('/') }">
              <span>去<br>使<br>用</span>
            </el-button>
            <el-button class="use-coupon-btn" :disabled="item.remainQuantity <= 0" v-else-if="item.remainQuantity <= 0">
              <span>立<br>即<br>领<br>取</span>
            </el-button>
            <el-button class="use-coupon-btn" :disabled="item.remainQuantity <= 0" v-else-if="item.isReciever === 'Y'" @click="handleReceive(item)">
              <span>立<br>即<br>领<br>取</span>
            </el-button>


            <i v-if="item.userReceivedQuantity > 0" class="has-receive-icon"></i>
          </el-row>
        </template>
         <!-- 数据为空 -->
         <table-empty-box  v-if="tableData.length === 0"  />
      </div>

    </div>
  </div>
</template>
<script>
  import Search from '@/components/Search/Search'
  import { pageCouponList, saveCouponReceiveSituation } from '@/api/promotion-zone'
  import { getToken } from '@/utils/auth'
  import tableEmptyBox from "@/components/tableEmptyDefault"
  export default {
    name: 'coupon-center',
    components: {
      Search,
      tableEmptyBox
    },
    data () {
      return {
        tableData: [],
        isLoading: false,
      }
    },
    mounted () {
      this.initList()
    },
    methods: {
      handleReceive (item) {
        if (getToken()) {
          saveCouponReceiveSituation({ couponId: item.couponId }).then(res => {
            this.$message.success('领取成功')
            this.initList()
          })
        } else {
          this.$message.warning('请先登录')
          this.$router.push(`/login`)
        }
      },
      initList () {
        this.isLoading = true
        pageCouponList({
          pageSize: 10000,
          pageNum: 0,
          condition: {
            notEnd: 'Y',
            // isShow: "Y"
          }
        }).then(res => {
          this.tableData = res.data.records
          this.isLoading = false
        }, error => {
          this.isLoading = false
        })
      }
    }
  }
</script>
<style scoped lang='scss'>
  .coupon-center-page {
    background-color: #F5F5F5;
    padding-bottom: 26px;
    .coupon-banner-bg{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 400px;
      min-width: 1200px;
      background: #fff url("../../../assets/img/promotion-zone/coupon-center/coupon-center-banner.png") no-repeat center;
      background-size: cover;
    }

    .coupon-center-content {
      width: 100%;
      overflow: hidden;
      position: relative;
      background-size: contain;
      background: #fff;

          //    优惠券
      .coupon-item-box {
        width: 1210px;
        min-height: 350px;
        margin: 410px auto 0;
        border-radius: 8px;
        padding: 26px 11px 11px;
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        line-height: 25px;

        .coupon-item-content {
          position: relative;
          color: #666;
          text-align: left;
          padding: 20px 20px 0 20px;
          margin-bottom: 15px;
          width: 287px;
          height: 116px;
          background: url("../../../assets/img/member-center/my-coupon-bg.png") no-repeat center;
          border-radius: 8px 8px 0 0;
          box-shadow: 0 4px 7px 2px #e5e5e5;

          .coupon-pass-time {
            position: absolute;
            top: 0;
            left: 0;
            width: 66%;
          }

          .col-1 {
            color: rgba(250, 100, 0, 1);
            height: 24px;
            display: flex;
            align-items: center;
          }

          .col-1-full {
            display: inline-block;
            vertical-align: top;
            width: 10px;
            height: 22px;
            font-size: 16px;
            font-weight: 600;
          }

          .col-1-discount {
            display: inline-block;
            height: 24px;
            margin: 0 4px;
            font-size: 24px;
            font-weight: bold;
          }

          .col-1-cont {
            display: inline-block;
            background: rgba(250, 100, 0, 0.1);
            border-radius: 2px;
            padding: 0 8px;
            font-size: 14px;
            font-weight: 400;
          }

          .col-1-2-discount-text {
            margin-right: 4px;
            font-size: 16px;
            font-weight: 600;
          }

          .coupon-type-text {
            color: rgba(34, 34, 34, 1);
          }

          .col-3-text {
            margin-top: 8px;
            width: 200px;
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: rgba(153, 153, 153, 1);
            line-height: 20px;
          }

          .col-flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .use-coupon-btn {
            background: none;
            border: none;
            width: 40px;
            justify-content: center;
            display: flex;
            align-items: center;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            font-size: 14px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: rgba(255, 255, 255, 1);
            line-height: 20px;
          }

          .has-receive-icon {
            position: absolute;
            top: 0;
            right: 42px;
            width: 52px;
            height: 44px;
            background: url("../../../assets/img/promotion-zone/coupon-center/has-receive.png") no-repeat;
          }
        }

        .has-used {
          background: url("../../../assets/img/member-center/my-coupon-bg-used.png") no-repeat center;
        }

        .has-pass {
          background: url("../../../assets/img/member-center/my-coupon-bg-pass.png") no-repeat center;

          .use-coupon-btn {
            color: rgba(153, 153, 153, 1);
          }
        }
      }
    }

  }
</style>

<template>
    <div class="quotation">
        <search-pad
                class="client-file-search-pad"
                :model="searchForm"
                :need-other-btn="false"
                :has-expand="false"
                @search="handleSearch"
                @reset="handleReset"
        >
            <search-pad-item prop="erpCode" label="客户编码">
                <el-input size="small" @keyup.enter.native="handleSearch" v-model="searchForm.buyersNo" placeholder="输入客户编码"/>
            </search-pad-item>
            <search-pad-item prop="buyerNm" label="客户名称">
                <el-input size="small" v-model="searchForm.buyersNm" @keyup.enter.native="handleSearch" placeholder="输入客户名称"/>
            </search-pad-item>
            <search-pad-item prop="productCode" label="商品编码">
                <el-input size="small" v-model="searchForm.productCode" @keyup.enter.native="handleSearch" placeholder="输入商品编码"/>
            </search-pad-item>
            <search-pad-item prop="productNm" label="商品名称">
                <el-input size="small" v-model="searchForm.productNm" @keyup.enter.native="handleSearch" placeholder="输入商品名称"/>
            </search-pad-item>
            <search-pad-item prop="manufacturer" label="厂家产地">
                <el-input size="small" v-model="searchForm.manufacturer" @keyup.enter.native="handleSearch" placeholder="输入厂家产地"/>
            </search-pad-item>
            <search-pad-item prop="dealTime" label="更新时间">
                <el-date-picker
                        size="small"
                        v-model="searchForm.updateTime"
                        @change="handleChange"
                        style="width: 240px"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </search-pad-item>
        </search-pad>

        <div class="handleBtn">
            <el-button size="mini" type="primary" @click="onAdd">新增</el-button>
            <el-button size="mini" type="primary" @click="onDelete" :disabled="!selectItem.length">批量删除</el-button>
        </div>

        <div class="tab">
            <el-tabs v-model="activeName" @tab-click="handleTab">
                <el-tab-pane label="全部" name=""></el-tab-pane>
                <el-tab-pane label="待审核" name="WAIT_APPROVE"></el-tab-pane>
                <el-tab-pane label="已审核" name="APPROVE_SUCCESS"></el-tab-pane>
                <el-tab-pane label="已拒绝" name="APPROVE_FAILURE"></el-tab-pane>
                <el-tab-pane label="已失效" name="APPROVE_INVALID"></el-tab-pane>
            </el-tabs>
        </div>

        <div class="list">
            <im-table-page
                    ref="tablePage"
                    :data.sync="tableData"
                    :remote-method="queryData"
                    :addQuantity="true"
                    @updated="updated"
                    :index="true"
                    :more-check-box="(val)=>{selectItem = val}"
                    header-row-class-name="header-row"
                    style="width: 100%">
                <el-table-column type="selection" label="选择" align="center" fixed="left" width="50"/>
                <el-table-column prop="code" label="报价单编码" width="100px" show-overflow-tooltip />
                <el-table-column prop="buyersNo" align="left" label="客户编码" show-overflow-tooltip width="100px"/>
                <el-table-column prop="buyersNm" align="left" label="客户名称" width="200px"/>
                <el-table-column prop="productCode" align="left" label="商品编码" show-overflow-tooltip width="100px"/>
                <el-table-column prop="productNm" align="left" label="商品名称" width="230px">
                    <template slot-scope="scope">
                        {{$util.getGoodsName(scope.row)}}
                    </template>
                </el-table-column>
                <el-table-column prop="spec" align="left" label="规格" min-width="130px"/>
                <el-table-column prop="unit" align="left" label="单位" show-overflow-tooltip width="56px"/>
                <el-table-column prop="manufacturer" align="left" label="厂家(上市许可持有人)/产地" width="250px">
                    <template slot-scope="scope">
                        {{scope.row.manufacturer}}{{scope.row.area ? '/' + scope.row.area : ''}}
                    </template>
                </el-table-column>
                <el-table-column prop="price" align="right" label="新增报价" show-overflow-tooltip width="100px">
                    <template slot-scope="scope">
                        <span style="color:#F59A23">{{$util.toFixed(scope.row.price)}}</span>
                    </template>
                </el-table-column>
<!--                <el-table-column prop="grossProfitRate" align="left" label="毛利率" width="100px">-->
<!--                    <template slot-scope="scope">-->
<!--                        {{scope.row.grossProfitRate}}%-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column prop="originalUnitPrice" align="right" label="当前电商价" width="100px">
                    <template slot-scope="scope">
                        <span style="color:#F59A23">{{$util.toFixed(getPrice(scope.row))}}</span>
                    </template>
                </el-table-column>
<!--                <el-table-column prop="assessmentPrice" align="right" label="当前考核价" width="100px">-->
<!--                    <template slot-scope="scope">-->
<!--                        <span style="color:#70B603">{{$util.toFixed(scope.row.assessmentPrice)}}</span>-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column prop="createTime" align="center" label="报价时间" width="100px"/>
                <el-table-column prop="approveStat" fixed="right" align="center" label="报价单状态" width="100px">
                    <template slot-scope="scope">
                        <div v-html="getApproveStat(scope.row.approveStat)"></div>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" align="center" width="200" label="操作">
                    <template slot-scope="scope">
                            <el-button type="text" v-if="scope.row.isStop === 'N' && scope.row.approveStat === 'APPROVE_SUCCESS'" @click="invalidData(scope.row)">作废</el-button>
                            <el-button type="text" v-if="scope.row.approveStat === 'APPROVE_FAILURE'" @click="reExamineData(scope.row)">申请复审</el-button>
                            <el-button type="text" v-if="scope.row.isStop === 'Y' && scope.row.approveStat === 'APPROVE_INVALID'" @click="openData(scope.row)">启用</el-button>
                            <el-button type="text" @click="onEdit(scope.row)" v-if="scope.row.approveStat !== 'APPROVE_INVALID'">编辑</el-button>
                            <el-button type="text" @click="deleteItem(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </im-table-page>
        </div>
    </div>
</template>

<script>
    import SearchPad from '../../components/search-pad'
    import SearchPadItem from '../../components/search-pad-item'
    import {
        changeQuotationIsStop,
        deleteQuotation,
        getQuotationList,
        updateQuotation
    } from "../../../../api/member-center/transactions-using";
    export default {
        name: "quotation",
        components:{
            SearchPad,
            SearchPadItem
        },
        data(){
            return {
                isExpand:false,
                activeName:'',
                searchForm:{
                    updateTime:[],
                    buyersNo: '',
                    buyersNm: '',//客户名称
                    approveStat:'',//审核状态
                    startDate: '',//开始日期
                    endDate: '',//结束日期
                    productNm: '',//商品名称
                    productCode: '',//商品编码
                    manufacturer:''//厂家
                },
                selectItem:[],
                tableData:[]
            }
        },
        methods:{
            getPrice(row){
              let {customerTypePriceVo} = row
              if(customerTypePriceVo && customerTypePriceVo.length){
                return customerTypePriceVo[0].price
              }
            },
            getApproveStat(val){
                let str = ''
                switch (val){
                    //审核中
                    case 'WAIT_APPROVE':
                        str = '<span style="color:#E6A23C">审核中</span>'
                        break
                    //已通过
                    case 'APPROVE_SUCCESS':
                        str = '<span style="color:#67C23A">已通过</span>'
                        break
                    //已拒绝
                    case 'APPROVE_FAILURE':
                        str = '<span style="color:#F56C6C">已拒绝</span>'
                        break
                    //已失效
                    case 'APPROVE_INVALID':
                        str = '<span style="color:#909399">已失效</span>'
                        break
                    default:
                        break
                }
                return str
            },
            handleTab (tabs) {
                let name = tabs.name
                this.searchForm.approveStat = name === '' ? '' : name
                this.activeName = name
                this.doRefresh()
            },
            handleSearch(){
                this.doRefresh()
            },
            doRefresh () {
                this.$refs.tablePage.doRefresh({ currentPage: 0, pageSize: 10, condition: this.searchForm })
                // this.getStateNum()
            },
            handleReset(){
                this.searchForm = {
                    updateTime:[],
                    erpCode: '',
                    buyerNm: '',
                    startDate: '',
                    endDate: '',
                    productNm: '',
                    productCode: '',
                    manufacturer:''
                }
            },
            handleChange(){
                this.doRefresh()
            },
            onAdd(){
                this.$emit('change-page', {
                    page: 'quotationUpdate',
                    params: {
                        editType: 'add'
                    }
                })
            },
            onEdit(item){
                this.$emit('change-page', {
                    page: 'quotationUpdate',
                    params: {
                        rowData: item,
                        editType: 'edit'
                    }
                })
            },
            //删除某个报价单
            deleteItem(item){
                this.$confirm(`请确认操作:即将删除报价单${item.code}`,'提示',{
                    confirmButtonText:'确认',
                    cancelButtonText:'取消',
                    type:'warning'
                }).then(()=>{
                    deleteQuotation({
                        quotationIdList:[item.quotationId]
                    }).then(res=>{
                        this.$message({
                            message:'删除成功',
                            type:'success'
                        })
                        this.doRefresh()
                    })
                })
            },
            //删除已选中报价单
            onDelete(){
                if(!this.selectItem.length){
                    this.$message({
                        message:'请选择报价单',
                        type:'error'
                    })
                    return
                }
                this.$confirm(`确认删除已选中的报价单？`,'提示',{
                    confirmButtonText:'确认',
                    cancelButtonText:'取消',
                    type:'warning'
                }).then(()=>{
                    let data = Object.assign([],this.selectItem)
                    data = data.map(item=>{
                        return item.quotationId
                    })
                    deleteQuotation({
                        quotationIdList:data
                    }).then(res=>{
                        this.$message({
                            message:'删除成功',
                            type:'success'
                        })
                        this.doRefresh()
                    })
                })
            },
            //获取报价单数据
            queryData(data){
                if (this.searchForm.updateTime && this.searchForm.updateTime.length > 0) {
                    this.searchForm.startDate = this.$util.parseTime(this.searchForm.updateTime[0], '{y}-{m}-{d}') + ' 00:00:00'
                    this.searchForm.endDate = this.$util.parseTime(this.searchForm.updateTime[1], '{y}-{m}-{d}') + ' 23:59:59'
                } else {
                    this.searchForm.startDate = ''
                    this.searchForm.endDate = ''
                }
                return getQuotationList({
                    ...data,
                    condition: this.searchForm
                })
            },
            //作废报价单
            invalidData(item){
                this.$confirm(`请确认操作:即将作废报价单${item.code}`,'提示',{
                    confirmButtonText:'确认',
                    cancelButtonText:'取消',
                    type:'warning'
                }).then(()=>{
                    changeQuotationIsStop({
                        quotationId: item.quotationId,
                        isStop:'Y'
                    }).then(res => {
                        this.$message({
                            message:`作废报价单${item.code}成功`,
                            type:'success'
                        })
                        this.doRefresh()
                    })
                })
            },
            //启用报价单
            openData(item){
                this.$confirm(`请确认操作:即将启用报价单${item.code}`,'提示',{
                    confirmButtonText:'确认',
                    cancelButtonText:'取消',
                    type:'warning'
                }).then(()=>{
                    changeQuotationIsStop({
                        quotationId: item.quotationId,
                        isStop:'N'
                    }).then(res => {
                        this.$message({
                            message:`启用报价单${item.code}成功`,
                            type:'success'
                        })
                        this.doRefresh()
                    })
                })
            },
            //申请重新审核报价单
            reExamineData(item){
                this.$confirm(`请确认操作:申请重新审核报价单${item.code}`,'提示',{
                    confirmButtonText:'确认',
                    cancelButtonText:'取消',
                    type:'warning'
                }).then(()=>{
                    updateQuotation({
                        ...item,
                        approveStat:'WAIT_APPROVE'
                    }).then(res => {
                        this.$message({
                            message:`申请重新审核报价单${item.code}成功`,
                            type:'success'
                        })
                        this.doRefresh()
                    })
                })
            },
            updated(){
                this.$refs.tablePage.doLayout()
            }
        }
    }
</script>

<style scoped lang="scss">
    /deep/{
        .el-message-box__wrapper{
            /deep/.myMsgBox{
                width: 700px;
            }
        }
    }
    .quotation{
        background-color: white;
        height: 100%;
        .handleBtn{
            padding: 10px 20px 20px;
            border-bottom: 1px solid #efefef;
            display: flex;
            justify-content: flex-start;
        }
        .tab{
            padding: 10px 20px;
        }
        .list{
            padding: 0 20px;
        }
        /deep/ {
            .el-tabs__nav-wrap::after {
                background-color: #fff;
            }

            .el-tabs__nav {
                margin: 0 20px;
                height: 40px;
                line-height: 50px;
            }
        }
    }
</style>

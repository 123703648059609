<template>
  <div class="info-channel-content">
    <Search :index-nav="true" />
    <div class="channel-banner">
      <div class="banner-bg"></div>
      <p>视频资讯</p>
    </div>
    <div class="channel-nav">
      <div class="nav-odei">
        <div class="nav-list" v-for="(item, index) in navList" :class="{'active': index === navIndex}" @click="handleEdit(item.videoGroupId, index, item.videoGroupNm)">{{ item.videoGroupNm }}</div>
      </div>
    </div>
    <div class="channel-content">
      <div class="channel-odes">
        <div class="channel-list">
          <div class="channel-list" v-for="item in contentList">
            <div class="info-name" >{{ item.videoNm }}</div>
            <div class="info-introduce" >

              <div>
<!--                <el-button type="success" plain @click="playVideo(item.vUrl)"  ref="btn" size="mini" >-->
<!--                  -->
<!--                </el-button>-->
                <div class="video-img" plain @click="playVideo(item.vUrl, item.pUrl)">
                  <img class="v-img" :src="item.pUrl" alt="">
                  <i class="el-icon-video-play"></i>
                </div>
              </div>
            </div>
            <div class="info-bot">
              <span>发布时间：{{ item.updateTime }}</span>
            </div>
          </div>
        </div>
      </div>

      <empty-box v-if="contentList.length === 0" style="padding-bottom:80px;" />
      <div class="channel-pagination">
        <el-pagination
          :hide-on-single-page="true"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="5"
          layout="total, prev, pager, next, jumper"
          background
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="视频预览"
      :visible.sync="dialogPlay"
      v-model="dialogPlay"
      width="55%"
      @close="closeDialog"
    >
      <div class="video-box">
        <video
          :src= "videoUrl"
          :poster="videoPUrl"
          controls
          autoplay
          class="video"
          ref="dialogVideo"
        ><source type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'></video>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import Search from '@/components/Search/Search'
import { appVideoGroup, pageAppVideo } from '@/api/promotion-zone/video-channel'
import emptyBox from '@/components/tableEmptyDefault'
import {getBus} from "@/utils/auth";
import { addInfVideoViews } from '@/api/index/index'
export default {
  components: {
    Search,
    emptyBox
  },
  data() {
    return {
      isExpend: false,
      total: 0,
      currentPage: 1,
      pageSize: 5,
      isShowMainInfo: true,
      navList: [],
      expendList: [],
      navIndex: 0,
      contentList: [],
      videoGroupId: '',
      videoGroupNm: '',
      loading: true,
      dialogPlay: false,
      videoUrl: "",
      videoPUrl: "",
      type: '',
    }
  },
  created () {
    this.loading = true
  },
  mounted() {
    document.addEventListener('click', () => {
      this.isExpend = false
    })
    appVideoGroup(getBus().orgId).then(res => {
      this.expendList = res.data

      this.navList = res.data
      const videoGroupNm = this.$route.params.videoGroupNm
      let isId = false
      let indexId = 0
      res.data.map((val, index) => {
        if (videoGroupNm && val.videoGroupNm === videoGroupNm) {
          isId = true
          indexId = index
        }
      })
      if (isId) {
        this.handleEdit(res.data[indexId].videoGroupId, indexId, res.data[indexId].videoGroupNm)
      } else {
        this.handleEdit(res.data[0].videoGroupId, 0, res.data[0].videoGroupNm)
      }
    })
  },
  methods: {
    handleCurrentChange(val) {
      pageAppVideo({
        pageSize: 5,
        pageNum: val,
        condition: {
          videoGroupId: this.videoGroupId,
          orgId:getBus().orgId
        }
      }).then(res => {
        this.total = res.data.total

        this.contentList = res.data.records
      })
    },
    checkDetail(item) {
      addInfVideoViews(item.infVideoId)
      if (item.chainOrNot === 'Y') {
        let url = /^(http|https)/.test(item.externalUrlAddress) ? item.externalUrlAddress : 'http://' + item.externalUrlAddress
        window.open(url, '_blank')
        return ''
      }
      if (item.isShowNewWindow === 'Y') {
        const { href } = this.$router.resolve({
          path: '/info-detail/' + item.infVideoId,
          query: { name: this.videoGroupNm }
        })
        window.open(href, '_blank')
      } else {
        this.$router.push({path: `/info-detail/${item.infVideoId}`, query: { name: this.videoGroupNm }})
      }
    },
    handleEdit(id, index, videoGroupNm) {
      this.videoGroupNm = videoGroupNm
      this.navIndex = index ? index : 0
      this.videoGroupId = id
      pageAppVideo({
        pageSize: 5,
        pageNum: this.currentPage,
        condition: {
          videoGroupId: id,
          orgId:getBus().orgId
        }
      }).then(res => {

        this.total = res.data.total

        this.contentList = res.data.records
      })
    },
    // 视频
    playVideo(url, img) {
      this.dialogPlay = true;
      this.videoUrl = url;
      this.videoPUrl = img
    },
    closeDialog() {
      this.videoUrl = ""; //清空数据 关闭视频播放
      this.videoPUrl = ''
    },
    getbytype() {

    }
  }
}
</script>

<style lang="scss" scoped>
.info-channel-content{
  background: #F5F5F5;
  padding-bottom: 40px;
  .channel-banner{
    position: relative;
    min-width: 1200px;
    .banner-bg{
      min-width: 1200px;
      height: 320px;
      background: url("../../../assets/img/promotion-zone/info-channel-banner.png") no-repeat center;
      background-size: cover;
    }
    p{
      position: absolute;
      font-size:100px;
      font-weight:800;
      color:rgba(255,255,255,1);
      top: 75px;
      left: 0;
      right: 0;
    }
  }
  .channel-nav{
    width: 1200px;
    margin: -20px auto 0;
    height:48px;
    position: relative;
    .nav-odei{
      background:rgba(255,255,255,1);
      box-shadow:0px 1px 0px 0px rgba(229,229,229,1);
      border-radius:4px 0 0 4px;
      display: flex;
      height:48px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      .nav-list{
        width:220px;
        line-height: 48px;
        font-size:18px;
        font-weight:400;
        color:rgba(34,34,34,1);
        cursor: pointer;
        &:first-child {
          border-radius:4px 0 0 4px;
        }
        &.active{
          background: $titleBg1;
          font-weight:500;
          color:rgba(255,255,255,1);
        }
        &:hover{
          background: $hoverBg1;
          font-weight:500;
          color:rgba(255,255,255,1);
        }
      }
      .nav-expend{
        width: 100px;
        line-height: 48px;
        font-size:18px;
        font-weight:400;
        color:rgba(34,34,34,1);
        position: relative;
        span{
          position: relative;
          cursor: pointer;
          padding-right: 10px;
          &:after{
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            border-top: 1px solid #222222;
            border-right: 1px solid #222222;
            top: 1px;
            right: -12px;
            transform: rotate(135deg);
            transition: all .3s ease-out;
            z-index: 3;
          }
        }
        .arrow-open{
          &:after{
            transform: rotate(135deg);
          }
        }
        .arrow-away{
          &:after{
            top: 7px;
            transform: rotate(315deg);
          }
        }
        .expend-content{
          width: 400px;
          display: flex;
          flex-flow: wrap;
          padding: 12px 10px 24px 40px;
          position: absolute;
          top: 49px;
          right: 0;
          background: #fff;
          z-index: 11;
          box-shadow:0px 8px 12px 0px rgba(0,0,0,0.08);
          animation: myfirst .5s;
          .expend-list{
            font-size:18px;
            font-weight:400;
            color:rgba(34,34,34,1);
            margin-right: 30px;
            margin-top: 12px;
            line-height:25px;
            cursor: pointer;
            &:nth-child(3n-2) {
              margin-left: 0;
            }
            &:hover{
              color: $hoverBg1;
            }
            &.active{
              color: $titleBg1;
            }
          }
          .none-more{
            line-height: 20px;
            padding-top: 12px;
            display: block;
            text-align: center;
            width: 100%;
            color: #b5b5b5;
            padding-right: 30px;
          }
        }
        .none-expend{
          opacity: 0;
          display: none;
        }
        @keyframes myfirst
        {
          from {opacity: 0;}
          to {display: none}
        }
      }
    }
  }
  .channel-content{
    width: 1200px;
    margin: 0 auto;
    background: #fff;
    .channel-odes{
      text-align: left;
      .channel-list{
        padding: 44px 48px;
        background:rgba(255,255,255,1);
        border-bottom: 1px #e5e5e5 solid;
        &:hover{
          background: $hoverBg;
        }
        .info-name{
          font-size:24px;
          font-weight:400;
          color:rgba(34,34,34,1);
          cursor: pointer;
          display: inline-block;
        }
        .info-introduce{
          font-size:14px;
          font-weight:400;
          color:rgba(102,102,102,1);
          line-height: 26px;
          padding-top: 10px;
          //text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          //text-overflow: ellipsis;
          //display: -webkit-box;
          //-webkit-line-clamp: 2;
          //-webkit-box-orient: vertical;
          max-height: 300px;
          //display: -webkit-box;
          //-webkit-box-orient: vertical;
          //-webkit-line-clamp: 2;
          //overflow: hidden;

          .video-img {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 300px;
            height: 200px;
            background: rgba(0, 0, 0, 0.1);
            .v-img {
              width: auto;
              height: 100%;
            }
            i {
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              color: #000;
              font-size: 60px;
              z-index: 9;
              cursor: pointer;
            }
          }
        }
        .info-bot{
          font-size:12px;
          font-weight:500;
          color:rgba(153,153,153,1);
          padding-top: 20px;
          span{
            padding-right: 25px;
          }
        }
      }
    }
    .channel-pagination{
      padding: 20px 0;
    }
  }
  .video-box {
    width: 100%;
    max-height: 480px;
    video {
      width: 100%;
      height: 100%;
      max-height: 480px;
      //object-fit: fill;
    }
  }
}
</style>

<style lang="scss">
.info-channel-content{
  .channel-content{
    .channel-odes{
      .info-introduce{
        img{
          max-width: 100%;
          height: 0;
        }
      }
    }
  }
}
</style>

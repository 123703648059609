<template>
  <div>
    <el-dialog width="1200px" class="dialog-license" title="我的站内信"
               :visible.sync="dialogVisible">
      <div>
        <!-- 按钮区域 -->
        <im-search-pad
            class="search-content"
            :model="searchForm"
            :has-expand="false"
            @search="handleSearch"
            @reset="handleReset"
        >
          <im-search-pad-item prop="readStatus">
            是否已读：
            <el-select
                v-model="searchForm.readStatus"
                placeholder="请选择状态"
                size="small"
                clearable
                class="!w-240px"
            >
              <el-option
                  v-for="dict in readStatusList"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
              />
            </el-select>
          </im-search-pad-item>&nbsp;&nbsp;
          <im-search-pad-item prop="readStatus">
            发送时间：
            <el-date-picker
                @change="handleChangeTime"
                style="width:240px;"
                v-model="time"
                size="small"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </im-search-pad-item>
        </im-search-pad>
        <div class="other-btns">
          <el-button size="small" @click="handleUpdateList">标记已读</el-button>
          <el-button size="small" @click="handleUpdateAll">全部已读</el-button>
        </div>
        <!-- 表格内容 -->
        <im-table-page
            style="margin-bottom:15px;padding: 0 15px;"
            ref="tablePage"
            :data.sync="tableData"
            :remote-method="queryData"
            :height="tableData.length>0?'340':'auto'"
            @updated="updated"
            :more-check-box="checkBox"
        >
          <el-table-column type="selection" width="55" align="center" :selectable="judgeSelect" />
          <el-table-column label="发送人" align="center" prop="templateNickname" width="100" />
          <el-table-column label="发送时间" prop="createTime" width="160" show-overflow-tooltip>
            <template #default="scope">
              <span>{{scope.row.createTime}}</span>
            </template>
          </el-table-column>
          <el-table-column label="类型" align="center" prop="templateType" width="100">
            <template #default="scope">
              <el-tag v-if="scope.row.templateType === 1">通知公告</el-tag>
              <el-tag v-else-if="scope.row.templateType === 2" type="success">系统公告</el-tag>
            </template>
          </el-table-column>
          <el-table-column
              label="消息内容"
              align="center"
              prop="templateContent"
              show-overflow-tooltip
          />
          <el-table-column label="是否已读" align="center" prop="readStatus" width="100">
            <template #default="scope">
              <el-tag v-if="scope.row.readStatus" type="danger">是</el-tag>
              <el-tag v-else type="info">否</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="阅读时间" align="center" prop="readTime" width="160">
            <template #default="scope">
              <span>{{scope.row.readTime}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100px">
            <template slot-scope="scope">
              <el-row class="table-edit-row">
                <el-button
                    link
                    :type="scope.row.readStatus ? 'primary' : 'warning'"
                    @click="openDetail(scope.row)" >
                  {{ scope.row.readStatus ? '详情' : '已读' }}
                </el-button>
                <!-- <el-button size="small" style="padding:2px 4px;font-size: 12px" @click="handleDelete(scope.row)">删除</el-button> -->
              </el-row>
            </template>
          </el-table-column>
        </im-table-page>
      </div>
      <div slot="footer">
        <el-button @click="handleHide">关 闭</el-button>
<!--        <el-button type="primary">确定</el-button>-->
      </div>
    </el-dialog>
    <el-dialog title="详情" :visible.sync="dialogDetailVisible">
      <el-descriptions class="margin-top" :column="1">
        <el-descriptions-item label="发送人">{{ detailCon.templateNickname }}</el-descriptions-item>
        <el-descriptions-item label="发送时间">{{ detailCon.createTime }}</el-descriptions-item>
        <el-descriptions-item label="消息类型">
          <el-tag v-if="detailCon.templateType === 1">通知公告</el-tag>
          <el-tag v-else-if="detailCon.templateType === 2" type="success">系统公告</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="是否已读">
          <el-tag v-if="detailCon.readStatus" type="danger">是</el-tag>
          <el-tag v-else type="info">否</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="阅读时间">{{ detailCon.readTime }}</el-descriptions-item>
        <el-descriptions-item label="内容">{{ detailCon.templateContent }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import { parseTime } from '@/utils'
// import { pageInquiryBox } from '@/api/member-center/transactions-using'
import {updateAllNotifyMessageRead, updateNotifyMessageRead, getMyNotifyMessagePage} from '@/api/org'
export default {
  name: "myNotifyMessage",
  props:{
    visible:{
      type: Boolean,
      default: false
    },
  },
  data(){
    return {
      time: [],
      tableData: [],
      searchForm: {
        pageNo: 1,
        pageSize: 10,
        readStatus: '',
        createTime: null
      },
      readStatusList: [
        {label: '是', value: true},
        {label: '否', value: false},
      ],
      multipleSelection: [],
      dialogDetailVisible: false,
      detailCon: {}
    }
  },
  computed:{
    dialogVisible:{
      get(){
        return this.visible
      },
      set(val){
        this.$emit('update:visible',val)
      }
    }
  },
  watch: {
    dialogVisible(newVal) {
      if(newVal) {
        this.handleReset()
      }
    }
  },
  methods:{
    handleHide(){
      this.$emit('update:visible',false)
    },
    show(){
      this.$emit('update:visible',true)
    },
    doRefresh () {
      this.$refs.tablePage.doRefresh({ currentPage: 0, pageSize: 10 })
    },
    handleSearch () {
      this.doRefresh()
    },
    handleReset () {
      this.searchForm = {
        pageNo: 1,
        pageSize: 10,
        readStatus: '',
        createTime: null
      }
      this.time = []
      this.handleSearch()
    },
    handleChangeTime () {
      this.searchForm.createTime = null
      this.searchForm.createTime = [this.time[0]+' 00:00:00', this.time[1]+' 23:59:59'].join(',')
    },
    judgeSelect(row, index) {
      return !row.readStatus
    },
    async queryData () {
      // return getMyNotifyMessagePage({...this.searchForm })
      // this.searchForm.createTime = this.searchForm.createTime.join(',')
      console.log('this.time',this.time)
      let res = await getMyNotifyMessagePage({...this.searchForm })
      console.log('res',res)
      if(res.data.list.length > 0) {
        res.data.records = res.data.list.map(item => {
          return item
        })
      } else {
        res.data.records = []
      }
      return res
    },
    updated () {
      this.$refs.tablePage.doLayout()
    },
    async openDetail(row) {
      if (!row.readStatus) {
        let params = {
          ids: [row.id]
        }
        let data = await updateNotifyMessageRead(params)
        if(data.data) {
          this.handleReset()
          this.$emit('success')
        }
      } else {
        this.detailCon = row
        this.dialogDetailVisible = true
      }
    },
    checkBox(val) {
      this.multipleSelection = val
    },
    /** 标记一些站内信已读 **/
    async handleUpdateList() {
      if (this.multipleSelection.length === 0) {
        return
      }
      let selectedIds = []
      this.multipleSelection.map(item => {
        selectedIds.push(item.id)
      })
      let params = {
        ids: selectedIds
      }
      await updateNotifyMessageRead(params)
      await this.handleReset()
      this.$emit('success')
    },
    /** 标记全部站内信已读 **/
    async handleUpdateAll() {
      await updateAllNotifyMessageRead()
      this.$message.success('全部已读成功!')
      // this.$refs.tablePage.clearSelection()
      this.handleReset()
      this.$emit('success')
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-license {
  /deep/ .el-dialog {
    text-align: left;

    .el-dialog__body {
      padding: 40px;
    }
  }
  .other-btns {
    position: absolute;
    top: 95px;
    left: 830px;
  }
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("Search", { attrs: { "index-nav": true } }),
      _c(
        "div",
        { staticClass: "breadcrumb" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { "separator-class": "el-icon-arrow-right" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页"),
              ]),
              _c("el-breadcrumb-item", [_vm._v(_vm._s(_vm.params.title))]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "chinaWestern" },
        _vm._l(_vm.titleAll, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "allChild" },
            [
              _c("div", { staticClass: "sizeCenter" }, [
                _c("div", { staticClass: "allCenter" }, [
                  _c("div", { staticClass: "leftText" }, [
                    _vm._v(_vm._s(index + 1 + "F")),
                  ]),
                  _c(
                    "div",
                    { staticClass: "rightText" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              path: "/commodity-list",
                              query: {
                                categoryId: item.categoryId,
                                categoryCode: item.categoryCode,
                              },
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.categoryNm))]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._l(item.childTree, function (itemSon, indexSon) {
                return _c("div", { key: indexSon, staticClass: "childNext" }, [
                  _c(
                    "div",
                    { staticClass: "leftTitle" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              path: "/commodity-list",
                              query: {
                                categoryId: itemSon.categoryId,
                                categoryCode: itemSon.categoryCode,
                              },
                            },
                          },
                        },
                        [_vm._v(_vm._s(itemSon.categoryNm))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "rightCenter" },
                    _vm._l(itemSon.childTree, function (itemSons, indexSons) {
                      return _c(
                        "div",
                        { key: indexSons, staticClass: "itemSon" },
                        [
                          indexSons !== 0
                            ? _c("span", { staticClass: "itemSonLine" })
                            : _vm._e(),
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  path: "/commodity-list",
                                  query: {
                                    categoryId: itemSons.categoryId,
                                    categoryCode: itemSons.categoryCode,
                                  },
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { margin: "0 0 0 10px" } },
                                [_vm._v(_vm._s(itemSons.categoryNm))]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ])
              }),
            ],
            2
          )
        }),
        0
      ),
      _c("ToolBar"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("Search"),
      _c(
        "div",
        { staticClass: "breadcrumb" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { "separator-class": "el-icon-arrow-right" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页"),
              ]),
              _vm.strList.length > 0
                ? _c(
                    "el-breadcrumb-item",
                    {
                      attrs: {
                        to: {
                          path: "/commodity-list",
                          query: {
                            categoryId: _vm.product.categoryId,
                            categoryCode: _vm.product.categoryCode,
                          },
                        },
                      },
                    },
                    [
                      _c(
                        "el-dropdown",
                        { attrs: { placement: "bottom-start" } },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.strList[0]))]),
                          _c(
                            "el-dropdown-menu",
                            {
                              staticClass: "childList",
                              attrs: { slot: "dropdown" },
                              slot: "dropdown",
                            },
                            _vm._l(_vm.firstList, function (item, index) {
                              return _c(
                                "el-dropdown-item",
                                { key: index, staticClass: "childListson" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          path: "/commodity-list",
                                          query: {
                                            categoryId: item.categoryId,
                                            categoryCode: item.categoryCode,
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.categoryNm) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.strList.length > 1
                ? _c(
                    "el-breadcrumb-item",
                    {
                      attrs: {
                        to: {
                          path: "/commodity-list",
                          query: {
                            categoryId: _vm.product.categoryId,
                            categoryCode: _vm.product.categoryCode,
                          },
                        },
                      },
                    },
                    [
                      _c(
                        "el-dropdown",
                        { attrs: { placement: "bottom-start" } },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.strList[1]))]),
                          _c(
                            "el-dropdown-menu",
                            {
                              staticClass: "childList",
                              attrs: { slot: "dropdown" },
                              slot: "dropdown",
                            },
                            _vm._l(_vm.secondList, function (item, index) {
                              return _c(
                                "el-dropdown-item",
                                { key: index, staticClass: "childListson" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          path: "/commodity-list",
                                          query: {
                                            categoryId: item.categoryId,
                                            categoryCode: item.categoryCode,
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.categoryNm) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.product.productNm || _vm.product.drugNm
                ? _c("el-breadcrumb-item", [
                    _vm._v(_vm._s(_vm._f("getGoodsName")(_vm.product))),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "detail-header" }, [
            _c(
              "div",
              { ref: "headerLeft", staticClass: "detail-header-left" },
              [
                _c(
                  "div",
                  { staticClass: "preview" },
                  [
                    _vm.userType == "SALESMAN"
                      ? _c("div", { staticClass: "identifying" }, [
                          _vm.product.whiteRecordFlag == 1
                            ? _c("img", {
                                attrs: {
                                  alt: "",
                                  src: require("@/assets/img/icon/whiterecord.png"),
                                },
                              })
                            : _vm.product.blackRecordFlag == 1
                            ? _c("img", {
                                attrs: {
                                  alt: "",
                                  src: require("@/assets/img/icon/blackrecord.png"),
                                },
                              })
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _c("im-pic-zoom", {
                      attrs: {
                        url: _vm.product.pictUrls || _vm.productDefaultImg,
                      },
                    }),
                    _vm.preview.length
                      ? _c("div", { staticClass: "min-img-box" }, [
                          _c(
                            "div",
                            {
                              staticClass: "button-prev",
                              on: { click: _vm.toLeftMove },
                            },
                            [_c("i", { staticClass: "el-icon-arrow-left" })]
                          ),
                          _c(
                            "ul",
                            { ref: "scorll_left", staticClass: "img-list" },
                            _vm._l(_vm.preview, function (item, index) {
                              return _c(
                                "li",
                                {
                                  class: { active: item.active },
                                  on: {
                                    mouseenter: function ($event) {
                                      return _vm.previewImg(index)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      alt: "",
                                      src: item.url || _vm.productDefaultImg,
                                    },
                                    on: {
                                      error: function ($event) {
                                        item.url = _vm.productDefaultImg
                                      },
                                    },
                                  }),
                                ]
                              )
                            }),
                            0
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "button-next",
                              on: { click: _vm.toRightMove },
                            },
                            [_c("i", { staticClass: "el-icon-arrow-right" })]
                          ),
                        ])
                      : _vm._e(),
                    _vm.userType !== "SALESMAN"
                      ? _c(
                          "div",
                          {
                            staticClass: "collect",
                            class: {
                              active: _vm.product.isProductCollect === "Y",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.starProduct(
                                  _vm.product.isProductCollect
                                )
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "el-icon-star-off" }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.product.isProductCollect === "Y"
                                    ? "已收藏"
                                    : "加入收藏"
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "message" },
                  [
                    _c("div", { staticClass: "message-top" }, [
                      _c("div", { staticClass: "mes-name" }, [
                        _vm.product.otcType && _vm.product.otcType !== "其他"
                          ? _c(
                              "b",
                              {
                                class: {
                                  jiaOtc: _vm.product.otcType === "甲OTC",
                                  yiOtc: _vm.product.otcType === "乙OTC",
                                  rxOtc: _vm.product.otcType === "RX",
                                },
                              },
                              [_vm._v(_vm._s(_vm.product.otcType))]
                            )
                          : _vm._e(),
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$util.getGoodsName(_vm.product)) +
                              " "
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "mes-subtitle" },
                        [
                          _c("tagList", {
                            attrs: { taglist: _vm.product.tagList },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "mes-info-list" }, [
                        _c("div", { staticClass: "mes-info-item" }, [
                          _c("span", { staticClass: "mes-info-title" }, [
                            _vm._v("规格"),
                          ]),
                          _c("span", { staticClass: "mes-info-content" }, [
                            _vm._v(_vm._s(_vm.product.spec)),
                          ]),
                        ]),
                        _c("div", { staticClass: "mes-info-item" }, [
                          _c("span", { staticClass: "mes-info-title" }, [
                            _vm._v("商品编码"),
                          ]),
                          _c("span", { staticClass: "mes-info-content" }, [
                            _vm._v(_vm._s(_vm.product.productCode)),
                          ]),
                        ]),
                        _c("div", { staticClass: "mes-info-item" }, [
                          _c("span", { staticClass: "mes-info-title" }, [
                            _vm._v("单位"),
                          ]),
                          _c("span", { staticClass: "mes-info-content" }, [
                            _vm._v(_vm._s(_vm.product.unit)),
                          ]),
                        ]),
                        _c("div", { staticClass: "mes-info-item" }, [
                          _c("span", { staticClass: "mes-info-title" }, [
                            _vm._v("销项税"),
                          ]),
                          _c("span", { staticClass: "mes-info-content" }, [
                            _vm._v(_vm._s(_vm.product.rate)),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "mes-info-item",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _c("span", { staticClass: "mes-info-title" }, [
                              _vm._v("厂家(上市许可持有人)/产地"),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "mes-info-content",
                                staticStyle: { "padding-left": "10px" },
                              },
                              [_vm._v(_vm._s(_vm.product.manufacturer))]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mes-info-item",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _c("span", { staticClass: "mes-info-title" }, [
                              _vm._v("最近效期"),
                            ]),
                            _c("span", { staticClass: "mes-info-content" }, [
                              _vm._v(_vm._s(_vm.product.expDate)),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm.product.discountEndTime
                      ? _c("div", { staticClass: "message-discount" }, [
                          _vm.product.isCountDown === "Y"
                            ? _c("div", { staticClass: "count-down" }, [
                                _c("span", { staticClass: "count-down-text" }, [
                                  _vm._v("距离结束剩余"),
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "count-down-num count-down-hour",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.timeList.day.split("")[0])
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "count-down-num count-down-hour",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.timeList.day.split("")[1])
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "count-down-point" },
                                  [_vm._v("天")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "count-down-num count-down-minute",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.timeList.hou.split("")[0])
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "count-down-num count-down-minute",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.timeList.hou.split("")[1])
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "count-down-point" },
                                  [_vm._v(":")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "count-down-num count-down-seconds",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.timeList.min.split("")[0])
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "count-down-num count-down-seconds",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.timeList.min.split("")[1])
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "count-down-point" },
                                  [_vm._v(":")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "count-down-num count-down-seconds",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.timeList.sec.split("")[0])
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "count-down-num count-down-seconds",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.timeList.sec.split("")[1])
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "price-num" }, [
                      _c("div", { staticClass: "price flex" }, [
                        _c("div", { staticClass: "wholesale flex" }, [
                          _c("div", { staticClass: "price-name" }, [
                            _vm._v("销售价"),
                          ]),
                          _c("div", { staticClass: "price-wholesale" }, [
                            _c(
                              "div",
                              { staticStyle: { "text-align": "right" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$util.getPriceByZero(
                                      _vm.product.price,
                                      _vm.product
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm.product.isDiscount === "Y"
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-decoration": "line-through",
                                      color: "#999999",
                                      "text-align": "right",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.product.salePrice
                                          ? "￥" + _vm.product.salePrice
                                          : ""
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                      _vm.coupon.length ||
                      _vm.additional.length ||
                      _vm.present.length ||
                      _vm.special.length ||
                      _vm.reduce.length
                        ? _c("div", { staticClass: "promotion" }, [
                            _c("div", { staticClass: "promotion-title" }, [
                              _c("span", { staticStyle: { width: "5em" } }, [
                                _vm._v("促销信息"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "promotion-list" },
                                [
                                  _vm.coupon.length
                                    ? _c(
                                        "div",
                                        { staticClass: "promotion-item" },
                                        [
                                          _vm._m(0),
                                          _vm._l(
                                            _vm.coupon,
                                            function (item, index) {
                                              return _c(
                                                "b",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        index < 4 &&
                                                        item.isShow === "Y",
                                                      expression:
                                                        "index < 4 && item.isShow === 'Y'",
                                                    },
                                                  ],
                                                  key: index,
                                                },
                                                [_vm._v(_vm._s(item.couponNm))]
                                              )
                                            }
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "promotion-more",
                                              on: {
                                                click: function ($event) {
                                                  _vm.dialogCouponVisible =
                                                    !_vm.dialogCouponVisible
                                                },
                                              },
                                            },
                                            [_vm._v("领取优惠券")]
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm.additional.length
                                    ? _c(
                                        "div",
                                        { staticClass: "promotion-item" },
                                        [
                                          _vm._m(1),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                "购满" +
                                                  _vm.additional[0]
                                                    .orderFullAmount +
                                                  "元，即可享受加价购优惠"
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.showAdditionalProduct = true
                                                },
                                              },
                                            },
                                            [_vm._v("查看加购商品")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.special.length
                                    ? _c(
                                        "div",
                                        { staticClass: "promotion-item" },
                                        [
                                          _vm._m(2),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                "购满" +
                                                  _vm.special[0]
                                                    .orderFullAmount +
                                                  "元，即可享受特价换购优惠"
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.showSpecialProduct = true
                                                },
                                              },
                                            },
                                            [_vm._v("查看换购商品")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.present.length
                                    ? _c(
                                        "div",
                                        { staticClass: "promotion-item" },
                                        [
                                          _c("div", [
                                            _vm.present[0].frsPresentRelList
                                              .length > 0
                                              ? _c("span", [_vm._v("自选套餐")])
                                              : _vm._e(),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.reduce.length
                                    ? _c(
                                        "div",
                                        { staticClass: "promotion-item" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "reduce-box" },
                                            [
                                              _vm._m(3),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "reduce-text",
                                                  attrs: {
                                                    title: _vm.reduceText,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.reduceText) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-dialog",
                                    {
                                      attrs: {
                                        width: "450px",
                                        title: "领取优惠券",
                                        visible: _vm.dialogCouponVisible,
                                      },
                                      on: {
                                        "update:visible": function ($event) {
                                          _vm.dialogCouponVisible = $event
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {},
                                        _vm._l(
                                          _vm.coupon,
                                          function (item, index) {
                                            return item.isShow === "Y"
                                              ? _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "coupon-dialog",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "flex" },
                                                      [
                                                        item.couponType ===
                                                        "FULL_REDUCTION"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "name",
                                                              },
                                                              [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "left-con",
                                                                }),
                                                                _c("p", [
                                                                  _vm._v(
                                                                    "¥" +
                                                                      _vm._s(
                                                                        item.fullReductionAmount
                                                                      )
                                                                  ),
                                                                ]),
                                                                _c("div", {
                                                                  staticClass:
                                                                    "right-con",
                                                                }),
                                                              ]
                                                            )
                                                          : _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "name",
                                                              },
                                                              [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "left-con",
                                                                }),
                                                                _c("p", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.discount
                                                                    ) + "折"
                                                                  ),
                                                                ]),
                                                                _c("div", {
                                                                  staticClass:
                                                                    "right-con",
                                                                }),
                                                              ]
                                                            ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "text",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text_1",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.couponNm
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            item.useTimeType ===
                                                            "IN_THE_DAY"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "text_2",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "领券当日起" +
                                                                        _vm._s(
                                                                          item.useValidDay
                                                                        ) +
                                                                        "天内可用 "
                                                                    ),
                                                                  ]
                                                                )
                                                              : item.useTimeType ===
                                                                "TIMES_OF_THE_DAY"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "text_2",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "领券次日起" +
                                                                        _vm._s(
                                                                          item.useValidDay
                                                                        ) +
                                                                        "天内可用 "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "text_2",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.useStartTime
                                                                      ) +
                                                                        " - " +
                                                                        _vm._s(
                                                                          item.useEndTime
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    item.isLimitTimes === "Y" &&
                                                    item.userReceivedQuantity <
                                                      item.limitNumber &&
                                                    item.remainQuantity > 0 &&
                                                    item.userReceivedQuantity <
                                                      item.remainQuantity
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "button",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleReceive(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("p", [
                                                              _vm._v(
                                                                "立即领券"
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    item.isLimitTimes === "N" &&
                                                    item.remainQuantity > 0
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "button",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleReceive(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("p", [
                                                              _vm._v(
                                                                "立即领券"
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : item.userReceivedQuantity >=
                                                          item.limitNumber &&
                                                        item.userReceivedQuantity >
                                                          0
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "button isReceive",
                                                          },
                                                          [
                                                            _c("p", [
                                                              _vm._v("已领取"),
                                                            ]),
                                                          ]
                                                        )
                                                      : item.remainQuantity <=
                                                          0 &&
                                                        item.userReceivedQuantity <=
                                                          0
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "button disabled",
                                                          },
                                                          [
                                                            _c("p", [
                                                              _vm._v("已抢光"),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              : _vm._e()
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-dialog",
                                    {
                                      attrs: {
                                        width: "450px",
                                        title: "特殊商品规则",
                                        visible: _vm.dialogPresentVisible,
                                      },
                                      on: {
                                        "update:visible": function ($event) {
                                          _vm.dialogPresentVisible = $event
                                        },
                                      },
                                    },
                                    _vm._l(_vm.present, function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "present-dialog",
                                        },
                                        [
                                          _c("div", { staticClass: "name" }, [
                                            _c("p", [
                                              _vm._v(
                                                "·满" +
                                                  _vm._s(item.orderFullAmount) +
                                                  "，送" +
                                                  _vm._s(
                                                    item.frsPresentRelList
                                                      .length
                                                  ) +
                                                  "件特殊商品"
                                              ),
                                            ]),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "img" },
                                            _vm._l(
                                              item.frsPresentRelList,
                                              function (img, i) {
                                                return _c("img", {
                                                  key: i,
                                                  attrs: {
                                                    src:
                                                      img.fieldId ||
                                                      _vm.productDefaultImg,
                                                    alt: "",
                                                  },
                                                  on: {
                                                    error: function ($event) {
                                                      img.fieldId =
                                                        _vm.productDefaultImg
                                                    },
                                                  },
                                                })
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                    _vm.isLicenseOutOfTime
                      ? _c(
                          "div",
                          { staticClass: "qualifications" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.userType !== "SALESMAN"
                                  ? "您有" +
                                      _vm.license.failureSum +
                                      "张证照已过期,该商品不能购买，请提供最新证照，详询4001-600998"
                                  : "该客户有" +
                                      _vm.license.failureSum +
                                      "张证照已过期，请联系客户提供最新证照"
                              ) + " "
                            ),
                            _vm.userType !== "SALESMAN"
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: "/member-center/personal-center/my-aptitudes",
                                    },
                                  },
                                  [_vm._v("更新资质")]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : [
                          _c("div", { staticClass: "message-bottom" }, [
                            _c(
                              "div",
                              { staticClass: "buy-num" },
                              [
                                _c("div", { staticClass: "promotion-title" }, [
                                  _vm._v("购买数量"),
                                ]),
                                _vm.quantityIf
                                  ? _c("el-input-number", {
                                      attrs: {
                                        size: "mini",
                                        "step-strictly": "",
                                        precision: _vm.getDecimalPointNum(
                                          _vm.product
                                        ),
                                        step: _vm.getRealMinBuyNum(_vm.product),
                                        min: _vm.getRealMinBuyNum(_vm.product),
                                        label: "购买数量",
                                      },
                                      model: {
                                        value: _vm.quantity,
                                        callback: function ($$v) {
                                          _vm.quantity = $$v
                                        },
                                        expression: "quantity",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.$store.getters.buyersVo &&
                                _vm.$store.getters.buyersVo.showStockType != 5
                                  ? _c("div", { staticClass: "stock" }, [
                                      _c("span", [_vm._v("库存：")]),
                                      _vm.$store.getters.buyersVo
                                        .showStockType == 4
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.product.stockQuantity >=
                                                    100
                                                    ? ">100"
                                                    : "<100"
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _vm.$store.getters.buyersVo
                                            .showStockType == 3
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.product.stockQuantity > 0
                                                    ? "有"
                                                    : "无"
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _vm.$store.getters.buyersVo
                                            .showStockType == 2
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.product.stockQuantity
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                                _c("div", { staticClass: "stock" }, [
                                  _vm._v(
                                    "最小购买倍数：" +
                                      _vm._s(
                                        _vm.getRealMinBuyNum(_vm.product)
                                      ) +
                                      " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "add-button" },
                              [
                                _vm.product.stockQuantity === 0
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "info" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRegisterGoods(
                                              _vm.product,
                                              _vm.quantity
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("缺货登记")]
                                    )
                                  : _vm.product.isOnSale === "N"
                                  ? _c(
                                      "el-button",
                                      { attrs: { type: "info" } },
                                      [_vm._v("已下架")]
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "code-button",
                                            attrs: {
                                              disabled: _vm.isBanAddToCart,
                                              loading: _vm.addToCartloading,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addItem(
                                                  _vm.product.skuId,
                                                  "NORMAL",
                                                  _vm.quantity,
                                                  _vm.product
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("../../assets/img/icon/icon-gouwuche.png"),
                                                alt: "",
                                              },
                                            }),
                                            _vm._v("加入购物车 "),
                                          ]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              disabled: _vm.isBanAddToCart,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.buyNow(
                                                  _vm.product,
                                                  _vm.quantity
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("立即购买")]
                                        ),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            ),
                          ]),
                        ],
                  ],
                  2
                ),
              ]
            ),
            _c(
              "div",
              { ref: "headerRight", staticClass: "detail-header-right" },
              [
                _c("div", { staticClass: "detail-header-right-text" }, [
                  _c("span", { staticClass: "title" }, [_vm._v("同类产品")]),
                  _c(
                    "span",
                    {
                      staticClass: "refresh",
                      on: {
                        click: function ($event) {
                          return _vm.getSimilarProductList()
                        },
                      },
                    },
                    [
                      _vm._v(" 换一批 "),
                      _c("div", { staticClass: "el-icon-refresh" }),
                    ]
                  ),
                ]),
                _vm.similarProduct.length
                  ? _c(
                      "div",
                      _vm._l(_vm.similarProduct, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "detail-header-right-content",
                            on: {
                              click: function ($event) {
                                return _vm.handleGetDetail(item.productId)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "detail-header-right-img" },
                              [
                                _c("img", {
                                  attrs: {
                                    alt: "",
                                    src: item.pictUrls || _vm.productDefaultImg,
                                  },
                                  on: {
                                    error: function ($event) {
                                      item.pictUrls = _vm.productDefaultImg
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c("p", { staticClass: "name" }, [
                              _vm._v(_vm._s(_vm.$util.getGoodsName(item))),
                            ]),
                            _c("p", { staticClass: "num" }, [
                              _vm._v(_vm._s(item.spec)),
                            ]),
                            _c("p", { staticClass: "company" }, [
                              _vm._v(_vm._s(item.manufacturer)),
                            ]),
                            _c("p", { staticClass: "price" }, [
                              _vm._v(_vm._s(item.priceStr)),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _c("div", [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "font-size": "13px",
                            "margin-top": "200px",
                          },
                        },
                        [_vm._v("暂无信息")]
                      ),
                    ]),
              ]
            ),
          ]),
          _vm.COLLOCATION_PACKAGE.length
            ? _c("swiper-package", {
                attrs: {
                  type: "COLLOCATION_PACKAGE",
                  "swiper-data": _vm.COLLOCATION_PACKAGE,
                },
              })
            : _vm._e(),
          _vm.FIXED_PACKAGE.length
            ? _c("swiper-package", {
                attrs: {
                  type: "FIXED_PACKAGE",
                  "swiper-data": _vm.FIXED_PACKAGE,
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "detail-footer" }, [
            _c(
              "div",
              { staticClass: "detail-header-right" },
              [
                _vm._m(4),
                _vm._l(_vm.historyList, function (item, index) {
                  return [
                    index < 4
                      ? _c(
                          "div",
                          {
                            key: index,
                            staticClass: "detail-header-right-content",
                          },
                          [
                            _vm.userType == "SALESMAN"
                              ? _c("div", { staticClass: "identifying" }, [
                                  item.whiteRecordFlag == 1
                                    ? _c("img", {
                                        attrs: {
                                          alt: "",
                                          src: require("@/assets/img/icon/whiterecord.png"),
                                        },
                                      })
                                    : item.blackRecordFlag == 1
                                    ? _c("img", {
                                        attrs: {
                                          alt: "",
                                          src: require("@/assets/img/icon/blackrecord.png"),
                                        },
                                      })
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "detail-header-right-img",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleGetDetail(item.productId)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    alt: "",
                                    src: item.picUrl || _vm.productDefaultImg,
                                  },
                                  on: {
                                    error: function ($event) {
                                      item.picUrl = _vm.productDefaultImg
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "name",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleGetDetail(item.productId)
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$util.getGoodsName(item)) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                            _c("p", { staticClass: "num" }, [
                              _vm._v(_vm._s(item.spec)),
                            ]),
                            _c("p", { staticClass: "company" }, [
                              _vm._v(_vm._s(item.manufacturer)),
                            ]),
                            _c("p", { staticClass: "price" }, [
                              item.price
                                ? _c("span", [_vm._v("¥" + _vm._s(item.price))])
                                : _vm._e(),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "detail-footer-tab" },
              [
                _c(
                  "el-tabs",
                  {
                    on: { "tab-click": _vm.handleTabClick },
                    model: {
                      value: _vm.activeFooter,
                      callback: function ($$v) {
                        _vm.activeFooter = $$v
                      },
                      expression: "activeFooter",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      {
                        staticClass: "first",
                        attrs: { label: "商品详情", name: "first" },
                      },
                      [
                        _c("div", { staticClass: "mes-info-list" }, [
                          _c("div", { staticClass: "mes-info-item" }, [
                            _c("span", { staticClass: "mes-info-title" }, [
                              _vm._v("商品编码"),
                            ]),
                            _c("span", { staticClass: "mes-info-content" }, [
                              _vm._v(_vm._s(_vm.product.productCode)),
                            ]),
                          ]),
                          _c("div", { staticClass: "mes-info-item" }, [
                            _c("span", { staticClass: "mes-info-title" }, [
                              _vm._v("单位"),
                            ]),
                            _c("span", { staticClass: "mes-info-content" }, [
                              _vm._v(_vm._s(_vm.product.unit)),
                            ]),
                          ]),
                          _c("div", { staticClass: "mes-info-item" }, [
                            _c("span", { staticClass: "mes-info-title" }, [
                              _vm._v("件包装"),
                            ]),
                            _c("span", { staticClass: "mes-info-content" }, [
                              _vm._v(_vm._s(_vm.product.packTotal)),
                            ]),
                          ]),
                          _c("div", { staticClass: "mes-info-item" }, [
                            _c("span", { staticClass: "mes-info-title" }, [
                              _vm._v("处方类型"),
                            ]),
                            _c("span", { staticClass: "mes-info-content" }, [
                              _vm._v(_vm._s(_vm.product.otcType)),
                            ]),
                          ]),
                          _c("div", { staticClass: "mes-info-item" }, [
                            _c("span", { staticClass: "mes-info-title" }, [
                              _vm._v("商品名称"),
                            ]),
                            _c("span", { staticClass: "mes-info-content" }, [
                              _vm._v(
                                _vm._s(_vm.$util.getGoodsName(_vm.product))
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "mes-info-item" }, [
                            _c("span", { staticClass: "mes-info-title" }, [
                              _vm._v("剂型"),
                            ]),
                            _c("span", { staticClass: "mes-info-content" }, [
                              _vm._v(_vm._s(_vm.product.dosageForm)),
                            ]),
                          ]),
                          _c("div", { staticClass: "mes-info-item" }, [
                            _c("span", { staticClass: "mes-info-title" }, [
                              _vm._v("品牌"),
                            ]),
                            _c("span", { staticClass: "mes-info-content" }, [
                              _vm._v(_vm._s(_vm.product.brandNm)),
                            ]),
                          ]),
                          _c("div", { staticClass: "mes-info-item" }, [
                            _c("span", { staticClass: "mes-info-title" }, [
                              _vm._v("生产企业"),
                            ]),
                            _c("span", { staticClass: "mes-info-content" }, [
                              _vm._v(_vm._s(_vm.product.manufacturer)),
                            ]),
                          ]),
                          _c("div", { staticClass: "mes-info-item" }, [
                            _c("span", { staticClass: "mes-info-title" }, [
                              _vm._v("规格"),
                            ]),
                            _c("span", { staticClass: "mes-info-content" }, [
                              _vm._v(_vm._s(_vm.product.spec)),
                            ]),
                          ]),
                          _c("div", { staticClass: "mes-info-item" }, [
                            _c("span", { staticClass: "mes-info-title" }, [
                              _vm._v("批准文号"),
                            ]),
                            _c("span", { staticClass: "mes-info-content" }, [
                              _vm._v(_vm._s(_vm.product.approvalNumber)),
                            ]),
                          ]),
                          _vm.userType === "BUYERS"
                            ? _c("div", { staticClass: "mes-info-item" }, [
                                _c("span", { staticClass: "mes-info-title" }, [
                                  _vm._v("最近效期"),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "mes-info-content" },
                                  [_vm._v(_vm._s(_vm.product.expDate))]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "detail-img-hr" }),
                        _c("div", {
                          staticClass: "detail-img second",
                          domProps: {
                            innerHTML: _vm._s(_vm.product.productDescrString),
                          },
                        }),
                        !_vm.product.productDescrString
                          ? _c("div", { staticClass: "empty-box" }, [
                              _c(
                                "div",
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "160px",
                                      height: "160px",
                                    },
                                    attrs: {
                                      src: _vm.emptyDefault,
                                      fit: "fill",
                                    },
                                  }),
                                  _c("p", { staticClass: "empty-tip" }, [
                                    _vm._v("暂无信息"),
                                  ]),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "说明书", name: "second" } },
                      [
                        _c("div", {
                          staticClass: "second",
                          domProps: {
                            innerHTML: _vm._s(_vm.product.instructionsString),
                          },
                        }),
                        !_vm.product.instructionsString
                          ? _c("div", { staticClass: "empty-box" }, [
                              _c(
                                "div",
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "160px",
                                      height: "160px",
                                    },
                                    attrs: {
                                      src: _vm.emptyDefault,
                                      fit: "fill",
                                    },
                                  }),
                                  _c("p", { staticClass: "empty-tip" }, [
                                    _vm._v("暂无信息"),
                                  ]),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm.isLogin
                      ? _c(
                          "el-tab-pane",
                          { attrs: { label: "下载商品资质", name: "fine" } },
                          [
                            _c(
                              "div",
                              { staticClass: "fine" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-top": "10px",
                                      "margin-bottom": "5px",
                                      "margin-left": "230px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "目前提供的pdf电子版的商品首营资质资料,如果需要纸质版,请联系业务员 "
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-table",
                                  {
                                    ref: "tableData",
                                    staticStyle: {
                                      margin: "0 10px 0 0",
                                      width: "100%",
                                    },
                                    attrs: { data: _vm.licenses, border: "" },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "productNm",
                                        label: "商品名称",
                                        align: "center",
                                        width: "180",
                                        "show-overflow-tooltip": "",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "productCode",
                                        label: "商品编码",
                                        align: "center",
                                        width: "180",
                                        "show-overflow-tooltip": "",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "pdfUrl",
                                        label: "操作",
                                        align: "center",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return scope.row.pdfUrl
                                                ? _vm._l(
                                                    scope.row.pdfUrl.split(","),
                                                    function (item, index) {
                                                      return _c(
                                                        "span",
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$util.downFiles(
                                                                      item,
                                                                      scope.row
                                                                        .productCode +
                                                                        "|" +
                                                                        scope
                                                                          .row
                                                                          .productNm
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "电子版资质资料"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  )
                                                : undefined
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.userType == "SALESMAN"
                      ? _c(
                          "el-tab-pane",
                          { attrs: { label: "批号有效期", name: "five" } },
                          [
                            _c(
                              "div",
                              { staticClass: "table-PHXQ-box" },
                              [
                                _c(
                                  "el-table",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.tableLoading,
                                        expression: "tableLoading",
                                      },
                                    ],
                                    ref: "tableDataPHXQ",
                                    staticStyle: {
                                      margin: "0 10px 0 0",
                                      width: "100%",
                                    },
                                    attrs: {
                                      data: _vm.tableDataPHXQ,
                                      border: "",
                                    },
                                  },
                                  _vm._l(_vm.phxqColumns, function (column) {
                                    return _c("el-table-column", {
                                      key: column.prop,
                                      attrs: {
                                        label: column.label,
                                        width: column.width,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                column.prop == "batchId"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            scope.row
                                                              .isTransport
                                                          )
                                                      ),
                                                      _vm.columnFlag == "Y"
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row
                                                                  .batchId
                                                              )
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ])
                                                  : column.prop == "costPrice"
                                                  ? _c("span", [
                                                      scope.row.isTransport ==
                                                      "调"
                                                        ? _c("div", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.getCostPrice(
                                                                    scope.row
                                                                      .costPrice,
                                                                    scope.row
                                                                      .dsj
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ])
                                                  : column.prop == "expDate"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            scope.row.expDate !=
                                                              null
                                                              ? _vm.$util.getTime(
                                                                  scope.row
                                                                    .expDate,
                                                                  "yyyy-MM-dd"
                                                                )
                                                              : "/"
                                                          ) +
                                                          " "
                                                      ),
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            scope.row[
                                                              column.prop
                                                            ]
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.additional.length
            ? _c(
                "el-dialog",
                {
                  staticClass: "addition-dialog",
                  attrs: {
                    width: "600px",
                    title: "加价购商品",
                    visible: _vm.showAdditionalProduct,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showAdditionalProduct = $event
                    },
                  },
                },
                [
                  _c("h3", [_vm._v(_vm._s(_vm.additional[0].activityNm))]),
                  _c(
                    "div",
                    { staticClass: "additionalBox" },
                    _vm._l(
                      _vm.additional[0].productVoList,
                      function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "additional" },
                          [
                            _c("div", { staticClass: "imgBox" }, [
                              _c("img", {
                                attrs: {
                                  src:
                                    item.thumbnailUrl ||
                                    item.picUrl ||
                                    _vm.productDefaultImg,
                                  alt: "",
                                },
                                on: {
                                  error: function ($event) {
                                    item.thumbnailUrl = _vm.productDefaultImg
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "additionContent" }, [
                              _c("div", { staticClass: "name" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$util.getGoodsName(item)) +
                                    " "
                                ),
                              ]),
                              _c("div", { staticClass: "spec" }, [
                                _vm._v(_vm._s(item.spec)),
                              ]),
                              _c("div", { staticClass: "manufacturer" }, [
                                _vm._v(_vm._s(item.manufacturer)),
                              ]),
                              _c("div", { staticClass: "price" }, [
                                _vm._v(
                                  "¥" +
                                    _vm._s(
                                      item.redemptionPrice ||
                                        item.price ||
                                        item.originalUnitPrice
                                    )
                                ),
                                _c("span", [
                                  _vm._v("¥" + _vm._s(item.salePrice)),
                                ]),
                              ]),
                            ]),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm.special.length
            ? _c(
                "el-dialog",
                {
                  staticClass: "addition-dialog",
                  attrs: {
                    width: "600px",
                    title: "特价换购商品",
                    visible: _vm.showSpecialProduct,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showSpecialProduct = $event
                    },
                  },
                },
                [
                  _c("h3", [_vm._v(_vm._s(_vm.special[0].activityNm))]),
                  _c(
                    "div",
                    { staticClass: "additionalBox" },
                    _vm._l(
                      _vm.special[0].productVoList,
                      function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "additional" },
                          [
                            _c("div", { staticClass: "imgBox" }, [
                              _c("img", {
                                attrs: {
                                  src:
                                    item.thumbnailUrl ||
                                    item.picUrl ||
                                    _vm.productDefaultImg,
                                  alt: "",
                                },
                                on: {
                                  error: function ($event) {
                                    item.thumbnailUrl = _vm.productDefaultImg
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "additionContent" }, [
                              _c("div", { staticClass: "name" }, [
                                _vm._v(_vm._s(_vm.$util.getGoodsName(item))),
                              ]),
                              _c("div", { staticClass: "spec" }, [
                                _vm._v(_vm._s(item.spec)),
                              ]),
                              _c("div", { staticClass: "manufacturer" }, [
                                _vm._v(_vm._s(item.manufacturer)),
                              ]),
                              _c("div", { staticClass: "price" }, [
                                _vm._v(
                                  "¥" +
                                    _vm._s(
                                      item.redemptionPrice ||
                                        item.price ||
                                        item.originalUnitPrice
                                    )
                                ),
                                _c("span", [
                                  _vm._v("¥" + _vm._s(item.salePrice)),
                                ]),
                              ]),
                            ]),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("ToolBar"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("span", [_vm._v("优惠券")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("span", [_vm._v("加价购")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("span", [_vm._v("特价采购")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "reduce-tag" }, [
      _c("span", [_vm._v("满减/送")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "detail-header-right-text" }, [
      _c("span", { staticClass: "title" }, [_vm._v("浏览记录")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info-detail-content" },
    [
      _c("Search", { attrs: { "index-nav": true } }),
      _c(
        "div",
        { staticClass: "nav-address" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { "separator-class": "el-icon-arrow-right" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页"),
              ]),
              _c(
                "el-breadcrumb-item",
                { attrs: { to: { path: "/info-channel" } } },
                [_vm._v("资讯中心")]
              ),
              _c("el-breadcrumb-item", [_vm._v(_vm._s(_vm.dataDateil.title))]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "detail-content" }, [
        _c("div", { staticClass: "detail-name" }, [
          _vm._v(_vm._s(_vm.dataDateil.title)),
        ]),
        _c("div", { staticClass: "detail-info" }, [
          _c("span", [
            _vm._v("发布时间：" + _vm._s(_vm.dataDateil.createTime)),
          ]),
        ]),
        _c("div", {
          staticClass: "info-content",
          domProps: { innerHTML: _vm._s(_vm.dataDateil.articleCont) },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import {downloadInvoice, downloadSheet} from "../api/member-center/order-manage/order-list";
import {getBus,getOrgId} from "./auth";

export function parseTime(time, cFormat) {
  if (!time) {
    return null
  }
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

//销售价为0的时候显示未报价
export function getPriceByZero(val,{price,salePrice,isDiscount}) {
    let value = String(val).replace(/,/g,'').replace(/￥/g,'')
    let salePrice1 = String(salePrice).replace(/,/g,'').replace(/￥/g,'')
    return isLogin() ? !!Number(value) ? `￥${toFixed(value)}` : !!Number(salePrice1) ? '￥0.00' : isDiscount === 'Y' ? '￥0.00' : '未报价' : '登录可见'
}

export function isLogin() {
    return !!sessionStorage.getItem('loginId')
}

export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
    decodeURIComponent(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace(/\+/g, ' ') +
    '"}'
  )
}

export function getTitle(type, name) {
  let titile
  switch (type) {
    case 'add':
      titile = '新增' + name
      break
    case 'edit':
      titile = '编辑' + name
      break
    case 'detail':
      titile = '查看' + name
      break
    default:
      titile = '新增' + name
  }
  return titile
}

export function isDisabled(type, isDisabled) {
  let disabled
  switch (type) {
    case 'add':
      disabled = false
      break
    case 'edit':
      if (typeof isDisabled === 'boolean') {
        disabled = isDisabled
      } else if (typeof isDisabled === 'function') {
        disabled = isDisabled()
      } else {
        disabled = false
      }
      break
    case 'detail':
      disabled = true
      break
    default:
      disabled = false
  }
  return disabled
}

export function deepClone(target) {
  let newObj = null
  if (target instanceof Array) {
    newObj = []
  } else if (target instanceof Object) {
    newObj = {}
  } else {
    return target
  }
  for (const key in target) {
    if (target instanceof Array || target instanceof Object) {
      newObj[key] = deepClone(target[key])
    } else {
      newObj[key] = target[key]
    }
  }
  return newObj
}

export function formatTreeData(data, isDisabled = false) {
  return data.map(item => {
    const dataItem = {
      id: item.resCode,
      label: item.resNm,
      disabled: isDisabled
    }
    if (item.children && item.children.length > 0) {
      dataItem.children = formatTreeData(item.children, isDisabled)
    } else if (item.btnList && item.btnList.length > 0) {
      dataItem.children = formatTreeData(item.btnList, isDisabled)
    }
    return dataItem
  })
}

export function formatTreeDataVisi(data, id, attrName) {
  return data.map(item => {
    item.add = false
    item.edit = false
    item.del = false
    if (item.categoryId === id) {
      item[attrName] = true
    }
    if (item.childTree && item.childTree.length > 0) {
      item.childTree = formatTreeDataVisi(item.childTree, id, attrName)
    }
    return item
  })
}

export function formatAreaTree(data) {
  const name = ['provinceGroup', 'cityGroup', 'regionGroup', 'townGroup']
  const areaName = []
  const ids = []
  const loop = (data, index) => {
    return data.map(item => {
      const obj = {
        districtId: item.districtId,
        parentCode: item.parentCode,
        nodeCode: item.nodeCode,
        name: item.name,
        districtType: item.districtType
      }
      if (item.districtList && item.districtList.length > 0) {
        obj[name[index]] = loop(item.districtList, ++index)
      } else {
        obj[name[index]] = []
        areaName.push(item.name)
        ids.push(item.districtId)
      }
      return obj
    })
  }
  return {
    treeData: { [name[0]]: loop(data, 1) },
    areaName,
    ids
  }
}

export function toThousands(num,len = 4) {
  num = String(num || 0)
  let decimal = ''
  if (num.lastIndexOf('.') !== -1) {
    decimal = num.split('.')[1]
  }
  num = num.split('.')[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  // let str = ''
  // for(let i = 0;i < len;i ++){
  //   str += '0'
  // }
  // num += '.' + (decimal + str).slice(0, len)
  num += '.' + decimal
  return num
}

export function scrollAnimation(currentY, targetY) {
  // 计算需要移动的距离
  let needScrollTop = targetY - currentY
  let _currentY = currentY
  setTimeout(() => {
    // 一次调用滑动帧数，每次调用会不一样
    const dist = Math.ceil(needScrollTop / 10)
    _currentY += dist
    window.scrollTo(_currentY, currentY)
    // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
    if (needScrollTop > 10 || needScrollTop < -10) {
      scrollAnimation(_currentY, targetY)
    } else {
      window.scrollTo(_currentY, targetY)
    }
  }, 1)
}

// 去除逗号
export function removeCommas(num) {
  if(num){
    if (num.toString().indexOf(',') !== -1) {
      num = num.replace(/[\*,\-]/g, '')
    }
    return Number(num)
  }else{
    return Number(num)
  }
}

// 计算销售价
export function calcSalePrice(list) {
  if (!list) return '0.00~0.00'
  const salePricelist = []
  list.map(val => {
    salePricelist.push(removeCommas(val.price))
  })
  const saleMax = Math.max.apply(null, salePricelist) ? Math.max.apply(null, salePricelist) : 0
  const saleMin = Math.min.apply(null, salePricelist) ? Math.min.apply(null, salePricelist) : 0
  return saleMin.toFixed(2) + '~' + saleMax.toFixed(2)
}

export function countDown (end) {
  end = String(end)
  let newTime = new Date().getTime()
  let endTime = new Date(end.replace(/-/g, '/'))
  let obj = null
  if (endTime - newTime > 0) {
    let time = (endTime - newTime) / 1000
    let day = parseInt(time / (60 * 60 * 24))
    let hou = parseInt(time % (60 * 60 * 24) / 3600)
    let min = parseInt(time % (60 * 60 * 24) % 3600 / 60)
    let sec = parseInt(time % (60 * 60 * 24) % 3600 % 60)
    const timeFormat = param => {
      return (param < 10 ? '0' + param : param).toString()
    }
    obj = {
      day: timeFormat(day),
      hou: timeFormat(hou),
      min: timeFormat(min),
      sec: timeFormat(sec)
    }
  } else {
    obj = {
      day: '00',
      hou: '00',
      min: '00',
      sec: '00'
    }
  }
  return obj
}

/*
* 递归，深拷贝
* Object new_obj新对象，Object obj拷贝目标
* */
export const copyData = (new_obj, obj) => {
  for (let key in obj) {
    if (typeof obj[key] === "object") {
      new_obj[key] = obj[key];
      copyData(new_obj[key], obj[key]);
    } else {
      new_obj[key] = obj[key];
    }
  }
  return new_obj;
};

// 保留小数点
// export const toFixed = (value, len = 4) => {
//   let val = String(value || 0).replace(/,/g,'').replace(/￥/g,'')
//   let arr = val.split('.')
//   if (arr.length === 1) {
//     let str = arr[0]
//     for (let i = 0; i < len; i++) {
//       if (i === 0) {
//         str += '.0'
//       } else {
//         str += '0'
//       }
//     }
//     return toThousands(str,len)
//   } else {
//     let str = arr[0]
//     for (let i = 0; i < len; i++) {
//       if (i === 0) {
//         str += '.' + arr[1][i]
//       } else {
//         str += arr[1][i] || '0'
//       }
//     }
//     return toThousands(str,len)
//   }
// }
/*
* 返回两数相加的值,修复加法bug .9999999999
* len 保留的小数长度
* */
export const adding = (a,b,len = 2) => {
    a = Number(a) || 0
    b = Number(b) || 0
    a = a * 1000000
    b = b * 1000000
    let val = (a + b).toFixed(0)
    return toFixed(val/1000000,len)
}

/*
* 返回两数相乘的值，修复乘法bug .9999999999
* len 保留的小数长度
* */
export const multiply = (a,b,len = 2)=>{
    a = Number(a) || 0
    b = Number(b) || 0
    a = a * 1000000
    b = b * 1000000
    let val = (a * b).toFixed(0)
    return toFixed(val/(1000000 * 1000000),len)
}

export const toFixed = (val) => {
  val = typeof val === 'string' ? val.replace(/,/g,'') : val;
  let value = String(val || 0),
      str = '',
      arr = value.split('.');

  if(arr.length > 1){
    if(arr[1].length > 4){
      str = arr[0] + '.' + arr[1].slice(0,4)
    }else if(arr[1].length >= 2){
      str = value
    }else{
      str = arr[0] + '.' + (arr[1] + '000').slice(0,2)
    }
  }else {
    str = arr[0] + '.00'
  }
  return toThousands(str)
}



/*
* type    'http','fileId'   ,要检单的用fileId
* */
export const downFiles = (url, name = '', type = 'http') => {
  return new Promise((resolve, reject) => {
    let urls = typeof url === 'string' ? [url] : (url || []);
    let params;
    let api;
    if (type === 'http') {
      api = downloadInvoice
      params = {filePathList: urls}
    } else {
      api = downloadSheet
      params = {
        fileIdList: urls,
        orgId: getBus().orgId
      }
    }

    function download(res) {
      downloadBlobFile(res)
    }

    console.log(params)
    api(params).then(res => {
      if(res.data.size < 1000){
        fileToJson(res.data).then(resq => {
          //code   '5' 文件不存在    'FILE_OPERATION_FAILED'  下载失败
          if (resq.code) {
            reject(resq)
          } else {
            download(res)
            resolve()
          }
        })
      }else {
          download(res)
          resolve()
      }
    }).catch(err => {
      reject()
    })


  })
}

/*
* 下载文件
* */
const downloadBlobFile = (res,name)=>{
  return new Promise((resolve,reject)=>{
    const reader = new FileReader()

    reader.onload = resq => {
      const {result} =  resq.target// 得到字符串
      try{    //能解析成对象，返回的就是blob类型的错误信息，解析成对象，抛出对象，提示错误
        const data = JSON.parse(result) // 解析成json对象
        if(typeof data === "object"){
          if(data.code && data.code !== '0'){
            reject(data)
          }
        }
      }catch (e){   //无法解析成对象，下载的是正确的blob文件
        let blob = new Blob([res.data], {type: 'application/zip'})
        const disposition = res.headers['content-disposition'].split(";")[1].split("=")[1];
        var fileName = decodeURI(disposition);
        //去掉双引号
        fileName = fileName.replace(/\"/g, "");
        let link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = name || fileName
        link.click()
        link.remove()
        setTimeout(()=>{
          resolve()
        },1000)
      }
    }
    reader.readAsText(new Blob([res.data]), 'utf-8')
  })
}

/**
 * 将file对象（.json文件）转为json对象
 * @param {File} file file对象
 */
const fileToJson = (file)=> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = res => {
      const { result } = res.target // 得到字符串
      const data = JSON.parse(result) // 解析成json对象
      resolve(data)
    } // 成功回调
    reader.onerror = err => {
      reject(err)
    } // 失败回调
    reader.readAsText(new Blob([file]), 'utf-8') // 按照utf-8编码解析
  })
}

//下载药检单   {"fileIdList":["str"],"orgId":0}
export const downSheetFiles = (url,orgId,name = '')=>{
  return new Promise((resolve,reject)=>{
    let urls = typeof url === 'string' ? [url] : (url || []);
      let params = {fileIdList: urls,orgId}
      downloadSheet(params).then(res =>{
        if(res.code == 'FILE_OPERATION_FAILED'){
          reject(res)
        }else {
          let blob = new Blob([res.data], {type :'application/zip'})
          const disposition = res.headers['content-disposition'].split(";")[1].split("=")[1];
          var fileName = decodeURI(disposition);
          //去掉双引号
          fileName = fileName.replace(/\"/g, "");
          let link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = name || fileName
          link.click()
          link.remove()
          resolve()
        }
      }).catch(err=>{
        reject()
      })
  })
}

export const getGoodsName = ({productNm,drugNm})=> {
    return productNm && drugNm ? `${drugNm}(${productNm})` : drugNm || productNm
}

/*
* 数字长度小于1的话在前面加0，用于格式化日期
* */
const formatNumber = (n) => {
  const str = n.toString();
  return str[1] ? str : `0${str}`;
};

/*
* 获取时间
* string date时间，string type时间格式
* */
export const getTime = (date = new Date().getTime(),type = "yyyy-MM-dd HH:mm:ss") => {
  date = date ? date : new Date().getTime()
  typeof date === 'string' ? date =  date.replace(/-/g,'/') : '';
  date = typeof date === "object" ? date : new Date(date);
  let year = date.getFullYear(),
      month = date.getMonth() + 1,
      day = date.getDate(),

      hour = date.getHours(),
      minute = date.getMinutes(),
      second = date.getSeconds(),

      t0 = date.getTime(),
      t1 = [year, month, day].map(formatNumber).join("-"),
      t2 = [year, month, day].map(formatNumber).join("/"),
      t3 = [hour, minute, second].map(formatNumber).join(":"),

      newTime = "";
  switch (type) {
    case "yyyy-MM-dd HH:mm:ss":
      newTime = `${t1} ${t3}`;
      break;
    case "yyyy/MM/dd HH:mm:ss":
      newTime = `${t2} ${t3}`;
      break;
    case "yyyy-MM-dd":
      newTime = t1;
      break;
    case "yyyy":
      newTime = year;
      break;
    case "MM":
      newTime = month;
      break;
    case "dd":
      newTime = day;
      break;
    case "HH":
      newTime = hour;
      break;
    case "mm":
      newTime = minute;
      break;
    case "ss":
      newTime = second;
      break;
    case "yyyy-MM":
      newTime = `${year}-${month}`;
      break;
    case "yyyy/MM/dd":
      newTime = t2;
      break;
    case "timeStamp-13":
      newTime = t0;
      break;
    case "timeStamp-10":
      newTime = String(t0).substr(0,10);
      break;
    default:
      newTime = `${t1} ${t3}`;
      break;
  }
  return newTime;
};

export default {
  toFixed,
  downFiles,
  getPriceByZero,
  isLogin,
  copyData,
  adding,
  multiply,
  getTime,
  countDown,
  calcSalePrice,
  removeCommas,
  scrollAnimation,
  toThousands,
  formatAreaTree,
  formatTreeDataVisi,
  downloadBlobFile,
  deepClone,
  isDisabled,
  getTitle,
  param2Obj,
  formatTime,
  parseTime,
  getGoodsName
}

<template>
  <div class="brand-center-content">
    <Search :index-nav="true"/>
    <div class="brand-content">
      <div class="brand-bg"></div>
      <div class="brand">
        <!--搜索框-->

        <div data-v-7b59048f="" class="search-input el-input el-input--large el-input-group el-input-group--append" style="width: 98%;padding-block-end:10px">
          <!----><input type="text" autocomplete="off" placeholder="请输入活动名称/商品名称" v-model="searchForm.searchParams" class="el-input__inner"><!----><!---->
          <div class="el-input-group__append">
            <button data-v-7b59048f="" type="button" @click="handleSearch" class="el-button search-button el-button--default"><!---->
            <i class="el-icon-search"></i><!---->
          </button>
          </div><!---->
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="优惠套餐" name="first">
            <!--搭配套餐-->
            <swiper-package v-if="swiperData.length" :model="'more'"
                            :swiper-data="swiperData"></swiper-package>
            <!--组合套餐-->
            <swiper-package v-if="FIXED_PACKAGE.length" :model="'more'" type="FIXED_PACKAGE"
                            :swiper-data="FIXED_PACKAGE"></swiper-package>
          </el-tab-pane>
          <el-tab-pane label="自选套餐" name="second">
            <swiper-package2 v-if="giftsData.length" :model="'more'"
                            :swiper-data="giftsData" @openGifts="handleOpenGiftsDialog"></swiper-package2>
            <el-empty v-else description="暂无数据"></el-empty>
          </el-tab-pane>
        </el-tabs>

        <!--分页-->
        <im-pagination
                v-if="total"
                :total="total"
                :hide-on-single-page="false"
                :current-page.sync="currentPage"
                :page-size.sync="pageSize"
                :hideOnSinglePage="false"
                :isShowMainInfo="true"
                @pagination="handlePagination"
        />
      </div>
    </div>
    <giftsDialog ref="giftsDialogRef" :activeType="nowActivityType" :fullCondition="fullCondition" @hideDialog="hideDialog"></giftsDialog>
  </div>
</template>

<script>
  import { pageDiscountPackage, pageFullGiftActivity, getFullGiftsList } from '@/api/promotion-zone'
  import Search from '@/components/Search/Search'
  import SwiperPackage from '../../detail/components/swiper-package'
  import SwiperPackage2 from './components/swiper-package'
  import giftsDialog from './components/gifts-dialog'
  import { toThousands, removeCommas } from '@/utils'
  import { addItem, batchAddItem } from '@/api/detail'
  import { getInfo } from '@/api/user'
  import {getToken, getBus} from "@/utils/auth";
  import { mapGetters } from 'vuex'

  export default {
    components: {
      Search,
      SwiperPackage,
      SwiperPackage2,
      giftsDialog
    },
    data () {
      return {
        searchForm: {
          searchParams: ''
        },
        swiperData:[],
        giftsData: [],
        pageSize: 10,
        total: 0,
        currentPage: 1,
        COLLOCATION_PACKAGE: [],
        FIXED_PACKAGE: [],
        activeName: 'first',
        nowActivityType: 0,
        fullCondition: 0
      }
    },
    created () {
      this.getProduct()
      // this.getOptionalProduct()
    },
    methods: {
      hideDialog() {
        // this.nowActivityType = 0
        // this.fullCondition = 0
      },
      handleOpenGiftsDialog(id, item, fullReduceSendRuleId) {
        // this.nowActivityType = parseInt(val)
        // this.fullCondition = parseFloat(num)
        let params = []
        item.map(val => {
          params.push({
            "skuId": val.skuId,
            "productId": val.productId,
            "productCode": val.productCode,
            "quantity": val.quantity
          })
        })
        getFullGiftsList(id,{masterProductConfigsList: params}).then(res => {
          if(res.code == 0) {
            if(res.data.frsPresentRelList !== null) {
              // 触发活动满减
              this.$refs.giftsDialogRef.open(res.data.frsPresentRelList, res.data.extCfg, res.data.wholeQuantity, item, id, null, [], res.data.fullReduceSendRuleId)
            } else {
              // 未触发活动满减直接加入购物车
              let itemList = item.map(val => {
                return {
                  skuId: val.skuId,
                  quantity: val.quantity
                }
              })
              batchAddItem({ itemList }).then(res => {
                const { code } = res
                if (code === '0') {
                  this.$message.success('加入成功！')
                  this.$store.dispatch('user/setCartNum', res.data.allProductTypeTotalQuantity)
                }
              }).catch(err=>{
              })
            }
          }
        })
      },
      handleClick(tab, event) {
        // console.log(tab, event);
        this.currentPage = 1
        if (this.activeName === 'first') {
          this.getProduct()
        } else if (this.activeName === 'second') {
          this.getOptionalProduct()
        }

      },
      handlePagination (data) {
        this.currentPage = Math.min(this.currentPage, Math.ceil(this.total / data.pageSize))
        this.getProduct()
      },
      // 自选套餐
      getOptionalProduct() {
        let params = {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          condition: {
            isPc: 'Y',
            orgId:getBus().orgId,
            activityNm:this.searchForm.searchParams
          }
        }
        pageFullGiftActivity(params).then(res => {
          if (res.code === '0') {
            let giftsList = res.data.records
            let temporaryArr = []
            giftsList.map((item, index) => {
              let list = item.activityProductInfoPage.records
              if (list.length) {
                list.forEach(val => {
                  val.select = false
                })
                temporaryArr.push(item)
              }
            })
            this.giftsData = [...temporaryArr]
            console.log("this.giftsData", this.giftsData)
          }
        })
      },
      getProduct() {
        pageDiscountPackage({
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          condition: {
            isPc: 'Y',
            orgId:getBus().orgId,
            activityNm:this.searchForm.searchParams
          }
        }).then(res => {
          const { data } = res
          data.records.sort(function(a,b){
            return a.endTime > b.endTime ? 1 : -1
          })
          // this.swiperData = data.records
          if (data && data.records) {
            let fix = data.records.filter(res => res.discountPackageType === 'FIXED_PACKAGE')
            let coll = data.records.filter(res => res.discountPackageType === 'COLLOCATION_PACKAGE')
            coll.forEach((data, index) => {
              let list = data.productMessageList
              if (!list.length) {
                coll.splice(index, 1)
              } else {
                list.forEach(item => {
                  item.retailPrice = toThousands(item.retailPrice)
                  item.discountPackagePrice = toThousands(item.discountPackagePrice)
                  item.select = false
                })
                list[0].select = true
                data.total = toThousands(removeCommas(list[0].salePrice)*list[0].quantity)
                data.packagePrice = toThousands(removeCommas(list[0].discountPackagePrice)*list[0].quantity)
                data.select = 1
              }
            })
            // this.COLLOCATION_PACKAGE = coll
            fix.forEach((data, index) => {
              let total = 0
              let list = data.productMessageList
              if (!list.length) {
                fix.splice(index, 1)
              } else {
                list.forEach(item => {
                  total += item.salePrice ? removeCommas(item.salePrice)*item.quantity : 0
                  item.price = toThousands(item.price)
                })
                data.total = toThousands(total)
              }
            })
            // this.FIXED_PACKAGE = fix

            this.swiperData = [...fix,...coll]
          }
          this.total = data.total
        })
      },
      handleSearch(){
        this.getProduct();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .brand-center-content {
    .brand-content {
      position: relative;
      overflow-y: hidden;
      min-width: 1200px;

      .brand-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        min-height: 2000px;
        min-width: 1200px;
        background-image: url("../../../assets/img/discount-package/discount-package-bg.png");
        background-repeat: repeat-x;
        background-size: contain;
      }

      .brand {
        width: 1220px;
        margin: 420px auto 40px;
      }
    }
  }
</style>

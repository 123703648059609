<template>
  <div class="account-manage">
    <h4 class="title">帐户管理</h4>
    <div class="table-page">
      <!-- 表格内容 -->
<!--      <im-table-page-->
<!--          ref="tablePage"-->
<!--          :data.sync="tableData"-->
<!--          :remote-method="queryData"-->
<!--          @updated="updated"-->
<!--      >-->
      <el-table ref="tablePage" :data="tableData" border>
        <el-table-column type="index" align="center" show-overflow-tooltip
                         fixed="left">
        </el-table-column>
        <el-table-column label="登录账号" align="center" prop="loginId" width="110px" show-overflow-tooltip />
        <el-table-column label="采购员" prop="purchaserName" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="padding-left: 12px">{{ scope.row.purchaserName}}</span>
            <span v-if="scope.row.isDefault === 'Y'" class="primary-account">主账号</span>
          </template>
        </el-table-column>
        <el-table-column label="启用状态" align="center" prop="isEnable" width="82px" show-overflow-tooltip>
          <template slot-scope="scope">
            <span :style="{color:(scope.row.isEnable==='Y'?'#666666':'#FF3B30')}">{{ scope.row.isEnable === 'N' ? '禁用' : '启用'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="联系手机" align="center" prop="loginMobileId" width="110px" show-overflow-tooltip />
        <el-table-column label="身份证号" align="center" prop="ipNum" width="170px" show-overflow-tooltip />
        <el-table-column label="身份证有效期" align="center" prop="ipEndDate" width="100px" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.ipEndDate ? scope.row.ipEndDate.substring(0,10) : ''}}
          </template>
        </el-table-column>
        <el-table-column label="委托书开始日期" align="center" prop="proxyStartDate" width="100px" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.proxyStartDate ? scope.row.proxyStartDate.substring(0,10) : ''}}
          </template>
        </el-table-column>
        <el-table-column label="委托书有效期" align="center" prop="proxyEndDate" width="100px" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.proxyEndDate ? scope.row.proxyEndDate.substring(0,10) : ''}}
          </template>
        </el-table-column>
      </el-table>
<!--      </im-table-page>-->
    </div>
  </div>
</template>

<script>
  import { getAccountInfo } from '@/api/member-center/personal-center'

  export default {
    name: 'AccountManage',
    data () {
      return {
        tableData: [],
        searchForm: {
          // pageSize: 10,
          // pageNum: 0
          // activityNm: ''
        }
      }
    },
    mounted() {
      this.queryData()
    },
    methods: {
      queryData () {
        getAccountInfo().then(res => {
          this.tableData = res.data
        })
      },
      updated () {
        this.$refs.tablePage.doLayout()
      },
    }
  }
</script>

<style scoped lang="scss">
  .account-manage {
    width: 100%;
    height: 100%;
    background: #ffffff;

    .primary-account {
      margin-left: 4px;
      display: inline-block;
      padding: 0 4px;
      color: #ffffff;
      background: $ThemeColor;
      border-radius: 3px;
    }

    .title {
      text-align: left;
      padding-left: 28px;
      font-size: 16px;
      height: 50px;
      line-height: 50px;
      font-weight: 600;
      color: rgba(43, 43, 43, 1);
      border-bottom: 1px solid #E5E5E5;
    }

    .table-page {
      padding: 16px 20px;

      /deep/ .el-table {
        thead {
          font-size: 14px;
          font-weight: 600;
          color: rgba(34, 34, 34, 1);
        }

        .el-table__header {
          background: #E5E5E5;
        }

        .cell {
          padding: 0;
          color: #666666;
        }

        .el-table__header th {
          padding: 10px 0;

          .cell {
            padding: 0 12px;
            color: rgba(34, 34, 34, 1);
          }
        }
      }

    }
  }
</style>

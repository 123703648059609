import {ApiPost} from "@/api";

/**
 * @description 往来账单核对列表
 */
export const getIntercourseBill = data => ApiPost('/pc/currentBills/pageCurrentBills',data)

/*
* 往来账单导出
* */
export const exportIntercourseBill = data => ApiPost('/pc/currentBills/exportCurrentBillsExcel',data,{
  responseType: 'blob'
})

/**
 * @description 我的优惠券
 */
export const pageCouponList = data => ApiPost('/pc/couponReceiveSituation/pageCouponList',data)

/**
 * @description 我的优惠券数量
 */
export const couponNumber = data => ApiPost('/pc/couponReceiveSituation/couponNumber',data)
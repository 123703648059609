var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.consultPriceDraftVisible
    ? _c(
        "im-drawer",
        {
          staticClass: "input-excel-drawer-page",
          attrs: {
            visible: _vm.consultPriceDraftVisible,
            title: "保存询价单",
            size: _vm.size,
            modal: false,
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.consultPriceDraftVisible = $event
            },
            confirm: _vm.handleconfirm,
            cancel: _vm.closeConfirm,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.formRules,
                "label-width": "160px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "询价单标题：", prop: "title" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { palceholder: "请为该询价单填写标题" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
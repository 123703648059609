import {ApiGet, ApiPost} from "@/api";

/**
 * @description 会员首页订单状态数量
 */
export const getOrderNum = data => ApiGet('/pc/order/memberDetailspOrderNum',data)

/**
 * @description 会员首页查看
 *
 */
export const memberDetails = data => ApiGet('/buyers/memberDetails',data)

/**
 * @description  查询用户最后一次登录信息
 *
 */
export const getByUserIdLast = data => ApiGet('/pc/userLoginLog/getByUserIdLast',data)

/**
 * @description  获取用户签名
 */
export const getUserSignature = data => ApiGet('/pc/userSignature/getUserSignature',data)

/**
 * @description  修改用户签名
 */
export const saveUserSignature = data => ApiPost('/pc/userSignature/saveOrUpdateUserSignature',data)
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "coupon-center-page" },
    [
      _c("Search", { attrs: { "index-nav": true, panelNav: true } }),
      _c("div", { staticClass: "coupon-center-content" }, [
        _c("div", { staticClass: "coupon-banner-bg" }),
        _c(
          "div",
          { staticClass: "coupon-item-box" },
          [
            _vm._l(_vm.tableData, function (item, index) {
              return item.isShow === "Y"
                ? [
                    _c(
                      "el-row",
                      {
                        key: item.couponUseSituationId,
                        class: {
                          "coupon-item-content": "coupon-item-content",
                          "has-used": item.isUse === "Y",
                          "has-pass": item.remainQuantity <= 0,
                        },
                        style: {
                          "margin-right":
                            index === _vm.tableData.lenght ? 0 : "10px",
                        },
                      },
                      [
                        item.remainQuantity <= 0
                          ? [
                              _c("img", {
                                staticClass: "coupon-pass-time",
                                attrs: {
                                  src: require("../../../assets/img/promotion-zone/coupon-center/coupon-pass-time.png"),
                                  alt: "",
                                },
                              }),
                            ]
                          : _vm._e(),
                        _c(
                          "el-col",
                          { staticClass: "col-1" },
                          [
                            item.couponType === "FULL_REDUCTION"
                              ? [
                                  _c("span", { staticClass: "col-1-full" }, [
                                    _vm._v("¥"),
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "col-1-discount" },
                                    [_vm._v(_vm._s(item.fullReductionAmount))]
                                  ),
                                  _c("span", { staticClass: "col-1-cont" }, [
                                    _vm._v(
                                      "满" + _vm._s(item.fullAmount) + "元使用"
                                    ),
                                  ]),
                                ]
                              : [
                                  _c(
                                    "span",
                                    { staticClass: "col-1-discount" },
                                    [_vm._v(_vm._s(item.discount))]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "col-1-2-discount-text" },
                                    [_vm._v("折")]
                                  ),
                                  _c("span", { staticClass: "col-1-cont" }, [
                                    _vm._v(
                                      "特定商品" + _vm._s(item.discount) + "折"
                                    ),
                                  ]),
                                ],
                          ],
                          2
                        ),
                        _c("el-col", { staticClass: "col-3-text" }, [
                          _c(
                            "p",
                            [
                              item.couponType === "FULL_REDUCTION"
                                ? _c(
                                    "span",
                                    { staticClass: "coupon-type-text" },
                                    [_vm._v("[满减券]")]
                                  )
                                : _c(
                                    "span",
                                    { staticClass: "coupon-type-text" },
                                    [_vm._v("[折扣券]")]
                                  ),
                              _vm._v(" " + _vm._s(item.couponNm) + " "),
                              item.illustrate
                                ? _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: item.illustrate,
                                        placement: "top-start",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-question",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          item.useTimeType === "IN_THE_DAY"
                            ? _c("p", [
                                _vm._v(
                                  "领券当日起" +
                                    _vm._s(item.useValidDay) +
                                    "天内可用"
                                ),
                              ])
                            : item.useTimeType === "TIMES_OF_THE_DAY"
                            ? _c("p", [
                                _vm._v(
                                  "领券次日起" +
                                    _vm._s(item.useValidDay) +
                                    "天内可用"
                                ),
                              ])
                            : _c("p", [
                                _vm._v(
                                  _vm._s(
                                    item.useStartTime
                                      .split(" ")[0]
                                      .replace(new RegExp("-", "gm"), ".")
                                  ) +
                                    " - " +
                                    _vm._s(
                                      item.useEndTime
                                        .split(" ")[0]
                                        .replace(new RegExp("-", "gm"), ".")
                                    )
                                ),
                              ]),
                        ]),
                        item.isReciever === "N"
                          ? _c(
                              "el-button",
                              {
                                staticClass: "use-coupon-btn",
                                attrs: { disabled: item.remainQuantity <= 0 },
                                on: {
                                  click: () => {
                                    _vm.$router.push("/")
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v("去"),
                                  _c("br"),
                                  _vm._v("使"),
                                  _c("br"),
                                  _vm._v("用"),
                                ]),
                              ]
                            )
                          : item.remainQuantity <= 0
                          ? _c(
                              "el-button",
                              {
                                staticClass: "use-coupon-btn",
                                attrs: { disabled: item.remainQuantity <= 0 },
                              },
                              [
                                _c("span", [
                                  _vm._v("立"),
                                  _c("br"),
                                  _vm._v("即"),
                                  _c("br"),
                                  _vm._v("领"),
                                  _c("br"),
                                  _vm._v("取"),
                                ]),
                              ]
                            )
                          : item.isReciever === "Y"
                          ? _c(
                              "el-button",
                              {
                                staticClass: "use-coupon-btn",
                                attrs: { disabled: item.remainQuantity <= 0 },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleReceive(item)
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v("立"),
                                  _c("br"),
                                  _vm._v("即"),
                                  _c("br"),
                                  _vm._v("领"),
                                  _c("br"),
                                  _vm._v("取"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        item.userReceivedQuantity > 0
                          ? _c("i", { staticClass: "has-receive-icon" })
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                : _vm._e()
            }),
            _vm.tableData.length === 0 ? _c("table-empty-box") : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="expandingCustomersDiv">
    <div class="title">我要拓客</div>
    <div class="tip">
      <img class="icon" src="@/assets/img/customer-service/info.png" >
      复制拓客链接、APP小程序二维码，即可进
    </div>
    <el-form ref="form" :model="form" label-width="120px" class="from">
      <el-form-item label="拓客链接：" class="formItem" prop="customerName">
        <el-input v-model="form.url" placeholder="输入单位全称" class="urlInput" @input="changeUrl"></el-input>
        <el-button class="copyUrlBt" size="small" @click="copyUrl">复制链接</el-button>
      </el-form-item>
      <el-form-item label="APP二维码：" class="formItem qrcodeFrom" prop="customerName">
        <div class="qrcodeDiv">
          <div id="qrcodeApp" ></div>
        </div>
        <img class="qrcodeImg" src="@/assets/img/customer-service/favicon.png">
        <el-button class="copyQrcodeBt" size="small" @click="copyQrcode">复制二维码</el-button>
      </el-form-item>
      <el-form-item label="小程序二维码：" class="formItem qrcodeFrom" prop="customerName">
        <div class="qrcodeDiv">
          <div id="qrcode" ></div>
        </div>
        <img class="qrcodeImg" src="@/assets/img/customer-service/favicon.png">
        <el-button class="copyQrcodeBt" size="small" @click="copyQrcode">复制二维码</el-button>
      </el-form-item>
    </el-form>
    <success-message
    v-show="showMessage"
    class="messageBox"
    :message="message"></success-message>
  </div>
</template>

<script>
import successMessage from './successMessage'
import QRCode from "qrcodejs2"

export default {
  name: "expandingCustomers",
  data(){
    return{
      form: {
        url: 'http://ytds.drugoogle.com/'
      },
      message: '',
      showMessage: false,
      link: 'http://ytds.drugoogle.com/'
    }
  },
  components: {
    successMessage,
    QRCode
  },
  methods: {
    changeUrl(val) {
      this.form.url = 'http://ytds.drugoogle.com/'
    },
    copyUrl() {
      this.message = '获取拓客链接成功，可复制链接进行分享'
      this.copyToClipboard('http://ytds.drugoogle.com/')
      this.show()
    },
    copyQrcode() {
      this.message = '获取拓客链接成功，可复制二维码图片进行分享'
    },
    show() {
      this.showMessage = true
      setTimeout(()=>{
        this.showMessage = false
      },1000)
    },
    // 复制信息到粘贴板
    copyToClipboard(s){
      if(window.clipboardData){
        window.clipboardData.setData('text',s);
      }else {
        (s => {
          document.oncopy = e => {
            e.clipboardData.setData('text',s);
            e.preventDefault();
            document.oncopy=null;
          }
        })(s);
        document.execCommand('Copy');
      }
    },
    qrcode (name) {
      new QRCode(name, {
        width: 88,
        height: 88,        // 高度
        text:  this.link,   // 二维码内容
        correctLevel : QRCode.CorrectLevel.H
        // render: 'canvas' ,   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        // background: '#f0f',   // 背景色
        // foreground: '#ff0'    // 前景色
      })
    }
  },
  mounted() {
    this.qrcode('qrcodeApp');
    this.qrcode('qrcode');
  }
}
</script>

<style lang="scss" scoped>
.expandingCustomersDiv{
  background-color: #FFFFFF;
  text-align: left;
  padding: 0 20px;
  min-height: 780px;
  .title {
    color: #2B2B2B;
    font-size: 16px;
    line-height: 50px;
    height: 50px;
    padding-left: 8px;
    font-weight: 700;
    border-bottom: #E5E5E5 solid 1px;
  }
  .tip {
    margin-top: 16px;
    background-color: #F1F7FE;
    font-size: 14px;
    color: #222222;
    height: 40px;
    line-height: 40px;
    padding-left: 16px;
    display: flex;
    .icon {
      width: 16px;
      height: 16px;
      margin-top: 12px;
      margin-right: 8px;
    }
  }
  .from{
    margin-top: 15px;
    .urlInput {
      width: 400px;
      /deep/ .el-input__inner {
        height: 32px;
      }
    }
    .copyUrlBt {
      color: #0971EB;
      border-color: #0971EB;
      margin-left: 12px;
      height: 32px;
      font-size: 14px;
    }
    /deep/ .qrcodeFrom .el-form-item__content {
      display: flex;
    }
    .qrcodeDiv{
      width: 100px;
      height: 100px;
      border: 1px solid #cccccc;
      border-radius: 3px;
      padding: 5px;
    }
    .qrcodeImg {
      width: 20px;
      height: 20px;
      position: absolute;
      z-index: 100;
      background-color: #ffffff;
      left: 40px;
      top: 40px;
    }
    .qrcode {
      width: 100px;
      height: 100px;
    }
    .copyQrcodeBt {
      width: 104px;
      height: 32px;
      color: #666666;
      border-color: #cccccc;
      margin-left: 12px;
      margin-top: 68px;
    }
  }
}
.messageBox {
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
</style>

<template>
  <im-page-modal
      class="good-collection-page"
      v-loading="loading"
      title="收藏的商品"
      :isShowBack="false"
  >
    <div class="aptitudes" v-if="license.isFailure === 'Y'">温馨提示：{{userType !=='SALESMAN'?'您有'  +  license.failureSum + '张证照已过期,请联系业务员更换,以免影响您的正常采购' : '该客户有'+ license.failureSum  +  '张证照已过期，以免影响正常采购！'}}<el-button size="small" type="text" @click="handleConfirm">查看详情</el-button>
      </div>
    <div v-show="tableData.length === 0" class="empty-box">
      <div>
        <el-image style="width: 160px; height: 160px" :src="emptyDefault" fit="fill"/>
        <p class="empty-tip">收藏无商品~</p>
      </div>
    </div>

    <im-table-page
        v-show="tableData.length !== 0"
        style="margin-bottom:15px"
        ref="tablePage"
        :data.sync="tableData"
        :isShowTableHeager="false"
        :hideOnSinglePage="false"
        :showCollection='true'
        :isHasBorder="false"
        :addQuantity="true"
        :isShowMainInfo="true"
        :remote-method="queryData"
        :more-check-box="(val)=>{selectItem = val}"
        @updated="updated"
    >
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="商品图片" prop="picUrl" min-width="130px">
        <template slot-scope="scope">
          <div style="width: 100px; height: 100px;poaition:relative">
            <router-link :to="'/detail/' + scope.row.productId">
              <el-image style="width:100px;height:100px" :src="scope.row.productVo.picUrl || productDefault"
                        @error="scope.row.productVo.picUrl = productDefault" fit="fill"/>
            </router-link>
            <router-link :to="'/detail/' + scope.row.productId">
              <el-image v-if="scope.row.productVo.isOnSale === 'N'" style="width:80px;height:80px;" class="collect-fail"
                        :src="collectFail" fit="fill"/>
            </router-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="商品描述" prop="orderFullAmount" width="240px">
        <template slot-scope="scope">
          <div style="height:100%;width:240px">
            <el-col class="detail-title">
              <span v-if="scope.row.productVo.otcType === 'A_OTC'" class="detail-title-tap">甲OTC</span>
              <span v-if="scope.row.productVo.otcType === 'B_OTC'" class="detail-title-tap">乙OTC</span>
              <span v-if="scope.row.productVo.otcType === 'RX'" class="detail-title-tap">RX</span>
              <router-link :to="'/detail/' + scope.row.productId"><span class="text_hidden"
                                                                        style="display:inline-block;width:180px;cursor:pointer">
                {{$util.getGoodsName(scope.row.productVo)}}
              </span>
              </router-link>
            </el-col>
            <el-col class='con-text'><span style="margin-right:16px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" :title="scope.row.productVo.spec">规格：{{scope.row.productVo.spec}}</span> <span>单位：{{scope.row.productVo.unit}}</span>
            </el-col>
            <el-col class='con-text'><span style="margin-right:16px;">中包装：{{scope.row.productVo.midPackTotal}}</span>
              <span>件包装：{{scope.row.productVo.packTotal}}</span></el-col>
            <el-col class='con-text'><p class="text_hidden" :title="scope.row.productVo.manufacturer">厂家(上市许可持有人)/产地：{{scope.row.productVo.manufacturer}}</p></el-col>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="商品价格" min-width="140px" show-overflow-tooltip align="right">
        <template slot-scope="scope">
          <div style="height:100%;padding-top:31px" class='con-text'>
            <!--<el-col><span>零售价：</span><span style="color:#FA6400">{{scope.row.productVo.retailPrice ? scope.row.productVo.retailPrice : '0.00'}}</span>
            </el-col>
            <el-col><span>医保价：</span><span style="color:#FA6400">{{scope.row.productVo.medicarePrice ? scope.row.productVo.medicarePrice : '0.00'}}</span>
            </el-col> -->
            <el-col><span>销售价：</span><span style="color:#FA6400">{{$util.getPriceByZero(scope.row.productVo.price,scope.row.productVo)}}</span>
            </el-col>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="库存" min-width="170px" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <div style="height:100%;padding-top:5px;" class='con-text'>
            <el-col><span>库存：</span><span>{{scope.row.productVo.stockQuantity >= 100 ? '>100' : '<100'}}</span></el-col>
            <el-col><span>最小购买倍数：</span><span>{{ getRealMinBuyNum(scope.row.productVo) }}</span>
            </el-col>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="170px" show-overflow-tooltip align="center">
        <template slot-scope="scope">
           <div class="table-operation" v-if="isLicenseOutOfTime">
            <!-- <p>{{ userType }}</p> -->
             <el-button disabled>证照过期</el-button>
          </div>
          <div style="height:100% ;" v-else>
            <el-col>
              <el-input-number class="change-count"
                               size="mini"
                               v-model="scope.row.count"
                               :precision="getDecimalPointNum(scope.row.productVo)"
                               :step="getRealMinBuyNum(scope.row.productVo)"
                               :min="getRealMinBuyNum(scope.row.productVo)"
                               step-strictly></el-input-number>
            </el-col>
            <el-col style="margin-top:15px;">
<!--              <el-button disabled style="background: #C0C0C0" class="cart-buy" size="small" v-if="scope.row.productVo.stockQuantity === 0">已售馨</el-button>-->
              <el-button size="mini"
                         type="info"
                         style="width: 140px"
                         v-if="scope.row.productVo.stockQuantity === 0"
                         @click="handleRegisterGoods(scope.row,scope.row.count)">缺货登记</el-button>
              <el-button v-else class="cart-buy"
                         @click="beforeAddItem(scope.row.skuId, 'NORMAL', scope.row.count,scope.row)"
                         :disabled="isBanAddToCart"
                         :loading='scope.row.loading'
                         size="small">加入购物车</el-button>
            </el-col>
            <i class="el-icon-delete del-product-btn" @click="handleDelete(scope.row)"></i>
          </div>
        </template>
      </el-table-column>
    </im-table-page>
    <div slot="footer" class="content-footer" v-if="tableData.length !== 0">
      <div class="left-con">
        <el-checkbox v-model="AllChecked" style="margin-right:20px;" @change="handleAllSelect">全选</el-checkbox>
        <span class="text-detail"><span style="color:#0971EB">已选 {{selectItem.length}} 商品</span></span>
      </div>
      <el-button class="btn-buy"
                 type="primary"
                 :loading='loadingCartAll'
                 @click="handleBuyMore"
                 :disabled="isBanAddToCart">{{ isLicenseOutOfTime ? '证照过期' : '加入购物车'}}</el-button>
    </div>
  </im-page-modal>
</template>
<script>
  import productDefault from '@/assets/img/index/product_default.png'
  import emptyDefault from '@/assets/img/member-center/emptycart.png'
  import collectFail from '@/assets/img/member-center/product-collect.png'
  import { getBus } from '@/utils/auth'
  import { pageProductCollect, delProductCollectById } from '@/api/member-center/transactions-using'
  import { addItem, batchAddItem } from '@/api/detail'
  import { mapGetters } from 'vuex'
  import addToCartMixin from '@/common/addToCart'

  export default {
    data () {
      return {
        loadingCartAll:false,
        loadingCart:false,
        loading: false,
        isShowBack: false,
        selectItem: [],
        tableData: [],
        AllChecked: false,
        emptyDefault,
        productDefault,
        collectFail, //失效商品样式
        userId: ''
      }
    },
    mixins:[addToCartMixin],
    created () {
      this.userId = getBus().buyersId
      this.$emit('handle-change-page', {
        text: '商品收藏',
        index: '5-2',
        url: '/member-center/transactions-using/good-collection',
      })
    },
    computed: {
      ...mapGetters(['license','userType'])
    },
    watch: {
      selectItem (newVal) {
        this.AllChecked = newVal.length === this.tableData.length
      }
    },
    methods: {
      handleConfirm () {
        this.$router.push(`/member-center/personal-center/my-aptitudes`)
      },
      doRefresh () {
        this.$refs.tablePage.doRefresh({ currentPage: 0, pageSize: 10 })
      },
      queryData(data) {
        this.tableData = [];
        return new Promise((then, reject) => {
          pageProductCollect({...data, condition: {userId: this.userId}}).then(res => {
            res.data.records = res.data.records.map(item=>{
              return {
                ...item,
                loading: false,
                ...item.productVo,
                count: this.getRealMinBuyNum(item.productVo)
              }
            })
            then(res)
          })
        })

      },
      updated () {
        this.$refs.tablePage.doLayout()
      },
      //批量加入
      handleBuyMore () {
        this.loadingCartAll = true
        if (this.selectItem.length === 0) {
          this.loadingCartAll = false
          this.$message.error('请选择商品')
          return
        }
        let itemList = this.selectItem.map(res => {
          return {
            skuId: res.productVo.skuId,
            quantity: res.count
          }
        })
        batchAddItem({ itemList }).then(res => {
          const { code } = res
          if (code === '0') {
            this.loadingCartAll = false
            this.$message.success('加入成功！')
            this.$store.dispatch('user/setCartNum', res.data.allProductTypeTotalQuantity)
          }
        }).catch(err=>{
          this.loadingCartAll = false
        })
      },
      handleDelete (row) {
        delProductCollectById(row.productCollectId).then(res => {
          this.$message.success(res.msg)
          this.doRefresh()
        })
      },
      handleAllSelect () {
        this.$refs.tablePage.$refs.table.toggleAllSelection()
      }
    }
  }
</script>
<style lang="scss" scope>
.aptitudes{
    line-height: 40px;
    font-size: 14px;
    color: #555;
    background-color: #FFF0EF;
    margin-bottom: 32px;
  }
  .good-collection-page {
    position: relative;
    background-color: white;

    .header {
      padding: 0 28px;
    }

    .content {
      padding: 0 20px 4px;
    }

    .el-table {
      flex: 1;

      .el-table__header-wrapper {
        display: none;
      }

      .el-table__row {
        height: 140px;

        &:last-child td {
           border: 0;
        }
      }

      .el-table .cell {
        padding-left: 0;
        padding-right: 0;
      }

      .detail-title {
        height: 22px;
        font-size: 16px;
        font-weight: 600;
        color: rgba(34, 34, 34, 1);
        line-height: 22px;
        margin-bottom: 6px;

        .detail-title-tap {
          overflow: hidden;
          margin-right: 4px;
          background-color: rgba(255, 102, 0, 1);
          border-radius: 4px;
          text-align: center;
          display: inline-block;
          padding: 2px;
          font-size: 14px;
          font-weight: 500;
          color: rgba(255, 255, 255, 1);
          line-height: 20px;
        }
      }

      //失效商品
      .collect-fail {
        position: absolute;
        z-index: 100;
        transform: translate(-60%, -50%);
        top: 50%;
        left: 50%;
      }

      .text_hidden {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .con-text {
        width: 100%;
        text-align: left;
        height: 21px;
        font-size: 12px;
        font-weight: 500;
        color: rgba(102, 102, 102, 1);
        line-height: 21px;
      }

      .cart-buy {
        width: 140px;
        background-color: rgba(255, 102, 0, 1);
        color: white;
        border: 0;
        outline: 0;
      }

      .change-count {
        width: 140px;
      }

      .del-product-btn {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 100;
        width: 16px;
        height: 16px;
        display: inline-block;
        background-color: #A3A79C;
        color: white;
        line-height: 16px;
        text-align: center;
      }
    }

    .im-pagination {
      bottom: 66px;
      right: 20px;
      border-top: none !important;
      padding-top: 0 !important;
    }

    .left-con {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .text-detail {
        font-size: 12px;
        font-weight: 500;
        color: rgba(102, 102, 102, 1);
        line-height: 17px;
      }
    }

    .content-footer {
      padding: 0 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .right-con {
        display: flex;
        align-items: center;
      }

      .btn-buy {
        margin-left: 20px;
        display: inline-block;
        width: 126px;
        height: 36px;
        line-height: 36px;
        padding: 0 28px;
        border-radius: 2px;
        color: rgba(255, 255, 255, 1);
        border: 1px solid rgba(255, 102, 0, 1);
        background: rgba(255, 102, 0, 1);
      }
    }

    .empty-box {
      flex: 1;
      display: flex;
      justify-content: center;
      padding-top: 252px;

      .empty-tip {
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(113, 113, 113, 1);
        line-height: 20px;
      }
    }

  }
</style>

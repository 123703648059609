import defaultSettings from '@/settings'
import md5 from 'js-md5'

const { salt } = defaultSettings

export function encodeMd5(str) {
  const _encodeStr = md5(str)
  return md5(_encodeStr + salt)
}


<template>
  <div class="returns-refunds">
    <div class="search-header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline" size="small">
        <el-form-item label="订单编号"  prop="orderNum">
          <el-input style="width: 163px;" v-model="searchForm.orderNum" placeholder="输入订单编号"></el-input>
        </el-form-item>
        <el-form-item label="商品名称" prop="productNm">
          <el-input style="width: 163px;" v-model="searchForm.productNm" placeholder="输入商品名称"></el-input>
        </el-form-item>
        <el-form-item label="发货批号" prop="sendBatch">
          <el-input style="width: 163px;" v-model="searchForm.sendBatch" placeholder="输入发货批号"></el-input>
        </el-form-item>
        <el-form-item label="客户编码" prop="buyersNo" v-if="activeName !== 'APPLY_RETURN'">
          <el-input style="width: 163px;" v-model="searchForm.buyersNo" placeholder="输入客户编码"></el-input>
        </el-form-item>
        <el-form-item label="客户名称" prop="buyersNm" v-if="activeName !== 'APPLY_RETURN'">
          <el-input style="width: 163px;" v-model="searchForm.buyersNm" placeholder="输入客户名称"></el-input>
        </el-form-item>
        <el-form-item label="退货编号" prop="returnedOrderNum"  v-if="activeName !== 'APPLY_RETURN'">
          <el-input style="width: 163px;" v-model="searchForm.returnedOrderNum" placeholder="输入退货编号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
          <el-button type="text" size="mini" @click="handleReset"><span style="color: #000000">重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleTab" class="refunt-step-tabs">
      <el-tab-pane name="VERIFY_RETURN">
          <span slot="label"> 审核退货</span>
      </el-tab-pane>
      <el-tab-pane name="PROCESSING_RETURN">
          <span slot="label"> 退货处理中 </span>
      </el-tab-pane>
      <el-tab-pane name="RECORD_RETURN">
          <span slot="label"> 退货记录 </span>
      </el-tab-pane>
      <el-tab-pane name="APPLY_RETURN">
          <span slot="label" > 申请退货</span>
      </el-tab-pane>
    </el-tabs>
    <div class="refunds-main">
      <div class="no-customer" v-if="activeName === 'APPLY_RETURN' && !isCustomer ">
        <el-image
          :src="NoCustomer">
        </el-image>
        <div class="span-info">请先选择客户，再进行退货申请</div>
        <el-button type="primary" class="btn-choose" @click="chooseCustomer">选择客户</el-button>
      </div>
      <div class="userDetail" v-if="isCustomer && activeName === 'APPLY_RETURN'">
        <div style="flex: 7;margin: 11px">
          <span class="userTitle">客户名称：</span><span class="userInfo">{{changeCustomer.buyersNm}}--{{changeCustomer.ettleAccountNm}}</span>
          <a class="changeAddr" @click="tochangeCustomer" >切换客户</a>
          <span class="userTitle">联系人：</span><span class="userInfo">{{changeCustomer.entContactMan}}</span>
          <span class="userTitle">联系电话：</span><span class="userInfo">{{changeCustomer.contactMoblie}}</span>
          <br/><br/>
          <span class="userTitle">收货地址：</span>
          <span class="userInfo" v-show="!isChangeAddr">{{selectAddr.address}}</span>
          <el-select @change="changeAddress" class="changeAddress" v-model="address" placeholder="请选择" v-show="isChangeAddr" style="margin-right: 24px;">
            <el-option
              v-for="item in addrs"
              :key="item.erpCode"
              :label="item.address"
              :value="item.erpCode">
            </el-option>
          </el-select>
          <a class="changeAddr" @click="changeAddr" v-if="addrs && addrs.length>1">切换地址</a>
          <span class="userTitle">可用额度：</span><span class="userInfo"> ￥{{changeCustomer.availableCredit}}</span>
        </div>
      </div>
      <!-- 表格 -->
      <im-table-page
        v-if="isShow"
        ref="tablePage"
        :data.sync="tableData"
        :remote-method="queryData"
        :span-method="spanMethod"
         row-key="id"
        :defaultExpandAll="true"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        :row-class-name="renderRowClass"
        style="color: #717171;"
      >
        <el-table-column :key="Math.random()" v-if="activeName==='APPLY_RETURN'" width="40">
          <template slot-scope="scope">
            <el-checkbox :disabled="scope.row.existReturnedPurchaseOrderList" @change="handleSelectionChange(scope.row)"   class="three-button" v-model="scope.row.checked"  style="float:left"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="商品信息" width="290px" show-overflow-tooltip  class="spanp">
          <template slot-scope="scope">
            <p v-if="scope.row.orderNum && activeName !== 'APPLY_RETURN'"  class="tabletitle">
              退货编号：<span class="order-row-text">{{scope.row.returnedOrderNum}}</span>
              订单编号：<span class="order-row-text">{{scope.row.orderNum}}</span>
              下单时间：<span class="order-row-text">{{scope.row.createDate}}</span>
              <template v-if="scope.row.buyersNm">
              客户名称：<span class="order-row-text">{{scope.row.buyersNm}}</span>
              </template>
              <template v-if="scope.row.ettleAccountNm !== '默认分支机构'">
                分支机构：<span class="order-row-text">{{scope.row.ettleAccountNm}}</span>
                分支机构编码：<span class="order-row-text">{{scope.row.code}}</span>
              </template>
            </p>
            <div class="product-info" v-else>
              <div class="product-image">
                 <el-image
                  :src="scope.row.picture || ProDuct"
                  @error="scope.row.picture = ProDuct"
                  fit="fit">
                </el-image>
              </div>
              <ul class="product-detail">
                <li class="proname">{{$util.getGoodsName(scope.row)}}</li>
                <li class="size-vender">
                  <p>规格：<span>{{ scope.row.spec}}</span></p>
                  <p class="manufacturer">厂家：<span class="manufacturerspan">{{ scope.row.manufacturer}}</span></p>
                </li>
              </ul>
            </div>
            <div></div>
          </template>
        </el-table-column>
        <el-table-column label="订单编号" v-if="activeName==='APPLY_RETURN'" prop="orderNum" width="115px" class="orderNum" show-overflow-tooltip style="padding:0px">
        </el-table-column>
        <el-table-column label="订单时间" v-if="activeName==='APPLY_RETURN'" prop="createDate" width="100px" show-overflow-tooltip  class="orderTime" >
        </el-table-column>
        <el-table-column label="发货批号" prop="sendBatch" width="85px" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="退货价" prop="money" align="right" width="90px" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>¥{{ scope.row.settlePrice }}</span>
          </template>
        </el-table-column>
        <el-table-column label="出库/可退数量" width="120px" prop="number" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.quantity}} / {{ scope.row.returnableQuantity}}</span>
          </template>
        </el-table-column>
        <el-table-column label="退货数量" v-if="activeName !== 'APPLY_RETURN'" :key="Math.random()" prop="returnOrderQuntity"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.returnOrderQuntity }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" :key="Math.random()" v-if="activeName !== 'APPLY_RETURN'" prop="returnedOrderStat"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="color: orange" v-if="scope.row.returnedOrderStat === 'WAIT_APPROVE'">等待审核</span>
            <span style="color: orange" v-if="scope.row.returnedOrderStat === 'WAIT_STOCK_IN'">退货处理中</span>
            <span style="color: orange" v-if="scope.row.returnedOrderStat === 'REFUSE'">退货拒绝</span>
            <span style="color: orange" v-if="scope.row.returnedOrderStat === 'FINSH'">退货成功</span>
            <span style="color: orange" v-if="scope.row.returnedOrderStat === 'CANCEL'">已取消</span>

          </template>
        </el-table-column>
        <el-table-column label="操作" width="110px">
          <template slot-scope="scope">
            <div class="apply" v-if="activeName==='VERIFY_RETURN'" style="width: 61px;margin: 0 auto;">
              <el-button style="padding:4px 8px;width: 61px" size="mini" @click="handleWatch(scope.row)">审核</el-button>
            </div>
            <div class="apply" v-if="activeName==='APPLY_RETURN'">
              <el-button style="padding:4px 8px" :disabled="scope.row.existReturnedPurchaseOrderList"  size="mini" @click="handleRefundOne(scope.row)">
                {{scope.row.existReturnedPurchaseOrderList? '已加退货清单' : '加入退货清单'}}
              </el-button>
            </div>
            <ul class="apply" v-if="activeName==='PROCESSING_RETURN'">
              <li>
                <el-button style="padding:4px 8px" size="mini" @click="handleWatch(scope.row)">退货详情</el-button>
              </li>
              <li v-if="scope.row.returnedOrderStat === 'WAIT_APPROVE'">
                <el-button style="padding:4px 8px;margin-top:4px" size="mini" @click="handleCancel(scope.row)">取消退货申请</el-button>
              </li>
            </ul>
            <ul class="apply" v-if="activeName==='RECORD_RETURN'">
              <li>
                <el-button style="padding:4px 8px" size="mini" @click="handleWatch(scope.row)">查看详情</el-button>
              </li>
              <li v-if="scope.row.returnedOrderStat === 'REFUSE'">
                <el-popover
                        class="refusePopover"
                        placement="bottom"
                        title="拒绝原因"
                        width="275"
                        trigger="click"
                >
                  <div class="popover-content" style="padding: 0px 15px 16px;">{{scope.row.returnReason}}</div>
                  <el-button type="text" size="mini" style="padding: 4px 8px" slot="reference">拒绝原因</el-button>
                </el-popover>
              </li>
              <li v-if="scope.row.returnedOrderStat === 'FINSH'">
                <el-popover
                  class="refusePopover"
                  placement="bottom"
                  title="钱款去向"
                  width="275"
                  trigger="click"
                >
                  <div class="popover-content" style="padding: 0px 15px 16px;">退至可用额度： <span class="returnMon">¥{{scope.row.refundTotalAmount}}</span></div>
<!--                  <el-button type="text" size="mini" style="padding: 4px 8px" slot="reference">钱款去向</el-button>-->
                </el-popover>
              </li>
            </ul>
          </template>
        </el-table-column>
      </im-table-page>
    </div>
    <!--加入退货单-->
    <div class="settlement-container" v-if="activeName === 'APPLY_RETURN'">
        <div class="settlement-row">
          <div class="row-left">
            <el-checkbox v-model="checkedAll" @change="handleCheckAllChange">全选</el-checkbox>
            <!-- <span class="total">共 {{refundsOrderList.length === 0 ? '0': refundsOrderList.length}} 种商品</span>
            <span class="total">总数量 {{refundsOrderList.length === 0 ? '0': refundsOrderList.length}} </span> -->
          </div>
          <div class="row-right">
            <div class="settlement">
            </div>
            <div class="settlement-button-group">
              <!-- <span class="final-info">合计退款金额：</span>
              <span class="total-money">&yen;{{(allMoney/100).toFixed(2)}}</span> -->
              <button class="immediate-settlement" @click="handleRefundOne('all')">加入退货清单</button>
              <button class="immediate-settlement-show" @click="checkRefundList">查看退货清单<span>({{refundsOrderList.length}})</span></button>
              <!-- <button class="immediate-settlement-first" @click="toRefundAll">一键退货</button> -->
            </div>
          </div>
        </div>
    </div>
    <add-customer  ref="addCustomer"  @child-msg="changePro" />
  </div>
</template>

<script>
  import AddCustomer from "./components/add-customer"
  import ProDuct from '@/assets/img/index/product_default.png'
  import NoCustomer from '@/assets/img/member-center/no-customer.png'
  import { pageApplyReturnOrderSalesmanList,
           pageReturnedOrderRecords ,
           cancelReturnedOrder,
           getSalesmanReceiveAddr,
           saveReturnedPurchaseOrderList
  } from '@/api/member-center/refunds'

  export default {
    name: 'ReturnsRefunds',
    components:{
     AddCustomer
    },
    data () {
      return {
        activeName: 'VERIFY_RETURN',
        form: {},
        searchForm:{
          orderNum:'',
          productNm:'',
          sendBatch:'',
          buyersNo:'',
          buyersId: '',
          ettleAccountId: '',
          erpAddrCode: '',
          returnedOrderNum: '',
          buyersNm: ''
        },
        ProDuct: ProDuct,
        tableData: [],
        NoCustomer: NoCustomer,
        checkedAll: false,
        address: '',
        addrs: [],
        isChangeAddr: false,
        // 已选的退货清单
        refundsOrderList:[],
        isCustomer: false,
        isShow: true,
        changeCustomer:{
          buyersNm: '', // 客户名称
          ettleAccountNm: '', // 分支机构
          entContactMan: '', //联系人
          availableCredit: '', // 可用额度
          contactMoblie: '', //联系电话
        },
        selectAddr: {
          address: '',
          erpCode: ''
        },
        allMoney: 0,
        selectAddress: {},
        // 在页面点击选择框 手动选择退货商品
        nowSelectOrder:[]
      }
    },
    created () {
      let {active} = this.$route.query
      this.activeName = active
      if(active){
        this.handleTab({name:active})
      }

      this.$emit('handle-change-page', {
        text: '退货/退款',
        index: '2-3',
        url: '/member-center/order-manage/refunds-yw'
      })
    },
    mounted() {

    },
    methods: {
      // 全选 点击全选之后 再去加入退货清单
      handleCheckAllChange(val) {
        this.nowSelectOrder = []
        if(val) {
          this.tableData.map(item => {
            item.checked = true
            if(!item.existReturnedPurchaseOrderList && item.checked){
              this.nowSelectOrder.push(item)
            }
          })
        }else {
           this.nowSelectOrder = []
        }
      },
      toRefundAll() {
        if(this.refundsOrderList.length === 0){
          this.$alert('您还未选择任何商品',  {
            confirmButtonText: '确定',
          });
        }else {
          this.$router.push({path:'/refunds-replay-list-yw',  query:{refundOrder: this.refundsOrderList, selectAddress: this.selectAddress}})
        }
      },
      // 计算总金额
      countMoney() {
        this.refundsOrderList.map(item => {
          this.allMoney = this.allMoney + item.settlePrice * 100
        })
      },
      changeAddress(value) {
        this.isChangeAddr = false
        this.addrs.map(item => {
          if(item.erpCode === value){
            this.selectAddr.address = item.address
            this.selectAddr.erpCode = item.erpCode
            this.selectAddress = {}
            this.selectAddress = item
          }
        })
        this.$refs.tablePage.doRefresh()
      },
      checkRefundList() {
        if(this.refundsOrderList.length !== 0){
          this.$router.push({path:'/refunds-replay-list-show', query: {ettleAccountId: this.searchForm.ettleAccountId, buyersId: this.searchForm.buyersId, erpAddrCode: this.searchForm.erpAddrCode, changeCustomer: this.changeCustomer, selectAddr: this.selectAddr, selectAddress:this.selectAddress}})
        }else {
          this.$alert('您还未选择任何商品',  {
            confirmButtonText: '确定',
          });
        }
      },
      // 修改商品选中状态
      handleSelectionChange (data) {
        this.nowSelectOrder = []
        this.tableData.map(item => {
          if(item.checked && !item.existReturnedPurchaseOrderList){
            this.nowSelectOrder.push(item)
          }
        })
      },
      changePro(data) {
        this.addrs = []
        this.searchForm.buyersId = data.buyersId
        this.searchForm.ettleAccountId = data.ettleAccountId
        this.changeCustomer.buyersNm = data.buyersNm
        this.changeCustomer.ettleAccountNm = data.ettleAccountNm
        this.changeCustomer.entContactMan = data.entContactMan
        this.changeCustomer.contactMoblie = data.contactMoblie
        this.changeCustomer.availableCredit = data.availableCredit
        this.isShow = true
        this.isCustomer = true
        this.selectAddr = {}

        this.$nextTick(()=>{
          this.$refs.tablePage.doRefresh()
        })
      },

      // 切换地址
      changeAddr (e) {
        if (!this.isChangeAddr) {
          this.isChangeAddr = true
        }
      },

      // 切换客户
      tochangeCustomer(){
        this.$refs.addCustomer.init()
        this.isShow = true
      },

      chooseCustomer() {
        this.$refs.addCustomer.init()
      },

      // 切换
      handleTab(tabs) {
        this.searchForm = {
            orderNum:'',
            productNm:'',
            sendBatch:'',
            returnedOrderNum: '',
            buyersNm: ''
        }
        this.activeName = tabs.name;
        if(tabs.name === 'APPLY_RETURN' && !this.isCustomer){
          this.isShow = false
        }
        if(tabs.name !== 'APPLY_RETURN' ){
          this.isShow = true
        }
        if(this.isShow){
          this.$nextTick(()=>{
            this.$refs.tablePage.doRefresh()
          })
        }
      },
      renderRowClass(row) {
        if (row.row.orderNum && this.activeName !== 'APPLY_RETURN') {
          return 'order-row'
        } else {
          return ''
        }
      },
     queryData (data) {
        // if(this.$route.query.activeName === 'PROCESSING_RETURN1'){
        //   this.activeName = 'PROCESSING_RETURN'
        // }
        return new Promise(then => {
          if(this.activeName === 'APPLY_RETURN'){
           this.refundsOrderList = []
           this.allMoney = 0
           getSalesmanReceiveAddr({
               buyersIdSearch: this.searchForm.buyersId,
               ettleAccountIdSearch: this.searchForm.ettleAccountId
           }).then(res => {
              if(!!res.data.length) {
                this.addrs = res.data
                this.addrs.map(item => {
                  item.address = item.province + '-' + item.city + '-' + item.district + '-' + item.receiverAddr
                })


                if(!this.selectAddr.erpCode){
                  // 默认展示的申请退货的商品 收货地址取地址list的第一条
                  this.selectAddr.erpCode = this.addrs[0].erpCode
                  this.selectAddress = this.addrs[0]
                }
                this.selectAddr.address = this.addrs[0].address
                this.searchForm.erpAddrCode = this.selectAddr.erpCode
              }else {
                this.searchForm.erpAddrCode = ''
                this.selectAddr = {
                  address: '',
                  erpCode: ''
                }
              }


              let params = {
                buyersIdSearch: this.searchForm.buyersId,
                ettleAccountIdSearch: this.searchForm.ettleAccountId,
                ...this.searchForm
              };

              delete params['buyersId']
              delete params['ettleAccountId']

              pageApplyReturnOrderSalesmanList({
                ...data,
                condition: params
              }).then(res => {
                let prodata = res.data.records
                prodata.map(item => {
                  if(item.existReturnedPurchaseOrderList){
                    this.refundsOrderList.push(item)
                    item.checked = true
                  }
                })
                 this.countMoney()
                 then(res)
              })
           })
          }else {
            if(this.activeName === 'PROCESSING_RETURN'){
              this.searchForm.returnedOrderStat = 'waitStockIn'
            }else if(this.activeName === 'RECORD_RETURN'){
              this.searchForm.returnedOrderStat = 'record'
            }else if(this.activeName === 'VERIFY_RETURN'){
              this.searchForm.returnedOrderStat = 'waitApprove'
            }
            this.searchForm.buyersId = ''
            this.searchForm.ettleAccountId = ''
            this.searchForm.erpAddrCode = ''

            pageReturnedOrderRecords({
              ...data,
              condition: this.searchForm
            }).then(res => {
              res.data.records.map((item, index) => {
                item.children = item.returnOrderRecordsItemList
                item.id = (index + 1) + ''
                item.children.map((itemone, indexone) => {
                  let indexchild = indexone + 1
                  itemone.id = item.id + '-' + indexchild
                  itemone.returnedOrderStat = item.returnedOrderStat
                  itemone.returnedOrderId = item.returnedOrderId
                  itemone.returnReason = item.returnReason
                  itemone.refundTotalAmount = item.refundTotalAmount
                   if(indexchild === 1) {
                    itemone.first = 'first'
                    itemone.totalLen = item.returnOrderRecordsItemList.length
                  }
                })
              })
              this.tableData = res.data.records
              then(res)
            })
          }
        })
      },

      // 退货单
      handleRefundOne(data){
        // 最后的按钮 --- 加入退货清单
        if(data === 'all'){
          saveReturnedPurchaseOrderList({returnedPurchaseOrderListSaveVo: this.nowSelectOrder}).then(res => {
          if(res.code === '0'){
            this.$message.success('加入成功')
            this.$refs.tablePage.doRefresh()
          }else {
            this.$message.success('加入失败')
          }
        })
        }else {
          // 每一条商品后面的 加入退货清单
          let addRefund = []
          addRefund.push(data)
          saveReturnedPurchaseOrderList({returnedPurchaseOrderListSaveVo: addRefund}).then(res => {
            if(res.code === '0'){
              this.$message.success('加入成功')
              this.$refs.tablePage.doRefresh()
            }else {
              this.$message.success('加入失败')
            }
          })
        }

        this.countMoney()
      },
      handleWatch(data){
        this.$router.push({path:'/refunds-detail-yw', query:{returnedOrderId: data.returnedOrderId}})
      },
      handleSearch(){
        this.$refs.tablePage.doRefresh()
      },
      handleReset(){
        this.searchForm.orderNum = ''
        this.searchForm.productNm = ''
        this.searchForm.sendBatch = ''
        this.searchForm.returnedOrderNum = ''
        this.searchForm.buyersNm = ''
        console.log(this.isShow)
        if(this.isShow){
          this.$refs.tablePage.doRefresh()
        }
      },
      spanMethod(rowObj) {
        const {row, column, rowIndex, columnIndex} = rowObj;
        if (row.orderNum) {
          if (this.activeName === 'APPLY_RETURN') {
            if (columnIndex) {
              return {
                rowspan: 1,
                colspan: 1
              }
            }
          } else {
            return {
              rowspan: 1,
              colspan: 7
            }
          }
        }

        if(this.activeName !== 'APPLY_RETURN'){
          if (columnIndex === 5 || columnIndex === 6 ) {
            if(row.first === 'first')
            {
              return {
                rowspan: row.totalLen,
                colspan: 1
              }
            }
          }
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .tabletitle {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .changeAddress {
    /deep/ {
      .el-input--suffix .el-input__inner {
        height: 28px;
      }

      .el-input__icon {
        line-height: 24px;
      }
    }
  }

  .returns-refunds {
    position: relative;
    width: 100%;
    min-height: 100%;
    background: #ffffff;
    padding: 0 20px;
    text-align: left;

    .search-header {
      width: 100%;
      height: 110px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #E5E5E5;

      /deep/ .el-form-item {
        margin-bottom: 14px;
        margin-right: 20px;

        .el-form-item__label {
          padding-right: 4px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(113, 113, 113, 1);
        }

        .el-input__inner {
          padding: 0 12px;
          background: rgba(255, 255, 255, 1);
          border-radius: 4px;
          border: 1px solid rgba(204, 204, 204, 1);
        }
      }
    }

    .popover-content {
      padding: 24px 16px;
    }

    .refunds-main {
      padding-bottom: 85px;
      padding: 1px;
      height: auto;
      background: #ffffff;

      .product-info {
        display: flex;
        flex-direction: row;

        .product-image {
          width: 70px;
          height: 70px;

          .el-image {
            width: 100%;
            height: 100%;
          }
        }

        .product-detail {
          padding-left: 16px;
          flex: 1;
          overflow: hidden;

          .proname {
            white-space: nowrap;
            height: 20px;
            font-size: 14px;
            font-weight: 500;
            color: rgba(34, 34, 34, 1);
            line-height: 20px;
            margin-bottom: 4px;
            font-weight: bold;
          }

          .size-vender {
            font-size: 14px;
            font-weight: 400;
            color: rgba(113, 113, 113, 1);

            p {
              line-height: 20px;
              height: 20px;
            }
          }
        }

      }

      /deep/ .el-tabs__header {
        margin: 0 !important;
      }

      /deep/ .el-table {
        .order-row {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);

          td {
            background: #F1F7FE;
          }

          .order-row-text {
            margin-right: 10px;
          }

          .el-button {
            padding: 5px 8px;

            span {
              margin: 0;
            }
          }
        }

        .el-table-column--selection {

        }

        .el-table__header-wrapper .el-checkbox { //找到表头那一行，然后把里面的复选框隐藏掉
          display: none
        }

        .cell {
          width: 100% !important;

          .el-table__expand-icon {
            display: none;
          }

          .el-table__indent, .el-table__placeholder {
            display: none;
          }
        }
      }
    }
  }

  .no-customer {
    margin: 200px auto;
    /* position: relative; */
    width: 160px;
    /* width: 50%; */
    /* margin-left: 25%; */
  }

  .demo-form-inline {
    margin-top: 15px;
  }

  .btn-choose {
    margin: 20px auto;
    display: block;
    width: 80px;
    height: 28px;
    background: $ThemeColor;
    line-height: 28px;
    padding: 0px;
  }

  .span-info {
    margin: 10px -10px;
    width: 196px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #717171;
    line-height: 20px;
  }

  /*结算*/
  .settlement-container {
    margin-top: 18px;
    background: white;
    border-top: 1px solid #e5e5e5;
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0px;

    .settlement-row {
      margin: 0 auto;
      width: 100%;
      height: 60px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .row-left {
        font-size: 12px;
        font-weight: 500;
        color: rgba(102, 102, 102, 1);

        /deep/ .el-checkbox {
          margin-right: 23px;

          .el-checkbox__label {
            font-size: 12px;
            font-weight: 500;
            color: rgba(102, 102, 102, 1);
          }
        }

        span {
          cursor: pointer;
          margin-right: 20px;
        }
      }

      .row-right {
        display: flex;
        flex-direction: row;
        align-items: center;

        .settlement {
          margin-right: 12px;
          text-align: right;

          .total-money {
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            font-weight: 500;
            color: rgba(34, 34, 34, 1);

            span {
              font-size: 20px;
              color: rgba(224, 32, 52, 1);
            }
          }
        }

        .product-detail {
          height: 14px;
          font-size: 10px;
          font-weight: 500;
          color: rgba(102, 102, 102, 1);
          line-height: 14px;

          span {
            margin-left: 12px;
          }
        }

        .settlement-button-group {
          height: 100%;

          button {
            border: none;
            height: 36px;
            padding: 0 28px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
          }

          .total-money {
            //  padding: 0px;
            margin-right: 8px;
            width: 74px;
            height: 28px;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(224, 32, 52, 1);
            line-height: 28px;
          }

          .final-info {
            //  padding: 0px;
            margin-right: 8px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(34, 34, 34, 1);
            line-height: 20px;
          }

          .immediate-settlement-show {
            padding: 0px;
            margin-right: 8px;
            width: 140px;
            height: 36px;
            background: rgba(255, 255, 255, 1);
            border-radius: 2px;
            border: 1px solid rgba(204, 204, 204, 1);
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(102, 102, 102, 1);
            line-height: 20px;
          }

          .immediate-settlement-first {
            padding: 0px;
            margin-right: 8px;
            width: 88px;
            height: 36px;
            background: rgba(255, 255, 255, 1);
            border-radius: 2px;
            border: 1px solid $ThemeColor;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: $ThemeColor;
            line-height: 20px;
          }

          .immediate-settlement {
            padding: 0px;
            margin-right: 8px;
            width: 116px;
            height: 36px;
            background: $ThemeColor;
            border-radius: 2px;
            color: #ffffff;
            // background: #FF6600;
            cursor: pointer;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
            line-height: 20px;

            &:hover {
              opacity: .8;
            }
          }
        }
      }
    }
  }


  .settlement-container .settlement-row .row-left .total {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(34, 34, 34, 1);
    line-height: 17px;
  }


  .userDetail {
    margin-bottom: 10px;
    display: flex;
    text-align: left;
    width: 952px;
    height: 82px;
    background: #f1f7fe;
    border-radius: 4px;
  }

  .userTitle {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 20px;
  }

  .userInfo {
    margin-right: 24px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
  }

  .last {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(153, 153, 153, 1);
    line-height: 20px;
  }

  .changeAddr {
    position: relative;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: $ThemeColor;
    line-height: 22px;
    left: -13px;
  }

  .orderTime {
    word-break: break-all;
    word-wrap: break-word;
  }

  .orderNum {
    word-break: break-all;
    word-wrap: break-word;
  }

  .manufacturer {
    word-break: break-all;
    word-wrap: break-word;
    height: 20px;
    width: 202px;
  }

  .manufacturerspan {
    width: 160px;
    height: 100%;
    word-break: break-all;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    white-space: nowrap;
    /* width: 240px; */
    /* height: 24px; */
    display: inline-block;
    position: relative;
    top: 5px;
  }


</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "gifts-dialog",
      attrs: { title: _vm.title, visible: _vm.giftsDialog, width: "1200px" },
      on: {
        "update:visible": function ($event) {
          _vm.giftsDialog = $event
        },
        close: _vm.handleHide,
      },
    },
    [
      _c(
        "el-table",
        {
          ref: "giftsTable",
          class: { "hide-all-checked": _vm.singleSelect },
          attrs: { data: _vm.gridData, border: "", height: "480px" },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "selection",
              width: "45",
              align: "center",
              selectable: _vm.selectEnable,
            },
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "padding",
              label: "商品图",
              width: "80px",
              align: "center",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "commodity-image" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to:
                                "/detail/" + scope.row.presentDetail.productId,
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "product-img",
                              attrs: {
                                src:
                                  scope.row.presentDetail.imgUrl ||
                                  _vm.productDefaultImg,
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "presentDetail.presentNm",
              "class-name": "visibility",
              label: "商品名称",
              width: "160px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "presentDetail.spec",
              align: "center",
              label: "规格",
              width: "120px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "presentDetail.manufacturer",
              align: "left",
              label: "厂家(上市许可持有人)/产地",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "presentDetail.unit",
              align: "center",
              label: "单位",
              width: "56px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "采购数量", width: "160px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input-number", {
                      attrs: {
                        size: "mini",
                        disabled: _vm.isInput,
                        precision: 0,
                        min: 0,
                        step: 1,
                        max: scope.row.presentDetail.stock,
                        "step-strictly": "",
                      },
                      on: { change: _vm.handleChangeQuantity },
                      model: {
                        value: scope.row.presentQuantity,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "presentQuantity", $$v)
                        },
                        expression: "scope.row.presentQuantity",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "presentDetail.stock",
              align: "center",
              label: "剩余数量",
              width: "86px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "giftPrice",
              align: "center",
              label: "单价",
              width: "86px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticStyle: { color: "#FF3B30" } }, [
                      _vm._v("￥" + _vm._s(scope.row.giftPrice)),
                    ]),
                    _c("br"),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "text-decoration": "line-through",
                          color: "#999",
                        },
                      },
                      [_vm._v("¥" + _vm._s(scope.row.salePrice))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "总价", width: "86px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticStyle: { color: "#FF3B30" } }, [
                      _vm._v(
                        "￥" +
                          _vm._s(
                            scope.row.giftPrice * scope.row.presentQuantity
                          )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.handleHide } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.addItem } },
            [_vm._v("立即购买")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
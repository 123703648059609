var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("page-modal", { attrs: { title: "收银台", active: 2 } }, [
    _c("div", { staticClass: "cashier-container" }, [
      _c("div", { staticClass: "order-prompt" }, [
        _c("div", { staticClass: "order-state" }, [
          _c("i", { staticClass: "el-icon-success", attrs: { size: "32" } }),
          _c("span", [_vm._v("您的订单支付成功，请耐心等待！")]),
        ]),
        _c("div", { staticClass: "oder-sum-container" }, [
          _c("p", [
            _vm._v(" 订单总金额："),
            _c("span", [
              _vm._v(
                "¥" + _vm._s(_vm.$util.toFixed(_vm.receiver.orderTotalAmount))
              ),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "cashier-info" }, [
        _c("div", { staticClass: "cashier-title" }, [_vm._v("订单信息")]),
        _c("div", { staticClass: "cashier-content" }, [
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("订单编号")]),
            _c("div", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.receiver.orderNum) + " "),
              _c(
                "span",
                {
                  staticStyle: {
                    "margin-left": "10px",
                    color: "#0971EB",
                    cursor: "pointer",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.goDetails(_vm.orderId)
                    },
                  },
                },
                [_vm._v("查看订单详情")]
              ),
            ]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("交易金额")]),
            _c("div", { staticClass: "text" }, [
              _vm._v(
                _vm._s(_vm.$util.toFixed(_vm.receiver.orderTotalAmount)) + "元"
              ),
            ]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("购买时间")]),
            _c("div", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.receiver.createDate)),
            ]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("收货人")]),
            _c("div", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.receiver.receiverName)),
            ]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("联系电话")]),
            _c("div", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.receiver.receiverMobile)),
            ]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("收货地址")]),
            _c("div", { staticClass: "text" }, [
              _c("span", [_vm._v(_vm._s(_vm.receiver.receiverAddr))]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
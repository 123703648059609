import {ApiGet, ApiPost} from "@/api";

/**
 * 根据商品名称 查询商品
 */
export const getMedicineList = data => ApiPost('/pc/front/product/getIndexProductByParam',data)

/**
 * 获取积分规则
 */
export const getIntegralRuleList = data => ApiGet('/integralRule/getIntegralRuleList',data)

/**
 * 客户地址查询
 */
export const getUser = data => ApiGet('/pc/front/buyers/getAccountAndAddr',data)

/**
 * 文件上传
 */
export const uploadFiles = (data,onUploadProgress = () =>{}) => ApiPost('/pc/front/product/uploadFiles',data,{
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  onUploadProgress,// `onUploadProgress` 允许为上传处理进度事件
})

/**
 * 文件下载
 */
export const downLoad = reportType => ApiGet('reportServiceYtyy/importQueue/downloadImportTemplate', {
  reportType
},{
  responseType: 'arraybuffer'
},false)

/**
 * 下载草稿箱模板
 */
export const exportTemplate = type => ApiGet('/pc/front/product/exportTemplate', {
  type
},{
  responseType: 'blob',
})

/**
 * 飞速下单获取缓存列表
 * @param data
 * @returns {*}
 */
export const getFastOrderCache = data => ApiGet('/pc/front/product/getOrderQuicklyRedis', data)

/**
 * 保存缓存列表
 * @param data
 * @returns {*}
 */
export const saveFastOrderCache = data => ApiPost('/pc/front/product/saveOrderQuicklyRedis', data)

/**
 * 飞速下单清空缓存
 * @param data
 * @returns {*}
 */
export const delFastOrderCache = data => ApiPost('/pc/front/product/delOrderQuicklyRedis', data)

import {ApiPost} from "@/api";

/**
 * 从索引分页查询商品
 */
export const pageIndexProduct = data => ApiPost('/pc/front/product/pageIndexProduct',data)

/**
 * 获取企业基本信息
 */
export const saveFrontVerification = data => ApiPost('/pc/front/user/pcFrontVerification',data)

/**
 * 修改密码
 */
export const updatePcFrontPassword = data => ApiPost('/pc/front/user/updatePcFrontPassword',data)

/**
 * 获取生产厂家目录
 */
export const getManufacture = data => ApiPost('/pc/front/product/getManufacturer',data)

/**
* 获取批号效期
* */
export const getPHXQ = data => ApiPost('/skuInfo/front/getSkuInfo',data)

/**
* 获取最近远效期
* */
//export const getjyPHXQ = data => ApiPost('/skuInfo/front/getSkuInfoTwoData',data)

/**
* 下游客户获取最近远效期
* */
export const getBuyersjyPHXQ = data => ApiPost('/skuInfo/front/getSkuInfoTwoDataBuyers',data)
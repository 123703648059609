var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "history-purchase" }, [
    _c(
      "div",
      { staticClass: "search-header" },
      [
        _c(
          "el-form",
          {
            staticClass: "demo-form-inline",
            attrs: { inline: true, model: _vm.searchForm, size: "small" },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "商品名称" } },
              [
                _c("el-input", {
                  staticStyle: { width: "163px" },
                  attrs: { placeholder: "输入商品名称" },
                  model: {
                    value: _vm.searchForm.productNm,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchForm, "productNm", $$v)
                    },
                    expression: "searchForm.productNm",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "商品编码" } },
              [
                _c("el-input", {
                  staticStyle: { width: "163px" },
                  attrs: { placeholder: "输入商品编码" },
                  model: {
                    value: _vm.searchForm.productCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchForm, "productCode", $$v)
                    },
                    expression: "searchForm.productCode",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "下单时间" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择下单时间段" },
                    model: {
                      value: _vm.searchForm.orderTimeType,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "orderTimeType", $$v)
                      },
                      expression: "searchForm.orderTimeType",
                    },
                  },
                  _vm._l(_vm.orderTimeOptions, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: { click: _vm.onSubmit },
                  },
                  [_vm._v("搜索")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", size: "mini" },
                    on: { click: _vm.onReset },
                  },
                  [
                    _c("span", { staticStyle: { color: "#000000" } }, [
                      _vm._v("重置"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c(
          "im-table-page",
          {
            ref: "tablePage",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              "remote-method": _vm.queryData,
              addQuantity: true,
              "more-check-box": (val) => {
                _vm.selectItem = val
              },
              "header-row-class-name": "header-row",
            },
            on: {
              "update:data": function ($event) {
                _vm.tableData = $event
              },
              updated: _vm.updated,
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "selection",
                align: "center",
                fixed: "left",
                width: "45",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "name",
                fixed: "left",
                label: "商品名称",
                width: "252px",
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        { staticClass: "table-product-name" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/detail/" + scope.row.productId } },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.$util.getGoodsName(scope.row))
                                ),
                              ]),
                            ]
                          ),
                          _c("tagList", {
                            attrs: { taglist: scope.row.tagList },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "productCode",
                align: "left",
                label: "商品编码",
                "show-overflow-tooltip": "",
                width: "100px",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "manufacturer",
                align: "left",
                label: "厂家(上市许可持有人)/产地",
                width: "200px",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "spec",
                align: "left",
                label: "规格",
                "min-width": "100px",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "unit",
                align: "left",
                label: "单位",
                "show-overflow-tooltip": "",
                width: "56px",
              },
            }),
            _c("el-table-column", {
              attrs: { align: "left", label: "中/件包装", width: "100px" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(scope.row.midPackTotal) +
                            "/" +
                            _vm._s(scope.row.packTotal)
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "stockQuantity",
                align: "left",
                label: "库存",
                width: "60px",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          class:
                            scope.row.stockQuantity < 100
                              ? "inventory-warning"
                              : "",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              scope.row.stockQuantity < 100 ? "<100" : ">100"
                            )
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "price",
                align: "right",
                label: "单价",
                width: "70px",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "minBuyMultiple",
                align: "left",
                label: "最小购买倍数",
                width: "100px",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.getRealMinBuyNum(scope.row)) + " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "下单数量", width: "120px" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-input-number", {
                        attrs: {
                          size: "mini",
                          "step-strictly": "",
                          precision: _vm.getDecimalPointNum(scope.row),
                          step: _vm.getRealMinBuyNum(scope.row),
                          min: _vm.getRealMinBuyNum(scope.row),
                        },
                        model: {
                          value: scope.row.quantity,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "quantity", $$v)
                          },
                          expression: "scope.row.quantity",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                align: "right",
                fixed: "right",
                label: "小计",
                width: "100",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", { staticStyle: { color: "#FF3B30" } }, [
                        _vm._v(
                          " ¥" +
                            _vm._s(
                              (scope.row.price * scope.row.quantity).toFixed(2)
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                fixed: "right",
                align: "center",
                width: "105",
                label: "操作",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        { staticClass: "table-operation" },
                        [
                          scope.row.stockQuantity === 0
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { size: "mini", type: "info" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRegisterGoods(
                                        scope.row,
                                        scope.row.quantity
                                      )
                                    },
                                  },
                                },
                                [_vm._v("缺货登记")]
                              )
                            : _c(
                                "el-button",
                                {
                                  staticStyle: { padding: "4px 8px" },
                                  attrs: {
                                    size: "mini",
                                    disabled: _vm.isBanAddToCart,
                                    loading: scope.row.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.beforeAddItem(
                                        scope.row.skuId,
                                        "NORMAL",
                                        scope.row.quantity,
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("加入购物车")]
                              ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "settlement-container" }, [
      _c("div", { staticClass: "settlement-row" }, [
        _c(
          "div",
          { staticClass: "row-left" },
          [
            _c(
              "el-checkbox",
              {
                on: { change: _vm.handleAllSelect },
                model: {
                  value: _vm.AllChecked,
                  callback: function ($$v) {
                    _vm.AllChecked = $$v
                  },
                  expression: "AllChecked",
                },
              },
              [_vm._v("全选")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "row-right" }, [
          _c("div", { staticClass: "settlement" }),
          _c(
            "div",
            { staticClass: "settlement-button-group" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isBanAddToCart },
                  on: { click: _vm.handleBuyMore },
                },
                [_vm._v("加入购物车")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import {ApiGet, ApiPost} from "@/api";
/**
 * @description 申请退货列表---下游客户
 */
export const getRefundsOrders = data => ApiPost('/returnedOrder/pageApplyReturnOrderList',data)

/**
 * @description 文件上传
 */
export const batchUploadFile = data => ApiPost('/pc/front/buyers/batchRegisterUploadFile',data,{
  headers: {
    'Content-Type': 'multipart/form-data'
  },
})

/**
 * @description 提交退货单
 */
export const saveReturnedOrder = data => ApiPost('/returnedOrder/saveReturnedOrder',data)

/**
 * @description 提交退货单
 */
export const getReturnedOrderReason = data => ApiGet('/returnedOrder/getReturnedOrderReasonEnum',data)

/**
 * @description 分页查询退货记录  退货处理中
 */
export const pageReturnedOrderRecords = data => ApiPost('/returnedOrder/pageReturnedOrderRecordsList',data)

/**
 * @description 查询退货细单
 */
export const findRefundsOrderDetail = data => ApiPost('/returnedOrder/getByReturnedOrderId',data)

/**
 * @description 取消退货申请
 */
export const cancelReturnedOrder = data => ApiGet('/returnedOrder/cancelReturnedOrder',data)

/**
 * @description 申请退货列表--- 业务员
 */
export const pageApplyReturnOrderSalesmanList = data => ApiPost('/returnedOrder/pageApplyReturnOrderSalesmanList',data)

/**
 * @description 客户地址列表--- 业务员
 */
export const getSalesmanReceiveAddr = data => ApiGet('/returnedOrder/getOrderApplyReturnSalesmanReceiveAddr',data)

/**
 * @description 加入退货清单--- 业务员
 */
export const saveReturnedPurchaseOrderList = data => ApiPost('/returnedPurchaseOrderList/saveReturnedPurchaseOrderList',data)

/**
 * @description 分页查询退货清单--- 业务员
 */
export const pageReturnedPurchaseOrderList = data => ApiPost('/returnedPurchaseOrderList/pageReturnedPurchaseOrderList',data)

/**
 * @description 删除退货清单--- 业务员
 */
export const removeReturnedPurchaseOrderList = data => ApiGet('/returnedPurchaseOrderList/removeReturnedPurchaseOrderList',data)

/**
 * @description 申请退货--- 业务员
 */
export const saveSalesmanReturnedOrder = data => ApiPost('/returnedOrder/saveSalesmanReturnedOrder',data)

/**
 * @description 审核退货 --- 业务员
 */
export const returnedCheck = data => ApiPost('/returnedOrder/returnedCheck',data)
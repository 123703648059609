<template>
  <div class="member-center-home">
    <div class="member">
      <div class="member-info">
        <div>
          <div class="portrait">
            <img src="../../../assets/img/member-center/portrait.png" alt>
          </div>
          <div class="member-content">
            <div class="type">
              <div class="name">{{ buyersVo.salesmanName }}</div>
              <div class="phone">{{ buyersVo.salesmanContactTel }}</div>
            </div>
          </div>
        </div>
        <div class="member-item">
          <span @click="jumpCoupon">优惠券 <b>{{ userDetail.memberDetailsVo.couponsNum || 0 }}</b> 张</span>
        </div>
      </div>
      <div class="member-info-right">
        <div class="header">
          <template v-if="!editSign">
            <p class="sign" @click="handleEditSignBol">
              <i class="el-icon-edit"></i>
              <span class="sign-msg">{{ userSignName.signature ? userSignName.signature : '点击编辑文字设置自己的签名' }}</span>
            </p>
            <p class="login-msg">您是第{{ loginCount }}次登录，您上一次登录：{{ loginLastTime }}</p>
          </template>
          <p v-else class="sign">
            <el-input
              class="sign-input"
              placeholder="请输入签名"
              v-model="userSignName.signature"
              clearable
              :autofocus="autofocus"
              @blur="handleEditSign"
            >
              <i slot="prefix" class="el-icon-edit"></i>
            </el-input>
          </p>
        </div>
        <ul class="member-boxs">
          <li class="box">
            <div class="order-box" @click="jumpOrder('WAIT_PAY')">
              <div class="order-icon unpaid">
                <span class="badge">{{ memberDetailNum.toBePaid || 0 }}</span>
              </div>
              <p>待支付</p>
            </div>
          </li>
          <li class="box">
            <div class="order-box" @click="jumpOrder('WAIT_SEND')">
              <div class="order-icon library">
                <span class="badge">{{ memberDetailNum.toBeDelivered || 0 }}</span>
              </div>
              <p>待发货</p>
            </div>
          </li>
          <li class="box">
            <div class="order-box" @click="jumpOrder('SEND')">
              <div class="order-icon receive">
                <span class="badge">{{ memberDetailNum.toBeReceived || 0 }}</span>
              </div>
              <p>待收货</p>
            </div>
          </li>
          <li class="box">
            <div class="order-box" @click="jumpOrder('退货/退款')">
              <div class="order-icon return">
                <span class="badge">{{ memberDetailNum.returnGoodes || 0 }}</span>
              </div>
              <p>退货/退款</p>
            </div>
          </li>
          <li class="box">
            <div class="order-box" @click="jumpOrder('')">
              <div class="order-icon all-order"></div>
              <p>全部订单</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="member-main">
      <div class="about-order">
        <order-list ref="orderList" :remote-method="queryData" :time-value.sync="timeValue" :page-number="5"
                    :pager-count="5" :data.sync="tableData"/>
      </div>
    </div>
  </div>
</template>

<script>
  import ProDuct from '@/assets/img/member-center/product.png'
  import OrderList from '../order-manage/store-order/components/order-list'
  import {
    memberDetails,
    getOrderNum,
    getByUserIdLast,
    getUserSignature,
    saveUserSignature
  } from '@/api/member-center'
  import Swiper from '@/components/ForRcommend'
  import { pageOrder } from '@/api/member-center/order-manage/order-list'
  import { parseTime } from '@/utils'
  import { getBus } from '@/utils/auth'
  import productDefaultImg from '@/assets/img/index/product_default.png'
  import { getBrowsingHistory } from '@/api/detail'
  import { mapGetters } from 'vuex'
  import {getOrderNumDetail} from "@/api/user";

  export default {
    name: 'member-center-home',
    components: { OrderList, Swiper },
    data () {
      return {
        ProDuct,
        searchForm: {
          orderStatus: '',
          startDate: '',
          endDate: '',
          productNm: '',
          productCode: '',
          orderNum: '',
          paymentWayId: '',
          orderSource: ''
        },
        tableData: [],
        timeValue: '近7天',
        loginLastTime: '',
        loginCount: '',
        userSignName: {
          userId: '',
          signature: '',
          userSignatureId: ''//用户签名id
        },
        memberDetailNum:{
          returnGoodes: 0,
          toBConfirmed: 0,
          toBeDelivered: 0,
          toBePaid: 0,
          toBeReceived: 0
        },
        editSign: false,
        historyList: [],
        autofocus: false,
        productDefaultImg
      }
    },
    created () {
      this.$emit('handle-change-page', {
        text: '会员中心首页',
        index: '1-1',
        url: '/member-center',
        meta: { roles: ['BUYERS', 'SALESMAN', 'BUYERS_TEAM'] }
      })
      this.userSignName.userId = getBus().buyersId
      this.getDetail()

      this.getOrderNumDetail()
    },
    computed: {
      ...mapGetters(['userDetail', 'org', 'buyersVo'])
    },
    methods: {
      getOrderNumDetail(){
        getOrderNumDetail().then(res=>{
          if(res.code === '0'){
            this.memberDetailNum = res.data
          }
        })
      },
      jumpCoupon () {
        this.$router.push({ path: '/member-center/property-manage/my-coupon' })
      },
      jumpOrder (orderStatus) {
        let obj = {}
        if (orderStatus === '退货/退款') {
          obj = {
            text: '退货/退款',
            index: '2-2',
            url: '/member-center/order-manage/refunds-yw'
          }
        } else {
          obj = {
            text: '商城订单',
            index: '2-1',
            url: '/member-center/order-manage/store-order'
          }
        }
        this.$emit('handle-change-page', obj)
        this.$router.push({ path: obj.url, query: { activeName: orderStatus } })
      },
      queryData (data) {
        let nowDate = parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}')
        let halfYearDate = parseTime(new Date(Date.now() - (365 / 6 * 24 * 3600 * 1000)), '{y}-{m}-{d} {h}:{i}:{s}')
        switch (this.timeValue) {
          case '近7天' :
            halfYearDate = parseTime(new Date(Date.now() - (7 * 24 * 3600 * 1000)), '{y}-{m}-{d} {h}:{i}:{s}')
            break
          case '近30天' :
            halfYearDate = parseTime(new Date(Date.now() - (365 / 12 * 24 * 3600 * 1000)), '{y}-{m}-{d} {h}:{i}:{s}')
            break
          case '近三个月' :
            halfYearDate = parseTime(new Date(Date.now() - (365 / 4 * 24 * 3600 * 1000)), '{y}-{m}-{d} {h}:{i}:{s}')
            break
          case '近半年' :
            halfYearDate = parseTime(new Date(Date.now() - (365 / 2 * 24 * 3600 * 1000)), '{y}-{m}-{d} {h}:{i}:{s}')
            break
        }
        this.searchForm.startDate = halfYearDate
        this.searchForm.endDate = nowDate
        return pageOrder({
          ...data,
          condition: this.searchForm
        })
      },
      getDetail () {
        //获取登陆信息
        getByUserIdLast().then(res => {
          const { data } = res
          this.loginLastTime = data.lastTime
          this.loginCount = data.count
        })
        //获取用户签名
        this.handleGetSign()
        //获取浏览记录
        getBrowsingHistory(this.org.orgId).then(res => {
          this.historyList = res.data
        })
      },
      //获取用户签名
      handleGetSign () {
        getUserSignature().then(res => {
          const { data } = res
          if (data) {
            this.userSignName = data
          }
        })
      },
      // 点击签名出现输入框
      handleEditSignBol () {
        this.editSign = true
        this.autofocus = true
      },
      //编辑签名
      handleEditSign () {
        if (this.userSignName.userSignatureId) {
          this.handleUpdateSign()
        } else {
          this.handleSaveSign()
        }
        this.editSign = false
      },
      //修改签名
      handleUpdateSign () {
        var params = this.userSignName
        saveUserSignature(params).then(res => {
          this.$message.success(res.msg)
          this.editSign = false
          this.handleGetSign()
        })
      },
      //保存签名
      handleSaveSign () {
        var params = this.userSignName
        delete params.userSignatureId
        saveUserSignature(params).then(res => {
          this.$message.success(res.msg)
          this.editSign = false
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .member-center-home {
    width: 100%;
    height: auto;
    background: #f5f5f5;

    .member {
      margin: 16px 0;
      width: 100%;
      position: relative;
      background: #ffffff;
      border-radius: 8px;
      display: flex;

      .member-info {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
        width: 200px;
        background: $ThemeColorHalf;
        border-radius: 8px 0 0 8px;
        color: #ffffff;
        text-align: left;

        .member-item {
          line-height: 36px;
          width: 200px;
          cursor: pointer;
          background: $ThemeColor;
          border-bottom-left-radius: 8px;
          text-align: center;

          b {
            font-size: 24px;
            font-weight: bold;
          }
        }

        .portrait {
          margin: 0 auto;
          width: 64px;
          height: 64px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .member-content {
          padding: 2px 0;
          text-align: center;
          margin: 10px 0 0 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-height: 70px;

          .merchant-name {
            font-size: 20px;
            font-weight: 500;
          }

          .type {
            font-size: 16px;
            font-weight: 400;
            padding: 0 10px;

            .name {
              font-size: 18px;
            }

            .phone {
              margin-top: 6px;
            }
          }
        }
      }

      .member-info-right {
        width: 792px;

        .header {
          background: #ffffff;
          padding: 0 16px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 14px;
          height: 44px;
          line-height: 44px;
          border-bottom: 1px solid #e5e5e5;
          border-top-right-radius: 8px;

          .sign {
            color: rgba(102, 102, 102, 1);
            width: 450px;
            text-align: left;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            i {
              display: inline;
              margin-right: 8px;
              cursor: pointer;
            }

            .sign-msg {
              display: inline;
            }

            /deep/ .sign-input {
              width: 800px;

              .el-input__inner {
                border: none
              }
            }
          }

          .login-msg {
            color: rgba(153, 153, 153, 1);
            font-size: 12px;
          }
        }

        .member-boxs {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          height: 165px;

          .box {
            width: 110px;
            height: 110px;

            .order-box {
              cursor: pointer;
              padding: 0 10px;
              height: 100%;
              flex: 1;
              display: flex;
              flex-direction: column;
              align-items: center;

              &:hover {
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
                border-radius: 8px;

                .order-icon {
                  &.unpaid {
                    background-image: url("../../../assets/img/member-center/11-1.png");
                  }

                  &.library {
                    background-image: url("../../../assets/img/member-center/22-1.png");
                  }

                  &.receive {
                    background-image: url("../../../assets/img/member-center/33-1.png");
                  }

                  &.return {
                    background-image: url("../../../assets/img/member-center/44-1.png");
                  }

                  &.all-order {
                    background-image: url("../../../assets/img/member-center/55-1.png");
                  }
                }
              }

              .order-icon {
                position: relative;
                width: 48px;
                height: 48px;
                margin-top: 17px;
                margin-bottom: 12px;

                .badge {
                  position: absolute;
                  padding: 0 6px;
                  left: 35px;
                  top: -10px;
                  display: inline-block;
                  line-height: 20px;
                  height: 20px;
                  background: rgba(250, 100, 0, 1);
                  border-radius: 10px;
                  color: #ffffff;
                  font-size: 12px;
                  font-weight: 600;
                }

                &.unpaid {
                  background: url("../../../assets/img/member-center/11.png") no-repeat;
                  background-size: 100% 100%;
                }

                &.library {
                  background: url("../../../assets/img/member-center/22.png") no-repeat;
                  background-size: 100% 100%;
                }

                &.receive {
                  background: url("../../../assets/img/member-center/33.png") no-repeat;
                  background-size: 100% 100%;
                }

                &.return {
                  background: url("../../../assets/img/member-center/44.png") no-repeat;
                  background-size: 100% 100%;
                }

                &.all-order {
                  background: url("../../../assets/img/member-center/55.png") no-repeat;
                  background-size: 100% 100%;
                }
              }

              p {
                font-size: 14px;
                font-weight: 400;
                color: rgba(102, 102, 102, 1);
              }
            }

            .number {
              height: 46px;
              line-height: 46px;
              font-size: 36px;
              color: rgba(34, 34, 34, 1);
              margin-bottom: 8px;

              span {
                margin-left: 4px;
                font-size: 14px;
                color: rgba(34, 34, 34, 1);
              }

              &.money {
                color: #FA6400;
              }
            }

            p {
              font-size: 14px;
              font-weight: 400;
              color: rgba(102, 102, 102, 1);
            }
          }
        }
      }
    }
  }
</style>

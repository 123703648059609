<template>
  <el-popover
    :ref="'popover-' + index"
    :value="visible"
    :placement="placement"
    :width="width"
    :trigger="trigger"
  >
    <div class="confirm-popop">
      <p style="margin-top: 0;margin-bottom:13px;padding: 4px;">
        <i :class="iconType" style="margin-right: 4px;" />
        {{ tipTxt }}
      </p>
      <div style="text-align: right; margin: 0">
        <el-button size="mini" @click="handlePopoverCancel">取消</el-button>
        <el-button size="mini" type="primary" @click="handlePopoverConfirm">确定</el-button>
      </div>
    </div>
    <el-button
      v-if="btnType === 'text'"
      slot="reference"
      :disabled="disabled"
      class="im-confirm-popup--el-button"
      :class="btnClassName"
      type="text"
      @click.native.stop="handleShowPopover"
    >
      <i v-if="icon" :class="icon" />{{ referenceBtnTxt }}
    </el-button>
    <el-button
      v-if="btnType === 'icon'"
      slot="reference"
      :disabled="disabled"
      :class="btnClassName"
      type="text"
      :icon="icon"
      @click.stop="handleShowPopover"
    />
    <el-button
      v-if="btnType === 'button'"
      slot="reference"
      :disabled="disabled"
      :class="btnClassName"
      :type="buttonType"
      :icon="icon"
      @click.stop="handleShowPopover"
    >
      {{ referenceBtnTxt }}
    </el-button>
  </el-popover>
</template>
<script>
export default {
  name: 'ImConfirmPopup',
  props: {
    index: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: 'warning'
    },
    width: {
      type: [String, Number],
      default: '213'
    },
    tipTxt: {
      type: String,
      required: true
    },
    referenceBtnTxt: {
      type: String,
      default: ''
    },
    btnType: {
      type: String,
      default: 'text'
    },
    buttonType: {
      type: String,
      default: 'primary'
    },
    icon: {
      type: String,
      default: ''
    },
    btnClassName: {
      type: String,
      default: ''
    },
    placement: {
      type: String,
      default: 'bottom-end'
    },
    trigger: {
      type: String,
      default: 'click'
    },
    visible: {
      type: Boolean,
      default: false
    },
    beforeShow: {
      type: Function,
      default: () => {}
    },
    // 额外的自定义参数，传入后不做处理，在$emit中返回，用于v-for渲染该组件时，无法获取遍历对象数据的情况
    extraParam: {
      type: Object,
      default: function() {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconType() {
      const { type } = this
      if (type === 'warning') {
        return 'el-icon-warning color-FAAD14'
      } else if (type === 'error') {
        return 'el-icon-error color-F5222D'
      } else if (type === 'primary') {
        return 'el-icon-warning color-1890ff'
      } else {
        return ''
      }
    }
  },
  methods: {
    handlePopoverCancel() {
      this.handleHidePopover()
      this.$emit('cancel', this.index)
    },
    handlePopoverConfirm() {
      this.handleHidePopover()
      this.$emit('confirm', this.index)
    },
    handleShowPopover() {
      this.beforeShow(this.extraParam)
      // this.$refs[`popover-${this.index}`].doShow()
    },
    handleHidePopover() {
      this.$refs[`popover-${this.index}`].doClose()
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
.color-FAAD14 {
  color: #faad14;
}
.color-F5222D {
  color: #f5222d;
}
.color-1890ff{
  color: #1890ff;
}
.confirm-popop {
  padding: 16px;
}
.el-button--mini {
  padding: 1px 8px;
  line-height: 22px;
}

//下拉菜单内的按钮需要铺满菜单区域
.el-dropdown-menu__item {
  .im-confirm-popup--el-button {
    width: 100%;
    text-align: left;
    color: #606266;
  }
}
.el-dropdown-menu__item:not(.is-disabled):hover,
.el-dropdown-menu__item:focus {
  .im-confirm-popup--el-button {
    color: #46a6ff;
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "validContent" }, [
    _c("div", { staticClass: "validModal" }),
    _c("div", { staticClass: "validBox" }, [
      _c("div", { staticClass: "title" }, [_vm._v("请完成安全验证")]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "box",
        },
        [
          _c("slide-verify", {
            ref: "slideblock",
            attrs: {
              w: 350,
              h: 200,
              accuracy: _vm.accuracy,
              "slider-text": _vm.text,
            },
            on: {
              again: _vm.onAgain,
              fulfilled: _vm.onFulfilled,
              success: _vm.onSuccess,
              fail: _vm.onFail,
              refresh: _vm.onRefresh,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "handle" },
        [
          _c(
            "el-button",
            { attrs: { type: "text" }, on: { click: _vm.onCancel } },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import {ApiGet, ApiPost} from "@/api";

/**
 * @description 查询未结束的活动
 */
export const getPcDiscountRuleNoEnd = data => ApiGet('/pc/front/discountRule/getPcDiscountRuleNoEnd',data)

/**
 * @description 查询未结束的活动对应商品
 */
export const pageDiscountProductRel = data => ApiPost('/pc/front/discountRule/pageDiscountProductRel',data)
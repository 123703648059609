var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "im-page-modal",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "procurement-plan-update",
      attrs: {
        title: _vm.title,
        isShowBack: _vm.isShowBack,
        backTitle: "返回采购计划",
      },
      on: {
        "handle-back": function ($event) {
          return _vm.goBack(false)
        },
      },
    },
    [
      _c(
        "el-row",
        { staticStyle: { "padding-top": "20px" } },
        [
          _c("el-col", [
            _c(
              "span",
              { staticStyle: { display: "inline-block" } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.form,
                      rules: _vm.formRules,
                      "label-width": "142px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "采购计划名称：",
                          prop: "procurementPlanNm",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "400px" },
                          attrs: {
                            placeholder: "请输入采购计划名称",
                            disabled: _vm.disabled,
                          },
                          model: {
                            value: _vm.form.procurementPlanNm,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "procurementPlanNm", $$v)
                            },
                            expression: "form.procurementPlanNm",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "开启提醒：", prop: "isRemind" } },
                      [
                        _c("el-switch", {
                          staticClass: "swich-inner-text",
                          attrs: {
                            disabled: _vm.disabled,
                            "active-text": "是",
                            "inactive-text": "否",
                            "active-color": "#1CB7CD",
                            "active-value": "Y",
                            "inactive-value": "N",
                          },
                          model: {
                            value: _vm.form.isRemind,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "isRemind", $$v)
                            },
                            expression: "form.isRemind",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.form.isRemind === "Y"
                      ? [
                          false
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "是否重复提醒：",
                                    prop: "isRepeatRemind",
                                  },
                                },
                                [
                                  _c("el-switch", {
                                    staticClass: "swich-inner-text",
                                    staticStyle: { "margin-right": "30px" },
                                    attrs: {
                                      disabled: _vm.disabled,
                                      "active-text": "是",
                                      "inactive-text": "否",
                                      "active-color": "#1CB7CD",
                                      "active-value": "Y",
                                      "inactive-value": "N",
                                    },
                                    on: { change: _vm.changeIsRepeatRemind },
                                    model: {
                                      value: _vm.form.isRepeatRemind,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "isRepeatRemind",
                                          $$v
                                        )
                                      },
                                      expression: "form.isRepeatRemind",
                                    },
                                  }),
                                  _c("span", [_vm._v("每")]),
                                  _c("el-input-number", {
                                    staticStyle: {
                                      width: "60px",
                                      margin: "0 10px",
                                    },
                                    attrs: {
                                      size: "small",
                                      min: 0,
                                      disabled:
                                        _vm.disabled ||
                                        _vm.form.isRepeatRemind == "N",
                                      controls: false,
                                    },
                                    model: {
                                      value: _vm.form.remindDay,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "remindDay", $$v)
                                      },
                                      expression: "form.remindDay",
                                    },
                                  }),
                                  _c("span", [_vm._v("天重复提醒我")]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "提示内容：",
                                prop: "remindContent",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  size: "small",
                                  placeholder: "",
                                  disabled: _vm.disabled,
                                },
                                model: {
                                  value: _vm.form.remindContent,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "remindContent", $$v)
                                  },
                                  expression: "form.remindContent",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "提示方式：", prop: "warnWay" } },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  attrs: { disabled: _vm.disabled },
                                  on: { change: _vm.handleChangeWay },
                                  model: {
                                    value: _vm.form.warnWay,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "warnWay", $$v)
                                    },
                                    expression: "form.warnWay",
                                  },
                                },
                                [
                                  _c(
                                    "el-checkbox",
                                    { attrs: { label: "isPcRemind" } },
                                    [_vm._v("PC")]
                                  ),
                                  _c(
                                    "el-checkbox",
                                    { attrs: { label: "isAppRemind" } },
                                    [_vm._v("App")]
                                  ),
                                  _c(
                                    "el-checkbox",
                                    { attrs: { label: "isWechatRemind" } },
                                    [_vm._v("微信")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "提醒时间：",
                                prop: "remindTime",
                              },
                            },
                            [
                              _vm.form.isRepeatRemind !== "Y"
                                ? _c("el-date-picker", {
                                    staticStyle: { width: "400px" },
                                    attrs: {
                                      "prefix-icon": "el-icon-date",
                                      disabled: _vm.disabled,
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      type: "datetime",
                                      placeholder: "请选择提醒时间",
                                    },
                                    model: {
                                      value: _vm.form.remindTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "remindTime", $$v)
                                      },
                                      expression: "form.remindTime",
                                    },
                                  })
                                : _c("el-time-picker", {
                                    attrs: { placeholder: "请选择提醒时间" },
                                    model: {
                                      value: _vm.form.remindTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "remindTime", $$v)
                                      },
                                      expression: "form.remindTime",
                                    },
                                  }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "shop-list-header" },
        [
          _c("span", { staticClass: "header-text" }, [_vm._v("商品清单")]),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.handelAddShop } },
            [_vm._v("添加商品")]
          ),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.handleDeleteMore } },
            [_vm._v("批量删除")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "tablePage",
          class: { "is-tabledata-empty": _vm.form.tableData.length === 0 },
          staticStyle: { width: "100%" },
          attrs: { data: _vm.form.tableData, border: "" },
          on: {
            "selection-change": (val) => {
              _vm.selectItem = val
            },
          },
        },
        [
          _vm._v(" > "),
          _c("el-table-column", {
            attrs: { type: "selection", width: "50", align: "center" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "productNm",
              label: "商品名称",
              width: "180",
              fixed: "left",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$util.getGoodsName(scope.row)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "spec",
              label: "规格",
              width: "120",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "unit",
              label: "单位",
              width: "70",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "manufacturer",
              label: "厂家(上市许可持有人)/产地",
              width: "200",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "datepackTotal",
              label: "中/件包装",
              width: "120",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.packTotal ? scope.row.packTotal : "--")
                      ),
                    ]),
                    _vm._v(" / "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.midPackTotal ? scope.row.midPackTotal : "--"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "minBuyMultiple",
              label: "最小购买倍数",
              width: "150",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(_vm.getRealMinBuyNum(scope.row)) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "stockQuantity",
              label: "库存",
              width: "80",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.stockQuantity >= 100 ? "≥100" : "<100")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "price",
              align: "right",
              label: "单价",
              width: "80",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "productQuantity",
              label: "数量",
              width: "160",
              fixed: "right",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input-number", {
                      attrs: {
                        size: "mini",
                        "step-strictly": "",
                        precision: _vm.getDecimalPointNum(scope.row),
                        step: _vm.getRealMinBuyNum(scope.row),
                        min: _vm.getRealMinBuyNum(scope.row),
                      },
                      model: {
                        value: scope.row.productQuantity,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "productQuantity", $$v)
                        },
                        expression: "scope.row.productQuantity",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "date",
              label: "操作",
              width: "80",
              fixed: "right",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { padding: "2px 4px" },
                            attrs: { size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.form.tableData.splice(
                                  scope.$index,
                                  1
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.form.tableData.length === 0
        ? _c("div", { staticClass: "empty-default" }, [
            _c(
              "div",
              { staticClass: "empty-box" },
              [
                _c("el-image", {
                  staticStyle: { width: "160px", height: "160px" },
                  attrs: { src: _vm.emptyDefault, fit: "fill" },
                }),
                _c("p", { staticClass: "empty-tip" }, [_vm._v("暂无商品~")]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.editType === "add"
        ? _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "完成所有必填信息可提交",
                    placement: "top-end",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.onCommit },
                    },
                    [_vm._v("创建采购计划")]
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.goBack(false)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.editType === "edit"
        ? _c(
            "div",
            {
              staticClass: "content-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-checkbox",
                {
                  on: {
                    change: () => {
                      this.$refs.tablePage.toggleAllSelection()
                    },
                  },
                  model: {
                    value: _vm.AllChecked,
                    callback: function ($$v) {
                      _vm.AllChecked = $$v
                    },
                    expression: "AllChecked",
                  },
                },
                [_vm._v("全选")]
              ),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        "background-color": "#FA6400",
                        "border-color": "#FA6400",
                      },
                      attrs: {
                        size: "small",
                        type: "primary",
                        disabled: _vm.isBanAddToCart,
                      },
                      on: { click: _vm.handleAddCart },
                    },
                    [_vm._v("加入购物车")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.onCommit } },
                    [_vm._v("保存采购计划")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("add-shop", {
        ref: "addShop",
        attrs: { productList: _vm.form.tableData },
        on: {
          "update:productList": function ($event) {
            return _vm.$set(_vm.form, "tableData", $event)
          },
          "update:product-list": function ($event) {
            return _vm.$set(_vm.form, "tableData", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
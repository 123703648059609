var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      ref: "popover-" + _vm.index,
      attrs: {
        value: _vm.visible,
        placement: _vm.placement,
        width: _vm.width,
        trigger: _vm.trigger,
      },
    },
    [
      _c("div", { staticClass: "confirm-popop" }, [
        _c(
          "p",
          {
            staticStyle: {
              "margin-top": "0",
              "margin-bottom": "13px",
              padding: "4px",
            },
          },
          [
            _c("i", {
              class: _vm.iconType,
              staticStyle: { "margin-right": "4px" },
            }),
            _vm._v(" " + _vm._s(_vm.tipTxt) + " "),
          ]
        ),
        _c(
          "div",
          { staticStyle: { "text-align": "right", margin: "0" } },
          [
            _c(
              "el-button",
              {
                attrs: { size: "mini" },
                on: { click: _vm.handlePopoverCancel },
              },
              [_vm._v("取消")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "mini", type: "primary" },
                on: { click: _vm.handlePopoverConfirm },
              },
              [_vm._v("确定")]
            ),
          ],
          1
        ),
      ]),
      _vm.btnType === "text"
        ? _c(
            "el-button",
            {
              staticClass: "im-confirm-popup--el-button",
              class: _vm.btnClassName,
              attrs: {
                slot: "reference",
                disabled: _vm.disabled,
                type: "text",
              },
              nativeOn: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleShowPopover.apply(null, arguments)
                },
              },
              slot: "reference",
            },
            [
              _vm.icon ? _c("i", { class: _vm.icon }) : _vm._e(),
              _vm._v(_vm._s(_vm.referenceBtnTxt) + " "),
            ]
          )
        : _vm._e(),
      _vm.btnType === "icon"
        ? _c("el-button", {
            class: _vm.btnClassName,
            attrs: {
              slot: "reference",
              disabled: _vm.disabled,
              type: "text",
              icon: _vm.icon,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleShowPopover.apply(null, arguments)
              },
            },
            slot: "reference",
          })
        : _vm._e(),
      _vm.btnType === "button"
        ? _c(
            "el-button",
            {
              class: _vm.btnClassName,
              attrs: {
                slot: "reference",
                disabled: _vm.disabled,
                type: _vm.buttonType,
                icon: _vm.icon,
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleShowPopover.apply(null, arguments)
                },
              },
              slot: "reference",
            },
            [_vm._v(" " + _vm._s(_vm.referenceBtnTxt) + " ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
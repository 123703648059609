<template>
  <div class="returns-refunds">
    <div class="search-header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline" size="small">
        <el-form-item label="订单编号"  prop="orderNum">
          <el-input style="width: 163px;" v-model="searchForm.orderNum" placeholder="输入订单编号"></el-input>
        </el-form-item>
        <el-form-item label="商品名称" prop="productNm">
          <el-input style="width: 163px;" v-model="searchForm.productNm" placeholder="输入商品名称"></el-input>
        </el-form-item>
        <el-form-item label="发货批号" prop="sendBatch">
          <el-input style="width: 163px;" v-model="searchForm.sendBatch" placeholder="输入发货批号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
          <el-button type="text" size="mini" @click="handleReset"><span style="color: #000000">重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- <div class="refunds-main"> -->
      <el-tabs v-model="activeName" @tab-click="handleTab" class="refunt-step-tabs">
        <el-tab-pane name="APPLY_RETURN">
            <span slot="label" >申请退货</span>
        </el-tab-pane>
        <el-tab-pane name="PROCESSING_RETURN">
            <span slot="label"> 退货处理中 </span>
        </el-tab-pane>
        <el-tab-pane name="RECORD_RETURN">
            <span slot="label"> 退货记录 </span>
        </el-tab-pane>
      </el-tabs>
    <div class="refunds-main">
      <!-- 表格 -->
      <im-table-page
        ref="tablePage"
        :data.sync="tableData"
        :remote-method="queryData"
        :span-method="spanMethod"
        :defaultExpandAll="true"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        :row-class-name="renderRowClass"
        row-key="id"
        :show-page="true"
      >
        <el-table-column
          :key="Math.random()"
           :resizable="false"
          v-if="activeName==='APPLY_RETURN'"
          width="40">
          <template slot-scope="scope">
            <el-checkbox @change="handleSelectionChange(scope.row)" :disabled="scope.row.isReturnable === 'N'"  class="three-button" v-model='scope.row.checked'  style="float:left"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="商品信息" :resizable="false" width="308px" show-overflow-tooltip class="spanp">
          <template slot-scope="scope">
            <p v-if="scope.row.orderNum" class="tabletitle">
              <template v-if="activeName !== 'APPLY_RETURN'">
              退货编号：<span class="order-row-text">{{scope.row.returnedOrderNum}}</span>
              </template>
              订单编号：<span class="order-row-text">{{scope.row.orderNum}}</span>
              下单时间：<span class="order-row-text">{{scope.row.createDate}}</span>
              <template v-if="scope.row.ettleAccountNm !=='默认分支机构'">
                分支机构：<span class="order-row-text">{{scope.row.ettleAccountNm}}</span>
                分支机构编码：<span class="order-row-text">{{scope.row.code}}</span>
              </template>
              <el-button v-if="activeName === 'APPLY_RETURN'" type="primary" size="mini" @click="handleRefundFirst(scope.row)">一键退货</el-button>
              <template v-if="scope.row.returnedOrderStat === 'REFUSE'">
              拒绝原因：<span class="order-row-text">{{scope.row.returnReason}}</span>
              </template>
            </p>
            <div class="product-info" v-else>
              <div class="product-image">
                <router-link :to="'/detail/' + scope.row.productId"><el-image
                  :src="scope.row.picture || ProDuct"
                  @error="scope.row.picture = ProDuct"
                  fit="fit">
                </el-image>
                </router-link>
                <el-image
                  v-if="scope.row.isReturnable === 'N'"
                  :src="YiXiaJia"
                  fit="fit"
                  class="yixiajia"
                >
                </el-image>
              </div>
              <ul class="product-detail">
                <router-link :to="'/detail/' + scope.row.productId"><li class="proname">
                  {{$util.getGoodsName(scope.row)}}
                </li></router-link>
                <li class="size-vender">
                  <p>规格：<span>{{ scope.row.spec}}</span></p>
                  <p class=""><span>{{ scope.row.manufacturer}}</span></p>
                </li>
              </ul>
            </div>
            <div></div>
          </template>
        </el-table-column>
        <el-table-column label="商品编码"  :resizable="false" prop="productCode" min-width="100px" show-overflow-tooltip />
        <el-table-column label="发货批号"  :resizable="false" prop="sendBatch" width="85px" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="退货价" :resizable="false" prop="money" align="right" min-width="85px">
          <template slot-scope="scope">
            <span>¥{{ scope.row.settlePrice }}</span>
          </template>
        </el-table-column>
        <el-table-column label="出库/可退数量" :resizable="false" prop="number">
          <template slot-scope="scope">
            <span>{{ scope.row.quantity}} / {{ scope.row.returnableQuantity}}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="activeName !== 'APPLY_RETURN'"
          :key="Math.random()" :resizable="false"
          label="退货数量" prop="returnOrderQuntity"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.returnOrderQuntity }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :key="Math.random()" :resizable="false"
          v-if="activeName !== 'APPLY_RETURN'"
          label="状态" prop="returnedOrderStat"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="color: orange" v-if="scope.row.returnedOrderStat === 'WAIT_APPROVE' && !scope.row.orderNum">等待审核</span>
            <span style="color: orange" v-if="scope.row.returnedOrderStat === 'WAIT_STOCK_IN' && !scope.row.orderNum">退货处理中</span>
            <span style="color: orange" v-if="scope.row.returnedOrderStat === 'REFUSE' && !scope.row.orderNum">已拒绝</span>
            <span style="color: orange" v-if="scope.row.returnedOrderStat === 'FINSH' && !scope.row.orderNum">已完成</span>
            <span style="color: orange" v-if="scope.row.returnedOrderStat === 'CANCEL' && !scope.row.orderNum">已取消</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" :resizable="false">
          <template slot-scope="scope">
            <div class="apply" v-if="activeName==='APPLY_RETURN' && !scope.row.orderNum">
              <el-button style="padding:4px 8px"  v-if="scope.row.isReturnable !== 'N'" size="mini" @click="handleRefund(scope.row)">申请退货</el-button>
            </div>
            <ul class="apply" v-if="activeName==='PROCESSING_RETURN' && !scope.row.orderNum">
              <li>
                <el-button style="padding:4px 8px" size="mini" @click="handleWatch(scope.row)">退货详情</el-button>
              </li>
              <li v-if="scope.row.returnedOrderStat === 'WAIT_APPROVE'">
                <el-button style="padding:4px 8px;margin-top:4px" size="mini" @click="handleCancel(scope.row)">取消退货</el-button>
              </li>
            </ul>
            <ul class="apply" v-if="activeName==='RECORD_RETURN' && !scope.row.orderNum">
              <li>
                <el-button style="padding:4px 8px" size="mini" @click="handleWatch(scope.row)">查看详情</el-button>
              </li>
              <li  v-if="scope.row.returnedOrderStat === 'REFUSE'">
                <el-popover
                        class="refusePopover"
                        placement="bottom"
                        title="拒绝原因"
                        width="320"
                        trigger="click"
                >
                  <div class="popover-content">{{scope.row.returnReason}}</div>
                  <el-button type="text" size="mini" style="padding: 4px 8px" slot="reference">拒绝原因</el-button>
                </el-popover>
              </li>
              <li v-if="scope.row.returnedOrderStat === 'FINSH'">
                <el-popover
                        class="refusePopover"
                        placement="bottom"
                        title="钱款去向"
                        width="320"
                        trigger="click"
                >
                  <div class="popover-content">退至可用额度： <span class="returnMon">¥{{scope.row.returnedOrderAmount}}</span></div>
<!--                  <el-button type="text" size="mini" style="padding: 4px 8px" slot="reference">钱款去向</el-button>-->
                </el-popover>
              </li>
            </ul>
          </template>
        </el-table-column>
      </im-table-page>
    </div>
  </div>
</template>

<script>
  import ProDuct from '@/assets/img/index/product_default.png'
  import YiXiaJia from '@/assets/img/member-center/icon-yixiajia.png'
  import { getRefundsOrders, pageReturnedOrderRecords , cancelReturnedOrder} from '@/api/member-center/refunds'

  export default {
    name: 'ReturnsRefunds',
    data () {
      return {
        activeName: 'APPLY_RETURN',
        form: {},
        searchForm:{
          orderNum:'',
          productNm:'',
          sendBatch:'',
          returnedOrderStat: ''
        },
        ProDuct: ProDuct,
        YiXiaJia: YiXiaJia,
        tableData: [],

      }
    },
    beforeUpdate(){
        this.$nextTick(()=>{ this.$refs.tablePage.doLayout() })
    },
    created () {
      this.$emit('handle-change-page', {
        text: '退货/退款',
        index: '2-2',
        url: '/member-center/order-manage/refunds'
      })
    },
    methods: {
      handleSelectionChange(data) {
        if(data.orderNum && data.checked){
          this.tableData.map((item,index) => {
            if(item.orderNum === data.orderNum){
              this.tableData[index].children.map((itemone, indexone) => {
                if(itemone.isReturnable !== 'N'){
                itemone.checked = true
                }
              })

            }
          })
        }else if(data.orderNum && !data.checked){
          this.tableData.map((item,index) => {
            if(item.orderNum === data.orderNum){
              this.tableData[index].children.map((itemone, indexone) => {
                itemone.checked = false
              })
              }
          })
        }else if(!data.orderNum && data.checked) {
          data.checked = true
        }else if(!data.orderNum && !data.checked){
          data.checked = false
        }
      },
      handleTab(tabs) {
        this.activeName = tabs.name;
        // this.$refs.tablePage.doLayout()
        this.tableData = []
        this.$refs.tablePage.doRefresh()
      },
      onSubmit() {},
      renderRowClass(row) {
        if (row.row.orderNum) {
          return 'order-row'
        } else {
          return ''
        }
      },
      queryData (data) {
        // if(this.$route.query.activeName === 'PROCESSING_RETURN1'){
        //   this.activeName = 'PROCESSING_RETURN'
        // }
        return new Promise(then => {
          if(this.activeName === 'APPLY_RETURN'){
            getRefundsOrders({
              ...data,
              condition: this.searchForm
            }).then(res => {
              res.data.records.map((item, index) => {
                item.children = item.orderItemList
                item.id = (index + 1) + ''
                item.children.map((itemone, indexone) => {
                  let indexchild = indexone + 1
                  itemone.id = item.id + '-' + indexchild
                  itemone.returnedOrderStat = item.returnedOrderStat
                  itemone.orderId = item.orderId
                })
              })
              this.tableData = res.data.records
              then(res)
            })
          }else if(this.activeName === 'PROCESSING_RETURN'){
            this.searchForm.returnedOrderStat = 'processing'
            // 退货处理中
            pageReturnedOrderRecords({
              ...data,
              condition: this.searchForm
            }).then(res => {
              res.data.records.map((item, index) => {
                item.children = item.returnOrderRecordsItemList
                item.id = (index + 1) + ''
                item.children.map((itemone, indexone) => {
                  let indexchild = indexone + 1
                  itemone.id = item.id + '-' + indexchild
                  itemone.returnedOrderStat = item.returnedOrderStat
                  itemone.returnedOrderId = item.returnedOrderId
                   if(indexchild === 1) {
                    itemone.first = 'first'
                    itemone.totalLen = item.returnOrderRecordsItemList.length
                  }
                })
              })
              this.tableData = res.data.records
              then(res)
            })
          }else if(this.activeName === 'RECORD_RETURN'){
           this.searchForm.returnedOrderStat = 'record'
            // 退货记录
            pageReturnedOrderRecords({
              ...data,
              condition: this.searchForm
            }).then(res => {
              res.data.records.map((item, index) => {
                item.children = item.returnOrderRecordsItemList
                item.id = (index + 1) + ''
                item.children.map((itemone, indexone) => {
                  let indexchild = indexone + 1
                  itemone.id = item.id + '-' + indexchild
                  itemone.returnedOrderStat = item.returnedOrderStat
                  itemone.returnedOrderId = item.returnedOrderId
                  itemone.returnReason = item.returnReason
                  itemone.refundTotalAmount = item.refundTotalAmount
                  if(indexchild === 1) {
                    itemone.first = 'first'
                    itemone.totalLen = item.returnOrderRecordsItemList.length
                  }
                })
              })
              this.tableData = res.data.records
              then(res)
            })
          }

        })
      },
      // 退货单
      handleRefund(data){
        let itemid = data.orderItemId
        let refundOrder = {};
        this.tableData.map(item => {
          item.orderItemList.map(itemone => {
            if(itemone.orderItemId === itemid){
              refundOrder.createDate = item.createDate
              refundOrder.children =[]
              refundOrder.children.push(itemone)
              refundOrder.orderNum = item.orderNum
              refundOrder.orderId = item.orderId
              refundOrder.id = item.id
              refundOrder.refundOrderItemList =[]
              refundOrder.refundOrderItemList.push(itemone)
              refundOrder.receiverName = item.receiverName
              refundOrder.receiverMobile = item.receiverMobile
              refundOrder.province = item.province
              refundOrder.district = item.district
              refundOrder.city = item.city
              refundOrder.provinceId = item.provinceId
              refundOrder.districtId = item.districtId
              refundOrder.cityId = item.cityId
              refundOrder.receiverAddr = item.receiverAddr
              refundOrder.freightAmount = item.freightAmount
              refundOrder.packageId = item.packageId
            }
          })
        })
        this.$router.push({path:'/refunds-replay-list',  query:{refundOrder: refundOrder, onePro: true}})
      },
      // 一键退货
      handleRefundFirst(data) {
        let firstData = {}
        this.tableData.map(item => {
          if(item.orderNum === data.orderNum){
            firstData = item
          }
        })
        firstData.orderItemList = firstData.children.filter(item  => item.checked && item.checked === true)

        if(firstData.orderItemList.length === 0){
          this.$alert('还未选择该订单要退货的商品',  {
            confirmButtonText: '确定',
          });
        }else {
          let isRefund = true
          firstData.orderItemList.map(item => {
            if(item.isReturnable === 'N'){
              isRefund = false
            }
          })
          if(isRefund){
            this.$router.push({path:'/refunds-replay-list',  query:{refundOrder: firstData}})
          }else {
            this.$alert('选中的商品存在不可退的商品',  {
              confirmButtonText: '确定',
            });
          }
        }
      },
      handleWatch(data){
        let returnedOrderId = data.returnedOrderId
        this.$router.push({path:'/refunds-detail', query:{returnedOrderId: returnedOrderId}})
      },
      // 取消退货申请
      handleCancel(data) {
        let returnedOrderId = data.returnedOrderId
        let isCancel = true
        this.$confirm('您是否取消申请?', '取消申请', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          cancelReturnedOrder({returnedOrderId, isCancel}).then(res => {
            if(res.code === '0'){
              this.$message.success('取消成功! ')
              this.$refs.tablePage.doRefresh()
            }else {
              this.$message.error('取消失败! ')
            }
          })
        })
      },
      handleSearch(){
        this.$refs.tablePage.doRefresh()
      },
      handleReset(){
        this.searchForm = {
            orderNum:'',
            productNm:'',
            sendBatch:''
        }
        this.$refs.tablePage.doRefresh()
      },
      spanMethod(rowObj) {
        const {row, column, rowIndex, columnIndex} = rowObj;
        if (row.orderNum) {
          if (this.activeName === 'APPLY_RETURN') {
            if (columnIndex) {
              return {
                rowspan: 1,
                colspan: 5
              }
            }
          } else {
            return {
              rowspan: 1,
              colspan: 7
            }
          }
        }

        if(this.activeName !== 'APPLY_RETURN'){
          if (columnIndex === 6 || columnIndex === 7) {
            if(row.first === 'first') {
              return {
                rowspan: row.totalLen,
                colspan: 1
              }
            }
          }
        }
      }
    }

  }
</script>

<style scoped lang="scss">
  .tabletitle {
    font-size:12px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(102,102,102,1);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 10px 0;
  }
  .refusePopover {
    height: 110px;
  }
  .returns-refunds {
    width: 100%;
    min-height: 100%;
    // height: auto;
    background: #ffffff;
    padding: 0 20px;
    .search-header {
      width: 100%;
      height: 72px;
      display: flex;
      align-items: center;
      // padding: 0 20px;
      border-bottom: 1px solid #E5E5E5;

      /deep/ .el-form-item {
        margin-bottom: 0;
        margin-right: 20px;

        .el-form-item__label {
          padding-right: 4px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(113, 113, 113, 1);
        }

        .el-input__inner {
          padding: 0 12px;
          background: rgba(255, 255, 255, 1);
          border-radius: 4px;
          border: 1px solid rgba(204, 204, 204, 1);
        }
      }
    }

    .refunds-main {
      // width: 100%;
      height: auto;
      background: #ffffff;
      .product-info {
        display: flex;
        flex-direction: row;

        .product-image {
          width: 70px;
          height: 70px;
          position: relative;
          .el-image {
            width: 100%;
            height: 100%;
          }
        }

        .product-detail {
          padding-left: 16px;
          flex: 1;
          overflow: hidden;

          .proname {
            height: 20px;
            font-size: 14px;
            font-weight: 500;
            color: rgba(34, 34, 34, 1);
            line-height: 20px;
            margin-bottom: 4px;
          }

          .size-vender {
            font-size: 14px;
            font-weight: 400;
            color: rgba(113, 113, 113, 1);

            p {
              line-height: 20px;
              height: 20px;
            }
          }
        }

      }

      /deep/ .el-popover{
         .el-popover__title{
          background:rgba(250,250,250,1);
          border:1px solid rgba(233,233,233,1);
          font-weight: bold;
          // background:rgba(250,250,250,1);
          // border:1px solid rgba(233,233,233,1);
        }
          .popover-content {
           padding: 24px 16px;
        }
      }

      /deep/ .el-tabs__header {
        margin: 0 !important;
      }

      /deep/ .el-table {
        .order-row {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
          height: 47px;
          td{
            background: $hoverBg;
            padding: 0px 0px;
          }
          .order-row-text {
            margin-right: 10px;
          }

          .el-button {
            padding: 5px 8px;

            span {
              margin: 0;
            }
          }
        }

        .el-table-column--selection {

        }

        .el-table__header-wrapper .el-checkbox { //找到表头那一行，然后把里面的复选框隐藏掉
          display: none
        }

        .cell {
          width: 100% !important;

          .el-table__expand-icon {
            display: none;
          }

          .el-table__indent, .el-table__placeholder {
            display: none;
          }
        }
      }
    }
  }

  .yixiajia {
    position: absolute;
    left: 0px;
  }

  .popover-content {
    height: 52px;
    padding: 8px 15px;
  }

  .returnMon {
    font-size:14px;
    font-family:PingFang-SC-Bold,PingFang-SC;
    font-weight:bold;
    color:rgba(224,32,52,1);
    line-height:22px;
  }
</style>

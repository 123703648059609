var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customer-list" },
    [
      _c(
        "search-pad",
        {
          staticClass: "client-file-search-pad",
          attrs: { model: _vm.searchForm, hasExpand: false },
          on: { search: _vm.handleSearch, reset: _vm.handleReset },
        },
        [
          _c(
            "search-pad-item",
            { attrs: { prop: "searchField" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入客户编码/名称" },
                model: {
                  value: _vm.searchForm.searchField,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "searchField", $$v)
                  },
                  expression: "searchForm.searchField",
                },
              }),
            ],
            1
          ),
          _c(
            "search-pad-item",
            { attrs: { prop: "entContactMan" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入负责人" },
                model: {
                  value: _vm.searchForm.entContactMan,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "entContactMan", $$v)
                  },
                  expression: "searchForm.entContactMan",
                },
              }),
            ],
            1
          ),
          _c(
            "search-pad-item",
            { attrs: { prop: "contactMoblie" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入联系电话" },
                model: {
                  value: _vm.searchForm.contactMoblie,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "contactMoblie", $$v)
                  },
                  expression: "searchForm.contactMoblie",
                },
              }),
            ],
            1
          ),
          _c(
            "search-pad-item",
            { attrs: { prop: "export" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.exportData },
                },
                [_vm._v("导出数据")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c(
            "im-table-page",
            {
              ref: "tablePage",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "remote-method": _vm.queryData,
                "header-row-class-name": "header-row",
                "cell-class-name": _vm.cellClassName,
                "header-cell-class-name": _vm.cellClassName,
              },
              on: {
                "update:data": function ($event) {
                  _vm.tableData = $event
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "50px",
                  fixed: "left",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "状态",
                  fixed: "left",
                  prop: "isEnable",
                  width: "60px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          staticClass: "swich-inner-text",
                          attrs: {
                            "active-text": "启",
                            "inactive-text": "冻",
                            "active-color": "#1890FF",
                            "active-value": "Y",
                            "inactive-value": "N",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.switchChange(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.isEnable,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "isEnable", $$v)
                            },
                            expression: "scope.row.isEnable",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "erpCode",
                  fixed: "left",
                  label: "客户编码",
                  width: "80px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "buyersNm",
                  fixed: "left",
                  label: "客户名称",
                  width: "100px",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "ettleAccountNm",
                  label: "分支机构",
                  width: "100px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "userMobile",
                  label: "联系电话",
                  width: "115px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input-number", {
                          staticClass: "account-input",
                          staticStyle: { width: "100%" },
                          attrs: {
                            controls: false,
                            disabled: scope.row.isEdit,
                            precision: 0,
                            min: 0,
                            placeholder: "请输入联系方式",
                          },
                          model: {
                            value: scope.row.userMobile,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "userMobile", $$v)
                            },
                            expression: "scope.row.userMobile",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "availableCredit",
                  align: "right",
                  label: "可用额度",
                  "show-overflow-tooltip": "",
                  width: "110px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "剩余账期",
                  width: "80px",
                  prop: "limitCreditDate",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isEdit
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.limitCreditDate
                                    ? scope.row.limitCreditDate
                                    : "--"
                                )
                              ),
                            ])
                          : _c("el-input", {
                              staticClass: "account-input",
                              attrs: {
                                type: "number",
                                "auto-complete": "on",
                                placeholder: "请输入小于原账期天数",
                                disabled: scope.row.isEdit,
                              },
                              model: {
                                value: scope.row.limitCreditDate,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "limitCreditDate", $$v)
                                },
                                expression: "scope.row.limitCreditDate",
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "customerTypeName",
                  align: "center",
                  label: "客户类型",
                  width: "80px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "loginId",
                  align: "center",
                  label: "账号名称",
                  width: "80px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "legalPersonMember",
                  align: "center",
                  label: "企业法人",
                  width: "80px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "entContactMan",
                  align: "center",
                  label: "负责人",
                  width: "80px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "area",
                  align: "center",
                  label: "所在区域",
                  width: "100px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.province &&
                        scope.row.city &&
                        scope.row.district
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  `${scope.row.province}-${scope.row.city}-${scope.row.district}`
                                )
                              ),
                            ])
                          : _c("span"),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "businessRangeIds",
                  align: "center",
                  label: "经营范围",
                  width: "200px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "right",
                              trigger: "hover",
                              width: "600",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "business-detail" },
                              _vm._l(
                                scope.row.businessRangeDetail,
                                function (value, key) {
                                  return _c(
                                    "el-row",
                                    { key: key },
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                          },
                                          attrs: { span: 5 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "grid-content bg-purple",
                                              staticStyle: {
                                                "font-weight": "bold",
                                              },
                                            },
                                            [_vm._v(_vm._s(key) + "：")]
                                          ),
                                        ]
                                      ),
                                      _c("el-col", { attrs: { span: 19 } }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple-light",
                                            staticStyle: {
                                              "max-height": "400px",
                                              "overflow-y": "auto",
                                            },
                                          },
                                          [_vm._v(_vm._s(value))]
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            ),
                            _c(
                              "span",
                              {
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [_vm._v(_vm._s(scope.row.businessRangeNmsStr))]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "paymentWayName",
                  align: "center",
                  label: "结款方式",
                  width: "155px",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              multiple: "",
                              disabled: scope.row.isEdit,
                              placeholder: "请选择结款方式",
                            },
                            model: {
                              value: scope.row.paymentWayName,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "paymentWayName", $$v)
                              },
                              expression: "scope.row.paymentWayName",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "现款", value: "现款" },
                            }),
                            _c("el-option", {
                              attrs: { label: "赊销", value: "赊销" },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  align: "center",
                  width: "100",
                  label: "操作",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "table-operation" },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  padding: "4px 8px",
                                  "font-size": "12px",
                                },
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.replaceCustomerToOrder(scope.row)
                                  },
                                },
                              },
                              [_vm._v("代客下单")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "ul",
      { staticClass: "table-row-tags" },
      [
        _vm.activityList.length > 0
          ? _c(
              "el-dropdown",
              { attrs: { placement: "bottom-start" } },
              [
                _vm.taglist.findIndex((res) => res.tag === "促销") !== -1
                  ? _c("li", { staticClass: "row-tag tag-promotion" }, [
                      _vm._v(" 促销 "),
                      _c("i", { staticClass: "el-icon-arrow-down" }),
                    ])
                  : _vm._e(),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c("el-dropdown-item"),
                    _vm._l(_vm.activityList, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "dropdown-item" },
                        [
                          _c("br"),
                          _vm._v(" " + _vm._s(item.discountNm) + " "),
                          _c("br"),
                          _c("br"),
                          _vm._v(" " + _vm._s(item.discountTime) + " "),
                          _c("br"),
                          _c("br"),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.taglist.findIndex((res) => res.tag === "现款") !== -1
          ? _c("li", { staticClass: "row-tag tag-cash" }, [_vm._v("现款")])
          : _vm._e(),
        _vm.taglist.findIndex((res) => res.tag === "不得现金交易") !== -1
          ? _c("li", { staticClass: "row-tag tag-c ash" }, [
              _vm._v("不得现金交易"),
            ])
          : _vm._e(),
        _vm.taglist.findIndex((res) => res.tag === "历史购买") !== -1
          ? _c("li", { staticClass: "row-tag tag-promotion" }, [
              _vm._v("历史购买"),
            ])
          : _vm._e(),
        _vm.taglist.findIndex((res) => res.tag === "已加购") !== -1
          ? _c("li", { staticClass: "row-tag tag-plus" }, [_vm._v("已加购")])
          : _vm._e(),
        _vm.taglist.findIndex((res) => res.tag === "禁销") !== -1
          ? _c("li", { staticClass: "row-tag tag-waring" }, [_vm._v("禁销")])
          : _vm._e(),
        _vm.taglist.findIndex((res) => res.tag === "限销") !== -1
          ? _c("li", { staticClass: "row-tag tag-waring" }, [_vm._v("限销")])
          : _vm._e(),
        _vm.taglist.findIndex((res) => res.tag === "优惠券") !== -1
          ? _c("li", { staticClass: "row-tag tag-coupon" }, [_vm._v("优惠券")])
          : _vm._e(),
        _vm.taglist.findIndex((res) => res.tag === "二销") !== -1
          ? _c("li", { staticClass: "row-tag tag-coupon" }, [_vm._v("二销")])
          : _vm._e(),
        _vm.taglist.findIndex((res) => res.tag === "超值换购") !== -1
          ? _c("li", { staticClass: "row-tag tag-coupon" }, [
              _vm._v("超值换购"),
            ])
          : _vm._e(),
        _vm.taglist.findIndex((res) => res.tag === "近效") !== -1
          ? _c("li", { staticClass: "row-tag tag-coupon" }, [_vm._v("近效")])
          : _vm._e(),
        _vm.taglist.findIndex((res) => res.tag === "预售") !== -1
          ? _c("li", { staticClass: "row-tag tag-coupon" }, [_vm._v("预售")])
          : _vm._e(),
        _vm.taglist.findIndex((res) => res.tag === "集采") !== -1
          ? _c("li", { staticClass: "row-tag tag-coupon" }, [_vm._v("集采")])
          : _vm._e(),
        _vm.taglist.findIndex((res) => res.tag === "冷藏") !== -1
          ? _c("li", { staticClass: "row-tag tag-coupon" }, [_vm._v("冷藏")])
          : _vm._e(),
        _vm.taglist.findIndex((res) => res.tag === "冷冻") !== -1
          ? _c("li", { staticClass: "row-tag" }, [_vm._v("冷冻")])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <el-dialog width="800px" title="选择客户" visible @close="onClose">
        <div class="search">
            <el-input v-model="searchField" @keyup.enter.native="onSearch" placeholder="请输入客户编码/客户名称"></el-input>
            <el-button type="primary" size="mini" @click="onSearch">搜索</el-button>
        </div>
        <im-table-page
                ref="tablePage"
                :data.sync="tableData"
                :remote-method="queryData"
                :addQuantity="true"
                :index="true"
                header-row-class-name="header-row"
                style="width: 100%;margin-top: 15px">
            <el-table-column prop="erpCode" label="客户编码" width="100px" show-overflow-tooltip />
            <el-table-column prop="buyersNm" align="left" label="客户名称" show-overflow-tooltip />
            <el-table-column label="操作" align="center" width="80px">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" @click="onChoose(scope.row)">选择</el-button>
                </template>
            </el-table-column>
        </im-table-page>
    </el-dialog>

</template>

<script>
    import {pageCustomer} from "@/api/member-center/customer-service";

    export default {
        name: "chooseBuyers",
        props:{

        },
        data(){
            return {
                tableData:[],
                searchField:''
            }
        },
        methods:{
            onChoose(item){
                this.$emit('confirm',item)
                this.onClose()
            },
            onClose(){
              this.$emit('close')
            },
            onSearch(){
              this.$refs.tablePage.doRefresh({ currentPage: 0, pageSize: 10 })
            },
            queryData(data){
                return pageCustomer({
                    ...data,
                    condition:{
                        isDefault:'Y',
                        searchField:this.searchField
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .search{
        display: flex;
        justify-content: flex-start;
        .el-button{
            margin-left: 20px;
        }
    }
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "im-page-modal",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "procurement-plan-update",
          attrs: {
            title: _vm.title,
            isShowBack: !!_vm.isShowBack,
            backTitle: "返回",
          },
          on: {
            "handle-back": function ($event) {
              return _vm.goBack(false)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-content" },
            [
              _c(
                "el-form",
                { ref: "form", attrs: { model: _vm.form } },
                [
                  _c("el-form-item", { attrs: { prop: "buyersIdList" } }, [
                    _c(
                      "div",
                      { staticClass: "header" },
                      [
                        _c("span", { staticClass: "label" }, [
                          _c("i", [_vm._v("*")]),
                          _vm._v("客户信息"),
                        ]),
                        _vm.editType === "add"
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: { click: _vm.chooseBuyers },
                              },
                              [_vm._v("选择客户")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "buyersDetail" },
                      [
                        _vm.buyerSelectItem.erpCode || _vm.form.buyersNo
                          ? [
                              _c("span", [
                                _vm._v(
                                  "客户编码：" +
                                    _vm._s(
                                      _vm.editType !== "add"
                                        ? _vm.form.buyersNo
                                        : _vm.buyerSelectItem.erpCode
                                    )
                                ),
                              ]),
                              _c("span", [
                                _vm._v(
                                  "客户名称：" +
                                    _vm._s(
                                      _vm.editType !== "add"
                                        ? _vm.form.buyersNm
                                        : _vm.buyerSelectItem.buyersNm
                                    )
                                ),
                              ]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "productIdList" } },
                    [
                      _c(
                        "div",
                        { staticClass: "header" },
                        [
                          _c("span", { staticClass: "label" }, [
                            _c("i", [_vm._v("*")]),
                            _vm._v("商品信息"),
                          ]),
                          _vm.editType === "add"
                            ? _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: { click: _vm.chooseGoods },
                                },
                                [_vm._v("选择商品")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-table",
                        {
                          ref: "table",
                          staticStyle: { "margin-top": "3px" },
                          attrs: { border: true, data: _vm.tableData },
                        },
                        [
                          _c(
                            "el-table-column",
                            { attrs: { align: "center", type: "index" } },
                            [
                              _c("template", { slot: "header" }, [
                                _c("i", { staticClass: "el-icon-menu" }),
                              ]),
                            ],
                            2
                          ),
                          _c("el-table-column", {
                            attrs: {
                              label: "商品编码",
                              prop: "productCode",
                              align: "center",
                              "show-overflow-tooltip": "",
                              width: "100px",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "商品名称",
                              prop: "productNm",
                              align: "center",
                              "show-overflow-tooltip": "",
                              width: "200px",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$util.getGoodsName(scope.row)
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "规格",
                              prop: "spec",
                              align: "center",
                              "show-overflow-tooltip": "",
                              width: "130px",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "单位",
                              prop: "unit",
                              align: "center",
                              "show-overflow-tooltip": "",
                              width: "100px",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "manufacturer",
                              align: "center",
                              label: "厂家(上市许可持有人)/产地",
                              "show-overflow-tooltip": "",
                              width: "230px",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.manufacturer) +
                                        _vm._s(
                                          scope.row.area
                                            ? "/" + scope.row.area
                                            : ""
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "最小购买倍数",
                              prop: "minBuyMultiple",
                              align: "center",
                              "show-overflow-tooltip": "",
                              width: "120px",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getRealMinBuyNum(scope.row)
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "当前电商价",
                              align: "right",
                              prop: "price1",
                              "show-overflow-tooltip": "",
                              width: "120px",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#F59A23" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$util.toFixed(
                                              scope.row.customerTypePriceVo[0]
                                                .price
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "报价",
                              fixed: "right",
                              align: "center",
                              prop: "price",
                              "show-overflow-tooltip": "",
                              width: "150px",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input-number", {
                                      staticStyle: { width: "120px" },
                                      attrs: {
                                        min: 0,
                                        size: "mini",
                                        controls: false,
                                        placeholder: "输入报价",
                                      },
                                      model: {
                                        value: scope.row.price,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "price", $$v)
                                        },
                                        expression: "scope.row.price",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "有效期至",
                              prop: "date",
                              fixed: "right",
                              align: "center",
                              width: "240px",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "180px" },
                                      attrs: {
                                        size: "mini",
                                        type: "datetime",
                                        placeholder: "选择日期",
                                      },
                                      model: {
                                        value: scope.row.expDate,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "expDate", $$v)
                                        },
                                        expression: "scope.row.expDate",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm.editType === "add"
                            ? _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  fixed: "right",
                                  width: "80px",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "text",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeItem(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("移除")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3919524589
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "button" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.confirmForm },
                    },
                    [_vm._v("提交报价单")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { size: "mini" }, on: { click: _vm.goBack } },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm.chooseBuyersVisible
        ? _c("choose-buyers", {
            on: {
              confirm: _vm.onChoose,
              close: function ($event) {
                _vm.chooseBuyersVisible = false
              },
            },
          })
        : _vm._e(),
      _vm.chooseGoodsVisible
        ? _c("choose-goods", {
            attrs: { "customer-item": _vm.buyerSelectItem },
            on: {
              confirm: _vm.onChooseGoods,
              close: function ($event) {
                _vm.chooseGoodsVisible = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
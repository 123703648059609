<template>
    <im-page-modal
        class="index-analysis"
        v-loading="loading"
        title="指标分析"
        :isShowBack="false"
        >
        <div class="changeYear">
            <div style="width: 120px">
                <span class="year">{{nowYear}}年</span>
                <div class="addYear" :style="active1" @click="decYear" @mouseover="mouseOver('dec')" @mouseleave="mouseLeave('dec')">
                    <i class="el-icon-arrow-left lefticon"></i>
                </div>
                <div class="addYear" :style="active2" @click="addYear" @mouseover="mouseOver('add')" @mouseleave="mouseLeave('add')">
                    <i class="el-icon-arrow-right lefticon"></i>
                </div>
            </div>
        </div>
        <el-table
          :data="sale_list"
          border
          stripe
          show-summary
          :summary-method="getTotal"
          class="tableList"
        >
          <el-table-column
            prop="saleTime"
            label="时间"
            width="60"
          >
          </el-table-column>
          <el-table-column
            prop="saleMoney"
            align="right"
            label="销售金额"
            width="130"
          >
              <template slot-scope="scope">
                  {{$util.toFixed(scope.row.saleMoney)}}
              </template>
          </el-table-column>
          <el-table-column
            prop="grossProfit"
            label="毛利"
            width="130"
          >
          </el-table-column>
          <el-table-column
            prop="grossProfitMargin"
            label="毛利率"
            width="80"
          >
          </el-table-column>
          <el-table-column
            prop="returnMoney"
            align="right"
            label="回款金额"
          >
              <template slot-scope="scope">
                  {{$util.toFixed(scope.row.returnMoney)}}
              </template>
          </el-table-column>
        </el-table>
     </im-page-modal>
</template>
<script>

export default { 
   data() {
     return {
       loading:false,
       isShowBack:false,
       sale_list: [
         {
            saleTime: '1月',
            saleMoney: 7686789.0908,
            grossProfit: 758473.9282,
            grossProfitMargin: '0.98%',
            returnMoney: 1237387.98
         },
         {
            saleTime: '2月',
            saleMoney: 7686789.0908,
            grossProfit: 758473.9282,
            grossProfitMargin: '0.98%',
            returnMoney: 1237387.98
         },
         {
            saleTime: '3月',
            saleMoney: 7686789.0908,
            grossProfit: 758473.9282,
            grossProfitMargin: '0.98%',
            returnMoney: 1237387.98
         }  
       ],
       nowYear: 2020,
       active1: '',
       active2: '',
     }
    },
    mounted(){
    },
    methods:{
        getTotal(param) {
          const { columns, data } = param;
          const sums = ['合计', 10000, 10000, 10000, 10000];
          return sums;
        }, 

        addYear() {
           this.nowYear++;
        },

        decYear() {
           this.nowYear--;
        },

        mouseOver(state) {
           if(state === 'add'){
               this.active2 = 'color: #0971EB;border:1px solid rgba(9,113,235,1);';
           }else if(state === 'dec') {
               this.active1 = 'color: #0971EB;border:1px solid rgba(9,113,235,1);';
           }
        },

        mouseLeave(state) {
           if(state === 'add'){
               this.active2 = '';
           }else if(state === 'dec') {
               this.active1 = '';
           }
        } 
    }
}
</script>
<style  lang="scss" >
   .index-analysis{
       background-color:white;
       .content{
           padding:25px 20px;
       }
   }

   .tableList {
      width: 100%;
   } 
   
   .changeYear {
      line-height: 40px;
      height:40px;
      background:rgba(241,247,254,1);
      border-radius:4px 4px 0px 0px;
      border:1px solid rgba(229,229,229,1);
   }

   .addYear {
      width: 16px;
      height: 16px;
      border-radius: 2px 0px 0px 2px;
      border: 1px solid #cccccc;
      display: inline-block;
      line-height: 38px;
      position: relative;
      top: 2px;
      left: 10px;
   }

   .year {
      font-size:18px;
      font-family:PingFangSC-Semibold,PingFang SC;
      font-weight:600;
      color:rgba(34,34,34,1);
      line-height:25px;
    //   position: absolute;
    //   top: 0px;
    //   left: 0px;
   }

   .lefticon {
      position: absolute;
      top: 1px;
      left: 1px;
      font-size: 13px;
   }

   
</style>

<template>
  <im-page-modal
      class="user-info"
      title="账户资料"
      :isShowBack="false"
  >
    <el-col class='infoitem1'>
      <el-form  label-width="80px" v-model="formLabelAlign">
        <el-form-item label="用户头像">
          <el-avatar
            shape="square"
            :size="70"
            fit="cover"
            :src="formLabelAlign.icon? formLabelAlign.icon : imgDefault"
          ></el-avatar>
        </el-form-item>
        <el-form-item label="员工号">
          <el-input v-model="formLabelAlign.employeeNo" class="show-info" readonly>d</el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="formLabelAlign.salesmanName" class="show-info" readonly></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-input v-model="formLabelAlign.userSexCode === 'MEN'? '男':formLabelAlign.userSexCode === 'WOMEN' ? '女' : '保密'" class="show-info" readonly></el-input>
        </el-form-item>
        <el-form-item label="在职状态">
          <el-input v-model="formLabelAlign.isWorking === 'Y'? '在职':'离职'" class="show-info isworking" readonly></el-input>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col class='infoitem2'>
      <el-form label-width="80px" v-model="formLabelAlign">
        <el-form-item label="联系方式">
          <el-input v-model="formLabelAlign.loginMobileId" placeholder="未绑定" class="show-info" readonly></el-input>
          <el-button class="change-phone" @click="tochangePhone(formLabelAlign.loginMobileId)">{{formLabelAlign.loginMobileId? "修改手机":"绑定手机"}}</el-button>
        </el-form-item>
        <el-form-item label="身份证号">
          <el-input v-model="formLabelAlign.identityCardNum" class="show-info" readonly />
        </el-form-item>
      </el-form>
    </el-col>
  </im-page-modal>
</template>
<script>
  import { getUserInfo } from '@/api/member-center/personal-center'
  import imgDefault from '@/assets/img/member-center/slice.png'

  export default {
    name: 'securityCenter',
    data () {
      return {
        returnSecurity: this.$route.returnSecurity,
        formLabelAlign: {
          icon: '',
          employeeNo: '',
          salesmanName: '',
          userSex: '',
          isWorking: '',
          loginMobileId: '',
          identityCardNum: ''
        },
        imgDefault
      }
    },
    mounted () {
      this.init()
    },
    methods: {
      init() {
        getUserInfo().then(res => {
          // 显示带星号的身份证
          if(res.data.identityCardNum){
            res.data.identityCardNum = String(res.data.identityCardNum).replace(/^(.{6})(?:\w+)(.{4})$/, "\$1********\$2");
          }else {
            res.data.identityCardNum = ''
          }
          console.log(res)
          this.formLabelAlign = res.data;
        })
      },

      tochangePhone(data) {
        if(data === null){
        this.$router.push({path: '/change-phone', query: {oldphone: data, origin: "memberChangePhones"}})

        }else{

        this.$router.push({path: '/change-phone', query: {oldphone: data, origin: "memberChangePhone"}})
        }
      }
    }
  }
</script>
<style lang="scss">
  .user-info {
    background-color: white;
    font-size:14px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(34,34,34,1);

    .content {
      padding: 0 20px;
    }
  }

  .infoitem1 {
    margin-top: 16px;
    display: flex;
    align-items: center;
    height: 322px;
    border-radius: 4px;
    padding: 37px 20px 0;

    /deep/ .el-form-item {
      margin-bottom: 8px;
    }

    /deep/ .el-form {
      height: 255px;
    }

    /deep/ .el-avatar {
      position: absolute;
      height: 70px;
      width: 70px;
      line-height: 70px;
      top: -37px;
      left: 10px;
    }
  }

  .infoitem2 {
    margin-top: 16px;
    display: flex;
    align-items: center;
    height: 146px;
    border-radius: 4px;
    padding: 10px 20px;

    /deep/ .el-form-item {
      margin-bottom: 8px;
    }
  }

  .change-phone {
    padding: 0px;
    position: absolute;
    width: 72px;
    height: 28px;
    font-size: 14px;
    font-family: PingFangSC-Medium,PingFang SC;
    font-weight: 500;
    color: #666666;
    line-height: 20px;
    top: 6px;
    left:130px;
  }

  .show-info {
    /deep/ .el-input__inner {
      border: none;
    }
  }

  .isworking {
    /deep/ .el-input__inner {
      font-size:14px;
      font-family:PingFangSC-Regular,PingFang SC;
      font-weight:400;
      color:rgba(250,100,0,1);
    }
  }

 .user-info {
   /deep/ .el-form-item__label {
    text-align-last: justify;
    }
 }


</style>

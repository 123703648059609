/**
 * ImSearchPad
 * @module components
 * @desc table内敛确认提示框
 * @author Chen Runrong
 * @date 2020/03/21
 * @param {Boolean} [visible] - 是否显示 支持sync修饰符
 * @param {String} [title] - 标题
 */
import imTablePage from './index.vue'

const ImTablePage = {
  install: function(Vue) {
    Vue.component('ImTablePage', imTablePage)
  }
}

export default ImTablePage

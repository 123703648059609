<template>
    <div class="shop-cart-content">
    <div class="main">
        <!--采购商信息-->
        <p class="buyer-info">
        <span v-if="userType ==='SALESMAN'" class="buyer-tag">业务员</span>
            <span v-else class="buyer-tag">采购商</span>
        <span v-if="userType ==='SALESMAN'"
              class="buyer-name">{{ accountId.buyersNm }}-{{ accountId.ettleAccountNm }}</span>
        <span v-else class="buyer-name">{{ buyersVo.buyersNm }}-{{ accountId.ettleAccountNm }}</span>
        <span v-if="shopCart.receiverName" class="buyer-contact">联系人：{{ shopCart.receiverName }}</span>
        <span v-if="shopCart.receiverName" class="buyer-contact">联系电话：{{ shopCart.receiverMobile }}</span>
        <span v-if="couponItems.length" class="buyer-discounts" @click="dialogCouponVisible = true">领取优惠券</span>
      </p>
      <!--优惠信息-->
      <ul class="discounts" v-if="shopCart.discountList.length">
        <li class="discounts-row" v-for="(item, index) in shopCart.discountList" :key="index">
          <!--满减送-->
          <div v-if="item.discountType === 'FULL_REDUCE_SEND_ACTIVITY' && item.presentList.length> 0">
            <span class="discounts-tag">自选套餐</span>
            <span class="discounts-info">{{ item.discountNm }}</span>
            <el-button v-if="item.presentList.length" type="text" size="small"
                       @click="openDialogPresentVisible2(item.discountId, item.discountNm, item.triggerItemKeys)">选购商品
            </el-button>
          </div>
          <div v-else-if="item.discountType === 'FULL_REDUCE_SEND_ACTIVITY' && item.presentList.length === 0">
            <span class="discounts-tag">满减送</span>
            <span class="discounts-info">{{ item.discountNm }}</span>
          </div>
          <!--限时折扣-->
          <div v-else-if="item.discountType === 'DISCOUNT_ACTIVITY'">
            <span class="discounts-tag">限时折扣</span>
            <span class="discounts-info">{{ item.discountNm }}</span>
          </div>
            <!--推荐优惠套餐活动-->
            <!--          <div v-else-if="item.discountType === 'RECOMMEND_PACKAGE_PACKAGE_ACTIVITY'">-->
            <!--            <span class="discounts-tag">推荐优惠套餐</span>-->
            <!--            <span class="discounts-info">{{ item.discountNm }}</span>-->
            <!--            <router-link to="/discount-package"  style="margin-left: 20px; color: #1CB7CD; font-size: 14px;">去选购组合套餐</router-link>-->
            <!--          </div>-->
            <!--加价购-->
            <div v-else-if="item.discountType === 'ADDITIONAL_ACTIVITY' && item.additionalProductList.length">
            <span class="discounts-tag">加价购</span>
            <span class="discounts-info">{{ item.discountNm }}</span>
            <el-button type="text" size="small" @click="openDialogAdditionVisible(index)">换购商品</el-button>
          </div>
          <!--特价换购-->
          <div v-else-if="item.discountType === 'SPECIAL_PRICE_PURCHASE' && item.additionalProductList.length">
            <span class="discounts-tag">特价换购</span>
            <span class="discounts-info">{{ item.discountNm }}</span>
            <el-button type="text" size="small" @click="openDialogSpecialVisible(index)">特价换购商品</el-button>
          </div>
          <el-dialog class="addition-dialog" width="680px" title="加价购" :visible.sync="dialogAdditionVisible">
            <h3>{{ addition.discountNm }}</h3>
            <div class="checkChecked">
              <el-checkbox v-for="(additional, i) in addition.additionalProductList" :key="i"
                           v-model="additional.select">
                <img :src="(additional.productPicMap && additional.productPicMap['80X80']) || productDefaultImg"
                     @error="additional.productPicMap['80X80'] = productDefaultImg" alt>
                <div class="content">
                  <div class="name">{{ $util.getGoodsName(additional) }}</div>
                  <div class="spec">{{ additional.spec }}</div>
                  <div class="manufacturer">{{ additional.manufacturer }}</div>
                  <div class="price">¥{{ additional.originalUnitPrice }}<span>¥{{ additional.salePrice }}</span></div>
                </div>
              </el-checkbox>
            </div>
            <footer>
              <p v-if="addition.additionalProductList">
                已加购{{ addition.additionalProductList.filter(res => res.select).length }}件商品
                <span>共计
                  <b>¥{{
                      addition.additionalProductList.filter(res =>
                        res.select).length ? addition.additionalProductList.filter(res =>
                        res.select).map(res => res.originalUnitPrice).reduce((n, m) => n + m) : 0
                    }}</b>
                </span>
              </p>
              <span class="add-shopping-cart" @click="addItem()" :loading='loading'>加入购物车</span>
            </footer>
          </el-dialog>

          <el-dialog class="addition-dialog" width="680px" title="特价换购" :visible.sync="dialogSpecialVisible">
            <h3>{{ special.discountNm }}</h3>
            <div class="checkChecked">
              <el-checkbox v-for="(additional, i) in special.additionalProductList" :key="i"
                           v-model="additional.select">
                <img :src="(additional.productPicMap && additional.productPicMap['80X80']) || productDefaultImg"
                     @error="additional.productPicMap['80X80'] = productDefaultImg" alt>
                <div class="content">
                  <div class="name">{{ $util.getGoodsName(additional) }}</div>
                  <div class="spec">{{ additional.spec }}</div>
                  <div class="manufacturer">{{ additional.manufacturer }}</div>
                  <div class="price">¥{{ additional.originalUnitPrice }}<span>¥{{ additional.salePrice }}</span></div>
                </div>
              </el-checkbox>
            </div>
            <footer>
              <p v-if="special.additionalProductList">
                已加购{{ special.additionalProductList.filter(res => res.select).length }}件商品
                <span>共计
                  <b>¥{{
                          special.additionalProductList.filter(res =>
                              res.select).length ? special.additionalProductList.filter(res =>
                              res.select).map(res => res.originalUnitPrice).reduce((n, m) => n + m) : 0
                      }}</b>
                </span>
              </p>
                <span class="add-shopping-cart" @click="addSpecialItem()" :loading='loading'>加入购物车</span>
            </footer>
          </el-dialog>
          <el-dialog class="addition-dialog" width="1200px" max-height="680px" title="推荐套餐" :visible.sync="dialogPackageVisible">
            <div class="package-content">
                <!--搭配套餐-->
                <swiper-package v-if="swiperData.length" :model="'more'"
                                :swiper-data="swiperData" @refresh="refreshCart"></swiper-package>
                <!--组合套餐-->
                <swiper-package v-if="FIXED_PACKAGE.length" :model="'more'" type="FIXED_PACKAGE"
                                :swiper-data="FIXED_PACKAGE" @refresh="refreshCart"></swiper-package>
                <!--分页-->
                <im-pagination
                    v-if="total"
                    :total="total"
                    :hide-on-single-page="false"
                    :current-page.sync="currentPage"
                    :page-size.sync="pageSize"
                    :hideOnSinglePage="false"
                    :isShowMainInfo="true"
                    @pagination="handleGiftsPagination"
                />
            </div>
          </el-dialog>
        </li>
      </ul>
      <el-dialog width="450px" title="特殊商品" :visible.sync="dialogPresentVisible">
        <div class="present-dialog">
          <el-popover placement="top" trigger="hover" v-for="(present,i) in presentList" :key="i">
            <p style="padding: 5px 8px">{{ present.presentNm || present.productNm }}</p>
            <img :src="(present.productPicMap && present.productPicMap['48X48']) || productDefaultImg"
                 @error="present.productPicMap['48X48'] = productDefaultImg" slot="reference" alt>
          </el-popover>
        </div>
      </el-dialog>
      <el-dialog width="1000px" :title="giftsData.length?giftsData[0].discountNm: '自选套餐'" :visible.sync="dialogGiftsVisible">
        <div class="gifts-dialog" v-loading="giftsDataLoading">
          <swiper-package2 v-if="giftsData.length" :model="'more'"
                           :swiper-data="giftsData" @openGifts="handleOpenGiftsDialog"></swiper-package2>
<!--          <im-pagination-->
<!--              v-if="giftsParams.total"-->
<!--              :total="giftsParams.total"-->
<!--              :hide-on-single-page="false"-->
<!--              :current-page.sync="giftsParams.currentPage"-->
<!--              :page-size.sync="giftsParams.pageSize"-->
<!--              layout="prev, pager, next, jumper"-->
<!--              :hideOnSinglePage="false"-->
<!--              :isShowMainInfo="true"-->
<!--              @pagination="handleGiftsPagination"-->
<!--          />-->
        </div>
      </el-dialog>
      <el-dialog width="450px" title="优惠券" class="coupon-el-dialog__body" :visible.sync="dialogCouponVisible">
        <div class="coupon-dialog" v-for="(item, index) in couponItems" :key="index">
          <div class="flex">
            <div class="name" v-if="item.couponType === 'FULL_REDUCTION'">
              <div class="left-con"></div>
              <p>¥{{ item.fullReductionAmount }}</p>
              <div class="right-con"></div>
            </div>
            <div class="name" v-else>
              <div class="left-con"></div>
              <p>{{ item.discount }}折</p>
              <div class="right-con"></div>
            </div>
            <div class="text">
              <div class="text_1">{{ item.couponNm }}</div>
              <div class="text_2" v-if="item.useTimeType === 'IN_THE_DAY'">领券当日起{{ item.useValidDay }}天内可用</div>
              <div class="text_2" v-else-if="item.useTimeType === 'TIMES_OF_THE_DAY'">领券次日起{{ item.useValidDay }}天内可用
              </div>
              <div class="text_2" v-else>{{ item.useStartTime }} - {{ item.useEndTime }}</div>
            </div>
          </div>
<!--          <div class="button"><p @click="onCoupon(item)">立即领券</p></div>-->
          <div class="button" @click="onCoupon(item)"
               v-if="item.isLimitTimes === 'Y' && item.userReceivedQuantity < item.limitNumber && item.remainQuantity > 0 && item.userReceivedQuantity < item.remainQuantity">
            <p>立即领券</p>
          </div>
          <div class="button" @click="onCoupon(item)"
               v-if="item.isLimitTimes === 'N' && item.remainQuantity > 0">
            <p>立即领券</p>
          </div>
          <div class="button isReceive" v-else-if="item.userReceivedQuantity >= item.limitNumber && item.userReceivedQuantity > 0">
            <p>已领取</p>
          </div>
          <div class="button disabled" v-else-if="item.remainQuantity <= 0 && item.userReceivedQuantity <= 0">
            <p>已抢光</p>
          </div>
        </div>
      </el-dialog>
      <!--table列表-->
      <el-table ref="multipleTable" border :data.sync="shopCart.itemList" header-row-class-name="header-row"
                :class="{'is-tabledata-empty':shopCart.itemList.length === 0}"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                row-key="rowKey"
                :row-class-name="rowClassNameNormal"
                :span-method="spanMethodNormal"
                default-expand-all
                @select-all="handleSelectionAll"
                @selection-change="(val)=>{selectItem = val}"
                @select="handleSelectionChange"
      >
        <el-table-column type="index" class-name="index" align="center" label="序号" width="45"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.recommend ==='Y'"></span>
            <span v-else>{{ scope.row.number || 0 }}</span>
          </template>
        </el-table-column>
        <el-table-column type="selection" class-name="selection" align="center" width="45"
                         show-overflow-tooltip>
          <template slot-scope="scope">
<!--            <div v-if="!scope.row.isGroup">-->
<!--              <el-checkbox v-model="scope.row.selected" @change="handleSelectionChange('', scope.row)"/>-->
<!--            </div>-->
<!--            <div v-else>-->
<!--              <div class="selection" v-if="scope.row.shoppingCartItemType === 'ADDITIONAL' || scope.row.shoppingCartItemType === 'SPECIAL_PRICE_PURCHASE'">-->
<!--                <el-checkbox v-model="scope.row.selected" @change="handleSelectionChange('', scope.row)"/>-->
<!--              </div>-->
<!--              <div class="selection" v-else>-->
<!--                <el-checkbox v-model="scope.row.selected" @change="handleSelectionChange('', scope.row)"/>-->
<!--                <span v-for="item in scope.row.itemDetailList" class="span"></span>-->
<!--              </div>-->
<!--            </div>-->
            <div>
              <el-checkbox v-if="scope.row.recommend !== 'Y'" :disabled="notButton" :class="scope.row.selected && notButton ? 'disabled-checkbox' : ''" v-model="scope.row.selected" @change="handleSelectionChange('', scope.row)"/>
            </div>
          </template>
        </el-table-column>
        <el-table-column class-name="padding" label="商品图" width="80px" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-if="!scope.row.isGroup" class="commodity-image">
              <router-link :to="'/detail/'+scope.row.productId">
                <img class="product-img"
                     :src="(scope.row.productPicMap && scope.row.productPicMap['48X48']) || productDefaultImg"
                     @error="scope.row.productPicMap['48X48'] = productDefaultImg"
                     alt>
              </router-link>
<!--              <div v-if="scope.row.tagList.length && scope.row.tagList.findIndex(res => res.tag === '历史购买') !== -1"-->
<!--                   class="purchase-history-tag">历史购买-->
<!--              </div>-->
            </div>
            <div class="set-meal" v-else>
              <div class="meal-center">
                <span class="fixed" v-if="scope.row.shoppingCartItemType === 'FIX_DISCOUNT_PACKAGE'">【固定套餐】</span>
                <span class="discounts-tag" v-else-if="scope.row.shoppingCartItemType === 'ADDITIONAL'">超值换购</span>
                <span class="discounts-tag" v-else-if="scope.row.shoppingCartItemType === 'SPECIAL_PRICE_PURCHASE'">特价换购</span>
                <span class="discounts-tag" v-else-if="scope.row.recommend === 'Y'">推荐优惠套餐</span>
                <span class="discounts-tag" v-else-if="scope.row.shoppingCartItemType === 'FULL_GIFT'">自选套餐</span>
                <span class="dapei" v-else>【搭配套餐】</span>
                {{ scope.row.activityNm }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商品编码" width="70px" align="center" show-overflow-tooltip class-name="visibility">
          <template slot-scope="scope">
            <div v-if="!scope.row.isGroup">
              {{ scope.row.productCode }}
            </div>
            <div v-else-if="scope.row.recommend === 'Y'">
                <el-button @click="openDialogPackageVisible(scope.row.drugNm2)" size="mini">选套餐</el-button>
            </div>
            <div class="meal-right" v-else>
              <div v-if="scope.row.shoppingCartItemType !== 'ADDITIONAL' && scope.row.shoppingCartItemType !== 'SPECIAL_PRICE_PURCHASE' && scope.row.shoppingCartItemType !== 'FULL_GIFT'">
                套餐价：
                <span class="price">
                  ¥{{ scope.row.saleTotalPrice }}<s>¥{{ scope.row.originalTotalPrice }}</s>
                </span>
              </div>
              <div v-else-if="scope.row.shoppingCartItemType === 'FULL_GIFT'">
                活动价：
                <span class="price">¥{{ setTotal(scope.row.children) }}</span>
              </div>
              <div v-if="scope.row.shoppingCartItemType === 'FULL_GIFT' && (scope.row.children ? scope.row.children[0].isEditGiftPackage: '')" style="margin-right: 80px">
                <el-button @click="handleEdit(scope.row, scope.$index)" size="mini">编辑</el-button>
              </div>
              <div v-else-if="scope.row.shoppingCartItemType === 'FULL_GIFT' && (scope.row.children ? !scope.row.children[0].isEditGiftPackage: '')" style="margin-right: 80px">
                <el-button @click="openDialoggiftsVisible(scope.row.children, scope.row.activityId, scope.row.itemKey)" size="mini">保存</el-button>
                <el-button @click="handleCancle(scope.row, scope.$index)" size="mini">取消</el-button>
              </div>
              <el-input-number
                  v-if="scope.row.shoppingCartItemType !== 'ADDITIONAL' && scope.row.shoppingCartItemType !== 'SPECIAL_PRICE_PURCHASE' && scope.row.shoppingCartItemType !== 'FULL_GIFT'"
                  :min="1"
                  size="mini"
                  v-model="scope.row.quantity"
                  @change="handleChange(scope.row)"
                  class="input"/>
              <el-button @click="openDeleteDialog(scope.row.itemKey)" size="mini">删除</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="productNm" class-name="visibility" label="商品名称" width="160px">
          <template slot-scope="scope">
            <div v-if="!scope.row.isGroup" class="table-product-name">
              <p>
                <span class="row-tag tag-otc"
                    :class="{ 'jiaOtc': scope.row.otcTypeNm === '甲OTC', 'yiOtc': scope.row.otcTypeNm === '乙OTC', 'rxOtc': scope.row.otcTypeNm === 'RX' }"
                    v-if="scope.row.otcTypeNm && scope.row.otcTypeNm !== '其他'"
                >{{ scope.row.otcTypeNm }}</span>
                <router-link :to="'/detail/'+scope.row.productId">{{$util.getGoodsName(scope.row)}}</router-link>
              </p>
              <tagList :taglist='scope.row.tagList'></tagList>
            </div>
<!--            <div class="meal-right" v-else>-->
<!--              <div v-if="scope.row.shoppingCartItemType !== 'ADDITIONAL'">-->
<!--                套餐价：-->
<!--                <span class="price">-->
<!--                  ¥{{ scope.row.originalTotalPrice }}<s>¥{{ scope.row.saleTotalPrice }}</s>-->
<!--                </span>-->
<!--                <el-input-number :min="1" size="mini" v-model="scope.row.quantity" @change="handleChange(scope.row)"-->
<!--                                 class="input"/>-->
<!--              </div>-->
<!--              <el-button @click="openDeleteDialog(scope.row.itemKey)" size="mini">删除</el-button>-->
<!--            </div>-->
          </template>
        </el-table-column>
        <el-table-column prop="spec" align="center" label="规格" width="70px"/>
        <el-table-column prop="manufacturer" align="left" label="厂家(上市许可持有人)/产地" width="170px"/>
        <el-table-column prop="unit" align="center" label="单位" width="56px" show-overflow-tooltip/>
        <el-table-column prop="rate" align="center" label="销项税" width="56px" show-overflow-tooltip/>
        <el-table-column prop="stock" align="center" label="库存" width="60px" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.stock && scope.row.recommend !== 'Y'" :class="{'inventory-warning':scope.row.stock == '<100' || Number(scope.row.stock) < 100 }">{{ Math.floor(scope.row.stock) || scope.row.stock }}</span>
<!--            <span v-else-if="scope.row.stock && Number(scope.row.stock) < 100" class="inventory-warning">{{ scope.row.stock }}</span>-->
<!--            <span v-else>{{scope.row.stock}}</span>-->
          </template>
        </el-table-column>
<!--        <el-table-column prop="expDate" align="center" v-if="userType === 'BUYERS'" label="最近/远效期" width="200px" show-overflow-tooltip/>-->
        <el-table-column align="center" label="最小购买倍数" width="90px" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ getRealMinBuyNum(scope.row) || '/' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="采购数量" width="112px" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-if="scope.row.shoppingCartItemType !== 'NORMAL' && scope.row.shoppingCartItemType !== 'ADDITIONAL' && scope.row.shoppingCartItemType !== 'SPECIAL_PRICE_PURCHASE' && scope.row.recommend !== 'Y' && scope.row.shoppingCartItemType !== 'PRESENT'">
              {{ scope.row.quantity }}
            </div>
            <el-input-number
                v-else-if="scope.row.shoppingCartItemType === 'NORMAL' && scope.row.isMainProduct !== 'Y' || scope.row.shoppingCartItemType === 'SPECIAL_PRICE_PURCHASE' || scope.row.shoppingCartItemType === 'ADDITIONAL'"
                size="mini"
                @focus="changeNum(true)"
                @blur="changeNum(false)"
                v-model="scope.row.quantity"
                :precision="getDecimalPointNum(scope.row)"
                @change="handleChange(scope.row)"
                :min="getRealMinBuyNum(scope.row)"
                :step="getRealMinBuyNum(scope.row)"
                step-strictly/>
            <div v-if="scope.row.shoppingCartItemType === 'PRESENT' && scope.row.isMainProduct === 'N'">
              {{ scope.row.quantity }}
            </div>
            <el-input-number
                v-else-if="scope.row.shoppingCartItemType === 'NORMAL' && scope.row.isMainProduct === 'Y'"
                size="mini"
                :disabled="scope.row.isEditGiftPackage"
                @focus="changeNum(true)"
                @blur="changeNum(false)"
                v-model="scope.row.quantity"
                :precision="getDecimalPointNum(scope.row)"
                :min="getRealMinBuyNum(scope.row)"
                :step="getRealMinBuyNum(scope.row)"
                step-strictly/>
          </template>
        </el-table-column>
        <el-table-column align="right" label="单价" min-width="75px" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-if="scope.row.salePrice !== scope.row.originalUnitPrice">
              ¥{{ scope.row.originalUnitPrice }}<br/>
              <span v-if="scope.row.byDiscount !== false" style="text-decoration: line-through;color: #999">¥{{ scope.row.salePrice }}</span>
            </div>
            <div v-else>¥{{ scope.row.salePrice }}</div>
          </template>
        </el-table-column>
        <el-table-column align="right" label="小计" min-width="75px">
          <template slot-scope="scope">
<!--            <span style="color: #FF3B30">¥{{ (scope.row.originalTotalPrice * 1).toFixed(2) }}</span>-->
            <span style="color: #FF3B30">¥{{ formattedNumber(scope.row.originalTotalPrice) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="expDate" label="最近效期" min-width="80px">
          <template slot-scope="scope">
            <span>{{ scope.row.expDate?scope.row.expDate:'无' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" width="70" label="操作" fixed="right">
          <template slot-scope="scope">
              <div class="table-operation" v-if="scope.row.shoppingCartItemType !== 'FIX_DISCOUNT_PACKAGE' && scope.row.shoppingCartItemType !== 'COLLOCATE_DISCOUNT_PACKAGE' && scope.row.isMainProduct !== 'Y' && scope.row.shoppingCartItemType !== 'PRESENT'">
                <span v-if="userType !=='SALESMAN'"
                      @click="starProduct(scope.row)">{{ scope.row.isCollect === 'Y' ? '已收藏' : '收藏' }}</span>
                <span @click="openDeleteDialog(scope.row.itemKey)">删除</span>
              </div>
              <!--            <div class="table-operation" v-else-if="scope.row.shoppingCartItemType === 'RECOMMEND_PACKAGE_PACKAGE_ACTIVITY'">-->
              <!--              <span @click="openSelectedDialog(scope.row.itemKey)">选组合套餐</span>-->
              <!--            </div>-->
              <div class="table-operation" v-else-if="!scope.row.isEditGiftPackage">
                <span @click="handleDelete(scope.row, scope.$index)">删除</span>
              </div>
              <div v-else>/</div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 购物车为空替换 -->
      <div v-if="shopCart.itemList.length === 0" class="empty-default">
        <div class="empty-box">
          <el-image style="width: 160px; height: 160px" :src="emptyDefault" fit="fill"/>
          <p class="empty-tip">购物车为空，你可以去首页逛逛~</p>
          <router-link to="/"><el-button type="primary" size="mini">去首页逛逛</el-button></router-link>
        </div>
      </div>
      <!--失效商品-->
      <div v-if="shopCart.invalidItemList.length">
        <p class="disableText">已失效商品<span @click="onInvalidItem()">清空失效商品</span></p>
        <el-table class="disableTable"
                  header-row-class-name="header-row"
                  :span-method="spanMethodDisable"
                  ref="disableTable"
                  border
                  :data.sync="shopCart.invalidItemList"
                  :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                  row-key="rowKey"
                  :row-class-name="rowClassNameDisable"
                  default-expand-all>
          <el-table-column type="index" class-name="index" align="center" label="序号" width="45"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.number || 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column type="selection" class-name="selection" align="center" width="45"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="!scope.row.isGroup">
                失效
              </div>
              <div v-else>
                <div class="selection" v-if="scope.row.shoppingCartItemType === 'ADDITIONAL' || scope.row.shoppingCartItemType === 'SPECIAL_PRICE_PURCHASE'">
                  <el-checkbox :disabled="true" v-model="scope.row.selected"/>
                </div>
                <div class="selection" v-else>
                  <el-checkbox :disabled="true" v-model="scope.row.selected"/>
                  <span v-for="item in scope.row.itemDetailList" class="span"></span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column class-name="padding" label="商品图" align="center" width="80px" show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="!scope.row.isGroup" class="commodity-image">
                <img class="product-img"
                     :src="(scope.row.productPicMap && scope.row.productPicMap['48X48']) || productDefaultImg"
                     @error="scope.row.productPicMap['48X48'] = productDefaultImg" alt>
<!--                <div v-if="scope.row.tagList.length && scope.row.tagList.findIndex(res => res.tag === '历史购买') !== -1"-->
<!--                     class="purchase-history-tag">历史购买-->
<!--                </div>-->
              </div>
              <div class="set-meal" v-else>
                <div class="meal-center">
                  <span class="fixed" v-if="scope.row.shoppingCartItemType === 'FIX_DISCOUNT_PACKAGE'">【固定套餐】</span>
                  <span class="discounts-tag" v-else-if="scope.row.shoppingCartItemType === 'ADDITIONAL'">超值换购</span>
                  <span class="discounts-tag" v-else-if="scope.row.shoppingCartItemType === 'SPECIAL_PRICE_PURCHASE'">特价换购</span>
                  <span class="discounts-tag" v-else-if="scope.row.shoppingCartItemType === 'RECOMMEND_PACKAGE_PACKAGE_ACTIVITY'">推荐套餐</span>
                  <span class="dapei" v-else>【搭配套餐】</span>
                  {{ scope.row.activityNm }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="productCode" label="商品编码" width="70px" align="center" class-name="visibility" show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="!scope.row.isGroup">
                {{ scope.row.productCode }}
              </div>
              <div class="meal-right" v-else>
                <div v-if="scope.row.shoppingCartItemType !== 'ADDITIONAL' && scope.row.shoppingCartItemType !== 'SPECIAL_PRICE_PURCHASE'">
                  套餐价：
                  <span class="price">
                  ¥{{ scope.row.saleTotalPrice }}<s>¥{{ scope.row.originalTotalPrice }}</s>
                  </span>
                  <el-input-number size="mini" v-model="scope.row.quantity" :disabled="true" class="input"/>
                </div>
                <el-button @click="openDeleteDialog(scope.row.itemKey, 'disable')" size="mini">删除</el-button>
              </div>
            </template>
          </el-table-column>
<!--          <el-table-column prop="drugNm" label="通用名称" width="100px" show-overflow-tooltip fixed />-->
          <el-table-column prop="productNm" class-name="visibility" label="商品名称" width="160px"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="!scope.row.isGroup" class="table-product-name">
                <p>
                  <span
                    class="row-tag tag-otc"
                    :class="{ 'jiaOtc': scope.row.otcTypeNm === '甲OTC', 'yiOtc': scope.row.otcTypeNm === '乙OTC', 'rxOtc': scope.row.otcTypeNm === 'RX' }"
                    v-if="scope.row.otcTypeNm && scope.row.otcTypeNm !== '其他'">{{ scope.row.otcTypeNm }}</span>
                  {{$util.getGoodsName(scope.row)}}
                </p>
                <tagList :taglist='scope.row.tagList'></tagList>
                <span v-if="userType =='SALESMAN'">
                  失效原因:{{ scope.row.invalidReason }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="spec" align="center" label="规格" width="70px" show-overflow-tooltip/>
          <el-table-column prop="manufacturer" align="left" label="厂家(上市许可持有人)/产地" width="170px"/>
          <el-table-column prop="unit" align="center" label="单位" width="56px" show-overflow-tooltip/>
          <el-table-column prop="rate" align="center" label="销项税" width="56px" show-overflow-tooltip/>
          <el-table-column prop="stock" align="center" label="库存" width="60px" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.stock && scope.row.stock == '<100'" class="inventory-warning"><100</span>
              <span v-else-if="scope.row.stock && Number(scope.row.stock) < 100" class="inventory-warning">{{ scope.row.stock }}</span>
              <span v-else>{{scope.row.stock}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="minBuyMultiple" align="center" label="最小购买倍数" width="90px"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getRealMinBuyNum(scope.row) || '/' }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="采购数量" width="112px" show-overflow-tooltip>
            <template slot-scope="scope">
              <div
                  v-if="scope.row.shoppingCartItemType !== 'NORMAL' && scope.row.shoppingCartItemType !== 'ADDITIONAL' && scope.row.shoppingCartItemType !== 'SPECIAL_PRICE_PURCHASE'">
                {{ scope.row.quantity }}
              </div>
              <el-input-number
                  v-else
                  :disabled="true"
                  size="mini"
                  v-model="scope.row.quantity"/>
            </template>
          </el-table-column>
<!--          <el-table-column prop="midPackTotal" align="center" label="中/件包装" width="90px" show-overflow-tooltip/>-->
          <el-table-column prop="salePrice" align="right" label="单价" min-width="75px"/>
          <el-table-column align="right" label="小计" min-width="75">
            <template slot-scope="scope">
<!--              <span style="color: #FF3B30">¥{{ (scope.row.originalTotalPrice * 1).toFixed(2) }}</span>-->
              <span style="color: #FF3B30">¥{{ formattedNumber(scope.row.originalTotalPrice) }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="expDate" label="最近效期" min-width="80px">
            <template slot-scope="scope">
              <span>{{ scope.row.expDate?scope.row.expDate:'无' }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" width="70" label="操作" fixed="right">
            <template slot-scope="scope">
              <div class="table-operation"
                   v-if="scope.row.shoppingCartItemType !== 'FIX_DISCOUNT_PACKAGE' && scope.row.shoppingCartItemType !== 'COLLOCATE_DISCOUNT_PACKAGE'">
                <span v-if="userType !=='SALESMAN'" @click="starProduct(scope.row)">{{ scope.row.isCollect === 'Y' ? '已收藏' : '收藏' }}</span>
                <span @click="openDeleteDialog(scope.row.itemKey, 'disable')">删除</span>
              </div>
                <!--              <div class="table-operation" v-else-if="scope.row.shoppingCartItemType === 'RECOMMEND_PACKAGE_PACKAGE_ACTIVITY'">-->
                <!--                 <span @click="openSelectedDialog(scope.row.itemKey)">选组合套餐</span>-->
                <!--              </div>-->
                <div v-else>/</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--删除-->
      <el-dialog :visible.sync="dialogDeleteVisible" title="提示" width="400px" top="30vh">
        <img class="icon" src="@/assets/img/customer-service/icon-shanchu.png" alt>
        <p style="padding: 10px 0 20px" v-if="itemKey === 'all'">确认清空购物车？</p>
        <p style="padding: 10px 0 20px" v-else>{{ itemKey ? '确认删除此商品？' : '确认删除所选商品？' }}</p>
        <el-button @click="deleteRow(itemKey)" type="primary" class="agree" size="small">确认</el-button>
        <el-button @click="dialogDeleteVisible = false" class="agree" size="small">取消</el-button>
      </el-dialog>
    </div>
    <!--结算-->
    <div style="background-color: #fff;padding-bottom: 10px">
      <div class="settlement-container">
        <div class="settlement-row">
          <div class="row-left">
<!--            <el-checkbox v-if="shopCart.itemList.length" v-model="checked" @change="toggleAllSelection">全选</el-checkbox>-->
            <el-checkbox :class="checked && notButton ? 'disabled-checkbox' : ''" v-if="shopCart.itemList.length" :disabled="notButton" v-model="checked" @change="toggleAllSelection">全选</el-checkbox>

            <span v-if="shopCart.itemList.length" @click="openDeleteDialog()">删除选中商品</span>
            <span v-if="shopCart.itemList.length" @click="openDeleteDialog('all')">清空购物车</span>
            <router-link to="/"><span>返回首页</span></router-link>
          </div>
          <div class="row-right">
            <div class="settlement">
              <div class="total-money">
                商品总金额：
                <span class="money">¥{{ $util.toFixed(shopCart.productTotalAmount) }}</span>
              </div>
              <div class="product-detail">
                <span v-if="shopCart.presentTotalQuantity > 0">特殊商品：{{ shopCart.presentTotalQuantity }}件<el-button
                  type="text" size="small"
                  @click="openDialogPresentVisible()">查看特殊商品</el-button></span>
                <span>商品种类：{{ shopCart.productTypeTotalQuantity }}种</span>
                <span>总件数：{{ shopCart.productTotalQuantity }}</span>
                <span>赠送积分：{{ shopCart.obtainTotalIntegral }}</span>
                <span>促销优惠减免：-¥{{ shopCart.discountAmount }}</span>
              </div>
            </div>
            <div class="settlement-button-group">
              <button
                class="immediate-settlement"
                v-loading='notButton'
                :disabled="notButton"
                :class="{'disable': shopCart.enableSubmit === 'N' || selectItem.length == 0 }"
                @click="handleCollateOrder"
              >{{ shopCart.enableSubmit === 'N' ? `差${ shopCart.balance }元起送` : '立即结算' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <swiper class="recommend-swiper"/>

    <el-dialog
            title="以下商品库存不足，为您自动调整购买数量"
            :visible.sync="showLessStockModal"
            width="800px"
            :before-close="hideDialog"
            >
      <el-table :data="lessStockShops" max-height="500px">
        <el-table-column label="序号" align="center" prop="id"></el-table-column>
        <el-table-column align="center" prop="productNm" label="商品名称">
          <template slot-scope="scope">
              {{$util.getGoodsName(scope.row)}}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="productCode" label="商品编号"></el-table-column>
        <el-table-column prop="stockQuantity" align="center" label="库存" v-if="userType !== 'BUYERS' "></el-table-column>
        <el-table-column align="center" label="调整前数量">
          <template slot-scope="scope">
              {{(beforeChangeLessCart.find(item=>item.productId === scope.row.productId)).quantity}}
          </template>
        </el-table-column>
        <el-table-column prop="quantity" align="center" label="调整后数量"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="showLessStockModal = false">确 定</el-button>
      </span>
    </el-dialog>
    <giftsDialog ref="giftsDialogRef" @success="getCart"></giftsDialog>
  </div>
</template>

<script>
import tagList from '@/components/tagList/tagList'
import {
    clearInvalidItem,
    getCart,
    getSettlement,
    removeItem,
    updateAllSelected,
    updateQuantity,
    updateSelected
} from '@/api/shop-cart'
import {addItem, saveOrUpdateProductCollect, batchAddItem} from '@/api/detail'
import { pageDiscountPackage, getFullGiftsList, getPageActivityProduct, saveFullGiftDetailAndAddToCart } from '@/api/promotion-zone'
import {pageCouponList, saveCoupon} from '@/api/index/index'
import productDefaultImg from '@/assets/img/index/product_default.png'
import {getBus} from '@/utils/auth'
import {mapGetters} from 'vuex'
import emptyDefault from '@/assets/img/member-center/emptycart.png'
import Swiper from '@/components/ForRcommend'
import addToCartMixin from '@/common/addToCart'
import SwiperPackage from '../../detail/components/swiper-package'
import SwiperPackage2 from '@/views/shopping-cart/components/swiper-package'
import giftsDialog from '@/views/promotion-zone/discount-package/components/gifts-dialog'
import { toThousands, removeCommas } from '@/utils'

export default {
    name: 'ShoppingCartList',
    mixins: [addToCartMixin],
    components: {
        SwiperPackage2,
        Swiper,
        tagList,
        SwiperPackage,
        giftsDialog,
    },
    data() {
        return {
            loading: false,
            productDefaultImg,
            itemKey: '',
            shopCart: {
              itemList: [],
              discountList: [],
              invalidItemList: []
            },
            notButton:false,
            selectItem: [],
            checkedArr: [],
            couponItems: [],
            present: {},
            presents: [],
            presentList: [],
            addition: {},
            special: {},
            checked: true,
            paymentWayList: [],
            dialogDeleteVisible: false,
            dialogCouponVisible: false,
            dialogAdditionVisible: false,
            dialogPresentVisible: false,
            dialogGiftsVisible: false, // 自选套餐
            dialogSpecialVisible: false,
            dialogPackageVisible: false,
            buyersReceiverAddrId: '',
            OrderIndexArr: [],
            emptyDefault,
            disabledPay: false,
            beforeChangeLessCart: [],
            lessStockShops: [],
            showLessStockModal: false,
            businessIndex: 0,
            pageSize: 10,
            total: 0,
            currentPage: 1,
            swiperData:[],
            selectedName: '',
            FIXED_PACKAGE: [],
            isEditGiftPackage: true,
            giftsData: [],
            giftsDataLoading: true,
            giftsParams: {
              pageSize: 200,
              total: 0,
              currentPage: 1,
              discountNm: '',
              id: '',
              triggerItemKeys: []
            }
        }
    },
    mounted () {
      this.businessIndex = this.allBusiness.findIndex(item => item.orgId === this.org.orgId)
      this.getCart().then(_ => {
        // 结算页刷新重新回到结算页
        if (sessionStorage.getItem('collate')) {
          this.handleCollateOrder()
          sessionStorage.removeItem("collate")
        }
      })
      this.getCoupon()
    },
    methods: {
        handlePagination (data) {
            this.currentPage = Math.min(this.currentPage, Math.ceil(this.total / data.pageSize))
            this.getProduct()
        },
      handleGiftsPagination (data) {
        this.giftsParams.currentPage = Math.min(this.giftsParams.currentPage, Math.ceil(this.giftsParams.total / data.pageSize))
        this.getOptionalProduct()
      },
      // 清空失效商品
      onInvalidItem () {
        clearInvalidItem().then(res => {
          const { msg } = res
          if (msg === '操作成功') this.$message.success(msg)
          this.cartData(res)
        })
      },
      // 领取优惠券
      onCoupon (item) {
        saveCoupon({
          ...item,
          fullAmount:Number(item.fullAmount),
          fullReductionAmount:Number(item.fullReductionAmount)
        }).then(res => {
          const { msg } = res
          if (msg === '保存成功') this.$message.success('领取成功')
          this.getCoupon()
        })
        this.dialogCouponVisible = false
      },
      // 获取优惠券
      getCoupon () {
        pageCouponList({
          pageNum: 1,
          pageSize: 500,
          condition: { couponNm: '', couponStatus: '进行中', isShow: 'Y', couponType: '' }
        }).then(res => {
          function sub (str) {
            return str.substring(0, str.length - 3)
          }

          let coupon = res.data.records
          if (coupon && coupon.length) {
            coupon.forEach((data, i) => {
              coupon[i].fullAmount = sub(coupon[i].fullAmount.replace(/,/g, ''))
              coupon[i].fullReductionAmount = coupon[i].fullReductionAmount ? sub(coupon[i].fullReductionAmount.replace(/,/g, '')) : coupon[i].fullReductionAmount
              coupon[i].useStartTime = coupon[i].useStartTime ? coupon[i].useStartTime.replace(/-/g, '.').split(' ')[0] : '未知'
              coupon[i].useEndTime = coupon[i].useEndTime ? coupon[i].useEndTime.replace(/-/g, '.').split(' ')[0] : '未知'
            })
          }
          this.couponItems = coupon
        })
      },
        openDialogAdditionVisible(index) {
            this.addition = this.shopCart.discountList[index]
            this.dialogAdditionVisible = true
        },
        openDialogSpecialVisible(index) {
            this.special = this.shopCart.discountList[index]
            this.dialogSpecialVisible = true
        },
        // 打开套餐弹窗
        openDialogPackageVisible(drugNm) {
            // this.special = this.shopCart.discountList[index]
            this.selectedName = drugNm
            this.dialogPackageVisible = true
            this.getProduct()
        },
        // 自选套餐按钮
        openDialogPresentVisible(list) {
            this.presentList = list || this.presents
            this.dialogPresentVisible = true
        },
        async openDialogPresentVisible2(id, discountNm, triggerItemKeys) {
          this.giftsParams.id = id
          this.giftsParams.discountNm = discountNm
          this.giftsParams.triggerItemKeys = triggerItemKeys
          await this.getOptionalProduct()
          this.dialogGiftsVisible = true
        },
        // 获取套餐列表
        getProduct () {
            pageDiscountPackage({
                pageNum: this.currentPage,
                pageSize: this.pageSize,
                condition: {
                    isPc: 'Y',
                    orgId:getBus().orgId,
                    activityNm: this.selectedName
                }
            }).then(res => {
                const { data } = res
                data.records.sort(function(a,b){
                    return a.endTime > b.endTime ? 1 : -1
                })
                // this.swiperData = data.records
                if (data && data.records) {
                    let fix = data.records.filter(res => res.discountPackageType === 'FIXED_PACKAGE')
                    let coll = data.records.filter(res => res.discountPackageType === 'COLLOCATION_PACKAGE')
                    coll.forEach((data, index) => {
                        let list = data.productMessageList
                        if (!list.length) {
                            coll.splice(index, 1)
                        } else {
                            list.forEach(item => {
                                item.retailPrice = toThousands(item.retailPrice)
                                item.discountPackagePrice = toThousands(item.discountPackagePrice)
                                item.select = false
                            })
                            list[0].select = true
                            data.total = toThousands(removeCommas(list[0].salePrice)*list[0].quantity)
                            data.packagePrice = toThousands(removeCommas(list[0].discountPackagePrice)*list[0].quantity)
                            data.select = 1
                        }
                    })
                    // this.COLLOCATION_PACKAGE = coll
                    fix.forEach((data, index) => {
                        let total = 0
                        let list = data.productMessageList
                        if (!list.length) {
                            fix.splice(index, 1)
                        } else {
                            list.forEach(item => {
                                total += item.salePrice ? removeCommas(item.salePrice)*item.quantity : 0
                                item.price = toThousands(item.price)
                            })
                            data.total = toThousands(total)
                        }
                    })
                    // this.FIXED_PACKAGE = fix

                    this.swiperData = [...fix,...coll]
                }
                this.total = data.total
            })
        },
        // 刷新购物车
        refreshCart() {
            this.getCart()
            this.dialogPackageVisible = false
        },
        // 加入购物车
        addItem() {
            this.loading = true;
            let skuIdList = this.addition.additionalProductList.filter(res => res.select).map(res => res.skuId)
            if (skuIdList.length) {
                addItem({objId: this.addition.discountId, itemType: 'ADDITIONAL', quantity: 1, skuIdList}).then(res => {
            const { code } = res
            if (code === '0') {
              this.loading = false;
              this.$message.success('加入成功！')
              this.cartData(res)
              this.dialogAdditionVisible = false
            }
          }).catch(error => {
            this.loading = false;
            this.getCart();
            this.$message.error(error.msg);
          })
        } else {
          this.loading = false;
          this.$message.warning('请选择加价购商品')
        }
      },
      addSpecialItem () {
        this.loading = true;
        let skuIdList = this.special.additionalProductList.filter(res => res.select).map(res => res.skuId)
        if (skuIdList.length) {
          addItem({ objId: this.special.discountId, itemType: 'SPECIAL_PRICE_PURCHASE', quantity: 1, skuIdList }).then(res => {
            const { code } = res
            if (code === '0') {
              this.loading = false;
              this.$message.success('加入成功！')
              this.cartData(res)
              this.dialogSpecialVisible = false
            }
          }).catch(error => {
            this.loading = false;
            this.getCart();
            this.$message.error(error.msg);
          })
        } else {
          this.loading = false;
          this.$message.warning('请选择特价换购商品')
        }
      },
      // 收藏
      starProduct (row) {
        sensors.track("CartEdit", {
					"userAction": 'collect'
				});
        let type = row.isCollect === 'Y' ? 'remove' : 'save'
        saveOrUpdateProductCollect({
          userId: getBus().buyersId,
          productId: row.productId,
          type
        }).then(res => {
          const { msg } = res
          this.getCart()
          this.$message.success(msg)
        }).catch(error => {
          this.getCart()
        })
      },
      openDeleteDialog (itemKey, disabled) {
        this.dialogDeleteVisible = true
        this.itemKey = itemKey
        this.deleteType = disabled || ''
      },
      // 删除购物车项
      deleteRow (itemKey) {
        sensors.track("CartEdit", {
					"userAction": 'delete'
				});
        if (!itemKey && !this.checkedArr.length) return
        this.disabledPay = true
        let itemKeyList = []
        if (itemKey === 'all') {
          this.shopCart.itemList.map(res => {
            if (res.itemKey !== undefined) {
              itemKeyList.push(res.itemKey)
            }
          })

        } else if (this.checkedArr.length && itemKey) {
          itemKeyList = itemKey ? [itemKey] : this.checkedArr.map(res => res.itemKey)
        } else if (this.deleteType) {
          itemKeyList = [itemKey]
        } else if(this.checkedArr.length == 0 && itemKey){
          itemKeyList = [itemKey]
        } else{
          itemKeyList = this.selectItem.map(res=>res.itemKey)
        }
        removeItem({ itemKeyList }).then(res => {
          this.cartData(res)
          this.disabledPay = false
          this.$message.success('已删除')
        }).catch(error => {
          this.getCart()
          this.disabledPay = false
        })
        this.dialogDeleteVisible = false
      },
      // 全选按钮
      toggleAllSelection () {
        this.$refs.multipleTable.toggleAllSelection()
      },
      // 修改购物车商品全部选中
      handleSelectionAll (selection) {
        this.checkedArr = selection
        const isSelected = selection.length ? 'Y' : 'N'
        this.notButton = true;
        updateAllSelected({ isSelected }).then(res => {
          this.notButton = false;
          this.cartData(res)
          this.checked = !!selection.length
        }).catch(error => {
          this.notButton = false;
          this.getCart()
        })
      },
      // 修改购物车商品选中状态
      handleSelectionChange (selection, row) {
        let itemKey = row.itemKey
        this.notButton = true;
        updateSelected({ itemKey }).then(res => {
          this.notButton = false;
          if(res.code === '0'){
            this.cartData(res)
          }
        }).catch(error => {
          this.notButton = false;
          this.getCart()
        })
      },
      // 修改购物车商品数量
      handleChange (row) {
        this.changeShopItem(row).then(res=>{
          this.getCart()
        }).catch(err=>{
          this.getCart()
        })
      },
      changeShopItem(row){
        return new Promise((resolve,reject)=>{
          updateQuantity(row).then(res => {
            this.cartData(res)
            resolve()
          }).catch(error => {
            reject()
          })
        })
      },
      hideDialog(){
        this.showLessStockModal = false;
        this.lessStockShops = []
        this.beforeChangeLessCart = []
      },
      // 结算
      handleCollateOrder() {
        if (this.selectItem.length === 0) {
          return this.$message.error('您还未勾选商品！')
        }
        if (this.disabledPay) return '';
        let goodsList = [];
        this.notButton = true;
        this.shopCart.itemList.forEach(item =>{
          if(item.itemDetailList && item.itemDetailList.length > 0){

              item.itemDetailList.forEach(obj=>{

                if(obj.selected === true){
                  goodsList.push(obj)
                }
              })
          }else {
            if(item.selected === true){
              goodsList.push(item)
            }
          }
        })

        let tractCartItemList = goodsList.map(item => ({
					itemNo: item.productCode, 
					itemName: item.drugNm, 
				}));
				// console.log('去结算 tractCartItemList:', tractCartItemList)
				sensors.track("ToSettlementButtonClick", {
					"itemList": tractCartItemList,
					"promotionAmount": this.shopCart.discountAmount,
					"totalAmount": this.shopCart.productTotalAmount
				});

        //去下单
        let action = ()=>{
          let lessStockArr = goodsList.filter(item => item.quantity > item.stockQuantity);
          let enoughStockArr = goodsList.filter(item => item.quantity <= item.stockQuantity);
          let promiseArr = [];

          this.lessStockShops = this.$util.copyData([],lessStockArr)
          this.lessStockShops.forEach(item=>{
            this.beforeChangeLessCart.push(this.$util.copyData({},item))
          })

          if (lessStockArr.length > 0) {
            this.lessStockShops.forEach((item, index) => {
              let count = item.stockQuantity - (item.stockQuantity % this.getRealMinBuyNum(item))
              this.lessStockShops[index].quantity = count
              this.lessStockShops[index].id = index
              promiseArr.push(this.changeShopItem(this.lessStockShops[index]))
            });

            this.shopCart = {...this.shopCart, itemList: [...this.lessStockShops, ...enoughStockArr]};
            this.showLessStockModal = true;
            //更新购物车
            Promise.all(promiseArr).then(res => {
              this.getCart()
            }).catch(err => {
              this.getCart()
            });
            return
          }
          getSettlement().then(res => {
            this.notButton = false;
            if (this.shopCart.productTypeTotalQuantity && this.shopCart.enableSubmit === 'Y') {

              this.$emit('change-page', {
                params: {
                  title: '结算页',
                  currentView: 'CollateOrder',
                  active: 1,
                  shopCart: this.shopCart
                }
              })
            }
          })
        }

        let action1 = ()=>{
          if(!!goodsList.find(item => item.isCashTransaction === 'Y' && item.selected === true)){ //是否有现款结算的商品
            this.$confirm('您结算的商品中含现金品种，整单只能支持在线支付，是否确认结算？','提示',{
              distinguishCancelAndClose: true,
              confirmButtonText: '确认',
              cancelButtonText: '取消'
            }).then(res=>{
              action()
            }).catch(err=> {
              this.notButton = false;
            })
          }else {
            action()
          }
        }

        if(this.shopCart.discountList.length > 0) {
          if (!this.shopCart.discountList.every(item => {return item.discountType !== 'FULL_REDUCE_SEND_ACTIVITY'}) && !this.shopCart.discountList.every(item => {return item.presentList.length === 0})) {
            this.$confirm('您结算的商品中含有满足条件但未选择的特殊商品，是否确认结算？','提示',{
              distinguishCancelAndClose: true,
              confirmButtonText: '确认',
              cancelButtonText: '取消'
            }).then(res=>{
              if(this.userType === 'SALESMAN'){
                let org = this.allBusiness[this.businessIndex] || this.org
                this.$confirm(`您正在下单的客户信息为: <br/>核算单元：<span style="color:#E6A23C">${ org.orgNm}</span><br/>客户编码：<span style="color:#E6A23C">${this.accountId.code}</span><br/>客户名称：<span style="color:#E6A23C">${this.accountId.buyersNm}</span>`,'提示',{
                  distinguishCancelAndClose: true,
                  dangerouslyUseHTMLString: true,
                  confirmButtonText: '确认无误',
                  cancelButtonText: '取消'
                }).then(res=>{
                  action1()
                }).catch(err=>{
                  this.notButton = false;
                })
              }else {
                action1()
              }

            }).catch(err => {
              this.notButton = false;

            })
          } else {
            if(this.userType === 'SALESMAN'){
              let org = this.allBusiness[this.businessIndex] || this.org
              this.$confirm(`您正在下单的客户信息为: <br/>核算单元：<span style="color:#E6A23C">${ org.orgNm}</span><br/>客户编码：<span style="color:#E6A23C">${this.accountId.code}</span><br/>客户名称：<span style="color:#E6A23C">${this.accountId.buyersNm}</span>`,'提示',{
                distinguishCancelAndClose: true,
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确认无误',
                cancelButtonText: '取消'
              }).then(res=>{
                action1()
              }).catch(err=>{
                this.notButton = false;
              })
            }else {
              action1()
            }
          }
        } else {
          if(this.userType === 'SALESMAN'){
            let org = this.allBusiness[this.businessIndex] || this.org
            this.$confirm(`您正在下单的客户信息为: <br/>核算单元：<span style="color:#E6A23C">${ org.orgNm}</span><br/>客户编码：<span style="color:#E6A23C">${this.accountId.code}</span><br/>客户名称：<span style="color:#E6A23C">${this.accountId.buyersNm}</span>`,'提示',{
              distinguishCancelAndClose: true,
              dangerouslyUseHTMLString: true,
              confirmButtonText: '确认无误',
              cancelButtonText: '取消'
            }).then(res=>{
              action1()
            }).catch(err=>{
              this.notButton = false;
            })
          }else {
            action1()
          }
        }
      },
      // 重新渲染表格头部
      renderHeader (h, { column }) {
        return h('i', {
          class: 'header-row'
        })
      },
      // 渲染表格class
      rowClassName ({ row, rowIndex }, name) {
        if (row.isGroup) {
          if (row.shoppingCartItemType === 'ADDITIONAL' || row.shoppingCartItemType === 'SPECIAL_PRICE_PURCHASE') {
            return 'meal-row add'
          } else if(row.recommend ==='Y') {
            return 'meal-row add addPackage'
          } else {
            return 'meal-row'
          }

        } else {
          if (row.shoppingCartItemType === 'ADDITIONAL' || row.shoppingCartItemType === 'SPECIAL_PRICE_PURCHASE') {
            return 'addChildren'
          }
          this.shopCart[name].map((val, index) => {
            if (row.itemKey === val.itemKey) {
              return 'meal-row'
            }
          })
        }
        return ''
      },
      rowClassNameNormal (obj) {
        return this.rowClassName(obj, 'itemList')
      },
      rowClassNameDisable (obj) {
        return this.rowClassName(obj, 'invalidItemList')
      },
      // 合并行
      spanMethod ({ row, column, rowIndex, columnIndex }, name) {
        let row_col = 0
        let column_col = 0
        this.shopCart[name].map((val, index) => {
          if (row.itemKey === val.itemKey) {
            row_col = index
            column_col = val.children ? val.children.length + 1 : 0
          }
        })
        if (row.isGroup) {
          if (columnIndex === 2) {
            if(row.recommend === 'Y') {
              return [1, 10]
            } else if (row.shoppingCartItemType !== 'ADDITIONAL' || row.shoppingCartItemType !== 'SPECIAL_PRICE_PURCHASE') {
              return [1, 7]
            } else {
              return [1, 8]
            }
          } else if (columnIndex === 0 || columnIndex === 1) {
            if (row.shoppingCartItemType !== 'ADDITIONAL' || row.shoppingCartItemType !== 'SPECIAL_PRICE_PURCHASE') {
              return [column_col, 1]
            } else {
              return [0, 1]
            }
          } else if (columnIndex === 3) {
            return [1, 4]
          }
        } else if (row.isGroupChildren) {
          if (row.shoppingCartItemType !== 'ADDITIONAL' || row.shoppingCartItemType !== 'SPECIAL_PRICE_PURCHASE') {
            if (columnIndex === 0 || columnIndex === 1) {
              return [column_col, 1]
            }
          }
        }
      },
      spanMethodNormal (obj) {
        return this.spanMethod(obj, 'itemList')
      },
      spanMethodDisable (obj) {
        return this.spanMethod(obj, 'invalidItemList')
      },
      // 获取购物车数据
      getCart () {
        this.checked = true
        return new Promise((resolve, reject) => {
          getCart().then(response => {
            if (response.code === '0') {
              this.cartData(response)
              resolve(response)
            } else {
              reject()
            }
          }).catch(error => {
            reject(error)
          })
        })
      },
      // 购物车数据加工
      cartData (res) {
        const { data } = res
        if (!data) return
        let handleData = (arr) => {
          let all = []
          let allPlus = 0
          let number = 0
          let newArr = arr.map((item, index) => {
            if (item.shoppingCartItemType !== 'ADDITIONAL' && item.shoppingCartItemType !== 'SPECIAL_PRICE_PURCHASE' && item.itemDetailList.length > 0) {
              return {
                ...item,
                selected: item.selected === 'Y',
                rowKey: index + '',
                index: 'group',
                isGroup: true,
                expanded: true,
                menuLevel: 1,
                number: ++number,
                children: item.itemDetailList.map((child, childIndex) => {
                  return {
                    ...child,
                    selected: child.selected === 'Y',
                    checked: false,
                    isGroupChildren: true,
                    menuPid: index + '',
                    number: null,
                    menuLevel: 2,
                    rowKey: index + '-' + childIndex,
                    index: childIndex + 1,
                    itemKey: item.itemKey + '|' + child.itemKey,
                    isEditGiftPackage: true
                  }
                })
              }
            } else if (item.itemDetailList.length > 0) { //加价购
              let i = index + 1 + allPlus
              item.itemDetailList.forEach((child, childIndex) => {
                allPlus++
                number++
                all.push({
                  index: i++,
                  obj: {
                    ...child,
                    selected: child.selected === 'Y',
                    number,
                    itemKey: item.itemKey + '|' + child.itemKey,
                    isGroup: false
                  }
                })
              })
              return {
                ...item,
                selected: item.selected === 'Y',
                rowKey: index + '',
                index: 'group',
                isGroup: true,
                expanded: true,
                menuLevel: 1,
                children: []
              }
            } else {
              return {
                ...item,
                selected: item.selected === 'Y',
                number: ++number,
                isGroup: false
              }
            }
          })
          all.forEach(child => {
            newArr.splice(child.index, 0, child.obj)
          })
          return newArr
        }
          data.itemList = handleData(data.itemList)

          data.invalidItemList = handleData(data.invalidItemList)
          this.shopCart = data
          this.presents = data.presentList
          this.paymentWayList = data.paymentWayList
          this.buyersReceiverAddrId = data.buyersReceiverAddrId
          this.$store.dispatch('user/setCartNum', data.allProductTypeTotalQuantity)
          let itemList = this.shopCart.itemList
          const newArr = this.shopCart.itemList.map((item, index) => {
              item.packageId = 'N';//先设置所有的状态为N
              item.isEditGiftPackage = true // 是否可编辑
              this.shopCart.discountList.map((item1, index1) => {//再判断有相同项的状态为Y
                  if (item.itemKey === item1.selectSkuId && item.activityNm !== null) {
                      item.packageId = 'Y';
                      item.activityNm += '，' + item1.discountNm
                      // item.itemDetailList = [item]
                  } else  if (item.itemKey === item1.selectSkuId) {
                      item.packageId = 'Y';
                      item.activityNm = item1.discountNm
                  }
              })
              return item;
          })
          this.shopCart.itemList = [...new Set([...this.shopCart.itemList, ...newArr])]
          let forArr = JSON.parse(JSON.stringify(this.shopCart.itemList))
          let indexNum = 0;
          // 有推荐套餐时，往列表有套餐数据前插入活动信息
          forArr.filter((item, index) => {
              if (item.packageId === "Y") {
                  item.packageId = 'N'
                  this.shopCart.itemList.splice((index + indexNum), 0, {recommend: 'Y',isGroup: true, drugNm2: item.drugNm, activityNm: item.activityNm, discountType:"RECOMMEND_PACKAGE_PACKAGE_ACTIVITY"})
                  indexNum ++
              }
          })
          let add = this.shopCart.discountList.filter(res => res.discountType === 'ADDITIONAL_ACTIVITY' || res.discountType === 'SPECIAL_PRICE_PURCHASE')
          if (add.length) {
              add.forEach(data => {
                  data.additionalProductList.forEach(item => {
                      this.$set(item, 'select', false)
                  })
              })
          }
          if (itemList.length) {
              this.$nextTick(() => {
            itemList.forEach(data => {
              if (data.selected) {
                this.$refs.multipleTable.toggleRowSelection(data)
                this.checkedArr.push(data)
              } else {
                this.checked = false
              }
              if (data.shoppingCartItemType === 'ADDITIONAL' && data.itemDetailList.length) {
                data.itemDetailList.forEach(data => {
                  if (data.selected) {
                    this.checkedArr.push(data)
                  } else {
                    this.checked = false
                  }
                })
              }
              if (data.shoppingCartItemType === 'SPECIAL_PRICE_PURCHASE' && data.itemDetailList.length) {
                data.itemDetailList.forEach(data => {
                  if (data.selected) {
                    this.checkedArr.push(data)
                  } else {
                    this.checked = false
                  }
                })
              }
            })
          })
        } else {
          this.checked = false
        }
      },
      changeNum(val) {
        // this.disabledPay = val
        if (!val) {
          setTimeout(() => {
            this.disabledPay = val
          }, 200)
        } else {
          this.disabledPay = val
        }
      },
      //特殊商品套餐编辑开关
      handleEdit(val) {

        this.isEditGiftPackage = false
        this.shopCart.itemList.map(item=> {
          if (val.itemKey === item.itemKey) {
            item.children.forEach(li=> {
              li.isEditGiftPackage = false
            })
          }
        })
      },
      // 取消编辑
      handleCancle(val) {

        this.shopCart.itemList.map(item=> {
          if (val.itemKey === item.itemKey) {
            item.children.forEach(li=> {
              li.isEditGiftPackage = true
            })
          }
        })
        this.getCart()
      },
      setTotal(list) {
        let total = 0
        // let hasCheckedArr = []
        // list.map(item => {
        //   if (item.isMainProduct === 'Y') {
        //     hasCheckedArr.push(item)
        //   }
        // })
        total = list.map(item => {
          return parseFloat((item.originalUnitPrice * item.quantity).toFixed(2))
        }).reduce((pre, cur) => {
          return pre + cur;
        }, 0)
        return total.toFixed(2)
      },
      // 选特殊商品弹窗
      openDialoggiftsVisible(list, id, itemKey) {
        let masterProductConfigsList = []
        list.map(item => {
          if (item.isMainProduct === 'Y') {
            let obj = {
              skuId: item.skuId,
              productId: item.productId,
              productCode: item.productCode,
              quantity: item.quantity
            }
            masterProductConfigsList.push(obj)
          }
        })
        this.getFullGiftsList(masterProductConfigsList, id, itemKey, [])
      },
      handleOpenGiftsDialog(id, item) {
        this.getFullGiftsList(item, id, null, this.giftsParams.triggerItemKeys)
      },
      async getFullGiftsList(item, id, itemKey, triggerItemKeys) {
        let _this = this
        await getFullGiftsList(id,{masterProductConfigsList: item}).then( async (res) => {
          if(res.data.frsPresentRelList !== null) {
            // 触发活动满减
            _this.$refs.giftsDialogRef.open(res.data.frsPresentRelList, res.data.extCfg, res.data.wholeQuantity, item, id, itemKey, triggerItemKeys,res.data.fullReduceSendRuleId)
            _this.dialogGiftsVisible = false
          } else {
            // 未触发活动满减直接加入购物车
            let params = {
              giftConfigsList: [],
              masterProductConfigsList: item,
              fullReduceSendId: id,
              itemKey: itemKey
            }
            let data = await saveFullGiftDetailAndAddToCart(params)
            if (data.code == 0) {
              _this.$message.success('保存成功！')
              _this.getCart()
            } else {
              _this.$message.error(data.msg)
            }
            // let itemList = item.map(res => {
            //   return {
            //     skuId: res.skuId,
            //     quantity: res.quantity
            //   }
            // })
            // batchAddItem({ itemList }).then(res => {
            //   const { code } = res
            //   if (code === '0') {
            //     this.$message.success('加入成功！')
            //     _this.dialogGiftsVisible = false
            //     this.$store.dispatch('user/setCartNum', res.data.allProductTypeTotalQuantity)
            //     this.getCart()
            //   }
            // }).catch(err=>{
            // })
          }
        })
      },
      // 自选套餐
      getOptionalProduct() {
        let params = {
          pageNum: this.giftsParams.currentPage,
          pageSize: this.giftsParams.pageSize,
          objId: this.giftsParams.id
        }
        this.giftsData = []
        this.giftsDataLoading = true
        getPageActivityProduct(params).then(res => {
          if (res.code === '0') {
            this.giftsParams.total = res.data.total
            let giftsList = res.data.records
            giftsList.forEach(val => {
              val.select = false
              val.fullReduceSendId = this.giftsParams.id
              val.discountNm = this.giftsParams.discountNm
            })
            this.giftsData = [...giftsList]
            this.giftsDataLoading = false
          }
        })
      },
      handleDelete(row, index) {
        let prow = {}
        let num = null
        this.shopCart.itemList.map((item,idx) => {
          prow = this.getTreeTableParentRow(item, row)
          if (prow) {
            num = idx
            return
          }
        })
      },
      getTreeTableParentRow(baseRow, row) {
        let prow = null
        let children = baseRow.children
        if (children && children.length > 0) {
          children.forEach(item => {
            if (row == item) {
              prow = baseRow
            } else {
              prow = this.getTreeTableParentRow(item, row)
            }
          })
        }
      }
    },
    watch: {
      selectItem (newVal) {
        let arr = []
        this.shopCart.itemList.map(res => {
          if (res.itemKey !== undefined) {
            arr.push(res)
          }
        })
        this.checked = newVal.length === arr.length
        // this.checked = newVal.length === this.shopCart.itemList.length
      },
      refreshModule: {
        handler () {
          if(!!this.userType){
            this.getCart()
            this.getCoupon()
          }
        },
        deep: true
      }
    },
    computed: {
      ...mapGetters(['buyersVo', 'userType', 'accountId', 'allBusiness','refreshModule','org']),
      formattedNumber() {
        return function (price) {
          if(!price) {
            return
          }
          // 去除千分位
          const numberWithoutCommas = price.replace(/,/g, '');
          // 转换为数字并保留两位小数
          const numberWithTwoDecimals = parseFloat(numberWithoutCommas).toFixed(2);
          // 重新添加千分位
          const numberWithCommas = numberWithTwoDecimals.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          return numberWithCommas;
        }

      }
    }
  }
</script>

<style scoped lang="scss">
  .main {
    margin: 0 auto;
    width: 1200px;
    height: auto;
    /*采购商信息*/
    .buyer-info {
      width: 100%;
      background: #F5F5F5;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 10px;

      .buyer-tag {
        margin-right: 8px;
        display: inline-block;
        padding: 0 4px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        color: #ffffff;
        vertical-align: middle;
        background: $ThemeColor;
        border-radius: 2px;
      }

      .buyer-name {
        margin-right: 28px;
        font-size: 14px;
        font-weight: 500;
        color: #222222;
        line-height: 20px;
      }

      .buyer-contact {
        margin-right: 28px;
        font-size: 14px;
        font-weight: 500;
        color: #666666;
      }

      .buyer-discounts {
        margin-left: auto;
        cursor: pointer;
        display: inline-block;
        padding: 0 12px;
        height: 28px;
        line-height: 28px;
        font-size: 12px;
        color: #ffffff;
        background: #FF6600;
        border-radius: 4px;

        &:hover {
          opacity: .8;
        }
      }
    }

    .group-row {
      /*position: relative;*/
      background: #fafafa;

      td {
        z-index: 1;
        background: #fafafa;
      }

      .el-table__expand-icon {
        .el-icon-arrow-right::before {
          content: "\e791"
        }
      }

      .group-row-content {
        display: block;
        position: initial;

        .state {
          margin-right: 16px;
        }
      }

      .group-row-content-btn {
        padding: 0;
        line-height: 1.5;
      }
    }

    .discounts-tag {
      margin-left: 12px;
      margin-right: 8px;
      display: inline-block;
      padding: 0 4px;
      height: 20px;
      font-weight: 500;
      line-height: 20px;
      font-size: 12px;
      color: #ffffff;
      vertical-align: middle;
      background: linear-gradient(135deg, rgba(250, 167, 0, 1) 0%, rgba(250, 100, 0, 1) 100%);
      border-radius: 2px;
    }

    /*加购信息*/
    .discounts {
      width: 100%;
      background: #ffffff;
      padding: 14px 12px;

      .discounts-row {
        margin: 6px 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .discounts-info {
          font-size: 14px;
          font-weight: 500;
          color: rgba(0, 0, 0, 1);
        }

        /deep/ {
          .el-button--text {
            padding: 0 10px;
          }
        }
      }
    }

    .set-meal {
      background: rgba(241, 247, 254, 1);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .meal-center {
        font-size: 14px;
        font-weight: 600;
        color: #222222;

        .fixed {
          color: $ThemeColor;
        }

        .dapei {
          color: #FA6400;
        }

      }
    }

    .coupon-dialog {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      .flex {
        display: flex;
        text-align: left;
      }

      .name {
        color: #fff;
        margin-right: 10px;
        position: relative;
        line-height: 28px;
        text-align: center;

        .left-con, .right-con {
          width: 10px;
          height: 10px;
          background: #fff;
          position: absolute;
          border-radius: 50%;
          top: 12px;
          left: -5px;
        }

        .right-con {
          left: auto;
          right: -5px;
        }

        p {
          padding: 2px 15px;
          min-width: 80px;
          background-color: #FA6400;
          border-radius: 2px;
        }
      }

      .text {
        line-height: 1.3em;

        .text_1 {
          font-size: 14px;
          font-weight: 600;
          color: #222;
        }

        .text_2 {
          font-size: 12px;
          color: #999;
        }
      }

      .button {
        font-size: 12px;
        white-space: nowrap;
        line-height: 28px;
        text-align: center;
        width: 80px;

        p {
          color: #FA6400;
          padding: 0 16px;
          border: 1px solid #FA6400;
          border-radius: 2px;
          cursor: pointer;

          &:hover {
            color: #fff;
            background: #FA6400;
          }
        }
      }
      .disabled {
        p {
          color: #999;
          padding: 0 16px;
          border: 1px solid #999;
          border-radius: 2px;
          cursor: default;

          &:hover {
            color: #999;
            background: #fff;
          }
        }
      }
      .isReceive{
        p{
          cursor: default;
          &:hover {
            color: #fa6400;
            background: #fff;
          }
        }
      }
    }

    .present-dialog {
      text-align: left;

      .name {
        color: #222;
        font-size: 14px;
        font-weight: 600;
      }

      img {
        width: 50px;
        height: 50px;
        margin-right: 12px;
      }
    }
    .gifts-dialog {
      overflow-y: auto;
      max-height: 600px;
    }

    .addition-dialog {
      h3 {
        background-color: #F1F7FE;
        line-height: 40px;
        font-weight: 600;
        color: #222;
        text-align: left;
        padding-left: 20px;
      }

      .checkChecked {
        height: 400px;
        overflow-y: auto;

        .el-checkbox {
          display: flex;
          align-items: center;
          padding: 16px 20px;
          margin: 0;
          float: left;
          width: 320px;

          /deep/ .el-checkbox__label {
            display: flex;
          }

          .content {
            text-align: left;

            .name {
              color: #222;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 200px;
            }

            .manufacturer, .spec {
              font-size: 12px;
              color: #666666;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 200px;
            }

            .price {
              color: #E02034;
              font-weight: 600;
              font-size: 16px;

              span {
                text-decoration: line-through;
                font-size: 14px;
                font-weight: 400;
                color: #999;
                padding-left: 8px;
              }
            }
          }
        }
      }

      img {
        width: 80px;
        height: 80px;
        margin-right: 12px;
      }

      /deep/ {
        .el-dialog__body {
          padding: 0;
        }
      }

      footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 50px;
        height: 50px;
        padding: 0 13px 0 20px;
        background-color: #F5F5F5;

        .add-shopping-cart {
          font-size: 12px;
          font-weight: 500;
          height: 32px;
          line-height: 32px;
          color: rgba(255, 255, 255, 1);
          background: rgba(255, 102, 0, 1);
          border-radius: 2px;
          cursor: pointer;
          padding: 0 12px;
        }

        span {
          padding-left: 5px;
        }

        b {
          color: #E02034;
          font-size: 16px;
        }
      }
    }

    .disableText {
      text-align: left;
      font-size: 14px;
      margin-top: 30px;
      font-weight: 500;

      span {
        color: #666666;
        font-size: 12px;
        cursor: pointer;
        padding-left: 16px;
      }
    }

    .disableTable {
      margin-top: 12px;

      /deep/ {

        .el-table__row td:nth-child(2) {
          background-color: #F2F2F2;

          span {
            color: #666;
          }
        }
      }
    }
  }

  /*结算*/
  .settlement-container {
    width: 100%;
    height: auto;
    background: #ffffff;
    margin-top: 10px;
    box-shadow: 1px 4px 8px 0 rgba(0, 0, 0, 0.1);

    .settlement-row {
      margin: 0 auto;
      width: 1200px;
      height: 60px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .row-left {
        font-size: 12px;
        font-weight: 500;
        color: rgba(102, 102, 102, 1);

        /deep/ .el-checkbox {
          margin-right: 23px;

          .el-checkbox__label {
            font-size: 12px;
            font-weight: 500;
            color: rgba(102, 102, 102, 1);
          }
        }

        span {
          cursor: pointer;
          margin-right: 20px;
        }
      }

      .row-right {
        display: flex;
        flex-direction: row;
        align-items: center;

        .settlement {
          margin-right: 12px;
          text-align: right;

          .total-money {
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            font-weight: 500;
            color: rgba(34, 34, 34, 1);

            span {
              font-size: 20px;
              color: rgba(224, 32, 52, 1);
            }
          }
        }

        .product-detail {
          height: 14px;
          font-size: 12px;
          font-weight: 500;
          color: rgba(102, 102, 102, 1);
          line-height: 14px;

          span {
            margin-left: 12px;
          }

          /deep/ .el-button {
            padding: 0 0 0 5px;
            font-size: 12px;
          }
        }

        .settlement-button-group {
          height: 100%;

          button {
            border: none;
            height: 60px;
            padding: 0 28px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
          }

          .immediate-settlement {
            color: #ffffff;
            background: #E02034;

            &.disable {
              background-color: #bbb;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }

  // 表格为空替换
  .empty-default {
    display: flex;
    height: 530px;
    justify-content: center;
    padding-top: 80px;
    background: white;

    .empty-tip {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(113, 113, 113, 1);
      line-height: 20px;
      margin-bottom: 30px;
    }

    .empty-btn {
      background: rgba(9, 113, 235, 1);
      border-radius: 2px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      padding: 2px 4px;
    }
  }

  //购物车列表为空
  .is-tabledata-empty {
    /deep/ .el-table__body-wrapper {
      display: none
    }
  }

  /*表格*/
  /*解决列fixed后的办法处理*/
  /deep/ .el-table--border {
    border: 1px solid #E5E5E5;
  }

  /deep/ .el-table {
    .el-table__row {
      height: 60px;

      td .el-tooltip {
        min-width: 40px;
      }

      &.meal-row td.index {
        .el-tooltip {
          position: absolute;
          top: 14px;
        }
      }

      &.meal-row td.selection {
        padding: 0;

        .el-tooltip {
          position: absolute;
          top: 14px;
        }

        .selection {
          display: flex;
          flex-direction: column;
          align-items: center;

          .span {
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: #ccc;
            margin-bottom: 58px;

            &:before {
              content: "";
              position: absolute;
              height: 160px;
              background: #ccc;
              width: 1px;
              left: 21px;
            }

            &:nth-child(2):before {
              top: 15px;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }

          .el-checkbox {
            margin: 0;
          }

          .el-checkbox:first-child {
            margin-bottom: 54px;
          }
        }
      }

      &.meal-row.add td.selection {
        .el-tooltip {
          position: initial;
          padding: 0;
        }

        .selection {
          .el-checkbox {
            height: 70px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #d9d9d9;
            width: 100%;
            justify-content: center;
          }

          .el-checkbox:first-child {
            height: 55px;
            margin-bottom: 0;
          }

          .el-checkbox:last-child {
            border-bottom: none;
          }
        }
      }

      &.meal-row.add td.padding .set-meal {
        background-color: #fff;
      }

      &.meal-row.add td.visibility {
        border-right: 0;
        display: table-cell;

      }

      &.meal-row.add td:nth-child(3), &.meal-row.add td:nth-child(4) {
        background-color: #fff !important;
      }

      &.meal-row.add td:nth-child(3) {
        border-right: 0;
      }
      &.meal-row.addPackage {
          td:nth-child(1){
              border-right: 0;
              &>div {
                  width: 800px !important;
              }
          }
          td:nth-child(2){
              .el-button {
                  margin-left: 124px;
              }
          }
      }

      &.addChildren td {
        background-color: #fff0ef;
      }

      td.padding {
        padding: 0;

        .cell {
          padding: 0;

          .el-table__indent {
            padding: 0 !important;
          }

          .el-table__placeholder {
            display: none;
          }

          img {
            margin-top: 10px;
          }
        }
      }

      &.meal-row td.visibility {
        display: inline-block;

        .cell {
          visibility: initial;

          .meal-right {
            font-size: 12px;
            font-weight: 600;
            color: rgba(34, 34, 34, 1);
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 30px;

            .price {
              color: #FF3B30;
              padding-right: 16px;

              s {
                color: rgba(153, 153, 153, 1);
                padding-left: 4px;
              }
            }

            .input {
              margin-right: 12px;
            }
          }

          /deep/ .el-button--default {
            height: 24px;
            padding: 0 8px;
          }
        }
      }

      .cell {
        padding: 0 4px;
        font-size: 12px;
        font-weight: 500;
        color: rgba(38, 38, 38, 1);
      }


      .commodity-image {
        text-align: center;
      }

      .product-img {
        width: 50px;
        height: 50px;
      }

      /*重写计数器样式*/
      .el-input-number {
        width: 90px;
      }

      .el-input-number__decrease, .el-input-number__increase {
        width: 20px;
        height: 24px;
        background: #ffffff;
        box-sizing: border-box;
        border-radius: 1px;
        border: 1px solid rgba(230, 230, 230, 1);
      }

      .el-input-number__decrease {
        left: 0;
      }

      .el-input-number__increase {
        right: 0;
      }

      .el-input__inner {
        padding: 0 19px;
        height: 24px;
        line-height: 24px;
      }

      /*商品名称*/
      .table-product-name {
        display: flex;
        justify-content: center;
        flex-direction: column;
        p {
          font-size: 14px;
          font-weight: 600;
          color: rgba(34, 34, 34, 1);
          line-height: 20px;
          padding-bottom: 3px;
        }

        .table-row-tags {
          display: flex;
          flex-direction: row;
        }

        .row-tag {
          height: 18px;
          font-size: 12px;
          // font-weight: 500;
          line-height: 18px;
          border-radius: 3px;
          border-width: 1px;
          border-style: solid;
          margin-right: 4px;
          padding: 0 4px;
          box-sizing: border-box;
        }

        .tag-promotion {
          background: #FF6600;
          border: none;
          color: #ffffff;
        }

        .tag-cash {
          border-color: transparent;
          color: #025CD6;
          background-color: rgba(2, 92, 214, 0.1);
        }

        .tag-coupon {
          border-color: transparent;
          color: #FF6600;
          background-color: rgba(255, 102, 0, 0.1);
        }

        .tag-waring{
          border-color: transparent;
          color: #ffffff;
          background-color: red;
        }

        .tag-plus {
          border-color: transparent;
          color: #33AE67;
        }

        .tag-otc {
          border-color: transparent;
          background-color: #258C60;
          color: #fff !important;
          display: inline-block;
        }

        .jiaOtc {
          background: #E60012 !important;
        }

        .yiOtc {
          background: #33AE67 !important;
        }

        .rxOtc {
          background: #0091ff !important;
        }
      }

      /*库存*/
      .inventory-warning {
        font-weight: 600;
        color: rgba(255, 82, 0, 1);
      }

      /*操作栏*/
      .table-operation {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        span {
          width: 100%;
          cursor: pointer;
          margin-bottom: 5px;
          height: 20px;
          font-size: 12px;
          font-weight: 500;
          color: rgba(102, 102, 102, 1);
          line-height: 20px;
          padding: 0 4px;
          border-radius: 2px;
          border: 1px solid rgba(204, 204, 204, 1)
        }
      }

    }

    .el-table__row.meal-row {
      height: 40px;
    }
  }

  .commodity-image {
    .purchase-history-tag {
      position: absolute;
      padding: 0 4px;
      bottom: 0;
      height: 20px;
      line-height: 20px;
      background: rgba(250, 173, 20, 1);
      border-radius: 0 8px 0 0;
      font-size: 12px;
      font-weight: 600;
      color: rgba(255, 255, 255, 1);
    }
  }

  /deep/ .header-row {
    height: 40px;
    background: #E5E5E5;

    th {
      .cell {
        padding: 0 8px;
        font-size: 12px;
        font-weight: 600;
        color: rgba(34, 34, 34, 1);
      }
    }
  }

  .coupon-el-dialog__body /deep/ .el-dialog__body {
    max-height: 400px;
    overflow-y: auto;
  }
</style>

<style lang="scss">
  .shop-cart-content {
    .meal-row {
      td {
        &:not(:nth-child(-n+4)) {
          width: 0;
          height: 0;
          overflow: hidden;
          position: absolute;
        }

        .el-table__expand-icon {
          width: 0;
          height: 0;
          overflow: hidden;
          position: absolute;
        }
      }
    }
    .meal-row{
      .visibility{
        .cell{
          width: 501px !important;
        }
      }
    }

    .meal-row td:nth-child(1), .meal-row:hover td:nth-child(1), .meal-row td:nth-child(2), .meal-row:hover td:nth-child(2) {
      background-color: #FFF !important;
    }

    .meal-row td:nth-child(3), .meal-row:hover td:nth-child(3), .meal-row td:nth-child(4), .meal-row:hover td:nth-child(4) {
      background-color: #F1F7FE !important;
    }
  }
  /deep/ .disabled-checkbox {
    .is-disabled  {
      .el-checkbox__inner {
        background: #1890ff !important;
        border-color: #1890ff !important;
        &::after {
          border-color: #fff !important;
        }
      }
    }
  }
  .package-content {
      padding: 10px 20px 20px;
      box-sizing: border-box;
  }
  .el-dialog {
      .detail-header-right {
          .detail-swiper {
              .swiper-container {
                  width: 940px;
              }
          }
      }
  }

  .swiper-container {
      max-height: 660px;
      overflow: auto;
  }
</style>

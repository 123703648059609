<template>
  <div class="intercourse-bill">
    <div class="search-header">
      <el-form ref="searchForm" :inline="true" :model="searchForm" class="demo-form-inline" size="small">
        <el-form-item>
          <el-date-picker
            style="width: 230px; padding: 1px 12px;"
            size="small"
            v-model="searchForm.time"
            type="daterange"
            @change="changeDatePicker"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="searchForm.productNm" placeholder="请输入商品名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="searchForm.orderNum" placeholder="请输入订单编号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="exportData">导出</el-button>
          <el-button type="primary" size="mini" @click="onSubmit">搜索</el-button>
          <el-button type="text" size="mini" @click="onReset"><span style="color: #000000">重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="intercourse-bill-main">
      <el-tabs v-model="activeName" @tab-click="handleTab">
        <el-tab-pane name="NOT_RECEIVABLE"><span slot="label">未回款</span></el-tab-pane>
        <el-tab-pane name="B"><span slot="label">部分回款</span></el-tab-pane>
        <el-tab-pane name="IS_RECEIVABLE"><span slot="label">已回款</span></el-tab-pane>
      </el-tabs>
      <!-- 表格 -->
      <im-table-page ref="tablePage" :data.sync="tableData" :remote-method="queryData" @updated="updated"
         :more-check-box="handleSelectionChange" :cell-class-name="cellClassName"
         :header-cell-class-name="cellClassName">
        <el-table-column label="序号" type="index" fixed="left" width="55">
        </el-table-column>
        <el-table-column type="selection" fixed="left" width="40"></el-table-column>
        <el-table-column prop="businessDate" label="业务日期" fixed="left" width="90px"
           show-overflow-tooltip>
           <template slot-scope="scope">
             <span>{{ String(scope.row.businessDate).replace(new RegExp("-","gm"),".") }}</span>
           </template>
           </el-table-column>
        <el-table-column label="订单号" prop="orderNum" width="160px" show-overflow-tooltip></el-table-column>
        <el-table-column label="分支机构" prop="ettleAccountNm" align="left" min-width="100px" show-overflow-tooltip></el-table-column>
        <el-table-column label="金额" align="right" prop="amount" min-width="100px">
          <template slot-scope="scope">
            <span style="color:#FA6400">{{scope.row.redOrNot == "Y"?"-":""}}{{ $util.toFixed(scope.row.amount) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="goodsId" label="货品ID" width="80px" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="商品名称" width="80px" prop="productNm" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="通用名" min-width="80px" prop="commonNm">
        </el-table-column>
        <el-table-column label="规格" min-width="80px" prop="spec">
        </el-table-column>
        <el-table-column label="生产厂家" width="100px" prop="manufacturer" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="基本单位" width="60px" prop="unit" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="数量" width="60px" prop="quantity" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="单价" align="right" width="80px" prop="unitPric" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="color:#FA6400; ">{{ parseFloat(scope.row.unitPric).toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="发票日期" width="90px" prop="invoiceDate" show-overflow-tooltip>
          <template slot-scope="scope">
           <span>{{ String(scope.row.invoiceDate).replace(new RegExp("-","gm"),".") }}</span>
          </template>
        </el-table-column>
        <el-table-column label="发票号" width="80px" prop="invoiceNum" fixed="right" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="账期至" v-if="userType === 'SALESMAN'" width="90px" prop="paymentDays" fixed="right" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="逾期日期" v-if="userType === 'SALESMAN'" width="90px" prop="overdueDate" fixed="right" show-overflow-tooltip>
        </el-table-column>
      </im-table-page>
    </div>

    <!--结算-->
    <div class="settlement-container" v-if="tableData.length > 0 && activeName === 'NOT_RECEIVABLE'">
      <div class="settlement-row">
        <div class="settlement">
          <div class="total-money">
            账单金额：<span class="money">¥{{$util.toFixed(total)}}</span>
          </div>
        </div>
        <div class="settlement-button-group">
          <el-button type="primary" class="immediate-settlement" @click="bindPay">去支付</el-button>
        </div>
      </div>
    </div>
    <el-dialog class="dialog-table" title="支付账单" width="600px" :visible.sync="dialogVisible">
      <div class="dialog">
        <div class="dialog-title">账单金额</div>
        <div class="dialog-money">¥{{$util.toFixed(total)}}</div>
        <template v-for="item in payWayList ">
          <button :key="item.onLinePayWayEnum" class="payment-button alipay"
                  :style="{'background':'url(' + item.payWayLogo +') center center no-repeat'}"
                  @click="handlePay(item)"></button>
        </template>
        <template>
          <!-- 支付表单 -->
          <div v-html="payFormHtml"></div>
        </template>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import ProDuct from '@/assets/img/member-center/product.png'
  import {exportIntercourseBill, getIntercourseBill} from '@/api/member-center/property-manage'
  import { findEnablePaymentWay, goBank, currentBillsgoBank} from '@/api/shop-cart'
  import {getBus, getToken} from '@/utils/auth'
  import { toThousands } from '@/utils/index'
  import { mapGetters } from 'vuex'

  export default {
    name: 'IntercourseBill',
    data () {
      return {
        activeName: 'NOT_RECEIVABLE',
        searchForm: {
          isReceivable: 'N',
          productNm:'',
          orderNum:'',
          startTime: '',
          endTime: '',
        },
        ProDuct: ProDuct,
        tableData: [],
        selection: [],
        payWayList:[],
        payFormHtml: '',
        total: '0.0000',
        dialogVisible: false
      }
    },
    computed: {
      ...mapGetters(['userType'])
    },
    created () {
    },
    watch: {
      '$store.state.user.refreshModule': {
        handler() {
          if (getToken()) {   // 判断一下是否登录 点击登出会报错
            this.doRefresh()
          }
        },
        deep: true
      }
    },
    methods: {
      exportData(){
        exportIntercourseBill(this.searchForm).then(res =>{
          let blob = new Blob([res.data], {type :'application/zip'})
          const disposition = res.headers['content-disposition'].split(";")[1].split("=")[1];
          var fileName = decodeURI(disposition);
          //去掉双引号
          fileName = fileName.replace(/\"/g, "");
          let link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          link.remove()
          this.$message.success('导出成功')
        })
      },
      // 修改购物车商品选中状态
      handleSelectionChange (selection) {
        this.selection = []
        let total = 0
        selection.forEach(data => {
          total = parseInt(total * 100000 +  Number(String(data.amount).replace(/,/g,'')) * 100000)/100000;
          this.selection.push(data.currentBillsId)
        })
        this.total = this.$util.toFixed(toThousands(total))
      },
      handleTab (tabs) {
        this.activeName = tabs.name
        switch (this.activeName) {
          case "NOT_RECEIVABLE":
            this.searchForm.isReceivable = 'N'
            break;
          case "IS_RECEIVABLE":
            this.searchForm.isReceivable = 'Y'
            break;
          case "B":
            this.searchForm.isReceivable = 'B'
            break;
          default:
            break
        }
        this.doRefresh()
      },
      changeDatePicker (dateList) {
        this.searchForm.startTime = dateList[0]
        this.searchForm.endTime = dateList[1]
      },
      onSubmit () {
        this.doRefresh()
      },
      onReset () {
        this.searchForm.time = []
        this.searchForm.startTime = ''
        this.searchForm.endTime = ''
        this.searchForm.productNm = ''
        this.searchForm.orderNum = ''
        this.doRefresh()
      },
      doRefresh () {
        this.$refs.tablePage.doRefresh({ currentPage: 0, pageSize: 10, condition: this.searchForm })
      },
      queryData (data) {
        return getIntercourseBill({ ...data, condition: this.searchForm })
      },
      updated () {
        this.$refs.tablePage.doLayout()
      },
      bindPay(){
        if(this.selection.length <= 0) return this.$message.error('请勾选对应选项')
        //获取支付方式
        findEnablePaymentWay({ orgId: getBus().orgId }).then(res => {
          this.payWayList = res.data
        })
        this.dialogVisible = true
      },
      //付款
      handlePay (item) {
        //支付宝支付
        if (item.onlinePayWayEnum === 'PC_ALIPAY' || item.onlinePayWayEnum === 'ICBC_PAY') {
          currentBillsgoBank({
            payWayId: item.paymentWayId,
            currentBillsIds: this.selection
          }).then(res => {
            if (res.data) {
              console.log("123321",res.data)
              this.payFormHtml = res.data
              setTimeout(() => {
                let payForm = document.getElementById('payForm')
                payForm.target = '_blank'
                payForm.submit()
              }, 200)
            }
          })
        }
      },
      cellClassName ({ row, column, rowIndex, columnIndex }) {
        return 'cellFont'
      }
    }

  }
</script>

<style scoped lang="scss">
  .intercourse-bill {
    width: 100%;
    height: 100%;
    background: #ffffff;
    position: relative;

    .search-header {
      width: 100%;
      height: 72px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      border-bottom: 1px solid #E5E5E5;

      /deep/ {
        .el-form-item {
          margin-bottom: 0;
          margin-right: 20px;

          .el-form-item__label {
            padding-right: 4px;
            font-size: 14px;
            font-weight: 400;
            color: rgba(113, 113, 113, 1);
          }

          .el-input__inner {
            padding: 0 12px;
            background: rgba(255, 255, 255, 1);
            border-radius: 4px;
            border: 1px solid rgba(204, 204, 204, 1);
          }
        }

        .el-range-separator {
          height: 30px;
          line-height: 30px;
        }
      }
    }

    .intercourse-bill-main {
      width: 100%;
      height: auto;
      background: #ffffff;
      padding: 0 20px;

      .product-info {
        display: flex;
        flex-direction: row;

        .product-image {
          width: 70px;
          height: 70px;

          .el-image {
            width: 100%;
            height: 100%;
          }
        }
      }

      /deep/ el-popover el-popper .popover-content {
        padding: 24px 16px;
      }

      /deep/ .el-tabs__header {
        margin: 0 !important;
      }

      /deep/ .el-table {
        .order-row {
          background: #F1F7FE;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);

          span {
            margin-right: 20px;
          }

          .el-button {
            padding: 5px 8px;

            span {
              margin: 0;
            }
          }
        }

        .el-table-column--selection {

        }

        .el-table__header-wrapper .el-checkbox { //找到表头那一行，然后把里面的复选框隐藏掉
          display: none
        }

        .cell {
          width: 100% !important;

          .el-table__expand-icon {
            display: none;
          }

          .el-table__indent, .el-table__placeholder {
            display: none;
          }
        }
      }
    }

    /*结算*/
    .settlement-container {
      border-top: 1px solid rgba(229, 229, 229, 1);;
      width: 100%;
      height: auto;
      background: #ffffff;
      //position: absolute;
      bottom: 0;
      margin-top: 16px;

      .settlement-row {
        margin: 0 auto;
        padding: 0 20px;
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .settlement {
          margin-right: 12px;
          text-align: right;

          .total-money {
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            font-weight: 500;
            color: rgba(34, 34, 34, 1);

            span {
              font-size: 20px;
              color: rgba(224, 32, 52, 1);
            }
          }
        }

        .product-detail {
          height: 14px;
          font-size: 12px;
          font-weight: 500;
          color: rgba(102, 102, 102, 1);
          line-height: 14px;

          span {
            margin-left: 12px;
          }
        }

        .settlement-button-group {
          /*height: 100%;*/

          button {
            border: none;
            height: 36px;
            line-height: 36px;
            padding: 0 28px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            cursor: pointer;

            &:hover {
              opacity: .9;
            }
          }

          .immediate-settlement {
            color: #ffffff;
            background: #E02034;
            font-size: 14px;
            width: 126px;
          }
        }

      }
    }
    /deep/ .dialog-table{
      .el-dialog__body{
        background-color: #F5F5F5;
      }
      .el-dialog__header{
        border-bottom: 1px solid #e5e5e5;
      }
    }
    .dialog{
      width: 400px;
      padding: 14px 0 16px;
      background-color: #fff;
      margin: 0 auto;
      .dialog-title{
        height: 20px;
        line-height: 20px;
        font-weight: bold;
        color: #666;
      }
      .dialog-money{
        color: #FF3B30;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 24px;
      }
      .payment-button {
        cursor: pointer;
        display: block;
        width: 340px;
        height: 44px;
        border-radius: 4px;
        text-align: center;
        text-decoration: none;
        border: 1px solid #ccc;
        background: #ffffff;
        padding: 6px 0;
        box-sizing: border-box;
        margin: 16px auto 0;

        &.wechat {
          background: url("img/wechat.png") center center no-repeat;
        }

        &.alipay {
          background: url("img/alipay.png") center center no-repeat;
        }

        &.unionpay {
          background: url("img/unionpay.png") center center no-repeat;
        }
      }
    }
  }
  /deep/ .cellFont .cell {
    font-size: 12px!important;
  }
</style>

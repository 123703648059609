<template>
  <im-drawer
      v-if="addShopVisible"
      :visible.sync="addShopVisible"
      :title="title"
      :size="850"
      :modal="false"
      :append-to-body="true"
      @confirm="handleConfirm"
      @cancel="closeConfirm"
  >
    <div class="section-container">
      <!-- 搜索区域 -->
      <im-search-pad
          class="search-content"
          :model="searchForm"
          :has-expand="false"
          @search="handleSearch"
          @reset="handleReset"
      >
        <im-search-pad-item prop="searchFiled" width="210px">
          <el-input v-model="searchForm.searchFiled" placeholder="输入商家名称/编码/厂家"/>
        </im-search-pad-item>
      </im-search-pad>
      <!-- 表格内容 -->
      <im-table-page
          ref="tablePage"
          :data.sync="tableData"
          :remote-method="queryData"
          :index="true"
          :more-check-box="(val)=>{selectItem = val}"
          @updated="updated"
      >
        <!-- <el-table-column label="序号" prop="activityNm" width="60px" show-overflow-tooltip fixed="left" align="center" >
          <template slot-scope="scope">
            <span >{{ scope.$index}}</span>
          </template>
        </el-table-column> -->
        <el-table-column type="selection" width="50" fixed="left"/>
        <el-table-column label="商品主图" prop="picUrl" fixed="left" min-width="90" align="center">
          <template slot-scope="scope">
            <el-popover placement="right" trigger="hover">
              <div class="img-content" style="text-align:center;padding:5px 0">
                <el-image style="width: 104px; height: 104px" :src="scope.row.picUrl || productDefault" fit="fill"/>
              </div>
              <img
                  slot="reference"
                  style="width: 40px; height: 40px"
                  :src="scope.row.thumbnailUrl || productDefault"
                  fit="fill"
                  @error="scope.row.thumbnailUrl = productDefault"
                  alt
              >
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="商品编码" prop="productCode" min-width="160px" show-overflow-tooltip/>
        <el-table-column label="商品名称" prop="productNm" min-width="130px" show-overflow-tooltip>
          <template slot-scope="scope">
            {{$util.getGoodsName(scope.row)}}
          </template>
        </el-table-column>
        <el-table-column label="规格" prop="spec" min-width="120px" show-overflow-tooltip/>
        <el-table-column label="单位" prop="unit" width="70px" show-overflow-tooltip/>
        <el-table-column label="厂家(上市许可持有人)/产地" prop="manufacturer" min-width="160px" show-overflow-tooltip/>
        <el-table-column label="剂型" prop="dosageForm" width="80px" show-overflow-tooltip/>
        <el-table-column label="批准文号" prop="approvalNumber" width="190px" show-overflow-tooltip/>
      </im-table-page>
    </div>
  </im-drawer>
</template>
<script>
  import productDefault from '@/assets/img/index/product_default.png'
  import { pageIndexProduct } from '@/api/member-center/transactions-using'

  export default {
    props: {
      productList: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data () {
      return {
        addShopVisible: false,
        title: '添加商品',
        tableData: [],
        selectItem: [],
        searchForm: {
          searchFiled: '',
          isOnSale: 'Y'
        },
        productDefault
      }
    },
    watch: {
      tableData (newVal) {
        var newList = this.productList
        this.hasSelect(newList)
      }
    },
    methods: {
      open () {
        this.addShopVisible = true

      },
      // 表格数据回显
      hasSelect (dataList) {
        this.$nextTick(() => {
          this.tableData.map((item) => {
            dataList.map((itm) => {
              if (item.productId === itm.productId) {
                this.$refs.tablePage.toggleRowSelection(item, true)
              }
            })
          })
        })
      },
      handleConfirm () {
        var newList = this.productList
        for (var item1 of this.selectItem) {
          let flag = true
          for (var item2 of newList) {
            if (item1.productId === item2.productId) {
              flag = false
            }
          }
          if (flag) {
            item1.productQuantity = 1
            newList.unshift(item1)
          }
        }
        this.$emit('update:productList', JSON.parse(JSON.stringify(newList)))
        this.addShopVisible = false
      },
      doRefresh () {
        this.$refs.tablePage.doRefresh({ currentPage: 0, pageSize: 10 })
      },
      closeConfirm () {
        this.addShopVisible = false
      },
      handleSearch () {
        this.doRefresh()
      },
      handleReset () {
        this.searchForm = {
          searchFiled: '',
          isOnSale: 'Y'
        }
        this.doRefresh()
      },
      queryData (data) {
        return pageIndexProduct({ ...data, condition: this.searchForm })
      },
      updated () {
        this.$refs.tablePage.doLayout()
      }

    }
  }
</script>
<style scoped lang="scss">
  .search-content {
    border-left: none;
    text-align: left;
  }
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "im-page-modal",
    {
      staticClass: "approval-page",
      attrs: { title: "审批流", isShowBack: false },
    },
    [
      _c(
        "div",
        { staticClass: "approval-list" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleTab },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "待处理", name: "WAIT_APPROVE" },
              }),
              _c("el-tab-pane", {
                attrs: { label: "通过", name: "APPROVE_SUCCESS" },
              }),
              _c("el-tab-pane", {
                attrs: { label: "驳回", name: "APPROVE_FAILURE" },
              }),
              _c("el-tab-pane", { attrs: { label: "已完成", name: "FINISH" } }),
            ],
            1
          ),
          _c(
            "im-table-page",
            {
              ref: "tablePage",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "remote-method": _vm.queryData,
                "row-key": "id",
              },
              on: {
                "update:data": function ($event) {
                  _vm.tableData = $event
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "名称",
                  prop: "buyersNm",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "订单编号",
                  prop: "orderNum",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "类型",
                  width: "115px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("退单")])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "状态",
                  prop: "nodeNewStatus",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.nodeNewStatus === "WAIT_APPROVE"
                          ? _c("span", [
                              _vm._v(
                                "待" +
                                  _vm._s(scope.row.nodeNewStatusName) +
                                  "审核"
                              ),
                            ])
                          : _vm._e(),
                        scope.row.nodeNewStatus === "APPROVE_FAILURE"
                          ? _c("span", [_vm._v("驳回")])
                          : _vm._e(),
                        scope.row.nodeNewStatus === "FINISH" &&
                        scope.row.refundStat === "REFUNDING"
                          ? _c("span", [_vm._v("通过（退款中）")])
                          : _vm._e(),
                        scope.row.nodeNewStatus === "FINISH" &&
                        scope.row.refundStat === "FINISH"
                          ? _c("span", [_vm._v("通过")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  prop: "createTime",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  align: "center",
                  width: "200",
                  label: "操作",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleSee(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "退款",
            visible: _vm.dialogVisible,
            width: "60%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "process-list" },
            [
              _c(
                "el-steps",
                { staticClass: "flow-box", attrs: { active: _vm.flowIndex } },
                _vm._l(_vm.processList, function (item, index) {
                  return _c(
                    "el-step",
                    {
                      key: index,
                      class: {
                        pass: _vm.setShowClass(item.approveStatus),
                        pass: _vm.setShowClass(item.approveStatus),
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "description" },
                        [
                          _c("el-card", { staticClass: "flow-card" }, [
                            _c("h4", { staticClass: "unser-name" }, [
                              _vm._v(
                                _vm._s(item.nodeName.split("节点")[0]) +
                                  ": " +
                                  _vm._s(item.approverName)
                              ),
                            ]),
                            item.approveStatus === "APPROVE_SUCCESS"
                              ? _c("p", { staticClass: "remarks" }, [
                                  _vm._v(
                                    "同意 | " +
                                      _vm._s(
                                        item.approveRemark != ""
                                          ? item.approveRemark
                                          : "无"
                                      )
                                  ),
                                ])
                              : item.approveStatus === "APPROVE_FAILURE"
                              ? _c("p", { staticClass: "remarks" }, [
                                  _vm._v(
                                    "驳回 | " +
                                      _vm._s(
                                        item.approveRemark != ""
                                          ? item.approveRemark
                                          : "无"
                                      )
                                  ),
                                ])
                              : _c("p", { staticClass: "remarks" }, [
                                  _vm._v(_vm._s(item.approveRemark)),
                                ]),
                            _c("span", { staticClass: "flow-time" }, [
                              _vm._v(
                                _vm._s(
                                  item.approveTime != null
                                    ? item.approveTime
                                    : ""
                                )
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticClass: "goods-list",
              staticStyle: { "margin-bottom": "10px" },
              attrs: { data: _vm.goodsList, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { property: "productCode", label: "商品编码" },
              }),
              _c("el-table-column", {
                attrs: { property: "productNm", label: "商品图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticStyle: { width: "60px", height: "60px" },
                          attrs: { src: scope.row.picture },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { property: "drugNm", label: "商品名称" },
              }),
              _c("el-table-column", {
                attrs: { property: "manufacturer", label: "数量" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.returnedQuantity != null
                                ? scope.row.returnedQuantity
                                : 1
                            )
                          ),
                        ]),
                        _c("span", [_vm._v(_vm._s(scope.row.unit))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { property: "totalAmount", label: "退款金额" },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "refund-amount" }, [
            _vm._v("退款总金额：￥" + _vm._s(_vm.refundAmount)),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.searchForm.approveStatus === "WAIT_APPROVE",
                  expression: "searchForm.approveStatus === 'WAIT_APPROVE'",
                },
              ],
              staticClass: "approval-operate",
            },
            [
              _c("label", { attrs: { for: "approvalText" } }, [_vm._v("备注")]),
              _c("el-input", {
                attrs: {
                  id: "approvalText",
                  type: "textarea",
                  placeholder: "请输入内容",
                  maxlength: "30",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.approveRemark,
                  callback: function ($$v) {
                    _vm.approveRemark = $$v
                  },
                  expression: "approveRemark",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.searchForm.approveStatus === "WAIT_APPROVE",
                  expression: "searchForm.approveStatus === 'WAIT_APPROVE'",
                },
              ],
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submit("APPROVE_SUCCESS")
                    },
                  },
                },
                [_vm._v("通 过")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.submit("APPROVE_FAILURE")
                    },
                  },
                },
                [_vm._v("驳 回")]
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.searchForm.approveStatus !== "WAIT_APPROVE",
                  expression: "searchForm.approveStatus !== 'WAIT_APPROVE'",
                },
              ],
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.approveLoading,
            expression: "approveLoading",
          },
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.approveLoading,
            expression: "approveLoading",
          },
        ],
        staticClass: "loading-mask",
        attrs: { "element-loading-text": "审批提交中" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "container",
      attrs: { width: "900px", title: "选择商品", visible: "" },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "div",
        { staticClass: "search" },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入商品名称/编码/厂家" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.onSearch.apply(null, arguments)
              },
            },
            model: {
              value: _vm.searchField,
              callback: function ($$v) {
                _vm.searchField = $$v
              },
              expression: "searchField",
            },
          }),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.onSearch },
            },
            [_vm._v("搜索")]
          ),
        ],
        1
      ),
      _c(
        "im-table-page",
        {
          ref: "tablePage",
          staticStyle: { width: "100%", "margin-top": "15px" },
          attrs: {
            data: _vm.tableData,
            "remote-method": _vm.queryData,
            addQuantity: true,
            index: true,
            moreCheckBox: (val) => {
              _vm.selectItem = val
            },
            "header-row-class-name": "header-row",
          },
          on: {
            "update:data": function ($event) {
              _vm.tableData = $event
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "60px", label: "选择" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "productCode",
              label: "商品编码",
              width: "100px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "productNm",
              align: "left",
              label: "商品名称",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$util.getGoodsName(scope.row)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "spec",
              label: "规格",
              width: "100px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "manufacturer",
              align: "center",
              label: "厂家(上市许可持有人)/产地",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(scope.row.manufacturer) +
                        _vm._s(scope.row.area ? "/" + scope.row.area : "") +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "unit",
              align: "center",
              label: "单位",
              width: "60px",
              "show-overflow-tooltip": "",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "button" },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "120px" },
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.onChoose },
            },
            [_vm._v("确认")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "120px" },
              attrs: { size: "small" },
              on: { click: _vm.onClose },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "registerDiv" },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          { staticClass: "registerHeader" },
          [
            _c(
              "router-link",
              { staticClass: "already-button", attrs: { to: "/" } },
              [
                _c("img", {
                  staticClass: "bgImg",
                  attrs: { src: require("@/assets/img/logo.gif"), alt: "" },
                }),
              ]
            ),
            _c("div", { staticClass: "division" }),
            _c("span", { staticClass: "title" }, [_vm._v("注册新用户")]),
            _c("span", { staticClass: "already" }, [
              _vm._v("已有账户？"),
              _c(
                "a",
                {
                  staticStyle: { "margin-right": "10px" },
                  on: { click: _vm.goLogin },
                },
                [_vm._v("立即登录")]
              ),
              _vm._v("|"),
              _c(
                "a",
                {
                  staticStyle: { "margin-left": "10px" },
                  on: { click: _vm.goHome },
                },
                [_vm._v("返回首页")]
              ),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "step" }, [
        _c("span", { staticClass: "stepNum" }, [_vm._v("1")]),
        _c("span", { staticClass: "stepTitle" }, [_vm._v("填写账户信息")]),
        _c("span", {
          staticClass: "stepLine",
          class: { stepLineNot: _vm.writeInfo },
        }),
        _c(
          "span",
          { staticClass: "stepNum", class: { stepNumNot: _vm.writeInfo } },
          [_vm._v("2")]
        ),
        _c(
          "span",
          { staticClass: "stepTitle", class: { stepTitleNot: _vm.writeInfo } },
          [_vm._v("完善资料并提交")]
        ),
        _c("span", {
          staticClass: "stepLine",
          class: { stepLineNot: _vm.writeSuccess },
        }),
        _c(
          "span",
          { staticClass: "stepNum", class: { stepNumNot: _vm.writeSuccess } },
          [_vm._v("3")]
        ),
        _c(
          "span",
          {
            staticClass: "stepTitle",
            class: { stepTitleNot: _vm.writeSuccess },
          },
          [_vm._v("资料提交审核")]
        ),
      ]),
      _c(
        "keep-alive",
        {
          attrs: {
            include: ["accountInfo", "improveInfo", "submittedSuccess"],
          },
        },
        [
          _c(_vm.currentView, {
            tag: "component",
            attrs: { params: _vm.params, customerName: _vm.customerName },
            on: { next: _vm.next },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "divisionClass" }),
      _c("Footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="registerDiv">
    <Search :return-security="isReturn"  :hidden-nav="true" :hidden-search="true" :originChange='origin' :hidden-search-title="changeTitle"/>
    <keep-alive :include="['verifyPhone', 'changeSuccess']">
      <component :is="currentView" @next="next" :params="params" :old-phone="oldphone"/>
    </keep-alive>
    <div class="divisionClass"></div>
  </div>
</template>

<script>
import Search from '@/components/Search/Search'
import changeSuccess from './change-success'
import verifyPhone from './verify-phone'


export default {
  name: 'ChangePhone',
   components: {
    Search,
    changeSuccess,
    verifyPhone
  },
  data () {
    return {
      currentView: 'verifyPhone',
      origin: this.$route.query.origin,
      changeTitle: 'memberChangePhone',
      params: '',
      isReturn: false,
      oldphone: this.$route.query.oldphone
    }
  },
  mounted(){
    if(this.origin === 'memberChangePhones'){
      this.currentView = 'changeSuccess';
      this.isReturn = true;
    }
  },
  methods: {
    next(index, params) {
      if (index === 2) {
        this.currentView = 'changeSuccess';
        this.isReturn = true;
      }else {
        this.currentView = 'verifyPhone'
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.registerDiv{
  width: 100%;
  text-align: center;

  .divisionClass{
    height: 20px;
    width: 100%;
    background: #F6F6F6;
  }
}
</style>


var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "im-page-modal",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "product-code-list-page",
      attrs: { title: "商品对码表", isShowBack: false },
    },
    [
      _c(
        "im-search-pad",
        {
          staticClass: "search-content",
          attrs: { model: _vm.searchForm, "has-expand": false },
          on: { search: _vm.handleSearch, reset: _vm.handleReset },
        },
        [
          _c(
            "im-search-pad-item",
            { attrs: { prop: "thirdProduct" } },
            [
              _vm._v(" 第三方商品信息： "),
              _c("el-input", {
                staticStyle: { width: "180px", height: "32px" },
                attrs: { placeholder: "商品编码/商品名称/生产厂家" },
                model: {
                  value: _vm.searchForm.thirdProduct,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "thirdProduct", $$v)
                  },
                  expression: "searchForm.thirdProduct",
                },
              }),
            ],
            1
          ),
          _vm._v("   "),
          _c(
            "im-search-pad-item",
            { attrs: { prop: "product" } },
            [
              _vm._v(" 平台商品信息： "),
              _c("el-input", {
                staticStyle: { width: "180px", height: "32px" },
                attrs: { placeholder: "商品编码/商品名称/生产厂家" },
                model: {
                  value: _vm.searchForm.product,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "product", $$v)
                  },
                  expression: "searchForm.product",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "im-table-page",
        {
          ref: "tablePage",
          staticStyle: { "margin-bottom": "15px" },
          attrs: { data: _vm.tableData, "remote-method": _vm.queryData },
          on: {
            "update:data": function ($event) {
              _vm.tableData = $event
            },
            updated: _vm.updated,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "商品编码",
              prop: "productCode",
              width: "180px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "商品规格",
              prop: "spec",
              "min-width": "160px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "商品单位",
              prop: "unit",
              "min-width": "120px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "生产厂家",
              prop: "manufacturer",
              "min-width": "180px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "鹭燕商品编码",
              prop: "thirdProductCode",
              width: "180px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "鹭燕商品名称",
              prop: "thirdProductName",
              "min-width": "180px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "规格",
              prop: "thirdProductSpec",
              "min-width": "100px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "单位",
              prop: "thirdProductUtil",
              "min-width": "60px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "生产厂家",
              prop: "thirdProductFactory",
              "min-width": "180px",
              "show-overflow-tooltip": "",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
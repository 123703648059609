<template>
  <div class="registerDiv">
    <div class="header">
      <div class="registerHeader">
        <router-link class="already-button" to="/"><img class="bgImg" src="@/assets/img/logo.gif" alt=""></router-link>
        <div class="division"></div>
        <span class="title">注册新用户</span>
        <span class="already">已有账户？<a @click="goLogin" style="margin-right: 10px">立即登录</a>|<a style="margin-left: 10px" @click="goHome">返回首页</a></span>
      </div>
    </div>
    <div class="step">
      <span class="stepNum">1</span>
      <span class="stepTitle">填写账户信息</span>
      <span class="stepLine" :class="{stepLineNot: writeInfo}"></span>
      <span class="stepNum" :class="{stepNumNot: writeInfo}">2</span>
      <span class="stepTitle" :class="{stepTitleNot: writeInfo}">完善资料并提交</span>
      <span class="stepLine" :class="{stepLineNot: writeSuccess}"></span>
      <span class="stepNum" :class="{stepNumNot: writeSuccess}">3</span>
      <span class="stepTitle" :class="{stepTitleNot: writeSuccess}">资料提交审核</span>
    </div>
    <keep-alive :include="['accountInfo','improveInfo', 'submittedSuccess']">
      <component :is="currentView" @next="next" :params="params" :customerName="customerName"/>
    </keep-alive>
    <div class="divisionClass"></div>
    <Footer></Footer>
  </div>
</template>

<script>

import accountInfo from './accountInfo'
import improveInfo from './improveInfo'
import submittedSuccess from './submittedSuccess'
import Footer from '@/components/Footer/Footer';
import {
  findRegisterBuyersInfoByLoginUser,
  saveUnableUser
} from "@/api/register";
export default {
  name: 'Register',
  components: {
    accountInfo,
    improveInfo,
    submittedSuccess,
    Footer
  },
  data () {
    return {
      currentView: 'accountInfo',
      registerKey: this.$route.query.registerKey,
      loginId: this.$route.query.loginId,
      form:{
        name: '',
        phone: '',
        password: '',
        passwordAgain: '',
        code: '',
        agreement: false
      },
      writeInfo: true,
      writeSuccess: true,
      params: {
        phone: ''
      },
      customerName: ''
    }
  },
  methods: {
    goHome(){
      this.$router.push('/')
    },
    goLogin(){
      this.$router.push(`/login`)
    },
    next(index, params) {
      if (index === 2) {
        this.writeInfo = false
        this.writeSuccess = true
        this.params = params
        this.currentView = 'improveInfo'
      }else if (index === 3 ) {
        window.scrollTo(0,0);
        this.writeInfo = false
        this.writeSuccess = false
        this.customerName = params.customerName
        this.params = {
          ...params,
          registerKey:params.registerKey,
          isChange:true
        }
        this.currentView = 'submittedSuccess'
      }else {
        this.writeInfo = true
        this.writeSuccess = true
        this.currentView = 'accountInfo'
      }
    }
  },
  mounted() {
    if(this.registerKey){
      this.currentView = this.$route.query.currentView || ''
      findRegisterBuyersInfoByLoginUser(this.registerKey).then(res => {
        this.params = {
          ...res.data,
          registerKey: this.registerKey,
          isChange: false,
          registerLoginId: this.loginId,
        }
        console.log(res.data)
        if(res.data.registerBuyersInfoId){
          this.params.loginMobileId = res.data.loginMobileId
          this.params.isChange = true
          this.customerName = res.data.customerName
          this.writeInfo = false
          this.writeSuccess = false
          this.currentView = 'submittedSuccess'
        } else {
          this.params.loginMobileId = res.data.loginMobileId
          this.writeInfo = false
          this.writeSuccess = true
          this.currentView = 'improveInfo'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.registerDiv{
  width: 100%;
  text-align: center;
  .header {
    height: 100px;
    width: 100%;
    text-align: center;
    box-shadow:0px 4px 8px 0px rgba(0,0,0,0.08);
    .registerHeader {
      width: 1200px;
      margin: 0 auto;
      text-align: left;
      display: flex;
      .bgImg {
        width: 230px;
        height: 80px;
        margin-top: 10px;
      }
      .division {
        width:1px;
        height:40px;
        background:rgba(204,204,204,1);
        margin: 30px 20px 0 20px;
      }
      .title{
        font-size:20px;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(34,34,34,1);
        line-height: 100px;
      }
      .already{
        font-size:12px;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color: #666;
        line-height:100px;
        margin-left: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        a{
          @include TextHighlightColor
        }
      }
    }
  }
  .step {
    display: flex;
    width: 600px;
    margin: 40px auto 0 auto;
    .stepNum {
      width: 21px;
      height: 21px;
      border-radius: 10px;
      text-align: center;
      line-height: 21px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      @include StepNumColor
    }
    .stepNumNot {
      background-color: #E5E5E5;
      color: #999999;
    }
    .stepTitle {
      line-height: 20px;
      color: #222222;
      margin-left: 8px;
      font-weight: 700;
      font-size: 16px;
    }
    .stepTitleNot {
      color: #A3A3A3;
    }
    .stepLine {
      width: 60px;
      height: 1px;
      @include StepLineColor;
      margin: auto 16px;
    }
    .stepLineNot {
      background-color: #DDDDDD;
    }
  }
  .registerFrom {
    width: 400px;
    margin: 40px auto;
    input{
      padding-left: 48px;
    }
    .input-icon {
      position: absolute;
      left: 12px;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      .img-user {
        width: 14px;
        height: 16px;
      }
      .img-suo {
        width: 13px;
        height: 16px;
      }
    }
    .checkSpan {
      margin-left: 10px;
      font-size: 12px;
      color: #A3A3A3;
      a {
        @include TextHighlightColor
      }
    }
  }
  .checkFrom{
    text-align: left;
    margin-bottom: 10px;
  }
  .sureBut {
    width: 400px;
    height: 40px;
    background-color: #0971EB;
  }
  .divisionClass{
    height: 20px;
    width: 100%;
    background: #F6F6F6;
  }
}
</style>

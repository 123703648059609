<template>
  <div class="main">
    <!-- 搜索、导航栏 -->
    <Search :index-nav="false"/>
    <!--医疗执照轮播图-->
    <div class="licence-banner">
      <el-carousel :interval="5000" height="110px" arrow="never">
        <el-carousel-item v-for="(item, index) in licence.PC_LICENCE" :key="index">
          <img style="width: 100%;height: 100%" v-if="licence.PC_LICENCE.length" :src="item.sysFileUrl" @click="bindUrl(item.link)" alt>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 头部轮播 -->
    <div class="header-container">
      <!-- 左边背景 -->
      <div class="img" v-if="page.PC_BANNER_LEFT.length" @click="bindUrl(page.PC_BANNER_LEFT[0].link)"
           :style="{backgroundImage:(!page.PC_BANNER_LEFT.length)?'none':'url(' + page.PC_BANNER_LEFT[0].sysFileUrl + ')'}"></div>
      <!-- 中间部分 -->
      <div class="nav-body">
        <!-- 中间部分 -->
        <div class="nav-content">
          <!-- 轮播图 -->
          <div class="nav-carousel">
            <el-carousel :interval="5000" arrow="always" height="270px">
              <el-carousel-item v-for="(item, index) in page.PC_BANNER" :key="index">
                <img style="width: 100%;height: 100%" v-if="page.PC_BANNER.length" :src="item.sysFileUrl" @click="bindUrl(item.link)" alt>
              </el-carousel-item>
            </el-carousel>
            <div class="nav-show">
              <img v-if="page.PC_BANNER_F1.length" :src="page.PC_BANNER_F1[0].sysFileUrl"
                   @click="bindUrl(page.PC_BANNER_F1[0].link)" alt class="nav-show-left">
              <img v-if="page.PC_BANNER_F2.length" :src="page.PC_BANNER_F2[0].sysFileUrl"
                   @click="bindUrl(page.PC_BANNER_F2[0].link)" alt class="nav-show-right">
            </div>
          </div>
          <!-- 个人中心 -->
          <div class="nav-person" v-if="buyersVo.buyersNm">
            <div class="nav-person-img2">
              <img :src="require('@/assets/img/nav/person2.png')" alt>
              <div>
                <p>Hi，{{buyersVo.buyersNm}}</p>
                <p class="last" v-if="userDetail.memberDetailsVo.isCredit === 'Y'">
                  可用额度：¥{{userDetail.memberDetailsVo.availableCredit || 0}}</p>
              </div>
            </div>
            <div class="nav-person-list" v-if="userDetail.orderStateVos">
              <div class="list-button" @click="jumpOrder('WAIT_PAY')">
                <p class="num">{{userDetail.orderStateVos.toBePaid || 0}}</p>
                <p class="text">待付款</p>
              </div>
              <div class="list-button" @click="jumpOrder('WAIT_SEND')">
                <p class="num">{{userDetail.orderStateVos.toBeDelivered || 0}}</p>
                <p class="text">待发货</p>
              </div>
              <div class="list-button" @click="jumpOrder('SEND')">
                <p class="num">{{userDetail.orderStateVos.toBeReceived || 0}}</p>
                <p class="text">待收货</p>
              </div>
              <div class="list-button" @click="jumpOrder('WAIT_APPROVE')">
                <p class="num">{{userDetail.orderStateVos.toBConfirmed || 0}}</p>
                <p class="text">待确认</p>
              </div>
            </div>
          </div>
          <div class="nav-person" v-else>
            <div class="nav-person-img">
              <img src="../../assets/img/nav/person.png" alt>
              <p>Hi，欢迎来到{{defaultSettings.title}}</p>
            </div>
            <div class="nav-person-button">
              <router-link to="/login">
                <el-button size="small" round>登录</el-button>
              </router-link>
              <router-link to="/register" class="last">
                <el-button size="small" round>注册</el-button>
              </router-link>
            </div>
          </div>
          <!-- 公告栏 -->
          <div class="nav-notice">
            <p v-if="page.PC_INFORMATION_TOP_LINK.length" class="nav-notice-more">
              {{ page.PC_INFORMATION_TOP_LINK[0].title }}
              <span @click="infoCheckMore('categoryNm')">更多</span>
            </p>
            <ul v-if="page['PC_INFORMATION'] && page['PC_INFORMATION'].length">
              <li v-for="(item,index) in page['PC_INFORMATION'][0].infArticleVos" :key="index" v-show="index<6"
                  @click="jumpInfo(item)">
                {{index+1}}、{{item.title}}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 右边背景 -->
      <div class="img img-r" v-if="page.PC_BANNER_RIGHT.length" @click="bindUrl(page.PC_BANNER_RIGHT[0].link)"
           :style="{backgroundImage:(!page.PC_BANNER_RIGHT.length)?'none':'url(' + page.PC_BANNER_RIGHT[0].sysFileUrl + ')'}"></div>
    </div>
    <!-- 内容 -->
    <div class="content" id="content">
      <!-- 限时抢购 -->
      <div class="pink-bg bbb scroll-item" v-if="flashSaleList.length">
        <div class="commodity">
          <!-- 头部 -->
          <div class="commodity-head" v-if="page.PC_SHOP_LINK_TOP_F1.length || showCountDown">
            <div class="commodity-text">
              <div>
                <span v-if="page.PC_SHOP_LINK_TOP_F1.length" class="commodity-title">{{ page.PC_SHOP_LINK_TOP_F1[0].title }}</span>
                <span v-if="page.PC_SHOP_LINK_TOP_F1.length" class="commodity-remarks">{{ page.PC_SHOP_LINK_TOP_F1[0].fitTitle }}</span>
                <div class="count-down" v-if="showCountDown">
                  <span class="count-down-text">距离结束剩余</span>
                  <span class="count-down-num count-down-hour">{{ timeList.day.split('')[0] }}</span>
                  <span class="count-down-num count-down-hour">{{ timeList.day.split('')[1] }}</span>
                  <span class="count-down-point">天</span>
                  <span class="count-down-num count-down-minute">{{ timeList.hou.split('')[0] }}</span>
                  <span class="count-down-num count-down-minute">{{ timeList.hou.split('')[1] }}</span>
                  <span class="count-down-point">:</span>
                  <span class="count-down-num count-down-seconds">{{ timeList.min.split('')[0] }}</span>
                  <span class="count-down-num count-down-seconds">{{ timeList.min.split('')[1] }}</span>
                  <span class="count-down-point">:</span>
                  <span class="count-down-num count-down-seconds">{{ timeList.sec.split('')[0] }}</span>
                  <span class="count-down-num count-down-seconds">{{ timeList.sec.split('')[1] }}</span>
                </div>
                <router-link to="/discount-time">
                  <span class="commodity-more">更多<i class="el-icon-arrow-right"></i></span>
                </router-link>
              </div>
            </div>
          </div>
          <!-- 内容 -->
          <div class="commodity-content">
            <div class="commodity-explain commodity-explain-width" @click="bindUrl(page.PC_SHOP_ADV_F1[0].link)"
                 :style="{backgroundImage:(!page.PC_SHOP_ADV_F1.length)?'none':'url(' + page.PC_SHOP_ADV_F1[0].sysFileUrl + ')'}"></div>
            <div class="commodity-item" v-for="(item, index) in flashSaleList" :key="index" v-show="index < 5">
               <div class="identifying" v-if="userType =='SALESMAN'">
                  <img v-if="item.whiteRecordFlag  == 1" alt src="@/assets/img/icon/whiterecord.png">
                  <img v-else-if="item.blackRecordFlag  == 1" alt src="@/assets/img/icon/blackrecord.png">
               </div>
              <div class="commodity-item-img">
                <!--                <div class="commodity-item-discount">4.1<span>折</span></div>-->
                <router-link :to="{path: '/detail/' + item.productId}">
                  <img alt :src="item.prcUrl144 ||item.pictUrls || item.picUrl || productDefaultImg" @error="item.pictUrls = productDefaultImg">
                </router-link>
              </div>
              <div class="commodity-item-info">
                <router-link :to="{path: '/detail/' + item.productId}"><p class="commodity-item-name">
                  <p class="commodity-item-name">
                  {{$util.getGoodsName(item)}}
                  </p>
                </router-link>
                <div class="commodity-item-detail">
                  <p>{{ item.spec }}/{{ item.unit }}</p>
                  <p>{{ item.manufacturer }}</p>
                </div>
                <div v-if="item.visible && item.priceStr !== '登录可见'">
                  <div v-if="item.price == 0">
                    <p class="commodity-price">未报价</p>
                  </div>
                  <div v-else>
                    <p class="commodity-price">{{$util.getPriceByZero(item.price,item)}}</p>
                    <p class="commodity-price commodity-price-sub">¥{{item.salePrice}}</p>
                  </div>
                </div>
                <div v-else>
                  <p class="commodity-price" style="margin-bottom: 25px">{{showPrice({priceStr:'登录可见'})}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 折扣 -->
      <div class="offer aaa scroll-item" v-if="page.PC_SHOP_PRODUCT_F2.length">
        <div class="commodity">
          <!-- 头部 -->
          <div class="commodity-head" v-if="page.PC_SHOP_LINK_TOP_F2.length">
            <div class="commodity-text">
              <div v-if="page.PC_SHOP_LINK_TOP_F2.length">
                <span class="commodity-title">{{ page.PC_SHOP_LINK_TOP_F2[0].title }}</span>
                <span class="commodity-remarks">{{ page.PC_SHOP_LINK_TOP_F2[0].fitTitle }}</span>
              </div>
            </div>
          </div>
          <!-- 内容 -->
          <div class="commodity-content" v-if="page.PC_SHOP_ADV_F2.length > 0 || page.PC_SHOP_PRODUCT_F2.length> 0">
            <div class="commodity-explain commodity-explain-width" @click="bindUrl(page.PC_SHOP_ADV_F2[0].link)"
                 :style="{backgroundImage:(!page.PC_SHOP_ADV_F2.length)?'none':'url(' + page.PC_SHOP_ADV_F2[0].sysFileUrl + ')'}"></div>
            <div class="commodity-explain-bg">
              <img alt src="../../assets/img/index/bg-2-2.png">
            </div>
            <div v-for="(productList, index) in page.PC_SHOP_PRODUCT_F2" :key="index" class="commodity-item">
              <div class="identifying" v-if="userType =='SALESMAN'">
                <img v-if="productList.whiteRecordFlag  == 1" alt src="@/assets/img/icon/whiterecord.png">
                <img v-else-if="productList.blackRecordFlag  == 1" alt src="@/assets/img/icon/blackrecord.png">
              </div>
              <div class="commodity-item-img">
                <router-link :to="{path: '/detail/' + productList.productId}">
                  <img alt :src="productList.productVo.pictUrl144 || productList.productVo.pictUrls || productDefaultImg"
                       @error="productList.productVo.pictUrls = productDefaultImg">
                </router-link>
              </div>
              <div class="commodity-item-info">
                <router-link :to="{path: '/detail/' + productList.productId}">
                  <p class="commodity-item-name">
                    {{$util.getGoodsName(productList.productVo)}}
                  </p>
                </router-link>

                <div class="commodity-item-detail">
                  <p>{{ productList.productVo.spec }}/{{ productList.productVo.unit }}</p>
                  <p>{{ productList.productVo.manufacturer }}</p>
                </div>

                <div>
                  <p class="commodity-price" style="margin: 15px 0 0">{{$util.getPriceByZero(productList.productVo.price,productList.productVo)}}</p>
                  <p class="commodity-price commodity-price-sub" style="margin: 5px 0 0" v-if="productList.productVo.isDiscount === 'Y'">¥{{productList.productVo.salePrice}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 领券中心 -->
      <div class="coupon commodity scroll-item" v-if="page.PC_SHOP_LINK_TOP_F3.length && couponItems.length">
        <!-- 头部 -->
        <div class="commodity-head" v-if="page.PC_SHOP_LINK_TOP_F3.length">
          <div class="commodity-text">
            <div v-if="page.PC_SHOP_LINK_TOP_F3.length">
              <span class="commodity-title">{{ page.PC_SHOP_LINK_TOP_F3[0].title }}</span>
              <span class="commodity-remarks">{{ page.PC_SHOP_LINK_TOP_F3[0].fitTitle }}</span>
            </div>
          </div>
        </div>
        <!-- 内容 -->
        <div class="commodity-content" v-if="page.PC_SHOP_ADV_F3.length > 0 || couponItems.length > 0">
          <div class="commodity-explain" @click="couponCheckMore"
               :style="{backgroundImage:(!page.PC_SHOP_ADV_F3.length)?'none':'url(' + page.PC_SHOP_ADV_F3[0].sysFileUrl + ')'}"></div>
          <div class="coupon-list">
            <div class="coupon-item" v-for="(item, index) in couponItems" :key="index" v-show="index < 3">
              <p class="coupon-condition" v-if="item.thresholdType === 'NONE'">
                无使用门槛
                <el-tooltip v-if="item.illustrate" class="item" effect="dark" :content="item.illustrate" placement="top-start">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </p>
              <p class="coupon-condition" v-else>
                满{{item.fullAmount}}元可用
                <el-tooltip v-if="item.illustrate" class="item" effect="dark" :content="item.illustrate" placement="top-start">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </p>
              <div v-if="item.couponType === 'FULL_REDUCTION'">
                <p class="coupon-price"><span>¥</span><b>{{item.fullReductionAmount}}</b></p>
                <p class="coupon-user">{{item.couponNm}}</p><!--[满减券]-->
              </div>
              <div v-else>
                <p class="coupon-price"><b>{{item.discount}}</b><span>折</span></p>
                <p class="coupon-user">{{item.couponNm}}</p><!--[折扣券]-->
              </div>
              <p class="coupon-time" v-if="item.useTimeType === 'IN_THE_DAY'">领券当日起{{item.useValidDay}}天内可用</p>
              <p class="coupon-time" v-else-if="item.useTimeType === 'TIMES_OF_THE_DAY'">领券次日起{{ item.useValidDay
                }}天内可用</p>
              <p class="coupon-time" v-else>{{item.useStartTime}} - {{item.useEndTime}}</p>
              <p
                  class="coupon-button"
                  @click="onCoupon(item)"
                  v-if="item.isLimitTimes === 'Y' && item.userReceivedQuantity < item.limitNumber && item.remainQuantity > 0 && item.userReceivedQuantity < item.remainQuantity"
              >立即领取</p>
              <p class="coupon-button" @click="onCoupon(item)" v-if="item.isLimitTimes === 'N' && item.remainQuantity > 0">立即领取</p>
              <p class="coupon-button coupon-disabled" v-else-if="item.userReceivedQuantity >= item.limitNumber && item.userReceivedQuantity > 0">已领取</p>
              <p class="coupon-button coupon-disabled" v-else-if="item.remainQuantity <= 0 && item.userReceivedQuantity <= 0">已抢光</p>
<!--              <div class="button" @click="handleReceive(item)"-->
<!--                   v-if="item.isLimitTimes === 'Y' && item.userReceivedQuantity < item.limitNumber && item.remainQuantity > 0 && item.userReceivedQuantity < item.remainQuantity">-->
<!--                <p>立即领券</p>-->
<!--              </div>-->
<!--              <div class="button" @click="handleReceive(item)"-->
<!--                   v-if="item.isLimitTimes === 'N' && item.remainQuantity > 0">-->
<!--                <p>立即领券</p>-->
<!--              </div>-->
<!--              <div class="button isReceive" v-else-if="item.userReceivedQuantity >= item.limitNumber && item.userReceivedQuantity > 0">-->
<!--                <p>已领取</p>-->
<!--              </div>-->
<!--              <div class="button disabled" v-else-if="item.remainQuantity <= 0 && item.userReceivedQuantity <= 0">-->
<!--                <p>已抢光</p>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
      <!-- 精选推荐 -->
      <div class="recommend commodity scroll-item" v-if="page.PC_SHOP_LINK_TOP_F4.length && (page.PC_SHOP_ADV_F4.length || productItems.length)">
        <!-- 头部 -->
        <div class="commodity-head" v-if="page.PC_SHOP_LINK_TOP_F4.length">
          <div class="commodity-text">
            <div v-if="page.PC_SHOP_LINK_TOP_F4.length">
              <span class="commodity-title">{{ page.PC_SHOP_LINK_TOP_F4[0].title }}</span>
              <span class="commodity-remarks">{{ page.PC_SHOP_LINK_TOP_F4[0].fitTitle }}</span>
            </div>
          </div>
        </div>
        <!-- 内容 -->
        <div v-for="(item, index) in page.PC_SHOP_ADV_F4" :key="index" class="recommend-img">
          <img v-if="page.PC_SHOP_ADV_F4.length" @click="bindUrl(item.link)" :src="item.sysFileUrl" alt>
        </div>
      </div>
      <!-- 广告横幅 -->
      <div class="advertisement" v-if="page.PC_SHOP_ADV_F5.length">
        <!-- 内容 -->
        <div class="advertisement-content">
          <div v-for="(item, index) in page.PC_SHOP_ADV_F5" :key="index" class="advertisement-img" v-if="item.sysFileUrl">
            <img @click="bindUrl(item.link)" :src="item.sysFileUrl" alt>
          </div>
        </div>
      </div>
      <!-- 系列产品 -->
      <div class="product scroll-item" v-for="(item, index) in productItems" v-if="page['PC_SHOP_PRODUCT_F'+ ( index + 6 )].length" :key="index">
        <div class="product-top">
          <div class="product-top-left" :style="{'background':item.color}">
            <div class="product-top-title" v-if="page['PC_SHOP_LINK_F'+ ( index + 6 ) +'_1'].length">
              {{ page['PC_SHOP_LINK_F'+ ( index + 6 ) +'_1'][0].title }}
            </div>
            <div v-if="page['PC_SHOP_LINK_F'+ ( index + 6 ) +'_2'].length">
              <router-link v-for="(t, i) in page['PC_SHOP_LINK_F'+ ( index + 6 ) +'_2']" :to="t.titleLink" :key="i"
                           class="product-top-label">{{ t.title }}
              </router-link>
            </div>
          </div>
          <div class="product-top-left-bg"
               v-if="page['PC_SHOP_LINK_F'+ ( index + 6 ) +'_1'].length || page['PC_SHOP_LINK_F'+ ( index + 6 ) +'_2'].length || page['PC_SHOP_ADV_F'+ ( index + 6 ) +'_3'].length || page['PC_SHOP_ADV_F'+ ( index + 6 ) +'_4'].length"
          >
            <img alt :src="item.bg">
          </div>
          <img class="img-left" v-if="page['PC_SHOP_ADV_F'+ ( index + 6 ) +'_3'].length" alt
               @click="bindUrl(page['PC_SHOP_ADV_F'+ ( index + 6 ) +'_3'][0].link)"
               :src="page['PC_SHOP_ADV_F'+ ( index + 6 ) +'_3'][0].sysFileUrl">
          <img class="img-right" v-if="page['PC_SHOP_ADV_F'+ ( index + 6 ) +'_4'].length" alt
               @click="bindUrl(page['PC_SHOP_ADV_F'+ ( index + 6 ) +'_4'][0].link)"
               :src="page['PC_SHOP_ADV_F'+ ( index + 6 ) +'_4'][0].sysFileUrl">
        </div>
        <div class="product-list" v-if="page['PC_SHOP_PRODUCT_F'+ ( index + 6 )].length">
          <div v-for="(productList, i) in page['PC_SHOP_PRODUCT_F'+ ( index + 6 )]" :key="i" class="product-item">
            <div class="identifying" v-if="userType =='SALESMAN'">
              <img v-if="productList.whiteRecordFlag  == 1" alt src="@/assets/img/icon/whiterecord.png">
              <img v-else-if="productList.blackRecordFlag  == 1" alt src="@/assets/img/icon/blackrecord.png">
            </div>
            <div class="product-item-img">
              <router-link :to="{path: '/detail/' + productList.productId}">
                <img alt :src="productList.productVo.pictUrls || productDefaultImg"
                     @error="productList.productVo.pictUrls = productDefaultImg">
              </router-link>
            </div>
            <div class="product-item-info">
              <router-link :to="{path: '/detail/' + productList.productId}">
                <p class="name">
                  {{$util.getGoodsName(productList.productVo)}}
                </p>
              </router-link>
              <div class="product-item-detail">
                <p>{{ productList.productVo.spec }}/{{ productList.productVo.unit }}{{ productList.whiteRecordFlag }}</p>
                <p>{{ productList.productVo.manufacturer }}</p>
              </div>
              <p class="product-price">{{ showPrice(productList.productVo) }}</p>
            </div>
          </div>
        </div>
        <div class="product-logo" v-if="page['PC_BRAND_F'+ ( index + 1 )].length">
          <div class="img" v-for="(logo, i) in page['PC_BRAND_F'+ ( index + 1 )]" :key="i">
            <router-link :to="{path: '/brand-detail/' + logo.brandId}">
            <img alt class="product-logo-img" :src="logo.brandLogo || brandDefaultImg"
                 @error="logo.brandLogo = brandDefaultImg">
            </router-link>
          </div>
        </div>
      </div>
      <!-- 加载过程 -->
      <div v-if="showIndex" v-loading="showIndex" class="show_loading"></div>
      <!-- 末尾横幅 -->
      <div class="footer-banner" v-if="page.PC_SHOP_ADV_F11.length">
        <img v-if="page.PC_SHOP_ADV_F11.length" @click="bindUrl(page.PC_SHOP_ADV_F11[0].link)" :src="page.PC_SHOP_ADV_F11[0].sysFileUrl" alt>
      </div>
      <!-- 医疗资讯 -->
      <div class="information commodity" v-if="page.PC_SHOP_LINK_TOP_F5.length">
        <!-- 头部 -->
        <div class="commodity-head" v-if="page.PC_SHOP_LINK_TOP_F5.length">
          <div class="commodity-text">
            <div v-if="page.PC_SHOP_LINK_TOP_F5.length">
              <span class="commodity-title">{{ page.PC_SHOP_LINK_TOP_F5[0].title }}</span>
              <span class="commodity-remarks">{{ page.PC_SHOP_LINK_TOP_F5[0].fitTitle }}</span>
              <span class="commodity-more">更多<i class="el-icon-arrow-right"></i></span>
            </div>
          </div>
        </div>
        <!-- 内容 -->
        <div class="item">
          <div class="item-lt" v-for="(item, index) in 3" :key="index" :class="{'active': noticeActive[index]}"
               @mouseover="selectStyle(index)">
            <transition name="fade">
              <div class="item-lt-content item-lt-bg">
                <div class="item-list">
                  <div v-if="page['PC_BOTTOM_LINK_'+ ( index + 1 )].length">
                    <div class="list-name">{{ page['PC_BOTTOM_LINK_'+ ( index + 1 )][0].title }}</div>
                    <div class="list-line"></div>
                    <div class="list-name-english">{{ page['PC_BOTTOM_LINK_'+ ( index + 1 )][0].fitTitle }}</div>
                    <div class="more" @click="infoCheckMore(page['PC_BOTTOM_LINK_'+ ( index + 1 )][0].title)">查看更多</div>
                  </div>
                </div>
                <transition>
                  <div v-show="noticeActive[index]" class="item-notice">
                    <div class="notice-content">
                      <ul v-if="page['PC_BOTTOM_INFORMATION_B'+ ( index + 1 )] && page['PC_BOTTOM_INFORMATION_B'+ ( index + 1 )].length">
                        <li v-for="(c, i) in page['PC_BOTTOM_INFORMATION_B'+ ( index + 1 )][0].infArticleVos" :key="i"
                            v-show="i<6"
                            @click="jumpInfo(c)">
                          <span>{{ c.title }}</span>
                          <div class="notice-time">{{ c.createTime ? c.createTime.substring(0, 10) : '' }}</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </transition>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <!-- 友情链接 -->
      <div class="link" v-if="page.PC_BOTTOM_LINK_B1.length">
        <div class="title">友情链接</div>
        <div v-if="page.PC_BOTTOM_LINK_B1.length" class="link-list">
          <div v-for="(item, index) in page.PC_BOTTOM_LINK_B1" :key="index" class="link-item">
            <a :href="item.titleLink" target="_blank" :title="item.title">{{ item.title }}</a>
            <i></i>
          </div>
        </div>
      </div>
      <!-- 左侧边栏 -->
      <div v-if="toolbar.length" class="left-toolbar" :class="{'scroll-fixed': toolbarFixed}">
        <div class="toolbar-tabs">
          <div class="toolbar-bd" id="toolbar">
            <div class="toolbar-item" v-if="userType ==='BUYERS'">
              <div class="arrival-num">{{ arrivalGoodsNum }}</div>
              <router-link to='/arrival-reminder'>
                <a>到货提醒</a>
              </router-link>
            </div>
            <div class="toolbar-item" v-else-if="userType ==='SALESMAN'" @click="getAccountId">
              <div class="arrival-num">{{ arrivalGoodsNum }}</div>
              <a>到货提醒</a>
            </div>
            <div class="toolbar-item" v-for="(item, index) in toolbar" :key="index" @click="toolbarScroll(index)">
              <a :class="{'cur': item.active}">{{item.name}}</a>
            </div>
            <div @click="toolbarScroll('top')" class="toolbar-item">
              <div class="arrow-font" id="backtop"><i class="el-icon-arrow-up"></i>回到顶部</div>
            </div>
          </div>
          <div class="toolbar-ft">
            <div class="pic">
              <img alt style="width: 100%" src="@/assets/img/index/logo-right.png">
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧边栏 -->
      <ToolBar/>
      <!-- 弹窗广告 -->
      <Popup v-if="header.PC_BANNER_ADV_1 && !!userType" :img-url="header.PC_BANNER_ADV_1 || []"/>
    </div>
  </div>
</template>

<script>
  import Search from '@/components/Search/Search'
  import ToolBar from '@/components/ToolBar/ToolBar'
  import Popup from '@/components/Popup/Popup'
  import { scrollAnimation, countDown } from '@/utils'
  import { mapGetters } from 'vuex'
  import {
    getDiscountRuleStart,
    pageDiscountProductRel,
    pageCouponList,
    saveCoupon,
    addInfArticleViews
  } from '@/api/index/index'
  import brandDefaultImg from '@/assets/img/index/brand_default.png'
  import productDefaultImg from '@/assets/img/index/product_default.png'
  import { getToken, getAccount } from '@/utils/auth'
  import {ArrivalReminderCount} from '@/api/arrival-reminder/index'

  export default {
    name: 'index',
    components: {
      Search,
      ToolBar,
      Popup
    },
    data () {
      return {
        productDefaultImg,
        brandDefaultImg,
        toolbarFixed: false,
        productItems: [
          {
            bg: require('../../assets/img/index/product/bg1.png'),
            color: 'linear-gradient(135deg, #70B6FE 0%, #5A86E7 100%)'
          },
          {
            bg: require('../../assets/img/index/product/bg2.png'),
            color: 'linear-gradient(135deg, #F5D33C 0%, #F5D33C 100%)'
          },
          {
            bg: require('../../assets/img/index/product/bg3.png'),
            color: 'linear-gradient(135deg, #A6E056 0%, #84B421 100%)'
          },
          {
            bg: require('../../assets/img/index/product/bg4.png'),
            color: 'linear-gradient(135deg, #D48FEC 0%, #A677DF 100%)'
          },
          {
            bg: require('../../assets/img/index/product/bg5.png'),
            color: 'linear-gradient(135deg, #F698B0 0%, #ED5F74 100%)'
          }
        ],
        noticeActive: [true, false, false],
        page: {
          PC_SHOP_ADV_F1: [],
          PC_SHOP_LINK_TOP_F1: [],
          PC_SHOP_ADV_F2: [],
          PC_SHOP_LINK_TOP_F2: [],
          PC_SHOP_PRODUCT_F2: [],
          PC_SHOP_LINK_TOP_F3: [],
          PC_SHOP_ADV_F3: [],
          PC_SHOP_LINK_TOP_F4: [],
          PC_SHOP_ADV_F4: [],
          PC_SHOP_ADV_F5: [],
          PC_SHOP_LINK_F6_1: [],
          PC_SHOP_LINK_F6_2: [],
          PC_SHOP_ADV_F6_3: [],
          PC_SHOP_ADV_F6_4: [],
          PC_SHOP_PRODUCT_F6: [],
          PC_BRAND_F1: [],
          PC_SHOP_LINK_F7_1: [],
          PC_SHOP_LINK_F7_2: [],
          PC_SHOP_ADV_F7_3: [],
          PC_SHOP_ADV_F7_4: [],
          PC_SHOP_PRODUCT_F7: [],
          PC_BRAND_F2: [],
          PC_SHOP_LINK_F8_1: [],
          PC_SHOP_LINK_F8_2: [],
          PC_SHOP_ADV_F8_3: [],
          PC_SHOP_ADV_F8_4: [],
          PC_SHOP_PRODUCT_F8: [],
          PC_BRAND_F3: [],
          PC_SHOP_LINK_F9_1: [],
          PC_SHOP_LINK_F9_2: [],
          PC_SHOP_ADV_F9_3: [],
          PC_SHOP_ADV_F9_4: [],
          PC_SHOP_PRODUCT_F9: [],
          PC_BRAND_F4: [],
          PC_SHOP_LINK_F10_1: [],
          PC_SHOP_LINK_F10_2: [],
          PC_SHOP_ADV_F10_3: [],
          PC_SHOP_ADV_F10_4: [],
          PC_SHOP_PRODUCT_F10: [],
          PC_BRAND_F5: [],
          PC_SHOP_ADV_F11: [],
          PC_SHOP_LINK_TOP_F5: [],
          PC_BOTTOM_LINK_1: [],
          PC_BOTTOM_LINK_2: [],
          PC_BOTTOM_LINK_3: [],
          PC_BOTTOM_LINK_B1: [],
          PC_BANNER_LEFT: [],
          PC_BANNER_RIGHT: [],
          PC_BANNER: [],
          PC_BANNER_F1: [],
          PC_BANNER_F2: [],
          PC_INFORMATION_TOP_LINK: []
        },
        licence: {
          PC_LICENCE:[
            {
              sysFileUrl: 'https://b2b-image.eshop-luyan.com.cn/common/licence/1gfzz.jpg',
              link:'https://b2b-image.eshop-luyan.com.cn/common/licence/1gfzz.jpg'
            },
            {
              sysFileUrl: 'https://b2b-image.eshop-luyan.com.cn/common/licence/2ypjyxk.jpg',
              link:'https://b2b-image.eshop-luyan.com.cn/common/licence/2ypjyxk.jpg'
            },
            {
              sysFileUrl: 'https://b2b-image.eshop-luyan.com.cn/common/licence/3ylqxxk.jpg',
              link:'https://b2b-image.eshop-luyan.com.cn/common/licence/3ylqxxk.jpg'
            },
            {
              sysFileUrl: 'https://b2b-image.eshop-luyan.com.cn/common/licence/4elylqxxk.jpg',
              link:'https://b2b-image.eshop-luyan.com.cn/common/licence/4elylqxxk.jpg'
            },
            {
              sysFileUrl: 'https://b2b-image.eshop-luyan.com.cn/common/licence/5gfhlwypxxfwzgzs.jpg',
              link:'https://b2b-image.eshop-luyan.com.cn/common/licence/5gfhlwypxxfwzgzs.jpg'
            },
            {
              sysFileUrl: 'https://b2b-image.eshop-luyan.com.cn/common/licence/6spjyxk.jpg',
              link:'https://b2b-image.eshop-luyan.com.cn/common/licence/6spjyxk.jpg'
            },
          ]
        },
        toolbar: [],
        showCountDown:false,
        flashSaleList: [],
        couponItems: [],
        timeList: {
          day: '00',
          hou: '00',
          min: '00',
          sec: '00'
        },
        arrivalGoodsNum: 0
      }
    },
    activated () {
      this.ArrivalReminderCount()
      this.getModule()
      window.addEventListener('scroll', this.handleScroll)
    },
    deactivated () {
      window.removeEventListener('scroll', this.handleScroll)
    },
    destroyed () {
      if (this.setIntervalObj) {
        clearInterval(this.setIntervalObj)
      }
    },
    methods: {
      jumpInfo (item) {
        addInfArticleViews(item.infArticleId)
        // this.$router.push({ path: `/info-detail/${id}` })
        // addInfArticleViews(item.infArticleId)
        if (item.chainOrNot === 'Y') {
          if (item.externalUrlAddress && item.externalUrlAddress.indexOf('http://') !== -1 && item.externalUrlAddress.indexOf('https://') !== -1) {
            window.open(item.externalUrlAddress, '_blank')
          } else {
            window.open('http://' + item.externalUrlAddress, '_blank')
          }

          return ''
        }
        if (item.isShowNewWindow === 'Y') {
          const { href } = this.$router.resolve({
            path: '/info-detail/' + item.infArticleId
          })
          window.open(href, '_blank')
        } else {
          this.$router.push({path: `/info-detail/${item.infArticleId}`})
        }
      },
      jumpOrder (orderStatus) {
        this.$router.push({ path: '/member-center/order-manage/store-order', query: { activeName: orderStatus } })
      },
      bindUrl (path) {
        if (path.substr(0, 1) === '/') {
          this.$router.push({ path })
        } else {
          window.open(path, '_blank')
        }
      },
      onCoupon (item) {
        if (getToken()) {
          saveCoupon({ couponId: item.couponId }).then(res => {
            const { msg } = res
            if (msg === '保存成功') this.$message.success('领取成功')
            this.getCoupon()
          })
        } else {
          this.$message.warning('请先登录')
          this.$router.push(`/login`)
        }
      },
      getPage () {
        let data = {}

        Object.keys(this.header).map(item=>{
            if(!data[item]){
              data[item] = this.header[item] || []
            }
        })
        this.page = {...this.page, ...data}
        this.watchToolbar(this.flashSaleList)
      },
      watchToolbar (list) {
        // toolbar拼接
        const LINK = ['PC_SHOP_LINK_TOP_F1', 'PC_SHOP_LINK_TOP_F2', 'PC_SHOP_LINK_TOP_F3', 'PC_SHOP_LINK_TOP_F4',
          'PC_SHOP_LINK_F6_1', 'PC_SHOP_LINK_F7_1', 'PC_SHOP_LINK_F8_1', 'PC_SHOP_LINK_F9_1', 'PC_SHOP_LINK_F10_1']
        let title = [];
        for (const i of LINK) {
          if (this.page[i] && this.page[i].length) {
            let canPush = true;
            switch (i) {
              case 'PC_SHOP_LINK_TOP_F1':
                canPush = this.flashSaleList.length > 0
                break;
              case 'PC_SHOP_LINK_TOP_F2':
                canPush = this.page['PC_SHOP_PRODUCT_F2'].length > 0
                break;
              case 'PC_SHOP_LINK_TOP_F3':
                canPush = this.couponItems.length > 0
                break;
              case 'PC_SHOP_LINK_TOP_F4':
                canPush = this.page['PC_SHOP_ADV_F4'].length > 0 || this.productItems.length > 0
                break;
              case 'PC_SHOP_LINK_F6_1':
                canPush = this.page['PC_SHOP_PRODUCT_F6'].length > 0
                break;
              case 'PC_SHOP_LINK_F7_1':
                canPush = this.page['PC_SHOP_PRODUCT_F7'].length > 0
                break;
              case 'PC_SHOP_LINK_F8_1':
                canPush = this.page['PC_SHOP_PRODUCT_F8'].length > 0
                break;
              case 'PC_SHOP_LINK_F9_1':
                canPush = this.page['PC_SHOP_PRODUCT_F9'].length > 0
                break;
              case 'PC_SHOP_LINK_F10_1':
                canPush = this.page['PC_SHOP_PRODUCT_F10'].length > 0
                break;
              default:
                break
            }

            if (!!canPush) {
              title.push({
                name: this.page[i].length ? this.page[i][0].title : '',
                active: false
              })
            }
          }
        }
        if (!list.length) {
          if(title.findIndex(res => res.name === '限时抢购') >= 0){
            title.splice(title.findIndex(res => res.name === '限时抢购'), 1)
          }
        }
        this.toolbar = title
      },
      getModule () {
        this.getDiscount()
        // 优惠券
        this.getCoupon()
        this.getPage();
      },
      getCoupon () {
        pageCouponList({
          pageNum: 1,
          pageSize: 3,
          condition: { couponNm: '', couponStatus: '进行中', isShow: 'Y', couponType: '' }
        }).then(res => {
          console.log('getCoupon res:', res)
          function sub (str) { return str.substring(0, str.length - 3) }

          let coupon = res.data.records
          if (coupon && coupon.length) {
            coupon.forEach((data, i) => {
              //coupon[i].fullAmount = sub(coupon[i].fullAmount.replace(/,/g, ''))
              //coupon[i].fullReductionAmount = coupon[i].fullReductionAmount ? sub(coupon[i].fullReductionAmount.replace(/,/g, '')) : coupon[i].fullReductionAmount
              coupon[i].useStartTime = coupon[i].useStartTime ? coupon[i].useStartTime.replace(/-/g, '.').split(' ')[0] : '未知'
              coupon[i].useEndTime = coupon[i].useEndTime ? coupon[i].useEndTime.replace(/-/g, '.').split(' ')[0] : '未知'
            })
          }
          this.couponItems = coupon
        })
      },
      getDiscount () {
        getDiscountRuleStart().then(res => {
          console.log('getDiscountRuleStart res:', res)
          const { data } = res
          if (data) {
            if (data.isCountDown === 'Y') {
              this.showCountDown = true
            }else{
              this.showCountDown = false
            }

            this.setIntervalObj = setInterval(() => {
              this.timeList = countDown(data.endTime)
            }, 1000)
            pageDiscountProductRel({
              pageNum: 1,
              pageSize: 5,
              condition: { discountRuleId: data.discountRuleId }
            }).then(res => {
              console.log('pageDiscountProductRel res:', res)
              const { data } = res

              this.flashSaleList = data.records
            })
          } else {
            this.flashSaleList = []
          }
        })
      },
      selectStyle (index) {
        this.noticeActive = [false, false, false]
        this.noticeActive[index] = true
      },
      handleScroll () {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        let offsetTop = document.querySelector('#content').offsetTop
        this.toolbarFixed = scrollTop + 50 > offsetTop
        // let jump = document.querySelectorAll('.scroll-item')
        // jump.forEach((item, index) => {
        //   if (scrollTop >= item.offsetTop + 601) {
        //     let toolbar = this.toolbar
        //     if (toolbar.length) {
        //       toolbar.forEach((e, i) => {
        //         toolbar[i].active = false
        //       })
        //       toolbar[index].active = true
        //     }
        //   }
        // })
      },
      toolbarScroll (index) {
        const currentY = document.documentElement.scrollTop || document.body.scrollTop
        if (index === 'top') {
          scrollAnimation(currentY, 0)
          return
        }
        let jump = document.getElementsByClassName('scroll-item')
        if(jump.length){
          let offsetTop = jump[index].offsetTop
          this.toolbar.forEach((item,i)=>{
            item.active = i === index
          })

          scrollAnimation(currentY, offsetTop + 601)
        }
      },
      couponCheckMore () {
        this.$router.push('/coupon-center')
      },
      infoCheckMore (categoryNm) {
        this.$router.push({ name: 'InfoChannel', params: { categoryNm } })
      },
      ArrivalReminderCount() {
        let buyersId = ''
        if (getAccount()) {
          buyersId = getAccount().buyersId
        } else {
          buyersId = ''
        }
        ArrivalReminderCount({buyersId: buyersId}).then(res => {
          this.arrivalGoodsNum = res.data
        })
      },
      getAccountId() {
        // if (this.$store.getters.accountId) {
        if (getAccount()) {
          this.$router.push("/arrival-reminder");
        } else {
          this.$message.error('请选择客户，方便查看到货商品！')
        }
      }
    },
    computed: {
      ...mapGetters(['refreshModule', 'buyersVo', 'userDetail', 'header', 'userType', 'accountId', 'showIndex']),
      showPrice(){
        return ({priceStr,price}) => priceStr == '登录可见' ?getToken()?'获取价格中':'登录可见': priceStr == '¥0.00' ? '未报价': price;
      }
    },
    created(){
      console.log(this.userType)

    },
    watch: {
      flashSaleList: {
        handler (newVal) {
          this.watchToolbar(newVal)
        },
        deep: true
      },
      header: {
        handler (n) {
          this.getPage();
        },
        deep: true
      },
      buyersVo: {
        handler (newVal){
          this.getModule()
        },deep:true
      },
      refreshModule: {
        handler () {
          if (this.setIntervalObj) {
            clearInterval(this.setIntervalObj)
          }
          this.getModule()
        },
        deep: true
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/styles/home.scss'
</style>

<style lang="scss" scoped>
  .nav-content /deep/ {

    .el-carousel__arrow {
      height: 70px;
      border-radius: 0;

      i:before {
        content: '';
      }
    }

    .el-carousel__arrow--left {
      background: url("../../assets/img/promotion-zone/brand-center/arrow-swiper-left.png") no-repeat;
    }

    .el-carousel__arrow--right {
      background: url("../../assets/img/promotion-zone/brand-center/arrow-swiper-right.png") no-repeat;
    }
  }
  .show_loading{
    height: 0px;width: 100%;
    margin: 100px 0;
  }
  .commodity-item, .product-item {
    position: relative;

  }
  .identifying {
     position: absolute;
     top: 10px;
     left: 10px;
     width: 32px;
     height: 32px;
     z-index: 10;
     img {
       width: 100%;
       height: 100%;
     }
  }
  .licence-banner {
    position: absolute;
    top: 44px;
    right: 190px;
    width: 160px;
    height: 110px;
  }
  .toolbar-item {
    position: relative;
    .arrival-num{
      position: absolute;
      top: 0;
      right: 0;
      height: 18px;
      min-width: 18px;
      border-radius: 50%;
      background: #EA4F3D;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      line-height: 18px;
      text-align: center;
    }
  }
  .coupon-price b {
    font-size: 60px !important;
  }
</style>

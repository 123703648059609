var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-settlement-container" },
    [
      _c("div", { staticClass: "check-box" }, [
        _c("div", { staticClass: "title" }, [_vm._v(" 收货地址 ")]),
        _c(
          "div",
          { staticClass: "check-radio" },
          [
            _vm.shop.receiverAddrList.length
              ? _c(
                  "el-radio-group",
                  {
                    on: { change: _vm.addressChange },
                    model: {
                      value: _vm.shop.buyersReceiverAddrId,
                      callback: function ($$v) {
                        _vm.$set(_vm.shop, "buyersReceiverAddrId", $$v)
                      },
                      expression: "shop.buyersReceiverAddrId",
                    },
                  },
                  _vm._l(_vm.shop.receiverAddrList, function (item, index) {
                    return _c(
                      "el-radio",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: index < _vm.checkShowNum,
                            expression: "index<checkShowNum",
                          },
                        ],
                        key: index,
                        attrs: { label: item.buyersReceiverAddrId },
                      },
                      [
                        "缺省" == item.receiverName
                          ? _c("span", [_vm._v(_vm._s(item.contactCustomerNm))])
                          : _c("span", [
                              _vm._v(
                                _vm._s(item.contactCustomerNm) +
                                  "【" +
                                  _vm._s(item.receiverName) +
                                  "】"
                              ),
                            ]),
                        _c("span", [_vm._v(_vm._s(item.receiverMobile))]),
                        _c("span", [
                          _c("span", [_vm._v(_vm._s(item.province))]),
                          _c("span", [_vm._v(_vm._s(item.city))]),
                          _c("span", [_vm._v(_vm._s(item.district))]),
                          _c("span", [_vm._v(_vm._s(item.receiverAddr))]),
                        ]),
                      ]
                    )
                  }),
                  1
                )
              : _c(
                  "div",
                  {
                    staticStyle: {
                      "padding-right": "40px",
                      "font-size": "14px",
                      "font-weight": "500",
                      color: "#e02020",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.userType === "SALESMAN"
                            ? "该客户未备案收货地址。"
                            : "您还未备案收货地址，请联系业务员。"
                        ) +
                        " "
                    ),
                  ]
                ),
            _vm.shop.receiverAddrList && _vm.shop.receiverAddrList.length > 1
              ? [
                  _vm.isExpand
                    ? _c(
                        "span",
                        {
                          staticClass: "check-radio-span",
                          on: { click: _vm.checkShow },
                        },
                        [
                          _vm._v(" 收起更多地址 "),
                          _c("i", {
                            staticClass: "toggle-arrow el-icon-arrow-up",
                          }),
                        ]
                      )
                    : _c(
                        "span",
                        {
                          staticClass: "check-radio-span check-radio-e",
                          on: { click: _vm.checkShow },
                        },
                        [
                          _vm._v(" 展开更多地址 "),
                          _c("i", {
                            staticClass: "toggle-arrow el-icon-arrow-down",
                          }),
                        ]
                      ),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c(
        "div",
        { staticClass: "check-box" },
        [
          _c("div", { staticClass: "title" }, [_vm._v(" 支付方式 ")]),
          _c(
            "el-radio-group",
            {
              on: { change: _vm.paymentChange },
              model: {
                value: _vm.shop.paymentWayId,
                callback: function ($$v) {
                  _vm.$set(_vm.shop, "paymentWayId", $$v)
                },
                expression: "shop.paymentWayId",
              },
            },
            _vm._l(_vm.shop.paymentWayList, function (item, index) {
              return _c(
                "el-radio",
                {
                  key: item.paymentWayCode,
                  attrs: { label: item.paymentWayId },
                },
                [
                  _c("span", [_vm._v(_vm._s(item.paymentWayName))]),
                  item.paymentWayCode === "CREDIT"
                    ? _c("span", [
                        _vm._v(
                          "您的可用额度：¥" + _vm._s(_vm.shop.availableCredit)
                        ),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "inventory" },
        [
          _c(
            "h4",
            [
              _vm._v("商品清单 "),
              _c(
                "el-button",
                {
                  attrs: { type: "text", size: "small" },
                  on: { click: _vm.goShop },
                },
                [_vm._v("返回购物车")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "table",
              class:
                !_vm.hidden && _vm.itemList.length > 4 ? "hidden-more" : "",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.itemList,
                "header-row-class-name": "header-row",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  align: "center",
                  label: "序号",
                  width: "45",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "商品图", width: "80px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          staticClass: "product-img",
                          attrs: {
                            alt: "",
                            src:
                              (scope.row.productPicMap &&
                                scope.row.productPicMap["48X48"]) ||
                              _vm.productDefaultImg,
                          },
                          on: {
                            error: function ($event) {
                              scope.row.productPicMap["48X48"] =
                                _vm.productDefaultImg
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "productCode",
                  label: "商品编码",
                  align: "center",
                  "min-width": "70px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "productNm",
                  label: "商品名称",
                  "min-width": "200px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "table-product-name" }, [
                          _c("p", [
                            scope.row.otcTypeNm &&
                            scope.row.otcTypeNm !== "其他"
                              ? _c(
                                  "b",
                                  {
                                    staticClass: "otc",
                                    class: {
                                      jiaOtc: scope.row.otcTypeNm === "甲OTC",
                                      yiOtc: scope.row.otcTypeNm === "乙OTC",
                                      rxOtc: scope.row.otcTypeNm === "RX",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(scope.row.otcTypeNm))]
                                )
                              : _vm._e(),
                            _vm._v(
                              _vm._s(_vm.$util.getGoodsName(scope.row)) + " "
                            ),
                          ]),
                        ]),
                        _c("ul", { staticClass: "table-row-tags" }, [
                          scope.row.shoppingCartItemType === "ADDITIONAL"
                            ? _c("li", { staticClass: "row-tag tag-coupon" }, [
                                _vm._v("超值换购 "),
                              ])
                            : scope.row.shoppingCartItemType ===
                              "SPECIAL_PRICE_PURCHASE"
                            ? _c("li", { staticClass: "row-tag tag-coupon" }, [
                                _vm._v("特价换购 "),
                              ])
                            : scope.row.shoppingCartItemType === "PRESENT"
                            ? _c("li", { staticClass: "row-tag tag-coupon" }, [
                                _vm._v("特殊商品 "),
                              ])
                            : scope.row.tagList.findIndex(
                                (res) => res.tag === "禁限销"
                              ) !== -1
                            ? _c("li", { staticClass: "row-tag tag-coupon" }, [
                                _vm._v("禁限销 "),
                              ])
                            : scope.row.tagList.findIndex(
                                (res) => res.tag === "近效期"
                              ) !== -1
                            ? _c("li", { staticClass: "row-tag tag-coupon" }, [
                                _vm._v("近效期 "),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "spec",
                  align: "center",
                  label: "规格",
                  width: "90px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "manufacturer",
                  align: "left",
                  label: "厂家(上市许可持有人)/产地",
                  "show-overflow-tooltip": "",
                  width: "170px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "unit",
                  align: "center",
                  label: "单位",
                  "show-overflow-tooltip": "",
                  width: "50px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "rate",
                  align: "center",
                  label: "销项税",
                  "show-overflow-tooltip": "",
                  width: "60px",
                },
              }),
              _vm.userType === "SALESMAN"
                ? _c("el-table-column", {
                    attrs: {
                      prop: "stock",
                      align: "center",
                      label: "库存",
                      "min-width": "60px",
                    },
                  })
                : _vm._e(),
              _vm.userType === "BUYERS"
                ? _c("el-table-column", {
                    attrs: {
                      prop: "stock",
                      align: "center",
                      label: "库存",
                      "min-width": "60px",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.stock && scope.row.stock === ">100"
                                ? _c("span", [_vm._v(">100")])
                                : _c(
                                    "span",
                                    { staticClass: "inventory-warning" },
                                    [_vm._v("<100")]
                                  ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      133539751
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  prop: "minBuyMultiple",
                  align: "center",
                  label: "最小购买倍数",
                  width: "90px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.getRealMinBuyNum(scope.row)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "quantity",
                  align: "center",
                  label: "采购数量",
                  width: "80px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "originalUnitPrice",
                  align: "right",
                  label: "单价",
                  "min-width": "100px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isGifts === "Y" &&
                        scope.row.isMainProduct === "N"
                          ? _c("div", [
                              _vm._v(
                                " ¥" + _vm._s(scope.row.originalUnitPrice)
                              ),
                              _c("br"),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "text-decoration": "line-through",
                                    color: "#999",
                                  },
                                },
                                [_vm._v("¥" + _vm._s(scope.row.salePrice))]
                              ),
                            ])
                          : _c("span", [
                              _vm._v(_vm._s(scope.row.originalUnitPrice)),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "originalTotalPrice",
                  align: "right",
                  label: "小计",
                  "min-width": "100px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isGifts === "Y" &&
                        scope.row.isMainProduct === "N"
                          ? _c("div", { staticStyle: { color: "#FF3B30" } }, [
                              _vm._v(
                                " ¥" +
                                  _vm._s(
                                    (scope.row.originalTotalPrice * 1).toFixed(
                                      2
                                    )
                                  )
                              ),
                              _c("br"),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "text-decoration": "line-through",
                                    color: "#FF3B30",
                                  },
                                },
                                [_vm._v("¥" + _vm._s(scope.row.saleTotalPrice))]
                              ),
                            ])
                          : _c("span", { staticStyle: { color: "#FF3B30" } }, [
                              _vm._v(
                                "¥" +
                                  _vm._s(
                                    (scope.row.originalTotalPrice * 1).toFixed(
                                      2
                                    )
                                  )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.itemList.length > 4
        ? _c(
            "div",
            {
              staticClass: "toggle-table",
              on: {
                click: function ($event) {
                  _vm.hidden = !_vm.hidden
                },
              },
            },
            [
              _vm.hidden
                ? _c("div", { staticClass: "fold" }, [
                    _c("span", [_vm._v("收起")]),
                    _c("i", { staticClass: "el-icon-arrow-up el-icon--right" }),
                  ])
                : _c("div", { staticClass: "fold" }, [
                    _c("span", [
                      _vm._v(
                        "展示更多" + _vm._s(_vm.surplusItemNum) + "件商品"
                      ),
                    ]),
                    _c("i", {
                      staticClass: "el-icon-arrow-down el-icon--right",
                    }),
                  ]),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "remark" },
        [
          _c("span", [_vm._v("备注")]),
          _c("el-input", {
            attrs: { placeholder: "请输入您的订单备注信息" },
            model: {
              value: _vm.remark,
              callback: function ($$v) {
                _vm.remark = $$v
              },
              expression: "remark",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "invoice-info" },
        [
          _vm._v(" 发票信息 "),
          _c("span", [_vm._v(_vm._s(_vm.shop.invoiceTypeNm))]),
          _c("span", [_vm._v(_vm._s(_vm.shop.invoiceTitle))]),
          _c(
            "el-button",
            { attrs: { type: "text" }, on: { click: _vm.openAgreement } },
            [_vm._v("查看详情")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, width: "500px" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: { "font-size": "14px" },
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v(" 发票信息 ")]
          ),
          _c("div", { staticClass: "dialog-content" }, [
            _c("div", { staticClass: "invo-list" }, [
              _c("div", { staticClass: "invo-lt" }, [_vm._v("发票类型")]),
              _c("div", { staticClass: "invo-rt" }, [
                _vm._v(_vm._s(_vm.shop.invoiceTypeNm)),
              ]),
            ]),
            _c("div", { staticClass: "invo-list" }, [
              _c("div", { staticClass: "invo-lt" }, [_vm._v("发票抬头")]),
              _c("div", { staticClass: "invo-rt" }, [
                _vm._v(_vm._s(_vm.shop.invoiceTitle)),
              ]),
            ]),
            _c("div", { staticClass: "invo-list" }, [
              _c("div", { staticClass: "invo-lt" }, [_vm._v("税号")]),
              _c("div", { staticClass: "invo-rt" }, [
                _vm._v(_vm._s(_vm.shop.invoiceTfn)),
              ]),
            ]),
            _c("div", [
              _vm.shop.unitAddr
                ? _c("div", { staticClass: "invo-list" }, [
                    _c("div", { staticClass: "invo-lt" }, [_vm._v("单位地址")]),
                    _c("div", { staticClass: "invo-rt" }, [
                      _vm._v(_vm._s(_vm.shop.unitAddr)),
                    ]),
                  ])
                : _vm._e(),
              _vm.shop.regTel
                ? _c("div", { staticClass: "invo-list" }, [
                    _c("div", { staticClass: "invo-lt" }, [_vm._v("注册电话")]),
                    _c("div", { staticClass: "invo-rt" }, [
                      _vm._v(_vm._s(_vm.shop.regTel)),
                    ]),
                  ])
                : _vm._e(),
              _vm.shop.bankAccount
                ? _c("div", { staticClass: "invo-list" }, [
                    _c("div", { staticClass: "invo-lt" }, [_vm._v("银行账号")]),
                    _c("div", { staticClass: "invo-rt" }, [
                      _vm._v(_vm._s(_vm.shop.bankAccount)),
                    ]),
                  ])
                : _vm._e(),
              _vm.shop.openBank
                ? _c("div", { staticClass: "invo-list" }, [
                    _c("div", { staticClass: "invo-lt" }, [_vm._v("开户银行")]),
                    _c("div", { staticClass: "invo-rt" }, [
                      _vm._v(_vm._s(_vm.shop.openBank)),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "agree",
                  attrs: { size: "mini" },
                  on: { click: _vm.dialogReturn },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "coupons" },
        [
          _vm.shop.couponList.length || _vm.shop.notCouponList.length
            ? _c(
                "el-collapse",
                {
                  model: {
                    value: _vm.collapse,
                    callback: function ($$v) {
                      _vm.collapse = $$v
                    },
                    expression: "collapse",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "优惠券", name: "1" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        _vm._l(_vm.shop.couponList, function (item, i) {
                          return _c("el-col", { key: i, attrs: { span: 6 } }, [
                            _c("div", { staticClass: "coupon usable" }, [
                              _c("div", { staticClass: "coupon-left" }, [
                                item.couponTypeNm === "折扣"
                                  ? _c("p", { staticClass: "money" }, [
                                      _c("span", [
                                        _vm._v(_vm._s(item.discount)),
                                      ]),
                                      _vm._v(" 折"),
                                    ])
                                  : _c("p", { staticClass: "money" }, [
                                      _vm._v("¥"),
                                      _c("span", [
                                        _vm._v(_vm._s(item.discount)),
                                      ]),
                                    ]),
                                _c("p", { staticClass: "condition" }, [
                                  _vm._v(_vm._s(item.discountDesc)),
                                ]),
                              ]),
                              _c("div", { staticClass: "coupon-right" }, [
                                _c("p", { staticClass: "name" }, [
                                  _vm._v(_vm._s(item.couponNm)),
                                ]),
                                _c("p", { staticClass: "indate" }, [
                                  _vm._v("有效期：" + _vm._s(item.useEndTime)),
                                ]),
                              ]),
                              _c("div", {
                                staticClass: "selection-icon",
                                class: item.select ? "selected" : "unselected",
                                on: {
                                  click: function ($event) {
                                    return _vm.onCoupon(item.receiveRecordId)
                                  },
                                },
                              }),
                            ]),
                          ])
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "不可用优惠券", name: "2" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        _vm._l(_vm.shop.notCouponList, function (item, i) {
                          return _c("el-col", { key: i, attrs: { span: 6 } }, [
                            _c(
                              "div",
                              { staticClass: "coupon usable grayed-out" },
                              [
                                _c("div", { staticClass: "coupon-left" }, [
                                  item.couponTypeNm === "折扣"
                                    ? _c("p", { staticClass: "money" }, [
                                        _c("span", [
                                          _vm._v(_vm._s(item.discount)),
                                        ]),
                                        _vm._v(" 折"),
                                      ])
                                    : _c("p", { staticClass: "money" }, [
                                        _vm._v("¥"),
                                        _c("span", [
                                          _vm._v(_vm._s(item.discount)),
                                        ]),
                                      ]),
                                ]),
                                _c("div", { staticClass: "coupon-right" }, [
                                  _c("p", { staticClass: "name" }, [
                                    _vm._v(_vm._s(item.couponNm)),
                                  ]),
                                  _c("p", { staticClass: "indate" }, [
                                    _vm._v(
                                      "有效期：" + _vm._s(item.useEndTime)
                                    ),
                                  ]),
                                  _c(
                                    "p",
                                    { staticClass: "reason" },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "bottom",
                                            width: "200",
                                            trigger: "hover",
                                          },
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                padding: "10px",
                                                "font-size": "12px",
                                                "line-height": "18px",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.discountDesc))]
                                          ),
                                          _c(
                                            "i",
                                            {
                                              staticClass: "el-icon-info",
                                              staticStyle: {
                                                "margin-top": "4px",
                                                position: "absolute",
                                              },
                                              attrs: { slot: "reference" },
                                              slot: "reference",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "2px",
                                                  },
                                                },
                                                [_vm._v("不可用原因")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("span", {
                                        staticStyle: { "margin-left": "16px" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "use-coupon-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleUesCoupon(
                                          item.couponId
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("去"),
                                      _c("br"),
                                      _vm._v("使"),
                                      _c("br"),
                                      _vm._v("用"),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ])
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("p", { staticClass: "none" }, [_vm._v("暂无可用优惠券")]),
        ],
        1
      ),
      _vm.pointShow()
        ? _c("div", { staticClass: "point" }, [
            _c("div", { staticClass: "point-text" }, [
              _vm._v(
                " 当前剩余积分" +
                  _vm._s(_vm.shop.userIntegralVo.availableIntegral) +
                  "，本单可以抵扣" +
                  _vm._s(
                    _vm.shop.userIntegralVo.availableIntegralDiscountAmount
                  ) +
                  "元，是否使用 "
              ),
            ]),
            _c(
              "div",
              { staticClass: "point-switch" },
              [
                _c("el-switch", {
                  attrs: { "active-color": "#00D5D1" },
                  on: { change: _vm.switchChange },
                  model: {
                    value: _vm.isPointCheck,
                    callback: function ($$v) {
                      _vm.isPointCheck = $$v
                    },
                    expression: "isPointCheck",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "settlement" }, [
        _c("div", { staticClass: "settlement-info" }, [
          _c("div", { staticClass: "settlement-info-row" }, [
            _c("span", [_vm._v("商品总金额：")]),
            _c("span", { staticClass: "money" }, [
              _vm._v(
                "¥" + _vm._s(_vm.$util.toFixed(_vm.shop.productTotalAmount))
              ),
            ]),
          ]),
          _c("div", { staticClass: "settlement-info-row" }, [
            _c("span", [_vm._v("运费：")]),
            _c("span", { staticClass: "money" }, [
              _vm._v("¥" + _vm._s(_vm.shop.freightAmount)),
            ]),
          ]),
          _c("div", { staticClass: "settlement-info-row" }, [
            _c("span", [_vm._v("优惠金额：")]),
            _c("span", { staticClass: "money" }, [
              _vm._v("¥" + _vm._s(_vm.shop.discountAmount)),
            ]),
          ]),
          _c("div", { staticClass: "settlement-info-row" }, [
            _c("span", [_vm._v("积分抵扣：")]),
            _c("span", { staticClass: "money" }, [
              _vm._v("¥" + _vm._s(_vm.integralDiscountAmount)),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "confirm-row" },
          [
            _c("div", { staticClass: "amount-payable" }, [
              _vm._v(" 订单应付金额 "),
              _c("p", [
                _vm._v(
                  "¥" + _vm._s(_vm.$util.toFixed(_vm.shop.originalTotalPrice))
                ),
              ]),
            ]),
            _c(
              "el-button",
              {
                staticClass: "confirm-button",
                class: { disabled: _vm.isOverdue },
                attrs: {
                  loading: _vm.isSubmitLoading,
                  disabled: _vm.isSubmitLoading,
                },
                on: { click: _vm.isNearEffective },
              },
              [_vm._v("确认提交")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "consignee-info" }, [
          _vm._v(" 收货人：" + _vm._s(_vm.shop.receiverName) + " "),
          _c("span", { staticClass: "phone" }, [
            _vm._v(_vm._s(_vm.shop.receiverMobile)),
          ]),
          _c("span", { staticClass: "address" }, [
            _vm._v(
              "寄送至：" +
                _vm._s(_vm.shop.province) +
                _vm._s(_vm.shop.city) +
                _vm._s(_vm.shop.district) +
                _vm._s(_vm.shop.receiverAddr)
            ),
          ]),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-table dialog-table-effective",
          attrs: {
            title: "效期提醒",
            width: "700px",
            visible: _vm.dialogTableVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c("h4", [
            _c("img", {
              staticClass: "icon",
              attrs: {
                src: require("@/assets/img/customer-service/icon-shanchu.png"),
                alt: "",
              },
            }),
            _vm._v(" 以下商品效期小于一年，您是否确定下单？ "),
          ]),
          _c(
            "el-table",
            {
              staticStyle: { "margin-bottom": "10px" },
              attrs: { data: _vm.isNear, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "55",
                  fixed: "left",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { property: "productNm", label: "商品名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.$util.getGoodsName(scope.row)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  property: "manufacturer",
                  label: "生产厂家",
                  width: "250",
                },
              }),
              _c("el-table-column", {
                attrs: { property: "spec", label: "规格", width: "140" },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleSubmitOrder },
                },
                [_vm._v("继续下单")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <im-page-modal
      v-if="this.isAuthInquiryBox == 'Y'"
      class="consult-price-drafts-list"
      v-loading="loading"
      title="询价草稿箱"
      :isShowBack="false"
  >
    <!-- 按钮区域 -->
    <im-search-pad
        class="search-content"
        :model="searchForm"
        :has-expand="false"
        @search="handleSearch"
        @reset="handleReset"
    >
      <im-search-pad-item prop="searchField">
        询价单标题：
        <el-input style="width:180px;height:32px" size="small" v-model="searchForm.title" placeholder="输入标题"/>
      </im-search-pad-item>&nbsp;&nbsp;
      <im-search-pad-item prop="searchField">
        询价单时间：
        <el-date-picker
            @change="handleChangeTime"
            style="width:240px;"
            v-model="time"
            size="small"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </im-search-pad-item>
    </im-search-pad>
    <!-- 表格内容 -->
    <im-table-page
        style="margin-bottom:15px;padding: 0 15px;"
        ref="tablePage"
        :data.sync="tableData"
        :remote-method="queryData"
        @updated="updated"
    >
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="询价单标题" prop="title" width="200px" show-overflow-tooltip/>
      <el-table-column label="商品总品规格数" prop="inquiryBoxDetailCount" width="130px" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.inquiryBoxDetailCount">共<span class="text11">{{scope.row.inquiryBoxDetailCount}}</span>种</span>
        </template>
      </el-table-column>
      <el-table-column label="保存时间" prop="createTime" min-width="167px" show-overflow-tooltip/>
      <el-table-column label="操作" width="120px" fixed="right">
        <template slot-scope="scope">
          <el-row class="table-edit-row">
            <el-button size="small" style="padding:2px 4px;font-size: 12px" @click="handleEdit(scope.row)">编辑
            </el-button>
            <!-- <el-button size="small" style="padding:2px 4px;font-size: 12px" @click="handleDelete(scope.row)">删除</el-button> -->
            <im-confirm-popup
                :index="scope.$index"
                tip-txt="是否确定该询价？"
                reference-btn-txt="删除"
                @confirm="handleDelete(scope.row)"
            />
          </el-row>
        </template>
      </el-table-column>
    </im-table-page>
  </im-page-modal>
  <div v-else class="no-auth">
    询价功能暂未开放
  </div>
</template>
<script>
  import { pageInquiryBox, delInquiryBoxById, getInquiryBoxById } from '@/api/member-center/transactions-using'
  import {mapGetters} from "vuex";

  export default {
    name: 'ConsultPriceDraftsList',
    props: {
      params: {
        type: Object,
        default () {
          return {
            AllChecked: false,
            loading: false
          }
        }
      }
    },
    data () {
      return {
        loading: false,
        time: [],
        tableData: [],
        searchForm: {
          title: '',
          startCreateTime: '',
          endCreateTime: '',
        },
      }
    },
    computed: {
      ...mapGetters(['isAuthInquiryBox'])
    },
    watch: {
      params: {
        handler (newVal) {
          if (newVal.refreshList) {
            this.doRefresh()
          }
        },
        deep: true
      }
    },
    methods: {
      doRefresh () {
        this.$refs.tablePage.doRefresh({ currentPage: 0, pageSize: 10 })
      },
      handleSearch () {
        this.doRefresh()
      },
      handleReset () {
        this.searchForm = {
          title: '',
          startCreateTime: '',
          endCreateTime: '',
        }
        this.time = []
        this.handleSearch()
      },
      handleChangeTime () {
        this.searchForm.startCreateTime = this.time[0]
        this.searchForm.endCreateTime = this.time[1]
      },
      queryData (data) {
        return pageInquiryBox({ ...data, condition: this.searchForm })
        // var tableData = [{inquiryBoxDetailCount:8},{}]
        // return Promise.resolve({
        //       code: 0,
        //       msg: '',
        //       data: {
        //         records: tableData,
        //         total: tableData.length
        //       }
        //   })
      },
      updated () {
        this.$refs.tablePage.doLayout()
      },
      // handleAdd() {
      //     this.$emit('change-page', {
      //         page: 'ConsultPriceList',
      //         params: {
      //         editType: 'add'
      //         }
      //     })
      // },
      handleDelete (row) {
        delInquiryBoxById(row.inquiryDraftBoxId).then(res => {
          this.$message.success('删除成功')
          this.doRefresh()
        })
      },
      handleEdit (row) {
        var shopList = []
        getInquiryBoxById(row.inquiryDraftBoxId).then(res => {
          shopList = res.data
          this.$emit('change-page', {
            page: 'ConsultPriceDraftsUpdate',
            params: {
              editType: 'edit',
              rowData: row,
              shopList: shopList
            }
          })
        })

      },
      onCommit () {

      }

    }
  }
</script>
<style lang="scss">
  .consult-price-drafts-list {
    .content {
      padding-top: 0;
    }


    .page-header-btn {
      .el-input__inner {
        height: 30px;
        line-height: 30px
      }
    }
  }
</style>
<style scoped lang="scss">
  .text11{
    @include TextHighlightColor
  }
  .consult-price-drafts-list {
    min-height: 100% !important;
    margin: 0 auto;
    .page-header-btn {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;

      .right {
        font-style: normal;
        font-size: 12px;
        color: #666666;

        .el-input__inner {
          height: 30px;
        }
      }
    }

    .search-content {
      border-left: none;
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      color: rgba(113, 113, 113, 1);
      margin: 0;
    }

    /deep/ {
      .im-confirm-popup--el-button span {
        font-size: 12px;
        padding: 1px 4px;
        margin-left: 8px;
        border: 1px solid #DCDFE6;
        border-radius: 3px;
        color: #606266;

        &:hover {
          color: #0971EB;
        }
      }
    }
  }
  .no-auth{
    width: 100%;
    height: 100vh;
    background: #fff;
    padding-top: 60px;
  }
</style>

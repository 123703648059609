import {ApiGet, ApiPost} from "@/api";

/**
 * @description 根据资讯分类
 */
export const appVideoGroup = id => ApiPost('/video/v1/appVideoGroup', {
    orgId: id,
},{
    id
})

/**
 * @description 获取资讯列表
 */
export const pageAppVideo = data => ApiPost('/video/v1/pageAppVideo', data)

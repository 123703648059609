/**
 * ImPicZoom
 * @module components
 * @desc 通用图片放大镜
 * @author chang
 * @date 2020/07/23
 * * @param {string} [url] - 图片地址
 * * @param {string} [big-url] - 大图地址
 * * @param {number} [scale] - 图片放大倍数
 * * @param {boolean} [scroll] - 放大时页面是否可滚动
 * * @param {boolean} [show-edit] - 是否显示旋转图片按钮
 * @example 调用示例
 *  <pic-zoom url="static/img.jpg" :scale="3"></pic-zoom>
 */
import imPicZoom from './index.vue'

const ImPicZoom = {
  install: function(Vue) {
    Vue.component('ImPicZoom', imPicZoom)
  }
}

export default ImPicZoom

<template>
  <div class="login-container">
    <div class="login-content">
      <div class="login-rt">
        <div class="ls-logo">
          <router-link to="/"><img src="../../assets/img/logo.gif" alt/></router-link>
        </div>
        <div class="rt-content">
          <el-tabs v-model="activeName" class="login-tab">
            <!-- 账号密码登录 -->
            <el-tab-pane label="账号登录" name="first" class="first-zp">
              <el-form
                  ref="loginForm"
                  :model="loginForm"
                  :rules="loginRules"
                  class="login-form"
                  auto-complete="on"
                  label-position="left"
              >
                <el-form-item prop="username">
                  <el-input
                      ref="username"
                      v-model="loginForm.username"
                      placeholder="输入登录账号"
                      name="username"
                      type="text"
                      tabindex="1"
                      auto-complete="on"
                      clearable
                      @keyup.enter.native="showValid"
                  />
                  <div class="input-icon">
                    <img src="../../assets/img/login/icon-user.png" alt/>
                  </div>
                </el-form-item>

                <el-form-item prop="password">
                  <el-input
                      :key="passwordType"
                      ref="password"
                      v-model="loginForm.password"
                      :type="passwordType"
                      placeholder="输入登录密码"
                      name="password"
                      tabindex="2"
                      auto-complete="on"
                      clearable
                      @keyup.enter.native="showValid"
                  />
                  <div class="input-icon">
                    <img src="../../assets/img/login/icon-suo.png" alt/>
                  </div>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <!-- 手机号登录 -->
            <el-tab-pane label="手机登录" name="second">
              <el-form
                  ref="loginFormPhone"
                  :model="loginFormPhone"
                  :rules="loginPhoneRules"
                  class="login-form"
                  auto-complete="on"
                  label-position="left"
              >
                <el-form-item prop="phone">
                  <el-input
                      ref="phone"
                      v-model="loginFormPhone.phone"
                      placeholder="输入手机号码"
                      name="phone"
                      type="text"
                      tabindex="1"
                      auto-complete="on"
                      clearable
                  />
                  <div class="input-icon">
                    <img src="../../assets/img/login/icon-shouji.png" alt/>
                  </div>
                </el-form-item>
                <el-from-item v-if="slider_show">
                  <SlidePuzzleVerification :isShow="slider_show" @validationResults="validationResults"></SlidePuzzleVerification>
                </el-from-item>
                <el-form-item prop="sms" class="sj-yanzheng">
                  <el-input
                      ref="sms"
                      v-model="loginFormPhone.sms"
                      placeholder="输入验证码"
                      name="sms"
                      tabindex="2"
                      @keyup.enter.native="showValid"
                      clearable
                  />
                  <div class="input-icon">
                    <img src="../../assets/img/login/icon-yanzhengma.png" alt/>
                  </div>
                  <el-button
                      :disabled="!VerifBtn"
                      @click="handleVerif"
                  >{{timeNum > 0 ? `已发送${timeNum}s` : '发送验证码'}}
                  </el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-button :loading="loading" type="primary" plain class="btn-login" @click.native.prevent="showValid">登录
            </el-button>
            <div class="more-opration" v-if="activeName === 'first'">
              <el-checkbox v-model="autoLogin">记住登录状态</el-checkbox>
              <div class="flex">
                <el-button type="text" @click="goForgetPwd">忘记密码</el-button>
<!--                <span class="line"></span>-->
<!--                <router-link to="/register"><el-button type="text">立即注册</el-button></router-link>-->
              </div>
            </div>
            <div class="more-opration" style="justify-content: flex-end" v-else>
              <router-link to="/register"><el-button type="text">我是新用户？ 立即注册</el-button></router-link>
            </div>
          </el-tabs>
        </div>
      </div>
    </div>
    <slide-verify-valid ref="slideVerify" v-show="isShowValid" @onCancel="hideValid" @onSuccess="validSuccess"></slide-verify-valid>
  </div>
</template>

<script>
  import { validPhone } from '@/utils/validate'
  import { encodeMd5 } from '@/utils/encode-md5'
  import { mapGetters } from 'vuex'
  import { removeAccount } from '@/utils/auth'
  // import SlidePuzzleVerification from './SlidePuzzleVerification'

  import SlideVerifyValid from './components/slideVerifyValid'
  export default {
    name: 'Login',
    components: {
      // SlidePuzzleVerification,
      SlideVerifyValid
    },
    data () {
      const validatePhone = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入手机号 '))
        } else {
          if (!validPhone(value)) {
            callback(new Error('手机格式不正确 '))
          }
          callback()
        }
      }
      return {
        slider_show: false, // 滑块显示
        activeName: 'first',
        isShowValid:false,
        loginForm: {
          username: '',
          password: '',
          code: ''
        },
        loginRules: {
          username: [
            { required: true, trigger: 'blur', message: '请输入用户名' }
          ],
          password: [{ required: true, trigger: 'blur', message: '请输入密码' }],
          code: [{ required: true, trigger: 'blur', message: '请输入验证码' }]
        },
        loginFormPhone: {
          phone: '',
          sms: ''
        },
        loginPhoneRules: {
          phone: [{ required: true, trigger: 'blur', validator: validatePhone }],
          sms: [{ required: true, trigger: 'blur', message: '请输入验证码' }]
        },
        loading: false,
        autoLogin: false,
        passwordType: 'password',
        redirect: undefined,
        codeBaseUrl: `${process.env.VUE_APP_BASE_API}/code`,
        random: new Date().getTime(),
        timeNum: 0,
        VerifBtn: false,
        murmur: ''
      }
    },
    computed: {
      ...mapGetters(['allBusiness','userType']),
      codeUrl () {
        return `${this.codeBaseUrl}?randomStr=${this.random}`
      }
    },
    watch: {
      $route: {
        handler: function (route) {
          this.redirect = route.query && route.query.redirect
        },
        immediate: true
      },
      loginFormPhone: {
        handler: function () {
          if (this.loginFormPhone.phone !== '') {
            if (validPhone(this.loginFormPhone.phone)) {
              if (this.timeNum === 0) {
                this.VerifBtn = true
              }
            } else {
              this.VerifBtn = false
            }
          } else {
            this.VerifBtn = false
          }
        },
        deep: true
      }
    },
    mounted() {
      // console.log('设备id', process.env.VUE_APP_MACHINEID)
      // console.log('设备MAC', process.env.VUE_APP_MAC)
      // console.log('设备ip', process.env.VUE_APP_IP)
      // console.log('process.env.VUE_APP_MACADDR', process.env.VUE_APP_MACADDR)
    },
    methods: {
      validSuccess(){
        this.$message.success('验证通过')
        setTimeout(()=>{
          this.isShowValid = false
        },1000)
        this.handleLogin()
      },
      hideValid(){
        this.isShowValid = false
        this.$message.error('请先验证通过才可以登录')
      },
      showValid(){
        this.handleLogin()
        return
        if (this.activeName === 'first') {
          this.$refs.loginForm.validate(valid => {
            if(valid){
                this.$refs.slideVerify.handleReset()
                this.isShowValid = true
            }
          })
        } else {
          this.$refs.loginFormPhone.validate(valid => {
              if(valid){
                this.$refs.slideVerify.handleReset()
                this.isShowValid = true
              }
          })
        }

      },
      goForgetPwd() {
        this.$router.push({path:'/forget-password',query:{
          isEdit:false
        }})
      },
      getRandom () {
        this.random = new Date().getTime()
      },
      handleLogin () {
        if (this.activeName === 'first') {
          this.handleLoginAccount()
        } else {
          this.handleLoginPhone()
        }
      },
      handleLoginAccount () {
        this.$refs.loginForm.validate(valid => {
          if (valid) {
            this.loading = true
            const params = {
              code: this.loginForm.code,
              randomStr: this.random
            }
            const data = {
              loginId: this.loginForm.username,
              password: encodeMd5(this.loginForm.password)
            }
            this.$store.dispatch('user/login', { params, data }).then(async _ => {
              if(_.registerKey){
                this.$router.push({ path: '/register', query:{registerKey: _.registerKey,loginId: this.loginForm.username}})
                this.loading = false
                return
              }
              removeAccount()
              try {
                await this.$store.dispatch('user/getInfo')
              }catch (e){
                this.loading = false
              }
              if(this.userType === "MANUFACTURER_SALESMAN"){
                removeAccount()
                this.$message.error('厂家业务员账号无法登录')
              }else {
                await this.$router.push({ path: '/' })
              }
              this.loading = false
            }).catch((err) => {
              if(err && err.msg){
                this.$message.error(err.msg)
              }
              this.loading = false
              this.getRandom()
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      handleVerif () {
        // this.handleLoginPhone()
        this.rightTime()
      },
      handleLoginPhone () {
        this.$refs.loginFormPhone.validate(valid => {
          if (valid) {
            this.loading = true
            const data = this.loginFormPhone
            this.$store.dispatch('user/phoneVerif', { data }).then(async _ => {
              removeAccount()
              try {
                await this.$store.dispatch('user/getInfo')

              }catch (e){
                this.loading = false
              }
              if(this.userType === "MANUFACTURER_SALESMAN"){
                 removeAccount()
                 this.$message.error('厂家业务员账号无法登录')
              }else {
                await this.$router.push({ path: '/' })
              }
              this.loading = false
            }).catch(() => {
              this.getRandom()
              this.loading = false
            })
          }
        })
      },
      rightTime () {
        // 倒计时
        if (!this.timeNum) {
          const data = this.loginFormPhone.phone
          this.$store
            .dispatch('user/phoneVerifCode', { data })
            .then(response => {
              this.timeNum = 60 // 初始值为60秒
              this.VerifBtn = false
              const clertime = setInterval(() => {
                // 计时器
                this.timeNum--
                if (this.timeNum <= 0) {
                  // 如果减到0则清楚计时器
                  clearInterval(clertime)
                  this.VerifBtn = true
                }
              }, 1000)
            })
        }
      }
    }
  }
</script>

<style lang="scss">

  $bg: #283443;
  $light_gray: #fff;
  $cursor: #000;

  @supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
    .login-container .el-input input {
      color: $cursor;
    }
  }

  /* reset element-ui css */
  .login-container {
    min-width: 1200px;
    background: url(../../assets/img/login/BJ-denglu.png) no-repeat center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 150px;

    .login-content {
      min-width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: center;

      .login-rt {
        background: #FFF;
        border-radius: 4px;
        width: 400px;
        /* 适配ie */
        margin-top: 100px\9;
        margin-left: 50%\9;
        transform: translateX(-50%)\9;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { /* ie 10+ */
          margin-top: 0;
          margin-left: 0;
          transform: translateX(0);
        }
        /* end */
        .ls-logo {
          padding: 14px 0;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          background-color: #F1F7FE;
        }

        .rt-content {
          padding: 32px 30px 40px;
        }

        .login-tab {
          .el-tabs__header {
            margin: 0;
          }

          .el-tabs__nav {
            width: 100%;
            display: flex;

            .el-tabs__item {
              height: 60px;
              line-height: 60px;
              width: 30%;
              text-align: center;
              padding: 0;
              font-size: 18px;
              color: #595959;
              font-weight: 400;

              &.is-active {
                @include TextHighlightColor;
              }
            }
          }

          .el-tabs__content {
            .el-tab-pane {
              .el-form-item {
                margin-top: 24px;
              }

              .el-form-item--small.el-form-item {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .el-input {
      display: inline-block;
      height: 40px;

      input {
        background: #fff;
        padding-left: 40px;
        height: 40px;
        caret-color: $cursor;
        font-size: 16px;
        font-weight: 500;
        border-radius: 4px;
        border: 1px solid rgba(217, 217, 217, 1);
        box-sizing: border-box;

        &:-webkit-autofill {
          box-shadow: 0 0 0px 1000px #fff inset !important;
          -webkit-text-fill-color: $cursor !important;
        }
      }
    }

    .input-icon {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
      }
    }

    .login-tab {
      .el-row .el-col > .el-form-item input {
        border-radius: 5px 0 0 5px;
        border-right: 0;
      }

      .el-row .el-col > .el-button--small {
        border-radius: 0 5px 5px 0;
        height: 40px !important;
        background-color: #fff;
        font-size: 18px;
        @include TextHighlightColor;
        font-weight: 500;
        padding: 8px 5px;
        text-align: center;
      }
    }

    .more-opration {
      padding-top: 16px;

      .flex {
        display: flex;
        align-items: center;
      }

      .line {
        margin: 0 6px;
        width: 1px;
        height: 16px;
        background-color: #ccc;
      }

      .el-button--small {
        padding: 0;
      }

      .el-checkbox__input {
        padding-bottom: 1px;
      }

      .el-button--text {
        color: #666;

        &:hover {
          @include TextHighlightColor;
        }
      }
    }

    .login-content .login-rt .login-tab .el-tabs__content .el-tab-pane .sj-yanzheng .el-button {
      margin-top: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      color: #999;
      font-size: 16px;
      font-weight: 500;
      border: 1px solid #999;
      width: 104px;
      padding: 0;

      &.is-disabled {
        color: #ccc;
        background-color: #f8f8f8;
        border-color: #ccc;
      }

      span {
        font-size: 16px;
      }
    }

    .sj-yanzheng {
      .el-input__inner {
        margin-right: 120px;
        width: 224px;
      }
    }

    .first-zp {
      .el-form-item {
        position: relative;

        .zp-code {
          position: absolute;
          top: 10px;
          bottom: 10px;
          right: 0;
          display: flex;

          span {
            font-size: 16px;
          }

          .el-button {
            margin-top: 0 !important;
            font-size: 18px;
            font-weight: 500;
            color: #1890ff;
            padding: 0 8px 0 4px;
          }
        }
      }

      .yanzheng {
        .el-form-item__content > .el-input input {
          padding-right: 155px;
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  $bg: #2d3a4b;
  $dark_gray: #889aa4;
  $light_gray: #eee;

  .login-container {
    min-height: 100%;
    width: 100%;

    .login-tab {
      position: relative;
      width: 100%;

      /deep/.el-tabs__nav {
        width: 100%;
        .el-tabs__active-bar{
          @include TabsActiveBar
        }
      }
    }

    .login-form {
      position: relative;
      max-width: 100%;
      padding-top: 4px;
      margin: 0 auto;
      // overflow: hidden;
    }

    .svg-container {
      padding: 6px 5px 6px 15px;
      color: $dark_gray;
      vertical-align: middle;
      width: 30px;
      display: inline-block;
    }

    .title-container {
      position: relative;
      padding-top: 114px;
      text-align: center;

      .header {
        font-size: 33px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 600;
        font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      }

      .desc {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        margin-top: 12px;
        margin-bottom: 40px;
      }
    }

    .show-pwd {
      position: absolute;
      right: 10px;
      top: 3px;
      font-size: 16px;
      color: $dark_gray;
      cursor: pointer;
      user-select: none;
    }

    .img-container {
      background-color: #fff;
      height: 28px;
      width: 84px;

      img {
        width: 100%;
        height: 100%;
        border: none;
      }
    }

    .more-opration {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 6px;
      font-size: 12px;
    }

    .btn-login {
      height: 40px;
      border: 0;
      width: 100%;
      font-size: 14px;
      @include NormalBtnColor;
      font-weight: 500;
      padding: 0;
    }
  }
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "empty-box" }, [
    _c(
      "div",
      [
        _c("el-image", {
          staticStyle: { width: "160px", height: "160px" },
          attrs: { src: _vm.imgUrl, fit: "fill" },
        }),
        _c("p", { staticClass: "empty-tip" }, [_vm._v(_vm._s(_vm.empytTip))]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import {ApiGet, ApiPost} from "@/api";

/**
 * @description 地区选择
 */
export const getDistrictData = data => ApiGet('/wx/front/buyers/listByParentCode',data)

/**
 * @description 保存注册信息
 */
export const saveUnableUser = (params,data) => ApiPost('/pc/front/buyers/saveUnableUser',data,{
  headers: {
    'x-validate-code': params.code,
    'x-random-str': params.randomStr
  }
})

/**
 * @description 获取经营范围列表
 */
export const getRegisterBusinessRangeCategoryList = data => ApiGet('/pc/front/buyers/getRegisterBusinessRangeCategoryList',data)

/**
 * @description 获取资质列表
 */
export const getLicenses = customerType => ApiGet('/pc/front/buyers/findLicenseByRegisterCustomerType', {
  customerType
})

/**
 * @description 文件上传
 */
export const batchRegisterUploadFile = data => ApiPost('/pc/front/buyers/batchRegisterUploadFile',data,{
  headers: {
    'Content-Type': 'multipart/form-data'
  },
})

/**
 * @description 资料提交
 */
export const register = data => ApiPost('/pc/front/buyers/register',data)

/**
 * @description 资料提交修改
 */
export const updateBuyersAudit = data => ApiPost('/pc/front/buyers/updateBuyersAudit',data)

/**
 * @description 注册资料查询
 */
export const findRegisterBuyersInfoByLoginUser = registerKey => ApiGet('/pc/front/buyers/findRegisterBuyersInfoByLoginUser', {
  registerKey
})

/**
 * @description 资料提交
 */
export const getAgreement = data => ApiGet('/pc/infArticle/front/getAgreement',data)
<template>
  <div class="category-container">
    <div
        class="first-grade grade-item"
        v-if="firstSelectItem || secondSelectItem || thirdSelectItem || selectFacture || checkList.length">
      <div class="grade-label">
        已选择：
      </div>
      <ul class="grade-values">
        <el-tag
            size="small" closable
            v-if="selectItem"
            @close="()=>{firstSelectItem = '';secondSelectItem = '';thirdSelectItem = '';handleSearch()}">
          {{selectItem}}
        </el-tag>
        <el-tag size="small" v-if="selectFacture" closable @close="()=>{selectFacture = '';handleSearch('nowCategory')}">
          厂家：{{selectFacture}}
        </el-tag>
        <template v-if="checkList.length">
          <el-tag
              size="small" closable
              v-for="tag in checkList" :key="tag"
              @close="()=>{checkList.splice(checkList.indexOf(tag), 1);handleSearch('nowCategory')}"
          >
            {{tag === 'RX' ? '处方RX':''}}
            {{tag === 'A_OTC' ? '甲OTC':''}}
            {{tag === 'B_OTC' ? '乙OTC':''}}
            {{tag === 'OTHER' ? '非药品':''}}
          </el-tag>
        </template>
      </ul>
    </div>
    <div class="first-grade grade-item">
      <div class="grade-label">
        一级类目：
      </div>
      <ul class="grade-values">
        <el-radio-group v-model="firstSelectItem" v-if="productCategory">
          <el-radio
              v-for="(item,index) in productCategory.childTree"
              :key="index"
              :label="item"
              @change="() =>{thirdSelectItem = ''; secondSelectItem = ''; handleSelectItem(item.categoryCode); handleSearch(item)}">
            {{item.categoryNm}}
          </el-radio>
        </el-radio-group>
      </ul>
      <div class="selection-item">
        <span @click="isExpand0 = !isExpand0">
          <i class="toggle-arrow" :class="isExpand0 ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
        </span>
      </div>
    </div>

    <ul v-show="isExpand0">
      <li class="grade-item" v-show="secondCategory.length">
        <div class="grade-label">
          二级类目：
        </div>
        <ul class="grade-values" :class="isExpand1 ? '' : 'small'">
          <el-radio-group v-model="secondSelectItem">
            <el-radio
                v-for="(item,index) in secondCategory"
                :key="index"
                :label="item"
                @change="() =>{ thirdSelectItem = ''; handleSelectItem(item.categoryCode); handleSearch(item) }">
              {{item.categoryNm}}
            </el-radio>
          </el-radio-group>
        </ul>
        <div class="selection-item">
          <!-- <span>多选<i class="el-icon-plus el-icon--right"></i></span> -->
          <span @click="isExpand1 = !isExpand1">{{isExpand1 ? '收起' : '更多'}}
             <i class="el-icon--right" :class="isExpand1 ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
          </span>
        </div>
      </li>
      <li class="grade-item" v-show="thirdCategory.length">
        <div class="grade-label">
          三级类目：
        </div>
        <ul class="grade-values" :class="isExpand2 ? '' : 'small'">
          <el-radio-group v-model="thirdSelectItem">
            <el-radio
                v-for="(item,index) in thirdCategory"
                :key="index"
                :label="item"
                @change="handleSearch(item)">
              {{item.categoryNm}}
            </el-radio>
          </el-radio-group>
        </ul>
        <div class="selection-item">
          <!-- <span>多选<i class="el-icon-plus el-icon--right"></i></span> -->
          <span @click="isExpand2 = !isExpand2">{{isExpand2 ? '收起' : '更多'}}
            <i class="el-icon--right" :class="isExpand2 ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
          </span>
        </div>
      </li>
      <li class="grade-item">
        <div class="grade-label">
          生产厂家：
        </div>
        <ul class="grade-values" :class="isExpand3 ? '' : 'small'">
          <!-- <li v-for="item in manufacture" :key="item"  @click="handleSearch('',item)">{{item}}</li> -->
          <el-radio-group v-model="selectFacture">
            <el-radio
                v-for="(item,index) in manufacture"
                :key="index"
                :label="item"
                @change="handleManufacture(item)">
              {{item}}
            </el-radio>
          </el-radio-group>
        </ul>
        <div class="selection-item">
          <!-- <span>多选<i class="el-icon-plus el-icon--right"></i></span> -->
          <span @click="isExpand3 = !isExpand3">{{isExpand3 ? '收起' : '更多'}}
            <i class="el-icon--right" :class="isExpand3 ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
          </span>
        </div>
      </li>
      <li class="grade-item">
        <div class="grade-label">
          处方类：
        </div>
        <div class="grade-values">
          <el-checkbox-group v-model="checkList" @change="handleSearch('nowCategory')">
            <el-checkbox label="RX">处方RX</el-checkbox>
            <el-checkbox label="A_OTC">甲OTC</el-checkbox>
            <el-checkbox label="B_OTC">乙OTC</el-checkbox>
            <el-checkbox label="OTHER">非药品</el-checkbox>
          </el-checkbox-group>
        </div>
      </li>

      <li class="grade-item" v-if="false">
        <div class="grade-label">
          活动：
        </div>
        <div class="grade-values">
            <el-checkbox v-model="isDisCount" @change="handleSearch">是否促销</el-checkbox>
        </div>
      </li>

    </ul>

    <div class="table-switch">
      <div class="table-switch-row">
        <ul class="sort">
          <li>默认</li>
          <li><el-checkbox v-model="isDisCount" @change="handleSearch">促销商品</el-checkbox></li>
          <!--          <li class="sort-box">-->
          <!--            <span>销量排序</span>-->
          <!--            <span class="caret-wrapper">-->
          <!--                <i class="sort-caret ascending"></i>-->
          <!--                <i class="sort-caret descending"></i>-->
          <!--            </span>-->
          <!--          </li>-->
          <!--          <li class="sort-box">-->
          <!--            价格排序-->
          <!--            <span class="caret-wrapper">-->
          <!--                <i class="sort-caret ascending"></i>-->
          <!--                <i class="sort-caret descending"></i>-->
          <!--            </span></li>-->
        </ul>
<!--        <div class="check-box">-->
<!--          <el-checkbox v-model="checked">不看禁销商品</el-checkbox>-->
<!--        </div>-->
        <div class="right-iew-pattern">
          <slot></slot>
          <div class="view-pattern">
            <div class="photo-board" :class="{'active':viewModel==='PhotoBoard'}"
                 @click="handleSwitchView('PhotoBoard')"><span></span> 大图
            </div>
            <div class="table-board" :class="{'active':viewModel==='ListBoard'}"
                 @click="handleSwitchView('ListBoard')"><span></span> 列表
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getManufacture } from '@/api/commodity-list'
  import { mapGetters } from 'vuex'

  export default {
    name: 'category-search',
    props: {
      manufacture:{
        type: Array,
        default: () => { return [] }
      },
      viewModel: {
        type: String,
        default: ''
      },
      searchForm: {
        type: Object,
        default: () => { return {} }
      },
      select: {
        type: Function,
        default: () => {}
      }
    },
    data () {
      return {
        checked: false,
        isExpand0: false,
        isExpand1: true,
        isExpand2: true,
        isExpand3: false,
        checkList: [],
        secondCategory: [],
        thirdCategory: [],
        // manufacture: [],
        selectTags: [],
        firstId: '',
        secondId: '',
        thirdSelectItem: '',
        isDisCount: false,
        firstSelectItem: '',
        secondSelectItem: '',
        categoryItem: {}, //选中分类
        selectFacture: '' //选中厂家
      }
    },
    watch: {
      searchForm(){

      },
      $route: {
        handler (route) {
          this.handleSelectItem(route.query.categoryCode)
          if(this.searchForm.searchFiled !== this.selectFacture){
            this.selectFacture = ''
          }
        },
        immediate: true
      },
      productCategory: {
        handler (category) {
          this.handleSelectItem(this.$route.query.categoryCode, category)
          // this.handleGetSecondNav(this.$route)
        },
        immediate: true
      }
    },
    computed: {
      ...mapGetters(['productCategory']),
      selectItem () {
        if (this.thirdSelectItem) {
          return this.thirdSelectItem.categoryNm
        } else if (this.secondSelectItem) {
          return this.secondSelectItem.categoryNm
        } else if (this.firstSelectItem) {
          return this.firstSelectItem.categoryNm
        }
      },
      // index () {
      //   let code = this.$route.query.categoryCode
      //   let result = []
      //   if (code && code.length) {
      //     for (let i = 0; i < code.length; i += 3) {
      //       result.push(code.slice(i, i + 3))
      //     }
      //     result.splice(0, 1)
      //     result = result.map(item => (--item).toString())
      //   } else {
      //     result = ['']
      //   }
      //   return result
      // }
    },
    mounted () {
      //生产厂家
      // getManufacture(this.searchForm).then(res => {
      //   this.manufacture = res.data
      // })
      // console.log(this.$store.state.user.productCategory)
    },
    methods: {
      //生产厂家
      getManufacture(data){

        getManufacture(this.searchForm).then(res => {
          this.manufacture = res.data
        })
      },
      handleSelectItem (code,category) {
        if(category) this.$store.state.user.productCategory = category;
        this.firstSelectItem = ''
        this.secondSelectItem = ''
        this.thirdSelectItem = ''
        this.secondCategory = []
        this.thirdCategory = []
        if(code && this.productCategory.childTree) {
          let one = this.productCategory.childTree.filter(e => code.indexOf(e.categoryCode) === 0);
          if(one && one.length > 0){
            this.firstSelectItem = one[0]
            this.secondCategory = this.firstSelectItem.childTree
            this.$emit('secondCategory',this.secondCategory)
            let two = one[0].childTree.filter(e => code.indexOf(e.categoryCode) === 0);
            if(two && two.length > 0){
              this.secondSelectItem = two[0]
              this.thirdCategory = this.secondSelectItem.childTree
              this.$emit('secondCategory',this.secondCategory)
              let third = two[0].childTree.filter(e => code.indexOf(e.categoryCode) === 0);
              this.thirdSelectItem = third[0]
            }
          }
          this.$emit('select', [this.firstSelectItem, this.secondSelectItem, this.thirdSelectItem])
        }
        // let index = this.index
        // let one = productCategory.childTree ? productCategory.childTree[index[0]] : ''
        // if (index[0] && one) {
        //   this.firstSelectItem = one
        //   this.secondSelectItem = ''
        //   this.thirdSelectItem = ''
        //   let two = one.childTree[index[1]]
        //   if (index[1] && two) {
        //     this.secondSelectItem = two
        //     let third = two.childTree[index[2]]
        //     if (index[2] && third) {
        //       this.thirdSelectItem = third
        //     }
        //   }
        //   this.$emit('select', [this.firstSelectItem, this.secondSelectItem, this.thirdSelectItem])
        // }
      },
      handleSwitchView (viewModel) {
        if (viewModel !== this.viewModel) {
          this.$emit('change-page', {
            viewModel
          })
        }
      },
      //点击生产厂家
      handleManufacture(){
        this.searchForm.searchFiled = this.selectFacture
        this.handleSearch('nowCategory')
      },
      handleSearch (categoryItem = {}) {
        if (categoryItem !== 'nowCategory'){
          this.categoryItem = categoryItem || {}
          this.searchForm.categoryId = categoryItem.categoryId || ''
          this.searchForm.categoryCode = categoryItem.categoryCode || ''
        }
        this.searchForm.otcType = this.checkList.join(',')
        this.searchForm.isDiscount = !!this.isDisCount ? 'Y' : undefined
        if(this.searchForm.searchFiled !== this.selectFacture){
            this.selectFacture = ''
        }
        this.$emit('update:searchForm', this.searchForm)
        this.$emit('select', [this.firstSelectItem, this.secondSelectItem, this.thirdSelectItem])
        // this.getManufacture(this.searchForm);
        this.$emit('search')
      },
      //二级类目
      // handleGetSecondNav (route) {
      //   let child = this.productCategory.childTree ? this.productCategory.childTree[index] : ''
      //   if (child && child.childTree) {
      //     this.secondCategory = child.childTree
      //   }
      //   this.$emit('secondCategory',this.secondCategory)
      //   this.handleGetThirdNav(this.index[1] || 0)
      // },
      //三级类目
      // handleGetThirdNav (index) {
      //   if (this.secondCategory.length > 0 && this.secondCategory[index].childTree) {
      //     this.thirdCategory = this.secondCategory[index].childTree
      //   }
      // }
    }
  }
</script>

<style scoped lang="scss">
  .category-container {
    width: 100%;
    height: auto;
    background: #ffffff;
    border: 1px solid rgba(229, 229, 229, 1);

    .grade-item {
      display: flex;
      flex-direction: row;
      padding: 5px 14px;
      border-bottom: 1px solid rgba(229, 229, 229, 1);

      &:last-child {
        border-bottom: none;
      }

      .grade-label {
        width: 70px;
        height: 24px;
        line-height: 24px;
        margin-right: 16px;
        font-size: 12px;
        font-weight: 600;
        color: rgba(153, 153, 153, 1);
        text-align: right;
      }

      .grade-values {
        flex: 1;
        text-align: left;
        font-size: 12px;
        font-weight: 500;
        color: rgba(34, 34, 34, 1);
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        overflow: hidden;
        align-items: center;
        &.small {
          height: 24px;
        }

        li {
          height: 24px;
          padding: 0 1px;
          line-height: 24px;
          margin-right: 40px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 120px;
          cursor: pointer;
        }

        /deep/ .el-tag {
          margin-right: 2px;
        }

        /deep/ .el-radio {
          color: rgba(34, 34, 34, 1);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 40px;

          .el-radio__input {
            display: none
          }

          .el-radio__label {
            height: 24px;
            font-size: 12px;
            padding: 0 1px;
            line-height: 24px;
          }
        }

        /deep/ {
          .el-checkbox-group {
            display: flex;
            align-items: center;
          }

          .el-checkbox {
            .el-checkbox__label {
              font-size: 12px;
              font-weight: 500;
            }
          }
        }
      }

      .selection-item {
        width: 134px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        span {
          display: inline-block;
          margin-left: 12px;
          padding: 3px 6px;
          border-radius: 2px;
          border: 1px solid rgba(204, 204, 204, 1);
          font-size: 12px;
          font-weight: 500;
          color: rgba(34, 34, 34, 1);
          cursor: pointer;

          &:first-child {
            margin-left: 0;
          }

          &:hover i {
            @include TextHighlightColor
          }

          &.active, &:hover {
            @include TextHighlightColor;
            border: 1px solid $ThemeColor;
          }
        }
      }

      &.first-grade {
        .selection-item span {
          border-radius: 50%;
          padding: 0;

          .toggle-arrow {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            font-size: 12px;
          }
        }
      }
    }


    .table-switch {
      height: 48px;
      background: rgba(242, 242, 242, 1);
      padding: 8px 12px;

      .table-switch-row {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .sort {
          display: flex;
          height: 100%;
          align-items: center;
          border-radius: 2px;
          margin-right: 12px;
          font-size: 14px;
          font-weight: 600;
          color: rgba(102, 102, 102, 1);

          li {
            padding: 0 16px;
            margin-right: 10px;
            background: #fff;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            &.sort-box {
              display: flex;
              align-items: center;

              &.active {
                color: #0971EB;
              }

              .caret-wrapper {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                height: 34px;
                width: 24px;
                vertical-align: middle;
                cursor: pointer;
                overflow: initial;
                position: relative;

                .sort-caret {
                  width: 0;
                  height: 0;
                  border: 5px solid transparent;
                  position: absolute;
                  left: 7px;
                }

                .ascending {
                  border-bottom-color: #CCCCCC;
                  top: 5px;
                }

                .descending {
                  border-top-color: #CCCCCC;
                  bottom: 7px;
                }
              }
            }
          }
        }

        .check-box {
          flex: 1;
          text-align: left;
        }
        .right-iew-pattern{
          display: flex;
        }

        .view-pattern {
          display: flex;
          font-size: 14px;
          align-items: center;
          font-weight: 500;
          color: rgba(34, 34, 34, 1);

          .photo-board, .table-board {
            display: flex;
            align-items: center;
            cursor: pointer;

            span {
              display: inline-block;
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }

            &.photo-board {
              margin-right: 14px;

              span {
                background: url("../img/photo-board.png") 100% 100% no-repeat;
              }

              &.active {
                span {
                  width: 16px;
                  height: 16px;
                  background: url("../img/photo-board-active.png") no-repeat;
                  background-size: 100% 100%;
                }
              }
            }

            &.table-board {
              span {
                background: url("../img/table-board.png") 100% 100% no-repeat;
              }

              &.active {
                span {
                  width: 16px;
                  height: 16px;
                  background: url("../img/table-board-active.png") no-repeat;
                  background-size: 100% 100%;
                }
              }
            }
          }
        }
      }
    }
  }
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "procurement-plan-page" },
    [
      _c(
        "keep-alive",
        { attrs: { include: ["ProcurementPlanList"] } },
        [
          _c(_vm.currentView, {
            tag: "component",
            attrs: { params: _vm.params },
            on: { "change-page": _vm.handleChangePage },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <ul class="table-row-tags">
      <el-dropdown placement='bottom-start' v-if="activityList.length>0">
        <li
          class="row-tag tag-promotion"
          v-if="taglist.findIndex(res => res.tag === '促销') !== -1"
        >
          促销
          <i class="el-icon-arrow-down"></i>
        </li>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item></el-dropdown-item>
        <div class="dropdown-item" v-for="(item,index) in activityList" :key="index">
          <br />

          {{ item.discountNm }}
          <br />
          <br />
          {{ item.discountTime }}
          <br />
          <br />
        </div>
      </el-dropdown-menu>
    </el-dropdown>
      <li
        class="row-tag tag-cash"
        v-if="taglist.findIndex(res => res.tag === '现款') !== -1"
      >现款</li>
      <li
        class="row-tag tag-c ash"
        v-if="taglist.findIndex(res => res.tag === '不得现金交易') !== -1"
      >不得现金交易</li>
      <li
        class="row-tag tag-promotion"
        v-if="taglist.findIndex(res => res.tag === '历史购买') !== -1"
      >历史购买</li>
      <li
        class="row-tag tag-plus"
        v-if="taglist.findIndex(res => res.tag === '已加购') !== -1"
      >已加购</li>
      <li
        class="row-tag tag-waring"
        v-if="taglist.findIndex(res => res.tag === '禁销') !== -1"
      >禁销</li>
      <li
        class="row-tag tag-waring"
        v-if="taglist.findIndex(res => res.tag === '限销') !== -1"
      >限销</li>
      <li
        class="row-tag tag-coupon"
        v-if="taglist.findIndex(res => res.tag === '优惠券') !== -1"
      >优惠券</li>
      <li
        class="row-tag tag-coupon"
        v-if="taglist.findIndex(res => res.tag === '二销') !== -1"
      >二销</li>
      <li
        class="row-tag tag-coupon"
        v-if="taglist.findIndex(res => res.tag === '超值换购') !== -1"
      >超值换购</li>
      <li
        class="row-tag tag-coupon"
        v-if="taglist.findIndex(res => res.tag === '近效') !== -1"
      >近效</li>
      <li
        class="row-tag tag-coupon"
        v-if="taglist.findIndex(res => res.tag === '预售') !== -1"
      >预售</li>
      <li
        class="row-tag tag-coupon"
        v-if="taglist.findIndex(res => res.tag === '集采') !== -1"
      >集采</li>
      <li
        class="row-tag tag-coupon"
        v-if="taglist.findIndex(res => res.tag === '冷藏') !== -1"
      >冷藏</li>
      <li
              class="row-tag"
              v-if="taglist.findIndex(res => res.tag === '冷冻') !== -1"
      >冷冻</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    taglist: {
      type: Array,
      default(){
        return []
      }
    },
  },
  mounted(){
    this.taglist.map(r=>{
      if (r.tag === '促销' && r.discountList.length>0) {
        this.activityList=r.discountList
      }
    })
  },
  data() {
    return {
      activityList:[]
    };
  },
};
</script>

<style lang="scss" scoped>
.dropdown-item{
  width: 100%;
  height: 100%;

  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  // background: #FFFFFF;
  // margin: 10px 0 10px 0;
  padding: 0 8px 0 8px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
}
.table-row-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .row-tag {
    height: 18px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
    margin-right: 4px;
    margin-bottom: 5px;
    padding: 0 4px;
    box-sizing: border-box;
  }
  .tag-waring {
    border-color: transparent;
    color: #ffffff;
    background-color: red;
  }

  .tag-promotion {
    background: #ff6600;
    border: none;
    color: #ffffff;
  }

  .tag-cash {
    border-color: $tag-cash;
    color: $tag-cash;
  }

  .tag-coupon {
    border-color: $tag-coupon;
    color: $tag-coupon;
  }

  .tag-plus {
    border-color:  $tag-plus;
    color: $tag-plus;
  }
}
</style>

<template>
  <div class="im-search-pad">
    <el-form ref="searchForm" :inline="true" :model="model">
      <slot />
      <el-form-item v-if="!isSearchBtn">
        <el-button type="primary" @click="onSearch" size="small">搜索</el-button>
        <el-button @click="onReset" size="small">重置</el-button>
        <el-button v-if="hasExpand" type="text" @click="onExpand">
          {{ isExpanded? '收起更多' : '展开更多' }}
          <i :class="isExpanded ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" />
        </el-button>
      </el-form-item>
    </el-form>
    <div v-if="isSearchBtn" :class="{'isSearchBottom':isSearchBtn}">
      <div class="bottom-content">
        <el-button type="primary" size="small" @click="onSearch">搜索</el-button>
        <el-button @click="onReset" size="small">重置</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ImSearchPad',
  props: {
    model: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isExpand: {
      type: Boolean,
      default: false
    },
    hasExpand: {
      type: Boolean,
      default: true
    },
    isSearchBtn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isExpanded: this.isExpand
    }
  },
  methods: {
    onSearch() {
      this.$emit('search')
    },
    onReset() {
      this.$refs['searchForm'].resetFields()
      this.$emit('reset')
    },
    onExpand() {
      this.isExpanded = !this.isExpanded
      this.$emit('update:isExpand', this.isExpanded)
    }
  }
}
</script>
<style lang="scss">
@import '~@/styles/variables';
.im-search-pad {
  margin-bottom: 16px;
  background-color: #fff;
  padding: 16px 16px 0;

  .el-form-item {
    margin-bottom: 16px;
  }
  .isSearchBottom{
     width:100%;
     padding:10px 0 ;
     .bottom-content{
       width:100%;
       display:flex;
       justify-content: center;
       padding-top:10px;
       border-top:1px solid #ccc;
     }
  }
}
</style>

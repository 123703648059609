import {ApiGet, ApiPost} from "@/api";

/**
 * @description 分页搜索品牌及对应的商品
 */
export const pageBrandProduct = data => ApiPost('/pc/front/pageModule/pageBrandProduct', data)

/**
 * @description 分页搜索品牌及对应的商品
 */
export const pageListBrand = data => ApiPost('/pc/front/pageModule/pageListBrand', data)

/**
 * @description 获取后台品牌装修接口
 */
export const getPageBrandMap = brandId => ApiGet('/front/pageModule/getPageBrandMap', {
    brandId
},{
    id: brandId
})

/**
 * @description 获取后台品牌装修接口
 */
export const getBrandProductList = data => ApiPost('/pc/front/pageModule/getBrandProductList', data)

/**
 * @description 获取后台品牌装修接口
 */
export const getBrandById = brandId => ApiGet('/brand/front/getBrandById', {
    brandId
})
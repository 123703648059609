<template>
  <div class="member-center">
    <Search :hidden-nav="true"/>
    <div class="member-center-container">
      <div class="container">
        <div class="results">
          <div>
            <div class="results-text"><i size="32" class="el-icon-success" style="color: #09AD25;" /> 您的账单支付成功！</div>
            <div class="results-text"><i size="32" class="el-icon-error" style="color: red;" /> 支付失败，可返回重新再试</div>
            <div class="results-money">账单总金额：<span>¥28000.00</span></div>
          </div>
          <el-button type="primary" @click="goBack"> 返回往来账核对</el-button>
        </div>
        <div style="padding: 20px 20px 0 114px;">
         <im-table-page ref="tablePage" :data.sync="tableData" :remote-method="queryData" style="width:1066px">
           <el-table-column label="序号" type="index" fixed="left" width="55">
           </el-table-column>
           <el-table-column prop="businessDate" label="业务日期" fixed="left" width="105px"
             show-overflow-tooltip>
             <template slot-scope="scope">
               <span>{{ scope.row.businessDate.replace(new RegExp("-","gm"),"/") }}</span>
             </template>
             </el-table-column>
             <el-table-column label="发票号" width="165px" prop="invoiceNum" show-overflow-tooltip />
             <el-table-column label="分支机构" prop="ettleAccountNm" width="180px" show-overflow-tooltip></el-table-column>
             <el-table-column label="金额" align="right" prop="amount" width="100px" show-overflow-tooltip>
               <template slot-scope="scope">
               <span style="color:#FA6400">{{ $util.toFixed(scope.row.amount) }}</span>
               </template>
             </el-table-column>
             <el-table-column prop="goodsId" label="货品ID" width="105px" show-overflow-tooltip>
             </el-table-column>
             <el-table-column label="商品名称" width="120px" prop="productNm" show-overflow-tooltip>
             </el-table-column>
             <el-table-column label="通用名" width="80px" prop="commonNm" show-overflow-tooltip>
             </el-table-column>
             <el-table-column label="规格" width="100px" prop="spec" show-overflow-tooltip>
             </el-table-column>
             <el-table-column label="生产厂家" width="200px" prop="manufacturer" show-overflow-tooltip>
             </el-table-column>
             <el-table-column label="基本单位" width="100px" prop="unit" show-overflow-tooltip>
             </el-table-column>
             <el-table-column label="数量" width="100px" prop="quantity" show-overflow-tooltip>
             </el-table-column>
             <el-table-column label="单价" align="right" width="100px" prop="unitPric" show-overflow-tooltip>
               <template slot-scope="scope">
               <span style="color:#FA6400; ">{{ $util.toFixed(scope.row.unitPric) }}</span>
               </template>
             </el-table-column>
             <el-table-column label="发票日期" width="105px" prop="invoiceDate" show-overflow-tooltip>
             <template slot-scope="scope">
                           <span>{{ scope.row.invoiceDate.replace(new RegExp("-","gm"),".") }}</span>
                         </template>
             </el-table-column>
             <el-table-column label="账期至" v-if="userType === 'SALESMAN'" width="80px" prop="paymentDays" fixed="right" show-overflow-tooltip>
             </el-table-column>
             <el-table-column label="逾期日期" v-if="userType === 'SALESMAN'" width="80px" prop="overdueDate" fixed="right" show-overflow-tooltip>
             </el-table-column>
             </im-table-page>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Search from '@/components/Search/Search'
  import { getIntercourseBill } from '@/api/member-center/property-manage'
  import { mapGetters } from 'vuex'
  export default {
    name: 'intercourseSuccess',
    components: { Search },
    data () {
      return {
        tableData: [],
        searchForm: {
          isReceivable: 'N',
          startTime: '',
          endTime: '',
        }
      }
    },
    computed: {
      ...mapGetters(['userType'])
    },
    created () {
    },
    methods: {
      queryData (data) {
        return getIntercourseBill({ ...data, condition: this.searchForm })
      },
      goBack(){
        this.$router.push('/member-center/property-manage/intercourse-bill')
      }
    }
  }
</script>

<style scoped lang="scss">
  .member-center-container{
    background: #F5F5F5;
    padding: 20px 0;
  }
  .container{
    margin: 0 auto;
    width: 1200px;
    height: auto;
    border-radius: 8px 8px 0px 0px;
    background-color: #fff;
  }
  .results{
    display: flex;
    justify-content: space-between;
    align-items:center;
    height: 130px;
    padding: 0 20px 0 84px;
    border-bottom: 1px solid #e5e5e5;
  }
  .results-text{
    text-align: left;
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    color: #373737;
    margin-bottom: 9px;
  }
  .results-money{
     text-align: left;
     line-height: 28px;
     font-size: 16px;
     color: #262626;
     font-weight: 600;
     padding-left: 30px;
     span{
       color: #FF3B30;
       font-size: 20px;
     }
  }
</style>

<template>
  <div class="brand-center-content">
    <Search :index-nav="true"/>
    <div class="brand-content">
      <div class="brand-bg"></div>
      <div class="brand">
        <div class="company">
          <div v-for="(item, index) in companyData" :key="index" class="company-list">
            <div class="company-logo"><img :src="item.brandLogo || brandDefaultImg" @error="item.brandLogo = brandDefaultImg" @click="checkDetail(item.brandId)" /></div>
            <div class="company-name" @click="checkDetail(item.brandId)">{{ item.brandNm }}</div>
          </div>
        </div>
        <div class="brand-list" v-for="(value, index) in brandList" :key="index">
          <div class="brand-lt">
            <div class="comp-logo"><img :src="value.brandLogo || brandDefaultImg" @click="checkDetail(value.brandId)" @error="value.brandLogo = brandDefaultImg" /></div>
            <div class="comp-name" @click="checkDetail(value.brandId)">{{ value.brandNm }}</div>
            <div class="comp-introduce">{{ value.brandStory }}</div>
            <div class="check-button" @click="checkDetail(value.brandId)">查看品牌详情</div>
          </div>
          <div class="brand-rt">
            <div class="comm-list" v-for="(item, index) in value.brandProductDetailVoList" :key="index">
              <div class="comm-content">
                <div class="identifying" v-if="userType =='SALESMAN'">
                    <img v-if="item.whiteRecordFlag  == 1" alt src="@/assets/img/icon/whiterecord.png">
                    <img v-else-if="item.blackRecordFlag  == 1" alt src="@/assets/img/icon/blackrecord.png">
                </div>
                <div class="comm-logo">
                  <router-link :to="'/detail/' + item.productId"><img :src="item.picUrl || productDefault" @error="item.picUrl = productDefault" /></router-link>
                  <div class="comm-price">{{ isLogin ? $util.getPriceByZero(item.price,item) : '登录可见'}}</div>
                </div>
                <router-link :to="'/detail/' + item.productId">

                  <div class="comm-name">
                    {{$util.getGoodsName(item)}}
                  </div>
                </router-link>
                <div class="comm-spec">{{ item.spec }} x {{ item.unit }}</div>
                <div class="comm-manufactor">{{ item.manufacturer }}</div>
              </div>
              <div class="purchase">
                <div class="spec">
                  <span>最小购买倍数 {{ getRealMinBuyNum(item)}} </span>
                  <template v-if="userType ==='SALESMAN'">
                    <span>库存 {{ item.stockQuantity}}</span>
                  </template>
                  <template v-if="userType === 'BUYERS'">
                    <span>库存 {{ item.stockQuantity >= 100 ? '>100' : '<100' }}</span>
                  </template>
                </div>
                <div class="num">
                  <div class="input-num">
                    <el-input-number
                      v-model="item.num"
                      :precision="getDecimalPointNum(item)"
                      :min="getRealMinBuyNum(item)"
                      :step="getRealMinBuyNum(item)"
                      :max="item.stockQuantity"
                      step-strictly
                      size="mini"
                    />
                  </div>
                  <div class="pur-button" @click="buyNow(item,item.num)">立即购买</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import Search from '@/components/Search/Search'
import { pageBrandProduct, getBrandProductList } from '@/api/promotion-zone/brand-center'
import { getPageModuleMap } from '@/api/index/index'
import brandDefaultImg from '@/assets/img/index/brand_default.png'
import productDefault from '@/assets/img/index/product_default.png'
import { addItem } from '@/api/detail'
import {mapGetters} from "vuex";
import addToCartMixin from '@/common/addToCart'

export default {
  components: {
    Search
  },
  mixins:[addToCartMixin],
  data() {
    return {
      isLogin:false,
      companyData: [],
      brandList: [],
      num: 1,
      brandDefaultImg,
      productDefault
    }
  },
  created() {
    this.doRefresh()
  },
  mounted(){
    this.isLogin = getToken()
  },
  watch: {
    refreshModule :{
      handler () {
        this.doRefresh()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['refreshModule','userType'])
  },
  methods: {
    checkDetail(brandId) {
      this.$router.push('/brand-detail/' + brandId)
    },
    doRefresh() {
      getPageModuleMap({
        innerCodes: ['PC_BRAND_LIST', 'PC_BRAND_LIST_F1']
      }).then(res => {
        this.companyData = res.data.PC_BRAND_LIST
        let brandIds = []
        res.data.PC_BRAND_LIST_F1.map(val => {
          brandIds.push(val.brandId)
        })
        getBrandProductList({
          brandIds: brandIds
        }).then(res => {
          res.data.map(val => {
            val.brandProductDetailVoList = val.brandProductDetailVoList.slice(0, 5)
            val.brandProductDetailVoList.map(item => {
              item.num = this.getRealMinBuyNum(item)
            })
          })
          this.brandList = res.data
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.brand-center-content {
  .brand-content {
    position: relative;
    overflow-y: hidden;
    min-width: 1200px;
    background: rgba(21, 29, 82, 1);

    .brand-bg {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2000px;
      min-width: 1200px;
      background: url("../../../assets/img/promotion-zone/brand-center/brand-center-bg.png") no-repeat center;
      background-size: cover;
    }

    .brand {
      width: 1200px;
      margin: 345px auto 40px;
      position: relative;
      z-index: 1;

      .company {
        background: linear-gradient(135deg, rgba(254, 233, 209, 1) 0%, rgba(240, 207, 178, 1) 100%);
        border-radius: 8px;
        display: flex;
        flex-flow: wrap;
        padding: 20px 20px 10px;

        .company-list {
          width: 185px;
          height: 185px;
          background: rgba(255, 255, 255, 1);
          border-radius: 8px;
          margin-bottom: 10px;
          padding: 32px 20px 0;
          margin-left: 10px;
          &:nth-child(6n - 5){
            margin-left: 0;
          }

          .company-logo {
            width: 144px;
            height: 88px;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .company-name {
            padding-top: 12px;
            font-size: 14px;
            font-weight: 500;
            color: #8B4B32;
            cursor: pointer;
          }
        }
      }

      .brand-list {
        margin-top: 20px;
        background: #fff;
        border-radius: 8px;
        height: 282px;
        display: flex;

        .brand-lt {
          width: 144px;
          min-width: 144px;
          padding: 24px 8px 10px 16px;
          background: linear-gradient(135deg, rgba(254, 233, 209, 1) 0%, rgba(240, 207, 178, 1) 100%);
          border-radius: 8px;
          text-align: left;

          .comp-logo {
            width: 80px;
            height: 80px;

            img {
              width: 100%;
              height: 100%;
              &:hover{
              cursor: pointer;
              }
            }
          }

          .comp-name {
            font-size: 16px;
            font-weight: 600;
            color: #8B4B32;
            padding-top: 24px;
            &:hover{
            cursor: pointer;
            }
          }

          .comp-introduce {
            font-size: 12px;
            font-weight: 500;
            color: #99877A;
            line-height: 18px;
            padding-top: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
          }

          .check-button {
            margin-top: 12px;
            display: inline-block;
            padding: 0 12px;
            height: 26px;
            line-height: 26px;
            font-size: 12px;
            font-weight: 400;
            color: rgba(139, 75, 50, 1);
            background: rgba(254, 243, 221, 0.6);
            box-shadow: 2px 4px 6px 0px #f1ceb0;
            border-radius: 16px;
            cursor: pointer;

            &:hover {
              opacity: 0.9;
            }
          }
        }

        .brand-rt {
          padding: 12px;
          display: flex;

          .comm-list {
            height: 100%;
            margin-left: 8px;
            position: relative;
            overflow: hidden;

            &:first-child {
              margin-left: 0;
            }

            .comm-content {
              position: relative;
              width: 200px;
              height: 100%;
              background: linear-gradient(135deg, rgba(252, 253, 248, 1) 0%, rgba(253, 236, 219, 1) 100%);
              border-radius: 4px;
              padding: 20px 20px 0;
              text-align: left;

              .comm-logo {
                width: 100%;
                height: 160px;
                position: relative;

                img {
                  width: 100%;
                  height: 100%;
                }

                .comm-price {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  height: 28px;
                  line-height: 28px;
                  text-align: center;
                  background: rgba(56, 63, 123, 1);
                  border-radius: 4px;
                  font-size: 18px;
                  font-weight: 600;
                  color: rgba(254, 233, 209, 1);
                }
              }

              .comm-name {
                font-size: 14px;
                font-weight: bold;
                color: rgba(139, 75, 50, 1);
                padding-top: 7px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .comm-spec {
                font-size: 12px;
                font-weight: 600;
                color: rgba(153, 135, 122, 1);
                line-height: 17px;
                padding-top: 6px;
                height: 24px;
                overflow: hidden;
              }

              .comm-manufactor {
                font-size: 12px;
                font-weight: 500;
                color: rgba(153, 135, 122, 1);
                line-height: 17px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .purchase {
              position: absolute;
              bottom: -80px;
              left: 0;
              right: 0;
              transition: bottom .3s ease-out;

              .spec {
                height: 28px;
                line-height: 28px;
                background: rgba(102, 102, 102, 1);
                box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.15);
                color: rgba(255, 255, 255, 1);
                font-size: 12px;
                span {
                  padding: 0 6px;
                  float: left;
                }
              }

              .num {
                display: flex;

                .input-num {
                  width: 100px;
                  padding: 6px;
                  background: rgba(47, 56, 68, 1);
                }

                .pur-button {
                  flex: 1;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: rgba(250, 100, 0, 1);
                  font-size: 16px;
                  font-weight: 400;
                  color: rgba(255, 255, 255, 1);
                  cursor: pointer;
                }
              }
            }
          }

          .comm-list:hover .purchase {
            bottom: 0;
          }
        }
      }
    }
  }
  .identifying {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 32px;
    height: 32px;
    z-index: 10;
    img {
     width: 100%;
     height: 100%;
    }
  }
}
</style>

<style lang="scss">
.brand-center-content {
  .brand-list {
    .purchase {
      .input-num {
        .el-input-number {
          width: 100%;
          line-height: 22px;
        }

        .el-input-number__decrease, .el-input-number__increase {
          width: 24px;
        }

        .el-input__inner {
          height: 24px;
          line-height: 24px;
          padding-left: 24px;
          padding-right: 29px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="forget-password-from">
    <div class="pwd-success">
      <div class="success-tips clearfix">
        <div class="tips-icon">
          <img src="@/assets/img/login/icon-success.png" alt="">
          <span>恭喜您密码修改成功！</span>
        </div>
        <p>您的账号密码修改成功，<el-button class="login" type="text" @click="goLogin">登录</el-button>后可以正常下单</p>
        <el-button type="primary" class="login-btn" @click="goLogin">去登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    params: {
      type: Object,
      default: {}
    },
  },
  methods: {
    goLogin() {
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
.forget-password-from {
  .pwd-success {
    padding-top: 40px;
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    .el-button--text{
      font-size: 16px;
    }
    .success-tips {
      display: inline-block;
      .tips-icon {
        display: flex;
        justify-content: center;
        img {
          width: 32px;
          height: 32px;
        }
        span{
          font-size:24px;
          font-weight:500;
          color:rgba(38,38,38,1);
          line-height: 32px;
          padding-left: 8px;
        }
      }
      p {
        padding-top: 16px;
        font-size: 16px;
        font-weight: 500;
        display: block;
        color: #222222;
        padding-left: 12px;
        .login{
          @include TextHighlightColor
        }
      }
    }
    .login-btn {
      width: 160px;
      display: block;
      margin:40px auto 100px;
      height: 40px;
      font-size: 16px;
      font-weight: 500;
      @include NormalBtnColor
    }
  }
}
</style>


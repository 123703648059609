var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "main",
    },
    [
      _c(
        "el-table",
        {
          ref: "table",
          class: { "is-tabledata-empty": _vm.tableData.length === 0 },
          staticStyle: { "margin-left": "1px" },
          attrs: {
            border: "",
            data: _vm.tableData,
            "row-class-name": _vm.tableRowClassName,
            "header-row-class-name": "header-row",
            "span-method": _vm.spanMethod,
          },
          on: {
            "update:data": function ($event) {
              _vm.tableData = $event
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "code",
              fixed: "left",
              label: "商品编码",
              align: "center",
              width: "110px",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "table-product-code" }, [
                      _c("p", [_vm._v(_vm._s(scope.row.productCode))]),
                      scope.row.isHistoryBuyProduct === "Y"
                        ? _c("span", { staticClass: "history" }, [
                            _vm._v("历史购买"),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "name", label: "商品名称", "min-width": "120px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-popover",
                      { attrs: { placement: "right", trigger: "hover" } },
                      [
                        _c(
                          "div",
                          { staticClass: "img-content" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "136px", height: "136px" },
                              attrs: {
                                src: scope.row.picUrl || _vm.Product,
                                fit: "fill",
                              },
                              on: {
                                error: function ($event) {
                                  scope.row.picUrl = _vm.Product
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "table-product-name",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          },
                          [
                            scope.row.otcType && scope.row.otcType !== "其他"
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "name-tag",
                                    class: {
                                      jiaOtc: scope.row.otcType === "甲OTC",
                                      yiOtc: scope.row.otcType === "乙OTC",
                                      rxOtc: scope.row.otcType === "RX",
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row.otcType))]
                                )
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                staticClass: "product-name",
                                on: {
                                  click: function ($event) {
                                    return _vm.jumpDetail(scope.row.productId)
                                  },
                                },
                              },
                              [
                                _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$util.getGoodsName(scope.row)
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                            _c("tagList", {
                              attrs: { taglist: scope.row.tagList },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm.userType == "SALESMAN"
            ? _c("el-table-column", {
                attrs: { label: "标识", "min-width": "80px", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "identifying" }, [
                            scope.row.whiteRecordFlag == 1
                              ? _c("img", {
                                  attrs: {
                                    alt: "",
                                    src: require("@/assets/img/icon/whiterecord.png"),
                                  },
                                })
                              : scope.row.blackRecordFlag == 1
                              ? _c("img", {
                                  attrs: {
                                    alt: "",
                                    src: require("@/assets/img/icon/blackrecord.png"),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2087956621
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              prop: "spec",
              align: "center",
              label: "规格",
              "min-width": "80px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "manufacturer",
              align: "left",
              label: "厂家(上市许可持有人)/产地",
              width: "150px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "unit",
              align: "center",
              label: "单位",
              "show-overflow-tooltip": "",
              width: "56px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "packTotal",
              align: "center",
              label: "件包装",
              width: "60px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            key: "rate",
            attrs: {
              prop: "rate",
              align: "center",
              label: "销项税",
              "min-width": "30px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "price",
              align: "right",
              label: "销售价",
              "min-width": "60px",
              sortable: "",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "salePrice" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$util.getPriceByZero(scope.row.price, scope.row)
                        )
                      ),
                    ]),
                    scope.row.isDiscount === "Y" && _vm.$util.isLogin()
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#999999",
                              "text-decoration": "line-through",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.salePrice
                                  ? "￥" + scope.row.salePrice
                                  : ""
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm.$store.getters.buyersVo &&
          _vm.$store.getters.buyersVo.showStockType != 5
            ? _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "stockQuantity",
                  align: "center",
                  label: "库存",
                  "min-width": "60px",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.$store.getters.buyersVo.showStockType == 4
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.stockQuantity >= 100
                                        ? ">100"
                                        : "<100"
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm.$store.getters.buyersVo.showStockType == 3
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.stockQuantity > 0 ? "有" : "无"
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm.$store.getters.buyersVo.showStockType == 2
                            ? _c(
                                "span",
                                {
                                  class:
                                    scope.row.stockQuantity &&
                                    scope.row.stockQuantity >= 100
                                      ? ""
                                      : "inventory-warning",
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(scope.row.stockQuantity) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  82669791
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              prop: "expDate",
              align: "center",
              label: "最近效期",
              "min-width": "80px",
              sortable: "",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "采购数量", width: "106px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input-number", {
                      attrs: {
                        size: "mini",
                        precision: _vm.getDecimalPointNum(scope.row),
                        "step-strictly": "",
                        step: _vm.getRealMinBuyNum(scope.row),
                        min: _vm.getRealMinBuyNum(scope.row),
                      },
                      model: {
                        value: scope.row.count,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "count", $$v)
                        },
                        expression: "scope.row.count",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", width: "120", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.isLicenseOutOfTime
                      ? _c(
                          "div",
                          { staticClass: "table-operation" },
                          [
                            _c(
                              "el-button",
                              { attrs: { size: "mini", disabled: true } },
                              [_vm._v("证照过期")]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "table-operation" },
                          [
                            scope.row.stockQuantity === 0
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { size: "mini", type: "info" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleRegisterGoods(
                                          scope.row,
                                          scope.row.count
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("缺货登记")]
                                )
                              : _c(
                                  "el-button",
                                  {
                                    staticClass: "add-shopping-cart",
                                    attrs: {
                                      size: "mini",
                                      disabled: _vm.isBanAddToCart,
                                      loading: scope.row.loading,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.beforeAddItem(
                                          scope.row.skuId,
                                          "NORMAL",
                                          scope.row.count,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("加入购物车 ")]
                                ),
                          ],
                          1
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.tableData.length === 0 && !_vm.isLoading
        ? _c("empty-box", { staticStyle: { padding: "16px" } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="validContent">
        <div class="validModal"></div>
        <div class="validBox">
            <div class="title">请完成安全验证</div>
            <div class="box" v-loading="loading">
                <slide-verify
                        ref="slideblock"
                        :w="350"
                        :h="200"
                        @again="onAgain"
                        @fulfilled="onFulfilled"
                        @success="onSuccess"
                        @fail="onFail"
                        @refresh="onRefresh"
                        :accuracy="accuracy"
                        :slider-text="text"
                ></slide-verify>
            </div>
            <div class="handle">
                <el-button type="text" @click="onCancel">取消</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import SlideVerify from '@/components/SlideVerify/'
    export default {
        name: "slideVerifys",
        components:{
          SlideVerify
        },
        data(){
            return {
                loading:false,
                text:'向右拖动滑块填充拼图',
                msg: '',
                // 精确度小，可允许的误差范围小；为1时，则表示滑块要与凹槽完全重叠，才能验证成功。默认值为5
                accuracy: 8,
            }
        },
        methods:{
            onSuccess(times){
                // console.log('验证通过，耗时 ' + times + '毫秒');
                this.msg = `验证通过, 耗时${(times / 1000).toFixed(1)}s`
                this.$emit('onSuccess',times)
            },
            onFail(){
                // console.log('验证不通过');
                this.msg = ''
            },
            onRefresh(){
                // console.log('点击了刷新小图标');
                this.msg = ''
                this.loading = true
            },
            onFulfilled() {
                setTimeout(()=>{
                    this.loading = false
                },1000)
                // console.log('刷新成功啦！');
            },
            onCancel(){
                this.$emit('onCancel')
            },
            onAgain() {
                // console.log('检测到非人为操作的哦！');
                this.msg = 'try again';
                // 刷新
                this.$refs.slideblock.reset();
            },
            handleReset() {
                this.loading = true
                // 父组件直接可以调用刷新方法
                this.$refs.slideblock.reset();
            },
        }
    }
</script>

<style scoped lang="scss">
    .validContent{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        min-width: 1200px;
        z-index: 999;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .validModal{
            width: 100%;
            position: absolute;
            z-index: 0;
            background: rgba(0,0,0,.25);
            height: 100%;
            left: 0;
            top: 0;
        }
        .validBox{
            position: relative;
            z-index: 1;
            background: #ffffff;
            border-radius: 10px;
            padding: 20px 20px 10px;
            box-shadow: 0 0 10px 0 rgba(0,0,0,.3);
            animation: fadeIn .3s linear;
            .title{
                width: 100%;
                text-align: left;
                font-size: 18px;
                margin: 0 0 20px 0;
            }
        }
    }
    @keyframes fadeIn {
        from{
            opacity: .3;
        }
        to{
            opacity: 1;
        }
    }
</style>

<template>
  <div class="procurement-plan-list">
    <div class="page-header">采购计划</div>
    <div class="aptitudes" v-if="license.isFailure === 'Y'">温馨提示：{{userType !=='SALESMAN'?'您有'  +  license.failureSum + '张证照已过期,请联系业务员更换,以免影响您的正常采购' : '该客户有'+ license.failureSum  +  '张证照已过期，以免影响正常采购！'}}<el-button size="small" type="text" @click="handleConfirm">查看详情</el-button>
      </div>
    <!-- 按钮区域 -->
    <div class="page-header-btn">
      <div>
        <el-button type="primary" @click="handleAdd" style="height:35px;line-height: 35px;padding: 0 20px">
          新增采购计划
        </el-button>
      </div>
      <div class="right">
        <span style="margin-right: 10px;">计划名称</span>
        <el-input v-model="searchForm.procurementPlanNm" placeholder="输入计划名称" style="width:210px;margin-right:20px"/>
        <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
        <el-button type="text" size="small" style="height:30px;color:#000000" @click="handleReset">重置</el-button>
      </div>
    </div>
    <!-- 表格内容 -->
    <im-table-page
        ref="tablePage"
        :data.sync="tableData"
        :remote-method="queryData"
        @updated="updated"
    >
      <el-table-column label="序号" prop="procurementPlanNm" width="60px" show-overflow-tooltip fixed="left"
                       align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + 1}}</span>
        </template>
      </el-table-column>
      <el-table-column label="提醒" prop="isRemind" width="80px" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-switch
              v-model="scope.row.isRemind"
              :disabled="true"
              class="swich-inner-text"
              active-text="启"
              inactive-text="停"
              active-color="#1CB7CD"
              active-value="Y"
              inactive-value="N"
              @change="switchChange(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column label="模板名称" prop="procurementPlanNm" width="200px" show-overflow-tooltip/>
      <el-table-column label="商品总品规" prop="productQuantity" min-width="110px" show-overflow-tooltip>
        <template slot-scope="scope">
          共<span class="text111">{{ scope.row.productQuantity }}</span>种
        </template>
      </el-table-column>
      <el-table-column label="提醒方式" prop="useCommodityTypes" width="140px" max-width="200px" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{scope.row.isAppRemind === 'Y' ? 'APP' : ''}}</span>&nbsp;
          <span>{{scope.row.isPcRemind === 'Y' ? 'PC' : ''}}</span>&nbsp;
          <span>{{scope.row.isWechatRemind === 'Y' ? '微信' : ''}}</span>
        </template>
      </el-table-column>
      <el-table-column label="提醒时间" prop="remindTime" min-width="176" show-overflow-tooltip/>
<!--      <el-table-column label="重复提醒" prop="isRepeatRemind" min-width="135" max-width="200" show-overflow-tooltip>-->
<!--        <template slot-scope="scope">-->
<!--          <span>{{scope.row.isRepeatRemind === 'Y' && !!Number(scope.row.remindDay) ? `是,每${scope.row.remindDay}天提醒我` : '否'}}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="操作" min-width="180px" fixed="right">
        <template slot-scope="scope">
          <el-row class="table-edit-row">

            <el-button size="mini"
                       style="padding: 2px 4px;border-color: #DCDFE6!important;color: #606266!important;"
                       v-loading='scope.row.loading'
                       @click="addItem(scope.row.procurementPlanId,scope.row)"
                       :disabled="isBanAddToCart">
              {{ isLicenseOutOfTime ? '证照过期' : '一键采购'}}
            </el-button>

            <el-button size="mini" style="padding: 2px 4px;border-color: #DCDFE6!important;color: #606266!important;" @click="handleEdit(scope.row)">编辑</el-button>
            <im-confirm-popup
                :index="scope.$index"
                tip-txt="是否删除该采购计划？"
                reference-btn-txt="删除"
                @confirm="handleDelete(scope.row)"
            />
          </el-row>
        </template>
      </el-table-column>
    </im-table-page>
  </div>
</template>
<script>
  import { pageProcurementPlan, delProcurementPlanById, planBuy } from '@/api/member-center/transactions-using'
  import { mapGetters } from 'vuex'
  import addToCartMixin from '@/common/addToCart'

  export default {
    name: 'ProcurementPlanList',
    mixins:[addToCartMixin],

    props: {
      params: {
        type: Object,
        default () {
          return {}
        }
      }
    },
    data () {
      return {
        tableData: [],
        searchForm: {
          procurementPlanNm: ''
        }
      }
    },
    computed: {
      ...mapGetters(['license','userType'])
    },
    watch: {
      params: {
        handler (newVal) {
          if (newVal.refreshList) {
            this.doRefresh()
          }
        },
        deep: true
      },
      '$store.state.user.refreshModule': {
        handler () {
          this.doRefresh()
        },
        deep: true
      }
    },
    methods: {
      handleConfirm () {
        this.$router.push(`/member-center/personal-center/my-aptitudes`)
      },
      doRefresh () {
        this.$refs.tablePage.doRefresh({ currentPage: 0, pageSize: 10 })
      },
      handleSearch () {
        this.doRefresh()
      },
      handleReset () {
        this.searchForm = {
          procurementPlanNm: ''
        }
        this.doRefresh()
      },
      switchChange () {

      },
      queryData (data) {
        let listAll = {}
        this.tableData = [];
        return new Promise((then,reject)=>{
         pageProcurementPlan({ ...data, condition: this.searchForm }).then(res=>{
           res.data.records.map(r=>{
              listAll = Object.assign(r,{loading:false})
              this.tableData.push(listAll)
            })
            then(res)
         })
        })
      },
      updated () {
        this.$refs.tablePage.doLayout()
      },
      handleAdd () {
        this.$emit('change-page', {
          page: 'ProcurementPlanUpdate',
          params: {
            editType: 'add'
          }
        })
      },
      addItem (procurementPlanId,r) {
        r.loading=true
        planBuy({ procurementPlanId }).then(res => {
          const { code } = res
          if (code === '0') {
            r.loading=false
            this.$message.success('加入成功！')
            this.$store.dispatch('user/setCartNum', res.data.allProductTypeTotalQuantity)
          }
        }).catch(err=>{
          r.loading=false
        })
      },
      handleEdit (row) {
        this.$emit('change-page', {
          page: 'ProcurementPlanUpdate',
          params: {
            rowData: row,
            editType: 'edit'
          }
        })

      },
      handleDelete (row) {
        delProcurementPlanById(row.procurementPlanId).then(res => {
          this.$message.success('删除成功！')
          this.doRefresh()
        })
      }
    }
  }
</script>
<style lang="scss">
  .page-header-btn {
    .el-input__inner {
      height: 30px;
      line-height: 30px
    }
  }
</style>
<style scoped lang="scss">
.text111{
  @include TextHighlightColor;
  margin: 0 3px;
}
.aptitudes{
    line-height: 40px;
    font-size: 14px;
    color: #555;
    background-color: #FFF0EF;
    margin-bottom: 32px;
  }
  .page-header-btn {
    .el-input__inner {
      height: 30px;
      line-height: 30px
    }
  }

  .procurement-plan-list {
    margin: 0 auto;

    .page-header {
      margin: 0;
      font-size: 16px;
      color: #262626;
      font-weight: bold;
      height: 50px;
      line-height: 50px;
      text-align: left;
      padding: 0 28px;
      border-bottom: 1px solid #e1e1e1;
    }

    /deep/ {
      .im-confirm-popup--el-button span {
        font-size: 12px;
        padding: 1px 4px;
        margin-left: 8px;
        border: 1px solid #DCDFE6;
        border-radius: 3px;
        color: #606266;

        &:hover {
          color: #0971EB;
        }
      }
    }

    .page-header-btn {
      display: flex;
      justify-content: space-between;
      padding: 20px 20px 10px;

      .right {
        font-style: normal;
        font-size: 12px;
        color: #666666;
        display: flex;
        align-items: center;

        /deep/ {
          .el-button--small {
            font-size: 12px;
          }
        }

        .el-input__inner {
          height: 30px;
        }
      }
    }

    .im-table-page {
      padding: 0 20px;
    }
  }
</style>

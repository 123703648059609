var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "registerDiv" },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          { staticClass: "registerHeader" },
          [
            _c(
              "router-link",
              { staticClass: "already-button", attrs: { to: "/" } },
              [
                _c("img", {
                  staticClass: "bgImg",
                  attrs: { src: require("@/assets/img/logo.gif"), alt: "" },
                }),
              ]
            ),
            _c("div", { staticClass: "division" }),
            _c("span", { staticClass: "title" }, [
              _vm._v(
                _vm._s(this.$route.query.isEdit ? "修改密码" : "找回密码")
              ),
            ]),
            _c(
              "span",
              { staticClass: "already" },
              [
                _c(
                  "router-link",
                  { staticClass: "already-button", attrs: { to: "/login" } },
                  [_vm._v("登录")]
                ),
                _c(
                  "router-link",
                  { staticClass: "already-button", attrs: { to: "/register" } },
                  [_vm._v("注册")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "step" }, [
        _c("span", { staticClass: "stepNum" }, [_vm._v("1")]),
        _c("span", { staticClass: "stepTitle" }, [_vm._v("验证手机号")]),
        _c("span", {
          staticClass: "stepLine",
          class: { stepLineNot: _vm.writeInfo },
        }),
        _c(
          "span",
          { staticClass: "stepNum", class: { stepNumNot: _vm.writeInfo } },
          [_vm._v("2")]
        ),
        _c(
          "span",
          { staticClass: "stepTitle", class: { stepTitleNot: _vm.writeInfo } },
          [_vm._v("设置新密码")]
        ),
        _c("span", {
          staticClass: "stepLine",
          class: { stepLineNot: _vm.writeSuccess },
        }),
        _c(
          "span",
          { staticClass: "stepNum", class: { stepNumNot: _vm.writeSuccess } },
          [_vm._v("3")]
        ),
        _c(
          "span",
          {
            staticClass: "stepTitle",
            class: { stepTitleNot: _vm.writeSuccess },
          },
          [_vm._v("设置成功，去登录")]
        ),
      ]),
      _c(
        "keep-alive",
        { attrs: { include: ["accountInfo", "setPassword"] } },
        [
          _c(_vm.currentView, {
            tag: "component",
            attrs: { params: _vm.params },
            on: { next: _vm.next },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "divisionClass" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
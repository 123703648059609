<template>
  <div class="main">
    <!-- 搜索、导航栏 -->
    <Search :index-nav="true" />
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ params.title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 中间内容 -->
    <div class="chinaWestern">
      <div class="allChild" v-for="(item,index) in titleAll" :key="index">
        <div class="sizeCenter">
          <div class="allCenter">
          <div class="leftText">{{ index+1 + 'F' }}</div>
            <div class="rightText"><router-link :to="{path:'/commodity-list',query:{categoryId:item.categoryId,categoryCode:item.categoryCode}}">{{ item.categoryNm }}</router-link></div>
          </div>
        </div>
        <div class="childNext" v-for="(itemSon,indexSon) in item.childTree" :key="indexSon">
          <div class="leftTitle"><router-link :to="{path:'/commodity-list',query:{categoryId:itemSon.categoryId,categoryCode:itemSon.categoryCode}}">{{ itemSon.categoryNm }}</router-link></div>
          <div class="rightCenter">
            <!--  -->
            <div class="itemSon" v-for="(itemSons,indexSons) in itemSon.childTree" :key="indexSons">
              <span class="itemSonLine" v-if="indexSons !== 0"></span>
              <router-link :to="{path:'/commodity-list', query: {categoryId:itemSons.categoryId,categoryCode:itemSons.categoryCode}}">
                <span style="margin: 0 0 0 10px">{{ itemSons.categoryNm }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 右侧边栏 -->
    <ToolBar />
  </div>
</template>

<script>
import Search from "@/components/Search/Search";
import ToolBar from "@/components/ToolBar/ToolBar";
import { scrollAnimation } from "@/utils";
import { mapGetters } from "vuex";
import {
  getDiscountRuleStart,
  pageDiscountProductRel,
  pageCouponList,
  saveCoupon,
  getPageModuleMap,
  categoryTree,
} from "@/api/index/index";
import brandDefaultImg from "@/assets/img/index/brand_default.png";
import productDefaultImg from "@/assets/img/index/product_default.png";
import { getToken } from "@/utils/auth";
import store from "../../store";

export default {
  name: "index",
  components: {
    Search,
    ToolBar,
  },
  data() {
    return {
      titleAll: [],
      params: {},
    };
  },
  mounted() {
    this.params = this.$route.meta;
    this.getallCategoryTree();
  },
  methods: {
    //获取全部分类
    async getallCategoryTree() {
      await categoryTree().then((res) => {
        this.titleAll = res.data[0].childTree;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  margin: 0 auto;
  background: rgba(245, 245, 245, 1);
  .breadcrumb {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    padding-left: 24px;
    width: 1200px;
    margin: 0 auto;

    /deep/ .el-breadcrumb {
      display: inline-block;
      line-height: 30px;
      margin: 16px 0 14px 0;
      float: left;
    }

    .buy {
      // float: right;

      img {
        margin-left: 5px;
        width: 16px;
        height: 16px;
        vertical-align: text-bottom;
      }
    }
  }
  .chinaWestern {
    margin: 14px 0 0 0;
    position: relative;
    border-bottom: 24px solid #F5F5F5;
    // background: rgba(230, 242, 255, 1);
    .childNext {
      width: 100%;
      display: inline-flex;
      clear: both;
      padding: 15px 0 0 55px;
      .leftTitle {
        width: 10%;
        line-height: 28px;
        a{
          float: left;
          font-size: 14px;
          @include TextHighlightColor;
          font-family: PingFangSC-Medium,PingFang SC,微软雅黑;
          font-weight: 500;
          line-height: 25px;
        }
      }
      .rightCenter {
        // width: 1040px;
        // height: 50px;
        width: 90%;
        font-size: 14px;
        font-weight: 500;
        margin-left: 40px;
        color: rgba(102, 102, 102, 1);
        line-height: 28px;
        .itemSon {
          font-size: 14px;
          display: inline;
          // margin: 20px;
          float: left;
          font-weight: 500;
          color: rgba(102, 102, 102, 1);
          line-height: 28px;
          .itemSonLine{
            position: relative;
            &:after{
              content: '';
              width: 1px;
              height: 12px;
              position: absolute;
              top: 50%;
              left: 0;
              margin-top: -6px;
              background: #666666;
            }
          }
          a {
            margin-right: 10px;
            cursor: pointer;
            &:hover{
              @include TextHighlightColor
            }
          }
        }
      }
    }
    .allChild {
      width: 1200px;
      // height: 529px;
      // margin: 0 0 20px 0;
      margin: 14px auto 0;
      background: rgba(255, 255, 255, 1);
      padding-bottom: 15px;
      .sizeCenter {
        // width: 28px;
        // height: 28px;
        height: 44px;
        background: #e6f2ff;
        .allCenter{
          display: inline-flex;
          float: left;
          clear: both;
        .leftText {
           width: 28px;
          height: 28px;
          border-radius: 2px;
          color: #ffffff;
          margin: 8px 0 8px 22px;
          background: $ThemeColor;
          line-height: 28px;
          font-size: 20px;
          font-weight: bold;
        }
        .rightText {
          height: 28px;
          font-size: 20px;
          margin: 6px 0 6px 8px;
          font-weight: 500;
          color: rgba(34, 34, 34, 1);
          line-height: 28px;
        }
      }
        }
    }
  }
}
</style>

<template>
  <div class="right-toolbar">
    <div class="toolbar-tabs">
      <div class="toolbar-bd">
        <div class="icon-extra bd-index">
          <a href="#">
            <i class="tab-ico"></i>
            <span class="tab-text">返回首页</span>
          </a>
        </div>
        <div class="icon-extra bd-order" @click="goPage('transactions-using/procurement-plan')">
          <a href="#">
            <i class="tab-ico"></i>
            <span class="tab-text">采购计划</span>
          </a>
        </div>
        <div class="icon-extra bd-detailed" @click="goPage('property-manage/my-coupon')">
          <a href="#">
            <i class="tab-ico"></i>
            <span class="tab-text">我的优惠券</span>
          </a>
        </div>
        <div class="icon-extra bd-heart" @click="goPage('transactions-using/good-collection')">
          <a href="#">
            <i class="tab-ico"></i>
            <span class="tab-text">我的收藏</span>
          </a>
        </div>
        <div class="icon-extra bd-time" @click="goPage('order-manage/history-purchase')">
          <a href="#">
            <i class="tab-ico"></i>
            <span class="tab-text">采购历史</span>
          </a>
        </div>

        <div class="icon-extra bd-phone">
          <a href="#">
            <i class="tab-ico"></i>
            <div class="software">
              <div class="software-code">
                <div class="code">
                  <img src="../../assets/img/index/code.png" alt="">
                </div>
                <div class="gift">
                  <p>扫一扫</p>
                  <p>关注微信公众号</p>
                </div>
              </div>
              <div class="software-logo">
                <img src="../../assets/img/index/logo-right.png" alt="">
              </div>
            </div>
          </a>
        </div>
        <router-link to="/shopping-cart">
          <div class="bd-cart">
            <a href="#">
              <div class="shop-cart">
                <p>购</p>
                <p>物</p>
                <p>车</p>
                <span class="shop-num" id="bottomCart">{{cartNum}}</span>
              </div>
            </a>
          </div>
        </router-link>
        <div @click="toolbarScroll()" class="icon-extra">
          <i class="el-icon-arrow-up"></i>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import { scrollAnimation } from '@/utils'
  import { mapGetters } from 'vuex'

  export default {
    name: 'ToolBar',
    data () {
      return {}
    },
    methods: {
      toolbarScroll () {
        const currentY = document.documentElement.scrollTop || document.body.scrollTop
        scrollAnimation(currentY, 0)
      },
      goPage (name) {
        this.$router.push({ path: '/member-center/' + name })
      }
    },
    computed: {
      ...mapGetters(['cartNum'])
    }
  }
</script>

<style lang="scss" scoped>

  .right-toolbar {
    position: fixed;
    width: 36px;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #333333;
    z-index: 20;

    .toolbar-bd {
      width: 36px;
      position: absolute;
      top: 25%;
      left: 0;
    }

    .tab-ico {
      background-color: #333333;
      background-repeat: no-repeat;
      background-position: center;
    }

    .bd-index .tab-ico {
      background-image: url("../../assets/img/icon/index.png")
    }

    .bd-order .tab-ico {
      background-image: url("../../assets/img/icon/order.png")
    }

    .bd-detailed .tab-ico {
      background-image: url("../../assets/img/icon/detailed.png")
    }

    .bd-heart .tab-ico {
      background-image: url("../../assets/img/icon/heart.png")
    }

    .bd-time .tab-ico {
      background-image: url("../../assets/img/icon/time.png")
    }

    .bd-phone {
      .tab-ico {
        background-image: url("../../assets/img/icon/phone.png")
      }

      .software {
        width: 143px;
        background: $ThemeColor;
        position: absolute;
        top: 0;
        right: -180px;
        z-index: 1;

        .software-code {
          height: 175px;
          padding-top: 22px;
          box-sizing: border-box;

          .code {
            width: 100px;
            height: 100px;
            margin: 0 auto;

            img {
              width: 100px;
              height: 100px;
            }
          }

          .gift {
            font-size: 12px;
            padding: 10px 0 20px;
            color: #fff;
            line-height: 1.3;

            span {
              color: #ffd830;
            }
          }

          .software-content p {
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
            padding-top: 3px;

            span {
              color: #FFCC00;
            }
          }
        }

        .software-logo {
          height: 58px;
          width: 143px;
          img{
            width: 100%;
          }
        }
      }

      &:hover .software {
        right: 36px;
      }
    }

    .bd-cart {
      width: 36px;
      height: 120px;
      margin-top: 16px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: $ThemeColor;
      }

      .shop-cart {
        p {
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          text-align: center;
        }

        .shop-num {
          font-size: 14px;
          font-weight: 500;
          color: rgba(34, 34, 34, 1);
          width: 20px;
          height: 20px;
          background: rgba(255, 255, 255, 1);
          border-radius: 14px;
          margin-top: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .icon-extra {
      width: 36px;
      height: 36px;
      margin-top: 16px;
      position: relative;

      &:hover {
        background: $ThemeColor;

        .tab-ico, .tab-text {
          background-color: $ThemeColor;
        }
      }

      a {
        display: block;
        width: 36px;
        height: 36px;
        position: relative;
      }

      .tab-text {
        position: absolute;
        left: 0;
        top: 0;
        -webkit-transition: left .3s ease-in-out .1s;
        transition: left .3s ease-in-out .1s;
        z-index: 1;
        width: 80px;
        height: 36px;
        background: #333;
        border-radius: 3px 0 0 3px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .tab-ico {
        display: block;
        width: 36px;
        height: 36px;
        background-color: #333333;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
      }

      .el-icon-arrow-up {
        color: #fff;
        font-weight: 600;
        font-size: 18px;
        position: relative;
        top: 6px;
        cursor: pointer;
      }
    }

    .icon-extra:hover .tab-text {
      left: -80px;
    }
  }
</style>

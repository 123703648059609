<template>
  <div class="history-purchase">
    <div class="search-header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline" size="small">
        <el-form-item label="商品名称">
          <el-input style="width: 163px;" v-model="searchForm.productNm" placeholder="输入商品名称"></el-input>
        </el-form-item>
        <el-form-item label="商品编码">
          <el-input style="width: 163px;" v-model="searchForm.productCode" placeholder="输入商品编码"></el-input>
        </el-form-item>
        <el-form-item label="下单时间">
          <el-select v-model="searchForm.orderTimeType" placeholder="请选择下单时间段">
            <el-option
                v-for="item in orderTimeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="onSubmit">搜索</el-button>
          <el-button type="text" size="mini" @click="onReset"><span style="color: #000000">重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="main">
      <!--table列表-->
      <im-table-page
          ref="tablePage"
          :data.sync="tableData"
          :remote-method="queryData"
          :addQuantity="true"
          @updated="updated"
          :more-check-box="(val)=>{selectItem = val}"
          header-row-class-name="header-row"
          style="width: 100%">
        <el-table-column type="selection" align="center" fixed="left" width="45"/>
        <el-table-column prop="name" fixed="left" label="商品名称" width="252px" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="table-product-name">
              <router-link :to="'/detail/' + scope.row.productId"><p>{{$util.getGoodsName(scope.row)}}</p></router-link>
              <tagList :taglist='scope.row.tagList'></tagList>
            </div>

          </template>
        </el-table-column>
        <el-table-column prop="productCode" align="left" label="商品编码" show-overflow-tooltip width="100px"/>
        <el-table-column prop="manufacturer" align="left" label="厂家(上市许可持有人)/产地" width="200px"/>
        <el-table-column prop="spec" align="left" label="规格" min-width="100px"/>
        <el-table-column prop="unit" align="left" label="单位" show-overflow-tooltip width="56px"/>
        <el-table-column align="left" label="中/件包装" width="100px">
          <template slot-scope="scope">
            <span>{{scope.row.midPackTotal}}/{{scope.row.packTotal}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="stockQuantity" align="left" label="库存" width="60px">
          <template slot-scope="scope">
            <span :class="scope.row.stockQuantity < 100 ? 'inventory-warning' : ''">{{scope.row.stockQuantity < 100 ? '<100':'>100'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="price" align="right" label="单价" width="70px"/>
        <el-table-column prop="minBuyMultiple" align="left" label="最小购买倍数" width="100px">
          <template slot-scope="scope">
            {{getRealMinBuyNum(scope.row)}}
          </template>
        </el-table-column>
        <el-table-column align="center" label="下单数量" width="120px">
          <template slot-scope="scope">
            <el-input-number size="mini"
                             v-model="scope.row.quantity"
                             step-strictly
                             :precision="getDecimalPointNum(scope.row)"
                             :step="getRealMinBuyNum(scope.row)"
                             :min="getRealMinBuyNum(scope.row)"/>
          </template>
        </el-table-column>
        <el-table-column align="right" fixed="right" label="小计" width="100">
          <template slot-scope="scope">
            <span style="color: #FF3B30">
                ¥{{ (scope.row.price * scope.row.quantity).toFixed(2) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" width="105" label="操作">
          <template slot-scope="scope">
            <div class="table-operation">
<!--              <el-button disabled size="mini" v-if="scope.row.stockQuantity === 0">已售馨</el-button>-->
              <el-button style="width: 100%"
                         size="mini"
                         type="info"
                         v-if="scope.row.stockQuantity === 0"
                         @click="handleRegisterGoods(scope.row,scope.row.quantity)">缺货登记</el-button>
              <el-button v-else size="mini"
                         :disabled="isBanAddToCart"
                         @click="beforeAddItem(scope.row.skuId, 'NORMAL', scope.row.quantity,scope.row)"
                         :loading='scope.row.loading'
                         style="padding: 4px 8px">加入购物车</el-button>
            </div>
          </template>
        </el-table-column>
      </im-table-page>

    </div>
    <!--加入购物车-->
    <div class="settlement-container">
      <div class="settlement-row">
        <div class="row-left">
          <el-checkbox v-model="AllChecked" @change="handleAllSelect">全选</el-checkbox>
        </div>
        <div class="row-right">
          <div class="settlement"></div>
          <div class="settlement-button-group">
            <el-button type="primary" @click="handleBuyMore" :disabled="isBanAddToCart">加入购物车</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import tagList from '@/components/tagList/tagList'
  import { getHistoryPurchase } from '@/api/member-center/order-manage'
  import { addItem, batchAddItem } from '@/api/detail'
  import {mapGetters} from 'vuex'
  import addToCartMixin from '@/common/addToCart'

  export default {
    name: 'HistoryPurchase',
    components:{
      tagList
    },
    mixins:[addToCartMixin],
    computed:{
      ...mapGetters(['license'])
    },
    data () {
      return {
        loadingCart:false,
        loading:false,
        searchForm: {
          productNm: '',
          productCode: '',
          orderTimeType: ''
        },
        orderTimeOptions: [
          {
            value: 'one',
            label: '近60日内订单'
          },
          {
            value: 'two',
            label: '近半年内订单'
          },
          {
            value: 'three',
            label: '近一年内订单'
          },
          {
            value: 'four',
            label: '全部订单'
          },
        ],
        selectItem: [],
        tableData: [],
        AllChecked: false
      }
    },
    watch: {
      selectItem (newVal) {
        this.AllChecked = newVal.length === this.tableData.length
      }
    },
    methods: {
      handleAllSelect () {
        this.$refs.tablePage.$refs.table.toggleAllSelection()
      },
      handleBuyMore(){
        if (this.selectItem.length === 0) {
          this.$message.error('请选择商品')
          return
        }
        let itemList = this.selectItem.map(res => {
          return {
            skuId: res.skuId,
            quantity: res.quantity
          }
        })
        batchAddItem({ itemList }).then(res => {
          const { code } = res
          if (code === '0') {
            this.$message.success('加入成功！')
            this.$store.dispatch('user/setCartNum', res.data.allProductTypeTotalQuantity)
          }
        }).catch(err=>{
        })
      },
      //加入购物车
      handleBuy (row) {
        row.loading = true
        if (row.stockQuantity < row.quantity) {
          row.loading = false
          this.$message.info('库存不足')
          return
        }
        addItem({ objId: row.skuId, itemType: 'NORMAL', quantity: row.quantity }).then(res => {
          const { code } = res
          if (code === '0') {
            row.loading = false
            this.$message.success('加入成功！')
            this.$store.dispatch('user/setCartNum', res.data.allProductTypeTotalQuantity)
          }
        }).catch(err=>{
            row.loading = false
        })
      },
      onSubmit () {
        this.doRefresh()
      },
      onReset () {
        this.searchForm = {
          productNm: '',
          productCode: '',
          orderTimeType: ''
        }
        this.onSubmit()
      },
      doRefresh () {
        this.$refs.tablePage.doRefresh({ currentPage: 0, pageSize: 10, condition: this.searchForm })
        // this.getStateNum()
      },
      queryData (data) {
        let listAll = {}
        this.tableData = [];
        return new Promise((then,reject)=>{
          getHistoryPurchase({
            ...data,
            condition: this.searchForm
          }).then(res=>{
            res.data.records.map(r=>{
              this.tableData.push({
                ...r,
                loading:false,
                quantity: this.getRealMinBuyNum(r)
              })
            })
            then(res)
          })
        })

      },
      updated () {
        this.$refs.tablePage.doLayout()
      }
    }
  }
</script>

<style scoped lang="scss">
  .history-purchase {
    height: 100%;
    background-color: #fff;
    position: relative;
    padding-bottom: 78px;
  }

  .search-header {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-bottom: 1px solid #E5E5E5;
    background: #ffffff;

    /deep/ .el-form-item {
      margin-bottom: 0;
      margin-right: 20px;

      .el-form-item__label {
        padding-right: 4px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(113, 113, 113, 1);
      }

      .el-input__inner {
        padding: 0 12px;
        background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        border: 1px solid rgba(204, 204, 204, 1);
      }
    }
  }

  .main {
    padding: 16px 20px;
    margin: 0 auto;
    width: 100%;
    height: auto;
    background: #ffffff;


  }

  /*结算*/
  .settlement-container {
    border: 1px solid rgba(229, 229, 229, 1);
    width: 100%;
    height: auto;
    background: #ffffff;
    padding-top: 16px;
    position: absolute;
    bottom: 0;
    .settlement-row {
      padding: 0 20px;
      margin: 0 auto;
      width: 100%;
      height: 60px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .row-left {
        font-size: 12px;
        font-weight: 500;
        color: rgba(102, 102, 102, 1);

        /deep/ .el-checkbox {
          margin-right: 23px;

          .el-checkbox__label {
            font-size: 12px;
            font-weight: 500;
            color: rgba(102, 102, 102, 1);
          }
        }

        span {
          cursor: pointer;
          margin-right: 20px;
        }
      }

      .row-right {
        display: flex;
        flex-direction: row;
        align-items: center;

        .settlement {
          margin-right: 12px;
          text-align: right;

          .total-money {
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            font-weight: 500;
            color: rgba(34, 34, 34, 1);

            span {
              font-size: 20px;
              color: rgba(224, 32, 52, 1);
            }
          }
        }

        .product-detail {
          height: 14px;
          font-size: 12px;
          font-weight: 500;
          color: rgba(102, 102, 102, 1);
          line-height: 14px;

          span {
            margin-left: 12px;
          }
        }

        .settlement-button-group {
          height: 100%;

          button {
            border: none;
            height: 36px;
            padding: 0 28px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
          }

          .immediate-settlement {
            color: #ffffff;
            background: #FF6600;
            font-size: 14px;
            cursor: pointer;

            &:hover {
              opacity: .8;
            }
          }
        }
      }
    }
  }

  /*表格*/
  /*解决列fixed后的办法处理*/

  /deep/
  .el-table--border {
    border: 1px solid #E5E5E5;
  }

  /deep/ .el-table__row {
    height: 60px;

    .cell {
      padding: 0 8px;
      font-size: 12px;
      font-weight: 500;
      color: rgba(38, 38, 38, 1);
    }

    .product-img {
      width: 50px;
      height: 50px;
    }

    /*重写计数器样式*/
    .el-input-number {
      width: 100px;
    }

    .el-input-number__decrease, .el-input-number__increase {
      width: 20px;
      height: 24px;
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 1px;
      border: 1px solid rgba(230, 230, 230, 1);
    }

    .el-input-number__decrease {
      left: 0;
    }

    .el-input-number__increase {
      right: 0;
    }

    .el-input__inner {
      padding: 0 19px;
      height: 24px;
      line-height: 24px;
    }

    /*商品名称*/
    .table-product-name {
      p {
        height: 20px;
        font-size: 14px;
        font-weight: 600;
        color: rgba(34, 34, 34, 1);
        line-height: 20px;
      }

      .table-row-tags {
        display: flex;
        flex-direction: row;

        .row-tag {
          height: 18px;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          border-radius: 3px;
          border-width: 1px;
          border-style: solid;
          margin-right: 4px;
          padding: 0 4px;
          box-sizing: border-box;
        }

        .tag-promotion {
          background: #FF6600;
          border: none;
          color: #ffffff;
        }

        .tag-cash {

          border-color: rgba(2, 92, 214, 0.5);
          color: #025CD6;
        }

        .tag-coupon {
          border-color: rgba(255, 163, 102, 0.5);
          color: #FF6600;
        }

        .tag-plus {
          border-color: rgba(51, 174, 103, 0.5);
          color: #33AE67;
        }
      }
    }

    /*库存*/
    .inventory-warning {
      font-weight: 600;
      color: rgba(255, 82, 0, 1);
    }

    /*操作栏*/
    .table-operation {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

  }

  /deep/ .header-row {
    height: 40px;
    background: #E5E5E5;

    th {
      .cell {
        padding: 0 8px;
        font-size: 12px;
        font-weight: 600;
        color: rgba(34, 34, 34, 1);
      }
    }
  }
</style>

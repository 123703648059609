import dayjs from 'dayjs';
console.log("track->index.js process.env:", process.env);

export const trackInit = () => {
  if (process.env.VUE_APP_TRACK_ENABLE == 'Y') {
    console.log("track->index.js process.env.VUE_APP_TRACK_ENABLE:", process.env.VUE_APP_TRACK_ENABLE);

    const script = document.createElement("script");
    script.src = "./autotrack.js"; // 脚本路径

    script.async = true; // 根据需要设置 async 或 defer
    document.head.appendChild(script);
  }
};


export function wrapTrack(originalTrack) {
	return (eventName, props = {}) => {
		// 自动添加 eventTime
		let currentTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')
		props.eventTime = currentTime
		// 调用原始的 track 方法
		try {
			// 调用原始的 track 方法
			if (typeof originalTrack === 'function') {
				originalTrack.call(this, eventName, props);
			} else {
				console.warn('originalTrack is not a function');
			}
		} catch (error) {
			// 捕获异常并打印警告，避免程序崩溃
			console.error('Error in original sensors track function:', error);
		}
	};
}
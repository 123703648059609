<template>
  <im-page-modal
      class="my-salesman-page"
      v-loading="loading"
      title="我的业务员"
      :isShowBack="false"
  >
    <el-col class='saleman-item'>
      <el-image style="width: 48px; height: 48px;margin-right:20px" :src="picUrl || imgDefault"/>
      <div style="height:100%;">
        <el-col class='con-text'><span>业务员名称：{{saleManList.salesmanName}}</span></el-col>
        <el-col class='con-text'><span>业务员手机：{{saleManList.salesmanContactTel}}</span></el-col>
      </div>
    </el-col>
  </im-page-modal>
</template>
<script>
  import { findByUserId } from '@/api/member-center/transactions-using'
  import imgDefault from '@/assets/img/member-center/portrait.png'

  export default {
    data () {
      return {
        loading: false,
        isShowBack: false,
        name: '',
        tel: '',
        picUrl: '',
        saleManList: {},
        imgDefault
      }
    },
    mounted () {
      this.$emit('handle-change-page', {
        text: '我的业务员',
        index: '5-1',
        url: '/member-center/transactions-using/my-salesman',
      })
      findByUserId().then(res => {
        this.saleManList = res.data
      })
    },
    methods: {
      updated () {
        this.$refs.tablePage.doLayout()
      },
      handleBuy () {

      }
    }
  }
</script>
<style lang="scss">
  .my-salesman-page {
    background-color: white;

    .header {
      padding: 0 28px;
    }

    .content {
      padding: 0 20px;
    }

    .saleman-item {
      margin-top: 16px;
      display: flex;
      align-items: center;
      height: 104px;
      border-radius: 4px;
      border: 1px solid rgba(229, 229, 229, 1);
      padding: 28px 20px;

      .con-text {
        text-align: left;
        height: 24px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(34, 34, 34, 1);
        line-height: 24px;
      }
    }
  }
</style>

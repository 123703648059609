var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.addShopVisible
    ? _c(
        "im-drawer",
        {
          attrs: {
            visible: _vm.addShopVisible,
            title: _vm.title,
            size: 850,
            modal: false,
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addShopVisible = $event
            },
            confirm: _vm.handleConfirm,
            cancel: _vm.closeConfirm,
          },
        },
        [
          _c(
            "div",
            { staticClass: "section-container" },
            [
              _c(
                "im-search-pad",
                {
                  staticClass: "search-content",
                  attrs: { model: _vm.searchForm, "has-expand": false },
                  on: { search: _vm.handleSearch, reset: _vm.handleReset },
                },
                [
                  _c(
                    "im-search-pad-item",
                    { attrs: { prop: "searchFiled", width: "210px" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入商家名称/编码/厂家" },
                        model: {
                          value: _vm.searchForm.searchFiled,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "searchFiled", $$v)
                          },
                          expression: "searchForm.searchFiled",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "im-table-page",
                {
                  ref: "tablePage",
                  attrs: {
                    data: _vm.tableData,
                    "remote-method": _vm.queryData,
                    index: true,
                    "more-check-box": (val) => {
                      _vm.selectItem = val
                    },
                  },
                  on: {
                    "update:data": function ($event) {
                      _vm.tableData = $event
                    },
                    updated: _vm.updated,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "50", fixed: "left" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品主图",
                      prop: "picUrl",
                      fixed: "left",
                      "min-width": "90",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "right",
                                    trigger: "hover",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "img-content",
                                      staticStyle: {
                                        "text-align": "center",
                                        padding: "5px 0",
                                      },
                                    },
                                    [
                                      _c("el-image", {
                                        staticStyle: {
                                          width: "104px",
                                          height: "104px",
                                        },
                                        attrs: {
                                          src:
                                            scope.row.picUrl ||
                                            _vm.productDefault,
                                          fit: "fill",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("img", {
                                    staticStyle: {
                                      width: "40px",
                                      height: "40px",
                                    },
                                    attrs: {
                                      slot: "reference",
                                      src:
                                        scope.row.thumbnailUrl ||
                                        _vm.productDefault,
                                      fit: "fill",
                                      alt: "",
                                    },
                                    on: {
                                      error: function ($event) {
                                        scope.row.thumbnailUrl =
                                          _vm.productDefault
                                      },
                                    },
                                    slot: "reference",
                                  }),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      49035071
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品编码",
                      prop: "productCode",
                      "min-width": "160px",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品名称",
                      prop: "productNm",
                      "min-width": "130px",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$util.getGoodsName(scope.row)) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3325910173
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "规格",
                      prop: "spec",
                      "min-width": "120px",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "单位",
                      prop: "unit",
                      width: "70px",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "厂家(上市许可持有人)/产地",
                      prop: "manufacturer",
                      "min-width": "160px",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "剂型",
                      prop: "dosageForm",
                      width: "80px",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "批准文号",
                      prop: "approvalNumber",
                      width: "190px",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
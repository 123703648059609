<template>
    <div class="main">
      <!-- 搜索、导航栏 -->
      <header class="header">
        <div class="header-content">
          <div class="logo">
            <router-link to="/">
              <img v-if="header.PC_TOP_LEFT_T1 && header.PC_TOP_LEFT_T1.length"
                   :src="header.PC_TOP_LEFT_T1[0].sysFileUrl" alt>
            </router-link>
          </div>
          <h4>到货提醒</h4>

        </div>
      </header>
      <ShoppingCartList></ShoppingCartList>

    </div>
</template>
<script>
  import ShoppingCartList from './components/list'
  import {mapGetters} from 'vuex'
  export default {
    name: 'arrivalReminder',
    components: {ShoppingCartList},
    computed: {
      ...mapGetters(['header'])
    },
    data() {
        return {

        }
    },
    watch: {

    },
    mounted(){
    },
    methods: {


    }
  }
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 100px;
  background: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 10px;

  .header-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    width: 1200px;
    height: 100%;

    .logo {
      margin-right: 20px;

      img {
        width: 230px;
        height: 80px;
      }
    }

    h4 {
      padding-left: 20px;
      flex: 1;
      text-align: left;
      font-size: 20px;
      font-weight: 500;
      line-height: 40px;
      height: 40px;
      border-left: 1px solid #CCCCCC;
      color: #222222;
    }
  }
}
</style>



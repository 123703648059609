var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "im-page-modal" }, [
    _vm.showHeader
      ? _c(
          "div",
          { staticClass: "header" },
          [
            _c("h3", [_vm._v(_vm._s(_vm.title))]),
            _vm.isShowBack
              ? _c(
                  "el-button",
                  { attrs: { size: "small" }, on: { click: _vm.goBack } },
                  [_vm._v(_vm._s(_vm.backTitle))]
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "content" }, [_vm._t("default")], 2),
    _vm.$scopedSlots.footer
      ? _c("div", { staticClass: "footer" }, [_vm._t("footer")], 2)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { getBus } from '@/utils/auth'
import {ApiDelete, ApiGet, ApiPost} from "@/api";

/**
 * @description 获取某个商品详情
 */
export const getCart = data => ApiGet('/pc/member/normalShoppingFlow/getCart',data)

/**
 * @description 加入购物车
 */
export const addItem = data => ApiPost('/pc/member/normalShoppingFlow/addItem',data)

/**
 * @description 修改购物车数量
 */
export const updateQuantity = data => ApiPost('/pc/member/normalShoppingFlow/updateQuantity',data)

/**
 * @description 单选
 */
export const updateSelected = data => ApiPost('/pc/member/normalShoppingFlow/updateSelected',data)

/**
 * @description 全选
 */
export const updateAllSelected = data => ApiGet('/pc/member/normalShoppingFlow/updateAllSelected',data)

/**
 * @description 删除购物车某个
 */
export const removeItem = data => ApiPost('/pc/member/normalShoppingFlow/removeItem',data)

/**
 * @description 取消收藏
 */
export const delByUserIdAndProductId = productId => ApiDelete('/pc/productCollect/delByUserIdAndProductId', {
  userId: getBus().buyersId,
  productId
})

/**
 * @description 清除失效购物车项
 */
export const clearInvalidItem = data => ApiGet('/pc/member/normalShoppingFlow/clearInvalidItem',data)

/**
 * @description 提交订单
 */
export const submitOrder = data => ApiGet('/pc/member/normalShoppingFlow/saveOrder',data)

/**
 * @description 修改收货地址
 */
export const updateReceiverAddr = buyersReceiverAddrId => ApiGet('/pc/member/normalShoppingFlow/updateReceiverAddr', {
  buyersReceiverAddrId
},{
  id: buyersReceiverAddrId
})

/**
 * @description 修改结算方式
 */
export const updatePaymentWay = data => ApiPost('/pc/member/normalShoppingFlow/updatePaymentWay',data)

/**
 * @description 获取结算页
 */
export const getSettlement = data => ApiGet('/pc/member/normalShoppingFlow/getSettlement',data)

/**
 * @description 支付请求接口
 */
export const goBank = data => ApiPost('/payment/goBank',data)

/**
 * @description 收银台-展示支付列表接口
 */
export const findEnablePaymentWay = data => ApiGet('/pc/member/paymentWay/findEnablePaymentWay',data)

/**
 * @description 修改优惠券
 */
export const updateCoupon = data => ApiGet('/pc/member/normalShoppingFlow/updateCoupon',data)

/**
 * @description 订单提交回显
 */
export const getOrderInf = data => ApiGet('/pc/order/getOrderInf',data)

/**
 * @description 后台回调
 */
export const receive = data => ApiGet('/payment/front/alipay/receive',data)

/**
 * @description 购物车数量
 */
export const getAllProductTypeTotalQuantity = data => ApiGet('/pc/member/normalShoppingFlow/getAllProductTypeTotalQuantity',data)

/**
 * @description 往来账支付
 */
export const currentBillsgoBank = data => ApiPost('/payment/currentBillsgoBank',data)

/**
 * @description 普通商品-缺省登记
 */
export const registerGood = data => ApiPost('/outStockRegistration/saveOutStockRegistration',data)

/**
 * @description 招行支付数据获取
 * @param data
 * @returns {*}
 * @constructor
 */
export const ICBCPay = data => ApiPost('/corePay/merchantBankQrCode',data)

/**
 * @description 飞速下单，批量缺货登记
 * @param data
 * @returns {*}
 */
export const saveBatchOutStockRegistration = data => ApiPost('/outStockRegistration/saveBatchOutStockRegistration',data)

/**
 * @description 积分抵扣
 * @param data
 * @returns {*}
 */
export const useIntegral = data => ApiPost('/pc/member/normalShoppingFlow/useIntegral?isUseIntegral=' + data)




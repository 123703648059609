import {ApiGet, ApiPost} from "@/api";

/**
 * @description 分页查询我的客户
 */
export const getUserIntegral = data => ApiGet('/pc/userIntegral/getUserIntegral',data)

/**
 * @description 分页查询我的客户
 */
export const pageUserIntegralLog = data => ApiPost('/pc/userIntegralOperationLog/pageUserIntegralLog', data)

<template>
  <div>
    <footer class="footer">
      <div class="icon-row">
        <div class="footer-icon" v-for="(item, index) in 5" @click="bindUrl(header['PC_BOTTOM_ADV_B'+ ( index + 2 )][0].link)">
          <h5 class="footer-icon-child" :style="{backgroundImage:(!header['PC_BOTTOM_ADV_B'+ ( index + 2 )] || !header['PC_BOTTOM_ADV_B'+ ( index + 2 )].length)?'none':'url(' + header['PC_BOTTOM_ADV_B'+ ( index + 2 )][0].sysFileUrl + ')'}"></h5>
          <div v-if="header['PC_BOTTOM_ADV_B'+ ( index + 2 )] && header['PC_BOTTOM_ADV_B'+ ( index + 2 )].length" class="footer-icon-text">
            <span class="footer-icon-name">{{ header['PC_BOTTOM_ADV_B'+ ( index + 2 )][0].title }}</span>
            <span class="footer-icon-little footer-icon-name">{{ header['PC_BOTTOM_ADV_B'+ ( index + 2 )][0].subtitleTitle }}</span>
          </div>
        </div>
      </div>
      <div class="service-intro">
        <div class="service-type">
          <div class="service-type-info" v-for="(guide, index) in footer" :key="index">
            <ul>
              <li>{{guide.categoryNm}}</li>
              <li v-for="(item, index) in guide.infArticleVos" :key="index" @click="jumpInfo(item.infArticleId, guide.categoryNm)">{{item.title}}</li>
            </ul>
          </div>
        </div>
        <div class="service-code">
          <div class="service-code-item" v-for="(item, index) in 2" v-if="header['PC_BOTTOM_ADV_B'+ ( index + 7 )] && header['PC_BOTTOM_ADV_B'+ ( index + 7 )].length">
            <img alt :src="header['PC_BOTTOM_ADV_B'+ ( index + 7 )][0].sysFileUrl" @click="bindUrl(header['PC_BOTTOM_ADV_B'+ ( index + 7 )][0].link)">
            <p>{{ header['PC_BOTTOM_ADV_B'+ ( index + 7 )][0].title }}</p>
          </div>
        </div>
      </div>
      <div class="footer-last" v-if="header['PC_BUTTOM_LINK'] && header['PC_BUTTOM_LINK'].length" v-html="header['PC_BUTTOM_LINK'][0].cont"></div>
    </footer>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { addInfArticleViews } from '@/api/index/index'

  export default {
    name: 'Footer',
    methods: {
      bindUrl (path) {
        if (path.substr(0, 1) === '/') {
          this.$router.push({ path })
        } else {
          window.open(path, '_blank')
        }
      },
      jumpInfo (id, name) {
        addInfArticleViews(id)
        this.$router.push({ path: `/info-detail/${id}`, query: { name } })
      }
    },
    computed: {
      ...mapGetters(['header', 'footer'])
    }
  }
</script>

<style lang="scss" scoped>
  .footer {
    height: 390px;
    margin: 0 auto;

    .icon-row {
      width: 1200px;
      margin: 25px auto;
      padding-top: 8px;
      height: 64px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      .footer-icon {
        margin: 0 17px;
        display: flex;
        width: calc(20% - 34px);

        .footer-icon-text {
          @include TextHighlightColor;
          font-size: 22px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
          text-align: left;
          padding-left: 7px;
          flex: 1;

          .footer-icon-name {
            height: 1em;
            overflow: hidden;
          }

          .footer-icon-little {
            font-size: 14px;
            margin-top: 4px;
            @include TextGrayColor
          }
        }

        .footer-icon-child {
          margin-top: 2px;
          width: 55px;
          height: 55px;
          text-indent: -999px;
        }
      }
    }

    .service-intro {
      width: 1200px;
      margin: 0 auto;
      border-top: 1px solid #EEEEEE;
      height: 240px;
      display: flex;

      .service-type {
        margin: 39px auto;
        height: 200px;
        display: flex;
        flex: 7;
        flex-direction: row;
        justify-content: space-between;

        .service-type-info {
          text-align: left;

          li {
            color: #666666;
            font-size: 12px;
            cursor: pointer;
            line-height: 26px;

            &:hover {
              @include TextHighlightColor;
            }
          }

          li:first-child {
            font-size: 17px;
            line-height: 35px;
            font-weight: bold;
            padding-bottom: 4px;
            color: #222222;
            cursor: default;

            &:hover {
              color: #222222;
            }
          }
        }
      }

      .service-code {
        flex: 4;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        .service-code-item {
          margin-left: 60px;
          img{
            width: 122px;
            height: 122px;
          }
        }
      }
    }

    .footer-last {
      background-color: #f5f5f5;
      height: 130px;
      color: #222;
      font-size: 12px;
    }
  }
</style>

import {ApiPost} from "@/api";
import {ApiGet} from "../index";

/**
 * @description 领券中心 分页 搜索优惠券列表
 */
export const pageCouponList = data => ApiPost('/pc/front/coupon/pageCouponList',data)

/**
 * @description 领券中心 领取优惠券
 */
export const saveCouponReceiveSituation = data => ApiPost('/pc/couponReceiveSituation/saveCouponReceiveSituation',data)

/**
 * @description 优惠套餐
 */
export const pageDiscountPackage = data => ApiPost('/pc/front/discountPackage/pageDiscountPackage',data)

/**
 * @description 满减赠
 */
export const pageFullGiftActivity = data => ApiGet('/fullReduceSend/pageFullGiftActivity',data)

/**
 * @description 获取特殊商品
 */
export const getFullGiftsList = (id, data) => ApiPost('/fullReduceSend/getFullGiftFrsPresentRel/'+id,data)

/**
 * @description 满减赠加入购物车
 */
export const saveFullGiftDetailAndAddToCart = data => ApiPost('/fullReduceSend/saveFullGiftDetailAndAddToCart',data)

/**
 * @description 获取主商品列表
 */
export const getPageActivityProduct = params => ApiGet('/fullReduceSend/pageActivityProduct/'+params.objId+'?pageNum='+params.pageNum+'&pageSize='+params.pageSize)
import {ApiGet, ApiPost} from "@/api";

/**
 * @description 获取企业基本信息
 */
export const getCorporateBaseInfo = data => ApiGet('/pc/personalCenter/getBuyersByUserId',data)

/**
 * @description 获取企业经营范围
 */
export const getCorporateBusinessRange = data => ApiGet('/pc/personalCenter/getBusinessRangeCategoryList',data)

/**
 * @description 获取企业收货地址
 */
export const getCorporateReceiverAddr = data => ApiGet('/pc/personalCenter/getBuyersReceiverAddrByUserId',data)

/**
 * @description 获取企业发票信息
 */
export const getCorporateInvoiceInfo = data => ApiGet('/pc/personalCenter/getInvoiceByUserId',data)

/**
 * @description 获取账户信息
 */
export const getAccountInfo = data => ApiGet('/pc/personalCenter/getUserEttleAccountRel',data)

/**
 * @description 获取账户信息
 */
export const getMyAptitudes = data => ApiGet('/pc/personalCenter/getLicenseListByUserId',data)

/**@description 修改手机号
 *
 */
export const changePhone = data => ApiPost('/pc/front/user/bindMobile',data)

/** @description 获取账户资料
 *
 */
export const getUserInfo = data => ApiGet('/pc/front/user/getAccountInfo',data)

/**
 * @description  保存采购商资质
 */
export const saveBuyersLicenseRel = data => ApiPost('/buyersLicenseRel/saveBuyersLicenseRel',data)
<template>
  <div class="main">
   <!-- 页面结构 -->
    <Search :hidden-nav="true"/>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/member-center' }">会员中心</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/member-center/order-manage/refunds' }">申请退货</el-breadcrumb-item>
        <el-breadcrumb-item>填写退货单</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button class="toback" @click="toback">返回</el-button>
    </div>
    <div class="content">
      <template>
        <div class="header center">
            <h3 style="padding:0 12px;font-weight:600">退货商品</h3>
        </div>
        <div style="padding:12px;background-color:white">
       <im-table-page
              ref="tablePage"
              :data.sync="tableData"
              :remote-method="queryData"
              :span-method="spanMethod"
               row-key="id"
              :defaultExpandAll="true"
              :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
              :row-class-name="renderRowClass">
        <el-table-column
                :key="Math.random()"
                v-if="activeName!=='APPLY_RETURN'"
                type="selection"
                width="40"
        >
        </el-table-column>
        <el-table-column label="商品信息" width="308px" show-overflow-tooltip>
          <template slot-scope="scope">
            <div  v-if="scope.row.orderNum">
              订单编号：<span class="order-row-text">{{scope.row.orderNum}}</span>
              下单时间：<span class="order-row-text">{{scope.row.createDate}}</span>
            </div>
            <div class="product-info" v-else>
              <div class="product-image">
                <el-image
                  :src="scope.row.picture || ProDuct"
                  @error="scope.row.picture = ProDuct"
                  fit="fit">
                </el-image>
              </div>
              <ul class="product-detail">
                <li class="proname">{{$util.getGoodsName(scope.row)}}</li>
                <li class="size-vender">
                  <p>规格：<span>{{ scope.row.spec}}</span></p>
                  <p class="">厂家：<span>{{ scope.row.manufacturer}}</span></p>
                </li>
              </ul>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="发货批号" prop="sendBatch" width="85px" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="退货价" prop="money" width="80px" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>¥{{ scope.row.settlePrice }}</span>
          </template>
        </el-table-column>
        <el-table-column label="出库/可退数量" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.quantity}} / {{ scope.row.returnableQuantity}}</span>
          </template>
        </el-table-column>
        <el-table-column
                label="退货数量"
                width="170"
                show-overflow-tooltip
        >
          <template slot-scope="scope">
              <el-input-number
                  v-model="scope.row.refundsnum"
                  size="mini"
                  :step="getRealMinBuyNum(scope.row)"
                  :min="0"
                  :precision="getDecimalPointNum(scope.row)"
                  :max="scope.row.returnableQuantity" />
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80" align="center">
          <template slot-scope="scope">
            <el-row class="table-edit-row">
              <span  class="table-edit-row-item">
                <el-button size="small" style="padding:2px 4px;" @click="handleDelete(scope.row)">删除</el-button>
              </span>
            </el-row>
          </template>
        </el-table-column>
        <!-- </el-table> -->
       </im-table-page>
      </div>
     </template>
      <!-- 基本信息 -->
      <template>
       <div class="header center">
            <h3 style="padding:0 12px;font-weight:600">退货信息</h3>
       </div>
        <el-row style="padding: 16px;">
          <el-col>
            <span style="display:inline-block;">
            <el-form
              ref="form"
              :model="form"
              :rules="formRules"
              label-width="142px"
            >
              <el-form-item label="退货原因：" prop="remark">
                <el-select
                  v-model="form.remark"
                  style="width:330px"
                  placeholder="请选择退货原因"
                  :disabled="false"
                >
                  <el-option v-for="item in reasonList" :key="item.value" :label="item.label" :value="item.value"/>
                </el-select>
              </el-form-item>
               <el-form-item label="退货说明：" prop="descr">
                <el-input
                  type="textarea"
                  v-model="form.descr"
                  style="width: 330px;"
                  placeholder="请描述退货原因"
                  :disabled="false"
                />
              </el-form-item>

              <el-form-item label="退货凭证：" required prop="volist" class="picture-upload">
                  <el-upload
                    action="#"
                    list-type="picture-card"
                    class="upLoad-picture"
                    :on-remove="(file) => handleRemove(file)"
                    :before-upload="beforeUpload"
                    :http-request="(file) => Upload(file)"
                    :limit="limitNum"
                    :class="hideUploadEdit ? hideTrue : hideFalse "
                    :on-change="handleEditChange"
                  >
                    <span slot="trigger" class="triggerImg">
                      <i class="el-icon-plus"></i><br>
                    </span>
                  </el-upload>
                <p>凭证要求：商品的外包装必须拍摄清晰完整</p>
                <p>最多可上传5张图片，图片大小不超过8M，支持jpeg\jpg\png格式</p>
              </el-form-item>
              <el-form-item label="退货地址：" prop="address">
                <p>{{form.receiverName}} - {{form.receiverMobile}} - {{form.province}} - {{form.city}} - {{form.district}} - {{form.receiverAddr}}</p>
              </el-form-item>
             </el-form>
            </span>
           </el-col>
         </el-row>
         </template>
     </div>
     <div class="footer-content">
          <el-button type="primary" :disabled="notCommit || isUploading" @click="handleCommit">{{isUploading ? '正在上传图片...' : '提交'}}</el-button>
     </div>
      <el-dialog
          title="提交成功"
          top="30vh"
          @close="closeModal"
          :visible.sync="visibleSuccess"
          :modal="false"
          width="15%"
        >
          <h3 class="tip-title"><i class="icon-success"></i>退货申请成功,请等待业务员审核! </h3>
          <p class='tip-detail'>
            <el-button class="tobackSuccess" @click="tobackSuccess" :disabled="returnBtn">返回退货退款 <span v-if="returnBtn">({{timeNum}}s)</span></el-button>
          </p>
      </el-dialog>
      <el-dialog
          title="提交失败"
          top="30vh"
          :visible.sync="visibleError"
          :modal="false"
          width="15%"
        >
          <h3 class="tip-title"><i class="icon-error"></i>退货申请失败,请联系业务员</h3>
          <p class='tip-detail'>
             <el-button  class="tobackError" @click="tobackError">关闭</el-button>
          </p>
      </el-dialog>
  </div>
</template>

<script>
  import Search from '@/components/Search/Search'
  import ProDuct from '@/assets/img/member-center/product.png'
  import formRules from '@/utils/validate'
  import {batchUploadFile, saveReturnedOrder} from '@/api/member-center/refunds'
  import refunds from "@/views/member-center/order-manage/refunds/mixins/refunds";
  import addToCartMixin from '@/common/addToCart'

  export default {
    name: 'refunds-replay-list',
    components: {
      Search,
    },
    data () {
      return {
        ProDuct: ProDuct,
        visibleSuccess:false,
        visibleError: false,
        activeName:'APPLY_RETURN',
        tableData: [],
        form:{
         fileIdS: '',
         descr:'',
         remark:'',
         fileIdList:[],
         receiverName: '',
         receiverMobile: '',
         province: '',
         city: '',
         district: '',
         receiverAddr: ''
        },
        formRules:{
           remark: [formRules.blurRes('退货原因')],
           fileIdList: [formRules.blurRes('退货凭证')]
        },
        hideUpload: [],
        timeNum: 5,
        returnBtn: true,
        notCommit:true,
        hideUploadEdit: false,
        limitNum: 5,
        hideFalse: 'hideFalse',
        hideTrue: 'hideTrue'
      }
    },
    mounted() {

    },
    mixins:[refunds,addToCartMixin],
    methods: {
      handleEditChange(file, fileList) {
        if(fileList.length >= this.limitNum){
          this.hideUploadEdit = true
        }
      },
      closeModal(){
          this.tobackSuccess()
      },
      tobackSuccess() {
        this.$router.push({path:'/member-center/order-manage/refunds', query: {activeName: 'PROCESSING_RETURN1'}})
      },
      tobackError() {
        this.visibleError = false
      },
      toback() {
        this.$router.push({path:'/member-center/order-manage/refunds'})
      },
      handleRemove(file) {
        this.fileList = this.fileList.filter(item => item.uid !== file.uid)
        if(this.fileList.length < this.limitNum){
          this.hideUploadEdit = false
        }
      },
      spanMethod(rowObj) {
        const {row, column, rowIndex, columnIndex} = rowObj;
        if (row.orderNum) {
          if (this.activeName === 'APPLY_RETURN') {
            if (columnIndex === 0) {
              return [1, 6]
            }
          } else {
            return [1, 8]
          }
        }
      },
      renderRowClass(row) {
        if (row.row.orderNum) {
          return 'order-row'
        } else {
          return ''
        }
      },
      queryData(){
        this.tableData = []
        let refundOrder = this.$route.query.refundOrder
        // refundOrderItemList
        if(refundOrder.children.length !== 0){
          this.notCommit = false
          refundOrder.children.map(item => {
            item.refundsnum = this.getRealMinBuyNum(item)
          })
        }
        if(this.$route.query.onePro){
          this.tableData.push(refundOrder)
        }else {
          refundOrder.children = []
          refundOrder.children = refundOrder.orderItemList
          this.tableData.push(refundOrder)
        }

        this.form.receiverName = this.tableData[0].receiverName
        this.form.receiverMobile = this.tableData[0].receiverMobile
        this.form.receiverAddr = this.tableData[0].receiverAddr
        this.form.province = this.tableData[0].province
        this.form.city = this.tableData[0].city
        this.form.district = this.tableData[0].district
        return Promise.resolve({
          code: 0,
          msg: '',
          data: {
            records: this.tableData,
            total: this.tableData.length
          }
        })
      },
      handleDelete(data){
        this.tableData[0].children = this.tableData[0].children.filter(item => item.orderItemId !== data.orderItemId)
      },
      //提交
      handleCommit(){
        let pronum = true
        if(this.fileList.length === 0){
           this.$alert('您还未选择退货凭证',  {
            confirmButtonText: '确定',
           });
        }else if(this.form.remark === ""){
          this.$alert('您还未填写退货原因',  {
            confirmButtonText: '确定',
          });
        }else {
          if( this.tableData[0].children.length === 0){
            this.$alert('此订单已经没有商品，无法进行退货',  {
              confirmButtonText: '确定',
            });
          }else {
            this.tableData[0].children.map(item => {
              if(!item.refundsnum){
               pronum = false
              }
            })
            if(!pronum){
              this.$alert('存在商品没有填写退货数量',  {
                confirmButtonText: '确定',
              })
            }else {
              this.fileList.map(item => {
                this.form.fileIdList.push(item.fileId)
              })
              this.form.fileIdS = this.form.fileIdList.join(',')
              let order = this.tableData[0]
              let orderInfro = {...order, ...this.form}
              let allMoney = 0
              orderInfro.children.map(item => {
                allMoney = allMoney + parseFloat(item.settlePrice).toFixed(2) * 100 * item.refundsnum
                item.unitPrice = item.settlePrice
                item.batchNum = item.sendBatch
                item.totalAmount = parseFloat((parseFloat(item.settlePrice).toFixed(2) * 100 * item.refundsnum)/100).toFixed(2)
                item.quantity = item.refundsnum
              })
              orderInfro.returnedOrderAmount = parseFloat(allMoney/100) .toFixed(2)
              orderInfro.returnedOrderNum = orderInfro.orderNum
              orderInfro.returnedPurchaseOrderItemSaveVoList = orderInfro.children

              saveReturnedOrder(orderInfro).then(res => {
                if(res.code === '0'){
                  this.notCommit = true
                  this.visibleSuccess = true
                  this.timeNum = 5
                  const clertime = setInterval(() => {
                  this.timeNum--
                  if (this.timeNum <= 0) {
                    clearInterval(clertime)
                    this.returnBtn = false
                  }
                }, 1000)
                }else {
                  this.visibleError = true
                }
              })
            }
          }

        }
      }

    }
  }
</script>

<style scoped lang="scss">
/* // 隐藏上传按钮 */
 .hideTrue {
    /deep/ .el-upload--picture-card{
      display: none;
    }
  }

  .hideFalse {
    /deep/ .el-upload--picture-card{
      display: inline-block;
    }
  }

  .center {
    display: flex;
    align-items: center;
  }

  .main {
    background-color: #f5f5f5;
    padding-bottom: 20px;
    .content {
      width: 1200px;
      margin: 0 auto;
      padding-bottom: 20px;
      background: #fff;

      .header {
        font-size: 14px;
        line-height: 40px;
        color: #262626;
        background: #F1F7FE;
        width: 100%;
        text-align: left;

        b {
          height: 18px;
          padding: 0 4px;
          color: $ThemeColor;
          border: 1px solid $ThemeColor;
          font-size: 12px;
          border-radius: 3px;
          margin: 0 10px 0 20px;
        }
      }

      .express-steps {
        max-height: 240px;
        overflow-y: auto;
      }

      .product-info {
        display: flex;
        flex-direction: row;

        .product-image {
          width: 70px;
          height: 70px;

          .el-image {
            width: 100%;
            height: 100%;
          }
        }

        .product-detail {
          padding-left: 16px;
          width: 212px;

          li {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;
          }

          .proname {
            height: 18px;
            font-size: 14px;
            font-weight: 500;
            color: rgba(34, 34, 34, 1);
            line-height: 18px;
            margin-bottom: 4px;
          }

          .detail {
            font-weight: 400;
            color: rgba(113, 113, 113, 1);
            line-height: 18px;
            height: 18px;

            .spec {
              margin-right: 10px;
            }

            &.manufacturer {
              white-space: normal;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              height: 36px;
            }
          }
        }
      }

        /deep/ .el-table {
        .order-row {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
          td{
            background: #F1F7FE;
          }
          .order-row-text {
            margin-right: 10px;
          }

          .el-button {
            padding: 5px 8px;

            span {
              margin: 0;
            }
          }
        }


        .cell {
          width: 100% !important;

          .el-table__expand-icon {
            display: none;
          }

          .el-table__indent, .el-table__placeholder {
            display: none;
          }
        }
      }



    }
    /deep/ .picture-upload{
          text-align:left;
         .el-upload--picture-card{
         width:80px;
         height:80px;
         line-height: 78px;
       }
       .el-upload--picture-card i {
         font-size:24px
       }
     }
   /deep/ .el-form{
       .el-form-item__content{
            text-align:left;
        }
   }
    .breadcrumb {
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      padding-left: 24px;
      width: 1200px;
      margin: 0 auto;
      position: relative;

      /deep/ .el-breadcrumb {
        display: inline-block;
        line-height: 50px;
        float: left;
      }
    }
  .footer-content{
    border-top:1px solid #ccc;
    width:1200px;
    margin:0 auto;
    height:80px;
    display:flex;
    justify-content: center;
    align-items: center;
    background:rgba(255,255,255,1);
    box-shadow:0px 1px 0px 0px rgba(229,229,229,1);
    /deep/.el-button{
      width:200px;
      height:40px;
      border-radius:2px
    }
  }
 }

     //弹窗
    /deep/.el-dialog{
       min-height:180px;
       min-width:400px;
      .el-dialog__header{
        text-align:left;
        height:36px;
        line-height:36px;
        background:rgba(245,245,245,1);
        border-radius:2px;
        padding:0 20px;
      }
      .el-dialog__title{
        font-size:14px;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(34,34,34,1);
      }
      .el-dialog__body{
        padding-top:20px;
      }
      .el-dialog__headerbtn{
         top:10px;
         right:16px;
      }
      .el-dialog__footer{
       background:rgba(245,245,245,1);
       position:absolute;
       width:100%;
       bottom:0;left:0;
       padding:0;
       height:52px;
       display:flex;
       align-items: center;
       justify-content: center;
      }
      .icon-success{
        margin-right: 15px;
        font-size: 20px;
        display: inline-block;
        width: 47px;
        height: 48px;
        background:url('../../../../../assets/img/member-center/upload-success.png') no-repeat;
        background-size: 100%;
      }
      .icon-error{
        margin-right: 15px;
        font-size: 20px;
        display: inline-block;
        width: 47px;
        height: 48px;
        background:url('../../../../../assets/img/member-center/upload-error.png') no-repeat;
        background-size: 100%;
      }
      .tip-title{
        padding-left: 30px;
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Medium,PingFang SC;
        color: #222222;
      }
      .tip-detail{
        margin-top:12px;
        line-height:20px;
        text-align:left;
        font-size:14px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(0,0,0,0.65);
        line-height:22px;
      }
   }

   .toback {
     width:48px;
     height:28px;
     background:rgba(255,255,255,1);
     font-size:12px;
     font-family:PingFangSC-Regular,PingFang SC;
     font-weight:400;
     line-height:22px;
     position: absolute;
     padding: 0;
     margin: auto;
     top: 12px;
     right: 21px;
   }

   .tobackSuccess {
     padding: 10px;
     margin: 0 auto;
     display: block;
     width: 140px;
   }

   .tobackError {
     padding: 10px;
     margin: 0 auto;
     display: block;
     background-color: #F1F2F4;
     width: 100px;
   }
</style>

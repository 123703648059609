console.log("track->index.js process.env:", process.env);

export const trackInit = () => {
  if (process.env.VUE_APP_TRACK_ENABLE == 'Y') {
    console.log("track->index.js process.env.VUE_APP_TRACK_ENABLE:", process.env.VUE_APP_TRACK_ENABLE);

    const script = document.createElement("script");
    script.src = "./autotrack.js"; // 脚本路径

    script.async = true; // 根据需要设置 async 或 defer
    document.head.appendChild(script);
  }
};

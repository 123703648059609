const getters = {
  buyersVo: state => state.user.buyersVo,
  isAuthInquiryBox: state => state.user.isAuthInquiryBox,
  org: state => state.user.org,
  license: state => state.user.license,
  userDetail: state => state.user.userDetail,
  token: state => state.user.token,
  userType: state => state.user.userType,
  allBusiness: state => state.user.allBusiness,
  accountId: state => state.user.accountId,
  refreshModule: state => state.user.refreshModule,
  header: state => state.user.header,
  footer: state => state.user.footer,
  consignedDelivery: state => state.user.consignedDelivery,
  cartNum: state => state.user.cartNum,
  productCategory: state => state.user.productCategory,
  userMobile:state=>state.user.userDetail.userMobile,
  showIndex:state=>state.user.showIndex
};

export default getters

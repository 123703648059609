import { getBus } from '@/utils/auth'
import {ApiDelete, ApiGet, ApiPost} from "@/api";

/**
 * @description 公共-消息数量
 */
export const ArrivalReminderCount = data => ApiPost('/pc/arrivalreminder/arrivalReminderCount',data)

/**
 * @description 根据客户id返回提醒信息
 */
export const ArrivalReminder = data => ApiPost('/pc/arrivalreminder/arrivalReminder',data)

/**
 * @description 修改已读状态
 */
export const ArrivalReminderOnce = data => ApiPost('/pc/arrivalreminder/arrivalReminderOnce',data)
<template>
  <div class="successDiv">
    <div class="header">
      <div class="registerHeader">
        <img class="bgImg" src="@/assets/img/register/bg.png" alt="">
        <div class="division"></div>
        <span class="title">下单成功</span>
      </div>
    </div>
    <div class="orderDiv">
      <div class="successTip">
        <div style="display: flex">
          <i class="el-icon-success successIcon"></i>
          您的订单已提交，请耐心等待！
          <!--          <span class="orderMoneyTitle">-->
          <!--            订单总金额：-->
          <!--            <span class="orderMoney">-->
          <!--              ¥{{ orderTotalAmount }}-->
          <!--            </span>-->
          <!--          </span>-->
        </div>
        <el-button @click="back" size="mini" class="backBut">返回订单</el-button>
      </div>
      <div class="orderInfo">
        <div class="title">订单信息</div>
        <!--        <div class="orderNum">-->
        <!--          <span class="orderNumSpan">订单编号：14015458255489452</span>-->
        <!--          <a style="color:#0971EB">查看订单详情</a>-->
        <!--        </div>-->
        <!--        <div class="orderNum">-->
        <!--          <span class="orderNumSpan">交易金额：186,548.00元</span>-->
        <!--          <span>购买时间：2019年4月12日 17:21:12</span>-->
        <!--        </div>-->
        <div class="tableDiv">
          <el-table
            :data="tableData"
            border
            class="table"
            style="width: 100%">
            <el-table-column label="序号" align="center" type="index" width="60" show-overflow-tooltip/>
            <el-table-column label="订单编号" prop="orderId" width="200" show-overflow-tooltip/>
            <el-table-column label="交易金额" prop="orderTotalAmount" align="right" width="100" show-overflow-tooltip>
              <template slot-scope="scope">
                {{$util.toFixed(scope.row.orderTotalAmount)}}
              </template>
            </el-table-column>
            <el-table-column label="客户名称" prop="buyersNm" width="200" show-overflow-tooltip/>
            <el-table-column label="门店编码" prop="storeCode" width="112" show-overflow-tooltip/>
            <el-table-column label="联系电话" prop="receiverMobile" width="112" show-overflow-tooltip/>
            <el-table-column label="收货地址" prop="receiverAddr" min-width="200" show-overflow-tooltip/>
            <el-table-column label="购买时间" prop="saveDate" width="155" show-overflow-tooltip/>
            <el-table-column fixed="right" width="64" label="操作">
              <template slot-scope="scope">
                <el-button style="padding: 4px 6px" size="mini" @click="toOrderDetail(scope.row.orderId)">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="orderInfo">
        <div class="title">缺货提醒
          <span class="titleTip">以下商品存在缺货，请到 <a style="color: #0971EB" @click="orderAgain">委托配送缺货下单</a> 中查</span>
        </div>
        <div class="tableDiv">
          <el-table
            :data="outOfStockData"
            border
            class="table"
            style="width: 100%">
            <el-table-column label="序号" type="index" width="60" show-overflow-tooltip></el-table-column>
            <el-table-column label="门店名称" prop="storeName" width="120" show-overflow-tooltip></el-table-column>
            <el-table-column label="商品编码" prop="erpCode" width="120" show-overflow-tooltip></el-table-column>
            <el-table-column label="商品名称" prop="drugNm" width="150" show-overflow-tooltip></el-table-column>
            <el-table-column label="规格" prop="spec" width="120" show-overflow-tooltip></el-table-column>
            <el-table-column label="平台价格" prop="price" width="120" show-overflow-tooltip>
              <template slot-scope="scope">
                {{$util.toFixed(scope.row.price)}}
              </template>
            </el-table-column>
            <el-table-column label="缺货数量" prop="quantity" width="100" show-overflow-tooltip></el-table-column>
            <el-table-column label="金额" prop="totalPrice" width="100" align="right" show-overflow-tooltip>
              <template slot-scope="scope">
                {{$util.toFixed(scope.row.totalPrice)}}
              </template>
            </el-table-column>
            <el-table-column label="缺货描述" prop="descInfo" min-width="100" show-overflow-tooltip></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "success",
    computed: {
      ...mapGetters(['consignedDelivery'])
    },
    props: {
      outOfStock: {
        type: String,
        default: () => {
          return ''
        }
      }
    },
    data () {
      return {
        tableData: [],
        outOfStockData: [],
        orderTotalAmount: 0
      }
    },
    mounted () {
      this.tableData = this.consignedDelivery.orderSaveSuccessList || []
      this.outOfStockData = this.consignedDelivery.shortageList || []
    },
    methods: {
      toOrderDetail (orderId) {
        this.$router.push({ path: '/order-detail/' + orderId })
      },
      orderAgain () {
        this.$router.push(`/member-center/consigned-delivery/out-of-stock-order/index`)
      },
      removeSemicolon (num) {
        let x = num.split(',');
        return parseFloat(x.join(""));
      },
      back () {
        this.$router.go(-1)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .successDiv {
    background-color: #f5f5f5;
    text-align: left;
    min-height: 780px;
    border-radius: 8px 8px 0px 0px;

    .header {
      background-color: #ffffff;
      height: 100px;
      width: 100%;
      text-align: center;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);

      .registerHeader {
        width: 1200px;
        margin: 0 auto;
        text-align: left;
        display: flex;

        .bgImg {
          width: 230px;
          height: 80px;
          margin-top: 10px;
        }

        .division {
          width: 1px;
          height: 40px;
          background: rgba(204, 204, 204, 1);
          margin: 30px 20px 0 20px;
        }

        .title {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(34, 34, 34, 1);
          line-height: 100px;
        }
      }
    }

    .orderDiv {
      width: 1200px;
      text-align: center;
      background-color: rgb(255, 255, 255);
      margin: 20px auto;
      padding: 0px 20px 20px 20px;
      border-radius: 8px 8px 0px 0px;

      .successTip {
        display: flex;
        text-align: left;
        font-size: 24px;
        padding-top: 32px;
        line-height: 32px;
        font-weight: 500;
        color: rgba(34, 34, 34, 1);
        justify-content: space-between;

        .successIcon {
          color: #09AD25;
          font-size: 32px;
          margin-right: 5px
        }

        .orderMoneyTitle {
          margin-left: 16px;
          font-size: 16px;
          font-weight: 500;
          color: rgba(34, 34, 34, 1);
          line-height: 32px;

          .orderMoney {
            font-size: 20px;
            font-weight: 500;
            color: rgba(255, 59, 48, 1);
            line-height: 32px;
          }
        }

        .backBut {
          width: 104px;
          height: 32px;
          background: rgba(255, 255, 255, 1);
          border-radius: 4px;
        }
      }

      .orderInfo {
        margin-top: 20px;
        width: 100%;
        border: 1px solid #E5E5E5;
        padding-bottom: 20px;

        .title {
          width: 100%;
          height: 40px;
          background-color: rgba(9, 113, 235, 0.06);
          line-height: 40px;
          font-weight: 800;
          color: #222222;
          text-align: left;
          padding-left: 20px;
          margin-bottom: 16px;
          font-size: 14px;

          .titleTip {
            color: #FF3B30;
            margin-left: 16px;
            font-weight: 400;
          }
        }

        .orderNum {
          display: flex;
          font-size: 14px;
          color: rgba(34, 34, 34, 1);
          margin-left: 16px;
          height: 32px;
          line-height: 32px;
        }

        .orderNumSpan {
          text-align: left;
          width: 230px;
          line-height: 32px;
        }
      }

      .tableDiv {
        padding: 0 16px;
      }
    }
  }
</style>

import {getInfo, getProcurementPlanTx, login, logout, phoneVerif, phoneVerifCode} from '@/api/user'
import { getPageModuleMap, getSubArticleCategoryByCode, categoryTree} from '@/api/index/index'
import { changAccount, changeBusiness, getBusiness } from '@/api/org'
import { getAllProductTypeTotalQuantity } from '@/api/shop-cart'
import {Notification} from "element-ui";
import {
  getToken,
  removeToken,
  setToken,
  getBus,
  setBus,
  setAccount,
  removeAccount,
  getUserType,
  setUserType,
  removeUserType,
  removeBus
} from '@/utils/auth'
import { resetRouter } from '@/router'
import router from '@/router'
import store from '../index'
import {getOrderNumDetail} from "@/api/user";
import {getTime} from "@/utils";
const $notification = Notification
const showPageModule = [
  ['PC_TOP_LEFT_T1', 'PC_TOP_RIGHT_T1', 'PC_TOP_RIGHT_T2', 'PC_NAVIGATION_LINK',
    'PC_BOTTOM_ADV_B2', 'PC_BOTTOM_ADV_B3', 'PC_BOTTOM_ADV_B4', 'PC_BOTTOM_ADV_B5', 'PC_BOTTOM_ADV_B6',
    'PC_BOTTOM_ADV_B7', 'PC_BOTTOM_ADV_B8', 'PC_BUTTOM_LINK', 'PC_TOP_SEARCH_LINK', 'PC_BANNER', 'PC_BANNER_F1',
    'PC_BANNER_ADV_1', 'PC_BANNER_F2', 'PC_INFORMATION_TOP_LINK', 'PC_BANNER_RIGHT', 'PC_BANNER_LEFT'],
  ['PC_SHOP_LINK_TOP_F1','PC_SHOP_ADV_F1', 'PC_SHOP_LINK_TOP_F2','PC_SHOP_ADV_F2','PC_SHOP_PRODUCT_F2'],
  ['PC_SHOP_LINK_TOP_F3','PC_SHOP_ADV_F3','PC_SHOP_LINK_TOP_F4','PC_SHOP_ADV_F4','PC_SHOP_ADV_F5'],
  ['PC_SHOP_LINK_F6_1', 'PC_SHOP_LINK_F6_2', 'PC_SHOP_ADV_F6_3', 'PC_SHOP_ADV_F6_4', 'PC_SHOP_PRODUCT_F6', 'PC_BRAND_F1',],
  ['PC_SHOP_LINK_F7_1', 'PC_SHOP_LINK_F7_2', 'PC_SHOP_ADV_F7_3', 'PC_SHOP_ADV_F7_4', 'PC_SHOP_PRODUCT_F7', 'PC_BRAND_F2',],
  ['PC_SHOP_LINK_F8_1', 'PC_SHOP_LINK_F8_2', 'PC_SHOP_ADV_F8_3', 'PC_SHOP_ADV_F8_4', 'PC_SHOP_PRODUCT_F8', 'PC_BRAND_F3',],
  ['PC_SHOP_LINK_F9_1', 'PC_SHOP_LINK_F9_2', 'PC_SHOP_ADV_F9_3', 'PC_SHOP_ADV_F9_4', 'PC_SHOP_PRODUCT_F9', 'PC_BRAND_F4',],
  ['PC_SHOP_LINK_F10_1', 'PC_SHOP_LINK_F10_2', 'PC_SHOP_ADV_F10_3', 'PC_SHOP_ADV_F10_4', 'PC_SHOP_PRODUCT_F10', 'PC_BRAND_F5',],
  ['PC_SHOP_ADV_F11', 'PC_SHOP_LINK_TOP_F5', 'PC_BOTTOM_LINK_1', 'PC_BOTTOM_LINK_2', 'PC_BOTTOM_LINK_3', 'PC_BOTTOM_LINK_B1',
    'PC_INFORMATION', 'PC_BOTTOM_INFORMATION_B1', 'PC_BOTTOM_INFORMATION_B2', 'PC_BOTTOM_INFORMATION_B3']
]

const state = {
  token: getToken(),
  org: getBus(),
  buyersVo: {},
  isAuthInquiryBox: '',
  userType: '', //用户类型
  accountId: {}, //当前机构机构
  avatar: '',
  cartNum: 0,
  refreshModule: '',
  license: { failureSum: 0 }, //资质
  header: {},
  footer: [],
  allBusiness: [], //获取所有经营单元
  roles: [],
  isChangeVersion: false,
  orgSalesmanVo: null,
  consignedDelivery: {},
  userDetail: {},
  productCategory: {},  //商品分类
  showIndex: 0,
  remindArr:[], //采购计划提醒的定时器
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_BUS: (state, org) => {
    state.org = org
  },
  CHANGE_VERSION(state,isChangeVersion){
      state.isChangeVersion = isChangeVersion
  },
  SET_ACCOUNT_ID: (state, accountId) => {
    state.accountId = accountId
  },
  SET_BUYER: (state, buyersVo) => {
    state.buyersVo = buyersVo
  },
  SET_ISAUTHINQUIRYBOX: (state, isAuthInquiryBox) => {
    state.isAuthInquiryBox = isAuthInquiryBox
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ALL_BUS: (state, business) => {
    state.allBusiness = business
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_ORG_SALESMAN_VO: (state, orgSalesmanVo) => {
    state.orgSalesmanVo = orgSalesmanVo
  },
  SET_REFRESH_MODULE: (state, str) => {
    state.refreshModule = str
  },
  SET_HEADER_DATA: (state, header) => {
    state.header = header
  },
  SET_SHOW_INDEX: (state, showIndex) => {
    state.showIndex = showIndex
  },
  SET_FOOTER_DATA: (state, footer) => {
    state.footer = footer
  },
  SET_CONSIGNED_DELIVERY: (state, consignedDelivery) => {
    state.consignedDelivery = consignedDelivery
  },
  SET_CART_NUM: (state, cartNum) => {
    state.cartNum = cartNum
  },
  SET_LICENSE: (state, license) => {
    state.license = license
  },
  SET_PRODUCT_CATEGORY:(state, productCategory)  => {
    state.productCategory = productCategory
  },
  SET_USER_TYPE:(state, userType)  => {
    state.userType = userType
  },
  SET_USER_DETAIL:(state, userDetail)  => {
    state.userDetail = userDetail
  },
  SET_REMIND_ARR(state,remindArr){
    state.remindArr = remindArr
  },
  //清除采购计划提醒倒计时资源
  CLEAR_REMIND_ARR(state){
    state.remindArr.map(item=>{
      clearInterval(item)
    })
    state.remindArr = []
  }
}

const actions = {
  // user login
  login ({ commit }, loginInfo) {
    console.log('用户登录 actions->loginInfo:', loginInfo)
    return new Promise((resolve, reject) => {
      login(loginInfo.params, loginInfo.data).then(response => {
        if (response.code === '0') {
          resolve(response.data)
        } else {
          reject(response)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

  //采购计划提醒
  getBuyPlanRemind({commit,state}){
    getProcurementPlanTx().then(res=>{
      let data = res.data || []
      data.map( item =>{
        let endTime = getTime(item.date,'timeStamp-10')
        let currentTime = getTime(new Date(),'timeStamp-10')
        if(Math.abs(endTime - currentTime) <= 3){
          $notification.warning({
            title: '采购计划提醒',
            duration: 0,
            dangerouslyUseHTMLString: true,
            message: `<div><strong>采购计划名：${item.name}</strong></div><div>内容:<span>${item.content}</span></div>`
          })
        }else {
          let timer = setInterval(()=>{
            let currentTime = getTime(new Date(),'timeStamp-10')
            if(Math.abs(endTime - currentTime) <= 3){
              $notification.warning({
                title: '采购计划提醒',
                duration: 0,
                dangerouslyUseHTMLString: true,
                message: `<div><strong>采购计划名：${item.name}</strong></div><div>内容:<span>${item.content}</span></div>`
              })
              clearInterval(timer)
              let arr = store.state.user.remindArr.filter(item => item !== timer)
              commit('SET_REMIND_ARR',arr)
            }
          },1000)

          commit('SET_REMIND_ARR',[
            ...store.state.user.remindArr,
            timer
          ])
        }
      })
    })
  },

  getInfo ({ commit, state }) {
    commit('CLEAR_REMIND_ARR')
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
        const { data } = response
        if (response.code === '0') {

          console.log('user.js->getInfo response:', response)
          sensors.logout()
          sensors.login(response.data.loginId)

          if(!!data.frontOrgVoList){
            // let list = data.frontOrgVoList.filter(e => data.memberDetailsVo.memberType != 'BUYERS' || ( e.merchantCode != "3" && e.merchantCode != "4") );
            // list = list.length ? list : data.frontOrgVoList.filter(e => e.merchantCode == "3");
            // list = list.length ? list : data.frontOrgVoList.filter(e => e.merchantCode == "4");
            // commit('SET_ALL_BUS', list)
            //   let list = data.frontOrgVoList.filter(item=> item.orgId === data.defaultOrg.orgId)
              let list = data.frontOrgVoList
              // list = [...list,{
              //   buyersId:123123,
              //   merchantCode:'2',
              //   orgId:'123213213323213213',
              //   orgNm:'123'
              // }]
              commit('SET_ALL_BUS', list)

          }
          commit('SET_BUS', data.defaultOrg)
          let buyersVo = data.buyersVo || { buyersNm: data.name }
          console.log('isAuthInquiryBox', data)
          if (data.orgSalesmanVo) {
            buyersVo.salesmanName = data.orgSalesmanVo.salesmanName
            buyersVo.salesmanContactTel = data.orgSalesmanVo.salesmanContactTel
            buyersVo.userMobile = data.userMobile
            commit('SET_ORG_SALESMAN_VO',data.orgSalesmanVo)
          }
          commit('SET_BUYER', buyersVo)
          commit('SET_ISAUTHINQUIRYBOX', data.isAuthInquiryBox)
          commit('SET_USER_TYPE', data.memberDetailsVo.memberType)
          setUserType(data.memberDetailsVo.memberType)
          let title
          switch (data.memberDetailsVo.memberType) {
            case 'BUYERS':
              title = '采购员'
              break
            case 'SALESMAN':
              title = '业务员'
              break
            case 'SUPPLIER':
              title = '供应商'
              break
            case 'MANUFACTURER_SALESMAN':
              title = '厂家业务员'
              break
            case 'BUSINESS_UNIT':
              title = '经营单元'
              break
            case 'ADMIN':
              title = '平台'
              break
            case 'REGISTER_BUYERS':
              title = '注册用户'
              break
            default:
              title = '未知'
          }
          data.memberDetailsVo.title = title
          commit('SET_USER_DETAIL', {
            memberDetailsVo: data.memberDetailsVo,
            orderStateVos: {},
            userMobile : data.userMobile,
            loginId : data.loginId,
            isOverdue: data.isOverdue,
            selectEttleAccountNm : data.selectEttleAccountNm
          })

          sessionStorage.setItem('loginId',data.loginId)
          sessionStorage.setItem('userMobile',data.userMobile)

          getOrderNumDetail().then(res=>{
              if(res.code === '0'){
                commit('SET_USER_DETAIL', {
                  memberDetailsVo: data.memberDetailsVo,
                  orderStateVos: res.data,
                  userMobile : data.userMobile,
                  loginId : data.loginId,
                  isOverdue: data.isOverdue,
                  selectEttleAccountNm : data.selectEttleAccountNm
                })
              }
          })

          commit('SET_CART_NUM', data.allProductTypeTotalQuantity || 0)
          commit('SET_LICENSE', {
            failureSum: data.failureSum,
            fileUrl: data.fileUrl,
            isOverdue: data.isOverdue, //超账期
            isAllLicenseExpired: data.isAllLicenseExpired, //全部资质过期
            isFailure: data.isFailure, //有资质过期
            isRealFailure: data.isRealFailure, //全部无法下单
            isDefault: data.isDefault,
            isNoRange: 'N', //是否有资质没有经营范围
            list:'', // 资质列表
            isAboutFailure:data.isAboutFailure, //是否即将过期
            proxyExpired:data.proxyExpired, //委托书是否过期
          })
          setBus(data.defaultOrg)
          resolve(data)
        } else {
          reject()
        }
      }).then(_ => {
        commit('SET_SHOW_INDEX', 0)
        store.dispatch('user/getHeaderData')
      }).then(_ => {
        if (state.buyersVo.buyersId) {
          getAllProductTypeTotalQuantity().then(res => {
            const { data } = res
            commit('SET_CART_NUM', data || 0)
          })
          store.dispatch('user/getBuyPlanRemind')
        }
      })
    })
  },

  getBusiness ({ commit }) {
    return new Promise((resolve, reject) => {
      getBusiness().then(response => {
        if (response.code === '0') {
          const { data } = response
          commit('SET_ALL_BUS', data)
          resolve(data)
        }else {
          reject()
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

  changeBusiness ({ commit, state }, item) {
    return new Promise((resolve, reject) => {
      changeBusiness(item.merchantCode).then(response => {
        if (response.code === '0') {
          commit('SET_BUS', item)
          commit('SET_SHOW_INDEX', 0)
          store.dispatch('user/getHeaderData')
          setBus(item)
          resolve()
        } else {
          reject()
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

  changAccount ({ commit }, item) {
    return new Promise((resolve, reject) => {
      changAccount(item).then(response => {
        if (response.code === '0') {
          commit('SET_ACCOUNT_ID', item)
          store.dispatch('user/getInfo').then(res=>{
            setAccount(item)
            resolve()
          }).catch(err=>{
            reject()
          })
        } else {
          reject()
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

  getHeaderData ({ commit , state }, headerArr) {
    return new Promise((resolve, reject) => {
      if(state.refreshModule === 'logout'){
        return
      }
      if(!!Promise.allSettled){
        let h = JSON.parse(JSON.stringify(state.header));

        getPageModuleMap({ innerCodes: showPageModule[0] }).then(response => {
          const { data } = response
          // console.log(data)
          if (response.code === '0') {
            for (const k in data) {
              h[k] = data[k];
            }
            commit('SET_HEADER_DATA', h)
            store.dispatch('user/getSubArticleCategoryByCode')
            store.dispatch('user/getProductCategory')

            let api = []
            showPageModule.forEach((item,index)=>{
              if(index !== 0){
                api.push(getPageModuleMap({innerCodes: showPageModule[index] }))
              }
            })
            commit('SET_SHOW_INDEX', 1)
            Promise.allSettled(api).then(res=>{
              let h1 = JSON.parse(JSON.stringify(state.header));
              res.forEach(item=>{
                if(item.status === 'fulfilled'){
                  let {code,data} = item.value
                  if(code === '0'){
                    for(const key in data){
                      h1[key] = data[key]
                    }
                  }
                }
              })
              commit('SET_HEADER_DATA', h1)
              commit('SET_SHOW_INDEX', 0)
              resolve()
            }).catch(e=>{
              reject()
            })
          } else {
            reject()
          }
        })

        return
      }

      getPageModuleMap({ innerCodes: showPageModule[state.showIndex] }).then(response => {
        const { data } = response
        // console.log(data)
        if (response.code === '0') {
          let h = JSON.parse(JSON.stringify(state.header));
          for (const k in data)
            h[k] = data[k];
          commit('SET_HEADER_DATA', h)
          resolve()
        } else {
          reject()
        }
      }).then(res => {
        if (!state.showIndex) store.dispatch('user/getSubArticleCategoryByCode') //底部资讯
      }).then(res => {
        if (!state.showIndex) store.dispatch('user/getProductCategory') //商品类目
      }).then(res => {
        commit('SET_SHOW_INDEX', state.showIndex + 1)
        if (state.showIndex < showPageModule.length) store.dispatch('user/getHeaderData')
        else commit('SET_SHOW_INDEX', 0)
      })
    })
  },

  //商品类目
  getProductCategory({ commit}) {
    return new Promise((resolve, reject) => {
      categoryTree().then(response => {
        if (response.code === '0') {
          const { data } = response
          data[0].childTree = data[0].childTree.sort((a,b)=>{
              return a.sort - b.sort
          })
          commit('SET_PRODUCT_CATEGORY', data[0])
          resolve(data)
        }else {
          reject()
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

  //底部资讯
  getSubArticleCategoryByCode({ commit}) {
    return new Promise((resolve, reject) => {
      getSubArticleCategoryByCode().then(response => {
        const { data } = response
        if (response.code === '0') {
          commit('SET_FOOTER_DATA', data)
          resolve()
        } else {
          reject()
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

  setRefreshModule ({ commit, dispatch }, refreshModule) {
    commit('SET_REFRESH_MODULE', refreshModule)
  },

  setConsignedDelivery ({ commit, dispatch }, consignedDelivery) {
    commit('SET_CONSIGNED_DELIVERY', consignedDelivery)
  },

  setCartNum ({ commit, dispatch }, cartNum) {
    commit('SET_CART_NUM', cartNum)
  },

  phoneVerif ({ commit }, verif) {
    return new Promise((resolve, reject) => {
      phoneVerif(verif.data).then(response => {
        if (response.code === '0') {
          resolve()
        } else {
          reject()
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

  phoneVerifCode ({ commit, state }, verif) {
    return new Promise((resolve, reject) => {
      phoneVerifCode(verif.data, state.allBusiness[0].merchantCode).then(response => {
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // 前端 登出
  logout ({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('CLEAR_REMIND_ARR')
      commit('SET_TOKEN', '')
      commit('SET_ALL_BUS', '')
      commit('SET_BUYER', '')
      commit('SET_ISAUTHINQUIRYBOX', '')
      commit('SET_ACCOUNT_ID', '')
      commit('SET_REFRESH_MODULE', 'logout')
      commit('SET_CART_NUM', 0)
      commit('SET_USER_TYPE', '')
      removeUserType()
      removeAccount()
      removeToken()
      resetRouter()
      removeBus()
      resolve()
    })
  },

  toSetToken ({ commit }, token) {
    return new Promise(resolve => {
      commit('SET_TOKEN', token)
      setToken(token)
      resolve()
    })
  },
  // remove token
  resetToken ({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },
  changeVersion({commit},isChangeVersion){
    commit('CHANGE_VERSION',isChangeVersion)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "header-box" }, [
    _c("div", { staticClass: "nav" }, [
      _c(
        "ul",
        { staticClass: "detail" },
        [
          _vm.allBusiness.length
            ? _c(
                "li",
                { staticClass: "first" },
                [
                  _vm.allBusiness.length > 1
                    ? _c(
                        "el-dropdown",
                        [
                          _c("span", { staticClass: "el-dropdown-link" }, [
                            _c("img", {
                              staticClass: "icon",
                              attrs: {
                                src: require("../../assets/img/icon/icon-yiyuan@2x.png"),
                                alt: "",
                              },
                            }),
                            _vm.org
                              ? _c("span", [
                                  _vm._v(" " + _vm._s(_vm.org.orgNm)),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.allBusiness[_vm.businessIndex].orgNm
                                      )
                                  ),
                                ]),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right",
                            }),
                          ]),
                          _c(
                            "el-dropdown-menu",
                            {
                              staticStyle: { "padding-right": "15px" },
                              attrs: { slot: "dropdown" },
                              slot: "dropdown",
                            },
                            _vm._l(_vm.allBusiness, function (item, index) {
                              return _c(
                                "el-dropdown-item",
                                {
                                  key: index,
                                  staticClass: "noSelect",
                                  class: {
                                    "checkBol-header":
                                      _vm.businessIndex === index ||
                                      item.orgNm === _vm.org.orgNm,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.changOrg(item, index)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      class:
                                        item.orgNm === _vm.org.orgNm
                                          ? "itemName"
                                          : "",
                                    },
                                    [_vm._v(_vm._s(item.orgNm))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _c("span", { staticClass: "business" }, [
                        _c("img", {
                          staticClass: "icon",
                          attrs: {
                            src: require("../../assets/img/icon/icon-yiyuan@2x.png"),
                            alt: "",
                          },
                        }),
                        _vm._v(" " + _vm._s(_vm.allBusiness[0].orgNm)),
                      ]),
                ],
                1
              )
            : _vm._e(),
          !_vm.buyersVo.buyersNm
            ? _c(
                "li",
                { staticClass: "first" },
                [
                  _c(
                    "span",
                    { staticClass: "text-color-red" },
                    [
                      _c(
                        "router-link",
                        {
                          staticStyle: { "margin-left": "15px" },
                          attrs: { to: "/login" },
                        },
                        [
                          _vm._v("请登录 "),
                          _c("i", { attrs: { type: "person" } }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("router-link", { attrs: { to: "/register" } }, [
                    _vm._v("免费注册 "),
                    _c("i", { attrs: { type: "person-add" } }),
                  ]),
                ],
                1
              )
            : _c("li", { staticClass: "first" }, [
                _vm.userType === "SALESMAN"
                  ? _c(
                      "span",
                      { staticClass: "welcome-text" },
                      [
                        _vm._v(" 您好，"),
                        _c(
                          "router-link",
                          {
                            staticStyle: { padding: "0 10px 0 0" },
                            attrs: { to: "/member-center" },
                          },
                          [_c("b", [_vm._v(_vm._s(_vm.buyersVo.salesmanName))])]
                        ),
                        _c("div", { staticStyle: { display: "inline-flex" } }, [
                          _c(
                            "em",
                            {
                              attrs: {
                                title: `${_vm.accountId.buyersNm}${
                                  _vm.accountId.ettleAccountNm == "默认分支机构"
                                    ? ""
                                    : "-" + _vm.accountId.ettleAccountNm
                                }`,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.accountId.buyersNm) +
                                  _vm._s(
                                    !_vm.accountId.ettleAccountNm ||
                                      _vm.accountId.ettleAccountNm ==
                                        "默认分支机构"
                                      ? ""
                                      : `-${_vm.accountId.ettleAccountNm}`
                                  )
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm.userType === "BUYERS_TEAM"
                  ? _c(
                      "span",
                      { staticClass: "welcome-text" },
                      [
                        _c(
                          "router-link",
                          {
                            staticStyle: { padding: "0 10px 0 0" },
                            attrs: { to: "/member-center" },
                          },
                          [
                            _c("b", [
                              _vm._v(_vm._s(_vm.buyersVo.buyersTeamName)),
                            ]),
                          ]
                        ),
                        _c("div", { staticStyle: { display: "inline-flex" } }, [
                          _c(
                            "em",
                            {
                              attrs: {
                                title: `${_vm.accountId.buyersNm}${
                                  _vm.accountId.ettleAccountNm == "默认分支机构"
                                    ? ""
                                    : "-" + _vm.accountId.ettleAccountNm
                                }`,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.accountId.buyersNm) +
                                  _vm._s(
                                    !_vm.accountId.ettleAccountNm ||
                                      _vm.accountId.ettleAccountNm ==
                                        "默认分支机构"
                                      ? ""
                                      : `-${_vm.accountId.ettleAccountNm}`
                                  )
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    )
                  : _c(
                      "span",
                      { staticClass: "welcome-text" },
                      [
                        _vm._v("您好，"),
                        _c(
                          "router-link",
                          { attrs: { to: "/member-center/home" } },
                          [
                            _c(
                              "b",
                              {
                                attrs: {
                                  title:
                                    _vm.buyersVo.buyersNm +
                                    "-" +
                                    _vm.accountId.ettleAccountNm,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.buyersVo.buyersNm) +
                                    _vm._s(
                                      _vm.accountId.ettleAccountNm ==
                                        "默认分支机构"
                                        ? ""
                                        : `-${
                                            _vm.accountId.ettleAccountNm
                                              ? _vm.accountId.ettleAccountNm
                                              : ""
                                          }`
                                    )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                _vm.userType !== "BUYERS" || _vm.account.length !== 1
                  ? _c(
                      "span",
                      {
                        staticClass: "welcome-text cur",
                        on: {
                          click: function ($event) {
                            _vm.dialogTableVisible = !_vm.dialogTableVisible
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.userType === "SALESMAN" ||
                                _vm.userType === "BUYERS_TEAM"
                                ? "切换客户"
                                : "切换分支机构"
                            ) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "span",
                  {
                    staticClass: "welcome-text cur",
                    on: { click: _vm.logout },
                  },
                  [_vm._v("退出")]
                ),
              ]),
          _c(
            "el-dialog",
            {
              staticClass: "dialog-table",
              attrs: {
                title:
                  _vm.userType === "SALESMAN" || _vm.userType === "BUYERS_TEAM"
                    ? "选择客户"
                    : "切换分支机构",
                width: "1100px",
                visible: _vm.dialogTableVisible,
                "close-on-click-modal": false,
                "show-close":
                  _vm.userType === "SALESMAN" ||
                  _vm.userType === "BUYERS_TEAM" ||
                  _vm.accountId.ettleAccountNm != null,
                "close-on-press-escape":
                  _vm.userType === "SALESMAN" ||
                  _vm.userType === "BUYERS_TEAM" ||
                  _vm.accountId.ettleAccountNm != null,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogTableVisible = $event
                },
              },
            },
            [
              _vm.userType === "SALESMAN" || _vm.userType === "BUYERS_TEAM"
                ? _c("h4", [_vm._v("请选择以下你需服务的客户")])
                : _c("h4", [_vm._v("请选择您要采购的分支机构")]),
              _c(
                "el-form",
                {
                  staticClass: "demo-form-header",
                  attrs: { inline: true, model: _vm.searchForm, size: "small" },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "orderNum" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "470px" },
                        attrs: {
                          placeholder:
                            _vm.userType === "SALESMAN" ||
                            _vm.userType === "BUYERS_TEAM"
                              ? "请输入客户名称/客户ERP编码/分支机构名称/分支机构编码"
                              : "请输入分支机构名称/分支机构编码/联系手机",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.handleSearch.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.searchForm.searchField,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "searchField", $$v)
                          },
                          expression: "searchForm.searchField",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.handleSearch },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading",
                    },
                  ],
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { data: _vm.account, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      label: "序号",
                      width: "55",
                      fixed: "left",
                      align: "center",
                    },
                  }),
                  _vm.userType === "SALESMAN" || _vm.userType === "BUYERS_TEAM"
                    ? _c("el-table-column", {
                        attrs: {
                          width: "210",
                          property: "erpCode",
                          label: "客户ERP编码",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "flex-start",
                                        "align-items": "center",
                                      },
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(scope.row.erpCode)),
                                      ]),
                                      scope.row.isOverdue === "Y"
                                        ? _c("div", { staticClass: "erpTab" }, [
                                            _vm._v("账期逾期"),
                                          ])
                                        : _vm._e(),
                                      scope.row.status === "Y"
                                        ? _c("div", { staticClass: "erpTab" }, [
                                            _vm._v("证照过期"),
                                          ])
                                        : _vm._e(),
                                      scope.row.proxyExpired === "Y"
                                        ? _c("div", { staticClass: "erpTab" }, [
                                            _vm._v("委托书过期"),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2671188186
                        ),
                      })
                    : _vm._e(),
                  _vm.userType === "SALESMAN" || _vm.userType === "BUYERS_TEAM"
                    ? _c("el-table-column", {
                        attrs: {
                          property: "buyersNm",
                          label: "客户名称",
                          "show-overflow-tooltip": "",
                        },
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      property: "code",
                      label: "分支机构编码",
                      "min-width": "80px",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_vm._v(" " + _vm._s(scope.row.code) + " ")]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "ettleAccountNm",
                      label: "分支机构",
                      "min-width": "100px",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.ettleAccountNm) + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.userType === "BUYERS"
                    ? _c("el-table-column", {
                        attrs: {
                          property: "userName",
                          label: "采购员",
                          width: "120",
                        },
                      })
                    : _vm._e(),
                  _vm.userType === _vm.BUYERS
                    ? _c("el-table-column", {
                        attrs: { prop: "district", label: "所在地区" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.province
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.province)),
                                      ])
                                    : _vm._e(),
                                  scope.row.city
                                    ? _c("span", [
                                        _vm._v(_vm._s("-" + scope.row.city)),
                                      ])
                                    : _vm._e(),
                                  scope.row.district
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s("-" + scope.row.district)
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1083788793
                        ),
                      })
                    : _vm._e(),
                  _vm.userType === "SALESMAN" || _vm.userType === "BUYERS_TEAM"
                    ? _c("el-table-column", {
                        attrs: {
                          property: "customerTypeName",
                          label: "客户类型",
                          width: "160",
                          "show-overflow-tooltip": "",
                        },
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: { width: "64", label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.userType === "SALESMAN" ||
                            _vm.userType === "BUYERS_TEAM"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleChangeAccount(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("选择 ")]
                                )
                              : scope.row.ettleAccountNm != "默认分支机构"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changAccount(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("选择 ")]
                                )
                              : _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changAccount(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("选择 ")]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm.userType === "SALESMAN" || _vm.userType === "BUYERS_TEAM"
                ? _c("im-pagination", {
                    attrs: {
                      "hide-on-single-page": false,
                      isShowMainInfo: true,
                      total: _vm.total,
                      "current-page": _vm.currentPage,
                      "page-size": _vm.pageSize,
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        _vm.currentPage = $event
                      },
                      "update:current-page": function ($event) {
                        _vm.currentPage = $event
                      },
                      "update:pageSize": function ($event) {
                        _vm.pageSize = $event
                      },
                      "update:page-size": function ($event) {
                        _vm.pageSize = $event
                      },
                      pagination: _vm.handlePagination,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("license-expired-modal", {
            attrs: { visible: _vm.dialogLicenseVisible },
            on: {
              "update:visible": function ($event) {
                _vm.dialogLicenseVisible = $event
              },
            },
          }),
          _c("myNotifyMessage", {
            ref: "myNotifyMessageRef",
            attrs: { visible: _vm.dialogMyNotifyVisible },
            on: {
              "update:visible": function ($event) {
                _vm.dialogMyNotifyVisible = $event
              },
              success: _vm.getUnreadCount,
            },
          }),
        ],
        1
      ),
      _c("ul", { staticClass: "location" }, [
        _c(
          "li",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$route.path !== "/",
                expression: "$route.path !== '/'",
              },
            ],
            staticClass: "top",
          },
          [
            _c(
              "div",
              [
                _c(
                  "router-link",
                  { staticStyle: { color: "#FA6400" }, attrs: { to: "/" } },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/icon/icon-shangchenshouye.png"),
                        alt: "",
                      },
                    }),
                    _vm._v(" 返回商城首页"),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
        _c("li", { staticClass: "top" }, [
          _c("div", [
            _c("a", { on: { click: _vm.goShop } }, [
              _c("img", {
                staticStyle: { width: "16px", height: "16px" },
                attrs: {
                  src: require("@/assets/img/icon/shoppingCart.png"),
                  alt: "",
                },
              }),
              _vm._v(" 购物车(" + _vm._s(_vm.cartNum) + ") "),
            ]),
          ]),
        ]),
        _c(
          "li",
          [
            _c(
              "router-link",
              { attrs: { to: "/member-center/order-manage/store-order" } },
              [_vm._v("我的订单")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "el-dropdown",
              [
                _c("span", { staticClass: "el-dropdown-link" }, [
                  _vm._v(" 会员中心"),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
                ]),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "el-dropdown-item",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to:
                                _vm.userType === "SALESMAN" ||
                                _vm.userType === "BUYERS_TEAM"
                                  ? "/member-center"
                                  : "/member-center/home",
                            },
                          },
                          [_vm._v("个人中心")]
                        ),
                      ],
                      1
                    ),
                    _vm.userType
                      ? _c(
                          "el-dropdown-item",
                          {
                            nativeOn: {
                              click: function ($event) {
                                return _vm.logout.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("登出")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("li", [
          _c(
            "span",
            { staticClass: "message-tip", on: { click: _vm.handleMessage } },
            [
              _c(
                "el-badge",
                {
                  staticClass: "item",
                  attrs: {
                    value: _vm.unreadCount,
                    hidden: !_vm.unreadCount,
                    max: 99,
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-message-solid",
                    staticStyle: { color: "#1cb7cd" },
                  }),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
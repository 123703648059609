<template>
  <div class="header-box">
    <div class="nav">
      <ul class="detail">
        <li class="first" v-if="allBusiness.length">
          <el-dropdown v-if="allBusiness.length > 1">
            <span class="el-dropdown-link">
              <img class="icon" src="../../assets/img/icon/icon-yiyuan@2x.png" alt>
              <span v-if="org"> {{ org.orgNm }}</span>
              <span v-else> {{ allBusiness[businessIndex].orgNm }}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" style="padding-right: 15px;">
              <el-dropdown-item @click.native="changOrg(item, index)"
                                v-for="(item, index) in allBusiness"
                                :key="index" class="noSelect"
                                :class="{'checkBol-header': businessIndex === index || item.orgNm === org.orgNm }">
                <span :class="item.orgNm === org.orgNm ? 'itemName' : ''">{{ item.orgNm }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span v-else class="business"><img class="icon" src="../../assets/img/icon/icon-yiyuan@2x.png" alt> {{ allBusiness[0].orgNm }}</span>
        </li>
        <li class="first" v-if="!buyersVo.buyersNm">
          <span class="text-color-red">
            <router-link to="/login" style="margin-left: 15px">请登录 <i type="person"></i></router-link>
          </span>
          <router-link to="/register">免费注册 <i type="person-add"></i></router-link>
        </li>
        <li class="first" v-else>
          <span v-if="userType ==='SALESMAN'" class="welcome-text">
            您好，<router-link style="padding: 0 10px 0 0"
                            to="/member-center"><b>{{ buyersVo.salesmanName }}</b></router-link>
            <div style="display: inline-flex">
              <em :title="`${accountId.buyersNm}${accountId.ettleAccountNm== '默认分支机构'?'':'-'+accountId.ettleAccountNm}`">{{ accountId.buyersNm }}{{ !accountId.ettleAccountNm || accountId.ettleAccountNm == '默认分支机构' ? '' : `-${accountId.ettleAccountNm}` }}</em>
            </div>
          </span>
          <span v-else-if="userType ==='BUYERS_TEAM'" class="welcome-text">
            <router-link style="padding: 0 10px 0 0"
                            to="/member-center"><b>{{ buyersVo.buyersTeamName }}</b></router-link>
            <div style="display: inline-flex">
              <em :title="`${accountId.buyersNm}${accountId.ettleAccountNm== '默认分支机构'?'':'-'+accountId.ettleAccountNm}`">{{ accountId.buyersNm }}{{ !accountId.ettleAccountNm || accountId.ettleAccountNm == '默认分支机构' ? '' : `-${accountId.ettleAccountNm}` }}</em>
            </div>
          </span>
          <span v-else class="welcome-text">您好，<router-link to="/member-center/home">
            <b :title="buyersVo.buyersNm+'-'+accountId.ettleAccountNm">{{ buyersVo.buyersNm }}{{ accountId.ettleAccountNm == '默认分支机构' ? '' : `-${accountId.ettleAccountNm ? accountId.ettleAccountNm : ''}` }}</b>
            </router-link>
          </span>
          <span
              class="welcome-text cur"
              v-if="userType !== 'BUYERS' || account.length !== 1"
              @click="dialogTableVisible = !dialogTableVisible"
          >
            {{ userType === 'SALESMAN' || userType === 'BUYERS_TEAM'? '切换客户' : '切换分支机构' }}
          </span>
          <span class="welcome-text cur" @click="logout">退出</span>
        </li>
        <el-dialog :title="userType === 'SALESMAN' || userType === 'BUYERS_TEAM' ? '选择客户' : '切换分支机构'"
                   class="dialog-table"
                   width="1100px"
                   :visible.sync="dialogTableVisible"
                   :close-on-click-modal="false"
                   :show-close="userType ==='SALESMAN' || userType === 'BUYERS_TEAM' || accountId.ettleAccountNm != null"
                   :close-on-press-escape="userType ==='SALESMAN' || userType === 'BUYERS_TEAM' || accountId.ettleAccountNm != null"
        >
          <h4 v-if="userType === 'SALESMAN' || userType === 'BUYERS_TEAM'">请选择以下你需服务的客户</h4>
          <h4 v-else>请选择您要采购的分支机构</h4>
          <el-form :inline="true" :model="searchForm" @submit.native.prevent class="demo-form-header" size="small">
            <el-form-item prop="orderNum">
              <el-input
                  style="width: 470px;"
                  v-model="searchForm.searchField"
                  :placeholder="userType === 'SALESMAN' || userType === 'BUYERS_TEAM' ? '请输入客户名称/客户ERP编码/分支机构名称/分支机构编码' : '请输入分支机构名称/分支机构编码/联系手机'"
                  @keyup.enter.native.stop.prevent="handleSearch"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="account" v-loading="tableLoading" border style="margin-bottom: 10px;">
            <el-table-column type="index" label="序号" width="55" fixed="left" align="center"/>
            <el-table-column v-if="userType === 'SALESMAN' || userType === 'BUYERS_TEAM'" width="210" property="erpCode" label="客户ERP编码" show-overflow-tooltip>
              <template slot-scope="scope">
                <div style="display: flex;justify-content: flex-start;align-items: center">
                  <div>{{ scope.row.erpCode }}</div>
                  <div class="erpTab" v-if="scope.row.isOverdue === 'Y'">账期逾期</div>
                  <div class="erpTab" v-if="scope.row.status === 'Y'">证照过期</div>
                  <div class="erpTab" v-if="scope.row.proxyExpired === 'Y'">委托书过期</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-if="userType === 'SALESMAN' || userType === 'BUYERS_TEAM'" property="buyersNm" label="客户名称" show-overflow-tooltip/>
            <el-table-column property="code" label="分支机构编码" min-width="80px" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.code }}
              </template>
            </el-table-column>
            <el-table-column property="ettleAccountNm" label="分支机构" min-width="100px" show-overflow-tooltip>
              <template slot-scope="scope">
                {{  scope.row.ettleAccountNm }}
              </template>
            </el-table-column>
            <el-table-column property="userName" label="采购员"  v-if="userType === 'BUYERS'" width="120"></el-table-column>
            <el-table-column v-if="userType === BUYERS" prop="district" label="所在地区" >
              <template slot-scope="scope">
                <span v-if="scope.row.province">{{ scope.row.province }}</span>
                <span v-if="scope.row.city">{{ '-' + scope.row.city }}</span>
                <span v-if="scope.row.district">{{ '-' + scope.row.district }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="userType === 'SALESMAN' || userType === 'BUYERS_TEAM'" property="customerTypeName" label="客户类型" width="160" show-overflow-tooltip/>
            <el-table-column width="64" label="操作">
              <template slot-scope="scope">
                <el-button v-if="userType === 'SALESMAN' || userType === 'BUYERS_TEAM'" type="text" size="small"
                           @click="handleChangeAccount(scope.row)">选择
                </el-button>
                <el-button v-else-if="scope.row.ettleAccountNm != '默认分支机构'" type="text" size="small"
                           @click="changAccount(scope.row)">选择
                </el-button>
                <el-button v-else="scope.row.ettleAccountNm == '默认分支机构'" type="text" size="small"
                           @click="changAccount(scope.row)">选择
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <im-pagination
              v-if="userType === 'SALESMAN' || userType === 'BUYERS_TEAM'"
              :hide-on-single-page="false"
              :isShowMainInfo="true"
              :total="total"
              :current-page.sync="currentPage"
              :page-size.sync="pageSize"
              @pagination="handlePagination"
          />
        </el-dialog>

        <!--资质过期提示-->
        <license-expired-modal :visible.sync="dialogLicenseVisible"></license-expired-modal>
        <myNotifyMessage ref="myNotifyMessageRef" :visible.sync="dialogMyNotifyVisible" @success="getUnreadCount"></myNotifyMessage>

      </ul>
      <ul class="location">
        <li class="top" v-show="$route.path !== '/'">
          <div>
            <router-link to="/" style="color: #FA6400"><img src="@/assets/img/icon/icon-shangchenshouye.png" alt> 返回商城首页</router-link>
          </div>
        </li>
        <li class="top">
          <div>
            <a @click="goShop">
              <img src="@/assets/img/icon/shoppingCart.png" style="width: 16px;height: 16px" alt> 购物车({{ cartNum }})
            </a>
          </div>
        </li>
        <li>
          <router-link to="/member-center/order-manage/store-order">我的订单</router-link>
        </li>
        <li>
          <el-dropdown>
            <span class="el-dropdown-link">
              会员中心<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <router-link :to="userType ==='SALESMAN' || userType ==='BUYERS_TEAM'? '/member-center' : '/member-center/home'">个人中心</router-link>
              </el-dropdown-item>
              <el-dropdown-item v-if="userType" @click.native="logout">登出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li>
          <span class="message-tip" @click="handleMessage">
            <el-badge :value="unreadCount" :hidden="!unreadCount" :max="99" class="item"><i class="el-icon-message-solid" style="color: #1cb7cd"></i></el-badge>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {getToken, getAccount, getUserType} from '@/utils/auth'
import {pageSelectEttleAccount, getUnreadNotifyMessageCount} from '@/api/org'
import {getMyAptitudes} from "@/api/member-center/personal-center";
import {cancelAllApi} from "@/utils/request";
import LicenseExpiredModal from "./components/licenseExpiredModal";
import myNotifyMessage from "./components/myNotifyMessage.vue";

export default {
  name: 'Header',
  components: {LicenseExpiredModal, myNotifyMessage},
  data() {
    return {
      BUYERS:"",
      account: [],
      pageSize: 10,
      businessIndex: 0,
      dialogTableVisible: false,
      dialogLicenseVisible: false,
      dialogMyNotifyVisible: false,
      dialogTableClose: false,
      total: 0,
      searchForm: {
        searchField: ''
      },
      currentPage: 1,
      userType: getUserType(),
      timer:null,
      tableLoading: true,
      unreadCount: 0
    }
  },
  methods: {
    handleSearch() {
      this.getAccountApi()
    },
    handlePagination(data) {
      this.currentPage = Math.min(this.currentPage, Math.ceil(this.total / data.pageSize))
      this.getAccountApi()
    },
    goShop() {
      if (this.$route.name === 'ShoppingCart') {
        this.$router.push({
          path: '/shopping-cart', query: {
            title: '购物车',
            currentView: 'ShoppingCartList',
            active: 0,
            random: Math.random()
          }
        })
      } else {
        this.$router.push(`/shopping-cart`)
      }
    },
    logout() {
      this.$store.dispatch('user/logout').then(_ => {
        sessionStorage.haveAccount = false
        sessionStorage.removeItem('loginId')
        sessionStorage.removeItem('userMobile')
        this.$router.push(`/login`)
        cancelAllApi()
      })
    },
    
    async changOrg(item, index) {
      await this.$store.dispatch('user/getInfo')
      this.$store.dispatch('user/changeBusiness', item).then(_ => {
        this.$store.dispatch('user/setRefreshModule', Math.random())
        //解决经营单元切换，是否禁止加购问题
        // this.$store.dispatch('user/getInfo')
        
        this.$message.success('切换成功')
        this.businessIndex = index
      })
      // await this.$store.dispatch('user/getInfo')
    },
    handleChangeAccount(row) {
      if (row.isOverdue === 'Y') {
        this.$confirm('该客户账期已逾期，确认继续？', '提示').then(res => {
          this.changAccount(row)
        }).catch(err => {
        })
      } else {
        this.changAccount(row)
      }
    },
    changAccount(row) {
      this.$store.dispatch('user/changAccount', row).then(_ => {
        this.$message.success('切换成功')
        sessionStorage.haveAccount = true
        this.$store.dispatch('user/setRefreshModule', Math.random())
        if ((this.license.isAllLicenseExpired === 'Y' || this.license.isOverdue === 'Y' || this.license.proxyExpired === 'Y') && (this.userType !== 'SALESMAN' || this.userType !== 'BUYERS_TEAM')) {
          this.dialogLicenseVisible = true
        }
        this.dialogTableVisible = false
        window.location.reload()
      })
    },
    getAccountApi() {
      let pageSize = 10
      this.tableLoading = true
      pageSize = this.userType === 'SALESMAN' || this.userType === 'BUYERS_TEAM' || this.userType === '' ? this.pageSize : 10000
      return new Promise((resolve, reject) => {
        pageSelectEttleAccount({
          pageSize: pageSize,
          pageNum: this.currentPage,
          condition: this.searchForm
        }).then(response => {
          const {data} = response
          this.account = data.records
          this.total = data.total
          resolve(data.records)
        }).catch(error => {
          reject(error)
        }).finally(() => {
          this.tableLoading = false
        })
      })
    },
    // 获得未读消息数
    getUnreadCount() {
      if (getToken()) {
        getUnreadNotifyMessageCount().then((data) => {
          console.log('data', data)
          if (data.code === '0') {
            this.unreadCount = data.data
          }
        })
      }
      this.unreadCount = 0
    },
    handleMessage() {
      this.getUnreadCount()
      this.dialogMyNotifyVisible = true
    }
  },
  created() {
    if (getToken()) {
      this.getAccountApi().then(res => {
        if (getAccount() && res.filter(item => item.toString() === getAccount().toString())) {
          this.$store.dispatch('user/changAccount', getAccount())
        } else {
          if (res.length == 1) {
            this.$store.dispatch('user/changAccount', res[0]).then(res => {
              sessionStorage.haveAccount = true
            })
          } else {
            //需要判断是否为主账号且为采购员
            this.dialogTableVisible = !(this.license.isDefault === 'N' && this.userType === 'BUYERS')
            if(this.dialogTableVisible){
              this.dialogLicenseVisible = false
            }
          }
        }
      })
    }
  },
  mounted() {
    this.getUnreadCount()
    setInterval(() => {
      this.getUnreadCount()
      // }, 1000 * 60 * 2)
    }, 1000 * 60 * 1)
  },
  watch: {
    'buyersVo'(newValue, oldValue) {
      if ((this.license.isAllLicenseExpired === 'Y' || this.license.isAboutFailure === 'Y' || this.license.proxyExpired === 'Y') && (this.userType !== 'SALESMAN' || this.userType !== 'BUYERS_TEAM')) {
          this.dialogLicenseVisible = true
      }
    },
    'account.length'(newValue) {
      this.dialogTableClose = !newValue
    },
    org(newVal) {
      this.businessIndex = this.allBusiness.indexOf(this.org)
    },
    'cartNum'(newVal) {
      this.GetCartNum = newVal
    }
  },
  computed: {
    ...mapGetters(['allBusiness', 'buyersVo', 'org', 'cartNum', 'license', 'accountId']),
  }
}
</script>

<style lang="scss" scoped>
.erpTab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: red;
  height: 20px;
  font-size: 12px;
  border: 1px solid red;
  padding: 1px 3px;
  border-radius: 3px;
  margin-left: 5px;
}

.noSelect {
  // width: 72px;
  // height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  // line-height: 17px;
}

.itemName {
  // background: #1890FF;
  // margin: 0 0 0 20px;
  //&::after{
  //  content: '√';
  //  margin: 0 0 0 20px;
  //}

}

.header-box {
  width: 100%;
  background-color: #F5F5F5;

  .nav {
    margin: 0 auto;
    width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    li {
      float: left;
      line-height: 30px;
    }

    a {
      color: #999999;
      padding-left: 20px;
      cursor: pointer;

      &:hover {
        @include TextHighlightColor
      }
    }

    .first {
      font-size: 13px;
      color: #999999;

      /deep/ .el-dropdown {
        font-size: 13px;
        cursor: pointer;
        @include TextHighlightColor;
        .el-icon--right {
          margin-left: 0;
        }
      }

      .icon {
        width: 13px;
        height: 13px;
        vertical-align: text-top;
      }

      .welcome-text {
        margin-left: 12px;
        color: #333;

        b, em {
          max-width: 260px;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        b {
          @include TextHighlightColor;
        }

        &.cur {
          cursor: pointer;

          &:hover {
            @include TextHighlightColor;
          }
        }
      }

      .text-color-red {
        a {
          @include TextHighlightColor;
          margin-left: 6px;
        }
      }

      a:first-child {
        padding-left: 10px;
        border-left: none;
        display: inline-flex;
      }
    }

    .dialog-table {
      h4 {
        font-size: 14px;
        color: #666;
        padding-bottom: 12px;
      }

      /deep/ .el-dialog {
        text-align: left;

        .el-dialog__body {
          padding: 20px;
        }
      }
    }



    .location {
      .top img {
        vertical-align: middle;
      }
      .top{
        >div{
          a{
            display: flex;
            align-items: center;
          }
        }
      }
      a {
        color: #222222;
        font-size: 12px;
        padding-right: 20px;
        border-right: 1px solid #ddd;

        &:hover {
          @include TextHighlightColor
        }
      }

      .el-dropdown-link {
        cursor: pointer;
        font-size: 12px;
        color: #222;
        padding-left: 15px;
      }

      .el-icon-arrow-down {
        font-size: 12px;
      }
    }
  }
}

.el-dropdown-menu {
  top: 15px !important;
  max-height: 90%;
  padding: 10px;
  overflow: auto;
}

.demo-form-header /deep/ {
  .el-form-item {
    margin-bottom: 10px;
  }

  .el-button--mini {
    padding: 8px 15px;
  }
}
.message-tip {
  padding: 0 20px;
  margin-left: 10px;
  border-left: 1px solid #ddd;
  cursor: pointer;
  /deep/.el-badge__content.is-fixed {
    top: 8px;
    right: 6px;
  }
}
</style>

<style lang="scss">
.business{
  color: #222;
}
.el-dropdown-menu__item.checkBol-header {
  @include TextHighlightColor;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 5px;
    height: 9px;
    border-color: $ThemeColor;
    border-style: solid;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    right: -7px;
    top: 50%;
    margin-top: -5px;
  }
}
</style>

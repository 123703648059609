var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: { width: "1315", placement: "bottom-start", trigger: "manual" },
      model: {
        value: _vm.inputText,
        callback: function ($$v) {
          _vm.inputText = $$v
        },
        expression: "inputText",
      },
    },
    [
      _c(
        "el-table",
        {
          ref: "fosTable",
          staticClass: "tableSearch",
          attrs: {
            data: _vm.gridData,
            "max-height": "330",
            id: "fosTable" + _vm.index,
            "row-class-name": _vm.rowClassName,
            "row-style": _vm.rowStyle,
            border: "",
          },
          on: { "row-click": _vm.rowClick },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "60",
              type: "index",
              label: "序号",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "80",
              property: "productCode",
              label: "商品编码",
            },
          }),
          _c("el-table-column", {
            attrs: { width: "250", property: "productNm", label: "商品名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "productNm" }, [
                      scope.row.otcType && scope.row.otcType !== "其他"
                        ? _c(
                            "span",
                            {
                              staticClass: "otcType",
                              class: {
                                jiaOtc: scope.row.otcType === "甲OTC",
                                yiOtc: scope.row.otcType === "乙OTC",
                                rxOtc: scope.row.otcType === "RX",
                              },
                            },
                            [_vm._v(" " + _vm._s(scope.row.otcType) + " ")]
                          )
                        : _vm._e(),
                      _c("p", [
                        _vm._v(
                          " " + _vm._s(_vm.$util.getGoodsName(scope.row)) + " "
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      [
                        _c("tagList", {
                          attrs: { taglist: scope.row.tagList },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm.userType == "SALESMAN"
            ? _c("el-table-column", {
                attrs: { label: "标识", "min-width": "80px", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "identifying" }, [
                            scope.row.whiteRecordFlag == 1
                              ? _c("img", {
                                  attrs: {
                                    alt: "",
                                    src: require("@/assets/img/icon/whiterecord.png"),
                                  },
                                })
                              : scope.row.blackRecordFlag == 1
                              ? _c("img", {
                                  attrs: {
                                    alt: "",
                                    src: require("@/assets/img/icon/blackrecord.png"),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2003240333
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { width: "130", property: "spec", label: "规格" },
          }),
          _c("el-table-column", {
            attrs: {
              width: "190",
              property: "manufacturer",
              label: "厂家(上市许可持有人)/产地",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(scope.row.manufacturer) +
                        _vm._s(scope.row.area ? "/" + scope.row.area : "") +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              "min-width": "50",
              property: "stockQuantityStatus",
              label: "库存",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        style: {
                          color: scope.row.stockQuantity > 100 ? "" : "#E02020",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.isSalesman
                              ? scope.row.stockQuantity
                              : scope.row.stockQuantity > 100
                              ? ">100"
                              : "<100"
                          )
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "right",
              width: "90",
              property: "buyersPrice",
              label: "销售价",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticStyle: { color: "#FF5200" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$util.getPriceByZero(
                            scope.row.buyersPrice,
                            scope.row
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm.isSalesman
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "90",
                  property: "assessmentPrice",
                  label: "考核价",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", { staticStyle: { color: "#FF5200" } }, [
                            _vm._v("¥" + _vm._s(scope.row.assessmentPrice)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  933679177
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "65",
              property: "packTotal",
              label: "件包装",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.packTotal))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "60",
              property: "rate",
              label: "销项税",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.rate))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "100", property: "step", label: "最小购买倍数" },
          }),
        ],
        1
      ),
      _c("el-input", {
        key: "input" + _vm.index,
        staticStyle: { height: "32px" },
        attrs: {
          slot: "reference",
          id: "inputFocus" + _vm.index,
          placeholder: "请输入商品名称|编码",
        },
        on: { input: _vm.getText, blur: _vm.blur, focus: _vm.validateCounts },
        nativeOn: {
          keyup: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
              )
                return null
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.up.apply(null, arguments)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown",
                ])
              )
                return null
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.down.apply(null, arguments)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.enter.apply(null, arguments)
            },
          ],
        },
        slot: "reference",
        model: {
          value: _vm.searchText,
          callback: function ($$v) {
            _vm.searchText = $$v
          },
          expression: "searchText",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="priceBody">
    <Search :hidden-nav="true"/>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/member-center' }">会员中心</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: subPath }">{{subtitle}}</el-breadcrumb-item>
        <el-breadcrumb-item>调价列表</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button @click="back" size="mini" class="backBut">返回</el-button>
    </div>
    <div class="interfaceOrderDiv">
      <!-- 搜索区域 -->
      <el-form :inline="true" :model="searchForm" class="searchForm" size="small" label-width="70px">
        <el-form-item label="门店编码">
          <el-input class="searchFormItem" v-model="searchForm.storeCode" placeholder="输入门店编码"></el-input>
        </el-form-item>
        <el-form-item label="门店名称">
          <el-input class="searchFormItem" v-model="searchForm.storeNm" placeholder="输入门店名称"></el-input>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input class="searchFormItem" v-model="searchForm.productNm" placeholder="输入商品名称"></el-input>
        </el-form-item>
        <el-form-item label="要货时间">
          <el-date-picker
              v-model="searchForm.time"
              class="dateFormItem"
              type="daterange"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              size="small"
              align="right"
              unlink-panels
              range-separator="至"
              @change="changeDatePicker"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item class="formBut">
          <el-button type="primary" size="mini" @click="search">搜索</el-button>
          <el-button type="text" size="mini" @click="reset">
            <span style="color: #000000">重置</span>
          </el-button>
<!--          <div style="width: 1px;height: 12px;background-color: #E5E5E5;margin: 0 8px;margin-top: 8px;"></div>-->
<!--          <el-button type="text" size="mini" @click="onExpand" style="color:#000000;font-weight:400;">-->
<!--            {{ isExpanded? '收起' : '展开' }}-->
<!--            <i :class="isExpanded ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" />-->
<!--          </el-button>-->
        </el-form-item>
      </el-form>
      <im-tool-bar class="butDiv">
        <template slot="left">
          <el-button type="primary" @click="savePrice" size="small">保存调价</el-button>
        </template>
        <template slot="right">
          <el-checkbox v-model="isPriceMatch" class="rightCheck" @change="checkChange">价格匹配</el-checkbox>
          <el-checkbox v-model="isPriceHigh" class="rightCheck" @change="checkChange">高于业态价</el-checkbox>
        </template>
      </im-tool-bar>
      <div class="tableDiv" v-loading="loading">
        <el-table
          :data="dataList"
          ref="table"
          border
          class="table"
          :cell-class-name="cellClassName"
          :header-cell-class-name="cellClassName"
          style="width: 100%">
          <el-table-column label="客户编码" prop="customerCode" width="80" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column label="门店编码" prop="storeCode" width="80" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column label="门店名称" prop="storeNm" width="100" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column label="订单编号" prop="distributionOrderNum" width="80" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column label="商品编码" prop="imallProductCode" width="80" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column label="商品名称" prop="productNm" width="120" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column label="规格" prop="spec" width="75" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column label="生产厂家" prop="manufacturer" width="120" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column label="平台价格" prop="askingPrice" width="80" align="right" show-overflow-tooltip ></el-table-column>
          <el-table-column label="要货价格" prop="originalPrice" width="100" align="right" show-overflow-tooltip >
            <template slot-scope="scope">
              <div style="display: flex">
                <span class="price" :style="{'background': removeSemicolon(scope.row.originalPrice) > removeSemicolon(scope.row.askingPrice) ? 'rgba(51,174,103,0.8)': 'rgba(224,32,52,0.8)'}">{{scope.row.originalPrice}}</span>
                <img v-if="removeSemicolon(scope.row.originalPrice) > removeSemicolon(scope.row.askingPrice)" class="priceIcon" src="@/assets/img/member-center/up.png">
                <img v-if="removeSemicolon(scope.row.originalPrice) < removeSemicolon(scope.row.askingPrice)" class="priceIcon" src="@/assets/img/member-center/down.png">
              </div>
            </template>
          </el-table-column>
          <el-table-column label="调价" prop="quantity" width="120" align="center" show-overflow-tooltip >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.adjustPrice"
                :class="scope.row.priceColor"
                type="text"/>
            </template>
          </el-table-column>
          <el-table-column label="浮动比例" prop="slidingScales" width="80" align="center" show-overflow-tooltip ></el-table-column>
          <el-table-column label="毛利率" prop="grossProfitRate" width="80" align="center" show-overflow-tooltip ></el-table-column>
          <el-table-column label="更新时间" prop="updateTime" min-width="80" align="center" show-overflow-tooltip ></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { adjustPrice } from '@/api/member-center/consigned-delivery'
import Search from '@/components/Search/Search'
import { mapGetters } from 'vuex'
export default {
  name: "interfaceOrder",
  components:{
    Search
  },
  computed: {
    ...mapGetters(['consignedDelivery'])
  },
  data(){
    return{
      searchForm: {
        type: 'adjustPrice',
        productNm: '',
        storeCode: '',
        startTime: '',
        endTime: '',
        storeNm: '',
        isPriceMatch: '',
        isPriceHigh: '',
        unmatched: 'N',
        time: []
      },
      price: '',
      checkItems: [],
      dataList: [],
      isExpanded: false,
      checked: false,
      isPriceMatch: false,
      isPriceHigh: false,
      unmatched: false,
      loading: false,
      multipleSelection: [],
      selectList: [],
      allChecked: false,
      subtitle: '',
      subPath: ''
    }
  },
  methods: {
    search() {
      let storeCode = new RegExp(this.searchForm.storeCode)
      let productNm = new RegExp(this.searchForm.productNm)
      let storeNm = new RegExp(this.searchForm.storeNm)
      let startTime = this.searchForm.startTime.replace(/-/g,"")
      let endTime = this.searchForm.endTime.replace(/-/g,"")
      this.dataList = this.consignedDelivery.filter(item => {
        let createTime = item.createTime.replace(/-/g,"")
        return item.productNm.match(productNm) && (!this.isPriceMatch || item.originalPrice === item.askingPrice)
            && (!this.isPriceHigh || item.originalPrice > item.askingPrice) && item.storeCode.match(storeCode) && item.storeNm.match(storeNm)
            && (this.searchForm.time.length < 1 || (startTime <= createTime && createTime <= endTime))
      })
    },
    reset() {
      this.searchForm = {
        type: 'adjustPrice',
        productNm: '',
        storeCode: '',
        startTime: '',
        endTime: '',
        storeNm: '',
        isPriceMatch: '',
        isPriceHigh: '',
        unmatched: 'N',
        time: []
      }
      this.updateData()
    },
    changeDatePicker(dateList) {
      this.searchForm.startTime = dateList[0];
      this.searchForm.endTime = dateList[1];
    },
    checkChange() {
      this.search()
    },
    onExpand() {
      this.isExpanded = !this.isExpanded
    },
    updateData() {
      this.dataList = this.consignedDelivery
    },
    cellClassName ({ row, column, rowIndex, columnIndex }) {
      if (row.unmatched === 'Y') {
        return 'cellColorOrder'
      } else {
        return 'cellColorOrder2'
      }
    },
    savePrice() {
      let params = {
        distributionOrderAdjustPriceConditionVos: []
      }
      let priceError = false
      this.dataList.forEach(item => {
        if(item.adjustPrice){
          let askingPrice = this.removeSemicolon(item.askingPrice)
          if(item.adjustPrice < askingPrice) {
            priceError = true
            this.$set(item, 'priceColor', 'priceRed')
          }else{
            this.$set(item, 'priceColor', '')
          }
          let distribution = {
            adjustPrice: item.adjustPrice,
            retailPrice: item.adjustPrice,
            distributionOrderItemId: item.distributionOrderItemId
          }
          params.distributionOrderAdjustPriceConditionVos.push(distribution)
        }else{
          this.$set(item, 'priceColor', '')
        }
      })
      if (params.distributionOrderAdjustPriceConditionVos.length < 1) {
        this.$message.error('请输入需要调整的价格')
        return
      }
      if (priceError) {
        this.$confirm('调价低于平台价格，是否确定调价', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          adjustPrice(params).then(res => {
            this.dataList = this.consignedDelivery.filter(item => {
              return !item.adjustPrice
            })
            this.$store.dispatch('user/setConsignedDelivery', this.dataList)
            console.log(this.consignedDelivery)
            this.$message.success('修改成功')
          })
        }).catch(() => {
        })
        return
      }
      adjustPrice(params).then(res => {
        this.dataList = this.consignedDelivery.filter(item => {
          return !item.adjustPrice
        })
        this.$store.dispatch('user/setConsignedDelivery', this.dataList)
        console.log(this.consignedDelivery)
        this.$message.success('修改成功')
      })
    },
    // 去掉千分位
    removeSemicolon(num){
      let x = num.split(',');
      return parseFloat(x.join(""));
    },
    back(){
      this.$router.go(-1)
    }
  },
  mounted() {
    if (this.$route.query.outOfStock == 'Y'){
      this.subtitle = '委托配送缺货订单'
      this.subPath = '/member-center/consigned-delivery/out-of-stock-order/index'
    }else{
      this.subtitle = '委托配送订单'
      this.subPath = '/member-center/consigned-delivery/interface-order/order'
    }
    this.updateData()
  }
}
</script>

<style lang="scss" scoped>
.priceBody {
  background-color: #f5f5f5;
  padding-bottom: 20px;
  .breadcrumb {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    padding-left: 24px;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .backBut{
      color: #0971EB;
      border-color: #0971EB;
      height: 28px;
      margin-top: 11px;
      margin-right: 20px;
    }
    /deep/ .el-breadcrumb {
      display: inline-block;
      line-height: 50px;
      float: left;
    }
  }
}
.interfaceOrderDiv {
  background-color: #FFFFFF;
  margin: 0px auto;
  text-align: left;
  //padding: 0 20px;
  min-height: 780px;
  border-radius:8px 8px 0px 0px;
  width: 1300px;
  .searchForm{
    border-bottom: 1px solid #E5E5E5;
    padding: 18px 20px 0 20px;
    .searchFormItem{
      width: 147px;
      margin-right: 10px;
    }
    .dateFormItem {
      width: 200px;
      margin-right: 10px;
    }
    .rightCheck{
      line-height: 34px;
    }
    .formBut /deep/.el-form-item__content{
      display: flex;
    }
  }
  .butDiv {
    margin: 18px 0px;
    padding: 0 20px 0 8px;
  }
  .tableDiv {
    padding: 0 8px 18px 8px;
    border-bottom: 1px solid #E5E5E5;
    .tableTitle{
      width: 100%;
      height: 40px;
      background: rgba(245,245,245,1);
      border-radius: 4px 4px 0px 0px;
      display: flex;
      line-height: 40px;
      font-size: 14px;
      font-weight:400;
      color:rgba(102,102,102,1);
    }
    .noData {
      text-align: center;
      padding-top: 15px;
      font-size: 14px;
      color: #909399;
    }
    .tableItem{
      height:40px;
      background:rgba(241,247,254,1);
      border:1px solid rgba(229,229,229,1);
      border-bottom: none;
      line-height: 40px;
      margin-top: 8px;
      font-size: 14px;
      font-weight:500;
      color:rgba(102,102,102,1);
      padding-left: 10px;
      .tableAllChecked{
        margin-left: 20px;
        margin-right: 23px;
      }
      span{
        margin-right: 15px;
      }
    }
    .table{
      /deep/.el-input__inner{
        height: 32px;
        padding: 0 5px;
      }
      .price {
        color: #ffffff;
        border-radius: 2px;
        height: 100%;
        text-align: center;
        margin: 0 3px;
        padding: 0 5px;
      }
      .priceIcon {
        margin-top: 4px;
        width: 12px;
        height: 14px;
      }
      .priceRed /deep/ .el-input__inner{
        border-color: #FF3B30;
      }
    }
    .table-page {
      margin-top: 15px;
    }
  }
  .bottomDiv {
    display: flex;
    padding: 20px 0;
    line-height: 36px;
    justify-content: space-between;
    .removeBut{
      color: #666666;
      border-color: #CCCCCC;
      font-size:14px;
      font-weight:400;
    }
    .orderBut{
      color: #ffffff;
      border-color: #FA6400;
      background-color: #FA6400;
      font-size:14px;
      font-weight:400;
    }
  }
  /deep/.el-form-item--small.el-form-item{
    margin-bottom: 18px;
  }
}
</style>

<style lang="scss">
.cellColor .cell{
  color: #E02020;
}
</style>

/**
 * ImSearchPad
 * @module components
 * @desc table内敛确认提示框
 * @author Chen Runrong
 * @date 2020/03/17
 * @param {Number} [index] - table的行索引
 * @param {String} [tipTxt] - 提示语
 * @param {String} [referenceBtnTxt] - 按钮文字
 * @example 调用示例
 * <im-confirm-popup :index="scope.$index" tip-txt="是否确定作废档案？" reference-btn-txt="作废" />
 */
import imConfirmPopup from './index.vue'

const ImConfirmPopup = {
  install: function(Vue) {
    Vue.component('ImConfirmPopup', imConfirmPopup)
  }
}

export default ImConfirmPopup

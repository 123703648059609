<template>
    <div class="shopping-cart-container">
        <header class="header">
            <div class="header-content">
                <div class="logo">
                    <router-link to="/">
                        <img v-if="header.PC_TOP_LEFT_T1 && header.PC_TOP_LEFT_T1.length"
                             :src="header.PC_TOP_LEFT_T1[0].sysFileUrl" alt>
                    </router-link>
                </div>
                <h4>{{ params.title }}</h4>
                <div class="steps">
                    <el-steps :active="params.active"
                              :align-center="true"
                              finish-status="finish"
                              process-status="finish">
                        <el-step title="进货单"></el-step>
                        <el-step title="填写核对订单"></el-step>
                        <el-step title="提交订单"></el-step>
                    </el-steps>
                </div>
            </div>
        </header>
        <component
            :is="params.currentView"
            :params="params"
            @change-page="handleChangePage"
        />
        <!--    <swiper class="recommend-swiper"/>-->
    </div>
</template>

<script>
import ShoppingCartList from './components/list'
import CollateOrder from './components/collate-order'
import Cashier from './components/cashier'
import paySuccess from './components/pay-success'
import {mapGetters} from 'vuex'

export default {
    name: 'ShoppingCart',
    components: {ShoppingCartList, CollateOrder, Cashier, paySuccess},
    data() {
        return {
            params: {
                title: '购物车',
                currentView: 'ShoppingCartList',
                active: 0,//步骤条
            },
        }
    },
    computed: {
        ...mapGetters(['header'])
    },
    mounted() {
        if (this.$route.query.active) {
            this.params = this.$route.query
            if (typeof (this.params.active) === 'string') {
                this.params.active = parseInt(this.params.active)
            }
        }
    },
    watch: {
        "$route.query": {
            handler(newVal) {
                if (newVal.currentView) {
                    this.handleChangePage({params: newVal})
                }
            },
            deep: true
        }
    },
    methods: {
        handleChangePage(data) {
            this.params = data.params
        }
    }
}
</script>

<style scoped lang="scss">

.shopping-cart-container {
    width: 100%;
    min-width: 1500px;
    height: auto;
    padding-bottom: 20px;
    background: #F5F5F5;

    .header {
        width: 100%;
        height: 100px;
        background: #FFFFFF;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
        margin-bottom: 10px;

        .header-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 auto;
            width: 1200px;
            height: 100%;

            .logo {
                margin-right: 20px;

                img {
                    width: 230px;
                    height: 80px;
                }
            }

            h4 {
                padding-left: 20px;
                flex: 1;
                text-align: left;
                font-size: 20px;
                font-weight: 500;
                line-height: 40px;
                height: 40px;
                border-left: 1px solid #CCCCCC;
                color: #222222;
            }

            /*步骤条*/
            .steps {
                width: 400px;
                flex: 1;

                /deep/ .el-steps {
                    .el-step__icon {
                        border: none;
                        width: 24px;
                        height: 24px;
                        font-size: 14px;
                        background: rgba(229, 229, 229, 1);

                        .el-step__icon-inner {
                            color: #999999;
                        }
                    }

                    .el-step__title {
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(153, 153, 153, 1);
                    }

                    /*完成步骤*/
                    .is-finish {
                        .el-step__icon {
                            .el-step__icon-inner {
                                color: #ffffff;
                            }

                            color: #0971EB;
                            background: #0971EB;
                        }

                        &.el-step__title {
                            color: rgba(34, 34, 34, 1);
                        }
                    }


                    .el-step__main {
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(34, 34, 34, 1);
                    }

                    .el-step__line {
                        margin: 0 28px !important;
                        height: 1px;
                    }
                }
            }
        }
    }


    /*为你推荐*/
    //.recommend-container {
    //
    //  .recommend {
    //    margin: 0 auto;
    //    border-radius: 8px 8px 0 0;
    //    border: 1px solid rgba(229, 229, 229, 1);
    //    width: 1200px;
    //    height: auto;
    //    background: #ffffff;
    //
    //    p {
    //      box-sizing: border-box;
    //      padding-left: 20px;
    //      width: 100%;
    //      height: 28px;
    //      line-height: 28px;
    //      text-align: left;
    //      font-size: 14px;
    //      font-weight: 600;
    //      color: rgba(34, 34, 34, 1);
    //      background: rgba(255, 102, 0, 0.05);
    //    }
    //
    //    /*轮播图*/
    //    .recommend-swiper {
    //      width: 100%;
    //      height: 158px;
    //    }
    //  }
    //}
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main", on: { click: _vm.close } },
    [
      _c("Search", { attrs: { "index-nav": true } }),
      _vm.license.isAllLicenseExpired === "Y"
        ? _c(
            "div",
            { staticClass: "aptitudes" },
            [
              _vm._v(
                "温馨提示：" +
                  _vm._s(
                    _vm.userType !== "SALESMAN"
                      ? "您有" +
                          _vm.license.failureSum +
                          "张证照已过期,请联系业务员更换,以免影响您的正常采购"
                      : "该客户有" +
                          _vm.license.failureSum +
                          "张证照已过期，以免影响正常采购！"
                  ) +
                  " "
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "text" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v("查看详情")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "user" }, [
        _c("div", { staticClass: "userDetail" }, [
          _c(
            "div",
            { staticStyle: { flex: "7", margin: "11px" } },
            [
              _c("span", { staticClass: "userTitle" }, [_vm._v("客户名称：")]),
              _c("span", { staticClass: "userInfo" }, [
                _vm._v(
                  _vm._s(_vm.buyersNm) +
                    _vm._s(
                      _vm.ettleAccount.ettleAccountNm === "默认分支机构"
                        ? ""
                        : " - " + _vm.ettleAccount.ettleAccountNm
                    )
                ),
              ]),
              _c("br"),
              _c("br"),
              _c("span", { staticClass: "userTitle" }, [_vm._v("联系人：")]),
              _c("span", { staticClass: "userInfo" }, [
                _vm._v(_vm._s(_vm.receiverName)),
              ]),
              _c("span", { staticClass: "userTitle" }, [_vm._v("联系电话：")]),
              _c("span", { staticClass: "userInfo" }, [
                _vm._v(_vm._s(_vm.receiverMobile)),
              ]),
              _c("br"),
              _c("br"),
              _c("span", { staticClass: "userTitle" }, [_vm._v("收货地址：")]),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isChangeAddr,
                      expression: "!isChangeAddr",
                    },
                  ],
                  staticClass: "userInfo",
                },
                [_vm._v(_vm._s(_vm.address))]
              ),
              _c(
                "el-select",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isChangeAddr,
                      expression: "isChangeAddr",
                    },
                  ],
                  staticStyle: { "margin-right": "24px", width: "270px" },
                  attrs: { placeholder: "请选择", id: "selectAdd" },
                  on: { change: _vm.addrChange },
                  model: {
                    value: _vm.address,
                    callback: function ($$v) {
                      _vm.address = $$v
                    },
                    expression: "address",
                  },
                },
                _vm._l(_vm.addressOptions, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _vm.addressOptions.length > 1
                ? _c(
                    "el-button",
                    { attrs: { type: "text" }, on: { click: _vm.changeAddr } },
                    [_vm._v("切换地址")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.userDetail.memberDetailsVo &&
          _vm.userDetail.memberDetailsVo.isCredit === "Y"
            ? _c("div", {
                staticStyle: {
                  width: "1px",
                  height: "39px",
                  background: "rgba(229,229,229,1)",
                  margin: "11px",
                },
              })
            : _vm._e(),
          _vm.userDetail.memberDetailsVo &&
          _vm.userDetail.memberDetailsVo.isCredit === "Y"
            ? _c("div", { staticStyle: { flex: "3", margin: "11px" } }, [
                _c("p", { staticClass: "last" }, [_vm._v("可用额度：")]),
                _c("p", { staticClass: "userMoney" }, [
                  _vm._v("¥" + _vm._s(_vm.totalCredit)),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "productList" },
        [
          _c(
            "div",
            { staticClass: "excel" },
            [
              _c(
                "el-button",
                {
                  staticClass: "excelButton",
                  attrs: { type: "primary" },
                  on: { click: _vm.importExcel },
                },
                [_vm._v("Excel导入采购计划")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingTablePro,
                  expression: "loadingTablePro",
                },
              ],
              ref: "multipleTable",
              staticClass: "tablePro",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData,
                "header-row-class-name": "header-row",
                height: "600",
                "header-cell-class-name": _vm.cellClass,
                id: "tableList",
                "row-class-name": _vm.tableRowClassName,
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                ref: "checkTab",
                attrs: { type: "selection", width: "35" },
              }),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  align: "center",
                  label: "序号",
                  width: "45",
                },
              }),
              _c("el-table-column", {
                key: "productCode",
                attrs: {
                  prop: "productCode",
                  label: "商品编码",
                  align: "center",
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.productCode) + " "),
                        _c(
                          "div",
                          [
                            _c("tagList", {
                              attrs: { taglist: scope.row.tagList },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                key: "productNm",
                attrs: {
                  prop: "productNm",
                  label: "商品名称",
                  "min-width": "110px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("fast-order-search", {
                          ref: "productNm" + scope.$index,
                          staticClass: "inputName",
                          attrs: {
                            index: scope.$index,
                            id: "search" + scope.$index,
                            productNm: _vm.$util.getGoodsName(scope.row),
                            isSalesman: _vm.isSalesman,
                          },
                          on: {
                            getIndex: _vm.getIndex,
                            fast_order_fu: _vm.getSelectPro,
                            fast_order_tab: function ($event) {
                              return _vm.getSelectTab()
                            },
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                $event.keyCode !== 46
                              )
                                return null
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.handleDelete(scope.row, scope.$index)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm.userType == "SALESMAN"
                ? _c("el-table-column", {
                    attrs: {
                      label: "标识",
                      "min-width": "50px",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "identifying" }, [
                                scope.row.whiteRecordFlag == 1
                                  ? _c("img", {
                                      attrs: {
                                        alt: "",
                                        src: require("@/assets/img/icon/whiterecord.png"),
                                      },
                                    })
                                  : scope.row.blackRecordFlag == 1
                                  ? _c("img", {
                                      attrs: {
                                        alt: "",
                                        src: require("@/assets/img/icon/blackrecord.png"),
                                      },
                                    })
                                  : _vm._e(),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2087956621
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                key: "spec",
                attrs: {
                  prop: "spec",
                  align: "center",
                  label: "规格",
                  width: "90px",
                },
              }),
              _c("el-table-column", {
                key: "manufacturer",
                attrs: {
                  prop: "manufacturer",
                  align: "center",
                  label: "厂家(上市许可持有人)/产地",
                  width: "100px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.manufacturer) +
                            _vm._s(scope.row.area ? "/" + scope.row.area : "") +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                key: "packTotal",
                attrs: {
                  prop: "packTotal",
                  align: "center",
                  label: "件包装",
                  width: "65px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.packTotal))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                key: "unit",
                attrs: {
                  prop: "unit",
                  align: "center",
                  label: "单位",
                  "min-width": "50px",
                },
              }),
              _c("el-table-column", {
                key: "rate",
                attrs: {
                  prop: "rate",
                  align: "center",
                  label: "销项税",
                  width: "60px",
                },
              }),
              _c("el-table-column", {
                key: "buyNum",
                attrs: {
                  prop: "buyNum",
                  align: "center",
                  label: "采购数量",
                  width: "120px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input-number", {
                          key: "inputNum" + scope.$index,
                          staticClass: "proNums",
                          attrs: {
                            size: "mini",
                            precision: _vm.getDecimalPointNum(scope.row),
                            placeholder: "数量",
                            "step-strictly": "",
                            disabled: !scope.row.productId,
                            step: scope.row.productId
                              ? _vm.getRealMinBuyNum(scope.row)
                              : 1,
                            min: scope.row.productId
                              ? _vm.getRealMinBuyNum(scope.row)
                              : 0,
                            id: scope.$index,
                          },
                          on: {
                            change: (currentValue, oldValue) =>
                              _vm.handleChange(
                                currentValue,
                                oldValue,
                                scope.$index
                              ),
                          },
                          nativeOn: {
                            keyup: [
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.nextProduct(scope.$index)
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  $event.keyCode !== 46
                                )
                                  return null
                                return _vm.handleDelete(scope.row, scope.$index)
                              },
                            ],
                          },
                          model: {
                            value: scope.row.buyNum,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "buyNum", $$v)
                            },
                            expression: "scope.row.buyNum",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm.$store.getters.buyersVo &&
              _vm.$store.getters.buyersVo.showStockType != 5
                ? _c("el-table-column", {
                    attrs: {
                      prop: "stockQuantity",
                      sortable: "",
                      align: "center",
                      label: "库存",
                      "min-width": "60px",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.$store.getters.buyersVo.showStockType == 4
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.stockQuantity >= 100
                                            ? ">100"
                                            : "<100"
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm.$store.getters.buyersVo.showStockType == 3
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.stockQuantity > 0
                                            ? "有"
                                            : "无"
                                        ) +
                                        " "
                                    ),
                                  ])
                                : scope.row.stockQuantity === -1
                                ? _c("span", [_vm._v("-/-")])
                                : _vm.$store.getters.buyersVo.showStockType == 2
                                ? _c(
                                    "span",
                                    {
                                      style: {
                                        color:
                                          scope.row.stockQuantity > 100
                                            ? ""
                                            : "#E02020",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(scope.row.stockQuantity) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1531272729
                    ),
                  })
                : _vm._e(),
              _vm.userType === "SALESMAN"
                ? _c("el-table-column", {
                    key: "lastSellPrice",
                    staticClass: "price",
                    staticStyle: { color: "#FF5200" },
                    attrs: {
                      prop: "lastSellPrice",
                      align: "right",
                      label: "上次销价",
                      "min-width": "75px",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.lastSellPrice
                                ? _c("span", [
                                    _vm._v(
                                      "¥" + _vm._s(scope.row.lastSellPrice)
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1357056791
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                key: "buyersPrice",
                staticClass: "price",
                attrs: {
                  prop: "buyersPrice",
                  align: "right",
                  label: "销售价",
                  sortable: "",
                  "min-width": "65px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticStyle: { color: "#FF6600" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.getListPrice(scope.row)) + " "
                          ),
                        ]),
                        scope.row.isDiscount === "Y" &&
                        _vm.getListPrice(scope.row) !==
                          "￥" + scope.row.salePrice
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  "text-decoration": "line-through",
                                  color: "#999999",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.salePrice
                                        ? "￥" + scope.row.salePrice
                                        : ""
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm.isSalesman
                ? _c("el-table-column", {
                    key: "assessmentPrice",
                    staticClass: "price",
                    attrs: {
                      prop: "assessmentPrice",
                      align: "right",
                      label: "考核价",
                      "min-width": "65px",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.assessmentPrice
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#FF6600" } },
                                    [
                                      _vm._v(
                                        "¥" + _vm._s(scope.row.assessmentPrice)
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2758379498
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                key: "step",
                attrs: {
                  prop: "step",
                  align: "center",
                  label: "最小购买倍数",
                  width: "100px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.getRealMinBuyNum(scope.row))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm.userType === "BUYERS" || _vm.userType === "BUYERS_TEAM"
                ? _c("el-table-column", {
                    key: "expDate",
                    staticClass: "price",
                    staticStyle: { color: "#FF5200" },
                    attrs: {
                      prop: "expDate",
                      align: "center",
                      label: "最近效期",
                      width: "130px",
                      sortable: "",
                    },
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { align: "center", width: "200px", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.productId && _vm.userType === "SALESMAN"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showPHXQ(scope.row, scope.$index)
                                  },
                                },
                              },
                              [_vm._v("查看批号效期")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.row, scope.$index)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                        scope.row.stockQuantity === 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRegisterGoods(
                                      scope.row,
                                      scope.row.buyNum
                                    )
                                  },
                                },
                              },
                              [_vm._v("缺货登记")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "addPro",
              on: {
                click: function ($event) {
                  return _vm.addPro()
                },
              },
            },
            [_vm._v(" +添加 ")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "settlement-container" }, [
        _c("div", { staticClass: "settlement-row" }, [
          _c(
            "div",
            { staticClass: "row-left" },
            [
              _c(
                "el-checkbox",
                {
                  on: {
                    change: function ($event) {
                      return _vm.selectAll(_vm.checkedAll)
                    },
                  },
                  model: {
                    value: _vm.checkedAll,
                    callback: function ($$v) {
                      _vm.checkedAll = $$v
                    },
                    expression: "checkedAll",
                  },
                },
                [_vm._v("全选可购买")]
              ),
              _c(
                "el-checkbox",
                {
                  on: {
                    change: function ($event) {
                      return _vm.selectAllLack(_vm.checkedAllLack)
                    },
                  },
                  model: {
                    value: _vm.checkedAllLack,
                    callback: function ($$v) {
                      _vm.checkedAllLack = $$v
                    },
                    expression: "checkedAllLack",
                  },
                },
                [_vm._v("全选缺货登记")]
              ),
              _c(
                "el-checkbox",
                {
                  on: {
                    change: function ($event) {
                      return _vm.selectAllInterdiction(
                        _vm.checkedAllInterdiction
                      )
                    },
                  },
                  model: {
                    value: _vm.checkedAllInterdiction,
                    callback: function ($$v) {
                      _vm.checkedAllInterdiction = $$v
                    },
                    expression: "checkedAllInterdiction",
                  },
                },
                [_vm._v("全选禁销")]
              ),
              _c("img", {
                staticStyle: {
                  position: "relative",
                  width: "15px",
                  top: "2px",
                  right: "3px",
                },
                attrs: { alt: "", src: require("@/assets/img/icon/clear.png") },
              }),
              _c(
                "span",
                {
                  staticClass: "deleteAll",
                  on: {
                    click: function ($event) {
                      return _vm.toggleSelection()
                    },
                  },
                },
                [_vm._v("清空商品")]
              ),
              _c(
                "span",
                {
                  staticClass: "deleteAll",
                  on: {
                    click: function ($event) {
                      return _vm.saveBatchOutStockRegistration()
                    },
                  },
                },
                [_vm._v("批量缺货登记")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "row-right" }, [
            _c("div", { staticClass: "settlement" }, [
              _c("div", { staticClass: "total-money" }, [
                _vm._v(" 商品总金额： "),
                _c("span", { staticClass: "money" }, [
                  _vm._v("¥" + _vm._s(_vm.$util.toFixed(_vm.allPrices))),
                ]),
              ]),
              _c("div", { staticClass: "product-detail" }, [
                _c("span", [
                  _vm._v(
                    "商品种类：" + _vm._s(_vm.multipleSelection.length) + "种"
                  ),
                ]),
                _c("span", [_vm._v("总数量：" + _vm._s(_vm.allCounts) + "个")]),
              ]),
            ]),
            _vm.isLicenseOutOfTime
              ? _c(
                  "div",
                  { staticClass: "table-operation" },
                  [
                    _c("el-button", { attrs: { disabled: "" } }, [
                      _vm._v("证照过期"),
                    ]),
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    staticClass: "settlement-button-group",
                    attrs: { loading: _vm.loading },
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "shopping-car",
                        attrs: { disabled: _vm.isBanAddToCart },
                        on: { click: _vm.checkExpDate },
                      },
                      [_vm._v("加入购物车")]
                    ),
                  ]
                ),
          ]),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, width: "520px" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "agreementDialog",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v("Excel导入采购计划")]
          ),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.formRules,
                "label-width": "128px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "matchingMethod", label: "选择匹配方式：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: { "line-height": "30px" },
                      model: {
                        value: _vm.form.matchingMethod,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "matchingMethod", $$v)
                        },
                        expression: "form.matchingMethod",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        { attrs: { label: "NM_SPEC_MANUFACTURER" } },
                        [_vm._v("品名+规格+厂家")]
                      ),
                      _c("el-radio", { attrs: { label: "APPROVAL_NUMBER" } }, [
                        _vm._v("国药准字"),
                      ]),
                      _c("el-radio", { attrs: { label: "ERP_CODE" } }, [
                        _vm._v("ERP商品编码"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "下载Excel模板：" } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.matchingMethod[_vm.form.matchingMethod])),
                  ]),
                  _c(
                    "el-button",
                    {
                      staticClass: "downExcelBt",
                      attrs: { size: "mini" },
                      on: { click: _vm.downExcel },
                    },
                    [_vm._v("下载模板")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "upload", label: "上传文件：" } },
                [
                  _c("input", {
                    ref: "uploadExcel",
                    staticStyle: { display: "none" },
                    attrs: {
                      id: "uploadExcel",
                      type: "file",
                      accept:
                        ".csv,.xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.importUp()
                      },
                    },
                  }),
                  _vm.isUploading
                    ? _c("el-progress", {
                        staticClass: "progress",
                        attrs: {
                          "stroke-width": 20,
                          percentage: _vm.uploadProgressLen,
                          status: _vm.uploadStatus,
                        },
                      })
                    : _vm._e(),
                  !_vm.isUploading
                    ? _c(
                        "el-button",
                        {
                          staticClass: "upExcelBt",
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.upload },
                        },
                        [_vm._v("上传Excel文件")]
                      )
                    : _vm._e(),
                  _c("p", { staticStyle: { "font-size": "12px" } }, [
                    _vm._v("为避免导入失败，请下载最新模板后再导入"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogSuccess, width: "400px" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSuccess = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "agreementDialog",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v("导入提示")]
          ),
          _c("div", { staticClass: "importDiv" }, [
            _c("img", {
              staticClass: "importImg",
              attrs: { src: _vm.importDialog.imgSrc, alt: "" },
            }),
            _vm._v(" " + _vm._s(_vm.importDialog.title) + " "),
          ]),
          this.errorList
            ? _c("span", [_vm._v(_vm._s(_vm.importDialog.text))])
            : _vm._e(),
          this.errorList &&
          this.errorList.length > 0 &&
          this.form.matchingMethod == "NM_SPEC_MANUFACTURER"
            ? _c(
                "table",
                { staticClass: "el-table el-table--border" },
                [
                  _c(
                    "thead",
                    {
                      staticClass:
                        "el-table_1_column_2 is-center el-table__cell",
                    },
                    [
                      _c(
                        "td",
                        {
                          staticClass:
                            "el-table_1_column_2 is-center el-table__cell",
                        },
                        [_vm._v("商品名称")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass:
                            "el-table_1_column_2 is-center el-table__cell",
                        },
                        [_vm._v("单位")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass:
                            "el-table_1_column_2 is-center el-table__cell",
                        },
                        [_vm._v("采购数量")]
                      ),
                    ]
                  ),
                  _vm._l(this.errorList, function (item, i) {
                    return _c("tr", { staticClass: "el-table__row" }, [
                      _c(
                        "td",
                        {
                          staticClass:
                            "el-table_1_column_2 is-center el-table__cell",
                        },
                        [_vm._v(_vm._s(item.productNm))]
                      ),
                      _c(
                        "td",
                        {
                          staticClass:
                            "el-table_1_column_2 is-center el-table__cell",
                        },
                        [_vm._v(_vm._s(item.spec))]
                      ),
                      _c(
                        "td",
                        {
                          staticClass:
                            "el-table_1_column_2 is-center el-table__cell",
                        },
                        [_vm._v(_vm._s(item.buyNm))]
                      ),
                    ])
                  }),
                ],
                2
              )
            : _vm._e(),
          this.errorList &&
          this.errorList.length > 0 &&
          this.form.matchingMethod == "ERP_CODE"
            ? _c(
                "table",
                { staticClass: "el-table el-table--border" },
                [
                  _c(
                    "thead",
                    {
                      staticClass:
                        "el-table_1_column_2 is-center el-table__cell",
                    },
                    [
                      _c(
                        "td",
                        {
                          staticClass:
                            "el-table_1_column_2 is-center el-table__cell",
                        },
                        [_vm._v("商品ERP编码")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass:
                            "el-table_1_column_2 is-center el-table__cell",
                        },
                        [_vm._v("采购数量")]
                      ),
                    ]
                  ),
                  _vm._l(this.errorList, function (item, i) {
                    return _c("tr", { staticClass: "el-table__row" }, [
                      _c(
                        "td",
                        {
                          staticClass:
                            "el-table_1_column_2 is-center el-table__cell",
                        },
                        [_vm._v(_vm._s(item.erpCode))]
                      ),
                      _c(
                        "td",
                        {
                          staticClass:
                            "el-table_1_column_2 is-center el-table__cell",
                        },
                        [_vm._v(_vm._s(item.buyNm))]
                      ),
                    ])
                  }),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
      _vm.isShowPHXQ
        ? _c("pi-hao-xiao-qi", {
            attrs: { visible: _vm.isShowPHXQ, rowData: _vm.rowData },
            on: {
              close: function ($event) {
                _vm.isShowPHXQ = false
              },
            },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "以下是近效期品种",
            visible: _vm.expDateVisible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.expDateVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              ref: "expDateTable",
              attrs: { border: "", data: _vm.submitOrderData },
            },
            [
              _c("el-table-column", {
                attrs: { label: "商品编码", prop: "productCode", width: "90" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "商品名称",
                  prop: "productNm",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.$util.getGoodsName(scope.row)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "规格", prop: "spec", "min-width": "80" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "生产厂家",
                  prop: "manufacturer",
                  "min-width": "140",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "最近效期",
                  prop: "expDate",
                  "min-width": "100",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.expDateVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCollateOrder(1)
                    },
                  },
                },
                [_vm._v("继续下单")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("Search", { attrs: { "index-nav": false } }),
      _c(
        "div",
        { staticClass: "licence-banner" },
        [
          _c(
            "el-carousel",
            { attrs: { interval: 5000, height: "110px", arrow: "never" } },
            _vm._l(_vm.licence.PC_LICENCE, function (item, index) {
              return _c("el-carousel-item", { key: index }, [
                _vm.licence.PC_LICENCE.length
                  ? _c("img", {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: { src: item.sysFileUrl, alt: "" },
                      on: {
                        click: function ($event) {
                          return _vm.bindUrl(item.link)
                        },
                      },
                    })
                  : _vm._e(),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-container" }, [
        _vm.page.PC_BANNER_LEFT.length
          ? _c("div", {
              staticClass: "img",
              style: {
                backgroundImage: !_vm.page.PC_BANNER_LEFT.length
                  ? "none"
                  : "url(" + _vm.page.PC_BANNER_LEFT[0].sysFileUrl + ")",
              },
              on: {
                click: function ($event) {
                  return _vm.bindUrl(_vm.page.PC_BANNER_LEFT[0].link)
                },
              },
            })
          : _vm._e(),
        _c("div", { staticClass: "nav-body" }, [
          _c("div", { staticClass: "nav-content" }, [
            _c(
              "div",
              { staticClass: "nav-carousel" },
              [
                _c(
                  "el-carousel",
                  {
                    attrs: { interval: 5000, arrow: "always", height: "270px" },
                  },
                  _vm._l(_vm.page.PC_BANNER, function (item, index) {
                    return _c("el-carousel-item", { key: index }, [
                      _vm.page.PC_BANNER.length
                        ? _c("img", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: { src: item.sysFileUrl, alt: "" },
                            on: {
                              click: function ($event) {
                                return _vm.bindUrl(item.link)
                              },
                            },
                          })
                        : _vm._e(),
                    ])
                  }),
                  1
                ),
                _c("div", { staticClass: "nav-show" }, [
                  _vm.page.PC_BANNER_F1.length
                    ? _c("img", {
                        staticClass: "nav-show-left",
                        attrs: {
                          src: _vm.page.PC_BANNER_F1[0].sysFileUrl,
                          alt: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.bindUrl(_vm.page.PC_BANNER_F1[0].link)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.page.PC_BANNER_F2.length
                    ? _c("img", {
                        staticClass: "nav-show-right",
                        attrs: {
                          src: _vm.page.PC_BANNER_F2[0].sysFileUrl,
                          alt: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.bindUrl(_vm.page.PC_BANNER_F2[0].link)
                          },
                        },
                      })
                    : _vm._e(),
                ]),
              ],
              1
            ),
            _vm.buyersVo.buyersNm
              ? _c("div", { staticClass: "nav-person" }, [
                  _c("div", { staticClass: "nav-person-img2" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/nav/person2.png"),
                        alt: "",
                      },
                    }),
                    _c("div", [
                      _c("p", [_vm._v("Hi，" + _vm._s(_vm.buyersVo.buyersNm))]),
                      _vm.userDetail.memberDetailsVo.isCredit === "Y"
                        ? _c("p", { staticClass: "last" }, [
                            _vm._v(
                              " 可用额度：¥" +
                                _vm._s(
                                  _vm.userDetail.memberDetailsVo
                                    .availableCredit || 0
                                )
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm.userDetail.orderStateVos
                    ? _c("div", { staticClass: "nav-person-list" }, [
                        _c(
                          "div",
                          {
                            staticClass: "list-button",
                            on: {
                              click: function ($event) {
                                return _vm.jumpOrder("WAIT_PAY")
                              },
                            },
                          },
                          [
                            _c("p", { staticClass: "num" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.userDetail.orderStateVos.toBePaid || 0
                                )
                              ),
                            ]),
                            _c("p", { staticClass: "text" }, [
                              _vm._v("待付款"),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "list-button",
                            on: {
                              click: function ($event) {
                                return _vm.jumpOrder("WAIT_SEND")
                              },
                            },
                          },
                          [
                            _c("p", { staticClass: "num" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.userDetail.orderStateVos.toBeDelivered ||
                                    0
                                )
                              ),
                            ]),
                            _c("p", { staticClass: "text" }, [
                              _vm._v("待发货"),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "list-button",
                            on: {
                              click: function ($event) {
                                return _vm.jumpOrder("SEND")
                              },
                            },
                          },
                          [
                            _c("p", { staticClass: "num" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.userDetail.orderStateVos.toBeReceived || 0
                                )
                              ),
                            ]),
                            _c("p", { staticClass: "text" }, [
                              _vm._v("待收货"),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "list-button",
                            on: {
                              click: function ($event) {
                                return _vm.jumpOrder("WAIT_APPROVE")
                              },
                            },
                          },
                          [
                            _c("p", { staticClass: "num" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.userDetail.orderStateVos.toBConfirmed || 0
                                )
                              ),
                            ]),
                            _c("p", { staticClass: "text" }, [
                              _vm._v("待确认"),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ])
              : _c("div", { staticClass: "nav-person" }, [
                  _c("div", { staticClass: "nav-person-img" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/img/nav/person.png"),
                        alt: "",
                      },
                    }),
                    _c("p", [
                      _vm._v(
                        "Hi，欢迎来到" + _vm._s(_vm.defaultSettings.title)
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "nav-person-button" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/login" } },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", round: "" } },
                            [_vm._v("登录")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "router-link",
                        { staticClass: "last", attrs: { to: "/register" } },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", round: "" } },
                            [_vm._v("注册")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
            _c("div", { staticClass: "nav-notice" }, [
              _vm.page.PC_INFORMATION_TOP_LINK.length
                ? _c("p", { staticClass: "nav-notice-more" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.page.PC_INFORMATION_TOP_LINK[0].title) +
                        " "
                    ),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.infoCheckMore("categoryNm")
                          },
                        },
                      },
                      [_vm._v("更多")]
                    ),
                  ])
                : _vm._e(),
              _vm.page["PC_INFORMATION"] && _vm.page["PC_INFORMATION"].length
                ? _c(
                    "ul",
                    _vm._l(
                      _vm.page["PC_INFORMATION"][0].infArticleVos,
                      function (item, index) {
                        return _c(
                          "li",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: index < 6,
                                expression: "index<6",
                              },
                            ],
                            key: index,
                            on: {
                              click: function ($event) {
                                return _vm.jumpInfo(item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(index + 1) +
                                "、" +
                                _vm._s(item.title) +
                                " "
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
        _vm.page.PC_BANNER_RIGHT.length
          ? _c("div", {
              staticClass: "img img-r",
              style: {
                backgroundImage: !_vm.page.PC_BANNER_RIGHT.length
                  ? "none"
                  : "url(" + _vm.page.PC_BANNER_RIGHT[0].sysFileUrl + ")",
              },
              on: {
                click: function ($event) {
                  return _vm.bindUrl(_vm.page.PC_BANNER_RIGHT[0].link)
                },
              },
            })
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "content", attrs: { id: "content" } },
        [
          _vm.flashSaleList.length
            ? _c("div", { staticClass: "pink-bg bbb scroll-item" }, [
                _c("div", { staticClass: "commodity" }, [
                  _vm.page.PC_SHOP_LINK_TOP_F1.length || _vm.showCountDown
                    ? _c("div", { staticClass: "commodity-head" }, [
                        _c("div", { staticClass: "commodity-text" }, [
                          _c(
                            "div",
                            [
                              _vm.page.PC_SHOP_LINK_TOP_F1.length
                                ? _c(
                                    "span",
                                    { staticClass: "commodity-title" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.page.PC_SHOP_LINK_TOP_F1[0].title
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.page.PC_SHOP_LINK_TOP_F1.length
                                ? _c(
                                    "span",
                                    { staticClass: "commodity-remarks" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.page.PC_SHOP_LINK_TOP_F1[0]
                                            .fitTitle
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.showCountDown
                                ? _c("div", { staticClass: "count-down" }, [
                                    _c(
                                      "span",
                                      { staticClass: "count-down-text" },
                                      [_vm._v("距离结束剩余")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "count-down-num count-down-hour",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.timeList.day.split("")[0])
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "count-down-num count-down-hour",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.timeList.day.split("")[1])
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "count-down-point" },
                                      [_vm._v("天")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "count-down-num count-down-minute",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.timeList.hou.split("")[0])
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "count-down-num count-down-minute",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.timeList.hou.split("")[1])
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "count-down-point" },
                                      [_vm._v(":")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "count-down-num count-down-seconds",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.timeList.min.split("")[0])
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "count-down-num count-down-seconds",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.timeList.min.split("")[1])
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "count-down-point" },
                                      [_vm._v(":")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "count-down-num count-down-seconds",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.timeList.sec.split("")[0])
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "count-down-num count-down-seconds",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.timeList.sec.split("")[1])
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "router-link",
                                { attrs: { to: "/discount-time" } },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "commodity-more" },
                                    [
                                      _vm._v("更多"),
                                      _c("i", {
                                        staticClass: "el-icon-arrow-right",
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "commodity-content" },
                    [
                      _c("div", {
                        staticClass:
                          "commodity-explain commodity-explain-width",
                        style: {
                          backgroundImage: !_vm.page.PC_SHOP_ADV_F1.length
                            ? "none"
                            : "url(" +
                              _vm.page.PC_SHOP_ADV_F1[0].sysFileUrl +
                              ")",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.bindUrl(_vm.page.PC_SHOP_ADV_F1[0].link)
                          },
                        },
                      }),
                      _vm._l(_vm.flashSaleList, function (item, index) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: index < 5,
                                expression: "index < 5",
                              },
                            ],
                            key: index,
                            staticClass: "commodity-item",
                          },
                          [
                            _vm.userType == "SALESMAN"
                              ? _c("div", { staticClass: "identifying" }, [
                                  item.whiteRecordFlag == 1
                                    ? _c("img", {
                                        attrs: {
                                          alt: "",
                                          src: require("@/assets/img/icon/whiterecord.png"),
                                        },
                                      })
                                    : item.blackRecordFlag == 1
                                    ? _c("img", {
                                        attrs: {
                                          alt: "",
                                          src: require("@/assets/img/icon/blackrecord.png"),
                                        },
                                      })
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "commodity-item-img" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: { path: "/detail/" + item.productId },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        alt: "",
                                        src:
                                          item.prcUrl144 ||
                                          item.pictUrls ||
                                          item.picUrl ||
                                          _vm.productDefaultImg,
                                      },
                                      on: {
                                        error: function ($event) {
                                          item.pictUrls = _vm.productDefaultImg
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "commodity-item-info" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: { path: "/detail/" + item.productId },
                                    },
                                  },
                                  [
                                    _c("p", {
                                      staticClass: "commodity-item-name",
                                    }),
                                    _c(
                                      "p",
                                      { staticClass: "commodity-item-name" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$util.getGoodsName(item)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "commodity-item-detail" },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(item.spec) +
                                          "/" +
                                          _vm._s(item.unit)
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(_vm._s(item.manufacturer)),
                                    ]),
                                  ]
                                ),
                                item.visible && item.priceStr !== "登录可见"
                                  ? _c("div", [
                                      item.price == 0
                                        ? _c("div", [
                                            _c(
                                              "p",
                                              {
                                                staticClass: "commodity-price",
                                              },
                                              [_vm._v("未报价")]
                                            ),
                                          ])
                                        : _c("div", [
                                            _c(
                                              "p",
                                              {
                                                staticClass: "commodity-price",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$util.getPriceByZero(
                                                      item.price,
                                                      item
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "commodity-price commodity-price-sub",
                                              },
                                              [
                                                _vm._v(
                                                  "¥" + _vm._s(item.salePrice)
                                                ),
                                              ]
                                            ),
                                          ]),
                                    ])
                                  : _c("div", [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "commodity-price",
                                          staticStyle: {
                                            "margin-bottom": "25px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.showPrice({
                                                priceStr: "登录可见",
                                              })
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.page.PC_SHOP_PRODUCT_F2.length
            ? _c("div", { staticClass: "offer aaa scroll-item" }, [
                _c("div", { staticClass: "commodity" }, [
                  _vm.page.PC_SHOP_LINK_TOP_F2.length
                    ? _c("div", { staticClass: "commodity-head" }, [
                        _c("div", { staticClass: "commodity-text" }, [
                          _vm.page.PC_SHOP_LINK_TOP_F2.length
                            ? _c("div", [
                                _c("span", { staticClass: "commodity-title" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.page.PC_SHOP_LINK_TOP_F2[0].title
                                    )
                                  ),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "commodity-remarks" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.page.PC_SHOP_LINK_TOP_F2[0].fitTitle
                                      )
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.page.PC_SHOP_ADV_F2.length > 0 ||
                  _vm.page.PC_SHOP_PRODUCT_F2.length > 0
                    ? _c(
                        "div",
                        { staticClass: "commodity-content" },
                        [
                          _c("div", {
                            staticClass:
                              "commodity-explain commodity-explain-width",
                            style: {
                              backgroundImage: !_vm.page.PC_SHOP_ADV_F2.length
                                ? "none"
                                : "url(" +
                                  _vm.page.PC_SHOP_ADV_F2[0].sysFileUrl +
                                  ")",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.bindUrl(
                                  _vm.page.PC_SHOP_ADV_F2[0].link
                                )
                              },
                            },
                          }),
                          _vm._m(0),
                          _vm._l(
                            _vm.page.PC_SHOP_PRODUCT_F2,
                            function (productList, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "commodity-item" },
                                [
                                  _vm.userType == "SALESMAN"
                                    ? _c(
                                        "div",
                                        { staticClass: "identifying" },
                                        [
                                          productList.whiteRecordFlag == 1
                                            ? _c("img", {
                                                attrs: {
                                                  alt: "",
                                                  src: require("@/assets/img/icon/whiterecord.png"),
                                                },
                                              })
                                            : productList.blackRecordFlag == 1
                                            ? _c("img", {
                                                attrs: {
                                                  alt: "",
                                                  src: require("@/assets/img/icon/blackrecord.png"),
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "commodity-item-img" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              path:
                                                "/detail/" +
                                                productList.productId,
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              alt: "",
                                              src:
                                                productList.productVo
                                                  .pictUrl144 ||
                                                productList.productVo
                                                  .pictUrls ||
                                                _vm.productDefaultImg,
                                            },
                                            on: {
                                              error: function ($event) {
                                                productList.productVo.pictUrls =
                                                  _vm.productDefaultImg
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "commodity-item-info" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              path:
                                                "/detail/" +
                                                productList.productId,
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "commodity-item-name",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$util.getGoodsName(
                                                      productList.productVo
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "commodity-item-detail",
                                        },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                productList.productVo.spec
                                              ) +
                                                "/" +
                                                _vm._s(
                                                  productList.productVo.unit
                                                )
                                            ),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                productList.productVo
                                                  .manufacturer
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c("div", [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "commodity-price",
                                            staticStyle: { margin: "15px 0 0" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$util.getPriceByZero(
                                                  productList.productVo.price,
                                                  productList.productVo
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        productList.productVo.isDiscount === "Y"
                                          ? _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "commodity-price commodity-price-sub",
                                                staticStyle: {
                                                  margin: "5px 0 0",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "¥" +
                                                    _vm._s(
                                                      productList.productVo
                                                        .salePrice
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm.page.PC_SHOP_LINK_TOP_F3.length && _vm.couponItems.length
            ? _c("div", { staticClass: "coupon commodity scroll-item" }, [
                _vm.page.PC_SHOP_LINK_TOP_F3.length
                  ? _c("div", { staticClass: "commodity-head" }, [
                      _c("div", { staticClass: "commodity-text" }, [
                        _vm.page.PC_SHOP_LINK_TOP_F3.length
                          ? _c("div", [
                              _c("span", { staticClass: "commodity-title" }, [
                                _vm._v(
                                  _vm._s(_vm.page.PC_SHOP_LINK_TOP_F3[0].title)
                                ),
                              ]),
                              _c("span", { staticClass: "commodity-remarks" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.page.PC_SHOP_LINK_TOP_F3[0].fitTitle
                                  )
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
                _vm.page.PC_SHOP_ADV_F3.length > 0 || _vm.couponItems.length > 0
                  ? _c("div", { staticClass: "commodity-content" }, [
                      _c("div", {
                        staticClass: "commodity-explain",
                        style: {
                          backgroundImage: !_vm.page.PC_SHOP_ADV_F3.length
                            ? "none"
                            : "url(" +
                              _vm.page.PC_SHOP_ADV_F3[0].sysFileUrl +
                              ")",
                        },
                        on: { click: _vm.couponCheckMore },
                      }),
                      _c(
                        "div",
                        { staticClass: "coupon-list" },
                        _vm._l(_vm.couponItems, function (item, index) {
                          return _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: index < 3,
                                  expression: "index < 3",
                                },
                              ],
                              key: index,
                              staticClass: "coupon-item",
                            },
                            [
                              item.thresholdType === "NONE"
                                ? _c(
                                    "p",
                                    { staticClass: "coupon-condition" },
                                    [
                                      _vm._v(" 无使用门槛 "),
                                      item.illustrate
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content: item.illustrate,
                                                placement: "top-start",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-question",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _c(
                                    "p",
                                    { staticClass: "coupon-condition" },
                                    [
                                      _vm._v(
                                        " 满" +
                                          _vm._s(item.fullAmount) +
                                          "元可用 "
                                      ),
                                      item.illustrate
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content: item.illustrate,
                                                placement: "top-start",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-question",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                              item.couponType === "FULL_REDUCTION"
                                ? _c("div", [
                                    _c("p", { staticClass: "coupon-price" }, [
                                      _c("span", [_vm._v("¥")]),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(item.fullReductionAmount)
                                        ),
                                      ]),
                                    ]),
                                    _c("p", { staticClass: "coupon-user" }, [
                                      _vm._v(_vm._s(item.couponNm)),
                                    ]),
                                  ])
                                : _c("div", [
                                    _c("p", { staticClass: "coupon-price" }, [
                                      _c("b", [_vm._v(_vm._s(item.discount))]),
                                      _c("span", [_vm._v("折")]),
                                    ]),
                                    _c("p", { staticClass: "coupon-user" }, [
                                      _vm._v(_vm._s(item.couponNm)),
                                    ]),
                                  ]),
                              item.useTimeType === "IN_THE_DAY"
                                ? _c("p", { staticClass: "coupon-time" }, [
                                    _vm._v(
                                      "领券当日起" +
                                        _vm._s(item.useValidDay) +
                                        "天内可用"
                                    ),
                                  ])
                                : item.useTimeType === "TIMES_OF_THE_DAY"
                                ? _c("p", { staticClass: "coupon-time" }, [
                                    _vm._v(
                                      "领券次日起" +
                                        _vm._s(item.useValidDay) +
                                        "天内可用"
                                    ),
                                  ])
                                : _c("p", { staticClass: "coupon-time" }, [
                                    _vm._v(
                                      _vm._s(item.useStartTime) +
                                        " - " +
                                        _vm._s(item.useEndTime)
                                    ),
                                  ]),
                              item.isLimitTimes === "Y" &&
                              item.userReceivedQuantity < item.limitNumber &&
                              item.remainQuantity > 0 &&
                              item.userReceivedQuantity < item.remainQuantity
                                ? _c(
                                    "p",
                                    {
                                      staticClass: "coupon-button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onCoupon(item)
                                        },
                                      },
                                    },
                                    [_vm._v("立即领取")]
                                  )
                                : _vm._e(),
                              item.isLimitTimes === "N" &&
                              item.remainQuantity > 0
                                ? _c(
                                    "p",
                                    {
                                      staticClass: "coupon-button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onCoupon(item)
                                        },
                                      },
                                    },
                                    [_vm._v("立即领取")]
                                  )
                                : item.userReceivedQuantity >=
                                    item.limitNumber &&
                                  item.userReceivedQuantity > 0
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "coupon-button coupon-disabled",
                                    },
                                    [_vm._v("已领取")]
                                  )
                                : item.remainQuantity <= 0 &&
                                  item.userReceivedQuantity <= 0
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "coupon-button coupon-disabled",
                                    },
                                    [_vm._v("已抢光")]
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.page.PC_SHOP_LINK_TOP_F4.length &&
          (_vm.page.PC_SHOP_ADV_F4.length || _vm.productItems.length)
            ? _c(
                "div",
                { staticClass: "recommend commodity scroll-item" },
                [
                  _vm.page.PC_SHOP_LINK_TOP_F4.length
                    ? _c("div", { staticClass: "commodity-head" }, [
                        _c("div", { staticClass: "commodity-text" }, [
                          _vm.page.PC_SHOP_LINK_TOP_F4.length
                            ? _c("div", [
                                _c("span", { staticClass: "commodity-title" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.page.PC_SHOP_LINK_TOP_F4[0].title
                                    )
                                  ),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "commodity-remarks" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.page.PC_SHOP_LINK_TOP_F4[0].fitTitle
                                      )
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.page.PC_SHOP_ADV_F4, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "recommend-img" },
                      [
                        _vm.page.PC_SHOP_ADV_F4.length
                          ? _c("img", {
                              attrs: { src: item.sysFileUrl, alt: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.bindUrl(item.link)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.page.PC_SHOP_ADV_F5.length
            ? _c("div", { staticClass: "advertisement" }, [
                _c(
                  "div",
                  { staticClass: "advertisement-content" },
                  _vm._l(_vm.page.PC_SHOP_ADV_F5, function (item, index) {
                    return item.sysFileUrl
                      ? _c(
                          "div",
                          { key: index, staticClass: "advertisement-img" },
                          [
                            _c("img", {
                              attrs: { src: item.sysFileUrl, alt: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.bindUrl(item.link)
                                },
                              },
                            }),
                          ]
                        )
                      : _vm._e()
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm._l(_vm.productItems, function (item, index) {
            return _vm.page["PC_SHOP_PRODUCT_F" + (index + 6)].length
              ? _c("div", { key: index, staticClass: "product scroll-item" }, [
                  _c("div", { staticClass: "product-top" }, [
                    _c(
                      "div",
                      {
                        staticClass: "product-top-left",
                        style: { background: item.color },
                      },
                      [
                        _vm.page["PC_SHOP_LINK_F" + (index + 6) + "_1"].length
                          ? _c("div", { staticClass: "product-top-title" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.page[
                                      "PC_SHOP_LINK_F" + (index + 6) + "_1"
                                    ][0].title
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        _vm.page["PC_SHOP_LINK_F" + (index + 6) + "_2"].length
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.page["PC_SHOP_LINK_F" + (index + 6) + "_2"],
                                function (t, i) {
                                  return _c(
                                    "router-link",
                                    {
                                      key: i,
                                      staticClass: "product-top-label",
                                      attrs: { to: t.titleLink },
                                    },
                                    [_vm._v(_vm._s(t.title) + " ")]
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm.page["PC_SHOP_LINK_F" + (index + 6) + "_1"].length ||
                    _vm.page["PC_SHOP_LINK_F" + (index + 6) + "_2"].length ||
                    _vm.page["PC_SHOP_ADV_F" + (index + 6) + "_3"].length ||
                    _vm.page["PC_SHOP_ADV_F" + (index + 6) + "_4"].length
                      ? _c("div", { staticClass: "product-top-left-bg" }, [
                          _c("img", { attrs: { alt: "", src: item.bg } }),
                        ])
                      : _vm._e(),
                    _vm.page["PC_SHOP_ADV_F" + (index + 6) + "_3"].length
                      ? _c("img", {
                          staticClass: "img-left",
                          attrs: {
                            alt: "",
                            src: _vm.page[
                              "PC_SHOP_ADV_F" + (index + 6) + "_3"
                            ][0].sysFileUrl,
                          },
                          on: {
                            click: function ($event) {
                              _vm.bindUrl(
                                _vm.page[
                                  "PC_SHOP_ADV_F" + (index + 6) + "_3"
                                ][0].link
                              )
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.page["PC_SHOP_ADV_F" + (index + 6) + "_4"].length
                      ? _c("img", {
                          staticClass: "img-right",
                          attrs: {
                            alt: "",
                            src: _vm.page[
                              "PC_SHOP_ADV_F" + (index + 6) + "_4"
                            ][0].sysFileUrl,
                          },
                          on: {
                            click: function ($event) {
                              _vm.bindUrl(
                                _vm.page[
                                  "PC_SHOP_ADV_F" + (index + 6) + "_4"
                                ][0].link
                              )
                            },
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm.page["PC_SHOP_PRODUCT_F" + (index + 6)].length
                    ? _c(
                        "div",
                        { staticClass: "product-list" },
                        _vm._l(
                          _vm.page["PC_SHOP_PRODUCT_F" + (index + 6)],
                          function (productList, i) {
                            return _c(
                              "div",
                              { key: i, staticClass: "product-item" },
                              [
                                _vm.userType == "SALESMAN"
                                  ? _c("div", { staticClass: "identifying" }, [
                                      productList.whiteRecordFlag == 1
                                        ? _c("img", {
                                            attrs: {
                                              alt: "",
                                              src: require("@/assets/img/icon/whiterecord.png"),
                                            },
                                          })
                                        : productList.blackRecordFlag == 1
                                        ? _c("img", {
                                            attrs: {
                                              alt: "",
                                              src: require("@/assets/img/icon/blackrecord.png"),
                                            },
                                          })
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "product-item-img" },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            path:
                                              "/detail/" +
                                              productList.productId,
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            alt: "",
                                            src:
                                              productList.productVo.pictUrls ||
                                              _vm.productDefaultImg,
                                          },
                                          on: {
                                            error: function ($event) {
                                              productList.productVo.pictUrls =
                                                _vm.productDefaultImg
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "product-item-info" },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            path:
                                              "/detail/" +
                                              productList.productId,
                                          },
                                        },
                                      },
                                      [
                                        _c("p", { staticClass: "name" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$util.getGoodsName(
                                                  productList.productVo
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "product-item-detail" },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(productList.productVo.spec) +
                                              "/" +
                                              _vm._s(
                                                productList.productVo.unit
                                              ) +
                                              _vm._s(
                                                productList.whiteRecordFlag
                                              )
                                          ),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              productList.productVo.manufacturer
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c("p", { staticClass: "product-price" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.showPrice(productList.productVo)
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                  _vm.page["PC_BRAND_F" + (index + 1)].length
                    ? _c(
                        "div",
                        { staticClass: "product-logo" },
                        _vm._l(
                          _vm.page["PC_BRAND_F" + (index + 1)],
                          function (logo, i) {
                            return _c(
                              "div",
                              { key: i, staticClass: "img" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        path: "/brand-detail/" + logo.brandId,
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "product-logo-img",
                                      attrs: {
                                        alt: "",
                                        src:
                                          logo.brandLogo || _vm.brandDefaultImg,
                                      },
                                      on: {
                                        error: function ($event) {
                                          logo.brandLogo = _vm.brandDefaultImg
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ])
              : _vm._e()
          }),
          _vm.showIndex
            ? _c("div", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.showIndex,
                    expression: "showIndex",
                  },
                ],
                staticClass: "show_loading",
              })
            : _vm._e(),
          _vm.page.PC_SHOP_ADV_F11.length
            ? _c("div", { staticClass: "footer-banner" }, [
                _vm.page.PC_SHOP_ADV_F11.length
                  ? _c("img", {
                      attrs: {
                        src: _vm.page.PC_SHOP_ADV_F11[0].sysFileUrl,
                        alt: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.bindUrl(_vm.page.PC_SHOP_ADV_F11[0].link)
                        },
                      },
                    })
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.page.PC_SHOP_LINK_TOP_F5.length
            ? _c("div", { staticClass: "information commodity" }, [
                _vm.page.PC_SHOP_LINK_TOP_F5.length
                  ? _c("div", { staticClass: "commodity-head" }, [
                      _c("div", { staticClass: "commodity-text" }, [
                        _vm.page.PC_SHOP_LINK_TOP_F5.length
                          ? _c("div", [
                              _c("span", { staticClass: "commodity-title" }, [
                                _vm._v(
                                  _vm._s(_vm.page.PC_SHOP_LINK_TOP_F5[0].title)
                                ),
                              ]),
                              _c("span", { staticClass: "commodity-remarks" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.page.PC_SHOP_LINK_TOP_F5[0].fitTitle
                                  )
                                ),
                              ]),
                              _vm._m(1),
                            ])
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "item" },
                  _vm._l(3, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "item-lt",
                        class: { active: _vm.noticeActive[index] },
                        on: {
                          mouseover: function ($event) {
                            return _vm.selectStyle(index)
                          },
                        },
                      },
                      [
                        _c("transition", { attrs: { name: "fade" } }, [
                          _c(
                            "div",
                            { staticClass: "item-lt-content item-lt-bg" },
                            [
                              _c("div", { staticClass: "item-list" }, [
                                _vm.page["PC_BOTTOM_LINK_" + (index + 1)].length
                                  ? _c("div", [
                                      _c("div", { staticClass: "list-name" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.page[
                                              "PC_BOTTOM_LINK_" + (index + 1)
                                            ][0].title
                                          )
                                        ),
                                      ]),
                                      _c("div", { staticClass: "list-line" }),
                                      _c(
                                        "div",
                                        { staticClass: "list-name-english" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.page[
                                                "PC_BOTTOM_LINK_" + (index + 1)
                                              ][0].fitTitle
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "more",
                                          on: {
                                            click: function ($event) {
                                              _vm.infoCheckMore(
                                                _vm.page[
                                                  "PC_BOTTOM_LINK_" +
                                                    (index + 1)
                                                ][0].title
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("查看更多")]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c("transition", [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.noticeActive[index],
                                        expression: "noticeActive[index]",
                                      },
                                    ],
                                    staticClass: "item-notice",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "notice-content" },
                                      [
                                        _vm.page[
                                          "PC_BOTTOM_INFORMATION_B" +
                                            (index + 1)
                                        ] &&
                                        _vm.page[
                                          "PC_BOTTOM_INFORMATION_B" +
                                            (index + 1)
                                        ].length
                                          ? _c(
                                              "ul",
                                              _vm._l(
                                                _vm.page[
                                                  "PC_BOTTOM_INFORMATION_B" +
                                                    (index + 1)
                                                ][0].infArticleVos,
                                                function (c, i) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: i < 6,
                                                          expression: "i<6",
                                                        },
                                                      ],
                                                      key: i,
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.jumpInfo(c)
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(_vm._s(c.title)),
                                                      ]),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "notice-time",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              c.createTime
                                                                ? c.createTime.substring(
                                                                    0,
                                                                    10
                                                                  )
                                                                : ""
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm.page.PC_BOTTOM_LINK_B1.length
            ? _c("div", { staticClass: "link" }, [
                _c("div", { staticClass: "title" }, [_vm._v("友情链接")]),
                _vm.page.PC_BOTTOM_LINK_B1.length
                  ? _c(
                      "div",
                      { staticClass: "link-list" },
                      _vm._l(
                        _vm.page.PC_BOTTOM_LINK_B1,
                        function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "link-item" },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: item.titleLink,
                                    target: "_blank",
                                    title: item.title,
                                  },
                                },
                                [_vm._v(_vm._s(item.title))]
                              ),
                              _c("i"),
                            ]
                          )
                        }
                      ),
                      0
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.toolbar.length
            ? _c(
                "div",
                {
                  staticClass: "left-toolbar",
                  class: { "scroll-fixed": _vm.toolbarFixed },
                },
                [
                  _c("div", { staticClass: "toolbar-tabs" }, [
                    _c(
                      "div",
                      { staticClass: "toolbar-bd", attrs: { id: "toolbar" } },
                      [
                        _vm.userType === "BUYERS"
                          ? _c(
                              "div",
                              { staticClass: "toolbar-item" },
                              [
                                _c("div", { staticClass: "arrival-num" }, [
                                  _vm._v(_vm._s(_vm.arrivalGoodsNum)),
                                ]),
                                _c(
                                  "router-link",
                                  { attrs: { to: "/arrival-reminder" } },
                                  [_c("a", [_vm._v("到货提醒")])]
                                ),
                              ],
                              1
                            )
                          : _vm.userType === "SALESMAN"
                          ? _c(
                              "div",
                              {
                                staticClass: "toolbar-item",
                                on: { click: _vm.getAccountId },
                              },
                              [
                                _c("div", { staticClass: "arrival-num" }, [
                                  _vm._v(_vm._s(_vm.arrivalGoodsNum)),
                                ]),
                                _c("a", [_vm._v("到货提醒")]),
                              ]
                            )
                          : _vm._e(),
                        _vm._l(_vm.toolbar, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "toolbar-item",
                              on: {
                                click: function ($event) {
                                  return _vm.toolbarScroll(index)
                                },
                              },
                            },
                            [
                              _c("a", { class: { cur: item.active } }, [
                                _vm._v(_vm._s(item.name)),
                              ]),
                            ]
                          )
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "toolbar-item",
                            on: {
                              click: function ($event) {
                                return _vm.toolbarScroll("top")
                              },
                            },
                          },
                          [_vm._m(2)]
                        ),
                      ],
                      2
                    ),
                    _vm._m(3),
                  ]),
                ]
              )
            : _vm._e(),
          _c("ToolBar"),
          _vm.header.PC_BANNER_ADV_1 && !!_vm.userType
            ? _c("Popup", {
                attrs: { "img-url": _vm.header.PC_BANNER_ADV_1 || [] },
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "commodity-explain-bg" }, [
      _c("img", {
        attrs: { alt: "", src: require("../../assets/img/index/bg-2-2.png") },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "commodity-more" }, [
      _vm._v("更多"),
      _c("i", { staticClass: "el-icon-arrow-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "arrow-font", attrs: { id: "backtop" } }, [
      _c("i", { staticClass: "el-icon-arrow-up" }),
      _vm._v("回到顶部"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "toolbar-ft" }, [
      _c("div", { staticClass: "pic" }, [
        _c("img", {
          staticStyle: { width: "100%" },
          attrs: { alt: "", src: require("@/assets/img/index/logo-right.png") },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
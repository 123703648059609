<template>
    <div class="main" v-loading="isLoading">
        <!--table列表-->
        <el-table ref="table" border :data.sync="tableData" :row-class-name="tableRowClassName"
            header-row-class-name="header-row" :span-method="spanMethod"
            :class="{ 'is-tabledata-empty': tableData.length === 0 }" style="margin-left: 1px;">
            <!--      <el-table-column type="index" align="center" label="序号" fixed="left" width="45"></el-table-column>-->
            <el-table-column prop="code" fixed="left" label="商品编码" align="center" width="110px" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div class="table-product-code">
                        <p>{{ scope.row.productCode }}</p>
                        <span class="history" v-if="scope.row.isHistoryBuyProduct === 'Y'">历史购买</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="商品名称" min-width="120px">
                <template slot-scope="scope">
                    <el-popover placement="right" trigger="hover">
                        <div class="img-content">
                            <el-image :src="scope.row.picUrl || Product" fit="fill" @error="scope.row.picUrl = Product"
                                style="width: 136px; height: 136px" />
                        </div>
                        <div class="table-product-name" slot="reference">
                            <span class="name-tag" v-if="scope.row.otcType && scope.row.otcType !== '其他'"
                                :class="{ 'jiaOtc': scope.row.otcType === '甲OTC', 'yiOtc': scope.row.otcType === '乙OTC', 'rxOtc': scope.row.otcType === 'RX' }">{{ scope.row.otcType }}</span>
                            <span class="product-name" @click="jumpDetail(scope.row.productId)">
                                <p>
                                    {{ $util.getGoodsName(scope.row) }}
                                </p>

                            </span>
                            <!-- 标签(tag)组件 -->
                            <tagList :taglist='scope.row.tagList'></tagList>
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column v-if="userType == 'SALESMAN'" label="标识" min-width="80px" align="center">
                <template slot-scope="scope">
                    <div class="identifying">
                        <img v-if="scope.row.whiteRecordFlag == 1" alt src="@/assets/img/icon/whiterecord.png">
                        <img v-else-if="scope.row.blackRecordFlag == 1" alt src="@/assets/img/icon/blackrecord.png">
                    </div>
                </template>
            </el-table-column>
            <!--      <el-table-column prop="drugNm" align="left" label="通用名称" show-overflow-tooltip-->
            <!--                       width="160px"></el-table-column>-->
            <el-table-column prop="spec" align="center" label="规格" min-width="80px"
                show-overflow-tooltip></el-table-column>

            <el-table-column prop="manufacturer" align="left" label="厂家(上市许可持有人)/产地" width="150px"
                show-overflow-tooltip></el-table-column>
            <el-table-column prop="unit" align="center" label="单位" show-overflow-tooltip width="56px"></el-table-column>

            <el-table-column prop="packTotal" align="center" label="件包装" width="60px"
                show-overflow-tooltip></el-table-column>
            <el-table-column prop="rate" align="center" label="销项税" min-width="30px" key="rate"></el-table-column>
            <el-table-column prop="price" align="right" label="销售价" min-width="60px" sortable show-overflow-tooltip>
                <template slot-scope="scope">
                    <div class="salePrice">{{ $util.getPriceByZero(scope.row.price, scope.row) }}</div>
                    <div style="color: #999999;text-decoration: line-through"
                        v-if="scope.row.isDiscount === 'Y' && $util.isLogin()">{{ scope.row.salePrice ? '￥' +
                        scope.row.salePrice : ''}}</div>
                </template>
            </el-table-column>

            <!--<el-table-column prop="medicarePrice" align="right" label="医保价" min-width="60px">
                <template slot-scope="scope">
                    <span class="medicarePrice">{{ scope.row.medicarePrice }}</span>
                </template>
            </el-table-column>

            <el-table-column prop="retailPrice" align="right" label="零售价" min-width="60px"></el-table-column> -->

            <!-- <el-table-column prop="stockQuantity" align="center" label="库存" min-width="60px" v-if="userType ==='SALESMAN'"></el-table-column>

            <el-table-column prop="stockQuantity" align="center" label="库存" min-width="60px" v-if="userType === 'BUYERS'">
                <template slot-scope="scope">
                    <span v-if="scope.row.stockQuantity && scope.row.stockQuantity>=100">>100</span>
                    <span class="inventory-warning" v-else><100</span>
                </template>
            </el-table-column> -->

            <el-table-column v-if="$store.getters.buyersVo && $store.getters.buyersVo.showStockType != 5" sortable
                prop="stockQuantity" align="center" label="库存" min-width="60px">
                <template slot-scope="scope">
                    <span v-if="$store.getters.buyersVo.showStockType == 4">
                        {{ scope.row.stockQuantity >= 100 ? '>100' : '<100' }} </span>

                            <span v-else-if="$store.getters.buyersVo.showStockType == 3">
                                {{ scope.row.stockQuantity > 0 ? '有' : '无' }}
                            </span>
                            <span
                                :class="scope.row.stockQuantity && scope.row.stockQuantity >= 100 ? '' : 'inventory-warning'"
                                v-else-if="$store.getters.buyersVo.showStockType == 2">
                                {{ scope.row.stockQuantity }}
                            </span>
                </template>
            </el-table-column>


            <el-table-column prop="expDate" align="center" label="最近效期" min-width="80px" sortable show-overflow-tooltip>

            </el-table-column>


            <el-table-column align="center" label="采购数量" width="106px">
                <template slot-scope="scope">
                    <el-input-number size="mini" v-model="scope.row.count" :precision="getDecimalPointNum(scope.row)"
                        step-strictly :step="getRealMinBuyNum(scope.row)" :min="getRealMinBuyNum(scope.row)" />
                </template>
            </el-table-column>

            <el-table-column align="center" width="120" label="操作">
                <template slot-scope="scope">
                    <div class="table-operation" v-if="isLicenseOutOfTime">
                        <el-button size="mini" :disabled='true'>证照过期</el-button>
                    </div>
                    <div class="table-operation" v-else>
                        <!--                        <span class="sell-out" v-if="scope.row.stockQuantity === 0">已售馨</span>-->
                        <el-button style="width: 100%" size="mini" type="info" v-if="scope.row.stockQuantity === 0"
                            @click="handleRegisterGoods(scope.row, scope.row.count)">缺货登记</el-button>
                        <el-button class="add-shopping-cart" size="mini" :disabled="isBanAddToCart"
                            @click="beforeAddItem(scope.row.skuId, 'NORMAL', scope.row.count, scope.row)"
                            :loading='scope.row.loading' v-else>加入购物车
                        </el-button>
                    </div>

                </template>
            </el-table-column>
        </el-table>
        <empty-box style="padding:16px" v-if="tableData.length === 0 && !isLoading" />
    </div>
</template>

<script>
import tagList from '@/components/tagList/tagList'
import Product from '@/assets/img/index/product_default.png'
import emptyBox from '@/components/tableEmptyDefault'
import { mapGetters } from 'vuex'
import addToCartMixin from '@/common/addToCart'

export default {
    name: 'ShoppingCartList',
    components: {
        emptyBox,
        tagList
    },
    mixins: [addToCartMixin],
    props: {
        tableData: {
            type: Array
        },
        isLoading: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            loading: false,
            Product,
        }
    },
    computed: {
        ...mapGetters(['license', 'userType'])
    },
    created() {
        console.log(this.tableData)
    },
    methods: {
        tableRowClassName({ row, rowIndex }) {
            if (row.isSkuInfoRed === 'Y') {
                return 'expDate-row'
            }
            return '';
        },
        jumpDetail(productId) {
            this.$router.push({ path: '/detail/' + productId })
        },
        // 重新渲染表格头部
        renderHeader(h, { column }) {
            return h('i', {
                class: 'header-row'
            })
        },
        //合并行
        spanMethod({ row, column, rowIndex, columnIndex }) {
            if (row.type === 'complimentary') {
                return [rowIndex, 14]
                // }
            } else if (row.type === 'package') {
                if (columnIndex === 2) {
                    return [1, 12]
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
/deep/ .el-table {
    .expDate-row {
        td {
            background: rgba(240, 230, 140, .2) !important;
        }

        .cell {
            color: #FF5200 !important;

            >span {
                color: #FF5200 !important;
            }

            div {
                color: #FF5200 !important;
            }

            input {
                color: #FF5200 !important;
            }

            p {
                color: #FF5200 !important;
            }
        }
    }
}

.salePrice {
    color: #FF6600;
}

.medicarePrice {
    color: #339900;
}

.img-content {
    text-align: center;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main {
    margin: 0 auto;
    min-width: 1200px;
    width: 100%;
    max-width: 1600px;
    height: auto;
    min-height: 200px;
    background: #ffffff;

    /*采购商信息*/
    .buyer-info {
        width: 100%;
        height: 48px;
        background: #F5F5F5;
        line-height: 48px;
        /*text-align: left;*/
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .buyer-tag {
            margin-right: 8px;
            display: inline-block;
            padding: 0 4px;
            height: 20px;
            line-height: 20px;
            font-size: 12px;
            color: #ffffff;
            vertical-align: middle;
            background: rgba(2, 92, 214, 1);
            border-radius: 2px;
        }

        .buyer-name {
            margin-right: 28px;
            font-size: 14px;
            font-weight: 500;
            color: #222222;
            line-height: 20px;
        }

        .buyer-contact {
            margin-right: 28px;
            font-size: 14px;
            font-weight: 500;
            color: #666666;
        }

        .buyer-discounts {
            margin-left: auto;
            /*margin-left: 12px;*/
            /*margin-right: 8px;*/
            display: inline-block;
            padding: 0 4px;
            height: 20px;
            line-height: 20px;
            font-size: 12px;
            color: #ffffff;
            vertical-align: middle;
            background: #FF6600;
            border-radius: 2px;
        }
    }

    /*加购信息*/
    .discounts {
        width: 100%;
        /*height: 48px;*/
        background: #ffffff;
        /*line-height: 48px;*/
        /*text-align: left;*/
        padding: 14px 12px;

        .discounts-row {
            margin: 6px 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .discounts-tag {
                margin-left: 12px;
                margin-right: 8px;
                display: inline-block;
                padding: 0 4px;
                height: 20px;
                line-height: 20px;
                font-size: 12px;
                color: #ffffff;
                vertical-align: middle;
                background: #FF6600;
                border-radius: 2px;
            }

            .discounts-info {
                font-size: 14px;
                font-weight: 500;
                color: rgba(0, 0, 0, 1);
            }
        }
    }
}

/*结算*/
.settlement-container {
    width: 100%;
    height: auto;
    background: #ffffff;
    margin-top: 10px;

    .settlement-row {
        margin: 0 auto;
        width: 1200px;
        height: 60px;
        box-shadow: 0px 4px 8px #000000;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .row-left {
            font-size: 12px;
            font-weight: 500;
            color: rgba(102, 102, 102, 1);

            /deep/ .el-checkbox {
                margin-right: 23px;

                .el-checkbox__label {
                    font-size: 12px;
                    font-weight: 500;
                    color: rgba(102, 102, 102, 1);
                }
            }

            span {
                cursor: pointer;
                margin-right: 20px;
            }
        }

        .row-right {
            display: flex;
            flex-direction: row;
            align-items: center;

            .settlement {
                margin-right: 12px;
                text-align: right;

                .total-money {
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                    font-weight: 500;
                    color: rgba(34, 34, 34, 1);

                    span {
                        font-size: 20px;
                        color: rgba(224, 32, 52, 1);
                    }
                }
            }

            .product-detail {
                height: 14px;
                font-size: 12px;
                font-weight: 500;
                color: rgba(102, 102, 102, 1);
                line-height: 14px;

                span {
                    margin-left: 12px;
                }
            }

            .settlement-button-group {
                height: 100%;

                button {
                    border: none;
                    height: 60px;
                    padding: 0 28px;
                    text-align: center;
                    text-decoration: none;
                    display: inline-block;
                    font-size: 16px;
                }

                .immediate-settlement {
                    color: #ffffff;
                    background: #E02034;

                }
            }
        }
    }
}

.is-tabledata-empty {
    /deep/ .el-table__body-wrapper {
        display: none
    }
}

/*表格*/
/*解决列fixed后的办法处理*/
/deep/ .el-table--border {
    border: 1px solid #E5E5E5;
}

/deep/ .el-table__row {
    height: 60px;

    .cell {
        padding: 0 8px;
        font-size: 12px;
        font-weight: 500;
        color: rgba(38, 38, 38, 1);
    }

    .paddingNo .cell {
        padding: 0;
    }

    .product-img {
        width: 50px;
        height: 50px;
    }

    .el-input-number {
        width: 88px;
    }

    .el-input-number__decrease,
    .el-input-number__increase {
        width: 20px;
        height: 24px;

        /*right: 0;*/
        background: #ffffff;
        box-sizing: border-box;
        border-radius: 1px;
        border: 1px solid rgba(230, 230, 230, 1);
    }

    .el-input-number__decrease {
        left: 0;
    }

    .el-input-number__increase {
        right: 0;
    }

    .el-input__inner {
        padding: 0 19px;
        height: 24px;
        line-height: 24px;
    }

    /*商品编码*/
    .table-product-code {
        margin: 0 -8px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
            height: 20px;
            font-size: 12px;
            font-weight: 500;
            color: rgba(38, 38, 38, 1);
            line-height: 20px;
        }

        .history {
            padding: 0 4px;
            height: 20px;
            width: 60px;
            font-size: 12px;
            font-weight: 600;
            color: rgba(255, 255, 255, 1);
            line-height: 20px;
            background: rgba(250, 173, 20, 1);
            border-radius: 0 8px 0 0;
            position: absolute;
            bottom: 0;
        }
    }

    /*商品名称*/
    .table-product-name {
        cursor: pointer;

        .product-name {
            height: 20px;
            font-size: 14px;
            font-weight: 600;
            color: rgba(34, 34, 34, 1);
            line-height: 20px;
        }

        .name-tag {
            font-size: 14px;
            // font-weight: 500;
            color: rgba(255, 255, 255, 1);
            background: rgba(255, 102, 0, 1);
            border-radius: 4px;
            padding: 0 4px;
        }

        .table-row-tags {
            display: flex;
            flex-direction: row;

            .row-tag {
                height: 18px;
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                border-radius: 3px;
                border-width: 1px;
                border-style: solid;
                margin-right: 4px;
                padding: 0 4px;
                box-sizing: border-box;
            }

            .tag-waring {
                border-color: transparent;
                color: #ffffff;
                background-color: red;
            }

            .tag-promotion {
                background: #FF6600;
                border: none;
                color: #ffffff;
            }

            .tag-cash {

                border-color: rgba(2, 92, 214, 0.5);
                color: #025CD6;
            }

            .tag-coupon {
                border-color: rgba(255, 163, 102, 0.5);
                color: #FF6600;
            }

            .tag-plus {
                border-color: rgba(51, 174, 103, 0.5);
                color: #33AE67;
            }
        }
    }

    /*库存*/
    .inventory-warning {
        font-weight: 600;
        color: rgba(255, 82, 0, 1);
    }

    /*操作栏*/
    .table-operation {
        display: flex;
        flex-direction: row;
        align-items: center;

        .add-shopping-cart {
            font-size: 12px;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
            width: 100%;
            border: 0 !important;
            background: rgba(255, 102, 0, 1);
            border-radius: 2px;
            cursor: pointer;

        }

        .sell-out {
            font-size: 12px;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
            line-height: 32px;
            width: 100%;
            background: #C0C0C0;
            border-radius: 2px;
            cursor: not-allowed;
        }
    }

}

/deep/ .header-row {
    height: 40px;
    background: #E5E5E5;

    th {

        /*box-sizing: border-box;*/
        /*padding: 12px 0;*/
        .cell {
            padding: 0 8px;
            font-size: 12px;
            font-weight: 600;
            color: rgba(34, 34, 34, 1);
        }
    }
}

.identifying {
    display: inline-block;
    width: 32px;
    height: 32px;

    img {
        width: 100%;
        height: 100%;
    }
}

.jiaOtc {
    background: #E60012 !important;
}

.yiOtc {
    background: #33AE67 !important;
}

.rxOtc {
    background: #0091ff !important;
}
</style>

<template>
  <im-page-modal
      class="my-coupon-list-page"
      v-loading="loading"
      :show-header="false"
      :isShowBack="false"
  >
    <el-tabs v-model="activeName" @tab-click="handleClick" class="use-tab">
      <el-tab-pane label="可使用" name="canUse">
              <span slot="label">
                  可使用
              (<span>{{ canUseNum }}</span>)
             </span>
      </el-tab-pane>
      <el-tab-pane label="已使用" name="isUse">
            <span slot="label">
                 已使用
             (<span>{{ isUseNum}}</span>)
            </span>
      </el-tab-pane>
      <el-tab-pane label="已过期" name="isFailure">
            <span slot="label">
                已失效
            (<span>{{ isFailureNum}}</span>)
            </span>
      </el-tab-pane>
    </el-tabs>
    <el-col class="coupon-item-box" v-if="tableData.length">
      <div
          :class="{'coupon-item-content':'coupon-item-content', 'has-used': item.isUse === 'Y' ,'has-pass': isFailure === 'Y'}"
          v-for="item in tableData"
          :key="item.couponUseSituationId">
                 <span class="coupon-type-tap">
                    店铺{{item.couponType !== 'FULL_REDUCTION' ? '折扣券' : '满减券'}}
                </span>
        <el-col class="col-1">
          <template v-if="item.couponType === 'FULL_REDUCTION'">
            <span class="col-1-full">&yen;</span>
            <span class="col-1-discount">{{ item.fullReductionAmount ? Number(item.fullReductionAmount.replace(',','')) : ''}}</span>
          </template>
          <template v-else>
            <span class="col-1-discount">{{item.discount}}</span>
            <span class="col-1-2-discount-text">折</span>
          </template>
        </el-col>
        <el-col>
          <p class="col-2-text" v-if="item.couponType === 'FULL_REDUCTION'">
            <template v-if="item.fullAmount === '0.00'">
              无门槛使用
            </template>
            <template v-else>
              满{{Number(item.fullAmount.replace(',',''))}}元使用
            </template>
          </p>
          <p class="col-2-text" v-else>
            <template v-if="item.fullAmount === '0.00'">
              无门槛使用
            </template>
            <template v-else>
              满{{Number(item.fullAmount.replace(',',''))}}使用
            </template>
          </p>
        </el-col>
        <el-col class="col-3-text">
          <p>
            {{item.couponNm}}
            <el-tooltip v-if="item.illustrate" class="item" effect="dark" :content="item.illustrate" placement="top-start">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </p>
          <p>{{ item.startTime ? item.startTime.replace(new RegExp('-','gm'),'.') : '未知' }} -
            {{ item.failureTime ? item.failureTime.replace(new RegExp("-","gm"),".") : '未知' }}</p>
        </el-col>
        <el-button class="use-coupon-btn"  @click="handleUesCoupon(item.couponId)" :disabled="item.isUse === 'Y' || isFailure === 'Y'" >
          <span v-if="item.isUse === 'N' && isFailure !== 'Y' ">去<br>使<br>用</span>
          <span v-else-if="item.isUse === 'Y'">已<br>使<br>用</span>
          <span v-else-if="isFailure === 'Y'">已<br>失<br>效</span>
        </el-button>
      </div>
    </el-col>
    <empty-default v-if="!tableData.length"/>
    <el-col class="pagination">
      <im-pagination
          v-if="total"
          :showCollection="showCollection"
          :isShowMainInfo="isShowMainInfo"
          :hideOnSinglePage="hideOnSinglePage"
          :page-sizes="pageSizes"
          class="table-page"
          :total="total"
          :current-page.sync="currentPage"
          :page-size.sync="pageSize"
          @pagination="handlePagination"
      />
    </el-col>
  </im-page-modal>
</template>
<script>
  import { pageCouponList,couponNumber } from '@/api/member-center/property-manage'
  import emptyDefault from '@/components/tableEmptyDefault'

  export default {
    components: {
      emptyDefault
    },
    data () {
      return {
        pageSizes: [12, 18, 24, 36, 48, 96],
        hideOnSinglePage: false,
        showCollection: true,
        loading: false,
        activeName: 'canUse',
        canUseNum: 0,
        isUseNum: 0,
        isFailureNum: 0,
        isShowMainInfo: true,
        currentPage: 0,
        pageSize: 12,
        total: 0,
        isUse: 'N',
        isFailure: 'N',
        tableData: []
      }
    },
    created () {
      this.$emit('handle-change-page', {
        text: '我的优惠券',
        index: '4-1',
        url: '/member-center/property-manage/my-coupon'
      })
      this.queryData()
      this.couponNumber()
    },
    computed: {
      disabled () {
        return this.activeName !== 'canUse'
      }
    },
    methods: {
      couponNumber(){
        couponNumber().then(res=>{
          console.log(res)
            this.canUseNum = res.data.enableCouponQuantity || 0
            this.isUseNum = res.data.hasCouponQuantity || 0
            this.isFailureNum = res.data.pastCouponQuantity || 0
        })
      },
      handleClick () {
        this.isUse = this.activeName === 'isUse' ? 'Y' : 'N'
        this.isFailure = this.activeName === 'isFailure' ? 'Y' : 'N'
        this.currentPage = 0
        this.queryData()
      },
      handlePagination (data) {
        this.currentPage = Math.min(this.currentPage, Math.ceil(this.total / data.pageSize))
        this.queryData()
      },
      queryData () {
        this.loading = true
        let params = {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          condition: {
            isUse: this.isUse,
            isFailure: this.isFailure
          }
        }
        if (this.activeName === 'isUse') {
          delete params.condition.isFailure
        }
        pageCouponList(params).then(res => {
          this.tableData = res.data.records
          this.total = res.data.total
          // if (this.tableData.length) {
          // }
          this.loading = false
        }, err => {
          this.loading = false
        })
      },
      handleUesCoupon (id) {
        // this.$router.push({ path: '/' })
        this.$router.push({ path: '/commodity-list', query: { couponId: id} })
      }
    }
  }
</script>
<style lang="scss">
  .my-coupon-list-page {
    .header {
      border-bottom: none
    }

    .content {
      padding: 6px 0;
    }

    .el-tabs__nav-wrap::after {
      display: none
    }
  }
</style>
<style scoped lang="scss">
  .my-coupon-list-page {
    background-color: white;

    .use-tab {
      display: flex;
      align-items: center;
      padding: 0 18px;
      height: 52px;
    }

    .coupon-item-box {
      padding-top: 10px;
      display: flex;
      flex-wrap: wrap;
      line-height: 25px;

      .coupon-item-content {
        margin-left: 20px;
        position: relative;
        color: #666;
        text-align: left;
        padding: 28px 20px 0 20px;
        margin-bottom: 20px;
        width: 299px;
        height: 172px;
        background-image: url("../../../../assets/img/member-center/my-coupon-bg.png");
        border-radius: 8px 8px 0 0;

        &:nth-child(3n+1) {
          margin-left: 14px;
        }

        .col-1 {
          display: flex;
          align-items: center;
          height: 36px;
          margin-top: 5px;
        }

        .col-1-full {
          display: inline-block;
          vertical-align: top;
          width: 10px;
          height: 36px;
          font-size: 16px;
          font-weight: 600;
          color: rgba(250, 100, 0, 1);
        }

        .col-1-discount {
          display: inline-block;
          font-size: 36px;
          height: 36px;
          line-height: 36px;
          font-weight: 600;
          color: rgba(250, 100, 0, 1);
        }

        .col-1-2-discount-text {
          display: inline-block;
          height: 36px;
          line-height: 36px;
          font-size: 24px;
          font-weight: 600;
          color: rgba(250, 100, 0, 1);
        }

        .col-2-text {
          font-size: 14px;
          font-weight: 400;
          color: rgba(34, 34, 34, 1);
          line-height: 20px;
        }

        .col-3-text {
          margin-top: 8px;
          width: 200px;
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: rgba(153, 153, 153, 1);
          line-height: 20px;
        }

        .col-flex {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .coupon-type-tap {
          width: 80px;
          height: 20px;
          background: rgba(250, 100, 0, 0.1);
          border-radius: 2px 2px 16px 2px;
          font-size: 12px;
          font-weight: 400;
          color: rgba(250, 100, 0, 1);
          line-height: 20px;
          text-align: center;
          position: absolute;
          top: 4px;
          left: 4px;
        }

        .use-coupon-btn {
          background: none;
          border: none;
          width: 56px;
          justify-content: center;
          display: flex;
          align-items: center;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          font-size: 14px;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          line-height: 20px;
        }
      }

      .has-used {
        background-image: url("../../../../assets/img/member-center/my-coupon-bg-used.png");
      }

      .has-pass {
        background-image: url("../../../../assets/img/member-center/my-coupon-bg-pass.png");

        .use-coupon-btn {
          color: rgba(153, 153, 153, 1);
        }
      }
    }

    .pagination {
      padding: 0 28px;
    }
  }
</style>

/**
 * ImSearchPad
 * @module components
 * @desc table内敛确认提示框
 * @author Chen Runrong
 * @date 2020/03/21
 * @param {Boolean} [visible] - 是否显示 支持sync修饰符
 * @param {String} [title] - 标题
 * @example 调用示例
 * <im-drawer title="新增客户级别" :visible.sync="drawerVisible" @confirm="handleConfirm">
      <el-form ref="form" label-width="180px" style="padding-right:50px;padding-top: 24px;" :model="form" :rules="formRules">
        <el-form-item label="级别名称：" prop="levelName">
          <el-input v-model="form.levelName" placeholder="输入级别名称" />
        </el-form-item>
        <el-form-item label="价格系数：" prop="price">
          <el-input v-model="form.price" class="el-input-append-text" placeholder="输入系数值">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
      </el-form>
    </im-drawer>
 */
import imDrawer from './index.vue'

const ImDrawer = {
  install: function(Vue) {
    Vue.component('ImDrawer', imDrawer)
  }
}

export default ImDrawer

<template>
    <el-form ref="form" :model="form" :rules="formRules" label-width="0px" class="forget-password-from">
      <el-form-item prop="password">
        <el-input v-model="form.password" placeholder="设置登录密码" type="password"></el-input>
        <div class="input-icon">
          <img src="@/assets/img/register/icon-suo.png" class="img-suo" alt/>
        </div>
      </el-form-item>
      <el-form-item prop="passwordAgain">
        <el-input v-model="form.passwordAgain" placeholder="再次输入登录密码" type="password" @keyup.enter.native="next" />
        <div class="input-icon">
          <img src="@/assets/img/register/icon-suo.png" class="img-suo" alt/>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button @click="next()" class="sureBut" type="primary">下一步</el-button>
      </el-form-item>
    </el-form>
</template>

<script>
import { updatePcFrontPassword } from '@/api/login/forget-password'
import { encodeMd5 } from '../../../utils/encode-md5'
export default {
  name: 'AccountInfor',
  props: {
    params: {
      type: Object,
      default: {}
    },
  },
  data () {
    const validateConfirmPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('确认密码不能为空'))
      }
      if (this.form.password && value !== this.form.password) {
        return callback(new Error('确认密码与新密码不相同'))
      }
      callback()
    }
    return {
      active: 0,
      form:{
        password: '',
        passwordAgain: ''
      },
      formRules: {
        password: [{ required: true, trigger: 'blur', message: '登录密码不能为空' }],
        passwordAgain: [{ required: true, trigger: 'blur', validator: validateConfirmPassword }]
      },
      codeBaseUrl: `${process.env.VUE_APP_BASE_API}/code`,
      random: new Date().getTime(),
      dialogVisible: false
    }
  },
  computed: {
    codeUrl () {
      return `${this.codeBaseUrl}?randomStr=${this.random}`
    }
  },
  methods: {
    next() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let head = {
            password: encodeMd5(this.form.password),
            loginId: this.params.loginId
          }
          updatePcFrontPassword({...head}).then(res => {
            this.$emit('next', 3)
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.forget-password-from {
  width: 400px;
  margin: 40px auto;
  /deep/ .el-input__inner{
    padding-left: 45px;
  }
  /deep/ .el-dialog__header {
    padding: 0px;
  }
  /deep/ .el-dialog__headerbtn {
    top: 10px;
  }
  .input-icon {
    position: absolute;
    left: 12px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    .img-suo {
      width: 16px;
      height: 16px;
    }
  }
  .sureBut {
    width: 400px;
    height: 40px;
    @include NormalBtnColor
  }
}
</style>

import {ApiDelete, ApiGet, ApiPost, ApiPut} from "@/api";

/**
 * @description 我的业务员
 */
export const findByUserId = data => ApiGet('/pc/orgSalesman/findByUserId',data)

/**
 * @description 分页查询采购计划添加商品列表
 */
export const pageIndexProduct = data => ApiPost('/sku/pageIndexSku',data)

/**
 * @description 分页查询采购计划
 */
export const pageProcurementPlan = data => ApiPost('/pc/procurementPlan/pageProcurementPlan',data)

/**
 * @description 保存修改采购计划
 */
export const saveOrUpdateProcurementPlan = data => ApiPost('/pc/procurementPlan/saveOrUpdateProcurementPlan',data)

/**
 * @description 根据采购计划ID获取采购计划信息
 */
export const getProcurementPlanById = procurementPlanId => ApiGet('/pc/procurementPlan/getProcurementPlanById', {
  procurementPlanId
})

/**
 * @description 根据采购计划ID删除采购计划信息
 */
export const delProcurementPlanById = procurementPlanId => ApiDelete(`/pc/procurementPlan/delProcurementPlanById?procurementPlanId=${procurementPlanId}`, {
  procurementPlanId
})

/**
 * @description 保存或修改商品收藏
 */
export const saveOrUpdateProductCollect = data => ApiPost('/pc/productCollect/saveOrUpdateProductCollect',data)

/**
 * @description 分页查询商品收藏
 */
export const pageProductCollect = data => ApiPost('/pc/productCollect/pageProductCollect',data)

/**
 * @description 根据商品收藏ID获取商品收藏信息
 */
export const getProductCollectById = productCollectId => ApiGet('/pc/productCollect/getProductCollectById', {
  productCollectId
})

/**
 * @description 根据商品收藏ID删除商品收藏信息
 */
export const delProductCollectById = productCollectId => ApiDelete(`/pc/productCollect/delProductCollectById?productCollectId=${productCollectId}`, {
  productCollectId
})

/**
 * @description 分页查询客户询价草稿箱
 */
export const pageInquiryBox = data => ApiPost('/pc/inquiryBox/pageInquiryBox',data)

/**
 * @description 保存或修改客户询价草稿箱
 */
export const saveOrUpdateInquiryBox = data => ApiPost('/pc/inquiryBox/saveOrUpdateInquiryBox',data)

/**
 * @description 根据客户询价草稿箱ID获取客户询价草稿箱信息
 */
export const getInquiryBoxById = inquiryDraftBoxId => ApiGet('/pc/inquiryBoxDetail/getInquiryBoxDetailById', {
  inquiryDraftBoxId
})

/**
 * @description 根据客户询价草稿箱ID删除客户询价草稿箱信息
 */
export const delInquiryBoxById = inquiryDraftBoxId => ApiDelete('/pc/inquiryBox/delInquiryBoxById', {
  inquiryDraftBoxId
})

/**
 * @description 下载草稿箱模板
 */
export const exportTemplate = type => ApiGet('/pc/inquiryBoxDetail/exportTemplate', {
  type
},{
  responseType: 'blob',
})

/**
 * @description 商品对码表
 */
export const pageProductCode = data => ApiPost('/pc/front/product/pageProductCode',data)

/**
 * @description 一键采购
 */
export const planBuy = data => ApiGet('/pc/member/normalShoppingFlow/planBuy',data)

/**
 * @description 导出询价
 */
export const exportExcel = data => ApiPost('/pc/inquiryBoxDetail/exportExcel',data,{
  responseType: 'blob'
})

/*
* @description 获取报价单列表
* */
export const getQuotationList = data => ApiPost('/quotation/pageQuotation',data)

/*
* @description 根据ID获取报价单
* */
export const getQuotationById = data => ApiGet('/quotation/getQuotationById',data)

/*
* @description 修改报价单
* */
export const updateQuotation = data => ApiPut('/quotation/updateQuotation',data)

/*
* @description 保存报价单
* */
export const saveQuotation = data => ApiPost('/quotation/saveQuotation',data)

/*
* @description 修改报价单作废状态
* */
export const changeQuotationIsStop = data => ApiGet('/quotation/updateIsStop',data)

/*
* @description 删除报价单
* */
export const deleteQuotation = data => ApiDelete('/quotation/delQuotationById',data)

/*
* @description 报价单选择商品列表
* */
export const quotationProductList = data => ApiPost('/pc/front/product/pageIndexProductQuotation',data)

/**
 * @description 保存修改采购计划
 */
export const getConsultPrice = data => ApiPost('/pc/inquiryBoxDetail/pageSkuVo/INQUIRYBOXDETAILKH',data)

/**
 * @description 是否有询价权限
 */
export const inTransferGroup = data => ApiPost('/transferGroup/inTransferGroup',data)
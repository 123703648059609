<template>
    <div class="shop-cart-content">
        <div class="main">
            <!--table列表-->
            <el-table ref="multipleTable"
                v-loading="loading"
                border
                :data.sync="goodsData"
                header-row-class-name="header-row"
                @selection-change="handleSelectionChange">
                <el-table-column type="index" class-name="index" align="center" label="序号" width="45"
                                 show-overflow-tooltip>
                </el-table-column>
                <el-table-column type="selection" class-name="selection" align="center" width="45"
                                 show-overflow-tooltip>
                </el-table-column>
                <el-table-column class-name="padding" label="商品图" width="80px" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div class="commodity-image">
                            <router-link :to="'/detail/'+scope.row.productId">
                                <img class="product-img"
                                     :src="(scope.row.productPicMap && scope.row.productPicMap['48X48']) || productDefaultImg"
                                     @error="scope.row.productPicMap['48X48'] = productDefaultImg"
                                     alt>
                            </router-link>
                            <!--              <div v-if="scope.row.tagList.length && scope.row.tagList.findIndex(res => res.tag === '历史购买') !== -1"-->
                            <!--                   class="purchase-history-tag">历史购买-->
                            <!--              </div>-->
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="productCode" label="商品编码" width="70px" align="center" show-overflow-tooltip class-name="visibility">
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.productCode }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="productNm" class-name="visibility" label="商品名称" width="160px">
                    <template slot-scope="scope">
                        <div v-if="!scope.row.isGroup" class="table-product-name">
                            <p>
                                <span class="row-tag tag-otc"
                                    :class="{ 'jiaOtc': scope.row.otcTypeNm === '甲OTC', 'yiOtc': scope.row.otcTypeNm === '乙OTC', 'rxOtc': scope.row.otcTypeNm === 'RX' }"
                                    v-if="scope.row.otcTypeNm && scope.row.otcTypeNm !== '其他'"
                                    >{{ scope.row.otcTypeNm }}</span>
                                <router-link :to="'/detail/'+scope.row.productId">{{$util.getGoodsName(scope.row)}}</router-link>
                            </p>
                            <tagList :taglist='scope.row.tagList'></tagList>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="spec" align="center" label="规格" width="70px"/>
                <el-table-column prop="manufacturer" align="left" label="厂家(上市许可持有人)/产地" width="170px"/>
                <el-table-column prop="unit" align="center" label="单位" width="56px" show-overflow-tooltip/>
                <el-table-column prop="stock" align="center" label="库存" width="60px" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span :class="{'inventory-warning':scope.row.stockQuantity == '<100' || Number(scope.row.stockQuantity) < 100 }">{{ Math.floor(scope.row.stockQuantity) || scope.row.stockQuantity }}</span>
                        <!--            <span v-else-if="scope.row.stock && Number(scope.row.stock) < 100" class="inventory-warning">{{ scope.row.stock }}</span>-->
                        <!--            <span v-else>{{scope.row.stock}}</span>-->
                    </template>
                </el-table-column>
                <!--        <el-table-column prop="expDate" align="center" v-if="userType === 'BUYERS'" label="最近/远效期" width="200px" show-overflow-tooltip/>-->
                <el-table-column align="center" label="最小购买倍数" width="90px" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ getRealMinBuyNum(scope.row) || '/' }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="采购数量" width="112px" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-input-number
                            size="mini"
                            v-model="scope.row.enquiryNumber"
                            :precision="getDecimalPointNum(scope.row)"
                            :min="getRealMinBuyNum(scope.row)"
                            :step="getRealMinBuyNum(scope.row)"
                            step-strictly/>
                    </template>
                </el-table-column>
                <el-table-column align="right" label="单价" mi-width="75px" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div>¥{{ scope.row.price }}</div>
                    </template>
                </el-table-column>
                <el-table-column align="right" label="小计" min-width="75px" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span style="color: #FF3B30">¥{{ (scope.row.originalTotalPrice * 1).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="pushTime" align="center" label="到货时间" width="160px"/>
                <el-table-column align="center" width="70" label="操作">
                    <template slot-scope="scope">
                        <div class="table-operation"
                             v-if="scope.row.shoppingCartItemType !== 'FIX_DISCOUNT_PACKAGE' && scope.row.shoppingCartItemType !== 'COLLOCATE_DISCOUNT_PACKAGE'">
                            <span @click="openDeleteDialog(scope.row.regId)">删除</span>
                        </div>
                        <!--            <div class="table-operation" v-else-if="scope.row.shoppingCartItemType === 'RECOMMEND_PACKAGE_PACKAGE_ACTIVITY'">-->
                        <!--              <span @click="openSelectedDialog(scope.row.itemKey)">选组合套餐</span>-->
                        <!--            </div>-->
                        <div v-else>/</div>
                    </template>
                </el-table-column>
                <template slot="empty">
                  <!-- 购物车为空替换 -->
                  <div v-if="goodsData.length === 0" class="empty-default">
                      <div class="empty-box">
                          <el-image style="width: 160px; height: 160px" :src="emptyDefault" fit="fill"/>
                          <p class="empty-tip">到货通知为空，你可以去首页逛逛~</p>
                          <router-link to="/"><el-button type="primary" size="mini">去首页逛逛</el-button></router-link>
                      </div>
                  </div>
                </template>
            </el-table>
            <im-pagination
                :show-collection="true"
                class="table-page"
                :total="total"
                :page-sizes="pageSize"
                :current-page.sync="currentPage"
                :page-size.sync="pageSize"
                @pagination="handlePagination"
            />
        </div>
        <!--删除-->
        <el-dialog :visible.sync="dialogDeleteVisible" title="提示" width="400px" top="30vh">
            <img class="icon" src="@/assets/img/customer-service/icon-shanchu.png" alt>
            <p style="padding: 10px 0 20px">{{ regId===''?'确认删除所选商品？': '确认删除该商品？'}}</p>
            <el-button @click="deleteRow()" type="primary" class="agree" size="small">确认</el-button>
            <el-button @click="dialogDeleteVisible = false" class="agree" size="small">取消</el-button>
        </el-dialog>
        <!--结算-->
        <div style="background-color: #fff;padding-bottom: 10px">
            <div class="settlement-container">
                <div class="settlement-row">
                    <div class="row-left">
                        <el-checkbox :class="checked ? 'disabled-checkbox' : ''" v-if="goodsData.length" v-model="checked" @change="toggleAllSelection">全选</el-checkbox>

                        <span v-if="goodsData.length" @click="openDeleteDialog()">删除选中商品</span>
                        <router-link to="/"><span>返回首页</span></router-link>
                    </div>
                    <div class="row-right">
                        <div class="settlement">
                            <div class="total-money">
                                商品总金额：
                                <span class="money">¥{{ goodsTotal.toFixed(2) }}</span>
                            </div>
                            <div class="product-detail">
<!--                                <span>商品种类：{{ shopCart.productTypeTotalQuantity }}种</span>-->
                                <span>总件数：{{ checkedArr.length }}</span>
<!--                                <span>赠送积分：{{ shopCart.obtainTotalIntegral }}</span>-->
<!--                                <span>促销优惠减免：-¥{{ shopCart.discountAmount }}</span>-->
                            </div>
                        </div>
                        <div class="settlement-button-group">
                            <button
                                class="immediate-settlement"
                                :class="{'disable': checkedArr.length == 0 }"
                                @click="handleCollateOrder"
                            >加入购物车</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import tagList from '@/components/tagList/tagList'
import {
    ArrivalReminder,
    ArrivalReminderOnce
} from '@/api/arrival-reminder'
import { batchAddItem } from '@/api/detail'
import productDefaultImg from '@/assets/img/index/product_default.png'
import {getBus, getAccount} from '@/utils/auth'
import emptyDefault from '@/assets/img/member-center/emptycart.png'
import addToCartMixin from '@/common/addToCart'
import {mapGetters} from "vuex";

export default {
    name: 'ShoppingCartList',
    mixins: [addToCartMixin],
    components: {
        tagList
    },
    data() {
        return {
            loading: false,
            productDefaultImg,
            regId: '',
            checkedArr: [],
            checked: false,
            dialogDeleteVisible: false,

            emptyDefault,
            pageSize: 10,
            total: 0,
            currentPage: 1,
            goodsData: [],
            buyersId: null
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.ArrivalReminder()
        })

    },
    methods: {
        // 获取商品列表
        async ArrivalReminder() {
            this.loading = true
            if (this.userType === 'BUYERS') {
                this.buyersId = getBus().buyersId
            } else {
                this.buyersId = getAccount().buyersId
            }
            let params = {
                condition: {
                    buyersId: this.buyersId
                },
                pageNum: this.currentPage,
                pageSize: this.pageSize
            }
            await ArrivalReminder(params).then(res => {
                this.goodsData = res.data.records
                this.total = res.data.total
              console.log('this.buyersId', this.buyersId)
            }).catch((err)=> {
                console.log(err)
            }).finally(()=> {
                this.loading = false
            })
        },
      handlePagination (data) {
        this.currentPage = Math.min(this.currentPage, Math.ceil(this.total / data.pageSize))
        this.ArrivalReminder()
      },
        // 将商品标记为已读
        ArrivalReminderOnce(regIdList) {
            ArrivalReminderOnce({regIdList: regIdList}).then(res => {
                if (res.data == true) {
                    this.$message.success('操作成功！')
                }
            }).catch((err)=> {
                console.log(err)
            }).finally(()=> {
                this.ArrivalReminder()
            })
        },
        openDeleteDialog (regId) {
            this.dialogDeleteVisible = true
            if (regId) {
                this.regId = regId
            } else {
                this.regId = ''
            }

        },
        deleteRow() {
            let regIdList = []
            if (this.regId === '' && this.checkedArr.length) {
                this.checkedArr.forEach(goods => {
                    regIdList.push(goods.regId)
                })

                console.log('checkedArr',regIdList)
            } else if(this.regId !== '') {
                regIdList.push(this.regId)
                console.log('regId', regIdList)
            } else {
                this.$message.error('请选择商品')
                this.dialogDeleteVisible = false
                return
            }
            this.dialogDeleteVisible = false
            this.ArrivalReminderOnce(regIdList)
        },
        // 全选按钮
        toggleAllSelection () {
            this.$refs.multipleTable.toggleAllSelection()
            this.checkedArr = this.$refs.multipleTable.selection
        },
        // 修改商品选中状态
        handleSelectionChange (selection, row) {
            this.checkedArr = selection
        },
        // 数组对象相同值相加去重
        delSameObjValue(arr, resultNum, keyName, keyValue) {
            const warp = new Map();
            arr.forEach(i => {
                let str = keyName.map(v => i[v]).join('_');
                i[resultNum] = keyValue.reduce((p, c) => p += i[c], 0);
                warp.has(str) ? warp.get(str)[resultNum] += i[resultNum] : warp.set(str, i);
            });
            return Array.from(warp).map(([, v]) => v);
        },
        // 加入购物车
        handleCollateOrder() {
            if (this.checkedArr.length) {
                /* 处理选中商品的参数 */
                const skuIdList = this.checkedArr.map(goods => ({
                    skuId: goods.skuId,
                    quantity: goods.enquiryNumber
                }))
                let params = this.delSameObjValue(skuIdList, 'quantity', ['skuId'], ['quantity'])
                let regIdList = []
                this.checkedArr.forEach(goods => {
                    regIdList.push(goods.regId)
                })
                batchAddItem({ itemList: params }).then(res => {
                    const { code } = res
                    if (code === '0') {
                        this.$message.success('加入成功！')
                        this.$store.dispatch('user/setCartNum', res.data.allProductTypeTotalQuantity)
                        ArrivalReminderOnce({regIdList: regIdList}).then(res => {
                            this.ArrivalReminder()
                        })
                    } else {
                        return
                    }
                })
            } else {
                this.$message.error('请选择商品')
            }

        },
    },
    watch: {
        checkedArr (newVal) {
            this.checked = newVal.length === this.goodsData.length
        },
        // accountId (newVal) {
        //     this.buyersId = newVal.buyersId
        //     this.ArrivalReminder()
        // }
    },
    computed: {
        ...mapGetters(['userType', 'accountId']),
        goodsTotal() {
            let total = 0
            total = this.checkedArr.map(item => {
                return item.enquiryNumber * item.price
            }).reduce((pre, cur) => pre + cur, 0)
            console.log('total', total)
            return total
        }
    }
}
</script>

<style scoped lang="scss">
.main {
    margin: 0 auto;
    width: 1200px;
    height: auto;
    /*采购商信息*/
    .buyer-info {
        width: 100%;
        background: #F5F5F5;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 10px;

        .buyer-tag {
            margin-right: 8px;
            display: inline-block;
            padding: 0 4px;
            height: 20px;
            line-height: 20px;
            font-size: 12px;
            color: #ffffff;
            vertical-align: middle;
            background: $ThemeColor;
            border-radius: 2px;
        }

        .buyer-name {
            margin-right: 28px;
            font-size: 14px;
            font-weight: 500;
            color: #222222;
            line-height: 20px;
        }

        .buyer-contact {
            margin-right: 28px;
            font-size: 14px;
            font-weight: 500;
            color: #666666;
        }

        .buyer-discounts {
            margin-left: auto;
            cursor: pointer;
            display: inline-block;
            padding: 0 12px;
            height: 28px;
            line-height: 28px;
            font-size: 12px;
            color: #ffffff;
            background: #FF6600;
            border-radius: 4px;

            &:hover {
                opacity: .8;
            }
        }
    }

    .group-row {
        /*position: relative;*/
        background: #fafafa;

        td {
            z-index: 1;
            background: #fafafa;
        }

        .el-table__expand-icon {
            .el-icon-arrow-right::before {
                content: "\e791"
            }
        }

        .group-row-content {
            display: block;
            position: initial;

            .state {
                margin-right: 16px;
            }
        }

        .group-row-content-btn {
            padding: 0;
            line-height: 1.5;
        }
    }

    .discounts-tag {
        margin-left: 12px;
        margin-right: 8px;
        display: inline-block;
        padding: 0 4px;
        height: 20px;
        font-weight: 500;
        line-height: 20px;
        font-size: 12px;
        color: #ffffff;
        vertical-align: middle;
        background: linear-gradient(135deg, rgba(250, 167, 0, 1) 0%, rgba(250, 100, 0, 1) 100%);
        border-radius: 2px;
    }

    /*加购信息*/
    .discounts {
        width: 100%;
        background: #ffffff;
        padding: 14px 12px;

        .discounts-row {
            margin: 6px 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .discounts-info {
                font-size: 14px;
                font-weight: 500;
                color: rgba(0, 0, 0, 1);
            }

            /deep/ {
                .el-button--text {
                    padding: 0 10px;
                }
            }
        }
    }

    .set-meal {
        background: rgba(241, 247, 254, 1);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .meal-center {
            font-size: 14px;
            font-weight: 600;
            color: #222222;

            .fixed {
                color: $ThemeColor;
            }

            .dapei {
                color: #FA6400;
            }

        }
    }

    .coupon-dialog {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        .flex {
            display: flex;
            text-align: left;
        }

        .name {
            color: #fff;
            margin-right: 10px;
            position: relative;
            line-height: 28px;
            text-align: center;

            .left-con, .right-con {
                width: 10px;
                height: 10px;
                background: #fff;
                position: absolute;
                border-radius: 50%;
                top: 12px;
                left: -5px;
            }

            .right-con {
                left: auto;
                right: -5px;
            }

            p {
                padding: 2px 15px;
                min-width: 80px;
                background-color: #FA6400;
                border-radius: 2px;
            }
        }

        .text {
            line-height: 1.3em;

            .text_1 {
                font-size: 14px;
                font-weight: 600;
                color: #222;
            }

            .text_2 {
                font-size: 12px;
                color: #999;
            }
        }

        .button {
            font-size: 12px;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
            width: 80px;

            p {
                color: #FA6400;
                padding: 0 16px;
                border: 1px solid #FA6400;
                border-radius: 2px;
                cursor: pointer;

                &:hover {
                    color: #fff;
                    background: #FA6400;
                }
            }
        }
        .disabled {
            p {
                color: #999;
                padding: 0 16px;
                border: 1px solid #999;
                border-radius: 2px;
                cursor: default;

                &:hover {
                    color: #999;
                    background: #fff;
                }
            }
        }
        .isReceive{
            p{
                cursor: default;
                &:hover {
                    color: #fa6400;
                    background: #fff;
                }
            }
        }
    }

    .present-dialog {
        text-align: left;

        .name {
            color: #222;
            font-size: 14px;
            font-weight: 600;
        }

        img {
            width: 50px;
            height: 50px;
            margin-right: 12px;
        }
    }

    .addition-dialog {
        h3 {
            background-color: #F1F7FE;
            line-height: 40px;
            font-weight: 600;
            color: #222;
            text-align: left;
            padding-left: 20px;
        }

        .checkChecked {
            height: 400px;
            overflow-y: auto;

            .el-checkbox {
                display: flex;
                align-items: center;
                padding: 16px 20px;
                margin: 0;
                float: left;
                width: 320px;

                /deep/ .el-checkbox__label {
                    display: flex;
                }

                .content {
                    text-align: left;

                    .name {
                        color: #222;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 200px;
                    }

                    .manufacturer, .spec {
                        font-size: 12px;
                        color: #666666;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 200px;
                    }

                    .price {
                        color: #E02034;
                        font-weight: 600;
                        font-size: 16px;

                        span {
                            text-decoration: line-through;
                            font-size: 14px;
                            font-weight: 400;
                            color: #999;
                            padding-left: 8px;
                        }
                    }
                }
            }
        }

        img {
            width: 80px;
            height: 80px;
            margin-right: 12px;
        }

        /deep/ {
            .el-dialog__body {
                padding: 0;
            }
        }

        footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 50px;
            height: 50px;
            padding: 0 13px 0 20px;
            background-color: #F5F5F5;

            .add-shopping-cart {
                font-size: 12px;
                font-weight: 500;
                height: 32px;
                line-height: 32px;
                color: rgba(255, 255, 255, 1);
                background: rgba(255, 102, 0, 1);
                border-radius: 2px;
                cursor: pointer;
                padding: 0 12px;
            }

            span {
                padding-left: 5px;
            }

            b {
                color: #E02034;
                font-size: 16px;
            }
        }
    }

    .disableText {
        text-align: left;
        font-size: 14px;
        margin-top: 30px;
        font-weight: 500;

        span {
            color: #666666;
            font-size: 12px;
            cursor: pointer;
            padding-left: 16px;
        }
    }

    .disableTable {
        margin-top: 12px;

        /deep/ {

            .el-table__row td:nth-child(2) {
                background-color: #F2F2F2;

                span {
                    color: #666;
                }
            }
        }
    }
}

/*结算*/
.settlement-container {
    width: 100%;
    height: auto;
    background: #ffffff;
    margin-top: 10px;
    box-shadow: 1px 4px 8px 0 rgba(0, 0, 0, 0.1);

    .settlement-row {
        margin: 0 auto;
        width: 1200px;
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .row-left {
            font-size: 12px;
            font-weight: 500;
            color: rgba(102, 102, 102, 1);

            /deep/ .el-checkbox {
                margin-right: 23px;

                .el-checkbox__label {
                    font-size: 12px;
                    font-weight: 500;
                    color: rgba(102, 102, 102, 1);
                }
            }

            span {
                cursor: pointer;
                margin-right: 20px;
            }
        }

        .row-right {
            display: flex;
            flex-direction: row;
            align-items: center;

            .settlement {
                margin-right: 12px;
                text-align: right;

                .total-money {
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                    font-weight: 500;
                    color: rgba(34, 34, 34, 1);

                    span {
                        font-size: 20px;
                        color: rgba(224, 32, 52, 1);
                    }
                }
            }

            .product-detail {
                height: 14px;
                font-size: 12px;
                font-weight: 500;
                color: rgba(102, 102, 102, 1);
                line-height: 14px;

                span {
                    margin-left: 12px;
                }

                /deep/ .el-button {
                    padding: 0 0 0 5px;
                    font-size: 12px;
                }
            }

            .settlement-button-group {
                height: 100%;

                button {
                    border: none;
                    height: 60px;
                    padding: 0 28px;
                    text-align: center;
                    text-decoration: none;
                    display: inline-block;
                    font-size: 16px;
                }

                .immediate-settlement {
                    color: #ffffff;
                    background: #E02034;

                    &.disable {
                        background-color: #bbb;
                        cursor: not-allowed;
                    }
                }
            }
        }
    }
}

// 表格为空替换
.empty-default {
    display: flex;
    height: 530px;
    justify-content: center;
    padding-top: 80px;
    background: white;

    .empty-tip {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(113, 113, 113, 1);
        line-height: 20px;
        margin-bottom: 30px;
    }

    .empty-btn {
        background: rgba(9, 113, 235, 1);
        border-radius: 2px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        padding: 2px 4px;
    }
}

//购物车列表为空
.is-tabledata-empty {
    /deep/ .el-table__body-wrapper {
        display: none
    }
}

/*表格*/
/*解决列fixed后的办法处理*/
/deep/ .el-table--border {
    border: 1px solid #E5E5E5;
}

/deep/ .el-table {
    .el-table__row {
        height: 60px;

        td .el-tooltip {
            min-width: 40px;
        }

        &.meal-row td.index {
            .el-tooltip {
                position: absolute;
                top: 14px;
            }
        }

        &.meal-row td.selection {
            padding: 0;

            .el-tooltip {
                position: absolute;
                top: 14px;
            }

            .selection {
                display: flex;
                flex-direction: column;
                align-items: center;

                .span {
                    width: 7px;
                    height: 7px;
                    border-radius: 50%;
                    background-color: #ccc;
                    margin-bottom: 58px;

                    &:before {
                        content: "";
                        position: absolute;
                        height: 160px;
                        background: #ccc;
                        width: 1px;
                        left: 21px;
                    }

                    &:nth-child(2):before {
                        top: 15px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .el-checkbox {
                    margin: 0;
                }

                .el-checkbox:first-child {
                    margin-bottom: 54px;
                }
            }
        }

        &.meal-row.add td.selection {
            .el-tooltip {
                position: initial;
                padding: 0;
            }

            .selection {
                .el-checkbox {
                    height: 70px;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #d9d9d9;
                    width: 100%;
                    justify-content: center;
                }

                .el-checkbox:first-child {
                    height: 55px;
                    margin-bottom: 0;
                }

                .el-checkbox:last-child {
                    border-bottom: none;
                }
            }
        }

        &.meal-row.add td.padding .set-meal {
            background-color: #fff;
        }

        &.meal-row.add td.visibility {
            border-right: 0;
            display: table-cell;

        }

        &.meal-row.add td:nth-child(3), &.meal-row.add td:nth-child(4) {
            background-color: #fff !important;
        }

        &.meal-row.add td:nth-child(3) {
            border-right: 0;
        }
        &.meal-row.addPackage {
            td:nth-child(1){
                border-right: 0;
                &>div {
                    width: 800px !important;
                }
            }
            td:nth-child(2){
                .el-button {
                    margin-left: 124px;
                }
            }
        }

        &.addChildren td {
            background-color: #fff0ef;
        }

        td.padding {
            padding: 0;

            .cell {
                padding: 0;

                .el-table__indent {
                    padding: 0 !important;
                }

                .el-table__placeholder {
                    display: none;
                }

                img {
                    margin-top: 10px;
                }
            }
        }

        &.meal-row td.visibility {
            display: inline-block;

            .cell {
                visibility: initial;

                .meal-right {
                    font-size: 12px;
                    font-weight: 600;
                    color: rgba(34, 34, 34, 1);
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    height: 30px;

                    .price {
                        color: #FF3B30;
                        padding-right: 16px;

                        s {
                            color: rgba(153, 153, 153, 1);
                            padding-left: 4px;
                        }
                    }

                    .input {
                        margin-right: 12px;
                    }
                }

                /deep/ .el-button--default {
                    height: 24px;
                    padding: 0 8px;
                }
            }
        }

        .cell {
            padding: 0 4px;
            font-size: 12px;
            font-weight: 500;
            color: rgba(38, 38, 38, 1);
        }


        .commodity-image {
            text-align: center;
        }

        .product-img {
            width: 50px;
            height: 50px;
        }

        /*重写计数器样式*/
        .el-input-number {
            width: 90px;
        }

        .el-input-number__decrease, .el-input-number__increase {
            width: 20px;
            height: 24px;
            background: #ffffff;
            box-sizing: border-box;
            border-radius: 1px;
            border: 1px solid rgba(230, 230, 230, 1);
        }

        .el-input-number__decrease {
            left: 0;
        }

        .el-input-number__increase {
            right: 0;
        }

        .el-input__inner {
            padding: 0 19px;
            height: 24px;
            line-height: 24px;
        }

        /*商品名称*/
        .table-product-name {
            display: flex;
            justify-content: center;
            flex-direction: column;
            p {
                font-size: 14px;
                font-weight: 600;
                color: rgba(34, 34, 34, 1);
                line-height: 20px;
                padding-bottom: 3px;
            }

            .table-row-tags {
                display: flex;
                flex-direction: row;
            }

            .row-tag {
                height: 18px;
                font-size: 12px;
                // font-weight: 500;
                line-height: 18px;
                border-radius: 3px;
                border-width: 1px;
                border-style: solid;
                margin-right: 4px;
                padding: 0 4px;
                box-sizing: border-box;
            }

            .tag-promotion {
                background: #FF6600;
                border: none;
                color: #ffffff;
            }

            .tag-cash {
                border-color: transparent;
                color: #025CD6;
                background-color: rgba(2, 92, 214, 0.1);
            }

            .tag-coupon {
                border-color: transparent;
                color: #FF6600;
                background-color: rgba(255, 102, 0, 0.1);
            }

            .tag-waring{
                border-color: transparent;
                color: #ffffff;
                background-color: red;
            }

            .tag-plus {
                border-color: transparent;
                color: #33AE67;
            }

            .tag-otc {
                border-color: transparent;
                background-color: #258C60;
                color: #fff !important;
                display: inline-block;
            }

            .jiaOtc {
                background: #E60012 !important;
            }

            .yiOtc {
                background: #33AE67 !important;
            }

            .rxOtc {
                background: #0091ff !important;
            }
        }

        /*库存*/
        .inventory-warning {
            font-weight: 600;
            color: rgba(255, 82, 0, 1);
        }

        /*操作栏*/
        .table-operation {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            span {
                width: 100%;
                cursor: pointer;
                margin-bottom: 5px;
                height: 20px;
                font-size: 12px;
                font-weight: 500;
                color: rgba(102, 102, 102, 1);
                line-height: 20px;
                padding: 0 4px;
                border-radius: 2px;
                border: 1px solid rgba(204, 204, 204, 1)
            }
        }

    }

    .el-table__row.meal-row {
        height: 40px;
    }
}

.commodity-image {
    .purchase-history-tag {
        position: absolute;
        padding: 0 4px;
        bottom: 0;
        height: 20px;
        line-height: 20px;
        background: rgba(250, 173, 20, 1);
        border-radius: 0 8px 0 0;
        font-size: 12px;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
    }
}

/deep/ .header-row {
    height: 40px;
    background: #E5E5E5;

    th {
        .cell {
            padding: 0 8px;
            font-size: 12px;
            font-weight: 600;
            color: rgba(34, 34, 34, 1);
        }
    }
}

.coupon-el-dialog__body /deep/ .el-dialog__body {
    max-height: 400px;
    overflow-y: auto;
}
</style>

<style lang="scss">
.shop-cart-content {
    .meal-row {
        td {
            &:not(:nth-child(-n+4)) {
                width: 0;
                height: 0;
                overflow: hidden;
                position: absolute;
            }

            .el-table__expand-icon {
                width: 0;
                height: 0;
                overflow: hidden;
                position: absolute;
            }
        }
    }
    .meal-row{
        .visibility{
            .cell{
                width: 501px !important;
            }
        }
    }

    .meal-row td:nth-child(1), .meal-row:hover td:nth-child(1), .meal-row td:nth-child(2), .meal-row:hover td:nth-child(2) {
        background-color: #FFF !important;
    }

    .meal-row td:nth-child(3), .meal-row:hover td:nth-child(3), .meal-row td:nth-child(4), .meal-row:hover td:nth-child(4) {
        background-color: #F1F7FE !important;
    }
}
/deep/ .disabled-checkbox {
    .is-disabled  {
        .el-checkbox__inner {
            background: #1890ff !important;
            border-color: #1890ff !important;
            &::after {
                border-color: #fff !important;
            }
        }
    }
}
.package-content {
    padding: 10px 20px 20px;
    box-sizing: border-box;
}
.el-dialog {
    .detail-header-right {
        .detail-swiper {
            .swiper-container {
                width: 940px;
            }
        }
    }
}

.swiper-container {
    max-height: 660px;
    overflow: auto;
}
</style>

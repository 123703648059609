var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "forget-password-from",
      attrs: { model: _vm.form, rules: _vm.formRules, "label-width": "0px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { prop: "password" } },
        [
          _c("el-input", {
            attrs: { placeholder: "设置登录密码", type: "password" },
            model: {
              value: _vm.form.password,
              callback: function ($$v) {
                _vm.$set(_vm.form, "password", $$v)
              },
              expression: "form.password",
            },
          }),
          _c("div", { staticClass: "input-icon" }, [
            _c("img", {
              staticClass: "img-suo",
              attrs: {
                src: require("@/assets/img/register/icon-suo.png"),
                alt: "",
              },
            }),
          ]),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { prop: "passwordAgain" } },
        [
          _c("el-input", {
            attrs: { placeholder: "再次输入登录密码", type: "password" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.next.apply(null, arguments)
              },
            },
            model: {
              value: _vm.form.passwordAgain,
              callback: function ($$v) {
                _vm.$set(_vm.form, "passwordAgain", $$v)
              },
              expression: "form.passwordAgain",
            },
          }),
          _c("div", { staticClass: "input-icon" }, [
            _c("img", {
              staticClass: "img-suo",
              attrs: {
                src: require("@/assets/img/register/icon-suo.png"),
                alt: "",
              },
            }),
          ]),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              staticClass: "sureBut",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.next()
                },
              },
            },
            [_vm._v("下一步")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
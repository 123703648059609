var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.show && _vm.imgUrl.length
      ? _c(
          "div",
          {
            staticClass: "mask",
            on: {
              touchmove: function ($event) {
                $event.preventDefault()
              },
              click: _vm.onMask,
            },
          },
          [
            _vm.imgUrl.length
              ? _c("div", { staticClass: "window" }, [
                  _c("img", {
                    attrs: { src: _vm.imgUrl[0].sysFileUrl, alt: "" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.bindUrl(_vm.imgUrl[0].link)
                      },
                    },
                  }),
                  _c("img", {
                    staticClass: "close",
                    attrs: {
                      src: require("../../assets/img/icon/icon-guanbi.png"),
                      alt: "",
                    },
                  }),
                ])
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
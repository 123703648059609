var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog-license",
          attrs: {
            width: "600px",
            title: _vm.zzOutOfTime ? "资质超期提醒" : "资质提醒",
            visible: _vm.dialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.zzOutOfTime
            ? _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  { staticClass: "text" },
                  [
                    _c("h5", [_vm._v("温馨提示")]),
                    _vm.license.isAllLicenseExpired === "Y"
                      ? _c("p", [
                          _vm._v(
                            "您有" +
                              _vm._s(_vm.outTimeAptitudesList.length) +
                              "张证照已过期"
                          ),
                          _vm.license.proxyExpired === "Y"
                            ? _c("i", [_vm._v(",并且委托书已过期")])
                            : _vm._e(),
                        ])
                      : _vm.license.isFailure === "Y"
                      ? _c(
                          "p",
                          [
                            _vm._v(" 您的证照"),
                            _vm._l(
                              _vm.outTimeAptitudesList,
                              function (item, index) {
                                return _c("span", { key: index }, [
                                  _vm._v(
                                    _vm._s(
                                      index ===
                                        _vm.outTimeAptitudesList.length - 1
                                        ? item.licenseNm
                                        : item.licenseNm + "，"
                                    )
                                  ),
                                ])
                              }
                            ),
                            _vm.license.proxyExpired === "Y"
                              ? _c("i", [_vm._v("和委托书")])
                              : _vm._e(),
                            _vm._v("已过期 "),
                          ],
                          2
                        )
                      : _vm.license.isAboutFailure === "Y"
                      ? _c(
                          "p",
                          [
                            _vm._v(" 您的证照"),
                            _vm._l(
                              _vm.noMoreTimeAptitudesList,
                              function (item, index) {
                                return _c("span", { key: index }, [
                                  _vm._v(
                                    _vm._s(
                                      index ===
                                        _vm.noMoreTimeAptitudesList.length - 1
                                        ? item.licenseNm
                                        : item.licenseNm + "，"
                                    )
                                  ),
                                ])
                              }
                            ),
                            _vm._v("即将过期"),
                            _vm.license.proxyExpired === "Y"
                              ? _c("i", [_vm._v("，并且委托书已过期")])
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                    _c("p", [
                      _vm._v("请及时联系业务员更换，以免影响您的正常采购！"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.toLicense },
                      },
                      [_vm._v("查看详情")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm.licenseAsRangesList.length > 0
            ? _c(
                "div",
                { staticClass: "text_CQ" },
                [
                  _c("h5", [_vm._v("温馨提示")]),
                  _c("p", [
                    _vm._v(
                      "您的" +
                        _vm._s(_vm.licenseAsRangesList.length) +
                        "张证照无经营范围"
                    ),
                  ]),
                  _c("p", [
                    _vm._v("请及时联系业务员更换，以免影响您的正常采购！"),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.toLicense },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="activity-decorate-content">
    <Search :index-nav="true"/>
    <div class="brand-content">
      <div class="brand-bg">
        <img :src="topImg" alt="">
      </div>
      <div class="brand">
        <!--搜索框-->

        <div data-v-7b59048f="" class="search-input el-input el-input--large el-input-group el-input-group--append" style="width: 98%;padding-block-end:10px">
          <!----><input type="text" autocomplete="off" placeholder="请输入商品名称" v-model="queryParams.condition.searchFiled" class="el-input__inner"><!----><!---->
          <div class="el-input-group__append">
            <button data-v-7b59048f="" type="button" @click="handleSearch" class="el-button search-button el-button--default"><!---->
            <i class="el-icon-search"></i><!---->
          </button>
          </div><!---->
        </div>
        <div class="goods-list" v-loading="pageLoading" v-if="tableData.length > 0">
          <div v-for="(product, index) in tableData" :key="index" class="commodity-item">
            <div class="commodity-item-img">
              <router-link :to="{path: '/detail/' + product.productId}">
                <img alt :src="product.picUrl || productDefaultImg"
                     @error="product.picUrl = productDefaultImg">
              </router-link>
            </div>
            <div class="commodity-item-info">
              <router-link :to="{path: '/detail/' + product.productId}">
                <p class="commodity-item-name">
<!--                  {{$util.getGoodsName(product.productNm)}}-->
                  {{product.drugNm}}
                </p>
              </router-link>

              <div class="commodity-item-detail">
                <p>{{ product.spec }}/{{ product.unit }}</p>
                <p>{{ product.manufacturer }}</p>
              </div>

              <div class="commodity-add-shopcart">
                <p class="commodity-price">¥{{product.price}}</p>
                <el-button class="code-button"
                           :disabled="isBanAddToCart"
                           @click="addItem(product.skuId, 'NORMAL',quantity,product)">
                  加入
                  <img src="../../../assets/img/icon/icon-gouwuche.png" alt />
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <el-empty v-else description="暂无数据"></el-empty>
        <!--分页-->
        <im-pagination
                v-if="total"
                :total="total"
                :hide-on-single-page="false"
                :current-page.sync="currentPage"
                :page-size.sync="queryParams.pageSize"
                :hideOnSinglePage="false"
                :isShowMainInfo="true"
                :pageSizes="pageSizesArr"
                @pagination="handlePagination"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import {
    addItem
  } from '@/api/detail'
  import productDefaultImg from '@/assets/img/index/product_default.png'
  import Search from '@/components/Search/Search'
  import {pageAreaActivityProduct, findPicByAreaActivityId} from '@/api/promotion-zone/activity-decorate/index'
  import addToCartMixin from '@/common/addToCart'
  export default {
    components: {
      Search,
    },
    mixins:[addToCartMixin],
    data () {
      return {
        searchForm: {
          searchParams: ''
        },
        pageSize: 10,
        total: 0,
        currentPage: 1,
        pageSizesArr: [12, 24, 36, 48, 60],
        queryParams: {
          pageSize: 12,
          pageNum: 1,
          condition: {
            searchFiled: "",
            areaActivityId: ''
          }
        },
        tableData: [],
        productDefaultImg,
        addToCartloading:false,
        pageLoading: true,
        quantity: 1,
        product: {
          pictUrls: '',
        },
        topImg: ''
      }
    },
    watch: {
      //监听路由地址的改变
      $route:{
        immediate:true,
        handler(){
          if(this.$route.query.areaActivityId){//需要监听的参数
            this.queryParams.condition.areaActivityId = this.$route.query.areaActivityId
            this.tableData = ''
            this.total = ''
            this.topImg = ''
            this.getDate()
            this.getTopImg()
          }
        }
      }
    },
    created (){
      this.getDate()
      this.queryParams.condition.areaActivityId = this.$route.query.areaActivityId
      this.getTopImg()
    },
    methods: {
      getDate() {
        pageAreaActivityProduct(this.queryParams).then(res=> {
          this.tableData = res.data.records
          this.total = res.data.total
        })
      },
      handlePagination (data) {
        this.queryParams.pageNum = Math.min(this.currentPage, Math.ceil(this.total / data.pageSize))
        this.getDate()
      },
      handleSearch(){
        this.queryParams.pageNum = 1
        this.getDate()
      },
      async getTopImg() {
        this.pageLoading = true
        let result = await findPicByAreaActivityId(this.$route.query.areaActivityId)
        if (result.code == 0) {
          this.topImg = result.data.picUrl
          this.pageLoading = false
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .activity-decorate-content {
    .brand-content {
      position: relative;
      overflow-y: hidden;
      min-width: 1200px;

      .brand-bg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 440px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .brand {
        width: 1220px;
        margin: 40px auto 0;
      }
    }
    .goods-list {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      .commodity-item {
        width: 190px;
        margin: 10px 0 0 10px;
        border: 1px solid #e7e7e7;
        background-color: #fff;
        .commodity-item-img {
          margin: 0 auto;
          padding-top: 16px;
          img {
            cursor: pointer;
            width: 144px;
            height: 144px;
          }
        }
        .commodity-item-info {
          padding: 10px 20px;
          font-size: 14px;
          font-weight: bold;
          text-align: left;
          .commodity-item-name {
            cursor: pointer;
            margin-bottom: 6px;
            overflow: hidden;
            height: 1em;
          }
          .commodity-item-detail {
            font-size: 12px;
            color: #666;
            font-weight: normal;
            p {
              line-height: 1.4em;
              overflow: hidden;
              height: 1.4em;
            }
          }
          .commodity-price {

            font-size: 18px;
            color: #FF3B30;
          }
        }
        .commodity-add-shopcart {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin: 15px 0px 0px;
          .code-button {
            border: none;
            padding: 4px 10px;
            background: #ff7d18;
            color: #fff;
            font-size: 12px;
            line-height: 14px;
            img {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }

    .commodity-item, .product-item {
      position: relative;

    }
    .identifying {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 32px;
      height: 32px;
      z-index: 10;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .im-pagination {
      z-index: 10;
    }
  }
</style>

<template>
  <div v-if="this.isAuthInquiryBox == 'Y'">

    <!-- <div class="procurement-plan-list"> -->
    <im-page-modal class="procurement-plan-list" v-loading="loading" title="发布询价单" :isShowBack="false">
      <div class="aptitudes" v-if="license.isFailure === 'Y'">温馨提示：{{ userType !== 'SALESMAN' ? '您有' +
        license.failureSum +
        '张证照已过期,请联系业务员更换,以免影响您的正常采购！' : '该客户有' + license.failureSum + '张证照已过期，以免影响正常采购！' }}<el-button size="small"
          type="text" @click="handleConfirm">查看详情</el-button>
      </div>
      <!-- 按钮区域 -->
      <div class="page-header-btn">
        <el-button class="import" type="primary" @click="handleAdd">
          Excel批量导入询价
        </el-button>
        <div class="right">
          <el-button size="small" @click="exportExcel">导出询价单</el-button>
          <el-button size="small" @click='handleGoToDraft()'>我的草稿箱</el-button>
        </div>
      </div>
      <div class="table-padding">
<!--        @selection-change="(val) => { selectItem = val }"-->
        <el-table ref="tablePage" :data="tableData" :class="{ 'is-tabledata-empty': tableData.length === 0 }" default-expand-all border
          style="width: 100%" row-key="skuId" @row-click="handleRowClick" :tree-props="{children: 'otherSkulist', hasChildren: 'hasChildren'}">
          <el-table-column label="序号" width="80px" show-overflow-tooltip align="center" fixed="left">
            <template slot-scope="scope">
<!--              <span>{{ scope.$index + 1 }}</span>-->
              <span v-if="scope.row.index || scope.row.index === 0">{{ scope.row.index + 1 }}</span>
            </template>
          </el-table-column>
<!--          <el-table-column type="selection" width="55" align="center" fixed="left" />-->
          <el-table-column label="商品编码" prop="productCode" min-width="100px" show-overflow-tooltip />
          <el-table-column label="商品名称" prop="productNm" min-width="200px" show-overflow-tooltip>
            <template slot-scope="scope">
              <fast-order-search
                v-if="scope.row.index || scope.row.index === 0"
                :ref="'productNm' + scope.$index"
                class="inputName"
                :index="scope.row.index"
                :id="'search'+scope.$index"
                :productNm="$util.getGoodsName(scope.row)"
                @keyup.46.native.stop.prevent="handleDelete(scope.row,scope.$index)"
                @getIndex="getIndex"
                @fast_order_fu="selectRow"
              >
              </fast-order-search>
              <span v-else>{{scope.row.drugNm}}({{scope.row.productNm}})</span>
            </template>
          </el-table-column>
          <el-table-column label="规格" prop="spec" min-width="100px" show-overflow-tooltip />
          <el-table-column label="单位" prop="unit" min-width="80px" show-overflow-tooltip />
          <el-table-column label="区域" prop="orgNm" min-width="170px" show-overflow-tooltip />
          <el-table-column label="单价" prop="price" align="right" min-width="100px" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.price }}￥</span>
            </template>
          </el-table-column>
          <el-table-column label="厂家(上市许可持有人)/产地" prop="manufacturer" width="200px" show-overflow-tooltip />
          <el-table-column label="件包装" prop="useCommodityTypes" min-width="100px" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.packTotal || '--' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="最小购买倍数" prop="minBuyMultiple" min-width="100px" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getRealMinBuyNum(scope.row) }}
            </template>
          </el-table-column>
          <el-table-column label="库存" prop="stockQuantity" min-width="120px" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ userType === 'BUYERS' ? scope.row.stockQuantity > 100 ? '>100' : '<100' : scope.row.stockQuantity }}
                </template>
          </el-table-column>
<!--          <el-table-column label="数量" prop="buyNm" min-width="170px" show-overflow-tooltip align="center" fixed="right">-->
<!--            <template slot-scope="scope">-->
<!--              <el-input-number size="mini" v-model="scope.row.buyNm" :precision="getDecimalPointNum(scope.row)" :step="scope.row.productId ? getRealMinBuyNum(scope.row) : 1" :min='scope.row.productId ? getRealMinBuyNum(scope.row) : 0' step-strictly-->
<!--                @change="handleChangeNum(scope.row)" />-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column label="操作" width="80px" fixed="right">
            <template slot-scope="scope">
              <el-row class="table-edit-row">
                <span class="table-edit-row-item">
                  <el-button size="small" style="padding:2px 4px;"
                    @click="deleteNode(scope.row)">删除</el-button>
                </span>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 表格为空默认替换 -->
      <div v-if="tableData.length === 0" class="empty-default">
        <div class="empty-box">
          <el-image style="width: 160px; height: 160px" :src="emptyDefault" fit="fill" />
          <p class="empty-tip">暂无信息~</p>
        </div>
      </div>
      <el-button size="small" class="addPro" @click="addPro()">
        +添加
      </el-button>
      <div slot="footer" class="content-footer">
        <div class="left-con" v-if="false">
          <el-checkbox v-model="AllChecked" style="margin-right:20px;" @change="handleAllSelect">全选</el-checkbox>
          <span class="text-detail">共 <span style="color:#0971EB;padding:0 2px">{{ selectItem.length ? selectItem.length
            :
              0}}</span>种商品，总数量<span style="color:#0971EB;padding:0 2px">{{ totalNum }}</span></span>
        </div>
        <div class="right-con">
          <span class="text-one" v-if="false">合计:</span>
          <span class="text-two" v-if="false">&yen;{{ $util.toFixed(totalPrice) }}</span>
<!--          <el-button @click="onCommit" v-if="isLicenseOutOfTime" :loading='loading' disabled>证照过期</el-button>-->
<!--          <el-button class="btn-two" type="primary" @click="onCommit" :disabled="isBanAddToCart" v-else-->
<!--            :loading='loading'>加入购物车</el-button>-->
          <el-button class='btn-one' @click="() => { this.$refs.AddConsultPrice.open() }">保存草稿</el-button>
        </div>
      </div>
      <input-excel ref="inputExcel" @uploadResult="uploadResult" :tableData.sync='tableData' :resData.sync='resData'
        :formWay.sync='formWay' />
      <!-- 保存询价草稿 -->
      <add-consult-price-title ref="AddConsultPrice" :consultTitle.sync="title" @handleSaveDraft="handleSaveDraft" />
      <el-dialog title="导入提示" top="30vh" v-if="visibleIsInputSuccess" :visible.sync="visibleIsInputSuccess"
        :modal="false" width="15%">
        <h3 class="tip-title"><i class="icon-success"></i>恭喜你，导入成功！</h3>
        <p class='tip-detail' v-if="resData.errCount">其中有{{ resData.errCount }}个无效编码，详情
          <el-button type="text" @click="viewNotes(resData.errList)">查看备注</el-button>
          。
        </p>
      </el-dialog>
      <el-dialog title="导入提示" top="30vh" v-if="visibleIsInputFail" :visible.sync="visibleIsInputFail" :modal="false">
        <h3 class="tip-title"><i class="icon-error" />导入失败!</h3>
        <p class='tip-detail'>文件格式错误！请检查Excel文件格式</p>
        <p class='tip-detail'> 或检查匹配方式是否正确。</p>
      </el-dialog>
      <el-dialog title="查看备注" top="30vh" width="1000px" :visible.sync="visibleViewNotes">
        <p class="noViewNotes">以下商品存在编码无效</p>
        <el-table :data="tableDataNotes" border max-height="200" style="width: 100%">
          <el-table-column type="index" label="序号" width="60">
          </el-table-column>
          <el-table-column prop="erpCode" label="商品编码" min-width="120" show-overflow-tooltip width="100">
          </el-table-column>
          <el-table-column prop="productNm" label="商品名称" min-width="120" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="spec" label="规格">
          </el-table-column>
          <el-table-column prop="unit" label="单位">
          </el-table-column>
          <el-table-column prop="manuFacturer" label="生产厂家">
          </el-table-column>
          <el-table-column prop="approvalNumber" label="国药准字" v-if="formWay === 'INQUIRYBOXDETAILGY'">
          </el-table-column>
          <el-table-column prop="barCode" label="商品条形码(69码)"
            v-if="formWay === 'INQUIRYBOXDETAILKH' || formWay === 'INQUIRYBOXDETAILSP'">
          </el-table-column>
          <el-table-column prop="buyNm" label="购买数量">
          </el-table-column>
        </el-table>
        <!-- <span style="color:red">*</span>询价单标题:<el-input style="width:200px;margin-left:20px;" v-model="title" palceholder="请为该询价单填写标题"></el-input> -->
        <div class="dialog-footer">
          <!-- <el-button type="primary" @click="handleSaveDraft" size="small">确认</el-button> -->
          <el-button @click="visibleViewNotes = false" size="small">返回</el-button>
        </div>
      </el-dialog>
    </im-page-modal>
    <!-- </div> -->
  </div>
  <div v-else class="no-auth">
    询价功能暂未开放
  </div>
</template>
<script>
import inputExcel from './components/input-excel'
import AddConsultPriceTitle from './components/add-consult-price'
import { saveOrUpdateInquiryBox, exportExcel, getConsultPrice, inTransferGroup } from '@/api/member-center/transactions-using'
import emptyDefault from '@/assets/img/member-center/content-empty-default.png'
import { batchAddItem } from '@/api/detail'
import { mapGetters } from 'vuex'
import addToCartMixin from '@/common/addToCart'
import fastOrderSearch from './components/fastOrderSearch'
export default {
  name: 'ConsultPriceCoulumn',
  mixins: [addToCartMixin],
  components: {
    inputExcel,
    AddConsultPriceTitle,
    fastOrderSearch
  },
  props: {
    params: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      visibleViewNotes: false,
      AllChecked: false,
      loading: false,
      searchFiled: '',
      indexInput: 0,
      tableData: [],
      tableDataNotes: [],
      selectItem: [],
      totalPrice: 0,
      totalNum: 0,
      title: '',
      formWay: '',
      resData: {},
      visibleIsSaveDraft: false,
      visibleIsInputSuccess: false,
      visibleIsInputFail: false,
      searchForm: {
        activityNm: ''
      },
      emptyDefault
    }
  },
  computed: {
    ...mapGetters(['license', 'userType', 'buyersVo', 'isAuthInquiryBox'])
  },
  mounted () {
    this.$store.dispatch('user/getInfo')
  },
  watch: {
    params: {
      handler(newVal) {
        if (newVal.refreshList) {
          this.doRefresh()
        }
      },
      deep: true
    },
    selectItem(newVal) {
      this.AllChecked = newVal.length === this.tableData.length
      this.checkSum(newVal)
    },
    visibleIsInputFail(newVal) {
      if (newVal) {
        setTimeout(() => { this.visibleIsInputFail = false }, 4000)
      }
    },
    visibleIsInputSuccess(newVal) {
      if (newVal) {
        setTimeout(() => { this.visibleIsInputSuccess = false }, 4000)
      }
    },
    tableData(newVal) {
      this.tableData.forEach((item, index)=> {
        this.tableData[index]['index'] = index
      })
    },
    '$store.state.user.refreshModule': {
      handler() {
        this.tableData = []
      },
      deep: true
    }
  },
  
  methods: {
    handleSearch() {
      console.log('searchFiled', this.searchFiled)
    },
    uploadResult(val) {
      console.log(val)
      if (!!val) {
        this.visibleIsInputSuccess = true
        this.visibleIsInputFail = false
      } else {
        this.visibleIsInputFail = true
        this.visibleIsInputSuccess = false
      }
    },
    viewNotes(list) {
      this.tableDataNotes = list
      this.visibleViewNotes = true
    },
    handleConfirm() {
      this.$router.push(`/member-center/personal-center/my-aptitudes`)
    },
    doRefresh() {
      this.$refs.tablePage.doRefresh({ currentPage: 0, pageSize: 10 })
    },
    queryData(data) {
      //  return pageAdditionalActivity({ ...data, condition: this.searchForm })
      // var tableData = [{},{}]
      // return Promise.resolve({
      //       code: 0,
      //       msg: '',
      //       data: {
      //         records: tableData,
      //         total: tableData.length
      //       }
      //   })
    },
    updated() {
      this.$refs.tablePage.doLayout()
    },
    handleAdd() {
      this.$refs.inputExcel.open()
    },
    //保存草稿
    handleSaveDraft() {
      this.visibleIsSaveDraft = false
      if (!this.tableData.length) {
        this.$message.error('询价商品不能为空')
        return
      }
      var inquiryBoxDetailSaveVoList = []
      this.tableData.map((item, index) => {
        var newObj = {
          inquiryBoxDetailId: item.inquiryBoxDetailId,
          productId: item.productId,
          inquiryDraftBoxId: item.inquiryDraftBoxId,
          productNm: item.productNm,
          drugNm: item.drugNm,
          pageSkuVo: this.tableData[index]
          //  skuID:item.skuId
        }
        inquiryBoxDetailSaveVoList.push(newObj)
      })
      var params = {
        title: this.title,
        inquiryDraftBoxId: '',
        inquiryBoxDetailSaveVoList
      }
      saveOrUpdateInquiryBox(params).then(res => {
        this.$message.success(res.msg)
      })
    },
    /**
     * @return {number}
     */
    ReformatPrice(num) {
      return Number(num.split(',').join(''))
    },
    checkSum(newVal) {
      var totalNum = 0
      var totalPrice = 0
      newVal.map(item => {
        if (item.buyNm) {
          totalNum += item.buyNm
          totalPrice += item.buyNm * this.ReformatPrice(item.price)
        }
      })
      this.totalNum = totalNum
      this.totalPrice = totalPrice.toFixed(2)
    },
    handleGoToDraft() {
      this.$router.push({ path: '/member-center/transactions-using/consult-price-drafts' })
    },
    handleAllSelect() {
      this.$refs.tablePage.toggleAllSelection()
    },
    handleChangeNum(row) {
      this.checkSum(this.selectItem)

    },
    onCommit() {
      this.loading = true
      if (!this.selectItem.length) {
        this.loading = false
        this.$message.warning('请选择商品')
      } else {
        let itemList = this.selectItem.map(res => {
          return {
            skuId: res.skuId,
            quantity: res.buyNm
          }
        })
        batchAddItem({ itemList }).then(res => {
          const { code } = res
          if (code === '0') {
            this.loading = false
            this.$message.success('加入成功！')
            this.$store.dispatch('user/setCartNum', res.data.allProductTypeTotalQuantity)
          }
        }).catch(err => {
          this.loading = false
        })
      }
    },
    exportExcel() {
      let data = []
      this.tableData.map(val => {
        if (val.productCode !== ''){
          data.push({
            ...val,
            erpCode: val.erpCode,
            productNm: this.$util.getGoodsName(val),
            spec: val.spec,
            unit: val.unit,
            manufacturer: val.manufacturer,
            approvalNumber: val.approvalNumber,
            productCode: val.productCode,
            barCode: val.barCode,
            buyNm: val.buyNm,
            price: val.price,
            packTotal: val.packTotal,
            minBuyMultiple: this.getRealMinBuyNum(val)
          })
        }
      })
      exportExcel({ inquiryBoxDetailVoList: data }).then(res => {
        // const content = res.data
        // const fileNamex = '询价单-' + (new Date()).valueOf() + '.xlsx'
        // const blob = new Blob([content])
        // if ('download' in document.createElement('a')) { // 非IE下载
        //   const elink = document.createElement('a')
        //   elink.download = fileNamex
        //   elink.style.display = 'none'
        //   elink.href = URL.createObjectURL(blob)
        //   document.body.appendChild(elink)
        //   elink.click()
        //   URL.revokeObjectURL(elink.href) // 释放URL 对象
        //   document.body.removeChild(elink)
        // } else { // IE10+下载
        //   navigator.msSaveBlob(blob, fileNamex)
        // }
        let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
        const disposition = res.headers['content-disposition'].split(";")[1].split("=")[1];
        var fileName = decodeURI(disposition);
        //去掉双引号
        fileName = fileName.replace(/\"/g, "");
        let link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = fileName
        link.click()
        link.remove()
        this.$message.success('下载成功')
      })
    },
    // 增加一列商品
    addPro() {
      let startData = {
        skuId: new Date().getTime() + '' + this.tableData.length,
        productId: '',
        orgId: '',
        productCode: '',
        productNm: '',
        minBuyMultiple: '-/-',
        step: 1,
        manufacturer: '-/-',
        rate: '-/-',
        area: '',
        spec: '-/-',
        unit: '-/-',
        medicarePrice: '-/-',
        lastSellPrice: '-/-',
        assessmentPrice: '-/-',
        packTotal: '-/-',
        retailPrice: '-/-',
        stockQuantity: -1,
        stockQuantityStatus: '',
        otcType: '',
        price: '-/-',
        buyersPrice: '-/-',
        buyNum: ''
      }
      this.tableData.push(startData)
      // this.$nextTick(() => {
      //   this.$refs.tablePage.bodyWrapper.scrollTop = this.$refs.tablePage.bodyWrapper.scrollHeight
      // })
    },
    // 删除商品
    handleDelete(data, index) {
      this.tableData.splice(index, 1)
    },
    getIndex(index) {
      this.indexInput = index
    },
    handleRowClick() {

    },
    async selectRow(item) {
      let res = await getConsultPrice({erpCode: item.productCode})
      let isExist = this.tableData.some((item, num) => item.productCode === res.data.list[0].productCode)
      if (isExist) {
        this.$message.error('该商品已存在')
        return
      }
      if (res.data === null) {
        this.$message.error(res.msg)
        return
      }
      this.tableData.splice(this.indexInput, 1, res.data.list[0])
      this.$forceUpdate();
    },
    deleteNode(node) {
      // 递归查找要删除的节点的父节点
      const findParent = (nodes, nodeId) => {
        for (let i = 0; i < nodes.length; i++) {
          if (nodes[i].otherSkulist && nodes[i].otherSkulist.find(child => child.skuId === nodeId)) {
            return nodes[i];
          } else if (nodes[i].otherSkulist && nodes[i].otherSkulist.length > 0) {
            const parent = findParent(nodes[i].otherSkulist, nodeId);
            if (parent) return parent;
          }
        }
        return null;
      };

      const parent = findParent(this.tableData, node.skuId);
      if (parent) {
        parent.otherSkulist = parent.otherSkulist.filter(child => child.skuId !== node.skuId);
      } else {
        // 如果没有找到父节点，则直接在根节点数组中筛选
        this.tableData = this.tableData.filter(item => item.skuId !== node.skuId);
      }
    }
  }
}
</script>
<style lang="scss">
.noViewNotes {
  margin: 0 0 20px 0;
  height: 50px;
  width: 100%;
  line-height: 50px;
  background: #F1F7FE;
  text-align: left;
  font-size: 14px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #222222;
}

.dialog-footer {
  height: 50px;
  margin: 20px 0 0 0;
  line-height: 50px;
  background: whitesmoke;
}

.aptitudes {
  line-height: 40px;
  font-size: 14px;
  color: #555;
  background-color: #FFF0EF;
  margin-bottom: 32px;
}

.procurement-plan-list {
  background-color: white;

  .header {
    margin: 0;
    font-size: 16px;
    color: #262626;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    text-align: left;
    padding: 0 28px;
  }

  .content {
    padding: 10px 0 20px;
  }

  .footer {
    box-shadow: none;
    border-top: 1px solid #ccc;
    height: 60px;
  }

  .page-header-btn {
    .el-input__inner {
      height: 30px;
      line-height: 30px
    }
  }

  .el-dialog {
    min-height: 180px;
    min-width: 400px;

    .el-dialog__header {
      text-align: left;
      height: 36px;
      line-height: 36px;
      background: rgba(245, 245, 245, 1);
      border-radius: 2px;
      padding: 0 20px;
    }

    .el-dialog__title {
      font-size: 14px;
      font-weight: 500;
      color: rgba(34, 34, 34, 1);
    }

    .el-dialog__body {
      padding-top: 20px;
    }

    .el-dialog__headerbtn {
      top: 10px;
      right: 16px;
    }

    .el-input__inner {
      height: 32px;
    }

    .el-dialog__footer {
      background: rgba(245, 245, 245, 1);
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      padding: 0;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .tip-title {
      text-align: left;
      font-size: 16px;
      color: rgba(34, 34, 34, 1);
      display: flex;
      align-items: center;

      .icon-success {
        margin-right: 4px;
        display: inline-block;
        width: 32px;
        height: 32px;
        background: url('../../../../assets/img/member-center/upload-success.png') no-repeat;
      }

      .icon-error {
        margin-right: 4px;
        display: inline-block;
        width: 32px;
        height: 32px;
        background: url('../../../../assets/img/member-center/upload-error.png') no-repeat;
      }
    }

    .tip-detail {
      margin-top: 12px;
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 22px;
    }
  }

  .is-tabledata-empty {
    .el-table__body-wrapper {
      display: none
    }
  }
}
</style>
<style scoped lang="scss">
.procurement-plan-list {
  min-height: 100% !important;
  margin: 0 auto;

  .page-header-btn {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;

    /deep/ .el-button.import {
      line-height: 35px;
      height: 35px;
      padding: 0 20px;
    }

    .right {
      font-style: normal;
      font-size: 12px;
      color: #666666;

      .el-input__inner {
        height: 30px;
      }
    }
  }

  .content-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
    box-sizing: border-box;

    .left-con {
      .text-detail {
        font-size: 12px;
        font-weight: 500;
        color: rgba(102, 102, 102, 1);
        line-height: 17px;
      }
    }

    .right-con {
      display: flex;
      align-items: center;

      .text-one {
        font-size: 14px;
        font-weight: 500;
        color: rgba(34, 34, 34, 1);
        padding-right: 5px;
      }

      .text-two {
        font-size: 20px;
        font-weight: 500;
        color: rgba(224, 32, 52, 1);
        margin-right: 15px;
      }

      .btn-one {
        display: inline-block;
        height: 36px;
        line-height: 36px;
        padding: 0 35px;
        border-radius: 2px;
      }

      .btn-two {
        display: inline-block;
        height: 36px;
        line-height: 36px;
        padding: 0 35px;
        border-radius: 2px;
      }
    }
  }

  .table-padding {
    padding: 0 20px;
    margin-bottom: 20px;
  }

  .empty-default {
    display: flex;
    height: auto;
    justify-content: center;
    padding-top: 80px;
    padding-bottom: 40px;

    .empty-tip {
      height: 20px;
      font-size: 14px;
      font-weight: 500;
      color: rgba(113, 113, 113, 1);
      line-height: 20px;
    }
  }
}
.no-auth{
  width: 100%;
  height: 100vh;
  background: #fff;
  padding-top: 60px;
}

.addPro {
  cursor: pointer;
  width: calc(100% - 40px);
  margin: 0 20px;
  padding: 0;
  height: 36px;
  background: white;
  border-radius: 4px;
  border: 1px dashed #cccccc;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  @include TextHighlightColor;
  line-height: 31px;;
  box-sizing: border-box;
}
</style>

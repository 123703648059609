<template>
  <div class="interfaceOrderDiv">
    <div class="title">订单执行情况</div>
    <!-- 搜索区域 -->
    <el-form :inline="true" :model="searchForm" class="searchForm" size="small" label-width="70px">
      <el-form-item label="客户编码">
        <el-input class="searchFormItem" v-model="searchForm.customerCode" placeholder="输入客户编码"></el-input>
      </el-form-item>
      <el-form-item label="门店编码">
        <el-input class="searchFormItem" v-model="searchForm.storeCode" placeholder="输入门店编码"></el-input>
      </el-form-item>
      <el-form-item label="门店名称">
        <el-input class="searchFormItem" v-model="searchForm.storeNm" placeholder="输入门店名称"></el-input>
      </el-form-item>
      <el-form-item label="商品名称" v-show="isExpanded">
        <el-input class="searchFormItem" v-model="searchForm.productNm" placeholder="输入商品名称"></el-input>
      </el-form-item>
      <el-form-item label="配送地址" v-show="isExpanded">
        <el-input class="searchFormItem" v-model="searchForm.sendAddr" placeholder="输入配送地址"></el-input>
      </el-form-item>
      <el-form-item label="要货时间" v-show="isExpanded">
        <el-date-picker
            v-model="searchForm.time"
            class="dateFormItem"
            type="daterange"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            size="small"
            align="right"
            unlink-panels
            range-separator="至"
            @change="changeDatePicker"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item class="formBut">
        <el-button type="primary" size="mini" @click="search">搜索</el-button>
        <el-button type="text" size="mini" @click="reset">
          <span style="color: #000000">重置</span>
        </el-button>
        <div style="width: 1px;height: 12px;background-color: #E5E5E5;margin: 0 8px;margin-top: 8px;"></div>
        <el-button type="text" size="mini" @click="onExpand" style="color:#000000;font-weight:400;">
          {{ isExpanded? '收起' : '展开' }}
          <i :class="isExpanded ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" />
        </el-button>
      </el-form-item>
    </el-form>
    <div class="tableDiv" v-loading="loading">
      <im-table-page
          ref="tablePage"
          :data.sync="tableData"
          :remote-method="remoteMode"
          :cell-class-name="cellClassName"
          :header-cell-class-name="cellClassName"
          class="table">
        <el-table-column label="接口数据" show-overflow-tooltip align="center">
          <el-table-column label="客户编码" prop="customerCode" width="60" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column label="门店编码" prop="storeCode" width="60" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column label="分支机构编码" prop="code" width="80" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column label="门店名称" prop="storeNm" width="80" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column label="订单编号" prop="distributionOrderNum" width="80" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column label="第三方商品编码" prop="thirdProductCode" width="80" show-overflow-tooltip align="center"></el-table-column>
          <!--            <el-table-column label="商品编码" prop="imallProductCode" width="80" show-overflow-tooltip ></el-table-column>-->
          <el-table-column label="商品名称" prop="productNm" width="100" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column label="规格" prop="spec" width="80" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column label="厂家(上市许可持有人)/产地" prop="manufacturer" width="110" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column label="数量" prop="quantity" width="60" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="价格" prop="askingPrice" align="right" min-width="70" show-overflow-tooltip ></el-table-column>
        </el-table-column>
        <el-table-column label="商城数据" min-width="300" align="center">
          <el-table-column label="商品编码" prop="imallProductCode" width="60" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column label="规格" prop="spec" width="80" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column label="数量" prop="quantity" width="60" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="价格" align="right" prop="askingPrice" min-width="70" show-overflow-tooltip ></el-table-column>
        </el-table-column>
      </im-table-page>
    </div>
  </div>
  <!--  </div>-->
</template>

<script>
import { pageDistributionOrders } from '@/api/member-center/consigned-delivery'
export default {
  name: "dataExecution",
  data(){
    return{
      searchForm: {
        type: 'dataProcessSearch',
        customerCode: '',
        sendAddr: '',
        productNm: '',
        storeCode: '',
        startTime: '',
        endTime: '',
        storeNm: '',
        isPriceMatch: '',
        isPriceHigh: '',
        unmatched: 'N',
        time: []
      },
      price: '',
      checkItems: [],
      dataList: [],
      tableData: [],
      isExpanded: false,
      checked: false,
      isPriceMatch: false,
      isPriceHigh: false,
      unmatched: false,
      loading: false,
      currentPage: 0,
      pageSize: 30,
      total: 0,
      multipleSelection: [],
      selectList: [],
      allChecked: false
    }
  },
  methods: {
    search() {
      this.doRefresh()
    },
    reset() {
      this.searchForm = {
        type: 'dataProcessSearch',
        customerCode: '',
        sendAddr: '',
        productNm: '',
        storeCode: '',
        startTime: '',
        endTime: '',
        storeNm: '',
        isPriceMatch: '',
        isPriceHigh: '',
        unmatched: 'N',
        time: []
      }
      this.doRefresh()
    },
    changeDatePicker(dateList) {
      console.log(this.searchForm.time)
      this.searchForm.startTime = dateList[0] + ' 00:00:00'
      this.searchForm.endTime = dateList[1] + ' 23:59:59'
    },
    remoteMode(pageParam){
      if( pageParam.pageSize < 30 ){
        pageParam.pageSize = 30
      }
      return pageDistributionOrders({
        ...pageParam, condition: this.searchForm
      })
    },
    checkBox(val) {
      this.checkItems = val
      console.log(this.checkItems)
    },
    onExpand() {
      this.isExpanded = !this.isExpanded
    },
    doRefresh() {
      this.$refs.tablePage.doRefresh({currentPage: 0, pageSize: 30, condition: this.searchForm})
    },
    cellClassName ({ row, column, rowIndex, columnIndex }) {
      return 'cellFont'
    }
  },
  mounted() {
    let date = new Date();
    let year = date.getFullYear(); // 年
    let month = date.getMonth() + 1; // 月
    let day = date.getDate(); // 日
    this.searchForm.time = [year + '-' + month + '-' + day,year + '-' + month + '-' + day]
    this.searchForm.startTime = year + '-' + month + '-' + day + ' 00:00:00'
    this.searchForm.endTime = year + '-' + month + '-' + day + ' 23:59:59'
  }
}
</script>

<style lang="scss" scoped>
.interfaceOrderDiv {
  background-color: #FFFFFF;
  text-align: left;
  padding: 0 20px;
  min-height: 780px;
  border-radius:8px 8px 0px 0px;
  .title {
    color: #2B2B2B;
    font-size: 16px;
    line-height: 50px;
    height: 50px;
    padding-left: 8px;
    font-weight: 700;
    border-bottom: #E5E5E5 solid 1px;
  }
  .searchForm{
    border-bottom: 1px solid #E5E5E5;
    padding: 18px 20px 0 20px;
    .searchFormItem{
      width: 147px;
      margin-right: 10px;
    }
    .dateFormItem {
      width: 220px;
      margin-right: 10px;
    }
    .rightCheck{
      line-height: 34px;
    }
    .formBut /deep/.el-form-item__content{
      display: flex;
    }
  }
  .tableDiv {
    padding-bottom:18px;
    margin-top: 16px;
    .tableTitle{
      width: 100%;
      height: 40px;
      background: rgba(245,245,245,1);
      border-radius: 4px 4px 0px 0px;
      display: flex;
      line-height: 40px;
      font-size: 14px;
      font-weight:400;
      color:rgba(102,102,102,1);
    }
    .noData {
      text-align: center;
      padding-top: 15px;
      font-size: 14px;
      color: #909399;
    }
    .tableItem{
      height:40px;
      background:rgba(241,247,254,1);
      border:1px solid rgba(229,229,229,1);
      border-bottom: none;
      line-height: 40px;
      margin-top: 8px;
      font-size:12px;
      font-weight:500;
      color:rgba(102,102,102,1);
      span{
        margin-right: 15px;
      }
    }
    .table{
      .price {
        color: #ffffff;
        border-radius: 2px;
        height: 100%;
        text-align: center;
        margin: 0 3px;
        padding: 0 5px;
      }
      .priceIcon {
        margin-top: 4px;
        width: 12px;
        height: 14px;
      }
    }
    .table-page {
      margin-top: 15px;
    }
  }
  /deep/.el-form-item--small.el-form-item{
    margin-bottom: 18px;
  }
  /deep/ .is-group .cell{
    color:rgba(102,102,102,1);
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
  }
}
</style>

<style lang="scss">
.cellColor .cell{
  font-size:12px;
  font-weight:500;
  color:rgba(102,102,102,1);
  line-height:20px;
  white-space: pre-line;
  font-family:PingFangSC-Medium,PingFang SC;
}
.cellFont .cell {
  font-size: 12px!important;
}
</style>

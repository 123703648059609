<template>
  <div class="swiper-container">
    <!--    自选套餐专区-->
    <div class="more" v-for="(item,index) in swiperData" :key="index">
      <div class="header">/ {{item.activityNm}} /</div>
      <div class="detail-header-right">
        <div class="detail-swiper">
          <div v-for="(list,i) in item.activityProductInfoPage.records" :key="i">
            <div class="detail-header-right-content">
              <div class="identifying" v-if="userType =='SALESMAN'">
                <img v-if="list.whiteRecordFlag  == 1" alt src="@/assets/img/icon/whiterecord.png">
                <img v-else-if="list.blackRecordFlag  == 1" alt src="@/assets/img/icon/blackrecord.png">
              </div>
              <div class="detail-header-right-img">
                <router-link :to="'/detail/' + list.productId">
                  <img :src="list.picUrl || productDefaultImg" @error="list.picUrl = productDefaultImg" alt/>
                </router-link>
                <span>{{list.quantity}}件</span>
              </div>
              <router-link :to="'/detail/' + list.productId">
                <p class="name">
                  {{$util.getGoodsName(list)}}
                </p>
              </router-link>
              <p class="num">规格：<span>{{ list.spec }}</span> 单位：{{ list.unit }}</p>
              <p class="company" :title="list.manufacturer">{{list.manufacturer}}</p>
              <p class="price" v-if="item.discountPackageType === 'FIXED_PACKAGE'">
                <span v-if="isLogin">¥{{ $util.toFixed(list.salePrice) }}</span>
                <span v-else>登录可见</span></p>
              <p @click="onSelectIcon('COLLOCATION_PACKAGE', index, i)" class="price img" v-else>
                <img :src="list.select ? select : selectNo" alt/>
                <span v-if="isLogin">¥{{$util.toFixed(list.salePrice)}}</span>
                <span v-else>登录可见</span>
              </p>
              <div class="buy-num">
                <el-input-number
                    v-if="isLogin"
                    v-model="list.quantity"
                    :precision="0"
                    :min="1"
                    :step="1"
                    :max="list.stockQuantity"
                    step-strictly
                    size="mini"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="detail-package">
          <p class="text" v-if="item.discountPackageType === 'FIXED_PACKAGE'">
            <span><el-image style="width: 100px;height: 100px;" :src="item.imgUrl || productDefaultImg" @error="item.imgUrl = productDefaultImg" /></span>
          </p>
          <p class="num" v-if="item.discountPackageType === 'FIXED_PACKAGE'"><span>{{item.activityProductInfoPage.records.length}}</span>件组合商品</p>
          <p class="select" v-else>已选<span>{{setNumberTotal(item.activityProductInfoPage.records)}}</span>个商品</p>
          <p class="text">总价</p>
          <p class="new">
            <span v-if="isLogin">¥{{setMoneyTotal(item.activityProductInfoPage.records) || 0}}</span>
            <span v-else>登录可见</span>
          </p>
<!--          <p class="old">-->
<!--            <span v-if="isLogin">¥{{$util.toFixed(item.total)}}</span>-->
<!--            <span v-else>登录可见</span>-->
<!--          </p>-->
          <el-button class="buy"
                     @click="addItem(item.fullReduceSendId, item.activityProductInfoPage.records, item.extCfg)"
                     type="primary">立即购买
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import { addItem } from '@/api/detail'
  import 'swiper/css/swiper.css'
  import select from '@/assets/img/icon/gouxuan-s.png'
  import selectNo from '@/assets/img/icon/gouxuan.png'
  import productDefaultImg from '@/assets/img/index/product_default.png'
  import { getToken } from '@/utils/auth'
  import {mapGetters} from 'vuex'
  import addToCartMixin from '@/common/addToCart'

  export default {
    name: 'SwiperPackage2',
    components: { Swiper, SwiperSlide },
    mixins:[addToCartMixin],

    props: {
      swiperData: {
        type: Array,
        required: true
      },
      type: {
        type: String,
        default:''
      },
      model: {
        type: String,
        default: 'small'
      },
      loopAdditionalSlides: {
        type: Number,
        default: 5
      },
      slidesPerView: {
        type: Number,
        default: 5
      },
    },
    computed:{
      ...mapGetters(['userType','license']),
      totalNum: () => {
        return (timestamp) => (timestamp > 0 ? convertDate(timestamp) : "--");
      },
      totalMoney: () => {
        return (timestamp) => (timestamp > 0 ? convertDate(timestamp) : "--");
      },
    },
    created () {
      this.activeName = this.type + 0
      this.swiperOption.slidesPerView = this.slidesPerView || 4
      this.swiperOption.loopAdditionalSlides = this.loopAdditionalSlides || 4
    },
    data () {
      return {
        select,
        selectNo,
        productDefaultImg,
        activeName: '',

        swiperOption: {
          watchOverflow: true,
          // 设定初始化时slide的索引
          initialSlide: 0,
          //Slides的滑动方向，可设置水平(horizontal)或垂直(vertical)
          direction: 'horizontal',
          // 自动切换图配置
          // autoplay: {
          //     delay: 5000,
          //     stopOnLastSlide: false,
          //     disableOnInteraction: true
          // },
          autoplay: false,
          // 箭头配置
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          // 环状轮播
          loop: false,
          loopAdditionalSlides: 4,
          // 一个屏幕展示的数量
          slidesPerView: 4,
          loopedSlides: 3,
          // 间距
          spaceBetween: 20,
          // 修改swiper自己或子元素时，自动初始化swiper
          observer: true,
          // 修改swiper的父元素时，自动初始化swiper
          observeParents: true,
        },
        isLogin: false,
      }
    },
    mounted () {
      this.isLogin = getToken()
      // console.log('swiperData', this.swiperData)
    },
    methods: {
      // 获取选中的商品数 quantity
      setNumberTotal(list) {
        let num = 0
        num = list.map(item => {
          if (item.select) {
            return item.quantity
          } else {
            return 0
          }
        }).reduce((pre, cur, index) => {
          return pre + cur;
        }, 0)
        return num
        console.log('num' ,num)
      },
      // 处理总价格
      setMoneyTotal(list) {
        let total = 0
        if (list.length > 0) {
          let isChecked = list.some(item => {
            return item.select
          })
          if (isChecked) {
             let hasCheckedArr = list.filter(item => {
               return item.select
             })
            total = hasCheckedArr.map(item => {
              if(item.select) {
                return parseFloat((item.salePrice * item.quantity).toFixed(2))
              }
            }).reduce((pre, cur) => {
              console.log()
              return pre + cur;
            }, 0)
            return total.toFixed(2)
          } else {
            return 0
          }
        } else {
          return 0
        }
      },
      onSelectIcon (type, index, i) {
        let list = this.swiperData[index]['activityProductInfoPage'].records
        if (list[i].stockQuantity == 0) {
          this.$message.warning("库存不足，剩余库存为0！")
          return
        }
        let packagePrice = 0
        let total = 0
        let select = 0
        list[i]['select'] = !list[i]['select']
        list.forEach((item,i) => {
          if (item.select) {
            let pkgPrice = typeof item.discountPackagePrice === 'string' ? Number(item.discountPackagePrice.replace(',','')) : Number(item.discountPackagePrice)
            let salePrice = typeof item.salePrice === 'string' ? Number(item.salePrice.replace(',','')) : Number(item.salePrice)
            packagePrice = item.salePrice ? Number(this.$util.adding(packagePrice,Number(this.$util.multiply(pkgPrice, item.quantity).replace(',',''))).replace(',','')) : 0
            total = item.salePrice ? Number(this.$util.adding(total,Number(this.$util.multiply(salePrice,item.quantity).replace(',',''))).replace(',','')) : 0
            select += 1
          }
        })
        this.swiperData[index].select = select
        this.swiperData[index].packagePrice = this.$util.toFixed(packagePrice)
        this.swiperData[index].total = this.$util.toFixed(total)
      },
      addItem (id, item, extCfg) {
        let selectionArr = []
        item.map(item => {
          if (item.select) {
            let obj = {
              skuId: item.skuId,
              productId: item.productId,
              productCode: item.productCode,
              stockQuantity: item.stockQuantity,
              price: item.salePrice,
              quantity: item.quantity
            }
            selectionArr.push(obj)
          }
        })
        if (selectionArr.length) {
          this.$emit("openGifts", id, selectionArr)
        } else {
          this.$message.warning('您还未勾选商品！')
        }

        return
        if (getToken()) {
          let itemType, skuIdList
          if (type === 'FIXED_PACKAGE') {
            itemType = 'FIX_DISCOUNT_PACKAGE'
            skuIdList = []
          } else {
            itemType = 'COLLOCATE_DISCOUNT_PACKAGE'
            skuIdList = list.filter(res => res.select).map(res => res.skuId)
            if (skuIdList.length === 1) return this.$message.warning('至少搭配一个商品')
          }
          addItem({ objId, itemType, quantity: 1, skuIdList }).then(res => {
            const { code } = res
            if (code === '0') {
              this.$message.success('加入成功！')
              this.$store.dispatch('user/setCartNum', res.data.allProductTypeTotalQuantity)
              this.$emit("refresh")
            }else {
              if(res.msg){
                this.$message.error(res.msg)
              }
            }
          }).catch(err=>{
            if(err.msg){
              this.$message.error(err.msg)
            }
          })
        } else {
          this.$message.warning('请先登录')
          this.$router.push(`/login`)
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .swiper-container {
    width: 100%;
    height: 100%;

    .swiper-button-prev, .swiper-button-next {
      width: 20px;
      height: 40px;

      &:after {
        content: none;
      }
    }

    .swiper-button-prev {
      left: 0;
      background: url("../../../../components/AwesomeSwiper/img/swiper-button-prev.png") 100% 100% no-repeat;
    }

    .swiper-button-next {
      right: 0;
      background: url("../../../../components/AwesomeSwiper/img/swiper-button-next.png") 100% 100% no-repeat;
    }
  }
  .detail-swiper {
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-height: 530px;
    padding-bottom: 20px;
  }
  .detail-middle {
    background-color: #fff;
    margin-bottom: 10px;
  }

  .more {
    margin: 0 10px 20px;
    box-shadow: 0 4px 12px 1px rgba(0, 0, 0, 0.06);
    border-radius: 8px;

    .header {
      background: $titleBg1;
      height: 50px;
      line-height: 50px;
      font-size: 20px;
      color: #fff;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .detail-header-right {
      border-bottom-left-radius: 8px;
    }
  }
  .buy-num {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: absolute;
    bottom: 0px;
    right: 0;
    //height: 40px !important;
    //font-size: 14px;
    .el-input-number--mini {
      width: 100px;
    }
  }

  .detail-header-right {
    display: flex;
    position: relative;
    //margin-right: 200px;
    background-color: #fff;
    border-right: 1px solid #e5e5e5;

    .detail-swiper {
      .swiper-container {
        width: 1000px;
      }
    }

    .detail-header-right-content {
      position: relative;
      width: 200px;

      .detail-header-right-img {
        img {
          cursor: pointer;
          margin-top: 12px;
          width: 140px;
          height: 140px;
          transition: transform 0.4s;
        }

        span {
          display: block;
          margin: 0 auto;
          line-height: 20px;
          font-size: 12px;
          position: absolute;
          height: 20px;
          padding: 0 5px;
          border-radius: 0 8px 0 0;
          background: rgba(0, 0, 0, .6);
          left: 30px;
          top: 130px;
          opacity: 1;
          color: #fff;
        }
      }

      p {
        font-size: 12px;
        color: #666;
        text-align: left;
        margin: 0 12px;
        line-height: 18px;
        height: 18px;
        overflow: hidden;
      }

      .name {
        font-size: 14px;
        font-weight: 600;
        color: #222;
        margin: 10px 12px 4px;
        height: 18px;
        overflow: hidden;
      }

      .num span {
        max-width: 75px;
        display: inline-flex;
      }

      .price {
        display: flex;
        align-items: center;
        height: 32px;
        color: #FF5200;
        font-size: 16px;

        &.img {
          cursor: pointer;
        }

        img {
          vertical-align: text-top;
          margin-right: 3px;
          cursor: pointer;
          width: 16px;
        }
      }

      .company {
        height: 18px;
        overflow: hidden;
        display: block;
        cursor: pointer;
      }
    }

    .plus-icon {
      position: absolute;
      top: 70px;
      font-size: 36px;
      color: #999;
      left: -11px;
    }

    .detail-package {
      width: 200px;
      display: table;
      text-align: left;
      padding-left: 18px;
      color: #999;
      font-weight: 600;
      background-color: #fff;

      .select {
        padding: 72px 0 15px;
        font-size: 14px;

        span {
          color: #222;
        }
      }

      .text {
        color: #222;
      }

      .new {
        color: #FF3B30;
        font-size: 20px;
        padding: 5px 0;
        margin-left: -2px;
      }

      .old {
        text-decoration: line-through;
      }

      /deep/ .el-button {
        padding: 0;
        margin: 27px 20px 0 0;
        width: 138px;
        font-size: 15px;
        height: 36px;
        line-height: 36px;
      }
    }
    .identifying {
         position: absolute;
         top: 10px;
         left: 10px;
         width: 32px;
         height: 32px;
         z-index: 10;
         img {
           width: 100%;
           height: 100%;
         }
    }
  }
  .gifts-dialog {
    z-index: 100000000;
  }
</style>

<template>
  <page-modal title="收银台" :active="2">
    <div class="cashier-container">
      <div class="order-prompt">
        <div class="order-state">
          <i size="32" class="el-icon-success"></i>
          <span>您的订单支付成功，请耐心等待！</span>
        </div>
        <div class="oder-sum-container">
          <p> 订单总金额：<span>¥{{ $util.toFixed(receiver.orderTotalAmount) }}</span></p>
        </div>
      </div>

      <div class="cashier-info">
        <div class="cashier-title">订单信息</div>
        <div class="cashier-content">
          <div class="item">
            <div class="label">订单编号</div>
            <div class="text">{{ receiver.orderNum }}
              <!-- <span style="margin-left: 10px;color:#0971EB;cursor: pointer;"
                    @click="goDetails(params.rowData.orderId)">查看订单详情</span> -->
              <span style="margin-left: 10px;color:#0971EB;cursor: pointer;"
                    @click="goDetails(orderId)">查看订单详情</span>
            </div>
          </div>
          <div class="item">
            <div class="label">交易金额</div>
            <div class="text">{{ $util.toFixed(receiver.orderTotalAmount) }}元</div>
          </div>
          <div class="item">
            <div class="label">购买时间</div>
            <div class="text">{{ receiver.createDate}}</div>
          </div>
          <div class="item">
            <div class="label">收货人</div>
            <div class="text">{{ receiver.receiverName }}</div>
          </div>
          <div class="item">
            <div class="label">联系电话</div>
            <div class="text">{{ receiver.receiverMobile }}</div>
          </div>
          <div class="item">
            <div class="label">收货地址</div>
            <div class="text">
              <span>{{ receiver.receiverAddr }}</span>
            </div>
          </div>
        </div>
      </div>
<!--      <div class="cashier-info">-->
<!--        <div class="cashier-title">注意事项</div>-->
<!--        <div class="cashier-content attention-list">-->
<!--          1、收货地址是否正确购买宝贝的规格是否是自己想要的规格（规格指的是码子、颜色等信息）付款价格是否有出入<br/>-->
<!--          2、是否赠送运费险了，如果未赠送，需看看是否要用官方赠送的运费险或者自行购买运费险<br/>-->
<!--          3、是否用金币币或者积分<br/>-->
<!--          4、是否要朋友代付<br/>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </page-modal>
</template>

<script>
  import pageModal from './page-modal'
  import { getOrderInf, receive } from '@/api/shop-cart'

  export default {
    name: 'paySuccess',
    components: { pageModal },
    props: {
      params: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data () {
      return {
        receiver: {
          receiverName: '',
          receiverMobile: '',
          createDate: '',
          orderTotalAmount: '',
          orderNum: '',
          receiverAddr: ''
        },
        payWayList: [],
        payFormHtml: '',
        orderId: ''
      }
    },
    created () {
      if (this.$route.params.id) {
        this.orderId = this.$route.params.id
      }
    },
    mounted () {
      getOrderInf({ orderId: this.orderId }).then(res => {
        const { data } = res
        this.receiver = data.data
        if (this.receiver.paymentWayCode === 'CASH') {
          let url = window.location.href
          receive(url.substring(url.indexOf('?'), url.length))
        }
      })
    },
    methods: {
      goDetails (orderId) {
        const { href } = this.$router.resolve({
          path: '/order-detail/' + orderId
        })
        window.open(href, '_blank')
      }
    }
  }
</script>

<style scoped lang="scss">
  .cashier-container {
    width: 1200px;
    height: auto;
    box-sizing: content-box;
    margin: 20px auto;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid rgba(238, 238, 238, 1);
    /*订单提示*/
    .order-prompt {
      padding: 40px 74px;
      text-align: left;

      .order-state {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        i {
          margin-right: 8px;
          font-size: 32px;
          color: #09AD25;
        }

        font-size: 24px;
        font-weight: 500;
        color: rgba(34, 34, 34, 1);
      }

      .oder-sum-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 40px;
        height: 32px;

        p {
          margin-right: 14px;
          font-size: 16px;
          font-weight: 500;
          color: rgba(34, 34, 34, 1);

          span {
            font-size: 20px;
            color: rgba(255, 59, 48, 1);
          }
        }
      }
    }

    /**/
    .cashier-info {
      border-top: 1px solid rgba(238, 238, 238, 1);
      padding: 20px;
      display: flex;
      justify-content: flex-start;

      .cashier-title {
        margin-right: 40px;
        font-size: 14px;
        font-weight: 600;
      }

      .cashier-content {
        flex: 1;
        /*订单信息*/
        .item {
          width: 100%;
          height: auto;
          display: flex;
          margin-bottom: 8px;
          font-size: 12px;
          font-weight: 500;
          color: rgba(34, 34, 34, 1);

          .label {
            width: 68px;
            min-width: 48px;
            text-align: left;
            flex-shrink: 0;
            margin-right: 20px;
          }

          .text {
            text-align: left;
            flex: 1;

            span {
              padding-right: 5px;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        /*支付平台*/
        .payment-amount {
          text-align: left;
          font-size: 12px;
          font-weight: 500;
          color: rgba(102, 102, 102, 1);
          margin-bottom: 12px;

          span {
            font-size: 16px;
            color: rgba(255, 59, 48, 1);
          }
        }

        .payment-button-groups {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;

          .payment-button {
            display: inline-block;
            width: 200px;
            height: 50px;
            text-align: center;
            text-decoration: none;
            border: 1px solid rgba(204, 204, 204, 1);
            background: #ffffff;
            margin-right: 16px;
            padding: 0;
            box-sizing: border-box;

            &.wechat {
              background: url("../img/wechat.png") 100% 100% no-repeat;
            }

            &.alipay {
              background: url("../img/alipay.png") 100% 100% no-repeat;
            }

            &.unionpay {
              background: url("../img/unionpay.png") 100% 100% no-repeat;
            }
          }
        }

        /*注意事项*/
        &.attention-list {
          text-align: left;
          font-size: 12px;
          font-weight: 500;
          color: rgba(102, 102, 102, 1);
          line-height: 16px;
        }
      }
    }
  }
</style>

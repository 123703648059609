var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "swiper-container" }, [
    _c("div", { staticClass: "more" }, [
      _c("div", { staticClass: "detail-header-right" }, [
        _c(
          "div",
          { staticClass: "detail-swiper" },
          _vm._l(_vm.swiperData, function (list, i) {
            return _c("div", { key: i }, [
              _c(
                "div",
                { staticClass: "detail-header-right-content" },
                [
                  _vm.userType == "SALESMAN"
                    ? _c("div", { staticClass: "identifying" }, [
                        list.whiteRecordFlag == 1
                          ? _c("img", {
                              attrs: {
                                alt: "",
                                src: require("@/assets/img/icon/whiterecord.png"),
                              },
                            })
                          : list.blackRecordFlag == 1
                          ? _c("img", {
                              attrs: {
                                alt: "",
                                src: require("@/assets/img/icon/blackrecord.png"),
                              },
                            })
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "detail-header-right-img" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/detail/" + list.productId } },
                        [
                          _c("img", {
                            attrs: {
                              src: list.picUrl || _vm.productDefaultImg,
                              alt: "",
                            },
                            on: {
                              error: function ($event) {
                                list.picUrl = _vm.productDefaultImg
                              },
                            },
                          }),
                        ]
                      ),
                      _c("span", [_vm._v(_vm._s(list.quantity) + "件")]),
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: "/detail/" + list.productId } },
                    [
                      _c("p", { staticClass: "name" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$util.getGoodsName(list)) + " "
                        ),
                      ]),
                    ]
                  ),
                  _c("p", { staticClass: "num" }, [
                    _vm._v("规格："),
                    _c("span", [_vm._v(_vm._s(list.spec))]),
                    _vm._v(" 单位：" + _vm._s(list.unit)),
                  ]),
                  _c(
                    "p",
                    {
                      staticClass: "company",
                      attrs: { title: list.manufacturer },
                    },
                    [_vm._v(_vm._s(list.manufacturer))]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "price img",
                      on: {
                        click: function ($event) {
                          return _vm.onSelectIcon(i)
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: list.select ? _vm.select : _vm.selectNo,
                          alt: "",
                        },
                      }),
                      _vm.isLogin
                        ? _c("span", [
                            _vm._v(
                              "¥" + _vm._s(_vm.$util.toFixed(list.salePrice))
                            ),
                          ])
                        : _c("span", [_vm._v("登录可见")]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "buy-num" },
                    [
                      _vm.isLogin
                        ? _c("el-input-number", {
                            attrs: {
                              precision: 0,
                              min: 1,
                              step: 1,
                              max: list.stockQuantity,
                              "step-strictly": "",
                              size: "mini",
                            },
                            model: {
                              value: list.quantity,
                              callback: function ($$v) {
                                _vm.$set(list, "quantity", $$v)
                              },
                              expression: "list.quantity",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "detail-package" },
          [
            _c("p", { staticClass: "text" }, [
              _c(
                "span",
                [
                  _c("el-image", {
                    staticStyle: { width: "100px", height: "100px" },
                    attrs: { src: _vm.productDefaultImg },
                    on: { error: _vm.productDefaultImg },
                  }),
                ],
                1
              ),
            ]),
            _c("p", { staticClass: "select" }, [
              _vm._v("已选"),
              _c("span", [_vm._v(_vm._s(_vm.setNumberTotal(_vm.swiperData)))]),
              _vm._v("个商品"),
            ]),
            _c("p", { staticClass: "text" }, [_vm._v("总价")]),
            _c("p", { staticClass: "new" }, [
              _vm.isLogin
                ? _c("span", [
                    _vm._v(
                      "¥" + _vm._s(_vm.setMoneyTotal(_vm.swiperData) || 0)
                    ),
                  ])
                : _c("span", [_vm._v("登录可见")]),
            ]),
            _c(
              "el-button",
              {
                staticClass: "buy",
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.addItem(
                      _vm.swiperData[0].fullReduceSendId,
                      _vm.swiperData
                    )
                  },
                },
              },
              [_vm._v("立即购买 ")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class=" swiper-container">
    <swiper :options="swiperOption">
      <swiper-slide v-for="(item, index) in recoProduct" :key="index">
        <div class="swiper-content">
          <div class="identifying" v-if="userType =='SALESMAN'">
            <img v-if="item.whiteRecordFlag  == 1" alt src="@/assets/img/icon/whiterecord.png">
            <img v-else-if="item.blackRecordFlag  == 1" alt src="@/assets/img/icon/blackrecord.png">
          </div>
          <div class="product">
            <div class="product-image" @click="checkDetail(item)">
              <el-image
                :src="item.picUrl || item.pictUrls || productImage"
                fit="fill"
                @error="item.picUrl = productImage"
              ></el-image>
            </div>
            <div class="product-description">
              <div class="product-detail">
                <div class="product-name">
                  <span
                    class="otc"
                    v-if="item.otcType && item.otcType !== '其他'"
                    :class="{ 'jiaOtc': item.otcType === '甲OTC', 'yiOtc': item.otcType === '乙OTC', 'rxOtc': item.otcType === 'RX' }"
                  >{{ item.otcType}}</span>
                  <span @click="checkDetail(item)" style="cursor: pointer;">
                    <span>
                      {{$util.getGoodsName(item)}}
                    </span>

                  </span>
                </div>
                <p class="specification" :title="item.spec+ 'x' + item.unit">{{ item.spec }}x{{ item.unit }}</p>
                <p class="manufacturers" :title="item.manufacturer">{{ item.manufacturer }}</p>
              </div>
              <p class="product-price">{{ isLogin ? $util.getPriceByZero(item.price,item) : '登录可见' }}</p>
            </div>
          </div>
              <tagList :taglist='item.tagList'></tagList>
        </div>
      </swiper-slide>
    </swiper>
    <!--以下看需要添加-->
    <!--            <div class="swiper-scrollbar">滚动条</div>-->

    <div class="swiper-button-next"></div>

    <div class="swiper-button-prev"></div>

    <div class="swiper-pagination" slot="pagination"></div>
  </div>
</template>

<script>
  import tagList from '@/components/tagList/tagList'
  import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
  import { recommendToList } from '@/api/index/index'
  import { getToken,getUserType } from '@/utils/auth'
  import { parseTime } from '@/utils'
  import 'swiper/css/swiper.css'
  import productImage from './img/product_default.png'

  export default {
    name: 'AwesomeSwiper',
    components: { Swiper, SwiperSlide ,tagList },
    directives: {
      swiper: directive
    },
    props: {
      loopAdditionalSlides: {
        type: Number,
        default: 4
      },
      slidesPerView: {
        type: Number,
        default: 4
      },
      recoProduct: {
        type: Array,
        default: () => {
          return []
        }
      },
    },
    created () {
      this.swiperOption.slidesPerView = this.slidesPerView || 4
      this.swiperOption.loopAdditionalSlides = this.loopAdditionalSlides || 4
      this.recoProduct.map(r=>{
        r.discountEndTime = parseTime(r.discountEndTime,"{y}-{m}-{d}")
        r.discountStartTime = parseTime(r.discountStartTime,"{y}-{m}-{d}")
      })

    },
    mounted() {
      this.isLogin = getToken()
    },
    data () {
      return {
        userType: getUserType(),
        productImage,
        isLogin:false,
        swiperOption: {
          // 分页器配置
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          watchOverflow: true,
          // 设定初始化时slide的索引
          initialSlide: 0,
          //Slides的滑动方向，可设置水平(horizontal)或垂直(vertical)
          direction: 'horizontal',
          // 自动切换图配置
          // autoplay: {
          //     delay: 5000,
          //     stopOnLastSlide: false,
          //     disableOnInteraction: true
          // },
          autoplay: false,
          // 箭头配置
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          // 环状轮播
          loop: false,
          loopAdditionalSlides: 4,
          // 一个屏幕展示的数量
          slidesPerView: 4,
          loopedSlides: 3,
          // 间距
          spaceBetween: 20,
          // 修改swiper自己或子元素时，自动初始化swiper
          observer: true,
          // 修改swiper的父元素时，自动初始化swiper
          observeParents: true
        }
      }
    },
    // mounted() {
    //   recommendToList().then(res => {
    //     this.recoProduct = res.data
    //   })
    // },
    methods: {
      checkDetail(item) {
        this.$router.push({ path: '/detail/' + item.productId })
      }
    }
  }
</script>

<style scoped lang="scss">
  .swiper-container {
    width: 100%;
    height: 100%;

    .swiper-button-prev, .swiper-button-next {
      width: 20px;
      height: 40px;

      &:after {
        content: none;
      }
    }

    .swiper-button-prev {
      left: 0;
      background: url("./img/swiper-button-prev.png") 100% 100% no-repeat;
    }

    .swiper-button-next {
      right: 0;
      background: url("./img/swiper-button-next.png") 100% 100% no-repeat;
    }

    .swiper-slide {
      padding: 0;
      box-sizing: border-box;
    }
    .swiper-content{
      position: relation;
      padding: 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    }

    .product {
      width: 100%;
      height: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: left;

      .product-image {
        width: 100px;
        height: 100px;
        margin-right: 8px;
        cursor: pointer;

        .el-image {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }

        /*background: url("./img/product.png") 100% 100% no-repeat;*/
      }

      .product-description {
        height: 100%;
        overflow: hidden;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /*justify-content: ;*/
        /*align-items: ;*/
        .product-detail {
          width: 100%;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          /*align-items: ;*/
          align-items: flex-start;

          .product-name {
            height: 20px;
            font-size: 14px;
            // font-weight: 600;
            color: rgba(34, 34, 34, 1);
            line-height: 20px;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
            text-overflow: ellipsis;

            .otc {
              margin-right: 4px;
              display: inline-block;
              height: 20px;
              color: #ffffff;
              font-size: 14px;
              background: rgba(255, 102, 0, 1);
              border-radius: 4px;
              padding: 0 2px;
            }
          }

          .specification {
            height: 17px;
            overflow: hidden;
            word-wrap: normal;
            font-size: 12px;
            font-weight: 500;
            color: rgba(102, 102, 102, 1);
            line-height: 17px;
            padding-top: 4px;
          }

          .manufacturers {
            width: 100%;
            overflow: hidden;
            word-wrap: normal;
            height: 17px;
            font-size: 12px;
            font-weight: 500;
            color: rgba(102, 102, 102, 1);
            line-height: 17px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-top: 3px;
          }
        }

        .product-price {
          height: 25px;
          font-size: 18px;
          font-weight: 600;
          color: rgba(255, 59, 48, 1);
          line-height: 25px;
        }
      }


    }

    .product-tag-group {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;

      .row-tag {
        height: 18px;
        font-size: 12px;
        /*font-family:PingFangSC-Medium,PingFang SC;*/
        font-weight: 500;
        /*color:rgba(2,92,214,1);*/
        line-height: 18px;
        border-radius: 3px;
        border-width: 1px;
        border-style: solid;
        margin-right: 4px;
        padding: 0 4px;
        box-sizing: border-box;

        /*border:1px solid rgba(2,92,214,1);*/
      }

      .tag-promotion {
        background: #FF6600;
        border: none;
        color: #ffffff;
      }

      .tag-cash {
        border-color: #025CD6;
        color: #025CD6;
      }

      .tag-coupon {
        border-color: #FF6600;
        color: #FF6600;
      }

      .tag-plus {
        border-color: #33AE67;
        color: #33AE67;
      }
    }
  }
  .identifying {
       position: absolute;
       top: 6px;
       left: 0px;
       width: 32px;
       height: 32px;
       z-index: 11;
       img {
         width: 100%;
         height: 100%;
       }
   }
   .identifying {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 32px;
        height: 32px;
        img {
          width: 100%;
          height: 100%;
        }
   }
  .jiaOtc{
    background: #E60012 !important;
  }
  .yiOtc{
    background: #33AE67 !important;
  }
  .rxOtc{
    background: #0091ff !important;
  }
</style>

<template>
  <el-form-item class="im-search-pad-item" :prop="prop">
    <slot />
  </el-form-item>
</template>
<script>
export default {
  name: 'ImSearchPadItem',
  props: {
    prop: {
      required: true,
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  }
}
</script>

/**
 * ImPageModal
 * @module components
 * @desc 列表表头的按钮工具栏
 * @author Mo Xiaomeng
 * @date 2020/03/14
 * * @param {String} [prop] - 表单域 model字段，该属性必填
 * @example 调用示例
 *  <im-search-pad-item prop="user">
      <el-input v-model="searchForm.user" placeholder="审批人" />
    </im-search-pad-item>
 */
import imPageModal from './index.vue'

const ImPageModal = {
  install: function(Vue) {
    Vue.component('ImPageModal', imPageModal)
  }
}
export default ImPageModal

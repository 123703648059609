import {ApiGet, ApiPost, ApiPut} from "@/api";

/**
 * @description 获取所有商家(未登录情况下使用)
 */
export const getBusiness = data => ApiGet('/pc/front/user/frontOrgList',data)

/**
 * @description 获取所有机构
 */
export const pageSelectEttleAccount = data => ApiPost('/pc/member/user/pageSelectEttleAccount',data)

/**
 * @description 切换当前商家
 */
export const changeBusiness = params => ApiGet('/pc/front/user/changeOrg', {},{
  headers: {
    'x-merchant-code-header': params
  }
})

/**
 * @description 切换当前机构
 */
export const changAccount = ({ettleAccountId,userId}) => ApiGet('/pc/member/user/selectBranch', {
  ettleAccountId,
  buyersUserId: userId
})

/**
 * @description 获得当前用户的未读站内信数量
 */
export const getUnreadNotifyMessageCount = params => ApiGet('/system/notify-message/get-unread-count', params)

/**
 * @description 获得我的站内信分页
 */
export const getMyNotifyMessagePage = params => ApiGet('/system/notify-message/my-page', params)

/**
 * @description 批量标记已读
 */
export const updateNotifyMessageRead = data => ApiPut('/system/notify-message/update-read',data)

/**
 * @description 标记所有站内信为已读
 */
export const updateAllNotifyMessageRead = data => ApiPut('/system/notify-message/update-all-read',data)

import {findEnablePaymentWay, goBank, ICBCPay} from "@/api/shop-cart";
import {getBus} from "@/utils/auth";
import qrCode from '@/common/qrCode'
export default {
    data(){
        return {
            payFormHtml: '', //支付表单，后端直接返回整个表单，用户点击直接提交表单发起支付
            ICBCQrCode: '', //招行支付二维码
            payWayList: [] , //支付方式列表
            payBtnLoading: false, //控制立即支付按钮是否处于loading中不可点击
            onlinePayWayEnum: ''
        }
    },
    mixins:[qrCode],
    computed:{

    },
    methods:{
        //获取支付方式
        getPayWay(){
            return findEnablePaymentWay({ orgId: getBus().orgId })
        },

        //付款
        async handlePay () {
            const item = this.payWayList.find(v => v.select)
            let {onlinePayWayEnum} = item
            this.onlinePayWayEnum = onlinePayWayEnum
            this.ICBCQrCode = ''
            this.payFormHtml = ''
            switch (onlinePayWayEnum){
                //支付宝支付
                case 'PC_ALIPAY':
                    this.payBtnLoading = true

                    let res = await goBank({
                        payWayId: item.paymentWayId,
                        orderId: this.orderId
                    })

                    this.payBtnLoading = false;

                    if (res.data) {
                        this.payFormHtml = res.data
                        setTimeout(() => {
                            let payForm = document.getElementById('payForm')
                            payForm.target = '_blank'
                        })
                    }

                    break
                //招行支付
                case 'ICBC_PAY':
                    this.payBtnLoading = true

                    let response = await ICBCPay({
                        payWayId: item.paymentWayId,
                        orderId: this.orderId
                    })

                    this.payBtnLoading = false

                    if(response.data){
                        this.ICBCQrCode = response.data['qrCode'] || ''
                    }else {
                        this.payFormHtml = ''
                    }

                    break
                default:
                    this.payFormHtml = ''
                    this.ICBCQrCode = ''
                    break
            }
        },

        goToPay(){
            switch (this.onlinePayWayEnum){
                case "PC_ALIPAY":
                    let payForm = document.getElementById('payForm')
                    if(payForm){
                        this.dialogPayVisible = true
                        payForm.submit()
                    }
                    break
                case "ICBC_PAY":
                    let routeUrl = this.$router.resolve({
                        path:'/ICBC-Pay',
                        query:{
                            qrCode: this.ICBCQrCode,
                            orderId: this.orderId,
                            orderTotalAmount: this.receiver.orderTotalAmount,
                            orderNum: this.receiver.orderNum
                        }
                    });
                    this.dialogPayVisible = true
                    window.open(routeUrl .href, '_blank');
                    break
                default:
                    break
            }

        }
    }
}
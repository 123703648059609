<template>
  <div class="customer-list">
    <search-pad
        class="client-file-search-pad"
        :model="searchForm"
        @search="handleSearch"
        @reset="handleReset"
        :hasExpand = false
    >
      <search-pad-item prop="searchField">
        <el-input v-model="searchForm.searchField" placeholder="请输入客户编码/名称"/>
      </search-pad-item>
      <search-pad-item prop="entContactMan">
        <el-input v-model="searchForm.entContactMan" placeholder="请输入负责人" />
      </search-pad-item>
      <search-pad-item prop="contactMoblie">
        <el-input v-model="searchForm.contactMoblie" placeholder="请输入联系电话" />
      </search-pad-item>
      <search-pad-item prop="export">
        <el-button size="mini" type="primary" @click="exportData">导出数据</el-button>
      </search-pad-item>
    </search-pad>

    <div class="main">
      <im-table-page
              ref="tablePage"
              :data.sync="tableData"
              :remote-method="queryData"
              header-row-class-name="header-row"
              :cell-class-name="cellClassName"
              :header-cell-class-name="cellClassName"
              style="width: 100%">
        <el-table-column type="index" label="序号" width="50px" fixed="left"  align="center"/>
        <el-table-column label="状态" fixed="left" prop="isEnable" width="60px"  align="center">
          <template slot-scope="scope">
            <el-switch
              active-text="启"
              inactive-text="冻"
              v-model="scope.row.isEnable"
              active-color="#1890FF"
              active-value="Y"
              inactive-value="N"
              class="swich-inner-text"
              @change="switchChange(scope.row)"
              />
          </template>
         </el-table-column>
        <el-table-column
                prop="erpCode"
                fixed="left"
                label="客户编码"
                width="80px"
                align="center"
                show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
                prop="buyersNm"
                fixed="left"
                label="客户名称"
                width="100px"
                show-overflow-tooltip
                align="center"
        >
        </el-table-column>
        <el-table-column
                prop="ettleAccountNm"
                label="分支机构"
                width="100px"
                align="center"
                show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
                prop="userMobile"
                label="联系电话"
                width="115px"
                align="center"
        >
         <template slot-scope="scope">
            <el-input-number
                v-model="scope.row.userMobile"
                class="account-input"
                :controls="false"
                :disabled="scope.row.isEdit"
                :precision="0"
                :min="0"
                style="width: 100%;"
                placeholder="请输入联系方式"
            />
          </template>
        </el-table-column>
        <el-table-column
                prop="availableCredit"
                align="right"
                label="可用额度"
                show-overflow-tooltip
                width="110px">
        </el-table-column>
        <el-table-column
                align="center"
                label="剩余账期"
                width="80px"
                prop="limitCreditDate"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.isEdit">{{ scope.row.limitCreditDate? scope.row.limitCreditDate : '--' }}</span>
            <el-input v-else type="number" v-model="scope.row.limitCreditDate" auto-complete="on" class="account-input"  placeholder="请输入小于原账期天数" :disabled="scope.row.isEdit" />
          </template>
        </el-table-column>
        <el-table-column
        prop="customerTypeName"
        align="center"
        label="客户类型"
        width="80px">
        </el-table-column>
        <el-table-column
                prop="loginId"
                align="center"
                label="账号名称"
                width="80px">
        </el-table-column>
        <el-table-column
                prop="legalPersonMember"
                align="center"
                label="企业法人"
                width="80px">
        </el-table-column>
         <el-table-column
                prop="entContactMan"
                align="center"
                label="负责人"
                width="80px">
        </el-table-column>
        <el-table-column
                prop="area"
                align="center"
                label="所在区域"
                width="100px">
           <template slot-scope="scope">
            <span v-if="scope.row.province && scope.row.city && scope.row.district">{{ `${scope.row.province}-${scope.row.city}-${scope.row.district}` }}</span>
            <span v-else></span>
           </template>
        </el-table-column>
        <el-table-column
            prop="businessRangeIds"
            align="center"
            label="经营范围"
            width="200px">
          <template slot-scope="scope">
            <el-popover
              placement="right"
              trigger="hover"
              width="600"
              >
              <div class="business-detail">
                <el-row  v-for="(value, key) in scope.row.businessRangeDetail" :key="key">
                  <el-col :span="5" style="text-align: right;">
                    <div class="grid-content bg-purple" style="font-weight:bold">{{key}}：</div>
                  </el-col>
                  <el-col :span="19">
                    <div class="grid-content bg-purple-light" style="max-height: 400px;overflow-y: auto">{{value}}</div>
                  </el-col>
                </el-row>
              </div>
              <span slot="reference">{{scope.row.businessRangeNmsStr}}</span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="paymentWayName"
          align="center"
          label="结款方式"
          width="155px"
          fixed="right">
           <template slot-scope="scope">
            <el-select v-model="scope.row.paymentWayName" multiple :disabled="scope.row.isEdit" placeholder="请选择结款方式">
              <el-option label="现款" value="现款" />
              <el-option label="赊销" value="赊销" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          align="center"
          width="100"
          label="操作">
          <template slot-scope="scope">
            <div class="table-operation">
<!--              <el-button size="mini" type="primary" style="padding: 4px 8px;font-size: 12px" @click="handleEdit(scope.row)">-->
<!--                {{scope.row.isEdit ? '编辑' : '保存'}}-->
<!--              </el-button>-->
              <el-button size="mini" type="primary" style="padding: 4px 8px;font-size: 12px" @click="replaceCustomerToOrder(scope.row)">代客下单</el-button>
            </div>
          </template>
        </el-table-column>
      </im-table-page>
    </div>
  </div>
</template>

<script>
  import SearchPad from '../../components/search-pad'
  import SearchPadItem from '../../components/search-pad-item'
  import {exportClient, pageCustomer, getBusinessRangeList, handleEditCustomer, isEnableCustomer } from '@/api/member-center/customer-service'
  import { validPhone } from '@/utils/validate'

  export default {
    name: 'my-customer-list',
    components: { SearchPad, SearchPadItem },
    data () {
      return {
        searchForm: {
          searchField: '',
          entContactMan: '',
          contactMoblie: ''
        },
        isExpand: false,
        activeName: 0,
        tableData: [],
        businessRanges: {},
      }
    },
    methods: {
     exportData(){
       exportClient(this.searchForm).then(res =>{
         let blob = new Blob([res.data], {type :'application/zip'})
         const disposition = res.headers['content-disposition'].split(";")[1].split("=")[1];
         var fileName = decodeURI(disposition);
         //去掉双引号
         fileName = fileName.replace(/\"/g, "");
         let link = document.createElement('a')
         link.href = URL.createObjectURL(blob)
         link.download = fileName
         link.click()
         link.remove()
         this.$message.success('导出成功')
       })
     },
     doRefresh() {
         this.$refs.tablePage.doRefresh({ currentPage: 0, pageSize: 10 })
     },
     switchChange(row) {
      // const { isEnable, id } = row
      let params = {
        isEnable:row.isEnable,
        id:row.ettleAccountId
      }
      isEnableCustomer(params).then(res => {
        if (isEnable === 'Y') {
          this.$message.success('启用成功')
          // this.$router.go(0)
        } else {
          this.$message.success('已停用')
          // this.$router.go(0)
        }
      })

      this.doRefresh();
     },
     handleSearch() {
        this.$refs.tablePage.doRefresh({
          currentPage: 0,
          pageSize: 10
        })
      },
      handleReset () {
        this.searchForm = {
          searchField: '',
          entContactMan: '',
          contactMoblie: ''
        }
        this.doRefresh();
      },

      handleEdit(data) {
        if(data.isEdit){
           data.isEdit = false;
        }else {
          if (!validPhone(data.userMobile)) return this.$message.error('请输入正确联系电话')
          if(data.paymentWayName.length <= 0) return this.$message.error('请选择结算方式')
          if(!data.limitCreditDate) return this.$message.error('请输入账期')
          data.isCash = data.paymentWayName.includes("现款") ? "Y" : "N"
          data.isCredit = data.paymentWayName.includes("赊销") ? "Y" : "N"
          // 保存
          const {  buyersId, ettleAccountId, userMobile,  isCash, isCredit, userId, limitCreditDate } = data;
          this.handleEditCustomer({buyersId, ettleAccountId, contactMoblie: userMobile,  isCash, isCredit, userAccountIfId: userId, limitCreditDate });
          this.doRefresh();
        }
      },

      handleEditCustomer(data) {
        handleEditCustomer(data).then(res => {
          if(res.code == "0"){
            this.$message.success('修改成功');
          }else {
            this.$message.success('修改失败');
          }
        })
      },

      // 代客下单 跳转到飞速下单页面
      replaceCustomerToOrder(row) {
        this.$store.dispatch('user/changAccount', row).then(_ => {
          this.$router.push('/fast-order')
        })
      },

      queryData(data) {
        return new Promise((then, reject) => {
        getBusinessRangeList().then(
          res => {
            this.businessRanges = res.data;
          }
        ).then( () => {
           pageCustomer({
              ...data,
              condition: this.searchForm
           }).then(res => {
              let obj = this.businessRanges;
              res.data.records = res.data.records.map((item, index) => {
                item.paymentWayName = item.paymentWayName.split('&')
                let businessRangeIdList = item.businessRangeIds.split(',');
                let businessRangeNms = [];
                let businessRangeKeys = [];
                // 每个客户的经营范围
                businessRangeIdList.map((iditem, idindex) => {
                  let preKey = '';
                  // 全部经营范围集合
                  for(var nowkey in obj){
                    let everyRangeIdList = obj[nowkey];
                    let finalStr = '';
                    everyRangeIdList.map((everyitem, everyindex) => {
                      if(everyitem.businessRangeId == iditem) {
                        businessRangeNms.push(everyitem.businessRangeNm);
                        let everyItemRange = {};
                        everyItemRange[nowkey] = everyitem.businessRangeNm;
                        businessRangeKeys.push(everyItemRange);
                      }
                    })
                  }
                })
                if(businessRangeNms.length > 2){
                  item.businessRangeNmsStr = businessRangeNms[0] + ',' + businessRangeNms[1] + ',' +'...'
                }else{
                  item.businessRangeNmsStr = businessRangeNms.join(',');
                }
                // console.log(businessRangeKeys);
                let allRange = {};
                businessRangeKeys.map((everyitem, everyindex) => {
                  for(var key in everyitem){
                    if(allRange == {}){
                      allRange = everyitem;
                    }else if(allRange[key]){
                      allRange[key] = allRange[key] + ' ; ' + everyitem[key];
                    }else if(!allRange[key]){
                      allRange[key] = everyitem[key];
                    }
                  }
                })
                item.businessRangeDetail = allRange;
                // console.log(allRange);
                item.isEdit = true;
                return item
              })
              then(res)
            })
        }
        )
      })
      },
      cellClassName ({ row, column, rowIndex, columnIndex }) {
        return 'cellFont'
      }
    }
  }
</script>

<style scoped lang="scss">
  .customer-list {
    background-color: #fff;
    text-align: left;
    font-size: 14px;
    height: 100%;

    .client-file-search-pad {
      padding: 20px 20px 10px;
      border-bottom: 1px solid #E5E5E5;

      .el-input {
        width: 231px;
        height: 32px;
      }

      /deep/ {
        .el-form-item__label, .el-form-item__content, .el-input__inner {
          height: 32px;
          line-height: 32px;
        }

        .el-input__inner {
          font-size: 14px;
          font-weight: 400;
          color: rgba(153, 153, 153, 1);
          padding: 0 8px;
        }
      }

      /deep/ {
        .search-pad-item {
          margin-right: 17px;
        }
      }
    }

    /deep/ {
      .el-tabs__nav-wrap::after {
        background-color: #fff;
      }

      .el-tabs__nav {
        margin: 0 20px;
        height: 40px;
        line-height: 50px;
      }

      .im-search-pad .search-pad-button[data-v-2ba93d14] .el-button--text {
        border-right: 0;
      }
    }
  }

  .main {
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;
    height: auto;
    background: #ffffff;
  }

  /*表格*/
  /*解决列fixed后的办法处理*/

  /deep/
  .el-table--border {
    border: 1px solid #E5E5E5;
  }

  /deep/ .el-table__row {
    height: 60px;

    .cell {
      padding: 0 8px;
      font-size: 14px;
      font-weight: 500;
      color: rgba(38, 38, 38, 1);
    }

    .product-img {
      width: 50px;
      height: 50px;
    }


    .el-input__inner {
      padding: 0 5px;
      height: 30px;
      line-height: 30px;
    }


    /*操作栏*/
    .table-operation {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  /deep/ .header-row {
    height: 40px;
    background: #E5E5E5;

    th {
      .cell {
        padding: 0 8px;
        font-size: 14px;
        font-weight: 600;
        color: rgba(34, 34, 34, 1);
      }
    }
  }

  .account-input {
    font-size: 14px;

    /deep/ {
      .el-table__row .el-input__inner {
        padding: 0px 5px;
      }
    }
  }

  .business-detail {
    padding: 10px 1px;
    overflow-y: auto;
    font-size: 14px;
    /deep/ {
      .el-row {
        margin: 12px;
      }
    }
  }
  /deep/ .cellFont .cell {
    font-size: 12px!important;
  }

</style>

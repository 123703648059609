
const REQUIREDTIP = '不能为空'


export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}


export function validUsername(str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}


export function validPhone(phone) {
  return /^1[3456789]\d{9}$/.test(phone)
}

export function validTel(tel) {
  return /(^(\d{3,4}-)?\d{7,8})$|(13[0-9]{9})/.test(tel)
}

export function validMail(mail) {
  return (/^[\w.\-]+@(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,3}$/.test(mail))
}

export function validIdCard(IDCard) {
  // 身份证地区
  var areaID = {
    11: '北京',
    12: '天津',
    13: '河北',
    14: '山西',
    15: '内蒙古',
    21: '辽宁',
    22: '吉林',
    23: '黑龙江',
    31: '上海',
    32: '江苏',
    33: '浙江',
    34: '安徽',
    35: '福建',
    36: '江西',
    37: '山东',
    41: '河南',
    42: '湖北',
    43: '湖南',
    44: '广东',
    45: '广西',
    46: '海南',
    50: '重庆',
    51: '四川',
    52: '贵州',
    53: '云南',
    54: '西藏',
    61: '陕西',
    62: '甘肃',
    63: '青海',
    64: '宁夏',
    65: '新疆',
    71: '台湾',
    81: '香港',
    82: '澳门',
    91: '国外'
  }

  var message = ''
  var checkIdCard = function (IDCard) {
    var iSum = 0
    if (!/^\d{17}(\d|x)$/i.test(IDCard)) {
      message = '你输入的身份证长度或格式错误!'
      return false
    }
    IDCard = IDCard.replace(/x$/i, 'a')
    if (areaID[parseInt(IDCard.substr(0, 2))] == null) {
      message = '你的身份证地区非法!'
      return false
    }
    var sBirthday = IDCard.substr(6, 4) + '-' + Number(IDCard.substr(10, 2)) + '-' + Number(IDCard.substr(12, 2))
    var d = new Date(sBirthday.replace(/-/g, '/'))
    if (sBirthday !== (d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate())) {

      message = '身份证上的出生日期非法!'
      return false
    }
    for (var i = 17; i >= 0; i--) { iSum += (Math.pow(2, i) % 11) * parseInt(IDCard.charAt(17 - i), 11) }
    if (iSum % 11 !== 1) {

      message = '你输入的身份证号非法!'
      return false
    }

    return true
  }
  var ret = checkIdCard(IDCard)
  console.log(message)
  return ret
}


export function isMoney(number) {
  var reg = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/
  return reg.test(number)
}


const validateMobile = (rule, value, callback) => {
  if (value === '') {
    return callback(new Error('手机号码' + REQUIREDTIP))
  }
  if (!validPhone(value)) {
    return callback(new Error('手机号码格式不正确'))
  }
  callback()
}


const validateEamil = (rule, value, callback) => {
  if (value === '') {
    return callback(new Error('邮箱' + REQUIREDTIP))
  }
  if (!validMail(value)) {
    return callback(new Error('邮箱格式不正确'))
  }
  callback()
}


const validateIdCard = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('身份证号码' + REQUIREDTIP))
  }
  if (!validIdCard(value)) {
    return callback(new Error('身份证号码不合法'))
  }
  callback()
}


const validateTel = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('电话号码' + REQUIREDTIP))
  }
  if (!validTel(value)) {
    return callback(new Error('电话号码格式不正确'))
  }
  callback()
}


const validateMoney = (rule, value, callback) => {
  if (value && !isMoney(value)) {
    return callback(new Error('输入金额格式错误'))
  }

  callback()
}

export default {
  blurRes: name => ({ required: true, trigger: 'blur', message: name + REQUIREDTIP }),

  changeRes: name => ({ required: true, trigger: 'change', message: name + REQUIREDTIP }),

  phone: { required: true, trigger: 'blur', validator: validateMobile },

  tel: { required: true, trigger: 'blur', validator: validateTel },

  email: { required: true, trigger: 'blur', validator: validateEamil },

  idCard: { required: true, trigger: 'blur', validator: validateIdCard },

  isMoney: { required: false, trigger: 'blur', validator: validateMoney },

  choice: { required: true, trigger: 'blur'},
  validPhone,
}

<template>
  <div class="main">
    <Search :hidden-nav="true"/>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/member-center' }">会员中心</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/member-center/order-manage/store-order' }">我的订单</el-breadcrumb-item>
        <el-breadcrumb-item>订单详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="line"></div>
    <div class="content">
      <div class="left">
        <h4 style="margin-bottom:4px;">{{orderVo.orderStatus}}</h4>
        <p v-if="overtime && orderVo.processStatCode === 'WAIT_PAY' &&  orderVo.paymentWayCode === 'CASH' && orderVo.isPayed === 'N'">剩余付款时间 <span>{{countdown.dd}}天 {{countdown.hh}}:{{countdown.mm}}:{{countdown.ss}}
        </span></p>
        <p v-if="!overtime">支付已超时,请重新购买</p>
        <p v-if="orderVo.processStatCode === 'WAIT_SEND'">仓库发货中，请您耐心等待</p>
        <p v-if="orderVo.processStatCode === 'SENDING'">部分商品未出库，请您耐心等待</p>
        <p v-if="orderVo.processStatCode === 'SEND' || orderVo.processStatCode === 'FINISH'">商品已全部出库，请留意物流最新信息。</p>
        <p v-if="orderVo.processStatCode === 'WAIT_APPROVE' && orderVo.isManufacturerSalesmanOrder">厂家业务员【{{orderVo.salesmanName}}】为您下单，请您进行确认！</p>
        <p v-if="orderVo.processStatCode === 'CANCEL'">订单已取消，交易自动关闭</p>
        <p v-if="orderVo.processStatCode === 'REFUND_FAILURE' && orderVo.partialFinishType === 'ERP'">订单退款已被驳回，继续交易</p>
        <p v-if="orderVo.processStatCode === 'REFUND_FAILURE' && orderVo.partialFinishType != 'ERP'">订单退款已被驳回，交易自动关闭</p>
        <el-button @click="toPay(orderVo.orderId)" v-if="overtime && orderVo.processStatCode === 'WAIT_PAY'" type="primary" size="mini">去支付</el-button>
        <el-button @click="bindVisible('确认订单')" v-if="orderVo.processStatCode === 'WAIT_APPROVE' && userType ==='BUYERS'" type="primary" size="mini">确认订单</el-button>
        <el-button @click="exportDetail(orderVo.orderId)" size="mini">导出商品明细</el-button>
        <!--<el-button @click="bindVisible('下载药检单')" v-if="orderVo.processStatCode === 'FINISH'" size="mini">下载药检单</el-button>-->
        <el-button @click="buyAgain" v-if="(userType ==='BUYERS' || userType ==='BUYERS_TEAM')" size="mini">再次购买</el-button>
        <el-button @click="buyAgain" v-if="userType ==='SALESMAN' && orderVo.processStatCode != 'WAIT_PAY' && orderVo.processStatCode != 'WAIT_APPROVE'" size="mini">再次购买</el-button>
        <el-button @click="bindCancelOrder(orderVo.orderId)" v-if="(orderVo.processStatCode === 'WAIT_PAY' || orderVo.processStatCode === 'WAIT_APPROVE') && userType ==='BUYERS'" size="mini">取消订单</el-button>
        <el-button @click="goOrderExpress(orderVo.orderId)" v-if="orderVo.processStatCode === 'SEND' || orderVo.processStatCode === 'SENDING' || orderVo.processStatCode === 'FINISH'" size="mini">查看物流</el-button>
        <el-button @click="toRefunds" v-if="orderVo.processStatCode === 'FINISH'" size="mini">申请退货</el-button>
        <el-button @click="downLoadLine" size="mini">下载商品资质</el-button>
      </div>
      <div class="steps">
        <el-steps
            :active="active"
            process-status="finish"
            finish-status="finish"
            :align-center="true">
          <el-step title="提交订单" :description="orderVo.createDate"></el-step>
          <el-step v-if="orderVo.processStatCode !== 'CANCEL'" title="付款成功" :description="orderVo.payedTime"></el-step>
          <el-step v-if="orderVo.processStatCode !== 'CANCEL'" title="商品出库" :description="orderVo.sentTime"></el-step>
          <el-step v-if="orderVo.processStatCode !== 'CANCEL'" title="等待收货" :description="orderVo.receiverTime"></el-step>
          <el-step v-if="orderVo.processStatCode !== 'CANCEL'" title="订单完成"  :description="orderVo.finishTime"></el-step>
          <el-step v-if="orderVo.processStatCode === 'CANCEL'" title="交易关闭"></el-step>
        </el-steps>
      </div>
    </div>
    <div class="footer">
      <div class="message">
        <div class="msg">
          <p class="num">订单编号: {{orderVo.orderNum}}</p>
          <div class="detail">
            <div><b>下单时间</b><span>{{orderVo.createDate}}</span></div>
            <div><b>支付方式</b><span v-if="orderVo.paymentWayCode === 'CASH'">在线支付</span><span v-if="orderVo.paymentWayCode === 'CREDIT'">赊销</span></div>
            <div><b>支付时间</b><span>{{orderVo.payedTime || '-/-'}}</span></div>
            <div><b>订单来源</b><span>{{orderVo.orderSource}}</span></div>
            <div><b>下单渠道</b><span>{{orderVo.orderChannel}}</span></div>
            <div><b>配送方式</b><span>{{orderVo.orderId}}</span></div>
            <div><b>备注信息</b><span>{{orderVo.remark}}</span></div>
          </div>
          <div class="detail">
            <div><b>收货人</b><span>{{orderVo.receiverName}}</span></div>
            <div><b>收货地区</b><span>{{orderVo.province}}-{{orderVo.city}}-{{orderVo.district}}</span></div>
            <div><b>联系手机</b><span>{{orderVo.receiverMobile}}</span></div>
            <div><b>详细地址</b><span>{{orderVo.receiverAddr}}</span></div>
          </div>
          <div class="detail">
            <div><b>客户名称</b><span>{{orderVo.buyerNm || orderBuyerVo.buyerNm}}</span></div>
            <div><b>客户编码</b><span>{{orderVo.buyerNo || orderBuyerVo.buyerNo}}</span></div>

            <div v-if="orderVo.ettleAccountNm!=='默认分支机构'" ><b>分支机构</b><span>{{orderVo.ettleAccountNm}}</span></div>
            <div v-if="orderVo.ettleAccountNm!=='默认分支机构'"><b>分支机构编码</b><span>{{orderVo.code}}</span></div>
            <div><b>下单联系人</b><span>{{orderVo.salesmanName || orderVo.receiverName}}</span></div>
            <div><b>联系手机</b><span>{{orderVo.loginMobileId}}</span></div>
          </div>
          <div class="detail">
            <div><b>总品规</b><span>{{orderVo.generalSpecification}}</span></div>
            <div><b>总数量</b><span>{{orderVo.totalNum}}</span></div>
            <div><b>商品总金额</b><span>{{$util.toFixed(orderVo.productTotalAmount)}}</span></div>
            <div><b>优惠券</b><span>{{orderVo.couponPrice}}</span></div>
            <div><b>折扣优惠</b><span>{{orderVo.discountPrice}}</span></div>
            <div><b>积分抵扣</b><span>{{orderVo.integralDiscountAmount}}</span></div>
            <div><b>运费</b><span>{{orderVo.freightAmount}}</span></div>
            <div><b>实付金额</b><span>{{$util.toFixed(orderVo.orderTotalAmount)}}</span></div>
<!--            <div><b>发票状态</b><span v-if="orderVo.processStatCode === 'FINISH' && currentBills.length > 0">已开票</span><span v-else>未开票</span><el-button type="text" @click="downloadInvoice" v-if="orderVo.processStatCode === 'FINISH' && currentBills.length > 0 && orderVo.invNoAddress !=='' " style="padding:0; flex:1">下载发票</el-button></div>-->
            <div><b>发票状态</b><span v-if="currentBills.length > 0">已开票</span><span v-else>未开票</span><el-button type="text" @click="downloadInvoice" v-if="currentBills.length > 0 && orderVo.invNoAddress !=='' " style="padding:0; flex:1">下载发票</el-button></div>
          </div>
        </div>
      </div>
      <div class="table">
        <template>
<!--          <el-table :data="[]" border style="width: 100%;" class="table-body">-->
<!--            <el-table-column-->
<!--                  type="selection"-->
<!--                  align="center"-->
<!--                  width="55">-->
<!--            </el-table-column>-->
<!--            <el-table-column label="商品信息" min-width="275px">-->
<!--              <template slot="header" slot-scope="scope">-->
<!--                <span>商品信息</span>-->
<!--                <el-button type="text" style="margin-left: 20px; padding: 0;" v-if="orderItemVos && orderItemVos.length > 0" @click="dialogListVisible = true">查看购物清单</el-button>-->
<!--              </template>-->
<!--            </el-table-column>-->
<!--            &lt;!&ndash; <el-table-column label="商品编码" width="90px" /> &ndash;&gt;-->
<!--            <el-table-column label="单价" align="right" width="180px" />-->
<!--            <el-table-column label="购买数量" width="90px"/>-->
<!--            <el-table-column align="right" label="实付金额" width="110px"></el-table-column>-->
<!--            <el-table-column align="right" width="110px" label="优惠明细" />-->
<!--            &lt;!&ndash;<el-table-column label="最近/远效期" prop="expDate" v-if="userType === 'BUYERS'" show-overflow-tooltip align="center"></el-table-column>&ndash;&gt;-->
<!--            <el-table-column label="发货信息" width="150px" v-if="orderVo.orderStatus === '已发货' || orderVo.orderStatus === '发货中' || orderVo.orderStatus === '已完成'" />-->
<!--            <el-table-column label="操作" fixed="right" width="110px" />-->
<!--          </el-table>-->
          <div v-for="(item,index) in packageVos" :key="item.packageId">
            <div class="package">
              <div class="package-index">包裹{{index+1}}</div>
              <div class="package-text">物流公司：{{item.logisticsCompanyNm}}</div>
              <div class="package-text">物流单号：{{item.logisticsOrderNum}}</div>
              <el-popover placement="bottom" width="600" trigger="click">
                <div style="line-height: 40px;border-bottom: 1px solid #E9E9E9;color: #262626;background: #fafafa">
                  <b style="padding: 2px 4px;color: #0971EB;border: 1px solid #0971EB;font-size: 12px;border-radius: 3px;margin: 0 5px 0 15px;">包裹{{index+1}}</b>
                  物流公司：<span style="padding-right: 20px">{{item.logisticsCompanyNm}}</span>
                  物流单号：<span>{{item.logisticsOrderNum}}</span>
                </div>
                <order-express :express="orderExpress" />
                <el-button slot="reference" type="text" class="express" @click="bindExpress(item.packageId)">
                  查看物流状态<i class="el-icon-arrow-down"></i>
                </el-button>
              </el-popover>
              <el-button @click="bindVisible('确认收货',item.packageId)" v-if="item.isBuyerSigned != 'Y'" type="primary" size="mini" style="margin-left: 20px; border: 1px solid #FA6400; background-color: #FA6400;">确认收货</el-button>
            </div>
            <el-table :data="item.itemDetailVos"
                      :show-header="false"
                      :span-method="objectSpanMethod1"
                      border style="width: 100%">
              <el-table-column label="商品信息" min-width="275px" show-overflow-tooltip>
                <template slot-scope="scope">
                   <img src="@/assets/img/icon/diaobo.png" class="diaobo" v-if="scope.row.isTransferRecordFlag === 'Y'">
                  <div class="product-info"><div class="product-image" @click="productDetail(scope.row)">
                      <el-image :src="scope.row.thumbnailUrl || productDefaultImg" @error="scope.row.thumbnailUrl = productDefaultImg"
                                fit="fit"/>
                      <img src="@/assets/img/detail/yizuofei.png" class="oneScrap" v-if="scope.row.orderItemStatus === 'INVALID'">
                    </div>
                    <ul class="product-detail">
                      <li class="name" @click="productDetail(scope.row)">
                        {{$util.getGoodsName(scope.row)}}
                      </li>
                      <li class="detail">
                        <p>商品编码：<span class="spec">{{ scope.row.productCode}}</span></p>
                      </li>
                      <li class="detail">
                        <p>规格：<span class="spec">{{ scope.row.spec}}</span> 单位：<span>{{ scope.row.unit}}</span></p>
                      </li>
                      <li class="manufacturer detail">生产厂家:{{ scope.row.manufacturer}}</li>
                      <li class="detail" v-if="userType ==='SALESMAN'">是否调拨:{{ scope.row.isTransferRecordFlag === 'Y' ? '是' : '否'}}</li>
                      <li class="detail" v-if="userType ==='SALESMAN'">调拨经营单元：{{ scope.row.transferRecordOrg || '无'}}</li>
                      <li class="manufacturer detail" style="color:#2B2B2B" v-if="scope.row.orderItemType === 'PRESENT'">[特殊商品]</li>
                    </ul>
                  </div>
                  <div></div>
                </template>
              </el-table-column>
              <el-table-column label="单价" prop="originalUnitPrice" width="180px" show-overflow-tooltip align="right">
                <template slot-scope="scope">
                  <span class="textColor">¥{{ scope.row.orderItemType === 'PRESENT'? '0.00':scope.row.originalUnitPrice }}</span>
                </template>
              </el-table-column>
              <el-table-column label="购买数量" width="90px" prop="number" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{ scope.row.num }}</span>
                </template>
              </el-table-column>
              <el-table-column label="实付金额" prop="originalUnitPrice" align="right" width="110px"
                               show-overflow-tooltip>
                <template slot-scope="scope">
<!--                  <p class="textColor">¥{{ $util.toFixed((scope.row.quantity * 100000) * (scope.row.originalUnitPrice * 100000) /10000000000) }}</p>-->
                  <p class="textColor">实付：¥{{ $util.toFixed(scope.row.subtotal) }}</p>
                  <!--                <s class="textColor" v-if="Number(scope.row.discountAmount) != 0">¥{{ (scope.row.salePrice * scope.row.num).toFixed(2) }}</s>-->
                </template>
              </el-table-column>
              <el-table-column label="优惠明细" width="110px" show-overflow-tooltip align="right">
                <template slot-scope="scope">
                  <span class="textColor">-￥{{scope.row.discountAmount}}</span>
                  <p  v-if="scope.row.integralDiscountAmount > 0" class="goodInfor">积分：
                    -￥{{scope.row.integralDiscountAmount}}
                  </p>
                </template>
              </el-table-column>
              <!--<el-table-column label="最近/远效期" prop="expDate" v-if="userType === 'BUYERS'" show-overflow-tooltip align="center"></el-table-column>-->
              <el-table-column label="发货信息" width="150px" show-overflow-tooltip v-if="orderVo.orderStatus === '已发货' || orderVo.orderStatus === '发货中' || orderVo.orderStatus === '已完成' || orderVo.orderStatus === '部分终止' || orderVo.orderStatus === '退款驳回'">
                <template slot-scope="scope">
                  <div :style="{'color': isHidelight(item.itemDetailVos,scope.row) ? '#fa6400' : ''}">
                    <p class="goodInfor">发货批号：{{ scope.row.batchNum }}</p>
                    <p class="goodInfor">有效期至：{{ scope.row.exp }}</p>
                    <p class="goodInfor">实发数量：x{{ scope.row.quantity  }}</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="110px" fixed="right" align="center">
                <template slot-scope="scope">
                  <div class="apply">
                    <el-button @click="beforeAddItem(scope.row.skuId, 'NORMAL', scope.row.num,scope.row)" :loading='loading' v-if="userType ==='BUYERS' || userType ==='BUYERS_TEAM'" class="download" size="mini" type="text">再次购买</el-button>
                    <el-button @click="beforeAddItem(scope.row.skuId, 'NORMAL', scope.row.num,scope.row)" :loading='loading' v-if="userType ==='SALESMAN' && orderVo.processStatCode != 'WAIT_PAY' && orderVo.processStatCode != 'WAIT_APPROVE'" class="download" size="mini" type="text">再次购买</el-button>
                    <el-button  @click="handleDownloadSheet(scope.row)" v-if="orderVo.processStatCode === 'FINISH'" class="download" size="mini" type="text">下载药检单</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>

        </template>

        <div class="package" v-show="packageVos && packageVos.length > 0 && tableData.length > 0"><div class="package-text">未发货</div></div>
        <im-table-page
            ref="tablePage"
            :data.sync="tableData"
            :remote-method="queryData"
            :show-page="false"
            row-key="id"
            :defaultExpandAll="true"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            :more-check-box="handleSelectionChange"
            v-show="tableData.length > 0">
          <el-table-column
            type="selection"
            :selectable="selectable"
            align="center"
            v-if="paramValueString === 'Y' && orderVo.processStatCode === 'WAIT_SEND' && orderVo.isPayed ==='Y' && orderVo.paymentWayCode ==='CASH'"
            width="55">
          <!--paramValueString === 'Y' && orderVo.processStatCode === 'WAIT_SEND' && orderVo.paymentWayCode ==='CASH' && orderVo.isPayed ==='Y'-->
          </el-table-column>
          <el-table-column
            type="selection"
            :selectable="selectable"
            align="center"
            v-else-if="userType =='SALESMAN' && adjustStatus == '1' && !salemanAdjustFlag && orderVo.paymentWayCode !='CASH'"
            width="55">
            <!--paramValueString === 'Y' && orderVo.processStatCode === 'WAIT_SEND' && orderVo.paymentWayCode ==='CASH' && orderVo.isPayed ==='Y'-->
          </el-table-column>
          <!-- 部分终止 orderVo.processStatCode === 'PARTIAL_FINISH'-->
          <el-table-column label="商品信息" min-width="275px" show-overflow-tooltip>
            <template slot="header" slot-scope="scope">
              <span>商品信息</span>
              <el-button type="text" style="margin-left: 20px; padding: 0;" v-if="orderItemVos && orderItemVos.length > 0" @click="dialogListVisible = true">查看购物清单</el-button>
              <el-button type="text" style="margin-left: 20px; padding: 0;" v-if="batchBtnShow && paramValueString === 'Y' && (orderVo.processStatCode === 'WAIT_SEND' || orderVo.processStatCode === 'PARTIAL_FINISH') && orderVo.paymentWayCode ==='CASH' && orderVo.isPayed ==='Y'" @click="toAllApplyRefunds">批量退款</el-button>
              <el-button size="mini" v-if="userType =='SALESMAN' && adjustStatus == '1' && !salemanAdjustFlag && orderVo.paymentWayCode !='CASH'" @click="updateOrderItemSalePrice(scope.row)">确认修改订单价格</el-button>
            </template>
            <template slot-scope="scope">
              <img src="@/assets/img/icon/diaobo.png" class="diaobo" v-if="scope.row.isTransferRecordFlag === 'Y'">
              <div class="product-info">
                <div class="product-image" @click="productDetail(scope.row)">
                  <el-image :src="scope.row.thumbnailUrl || productDefaultImg" @error="scope.row.thumbnailUrl = productDefaultImg"
                            fit="fit"/>
                  <img src="@/assets/img/detail/yizuofei.png" class="oneScrap" v-if="scope.row.orderItemStatus === 'INVALID'">
                </div>
                <ul class="product-detail">
                  <li class="name" @click="productDetail(scope.row)">{{$util.getGoodsName(scope.row)}}</li>
                  <li class="detail">
                      <p>商品编码：<span class="spec">{{ scope.row.productCode}}</span></p>
                    </li>
                  <li class="detail">
                    <p>规格：<span class="spec">{{ scope.row.spec}}</span> 单位：<span>{{ scope.row.unit}}</span></p>
                  </li>
                  <li class="manufacturer detail">生产厂家:{{ scope.row.manufacturer}}</li>
                  <li class="detail" v-if="userType ==='SALESMAN'">是否调拨:{{ scope.row.isTransferRecordFlag === 'Y' ? '是' : '否'}}</li>
                  <li class="detail" v-if="userType ==='SALESMAN'">调拨经营单元：{{ scope.row.transferRecordOrg || '无'}}</li>
                  <li class="manufacturer detail" style="color:#2B2B2B" v-if="scope.row.orderItemType === 'PRESENT'">[特殊商品]</li>
                  <li class="detail">
                    <p>备注：<span>{{ scope.row.remark === ''? '无': scope.row.remark}}</span></p>
                  </li>
                  <li class="detail">
                    <p >细单状态：{{scope.row.changeStatusName}}</p>
                  </li>
                </ul>
              </div>
              <div></div>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="productCode" label="商品编码" width="90px" align="center" show-overflow-tooltip /> -->
          <el-table-column label="单价" prop="money" width="180px" show-overflow-tooltip align="right">

            <template slot-scope="scope">
              <span v-if ="adjustStatus == '1' && orderVo.processStatCode === 'WAIT_SEND' && scope.row.refundFlag !== 2 && !salemanAdjustFlag">
                <ul class="product-detail" v-if="userType ==='SALESMAN' && orderVo.paymentWayCode !=='CASH'">
                  <li class="manufacturer detail">
                     <el-input-number :precision="2" :step="0.01" :min="0" :max="10000000000" size="mini" v-model="scope.row.settlePrice"> </el-input-number>
                  </li>
                  <li class="manufacturer detail">
                      <!-- 调价范围：{{(currencyToNumber(scope.row.originalUnitPrice)*minPercent).toFixed(2)}}~{{currencyToNumber(scope.row.originalUnitPrice)*maxPercent}} -->
                      调价范围：{{calcPrice(scope.row.originalUnitPrice, minPercent)}}~{{calcPrice(scope.row.originalUnitPrice, maxPercent)}}
                  </li>
                </ul>
                <span class="textColor" v-if="scope.row.originalUnitPrice == scope.row.settlePrice">¥{{scope.row.originalUnitPrice }}</span>
                <span class="textColor" v-else><s>¥{{scope.row.originalUnitPrice }}</s>¥{{scope.row.settlePrice}}</span>
              </span>
              <a v-else>
                <span class="textColor" v-if="scope.row.originalUnitPrice == scope.row.settlePrice">¥{{scope.row.originalUnitPrice }}</span>
                <span class="textColor" v-else><s>¥{{scope.row.originalUnitPrice }}</s>¥{{scope.row.settlePrice}}</span>
              </a>
            </template>
          </el-table-column>
          <el-table-column label="购买数量" width="90px" prop="number" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.num }}</span>
            </template>
          </el-table-column>
          <el-table-column align="right" label="金额" prop="code" width="110px"
              show-overflow-tooltip>
            <template slot-scope="scope">
              <p class="textColor" v-if="paramValueString === 'Y' && orderVo.processStatCode !== 'REFUND_FAILURE' && scope.row.refundFlag ==1 && orderVo.paymentWayCode ==='CASH' && orderVo.isPayed ==='Y'">退款：¥{{ $util.toFixed(scope.row.cancelQuantity * scope.row.settlePrice) }}</p>
<!--              <p class="textColor" v-else>实付：¥{{ $util.toFixed(scope.row.subtotal) }}</p>-->
              <p class="textColor" v-else>实付：¥{{ calculateTotalprice(scope.row.settlePrice, scope.row.num) }}</p>
<!--              <s class="textColor" v-if="Number(scope.row.discountAmount) != 0">¥{{ (scope.row.salePrice * scope.row.num).toFixed(2) }}</s>-->
            </template>
          </el-table-column>
          <el-table-column label="优惠明细" width="110px" prop="number" show-overflow-tooltip align="right">
            <template slot-scope="scope">
              <span class="textColor">-￥{{scope.row.discountAmount}}</span>
              <p  v-if="scope.row.integralDiscountAmount > 0" class="goodInfor">积分：
                -￥{{scope.row.integralDiscountAmount}}
              </p>
            </template>
          </el-table-column>
          <!--<el-table-column label="最近/远效期" prop="expDate" v-if="userType === 'BUYERS'" show-overflow-tooltip align="center"></el-table-column>-->
          <el-table-column label="发货信息" width="150px" prop="number" show-overflow-tooltip v-if="orderVo.orderStatus === '已发货' || orderVo.orderStatus === '发货中' || orderVo.orderStatus === '已完成' || orderVo.orderStatus === '部分终止' || orderVo.orderStatus === '退款驳回'">
          <template slot-scope="scope">
            <p class="goodInfor" v-if="scope.row.refundFlag ==1">退款数：× {{ scope.row.cancelQuantity }}</p>
            <p class="goodInfor" v-else>剩余数量：{{ scope.row.notSendQuantity }}</p>
          </template>
        </el-table-column>
          <el-table-column label="操作" width="110px" fixed="right" align="center" >
            <template slot-scope="scope">
              <div class="apply">
                <el-button @click="beforeAddItem(scope.row.skuId, 'NORMAL', scope.row.num,scope.row)" v-if="(userType ==='BUYERS' || userType ==='BUYERS_TEAM') && scope.row.orderItemType !== 'PRESENT'" class="download" type="text" size="mini">再次购买</el-button>
                <el-button @click="beforeAddItem(scope.row.skuId, 'NORMAL', scope.row.num,scope.row)" v-if="userType ==='SALESMAN' && orderVo.processStatCode != 'WAIT_PAY' && orderVo.processStatCode != 'WAIT_APPROVE' && scope.row.orderItemType !== 'PRESENT'" class="download" type="text" size="mini">再次购买</el-button>
                <el-button @click="handleDownloadSheet(scope.row)" v-if="orderVo.processStatCode === 'FINISH'" class="download" size="mini" type="text">下载药检单</el-button>
                <el-button @click="toApplyRefunds(scope.row)" v-if="paramValueString === 'Y' && scope.row.refundFlag ==0 && orderVo.processStatCode === 'WAIT_SEND' && orderVo.paymentWayCode ==='CASH' && orderVo.isPayed ==='Y'" class="download" type="text" size="mini">退款</el-button>
              </div>
            </template>
          </el-table-column>
        </im-table-page>
      </div>
      <!--弹框-->
      <el-dialog class="dialog-table" :width="dialogWidth" :visible.sync="dialogVisible">
        <span slot="title">{{dialogStatus}}</span>
        <img v-if="dialogStatus === '确认收货'" :src="comfirmGoodsIcon" class="comfirmPic">
        <img v-if="dialogStatus === '确认订单'" :src="comfirmOrderIcon" class="comfirmPic">
        <div class="comfirmOrder" v-if="dialogStatus === '确认收货' || dialogStatus === '确认订单'">您是否{{dialogStatus}}</div>
        <!--药检单-->
        <div class="sheet" v-if="dialogStatus == '下载药检单'">
          以下订单中的商品已检索到药检单，请勾选下载，若存在缺失资料品种请联系业务员（未出库品种不可下载）
        </div>
        <im-table-page v-if="dialogStatus == '下载药检单'" ref="tablePage" :data.sync="sheetData" :remote-method="querySheet" :more-check-box="moreCheckBox" >
          <el-table-column label="序号" type="index" width="60px" align="center"/>
          <el-table-column type="selection" width="40px" align="center"/>
          <el-table-column prop="productNm" label="商品名称" min-width="140px">
            <template slot-scope="scope">
              {{$util.getGoodsName(scope.row)}}
            </template>
          </el-table-column>
          <el-table-column prop="spec" label="规格" min-width="100px" align="center"/>
          <el-table-column prop="manufacturer" label="生产厂家" min-width="183px" />
          <el-table-column prop="batch" label="商品批号" width="100px" align="center"/>
        </im-table-page>
        <!--电子发票-->
        <div class="invoice" v-if="dialogStatus === '查看发票'">
          <div class="invoice-pic" v-for="item in currentBills"><img :src="item.invNoAddress"></div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="bindComfirmOrder" v-if="dialogStatus === '确认收货'">确 定</el-button>
          <el-button type="primary" @click="bindSureOrder" v-if="dialogStatus == '确认订单'">确 定</el-button>
          <el-button type="primary" @click="downloadSheet" :disabled="!fileIds.length > 0" v-if="dialogStatus == '下载药检单'">下载药检单</el-button>
          <el-button type="primary" @click="downloadInvoice" :disabled="!currentBills.length > 0" v-if="dialogStatus === '查看发票'">下载发票</el-button>
        </span>
      </el-dialog>
      <!--商品清单-->
      <el-dialog class="dialog-table" v-if="orderItemVos" width="1000px" title="购物清单" :visible.sync="dialogListVisible">
        <el-table :data="orderItemVos" border :max-height="dialogTableHeight + 'px'" style="width: 100%; padding-top: 1px;">
          <el-table-column label="商品信息" width="290px" show-overflow-tooltip>
            <template slot-scope="scope">
               <img src="@/assets/img/icon/diaobo.png" class="diaobo" v-if="scope.row.isTransferRecordFlag === 'Y'">
              <div class="product-info">
                <div class="product-image" @click="productDetail(scope.row)">
                  <el-image :src="scope.row.thumbnailUrl" @error="scope.row.thumbnailUrl = productDefaultImg"
                            fit="fit"/>
                  <img src="@/assets/img/detail/yizuofei.png" class="oneScrap" v-if="scope.row.orderItemStatus === 'INVALID'">
                </div>
                <ul class="product-detail">
                  <li class="name" @click="productDetail(scope.row)">
                    {{$util.getGoodsName(scope.row)}}
                  </li>
                  <li class="detail">
                    <p>规格：<span class="spec">{{ scope.row.spec}}</span> 单位：<span>{{ scope.row.unit}}</span></p>
                  </li>
                  <li class="manufacturer detail">生产厂家：{{ scope.row.manufacturer}}</li>
                  <li class="detail" v-if="userType ==='SALESMAN'">是否调拨:{{ scope.row.isTransferRecordFlag === 'Y' ? '是' : '否'}}</li>
                  <li class="detail" v-if="userType ==='SALESMAN'">调拨经营单元：{{ scope.row.transferRecordOrg || '无'}}</li>
                  <li class="manufacturer detail" style="color:#2B2B2B" v-if="scope.row.orderItemType === 'PRESENT'">[特殊商品]</li>
                </ul>
              </div>
              <div></div>
            </template>
          </el-table-column>
          <el-table-column label="单价" prop="money" width="180px" show-overflow-tooltip align="right">
            <template slot-scope="scope">
              <span class="textColor">¥{{ scope.row.orderItemType === 'PRESENT'? '0.00':scope.row.originalUnitPrice }}</span>
            </template>
          </el-table-column>
          <el-table-column label="购买数量" prop="number" show-overflow-tooltip align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.num }}</span>
            </template>
          </el-table-column>
          <el-table-column align="right"
              label="实付金额" prop="code" width="150px"
              show-overflow-tooltip>
            <template slot-scope="scope">
              <p class="textColor">¥{{ $util.toFixed(scope.row.subtotal) }}</p>
<!--              <s class="textColor" v-if="Number(scope.row.discountAmount) != 0">¥{{ (scope.row.salePrice * scope.row.num).toFixed(2) }}</s>-->
            </template>
          </el-table-column>
          <el-table-column label="优惠明细" prop="number" show-overflow-tooltip align="right">
            <template slot-scope="scope">
              <span class="textColor">-￥{{scope.row.discountAmount}}</span>
            </template>
          </el-table-column>
          <!--<el-table-column label="最近/远效期" prop="expDate" v-if="userType === 'BUYERS'" show-overflow-tooltip align="center"></el-table-column>-->
          <!--          <el-table-column-->
<!--          label="发货信息" prop="number" width="160px"-->
<!--          show-overflow-tooltip v-if="orderVo.orderStatus === '已发货' || orderVo.orderStatus === '发货中' || orderVo.orderStatus === '已完成'">-->
<!--            <template slot-scope="scope">-->
<!--              <p class="goodInfor">发货批号：{{ scope.row.batchNum }}</p>-->
<!--              <p class="goodInfor">有效期至：{{ scope.row.exp }}</p>-->
<!--              <p class="goodInfor">实发数量：x{{ scope.row.sendQuantity  }}</p>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column label="操作" width="100px" align="center">
            <template slot-scope="scope">
              <div class="apply">
                <el-button @click="beforeAddItem(scope.row.skuId, 'NORMAL', scope.row.num,scope.row)" v-if="(userType ==='BUYERS' || userType ==='BUYERS_TEAM') && scope.row.orderItemType !== 'PRESENT'" class="download" size="mini" type="text">再次购买</el-button>
                <el-button @click="beforeAddItem(scope.row.skuId, 'NORMAL', scope.row.num,scope.row)" v-if="userType ==='SALESMAN' && orderVo.processStatCode != 'WAIT_PAY' && orderVo.processStatCode != 'WAIT_APPROVE' && scope.row.orderItemType !== 'PRESENT'" class="download" size="mini" type="text">再次购买</el-button>
                <el-button @click="bindVisible('下载药检单')" v-if="orderVo.processStatCode === 'FINISH'" class="download" size="mini" type="text">下载药检单</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>

      <!--下载商品资质-->
      <el-dialog class="dialog-table" title="下载商品资质" width="60%" :visible.sync="downLicensesVisible">
        <el-table v-loading="downLicensesLoading" ref="tableData" :data="licenses" border >
          <el-table-column prop="productNm" label="商品名称" align="center" width="180" show-overflow-tooltip/>
          <el-table-column prop="productCode" label="商品编码" align="center" width="180" show-overflow-tooltip/>
          <!--<el-table-column prop="validOnDate" label="开始日期－结束日期" align="center" width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.isForever == 'Y'">长期</span>
              <span v-else-if="scope.row.licenseStartDate && scope.row.licenseEndDate">{{scope.row.licenseStartDate}}至{{scope.row.licenseEndDate}}</span>
              <span v-else>{{scope.row.licenseStartDate}}{{scope.row.licenseEndDate}}</span>
            </template>
          </el-table-column> -->
          <el-table-column prop="pdfUrl" label="操作" align="center">
            <template v-if="scope.row.pdfUrl" slot-scope="scope">
              <span v-for="(item, index) in scope.row.pdfUrl.split(',')">
                <span style="color: deepskyblue;cursor: pointer" @click="$util.downFiles(item,scope.row.productCode+'|'+scope.row.productNm)">电子版资质资料</span>
              </span>
            </template>
          </el-table-column>
        </el-table>

        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="downLicensesVisible = false">取 消</el-button>
        </span>
      </el-dialog>

      <!--批量退单详情-->
      <el-dialog class="dialog-table" title="退单" width="60%" :visible.sync="refundsVisible">
        <el-table v-loading="downLicensesLoading" ref="tableData2" :data="refundData" border >
            <el-table-column label="商品信息" min-width="275px" show-overflow-tooltip>
                <template slot-scope="scope">
                    <img src="@/assets/img/icon/diaobo.png" class="diaobo" v-if="scope.row.isTransferRecordFlag === 'Y'">
                    <div class="product-info">
                        <div class="product-image" @click="productDetail(scope.row)">
                            <el-image :src="scope.row.thumbnailUrl || productDefaultImg" @error="scope.row.thumbnailUrl = productDefaultImg"
                                      fit="fit"/>
                            <img src="@/assets/img/detail/yizuofei.png" class="oneScrap" v-if="scope.row.orderItemStatus === 'INVALID'">
                        </div>
                        <ul class="product-detail">
                            <li class="name" @click="productDetail(scope.row)">{{$util.getGoodsName(scope.row)}}</li>
                            <li class="detail">
                                <p>商品编码：<span class="spec">{{ scope.row.productCode}}</span></p>
                            </li>
                            <li class="detail">
                                <p>规格：<span class="spec">{{ scope.row.spec}}</span> 单位：<span>{{ scope.row.unit}}</span></p>
                            </li>
                            <li class="manufacturer detail">生产厂家:{{ scope.row.manufacturer}}</li>
                            <li class="detail" v-if="userType ==='SALESMAN'">是否调拨:{{ scope.row.isTransferRecordFlag === 'Y' ? '是' : '否'}}</li>
                            <li class="detail" v-if="userType ==='SALESMAN'">调拨经营单元：{{ scope.row.transferRecordOrg || '无'}}</li>
                            <li class="manufacturer detail" style="color:#2B2B2B" v-if="scope.row.orderItemType === 'PRESENT'">[特殊商品]</li>
                        </ul>
                    </div>
                    <div></div>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="productCode" label="商品编码" width="90px" align="center" show-overflow-tooltip /> -->
            <el-table-column label="单价" prop="money" width="100px" show-overflow-tooltip align="right">

              <template slot-scope="scope">
                <span class="textColor">¥{{ scope.row.originalUnitPrice }}</span>
              </template>
            </el-table-column>
            <el-table-column label="退款数量" width="160px" prop="number" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-input-number
                        size="mini"
                        v-model="scope.row.num"
                        placeholder="数量"
                        :precision="getDecimalPointNum(scope.row)"
                        :step="scope.row.productId ? getRealMinBuyNum(scope.row) : 1"
                        :min="orderVo.processStatCode == 'PARTIAL_FINISH'?scope.row.notSendQuantity:scope.row.minBuyDecimals"
                        :max="scope.row.notSendQuantity"
                        @change="handleChange"
                        class="proNums"
                    ></el-input-number>
                </template>
            </el-table-column>
        </el-table>

        <span slot="footer" class="dialog-footer">
          <el-button size="small" type="primary" @click="refundReson">确 定</el-button>
          <el-button size="small" @click="refundsVisible = false">取 消</el-button>
        </span>
      </el-dialog>
      <el-dialog class="dialog-table" width="400px" title="退款理由" :visible.sync="dialogRefundVisible">
        <div class="textarea-input">
          <textarea
            v-model="refundRemark"
            placeholder="请输入具体原因"
            class="textarea"
            maxlength="40"
          ></textarea>
          <!-- 右下角显示文字 -->
          <div class="word-limit">
            <span class="green">{{ refundRemark.length }}</span>/40
          </div>
        </div>
        <span slot="footer">
        <el-button @click="getRefunds" type="primary">确 定</el-button>
        <el-button @click="dialogRefundVisible = false">取 消</el-button>
      </span>
      </el-dialog>
    </div>
    <div v-show="refundLoading" class="loading-mask" v-loading="refundLoading" element-loading-text="退款请求中"></div>
  </div>
</template>

<script>
import {
  checkBuyAgain,
  findDetail,
  buyAgain,
  exportOrderItem,
  comfirmOrder,
  comfirmTheOrder,
  downloadSheet,
  pageOrderDrugList,
  currentBills,
  cancelOrder,
  downloadInvoice,
  findWmsLogistics,
  downloadSheetV2
} from '@/api/member-center/order-manage/order-list'
  import { findByParamInfInnerCode, refundOnlineOrderItem, refundOnlinePartialFinishOrderItem } from '@/api/member-center/order-manage/order-list'
  import { addItem, findRefundDetail, updateOrderItemSalePrice} from '@/api/detail'
  import Search from '@/components/Search/Search'
  import { getBus,getUserType } from '@/utils/auth'
  import { mapGetters } from 'vuex'
  import OrderExpress from './components/order-express'
  import productDefaultImg from '@/assets/img/index/product_default.png'
  import comfirmGoodsIcon from '@/assets/img/member-center/comfirm-order.png'
  import comfirmOrderIcon from '@/assets/img/member-center/icon-order.png'
  import { findByProductIds } from '@/api/user'
  import addToCartMixin from '@/common/addToCart'
  import numeral from 'numeral'
  export default {
    name: 'order-detail',
    components: { Search, OrderExpress },
    mixins:[addToCartMixin],
    data () {
      return {
        loading:false,
        productDefaultImg,
        comfirmGoodsIcon,
        comfirmOrderIcon,
        orderId: '',
        orderItemId: '',
        packageId:'',
        dialogTableHeight:window.innerHeight - 200,
        order: {},
        active: 1,
        orderVo: {},
        orderBuyerVo: {},
        returnOrderCashVo:{
          refundTotalAmount: 100.00,
          refundCouponAmount: 50.00
        },
        countdown: {
          dd: 0,
          hh: 0,
          mm: 0,
          ss: 0
        },
        overtime: 1,
        dialogReturnVisible: false,
        dialogVisible: false,
        dialogStatus: '',
        dialogWidth: '',
        dialogListVisible: false,
        refundsVisible: false,
        tableData: [],
        sheetData: [], //药检单
        refundData: [], // 退款
        fileIds:[],
        orderItemVos: [],//购物清单
        packageVos: [], //分包裹
        packageVosList:[],//包裹列表
        currentBills:[], //电子发票
        orderExpress:[],
        refundDetail: {}, //退款流水
        downLicensesVisible:false,
        downLicensesLoading:false,
        adjustStatus: '0',
        salemanAdjustFlag: false,
        minPercent:0,
        maxPercent:1,
        licenses:[],
        paramValueString: '', // 客户端是否可以发起退款
        multipleSelection: [],
        dialogRefundVisible: false,
        refundRemark: '',
        refundLoading: false,
        isClickRefund: true,
        batchBtnShow: false // 没有可退款商品时，隐藏批量退款按钮
      }
    },
    created () {
      this.orderId = this.$route.params.id
      this.findByParamInfInnerCode()
    },
    computed: {
      ...mapGetters(['userType']),
      expressCode(){//物流单号
          return this.orderExpress.length > 0 ? this.orderExpress[0].inputCode : ''
      },
      calculateTotalprice(){
        return function(price, percent) {
          let num = price+''
          let amount = num.replace(/,/g, '') * percent
          console.log('amount' , amount)
          return numeral(amount).format('0,0.00');
        };
      }
    },
    methods: {
      //是否高光显示发货信息
      isHidelight(list = [],row = {}){
        let arr = list.filter(item=> item.productId === row.productId && item.salesdtlId === row.salesdtlId)
        return arr.length > 1
      },
      //退款流水
      bindRefund(orderId){
        this.dialogReturnVisible = true
        findRefundDetail({orderId}).then(res => {
          if(res.code != '0') return
          this.refundDetail = res.data
        })
      },
      //倒计时
      getTimeList(endTime){
        let _this = this;
        setInterval(function(){
          let start = new Date().getTime();
          let rightTime =  endTime - start
          if (rightTime > 0) {
            let dd = Math.floor(rightTime / 1000 / 60 / 60 / 24)
            let hh = Math.floor((rightTime / 1000 / 60 / 60) % 24)
            let mm = Math.floor((rightTime / 1000 / 60) % 60)
            let ss = Math.floor((rightTime / 1000) % 60)
            dd = dd > 9 ? dd : '0' + dd
            hh = hh > 9 ? hh : '0' + hh
            mm = mm > 9 ? mm : '0' + mm
            ss = ss > 9 ? ss : '0' + ss
            _this.countdown.dd = dd
            _this.countdown.hh = hh
            _this.countdown.mm = mm
            _this.countdown.ss = ss
            _this.overtime = 1
          } else {
            _this.overtime = 0
          }
        },1000)
      },
      toRefunds(){
        if(this.userType === 'SALESMAN') {
          this.$router.push({path:'/member-center/order-manage/refunds-yw',query:{
            active:'APPLY_RETURN'
            }})
        }
        if(this.userType === 'BUYERS'){
          this.$router.push({path:'/member-center/order-manage/refunds'})
        }
      },
      // 申请退款
      toApplyRefunds(item) {
        this.refundData = []
        this.refundData.push(item)

        this.refundsVisible = true
      },
      // 批量申请退款
      toAllApplyRefunds() {
        this.refundData = []
        if(this.orderVo.processStatCode === 'PARTIAL_FINISH') {
          let refundOriginData = JSON.parse(JSON.stringify(this.$refs.tablePage.data))
          this.refundData = refundOriginData.filter((item) => {
            return item.refundFlag === 0
          })
          if (this.refundData.length) {
            this.refundsVisible = true
          } else {
            this.$message.error('您没有可退商品！')
          }
        } else {
          this.refundData = JSON.parse(JSON.stringify(this.multipleSelection))
          if (this.refundData.length) {
            this.refundsVisible = true
          } else {
            this.$message.error('未勾选退款商品！')
          }
        }

      },
      //取消订单
      bindCancelOrder(orderId){
        cancelOrder({ orderId }).then(res => {
          if(res.code != '0') return
          this.$message.success('订单取消成功!')
          this.$refs.tablePage.doRefresh()
        })
      },
      buyAgain () {
        buyAgain({ orderId: this.orderId }).then(res => {
          const { code } = res
          if (res.data.tips === '') {
            this.$message.success('加入成功')
          } else {
            this.$message.error(res.data.tips)
          }
          this.$store.dispatch('user/setCartNum', res.data.allProductTypeTotalQuantity)
        })
      },
      objectSpanMethod1(data,e){
        let {row, column, rowIndex, columnIndex} = data
        if(columnIndex !== 5){
            let arr = row.itemDetailVos.filter(item => item.productId === row.productId && item.salesdtlId === row.salesdtlId);
            if(rowIndex === 0){
                return {
                  rowspan: arr.length,
                  colspan:1
                }
            }else {
              if(row.itemDetailVos[rowIndex - 1].productId !== row.productId || row.itemDetailVos[rowIndex - 1].salesdtlId !== row.salesdtlId){
                return {
                  rowspan: arr.length,
                  colspan:1
                }
              }else {
                return {
                  rowspan: 0,
                  colspan: 0
                }
              }
            }
        }
      },
      //合并列
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex !== 5 && columnIndex !== 6) {
          if (rowIndex < this.packageVosList.length - 1){
            if (row.productId === this.packageVosList[rowIndex + 1].productId) {
                return {
                  rowspan: 2,
                  colspan: 1
                };
            }else {
              return {
                rowspan: 0,
                colspan: 0
              }
            }
          }else {
            return {
              rowspan: 0,
              colspan: 0
            }
          }
        }
      },
      //获取订单数据
      queryData () {
        let orderId = this.orderId
        return new Promise(then => {
          findDetail({
            orderId,
            orgId: getBus().orgId
          }).then(res => {
            const { data } = res
            this.packageVosList = [];
            this.orderVo = data.orderVo
            this.orderBuyerVo = data.orderBuyerVo
            this.orderItemVos = data.orderItemVos
            this.packageVos = data.packageVos || []
            if (this.orderVo.processStatCode === 'WAIT_PAY') {
               this.getTimeList(this.orderVo.paymentTime)
            }
            // 判断是否可以改价
            this.salemanAdjustFlag = this.orderItemVos.some(item => {
              return item.salemanAdjustFlag == 1
            })
            this.adjustStatus = data.adjustStatus // 返回是否调价标志0不能1能
            this.minPercent = data.minPercent
            this.maxPercent = data.maxPercent
            console.log('返回')
            console.log(data.orderItemVos)
            console.log(data.packageVos)
            this.packageVos.forEach((item,index)=>{
                // item.orderItemId = 1111
                item.itemDetailVos.forEach((obj,index1)=>{
                    obj = {...obj,...item}
                    this.packageVosList.push({...obj,...item})
                    item.itemDetailVos[index1] = obj
                })
                item.itemDetailVos = item.itemDetailVos.sort((a,b)=>{ //根据商品id排序
                    if(a.salesdtlId !== b.salesdtlId){
                      return -1
                    }else{
                      return a.productId - b.productId
                    }
                })

                this.packageVos[index] = item
            })
            this.packageVosList = this.packageVosList.sort((a,b)=>{//根据商品id排序
                if(a.salesdtlId !== b.salesdtlId){
                  return -1
                }else{
                  return a.productId - b.productId
                }
            })
            // if (this.orderVo.processStatCode === 'FINISH') {
              this.getCurrentBills()
            // }
            if (this.orderVo.processStatCode === 'SEND') {
              this.orderVo.orderStatus = data.packageVos ? '已发货' : '发货中'
            }
            this.batchBtnShow = data.orderItemVoList.some(item => {
              return item.refundFlag === 0
            })

              switch (this.orderVo.orderStatus) {
              case '待确认':
              case '待付款':
                this.active = 0
                break
              case '待发货':
              case '已取消':
                this.active = 1
                break
              case '发货中':
                this.active = 2
                break
              case '已发货':
                this.active = 3
                break
              case '已完成':
                this.active = 4
                break
            }
            data.records = data.orderItemVoList
            //修改调价输入展示错误
            data.records.forEach((item) => {
              item.settlePrice = this.priceToNumber(item.settlePrice)
            })
            data.total = data.orderItemVoList.length
            then(res)
          })
        })
      },
      productDetail(row) {
        if(row.orderItemType != 'PRESENT'){
          this.$router.push({name:"detail",params:{id: row.productId}})
        }
      },
      //去支付
      toPay (orderId) {
        this.$router.push({path:'/cashier/'+ orderId})
      },
      sheetRefresh() {
        this.$refs.tablePage.doRefresh({currentPage: 0, pageSize: 10})
      },
      querySheet (data) {
        return pageOrderDrugList({
          ...data,
          condition:{
            orderId: this.orderId
          }
        })
      },
      //查看发票
      getCurrentBills(){
        currentBills({ orderId: this.orderId }).then(res => {
          if(res.code != '0') return
          res.data.map((item)=>{
            if(item.invNoAddress)
            this.currentBills.push(item.invNoAddress)
          })
        })
      },
      //弹框
      bindVisible(status,packageId){
        switch (status){
          case '确认收货':
          case '确认订单':
            this.dialogWidth = '400px'
            break
          case '查看发票':
            this.dialogWidth = '640px'
            break
          case '下载药检单':
            this.dialogWidth = '900px'
            this.sheetRefresh()
            break
          default:
            break
        }

        this.dialogStatus = status //设置标题
        this.dialogVisible = true  //显示
        this.packageId = packageId
      },
      //点击下载药检单
      handleDownloadSheet(item){
        if(!item.salesdtlId){
          this.$message.warning('该条数据缺少细单ID')
        }else {
          this.downloadSheetV2(item)
        }
      },
      //确定收货
      bindComfirmOrder(){
        comfirmOrder({packageId:this.packageId,orderId:this.orderId}).then(res =>{
          if(res.code != '0') return
          this.dialogVisible = false
          this.$refs.tablePage.doRefresh()
        })
      },
      //确认订单
      bindSureOrder(){
        comfirmTheOrder({ orderId:this.orderId}).then(res => {
          if(res.code != '0') return
          this.dialogVisible = false
          this.$refs.tablePage.doRefresh()
        })
      },
      //下载药检单弹框多选
      moreCheckBox(val) {
        let fileIds = []
        val.forEach(element => {
          fileIds.push(element.fileId)
        })
        this.fileIds = fileIds
      },
      //下载药检单V2
      downloadSheetV2({salesdtlId,orderId,productNm}){
        let data = {
          // salesDtLid: 27269058,
          salesDtLid:salesdtlId,
          orgId: orderId
        }
        downloadSheetV2(data).then(res=>{
          this.$util.downloadBlobFile(res,`${productNm}-${orderId}-药检单`).then(()=>{
            this.$message.success('下载成功')
          }).catch( rej =>{
            this.$message.error(rej.msg)
          })
        })
      },
      //下载药检单
      downloadSheet(){
       let params = {fileIdList:this.fileIds,orgId:getBus().orgId}
        downloadSheet(params).then(res =>{
          this.$util.downloadBlobFile(res).then(()=>{
            this.$message.success('下载成功')
            this.dialogVisible = false
          })
        })
      },
      //下载电子发票
      downloadInvoice(){
       let params = {filePathList:this.currentBills}
       downloadInvoice(params).then(res =>{
            let blob = new Blob([res.data], {type :'application/zip'})
            const disposition = res.headers['content-disposition'].split(";")[1].split("=")[1];
            var fileName = decodeURI(disposition);
            //去掉双引号
            fileName = fileName.replace(/\"/g, "");
            let link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            link.remove()
            this.$message.success('下载成功')
            this.dialogVisible = false
        })
      },
      //查看物流状态
      bindExpress(packageId){
        findWmsLogistics({packageId:packageId}).then(res => {
          if(res.code != '0') return
          this.orderExpress = res.data.result || []
        })
      },
      //查看物流
      goOrderExpress (orderId) {
        this.$router.push({path:'/express-detail/' + orderId})
      },
      //导出商品明细
      exportDetail(id){
        exportOrderItem({'orderId':id}).then(res=>{
          let blob =new Blob([res.data],{type:'application/vnd.ms-excel'})
          const disposition = res.headers['content-disposition'].split(";")[1].split("=")[1];
          var fileName = decodeURI(disposition);
          //去掉双引号
          fileName = fileName.replace(/\"/g, "");
          let link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          link.remove()
          this.$message.success('下载成功')
        })
      },
      downLoadLine() {
        this.downLicensesVisible = true;
        this.downLicensesLoading = true;
        let productIdList = []
        this.orderItemVos.map(val => {
          productIdList.push(val.productId)
        })
        findByProductIds({productIds:productIdList.toString()}).then(res=>{
          const { data } = res
          this.licenses = data
          this.downLicensesLoading = false
        });
      },

      updateOrderItemSalePrice (row) {
        let _this = this
        let params = {
          orderItemListVo: [],
          orderId: null
        }
        if (this.multipleSelection.length) {
          params.orderId = this.multipleSelection[0].orderId
          // console.log('updateOrderItemSalePrice')
          this.multipleSelection.forEach((item) => {
            params.orderItemListVo.push({orderItemId: item.orderItemId, settlePrice: (item.settlePrice* 10000).toFixed(0)})
          })
          this.$confirm('此操作将进行改价, 是否继续?', '改价', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            updateOrderItemSalePrice(params).then(res => {
              const { code } = res
              if (code === '0') {
                _this.loading = false;
                _this.$message.success('修改成功！')
              }
              _this.$refs.tablePage.doRefresh()
            }).catch(error => {
              _this.loading = false;
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消改价'
            });
          });
        }
      },
      findByParamInfInnerCode() {
        const params= {
            innerCode: "CUSTOMER_REFUND_AUTH",
            orgId:  getBus().orgId
        }
        findByParamInfInnerCode(params).then(res => {
          if (this.userType == 'SALESMAN') {
            this.paramValueString = 'Y'
          }else {
              this.paramValueString = res.data.CUSTOMER_REFUND_AUTH
          }
        }).catch((err) => {
          this.paramValueString = 'N'
        })
      },
      // 选中单子列表
      handleSelectionChange(val) {
          console.log(val)
        this.multipleSelection = val;
      },
      // 购买数量加减
      handleChange(val) {
          console.log(val)
      },
      // 退单理由编辑
      refundReson() {
        this.refundsVisible = false
        this.dialogRefundVisible = true
      },
      // 细单退款
      getRefunds() {
        let _that = this
        const params ={
          buyersNo: this.orderVo.buyerNo,
          buyersNm: this.orderVo.buyerNm,
          orderId: this.orderVo.orderId,
          refundRemark: this.refundRemark,
          refundOrderItemDetailVoList: []
        }
        let isRequst = true
        this.refundData.forEach((item, index) => {
          if(item.num == 0) {
            isRequst = false
          }
          params.refundOrderItemDetailVoList.push({
            orderItemId: item.orderItemId,
            returnedQuantity: item.num,
            skuId: item.skuId,
            refundFlag: item.refundFlag,
            totalAmount: (item.num * item.settlePrice)
          })
        })
        if (isRequst) {
          if(this.userType ==='SALESMAN') {
            if (params.refundRemark !== "") {
              this.refundLoading = true
              this.dialogRefundVisible = false
              if(this.orderVo.processStatCode === 'PARTIAL_FINISH' && this.isClickRefund) {
                this.isClickRefund = false
                this.refundOnlinePartialFinishOrderItem(params)
              } else if(this.isClickRefund) {
                this.isClickRefund = false
                this.refundOnlineOrderItem(params)
              }
            } else {
              this.$message.warning('请填写退款理由')
            }
          } else {
            this.refundLoading = true
            this.dialogRefundVisible = false
            if(this.orderVo.processStatCode === 'PARTIAL_FINISH' && this.isClickRefund) {
                this.isClickRefund = false
              this.refundOnlinePartialFinishOrderItem(params)
            } else if(this.isClickRefund) {
                this.isClickRefund = false
              this.refundOnlineOrderItem(params)
            }
          }
        } else {
          this.$message.error('退款数量为0，请核实')
        }
      },
      // 待发货退款
      refundOnlineOrderItem(params) {
        let _that = this
        refundOnlineOrderItem(params).then(res => {
          if (res.code == "0") {
            this.$message.success('申请退款,等待审核!')
          } else {
            this.$message.error(res.msg)
          }
        }).catch((err) => {
          console.log(err)
        }).finally(()=> {
          this.refundLoading = false
          this.isClickRefund = true
          let timer = setTimeout(function () {
            _that.$refs.tablePage.doRefresh()
            clearTimeout(timer)
          }, 1000)
        })
      },
      // 部分终止退款
      refundOnlinePartialFinishOrderItem(params) {
        let _that = this
        refundOnlinePartialFinishOrderItem(params).then(res => {
          if (res.code == "0") {
            this.$message.success('申请退款,等待审核!')
          } else {
            this.$message.error(res.msg)
          }
        }).catch((err) => {
          console.log(err)
        }).finally(()=> {
          this.refundLoading = false
          this.isClickRefund = true
          let timer = setTimeout(function () {
            _that.$refs.tablePage.doRefresh()
            clearTimeout(timer)
          }, 1000)
        })
      },
      // 某项禁止退单
      selectable(row, index) {
        return row.refundFlag == 0
      },
      // 计算调价金额
      calcPrice(originalPrice, percent){
        let num = originalPrice+''
        let amount = num.replace(/,/g, '') * percent
        return numeral(amount).format('0,0.00');
      },
      // 千分位价格转为数字
      priceToNumber(originalPrice){
        let amount = originalPrice.replace(/,/g, '')
        return amount
      },
    }
  }

</script>

<style scoped lang="scss">
  $minWidth:1200px;
  $maxWidth:1600px;
  $mainWidth:90%;
  .main {
    background: #f5f5f5;
    .diaobo {
        width: 42px;
        height: 42px;
        z-index: 9;
    }
    /deep/.el-checkbox__inner {
      border-color: #999;
    }
    .line {
      min-width: $minWidth;
      max-width: $maxWidth;
      width: $mainWidth;
      height: 12px;
      background: #d9d9d9;
      box-shadow: 0 1px 0 0 #d1d1d1, 0 1px 3px 0 rgba(0, 0, 0, 0.3);
      border-radius: 6px;
      margin: 0 auto;
    }
    .goodInfor{
      font-size: 12px;
    }
    .content {
      width: $mainWidth;
      max-width: $maxWidth;
      min-width: $minWidth;
      margin: -5px auto 0;
      padding: 20px 24px;
      display: flex;
      border-radius: 8px;
      background-color: #fff;
      align-items: center;

      .left {
        text-align: left;
        width: 280px;

        h4 {
          font-size: 20px;
          color: #222;
        }

        p {
          font-size: 14px;
          color: #717171;
          padding: 6px 0 13px;

          span {
            color: #FF3B30;
          }
        }

        /deep/ .el-button {
          padding: 7px 8px;

          &:nth-child(6) {
            margin-top: 10px;
          }
          &:nth-child(3) {
            margin-right: 10px;
          }
          &.el-button--default{
            color: #666;
            border-color: #ccc;
          }
        }
        /deep/ .el-button + .el-button{
          margin-left: 0;
          margin-right: 10px;
        }
      }

      .steps {
        width: 920px;
        flex: 1;

        /deep/ .el-steps {
          border-left: 1px solid #e5e5e5;
          justify-content: center;

          .el-step {
            flex-basis: 20% !important;
          }

          .el-step__icon {
            border: none;
            width: 20px;
            height: 20px;
            font-size: 14px;
            background: #CCCCCC;

            .el-step__icon-inner {
              color: #fff;
            }
          }

          .el-step__title {
            font-size: 14px;
            font-weight: 500;
            color: rgba(153, 153, 153, 1);
            line-height: 34px;
          }

          /*完成步骤*/
          .is-finish {
            .el-step__icon {
              .el-step__icon-inner {
                color: #ffffff;
              }
              color:#0971EB;
              background:#0971EB;
            }

            &.el-step__title {
              color: rgba(34, 34, 34, 1);
            }
          }


          .el-step__main {
            font-weight: 500;
            color: rgba(34, 34, 34, 1);
          }

          .el-step__line {
            margin: 0 28px !important;
            height: 1px;
          }
        }
      }
    }

    .footer {
      width:$mainWidth;
      min-width: $minWidth;
      max-width: $maxWidth;
      margin: 10px auto 0;
      font-size: 14px;
      display: flex;
      padding-bottom: 20px;
      box-sizing: border-box;
      .message {
        width: 280px;
        .msg {
          border-radius: 8px;
          background-color: #fff;
          text-align: left;
          padding: 8px;
          box-sizing: border-box;
          .num {
            background-color: $hoverBg;
            padding: 10px 16px;
            color: #222222;
            border-radius: 4px 4px 0 0;
            margin: 0;
            font-size: 14px;
            span {
              color: #0971EB;
              margin-left: 15px;
              cursor: pointer;
            }
          }

          .header-msg {
            line-height: 40px;
            color: #262626;
            background: #F1F7FE;
            padding-left: 10px;
            margin-bottom: 10px;
          }

          .detail {
            padding: 18px 0 0 0;
            border-bottom: 1px solid rgba(229, 229, 229, 1);

            & > div {
              color: #999999;
              margin-bottom: 14px;
              display: flex;
            }

            b {
              width: 5em;
              text-align: right;
              display: inline-block;
              line-height: 1.4;
            }

            span {
              color: #2b2b2b;
              margin-left: 14px;
              flex: 1;
              line-height: 1.4;

              i {
                color: #FF3B30;
              }
            }

            &:last-child {
              border-bottom: none;
            }
          }

          /deep/ {
            .el-dialog {
              min-height: 440px;

              .el-dialog__body {
                padding: 10px 10px 20px;
              }

              .el-dialog__footer {
                padding: 11px;
                position: absolute;
                bottom: 0;
                width: 700px;
                background-color: #f5f5f5;
                text-align: center;

                .el-button {
                  padding: 8px 27px;
                  font-size: 12px;
                  border-radius: 0;
                }
              }
            }

            .el-table .el-table__header th .cell {
              color: #666;
              font-size: 12px;
            }
          }
        }
      }

      .table {
        border-radius: 8px;
        padding: 8px 8px 20px;
        margin-left: 10px;
        background-color: #fff;
        border-top: 1px solid #E5E5E5;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        display: block;
        flex: 1;
        width: 100%;
      }
        /deep/ {
          .el-table {
            .product-info {
              display: flex;
              flex-direction: row;
              padding: 0 10px;

              .product-image {
                width: 70px;
                min-width: 70px;
                height: 70px;
                cursor: pointer;

                .el-image {
                  width: 100%;
                  height: 100%;
                }
                .oneScrap{
                  width: 62px;
                  height: 62px;
                  position: absolute;
                  left: 14px;
                  top: 11px;
                }
              }

              .product-detail {
                padding-left: 16px;
                width: 212px;

                li {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-size: 12px;
                }

                .name {
                  height: 18px;
                  font-size: 14px;
                  font-weight: 500;
                  color: rgba(34, 34, 34, 1);
                  line-height: 18px;
                  margin-bottom: 4px;
                  cursor: pointer;
                }

                .detail {
                  font-weight: 400;
                  color: rgba(113, 113, 113, 1);
                  line-height: 18px;
                  height: 18px;

                  .spec {
                    margin-right: 10px;
                    max-width: 75px;
                    display: inline-flex;
                    overflow: hidden;
                  }

                  &.manufacturer {
                    white-space: normal;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  }
                }
              }
            }

            .order-row {
              background: #F1F7FE;
              td {
                background-color: #f2f6fe;
                padding: 0;
              }
            }

            .el-table__row .cell {
              //padding: 0;
            }

            .el-table__header-wrapper .el-table__header th .cell {
              color: #717171;
              font-weight: 400;
            }

            .cell {
              width: 100% !important;

              .el-table__expand-icon {
                display: none;
              }

              .el-table__indent, .el-table__placeholder {
                display: none;
              }
            }

            .download {
              color: #2B2B2B;
              font-size: 14px;

              &:hover {
                color: #0971EB;
              }
            }

            .textColor {
              color: #222;
            }
          }
        }
    }

    .breadcrumb {
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      padding-left: 24px;
      min-width: $minWidth;
      max-width: $maxWidth;
      width: $mainWidth;
      margin: 0 auto;

      /deep/ .el-breadcrumb {
        display: inline-block;
        line-height: 50px;
        float: left;
      }
    }
    .package{
      display: flex;
      align-items: center;
      border-left: 1px solid #E5E5E5;
      border-right: 1px solid #E5E5E5;
      border-bottom: 1px solid #E5E5E5;
      height: 40px;
      line-height: 40px;
      padding: 0 12px;
      text-align: left;
      background-color: $hoverBg;
      .package-index{
        font-size: 12px;
        height: 17px;
        line-height:17px;
        color: $ThemeColor;
        padding: 0 4px;
        border:1px solid $ThemeColor;
        border-radius:3px;
        margin-right: 8px;
      }
      .package-text{
        margin-right: 20px;
        font-size: 14px;
        color: #222;
      }
    }
    .sheet{
      text-align: left;
      padding: 10px 8px;
      line-height: 20px;
      font-size: 14px;
      color: #222;
      margin-bottom: 8px;
      background-color: #F1F7FE;
     }
     .invoice{
       height: 220px;
       margin-top: 30px;
       display: flex;
       overflow-y: hidden;
       flex-wrap: nowrap;
     }
     .invoice-pic{
       white-space: nowrap;
       width: 190px;
       height: 190px;
       padding: 10px;
       margin-right: 20px;
       border: 1px solid #e5e5e5;
       display: inline-block;
     }
     .invoice-pic img{
       display: block;
       width: 170px;
       height: 170px;
     }
    .comfirmPic{
      width: 24px;
      height: 24px;
      display: block;
      margin: 0 auto 10px;
    }
    .comfirmOrder{
      height: 20px;
      line-height: 20px;
      color: #717171;
      font-size: 14px;
      text-align: center;
    }
  }
  /deep/{
  .table-body .el-table__empty-block{
    display: none;
  }
  .dialog-table{
    .el-dialog__body{
      padding: 8px;
    }
    .el-dialog__footer{
      padding: 10px 0;
      text-align: center;
      background-color: #F5F5F5;
    }
  }
}
/deep/.el-step.is-center {
  .el-step__description{
    padding: 0;
  }
}
  .textarea-input {
      position: relative;
      .textarea {
          border: 1px solid #eee;
          outline: none; // 去除聚焦边框
          resize: none; // 去除右下的可拖动
          appearance: none; // 去除内阴影样式
          padding: 10px; // 增加内边距
          box-sizing: border-box;
          height: 96px;
          background: #fff;
          border-radius: 3px;
          width: 100%;
      }
      .word-limit {
          .green {
              color: #00D76E;
          }
          position: absolute;
          right: 10px;
          bottom: 10px;
          font-size: 12px;
          font-weight: 400;
          color: #999999;
      }
  }
  .loading-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
  }
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "interfaceOrderDiv" },
    [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.params.title))]),
      _c(
        "el-form",
        {
          staticClass: "searchForm",
          attrs: {
            inline: true,
            model: _vm.searchForm,
            size: "small",
            "label-width": "70px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "客户编码" } },
            [
              _c("el-input", {
                staticClass: "searchFormItem",
                class: { isExpanded: _vm.isExpanded },
                attrs: { placeholder: "输入客户编码" },
                model: {
                  value: _vm.searchForm.customerCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "customerCode", $$v)
                  },
                  expression: "searchForm.customerCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "分支机构编码", "label-width": "100px" } },
            [
              _c("el-input", {
                staticClass: "searchFormItem",
                class: { isExpanded: _vm.isExpanded },
                attrs: { placeholder: "输入分支机构编码" },
                model: {
                  value: _vm.searchForm.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "code", $$v)
                  },
                  expression: "searchForm.code",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "门店编码" } },
            [
              _c("el-input", {
                staticClass: "searchFormItem",
                class: { isExpanded: _vm.isExpanded },
                attrs: { placeholder: "输入门店编码" },
                model: {
                  value: _vm.searchForm.storeCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "storeCode", $$v)
                  },
                  expression: "searchForm.storeCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isExpanded,
                  expression: "isExpanded",
                },
              ],
              attrs: { label: "客户名称" },
            },
            [
              _c("el-input", {
                staticClass: "searchFormItem",
                class: { isExpanded: _vm.isExpanded },
                attrs: { placeholder: "输入客户名称" },
                model: {
                  value: _vm.searchForm.buyersNm,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "buyersNm", $$v)
                  },
                  expression: "searchForm.buyersNm",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isExpanded,
                  expression: "isExpanded",
                },
              ],
              attrs: { label: "分支机构名称", "label-width": "100px" },
            },
            [
              _c("el-input", {
                staticClass: "searchFormItem",
                class: { isExpanded: _vm.isExpanded },
                attrs: { placeholder: "输入分支机构名称" },
                model: {
                  value: _vm.searchForm.ettleAccountNm,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "ettleAccountNm", $$v)
                  },
                  expression: "searchForm.ettleAccountNm",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isExpanded,
                  expression: "isExpanded",
                },
              ],
              attrs: { label: "门店名称" },
            },
            [
              _c("el-input", {
                staticClass: "searchFormItem",
                class: { isExpanded: _vm.isExpanded },
                attrs: { placeholder: "输入门店名称" },
                model: {
                  value: _vm.searchForm.storeNm,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "storeNm", $$v)
                  },
                  expression: "searchForm.storeNm",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isExpanded,
                  expression: "isExpanded",
                },
              ],
              attrs: { label: "商品名称" },
            },
            [
              _c("el-input", {
                staticClass: "searchFormItem",
                class: { isExpanded: _vm.isExpanded },
                attrs: { placeholder: "输入商品名称" },
                model: {
                  value: _vm.searchForm.productNm,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "productNm", $$v)
                  },
                  expression: "searchForm.productNm",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isExpanded,
                  expression: "isExpanded",
                },
              ],
              attrs: { label: "要货时间", "label-width": "100px" },
            },
            [
              _c("el-date-picker", {
                staticClass: "dateFormItem",
                class: { isExpanded: _vm.isExpanded },
                attrs: {
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                  format: "yyyy-MM-dd",
                  size: "small",
                  align: "right",
                  "unlink-panels": "",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                on: { change: _vm.changeDatePicker },
                model: {
                  value: _vm.searchForm.time,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "time", $$v)
                  },
                  expression: "searchForm.time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "formBut" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.search },
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "text", size: "mini" },
                  on: { click: _vm.reset },
                },
                [
                  _c("span", { staticStyle: { color: "#000000" } }, [
                    _vm._v("重置"),
                  ]),
                ]
              ),
              _c("div", {
                staticStyle: {
                  width: "1px",
                  height: "12px",
                  "background-color": "#E5E5E5",
                  margin: "0 8px",
                  "margin-top": "8px",
                },
              }),
              _c(
                "el-button",
                {
                  staticStyle: { color: "#000000", "font-weight": "400" },
                  attrs: { type: "text", size: "mini" },
                  on: { click: _vm.onExpand },
                },
                [
                  _vm._v(" " + _vm._s(_vm.isExpanded ? "收起" : "展开") + " "),
                  _c("i", {
                    class: _vm.isExpanded
                      ? "el-icon-arrow-up"
                      : "el-icon-arrow-down",
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "im-tool-bar",
            { staticClass: "butDiv" },
            [
              _c(
                "template",
                { slot: "left" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.gotoPrice },
                    },
                    [_vm._v("调价")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.rematchStore },
                    },
                    [_vm._v("重新匹配门店")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.rematch },
                    },
                    [_vm._v("重新匹配品种")]
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "right" },
                [
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "rightCheck",
                      on: {
                        change: (checked) =>
                          _vm.checkChange(checked, "isPriceMatch"),
                      },
                      model: {
                        value: _vm.isPriceMatch,
                        callback: function ($$v) {
                          _vm.isPriceMatch = $$v
                        },
                        expression: "isPriceMatch",
                      },
                    },
                    [_vm._v(" 价格匹配 ")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "rightCheck",
                      on: {
                        change: (checked) =>
                          _vm.checkChange(checked, "isPriceLower"),
                      },
                      model: {
                        value: _vm.isPriceLower,
                        callback: function ($$v) {
                          _vm.isPriceLower = $$v
                        },
                        expression: "isPriceLower",
                      },
                    },
                    [_vm._v(" 低于业态价 ")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "rightCheck",
                      on: {
                        change: (checked) =>
                          _vm.checkChange(checked, "isPriceHigh"),
                      },
                      model: {
                        value: _vm.isPriceHigh,
                        callback: function ($$v) {
                          _vm.isPriceHigh = $$v
                        },
                        expression: "isPriceHigh",
                      },
                    },
                    [_vm._v(" 高于业态价 ")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "rightCheck",
                      on: {
                        change: (checked) =>
                          _vm.checkChange(checked, "unmatched"),
                      },
                      model: {
                        value: _vm.unmatched,
                        callback: function ($$v) {
                          _vm.unmatched = $$v
                        },
                        expression: "unmatched",
                      },
                    },
                    [_vm._v("数据异常 ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "tableDiv" },
            [
              _c(
                "el-table",
                {
                  ref: "table",
                  staticClass: "table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.dataList,
                    border: "",
                    size: "mini",
                    "row-class-name": _vm.tableRowClassName,
                    "cell-class-name": _vm.cellClassName,
                    "header-cell-class-name": _vm.cellClassName,
                    "cell-style": _vm.cellStyle,
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      width: "55",
                      fixed: "left",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "客户编码",
                      prop: "customerCode",
                      width: "80",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "门店编码",
                      prop: "storeCode",
                      width: "80",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "分支机构编码",
                      prop: "code",
                      width: "80",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "门店名称",
                      prop: "storeNm",
                      width: "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "订单编号",
                      prop: "distributionOrderNum",
                      width: "80",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品编码",
                      prop: "imallProductCode",
                      width: "80",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品名称",
                      prop: "productNm",
                      width: "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "规格",
                      prop: "spec",
                      width: "60",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "生产厂家",
                      prop: "manufacturer",
                      width: "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "数量",
                      prop: "quantity",
                      width: "60",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "平台报价/要货价格",
                      prop: "originalPrice",
                      algin: "right",
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticStyle: { display: "flex" } }, [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.askingPrice)),
                              ]),
                              scope.row.priceComparison === "1" ||
                              scope.row.priceComparison === "3"
                                ? _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _vm._v("/ "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "price",
                                          style: {
                                            background:
                                              scope.row.priceComparison === "3"
                                                ? "rgba(51,174,103,0.8)"
                                                : "rgba(224,32,52,0.8)",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(scope.row.originalPrice)
                                          ),
                                        ]
                                      ),
                                      scope.row.priceComparison === "3"
                                        ? _c("img", {
                                            staticClass: "priceIcon",
                                            attrs: {
                                              src: require("@/assets/img/member-center/up.png"),
                                              alt: "",
                                            },
                                          })
                                        : _c("img", {
                                            staticClass: "priceIcon",
                                            attrs: {
                                              src: require("@/assets/img/member-center/down.png"),
                                              alt: "",
                                            },
                                          }),
                                    ]
                                  )
                                : _c("span", [
                                    _vm._v(
                                      "/" + _vm._s(scope.row.originalPrice)
                                    ),
                                  ]),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "要货日期",
                      prop: "createTime",
                      width: "100",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "第三方商品编码",
                      prop: "thirdProductCode",
                      "min-width": "100",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("im-pagination", {
            staticClass: "table-page",
            attrs: {
              isShowMainInfo: true,
              total: _vm.total,
              "current-page": _vm.currentPage,
              "page-size": _vm.pageSize,
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function ($event) {
                _vm.currentPage = $event
              },
              "update:pageSize": function ($event) {
                _vm.pageSize = $event
              },
              "update:page-size": function ($event) {
                _vm.pageSize = $event
              },
              pagination: _vm.handlePagination,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottomDiv" },
        [
          _c(
            "el-checkbox",
            {
              staticClass: "allChecked",
              on: { change: _vm.selectAll },
              model: {
                value: _vm.allChecked,
                callback: function ($$v) {
                  _vm.allChecked = $$v
                },
                expression: "allChecked",
              },
            },
            [_vm._v("全选")]
          ),
          _c("div", { staticClass: "bottomRight" }, [
            _c(
              "div",
              { staticStyle: { "margin-right": "10px" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { filterable: "", placeholder: "请选择总单" },
                    on: { change: _vm.remarkSelect },
                    model: {
                      value: _vm.chooseRemark,
                      callback: function ($$v) {
                        _vm.chooseRemark = $$v
                      },
                      expression: "chooseRemark",
                    },
                  },
                  _vm._l(_vm.chooseRemarkList, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
                _c("el-input", {
                  attrs: { placeholder: "请输入订单备注" },
                  on: { change: _vm.remarkChange },
                  model: {
                    value: _vm.remark,
                    callback: function ($$v) {
                      _vm.remark = $$v
                    },
                    expression: "remark",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "bottomButton" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "removeBut",
                    attrs: { size: "medium" },
                    on: { click: _vm.batchDeletion },
                  },
                  [_vm._v("批量删除")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "orderBut",
                    attrs: { size: "medium", loading: _vm.loading },
                    on: { click: _vm.checkExpDate },
                  },
                  [_vm._v("立即下单")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "im-drawer",
        {
          attrs: {
            title: "委托配送匹配",
            visible: _vm.drawerVisible,
            "before-close": _vm.beforeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawerVisible = $event
            },
            confirm: _vm.handleConfirm,
            cancel: _vm.resetForm,
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.drawerLoading,
                  expression: "drawerLoading",
                },
              ],
              ref: "form",
              staticStyle: { "padding-right": "50px", "padding-top": "24px" },
              attrs: {
                "label-width": "180px",
                model: _vm.form,
                rules: _vm.formRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "模式名称：", prop: "modeName" } },
                [
                  _c("span", { staticClass: "modeName" }, [
                    _vm._v(_vm._s(_vm.form.modeName)),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.chooseMode()
                        },
                      },
                    },
                    [_vm._v("选择模式")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品ERP_ID：", prop: "erpCode" } },
                [
                  _c("span", { staticClass: "modeName" }, [
                    _vm._v(_vm._s(_vm.form.erpCode)),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.chooseGoods()
                        },
                      },
                    },
                    [_vm._v("选择商品")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "tipItem" }, [
                _c("p", [_vm._v("商品名称：" + _vm._s(_vm.form.productNm))]),
                _c("p", [_vm._v("商品规格：" + _vm._s(_vm.form.spec))]),
                _c("p", [_vm._v("商品单位：" + _vm._s(_vm.form.unit))]),
                _c("p", [_vm._v("生产厂家：" + _vm._s(_vm.form.manuFacturer))]),
              ]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "第三方商品编码：",
                    prop: "thirdProductCode",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入第三方商品编码" },
                    model: {
                      value: _vm.form.thirdProductCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "thirdProductCode", $$v)
                      },
                      expression: "form.thirdProductCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "第三方商品名称：",
                    prop: "thirdProductName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入第三方商品名称" },
                    model: {
                      value: _vm.form.thirdProductName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "thirdProductName", $$v)
                      },
                      expression: "form.thirdProductName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "第三方商品规格：",
                    prop: "thirdProductSpec",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入第三方商品规格" },
                    model: {
                      value: _vm.form.thirdProductSpec,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "thirdProductSpec", $$v)
                      },
                      expression: "form.thirdProductSpec",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "第三方商品单位：",
                    prop: "thirdProductUtil",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入第三方商品单位" },
                    model: {
                      value: _vm.form.thirdProductUtil,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "thirdProductUtil", $$v)
                      },
                      expression: "form.thirdProductUtil",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "第三方生产厂家：",
                    prop: "thirdProductFactory",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入第三方生产厂家" },
                    model: {
                      value: _vm.form.thirdProductFactory,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "thirdProductFactory", $$v)
                      },
                      expression: "form.thirdProductFactory",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "5px" },
                  attrs: { label: "数量比例：", prop: "quantityProportion" },
                },
                [
                  _c("el-input", {
                    attrs: { type: "number", placeholder: "请输入数量比例" },
                    model: {
                      value: _vm.form.quantityProportion,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "quantityProportion", $$v)
                      },
                      expression: "form.quantityProportion",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", [
                _c(
                  "span",
                  { staticStyle: { color: "#666666", "font-size": "13px" } },
                  [_vm._v("说明：数量比例=我方计价单位数量/客户计价单位数量")]
                ),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "是否取整：", prop: "roundingOrNot" } },
                [
                  _c("el-switch", {
                    staticClass: "swich-inner-text",
                    attrs: {
                      "active-text": "是",
                      "inactive-text": "否",
                      "active-color": "#1890FF",
                      "active-value": "Y",
                      "inactive-value": "N",
                    },
                    model: {
                      value: _vm.form.roundingOrNot,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "roundingOrNot", $$v)
                      },
                      expression: "form.roundingOrNot",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "im-drawer",
        {
          attrs: {
            title: "委托配送匹配",
            visible: _vm.storeDrawerVisible,
            "before-close": _vm.storeBeforeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.storeDrawerVisible = $event
            },
            confirm: _vm.storeHandleConfirm,
            cancel: _vm.storeResetForm,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { "padding-right": "50px", "padding-top": "24px" },
              attrs: {
                "label-width": "180px",
                model: _vm.storeForm,
                rules: _vm.storeFormRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "识别码：", prop: "identificationCode" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "选择识别码" },
                      model: {
                        value: _vm.storeForm.identificationCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.storeForm, "identificationCode", $$v)
                        },
                        expression: "storeForm.identificationCode",
                      },
                    },
                    _vm._l(_vm.identificationCodeList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "门店编码：", prop: "storeCode" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入门店编码" },
                    model: {
                      value: _vm.storeForm.storeCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.storeForm, "storeCode", $$v)
                      },
                      expression: "storeForm.storeCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "门店名称：", prop: "storeNm" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入门店名称" },
                    model: {
                      value: _vm.storeForm.storeNm,
                      callback: function ($$v) {
                        _vm.$set(_vm.storeForm, "storeNm", $$v)
                      },
                      expression: "storeForm.storeNm",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "地址ID：", prop: "addressId" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入地址ID" },
                    model: {
                      value: _vm.storeForm.addressId,
                      callback: function ($$v) {
                        _vm.$set(_vm.storeForm, "addressId", $$v)
                      },
                      expression: "storeForm.addressId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "模式名称：", prop: "modeName" } },
                [
                  _c("span", { staticClass: "modeName" }, [
                    _vm._v(_vm._s(_vm.storeForm.modeName)),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.chooseMode()
                        },
                      },
                    },
                    [_vm._v("选择模式")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否回传数据：", prop: "isBack" } },
                [
                  _c("el-switch", {
                    staticClass: "swich-inner-text",
                    attrs: {
                      "active-text": "是",
                      "inactive-text": "否",
                      "active-color": "#1890FF",
                      "active-value": "Y",
                      "inactive-value": "N",
                    },
                    model: {
                      value: _vm.storeForm.isBack,
                      callback: function ($$v) {
                        _vm.$set(_vm.storeForm, "isBack", $$v)
                      },
                      expression: "storeForm.isBack",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "5px" },
                  attrs: { label: "备注说明：", prop: "remark" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入备注说明" },
                    model: {
                      value: _vm.storeForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.storeForm, "remark", $$v)
                      },
                      expression: "storeForm.remark",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", [
                _c("span", { staticStyle: { color: "#666666" } }, [
                  _vm._v("建议填写客户的收货地址，便于区分"),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "im-drawer",
        {
          attrs: {
            title: _vm.modeDrawerTitle,
            visible: _vm.modeDrawerVisible,
            hasFooter: false,
            wrapperClosable: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.modeDrawerVisible = $event
            },
          },
        },
        [_c("choose-mode", { on: { chooseModeSucc: _vm.chooseModeSucc } })],
        1
      ),
      _c(
        "im-drawer",
        {
          attrs: {
            title: _vm.goodsDrawerTitle,
            visible: _vm.goodsVisible,
            hasFooter: false,
            wrapperClosable: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.goodsVisible = $event
            },
          },
        },
        [_c("choose-goods", { on: { chooseGoodsSucc: _vm.chooseGoodsSucc } })],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "以下是近效期品种",
            visible: _vm.expDateVisible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.expDateVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              ref: "expDateTable",
              attrs: { border: "", data: _vm.submitOrderData },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "商品编码",
                  prop: "imallProductCode",
                  width: "90",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "商品名称",
                  prop: "productNm",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.$util.getGoodsName(scope.row)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "规格", prop: "spec", "min-width": "80" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "生产厂家",
                  prop: "manufacturer",
                  "min-width": "140",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "最近/远效期",
                  prop: "expDate",
                  "min-width": "100",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.expDateVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.commitOrder } },
                [_vm._v("继续下单")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import request from '@/utils/request'
import defaultSettings from '@/settings'

export const ApiGet = (apiUrl,params,config = {},useFrontUrl = true) => {
    let frontUrl = useFrontUrl ? `/${defaultSettings.businessPre}` : ''

    return request({
        url: `${frontUrl}${apiUrl}`,
        method: 'GET',
        params,
        ...config
    })
}

export const ApiPost = (apiUrl,data,config = {},useFrontUrl = true) => {
    let frontUrl = useFrontUrl ? `/${defaultSettings.businessPre}` : ''

    return request({
        url: `${frontUrl}${apiUrl}`,
        method: 'POST',
        data,
        ...config
    })
}

export const ApiPut = (apiUrl,data,config = {},useFrontUrl = true) => {
    let frontUrl = useFrontUrl ? `/${defaultSettings.businessPre}` : ''

    return request({
        url: `${frontUrl}${apiUrl}`,
        method: 'PUT',
        data,
        ...config
    })
}

export const ApiDelete = (apiUrl,data,config = {},useFrontUrl = true) => {
    let frontUrl = useFrontUrl ? `/${defaultSettings.businessPre}` : ''

    return request({
        url: `${frontUrl}${apiUrl}`,
        method: 'DELETE',
        data,
        ...config
    })
}
<template>
    <el-dialog class="container" width="900px" title="选择商品" visible @close="onClose">
        <div class="search">
            <el-input v-model="searchField" @keyup.enter.native="onSearch" placeholder="请输入商品名称/编码/厂家"></el-input>
            <el-button type="primary" size="mini" @click="onSearch">搜索</el-button>
        </div>
        <im-table-page
                ref="tablePage"
                :data.sync="tableData"
                :remote-method="queryData"
                :addQuantity="true"
                :index="true"
                :moreCheckBox="(val)=>{selectItem = val}"
                header-row-class-name="header-row"
                style="width: 100%;margin-top: 15px">
            <el-table-column type="selection" width="60px" label="选择"/>
            <el-table-column prop="productCode" label="商品编码" width="100px" show-overflow-tooltip />
            <el-table-column prop="productNm" align="left" label="商品名称" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{$util.getGoodsName(scope.row)}}
                </template>
            </el-table-column>
            <el-table-column prop="spec" label="规格" width="100px" show-overflow-tooltip />
            <el-table-column prop="manufacturer" align="center" label="厂家(上市许可持有人)/产地" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{scope.row.manufacturer}}{{scope.row.area ? '/' + scope.row.area : ''}}
                </template>
            </el-table-column>
            <el-table-column prop="unit" align="center" label="单位" width="60px" show-overflow-tooltip />
        </im-table-page>
        <div class="button">
            <el-button style="width: 120px;" size="small" type="primary" @click="onChoose">确认</el-button>
            <el-button style="width: 120px;" size="small" @click="onClose">取消</el-button>
        </div>
    </el-dialog>

</template>

<script>
    import {quotationProductList} from "../../../../../api/member-center/transactions-using";
    export default {
        name: "chooseBuyers",
        props:{
            customerItem:{
                type:Object,
                default(){
                    return {}
                }
            }
        },
        data(){
            return {
                tableData:[],
                searchField:'',
                selectItem:[]
            }
        },
        created(){
            console.log(this.customerItem)
        },
        methods:{
            onChoose(){
                let data = Object.assign([],this.selectItem)
                data = data.map(item=>{
                    return {
                        ...item,
                        expDate:'',
                        price1: Number(item.price),
                        price: 0
                    }
                })
                this.$emit('confirm',data)
                this.onClose()
            },
            onClose(){
                this.$emit('close')
            },
            onSearch(){
                this.$refs.tablePage.doRefresh({ currentPage: 0, pageSize: 10 })
            },
            queryData(data){
                return quotationProductList({
                    ...data,
                    condition:{
                        // org: this.customerItem.ettleAccountId,
                        buyers: this.customerItem.buyersId,
                        searchFiled: this.searchField
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    /deep/{
        .el-dialog__body{
            padding-bottom: 80px;
        }
    }
    .search{
        display: flex;
        justify-content: flex-start;
        .el-button{
            margin-left: 20px;
        }
    }
    .button{
        position: absolute;
        display: flex;
        justify-content: center;
        bottom:0;
        padding: 15px 0;
        left: 0;
        right: 0;
        box-shadow: 0 -1px 5px 0 rgba(0,0,0,.2);
        .el-button{
            margin: 0 10px;
        }
    }
</style>
<template>
  <div class="shopping-cart-container">
    <header class="header">
      <div class="header-content">
        <div class="logo">
          <router-link to="/">
            <img v-if="header.PC_TOP_LEFT_T1 && header.PC_TOP_LEFT_T1.length" :src="header.PC_TOP_LEFT_T1[0].sysFileUrl" alt>
          </router-link>
        </div>
        <h4>{{title}}</h4>
        <div class="steps">
          <el-steps :active="active"
                    process-status="finish"
                    finish-status="finish"
                    :align-center="true">
            <el-step title="进货单"></el-step>
            <el-step title="填写核对订单"></el-step>
            <el-step title="提交订单"></el-step>
          </el-steps>
        </div>
      </div>
    </header>
    <slot />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'payPageModal',
    props:{
      title:{
        type:String,
        default:'收银台'
      },
      active:{
        type:Number,
        default:0
      },
    },
    data () {
      return {
       }
    },
    computed: {
      ...mapGetters(['header'])
    },
    mounted () {
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">

  .shopping-cart-container {
    width: 100%;
    min-width: 1500px;
    height: auto;
    padding-bottom: 20px;
    background: #F5F5F5;

    .header {
      width: 100%;
      height: 100px;
      background: #FFFFFF;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
      margin-bottom: 10px;

      .header-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 auto;
        width: 1200px;
        height: 100%;

        .logo {
          margin-right: 20px;
          img{
            width: 200px;
            height: 100px;
          }
        }

        h4 {
          padding-left: 20px;
          flex: 1;
          text-align: left;
          font-size: 20px;
          font-weight: 500;
          line-height: 40px;
          height: 40px;
          border-left: 1px solid #CCCCCC;
          color: #222222;
        }

        /*步骤条*/
        .steps {
          width: 400px;
          flex: 1;

          /deep/ .el-steps {
            .el-step__icon {
              border: none;
              width: 24px;
              height: 24px;
              font-size: 14px;
              background: rgba(229, 229, 229, 1);

              .el-step__icon-inner {
                color: #999999;
              }
            }

            .el-step__title {
              font-size: 16px;
              font-weight: 500;
              color: rgba(153, 153, 153, 1);
            }

            /*完成步骤*/
            .is-finish {
              .el-step__icon {
                .el-step__icon-inner {
                  color: #ffffff;
                }

                color: #0971EB;
                background: #0971EB;
              }

              &.el-step__title {
                color: rgba(34, 34, 34, 1);
              }
            }


            .el-step__main {
              font-size: 16px;
              font-weight: 500;
              color: rgba(34, 34, 34, 1);
            }

            .el-step__line {
              margin: 0 28px !important;
              height: 1px;
            }
          }
        }
      }
    }

  }
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "brand-center-content" },
    [
      _c("Search", { attrs: { "index-nav": true } }),
      _c("div", { staticClass: "brand-content" }, [
        _c("div", { staticClass: "brand-bg" }),
        _c(
          "div",
          { staticClass: "brand" },
          [
            _c(
              "div",
              { staticClass: "company" },
              _vm._l(_vm.companyData, function (item, index) {
                return _c("div", { key: index, staticClass: "company-list" }, [
                  _c("div", { staticClass: "company-logo" }, [
                    _c("img", {
                      attrs: { src: item.brandLogo || _vm.brandDefaultImg },
                      on: {
                        error: function ($event) {
                          item.brandLogo = _vm.brandDefaultImg
                        },
                        click: function ($event) {
                          return _vm.checkDetail(item.brandId)
                        },
                      },
                    }),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "company-name",
                      on: {
                        click: function ($event) {
                          return _vm.checkDetail(item.brandId)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.brandNm))]
                  ),
                ])
              }),
              0
            ),
            _vm._l(_vm.brandList, function (value, index) {
              return _c("div", { key: index, staticClass: "brand-list" }, [
                _c("div", { staticClass: "brand-lt" }, [
                  _c("div", { staticClass: "comp-logo" }, [
                    _c("img", {
                      attrs: { src: value.brandLogo || _vm.brandDefaultImg },
                      on: {
                        click: function ($event) {
                          return _vm.checkDetail(value.brandId)
                        },
                        error: function ($event) {
                          value.brandLogo = _vm.brandDefaultImg
                        },
                      },
                    }),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "comp-name",
                      on: {
                        click: function ($event) {
                          return _vm.checkDetail(value.brandId)
                        },
                      },
                    },
                    [_vm._v(_vm._s(value.brandNm))]
                  ),
                  _c("div", { staticClass: "comp-introduce" }, [
                    _vm._v(_vm._s(value.brandStory)),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "check-button",
                      on: {
                        click: function ($event) {
                          return _vm.checkDetail(value.brandId)
                        },
                      },
                    },
                    [_vm._v("查看品牌详情")]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "brand-rt" },
                  _vm._l(
                    value.brandProductDetailVoList,
                    function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "comm-list" },
                        [
                          _c(
                            "div",
                            { staticClass: "comm-content" },
                            [
                              _vm.userType == "SALESMAN"
                                ? _c("div", { staticClass: "identifying" }, [
                                    item.whiteRecordFlag == 1
                                      ? _c("img", {
                                          attrs: {
                                            alt: "",
                                            src: require("@/assets/img/icon/whiterecord.png"),
                                          },
                                        })
                                      : item.blackRecordFlag == 1
                                      ? _c("img", {
                                          attrs: {
                                            alt: "",
                                            src: require("@/assets/img/icon/blackrecord.png"),
                                          },
                                        })
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "comm-logo" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: "/detail/" + item.productId,
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            item.picUrl || _vm.productDefault,
                                        },
                                        on: {
                                          error: function ($event) {
                                            item.picUrl = _vm.productDefault
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _c("div", { staticClass: "comm-price" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.isLogin
                                          ? _vm.$util.getPriceByZero(
                                              item.price,
                                              item
                                            )
                                          : "登录可见"
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "router-link",
                                { attrs: { to: "/detail/" + item.productId } },
                                [
                                  _c("div", { staticClass: "comm-name" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$util.getGoodsName(item)) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c("div", { staticClass: "comm-spec" }, [
                                _vm._v(
                                  _vm._s(item.spec) + " x " + _vm._s(item.unit)
                                ),
                              ]),
                              _c("div", { staticClass: "comm-manufactor" }, [
                                _vm._v(_vm._s(item.manufacturer)),
                              ]),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "purchase" }, [
                            _c(
                              "div",
                              { staticClass: "spec" },
                              [
                                _c("span", [
                                  _vm._v(
                                    "最小购买倍数 " +
                                      _vm._s(_vm.getRealMinBuyNum(item)) +
                                      " "
                                  ),
                                ]),
                                _vm.userType === "SALESMAN"
                                  ? [
                                      _c("span", [
                                        _vm._v(
                                          "库存 " + _vm._s(item.stockQuantity)
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                                _vm.userType === "BUYERS"
                                  ? [
                                      _c("span", [
                                        _vm._v(
                                          "库存 " +
                                            _vm._s(
                                              item.stockQuantity >= 100
                                                ? ">100"
                                                : "<100"
                                            )
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _c("div", { staticClass: "num" }, [
                              _c(
                                "div",
                                { staticClass: "input-num" },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      precision: _vm.getDecimalPointNum(item),
                                      min: _vm.getRealMinBuyNum(item),
                                      step: _vm.getRealMinBuyNum(item),
                                      max: item.stockQuantity,
                                      "step-strictly": "",
                                      size: "mini",
                                    },
                                    model: {
                                      value: item.num,
                                      callback: function ($$v) {
                                        _vm.$set(item, "num", $$v)
                                      },
                                      expression: "item.num",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "pur-button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.buyNow(item, item.num)
                                    },
                                  },
                                },
                                [_vm._v("立即购买")]
                              ),
                            ]),
                          ]),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ])
            }),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
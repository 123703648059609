var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "discount-time-content" },
    [
      _c("Search", { attrs: { "index-nav": true } }),
      _c("div", { staticClass: "brand-content" }, [
        _c("div", { staticClass: "brand-bg" }),
        _c("div", { staticClass: "brand" }, [
          _c(
            "div",
            { staticClass: "time-nav" },
            [
              _c(
                "swiper",
                { ref: "mySwiper", attrs: { options: _vm.swiperOption } },
                _vm._l(_vm.tileZone, function (item, index) {
                  return _c(
                    "swiper-slide",
                    { key: index, attrs: { id: index } },
                    [
                      item.activeStatus === "进行中"
                        ? _c(
                            "div",
                            {
                              staticClass: "banner-item",
                              class: { active: index === _vm.activeIndex },
                              attrs: { id: index },
                              on: {
                                click: function ($event) {
                                  return _vm.handleClickSlide(
                                    index,
                                    item.discountRuleId
                                  )
                                },
                              },
                            },
                            [
                              _c("div", [
                                _c(
                                  "p",
                                  {
                                    staticClass: "activityNm",
                                    attrs: { title: item.activityNm },
                                  },
                                  [_vm._v(_vm._s(item.activityNm))]
                                ),
                                _c("p", { staticClass: "mid" }, [
                                  _c("span", [_vm._v(_vm._s(item.startTimes))]),
                                  _c("span", [_vm._v("正在秒杀")]),
                                ]),
                                _c("p", { staticClass: "footer" }, [
                                  _c("span", [_vm._v(_vm._s(item.startData))]),
                                  item.isCountDown === "Y"
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: { margin: "0 0 0 8px" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s("距结束" + item.showEndTime)
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                              _c("div", { staticClass: "noStarting" }),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "banner-item",
                              class: { active: index === _vm.activeIndex },
                              attrs: { id: index },
                              on: {
                                click: function ($event) {
                                  return _vm.handleClickSlide(
                                    index,
                                    item.discountRuleId
                                  )
                                },
                              },
                            },
                            [
                              item.activeStatus === "未开始"
                                ? _c("div", { staticClass: "noStart" }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "activityNm",
                                        attrs: { title: item.activityNm },
                                      },
                                      [_vm._v(_vm._s(item.activityNm))]
                                    ),
                                    _c("p", { staticClass: "mid" }, [
                                      _c("span", [
                                        _vm._v(_vm._s(item.startTimes)),
                                      ]),
                                      _c("span", [_vm._v("即将开抢")]),
                                    ]),
                                    _c("p", { staticClass: "footer" }, [
                                      _c("span", [
                                        _vm._v(_vm._s(item.startData)),
                                      ]),
                                    ]),
                                  ])
                                : _c("div", [_c("span", [_vm._v("即将开抢")])]),
                            ]
                          ),
                    ]
                  )
                }),
                1
              ),
              _c("div", {
                staticClass: "swiper-button-next swiper-button-next1",
              }),
              _c("div", {
                staticClass: "swiper-button-prev swiper-button-prev1",
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadBol,
                  expression: "loadBol",
                },
              ],
              staticClass: "load-content",
            },
            [
              _c(
                "div",
                { staticClass: "brand-list" },
                _vm._l(_vm.brandList, function (item) {
                  return _c("div", { staticClass: "comm-list" }, [
                    _c(
                      "div",
                      { staticClass: "comm-content" },
                      [
                        _c(
                          "div",
                          { staticClass: "comm-logo" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: { path: "/detail/" + item.productId },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: item.picUrl || _vm.productImage,
                                  },
                                  on: {
                                    error: function ($event) {
                                      item.picUrl = _vm.productImage
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c("div", { staticClass: "comm-price" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$util.getPriceByZero(item.price, item)
                                  )
                                ),
                              ]),
                              Number(item.salePrice)
                                ? _c("s", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$util.getPriceByZero(
                                          item.salePrice,
                                          item
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: { path: "/detail/" + item.productId },
                            },
                          },
                          [
                            _c("div", { staticClass: "comm-name" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$util.getGoodsName(item)) + " "
                              ),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "comm-spec" }, [
                          _vm._v(_vm._s(item.spec) + " x " + _vm._s(item.unit)),
                        ]),
                        _c("div", { staticClass: "comm-manufactor" }, [
                          _vm._v(_vm._s(item.manufacturer)),
                        ]),
                        _c("div", { staticClass: "buy-now" }, [
                          _vm._v("立即购买"),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "purchase" }, [
                      _c("div", { staticClass: "spec" }, [
                        _c(
                          "span",
                          { staticStyle: { "margin-right": "12px" } },
                          [
                            _vm._v(
                              "最小购买倍数：" +
                                _vm._s(_vm.getRealMinBuyNum(item))
                            ),
                          ]
                        ),
                        _vm.userType === "SALESMAN"
                          ? _c("span", [
                              _vm._v("库存 " + _vm._s(item.stockQuantity)),
                            ])
                          : _vm._e(),
                        _vm.userType === "BUYERS"
                          ? _c("span", [
                              _vm._v(
                                "库存 " +
                                  _vm._s(
                                    item.stockQuantity >= 100 ? ">100" : "<100"
                                  )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        { staticClass: "num" },
                        [
                          _c(
                            "div",
                            { staticClass: "input-num" },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  precision: _vm.getDecimalPointNum(item),
                                  min: _vm.getRealMinBuyNum(item),
                                  step: _vm.getRealMinBuyNum(item),
                                  "step-strictly": "",
                                  size: "mini",
                                },
                                model: {
                                  value: item.num,
                                  callback: function ($$v) {
                                    _vm.$set(item, "num", $$v)
                                  },
                                  expression: "item.num",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "pur-button",
                              attrs: { disabled: _vm.isBanAddToCart },
                              on: {
                                click: function ($event) {
                                  return _vm.buyNow(item, item.num)
                                },
                              },
                            },
                            [_vm._v("立即购买")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ]
          ),
          _vm.total > _vm.pageSize
            ? _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("im-pagination", {
                    staticClass: "table-page",
                    attrs: {
                      isShowMainInfo: _vm.isShowMainInfo,
                      total: _vm.total,
                      layout: "prev, pager, next, jumper",
                      "current-page": _vm.currentPage,
                      "page-size": _vm.pageSize,
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        _vm.currentPage = $event
                      },
                      "update:current-page": function ($event) {
                        _vm.currentPage = $event
                      },
                      "update:pageSize": function ($event) {
                        _vm.pageSize = $event
                      },
                      "update:page-size": function ($event) {
                        _vm.pageSize = $event
                      },
                      pagination: _vm.handlePagination,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
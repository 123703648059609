<template>
  <div class="main">
   <!-- 页面结构 -->
    <Search :hidden-nav="true"/>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/member-center' }">会员中心</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/member-center/order-manage/refunds' }">申请退货</el-breadcrumb-item>
        <el-breadcrumb-item>退货清单</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button class="toback" @click="toback">返回</el-button>
    </div>
    <div class="content">
      <template>
        <div class="search-header">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline" size="small">
            <el-form-item label="订单编号"  prop="orderNum">
              <el-input style="width: 163px;" v-model="searchForm.orderNum" placeholder="输入订单编号"></el-input>
            </el-form-item>
            <el-form-item label="商品名称" prop="productNm">
              <el-input style="width: 163px;" v-model="searchForm.productNm" placeholder="输入商品名称"></el-input>
            </el-form-item>
            <el-form-item label="发货批号" prop="sendBatch">
              <el-input style="width: 163px;" v-model="searchForm.sendBatch" placeholder="输入发货批号"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
              <el-button type="text" size="mini" @click.self="handleReset"><span style="color: #000000">重置</span>
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="userDetail">
          <div class="userDe">
            <span class="userTitle">客户名称：</span><span class="userInfo">{{changeCustomer.buyersNm}}--{{changeCustomer.ettleAccountNm}}</span>
            <a class="changeAddr" @click="tochangeCustomer" >切换客户</a>
            <span class="userTitle">联系人：</span><span class="userInfo">{{changeCustomer.entContactMan}}</span>
            <span class="userTitle">联系电话：</span><span class="userInfo">{{changeCustomer.contactMoblie}}</span>
            <br/>
            <div class="blankline">  </div>
            <span class="userTitle">收货地址：</span>
            <span class="userInfo" v-show="!isChangeAddr">{{selectAddr.address}}</span>
            <el-select @change="changeAddress" class="changeAddress" v-model="address" placeholder="请选择" v-show="isChangeAddr" style="margin-right: 24px;">
              <el-option
                v-for="item in addrs"
                :key="item.erpCode"
                :label="item.address"
                :value="item.erpCode">
              </el-option>
            </el-select>
            <a class="changeAddr" @click="changeAddr" v-if="addrs && addrs.length>1">切换地址</a>
            <span class="userTitle">可用额度：</span><span class="userInfo"> ￥{{changeCustomer.availableCredit}}</span>
          </div>
        </div>
        <div style="padding:12px;background-color:white">
          <im-table-page
                ref="tablePage"
                :data.sync="tableData"
                :remote-method="queryData"
                :span-method="spanMethod"
                 row-key="id"
                :defaultExpandAll="true"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                :row-class-name="renderRowClass">
            <el-table-column
              :key="Math.random()"
              width="40"
              label="">
              <template slot-scope="scope">
                <el-checkbox  @change="handleSelectionChange(scope.row)" v-model="scope.row.checked"  class="three-button" style="float:left"></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="商品信息" width="308px" show-overflow-tooltip>
              <template slot-scope="scope">
                <div class="product-info">
                  <div class="product-image">
                    <el-image
                      :src="scope.row.picture || ProDuct"
                      @error="scope.row.picture = ProDuct"
                      fit="fit">
                    </el-image>
                  </div>
                  <ul class="product-detail">
                    <li class="proname">{{$util.getGoodsName(scope.row)}}</li>
                    <li class="size-vender">
                      <p>规格：<span>{{ scope.row.spec}}</span></p>
                      <p class="">厂家：<span>{{ scope.row.manufacturer}}</span></p>
                    </li>
                  </ul>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="订单编号" prop="orderNum" min-width="120px" class="orderNum" show-overflow-tooltip style="padding:0px">
            </el-table-column>
            <el-table-column label="订单时间" prop="createDate" width="160px" show-overflow-tooltip  class="orderTime" >
            </el-table-column>
            <el-table-column label="发货批号" prop="sendBatch" min-width="85px" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="退货价" prop="money" align="right" min-width="80px" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>¥{{ scope.row.settlePrice }}</span>
              </template>
            </el-table-column>
            <el-table-column label="出库/可退数量" prop="quantity" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.quantity}} / {{ scope.row.returnableQuantity}}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="80" align="center">
              <template slot-scope="scope">
                <el-row class="table-edit-row">
                  <span  class="table-edit-row-item">
                    <el-button size="small" style="padding:2px 4px;" @click="handleDelete(scope.row)">删除</el-button>
                  </span>
                </el-row>
              </template>
            </el-table-column>
          </im-table-page>
        </div>
        <!--加入退货单-->
        <div class="settlement-container" v-if="activeName === 'APPLY_RETURN'">
          <div class="settlement-row">
            <div class="row-left">
              <el-checkbox :disabled="!chooseOrder.length" v-model="checkedAll" @change="handleCheckAllChange">选中当前已选订单号的所有商品</el-checkbox>
              <!-- <span class="total">共 {{chooseOrder.length === 0 ? '0': chooseOrder.length}} 种商品</span>
              <span class="total">总数量 {{chooseOrder.length === 0 ? '0': chooseOrder.length}} </span> -->
            </div>
            <div class="row-right">
              <div class="settlement">
              </div>
              <div class="settlement-button-group">
                <!-- <span class="final-info">合计退款金额：</span>
                <span class="total-money">&yen;{{(allMoney/100).toFixed(2)}}</span> -->
                <button class="immediate-settlement-show" @click="toRefundList">申请退货<span>({{chooseOrder.length}})</span></button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <add-customer  ref="addCustomer"  @child-msg="changePro" />
  </div>
</template>

<script>
  import AddCustomer from "./add-customer"
  import Search from '@/components/Search/Search'
  import ProDuct from '@/assets/img/index/product_default.png'
  import { pageReturnedPurchaseOrderList, getSalesmanReceiveAddr , removeReturnedPurchaseOrderList} from '@/api/member-center/refunds'
  export default {
    name: 'refunds-replay-list',
    components: {
      Search,
      AddCustomer
    },
    data () {
      return {
        ProDuct: ProDuct,
        isChangeAddr: false,
        searchForm:{
          orderNum:'',
          productNm:'',
          sendBatch:'',
          buyersId: '',
          ettleAccountId: '',
          erpAddrCode: ''
        },
        tableData: [],
        activeName: 'APPLY_RETURN',
        changeCustomer: {
          buyersNm: '', // 客户名称
          ettleAccountNm: '', // 分支机构
          entContactMan: '', //联系人
          availableCredit: '', // 可用额度
          contactMoblie: '', //联系电话
        },
        isCustomer: false,
        isShow: true,
        selectAddr: {
          address: '',
          erpCode: ''
        },
        address: '',
        addrs: [],
        checkedAll: false,
        allMoney: 0,
        chooseOrder: [],
        selectAddress : {},
        dataRel: {
          pageNum: 1,
          pageSize: 10
        }
      }
    },
    created () {
      let ettleAccountId = this.$route.query.ettleAccountId
      let buyersId = this.$route.query.buyersId
      let erpAddrCode = this.$route.query.erpAddrCode
      let changeCustomer = this.$route.query.changeCustomer
      let selectAddr = this.$route.query.selectAddr
      this.selectAddress = this.$route.query.selectAddress
      this.searchForm.ettleAccountId = ettleAccountId
      this.searchForm.buyersId = buyersId
      this.searchForm.erpAddrCode = erpAddrCode
      this.changeCustomer.buyersNm = changeCustomer.buyersNm
      this.changeCustomer.ettleAccountNm = changeCustomer.ettleAccountNm
      this.changeCustomer.entContactMan = changeCustomer.entContactMan
      this.changeCustomer.availableCredit = changeCustomer.availableCredit
      this.changeCustomer.contactMoblie = changeCustomer.contactMoblie
      this.selectAddr.address = selectAddr.address
      this.selectAddr.erpCode = selectAddr.erpCode
    },
    methods: {
      // 计算总金额
      countMoney() {
        this.allMoney = 0
        this.chooseOrder.map(item => {
          this.allMoney = this.allMoney + item.settlePrice * 100
        })
      },
      // 全选 点击全选之后 再去加入退货清单
      handleCheckAllChange(val) {
       if(val) {
         let firstItem
         if(this.chooseOrder.length){
            firstItem = this.chooseOrder[0]
         }else {
           firstItem = this.tableData[0]
         }

         let chooseArr = []
         this.tableData.map(item => {
           if(item.orderNum === firstItem.orderNum){
             item.checked = true
             chooseArr.push(item)
           }
         })
         this.chooseOrder = chooseArr
       }else {
        this.tableData.map((item,index) => {
            item.checked = false
         })
          this.chooseOrder = []
       }
       this.countMoney()
      },
      // 修改商品选中状态
      handleSelectionChange (data) {
        if(this.chooseOrder.length){
          let isSameOrder = this.chooseOrder.find(obj => obj.orderNum === data.orderNum)
          if(!isSameOrder){
            data.checked = false
            this.$message.warning('请选择选择相同订单号的商品退货')
            return
          }
        }
        this.chooseOrder = []
        this.tableData.map((item,index) => {
           if(item.checked){
             this.chooseOrder.push(item)
           }
        })
        this.countMoney()
      },
      handleDelete(data) {
        let order = data
        removeReturnedPurchaseOrderList({
          returnedPurchaseOrderListId: order.returnedPurchaseOrderListId
        }).then(res => {
          if(res.code === '0'){
            this.$message.success('删除成功')
          }else {
            this.$message.error('删除失败')
          }
        })
        this.$refs.tablePage.doRefresh()
      },
      // 申请退货
      toRefundList() {
        if(this.chooseOrder.length === 0){
           this.$alert('还未选择要退货的商品',  {
            confirmButtonText: '确定',
          });
        }else {
          this.$router.push({path:'/refunds-replay-list-yw',  query:{refundOrder: this.chooseOrder, selectAddress: this.selectAddress}})
        }
      },
       // 切换地址
      changeAddr (e) {
        if (!this.isChangeAddr) {
          this.isChangeAddr = true
        }
      },
      changePro(data) {
        this.searchForm.buyersId = data.buyersId
        this.searchForm.ettleAccountId = data.ettleAccountId
        this.changeCustomer.buyersNm = data.buyersNm
        this.changeCustomer.ettleAccountNm = data.ettleAccountNm
        this.changeCustomer.entContactMan = data.entContactMan
        this.changeCustomer.contactMoblie = data.contactMoblie
        this.changeCustomer.availableCredit = data.availableCredit
        this.isShow = true
        this.isCustomer = true
        this.queryData()
      },
      changeAddress(value) {
        this.isChangeAddr = false
        this.addrs.map(item => {
          if(item.erpCode === value){
            this.selectAddr.address = item.address
            this.selectAddr.erpCode = item.erpCode
            this.selectAddress = item
          }
        })
        this.$refs.tablePage.doRefresh()
      },
      // 切换客户
      tochangeCustomer(){
        this.$refs.addCustomer.init()
        this.isShow = true
      },
      handleSearch(){
          this.$refs.tablePage.doRefresh()
      },
      handleReset(){
        this.searchForm = {
            orderNum:'',
            productNm:'',
            sendBatch:''
        }
      },
      spanMethod(rowObj) {
        const {row, column, rowIndex, columnIndex} = rowObj;
        return [1, 1]
      },
      toback() {
        this.$router.push({path:'/member-center/order-manage/refunds-yw'})
      },
      renderRowClass(row) {
         return ''
      },
      queryData (data) {
        return new Promise(then => {
          this.allMoney = 0
          this.tableData = []
          getSalesmanReceiveAddr({
             buyersIdSearch: this.searchForm.buyersId,
             ettleAccountIdSearch: this.searchForm.ettleAccountId
           }).then(res => {
              if(res.data.length != 0) {
                this.addrs = res.data
                this.addrs.map(item => {
                  item.address = item.province + '-' + item.city + '-' + item.district + '-' + item.receiverAddr
                })
                if(this.selectAddr.erpCode === '' ){
                  // 默认展示的申请退货的商品 收货地址取地址list的第一条
                  this.selectAddr.address = this.addrs[0].address
                  this.selectAddr.erpCode = this.addrs[0].erpCode
                }
                this.searchForm.erpAddrCode = this.selectAddr.erpCode
              }else {
                this.searchForm.erpAddrCode = ''
                this.selectAddr = {
                  address: '',
                  erpCode: ''
                }
              }
              let requestData = {}
              if(!data){
                requestData = this.dataRel
              }else {
                requestData = data
              }

              console.log(this.searchForm)
              pageReturnedPurchaseOrderList({
               ...requestData,
               condition: this.searchForm
              }).then(res => {
                this.tableData = res.data.records
                then(res)
                this.countMoney()
              })
          })
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  /*结算*/
  .settlement-container {
    margin-top: 18px;
    background: white;
    border-top: 1px solid #e5e5e5;
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0px;
    .settlement-row {
      padding: 13px;
      margin: 0 auto;
      width: 100%;
      height: 60px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .row-left {
        font-size: 12px;
        font-weight: 500;
        color: rgba(102, 102, 102, 1);

        /deep/ .el-checkbox {
          margin-right: 23px;

          .el-checkbox__label {
            font-size: 12px;
            font-weight: 500;
            color: rgba(102, 102, 102, 1);
          }
        }

        span {
          cursor: pointer;
          margin-right: 20px;
        }
      }

      .row-right {
        display: flex;
        flex-direction: row;
        align-items: center;

        .settlement {
          margin-right: 12px;
          text-align: right;

          .total-money {
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            font-weight: bold;
            color: rgba(34, 34, 34, 1);

            span {
              font-size: 20px;
              color: rgba(224, 32, 52, 1);
            }
          }
        }

        .product-detail {
          height: 14px;
          font-size: 10px;
          font-weight: 500;
          color: rgba(102, 102, 102, 1);
          line-height: 14px;

          span {
            margin-left: 12px;
          }
        }

        .settlement-button-group {
          height: 100%;

          button {
            border: none;
            height: 36px;
            padding: 0 28px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
          }
          .total-money {
              //  padding: 0px;
            margin-right: 8px;
            width:74px;
            height:28px;
            font-size:20px;
            font-family:PingFangSC-Medium,PingFang SC;
            font-weight:500;
            color:rgba(224,32,52,1);
            line-height:28px;
          }
          .final-info {
              //  padding: 0px;
            margin-right: 8px;
            height:20px;
            font-size:14px;
            font-family:PingFangSC-Medium,PingFang SC;
            font-weight:bold;
            color:rgba(34,34,34,1);
            line-height:20px;
          }
          .immediate-settlement-show {
            padding: 0px;
            margin-right: 8px;
            width: 112px;
            height: 36px;
            background: $ThemeColor;
            border-radius: 2px;
            border:1px solid rgba(204,204,204,1);
            font-size:14px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 20px;

          }
          .immediate-settlement-first {
            padding: 0px;
            margin-right: 8px;
            width:88px;
            height:36px;
            background:rgba(255,255,255,1);
            border-radius:2px;
            border:1px solid r$ThemeColor;
            font-size:14px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:400;
            color: $ThemeColor;
            line-height:20px;
          }
          .immediate-settlement {
            padding: 0px;
            margin-right: 8px;
            width:116px;
            height:36px;
            background: $ThemeColor;
            border-radius:2px;
            color: #ffffff;
            // background: #FF6600;
            cursor: pointer;
            font-size:14px;
            font-family:PingFangSC-Medium,PingFang SC;
            font-weight:500;
            color:rgba(255,255,255,1);
            line-height:20px;
            &:hover {
              opacity: .8;
            }
          }
        }
      }
    }
  }


  .settlement-container .settlement-row .row-left .total {
    font-size:12px;
    font-family:PingFangSC-Medium,PingFang SC;
    font-weight:bold;
    color:rgba(34,34,34,1);
    line-height:17px;
  }

 .blankline {
   width: 100%;
   height: 6px;
 }
 .changeAddress {
    /deep/ {
      .el-input--suffix .el-input__inner {
        height: 28px;
      }

      .el-input__icon {
        line-height: 24px;
      }
    }
  }

  .changeAddr {
  position: relative;
  font-size: 14px;
  font-family: PingFangSC-Regular,PingFang SC;
  font-weight: 400;
  color: $ThemeColor;
  line-height: 22px;
  left: -13px;
}

.demo-form-inline {
  height: 35px;
}
.userDetail {
  display: flex;
  text-align: left;
  width: 98%;
  height: 82px;
  background: #f1f7fe;
  border-radius: 4px;
  margin: 10px auto;
}
.userDe {
    padding: 18px;
    width: 100%;
    height: 82px;
    background: #F1F7FE;
    border-radius: 4px;
}


.userTitle {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 20px;
}

.userInfo {
  margin-right: 24px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}


.search-header {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #717171;

    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
    padding: 25px 19px;

      /deep/ .el-form-item {
        margin-bottom: 14px;
        margin-right: 20px;

        .el-form-item__label {
          padding-right: 4px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(113, 113, 113, 1);
        }

        .el-input__inner {
          padding: 0 12px;
          background: rgba(255, 255, 255, 1);
          border-radius: 4px;
          border: 1px solid rgba(204, 204, 204, 1);
        }
      }
  }
  .center {
    display: flex;
    align-items: center;
  }

  .main {
    background-color: #f5f5f5;
    padding-bottom: 20px;
    .content {
      width: 1200px;
      margin: 0 auto;
      // padding-bottom: 20px;
      background: #fff;

      .header {
        font-size: 14px;
        line-height: 40px;
        color: #262626;
        background: #F1F7FE;
        width: 100%;
        text-align: left;

        b {
          height: 18px;
          padding: 0 4px;
          color: #0971EB;
          border: 1px solid #0971EB;
          font-size: 12px;
          border-radius: 3px;
          margin: 0 10px 0 20px;
        }
      }

      .express-steps {
        max-height: 240px;
        overflow-y: auto;
      }

      .product-info {
        display: flex;
        flex-direction: row;

        .product-image {
          width: 70px;
          height: 70px;

          .el-image {
            width: 100%;
            height: 100%;
          }
        }

        .product-detail {
          padding-left: 16px;
          width: 212px;

          li {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;
          }

          .proname {
            height: 18px;
            font-size: 14px;
            font-weight: 500;
            color: rgba(34, 34, 34, 1);
            line-height: 18px;
            margin-bottom: 4px;
          }

          .detail {
            font-weight: 400;
            color: rgba(113, 113, 113, 1);
            line-height: 18px;
            height: 18px;

            .spec {
              margin-right: 10px;
            }

            &.manufacturer {
              white-space: normal;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              height: 36px;
            }
          }
        }
      }

        /deep/ .el-table {
        .order-row {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
          td{
            background: #F1F7FE;
          }
          .order-row-text {
            margin-right: 10px;
          }

          .el-button {
            padding: 5px 8px;

            span {
              margin: 0;
            }
          }
        }


        .cell {
          width: 100% !important;

          .el-table__expand-icon {
            display: none;
          }

          .el-table__indent, .el-table__placeholder {
            display: none;
          }
        }
      }



    }
    /deep/ .picture-upload{
          text-align:left;
         .el-upload--picture-card{
         width:80px;
         height:80px;
         line-height: 78px;
       }
       .el-upload--picture-card i {
         font-size:24px
       }
     }
   /deep/ .el-form{
       .el-form-item__content{
            text-align:left;
        }
   }
    .breadcrumb {
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      padding-left: 24px;
      width: 1200px;
      margin: 0 auto;
      position: relative;

      /deep/ .el-breadcrumb {
        display: inline-block;
        line-height: 50px;
        float: left;
      }
    }
  .footer-content{
    border-top:1px solid #ccc;
    width:1200px;
    margin:0 auto;
    height:80px;
    display:flex;
    justify-content: center;
    align-items: center;
    background:rgba(255,255,255,1);
    box-shadow:0px 1px 0px 0px rgba(229,229,229,1);
    /deep/.el-button{
      width:200px;
      height:40px;
      border-radius:2px
    }
  }
 }

     //弹窗
    /deep/.el-dialog{
       min-height:180px;
       min-width:400px;
      .el-dialog__header{
        text-align:left;
        height:36px;
        line-height:36px;
        background:rgba(245,245,245,1);
        border-radius:2px;
        padding:0 20px;
      }
      .el-dialog__title{
        font-size:14px;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(34,34,34,1);
      }
      .el-dialog__body{
        padding-top:20px;
      }
      .el-dialog__headerbtn{
         top:10px;
         right:16px;
      }
      .el-dialog__footer{
       background:rgba(245,245,245,1);
       position:absolute;
       width:100%;
       bottom:0;left:0;
       padding:0;
       height:52px;
       display:flex;
       align-items: center;
       justify-content: center;
      }
      .icon-success{
        margin-right: 15px;
        font-size: 20px;
        display: inline-block;
        width: 47px;
        height: 48px;
        background:url('../../../../../assets/img/member-center/upload-success.png') no-repeat;
        background-size: 100%;
      }
      .icon-error{
        margin-right: 15px;
        font-size: 20px;
        display: inline-block;
        width: 47px;
        height: 48px;
        background:url('../../../../../assets/img/member-center/upload-error.png') no-repeat;
        background-size: 100%;
      }
      .tip-title{
        padding-left: 30px;
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Medium,PingFang SC;
        color: #222222;
      }
      .tip-detail{
        margin-top:12px;
        line-height:20px;
        text-align:left;
        font-size:14px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(0,0,0,0.65);
        line-height:22px;
      }
   }

   .toback {
     width:48px;
     height:28px;
     background:rgba(255,255,255,1);
     border:1px solid rgba(9,113,235,1);
     font-size:12px;
     font-family:PingFangSC-Regular,PingFang SC;
     font-weight:400;
     color:rgba(9,113,235,1);
     line-height:22px;
     position: absolute;
     padding: 0;
     margin: auto;
     top: 12px;
     right: 21px;
   }

   .tobackSuccess {
     padding: 10px;
     margin: 0 auto;
     display: block;
     background-color: #F1F2F4;
     width: 140px;
   }

   .tobackError {
     padding: 10px;
     margin: 0 auto;
     display: block;
     background-color: #F1F2F4;
     width: 100px;
   }
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "interfaceOrderDiv" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("未匹配数据列表")]),
      _c(
        "el-form",
        {
          staticClass: "searchForm",
          attrs: { inline: true, model: _vm.searchForm, size: "small" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "客户编码：" } },
            [
              _c("el-input", {
                staticClass: "searchFormItem",
                attrs: { placeholder: "输入客户编码" },
                model: {
                  value: _vm.searchForm.customerCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "customerCode", $$v)
                  },
                  expression: "searchForm.customerCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "门店编码：" } },
            [
              _c("el-input", {
                staticClass: "searchFormItem",
                attrs: { placeholder: "输入门店编码" },
                model: {
                  value: _vm.searchForm.storeCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "storeCode", $$v)
                  },
                  expression: "searchForm.storeCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "门店名称：" } },
            [
              _c("el-input", {
                staticClass: "searchFormItem",
                attrs: { placeholder: "输入门店名称" },
                model: {
                  value: _vm.searchForm.storeNm,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "storeNm", $$v)
                  },
                  expression: "searchForm.storeNm",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isExpanded,
                  expression: "isExpanded",
                },
              ],
              attrs: { label: "商品名称：" },
            },
            [
              _c("el-input", {
                staticClass: "searchFormItem",
                attrs: { placeholder: "输入商品名称" },
                model: {
                  value: _vm.searchForm.productNm,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "productNm", $$v)
                  },
                  expression: "searchForm.productNm",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isExpanded,
                  expression: "isExpanded",
                },
              ],
              attrs: { label: "配送地址：" },
            },
            [
              _c("el-input", {
                staticClass: "searchFormItem",
                attrs: { placeholder: "输入配送地址" },
                model: {
                  value: _vm.searchForm.sendAddr,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "sendAddr", $$v)
                  },
                  expression: "searchForm.sendAddr",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isExpanded,
                  expression: "isExpanded",
                },
              ],
              attrs: { label: "要货时间", "label-width": "100px" },
            },
            [
              _c("el-date-picker", {
                staticClass: "dateFormItem",
                class: { isExpanded: _vm.isExpanded },
                attrs: {
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                  format: "yyyy-MM-dd",
                  size: "small",
                  align: "right",
                  "unlink-panels": "",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                on: { change: _vm.changeDatePicker },
                model: {
                  value: _vm.searchForm.time,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "time", $$v)
                  },
                  expression: "searchForm.time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "formBut" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.search },
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "text", size: "mini" },
                  on: { click: _vm.reset },
                },
                [
                  _c("span", { staticStyle: { color: "#000000" } }, [
                    _vm._v("重置"),
                  ]),
                ]
              ),
              _c("div", {
                staticStyle: {
                  width: "1px",
                  height: "12px",
                  "background-color": "#E5E5E5",
                  margin: "0 8px",
                  "margin-top": "8px",
                },
              }),
              _c(
                "el-button",
                {
                  staticStyle: { color: "#000000", "font-weight": "400" },
                  attrs: { type: "text", size: "mini" },
                  on: { click: _vm.onExpand },
                },
                [
                  _vm._v(" " + _vm._s(_vm.isExpanded ? "收起" : "展开") + " "),
                  _c("i", {
                    class: _vm.isExpanded
                      ? "el-icon-arrow-up"
                      : "el-icon-arrow-down",
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "im-tool-bar",
        { staticClass: "butDiv" },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.rematch },
                },
                [_vm._v("重新匹配")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "im-table-page",
        {
          ref: "tablePage",
          attrs: {
            data: _vm.tableData,
            "more-check-box": _vm.checkBox,
            "cell-class-name": _vm.cellClassName,
            "header-cell-class-name": _vm.cellClassName,
            "remote-method": _vm.remoteMode,
          },
          on: {
            "update:data": function ($event) {
              _vm.tableData = $event
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "selection",
              width: "55",
              fixed: "left",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "客户编码",
              align: "center",
              prop: "customerCode",
              width: "80",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "客户名称",
              align: "center",
              prop: "buyersNm",
              width: "150",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "门店编码",
              align: "center",
              prop: "storeCode",
              width: "100",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "门店名称",
              align: "center",
              prop: "storeNm",
              width: "120",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "订单编码",
              align: "center",
              prop: "distributionOrderNum",
              width: "150",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "第三方商品编码",
              align: "center",
              prop: "thirdProductCode",
              width: "140",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "商品名称",
              align: "center",
              prop: "productNm",
              width: "130",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "规格",
              align: "center",
              prop: "spec",
              width: "80",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "生产厂家",
              align: "center",
              prop: "manufacturer",
              width: "130",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "要货数量",
              align: "center",
              prop: "quantity",
              width: "80",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "要货价格",
              align: "right",
              prop: "originalPrice",
              width: "100",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "要货日期",
              align: "center",
              prop: "createTime",
              width: "100",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "配送地址",
              align: "center",
              prop: "receiverAddr",
              "min-width": "220",
              "show-overflow-tooltip": "",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
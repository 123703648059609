/**
 * ImToolBar
 * @module components
 * @desc 列表表头的按钮工具栏
 * @author Mo Xiaomeng
 * @date 2020/03/14
 * @example 调用示例
 *  <im-search-pad-item prop="user">
      <el-input v-model="searchForm.user" placeholder="审批人" />
    </im-search-pad-item>
 */
import imToolBar from './index.vue'

const ImToolBar = {
  install: function(Vue) {
    Vue.component('ImToolBar', imToolBar)
  }
}

export default ImToolBar

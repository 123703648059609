<template>
  <div class="submittedDiv">
    <div class="submittedTip">
      <template v-if="params.openStat === 'APPROVE_FAILURE'">
        <i class="el-icon-error successIcon" style="color:#F56C6C"></i>
        {{'您的审核未通过'}}
      </template>
      <template v-else-if="params.openStat === 'APPROVE_SUCCESS'">
        <i class="el-icon-success successIcon"></i>
        {{'您的审核已通过'}}
      </template>
      <template v-else>
        <i class="el-icon-success successIcon"></i>
        {{'您的资料已经提交，正在审核中…'}}
      </template>
    </div>
    <div class="enterpriseInfo">
      <div class="fromTitle">填写的个人信息<span class="fromSpanTitle">请记住登录账号密码跟手机号，登录时需使用</span></div>
      <div class="infoDiv">
        <div class="infoFirst">登录账号：
          <span class="infoSpan">{{params.registerLoginId}}</span>
        </div>
        <div class="infoSecond" v-show="params.loginMobileId">手机号码：
          <span class="infoSpan">{{params.loginMobileId}}</span>
        </div>
      </div>
      <div class="infoDiv">
        <div class="infoFirst">账户类型：
          <span class="infoSpan">采购商</span>
        </div>
        <div class="infoSecond" v-show="customerName">单位名称：
          <span class="infoSpan">{{customerName}} </span>
        </div>
      </div>
    </div>
    <div class="enterpriseInfo nextInfo">
      <div class="fromTitle">审核进度<span class="fromSpanTitle">审核过程中，首页输入登录账号密码，可查看当前审核状态或修改注册信息</span></div>
      <div class="infoDiv">
        <div class="infoFirst">审核状态：
          <span class="infoSpan" v-if="params.openStat === 'APPROVE_FAILURE'">审核未通过</span>
          <span class="infoSpan" v-else-if="params.openStat === 'APPROVE_SUCCESS'">审核已通过</span>
          <span class="infoSpan" v-else>待审核</span>
        </div>
        <div class="infoSecond">注册信息：
<!--          <span class="infoSpan">已完善资料</span>-->
          <a class="changeInfo" @click="changeInfo" v-if="params.openStat === 'APPROVE_FAILURE'">重新提交</a>
          <a class="changeInfo" @click="changeInfo" v-if="params.openStat === 'WAIT_APPROVE'">修改资料</a>
        </div>
      </div>
      <div class="infoDiv" v-if="params.openStat !== 'APPROVE_SUCCESS'">
        <div class="infoFirst">审核意见：
          <span class="infoSpan">{{params.openStat === 'APPROVE_FAILURE' ? '修改资料重新提交' : '请耐心等待审核'}}</span>
        </div>
      </div>
      <div class="infoDiv" v-if="params.openStat !== 'APPROVE_SUCCESS'">
        <div class="infoFirst" style="width: 80%">拒绝理由：
          <span class="infoSpan" style="color: #666" >{{params.rejectionReasons}}</span>
        </div>
      </div>
    </div>
    <div class="backDiv">
      <el-button @click="back" class="back" style="margin-right: 8px" size="mini">返回首页</el-button>
      如有疑问请咨询QQ客服
    </div>
  </div>
</template>

<script>
export default {
  name: "submittedSuccess",
  props: {
    params: {
      type: Object,
      default: {}
    },
    customerName: {
      type: String,
      default: ''
    }
  },
  data() {
    return{}
  },
  methods: {
    back() {
      this.$router.push(`/`)
    },
    changeInfo() {
      this.params.isChange = true
      this.$emit('next', 2, this.params)
    }
  },
  mounted(){
    console.log(this.params)
  }
}
</script>

<style lang="scss" scoped>
.submittedDiv {
  width: 1200px;
  margin: 60px auto;
  font-size: 14px;
  .submittedTip {
    display: flex;
    text-align: left;
    font-size: 24px;
    line-height: 32px;
    .successIcon {
      color:#09AD25;
      font-size: 32px;
      margin-right: 5px
    }
  }
  .enterpriseInfo {
    margin-top: 20px;
    width: 100%;
    border: 1px solid #E5E5E5;
    padding-bottom: 20px;
    .fromTitle {
      width: 100%;
      height: 40px;
      background-color: rgba(9, 113, 235, 0.06);
      line-height: 40px;
      font-weight: 800;
      color: #222222;
      text-align: left;
      padding-left: 20px;
      margin-bottom: 30px;
      font-size: 16px;
      .fromSpanTitle {
        color:#666666;
        font-size: 14px;
        margin-left: 12px;
      }
    }
    .infoDiv {
      display: flex;
      font-size: 16px;
      text-align: left;
      color: #666666;
      font-weight: 700;
      margin-bottom: 20px;
      flex-wrap: wrap;
      line-height: 25px;
      .infoFirst {
        width: 40%;
        padding-left: 20px;
      }
      .infoSecond {
        width: 60%;
        .changeInfo {
          @include TextHighlightColor;
          margin-left: 16px;
        }
      }
      .infoSpan {
        color: #222222;
      }
    }
  }

  .nextInfo {
    margin-top: 0px;
    border-top: 0;
  }

  .backDiv {
    text-align: left;
    margin-top: 20px;
    color: #666666;
    font-size: 14px;
    .back {
      width: 80px;
      height: 28px;
    }
  }
}
</style>

<template>
  <div class="discount-time-content">
    <Search :index-nav="true" />
    <div class="brand-content">
      <div class="brand-bg"></div>
      <div class="brand">
        <div class="time-nav">
          <swiper :options="swiperOption" ref="mySwiper">
            <swiper-slide v-for="(item, index) in tileZone" :key="index" :id="index">
              <div v-if="item.activeStatus === '进行中'"
                   class="banner-item"
                   @click="handleClickSlide(index,item.discountRuleId)"
                   :id="index"
                   :class="{ active: index === activeIndex}"
              >
                    <div>
                      <p class="activityNm" :title="item.activityNm">{{ item.activityNm }}</p>
                      <p class="mid">
                        <span>{{ item.startTimes }}</span>
                        <span>正在秒杀</span>
                      </p>
                      <p class="footer"><span>{{ item.startData }}</span><span style="margin:0 0 0 8px"  v-if="item.isCountDown === 'Y'">{{ '距结束' + item.showEndTime }}</span></p>
                    </div>
                    <div  class="noStarting"></div>
              </div>
              <div v-else
                  class="banner-item"
                  @click="handleClickSlide(index,item.discountRuleId)"
                  :id="index"
                  :class="{ active: index === activeIndex}"
              >
                  <div class="noStart" v-if="item.activeStatus === '未开始'">
                    <p class="activityNm" :title="item.activityNm">{{ item.activityNm }}</p>
                    <p class="mid">
                      <span>{{item.startTimes }}</span>
                      <span>即将开抢</span>
                    </p>
                    <p class="footer"><span>{{item.startData }}</span></p>
                  </div>
                  <div v-else><span>即将开抢</span></div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-button-next swiper-button-next1"></div>
          <div class="swiper-button-prev swiper-button-prev1"></div>
        </div>
        <div class="load-content" v-loading="loadBol">
          <div class="brand-list">
            <div class="comm-list" v-for="item in brandList">
              <div class="comm-content">
                <div class="comm-logo">
                  <router-link :to="{ path: '/detail/' + item.productId }">
                    <img :src="item.picUrl || productImage" @error="item.picUrl = productImage" />
                  </router-link>
                  <div class="comm-price">
                    <span>{{ $util.getPriceByZero(item.price,item) }}</span>
                    <s v-if="Number(item.salePrice)">{{ $util.getPriceByZero(item.salePrice,item) }}</s>
                  </div>
                </div>
                <router-link :to="{ path: '/detail/' + item.productId }">
                  <div class="comm-name">
                    {{$util.getGoodsName(item)}}
                  </div>
                </router-link>
                <div class="comm-spec">{{ item.spec }} x {{ item.unit }}</div>
                <div class="comm-manufactor">{{ item.manufacturer }}</div>
                <div class="buy-now">立即购买</div>
              </div>
              <div class="purchase">
                <div class="spec">
                  <span style="margin-right: 12px">最小购买倍数：{{ getRealMinBuyNum(item)}}</span>
                  <span v-if="userType ==='SALESMAN'">库存 {{ item.stockQuantity}}</span>
                  <span v-if="userType === 'BUYERS'">库存 {{ item.stockQuantity >= 100 ? '>100' : '<100' }}</span>
                </div>
                <div class="num">
                  <div class="input-num">
                    <el-input-number
                      v-model="item.num"
                      :precision="getDecimalPointNum(item)"
                      :min="getRealMinBuyNum(item)"
                      :step="getRealMinBuyNum(item)"
                      step-strictly
                      size="mini"
                    />
                  </div>
                  <el-button class="pur-button"
                             :disabled="isBanAddToCart"
                             @click="buyNow(item,item.num)">立即购买</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination" v-if="total>pageSize">
          <im-pagination
            :isShowMainInfo="isShowMainInfo"
            class="table-page"
            :total="total"
            layout="prev, pager, next, jumper"
            :current-page.sync="currentPage"
            :page-size.sync="pageSize"
            @pagination="handlePagination"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Search from "@/components/Search/Search";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import {
  getPcDiscountRuleNoEnd,
  pageDiscountProductRel,
} from "@/api/promotion-zone/discount-time";
import productImage from "../../../assets/img/index/product_default.png";
import { parseTime } from "@/utils";
import {mapGetters} from 'vuex'
import addToCartMixin from '@/common/addToCart'
let vm = null;
export default {
  components: {
    Search,
    Swiper,
    SwiperSlide,
  },
  mixins:[addToCartMixin],
  computed:{
      ...mapGetters(['userType','license'])
  },
  data() {
    return {
      brandList: [],
      num: 1,
      isShowMainInfo: true,
      total: 0,
      currentPage: 1,
      pageSize: 40,
      activeIndex: 0,
      tileZone: [],
      loadBol: false,
      swiperOption: {
        // 轮播配置
        navigation: {
          nextEl: ".swiper-button-next1",
          prevEl: ".swiper-button-prev1",
        },
        slidesPerView: 5,
      },
      timeInter: "",
      endTime: "",
      productImage,
      discountRuleId: "",
      timers:[]
    };
  },
  beforeDestroy(){
    this.timers.forEach((item,index)=>{
      clearInterval(item)
    })
    this.timers = []
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData(){
      let arr = [];
      this.timers.forEach((item,index)=>{
        clearInterval(item)
      })
      this.timers = [];
      this.tileZone = [];
      getPcDiscountRuleNoEnd().then((res) => {
        //根据开始时间排序
        res.data.sort(function (a, b) {
          return a.startTime > b.startTime ? 1 : -1;
        });
        res.data.forEach((item,index) => {
          this.tileZone.push(item);
          item.startData = parseTime(item.startTime, "{y}.{m}.{d}");
          item.startTimes = parseTime(item.startTime, "{h}:{i}");
          ((n)=>{
            let timeLen = (this.$util.getTime(this.tileZone[n].endTime,'timeStamp-13') - new Date().getTime()) / 1000;
            let d = parseInt(timeLen / (3600 * 24)),//天
                    h = parseInt(timeLen % (3600 * 24) / 3600), //时
                    m = parseInt(timeLen % (3600 * 24) % 3600 / 60), //分
                    s = parseInt(timeLen % (3600 * 24) % 3600 % 60); //秒
            let showEndTime = `${d > 0 ? d + '天': ''}${ h < 10 ? '0' + h : h}:${ m < 10 ? '0' + m : m}:${ s < 10 ? '0' + s : s}`;
            let item = {...this.tileZone[n],showEndTime};
            this.$set(this.tileZone,n,item)
            let time = setInterval(()=>{
              timeLen --;
              if (timeLen <= 0){
                clearInterval(time);
                this.getData()
              }else {
                let d1 = parseInt(timeLen / (3600 * 24)),//天
                        h1 = parseInt(timeLen % (3600 * 24) / 3600), //时
                        m1 = parseInt(timeLen % (3600 * 24) % 3600 / 60), //分
                        s1 = parseInt(timeLen % (3600 * 24) % 3600 % 60); //秒
                let showEndTime1 = `${d1 > 0 ? d1 + '天': ''}${ h1 < 10 ? '0' + h1 : h1}:${ m1 < 10 ? '0' + m1 : m1}:${ s1 < 10 ? '0' + s1 : s1}`;
                let item1 = {...this.tileZone[n],showEndTime:showEndTime1};
                this.$set(this.tileZone,n,item1)
              }
            },1000)
            this.timers.push(time)
          })(index)
        });
        // console.log(this.tileZone);
        this.handleClickSlide(this.activeIndex, this.tileZone[this.activeIndex].discountRuleId);
      });
    },
    getEffectTime(timer,timeLen,effectTime,time){
      this[timeLen] = time;
      let h = parseInt(this[timeLen] / 3600), //时
              m = parseInt(this[timeLen] % 3600 / 60), //分
              s = parseInt(this[timeLen] % 3600) % 60; //秒
      this[effectTime] = `${ h < 10 ? '0' + h : h}:${ m < 10 ? '0' + m : m}:${ s < 10 ? '0' + s : s}`
      clearInterval(this[timer]);
      this[timer] = setInterval(()=>{
        this[timeLen] --;
        if (this[timeLen] <= 0){
          clearInterval(this[timer]);
          if(timer === 'timer1'){
            this.refresh();
          }
        }else {
          let h1 = parseInt(this[timeLen] / 3600), //时
                  m1 = parseInt(this[timeLen] % 3600 / 60), //分
                  s1 = parseInt(this[timeLen] % 3600) % 60; //秒
          this[effectTime] = `${ h1 < 10 ? '0' + h1 : h1}:${ m1 < 10 ? '0' + m1 : m1}:${ s1 < 10 ? '0' + s1 : s1}`
        }
      },1000)
    },
    handlePagination(val) {
      this.handProduct();
    },
    handleClickSlide(index, discountRuleId) {
      this.discountRuleId = discountRuleId;
      this.handProduct();
      clearInterval(this.timeInter);
      this.activeIndex = index;
    },
    handProduct() {
      this.loadBol = true;
      const params = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        condition: {
          discountRuleId: this.discountRuleId,
        },
      };
      pageDiscountProductRel({ ...params }).then((res) => {
        let data = [...res.data.records]
        data = data.map((item,index)=>{
          return {
            ...item,
            num: this.getRealMinBuyNum(item)
          }
        })
        this.brandList = data;
        this.total = res.data.total;
        this.loadBol = false;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.discount-time-content {
  .brand-content {
    position: relative;
    overflow-y: hidden;
    min-width: 1200px;

    .brand-bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      min-height: 1800px;
      min-width: 1200px;
      background: url("../../../assets/img/promotion-zone/discount-center/discount-time-bg.png")
        no-repeat center;
      background-size: cover;
    }

    .brand {
      width: 1200px;
      margin: 228px auto 40px;

      .time-nav {
        height: 70px;
        background-color: #fff;
        position: relative;
        padding: 0 36px;
        border-radius: 4px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .swiper-container {
          height: 100%;

          .swiper-slide {
            .banner-item {
              cursor: pointer;
              height: 100%;
              font-size: 16px;
              font-weight: bold;
              color: rgba(34, 34, 34, 1);
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              box-sizing: border-box;
              padding: 8px 0;
              text-align: center;
              >div{
                width: 100%;
              }
              .noStart{
                width: 100%;
              }
              .activityNm{
                width: 100%;
                padding: 0 10px;
                box-sizing: border-box;
                overflow:hidden;
                white-space:nowrap;
                text-overflow:ellipsis
              }
              .mid{
                font-size: 14px;
                margin-top: 10px;
                >span{
                  margin: 0 8px;
                }
              }
              .footer{
                margin-top: 5px;
                font-size: 12px;
              }
              &.active {
                background: linear-gradient(135deg, rgba(250, 167, 0, 1) 0%, rgba(250, 100, 0, 1) 100%);
                color: #fff !important;
              }
            }
          }
        }

        .swiper-button-next1 {
          height: 70px;
          width: 36px;
          background: url("../../../assets/img/promotion-zone/discount-center/icon-arrow-swiper-right.png");

          &:after {
            content: "";
          }

          right: 0;
          top: 20px;
        }

        .swiper-button-prev1 {
          height: 70px;
          width: 36px;
          background: url("../../../assets/img/promotion-zone/discount-center/icon-arrow-swiper-left.png");

          &:after {
            content: "";
          }

          left: 0;
          top: 20px;
        }
      }

      .brand-list {
        margin-top: 20px;
        display: flex;
        flex-flow: wrap;

        .comm-list {
          height: 100%;
          margin-left: 10px;
          position: relative;
          overflow: hidden;
          margin-bottom: 10px;

          &:nth-child(5n - 4) {
            margin-left: 0;
          }

          .comm-content {
            width: 232px;
            height: 350px;
            background: #fff;
            border-radius: 8px;
            padding: 16px;
            text-align: left;

            .comm-logo {
              width: 100%;
              height: 200px;
              position: relative;

              img {
                width: 100%;
                height: 100%;
              }

              .comm-price {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 6px 10px;
                background: url("../../../assets/img/promotion-zone/discount-center/discount-time-price-bg.png")
                  no-repeat;
                background-size: 100% 100%;
                border-radius: 4px;
                font-size: 20px;
                font-weight: 600;
                color: rgba(254, 233, 209, 1);
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                flex-wrap: wrap;
                span{
                  margin-right: 8px;
                }
                s {
                  display: block;
                  font-size: 14px;
                  font-weight: 400;
                  margin-top: 6px;
                }
              }
              .loginChack {
                font-size: 18px;
                font-weight: 600;
              }
            }

            .comm-name {
              font-size: 16px;
              font-weight: 600;
              color: rgba(34, 34, 34, 1);
              padding-top: 8px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 22px;
            }

            .comm-spec {
              font-size: 14px;
              font-weight: 500;
              color: rgba(102, 102, 102, 1);
              line-height: 20px;
              padding-top: 4px;
              height: 24px;
              overflow: hidden;
            }

            .comm-manufactor {
              font-size: 14px;
              font-weight: 500;
              color: rgba(102, 102, 102, 1);
              line-height: 20px;
              height: 20px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .buy-now {
              height: 28px;
              line-height: 28px;
              text-align: center;
              background: rgba(255, 255, 255, 1);
              border-radius: 4px;
              border: 1px solid rgba(250, 100, 0, 1);
              font-size: 14px;
              font-weight: bold;
              color: rgba(250, 100, 0, 1);
              margin-top: 16px;
            }
          }

          .purchase {
            position: absolute;
            bottom: -80px;
            left: 0;
            right: 0;
            transition: bottom 0.3s ease-out;

            .spec {
              height: 36px;
              line-height: 36px;
              background: rgba(102, 102, 102, 1);
              box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.15);
              color: rgba(255, 255, 255, 1);
              font-size: 12px;
              font-weight: 400;
              padding: 0 8px;
              text-align: left;

              span {
                float: left;
              }
            }

            .num {
              display: flex;

              .input-num {
                width: 100px;
                padding: 6px;
                background: rgba(47, 56, 68, 1);
              }

              .pur-button {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(250, 100, 0, 1);
                font-size: 16px;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
                border-radius: 0;
                border: 0;
                &.is-disabled{
                  background: #aaa !important;
                  color: #fff;
                }
              }
            }
          }
        }

        .comm-list:hover .purchase {
          bottom: 0;
        }
      }

      .pagination {
        background-color: #fff;
        height: 48px;
        border-radius: 4px;
        padding: 8px;
      }
    }
  }
}
</style>

<style lang="scss">
.discount-time-content {
  .brand-list {
    .purchase {
      .input-num {
        .el-input-number {
          width: 100%;
          line-height: 22px;
        }

        .el-input-number__decrease {
          width: 24px;
        }

        .el-input__inner {
          height: 24px;
          line-height: 24px;
          padding-left: 24px;
          padding-right: 29px;
        }
      }
    }
  }
}
</style>

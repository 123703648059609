var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "im-page-modal",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "my-salesman-page",
      attrs: { title: "我的业务员", isShowBack: false },
    },
    [
      _c(
        "el-col",
        { staticClass: "saleman-item" },
        [
          _c("el-image", {
            staticStyle: {
              width: "48px",
              height: "48px",
              "margin-right": "20px",
            },
            attrs: { src: _vm.picUrl || _vm.imgDefault },
          }),
          _c(
            "div",
            { staticStyle: { height: "100%" } },
            [
              _c("el-col", { staticClass: "con-text" }, [
                _c("span", [
                  _vm._v("业务员名称：" + _vm._s(_vm.saleManList.salesmanName)),
                ]),
              ]),
              _c("el-col", { staticClass: "con-text" }, [
                _c("span", [
                  _vm._v(
                    "业务员手机：" + _vm._s(_vm.saleManList.salesmanContactTel)
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
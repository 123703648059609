<template>
    <div class="procurement-plan-page">
        <keep-alive :include="['quotationList']">
            <component
                    :is="currentView"
                    :params="params"
                    @change-page="handleChangePage"
            />
        </keep-alive>
    </div>
</template>
<script>
    import quotationList from './list'
    import quotationUpdate from './add-update'

    export default {
        components: {
            quotationList,
            quotationUpdate
        },
        data () {
            return {
                currentView: 'quotationList',
                params: {
                    rowData: {},
                    refreshList: false
                },
                editType: ''
            }
        },
        mounted() {
            this.$emit('handle-change-page', {
                text: '业务员报价单',
                index: '3-8',
                url: '/member-center/transactions-using/quotation-list',
            })
        },
        methods: {
            handleChangePage (data) {
                this.params.rowData = {}
                this.params.refreshList = false
                if (data.params.rowData) {
                    this.params.rowData = data.params.rowData
                }
                this.params.refreshList = data.params.refreshList
                this.currentView = data.page
                this.params.editType = data.params.editType
            }
        }
    }
</script>
<style scoped>
    .procurement-plan-page {
        height: 100%;
        background-color: white;
    }
</style>

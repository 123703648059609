var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "im-page-modal",
    {
      staticClass: "user-info",
      attrs: { title: "账户资料", isShowBack: false },
    },
    [
      _c(
        "el-col",
        { staticClass: "infoitem1" },
        [
          _c(
            "el-form",
            {
              attrs: { "label-width": "80px" },
              model: {
                value: _vm.formLabelAlign,
                callback: function ($$v) {
                  _vm.formLabelAlign = $$v
                },
                expression: "formLabelAlign",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户头像" } },
                [
                  _c("el-avatar", {
                    attrs: {
                      shape: "square",
                      size: 70,
                      fit: "cover",
                      src: _vm.formLabelAlign.icon
                        ? _vm.formLabelAlign.icon
                        : _vm.imgDefault,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "员工号" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "show-info",
                      attrs: { readonly: "" },
                      model: {
                        value: _vm.formLabelAlign.employeeNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.formLabelAlign, "employeeNo", $$v)
                        },
                        expression: "formLabelAlign.employeeNo",
                      },
                    },
                    [_vm._v("d")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "姓名" } },
                [
                  _c("el-input", {
                    staticClass: "show-info",
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.formLabelAlign.salesmanName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formLabelAlign, "salesmanName", $$v)
                      },
                      expression: "formLabelAlign.salesmanName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "性别" } },
                [
                  _c("el-input", {
                    staticClass: "show-info",
                    attrs: { readonly: "" },
                    model: {
                      value:
                        _vm.formLabelAlign.userSexCode === "MEN"
                          ? "男"
                          : _vm.formLabelAlign.userSexCode === "WOMEN"
                          ? "女"
                          : "保密",
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formLabelAlign.userSexCode === "MEN"
                            ? "男"
                            : _vm.formLabelAlign,
                          "userSexCode === 'WOMEN' ? '女' : '保密'",
                          $$v
                        )
                      },
                      expression:
                        "formLabelAlign.userSexCode === 'MEN'? '男':formLabelAlign.userSexCode === 'WOMEN' ? '女' : '保密'",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "在职状态" } },
                [
                  _c("el-input", {
                    staticClass: "show-info isworking",
                    attrs: { readonly: "" },
                    model: {
                      value:
                        _vm.formLabelAlign.isWorking === "Y" ? "在职" : "离职",
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formLabelAlign,
                          "isWorking === 'Y'? '在职':'离职'",
                          $$v
                        )
                      },
                      expression:
                        "formLabelAlign.isWorking === 'Y'? '在职':'离职'",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { staticClass: "infoitem2" },
        [
          _c(
            "el-form",
            {
              attrs: { "label-width": "80px" },
              model: {
                value: _vm.formLabelAlign,
                callback: function ($$v) {
                  _vm.formLabelAlign = $$v
                },
                expression: "formLabelAlign",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "联系方式" } },
                [
                  _c("el-input", {
                    staticClass: "show-info",
                    attrs: { placeholder: "未绑定", readonly: "" },
                    model: {
                      value: _vm.formLabelAlign.loginMobileId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formLabelAlign, "loginMobileId", $$v)
                      },
                      expression: "formLabelAlign.loginMobileId",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "change-phone",
                      on: {
                        click: function ($event) {
                          return _vm.tochangePhone(
                            _vm.formLabelAlign.loginMobileId
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.formLabelAlign.loginMobileId
                            ? "修改手机"
                            : "绑定手机"
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "身份证号" } },
                [
                  _c("el-input", {
                    staticClass: "show-info",
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.formLabelAlign.identityCardNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.formLabelAlign, "identityCardNum", $$v)
                      },
                      expression: "formLabelAlign.identityCardNum",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
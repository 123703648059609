import router from './router'
import store from './store'
import NProgress from 'nprogress'
import { Message } from 'element-ui'
import 'nprogress/nprogress.css'
import { getToken, getBus, setBus, getAccount} from '@/utils/auth'
import getPageTitle from '@/utils/get-page-title'
import axios from 'axios'

const $message = Message

NProgress.configure({ showSpinner: false })

localStorage.app_version = JSON.stringify({version: 'init',t: new Date().getTime()})

const getVersion = ()=>{
  return axios.get(`/js/version.json?t=${new Date().getTime()}`)
}
//前端版本监测
const checkVersion = ()=>{
  let version = localStorage.app_version
  if(version){
    version = JSON.parse(version)
    if(version.version === 'init'){
      getVersion().then(res=>{
        localStorage.app_version = JSON.stringify({version:res.data.version, t: new Date().getTime()})
      })
    }else if(new Date().getTime() - version.t > 10000){
      getVersion().then(res=>{
        if(res.data.version !== version.version){
          store.dispatch('user/changeVersion',true)
          localStorage.app_version = JSON.stringify({version:res.data.version, t: new Date().getTime()})
        }
      })
    }
  }else {
    getVersion().then(res=>{
      localStorage.app_version = JSON.stringify({version:res.data.version, t: new Date().getTime()})
      store.dispatch('user/changeVersion',true)
    })
  }
}

router.beforeEach(async (to, from, next) => {
  checkVersion()

  NProgress.start()
  document.title = getPageTitle(to.meta.title)

  const hasOrg = store.getters.allBusiness && store.getters.allBusiness.length > 0

  const userType = store.getters.userType

  const org = getBus()

  if (getToken()) {
    if (!getAccount()) {
      await store.dispatch('user/getInfo')
    }

    const isBd = store.getters.userMobile

    if(to.meta.requireAuth || to.path === '/') {
      if (isBd !== null && isBd !== '') {
        next()
        NProgress.done()
      }else{
        if (store.getters.userType != 'BUYERS_TEAM') {
          $message.error({
            message:'请先绑定手机号'
          })
          next({path: '/change-phone', query: { origin: "memberChangePhones"}})
          NProgress.done()
        }

      }
    }else{
      if (to.path === '/login') {
        next({ path: '/' })
        NProgress.done()
      }else{
        next()
        NProgress.done()
      }
    }
  } else {
    if (!hasOrg) {
      let select = {}
      store.dispatch('user/getBusiness').then(res => {
        if (res.length) {
          // 刷新后重新获取已选商户
          select = org ? res.find(v => v.orgId === org.orgId) : res[0]
          setBus(select)
        }
      }).then(_ => {
        store.dispatch('user/changeBusiness', select)
      })
    }
    if (!to.meta.requireAuth) { // 无需登录权限，直接进入
      next()
      NProgress.done()
    } else {
      $message.warning('请先登录')
      next('/login') // 否则全部重定向到登录页
      NProgress.done()
    }
  }
  next()
  NProgress.done()
})

router.afterEach(() => {
  NProgress.done()
})

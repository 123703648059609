import QrCode from 'qrcode'
export default {
    methods:{
        /*
        * String canvasId 标签Canvas的Id值 required
        * */
        drawQrCode(canvasId, url, size = 200) {
            if(!canvasId){
                console.error('drawQrCode error: 缺少参数canvasId,该值必须传Canvas标签的id')
                return Promise.reject({errMsg: '缺少参数canvasId'})
            }

            if(!url){
                console.error('drawQrCode error: 参数url不能为空')
                return Promise.reject({errMsg: '缺少参数url'})
            }

            return new Promise((resolve,reject)=>{
                let canvas = document.getElementById(canvasId)
                console.log(canvas)
                QrCode.toCanvas(canvas, url, function (error) {
                    if (error) {
                        console.error(error)
                        return
                    }
                    console.log('success!');
                    resolve();
                })
            })
        }
    }
}
<template>
  <div id="app">
    <Header v-if="!whiteList.includes(key)"></Header>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <Footer v-if="!whiteList.includes(key)"></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header/Header';
import Footer from '@/components/Footer/Footer';
export default {
  name: 'App',
  data () {
    return {
      whiteList: ['','login','404','register','ICBCPay']
    }
  },
  components: {
    Header,
    Footer
  },
  computed: {
    key() {
      return this.$route.name || ''
    }
  },
  watch:{
    '$store.state.user.isChangeVersion'(val){
        if(val === true){
          this.$confirm('商城更新了新内容，请刷新页面获取更新','提示',{
                cancelButtonText: '稍后自行刷新',
                showCancelButton: false,
                closeOnClickModal: false,
                showClose: false,
                confirmButtonText: '立即刷新',
          }).then( res =>{
            location.reload()
          }).catch( err =>{
            console.log('自行刷新')
          })
          this.$store.dispatch('user/changeVersion',false)

        }
    }
  }
};
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #222;
  @include BgColor
}
</style>

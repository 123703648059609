<template>
  <el-dialog :title="title" :visible.sync="giftsDialog" class="gifts-dialog" width="1200px" @close="handleHide">
    <el-table :class="{'hide-all-checked': singleSelect}" :data="gridData" border ref="giftsTable" @selection-change="handleSelectionChange" height="480px">
      <el-table-column type="selection" width="45" align="center" :selectable='selectEnable' />
      <el-table-column class-name="padding" label="商品图" width="80px" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="commodity-image">
            <router-link :to="'/detail/'+scope.row.presentDetail.productId">
              <img class="product-img"
                   :src="scope.row.presentDetail.imgUrl|| productDefaultImg"
                   alt>
            </router-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="presentDetail.presentNm" class-name="visibility" label="商品名称" width="160px" show-overflow-tooltip />
      <el-table-column prop="presentDetail.spec" align="center" label="规格" width="120px" show-overflow-tooltip/>
      <el-table-column prop="presentDetail.manufacturer" align="left" label="厂家(上市许可持有人)/产地" show-overflow-tooltip/>
      <el-table-column prop="presentDetail.unit" align="center" label="单位" width="56px" show-overflow-tooltip/>
      <el-table-column align="center" label="采购数量" width="160px">
        <template slot-scope="scope">
<!--          <div v-if="scope.row.shoppingCartItemType !== 'NORMAL' && scope.row.shoppingCartItemType !== 'ADDITIONAL' && scope.row.shoppingCartItemType !== 'SPECIAL_PRICE_PURCHASE' && scope.row.recommend !== 'Y'">-->
<!--            {{ scope.row.quantity }}-->
<!--          </div>-->
          <el-input-number
            @change="handleChangeQuantity"
            size="mini"
            :disabled="isInput"
            v-model="scope.row.presentQuantity"
            :precision="0"
            :min="0"
            :step="1"
            :max="scope.row.presentDetail.stock"
            step-strictly/>
        </template>
      </el-table-column>
      <el-table-column prop="presentDetail.stock" align="center" label="剩余数量" width="86px" show-overflow-tooltip/>
      <el-table-column prop="giftPrice" align="center" label="单价" width="86px">
        <template slot-scope="scope">
          <span style="color: #FF3B30">￥{{scope.row.giftPrice}}</span><br>
          <span style="text-decoration: line-through;color: #999">¥{{ scope.row.salePrice }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="总价" width="86px">
        <template slot-scope="scope">
          <span style="color: #FF3B30">￥{{scope.row.giftPrice * scope.row.presentQuantity}}</span>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer">
      <el-button @click="handleHide">取 消</el-button>
      <el-button type="primary" @click="addItem">立即购买</el-button>
    </div>
  </el-dialog>
</template>

<script>
import productDefaultImg from '@/assets/img/index/product_default.png'
import addToCartMixin from '@/common/addToCart'
import {saveFullGiftDetailAndAddToCart} from "../../../../api/promotion-zone";
export default {
  name: "gifts-dialog",
  mixins: [addToCartMixin],
  props: {
    activeType: {
      type: Number
    },
    fullCondition: {
      type: Number
    }
  },
  data() {
    return {
      productDefaultImg,
      giftsDialog: false,
      gridData: [],
      dataonLineListSelections: [],
      singleSelect: false,
      isInput: false,
      total: 10,
      title: '选择特殊商品',
      totalQuantity: 0, // 总特殊商品数
      wholeQuantity: 0,
      goodsTotal: 0, // 已选赠品总数
      extCfg: {},
      masterProductConfigsList: [],
      fullReduceSendId: null,
      fullReduceSendRuleId: null,
      itemKey: null,
      triggerItemKeys: [],
      isChange: true
    }
  },
  watch: {
  },
  methods: {
    open(list, extCfg, wholeQuantity, item, id, itemKey, triggerItemKeys, fullReduceSendRuleId) {
      console.log('fullReduceSendRuleId', fullReduceSendRuleId)
      this.masterProductConfigsList = []
      this.giftsDialog = true
      this.title = '选择特殊商品'
      this.gridData = list
      item.map(val => {
        this.masterProductConfigsList.push({skuId:val.skuId,productId: val.productId, productCode: val.productCode, quantity:val.quantity})
      })
      // this.masterProductConfigsList = item
      this.extCfg = extCfg
      this.wholeQuantity = wholeQuantity
      this.totalQuantity = extCfg.giftNum * wholeQuantity
      this.fullReduceSendId = id
      this.fullReduceSendRuleId = fullReduceSendRuleId
      this.isChange = false
      if (itemKey !== null) {
        this.itemKey = itemKey
      } else {
        this.itemKey = null
      }
      if (triggerItemKeys.length > 0) {
        this.triggerItemKeys = triggerItemKeys
      } else {
        this.triggerItemKeys = []
      }
      if(extCfg.isSingleItem == 1) {
        this.singleSelect = true
        this.isInput = true
        this.gridData.map((item, index) => {
          this.gridData[index].presentQuantity = this.totalQuantity
        })
      } else if(extCfg.isSingleItem == 2 && extCfg.giftNum !== 0) {
        if (this.gridData.some(item=> {return item.presentQuantity>0})) {
          this.singleSelect = false
          this.isInput = true
          this.gridData.map((item, index) => {
            this.gridData[index].presentQuantity = this.gridData[index].presentQuantity * this.wholeQuantity
          })
          this.$nextTick(() => {
            this.$refs.giftsTable.toggleAllSelection()
          })
        } else {
          this.title = '选择特殊商品'+'(可选特殊商品数'+this.totalQuantity+'个,已选'+ this.goodsTotal +'个)'
          this.isInput = false
          this.singleSelect = false
          this.isChange = true
        }
      }
    },
    selectEnable(row, rowIndex) {
      if (row.presentDetail.stock !== 0) {
        return true
      }
    },
    handleHide() {
      this.giftsDialog = false
      this.$refs.giftsTable.clearSelection()
      this.$emit("hideDialog")
    },
    totalize() {
      let giftConfigsList = []
      this.$refs.giftsTable.selection.map(item => {
        giftConfigsList.push({quantity: item.presentQuantity})
      })
      this.goodsTotal = giftConfigsList.map(item => {
        return item.quantity
      }).reduce((pre, cur, index) => {
        return pre + cur;
      }, 0)
      if(this.isChange) {
        if (this.totalQuantity < this.goodsTotal) {
          this.title = '选择特殊商品'+'(可选特殊商品数0个,已选'+ this.goodsTotal +'个)'
          this.$message.warning('您多选了'+(this.goodsTotal-this.totalQuantity)+'个商品，请减少多余商品！')
        } else {
          this.title = '选择特殊商品'+'(可选特殊商品数'+ (this.totalQuantity - this.goodsTotal) + '个,已选'+ this.goodsTotal +'个)'
        }
      }
    },
    handleChangeQuantity(val) {
      console.log('val', val)
      this.totalize()
    },
    handleSelectionChange(val) {
      // 单选
      if (this.singleSelect) {
        if (val.length > 1) {
          this.$refs.giftsTable.clearSelection()
          this.$refs.giftsTable.toggleRowSelection(val.pop())
        }
        this.dataonLineListSelections = val
      } else {
        if (this.isInput) {
          // this.$refs.giftsTable.toggleAllSelection()
        } else {
          this.dataonLineListSelections = val
        }
      }
      this.totalize()
    },
    async addItem() {
      if (this.$refs.giftsTable.selection.length > 0) {
        let giftConfigsList = []
        this.$refs.giftsTable.selection.map(item => {
          giftConfigsList.push({skuId: item.presentDetail.skuId, productId: item.presentDetail.productId, quantity: item.presentQuantity, frsPresentRelId: item.frsPresentRelId})
        })
        let params = {}
        if (this.triggerItemKeys.length > 0 && this.itemKey === null) {
          // this.itemKey = null
          params = {
            giftConfigsList: giftConfigsList,
            masterProductConfigsList: this.masterProductConfigsList,
            fullReduceSendId: this.fullReduceSendId,
            fullReduceSendRuleId: this.fullReduceSendRuleId,
            triggerItemKeys: this.triggerItemKeys
          }
        } else if (this.triggerItemKeys.length === 0 && this.itemKey !== null) {
          // this.triggerItemKeys = []
          params = {
            giftConfigsList: giftConfigsList,
            masterProductConfigsList: this.masterProductConfigsList,
            fullReduceSendId: this.fullReduceSendId,
            fullReduceSendRuleId: this.fullReduceSendRuleId,
            itemKey: this.itemKey
          }
        } else {
          params = {
            giftConfigsList: giftConfigsList,
            masterProductConfigsList: this.masterProductConfigsList,
            fullReduceSendId: this.fullReduceSendId,
            fullReduceSendRuleId: this.fullReduceSendRuleId
          }
        }

        if (this.extCfg.isSingleItem == 2) {
          this.goodsTotal = giftConfigsList.map(item => {
            return item.quantity
          }).reduce((pre, cur, index) => {
            return pre + cur;
          }, 0)
          if (this.goodsTotal > this.totalQuantity) {
            this.$message.warning('最多只能选择'+this.totalQuantity+'个商品！')
            return
          } else if ((this.goodsTotal < this.totalQuantity) && this.isInput === false && this.singleSelect === false) {
            let remainingQuantity = this.totalQuantity - this.goodsTotal
            this.$confirm('您尚可再挑选'+remainingQuantity+'件商品，确定要立即购买？', '温馨提示', {
              dangerouslyUseHTMLString:true,
              confirmButtonText: "确定",
              cancelButtonText: "取消"
            }).then(() => {
              params.giftConfigsList = params.giftConfigsList.filter(item => {
                return item.quantity !== 0
              })
              saveFullGiftDetailAndAddToCart(params).then(data => {
                if (data.code == 0) {
                  this.$message.success('加入成功！')
                  this.$emit("success")
                  this.giftsDialog = false
                  this.$refs.giftsTable.clearSelection()
                  this.$emit("hideDialog")
                } else {
                  this.$message.error(data.msg)
                }
              }).catch(err => {
                this.$message({
                  message: err.msg,
                  type: 'error',
                  duration: 5 * 1000
                })
              })

            }).catch(() => {
              this.$message.warning('取消加入购物车！')
            })
          } else {
            await saveFullGiftDetailAndAddToCart(params).then(data => {
              if (data.code == 0) {
                this.$message.success('加入成功！')
                this.$emit("success")
                this.giftsDialog = false
                this.$refs.giftsTable.clearSelection()
                this.$emit("hideDialog")
              } else {
                this.$message.error(data.msg)
              }
            }).catch(err => {
              this.$message({
                message: err.msg,
                type: 'error',
                duration: 5 * 1000
              })
            })
          }
        } else {
          // let data = await saveFullGiftDetailAndAddToCart(params)
          await saveFullGiftDetailAndAddToCart(params).then(data => {
            if (data.code == 0) {
              this.$message.success('加入成功！')
              this.$emit("success")
              this.giftsDialog = false
              this.$refs.giftsTable.clearSelection()
              this.$emit("hideDialog")
            } else {
              this.$message.error(data.msg)
            }
          }).catch(err => {
            this.$message({
              message: err.msg,
              type: 'error',
              duration: 5 * 1000
            })
          })
          // if (data.code == 0) {
          //   this.$message.success('加入成功！')
          //   this.$emit("success")
          //   this.giftsDialog = false
          //   this.$refs.giftsTable.clearSelection()
          //   this.$emit("hideDialog")
          // } else {
          //   // this.$message({
          //   //   message: data.msg,
          //   //   type: "warning",
          //   //   customClass: 'messageIndex'
          //   // })
          //   this.giftsDialog = false
          // }
        }
      } else {
        let params = {
          giftConfigsList: [],
          masterProductConfigsList: this.masterProductConfigsList,
          fullReduceSendId: this.fullReduceSendId,
          fullReduceSendRuleId: this.fullReduceSendRuleId,
          triggerItemKeys: this.triggerItemKeys
        }
        this.$confirm('您还未选择特殊商品，确定要立即购买？', '温馨提示', {
          dangerouslyUseHTMLString:true,
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        }).then(() => {
          saveFullGiftDetailAndAddToCart(params).then(data => {
            if (data.code == 0) {
              this.$message.success('加入成功！')
              this.$emit("success")
              this.giftsDialog = false
              this.$refs.giftsTable.clearSelection()
              this.$emit("hideDialog")
            } else {
              this.$message.error(data.msg)
            }
          }).catch(err => {
            this.$message({
              message: err.msg,
              type: 'error',
              duration: 5 * 1000
            })
          })
        }).catch(() => {
          // this.$message.warning('取消加入购物车！')
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  //.messageIndex {
  //  z-index: 1000000 !important;
  //}
  .commodity-image {
    text-align: center;
  }
  .product-img {
    width: 60px;
    height: 60px;
  }
  /*商品名称*/
  .table-product-name {
    display: flex;
    justify-content: center;
    flex-direction: column;
    p {
      font-size: 14px;
      font-weight: 600;
      color: rgba(34, 34, 34, 1);
      line-height: 20px;
      padding-bottom: 3px;
    }

    .table-row-tags {
      display: flex;
      flex-direction: row;
    }

    .row-tag {
      height: 18px;
      font-size: 12px;
    // font-weight: 500;
      line-height: 18px;
      border-radius: 3px;
      border-width: 1px;
      border-style: solid;
      margin-right: 4px;
      padding: 0 4px;
      box-sizing: border-box;
    }

    .tag-promotion {
      background: #FF6600;
      border: none;
      color: #ffffff;
    }

    .tag-cash {
      border-color: transparent;
      color: #025CD6;
      background-color: rgba(2, 92, 214, 0.1);
    }

    .tag-coupon {
      border-color: transparent;
      color: #FF6600;
      background-color: rgba(255, 102, 0, 0.1);
    }

    .tag-waring{
      border-color: transparent;
      color: #ffffff;
      background-color: red;
    }

    .tag-plus {
      border-color: transparent;
      color: #33AE67;
    }

    .tag-otc {
      border-color: transparent;
      background-color: #258C60;
      color: #fff !important;
      display: inline-block;
    }

    .jiaOtc {
      background: #E60012 !important;
    }

    .yiOtc {
      background: #33AE67 !important;
    }

    .rxOtc {
      background: #0091ff !important;
    }
  }
  .hide-all-checked {
    /deep/.el-table__header-wrapper {
      .el-checkbox {
        display: none;
      }
    }
  }
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shop-cart-content" },
    [
      _c(
        "div",
        { staticClass: "main" },
        [
          _c("p", { staticClass: "buyer-info" }, [
            _vm.userType === "SALESMAN"
              ? _c("span", { staticClass: "buyer-tag" }, [_vm._v("业务员")])
              : _c("span", { staticClass: "buyer-tag" }, [_vm._v("采购商")]),
            _vm.userType === "SALESMAN"
              ? _c("span", { staticClass: "buyer-name" }, [
                  _vm._v(
                    _vm._s(_vm.accountId.buyersNm) +
                      "-" +
                      _vm._s(_vm.accountId.ettleAccountNm)
                  ),
                ])
              : _c("span", { staticClass: "buyer-name" }, [
                  _vm._v(
                    _vm._s(_vm.buyersVo.buyersNm) +
                      "-" +
                      _vm._s(_vm.accountId.ettleAccountNm)
                  ),
                ]),
            _vm.shopCart.receiverName
              ? _c("span", { staticClass: "buyer-contact" }, [
                  _vm._v("联系人：" + _vm._s(_vm.shopCart.receiverName)),
                ])
              : _vm._e(),
            _vm.shopCart.receiverName
              ? _c("span", { staticClass: "buyer-contact" }, [
                  _vm._v("联系电话：" + _vm._s(_vm.shopCart.receiverMobile)),
                ])
              : _vm._e(),
            _vm.couponItems.length
              ? _c(
                  "span",
                  {
                    staticClass: "buyer-discounts",
                    on: {
                      click: function ($event) {
                        _vm.dialogCouponVisible = true
                      },
                    },
                  },
                  [_vm._v("领取优惠券")]
                )
              : _vm._e(),
          ]),
          _vm.shopCart.discountList.length
            ? _c(
                "ul",
                { staticClass: "discounts" },
                _vm._l(_vm.shopCart.discountList, function (item, index) {
                  return _c(
                    "li",
                    { key: index, staticClass: "discounts-row" },
                    [
                      item.discountType === "FULL_REDUCE_SEND_ACTIVITY" &&
                      item.presentList.length > 0
                        ? _c(
                            "div",
                            [
                              _c("span", { staticClass: "discounts-tag" }, [
                                _vm._v("自选套餐"),
                              ]),
                              _c("span", { staticClass: "discounts-info" }, [
                                _vm._v(_vm._s(item.discountNm)),
                              ]),
                              item.presentList.length
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openDialogPresentVisible2(
                                            item.discountId,
                                            item.discountNm,
                                            item.triggerItemKeys
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("选购商品 ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : item.discountType === "FULL_REDUCE_SEND_ACTIVITY" &&
                          item.presentList.length === 0
                        ? _c("div", [
                            _c("span", { staticClass: "discounts-tag" }, [
                              _vm._v("满减送"),
                            ]),
                            _c("span", { staticClass: "discounts-info" }, [
                              _vm._v(_vm._s(item.discountNm)),
                            ]),
                          ])
                        : item.discountType === "DISCOUNT_ACTIVITY"
                        ? _c("div", [
                            _c("span", { staticClass: "discounts-tag" }, [
                              _vm._v("限时折扣"),
                            ]),
                            _c("span", { staticClass: "discounts-info" }, [
                              _vm._v(_vm._s(item.discountNm)),
                            ]),
                          ])
                        : item.discountType === "ADDITIONAL_ACTIVITY" &&
                          item.additionalProductList.length
                        ? _c(
                            "div",
                            [
                              _c("span", { staticClass: "discounts-tag" }, [
                                _vm._v("加价购"),
                              ]),
                              _c("span", { staticClass: "discounts-info" }, [
                                _vm._v(_vm._s(item.discountNm)),
                              ]),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openDialogAdditionVisible(
                                        index
                                      )
                                    },
                                  },
                                },
                                [_vm._v("换购商品")]
                              ),
                            ],
                            1
                          )
                        : item.discountType === "SPECIAL_PRICE_PURCHASE" &&
                          item.additionalProductList.length
                        ? _c(
                            "div",
                            [
                              _c("span", { staticClass: "discounts-tag" }, [
                                _vm._v("特价换购"),
                              ]),
                              _c("span", { staticClass: "discounts-info" }, [
                                _vm._v(_vm._s(item.discountNm)),
                              ]),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openDialogSpecialVisible(index)
                                    },
                                  },
                                },
                                [_vm._v("特价换购商品")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-dialog",
                        {
                          staticClass: "addition-dialog",
                          attrs: {
                            width: "680px",
                            title: "加价购",
                            visible: _vm.dialogAdditionVisible,
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.dialogAdditionVisible = $event
                            },
                          },
                        },
                        [
                          _c("h3", [_vm._v(_vm._s(_vm.addition.discountNm))]),
                          _c(
                            "div",
                            { staticClass: "checkChecked" },
                            _vm._l(
                              _vm.addition.additionalProductList,
                              function (additional, i) {
                                return _c(
                                  "el-checkbox",
                                  {
                                    key: i,
                                    model: {
                                      value: additional.select,
                                      callback: function ($$v) {
                                        _vm.$set(additional, "select", $$v)
                                      },
                                      expression: "additional.select",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          (additional.productPicMap &&
                                            additional.productPicMap[
                                              "80X80"
                                            ]) ||
                                          _vm.productDefaultImg,
                                        alt: "",
                                      },
                                      on: {
                                        error: function ($event) {
                                          additional.productPicMap["80X80"] =
                                            _vm.productDefaultImg
                                        },
                                      },
                                    }),
                                    _c("div", { staticClass: "content" }, [
                                      _c("div", { staticClass: "name" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$util.getGoodsName(additional)
                                          )
                                        ),
                                      ]),
                                      _c("div", { staticClass: "spec" }, [
                                        _vm._v(_vm._s(additional.spec)),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "manufacturer" },
                                        [
                                          _vm._v(
                                            _vm._s(additional.manufacturer)
                                          ),
                                        ]
                                      ),
                                      _c("div", { staticClass: "price" }, [
                                        _vm._v(
                                          "¥" +
                                            _vm._s(additional.originalUnitPrice)
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            "¥" + _vm._s(additional.salePrice)
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                          _c("footer", [
                            _vm.addition.additionalProductList
                              ? _c("p", [
                                  _vm._v(
                                    " 已加购" +
                                      _vm._s(
                                        _vm.addition.additionalProductList.filter(
                                          (res) => res.select
                                        ).length
                                      ) +
                                      "件商品 "
                                  ),
                                  _c("span", [
                                    _vm._v("共计 "),
                                    _c("b", [
                                      _vm._v(
                                        "¥" +
                                          _vm._s(
                                            _vm.addition.additionalProductList.filter(
                                              (res) => res.select
                                            ).length
                                              ? _vm.addition.additionalProductList
                                                  .filter((res) => res.select)
                                                  .map(
                                                    (res) =>
                                                      res.originalUnitPrice
                                                  )
                                                  .reduce((n, m) => n + m)
                                              : 0
                                          )
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                staticClass: "add-shopping-cart",
                                attrs: { loading: _vm.loading },
                                on: {
                                  click: function ($event) {
                                    return _vm.addItem()
                                  },
                                },
                              },
                              [_vm._v("加入购物车")]
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-dialog",
                        {
                          staticClass: "addition-dialog",
                          attrs: {
                            width: "680px",
                            title: "特价换购",
                            visible: _vm.dialogSpecialVisible,
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.dialogSpecialVisible = $event
                            },
                          },
                        },
                        [
                          _c("h3", [_vm._v(_vm._s(_vm.special.discountNm))]),
                          _c(
                            "div",
                            { staticClass: "checkChecked" },
                            _vm._l(
                              _vm.special.additionalProductList,
                              function (additional, i) {
                                return _c(
                                  "el-checkbox",
                                  {
                                    key: i,
                                    model: {
                                      value: additional.select,
                                      callback: function ($$v) {
                                        _vm.$set(additional, "select", $$v)
                                      },
                                      expression: "additional.select",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          (additional.productPicMap &&
                                            additional.productPicMap[
                                              "80X80"
                                            ]) ||
                                          _vm.productDefaultImg,
                                        alt: "",
                                      },
                                      on: {
                                        error: function ($event) {
                                          additional.productPicMap["80X80"] =
                                            _vm.productDefaultImg
                                        },
                                      },
                                    }),
                                    _c("div", { staticClass: "content" }, [
                                      _c("div", { staticClass: "name" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$util.getGoodsName(additional)
                                          )
                                        ),
                                      ]),
                                      _c("div", { staticClass: "spec" }, [
                                        _vm._v(_vm._s(additional.spec)),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "manufacturer" },
                                        [
                                          _vm._v(
                                            _vm._s(additional.manufacturer)
                                          ),
                                        ]
                                      ),
                                      _c("div", { staticClass: "price" }, [
                                        _vm._v(
                                          "¥" +
                                            _vm._s(additional.originalUnitPrice)
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            "¥" + _vm._s(additional.salePrice)
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                          _c("footer", [
                            _vm.special.additionalProductList
                              ? _c("p", [
                                  _vm._v(
                                    " 已加购" +
                                      _vm._s(
                                        _vm.special.additionalProductList.filter(
                                          (res) => res.select
                                        ).length
                                      ) +
                                      "件商品 "
                                  ),
                                  _c("span", [
                                    _vm._v("共计 "),
                                    _c("b", [
                                      _vm._v(
                                        "¥" +
                                          _vm._s(
                                            _vm.special.additionalProductList.filter(
                                              (res) => res.select
                                            ).length
                                              ? _vm.special.additionalProductList
                                                  .filter((res) => res.select)
                                                  .map(
                                                    (res) =>
                                                      res.originalUnitPrice
                                                  )
                                                  .reduce((n, m) => n + m)
                                              : 0
                                          )
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                staticClass: "add-shopping-cart",
                                attrs: { loading: _vm.loading },
                                on: {
                                  click: function ($event) {
                                    return _vm.addSpecialItem()
                                  },
                                },
                              },
                              [_vm._v("加入购物车")]
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-dialog",
                        {
                          staticClass: "addition-dialog",
                          attrs: {
                            width: "1200px",
                            "max-height": "680px",
                            title: "推荐套餐",
                            visible: _vm.dialogPackageVisible,
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.dialogPackageVisible = $event
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "package-content" },
                            [
                              _vm.swiperData.length
                                ? _c("swiper-package", {
                                    attrs: {
                                      model: "more",
                                      "swiper-data": _vm.swiperData,
                                    },
                                    on: { refresh: _vm.refreshCart },
                                  })
                                : _vm._e(),
                              _vm.FIXED_PACKAGE.length
                                ? _c("swiper-package", {
                                    attrs: {
                                      model: "more",
                                      type: "FIXED_PACKAGE",
                                      "swiper-data": _vm.FIXED_PACKAGE,
                                    },
                                    on: { refresh: _vm.refreshCart },
                                  })
                                : _vm._e(),
                              _vm.total
                                ? _c("im-pagination", {
                                    attrs: {
                                      total: _vm.total,
                                      "hide-on-single-page": false,
                                      "current-page": _vm.currentPage,
                                      "page-size": _vm.pageSize,
                                      hideOnSinglePage: false,
                                      isShowMainInfo: true,
                                    },
                                    on: {
                                      "update:currentPage": function ($event) {
                                        _vm.currentPage = $event
                                      },
                                      "update:current-page": function ($event) {
                                        _vm.currentPage = $event
                                      },
                                      "update:pageSize": function ($event) {
                                        _vm.pageSize = $event
                                      },
                                      "update:page-size": function ($event) {
                                        _vm.pageSize = $event
                                      },
                                      pagination: _vm.handleGiftsPagination,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _c(
            "el-dialog",
            {
              attrs: {
                width: "450px",
                title: "特殊商品",
                visible: _vm.dialogPresentVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogPresentVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "present-dialog" },
                _vm._l(_vm.presentList, function (present, i) {
                  return _c(
                    "el-popover",
                    { key: i, attrs: { placement: "top", trigger: "hover" } },
                    [
                      _c("p", { staticStyle: { padding: "5px 8px" } }, [
                        _vm._v(_vm._s(present.presentNm || present.productNm)),
                      ]),
                      _c("img", {
                        attrs: {
                          slot: "reference",
                          src:
                            (present.productPicMap &&
                              present.productPicMap["48X48"]) ||
                            _vm.productDefaultImg,
                          alt: "",
                        },
                        on: {
                          error: function ($event) {
                            present.productPicMap["48X48"] =
                              _vm.productDefaultImg
                          },
                        },
                        slot: "reference",
                      }),
                    ]
                  )
                }),
                1
              ),
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                width: "1000px",
                title: _vm.giftsData.length
                  ? _vm.giftsData[0].discountNm
                  : "自选套餐",
                visible: _vm.dialogGiftsVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogGiftsVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.giftsDataLoading,
                      expression: "giftsDataLoading",
                    },
                  ],
                  staticClass: "gifts-dialog",
                },
                [
                  _vm.giftsData.length
                    ? _c("swiper-package2", {
                        attrs: { model: "more", "swiper-data": _vm.giftsData },
                        on: { openGifts: _vm.handleOpenGiftsDialog },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-dialog",
            {
              staticClass: "coupon-el-dialog__body",
              attrs: {
                width: "450px",
                title: "优惠券",
                visible: _vm.dialogCouponVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogCouponVisible = $event
                },
              },
            },
            _vm._l(_vm.couponItems, function (item, index) {
              return _c("div", { key: index, staticClass: "coupon-dialog" }, [
                _c("div", { staticClass: "flex" }, [
                  item.couponType === "FULL_REDUCTION"
                    ? _c("div", { staticClass: "name" }, [
                        _c("div", { staticClass: "left-con" }),
                        _c("p", [
                          _vm._v("¥" + _vm._s(item.fullReductionAmount)),
                        ]),
                        _c("div", { staticClass: "right-con" }),
                      ])
                    : _c("div", { staticClass: "name" }, [
                        _c("div", { staticClass: "left-con" }),
                        _c("p", [_vm._v(_vm._s(item.discount) + "折")]),
                        _c("div", { staticClass: "right-con" }),
                      ]),
                  _c("div", { staticClass: "text" }, [
                    _c("div", { staticClass: "text_1" }, [
                      _vm._v(_vm._s(item.couponNm)),
                    ]),
                    item.useTimeType === "IN_THE_DAY"
                      ? _c("div", { staticClass: "text_2" }, [
                          _vm._v(
                            "领券当日起" + _vm._s(item.useValidDay) + "天内可用"
                          ),
                        ])
                      : item.useTimeType === "TIMES_OF_THE_DAY"
                      ? _c("div", { staticClass: "text_2" }, [
                          _vm._v(
                            "领券次日起" +
                              _vm._s(item.useValidDay) +
                              "天内可用 "
                          ),
                        ])
                      : _c("div", { staticClass: "text_2" }, [
                          _vm._v(
                            _vm._s(item.useStartTime) +
                              " - " +
                              _vm._s(item.useEndTime)
                          ),
                        ]),
                  ]),
                ]),
                item.isLimitTimes === "Y" &&
                item.userReceivedQuantity < item.limitNumber &&
                item.remainQuantity > 0 &&
                item.userReceivedQuantity < item.remainQuantity
                  ? _c(
                      "div",
                      {
                        staticClass: "button",
                        on: {
                          click: function ($event) {
                            return _vm.onCoupon(item)
                          },
                        },
                      },
                      [_c("p", [_vm._v("立即领券")])]
                    )
                  : _vm._e(),
                item.isLimitTimes === "N" && item.remainQuantity > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "button",
                        on: {
                          click: function ($event) {
                            return _vm.onCoupon(item)
                          },
                        },
                      },
                      [_c("p", [_vm._v("立即领券")])]
                    )
                  : item.userReceivedQuantity >= item.limitNumber &&
                    item.userReceivedQuantity > 0
                  ? _c("div", { staticClass: "button isReceive" }, [
                      _c("p", [_vm._v("已领取")]),
                    ])
                  : item.remainQuantity <= 0 && item.userReceivedQuantity <= 0
                  ? _c("div", { staticClass: "button disabled" }, [
                      _c("p", [_vm._v("已抢光")]),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          ),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              class: {
                "is-tabledata-empty": _vm.shopCart.itemList.length === 0,
              },
              attrs: {
                border: "",
                data: _vm.shopCart.itemList,
                "header-row-class-name": "header-row",
                "tree-props": {
                  children: "children",
                  hasChildren: "hasChildren",
                },
                "row-key": "rowKey",
                "row-class-name": _vm.rowClassNameNormal,
                "span-method": _vm.spanMethodNormal,
                "default-expand-all": "",
              },
              on: {
                "update:data": function ($event) {
                  return _vm.$set(_vm.shopCart, "itemList", $event)
                },
                "select-all": _vm.handleSelectionAll,
                "selection-change": (val) => {
                  _vm.selectItem = val
                },
                select: _vm.handleSelectionChange,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  "class-name": "index",
                  align: "center",
                  label: "序号",
                  width: "45",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.recommend === "Y"
                          ? _c("span")
                          : _c("span", [_vm._v(_vm._s(scope.row.number || 0))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  "class-name": "selection",
                  align: "center",
                  width: "45",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            scope.row.recommend !== "Y"
                              ? _c("el-checkbox", {
                                  class:
                                    scope.row.selected && _vm.notButton
                                      ? "disabled-checkbox"
                                      : "",
                                  attrs: { disabled: _vm.notButton },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleSelectionChange(
                                        "",
                                        scope.row
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.selected,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "selected", $$v)
                                    },
                                    expression: "scope.row.selected",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  "class-name": "padding",
                  label: "商品图",
                  width: "80px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.isGroup
                          ? _c(
                              "div",
                              { staticClass: "commodity-image" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: "/detail/" + scope.row.productId,
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "product-img",
                                      attrs: {
                                        src:
                                          (scope.row.productPicMap &&
                                            scope.row.productPicMap["48X48"]) ||
                                          _vm.productDefaultImg,
                                        alt: "",
                                      },
                                      on: {
                                        error: function ($event) {
                                          scope.row.productPicMap["48X48"] =
                                            _vm.productDefaultImg
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c("div", { staticClass: "set-meal" }, [
                              _c("div", { staticClass: "meal-center" }, [
                                scope.row.shoppingCartItemType ===
                                "FIX_DISCOUNT_PACKAGE"
                                  ? _c("span", { staticClass: "fixed" }, [
                                      _vm._v("【固定套餐】"),
                                    ])
                                  : scope.row.shoppingCartItemType ===
                                    "ADDITIONAL"
                                  ? _c(
                                      "span",
                                      { staticClass: "discounts-tag" },
                                      [_vm._v("超值换购")]
                                    )
                                  : scope.row.shoppingCartItemType ===
                                    "SPECIAL_PRICE_PURCHASE"
                                  ? _c(
                                      "span",
                                      { staticClass: "discounts-tag" },
                                      [_vm._v("特价换购")]
                                    )
                                  : scope.row.recommend === "Y"
                                  ? _c(
                                      "span",
                                      { staticClass: "discounts-tag" },
                                      [_vm._v("推荐优惠套餐")]
                                    )
                                  : scope.row.shoppingCartItemType ===
                                    "FULL_GIFT"
                                  ? _c(
                                      "span",
                                      { staticClass: "discounts-tag" },
                                      [_vm._v("自选套餐")]
                                    )
                                  : _c("span", { staticClass: "dapei" }, [
                                      _vm._v("【搭配套餐】"),
                                    ]),
                                _vm._v(
                                  " " + _vm._s(scope.row.activityNm) + " "
                                ),
                              ]),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "商品编码",
                  width: "70px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  "class-name": "visibility",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.isGroup
                          ? _c("div", [
                              _vm._v(" " + _vm._s(scope.row.productCode) + " "),
                            ])
                          : scope.row.recommend === "Y"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openDialogPackageVisible(
                                          scope.row.drugNm2
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("选套餐")]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { staticClass: "meal-right" },
                              [
                                scope.row.shoppingCartItemType !==
                                  "ADDITIONAL" &&
                                scope.row.shoppingCartItemType !==
                                  "SPECIAL_PRICE_PURCHASE" &&
                                scope.row.shoppingCartItemType !== "FULL_GIFT"
                                  ? _c("div", [
                                      _vm._v(" 套餐价： "),
                                      _c("span", { staticClass: "price" }, [
                                        _vm._v(
                                          " ¥" +
                                            _vm._s(scope.row.saleTotalPrice)
                                        ),
                                        _c("s", [
                                          _vm._v(
                                            "¥" +
                                              _vm._s(
                                                scope.row.originalTotalPrice
                                              )
                                          ),
                                        ]),
                                      ]),
                                    ])
                                  : scope.row.shoppingCartItemType ===
                                    "FULL_GIFT"
                                  ? _c("div", [
                                      _vm._v(" 活动价： "),
                                      _c("span", { staticClass: "price" }, [
                                        _vm._v(
                                          "¥" +
                                            _vm._s(
                                              _vm.setTotal(scope.row.children)
                                            )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                scope.row.shoppingCartItemType ===
                                  "FULL_GIFT" &&
                                (scope.row.children
                                  ? scope.row.children[0].isEditGiftPackage
                                  : "")
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-right": "80px" },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { size: "mini" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleEdit(
                                                  scope.row,
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("编辑")]
                                        ),
                                      ],
                                      1
                                    )
                                  : scope.row.shoppingCartItemType ===
                                      "FULL_GIFT" &&
                                    (scope.row.children
                                      ? !scope.row.children[0].isEditGiftPackage
                                      : "")
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-right": "80px" },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { size: "mini" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openDialoggiftsVisible(
                                                  scope.row.children,
                                                  scope.row.activityId,
                                                  scope.row.itemKey
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("保存")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { size: "mini" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleCancle(
                                                  scope.row,
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("取消")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                scope.row.shoppingCartItemType !==
                                  "ADDITIONAL" &&
                                scope.row.shoppingCartItemType !==
                                  "SPECIAL_PRICE_PURCHASE" &&
                                scope.row.shoppingCartItemType !== "FULL_GIFT"
                                  ? _c("el-input-number", {
                                      staticClass: "input",
                                      attrs: { min: 1, size: "mini" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleChange(scope.row)
                                        },
                                      },
                                      model: {
                                        value: scope.row.quantity,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "quantity", $$v)
                                        },
                                        expression: "scope.row.quantity",
                                      },
                                    })
                                  : _vm._e(),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openDeleteDialog(
                                          scope.row.itemKey
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "productNm",
                  "class-name": "visibility",
                  label: "商品名称",
                  width: "160px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.isGroup
                          ? _c(
                              "div",
                              { staticClass: "table-product-name" },
                              [
                                _c(
                                  "p",
                                  [
                                    scope.row.otcTypeNm &&
                                    scope.row.otcTypeNm !== "其他"
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "row-tag tag-otc",
                                            class: {
                                              jiaOtc:
                                                scope.row.otcTypeNm === "甲OTC",
                                              yiOtc:
                                                scope.row.otcTypeNm === "乙OTC",
                                              rxOtc:
                                                scope.row.otcTypeNm === "RX",
                                            },
                                          },
                                          [_vm._v(_vm._s(scope.row.otcTypeNm))]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: "/detail/" + scope.row.productId,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$util.getGoodsName(scope.row)
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c("tagList", {
                                  attrs: { taglist: scope.row.tagList },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "spec",
                  align: "center",
                  label: "规格",
                  width: "70px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "manufacturer",
                  align: "left",
                  label: "厂家(上市许可持有人)/产地",
                  width: "170px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "unit",
                  align: "center",
                  label: "单位",
                  width: "56px",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "rate",
                  align: "center",
                  label: "销项税",
                  width: "56px",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "stock",
                  align: "center",
                  label: "库存",
                  width: "60px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.stock && scope.row.recommend !== "Y"
                          ? _c(
                              "span",
                              {
                                class: {
                                  "inventory-warning":
                                    scope.row.stock == "<100" ||
                                    Number(scope.row.stock) < 100,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    Math.floor(scope.row.stock) ||
                                      scope.row.stock
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "最小购买倍数",
                  width: "90px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getRealMinBuyNum(scope.row) || "/") +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "采购数量",
                  width: "112px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.shoppingCartItemType !== "NORMAL" &&
                        scope.row.shoppingCartItemType !== "ADDITIONAL" &&
                        scope.row.shoppingCartItemType !==
                          "SPECIAL_PRICE_PURCHASE" &&
                        scope.row.recommend !== "Y" &&
                        scope.row.shoppingCartItemType !== "PRESENT"
                          ? _c("div", [
                              _vm._v(" " + _vm._s(scope.row.quantity) + " "),
                            ])
                          : (scope.row.shoppingCartItemType === "NORMAL" &&
                              scope.row.isMainProduct !== "Y") ||
                            scope.row.shoppingCartItemType ===
                              "SPECIAL_PRICE_PURCHASE" ||
                            scope.row.shoppingCartItemType === "ADDITIONAL"
                          ? _c("el-input-number", {
                              attrs: {
                                size: "mini",
                                precision: _vm.getDecimalPointNum(scope.row),
                                min: _vm.getRealMinBuyNum(scope.row),
                                step: _vm.getRealMinBuyNum(scope.row),
                                "step-strictly": "",
                              },
                              on: {
                                focus: function ($event) {
                                  return _vm.changeNum(true)
                                },
                                blur: function ($event) {
                                  return _vm.changeNum(false)
                                },
                                change: function ($event) {
                                  return _vm.handleChange(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.quantity,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "quantity", $$v)
                                },
                                expression: "scope.row.quantity",
                              },
                            })
                          : _vm._e(),
                        scope.row.shoppingCartItemType === "PRESENT" &&
                        scope.row.isMainProduct === "N"
                          ? _c("div", [
                              _vm._v(" " + _vm._s(scope.row.quantity) + " "),
                            ])
                          : scope.row.shoppingCartItemType === "NORMAL" &&
                            scope.row.isMainProduct === "Y"
                          ? _c("el-input-number", {
                              attrs: {
                                size: "mini",
                                disabled: scope.row.isEditGiftPackage,
                                precision: _vm.getDecimalPointNum(scope.row),
                                min: _vm.getRealMinBuyNum(scope.row),
                                step: _vm.getRealMinBuyNum(scope.row),
                                "step-strictly": "",
                              },
                              on: {
                                focus: function ($event) {
                                  return _vm.changeNum(true)
                                },
                                blur: function ($event) {
                                  return _vm.changeNum(false)
                                },
                              },
                              model: {
                                value: scope.row.quantity,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "quantity", $$v)
                                },
                                expression: "scope.row.quantity",
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "right",
                  label: "单价",
                  "min-width": "75px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.salePrice !== scope.row.originalUnitPrice
                          ? _c("div", [
                              _vm._v(
                                " ¥" + _vm._s(scope.row.originalUnitPrice)
                              ),
                              _c("br"),
                              scope.row.byDiscount !== false
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "text-decoration": "line-through",
                                        color: "#999",
                                      },
                                    },
                                    [_vm._v("¥" + _vm._s(scope.row.salePrice))]
                                  )
                                : _vm._e(),
                            ])
                          : _c("div", [
                              _vm._v("¥" + _vm._s(scope.row.salePrice)),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "right", label: "小计", "min-width": "75px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticStyle: { color: "#FF3B30" } }, [
                          _vm._v(
                            "¥" +
                              _vm._s(
                                (scope.row.originalTotalPrice * 1).toFixed(2)
                              )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "expDate",
                  label: "最近效期",
                  "min-width": "80px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.expDate ? scope.row.expDate : "无")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "70",
                  label: "操作",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.shoppingCartItemType !==
                          "FIX_DISCOUNT_PACKAGE" &&
                        scope.row.shoppingCartItemType !==
                          "COLLOCATE_DISCOUNT_PACKAGE" &&
                        scope.row.isMainProduct !== "Y" &&
                        scope.row.shoppingCartItemType !== "PRESENT"
                          ? _c("div", { staticClass: "table-operation" }, [
                              _vm.userType !== "SALESMAN"
                                ? _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.starProduct(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.isCollect === "Y"
                                            ? "已收藏"
                                            : "收藏"
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.openDeleteDialog(
                                        scope.row.itemKey
                                      )
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ])
                          : !scope.row.isEditGiftPackage
                          ? _c("div", { staticClass: "table-operation" }, [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(
                                        scope.row,
                                        scope.$index
                                      )
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ])
                          : _c("div", [_vm._v("/")]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm.shopCart.itemList.length === 0
            ? _c("div", { staticClass: "empty-default" }, [
                _c(
                  "div",
                  { staticClass: "empty-box" },
                  [
                    _c("el-image", {
                      staticStyle: { width: "160px", height: "160px" },
                      attrs: { src: _vm.emptyDefault, fit: "fill" },
                    }),
                    _c("p", { staticClass: "empty-tip" }, [
                      _vm._v("购物车为空，你可以去首页逛逛~"),
                    ]),
                    _c(
                      "router-link",
                      { attrs: { to: "/" } },
                      [
                        _c(
                          "el-button",
                          { attrs: { type: "primary", size: "mini" } },
                          [_vm._v("去首页逛逛")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.shopCart.invalidItemList.length
            ? _c(
                "div",
                [
                  _c("p", { staticClass: "disableText" }, [
                    _vm._v("已失效商品"),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.onInvalidItem()
                          },
                        },
                      },
                      [_vm._v("清空失效商品")]
                    ),
                  ]),
                  _c(
                    "el-table",
                    {
                      ref: "disableTable",
                      staticClass: "disableTable",
                      attrs: {
                        "header-row-class-name": "header-row",
                        "span-method": _vm.spanMethodDisable,
                        border: "",
                        data: _vm.shopCart.invalidItemList,
                        "tree-props": {
                          children: "children",
                          hasChildren: "hasChildren",
                        },
                        "row-key": "rowKey",
                        "row-class-name": _vm.rowClassNameDisable,
                        "default-expand-all": "",
                      },
                      on: {
                        "update:data": function ($event) {
                          return _vm.$set(
                            _vm.shopCart,
                            "invalidItemList",
                            $event
                          )
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          "class-name": "index",
                          align: "center",
                          label: "序号",
                          width: "45",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row.number || 0)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3263923925
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          "class-name": "selection",
                          align: "center",
                          width: "45",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  !scope.row.isGroup
                                    ? _c("div", [_vm._v(" 失效 ")])
                                    : _c("div", [
                                        scope.row.shoppingCartItemType ===
                                          "ADDITIONAL" ||
                                        scope.row.shoppingCartItemType ===
                                          "SPECIAL_PRICE_PURCHASE"
                                          ? _c(
                                              "div",
                                              { staticClass: "selection" },
                                              [
                                                _c("el-checkbox", {
                                                  attrs: { disabled: true },
                                                  model: {
                                                    value: scope.row.selected,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "selected",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.selected",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "selection" },
                                              [
                                                _c("el-checkbox", {
                                                  attrs: { disabled: true },
                                                  model: {
                                                    value: scope.row.selected,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "selected",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.selected",
                                                  },
                                                }),
                                                _vm._l(
                                                  scope.row.itemDetailList,
                                                  function (item) {
                                                    return _c("span", {
                                                      staticClass: "span",
                                                    })
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          660758308
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "class-name": "padding",
                          label: "商品图",
                          align: "center",
                          width: "80px",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  !scope.row.isGroup
                                    ? _c(
                                        "div",
                                        { staticClass: "commodity-image" },
                                        [
                                          _c("img", {
                                            staticClass: "product-img",
                                            attrs: {
                                              src:
                                                (scope.row.productPicMap &&
                                                  scope.row.productPicMap[
                                                    "48X48"
                                                  ]) ||
                                                _vm.productDefaultImg,
                                              alt: "",
                                            },
                                            on: {
                                              error: function ($event) {
                                                scope.row.productPicMap[
                                                  "48X48"
                                                ] = _vm.productDefaultImg
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    : _c("div", { staticClass: "set-meal" }, [
                                        _c(
                                          "div",
                                          { staticClass: "meal-center" },
                                          [
                                            scope.row.shoppingCartItemType ===
                                            "FIX_DISCOUNT_PACKAGE"
                                              ? _c(
                                                  "span",
                                                  { staticClass: "fixed" },
                                                  [_vm._v("【固定套餐】")]
                                                )
                                              : scope.row
                                                  .shoppingCartItemType ===
                                                "ADDITIONAL"
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "discounts-tag",
                                                  },
                                                  [_vm._v("超值换购")]
                                                )
                                              : scope.row
                                                  .shoppingCartItemType ===
                                                "SPECIAL_PRICE_PURCHASE"
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "discounts-tag",
                                                  },
                                                  [_vm._v("特价换购")]
                                                )
                                              : scope.row
                                                  .shoppingCartItemType ===
                                                "RECOMMEND_PACKAGE_PACKAGE_ACTIVITY"
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "discounts-tag",
                                                  },
                                                  [_vm._v("推荐套餐")]
                                                )
                                              : _c(
                                                  "span",
                                                  { staticClass: "dapei" },
                                                  [_vm._v("【搭配套餐】")]
                                                ),
                                            _vm._v(
                                              " " +
                                                _vm._s(scope.row.activityNm) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          315925099
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "productCode",
                          label: "商品编码",
                          width: "70px",
                          align: "center",
                          "class-name": "visibility",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  !scope.row.isGroup
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row.productCode) +
                                            " "
                                        ),
                                      ])
                                    : _c(
                                        "div",
                                        { staticClass: "meal-right" },
                                        [
                                          scope.row.shoppingCartItemType !==
                                            "ADDITIONAL" &&
                                          scope.row.shoppingCartItemType !==
                                            "SPECIAL_PRICE_PURCHASE"
                                            ? _c(
                                                "div",
                                                [
                                                  _vm._v(" 套餐价： "),
                                                  _c(
                                                    "span",
                                                    { staticClass: "price" },
                                                    [
                                                      _vm._v(
                                                        " ¥" +
                                                          _vm._s(
                                                            scope.row
                                                              .saleTotalPrice
                                                          )
                                                      ),
                                                      _c("s", [
                                                        _vm._v(
                                                          "¥" +
                                                            _vm._s(
                                                              scope.row
                                                                .originalTotalPrice
                                                            )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c("el-input-number", {
                                                    staticClass: "input",
                                                    attrs: {
                                                      size: "mini",
                                                      disabled: true,
                                                    },
                                                    model: {
                                                      value: scope.row.quantity,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "quantity",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.quantity",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { size: "mini" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openDeleteDialog(
                                                    scope.row.itemKey,
                                                    "disable"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ],
                                        1
                                      ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1157047581
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "productNm",
                          "class-name": "visibility",
                          label: "商品名称",
                          width: "160px",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  !scope.row.isGroup
                                    ? _c(
                                        "div",
                                        { staticClass: "table-product-name" },
                                        [
                                          _c("p", [
                                            scope.row.otcTypeNm &&
                                            scope.row.otcTypeNm !== "其他"
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "row-tag tag-otc",
                                                    class: {
                                                      jiaOtc:
                                                        scope.row.otcTypeNm ===
                                                        "甲OTC",
                                                      yiOtc:
                                                        scope.row.otcTypeNm ===
                                                        "乙OTC",
                                                      rxOtc:
                                                        scope.row.otcTypeNm ===
                                                        "RX",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row.otcTypeNm
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$util.getGoodsName(
                                                    scope.row
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c("tagList", {
                                            attrs: {
                                              taglist: scope.row.tagList,
                                            },
                                          }),
                                          _vm.userType == "SALESMAN"
                                            ? _c("span", [
                                                _vm._v(
                                                  " 失效原因:" +
                                                    _vm._s(
                                                      scope.row.invalidReason
                                                    ) +
                                                    " "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2524451886
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "spec",
                          align: "center",
                          label: "规格",
                          width: "70px",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "manufacturer",
                          align: "left",
                          label: "厂家(上市许可持有人)/产地",
                          width: "170px",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "unit",
                          align: "center",
                          label: "单位",
                          width: "56px",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "rate",
                          align: "center",
                          label: "销项税",
                          width: "56px",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "stock",
                          align: "center",
                          label: "库存",
                          width: "60px",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.stock && scope.row.stock == "<100"
                                    ? _c(
                                        "span",
                                        { staticClass: "inventory-warning" },
                                        [_vm._v("<100")]
                                      )
                                    : scope.row.stock &&
                                      Number(scope.row.stock) < 100
                                    ? _c(
                                        "span",
                                        { staticClass: "inventory-warning" },
                                        [_vm._v(_vm._s(scope.row.stock))]
                                      )
                                    : _c("span", [
                                        _vm._v(_vm._s(scope.row.stock)),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3051117860
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "minBuyMultiple",
                          align: "center",
                          label: "最小购买倍数",
                          width: "90px",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getRealMinBuyNum(scope.row) || "/"
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2773808035
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "采购数量",
                          width: "112px",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.shoppingCartItemType !== "NORMAL" &&
                                  scope.row.shoppingCartItemType !==
                                    "ADDITIONAL" &&
                                  scope.row.shoppingCartItemType !==
                                    "SPECIAL_PRICE_PURCHASE"
                                    ? _c("div", [
                                        _vm._v(
                                          " " + _vm._s(scope.row.quantity) + " "
                                        ),
                                      ])
                                    : _c("el-input-number", {
                                        attrs: { disabled: true, size: "mini" },
                                        model: {
                                          value: scope.row.quantity,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "quantity", $$v)
                                          },
                                          expression: "scope.row.quantity",
                                        },
                                      }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          989512647
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "salePrice",
                          align: "right",
                          label: "单价",
                          "min-width": "75px",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "right",
                          label: "小计",
                          "min-width": "75",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#FF3B30" } },
                                    [
                                      _vm._v(
                                        "¥" +
                                          _vm._s(
                                            (
                                              scope.row.originalTotalPrice * 1
                                            ).toFixed(2)
                                          )
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1926199483
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "expDate",
                          label: "最近效期",
                          "min-width": "80px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.expDate
                                          ? scope.row.expDate
                                          : "无"
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2723546435
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          width: "70",
                          label: "操作",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.shoppingCartItemType !==
                                    "FIX_DISCOUNT_PACKAGE" &&
                                  scope.row.shoppingCartItemType !==
                                    "COLLOCATE_DISCOUNT_PACKAGE"
                                    ? _c(
                                        "div",
                                        { staticClass: "table-operation" },
                                        [
                                          _vm.userType !== "SALESMAN"
                                            ? _c(
                                                "span",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.starProduct(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.isCollect ===
                                                        "Y"
                                                        ? "已收藏"
                                                        : "收藏"
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openDeleteDialog(
                                                    scope.row.itemKey,
                                                    "disable"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ]
                                      )
                                    : _c("div", [_vm._v("/")]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2549862461
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.dialogDeleteVisible,
                title: "提示",
                width: "400px",
                top: "30vh",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogDeleteVisible = $event
                },
              },
            },
            [
              _c("img", {
                staticClass: "icon",
                attrs: {
                  src: require("@/assets/img/customer-service/icon-shanchu.png"),
                  alt: "",
                },
              }),
              _vm.itemKey === "all"
                ? _c("p", { staticStyle: { padding: "10px 0 20px" } }, [
                    _vm._v("确认清空购物车？"),
                  ])
                : _c("p", { staticStyle: { padding: "10px 0 20px" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.itemKey ? "确认删除此商品？" : "确认删除所选商品？"
                      )
                    ),
                  ]),
              _c(
                "el-button",
                {
                  staticClass: "agree",
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteRow(_vm.itemKey)
                    },
                  },
                },
                [_vm._v("确认")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "agree",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.dialogDeleteVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: { "background-color": "#fff", "padding-bottom": "10px" },
        },
        [
          _c("div", { staticClass: "settlement-container" }, [
            _c("div", { staticClass: "settlement-row" }, [
              _c(
                "div",
                { staticClass: "row-left" },
                [
                  _vm.shopCart.itemList.length
                    ? _c(
                        "el-checkbox",
                        {
                          class:
                            _vm.checked && _vm.notButton
                              ? "disabled-checkbox"
                              : "",
                          attrs: { disabled: _vm.notButton },
                          on: { change: _vm.toggleAllSelection },
                          model: {
                            value: _vm.checked,
                            callback: function ($$v) {
                              _vm.checked = $$v
                            },
                            expression: "checked",
                          },
                        },
                        [_vm._v("全选")]
                      )
                    : _vm._e(),
                  _vm.shopCart.itemList.length
                    ? _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.openDeleteDialog()
                            },
                          },
                        },
                        [_vm._v("删除选中商品")]
                      )
                    : _vm._e(),
                  _vm.shopCart.itemList.length
                    ? _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.openDeleteDialog("all")
                            },
                          },
                        },
                        [_vm._v("清空购物车")]
                      )
                    : _vm._e(),
                  _c("router-link", { attrs: { to: "/" } }, [
                    _c("span", [_vm._v("返回首页")]),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "row-right" }, [
                _c("div", { staticClass: "settlement" }, [
                  _c("div", { staticClass: "total-money" }, [
                    _vm._v(" 商品总金额： "),
                    _c("span", { staticClass: "money" }, [
                      _vm._v(
                        "¥" +
                          _vm._s(
                            _vm.$util.toFixed(_vm.shopCart.productTotalAmount)
                          )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "product-detail" }, [
                    _vm.shopCart.presentTotalQuantity > 0
                      ? _c(
                          "span",
                          [
                            _vm._v(
                              "特殊商品：" +
                                _vm._s(_vm.shopCart.presentTotalQuantity) +
                                "件"
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openDialogPresentVisible()
                                  },
                                },
                              },
                              [_vm._v("查看特殊商品")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("span", [
                      _vm._v(
                        "商品种类：" +
                          _vm._s(_vm.shopCart.productTypeTotalQuantity) +
                          "种"
                      ),
                    ]),
                    _c("span", [
                      _vm._v(
                        "总件数：" + _vm._s(_vm.shopCart.productTotalQuantity)
                      ),
                    ]),
                    _c("span", [
                      _vm._v(
                        "赠送积分：" + _vm._s(_vm.shopCart.obtainTotalIntegral)
                      ),
                    ]),
                    _c("span", [
                      _vm._v(
                        "促销优惠减免：-¥" + _vm._s(_vm.shopCart.discountAmount)
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "settlement-button-group" }, [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.notButton,
                          expression: "notButton",
                        },
                      ],
                      staticClass: "immediate-settlement",
                      class: {
                        disable:
                          _vm.shopCart.enableSubmit === "N" ||
                          _vm.selectItem.length == 0,
                      },
                      attrs: { disabled: _vm.notButton },
                      on: { click: _vm.handleCollateOrder },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.shopCart.enableSubmit === "N"
                            ? `差${_vm.shopCart.balance}元起送`
                            : "立即结算"
                        ) + " "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _c("swiper", { staticClass: "recommend-swiper" }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "以下商品库存不足，为您自动调整购买数量",
            visible: _vm.showLessStockModal,
            width: "800px",
            "before-close": _vm.hideDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showLessStockModal = $event
            },
          },
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.lessStockShops, "max-height": "500px" } },
            [
              _c("el-table-column", {
                attrs: { label: "序号", align: "center", prop: "id" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "productNm",
                  label: "商品名称",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.$util.getGoodsName(scope.row)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "productCode",
                  label: "商品编号",
                },
              }),
              _vm.userType !== "BUYERS"
                ? _c("el-table-column", {
                    attrs: {
                      prop: "stockQuantity",
                      align: "center",
                      label: "库存",
                    },
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { align: "center", label: "调整前数量" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.beforeChangeLessCart.find(
                                (item) => item.productId === scope.row.productId
                              ).quantity
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "quantity",
                  align: "center",
                  label: "调整后数量",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showLessStockModal = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("giftsDialog", {
        ref: "giftsDialogRef",
        on: { success: _vm.getCart },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
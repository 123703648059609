<template>
  <im-drawer
    class="input-excel-drawer-page"
    v-if="inputExcelVisible"
    :visible.sync="inputExcelVisible"
    :title="title"
    :size="size"
    :modal="false"
    :append-to-body="true"
    @confirm="handleconfirm"
    @cancel="closeConfirm"
  >
    <el-form ref="form" :rules="formRules"  :model="form" label-width="160px" class="form" >
        <el-form-item label="选择匹配方式：" prop="way">
            <el-radio-group v-model="form.way">
             <el-radio label="INQUIRYBOXDETAILPM">品名+规格+厂家</el-radio>
             <el-radio label="INQUIRYBOXDETAILGY">国药准字</el-radio><br>
             <el-radio label="INQUIRYBOXDETAILSP">商品条形码(69码)</el-radio>
             <!-- <el-radio label="INQUIRYBOXDETAILPT">平台商品编码</el-radio><br> -->
             <el-radio label="INQUIRYBOXDETAILKH">ERP商品编码</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="下载Excel模板：">
             <span style="margin-right:10px">{{typeName}}</span> 
             <el-button size="mini" @click="handleDownExcel"> 下载模板</el-button>
        </el-form-item>
        <!-- <el-form-item label="上传模板类型：" prop="fileType">
              <el-select v-model="form.fileType" placeholder="请选择">
              <el-option
                v-for="item in fileTypeOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
        </el-form-item> -->
        <el-form-item label="上传文件：">
             <el-upload
                class="upload-demo"
                ref="upload"
                accept=".xls,.xlsx"
                :headers="headers"
                v-if="!isUploading"
                :limit="1"
                :action="uploadUrl"
                :data="uploadParams"
                :on-exceed="onExceed"
                :on-success="onSuccess"
                :on-progress="uploadProgress"
                :on-error="onError"
                :on-change="handleChange"
                :auto-upload="false"
                :file-list="fileList">
                 <el-button size="small" type="primary">上传Excel文件</el-button>
                <div slot="tip" class="el-upload__tip">为避免导入失败，请下载最新模板后导入</div>
             </el-upload>
             <template v-if="isUploading" >
                <el-progress class="progress" :stroke-width="20" :percentage="uploadProgressLen" :status="uploadStatus"></el-progress>
                <div class="el-upload__tip">为避免导入失败，请下载最新模板后导入</div>
             </template>
         </el-form-item>
     </el-form>
  </im-drawer>
</template>
<script>
import formRules from '@/utils/validate'
import defaultSettings from '@/settings'
import { mapGetters } from 'vuex'
import {exportTemplate} from '@/api/member-center/transactions-using'
export default {
  name: 'InputExcelDrawer',
  props: {
    visibleSuccess: {
      type: Boolean,
      default: false
    },
    visibleFail:{
      type: Boolean,
      default: false
    },
    tableData:{
      type:Array,
      default:()=>{
         return []
      }
    },
    resData:{
       type:Object,
       default: ()=>{
          return {}
       }
    }
  },
  data() {
    return {
      title: 'Excel批量导入询价',
      inputExcelVisible: false,
      size: '30%',
      uploadStatus:undefined,
      uploadProgressLen: 0 ,
      isUploading:false,
      fileList:[],
      // fileTypeOptions:[
      //   {name:'品名+规格+厂家',value:'INQUIRYBOXDETAILSP'},
      //   {name:'客户ERP商品编码',value:'INQUIRYBOXDETAILKH'},
      //   {name:'国药准字',value:'INQUIRYBOXDETAILGY'},
      //   {name:'平台商品编码',value:'INQUIRYBOXDETAILPT'},
      //   {name:'商品条形码(69码)',value:'INQUIRYBOXDETAILSP'},
      //   ],
      uploadUrl:`/api/${defaultSettings.businessPre}/pc/inquiryBoxDetail/uploadFiles`,
      form:{
         way:'INQUIRYBOXDETAILPM',
        //  fileType:''
      },
      formRules:{
         way: [formRules.blurRes('匹配方式')],
        //  fileType:[formRules.blurRes('上传文件模板类型')]
      }
     }
  },
  watch:{
     inputExcelVisible(newVal){
        if(newVal === true){
           this.form = {
            way:'INQUIRYBOXDETAILPM',
            fileType:''
          }
          this.fileList = []
        }
     }
  },
  computed:{
     ...mapGetters([
      'token'
     ]),
      headers() {
      return {
        'Client-API-Version': 1,
        'x-access-token-header': this.token
      }
    },
    //上传文件传参
    uploadParams() {
      return {
        // file: this.fileList[0] ? this.fileList[0].name :'',
        fileType: this.form.way
      }
    },
    typeName() {
      const typeNameMap = {
        INQUIRYBOXDETAILGY:'国药准字',
        INQUIRYBOXDETAILKH: 'ERP商品编码',
        INQUIRYBOXDETAILPM: '品名+规格+厂家',
        // INQUIRYBOXDETAILPT:"平台商品编码",
        INQUIRYBOXDETAILSP:"商品条形码(69码)"
      }
       return typeNameMap[this.form.way]
    }
  },
  methods: {
    open() {
      this.inputExcelVisible = true
    },
     handleChange(file, fileList) {
        this.fileList = fileList.slice(-1);
    },

    //上传进度
    uploadProgress(e){
      if(e.lengthComputable){
          this.uploadProgressLen = Math.floor((e.loaded / e.total) * 100 ) >= 100 ? 99 : Math.floor((e.loaded / e.total) * 100 )
      }
    },
    uploadError(res){
      setTimeout(()=>{
          this.uploadStatus = 'exception'
          setTimeout(()=>{
              this.isUploading = false
              this.$emit('uploadResult',false)
              this.$emit('update:visibleFail',true)
              this.$emit('update:formWay',this.form.way)
              this.inputExcelVisible = false
          },500)
      },800)
    },
    uploadSuccess(res){
      setTimeout(()=>{
          this.uploadStatus = 'success'
          this.uploadProgressLen = 100
          setTimeout(()=>{
              this.isUploading = false
              this.$emit('update:visibleSuccess',true)
              this.$emit('update:tableData',JSON.parse(JSON.stringify(res.data.list)))
              this.$emit('update:resData',res.data)
              this.$emit('update:formWay',this.form.way)
              this.$emit('uploadResult',true)
              this.inputExcelVisible = false
          },500)
      },800)
    },
    handleDownExcel(){
        exportTemplate(this.form.way).then( res =>{
          let blob = new Blob([res.data], {type :'application/vnd.ms-excel'})
          const disposition = res.headers['content-disposition'].split(";")[1].split("=")[1];
          var fileName = decodeURI(disposition);
          //去掉双引号
          fileName = fileName.replace(/\"/g, "");
          let link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          link.remove()
          this.$message.success('下载成功')
        })
    },
    onExceed(){
      this.$message.error('只能上传一个文件')
      this.inputExcelVisible = false
    },
    onSuccess(res, file, fileList) {
      if(res.code === '0' && !!res.data){
        this.uploadSuccess(res)
      }else{
        this.uploadError()
      }
    },
    onError(err, file, fileList) {
       this.$emit('update:visibleFail',true)
       this.uploadError()
    },
    // 确定
    handleconfirm() {
       this.$refs.form.validate(valid => {
          if (valid){
            this.isUploading = true
            this.uploadStatus = undefined
            this.$refs.upload.submit();
          }
        })
        // this.$refs.upload.submit();
        // this.inputExcelVisible = false
    },
    closeConfirm() {
      this.inputExcelVisible = false
    }
  }
}
</script>
<style lang="scss">
.input-excel-drawer-page{
    /deep/{
        .el-drawer{
            width: 450px!important;
        }
    }
    .el-form{
        .el-form-item__content{
            text-align:left;
        }
       .el-radio{
           display:inline-block;
           height:40px;
           line-height:40px;
       }
    }
}
</style>
<style scoped lang="scss">
 .form{
     padding:16px;
     .el-radio{
         display:inline-block;
         height:100%;
     }
     .btn-download{
         border: 1px solid #1890ff;
         padding:3px 5px;
     }
 }
 .progress{
     margin-top: 10px;
     /deep/.el-progress__text{
         font-size: 16px !important;
     }
 }
</style>

<template>
  <div class="brand-detail-content">
    <Search :index-nav="true"/>
    <div class="nav-address">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/brand-center' }">品牌旗舰店</el-breadcrumb-item>
        <el-breadcrumb-item>品牌详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 广告 -->
    <div class="advert-content">
      <div class="infor">
        <div class="w-1200 info-posi">
          <div class="info-logo"><img :src="brandInfo.brandLogoStr || brandDefaultImg" @error="brandInfo.brandLogoStr = brandDefaultImg"/></div>
          <div class="info-content" ref="brandInfo">
            <p>{{ brandInfo.brandNm }}</p>
            <div class="info-tips" ref="brandInfoTips">
              <span :class="{ 'expend-content': expendArrow }">
                {{ brandInfo.brandStory }}
                <span v-show="infoTips" :class="{ 'expend-arrow': !expendArrow, 'arrow': expendArrow }"
                      @click="expendArrow = !expendArrow"><img
                    src="@/assets/img/promotion-zone/brand-center/arrow-white-bottom.png"/></span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="advert-banner" v-if="page['PC_SHOP_ADV_BANNER1'].length">
        <swiper :options="swiperOption">
          <swiper-slide v-for="(item, index) in page['PC_SHOP_ADV_BANNER1']" :key="index">
            <img :src="item.sysFileUrl" @click="bindUrl(item.link)" alt/>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination"></div>
        <div class="swiper-button">
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
      </div>
      <div v-else class="seize"></div>
    </div>
    <div class="advert-space">
      <div class="w-1200">
        <div class="space-odei" v-if="page['PC_SHOP_ADV_BANNER_TOP1'].length > 0">
          <div class="space-list space-one"><router-link :to="page['PC_SHOP_ADV_BANNER_TOP1'][0].link"><img :src="page['PC_SHOP_ADV_BANNER_TOP1'][0].sysFileUrl"/></router-link></div>
        </div>
        <div class="space-odei" v-if="page['PC_SHOP_ADV_BANNER_TOP2'].length > 0 || page['PC_SHOP_ADV_BANNER_TOP3'].length > 0">
          <div class="space-list space-two" v-if="page['PC_SHOP_ADV_BANNER_TOP2'].length > 0">
            <router-link :to="page['PC_SHOP_ADV_BANNER_TOP2'][0].link"><img :src="page['PC_SHOP_ADV_BANNER_TOP2'][0].sysFileUrl" /></router-link>
          </div>
          <div class="space-list space-two" v-if="page['PC_SHOP_ADV_BANNER_TOP3'].length > 0">
            <router-link :to="page['PC_SHOP_ADV_BANNER_TOP3'][0].link"><img :src="page['PC_SHOP_ADV_BANNER_TOP3'][0].sysFileUrl" /></router-link>
          </div>
        </div>
        <div class="space-odei" v-if="page['PC_SHOP_ADV_BANNER_TOP4'].length > 0 || page['PC_SHOP_ADV_BANNER_TOP5'].length > 0 || page['PC_SHOP_ADV_BANNER_TOP6'].length > 0 || page['PC_SHOP_ADV_BANNER_TOP7'].length > 0">
          <div class="space-list space-three" v-if="page['PC_SHOP_ADV_BANNER_TOP4'].length > 0">
            <router-link :to="page['PC_SHOP_ADV_BANNER_TOP4'][0].link"><img :src="page['PC_SHOP_ADV_BANNER_TOP4'][0].sysFileUrl" /></router-link>
          </div>
          <div class="space-list space-three" v-if="page['PC_SHOP_ADV_BANNER_TOP5'].length > 0">
            <router-link :to="page['PC_SHOP_ADV_BANNER_TOP4'][0].link"><img :src="page['PC_SHOP_ADV_BANNER_TOP5'][0].sysFileUrl"/></router-link>
          </div>
          <div class="space-list space-three" v-if="page['PC_SHOP_ADV_BANNER_TOP6'].length > 0">
            <router-link :to="page['PC_SHOP_ADV_BANNER_TOP4'][0].link"><img :src="page['PC_SHOP_ADV_BANNER_TOP6'][0].sysFileUrl" /></router-link>
          </div>
          <div class="space-list space-three" v-if="page['PC_SHOP_ADV_BANNER_TOP7'].length > 0">
            <router-link :to="page['PC_SHOP_ADV_BANNER_TOP4'][0].link"><img :src="page['PC_SHOP_ADV_BANNER_TOP7'][0].sysFileUrl" /></router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="product-content">
      <category-search ref="categorySearch" :view-model="viewModel" @change-page="handleChangViewModel" :searchForm="searchForm" @search="queryData"/>
      <list-board :table-data="tableData" :is-loading="isLoading" v-if="viewModel==='ListBoard'"/>
      <photo-board :table-data="tableData" :is-loading="isLoading" v-if="viewModel==='PhotoBoard'"/>
      <pagination :isOnlyOnePageShow='!total>0' :total="total" :current-page.sync="currentPage" :page-size.sync="pageSize"
                  @pagination="handlePagination"/>
    </div>
  </div>
</template>

<script>
import Search from '@/components/Search/Search'
import CategorySearch from '@/views/commodity-list/components/category-search'
import ListBoard from '@/views/commodity-list/components/list-board'
import PhotoBoard from '@/views/commodity-list/components/photo-board'
import Pagination from '@/views/commodity-list/components/pagination'
import {Swiper, SwiperSlide, directive} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { pageBrandProduct, getBrandById } from '@/api/promotion-zone/brand-center'
import {getPageModuleMap} from '@/api/index/index'
import brandDefaultImg from '@/assets/img/index/brand_default.png'
import { getBus } from '@/utils/auth'
import { pageIndexProduct } from '@/api/commodity-list'
export default {
  components: {
    Search,
    CategorySearch,
    PhotoBoard,
    Pagination,
    ListBoard,
    Swiper,
    SwiperSlide,
    directive
  },
  data() {
    return {
      companyData: [],
      num: 1,
      tableData: [],
      viewModel: 'ListBoard',
      pageSize: 10,
      currentPage: 1,
      total: 10,
      // isOnlyOnePageShow:true,
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      expendArrow: true,
      page: {
        'PC_SHOP_ADV_BANNER1':[],
        'PC_SHOP_ADV_BANNER_TOP1':[],
        'PC_SHOP_ADV_BANNER_TOP2':[],
        'PC_SHOP_ADV_BANNER_TOP3':[],
        'PC_SHOP_ADV_BANNER_TOP4': [],
        'PC_SHOP_ADV_BANNER_TOP5':[],
        'PC_SHOP_ADV_BANNER_TOP6':[],
        'PC_SHOP_ADV_BANNER_TOP7':[]
      },
      brandInfo: {},
      infoTips: true,
      brandDefaultImg,
      isLoading: false,
      searchForm: {
        searchFiled: '',
        categoryId: '',
        orgId: getBus().orgId,
        stockCondition: '',
        otcType: '',
        isOnSale: 'Y',
        brandId: this.$route.params.id
      },
    }
  },
  mounted() {
    getPageModuleMap({
      innerCodes: ["PC_SHOP_ADV_BANNER1",
        "PC_SHOP_ADV_BANNER_TOP1",
        "PC_SHOP_ADV_BANNER_TOP2",
        "PC_SHOP_ADV_BANNER_TOP3",
        "PC_SHOP_ADV_BANNER_TOP4",
        "PC_SHOP_ADV_BANNER_TOP5",
        "PC_SHOP_ADV_BANNER_TOP6",
        "PC_SHOP_ADV_BANNER_TOP7"
      ],
      brandId: this.$route.params.id
    }).then(res => {
      this.page = res.data
    })
    getBrandById(this.$route.params.id).then(res => {
      this.brandInfo = res.data
    })
  },
  watch:{
    page () {
      this.$nextTick(_=>{
        this.infoTips = this.$refs.brandInfoTips.clientWidth >= 1000
        this.$refs.categorySearch.isExpand0 = false
      })
    },
    $route: {
      handler (route) {
        if (route.query.searchData) {
          this.searchForm.searchFiled = route.query.searchData
        }
        if (route.query.categoryId) {
          this.searchForm.categoryId = route.query.categoryId
        }
        if (route.query.categoryCode) {
          this.searchForm.categoryCode = route.query.categoryCode
        }
        this.queryData()
      },
      immediate: true
    }
  },
  methods: {
    bindUrl (path) {
      if (path.substr(0, 1) === '/') {
        this.$router.push({ path })
      } else {
        window.open(path, '_blank')
      }
    },
    checkDetail() {
      this.$router.push('/brand-detail')
    },
    handleChangViewModel(view) {
      this.viewModel = view.viewModel
    },
    handlePagination(data) {
      this.currentPage = Math.min(this.currentPage, Math.ceil(this.total / data.pageSize))
      this.queryData()
    },
    queryData () {
        this.isLoading = true
        pageIndexProduct({
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          condition: this.searchForm
        }).then(res => {
          const { data } = res
          if (data && data.records) {
            this.total = data.total
            data.records.forEach(item => {
              for(let key in item){
                if(item[key] === null) {
                  item[key] = '/'
                }
              }
              item.count = 1
            })
            this.tableData = data.records
          }
        }).finally(e=>{
          this.isLoading = false
        })
      },
  }
}
</script>

<style lang="scss">
.brand-detail-content {
  .nav-address {
    width: 1200px;
    margin: 0 auto;
    padding: 0 20px;

    .el-breadcrumb {
      height: 50px;
      line-height: 50px;
    }
  }

  .w-1200 {
    width: 1200px;
    margin: 0 auto;
  }

  .advert-content {
    text-align: left;
    position: relative;
    min-width: 1200px;

    .infor {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      background: rgba(21, 29, 82, 0.8);
      padding: 16px 0;

      .info-posi {
        padding: 0 32px;
        display: flex;

        .info-logo {
          width: 88px;
          min-width: 88px;
          height: 48px;
          background: #fff;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .info-content {
          color: #fff;
          padding-left: 16px;

          p {
            font-size: 20px;
            font-weight: bold;
            padding-top: 2px;
            line-height: 22px;
            padding-bottom: 4px;
          }

          span {
            font-size: 12px;
            font-weight: 500;
            display: inline-block;
            line-height: 18px;
            transition: all .3s ease-out;
          }

          .info-tips {
            position: relative;
          }

          .arrow {
            position: absolute;
            right: -8px;
            bottom: 4px;
            cursor: pointer;
          }

          .expend-content {
            overflow: hidden;
            white-space: nowrap;
            max-width: 1000px;
            text-overflow: ellipsis;
          }

          .expend-arrow {
            transform: rotate(-180deg);
            cursor: pointer;
          }
        }
      }
    }

    .advert-banner {
      height: 500px;
      width: 100%;
      position: relative;

      .swiper-container {
        height: 100%;

        .swiper-slide {
          overflow: hidden;
          display: flex;
          justify-content: center;
        }
      }

      .swiper-button {
        max-width: 1200px;
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 50%;

        .swiper-button-next {
          height: 70px;
          background: url("../../../assets/img/promotion-zone/brand-center/arrow-swiper-right.png") no-repeat;

          &:after {
            content: '';
          }
        }

        .swiper-button-prev {
          height: 70px;
          background: url("../../../assets/img/promotion-zone/brand-center/arrow-swiper-left.png") no-repeat;

          &:after {
            content: '';
          }
        }
      }

      .swiper-pagination {
        bottom: 20px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .swiper-pagination-bullet {
          width: 12px;
          height: 12px;
          margin: 0 8px;
          background: rgba(255, 255, 255, 1);
          border: 1px solid rgba(102, 102, 102, 1);
          opacity: 0.4;
        }

        .swiper-pagination-bullet-active {
          width: 18px;
          height: 18px;
          position: relative;
          background: rgba(255, 255, 255, 0.2);
          opacity: 1;
          border: 0;

          &:after {
            position: absolute;
            content: '';
            width: 12px;
            height: 12px;
            background: #fff;
            left: 50%;
            top: 50%;
            margin-top: -6px;
            margin-left: -6px;
            border-radius: 50%;
          }
        }
      }

      img {
        width: 1920px;
        height: 100%;
      }
    }
    .seize{
      height: 80px;
    }
  }

  .advert-space {
    background: url("../../../assets/img/promotion-zone/brand-center/advert-space-bg.png") no-repeat;
    background-size: 100% 100%;
    padding-bottom: 60px;

    .space-odei {
      padding-top: 20px;
      display: flex;
      justify-content: space-between;

      .space-list {
        padding: 8px;
        background: linear-gradient(135deg, rgba(252, 253, 248, 1) 0%, rgba(253, 236, 219, 1) 100%);
        border-radius: 8px;

        img {
          height: 284px;
          width: 100%;
        }
      }
      .space-one{
        img{
          width: 1184px;
        }
      }
      .space-two{
        img{
          width: 574px;
        }
      }
      .space-three{
        img{
          width: 269px;
        }
      }

      .space-three {
        img {
          height: 269px;
        }
      }

      .space-tow {

      }
    }
  }

  .product-content {
    width: 1200px;
    margin: -40px auto 0;
    box-shadow: 0px 3px 12px 0px rgba(34, 34, 34, 0.15);
    border-radius: 8px;
    overflow: hidden;
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
    },
    [
      _c(
        "ul",
        { staticClass: "photo-board-items" },
        _vm._l(_vm.tableData, function (item, index) {
          return _c(
            "li",
            {
              key: index,
              class: [
                "commodity-item",
                { "expDate-row": item.isSkuInfoRed === "Y" },
              ],
            },
            [
              _c("div", { staticClass: "commodity-image" }, [
                _c("img", {
                  attrs: { src: item.picUrl || _vm.productDefaultImg, alt: "" },
                  on: {
                    error: function ($event) {
                      item.picUrl = _vm.productDefaultImg
                    },
                  },
                }),
                item.isHistoryBuyProduct === "Y"
                  ? _c("div", { staticClass: "purchase-history-tag" }, [
                      _vm._v("历史购买"),
                    ])
                  : _vm._e(),
                item.stockQuantity === 0
                  ? _c("div", { staticClass: "sale-out-mark" }, [
                      _vm._v("已售罄"),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticClass: "commodity-presentation" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "commodity-name",
                      on: {
                        click: function ($event) {
                          return _vm.jumpDetail(item.productId)
                        },
                      },
                    },
                    [
                      item.otcType && item.otcType !== "其他"
                        ? _c(
                            "span",
                            {
                              staticClass: "name-tag",
                              class: {
                                jiaOtc: item.otcType === "甲OTC",
                                yiOtc: item.otcType === "乙OTC",
                                rxOtc: item.otcType === "RX",
                              },
                            },
                            [_vm._v(_vm._s(item.otcType))]
                          )
                        : _vm._e(),
                      _vm._v(" " + _vm._s(_vm.$util.getGoodsName(item)) + " "),
                    ]
                  ),
                  _c("ul", { staticClass: "commodity-middle" }, [
                    _c("li", { staticClass: "middle-item" }, [
                      _c("span", [
                        _vm._v("商品编码：" + _vm._s(item.productCode)),
                      ]),
                      _c("span", [_vm._v("规格：" + _vm._s(item.spec))]),
                      _c("span", [_vm._v("单位：" + _vm._s(item.unit))]),
                      _c("span", [_vm._v("件包装：" + _vm._s(item.packTotal))]),
                    ]),
                    _c("li", { staticClass: "middle-item" }, [
                      _vm._v(
                        "厂家(上市许可持有人)/产地：" +
                          _vm._s(item.manufacturer)
                      ),
                    ]),
                  ]),
                  _c("tagList", { attrs: { taglist: item.tagList } }),
                ],
                1
              ),
              _c("div", { staticClass: "commodity-price" }, [
                _c("div", { staticClass: "price-item price-detail" }, [
                  _c("span", { staticClass: "label" }, [_vm._v("销售价：")]),
                  _c("span", { staticClass: "value sale-price" }, [
                    _vm._v(
                      _vm._s(
                        _vm.isLogin
                          ? _vm.$util.getPriceByZero(item.price, item)
                          : "登录可见"
                      )
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "price-item" },
                  [
                    _c("div", { staticClass: "item-side" }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("销项税："),
                      ]),
                      _c("span", { staticClass: "value" }, [
                        _vm._v(_vm._s(item.rate)),
                      ]),
                    ]),
                    _vm.userType === "SALESMAN"
                      ? [
                          _c("div", { staticClass: "item-side" }, [
                            _vm._v("库存：" + _vm._s(item.stockQuantity)),
                          ]),
                        ]
                      : _vm._e(),
                    _vm.userType === "BUYERS"
                      ? [
                          item.stockQuantity && item.stockQuantity >= 100
                            ? _c("div", { staticClass: "item-side" }, [
                                _vm._v("库存："),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(">100"),
                                ]),
                              ])
                            : _c(
                                "div",
                                {
                                  staticClass: "item-side",
                                  staticStyle: { color: "#FF6600" },
                                },
                                [
                                  _vm._v("库存："),
                                  _c("span", { staticClass: "value" }, [
                                    _vm._v("<100"),
                                  ]),
                                ]
                              ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _c("div", { staticClass: "price-item" }, [
                  _c("div", { staticClass: "item-side" }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("最近效期："),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(item.expDate)),
                    ]),
                  ]),
                  _c("div", { staticClass: "item-side" }, [
                    _vm._v(" 最小购买倍数："),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.getRealMinBuyNum(item))),
                    ]),
                  ]),
                ]),
              ]),
              _vm.isLicenseOutOfTime
                ? _c(
                    "div",
                    { staticClass: "table-operation" },
                    [
                      _c("el-button", { attrs: { disabled: "" } }, [
                        _vm._v("证照过期" + _vm._s(_vm.isLicenseOutOfTime)),
                      ]),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "commodity-operation" },
                    [
                      _c(
                        "div",
                        { staticClass: "input-number" },
                        [
                          _c("el-input-number", {
                            attrs: {
                              size: "mini",
                              precision: _vm.getDecimalPointNum(item),
                              "step-strictly": "",
                              step: _vm.getRealMinBuyNum(item),
                              min: _vm.getRealMinBuyNum(item),
                            },
                            model: {
                              value: item.count,
                              callback: function ($$v) {
                                _vm.$set(item, "count", $$v)
                              },
                              expression: "item.count",
                            },
                          }),
                        ],
                        1
                      ),
                      item.stockQuantity === 0
                        ? _c(
                            "el-button",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { type: "info" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleRegisterGoods(
                                    item,
                                    item.count
                                  )
                                },
                              },
                            },
                            [_vm._v("缺货登记")]
                          )
                        : _c(
                            "el-button",
                            {
                              staticClass: "add-commodity-add",
                              attrs: {
                                disabled: _vm.isBanAddToCart,
                                loading: item.loading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.beforeAddItem(
                                    item.skuId,
                                    "NORMAL",
                                    item.count,
                                    item
                                  )
                                },
                              },
                            },
                            [_vm._v("加入购物车")]
                          ),
                    ],
                    1
                  ),
            ]
          )
        }),
        0
      ),
      _vm.tableData.length === 0 && !_vm.isLoading
        ? _c("empty-box", { staticStyle: { padding: "16px" } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
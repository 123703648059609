<template>
  <el-form-item class="search-pad-item" :label="label" :prop="prop">
    <slot/>
  </el-form-item>
</template>
<script>
  export default {
    name: 'SearchPadItem',
    props: {
      prop: {
        required: true,
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      }
    },
    data () {
      return {}
    }
  }
</script>
<style scoped lang="scss">
  .search-pad-item {
    margin-right: 10px;
    margin-bottom: 8px;
    height: 32px;

    /deep/ {
      .el-input__suffix {
        display: flex;
        align-items: center;
      }
      .el-form-item__label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(113, 113, 113, 1);
        padding-right: 4px;
      }
    }
  }

</style>

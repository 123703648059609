<template>
    <div class="main" @click="close">
        <!-- 搜索、导航栏 -->
        <Search :index-nav="true"/>
        <div class="aptitudes" v-if="license.isAllLicenseExpired === 'Y'">温馨提示：{{userType !=='SALESMAN'?'您有' + license.failureSum
            + '张证照已过期,请联系业务员更换,以免影响您的正常采购' : '该客户有'+ license.failureSum + '张证照已过期，以免影响正常采购！'}}
            <el-button size="small" type="text" @click="handleConfirm">查看详情</el-button>
        </div>
        <div class="user">
            <div class="userDetail">
                <div style="flex: 7;margin: 11px">
                    <span class="userTitle">客户名称：</span><span
                        class="userInfo">{{buyersNm}}{{ettleAccount.ettleAccountNm === '默认分支机构' ? '':' - '+ettleAccount.ettleAccountNm}}</span>
                    <br/><br/>
                    <span class="userTitle">联系人：</span><span class="userInfo">{{receiverName}}</span>
                    <span class="userTitle">联系电话：</span><span class="userInfo">{{receiverMobile}}</span>
                    <br/><br/>
                    <span class="userTitle">收货地址：</span><span class="userInfo" v-show="!isChangeAddr">{{address}}</span>
                    <el-select v-model="address" placeholder="请选择" v-show="isChangeAddr"
                               style="margin-right: 24px;width: 270px;" id="selectAdd" @change="addrChange">
                        <el-option
                                v-for="(item, index) in addressOptions"
                                :key="index"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                    <el-button type="text" @click="changeAddr" v-if="addressOptions.length>1">切换地址</el-button>
                </div>
                <div style="width:1px;height:39px;background:rgba(229,229,229,1);margin: 11px"
                     v-if="userDetail.memberDetailsVo && userDetail.memberDetailsVo.isCredit === 'Y'"></div>
                <div style="flex: 3;margin: 11px" v-if="userDetail.memberDetailsVo && userDetail.memberDetailsVo.isCredit === 'Y'">
                    <p class="last">可用额度：</p>
                    <p class="userMoney">¥{{totalCredit}}</p>
                </div>
            </div>
        </div>
        <!-- 商品列表 -->
        <div class="productList">
            <div class="excel">
                <el-button class="excelButton" type="primary" @click="importExcel">Excel导入采购计划</el-button>
            </div>
            <!--table列表-->
            <el-table
                    ref="multipleTable"
                    border
                    :data="tableData"
                    v-loading="loadingTablePro"
                    header-row-class-name="header-row"
                    style="width: 100%"
                    height="600"
                    class="tablePro"
                    :header-cell-class-name="cellClass"
                    id="tableList"
                    :row-class-name="tableRowClassName"
                    @selection-change="handleSelectionChange">
                <el-table-column
                        type="selection"
                        width="35"
                        ref="checkTab">
                </el-table-column>
                <el-table-column
                        type="index"
                        align="center"
                        label="序号"
                        width="45">
                </el-table-column>
                <el-table-column
                        prop="productCode"
                        label="商品编码"
                        align="center"
                        min-width="100"
                        key="productCode"
                        show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{scope.row.productCode}}
                        <div>
                            <tagList :taglist='scope.row.tagList'></tagList>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="productNm"
                        label="商品名称"
                        min-width="110px"
                        key="productNm"
                        align="center">
                    <template slot-scope="scope">

                        <fast-order-search
                                :ref="'productNm' + scope.$index"
                                class="inputName"
                                :index="scope.$index"
                                :id="'search'+scope.$index"
                                :productNm="$util.getGoodsName(scope.row)"
                                :isSalesman="isSalesman"
                                @keyup.46.native.stop.prevent="handleDelete(scope.row,scope.$index)"
                                @getIndex="getIndex"
                                @fast_order_fu="getSelectPro"
                                @fast_order_tab="getSelectTab()">
                        </fast-order-search>
                    </template>
                </el-table-column>
                <el-table-column v-if="userType =='SALESMAN'"
                        label="标识"
                        min-width="50px"
                        align="center">
                    <template slot-scope="scope">
                        <div class="identifying">
                            <img v-if="scope.row.whiteRecordFlag  == 1" alt src="@/assets/img/icon/whiterecord.png">
                            <img v-else-if="scope.row.blackRecordFlag  == 1" alt src="@/assets/img/icon/blackrecord.png">
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="spec"
                        align="center"
                        label="规格"
                        key="spec"
                        width="90px">
                </el-table-column>
                <el-table-column
                        prop="manufacturer"
                        align="center"
                        label="厂家(上市许可持有人)/产地"
                        key="manufacturer"
                        width="100px"
                        show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{scope.row.manufacturer}}{{scope.row.area ? '/' + scope.row.area : ''}}
                    </template>
                </el-table-column>
                <el-table-column prop="packTotal" align="center" label="件包装" width="65px" key="packTotal">
                    <template slot-scope="scope">
                        <span>{{scope.row.packTotal}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="unit"
                        align="center"
                        label="单位"
                        key="unit"
                        min-width="50px">
                </el-table-column>
                <el-table-column
                        prop="rate"
                        align="center"
                        label="销项税"
                        width="60px"
                        key="rate">
                </el-table-column>
                <el-table-column
                        prop="buyNum"
                        align="center"
                        label="采购数量"
                        key="buyNum"
                        width="120px">
                    <template slot-scope="scope">
                        <el-input-number
                                size="mini"
                                v-model="scope.row.buyNum"
                                :precision="getDecimalPointNum(scope.row)"
                                placeholder="数量"
                                step-strictly
                                :disabled="!scope.row.productId"
                                :step="scope.row.productId ? getRealMinBuyNum(scope.row) : 1"
                                :min="scope.row.productId ? getRealMinBuyNum(scope.row) : 0"
                                :key="'inputNum' + scope.$index"
                                @change="(currentValue, oldValue) => handleChange(currentValue, oldValue, scope.$index)"
                                @keyup.enter.native="nextProduct(scope.$index)"
                                @keyup.46.native="handleDelete(scope.row,scope.$index)"
                                :id="scope.$index"
                                class="proNums"
                        ></el-input-number>
                    </template>
                </el-table-column>
                 <el-table-column v-if="$store.getters.buyersVo && $store.getters.buyersVo.showStockType != 5" prop="stockQuantity" sortable align="center" label="库存" min-width="60px">
                    <template slot-scope="scope">
                        <span v-if="$store.getters.buyersVo.showStockType == 4">
                        {{scope.row.stockQuantity >= 100 ? '>100' : '<100'}}
                        </span>

                        <span v-else-if="$store.getters.buyersVo.showStockType == 3">
                        {{scope.row.stockQuantity > 0 ? '有' : '无'}}
                        </span>

                        <span v-else-if="scope.row.stockQuantity === -1">-/-</span>
                        <span :style="{color: scope.row.stockQuantity > 100 ? '': '#E02020'}" v-else-if="$store.getters.buyersVo.showStockType == 2">
                        {{scope.row.stockQuantity}}
                        </span>

                    </template>
                </el-table-column>
                <!-- <el-table-column prop="stockQuantity" align="center" label="库存" width="70px" key="stockQuantity">
                    <template slot-scope="scope">
            <span class="stockQuantity-warning">
              <span v-if="scope.row.stockQuantity === -1">-/-</span>
              <span v-else :style="{color: scope.row.stockQuantity > 100 ? '': '#E02020'}">{{isSalesman? scope.row.stockQuantity : scope.row.stockQuantity > 100 ? '>100' : '<100'}}</span>
            </span>
                    </template>
                </el-table-column> -->
                <el-table-column prop="lastSellPrice" v-if="userType === 'SALESMAN'" align="right" label="上次销价"
                                 class="price" style="color: #FF5200;" key="lastSellPrice" min-width="75px" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.lastSellPrice">¥{{scope.row.lastSellPrice}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="buyersPrice" align="right" label="销售价" class="price" key="buyersPrice" sortable
                                 min-width="65px" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="color: #FF6600">
                          {{getListPrice(scope.row)}}
                        </div>
                        <div style="text-decoration: line-through;color: #999999" v-if="scope.row.isDiscount === 'Y' && getListPrice(scope.row) !== '￥' + scope.row.salePrice">
                          {{scope.row.salePrice ? '￥' + scope.row.salePrice : ''}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="assessmentPrice" v-if="isSalesman" align="right" label="考核价" class="price"
                                 key="assessmentPrice" min-width="65px" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.assessmentPrice"
                              style="color: #FF6600">¥{{scope.row.assessmentPrice}}</span>
                    </template>
                </el-table-column>
                <!--<el-table-column prop="medicarePrice" align="right" label="医保价" class="price" key="medicarePrice"
                                 min-width="65px" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.medicarePrice" style="color: #339900">¥{{scope.row.medicarePrice}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="retailPrice" align="right" label="零售价" class="price" style="color: #FF5200;"
                                 key="retailPrice" min-width="65px" show-overflow-tooltip >
                    <template slot-scope="scope">
                        <span v-if="scope.row.retailPrice">¥{{scope.row.retailPrice}}</span>
                    </template>
                </el-table-column>-->
                <el-table-column prop="step" align="center" label="最小购买倍数" width="100px" key="step">
                    <template slot-scope="scope">
                        <span>{{getRealMinBuyNum(scope.row)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="expDate"
                        key="expDate"
                        v-if="userType === 'BUYERS' || userType === 'BUYERS_TEAM'"
                        align="center"
                        label="最近效期"
                        width="130px"
                        class="price"
                        sortable
                        style="color: #FF5200;">
                </el-table-column>
                <el-table-column
                        align="center"
                        width="200px"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" v-if="scope.row.productId && userType === 'SALESMAN'" @click="showPHXQ(scope.row,scope.$index)">查看批号效期</el-button>
                        <el-button type="text" size="mini" @click="handleDelete(scope.row,scope.$index)">删除</el-button>
                        <el-button type="text" size="mini" v-if="scope.row.stockQuantity === 0" @click="handleRegisterGoods(scope.row,scope.row.buyNum)">缺货登记</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="addPro" @click="addPro()">
                +添加
            </div>
        </div>
        <!--结算-->
        <div class="settlement-container">
            <div class="settlement-row">
                <div class="row-left">
                    <el-checkbox v-model="checkedAll" @change="selectAll(checkedAll)">全选可购买</el-checkbox>
                    <el-checkbox v-model="checkedAllLack" @change="selectAllLack(checkedAllLack)">全选缺货登记</el-checkbox>
                    <el-checkbox v-model="checkedAllInterdiction" @change="selectAllInterdiction(checkedAllInterdiction)">全选禁销</el-checkbox>
                    <img alt style="position: relative;width: 15px;top: 2px;right: 3px;"
                         src="@/assets/img/icon/clear.png">
                    <span class="deleteAll" @click="toggleSelection()">清空商品</span>
                    <span class="deleteAll" @click="saveBatchOutStockRegistration()">批量缺货登记</span>
                </div>
                <div class="row-right">
                    <div class="settlement">
                        <div class="total-money">
                            商品总金额：
                            <span class="money">¥{{$util.toFixed(allPrices)}}</span>
                        </div>
                        <div class="product-detail">
                            <span>商品种类：{{multipleSelection.length}}种</span>
                            <span>总数量：{{allCounts}}个</span>
                        </div>
                    </div>
                    <div class="table-operation" v-if="isLicenseOutOfTime">
                        <el-button disabled>证照过期</el-button>
                    </div>
                    <div class="settlement-button-group" :loading='loading' v-else>
                        <button class="shopping-car"
                                :disabled="isBanAddToCart"
                                @click="checkExpDate">加入购物车</button>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" width="520px">
            <div slot="title" class="agreementDialog">Excel导入采购计划</div>
            <el-form ref="form" :model="form" :rules="formRules" label-width="128px">
                <el-form-item prop="matchingMethod" label="选择匹配方式：">
                    <el-radio-group v-model="form.matchingMethod" style="line-height: 30px">
                        <el-radio label="NM_SPEC_MANUFACTURER">品名+规格+厂家</el-radio>
                        <el-radio label="APPROVAL_NUMBER">国药准字</el-radio>
<!--                        <el-radio label="PRODUCT_BAR_CODE">商品条形码(69码)</el-radio>-->
                        <!--            <el-radio label="PRODUCT_CODE">平台商品编码</el-radio>-->
                        <el-radio label="ERP_CODE">ERP商品编码</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="下载Excel模板：">
                    <span>{{matchingMethod[form.matchingMethod]}}</span>
                    <el-button class="downExcelBt" @click="downExcel" size="mini">下载模板</el-button>
                </el-form-item>
                <el-form-item prop="upload" label="上传文件：">
                    <input style="display: none" ref="uploadExcel" id="uploadExcel" type="file" @change="importUp()"
                           accept=".csv,.xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                    <el-progress v-if="isUploading" class="progress" :stroke-width="20" :percentage="uploadProgressLen" :status="uploadStatus"></el-progress>
                    <el-button v-if="!isUploading" class="upExcelBt" @click="upload" type="primary" size="mini">上传Excel文件</el-button>
                    <p style="font-size: 12px">为避免导入失败，请下载最新模板后再导入</p>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :visible.sync="dialogSuccess" width="400px">
            <div slot="title" class="agreementDialog">导入提示</div>
            <div class="importDiv">
                <img class="importImg" :src=importDialog.imgSrc alt/>
                {{importDialog.title}}
            </div>
            <span v-if="this.errorList">{{importDialog.text}}</span>
            <table class="el-table el-table--border" v-if="this.errorList && this.errorList.length >0 && this.form.matchingMethod == 'NM_SPEC_MANUFACTURER'">
                <thead class="el-table_1_column_2 is-center  el-table__cell">
                <td class="el-table_1_column_2 is-center  el-table__cell">商品名称</td> <td class="el-table_1_column_2 is-center  el-table__cell">单位</td> <td class="el-table_1_column_2 is-center  el-table__cell">采购数量</td>
                </thead>
                <tr v-for="(item,i) in this.errorList" class="el-table__row">
                    <td class="el-table_1_column_2 is-center  el-table__cell">{{item.productNm}}</td><td class="el-table_1_column_2 is-center  el-table__cell">{{item.spec}}</td><td class="el-table_1_column_2 is-center  el-table__cell">{{item.buyNm}}</td>
                </tr>

            </table>
            <table class="el-table el-table--border" v-if="this.errorList && this.errorList.length >0 && this.form.matchingMethod == 'ERP_CODE'">
                <thead class="el-table_1_column_2 is-center  el-table__cell">
                    <td class="el-table_1_column_2 is-center  el-table__cell">商品ERP编码</td><td class="el-table_1_column_2 is-center  el-table__cell">采购数量</td>
                </thead>
                <tr v-for="(item,i) in this.errorList" class="el-table__row">
                    <td class="el-table_1_column_2 is-center  el-table__cell">{{item.erpCode}}</td><td class="el-table_1_column_2 is-center  el-table__cell">{{item.buyNm}}</td>
                </tr>
            </table>
        </el-dialog>
        <pi-hao-xiao-qi :visible="isShowPHXQ" v-if="isShowPHXQ" :rowData="rowData"
                        @close="isShowPHXQ = false"></pi-hao-xiao-qi>

        <el-dialog
                title="以下是近效期品种"
                :visible.sync="expDateVisible"
                width="60%">
            <el-table ref="expDateTable" border :data="submitOrderData">
                <el-table-column label="商品编码" prop="productCode" width="90"></el-table-column>
                <el-table-column label="商品名称" prop="productNm" min-width="120">
                    <template slot-scope="scope">
                        {{$util.getGoodsName(scope.row)}}
                    </template>
                </el-table-column>
                <el-table-column label="规格" prop="spec" min-width="80"></el-table-column>
                <el-table-column label="生产厂家" prop="manufacturer" min-width="140"></el-table-column>
                <el-table-column label="最近效期" prop="expDate" min-width="100"></el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="expDateVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleCollateOrder(1)">继续下单</el-button>
            </span>
        </el-dialog>

        <!-- 上传进度
        <el-dialog title="上传进度" :visible.sync="isUploading" :close-on-click-modal="false" :show-close="false">
            <el-progress type="circle" :percentage="uploadProgressLen" :status="uploadStatus"></el-progress>
        </el-dialog>-->

    </div>
</template>
<script>
    import Search from '@/components/Search/Search'
    import fastOrderSearch from './fastOrderSearch'
    import {batchAddItem} from '@/api/detail'
    import {getUser, uploadFiles,saveFastOrderCache,getFastOrderCache,delFastOrderCache} from '@/api/fast-order'
    import {exportTemplate} from '@/api/member-center/transactions-using'
    import {getInfo} from '@/api/user'
    import {mapGetters} from 'vuex'
    import {getToken} from "@/utils/auth";
    import PiHaoXiaoQi from "@/components/PiHaoXiaoQi/PiHaoXiaoQi";
    import {getBuyersjyPHXQ} from "../../../api/commodity-list";
    import addToCartMixin from '@/common/addToCart'
    import {registerGood,saveBatchOutStockRegistration} from "@/api/shop-cart";
    import tagList from '@/components/tagList/tagList'


    export default {
        name: 'fastOrder',
        components: {
            Search,
            fastOrderSearch,
            PiHaoXiaoQi,
            tagList
        },
        mixins:[addToCartMixin],
        data() {
            return {
                timer: null,
                submitOrderData:[],
                expDateVisible:false,
                loading: false,
                loadingToPay: false,
                loadingTablePro: false,
                tableData: [],
                checkedAll: false,
                checkedAllLack: false, // 缺货登记全选
                checkedAllInterdiction: false, // 禁销全选
                isUploading: false,
                uploadStatus:undefined,
                uploadProgressLen:0,
                multipleSelection: [],
                allCounts: 0,
                allPrices: 0.00, // 整体乘以100
                dialogVisible: false,
                dialogSuccess: false,
                form: {
                    matchingMethod: 'NM_SPEC_MANUFACTURER'
                },
                formRules: {
                    matchingMethod: [{required: true, trigger: 'blur', message: '请选择匹配方式'}],
                },
                matchingMethod: {
                    NM_SPEC_MANUFACTURER: '品名+规格+厂家',
                    APPROVAL_NUMBER: '国药准字',
                    PRODUCT_BAR_CODE: '商品条形码(69码)',
                    PRODUCT_CODE: '平台商品编码',
                    ERP_CODE: 'ERP商品编码'
                },
                address: '',
                addressOptions: [],
                isChangeAddr: false,
                importDialog: {
                    imgSrc: require('@/assets/img/fast-order/success.png'),
                    title: '恭喜您，导入成功！',
                    text: ''
                },
                newFile: new FormData(),
                totalCredit: 0.00,
                ettleAccount: {
                    ettleAccountNm: ''
                },
                buyersNm: '',
                typeName: {
                    NM_SPEC_MANUFACTURER: 'INQUIRYBOXDETAILPM',
                    APPROVAL_NUMBER: 'INQUIRYBOXDETAILGY',
                    PRODUCT_BAR_CODE: 'INQUIRYBOXDETAILSP',
                    PRODUCT_CODE: 'INQUIRYBOXDETAILPT',
                    ERP_CODE: 'INQUIRYBOXDETAILKH'
                },
                indexInput: 0,
                isSalesman: false,
                isChangeNum: true,
                receiverName: '',
                receiverMobile: '',
                addressList: [],
                isShowPHXQ: false,
                rowData: {},
                errorList:[]
            }
        },
        watch: {
            '$store.state.user.refreshModule': {
                handler() {
                    if (getToken()) {   // 判断一下是否登录 点击登出会报错
                        this.getCacheOrder()
                    }
                },
                deep: true
            }
        },
        computed: {
            ...mapGetters(['userDetail','orgSalesmanVo','license', 'userType', 'accountId'])
        },
        // mounted() {
        //     this.getPrice()
        // },
        mounted(){
            this.getCacheOrder()
        },
        methods: {

           //直接点击缺货登记
            handleRegisterGoods(row,quantity){
                console.log(row)
                let html =  `<div>即将对商品` +
                    `<span style="color:#1CB7CD">${row.productCode}</span>` + `发起缺货登记，需求采购数为 ` +
                    `<span style="color:#F56C6C">${quantity}</span> ?` +
                    `</div>` +
                    `<div>运营人员可能依据采购数作参考进行补货</div>`
                this.$confirm(html,'请确认',{
                    type:'warning',
                    dangerouslyUseHTMLString: true
                }).then(res=>{
                    this.registerGoods(row,quantity).then(res=>{
                        this.$message.success(`商品${row.productCode}缺货登记成功`)
                    })
                }).catch(err=>{})
            },
            //缺货登记
            registerGoods(row = {},quantity = 1){
                let {buyersId,buyersNo,buyersNm} = this.buyersVo
                let {orgId} = this.org
                let {drugNm,productId,productNm,productCode,stockQuantity,spec,unit} = row
                let data = {
                    buyersId,
                    buyersCode: buyersNo,
                    buyersName: buyersNm,
                    buyersPhone: this.userDetail.userMobile,
                    orgId: orgId,
                    enquiryNumber: quantity,
                    productGeneralName: drugNm,
                    productId,
                    productName:productNm,
                    productCode,
                    stock: stockQuantity,
                    spec,
                    unit
                }
                return new Promise((resolve,reject)=>{
                    registerGood(data).then(res => {
                        const { code } = res
                        if (code === '0') {
                            //缺货登记成功
                            resolve(res)
                        }else {
                            //缺货登记失败
                            reject && reject(res)
                        }
                    }).catch(err=>{
                        //缺货登记失败
                        reject && reject(err)
                    })
                })
            },
            //获取列表需要展示的价格
            getListPrice(row){
              let price;
              let {minBuyDecimals,minBuyMultiple,minOrderQuantity} = row;

              minBuyDecimals = minBuyDecimals || 1;
              minBuyMultiple = minBuyMultiple || 1;
              minOrderQuantity = minOrderQuantity || 1;

              let realMinBuyMultiple = minBuyDecimals * minBuyMultiple;
              let realMinOrderQuantity = minBuyDecimals * minOrderQuantity;
              if(row.buyNum){
                if(row.buyNum % realMinBuyMultiple === 0 || row.buyNum > realMinBuyMultiple){
                  price = '￥' + row.salePrice
                }
                if(row.buyNum % realMinOrderQuantity === 0 || row.buyNum > realMinOrderQuantity){
                  price = row.buyersPrice === '-/-' ? '￥-/-' : this.$util.getPriceByZero(row.buyersPrice, row)
                }

                if(row.buyNum < realMinOrderQuantity && row.buyNum < realMinBuyMultiple){
                  price = '￥' + row.salePrice
                }
              }else {
                price = row.buyersPrice === '-/-' ? '￥-/-' : this.$util.getPriceByZero(row.buyersPrice, row)
              }

              return price
            },
            //获取未完成操作的缓存列表
            getCacheOrder(){
                this.tableData = []
                this.loadingTablePro = true
                getFastOrderCache().then(res=>{
                    if(res.code === '0'){
                      if(res.data && res.data.length){
                        let data = res.data.map(item=>{
                          item.rate = item.rate || '-/-'
                          item.lastSellPrice = item.lastSellPrice || '-/-'
                          item.step = this.getRealMinBuyNum(item)
                          return item
                        })

                        this.tableData = data
                          this.$nextTick(() => {
                              this.$refs.multipleTable.doLayout()
                          })
                        this.fomatTable()
                      }
                      this.getPrice()
                    }else {
                      this.getPrice()
                    }
                }).catch(err=>{
                  this.getPrice()
                }).finally(() => {
                    this.loadingTablePro = false
                })
            },
            //保存缓存列表
            saveCacheOrder(){
                let data = this.tableData.filter(item => !!item.productId)
                data = data.map(item=>{
                    return {
                    productId: item.productId,
                    buyNm: item.buyNum || ''
                    }
                })
                saveFastOrderCache({
                    orderQuicklyRedisVoList: data
                })
                .then(res => {
                    this.fomatTable()
                })
            },
            fomatTable(){
                let arr =[]
                let arr4=[];
                let arr2=[];
                let arr3 = []
                let arr5 = []
                let arr6 = []

                this.tableData.map((v,index) => {
                      if(v.productCode){
                          arr.push(v)
                      }else{
                          arr2.push(v)
                      }
                })
                arr.map(v => {
                    if( v.buyNum >  v.stockQuantity && v.stockQuantity != -1){
                           v.isRed = 'Y'
                           arr4.push(v)
                      }
                    else{
                      arr3.push(v)
                    }
                })
                arr3.map(v => {
                    if( v.forbidSalesFlag === "Y"){
                        arr5.push(v)
                    }
                    else{
                        arr6.push(v)
                    }
                })
                console.log(arr4)
                this.tableData =[...arr6,...arr5,...arr4,...arr2]
                // 上面步骤会导致表格的勾选取消
                this.checkedAll = false; // 可购全选
                this.checkedAllLack = false; // 缺货登记全选
                this.checkedAllInterdiction = false; // 禁销全选
            },
            //表格表单发生变化
            changeForm(){
              clearTimeout(this.timer)
              this.timer = setTimeout(()=>{
                this.saveCacheOrder()
                clearTimeout(this.timer)
              },1000)
            },
            tableRowClassName({row, rowIndex}) {
                if (row.isRed === 'Y') {
                    return 'expDate-row'
                } else if (row.forbidSalesFlag === 'Y') {
                    return  'forbidSalesDate-row'
                }
                return '';
            },
            showPHXQ(row) {
                this.rowData = row
                this.isShowPHXQ = true
            },
            handleConfirm() {
                this.$router.push(`/member-center/personal-center/my-aptitudes`)
            },
            addrChange(val) {
                this.receiverName = this.addressList[val].receiverName
                this.receiverMobile = this.addressList[val].receiverMobile
            },
            toCheckTab() {
                let checkList = document.getElementsByClassName("el-checkbox__original")
                let deleteList = document.getElementsByClassName("is-plain")
                let tabindexNum = 1
              if(Array.isArray(checkList)){
                checkList.forEach(item => {
                  item.setAttribute("tabindex", "-1")
                })
              }
              if(Array.isArray(deleteList)){
                deleteList.forEach(item => {
                  item.setAttribute("tabindex", "-1")
                })
              }
                this.tableData.forEach((item, index) => {
                    let searchInput = document.getElementById('search' + index)
                    searchInput.getElementsByTagName('input')[0].setAttribute("tabindex", '' + tabindexNum)
                    tabindexNum++
                    let inputNum = document.getElementById('' + index)
                    inputNum.setAttribute("tabindex", '' + tabindexNum)
                    tabindexNum++
                })
            },
            getPrice() {
                getInfo().then(res => {
                    this.totalCredit = res.data.memberDetailsVo.availableCredit
                    if (res.data.orgSalesmanVo) {
                        this.isSalesman = true
                        if (sessionStorage.haveAccount === 'true') {
                            this.getUser()
                        } else {
                            this.$message.error('请选择客户')
                        }
                    } else {
                        this.getUser()
                    }
                })
            },
            close() {
                let ref = this.$refs['productNm' + this.indexInput];
                if (!!ref) {
                    ref.closePopover()
                }
            },
            getUser() {
                // this.tableData = []
                if(this.tableData.length < 10){
                  for (let i = this.tableData.length; i < 10; i++) {
                    let startData = {
                      indexNum: i,
                      skuId: '',
                      productId: '',
                      orgId: '',
                      productCode: '',
                      productNm: '',
                      minBuyMultiple: '-/-',
                      lastSellPrice: '-/-',
                      step: 1,
                      manufacturer: '-/-',
                      area: '',
                      spec: '-/-',
                      rate: '-/-',
                      unit: '-/-',
                      medicarePrice: '-/-',
                      retailPrice: '-/-',
                      packTotal: '-/-',
                      stockQuantity: -1,
                      stockQuantityStatus: '',
                      otcType: '',
                      isCashTransaction: 'N',
                      buyersPrice: '-/-',
                      isHistoricalPurchases: 'Y',
                      buyNum: '',
                      expDate: '',
                      assessmentPrice: '-/-'
                    }
                    this.tableData.push(startData)  // 数据完全一样选择有问题
                  }
                  this.fomatTable()
                }
                getUser().then(res => {
                    this.addressOptions = []
                    this.isChangeAddr = false
                    if (res.data) {
                        this.ettleAccount = res.data.ettleAccount
                        this.buyersNm = res.data.buyersNm
                        if (res.data.ettleAccount.availableCredit) {
                          this.availableCredit = res.data.ettleAccount.availableCredit
                        }

                        if(res.data.addrs.length > 0){
                          this.address = res.data.addrs[0].province + res.data.addrs[0].city + res.data.addrs[0].district + res.data.addrs[0].receiverAddr
                          this.receiverName = res.data.addrs[0].receiverName
                          this.receiverMobile = res.data.addrs[0].receiverMobile
                          this.addressList = res.data.addrs
                          res.data.addrs.forEach((item, index) => {
                            let str = item.province + item.city + item.district + item.receiverAddr
                            let params = {label: str, value: index}
                            this.addressOptions.push(params)
                          })
                        }else {
                          this.$message.warning('当前客户未设置收货地址')
                        }

                    }
                })
            },
            nextProduct(index) {
                if (index === this.tableData.length - 1) {
                    this.addPro()
                }
                this.$nextTick(() => {
                    let nextIndex = index + 1
                    this.$refs['productNm' + nextIndex].getFocus()
                })
            },
            async getSelectPro(data, index) {
                if (!data) return;
                // 判断是否与列表中的数据重复
                let isExist = this.tableData.some((item, num) => index != num && item.productCode === data.productCode)
                if (isExist) {
                    this.$message.error('该商品已存在')
                    return
                } else {
                    data.step = this.getRealMinBuyNum(data)

                    let res;
                    //业务员选中商品显示批号效期
                    if (this.userType === 'SALESMAN') {
                        //先不上弹窗功能
                        // this.isShowPHXQ = true
                        this.rowData = data
                        // res = await getjyPHXQ({
                        //     productId: data.productId
                        // })
                    }

                    res = await getBuyersjyPHXQ({
                        productId: data.productId
                    })

                    //选择商品加入时获取最近/远效期 expDate
                    data.expDate = res.data.expDate
                    data.isRed = res.data.isRed || 'N'

                    this.$set(this.tableData, index, data)
                    this.changeForm()
                    this.$refs.multipleTable.toggleRowSelection(data)

                }
                data.buyNum = ''
                let inputNum = document.getElementById(index)
                inputNum.focus()
            },
            getSelectTab(data) {
                this.toCheckTab()
            },
            getIndex(index) {
                this.indexInput = index
            },
            cellClass(row) {
                if (row.columnIndex === 0) {
                    return 'DisabledSelection'
                }
            },
            async checkExpDate(){
                if(this.multipleSelection.length){
                    this.submitOrderData = [];
                    let product = {};
                    for(let item of this.multipleSelection){
                        // console.log(item)
                        if(item.isRed === 'Y'){
                            if(!product[item.productId]){
                                product[item.productId] = item.productId
                                this.submitOrderData.push(item)
                            }
                        }
                    }
                    if(!!this.submitOrderData.length){
                        this.expDateVisible = true
                    }else {
                        this.handleCollateOrder(1)
                    }
                }else {
                    this.handleCollateOrder(1)
                }
            },
            // 1--加入购物车  2--结算
            handleCollateOrder(type) {
                this.expDateVisible = false;
                let isError = false
                let errorText = []
                if (type === 1) {
                    this.loading = true;
                } else {
                    this.loadingToPay = true;
                }
                //需要加入购物车再跳转到结算页，否则拿不到商品清单
                if (this.multipleSelection.length > 0) {
                    let itemLists = []
                    this.multipleSelection.map(r => {
                        if (!r.buyNum) {
                            errorText.push(r.productNm)
                            isError = true
                        }
                        if (r.productCode) {
                            itemLists.push({skuId: r.skuId, quantity: r.buyNum})
                        }
                    })
                    let params = {
                        itemList: itemLists
                    }
                    if (isError) {
                        this.$message.error('请输入' + errorText.join(',') + '的商品数量')
                        return
                    }
                    batchAddItem(params).then(res => {
                        if (res.code === '0') {
                            this.$store.dispatch('user/setCartNum', res.data.allProductTypeTotalQuantity)
                            if (type === 1) {
                                this.loading = false;
                                this.$message.success('加入购物车成功')
                                this.tableData = this.tableData.filter(item => !this.multipleSelection.find(obj => obj.productId === item.productId))
                                this.changeForm()
                            } else if (type === 2) {
                                this.loading = false;
                                this.$router.push({
                                    path: '/shopping-cart', query: {
                                        title: '结算页',
                                        currentView: 'CollateOrder',
                                        active: 1,
                                    }
                                })
                            }
                        }
                    })
                } else {
                    this.loading = false;
                    this.$message.error('请先选择商品')
                }
            },
            // 商品数量修改
            handleChange(currentValue, oldValue, index) {
                // let reg = new RegExp(/^[+-]?(0|([1-9]\d*))(\.\d+)?$/);
                // if (!reg.test(currentValue)) {
                //     this.$message.error('采购数量必须是数字')
                //     this.tableData[index].buyNum = ''
                //     return
                // }
                if (this.isChangeNum) {
                    this.isChangeNum = false
                    let value = currentValue % this.tableData[index].step
                    if (currentValue < this.tableData[index].step) {
                        currentValue = this.tableData[index].step
                        this.$nextTick(() => {
                            this.tableData[index].buyNum = currentValue
                            this.countsAll()
                            this.countsPrice()
                        })
                        this.$message.error('采购数量必须按照最小购买倍数' + this.tableData[index].step + '的倍数采购')
                    } else if (value > 0) {
                        currentValue = currentValue - value
                        this.$nextTick(() => {
                            this.tableData[index].buyNum = currentValue
                            this.countsAll()
                            this.countsPrice()
                        })
                        this.$message.error('采购数量必须按照最小购买倍数' + this.tableData[index].step + '的倍数采购')
                    } else {
                        this.countsAll()
                        this.countsPrice()
                    }
                    this.changeForm()
                    setTimeout(() => {  // 容易误触 加个延迟
                        this.isChangeNum = true
                    }, 300);
                } else {
                    this.$nextTick(() => {
                        this.tableData[index].buyNum = oldValue
                        this.countsAll()
                        this.countsPrice()
                    })
                }
            },
            // 计算总数量
            countsAll() {
                this.allCounts = 0
                this.multipleSelection.map(item => {
                    if (item.productCode && item.buyNum) {
                        this.allCounts += Number(item.buyNum)
                    }
                })
            },
            // 计算总金额
            countsPrice() {
                this.allPrices = 0.00
                this.multipleSelection.map(item => {
                  if (item.productCode && item.buyersPrice && item.buyNum) {
                    let price = Number(String(this.getListPrice(item)).replace(/,|￥/g, '')) || 0
                    // this.allPrices += price * item.buyNum
                    let totalPrice = this.$util.multiply(price,item.buyNum)
                    this.allPrices = this.$util.adding(String(this.allPrices).replace(/,/g,''),totalPrice.replace(/,/g,''))
                  }
                })
                this.allPrices = this.$util.toFixed(this.allPrices)
            },
            // 切换地址
            changeAddr(e) {
                if (!this.isChangeAddr) {
                    this.isChangeAddr = true
                }
                document.getElementById('selectAdd').click()
            },
            // 增加一列商品
            addPro() {
                let startData = {
                    indexNum: this.tableData.length,
                    skuId: '',
                    productId: '',
                    orgId: '',
                    productCode: '',
                    productNm: '',
                    minBuyMultiple: '-/-',
                    step: 1,
                    manufacturer: '-/-',
                    rate: '-/-',
                    area: '',
                    spec: '-/-',
                    unit: '-/-',
                    medicarePrice: '-/-',
                    lastSellPrice: '-/-',
                    assessmentPrice: '-/-',
                    packTotal: '-/-',
                    retailPrice: '-/-',
                    stockQuantity: -1,
                    stockQuantityStatus: '',
                    otcType: '',
                    isCashTransaction: 'N',
                    buyersPrice: '-/-',
                    isHistoricalPurchases: 'Y',
                    buyNum: ''
                }
                this.tableData.push(startData)
                this.$nextTick(() => {
                    this.$refs.multipleTable.bodyWrapper.scrollTop = this.$refs.multipleTable.bodyWrapper.scrollHeight
                })
            },
            // 删除商品
            handleDelete(data, index) {
                this.tableData.splice(index, 1)
                if (data.productCode) {
                    this.multipleSelection = this.multipleSelection.filter(item => item.productCode !== data.productCode)
                    this.countsAll()
                    this.countsPrice()
                }
                this.changeForm()
            },
            handleSelectionChange(val) {
                this.multipleSelection = val
                // this.checkedAll = val.length === this.tableData.length
                this.countsAll()
                this.countsPrice()
            },
            // 全选
            selectAll(value) {
                this.checkedAllLack = false
                this.checkedAllInterdiction = false
                if (value) {
                    // this.$refs.multipleTable.clearSelection()
                    // this.tableData.forEach(item => {
                    //     if (item.skuId) {
                    //         this.$refs.multipleTable.toggleRowSelection(item, true)
                    //     }
                    // })
                    // this.multipleSelection = []
                    // this.tableData.map(item => {
                    //     if (item.skuId) {
                    //         this.multipleSelection.push(item)
                    //     }
                    // })
                    this.$refs.multipleTable.clearSelection()
                    this.tableData.forEach(item => {
                        if (item.isRed !== "Y" && item.forbidSalesFlag !== "Y") {
                            this.$refs.multipleTable.toggleRowSelection(item, true)
                        }
                    })
                    this.multipleSelection = []
                    this.tableData.map(item => {
                        if (item.isRed !== "Y" && item.forbidSalesFlag !== "Y") {
                            this.multipleSelection.push(item)
                        }
                    })
                } else {
                    this.$refs.multipleTable.clearSelection()
                    this.multipleSelection = []
                }
                this.countsAll()
                this.countsPrice()
            },
            // 缺货登记全选
            selectAllLack(value) {
                this.checkedAll = false
                this.checkedAllInterdiction = false
                if (value) {
                    this.$refs.multipleTable.clearSelection()
                    this.tableData.forEach(item => {
                        if (item.isRed === "Y") {
                            this.$refs.multipleTable.toggleRowSelection(item, true)
                        }
                    })
                    this.multipleSelection = []
                    this.tableData.map(item => {
                        if (item.isRed === "Y") {
                            this.multipleSelection.push(item)
                        }
                    })
                } else {
                    this.$refs.multipleTable.clearSelection()
                    this.multipleSelection = []
                }
                this.countsAll()
                this.countsPrice()
            },
            // 禁销全选
            selectAllInterdiction(value) {
                this.checkedAll = false
                this.checkedAllLack = false
                if (value) {
                    this.$refs.multipleTable.clearSelection()
                    this.tableData.forEach(item => {
                        if (item.forbidSalesFlag === "Y") {
                            this.$refs.multipleTable.toggleRowSelection(item, true)
                        }
                    })
                    this.multipleSelection = []
                    this.tableData.map(item => {
                        if (item.forbidSalesFlag === "Y") {
                            this.multipleSelection.push(item)
                        }
                    })
                } else {
                    this.$refs.multipleTable.clearSelection()
                    this.multipleSelection = []
                }
                this.countsAll()
                this.countsPrice()
            },
            // 清空商品
            toggleSelection() {
                delFastOrderCache();
                this.multipleSelection = []
                this.tableData = []
                for (let i = 0; i < 10; i++) {
                    let startData = {
                        indexNum: i,
                        skuId: '',
                        productId: '',
                        orgId: '',
                        productCode: '',
                        productNm: '',
                        minBuyMultiple: '-/-',
                        step: 1,
                        manufacturer: '-/-',
                        rate: '-/-',
                        area: '',
                        spec: '-/-',
                        unit: '-/-',
                        medicarePrice: '-/-',
                        assessmentPrice: '-/-',
                        retailPrice: '-/-',
                        stockQuantity: -1,
                        lastSellPrice: '-/-',
                        stockQuantityStatus: '',
                        otcType: '',
                        packTotal: '-/-',
                        isCashTransaction: 'N',
                        buyersPrice: '-/-',
                        isHistoricalPurchases: 'Y',
                        noProduct:true,
                        buyNum: ''
                    }
                    this.tableData.push(startData)
                    this.fomatTable()
                }

                this.countsAll()
                this.countsPrice()
            },
            saveBatchOutStockRegistration: function () {
                if (this.multipleSelection.length > 0) {
                    this.$confirm('是否是所选内容?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        let {buyersId, buyersNo, buyersNm} = this.buyersVo;
                        let {buyersPhone} = this.userDetail.userMobile;
                        let productList = [];
                        productList = this.multipleSelection.map(item => {
                            if(item && item.stockQuantity === 0){
                                return {
                                    productId: item.productId,
                                    productCode: item.productCode,
                                    productName: item.productNm || '',
                                    productGeneralName: item.drugNm,
                                    spec: item.spec,
                                    unit: item.unit,
                                    stock: item.stockQuantity,
                                    enquiryNumber: item.buyNum || '1'
                                }
                            }else if(item.stockQuantity >0 ){
                                return this.$message.error('当前货品有货,请重新选择!'+item.drugNm);
                            }
                        })
                        if (this.multipleSelection.every(item => { return item.stockQuantity === 0})) {
                            let data = {
                                buyersId: buyersId,
                                buyersCode: buyersNo,
                                buyersName: buyersNm,
                                buyersPhone: buyersPhone || '',
                                productList: productList
                            };
                                saveBatchOutStockRegistration(data).then(res => {
                                this.$message.success('登记成功!')
                            })
                        }
                    }).catch(() => {

                    });
                } else {
                    this.$message.warning('请先选择要缺货商品')
                }
            },
            importExcel() {
                this.dialogVisible = true
            },
            upload() {
                this.$refs.uploadExcel.dispatchEvent(new MouseEvent('click'))
            },
            importUp() {
                this.newFile = new FormData()
                this.newFile.append('file', event.currentTarget.files[0])
                this.newFile.append('fileType', this.form.matchingMethod)
                this.isUploading = true;
                this.uploadStatus = undefined;
                this.uploadProgressLen = 0;
                uploadFiles(this.newFile,this.uploadProgress).then(res => {
                    let dataList = res.data
                    //每次上传以前清空错误表格
                    this.errorList = []
                    if (dataList && dataList.list) {
                        this.tableData = dataList.list
                        this.tableData.map(v => {
                          v.step = this.getRealMinBuyNum(v)
                        })
                        let text = ''
                        if (dataList.errCount > 0) {
                            text = '其中有' + dataList.errCount + '个无效导入';
                            console.log(text)
                            this.errorList = dataList.errorList;
                        }
                        this.changeForm()
                        this.uploadSuccess(text)
                    } else {
                        this.uploadError()
                    }
                }).catch(error => {
                    this.uploadError()
                })
            },
            //上传进度
            uploadProgress(e){
                if(e.lengthComputable){
                    this.uploadProgressLen = Math.floor((e.loaded / e.total) * 100 ) >= 100 ? 99 : Math.floor((e.loaded / e.total) * 100 )
                }
            },
            uploadError(){
                setTimeout(()=>{
                    this.uploadStatus = 'exception'
                    setTimeout(()=>{
                        this.isUploading = false
                        this.importResult(require('@/assets/img/fast-order/error.png'), '导入失败', '')
                    },500)
                },800)
            },
            uploadSuccess(text){
                setTimeout(()=>{
                    this.uploadStatus = 'success'
                    this.uploadProgressLen = 100
                    setTimeout(()=>{
                        this.isUploading = false
                        this.importResult(require('@/assets/img/fast-order/success.png'), '恭喜您，导入成功！', text)
                    },500)
                },800)
            },
            importResult(imgSrc, title, text) {
                this.dialogVisible = false
                this.importDialog = {
                    imgSrc: imgSrc,
                    title: title,
                    text: text
                }
                this.dialogSuccess = true
                this.$refs.uploadExcel.value = ''
            },
            downExcel() {
                exportTemplate(this.typeName[this.form.matchingMethod]).then(res => {
                    let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'})
                    let link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = this.matchingMethod[this.form.matchingMethod] + '.xlsx'
                    link.click()
                    link.remove()
                    this.$message.success('下载成功')
                })
            },
            up(index) {
                console.log(index)
                this.$refs['productNm' + index].up()
            },
            down(index) {
                console.log(index)
                this.$refs['productNm' + index].down()
            },
            enter(index) {
                this.$refs['productNm' + index].enter()
            }
        }
    }
</script>

<style lang="scss" scoped>
    $minWidth: 1200px;
    $width: 95%;
    $maxWidth: 1600px;
    /deep/ .el-table {
        .expDate-row {
          td{
              background: rgba(240,230,140,.2) !important;
          }
          .cell{
            color: #FF5200 !important;
            >span{
              color: #FF5200 !important;
            }
            div{
              color: #FF5200 !important;
            }
            input{
              color: #FF5200 !important;
            }
            p{
              color: #FF5200 !important;
            }
          }
        }
        .forbidSalesDate-row {
            td{
                background: rgba(144, 147, 153,.2) !important;
            }
            .cell{
                color: #000 !important;
                >span{
                    color: #000 !important;
                }
                div{
                    color: #000 !important;
                }
                input{
                    color: #000 !important;
                }
                p{
                    color: #000 !important;
                }
            }
        }
    }

    .aptitudes {
        line-height: 40px;
        font-size: 14px;
        color: #555;
        background-color: #FFF0EF;
        margin-bottom: 32px;
    }

    .main {
        position: relative;
        padding-bottom: 20px;
    }

    .user {
        width: 100%;
        height: 205px;
        background: rgba(255, 255, 255, 1);
        position: relative;
        padding: 1px;
    }

    .userDetail {
        display: flex;
        text-align: left;
        margin: 20px auto 91px;
        min-width: $minWidth;
        max-width: $maxWidth;
        width: $width;
        height: 69px;
        background: rgba(255, 255, 255, 1);
    }

    .userTitle {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(34, 34, 34, 1);
        line-height: 20px;
    }

    .userInfo {
        margin-right: 24px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(102, 102, 102, 1);
        line-height: 20px;
    }

    .last {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(153, 153, 153, 1);
        line-height: 20px;
    }

    .userMoney {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 59, 48, 1);
        line-height: 28px;
    }

    .productList {
        width: $width;
        min-width: $minWidth;
        max-width: $maxWidth;
        min-height: 700px;
        margin: 10px auto;
        position: relative;
        top: -65px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 10px;
    }

    .excel {
        width: 100%;
        position: relative;
        height: 49px;
    }

    .excelButton {
        position: absolute;
        left: 0px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 20px;
        padding: 8px;
        width: 152px;
        height: 36px;
    }

    .productList {
        /deep/ .el-table .cell {
            padding-left: 10px;
            padding-right: 10px;
            font-size: 12px;
        }

        ///deep/ .el-table__row{
        //  td:not(.is-hidden):last-child{
        //    right: -1px;
        //  }
        //}
    }

    .tablePro {
        /deep/ .el-table__header th .cell {
            padding-left: 10px;
            padding-right: 10px;
            font-size: 12px;
        }
    }

    /deep/ .el-input__inner {
        padding: 0 8px;
        font-size: 12px;
    }

    .changeAddr {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(9, 113, 235, 1);
        line-height: 22px;
    }

    .delete {
        padding: 1px;
        width: 39px;
        height: 22px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #666666;
        line-height: 17px;
        margin-left: -4px;
    }

    .price {
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 82, 0, 1);
    }

    .proNums {
        width: 100%;
        height: 22px;
        line-height: 22px;
        /deep/{
          .el-input-number__decrease,
          .el-input-number__increase{
            width: 20px;
            height: 20px;
            line-height: 20px;
          }
          .el-input{
            height: 20px;
            line-height: 20px;
            .el-input__inner{
              height: 22px;
              line-height: 22px;
            }
          }
        }
    }

    .addPro {
        margin-top: 8px;
        cursor: pointer;
        width: 100%;
        height: 36px;
        background: white;
        border-radius: 4px;
        border: 1px dashed #cccccc;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        @include TextHighlightColor;
        line-height: 31px;;
    }

    .count {
        width: 100%;
        height: 60px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 1px 4px 8px 0px rgba(0, 0, 0, 0.1);
        margin: 0 auto;
    }

    .countCon {
        width: 1140px;
        height: 100%;
        border: 1px solid red;
    }

    /*结算*/
    .settlement-container {
        width: 100%;
        height: auto;
        margin-top: 10px;
        position: absolute;
        bottom: 1px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 1px 4px 8px 0px rgba(0, 0, 0, 0.1);

        .settlement-row {
            margin: 0 auto;
            width: $width;
            min-width: $minWidth;
            max-width: $maxWidth;
            height: 60px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .row-left {
                font-size: 12px;
                font-weight: 500;
                color: rgba(102, 102, 102, 1);

                /deep/ .el-checkbox {
                    margin-right: 23px;

                    .el-checkbox__label {
                        font-size: 12px;
                        font-weight: 500;
                        color: rgba(102, 102, 102, 1);
                    }
                }

                span {
                    cursor: pointer;
                    margin-right: 20px;
                }
            }

            .row-right {
                display: flex;
                flex-direction: row;
                align-items: center;

                .settlement {
                    margin-right: 12px;
                    text-align: right;

                    .total-money {
                        height: 28px;
                        line-height: 28px;
                        font-size: 14px;
                        font-weight: 500;
                        color: rgba(34, 34, 34, 1);

                        span {
                            font-size: 20px;
                            color: rgba(224, 32, 52, 1);
                        }
                    }
                }

                .product-detail {
                    height: 14px;
                    font-size: 12px;
                    font-weight: 500;
                    color: rgba(102, 102, 102, 1);
                    line-height: 14px;

                    span {
                        margin-left: 12px;
                    }
                }

                .settlement-button-group {
                    height: 100%;

                    button {
                        border: none;
                        height: 60px;
                        padding: 0 28px;
                        text-align: center;
                        text-decoration: none;
                        display: inline-block;
                        font-size: 16px;
                    }

                    .immediate-settlement {
                        color: #ffffff;
                        background: #E02034;
                    }

                    .shopping-car {
                      color: #ffffff;
                      background: #ED702D;
                      &:disabled{
                        background: #999;
                        color: #fff;
                      }
                    }
                }

                .inputName {
                    /deep/ .el-input__inner {
                        padding: 0 8px;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .deleteAll {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        @include TextHighlightColor
    }

    .el-table {
        /deep/ .DisabledSelection .cell .el-checkbox__inner {
            display: none;
            position: relative;
        }
    }

    .el-table {
        /deep/ .DisabledSelection .cell:before {
            content: "选择";
            position: absolute;
            right: -11px;
            width: 40px;
            top: 1px;
        }
    }

    .inputName {
        /deep/ .el-input__inner {
            padding: 0 8px;
            font-size: 12px;
        }
    }

    /deep/ .el-dialog__header {
        /*padding: 0px;*/
    }

    /deep/ .el-dialog__headerbtn {
        top: 10px;
    }

    /deep/ .el-form-item__content {
        text-align: left;
    }

    .agreementDialog {
        height: 36px;
        text-align: left;
        line-height: 36px;
        background-color: #F5F5F5;
        font-size: 14px;
        color: #222222;
        width: 100%;
        font-weight: 700;
    }

    .importDiv {
        color: #222222;
        font-size: 16px;
        line-height: 32px;
        display: flex;
        padding-left: 20px;
        font-weight: 500;

        .importImg {
            width: 32px;
            height: 32px;
            margin-right: 10px;
        }
    }

    .importP {
        padding-left: 20px;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        margin-top: 15px;
        text-align: left;
    }

    .downExcelBt {
        color: $ThemeColor;
        border-color: $ThemeColor;
        margin-left: 8px;
    }

    .upExcelBt {
        width: 110px;
        height: 30px;
        font-size: 12px;
    }
    .progress{
        margin-top: 10px;
        /deep/.el-progress__text{
            font-size: 16px !important;
        }
    }
    .identifying {
        display: inline-block;
        width: 32px;
        height: 32px;
        img {
            width: 100%;
            height: 100%;
        }
    }
</style>



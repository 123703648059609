<template>
    <el-form ref="form" :model="form" :rules="formRules" label-width="0px" class="forget-phone-from">
      <el-form-item :prop="oldPhone?oldPhone:loginMobileId">
        <el-input v-model="oldPhone?oldPhone:form.loginMobileId" placeholder="输入旧手机号码" type="tel" maxlength="11"></el-input>
        <div class="input-icon">
          <img src="@/assets/img/login/icon-shouji.png" class="img-shouji" alt/>
        </div>
      </el-form-item>
      <el-form-item prop="code">
        <el-input v-model="form.code" placeholder="输入旧手机短信验证码" @keyup.enter.native="next"></el-input>
        <div class="input-icon">
          <img src="@/assets/img/login/icon-yanzhengma.png" class="img-suo" alt/>
        </div>
        <div class="zp-code">
          <el-button v-show="!secondShow" type="text" class="btn-change-code" :disabled="!disRandom" @click="getRandom">获取短信验证码</el-button>
          <el-button v-show="secondShow" type="text" class="btn-change-code" :disabled="true">{{ second }}秒后可重新获取</el-button>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button @click="next()" class="sureBut" type="primary">下一步</el-button>
      </el-form-item>
    </el-form>
</template>

<script>
import formRules from '@/utils/validate'
import { phoneVerifCode, getSmsAccountInfState } from '@/api/user'
import { saveFrontVerification } from '@/api/login/forget-password'
import Search from '@/components/Search/Search'

export default {
  name: 'verifyPhone',
   components: {
    Search
  },
  props: {
    oldPhone: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      second: 59,
      secondShow: false,
      form:{
        loginMobileId: '',
        code: '',
      },
      formRules: {
        loginMobileId: [formRules.phone],
        oldPhone: [formRules.phone],
        code: [{ required: true, trigger: 'blur', message: '验证码不能为空' }]
      },
      random: new Date().getTime(),
      origin: this.$route.query.origin,
      changeTitle: 'memberChangePhone'
    }
  },
  computed: {
    disRandom() {
      if(this.oldPhone){
         return /^1[3456789]\d{9}$/.test(this.oldPhone)
      }else {
         return /^1[3456789]\d{9}$/.test(this.form.loginMobileId)
      }
    }
  },
  methods: {
    next() {
      if(this.oldPhone){
        this.form.loginMobileId = this.oldPhone;
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          // this.$emit('next', 2, 1);
          saveFrontVerification({...this.form}).then(res => {
            // let param = this.form.loginId
            this.$emit('next', 2, 1)
          })
        }
      })
    },
    getRandom () {
      getSmsAccountInfState().then(vas => {
        if (vas.data.state === 'Y') {
          let phone = '';
          if(this.oldPhone){
            phone = this.oldPhone;
          }else {
            phone = this.form.loginMobileId;
          }

          phoneVerifCode(phone).then(res => {
            this.secondShow = true
            const setInter = setInterval(() => {
              --this.second
              if (this.second < 0) {
                this.second = 59
                this.secondShow = false
                clearInterval(setInter)
              }
            }, 1000)
          })
        } else {
          this.$message.error('短信发送已关闭')
        }
      })

    },


  }
}
</script>

<style lang="scss" scoped>
.forget-phone-from {
  height: 328px;
  width: 400px;
  margin: 40px auto 25px;
  /deep/ .el-input__inner{
    padding-left: 45px;
  }
  /deep/ .el-dialog__header {
    padding: 0px;
  }
  /deep/ .el-dialog__headerbtn {
    top: 10px;
  }
  input{
    padding-left: 48px;
  }
  .input-icon {
    position: absolute;
    left: 7px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    .img-user {
      width: 37px;
      height: 36px;
    }
    .img-shouji {
      width: 37px;
      height: 36px;
    }
    .img-suo {
      width: 36px;
      height: 36px;
    }
  }
  .zp-code {
    position: absolute;
    top: 0px;
    bottom: 10px;
    right: 15px;
    display: flex;
    .img-container {
      background-color: #fff;
      height: 28px;
      width: 90px;
      margin-top: 4px;
      img {
        width: 72px;
        height: 32px;
      }
    }
  }
  .checkSpan {
    margin-left: 10px;
    font-size: 12px;
    color: #A3A3A3;
    a {
      color: #318AF8;
    }
  }
  .checkFrom{
    margin-bottom: 10px;
    text-align: right;
  }

  .sureBut {
    width: 400px;
    height: 40px;
    background-color: #0971EB;
  }
}

.registerDiv{
  width: 100%;
  text-align: center;
  .step {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px auto 0 auto;
    .stepNum {
      width: 20px;
      height: 20px;
      background-color: #0971EB;
      border-radius: 50%;
      color: #FFFFFF;
      text-align: center;
      line-height: 20px;
      font-size: 14px;
    }
    .stepNumNot {
      background-color: #E5E5E5;
      color: #999999;
    }
    .stepTitle {
      display: block;
      padding-top: 2px;
      color: #222222;
      margin-left: 8px;
      font-weight: 700;
      font-size: 16px;
    }
    .stepTitleNot {
      color: #A3A3A3;
    }
    .stepLine {
      width: 60px;
      height: 1px;
      background-color: #0971EB;
      margin: auto 16px;
    }
    .stepLineNot {
      background-color: #DDDDDD;
    }
  }
  .registerFrom {
    width: 400px;
    margin: 25px auto;
    input{
      padding-left: 48px;
    }
    .input-icon {
      position: absolute;
      left: 12px;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      .img-user {
        width: 14px;
        height: 16px;
      }
      .img-suo {
        width: 13px;
        height: 16px;
      }
    }
    .checkSpan {
      margin-left: 10px;
      font-size: 12px;
      color: #A3A3A3;
      a {
        color: #318AF8;
      }
    }
  }
  .checkFrom{
    text-align: left;
    margin-bottom: 10px;
  }
  .sureBut {
    width: 400px;
    height: 40px;
    background-color: #0971EB;
  }
  .divisionClass{
    height: 20px;
    width: 100%;
    background: #F6F6F6;
  }
}
</style>
<style lang="scss">
  .forget-phone-from{
    .el-dialog{
      width: 500px;
      .el-dialog__body{
        padding: 50px 20px;
      }
    }
  }
</style>

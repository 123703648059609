<template>
  <div v-if="recoProduct.length > 0">
    <div class="recommend-container recommend-default" v-if="type === 'default'">
      <div class="recommend">
        <p>为你精选</p>
        <swiper class="recommend-swiper" :reco-product="recoProduct" />
      </div>
    </div>
    <div class="recommend-container recommend-for" v-else>
      <div class="recommend">
        <p>猜您喜欢</p>
        <div>
          <swiper :slides-per-view="3" class="recommend-swiper" :reco-product="recoProduct"></swiper>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Swiper from '@/components/AwesomeSwiper'
import { recommendToList, pageRecommend } from '@/api/index/index'
import { mapGetters } from 'vuex'

export default {
  components: {
    Swiper
  },
  props: {
    type: {
      type: String,
      default: 'default',
    },
    appoint: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      recoProduct: ''
    }
  },
  computed: {
      ...mapGetters(['userDetail'])
    },
  watch:{
    'userDetail.selectEttleAccountNm' (newValue, oldValue) {
        if (this.userDetail.selectEttleAccountNm != 'null') {
          if (!this.appoint) {
            recommendToList().then(res => {
              this.recoProduct = res.data
            })
          } else {
            pageRecommend({
              pageSize: 1000,
              pageNum: 1,
              condition: {
                searchField: ''
              }
            }).then(res => {
              this.recoProduct = res.data.records
            })
          }
        }
      },
  },
  mounted() {
    if (!this.appoint) {
      recommendToList().then(res => {
        this.recoProduct = res.data
      })
    } else {
      pageRecommend({
        pageSize: 1000,
        pageNum: 1,
        condition: {
          searchField: ''
        }
      }).then(res => {
        this.recoProduct = res.data.records
      })
    }
  }
}
</script>

<style scoped lang="scss">
.recommend-container {
  width: 100%;
  height: auto;
  padding: 0 0 20px;
  background: #ffffff;
  border-radius: 8px 8px 0 0;
  margin-top: 12px;

  .recommend {
    margin: 0 auto;
    border-radius: 8px 8px 0 0;
    background: #ffffff;

    p {
      padding: 12px 16px;
      text-align: left;
      font-weight: 600;
      color: rgba(34, 34, 34, 1);
    }

    & > div {
      padding: 0 16px;
    }

    /*轮播图*/
    .recommend-swiper {
      width: 100%;
      height: 158px;
    }
  }
}
.recommend-default{
  padding: 20px 0;
    .recommend {
      margin: 0 auto;
      border-radius: 8px 8px 0 0;
      border: 1px solid rgba(229, 229, 229, 1);
      min-width: 1200px;
      max-width: 1600px;
      width: 100%;
      height: auto;
      background: #ffffff;
      p {
        box-sizing: border-box;
        width: 100%;
        height: 28px;
        line-height: 28px;
        text-align: left;
        font-size: 14px;
        font-weight: 600;
        color: rgba(34, 34, 34, 1);
        background: rgba(255, 102, 0, 0.05);
        padding: 0 0 0 20px;
      }

      /*轮播图*/
      .recommend-swiper {
        width: 100%;
        height: 158px;
      }
    }
}
.recommend-for{
  .recommend{
    width: 992px;
  }
}

</style>
